import dayjs from "dayjs";
import { useEffect, useMemo, useState } from "react";
import {
  DatePicker,
  Dialog,
  Flex,
  Text,
  Icon,
  Input,
  useDisclosure,
  Divider,
  Button,
} from "renos-ui";

const PeriodPicker = ({ value, onChange, isError, disabled }) => {
  const disclosure = useDisclosure({ isOpen: false });

  const today = new Date();
  const [startDate, setStartDate] = useState({
    date: value.startDate,
    time: value.startDate,
  });
  const [endDate, setEndDate] = useState({
    date: value.endDate,
    time: value.endDate,
  });

  const handleChange = (newValue, propertyName, isStart) => {
    let newData = newValue;

    if (propertyName === "time") {
      const splitTime = newValue.split(":");
      newData = new Date();
      newData.setHours(splitTime[0]);
      newData.setMinutes(splitTime[1]);
    }

    if (isStart) {
      setStartDate((prev) => ({ ...prev, [propertyName]: newData }));
      if (propertyName === "date" && newData > endDate[propertyName])
        setEndDate((prev) => ({ ...prev, [propertyName]: "" }));
    } else {
      setEndDate((prev) => ({ ...prev, [propertyName]: newData }));
    }
  };

  const handleSave = () => {
    const dateStart = startDate.date;
    dateStart.setHours(startDate.time.getHours());
    dateStart.setMinutes(startDate.time.getMinutes());

    const dateEnd = endDate.date;
    dateEnd.setHours(endDate.time.getHours());
    dateEnd.setMinutes(endDate.time.getMinutes());

    onChange({ startDate: dateStart, endDate: dateEnd });
    disclosure.onClose();
  };

  const isDisabled = useMemo(
    () => !startDate.date || !endDate.date,
    [startDate, endDate]
  );

  const formattedStartValue = useMemo(
    () =>
      value.startDate && dayjs(value.startDate).format("DD/MM/YYYY - HH:mm"),
    // eslint-disable-next-line
    [value.startDate, disclosure.isOpen]
  );

  const formattedEndValue = useMemo(
    () => value.endDate && dayjs(value.endDate).format("DD/MM/YYYY - HH:mm"),
    // eslint-disable-next-line
    [value.endDate, disclosure.isOpen]
  );

  useEffect(() => {
    const tempStartTime = new Date();
    tempStartTime.setHours(tempStartTime.getHours() + 1);

    const tempEndTime = new Date();
    tempEndTime.setHours(tempEndTime.getHours() + 2);

    setStartDate({
      date: value.startDate,
      time: value.startDate || tempStartTime,
    });
    setEndDate({
      date: value.endDate,
      time: value.endDate || tempEndTime,
    });
    // eslint-disable-next-line
  }, [disclosure.isOpen]);

  return (
    <>
      <Input
        label="Periode Mulai"
        placeholder="Pilih Tanggal"
        value={formattedStartValue}
        sufix={<Icon name="Calendar-outline" size={20} color="black50" />}
        size="small"
        readOnly
        onClick={disclosure.onOpen}
        isError={isError}
        disabled={disabled}
      />
      <Input
        label="Periode Berakhir"
        placeholder="Pilih Tanggal"
        value={formattedEndValue}
        sufix={<Icon name="Calendar-outline" size={20} color="black50" />}
        size="small"
        readOnly
        onClick={disclosure.onOpen}
        isError={isError}
        disabled={disabled}
      />

      <Dialog
        isVisible={disclosure.isOpen}
        onClose={disclosure.onClose}
        closable
        verticalCentered
        width="320px"
      >
        <Flex flexDirection="column" width="100%" gap="16px">
          <Text variant="heading3" weight="bold">
            Atur Periode Voucher
          </Text>
          <Divider />

          <Flex flexDirection="column" gap="24px">
            <Flex gap="16px" flexDirection="column">
              <Text variant="caption" weight="bold" color="black50">
                Periode Mulai
              </Text>
              <Flex gap="8px">
                <Flex width="156px">
                  <DatePicker
                    selected={startDate.date}
                    value={startDate.date}
                    minDate={today}
                    onChange={(newValue) =>
                      handleChange(newValue, "date", true)
                    }
                    dateFormat="dd MMM yyyy"
                    customInput={
                      <Input
                        placeholder="Tanggal mulai"
                        size="small"
                        width="156px"
                        sufix={
                          <Icon
                            name="Calendar-outline"
                            size={20}
                            color="black50"
                          />
                        }
                      />
                    }
                  />
                </Flex>
                <Flex width="156px">
                  <DatePicker.Time
                    selected={startDate.time}
                    value={startDate.time}
                    onChange={(_, newValue) =>
                      handleChange(newValue, "time", true)
                    }
                    customInput={({ formattedTime }) => (
                      <Input
                        placeholder="Masukan jam"
                        value={`${formattedTime.hour} : ${formattedTime.minute}`}
                        size="small"
                        error="Waktu minimal 1 jam dari saat ini"
                      />
                    )}
                  />
                </Flex>
              </Flex>
              <Flex gap="8px" flexDirection="column">
                <Text variant="caption" weight="bold" color="black50">
                  Periode Berakhir
                </Text>
                <Flex gap="8px">
                  <Flex width="156px">
                    <DatePicker
                      selected={endDate.date}
                      value={endDate.date}
                      onChange={(newValue) => handleChange(newValue, "date")}
                      minDate={startDate.date || today}
                      dateFormat="dd MMM yyyy"
                      customInput={
                        <Input
                          size="small"
                          placeholder="Tanggal selesai"
                          sufix={
                            <Icon
                              name="Calendar-outline"
                              size={20}
                              color="black50"
                            />
                          }
                        />
                      }
                    />
                  </Flex>
                  <Flex width="156px">
                    <DatePicker.Time
                      selected={endDate.time}
                      value={endDate.time}
                      onChange={(_, newValue) => handleChange(newValue, "time")}
                      customInput={({ formattedTime }) => (
                        <Input
                          placeholder="Masukan jam"
                          value={`${formattedTime.hour} : ${formattedTime.minute}`}
                          size="small"
                          error="Waktu harus sesudah periode sebelumnya"
                        />
                      )}
                    />
                  </Flex>
                </Flex>
              </Flex>
            </Flex>

            <Button isBlock disabled={isDisabled} onClick={handleSave}>
              Simpan Pengaturan
            </Button>
          </Flex>
        </Flex>
      </Dialog>
    </>
  );
};

export default PeriodPicker;
