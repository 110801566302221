import FilterCategory from "./FilterCategory";
import FilterDate from "./FilterDate";
import FilterPrice from "./FilterPrice";
import FilterShipping from "./FilterShipping";
import FilterStatus from "./FilterStatus";
import FilterStock from "./FilterStock";

const listFilter = [
  {
    label: "Kategory",
    value: "category",
    component: <FilterCategory />,
  },
  {
    label: "Status",
    value: "status",
    component: <FilterStatus />,
  },
  {
    label: "Stok",
    value: "stock",
    component: <FilterStock />,
  },
  {
    label: "Jasa Pengiriman",
    value: "deliveryService",
    component: <FilterShipping />,
  },
  {
    label: "Harga",
    value: "price",
    component: <FilterPrice />,
  },
  {
    label: "Tanggal",
    value: "date",
    component: <FilterDate />,
  },
];

export default listFilter;
