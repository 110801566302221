import { currencyFormatter } from "helpers";
import { Flex, Input, Text, useToast } from "renos-ui";

const PriceInput = ({
  value,
  setValue,
  isVariant,
  product_skus,
  disabled,
  valueError,
  setValueError,
  isHaveDiscount,
}) => {
  const toast = useToast();

  const handleVariant = (newPrice, indexVariant) => {
    const arrError = valueError;
    arrError[indexVariant] = false;

    const arrNewPrice = value.map((price, indexPrice) => {
      if (indexVariant === indexPrice) return newPrice;

      return price;
    });
    setValue([...arrNewPrice]);
    setValueError([...arrError]);
  };

  const onProductWithDiscountClicked = () => {
    toast({
      label:
        "Harga produk tidak bisa diubah karena produk sudah diatur untuk promosi.",
      placement: "bottom",
      width: "90dvw",
    });
  };

  if (isVariant)
    return (
      <Flex
        flexDirection="column"
        gap="24px"
        maxHeight="calc(100dvh - 139px)"
        overflow="auto"
      >
        {product_skus.map((variant, indexVariant) => {
          const isHaveDiscount = variant.is_product_discount_seasonal_active;

          return (
            <Flex
              flexDirection="column"
              gap="8px"
              key={`variant-${indexVariant}`}
            >
              <Text variant="caption" weight="bold" color="black50">
                {variant.formatted_variant_value_name}
              </Text>
              <Flex position="relative" width="100%">
                <Input.Number
                  disabled={disabled || isHaveDiscount}
                  size="small"
                  value={currencyFormatter(value[indexVariant])}
                  onChange={(newValue) => handleVariant(newValue, indexVariant)}
                  prefix={
                    <Text weight="bold" color="black50">
                      Rp
                    </Text>
                  }
                  isError={valueError[indexVariant]}
                  error="Harga tidak boleh 0"
                  max={999999999}
                />
                {isHaveDiscount && (
                  <Flex
                    position="absolute"
                    width="100%"
                    height="100%"
                    zIndex={100}
                    onClick={onProductWithDiscountClicked}
                  />
                )}
              </Flex>
            </Flex>
          );
        })}
      </Flex>
    );

  return (
    <Flex position="relative" width="100%">
      <Input.Number
        disabled={disabled || isHaveDiscount}
        size="small"
        value={currencyFormatter(value)}
        onChange={(newValue) => {
          setValue(newValue);
          setValueError(false);
        }}
        prefix={
          <Text weight="bold" color="black50">
            Rp
          </Text>
        }
        isError={valueError}
        error="Harga tidak boleh 0"
        max={999999999}
      />
      {isHaveDiscount && (
        <Flex
          position="absolute"
          width="100%"
          height="100%"
          zIndex={100}
          onClick={onProductWithDiscountClicked}
        />
      )}
    </Flex>
  );
};

export default PriceInput;
