import React, { useMemo } from "react";
import { useWatch } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { Box, Button, Flex, Spinner } from "renos-ui";

const BottomBar = ({ isLoading, setIsReset }) => {
  const history = useHistory();

  const shippingType = useWatch({ name: "shipping_type" });
  const productShipping = useWatch({ name: "product_shipping" });

  const isDisable = useMemo(() => {
    if (shippingType === 2 && productShipping.size === 0) {
      return true;
    }

    return false;
  }, [shippingType, productShipping]);

  return (
    <Flex
      backgroundColor="white"
      borderBottomLeftRadius={16}
      borderBottomRightRadius={16}
      py={16}
      px={24}
      justifyContent="space-between"
      boxShadow="0px 4px 4px rgba(20, 20, 32, 0.05)"
    >
      <Box width="160px">
        <Button
          type="button"
          isBlock
          variant="tertiary"
          size="large"
          onClick={() => history.push("/products/list")}
        >
          Batal
        </Button>
      </Box>
      <Flex alignItems="center" columnGap="16px">
        <Button
          type="submit"
          preffix={isLoading && <Spinner size="10px" color="black10" />}
          disabled={isLoading || isDisable}
          variant="secondary"
          size="large"
          onClick={() => setIsReset(true)}
        >
          Simpan & Tambah Baru
        </Button>
        <Box width="160px">
          <Button
            preffix={isLoading && <Spinner size="10px" color="white" />}
            disabled={isLoading || isDisable}
            type="submit"
            isBlock
            variant="primary"
            size="large"
          >
            Simpan
          </Button>
        </Box>
      </Flex>
    </Flex>
  );
};

export default BottomBar;
