import { Flex, Box, Text } from "renos-ui";
import IMGEmpty from "assets/images/ic_empty_product.png";

const EmptyProduct = () => {
  return (
    <Flex gap="8px" justifyContent="center" alignItems="center" margin="20px">
      <Flex flexDirection="column" alignItems="center">
        <Box
          as="img"
          src={IMGEmpty}
          width="160px"
          height="160px"
          alt="empty-product"
        />
        <Flex flexDirection="column" alignItems="center">
          <Text variant="heading4" weight="bold" color="black100">
            Belum Ada Daftar Produk
          </Text>
          <Text color="black75" variant="caption" text="center">
            Yuk, tambahkan produk pertamamu untuk
          </Text>
          <Text color="black75" variant="caption" text="center">
            mulai berjualan.
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default EmptyProduct;
