import { Flex, Skeleton, range } from "renos-ui";

const SkeletonDashboard = ({ itemShow = 1, showFilter = true }) => {
  return (
    <Flex flexDirection="column" width="100%">
      {showFilter && (
        <>
          <Flex
            p="16px"
            flexDirection="column"
            gap="16px"
            justifyContent="space-between"
          >
            <Skeleton height="20px" width="80%" />
          </Flex>
        </>
      )}
      {range(itemShow).map((_, indexItem) => (
        <Flex
          p="16px"
          gap="16px"
          justifyContent="space-between"
          key={`item-skeleton${indexItem}`}
        >
          <Skeleton height="80px" width="100%" />
        </Flex>
      ))}
    </Flex>
  );
};

export default SkeletonDashboard;
