import MobileLayout from "layouts/MobileLayout";
import React, { useMemo } from "react";
import {
  Button,
  Divider,
  Drawer,
  Flex,
  Icon,
  Input,
  Text,
  Toggle,
  isEmpty,
  useDisclosure,
  useToast,
} from "renos-ui";
import FormLayout from "../../components/FormLayout";
import { useProductAddContext } from "store/productAdd/reducer";
import constants from "store/productAdd/constants";
import { useHistory } from "react-router-dom";
import mobileUrl from "constants/mobileUrl";
import VariantTable from "./VariantTable";
import { mobileSkuValidationSchema } from "helpers/validation-schema/product-add";

const ProductSpesification = () => {
  const { push, goBack } = useHistory();
  const { state, dispatch } = useProductAddContext();
  const toast = useToast();

  const sniDisclosure = useDisclosure({ open: false });
  const deleteDisclosure = useDisclosure({ open: false });

  const {
    product_sku: productSku,
    stock,
    price,
    sku,
    product_active: productIsActive,
    isEdit,
    is_product_discount_seasonal_active,
    product_attributes,
  } = state;

  const isProductAttributeEmpty = useMemo(
    () =>
      product_attributes.length > 0 &&
      product_attributes.every((item) => isEmpty(item.product_attribute_value)),
    [product_attributes]
  );

  const onValueChange = (value, name) => {
    dispatch({
      type: constants.SAVE_VALUE,
      payload: {
        [name]: value,
      },
    });
  };

  const isHaveDiscount = useMemo(
    () => is_product_discount_seasonal_active,
    [is_product_discount_seasonal_active]
  );

  const isSku = useMemo(() => !isEmpty(sku) && sku.length > 0, [sku]);

  const onFinish = () => {
    if (
      state.sku.length === 0 &&
      state.stock === 0 &&
      isEdit &&
      state.product_active === true
    ) {
      toast({
        label:
          "Stok produk harus lebih dari 0 jika status SKU/Produk nya Aktif.",
        placement: "bottom",
      });

      return;
    }

    // handling default variant should active on edit
    if (isEdit && state.sku.length > 0) {
      const defaultSkuIndex = state.sku.findIndex((data) => data.default === 1);
      const activeSkuIndex = state.sku.findIndex((data) => data.status === 1);

      if (
        state.sku[defaultSkuIndex].status === 2 &&
        defaultSkuIndex !== activeSkuIndex &&
        activeSkuIndex >= 0
      ) {
        toast({
          label: "Pastikan varian aktif merupakan varian utama",
          placement: "bottom",
        });

        return;
      }
    }

    // handling active variant should have more than 0 stock
    if (isEdit && state.sku.length > 0) {
      const activeSkuwithZeroStock = state.sku.find(
        (data) => data.status === 1 && data.stock === 0
      );

      if (activeSkuwithZeroStock) {
        toast({
          label:
            "Stok produk harus lebih dari 0 jika status SKU/Produk nya Aktif.",
          placement: "bottom",
        });

        return;
      }
    }

    if (isSku) {
      if (!mobileSkuValidationSchema(sku, isSku)) {
        toast({
          label: "Lengkapi data sebelum melanjutkan",
          placement: "bottom",
        });

        return;
      }
    } else {
      if ((!stock && productIsActive) || !price) {
        toast({
          label: "Lengkapi data sebelum melanjutkan",
          placement: "bottom",
        });

        return;
      }
    }

    if (isEdit) {
      goBack();

      return;
    }

    push(mobileUrl.PRODUCTS_ADD);
  };

  const onProductWithDiscountClicked = () => {
    toast({
      label:
        "Harga produk tidak bisa diubah karena produk sudah diatur untuk promosi.",
      placement: "bottom",
      width: "90dvw",
    });
  };

  return (
    <MobileLayout
      header={{
        title: "Spesifikasi Produk",
        isHomepage: true,
        withBack: true,
        suffix: (
          <Flex alignItems="center" cursor="pointer" onClick={onFinish}>
            <Text color="blue75" weight="bold">
              Selesai
            </Text>
          </Flex>
        ),
      }}
    >
      <Flex padding="16px" flexDirection="column" rowGap="24px" width="100%">
        <FormLayout title="Varian Produk" rowGap="8px">
          <Flex justifyContent="space-between" alignItems="center">
            <Text width="240px" variant="caption" color="black50">
              Tambah varian untuk produkmu seperti pilihan warna, ukuran, atau
              lainnya. Maksimum 2 tipe varian, ya.
            </Text>
            <Button
              variant="secondary"
              size="medium"
              onClick={() => {
                push(mobileUrl.VARIANT_FORM);
                dispatch({
                  type: constants.SAVE_VALUE,
                  payload: { fromEmpty: true },
                });
              }}
            >
              {isSku ? "Edit" : "Tambah"}
            </Button>
          </Flex>
        </FormLayout>
        {isSku ? (
          <VariantTable />
        ) : (
          <>
            <FormLayout title="Harga" required rowGap="8px">
              <Flex position="relative" width="100%">
                <Input.Number
                  placeholder="Tulis harga produk"
                  size="small"
                  value={price}
                  max={999999999}
                  disabled={isHaveDiscount}
                  onChange={(value) => {
                    onValueChange(value, "price");
                  }}
                  prefix={
                    <Text weight="bold" color="black50">
                      Rp
                    </Text>
                  }
                />
                {isHaveDiscount && (
                  <Flex
                    position="absolute"
                    width="100%"
                    height="100%"
                    zIndex={100}
                    onClick={onProductWithDiscountClicked}
                  />
                )}
              </Flex>
            </FormLayout>
            <FormLayout title="Stok" required rowGap="8px">
              <Input.Number
                placeholder="Masukkan stok produk"
                size="small"
                max={99999}
                value={stock === 0 ? "0" : stock}
                onChange={(value) => onValueChange(value, "stock")}
              />
            </FormLayout>
            <FormLayout title="SKU" rowGap="8px">
              <Input
                placeholder="Stock Keeping Unit"
                size="small"
                hint="Tambahkan nomor SKU untuk mempermudah pencarian produkmu"
                value={productSku}
                onChange={(event) =>
                  onValueChange(event.target.value, "product_sku")
                }
              />
            </FormLayout>
            <FormLayout title="Status" required rowGap="8px">
              <Flex
                alignItems="start"
                columnGap="8px"
                onClick={() =>
                  dispatch({
                    type: constants.SAVE_VALUE,
                    payload: { product_active: !productIsActive },
                  })
                }
                cursor="pointer"
                width="fit-content"
              >
                <Toggle checked={productIsActive} />
                {productIsActive ? (
                  <Text weight="bold" color="blue50">
                    Aktif
                  </Text>
                ) : (
                  <Text weight="bold" color="black50">
                    Nonaktif
                  </Text>
                )}
              </Flex>
            </FormLayout>
          </>
        )}
        {product_attributes.length > 0 && (
          <Flex
            p={12}
            justifyContent="space-between"
            alignItems="center"
            borderStyle="solid"
            borderWidth="1px"
            borderColor="black5"
            borderRadius="8px"
          >
            <Flex flexDirection="column" rowGap="4px" width="200px">
              <Text variant="caption" weight="bold">
                Standarisasi Produk
              </Text>
              <Text variant="caption" color="black50">
                Tambahkan nomor SNI produkmu apabila tersedia.
              </Text>
            </Flex>
            {isProductAttributeEmpty ? (
              <Flex
                alignItems="center"
                justifyContent="center"
                padding="5px"
                borderWidth="1px"
                borderStyle="solid"
                borderColor="blue50"
                borderRadius="50%"
                cursor="pointer"
                onClick={() => push(mobileUrl.SNI_INPUT)}
              >
                <Icon name="Right-outline" size={14} color="blue50" />
              </Flex>
            ) : (
              <Icon
                name="Vertical-outline"
                size={20}
                cursor="pointer"
                onClick={sniDisclosure.onOpen}
              />
            )}
          </Flex>
        )}
      </Flex>
      <Drawer
        isVisible={sniDisclosure.isOpen}
        onClose={sniDisclosure.onClose}
        placement="bottom"
        closable
      >
        <Flex flexDirection="column" rowGap="16px" pt={16} mx={-16}>
          <Flex pl={16} onClick={() => push(mobileUrl.SNI_INPUT)}>
            <Text>Lihat Detail</Text>
          </Flex>
          <Divider />
          <Flex
            pl={16}
            onClick={() => {
              deleteDisclosure.onOpen();
              sniDisclosure.onClose();
            }}
          >
            <Text>Hapus</Text>
          </Flex>
        </Flex>
      </Drawer>
      <Drawer
        isVisible={deleteDisclosure.isOpen}
        onClose={deleteDisclosure.onClose}
        placement="bottom"
        closable
      >
        <Flex flexDirection="column" rowGap="24px" flex={1}>
          <Flex flexDirection="column" rowGap="4px">
            <Text variant="subtitle" weight="bold">
              Hapus lampiran?
            </Text>
            <Text color="black75">
              Penghapusan lampiran tidak dapat dibatalkan
            </Text>
          </Flex>
          <Flex columnGap="16px">
            <Button
              variant="secondary"
              isBlock
              onClick={deleteDisclosure.onClose}
            >
              Batalkan
            </Button>
            <Button
              isBlock
              onClick={() => {
                dispatch({
                  type: constants.SAVE_VALUE,
                  payload: {
                    product_attributes: product_attributes?.map((item) => ({
                      ...item,
                      product_attribute_value: null,
                    })),
                  },
                });
                deleteDisclosure.onClose();
              }}
            >
              Ya, Hapus
            </Button>
          </Flex>
        </Flex>
      </Drawer>
    </MobileLayout>
  );
};

export default ProductSpesification;
