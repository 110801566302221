import React from "react";
import { Flex, Text, Icon, Box, Toggle } from "renos-ui";

const AutomateTemplate = (props) => {
  const { checked, checkDefault, drawer, handleActiveSwitch, handleDisabled } =
    props;

  return (
    <Flex
      padding="24px"
      alignItems="center"
      backgroundColor={checked === true ? "blue5" : "black5"}
      borderRadius="8px"
      gap="16px"
      justifyContent="space-between"
    >
      <Flex gap="8px" alignItems="center" onClick={() => drawer.onOpen()}>
        <Icon
          name="Info-solid"
          color={checked === true ? "blue50" : "black50"}
          size={20}
        />
        <Text
          color={checked === true ? "blue75" : "black75"}
          variant="body"
          weight="bold"
        >
          Balas ulasan otomatis
        </Text>
      </Flex>
      <Flex>
        <Box
          onClick={
            checkDefault?.length === 0 ? handleDisabled : handleActiveSwitch
          }
        >
          <Toggle checked={checked} />
        </Box>
      </Flex>
    </Flex>
  );
};

export default AutomateTemplate;
