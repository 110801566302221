import { Dialog, Flex, Text, Button, useToast } from "renos-ui";
import { useDeleteSellerFleetSetting } from "services/logistics/sellerFleet";

const DialogDelete = ({ isOpen, onClose, refetchSetting, id }) => {
  const toast = useToast();

  const { isLoading, mutate: mutateDelete } = useDeleteSellerFleetSetting({
    onSuccess: () => {
      onClose();
      toast({
        label: "Pengaturan pengiriman berhasil dihapus.",
        placement: "top",
      });
      refetchSetting();
    },
    onError: () => {
      toast({
        label: "Pengaturan pengiriman gagal dihapus.",
        backgroundColor: "red50",
        placement: "top",
      });
    },
  });

  return (
    <Dialog isVisible={isOpen} onClose={onClose} verticalCentered>
      <Flex
        width="320px"
        fontFamily="sans-serif"
        flexDirection="column"
        rowGap="24px"
      >
        <Flex flexDirection="column" rowGap="8px">
          <Text variant="heading3" weight="bold">
            Hapus Pengaturan?
          </Text>
          <Text>
            Pengaturan pengiriman yang dipilih akan dihapus. Lanjut hapus?
          </Text>
        </Flex>
        <Flex flexDirection="row" justifyContent="space-between" gap="1rem">
          <Button isBlock size="large" variant="tertiary" onClick={onClose}>
            Tidak, Batal
          </Button>
          <Button
            isBlock
            size="large"
            variant="primary"
            onClick={() => mutateDelete({ courier_service_setting_id: id })}
            disabled={isLoading}
          >
            Ya, Hapus
          </Button>
        </Flex>
      </Flex>
    </Dialog>
  );
};

export default DialogDelete;
