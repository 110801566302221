import { Flex, Text, Icon } from "renos-ui";
import { StyledDisclosure } from "./styled";
import { Wrapper } from "containers/web/notification/styled";

const Card = ({ date, title, message, onClick, isRead }) => {
  return (
    <Flex
      flexDirection="column"
      padding="16px"
      borderBottomColor="black10"
      borderBottomStyle="solid"
      borderBottomWidth="1px"
      gap="4px"
      onClick={onClick}
      backgroundColor={isRead ? "white" : "blue5"}
    >
      <Text variant="small" color="black25">
        {date}
      </Text>
      <StyledDisclosure defaultOpen={false}>
        {({ isOpen, onToggle }) => (
          <Flex flexDirection="column">
            {!isOpen && (
              <Flex flexDirection="column" gap="4px">
                <Wrapper>
                  <Text weight="bold">{title}</Text>
                </Wrapper>
                <Wrapper>
                  <Text color="black75" lineClamp={2}>
                    {message}
                  </Text>
                </Wrapper>
              </Flex>
            )}

            {!isOpen && message.length > 200 && (
              <Flex
                width="100%"
                justifyContent="flex-end"
                alignItems="center"
                gap="4px"
                onClick={onToggle}
                cursor="pointer"
              >
                <Text variant="caption" color="blue50" weight="bold">
                  Selengkapnya
                </Text>
                <Icon
                  name={isOpen ? "Up-outline" : "Down-outline"}
                  size={16}
                  color="blue50"
                />
              </Flex>
            )}

            {isOpen && (
              <Flex flexDirection="column">
                <Flex flexDirection="column" gap="4px">
                  <Wrapper>
                    <Text weight="bold">{title}</Text>
                  </Wrapper>
                  <Wrapper>
                    <Text>{message}</Text>
                  </Wrapper>
                </Flex>
                <Flex
                  width="100%"
                  justifyContent="flex-end"
                  alignItems="center"
                  gap="4px"
                  onClick={onToggle}
                  cursor="pointer"
                >
                  <Text variant="caption" color="blue50" weight="bold">
                    Tutup
                  </Text>
                  <Icon
                    name={isOpen ? "Up-outline" : "Down-outline"}
                    size={16}
                    color="blue50"
                  />
                </Flex>
              </Flex>
            )}
          </Flex>
        )}
      </StyledDisclosure>
    </Flex>
  );
};

export default Card;
