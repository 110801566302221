import React, { useContext } from "react";
import { Flex, Label, Text } from "renos-ui";
import StoreContext from "providers/StoreProvider";

const MainAddress = () => {
  const {
    storeData: {
      data: { locations },
    },
  } = useContext(StoreContext);

  const ADDRESS = locations.filter((location) => location.is_primary)[0];

  return (
    <Flex
      borderRadius="8px"
      gap="16px"
      padding="16px"
      margin="16px"
      background="white"
      flexDirection="column"
    >
      <Flex flexDirection="column" gap="8px">
        <Flex gap="12px" alignItems="center">
          <Text variant="caption" weight="bold">
            {ADDRESS?.name}
          </Text>
          <Label variant="success">Alamat Utama</Label>
        </Flex>
        <Text variant="caption" color="black50">
          {ADDRESS?.contact_number}
        </Text>
        <Text variant="caption" color="black50">
          {ADDRESS?.address}
        </Text>
        <Text
          variant="caption"
          color="black50"
        >{`${ADDRESS?.lower_district_name}, ${ADDRESS?.district_name}, ${ADDRESS?.city_name}, ${ADDRESS?.province_name} - ${ADDRESS?.postal_code}`}</Text>
      </Flex>
    </Flex>
  );
};

export default MainAddress;
