import { Divider, Flex, Skeleton, range } from "renos-ui";

const Loader = () => {
  return (
    <Flex gap="8px" flexDirection="column">
      <Flex
        padding="24px"
        gap="24px"
        flexDirection="column"
        backgroundColor="white"
        borderRadius="16px 16px 0px 0px"
      >
        <Divider />
        {range(5).map((_, indexSkeleton) => (
          <Flex
            width="100%"
            columnGap="20px"
            alignItems="center"
            key={`card-skeleton-${indexSkeleton}`}
          >
            <Skeleton height="20px" width="20px" />
            <Flex flexGrow={1}>
              <Skeleton height="40px" width="100%" />
            </Flex>
            <Skeleton height="40px" width="150px" />
            <Skeleton height="40px" width="150px" />
            <Skeleton height="40px" width="150px" />
            <Skeleton height="40px" width="150px" />
            <Skeleton height="40px" width="150px" />
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
};

export default Loader;
