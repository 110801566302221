import React from "react";
import { Box, Divider, Flex, Input, Text } from "renos-ui";

const FilterPrice = () => {
  return (
    <Flex padding="24px" flexDirection="column" width="100%" rowGap="16px">
      <Flex flexDirection="column">
        <Text weight="bold" color="black75">
          Rentang Harga
        </Text>
        <Text variant="caption" color="black75">
          Atur filter berdasarkan rentang harga.
        </Text>
      </Flex>

      <Flex gap="8px" width="100%" alignItems="center">
        <Flex>
          <Input.Number
            size="small"
            prefix={
              <Text weight="bold" color="black50">
                Rp
              </Text>
            }
            placeholder="Harga minimum"
          />
        </Flex>
        <Box width="12px">
          <Divider />
        </Box>
        <Flex>
          <Input.Number
            size="small"
            prefix={
              <Text weight="bold" color="black50">
                Rp
              </Text>
            }
            placeholder="Harga maksimum"
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default FilterPrice;
