import { Box, Button, Flex, Icon, Spinner, Text, useToast } from "renos-ui";
import { StyledDialog } from "./styled";
import { useConfirmOrder } from "services/order-management";
import { useMemo } from "react";

const ModalMultipleApprove = ({
  isOpen,
  onClose,
  data,
  refetchStatus,
  refetchList,
}) => {
  const toast = useToast();

  const { mutate, isLoading } = useConfirmOrder({
    onSuccess: (data) => {
      const { message, frontend_message } = data;

      refetchStatus();
      refetchList();
      onClose();

      toast({
        label:
          frontend_message ||
          message ||
          `${totalOrder} Pesanan berhasil diterima`,
        placement: "top",
      });
    },
    onError: (data) => {
      const {
        response: {
          data: { frontend_message, message },
        },
      } = data;
      toast({
        label: frontend_message || message || "Pesanan gagal diterima",
        placement: "top",
        backgroundColor: "red50",
      });
    },
  });

  const handleConfirm = () => {
    const formData = new FormData();
    const tempParams = data.map((order) => order.order_id).join(",");

    formData.append("id", `[${tempParams}]`);

    mutate(formData);
  };

  const dataOrder = useMemo(
    () => data.filter((order) => order.order_status_id === 2),
    [data]
  );
  const totalOrder = useMemo(
    () => data.filter((order) => order.order_status_id === 2).length,
    [data]
  );

  return (
    <StyledDialog isVisible={isOpen} onClose={onClose} verticalCentered>
      <Flex width="448px" flexDirection="column" gap="24px">
        <Flex flexDirection="column" gap="8px" px="24px">
          <Flex justifyContent="space-between">
            <Text variant="heading3" weight="bold">
              Terima Pesanan Sekaligus
            </Text>
            <Icon
              name="Close-outline"
              cursor="pointer"
              size={24}
              color="black60"
              onClick={onClose}
            />
          </Flex>
          <Flex width="424px">
            <Text variant="subtitle" color="black75">
              Kamu akan menerima <b>{totalOrder}</b> pesanan sekaligus. Pastikan
              semua produk tersedia, ya.
            </Text>
          </Flex>
        </Flex>

        <Flex
          flexDirection="column"
          gap="8px"
          px="24px"
          maxHeight="calc(100vh - 300px)"
          overflowY="auto"
        >
          {dataOrder.map((order, indexOrder) => (
            <Flex
              key={`order-${indexOrder}`}
              padding="16px"
              borderRadius="8px"
              borderColor="black10"
              borderWidth="1px"
              borderStyle="solid"
              height="72px"
              gap="16px"
              alignItems="center"
            >
              <Flex
                width="64px"
                height="64px"
                borderRadius="8px"
                as="img"
                alt="product"
                src={order.products[0].product_image_url}
              />
              <Flex flexDirection="column">
                <Flex gap="8px" alignItems="center">
                  <Flex maxWidth="100px">
                    <Text lineClamp={1} variant="caption" color="black50">
                      {order.user_name}
                    </Text>
                  </Flex>
                  <Box
                    backgroundColor="black10"
                    width="4px"
                    height="4px"
                    borderRadius="50%"
                  />
                  <Text variant="caption" color="black50">
                    {order.formatted_order_date}
                  </Text>
                </Flex>
                <Text lineClamp={1} color="black75" weight="bold">
                  {order.products[0].product_name}
                  {order.products[0].variant_name &&
                    ` - ${order.products[0].variant_name}`}
                </Text>
                {order.products.length > 1 && (
                  <Text lineClamp={1} variant="caption">
                    + {order.products.length - 1} produk lainnya
                  </Text>
                )}
              </Flex>
            </Flex>
          ))}
        </Flex>

        <Flex p="16px 24px" boxShadow=" 0px 0px 8px rgba(20, 20, 32, 0.1)">
          <Button
            isBlock
            disabled={isLoading}
            preffix={isLoading && <Spinner color="white" size="8px" />}
            onClick={handleConfirm}
          >
            Terima Pesanan
          </Button>
        </Flex>
      </Flex>
    </StyledDialog>
  );
};

export default ModalMultipleApprove;
