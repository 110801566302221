import { useState, useEffect } from "react";
import { Button, Flex, Icon, Text, Box } from "renos-ui";
import { useGetTransactionSummary } from "services/statistic";
import Card from "./Card";
import dayjs from "dayjs";
import Chart from "./Chart";

const transactionType = [
  {
    name: "Pendapatan Bersih",
    summaryProperty: "net_income_short",
    key: "netIncome",
    tooltip: "Jumlah pendapatan bersih dari produkmu yang sudah terjual",
  },
  {
    name: "Produk Dilihat",
    summaryProperty: "product_views",
    key: "seen",
    tooltip:
      "Jumlah kunjungan ke halaman rincian produkmu dalam jangka waktu tertentu",
  },
  {
    name: "Produk Terjual",
    summaryProperty: "total_product",
    key: "soldOrder",
    tooltip: "Jumlah produk terjual dalam jangka waktu tertentu",
  },
  {
    name: "Tingkat Konversi",
    summaryProperty: "conversion_rate",
    key: "convertion",
    tooltip: "Jumlah presentase transaksi sukses dibagi jumlah produk dilihat",
  },
];

const TransactionSummary = ({ dataDate, setTempDate }) => {
  const convertPayload = (tempDataDate) => {
    const { startDate, endDate, type } = tempDataDate;
    const tempStartDate = dayjs(startDate).format("YYYY-MM-DD");
    const tempPayload = {
      date_type: type,
      start_date: tempStartDate,
      end_date: endDate ? dayjs(endDate).format("YYYY-MM-DD") : tempStartDate,
    };

    return { params: tempPayload };
  };

  const [payload, setPayload] = useState(convertPayload(dataDate));
  const [activeCard, setActiveCard] = useState("netIncome");
  const {
    data: dataTransactionSummary,
    isFetching: isTransactionSummaryFetching,
    isLoading: isTransactionSummaryLoading,
    isError: isTransactionSummaryError,
    refetch: refetchTransactionSummary,
  } = useGetTransactionSummary(payload);

  useEffect(() => {
    setPayload(convertPayload(dataDate));
    setTempDate(convertPayload(dataDate));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataDate]);

  return (
    <Flex gap="16px" flexDirection="column">
      <Flex justifyContent="space-between">
        <Flex flexDirection="column">
          <Text variant="heading3" weight="bold">
            Ringkasan Transaksi
          </Text>
          <Text color="black75">
            Pantau rutin perkembangan toko untuk atur strategi penjualan.
          </Text>
        </Flex>
        {isTransactionSummaryError && (
          <Box>
            <Button
              onClick={refetchTransactionSummary}
              preffix={<Icon name="Reload-outline" size={20} />}
            >
              Muat Ulang
            </Button>
          </Box>
        )}
      </Flex>

      <Flex gap="16px">
        {transactionType.map((transaction, indexTransaction) => (
          <Card
            isLoading={
              isTransactionSummaryLoading || isTransactionSummaryFetching
            }
            refetch={refetchTransactionSummary}
            dataTransaction={transaction}
            key={`transaction-card-${indexTransaction}`}
            dataTransactionSummary={dataTransactionSummary?.data}
            isActive={activeCard === transaction.key}
            setActiveCard={setActiveCard}
          />
        ))}
      </Flex>
      <Chart
        payload={payload}
        activeChart={activeCard}
        dateType={dataDate?.type}
      />
    </Flex>
  );
};

export default TransactionSummary;
