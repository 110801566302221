import React from "react";
import { Flex, Text, Icon, Box } from "renos-ui";

const Warning = (props) => {
  const { formValue, goToUrl } = props;

  return (
    <Flex
      padding="14px 16px"
      alignItems="center"
      backgroundColor="yellow5"
      borderRadius="8px"
      columnGap="8px"
      width="100%"
      height="64px"
      gap="12px"
      style={{ boxSizing: "border-box" }}
    >
      <Icon name="Info-solid" color="yellow50" size={20} />
      <Text variant="caption" color="yellow100">
        {formValue ? (
          <>
            Untuk mengubah nama tokomu lagi, harap hubungi kami di{" "}
            <Box
              color="yellow100"
              as="a"
              onClick={goToUrl}
              cursor="pointer"
              display="inline"
            >
              <Text weight="bold" variant="caption">
                sellersupport@renos.id
              </Text>
            </Box>
          </>
        ) : (
          "Nama Toko hanya dapat diubah 1 kali. Pastikan nama yang dimasukkan sudah benar."
        )}
      </Text>
    </Flex>
  );
};

export default Warning;
