import { useState, useMemo, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { isEmpty, useDisclosure } from "renos-ui";
import { useDeliveryContext } from "store/seller-fleet/reducer";
import {
  useGetCity,
  useGetDistrict,
  useGetProvince,
} from "services/logistics/area";
import { useGetSellerFleetById } from "services/logistics/sellerFleet";
import useQueryParams from "hooks/useQueryParams";
import constants from "store/seller-fleet/constants";
import useValidation from "../../hooks/useValidation";
import { conditionBaseType, conditionType } from "../../utlis";

const useDeliveryRegion = () => {
  const history = useHistory();
  const { state, dispatch } = useDeliveryContext();
  const { validation, setValidation } = useValidation();
  const query = useQueryParams();
  const selectedId = query.get("id");
  const { dataCoverage, isBack } = state;
  const [selectedIndex, setSelectedIndex] = useState(0);
  const provinceDisclosure = useDisclosure({ isOpen: false });
  const cityDisclosure = useDisclosure({ isOpen: false });
  const districtDisclosure = useDisclosure({ isOpen: false });

  const { mutate: getSellerFleetById, isLoading: isGetSellerFleetByIdLoading } =
    useGetSellerFleetById({
      onSuccess: ({ data }) => {

        const categoryFilled = data.categories.some(
          (categoryLvl0) =>
            categoryLvl0.active ||
            categoryLvl0.categories.some(
              (categoryLvl1) =>
                categoryLvl1.active ||
                categoryLvl1.categories.some(
                  (categoryLvl2) => categoryLvl2.active
                )
            )
        );

        const tempDataRates = {
          categories: data.categories,
          active: categoryFilled,
          calculation: {
            type:
              data.advanced_options.length !== 0
                ? "advanced_options"
                : data.weight_groups.length === 0
                ? "multiple"
                : "group",
            multiple:
              data.weight_groups.length === 0 &&
              data.advanced_options.length === 0
                ? {
                    weight: data.weight_multiple.base_weight || "0",
                    price: data.weight_multiple.base_price || "0",
                  }
                : {},
            group:
              data.weight_groups.length !== 0 &&
              data.advanced_options.length === 0
                ? data.weight_groups.map((weightGroup) => ({
                    min: weightGroup.minimum_weight || 1,
                    max: weightGroup.maximum_weight || 0,
                    price: weightGroup.shipping_cost || "0",
                  }))
                : [{ min: 1 }],
            advanced_options: data.advanced_options?.map((advanced) => {
              const condition = conditionType.find(
                (condition) => condition.label === advanced?.condition
              );
              const conditionBase = conditionBaseType.find(
                (conditionbase) =>
                  conditionbase.label === advanced?.condition_base
              );

              return {
                ...advanced,
                condition_type_id:
                  advanced?.condition_type_id || condition?.value,
                condition_base_id:
                  advanced?.condition_base_id || conditionBase?.value,
              };
            }),
          },
        };

        if (tempDataRates.calculation.advanced_options.length === 0) {
          tempDataRates.calculation.advanced_options = [
            {
              condition_type_id: 1,
              condition: "Kurang Dari",
              condition_base_id: 1,
              condition_weight: false,
              condition_base_weight_value: false,
              condition_base_tarif: false,
              condition_base_weight_start: false,
              condition_base_weight_end: false,
            },
          ];
        }

        const tempDataCoverage = data.coverages.map((coverage) => {
          const tempSelectedCity = [];
          const tempSelectedDistrict = [];
          coverage.cities.map((city) => {
            tempSelectedCity.push({
              province_id: coverage.province_id,
              province_name: coverage.province_name,
              city_id: city.city_id,
              city_name: city.city_name,
              check: true,
            });
            city.districts.map((district) => {
              tempSelectedDistrict.push({
                district_name: district.district_name,
                district_id: district.district_id,
                province_id: coverage.province_id,
                province_name: coverage.province_name,
                city_id: city.city_id,
                city_name: city.city_name,
                check: true,
              });

              return district;
            });

            return city;
          });

          return {
            selectedProvince: {
              province_id: coverage.province_id,
              province_name: coverage.province_name,
            },
            selectedCity: tempSelectedCity,
            listCity: [],
            selectedDistrict: tempSelectedDistrict,
            listDistrict: [],
            estimateDays: `${coverage.estimate_days_start}-${coverage.estimate_days_end}`,
          };
        });

        const tempValidationCoverage = new Array(tempDataCoverage.length).fill({
          selectedProvince: false,
          estimateDays: false,
          estimateDaysText: "",
        });

        const tempValidationRates = {
          multiple: {
            weight: false,
            price: false,
            weightText: "Kelipatan berat harus diisi.",
          },
          group: new Array(tempDataRates.calculation.group.length).fill({
            max: false,
            price: false,
          }),
          advancedOptions: new Array(
            tempDataRates.calculation.advanced_options.length
          ).fill({
            max: false,
            price: false,
          }),
        };

        
        setValidation({
          dataCoverage: tempValidationCoverage,
          dataRates: tempValidationRates,
        });

        dispatch({
          type: constants.SET_RESPONSE_EDIT,
          payload: {
            dataCoverage: [...tempDataCoverage],
            dataRates: tempDataRates,
            selectedId,
          },
        });
      },
      onError: () => {
        history.push("/account/set-delivery-region");
      },
    });

  const { data: dataProvince, isLoading: isLoadingProvince } = useGetProvince();

  const { mutate: mutateCity, isLoading: isLoadingCity } = useGetCity({
    onSuccess: ({ data, indexCoverage }) => {
      const tempCoverage = dataCoverage;
      dataCoverage[indexCoverage] = {
        ...dataCoverage[indexCoverage],
        listCity: data,
      };

      dispatch({
        type: constants.GET_CITY_BY_INDEX,
        payload: {
          indexCoverage,
          dataCoverage: tempCoverage,
        },
      });
    },
  });
  const { mutate: mutateDistrict, isLoading: isLoadingDistrict } =
    useGetDistrict({
      onSuccess: ({ data, indexCoverage }) => {
        const tempCoverage = dataCoverage;
        dataCoverage[indexCoverage] = {
          ...dataCoverage[indexCoverage],
          listDistrict: data,
        };

        dispatch({
          type: constants.GET_DISTRICT_BY_INDEX,
          payload: {
            indexCoverage,
            dataCoverage: tempCoverage,
          },
        });
      },
    });

  const handleShowDisclosure = (name, indexCoverage) => {
    switch (name) {
      case "province":
        dispatch({
          type: constants.GET_PROVINCE,
          payload: {
            indexCoverage,
            dataProvince: dataProvince?.map((row) => row.value),
          },
        });
        setSelectedIndex(indexCoverage);
        provinceDisclosure.onOpen();
        break;
      case "city":
        setSelectedIndex(indexCoverage);
        cityDisclosure.onOpen();
        break;
      case "district":
        setSelectedIndex(indexCoverage);
        districtDisclosure.onOpen();
        break;
      default:
        break;
    }
  };

  const handleChangeEstimate = (event, indexCoverage) => {
    const value = event.target.value;

    let val = value //eslint-disable-next-line
      .replace(/[A-Za-z!@#$%^&*()_+=\[\]{};':"\\|,.<>\/?]/gm, "")
      .trim();

    // eslint-disable-next-line prefer-const
    let tempVal = val.split("");

    // eslint-disable-next-line
    const dashCount = (val.match(/\-/gm) || []).length;

    if (dashCount > 1) {
      // eslint-disable-next-line
      val = val.replace(/\-$/gm, "");
    }

    const [firstVal, secondVal] = val.split("-");
    const singleNumber = val.split("-").length;

    const arrVal = val.split("");
    const hasTilde = arrVal.includes("~");
    const hasBacktick = arrVal.includes("`");

    let isFormatError = false;

    if (Number(firstVal) === 0) {
      isFormatError = true;
    }

    if (singleNumber <= 1) {
      isFormatError = true;
    }

    if ((val && val.length === 0) || Number(secondVal) - Number(firstVal) < 1) {
      isFormatError = true;
    }

    if (hasTilde || hasBacktick) {
      isFormatError = true;
    }

    if (tempVal[0] === "-") {
      isFormatError = true;
    }

    const tempCoverage = dataCoverage;
    tempCoverage[indexCoverage].estimateDays = val;

    const tempValidation = validation.dataCoverage;
    tempValidation[indexCoverage].estimateDays = isFormatError;
    tempValidation[indexCoverage].estimateDaysText = "";

    if (isFormatError) {
      tempValidation[indexCoverage].estimateDaysText =
        "Estimasi hari pengiriman yang anda masukkan salah.";
    }

    setValidation({
      ...validation,
      dataCoverage: [...tempValidation],
    });

    dispatch({
      type: constants.ADD_ESTIMATE_BY_INDEX,
      payload: {
        dataCoverage: [...tempCoverage],
      },
    });
  };

  const handleAddArea = () => {
    const tempCoverage = dataCoverage;
    const tempValidation = validation;

    const newCoverageItem = {
      selectedProvince: {},
      selectedCity: [],
      listCity: [],
      selectedDistrict: [],
      estimateDays: "",
      listDistrict: [],
      listProvince: dataProvince,
    };
    tempCoverage.push(newCoverageItem);

    tempValidation.dataCoverage.push({
      selectedProvince: false,
      estimateDays: false,
      estimateDaysText: "",
    });

    dispatch({
      type: constants.ADD_COVERAGE,
      payload: {
        dataCoverage: [...tempCoverage],
      },
    });
  };

  const handleDeleteCoverage = (index) => {
    const tempValidation = { ...validation };

    const tempDataCoverage = [...dataCoverage];
    tempDataCoverage.splice(index, 1);

    setValidation(tempValidation);

    const updatedDataCoverage = [...dataCoverage];
    updatedDataCoverage.splice(index, 1);

    dispatch({
      type: constants.DELETE_COVERAGE,
      payload: {
        dataCoverage: updatedDataCoverage,
      },
    });
  };

  const submitProvince = (tempData) => {
    mutateCity({
      ...tempData[selectedIndex].selectedProvince,
      indexCoverage: selectedIndex,
    });
  };

  const submitCity = (tempData) => {
    mutateDistrict({ city_id: tempData, indexCoverage: selectedIndex });
  };

  const isAllowAddArea = useMemo(
    () =>
      dataCoverage?.filter(
        (filterCoverage) =>
          !isEmpty(filterCoverage.selectedProvince) ||
          !isEmpty(filterCoverage.estimateDays)
      ).length === dataCoverage.length,
    [dataCoverage]
  );

  const isAllowRemoveArea = useMemo(
    () => dataCoverage || dataCoverage.length > 1,
    [dataCoverage]
  );

  const isLoadingAll = useMemo(
    () =>
      isGetSellerFleetByIdLoading &&
      isLoadingProvince &&
      isLoadingCity &&
      isLoadingDistrict,

    [
      isGetSellerFleetByIdLoading,
      isLoadingProvince,
      isLoadingCity,
      isLoadingDistrict,
    ]
  );

  useEffect(() => {
    if (selectedId !== null && !isBack) {
      getSellerFleetById({
        courier_service_setting_id: selectedId,
      });
    }
    //eslint-disable-next-line
  }, [selectedId, isBack]);

  return {
    history,
    validation,
    dataCoverage,
    selectedIndex,
    provinceDisclosure,
    cityDisclosure,
    districtDisclosure,
    isAllowRemoveArea,
    handleShowDisclosure,
    handleChangeEstimate,
    handleAddArea,
    handleDeleteCoverage,
    submitProvince,
    submitCity,
    isAllowAddArea,
    dispatch,
    isLoadingAll,
    mutateCity,
    mutateDistrict,
    isLoadingCity,
    isLoadingDistrict,
    isLoadingProvince,
    isGetSellerFleetByIdLoading,
  };
};

export default useDeliveryRegion;
