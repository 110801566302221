import { Flex, Icon, Text, useDisclosure } from "renos-ui";
import ModalPrint from "./ModalPrint";

const PrintLabel = ({ data }) => {
  const disclosurePrint = useDisclosure({ isOpen: false });

  return (
    <Flex>
      <Flex
        gap="8px"
        alignItems="center"
        cursor="pointer"
        onClick={disclosurePrint.onToggle}
      >
        <Icon name="Print-outline" color="black75" size={16} />
        <Text color="black75" weight="bold">
          Cetak Label
        </Text>
      </Flex>
      {disclosurePrint.isOpen && (
        <ModalPrint
          isOpen={disclosurePrint.isOpen}
          onClose={disclosurePrint.onClose}
          data={data}
        />
      )}
    </Flex>
  );
};

export default PrintLabel;
