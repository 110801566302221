import React, { useContext, useEffect, useMemo, useState } from "react";
import {
  Dialog,
  Flex,
  Text,
  Box,
  Divider,
  Input,
  Label,
  useDebounce,
  Button,
  Skeleton,
  Spinner,
} from "renos-ui";
import { useGetProductsV3 } from "services/product";
import Table from "widgets/desktop/Table";
import { FilterProduct } from "./Filter";
import { StyledProductImage } from "../styled";
import InfiniteScroll from "widgets/global/InfiniteScroll";
import { ModalRequestBiddingContext } from "..";
import { clearEmpty } from "helpers/clear-empty";

export const ProductList = ({ isVisible, onToggle, index }) => {
  const [data, setData] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState({});
  const { productListOffer, setProductListOffer } = useContext(
    ModalRequestBiddingContext
  );
  const [totalProduct, setTotalProduct] = useState(0);
  const [filterParams, setFilterParams] = useState({
    keyword: "",
    ordering: "asc",
    order_by: "",
    product_status_ids: 1,
    page: 1,
    limit: 10,
    product_type: "",
    product_stock: 0,
  });
  const [searchValue, setSearchValue] = useState("");

  const {
    data: dataProductList,
    isLoading,
    isRefetching,
    isFetching,
    remove,
  } = useGetProductsV3(
    { ...clearEmpty(filterParams) },
    {
      onSuccess: (dataProduct) => {
        const items = dataProduct?.data;

        if (filterParams.page === 1) {
          setData(items);
          setTotalProduct(dataProduct.pagination.total);
        } else {
          setData((prev) => [...prev, ...items]);
        }
      },
    }
  );

  const tableColumns = [
    {
      key: "radioButton",
      dataIndex: "radioButton",
      title: "",
      render: (_, data) => {
        return (
          <Input.Radio
            size="small"
            checked={selectedProduct?.product_id === data?.product_id}
            onClick={() => setSelectedProduct(data)}
            onChange={() => setSelectedProduct(data)}
          />
        );
      },
      width: "50px",
    },
    {
      key: "product_id",
      dataIndex: "product_id",
      title: (
        <Text weight="bold" variant="subtitle">
          Produk (
          {dataProductList ? dataProductList.pagination?.total : totalProduct})
        </Text>
      ),
      render: (_, data, isVariant) => {
        const {
          product_image_url,
          product_id,
          product_name,
          store_sku_number,
          formatted_variant_value_name,
          variant_value_id,
          product_skus,
        } = data;

        return (
          <Flex alignItems="center" columnGap="8px">
            <StyledProductImage
              as="img"
              src={product_image_url}
              alt={`product-img-${product_id}`}
              width="64px"
              height="64px"
              borderRadius="8px"
            />
            <Flex flexDirection="column" rowGap="4px" width="232px">
              <Text weight="bold" lineClamp={1}>
                {isVariant ? formatted_variant_value_name : product_name}
              </Text>
              <Text variant="caption" color="black50">
                SKU:{" "}
                {!store_sku_number
                  ? product_skus[0].store_sku_number
                  : store_sku_number}
              </Text>
              {variant_value_id?.length > 1 && (
                <Label>{`+${variant_value_id.length} varian`}</Label>
              )}
            </Flex>
          </Flex>
        );
      },
      width: "400px",
    },

    {
      key: "product_price_end",
      dataIndex: "product_price_end",
      title: (
        <Text weight="bold" variant="subtitle">
          Harga
        </Text>
      ),
      render: (_, data) => {
        const { product_price } = data;

        return <Text>{product_price}</Text>;
      },
      width: "200px",
    },
    {
      key: "product_stock",
      dataIndex: "product_stock",
      title: (
        <Text weight="bold" variant="subtitle">
          Stok
        </Text>
      ),
      render: (_, data) => <Text>{data.product_stock}</Text>,
      width: "200px",
    },
  ];

  useDebounce(
    () =>
      setFilterParams({
        ...filterParams,
        keyword: searchValue,
        page: 1,
      }),
    [searchValue],
    1000
  );

  const isNotReady = useMemo(
    () => (isLoading && isFetching) || isRefetching,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dataProductList]
  );

  const hasNextPage = useMemo(
    () =>
      dataProductList?.pagination?.current_page <
      dataProductList?.pagination?.last_page,
    [dataProductList]
  );

  const handleOnClick = () => {
    setProductListOffer(() => {
      const productListData = productListOffer.map((item, indexProductList) => {
        if (indexProductList === index) {
          item[`product`] = selectedProduct;
        }

        return item;
      });

      return productListData;
    });

    remove();
    onToggle();
  };

  useEffect(() => {
    setSelectedProduct(productListOffer?.[index]?.product);

    return () => remove();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dialog
      height="80vh"
      width="720px"
      closable
      isVisible={isVisible}
      onClose={onToggle}
    >
      <Flex gap="24px" flexDirection="column" width="100%">
        <Text weight="bold" variant="heading3">
          Tambah Produk
        </Text>
        <Box marginX="-40px">
          <Divider />
        </Box>

        <FilterProduct
          setData={setData}
          setFilterParams={setFilterParams}
          setSearchValue={setSearchValue}
        />

        <Flex
          overflowY="auto"
          width="100%"
          height="100%"
          flexDirection="column"
          justifyContent={isNotReady ? "center" : "inherit"}
          alignItems={isNotReady ? "center" : "inherit"}
        >
          {isNotReady && data.length === 0 ? (
            <Spinner />
          ) : (
            <InfiniteScroll
              page={filterParams.page}
              isLoading={isRefetching || isLoading}
              loader={<Skeleton itemShow={2} />}
              hasNextPage={hasNextPage}
              onChange={() => {
                setFilterParams((prev) => ({
                  ...prev,
                  page: prev.page + 1,
                }));
              }}
            >
              <Table columns={tableColumns} dataSource={data} />
            </InfiniteScroll>
          )}
        </Flex>
        <Box marginX="-40px" marginTop="-24px">
          <Divider />
        </Box>
        <Flex justifyContent="flex-end">
          <Box width="180px">
            <Button
              disabled={Boolean(!selectedProduct?.product_id)}
              onClick={handleOnClick}
              size="medium"
              isBlock
            >
              Simpan
            </Button>
          </Box>
        </Flex>
      </Flex>
    </Dialog>
  );
};
