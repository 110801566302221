import { Flex, Text } from "renos-ui";
import Web from "./Web";
import Mobile from "./Mobile";
import { useHistory } from "react-router-dom";
import desktopUrl from "constants/desktopUrl";

const Preview = ({
  activeTab,
  setActiveTab,
  isDesignActive,
  dataHomePageStatus,
}) => {
  const { push } = useHistory();

  return (
    <Flex
      width="368px"
      height="461px"
      borderRadius="16px"
      flexDirection="column"
      gap="16px"
    >
      <Flex
        padding="22px 24px"
        boxShadow="0px 4px 4px rgba(20, 20, 32, 0.05)"
        backgroundColor="white"
        borderTopLeftRadius="16px"
        borderTopRightRadius="16px"
        justifyContent="space-between"
        width="calc(778px - 48px)"
      >
        {dataHomePageStatus?.data?.template && (
          <Text variant="subtitle" weight="bold">
            {dataHomePageStatus?.data?.template?.name}
          </Text>
        )}
        <Flex
          cursor="pointer"
          onClick={() => push(desktopUrl.STORE_LAYOUT_TEMPLATE)}
        >
          <Text variant="subtitle" weight="bold" color="blue50">
            Ubah Template
          </Text>
        </Flex>
      </Flex>

      {activeTab === "web" ? (
        <Web {...{ activeTab, setActiveTab, isDesignActive }} />
      ) : (
        <Mobile {...{ activeTab, setActiveTab, isDesignActive }} />
      )}
    </Flex>
  );
};

export default Preview;
