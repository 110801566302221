import MobileLayout from "layouts/MobileLayout";
import React, { useEffect } from "react";
import { Box, Button, Flex, Text } from "renos-ui";
import { SwiperSlide } from "swiper/react";
import { StyledSwiper } from "./styled";
import { onboardItems } from "./constants";
import { useState } from "react";
import SplashScreen from "../splash-screen";

const OnboardPage = () => {
  const [isSplashDone, setIsSplashDone] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsSplashDone(true);
    }, 2000);
  }, []);

  const onLoginClicked = () =>
    window.open(
      `${process.env.REACT_APP_BUYER_SITE}/login?referrer=${window.location.origin}`,
      "_self"
    );

  const onJoinClicked = () => {
    return window.open(
      `${process.env.REACT_APP_BUYER_SITE}/register?referrer=${window.location.origin}`,
      "_self"
    );
  };

  return isSplashDone ? (
    <MobileLayout>
      <Flex
        width="100%"
        height="100%"
        boxSizing="border-box"
        p="32px"
        pt="72px"
        flexDirection="column"
        justifyContent="space-between"
      >
        <StyledSwiper pagination={true} slidesPerView={1} autoplay>
          {onboardItems.map((item, index) => (
            <SwiperSlide key={`onboard-${index}`}>
              <Flex flexDirection="column" alignItems="center" rowGap="40px">
                <Box
                  as="img"
                  src={`/assets/images/onboard/onboard${index + 1}.svg`}
                />
                <Flex
                  flexDirection="column"
                  rowGap="8px"
                  alignItems="center"
                  textAlign="center"
                >
                  <Text variant="subtitle" weight="bold">
                    {item.title}
                  </Text>
                  <Flex px="16px">
                    <Text color="black75" width="280px">
                      {item.subTitle}
                    </Text>
                  </Flex>
                </Flex>
              </Flex>
            </SwiperSlide>
          ))}
        </StyledSwiper>
        <Flex flexDirection="column" rowGap="8px">
          <Button isBlock onClick={onJoinClicked}>
            Daftar
          </Button>
          <Button isBlock variant="tertiary" onClick={onLoginClicked}>
            Masuk
          </Button>
        </Flex>
      </Flex>
    </MobileLayout>
  ) : (
    <SplashScreen />
  );
};

export default OnboardPage;
