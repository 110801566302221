import { useState } from "react";
import { Flex, Tabs } from "renos-ui";
import TopBar from "./TopBar";
import useQueryParams from "hooks/useQueryParams";
import { useHistory } from "react-router-dom";
import Rating from "./Rating";
import Review from "./Review";

const items = [
  {
    label: "Rating Produk",
    key: "rating",
    children: () => <Rating />,
  },
  {
    label: "Ulasan Produk",
    key: "ulasan",
    children: () => <Review />,
  },
];

const RatingReview = () => {
  const query = useQueryParams();
  const { push } = useHistory();
  const [activeTab, setActiveTab] = useState(
    query.get("tab-active") || "rating"
  );

  const handleChangeTabActive = (value) => {
    setActiveTab(value);
    push(`/sales/rating-review?tab-active=${value}`);
  };

  return (
    <Flex flexDirection="column" rowGap="8px" marginTop="20px">
      <TopBar />
      <Flex
        borderBottomLeftRadius="16px"
        borderBottomRightRadius="16px"
        boxShadow="0px 4px 4px rgba(20, 20, 32, 0.05)"
        backgroundColor="white"
        padding="24px"
        flexDirection="column"
        rowGap="24px"
        zIndex={0}
      >
        <Tabs
          onChange={handleChangeTabActive}
          defaultActiveKey={activeTab}
          placement="bottom"
          items={items}
        />
      </Flex>
    </Flex>
  );
};

export default RatingReview;
