import { Button, Dropdown, Flex, Icon } from "renos-ui";

const VerticalElipsButton = ({
  isPrimary,
  handleEdit,
  handleDelete,
  handleSetPrimary,
  length,
}) => {
  return (
    <Dropdown>
      <Dropdown.Toggle>
        <Button
          variant="tertiary"
          size="small"
          icon={
            <Flex style={{ pointerEvents: "none" }}>
              <Icon size={20} name="Vertical-outline" />
            </Flex>
          }
        />
      </Dropdown.Toggle>
      <Dropdown.Content padding="20px">
        <Dropdown.Item onClick={handleEdit}>Edit Alamat</Dropdown.Item>
        {!isPrimary && (
          <Dropdown.Item onClick={handleSetPrimary}>
            Jadikan Utama
          </Dropdown.Item>
        )}
        {!isPrimary && length > 1 && (
          <Dropdown.Item onClick={handleDelete}>Hapus Alamat</Dropdown.Item>
        )}
      </Dropdown.Content>
    </Dropdown>
  );
};

export default VerticalElipsButton;