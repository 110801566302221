import { Flex, Text } from "renos-ui";
import OpenStore from "./open-store";
import CloseStore from "./close-store";

const Status = () => {
  return (
    <Flex
      flexDirection="column"
      gap="24px"
      padding="24px"
      justifyContent="center"
    >
      <Flex flexDirection="column" gap="4px">
        <Text variant="subtitle" weight="bold">
          Status Toko
        </Text>
        <Text color="black75">
          Atur pengaturan status tokomu agar lebih efisien dalam memproses
          pesanan dan memberikan pelayanan.
        </Text>
      </Flex>

      <OpenStore />

      <CloseStore />
    </Flex>
  );
};

export default Status;
