import dayjs from "dayjs";
import StoreContext from "providers/StoreProvider";
import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { useContext } from "react";
import { Box, range, Flex, Skeleton, Text, Icon } from "renos-ui";
import {
  useGetNotification,
  useUpdateNotification,
} from "services/notification";
import { NotificationItem, StyledDisclosure, Wrapper } from "./styled";
import { useQueryClient } from "react-query";

const NotificationUpdate = () => {
  const {
    storeData: {
      data: { id: storeId },
    },
  } = useContext(StoreContext);
  const queryClient = useQueryClient();

  const { data, isLoading, fetchNextPage } = useGetNotification({
    items_per_page: 10,
    store_id: storeId,
    notification_type_id: "1,2",
  });

  const observerElem = useRef(null);

  const hasNextPage = useMemo(() => {
    let count = 0;

    if (data?.pages) {
      data.pages.forEach((page) => {
        count += page.data.length;
      });
    }

    return count < data?.pages[0].total;
  }, [data]);

  const handleObserver = useCallback(
    (entries) => {
      const [target] = entries;

      if (target.isIntersecting) {
        fetchNextPage();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fetchNextPage, hasNextPage]
  );
  const isNotifEmpty = useMemo(
    () => !isLoading && data.pages.map((page) => page.data)[0].length === 0,
    [data, isLoading]
  );

  useEffect(() => {
    const element = observerElem.current;

    if (element) {
      const option = { threshold: 0 };

      const observer = new IntersectionObserver(handleObserver, option);
      observer.observe(element);

      return () => observer.unobserve(element);
    }
  }, [fetchNextPage, hasNextPage, handleObserver, observerElem]);

  const { mutate: updateNotification } = useUpdateNotification({});

  const onNotificationClicked = (notificationId) => {
    const payload = {
      store_id: storeId,
      notification_id: notificationId,
    };
    updateNotification(payload);
    queryClient.invalidateQueries({
      queryKey: ["getNotification"],
    });
    queryClient.invalidateQueries({
      queryKey: ["notifCount"],
    });
  };

  if (isNotifEmpty) {
    return (
      <Flex
        justifyContent="center"
        alignItems="center"
        height="calc(100vh - 270px)"
      >
        <Flex flexDirection="column" rowGap="40px" alignItems="center">
          <img
            src="/assets/images/store-layout/no-product.svg"
            alt="no-notification-img"
            width="200px"
            height="200px"
          />
          <Flex
            flexDirection="column"
            textAlign="center"
            rowGap="8px"
            width="240px"
          >
            <Text variant="heading3" weight="bold">
              Belum Ada Notifikasi
            </Text>
            <Text>Terus pantau update terbaru untuk tokomu di sini.</Text>
          </Flex>
        </Flex>
      </Flex>
    );
  }

  return (
    <Flex
      flexDirection="column"
      rowGap="8px"
      width="100%"
      mt="16px"
      maxHeight="calc(100vh - 270px)"
      overflow="auto"
    >
      {isLoading
        ? range(4).map((_, i) => (
            <Skeleton key={`skeleton-${i}`} height="104px" />
          ))
        : data.pages.map((page) =>
            page.data.map((notificationData) => {
              const {
                notification_id: id,
                title,
                message,
                date_in: date,
                is_read: isRead,
              } = notificationData;

              return (
                <NotificationItem
                  key={id}
                  flexDirection="column"
                  rowGap="4px"
                  padding="16px"
                  borderRadius="16px"
                  backgroundColor={!isRead && "blue5"}
                  cursor="pointer"
                  onClick={() => onNotificationClicked(id)}
                >
                  <Text variant="caption" color="black50">
                    {dayjs(date).format("DD MMM YYYY")}
                  </Text>

                  <NotificationItem flexDirection="column" gap="4px">
                    <StyledDisclosure defaultOpen={false}>
                      {({ isOpen, onToggle }) => (
                        <Flex flexDirection="column">
                          {!isOpen && (
                            <Flex flexDirection="column" gap="4px">
                              <Text weight="bold">{title}</Text>
                              <Text color="black75" lineClamp={2}>
                                {message}
                              </Text>
                            </Flex>
                          )}

                          {!isOpen && message.length > 200 && (
                            <Flex
                              width="100%"
                              justifyContent="flex-end"
                              alignItems="center"
                              gap="4px"
                              onClick={onToggle}
                              cursor="pointer"
                            >
                              <Text
                                variant="caption"
                                color="blue50"
                                weight="bold"
                              >
                                Selengkapnya
                              </Text>
                              <Icon
                                name={isOpen ? "Up-outline" : "Down-outline"}
                                size={16}
                                color="blue50"
                              />
                            </Flex>
                          )}

                          {isOpen && (
                            <Flex flexDirection="column" gap="4px">
                              <Text weight="bold">{title}</Text>
                              <Wrapper>
                                <Text color="black75">{message}</Text>
                              </Wrapper>
                            </Flex>
                          )}

                          {isOpen && (
                            <Flex
                              width="100%"
                              justifyContent="flex-end"
                              alignItems="center"
                              gap="4px"
                              onClick={onToggle}
                              cursor="pointer"
                            >
                              <Text
                                variant="caption"
                                color="blue50"
                                weight="bold"
                              >
                                Tutup
                              </Text>
                              <Icon
                                name={isOpen ? "Up-outline" : "Down-outline"}
                                size={16}
                                color="blue50"
                              />
                            </Flex>
                          )}
                        </Flex>
                      )}
                    </StyledDisclosure>
                  </NotificationItem>
                </NotificationItem>
              );
            })
          )}

      {isLoading && (
        <Box ref={observerElem} width="100%">
          <Skeleton height="104px" />
        </Box>
      )}
    </Flex>
  );
};

export default NotificationUpdate;
