import { useDisclosure } from "renos-ui";
import AddModalPromo from "./AddModalPromo";
import List from "./list";

const PromoManagement = () => {
  const addDisclosure = useDisclosure({ isOpen: false });

  const handleAdd = () => {
    addDisclosure.onOpen();
  };

  return (
    <>
      <List handleAdd={handleAdd} />
      {addDisclosure.isOpen && <AddModalPromo addDisclosure={addDisclosure} />}
    </>
  );
};

export default PromoManagement;
