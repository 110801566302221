import React, { useMemo } from "react";
import { Flex, Icon, Text, isEmpty } from "renos-ui";
import { StyledDisclosure } from "./styled";
import { useHistory } from "react-router-dom";
import { useProductAddContext } from "store/productAdd/reducer";

const Accordion = ({
  selectedCategory,
  setSelectedCategory,
  onBack,
  ...item
}) => {
  const {
    options,
    category_name: categoryName,
    category_slug: slug,
    parent_id: parentId,
    category_id: id,
  } = item;

  const {
    state: { tempProductCategories, isEdit },
  } = useProductAddContext();

  const { goBack } = useHistory();

  const level = slug.split("/").length - 2;

  const onCategorySelected = (onToggle, isFinal) => {
    const tempSelected = [...selectedCategory];

    if (isFinal) {
      tempSelected[0] = item;
    } else {
      if (parentId) {
        tempSelected[1] = item;
      } else {
        tempSelected[2] = item;
      }
    }

    if (isFinal) {
      onBack(tempSelected);
      goBack();

      return;
    }

    setSelectedCategory(tempSelected);
    onToggle?.();
  };

  const isOpen = !isEmpty(
    tempProductCategories.find((data) => data.category_id === id)
  );

  const isDisabled = useMemo(() => {
    return parentId === null && !isOpen && isEdit;
  }, [isOpen, parentId, isEdit]);

  return options ? (
    <StyledDisclosure defaultOpen={isOpen}>
      {({ isOpen, onToggle }) => (
        <Flex flexDirection="column" width="100%">
          <Flex
            justifyContent="space-between"
            width="100%"
            boxSizing="border-box"
            onClick={() => (isDisabled ? null : onCategorySelected(onToggle))}
            py="12px"
            pl={`${16 + level * 16}px`}
            pr="16px"
          >
            <Text weight={isOpen && "bold"} color={isDisabled && "black50"}>
              {categoryName}
            </Text>
            <Icon
              name={isOpen ? "Up-outline" : "Down-outline"}
              size={20}
              color="black50"
            />
          </Flex>
          {isOpen && (
            <Flex height="8px" width="100%" backgroundColor="black5" />
          )}
          {isOpen &&
            options.map((item) => (
              <Accordion
                {...item}
                setSelectedCategory={setSelectedCategory}
                selectedCategory={selectedCategory}
                onBack={onBack}
              />
            ))}
        </Flex>
      )}
    </StyledDisclosure>
  ) : (
    <Flex
      justifyContent="space-between"
      width="100%"
      boxSizing="border-box"
      py="12px"
      pl={`${16 + level * 16}px`}
      pr="16px"
      onClick={() => onCategorySelected(null, true)}
      backgroundColor={
        id === selectedCategory[0]?.category_id || (isOpen && "black5")
      }
    >
      <Text>{categoryName}</Text>
    </Flex>
  );
};

export default Accordion;
