import { Flex, Text } from "renos-ui";

const EmptyData = () => {
  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      gap="8px"
      flexDirection="column"
      textAlign="center"
      width="100%"
      marginBottom="24px"
    >
      <Flex
        as="img"
        width="200px"
        height="160px"
        src="/assets/images/promo-management/empty-stock.svg"
        alt="empty-stock"
      />
      <Text variant="heading3" weight="bold">
        Tidak ada etalase
      </Text>
      <Text>Tidak ada etalase yang sesuai dengan keyword yang dimasukkan.</Text>
    </Flex>
  );
};

export default EmptyData;
