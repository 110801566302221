import { Divider, Drawer, Flex, Text, useDisclosure } from "renos-ui";
import Price from "./component/Price";
import { useMemo } from "react";
import StatusStock from "./component/StatusStock";
import { useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import mobileUrl from "constants/mobileUrl";

const PopupMore = ({
  disclosure: { isOpen, onClose },
  data,
  handleInlineEdit,
}) => {
  const { push } = useHistory();
  const queryClient = useQueryClient();
  const disclosurePrice = useDisclosure({ isOpen: false });
  const disclosureStatusStock = useDisclosure({ isOpen: false });

  const isChildDisclosureActive = useMemo(
    () => disclosurePrice.isOpen || disclosureStatusStock.isOpen,
    [disclosurePrice.isOpen, disclosureStatusStock.isOpen]
  );

  const handleOnSuccess = (newData) => {
    disclosurePrice.onClose();
    disclosureStatusStock.onClose();
    onClose();
    handleInlineEdit(newData);

    queryClient.invalidateQueries({
      queryKey: ["productStatus"],
    });
  };

  return (
    <>
      <Drawer isVisible={isOpen} onClose={onClose} closable placement="bottom">
        <Flex
          flexDirection="column"
          gap="16px"
          height={isChildDisclosureActive ? 0 : "100%"}
          marginTop="16px"
        >
          <Flex cursor="pointer" onClick={disclosureStatusStock.onOpen}>
            <Text color="black75">Ubah Stok </Text>
          </Flex>
          <Divider />
          <Flex cursor="pointer" onClick={disclosurePrice.onOpen}>
            <Text color="black75">Ubah Harga </Text>
          </Flex>
          <Divider />
          <Flex
            cursor="pointer"
            onClick={() =>
              push(`${mobileUrl.PRODUCTS_ADD}?id=${data.product_id}`)
            }
          >
            <Text color="black75">Edit Produk </Text>
          </Flex>
        </Flex>
      </Drawer>

      {disclosurePrice.isOpen && (
        <Price
          disclosure={disclosurePrice}
          handleOnSuccess={handleOnSuccess}
          data={data}
        />
      )}

      {disclosureStatusStock.isOpen && (
        <StatusStock
          disclosure={disclosureStatusStock}
          handleOnSuccess={handleOnSuccess}
          data={data}
        />
      )}
    </>
  );
};

export default PopupMore;
