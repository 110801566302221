import React from "react";
import {
  Flex,
  Text,
  Icon,
  Avatar,
  Dropdown,
  isEmpty,
  useDisclosure,
  Dialog,
  Button,
} from "renos-ui";
import { useHistory } from "react-router-dom";
import desktopUrl from "constants/desktopUrl";
import { StyledButtonDropdown } from "./styled";

const ChatHeaderRoom = ({
  selectedUser,
  online,
  typing,
  onlineStatus,
  handleDeleteChat,
}) => {
  const history = useHistory();
  const { avatar, name, id } = selectedUser;
  const disclosureDelete = useDisclosure({ isOpen: false });

  return (
    <Flex
      borderBottom="1px solid"
      borderColor="black10"
      padding="12px 16px"
      alignItems="center"
    >
      <Flex flexGrow="1">
        {selectedUser && (
          <Flex columnGap="12px" alignItems="center">
            <Flex>
              <Avatar
                size={40}
                src={
                  avatar && avatar !== "https://images.renos.id/"
                    ? avatar
                    : "https://cccloudstorage.blob.core.windows.net/images/UserLogo.png"
                }
              />
            </Flex>

            <Flex flexDirection="column">
              <Flex>
                <Text weight="bold">{name}</Text>
              </Flex>
              <Flex alignItems="center" gap="4px">
                <Text variant="small" color="blue50" weight="bold">
                  {typing
                    ? "Sedang mengetik..."
                    : online.isOnline
                    ? "Online"
                    : online.lastOnline
                    ? onlineStatus()
                    : ""}
                </Text>
                {online.isOnline && (
                  <Flex
                    backgroundColor="blue50"
                    width="8px"
                    height="8px"
                    borderRadius="100%"
                  />
                )}
              </Flex>
            </Flex>
          </Flex>
        )}
      </Flex>
      <Flex gap="16px" paddingY="8px" alignItems="center" position="relative">
        {!isEmpty(id) && (
          <Dropdown>
            <Dropdown.Toggle>
              <StyledButtonDropdown
                variant="tertiary"
                size="small"
                icon={
                  <Flex style={{ pointerEvents: "none" }}>
                    <Icon size={20} name="Vertical-outline" />
                  </Flex>
                }
              />
            </Dropdown.Toggle>
            <Dropdown.Content style={{ padding: "4px 0 !important" }}>
              <Dropdown.Item onClick={() => disclosureDelete.onToggle()}>
                <Flex gap="16px">
                  {/* <Flex width="3px" ml="-3px" backgroundColor="blue50" /> */}
                  <Text>Hapus Chat</Text>
                </Flex>
              </Dropdown.Item>
            </Dropdown.Content>
          </Dropdown>
        )}

        <Icon
          name="Setting-outline"
          size={20}
          cursor="pointer"
          onClick={() => history.push(desktopUrl.CHAT_SETTING)}
        />
      </Flex>

      <Dialog
        isVisible={disclosureDelete.isOpen}
        onClose={disclosureDelete.onClose}
        verticalCentered
        width="400px"
      >
        <Flex
          width="100%"
          fontFamily="sans-serif"
          flexDirection="column"
          rowGap="24px"
        >
          <Flex flexDirection="column" rowGap="8px">
            <Text variant="heading3" weight="bold">
              Hapus chat?
            </Text>
            <Text variant="body">
              Pesan chat dengan pembeli yang kamu hapus tidak dapat
              dikembalikan.
            </Text>
          </Flex>
          <Flex flexDirection="row" justifyContent="space-between" gap="1rem">
            <Button
              isBlock
              size="large"
              variant="tertiary"
              onClick={disclosureDelete.onClose}
            >
              Batal
            </Button>
            <Button
              isBlock
              size="large"
              variant="primary"
              onClick={() => {
                handleDeleteChat();
                disclosureDelete.onClose();
              }}
            >
              Ya, Hapus
            </Button>
          </Flex>
        </Flex>
      </Dialog>
    </Flex>
  );
};

export default ChatHeaderRoom;