import QiscusSDKCore from "qiscus-sdk-core";
import mitt from "mitt";

export const qiscus = new QiscusSDKCore();

export const event = mitt();

export const QISCUS = {
  init: () => {
    qiscus.init({
      AppId: process.env.REACT_APP_QISCUS_APP_ID,
      options: {
        loginSuccessCallback(authData) {
          event.emit("event", { kind: "login-success", data: authData });
        },
        newMessagesCallback(messages) {
          event.emit("event", { kind: "new-message", data: messages });
        },
        presenceCallback(data, idUser) {
          // eslint-disable-next-line no-param-reassign
          data = data.split(":");
          const isOnline = data[0] === "1";
          const lastOnline = new Date(Number(data[1]));
          event.emit("event", {
            kind: "online-presence",
            data: { isOnline, lastOnline, idUser },
          });
        },
        commentReadCallback: (data) => {
          event.emit("event", { kind: "comment-read", data });
        },
        commentDeliveredCallback(data) {
          event.emit("event", { kind: "comment-delivered", data });
        },
        typingCallback(data) {
          event.emit("event", { kind: "typing", data });
        },
        chatRoomCreatedCallback(data) {
          event.emit("event", { kind: "chat-room-created", data });
        },
        onReconnectCallback: function (data) {
          event.emit("event", { kind: "reconnecting", data });
        },
        loginErrorCallback(data) {
          event.emit("event", { kind: "fail-login", data });
        },
      },
    });
  },
  login: (data) => {
    if (data) {
      // NOTE: userId, uniqueKey, displayName, avatar(optional)
      const qParams = data;

      return qiscus.setUser(
        qParams.userId,
        qParams.userKey,
        qParams.username,
        qParams.avatarURL
      );
    }
  },
  logout: () => {
    qiscus.disconnect();
  },
  createRoom: (target) => {
    return qiscus
      .chatTarget(target)
      .then(function (room) {
        // On success
        // console.log(`room to user ${target} is created`, room);

        return room;
      })
      .catch(function (error) {
        // On error
        // console.log(`fail to create room to user ${target}`, error);
        return error;
      });
  },
  loadRooms: ({ page, limit, show_participants, show_empty }) => {
    return qiscus.loadRoomList({ page, limit, show_participants, show_empty });
  },
  getRoomById: (roomId) => {
    return qiscus.getRoomById(roomId);
  },
  loadComments: (roomId, options) => {
    return qiscus.loadComments(roomId, options);
  },
  sendMessage: (roomId, msg, uniqueId, type, payload, extras) => {
    if (!uniqueId) {
      return qiscus.sendComment(roomId, msg);
    } else {
      return qiscus.sendComment(roomId, msg, uniqueId, type, payload, extras);
    }
  },
  subscribeEvent: (roomId, callback) => {
    return qiscus.subscribeEvent(roomId, callback);
  },
  unsubscribeEvent: (roomId) => {
    return qiscus.unsubscribeEvent(roomId);
  },
  publishTyping: (typing) => {
    return qiscus.publishTyping(typing);
  },
  readComment: (roomId, lastReadCommentId) => {
    return qiscus.readComment(roomId, lastReadCommentId);
  },
  totalUnread: () => {
    return qiscus.getTotalUnreadCount();
  },
  roomInfo: (params) => {
    return qiscus.getRoomsInfo(params);
  },
  deleteChatRoom: (params) => {
    return qiscus.clearRoomMessages([params]);
  }
};
