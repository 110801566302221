import { Button, Flex, Icon, Spinner, Text, isEmpty, useToast } from "renos-ui";
import Picker from "./Picker";
import { useEffect, useState } from "react";
import {
  useRequestPickupInstant,
  useRequestPickupWithTime,
} from "services/order-management";
import FailLoadData from "widgets/mobile/FailLoadData";

const FormPickup = ({
  onClose,
  orderId,
  isOpen,
  toFirstPage,
  backToConfirmation,
}) => {
  const toast = useToast();
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const [pickupDate, setPickupDate] = useState({});
  const [pickupTime, setPickupTime] = useState({});
  const [isLowerParent, setIsLowerParent] = useState(false);
  const [listDay, setListDay] = useState([]);
  const [listTime, setListTime] = useState([]);

  const {
    data: dataTime,
    isLoading,
    isFetching,
    refetch,
    isError,
  } = useRequestPickupWithTime({ order_id: orderId, tz: timezone });

  const { mutate: requestPickupInstant, isLoading: isLoadingRequestPickup } =
    useRequestPickupInstant({
      onSuccess: (data) => {
        const { message, frontend_message } = data;
        toFirstPage();
        onClose();
        toast({
          label: frontend_message || message || "Request pickup berhasil",
          placement: "bottom",
        });
      },
      onError: (data) => {
        const {
          response: {
            data: { frontend_message, message },
          },
        } = data;
        toast({
          label: frontend_message || message || "Request pickup gagal",
          placement: "bottom",
          backgroundColor: "red50",
        });
      },
    });

  const handleSetDate = (newDate) => {
    const tempListTime = newDate?.hour_options.map((time) => ({
      value: time.submit_value,
      label: time.hour_label,
    }));
    setPickupTime({});
    setPickupDate(newDate);
    setListTime([...tempListTime]);
  };

  const handleRequest = () => {
    const params = {
      order_id: orderId,
      pickup_datetime: pickupTime.value,
      pickup_tz: timezone,
    };

    requestPickupInstant(params);
  };

  useEffect(() => {
    if (!isLoading && !isFetching && dataTime?.data) {
      const tempListDay = dataTime?.data.map((day) => ({
        hour_options: day.hour_options,
        label: day.day_label,
      }));
      setListDay([...tempListDay]);
      setPickupDate({});
      setPickupTime({});
    }
    // eslint-disable-next-line
  }, [isLoading, isFetching]);

  useEffect(() => {
    if (isOpen) refetch();
    // eslint-disable-next-line
  }, [isOpen]);

  return (
    <Flex
      flexDirection="column"
      width="100%"
      gap="32px"
      height={isLowerParent ? "0" : "100%"}
    >
      <Flex gap="8px" alignItems="center">
        <Icon
          name="Close-outline"
          size={24}
          color="black50"
          onClick={onClose}
        />
        <Text variant="subtitle" weight="bold">
          Kapan pesananmu ingin di Pick Up?
        </Text>
      </Flex>
      {isError ? (
        <FailLoadData onClick={refetch} />
      ) : (
        <>
          <Flex flexDirection="column" width="100%" gap="16px">
            <Picker
              label="Tanggal Pick Up"
              placeholder="Pilih tanggal"
              value={pickupDate}
              setValue={handleSetDate}
              setIsLowerParent={setIsLowerParent}
              list={listDay}
              isLoading={isLoading}
            />
            <Picker
              label="Waktu Pick Up"
              placeholder="Pilih tanggal"
              value={pickupTime}
              setValue={setPickupTime}
              setIsLowerParent={setIsLowerParent}
              list={listTime}
              isLoading={isLoading}
              isDisabled={isEmpty(pickupDate)}
            />
          </Flex>

          <Flex gap="8px">
            <Button
              variant="tertiary"
              size="medium"
              isBlock
              onClick={backToConfirmation}
            >
              Batal
            </Button>
            <Button
              size="medium"
              isBlock
              disabled={isEmpty(pickupTime) || isLoadingRequestPickup}
              preffix={
                isLoadingRequestPickup && <Spinner color="white" size="8px" />
              }
              onClick={handleRequest}
            >
              {isLoadingRequestPickup ? "Loading..." : "Request Pick Up"}
            </Button>
          </Flex>
        </>
      )}
    </Flex>
  );
};

export default FormPickup;
