import desktopUrl from "constants/desktopUrl";
import { useHistory } from "react-router-dom";
import { Button, Divider, Flex, Icon, Spinner, Text, useToast } from "renos-ui";
import { useEffect, useState } from "react";
import TableAdd from "./component/Table";
import dayjs from "dayjs";
import {
  useGetProductDiscountById,
  useSaveDiscountManagement,
} from "services/promo-management";
import useQueryParams from "hooks/useQueryParams";
import Loader from "./component/Loader";

const AddPromo = ({ location }) => {
  const history = useHistory();
  const toast = useToast();
  const query = useQueryParams();

  const [defaultData, setDefaultData] = useState([]);
  const [dataList, setDataList] = useState([]);
  const [dataError, setDataError] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const goBack = () => {
    history.push(desktopUrl.DISCOUNT);
  };

  const { isLoading: isSaveLoading, mutate: saveDiscountManagement } =
    useSaveDiscountManagement({
      onSuccess: (data) => {
        const { message, frontend_message } = data;

        toast({
          label:
            frontend_message || message || "Berhasil menyimpan diskon produk.",
          placement: "top",
        });
        setTimeout(() => {
          goBack();
        }, 2000);
      },
      onError: (data) => {
        const {
          response: {
            data: { frontend_message, message },
          },
        } = data;
        toast({
          label:
            frontend_message || message || "Gagal menyimpan diskon produk.",
          placement: "top",
          backgroundColor: "red50",
        });
      },
    });

  const handleError = () => {
    let errorCount = 0;
    const arrError = dataError;
    // eslint-disable-next-line
    defaultData.map((product, indexProduct) => {
      for (const [key] of Object.entries(arrError[indexProduct])) {
        if (key === "product_skus") {
          // eslint-disable-next-line
          product.product_skus.map((sku, indexSKU) => {
            for (const [keySKU] of Object.entries(
              arrError[indexProduct].product_skus[indexSKU]
            )) {
              if (sku.isActive) {
                if (!sku[keySKU] && keySKU !== "product_discount_amount") {
                  arrError[indexProduct].product_skus[indexSKU][keySKU] = true;
                  errorCount++;
                }

                const arrNotAllowZero = [
                  "product_discount_percentage",
                  "product_discount_quota",
                ];

                if (
                  arrNotAllowZero.includes(keySKU) &&
                  (!sku[keySKU] || sku[keySKU] === "0")
                ) {
                  arrError[indexProduct].product_skus[indexSKU][keySKU] = true;
                  errorCount++;
                }

                if (
                  sku[keySKU] > 90 &&
                  keySKU === "product_discount_percentage"
                ) {
                  arrError[indexProduct].product_skus[indexSKU][keySKU] = true;
                  errorCount++;
                }
              } else {
                arrError[indexProduct].product_skus[indexSKU][keySKU] = false;
              }
            }
          });
        } else {
          if (
            !product[key] &&
            !product.isHasMore &&
            key !== "product_discount_amount_start"
          ) {
            arrError[indexProduct][key] = true;
            errorCount++;
          }

          const arrNotAllowZero = [
            "product_discount_percentage_start",
            "product_discount_quota",
          ];

          if (
            arrNotAllowZero.includes(key) &&
            !product.isHasMore &&
            (!product[key] || product[key] === "0")
          ) {
            arrError[indexProduct][key] = true;
            errorCount++;
          }

          if (
            product[key] > 90 &&
            !product.isHasMore &&
            key === "product_discount_percentage_start"
          ) {
            arrError[indexProduct][key] = true;
            errorCount++;
          }
        }
      }
    });
    setDataError([...arrError]);

    return errorCount;
  };

  const handleSave = () => {
    const isError = handleError();

    if (isError) {
      toast({
        label: "Mohon lengkapi data terlebih dahulu.",
        placement: "top",
        backgroundColor: "red50",
      });

      return;
    }

    const arrParams = [];
    // eslint-disable-next-line
    defaultData.map((product) => {
      if (product.isHasMore) {
        // eslint-disable-next-line
        product.product_skus.map((sku) => {
          if (sku.isActive || sku.product_discount_id) {
            arrParams.push({
              is_ended: (sku.product_discount_id && !sku.isActive) || false,
              product_discount_id: sku.product_discount_id,
              product_sku_id: !sku.product_discount_id
                ? sku.product_sku_id
                : null,
              product_discount_date_start:
                sku.isActive || sku.product_discount_date_start
                  ? dayjs(sku.product_discount_date_start).format(
                      "YYYY-MM-DD HH:mm:00"
                    )
                  : new Date(),
              product_discount_date_end:
                sku.isActive || sku.product_discount_date_end
                  ? dayjs(sku.product_discount_date_end).format(
                      "YYYY-MM-DD HH:mm:00"
                    )
                  : new Date(),
              product_discount_quota:
                sku.isActive || sku.product_discount_quota
                  ? parseInt(sku.product_discount_quota)
                  : 0,
              discount_type_id: 1,
              discount_value:
                sku.isActive || sku.product_discount_percentage
                  ? parseInt(sku.product_discount_percentage)
                  : 0,
            });
          }
        });
      } else {
        arrParams.push({
          product_discount_id: product.product_discount_id,
          product_sku_id: !product.product_discount_id
            ? product.product_sku_id
            : null,
          product_discount_date_start: dayjs(
            product.product_discount_date_start
          ).format("YYYY-MM-DD HH:mm:00"),
          product_discount_date_end: dayjs(
            product.product_discount_date_end
          ).format("YYYY-MM-DD HH:mm:00"),
          product_discount_quota: parseInt(product.product_discount_quota),
          discount_type_id: 1,
          discount_value: parseInt(product.product_discount_percentage_start),
        });
      }
    });

    saveDiscountManagement({
      product_discounts: arrParams,
    });
  };

  const { mutate: getProductDiscountById } = useGetProductDiscountById({
    onSuccess: (data) => {
      if (data?.data && data?.data.length > 0) {
        const formatData = data.data.map((product) => {
          return handleDataEdit(product);
        });
        handleSetData(formatData);
        setIsLoading(false);
      } else {
        goBack();
      }
    },
    onError: () => {
      goBack();
    },
  });

  const handleDataEdit = (newData) => {
    let tempData = {};

    if (newData.product_type === "variant") {
      tempData = {
        isHasMore: true,
        product_id: newData.product_id,
        product_name: newData.product_name,
        product_stock: newData.product_stock,
        product_image_url: newData.product_image_url,
        product_skus: newData.product_skus.map((sku) => {
          const {
            product_sku_id,
            product_image_url,
            formatted_variant_value_name,
            formatted_product_price,
            product_sku_mpn,
            product_stock,
            product_price,

            active_product_discount: {
              discount_value,
              product_discount_quota,
              product_discount_price,
              formatted_product_discount_date_end,
              formatted_product_discount_date_start,
              product_discount_id,
            },
          } = sku;

          return {
            product_discount_id: product_discount_id,
            product_discount_percentage: discount_value,
            product_discount_quota: product_discount_quota,
            product_discount_date_end:
              formatted_product_discount_date_end &&
              new Date(formatted_product_discount_date_end),
            product_discount_date_start:
              formatted_product_discount_date_start &&
              new Date(formatted_product_discount_date_start),
            product_discount_amount: product_discount_price,
            product_sku_id: product_sku_id,
            product_id: newData.product_id,
            isActive: product_discount_id || false,
            isChecked: false,
            product_image_url: product_image_url,
            product_name: newData.product_name,
            product_sku_mpn: product_sku_mpn,
            product_stock: product_stock,
            formatted_variant_value_name,
            formatted_product_price,
            product_price,
          };
        }),
      };
    } else {
      const {
        active_product_discount: {
          discount_value,
          product_discount_quota,
          product_discount_price,
          formatted_product_discount_date_end,
          formatted_product_discount_date_start,
          product_discount_id,
        },
      } = newData.product_skus[0];

      tempData = {
        ...tempData,
        product_id: newData.product_id,
        product_discount_id: product_discount_id,
        product_discount_percentage_start: discount_value,
        product_discount_quota: product_discount_quota,
        product_discount_date_end:
          formatted_product_discount_date_end &&
          new Date(formatted_product_discount_date_end),
        product_discount_date_start:
          formatted_product_discount_date_start &&
          new Date(formatted_product_discount_date_start),
        product_discount_amount_start: product_discount_price,
        product_skus: [],
        product_image_url: newData.product_image_url,
        product_name: newData.product_name,
        product_price_start: newData.product_skus[0].product_price,
        product_sku_mpn: newData.product_skus[0].product_sku_mpn,
        product_stock: newData.product_skus[0].product_stock,
        formatted_product_price:
          newData.product_skus[0].formatted_product_price,
      };
    }

    return tempData;
  };

  const handleSetData = (arrData) => {
    setDefaultData(arrData);
    setDataList(arrData);
    setDataError(
      arrData.map((product) => ({
        product_discount_percentage_start: false,
        product_discount_quota: false,
        product_discount_date_end: false,
        product_discount_date_start: false,
        product_discount_amount_start: false,
        product_skus: product.product_skus.map((sku) => ({
          product_discount_percentage: false,
          product_discount_quota: false,
          product_discount_date_end: false,
          product_discount_date_start: false,
          product_discount_amount: false,
        })),
      }))
    );
  };

  useEffect(() => {
    const listId = query.get("id");

    if (listId) {
      getProductDiscountById({ product_ids: listId, limit: 9999 });
    } else if (location.state !== undefined) {
      setIsLoading(false);
      handleSetData(location.state.data);
    } else {
      goBack();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let tempDataList = dataList.map((product) => {
      const { product_id } = product;
      const filteredProduct = defaultData.filter(
        (filterProduct) => filterProduct.product_id === product_id
      );

      if (filteredProduct.length > 0) {
        return filteredProduct[0];
      }

      return product;
    });

    tempDataList = tempDataList.length === 0 ? defaultData : tempDataList;
    setDataList([...tempDataList]);
    // eslint-disable-next-line
  }, [defaultData]);

  return (
    <Flex gap="24px" flexDirection="column">
      <Flex gap="12px" alignItems="center">
        <Icon name="Back-outline" size={24} cursor="pointer" onClick={goBack} />
        <Text variant="heading3" weight="bold">
          Atur Diskon Produk
        </Text>
      </Flex>

      {isLoading ? (
        <Loader />
      ) : (
        <Flex gap="8px" flexDirection="column">
          <Flex
            padding="24px"
            gap="24px"
            flexDirection="column"
            backgroundColor="white"
            borderRadius="16px 16px 0px 0px"
          >
            <Divider />
            <TableAdd
              dataTable={dataList}
              setDataTable={setDataList}
              defaultData={defaultData}
              setDefaultData={setDefaultData}
              dataError={dataError}
              setDataError={setDataError}
            />
          </Flex>
          <Flex
            padding="16px"
            gap="16px"
            backgroundColor="white"
            justifyContent="flex-end"
            borderRadius="0px 0px 16px 16px"
          >
            <Flex width="160px">
              <Button isBlock variant="tertiary" onClick={goBack}>
                Batal
              </Button>
            </Flex>
            <Flex width="160px">
              <Button
                isBlock
                disabled={isSaveLoading}
                onClick={handleSave}
                preffix={isSaveLoading && <Spinner color="white" size="8px" />}
              >
                Simpan
              </Button>
            </Flex>
          </Flex>
        </Flex>
      )}
    </Flex>
  );
};

export default AddPromo;
