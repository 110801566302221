import styled from "styled-components";
import { Chip, Flex, colors } from "renos-ui";

export const StyledChipDiscount = styled(Chip)`
  padding: 1px 6px;
  font-size: 10px;
`;

export const FlexOverflowWrap = styled(Flex)`
  overflow-wrap: anywhere;
`;

export const SearchInputProductWrap = styled(Flex)`
  > div > span {
    border-color: ${colors.black5};
    border-radius: 100px;
    background-color: ${colors.black5};

    > input {
      background-color: ${colors.black5};
      color: ${colors.black25};
    }
  }
`;
