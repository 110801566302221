import React from "react";
import { Button, Flex, Text, Dialog, Spinner } from "renos-ui";

const SelectModal = ({
  selectModal,
  handleSaveSelect,
  value,
  isSaveLoading,
}) => {
  return (
    <Dialog
      isVisible={selectModal.isOpen}
      onClose={selectModal.onClose}
      verticalCentered
      width="434px"
      minHeight="100px"
      padding="40px"
    >
      <Flex flexDirection="column" width="100%" gap="24px">
        <Flex flexDirection="column" gap="8px">
          <Text variant="heading3" weight="bold">
            Pilih template ini?
          </Text>
          <Text>
            Template yang dipilih akan menjadi balasan otomatis untuk ulasan
            produk dengan rating bintang 4 dan 5.
          </Text>
        </Flex>
        <Flex justifyContent="space-between" gap="16px">
          <Button isBlock variant="tertiary" onClick={selectModal.onClose}>
            Kembali
          </Button>
          <Button
            isBlock
            onClick={handleSaveSelect}
            disabled={
              value?.id === "" || value?.message === "" || isSaveLoading
            }
            preffix={isSaveLoading && <Spinner size="16px" color="blue10" />}
          >
            Pilih
          </Button>
        </Flex>
      </Flex>
    </Dialog>
  );
};

export default SelectModal;
