import { useMemo, useState } from "react";
import {
  Button,
  DatePicker,
  Drawer,
  Flex,
  Spinner,
  Text,
  useToast,
} from "renos-ui";
import { Wrapper } from "./styled";
import { getRangeWeek } from "helpers";
import { useDownloadSalesReport } from "services/order-management";
import dayjs from "dayjs";
import { goToUrl } from "helpers";
import ENDPOINTS from "constants/endpoints";

const DownloadReport = ({ disclosure, onCloseMore }) => {
  const toast = useToast();

  const { isOpen, onClose } = disclosure;
  const tabItems = ["Per Hari", "Per Minggu", "Per Bulan"];
  const [selectedTab, setSelectedTab] = useState("Per Hari");
  const [selectedDate, setSelectedDate] = useState({
    startDate: "",
    endDate: "",
  });

  const { mutate, isLoading } = useDownloadSalesReport({
    onSuccess: () => {
      onClose();
      onCloseMore();

      toast({
        label: "Berhasil mengunduh laporan penjualan.",
        placement: "top",
      });

      goToUrl(
        `${process.env.REACT_APP_API}/${
          ENDPOINTS.download_sales_report
        }?sort_type=asc&date_start=${dayjs(selectedDate.startDate).format(
          "YYYY-MM-DD"
        )}&date_end=${dayjs(selectedDate.endDate).format("YYYY-MM-DD")}`,
        "_blank"
      );
    },
    onError: (data) => {
      const {
        response: {
          data: { frontend_message },
        },
      } = data;
      toast({
        label: frontend_message || "Gagal mengunduh laporan penjualan.",
        placement: "top",
        backgroundColor: "red50",
      });
    },
  });

  const handleDownload = () => {
    mutate({
      sort_type: "asc",
      date_start: dayjs(selectedDate.startDate).format("YYYY-MM-DD"),
      date_end: dayjs(selectedDate.endDate).format("YYYY-MM-DD"),
    });
  };

  const isDisabled = useMemo(
    () => isLoading || !selectedDate.startDate || !selectedDate.endDate,
    [isLoading, selectedDate]
  );

  const handleOnChangeCalendar = (dateValue) => {
    let tempStartDate = selectedTab === "Per Hari" ? dateValue : dateValue[0];
    let tempEndDate = null;
    const selectedYear = tempStartDate.getFullYear();
    const selectedMonth = tempStartDate.getMonth();
    const thisMonth = new Date().getMonth();
    const thisDay = new Date().getDate();
    const lastDate = new Date(selectedYear, selectedMonth + 1, 0).getDate();

    if (selectedTab === "Per Bulan") {
      tempStartDate.setDate(1);
      tempEndDate = new Date(
        selectedYear,
        selectedMonth,
        thisMonth === selectedMonth ? thisDay : lastDate
      );
    } else if (selectedTab === "Per Minggu") {
      const thisWeek = getRangeWeek(tempStartDate);
      tempStartDate = thisWeek[0];
      tempEndDate = thisWeek[1];
    }

    setSelectedDate({
      startDate: tempStartDate,
      endDate: selectedTab === "Per Hari" ? tempStartDate : tempEndDate,
      type: selectedTab,
    });
  };

  const renderDatepicker = useMemo(() => {
    if (selectedTab === "Per Hari") {
      return (
        <Wrapper>
          <DatePicker
            inline
            selected={selectedDate.startDate}
            onChange={(vals) => handleOnChangeCalendar(vals)}
            maxDate={new Date()}
          />
        </Wrapper>
      );
    }

    return (
      <Wrapper>
        <DatePicker
          inline
          selected={selectedDate.startDate}
          startDate={selectedDate.startDate}
          endDate={selectedDate.endDate}
          selectsRange
          onChange={(vals) => handleOnChangeCalendar(vals)}
          maxDate={new Date()}
        />
      </Wrapper>
    );
    // eslint-disable-next-line
  }, [selectedTab, selectedDate]);

  return (
    <Drawer isVisible={isOpen} onClose={onClose} closable placement="bottom">
      <Flex flexDirection="column" width="100%" height="100%">
        <Flex gap="16px" flexDirection="column">
          <Text variant="subtitle" weight="bold">
            Atur Rentang Tanggal
          </Text>

          <Flex gap="16px">
            {tabItems.map((item, indexItem) => (
              <Flex
                key={`tabs-${indexItem}`}
                padding="6px 16px"
                borderColor={selectedTab === item ? "blue50" : "black10"}
                borderStyle="solid"
                borderWidth="1px"
                borderRadius="100px"
                backgroundColor={selectedTab === item ? "blue5" : "white"}
                minWidth="fit-content"
                alignItems="center"
                onClick={() => setSelectedTab(item)}
              >
                <Text
                  variant="caption"
                  color={selectedTab === item ? "blue50" : "black75"}
                >
                  {item}
                </Text>
              </Flex>
            ))}
          </Flex>
          {renderDatepicker}
        </Flex>

        <Flex paddingTop="16px">
          <Button
            py="12px"
            size="medium"
            isBlock
            onClick={handleDownload}
            disabled={isDisabled}
            preffix={isLoading && <Spinner color="white" size="8px" />}
          >
            Download Laporan
          </Button>
        </Flex>
      </Flex>
    </Drawer>
  );
};

export default DownloadReport;
