import styled from "styled-components";
import { Chip, Flex, colors, Dialog, Button, SelectInput } from "renos-ui";

export const StyledChipDiscount = styled(Chip)`
  padding: 1px 6px;
  font-size: 10px;
`;

export const FlexOverflowWrap = styled(Flex)`
  overflow-wrap: anywhere;
`;

export const SearchInputProductWrap = styled(Flex)`
  > div > span {
    border-color: ${colors.black5};
    border-radius: 100px;
    background-color: ${colors.black5};

    > input {
      background-color: ${colors.black5};
      color: ${colors.black25};
    }
  }
`;

export const StyledDialog = styled(Dialog)`
  margin: -30px;
`;

export const StyledButtonDropdown = styled(Button)`
  border: none;
  font-size: 12px;
  outline: none;
`;

export const StyledCardOrder = styled(Flex)`
  gap: 20px;
  /* padding-bottom: 12px; */
  padding: 16px;
  align-items: center;
  border-bottom: 1px solid ${colors.black5};
  width: 100%;
  cursor: pointer;
`;

export const WrapCard = styled(Flex)`
  position: relative;
  background-color: ${({ isSelected }) => (isSelected && colors.blue5 )};
  &:hover {
      background-color: ${colors.blue5}
  }
`;

export const StyledSelectInput = styled(SelectInput)`
  width: 100%;
`;