import { colors, Input } from "renos-ui";
import styled from "styled-components";

export const StyledTextArea = styled(Input.TextArea)`
  &:focus,
  &:focus-visible {
    border-color: ${colors.blue50};
    outline: none;
  }
`;
