import { useHistory } from "react-router-dom";
import { Box, Button, colors, Flex, Icon, Text, range, Divider, isEmpty } from "renos-ui";
import FailLoadData from "widgets/global/FailLoadData";
import SettingLoading from "./SettingLoading";
import SettingRow from "./settingRow";

const TableSetting = ({
  dataSetting,
  isError,
  refetch,
  isLoading,
  isFetching,
}) => {
  const history = useHistory();

  const handleAddNew = () => {
    history.push("/setting/seller-fleet/add");
  };

  return (
    <Flex
      border={`1px solid ${colors.black10}`}
      borderRadius="8px"
      padding="24px"
      flexDirection="column"
      gap="24px"
    >
      <Flex justifyContent="space-between" width="100%" alignItems="center">
        <Text variant="subtitle" weight="bold">
          Daftar Pengaturan
        </Text>
        <Box>
          <Button
            preffix={<Icon name="Plus-outline" size={20} />}
            variant="secondary"
            size="medium"
            onClick={handleAddNew}
          >
            Tambah Pengaturan
          </Button>
        </Box>
      </Flex>
      <Divider />

      {isError ? (
        <FailLoadData onClick={refetch} />
      ) : isLoading || isFetching ? (
        range(5).map((_, indexLoading) => (
          <SettingLoading key={`row-loading${indexLoading}`} />
        ))
      ) : isEmpty(dataSetting?.data) ? (
        <Flex
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          gap="8px"
          padding="16px"
        >
          <Flex
            as="img"
            alt="empty-review"
            src={`/assets/images/mobile/stok-kosong.png`}
          />

          <Flex
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            gap="8px"
            marginBottom="16px"
            width="416px"
          >
            <Text weight="bold" variant="heading3" color="black70">
              Belum ada pengaturan kurir
            </Text>
            <Box fontSize="14px" textAlign="center" color="black50">
              Tambahkan pengaturan kurir agar pembeli dapat memilih layanan
              pengiriman yang tersedia.
            </Box>
          </Flex>
        </Flex>
      ) : (
        dataSetting?.data?.map((dataRowSetting, indexSetting) => (
          <SettingRow
            dataRow={dataRowSetting}
            key={`row-setting-${indexSetting}`}
            refetchSetting={refetch}
          />
        ))
      )}
    </Flex>
  );
};

export default TableSetting;