import MobileLayout from "layouts/MobileLayout";
import { useState } from "react";
import { Flex } from "renos-ui";
import Filter from "./Filter";
import Update from "./Update";
import Order from "./Order";

const Notification = () => {
  const [selected, setSelected] = useState("Penjualan");

  return (
    <MobileLayout
      header={{
        title: "Notifikasi",
        withBack: true,
      }}
      noBorder
    >
      <Flex flexDirection="column" gap="16px" width="100%">
        <Filter value={selected} setValue={setSelected} />
        {selected === "Update" ? (
          <Update selected={selected} />
        ) : (
          <Order selected={selected} />
        )}
      </Flex>
    </MobileLayout>
  );
};

export default Notification;
