import styled from "styled-components";
import { Swiper } from "swiper/react";

export const StyledSwiper = styled(Swiper)`
  .swiper-wrapper {
    position: relative;
    border-radius: 10px;
    div {
      box-sizing: border-box;
    }
  }
`;
