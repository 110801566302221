import React from "react";
import { Box, Flex, Input, SelectInput, Text } from "renos-ui";

const FilterStock = () => {
  return (
    <Flex padding="24px" flexDirection="column" width="100%" rowGap="16px">
      <Flex flexDirection="column">
        <Text weight="bold" color="black75">
          Stok Produk
        </Text>
        <Text variant="caption" color="black75">
          Atur filter berdasarkan stok.
        </Text>
      </Flex>

      <Flex gap="8px" width="100%">
        <Box width="40%">
          <SelectInput
            placeholder="Pilih Kondisi"
            options={[
              {
                value: ">",
                label: "Lebih dari",
              },
              {
                value: "<",
                label: "Kurang dari",
              },
              {
                value: "=",
                label: "Sama dengan",
              },
            ]}
          />
        </Box>
        <Flex flexGrow={1}>
          <Input.Number placeholder="0" />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default FilterStock;
