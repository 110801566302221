import MobileLayout from "layouts/MobileLayout";
import React, { useState } from "react";
import {
  Box,
  Avatar,
  Flex,
  Text,
  Upload,
  useToast,
  useUpload,
  Spinner,
  useDisclosure,
} from "renos-ui";
import { StyledButton } from "./styled";
import { useMemo } from "react";
import SkeletonInformation from "./component/skeleton";
import DrawerSave from "./component/ModalSave";
import Warning from "./component/warning";
import { IMAGE_FILE_TYPE } from "./constant";
import { useHistory } from "react-router-dom";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { StoreInfoSchema } from "./storeInfo-schema";
import { FieldTextInput, FieldTextAreaInput } from "widgets/global/HookForm";

import {
  useGetStoreDetail,
  useUpdateStore,
  useUploadStoreImg,
} from "services/store";
import { goToUrl, imageTypeCheckingDocument, blobToBase64 } from "helpers";
import { useQueryClient } from "react-query";

const AccountInfo = () => {
  const defaultImg = "/assets/images/default_store.svg";
  const addDisclosure = useDisclosure({ isOpen: false });

  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(StoreInfoSchema),
    defaultValues: {
      name: "",
      email: "",
      description: "",
      contact_person: "",
      phone_number: "",
    },
  });

  const [payload, setPayload] = useState();

  const history = useHistory();

  const handleCancelSave = () => {
    addDisclosure.onClose();
    refetchGetStoreDetail();
  };

  const queryClient = useQueryClient();
  const toast = useToast();

  const {
    isLoading: isGetStoreLoading,
    refetch: refetchGetStoreDetail,
    isFetching: isGetStoreDetailFetching,
  } = useGetStoreDetail(null, null, {
    onSuccess: (data) => {
      methods.setValue(
        "name_default",
        data?.data?.name === null ? "" : data?.data?.name
      );
      methods.setValue(
        "name",
        data?.data?.name === null ? "" : data?.data?.name
      );
      methods.setValue(
        "email",
        data?.data?.email === null ? "" : data?.data?.email
      );
      methods.setValue(
        "description",
        data?.data?.description === null ? "" : data?.data?.description
      );
      methods.setValue(
        "contact_person",
        data?.data?.contact_person === null ? "" : data?.data?.contact_person
      );
      methods.setValue(
        "phone_number",
        data?.data?.phone_number === null ? "" : data?.data?.phone_number
      );
      methods.setValue(
        "imageUrl",
        data?.data?.image_path === null ? "" : data?.data?.image_path
      );
      methods.setValue("imgChanged", false);
      methods.setValue("isNameUpdated", data?.data?.is_name_updated);
    },
  });

  const { mutate: mutateUpdateStore, isLoading: isMutateUpdateStoreLoading } =
    useUpdateStore({
      onSuccess: (data) => {
        const { message, frontend_message } = data.data;
        if (!methods.getValues("imgChanged")) refetchGetStoreDetail();
        toast({
          label: frontend_message || message,
          placement: "bottom",
        });

        addDisclosure.onClose();
      },
      onError: (data) => {
        const {
          response: {
            data: { frontend_message, message },
          },
        } = data;
        toast({
          label: frontend_message || message,
          placement: "bottom",
          backgroundColor: "red50",
        });
        addDisclosure.onClose();
      },
    });

  const { mutate: mutateUploadImg, isLoading: isMutateUploadImgLoading } =
    useUploadStoreImg({
      onSuccess: (data) => {
        const { message, frontend_message } = data;
        refetchGetStoreDetail();
        if (!methods.formState.isDirty)
          toast({
            label: frontend_message || message,
            placement: "bottom",
          });
        queryClient.invalidateQueries({
          queryKey: ["getCheckStore"],
        });
      },
      onError: (data) => {
        const {
          response: {
            data: { frontend_message, message },
          },
        } = data;
        toast({
          label: frontend_message || message,
          placement: "bottom",
          backgroundColor: "red50",
        });
      },
    });

  const isLoading = useMemo(
    () =>
      isGetStoreLoading ||
      isGetStoreDetailFetching ||
      isMutateUpdateStoreLoading ||
      isMutateUploadImgLoading,
    [
      isGetStoreLoading,
      isGetStoreDetailFetching,
      isMutateUpdateStoreLoading,
      isMutateUploadImgLoading,
    ]
  );

  const handleSave = (data) => {
    const dataSave = data || payload;
    const payloadData = {
      data: {
        name: dataSave?.name,
        email: dataSave?.email,
        contact_person: dataSave?.contact_person,
        phone_number: dataSave?.phone_number,
        description: dataSave?.description,
      },
    };

    if (methods.getValues("isNameUpdated")) delete payloadData.data.name;
    if (methods.formState.isDirty) mutateUpdateStore(payloadData);

    const formData = new FormData();
    formData.append("file", methods.getValues("imageFile"));
    if (methods.getValues("imageFile")) mutateUploadImg(formData);
  };

  const { register } = useUpload({
    fileType: "image",
    onRequest: async (file) => {
      const deepTypeChecking = await imageTypeCheckingDocument(file);

      if (deepTypeChecking) {
        const maxAllowedSize = 10000000;

        if (maxAllowedSize < file?.size) {
          toast({
            label: "Ukuran file tidak boleh melebihi dari 10mb.",
            placement: "bottom",
            backgroundColor: "red50",
          });

          return "";
        }

        const result = await blobToBase64(file);
        toast({
          label: "File berhasil diupload!",
          placement: "bottom",
        });

        methods.setValue("imageUrl", result);
        methods.setValue("imageFile", file);
        methods.setValue("imgChanged", true);
      } else {
        toast({
          label:
            "Gagal upload file. Harap cek kembali jenis file yang akan kamu upload.",
          placement: "bottom",
          backgroundColor: "red50",
          clickLabel: "Oke",
        });
      }
    },
    validator: (file) => {
      if (!IMAGE_FILE_TYPE.includes(file.type)) {
        toast({
          label:
            "Gagal upload file. Harap cek kembali jenis file yang akan kamu upload.",
          placement: "bottom",
          backgroundColor: "red50",
          clickLabel: "Oke",
        });

        return {
          message: "Format gambar tidak sesuai",
        };
      }

      return null;
    },
  });

  const onSubmit = (data) => {
    if (
      methods.getValues("isNameUpdated") ||
      data.name === methods.getValues("name_default")
    ) {
      handleSave(data);
    } else {
      setPayload(data);
      addDisclosure.onOpen();
    }
  };

  return (
    <MobileLayout
      header={{
        title: "Informasi Toko",
        withBack: true,
        withoutGoBack: true,
        onBack: () => history.push("/account"),
      }}
    >
      {isLoading ? (
        <SkeletonInformation item={6} />
      ) : (
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Flex flexDirection="column" gap="16px" width="100%">
              <Flex flexDirection="column" gap="9px" paddingBottom="80px">
                <Warning
                  formValue={methods.getValues("isNameUpdated")}
                  goToUrl={() =>
                    goToUrl("mailto:sellersupport@renos.id", "_self")
                  }
                />
                <Box
                  backgroundColor="black5"
                  margin="16px"
                  padding="16px"
                  borderRadius="8px"
                >
                  <Flex gap="12px">
                    <Flex flexDirection="column" alignItems="center">
                      <Avatar
                        backgroundColor="blue5"
                        size="60px"
                        isBorder
                        src={
                          methods.getValues("imageUrl") !== null
                            ? methods.getValues("imageUrl")
                            : defaultImg
                        }
                      />
                    </Flex>
                    <Flex flexDirection="column" gap="6px">
                      <Flex>
                        <Text variant="caption" weight="bold">
                          Foto Profil Toko
                        </Text>
                      </Flex>
                      <Flex gap="8px" flexDirection="column">
                        <Flex>
                          <Text variant="caption">
                            Ukuran File maksimal 10 Mb dengan format: JPG, JPEG,
                            dan PNG.
                          </Text>
                        </Flex>
                        <Flex>
                          <Upload {...register}>
                            <Text
                              color="blue50"
                              variant="caption"
                              weight="bold"
                            >
                              Pilih Foto
                            </Text>
                          </Upload>
                        </Flex>
                      </Flex>
                    </Flex>
                  </Flex>
                </Box>
                <Flex height="8px" backgroundColor="black5"></Flex>
                <Flex flexDirection="column" gap="20px" p="16px">
                  <Flex flexDirection="column" gap="16px">
                    <Box width="100%">
                      <FieldTextInput
                        maxLength={60}
                        disabled={methods.getValues("isNameUpdated")}
                        showCount
                        name="name"
                        placeholder=" Nama Toko"
                        label="Nama Toko"
                        isTrim
                      />
                    </Box>
                    <Box width="100%">
                      <FieldTextInput
                        maxLength={50}
                        label="Email Toko"
                        showCount
                        name="email"
                        placeholder="Ketik email toko"
                        isTrim
                      />
                    </Box>
                    <Box width="100%">
                      <FieldTextInput
                        maxLength={50}
                        label="Nama Contact Person"
                        showCount
                        name="contact_person"
                        placeholder="Nama Contact Person"
                        isTrim
                      />
                    </Box>
                    <Box width="100%">
                      <FieldTextInput
                        maxLength={15}
                        label=" No. HP Contact Person"
                        showCount
                        name="phone_number"
                        placeholder="Masukan nomor HP/Telepon"
                        isTrim
                        typePhoneNumber={true}
                      />
                    </Box>
                  </Flex>
                </Flex>
                <Flex height="8px" backgroundColor="black5"></Flex>
                <Flex flexDirection="column" gap="20px" p="16px">
                  <Flex flexDirection="column" gap="16px">
                    <Box width="100%">
                      <FieldTextAreaInput
                        maxLength={140}
                        label=" Deskripsi Toko"
                        showCount
                        name="description"
                        placeholder="Tambahkan deskripsi toko"
                        isTrim
                      />
                    </Box>
                  </Flex>
                </Flex>
              </Flex>

              <Flex
                bottom="0"
                width="100%"
                position="fixed"
                justifyContent="center"
                alignItems="center"
                boxShadow="0px 0px 8px 0px rgba(20, 20, 32, 0.05);"
                boxSizing="border-box"
                p="16px"
              >
                <StyledButton
                  isBlock
                  disabled={
                    !methods.formState.isValid && !methods.formState.isDirty
                  }
                  preffix={isLoading && <Spinner size="16px" color="blue10" />}
                >
                  Simpan
                </StyledButton>
              </Flex>
            </Flex>
          </form>
        </FormProvider>
      )}

      {/* handle action */}
      <DrawerSave
        disclosure={addDisclosure}
        handleCancelSave={handleCancelSave}
        handleSave={() => handleSave()}
      />
    </MobileLayout>
  );
};

export default AccountInfo;