import styled from "styled-components";
import { Input, Button, colors } from "renos-ui";

export const StyledTextArea = styled(Input.TextArea)`
  resize: none;
  height: 90px;
`;

export const StyledTextAreaOutofStock = styled(Input.TextArea)`
  resize: none;
  height: 60px;
`;

export const StyledButton = styled(Button)`
  :disabled {
    span {
      color: ${colors.black25};
    }
  }
`;
