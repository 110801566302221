import MobileLayout from "layouts/MobileLayout";
import React from "react";
import { Flex, Icon, useToast, useDisclosure } from "renos-ui";
import Empty from "./component/empty";
import { useGetNotes, useDeleteNotes } from "services/settings/note";
import { useState, useContext } from "react";
import StoreContext from "providers/StoreProvider";
import { useHistory } from "react-router-dom";
import mobileUrl from "constants/mobileUrl";
import { setGTM } from "helpers";
import SkeletonNote from "./component/skeleton";
import CardNotes from "./component/CardNotes";
import HandleDrawer from "./component/HandleDrawer";
import PreviewDrawer from "./component/PreviewDrawer";
import DeleteDrawer from "./component/DeleteDrawer";

const AccountNote = () => {
  const showDisclosure = useDisclosure({ isOpen: false });
  const deleteDisclosure = useDisclosure({ isOpen: false });
  const previewDisclosure = useDisclosure({ isOpen: false });
  const [selectedItem, setSelectedItem] = useState({});
  const toast = useToast();
  const history = useHistory();
  const [value, setValue] = useState({
    title: "",
    note: "",
  });

  const {
    storeData: {
      data: { id: storeId },
    },
  } = useContext(StoreContext);

  const {
    data: dataGetNotes,
    isLoading: isLoadingGetNotes,
    refetch: refetchGetNotes,
  } = useGetNotes({
    store_id: storeId,
    is_active: true,
  });

  const handleAdd = () => {
    history.push(mobileUrl.STORE_NOTES_FORM, { data: value, mode: "add" });
  };

  const toggleDrawerMenu = (item) => {
    setSelectedItem(item);
    showDisclosure.onOpen();
  };

  const handleDeleteConfirm = (index) => {
    deleteDisclosure.onOpen();
  };

  const handleDelete = () => {
    setGTM("seller_center_section", {
      event_category: "store_settings | notes",
      event_action: "click",
      event_label: "delete",
    });

    const payload = { note_id: selectedItem?.id };
    mutateDeleteNotes({ params: payload });
  };

  const handleEdit = (index) => {
    showDisclosure.onClose();
    const tempSelected = selectedItem;
    history.push(mobileUrl.STORE_NOTES_FORM, {
      data: tempSelected,
      mode: "edit",
    });
  };

  const handlePreview = (index) => {
    showDisclosure.onClose();
    previewDisclosure.onOpen();
    const tempSelected = selectedItem;
    setValue(tempSelected);
  };

  const { mutate: mutateDeleteNotes } = useDeleteNotes({
    onError: (error) => {
      const {
        response: {
          data: { frontend_message: message },
        },
      } = error;
      toast({
        label: message || "Catatan gagal dihapus.",
        placement: "bottom",
        backgroundColor: "red50",
      });
    },
    onSuccess: (data) => {
      const { frontend_message } = data;
      toast({
        label: frontend_message || "Catatan berhasil dihapus.",
        placement: "bottom",
      });
      showDisclosure.onClose();
      deleteDisclosure.onClose();
      refetchGetNotes();
    },
  });

  return (
    <MobileLayout
      header={{
        title: "Catatan Toko",
        isHomepage: true,
        withBack: true,
        withoutGoBack: true,
        onBack: () => history.push("/account"),
        suffix: (
          <Flex alignItems="center" cursor="pointer" onClick={handleAdd}>
            <Icon name="Plus-outline" color="black50" cursor="pointer" />
          </Flex>
        ),
      }}
    >
      {isLoadingGetNotes ? (
        <SkeletonNote itemShow={5} />
      ) : dataGetNotes?.data.length === 0 ? (
        <Empty value={value} />
      ) : (
        <Flex
          flexDirection="column"
          width="100%"
          backgroundColor="black5"
          p="16px"
          gap="16px"
          boxSizing="border-box"
        >
          {dataGetNotes?.data?.map((list, indexNote) => (
            <CardNotes
              key={`location-${indexNote}`}
              {...list}
              item={list}
              length={dataGetNotes?.data?.length}
              handleDelete={() => handleDeleteConfirm(indexNote)}
              handleEdit={() => handleEdit(indexNote)}
              toggleDrawer={toggleDrawerMenu}
            />
          ))}
        </Flex>
      )}

      {/* handle action */}
      <HandleDrawer
        disclosure={showDisclosure}
        handleEdit={handleEdit}
        handlePreview={handlePreview}
        handleDelete={deleteDisclosure}
      />

      <PreviewDrawer disclosure={previewDisclosure} value={value} />

      <DeleteDrawer disclosure={deleteDisclosure} handleDelete={handleDelete} />
    </MobileLayout>
  );
};

export default AccountNote;
