export const DATA_SHOWCASE = [
  {
    label: "Semua Produk",
    value: "all",
  },
  {
    label: "Etalase",
    value: "tagged",
  },
  {
    label: "Di luar Etalase",
    value: "untagged",
  },
];

export const DATA_CATEGORY = [
  {
    label: "Informasi Penjualan",
    description: "Stok, harga produk, SKU, status, dan berat.",
    value: "sales_information",
  },
  {
    label: "Informasi Dasar",
    description:
      "Ubah deskripsi produk, berat, minimum pemesanan, pre order, kondisi, URL gambar, dan URL video.",
    value: "basic_information",
  },
];
