import { Flex, Text, Icon, Divider } from "renos-ui";

const Header = ({ rightIcon, handleBack, title }) => {
  return (
    <Flex flexDirection="column" width="314px">
      <Flex py="16px" justifyContent="space-between" alignItems="center">
        <Flex gap="16px">
          <Icon
            name="Back-outline"
            size={24}
            cursor="pointer"
            onClick={handleBack}
          />
          <Text variant="subtitle" weight="bold">
            {title}
          </Text>
        </Flex>
        {rightIcon && rightIcon}
      </Flex>
      <Divider />
    </Flex>
  );
};

Header.defaultProps = {
  rightIcon: false,
  handleBack: () => {},
};

export default Header;
