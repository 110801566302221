import { useContext, useMemo, useState } from "react";
import FilterRatingContext from "providers/FilterRatingReview";
import {
  StyledFlexInputRounded,
  StyledFlexInputSearch,
  StyledInput,
} from "../styled";
import {
  Badge,
  Box,
  Button,
  Chip,
  Divider,
  Drawer,
  Flex,
  Icon,
  Input,
  Text,
  colors,
  useDebounce,
} from "renos-ui";

const Filter = () => {
  const [searchValue, setSearchValue] = useState("");
  const [isVisibleDrawer, setIsVisibleDrawer] = useState(false);
  const { filter, setFilter, setItems, setIsFiltered } =
    useContext(FilterRatingContext);
  const [selectedFilter, setSelectedFilter] = useState({
    replied: filter.replied,
    scores_in: filter.scores_in,
  });

  const itemFilter = {
    categories: [
      {
        label: "Semua",
        name: "replied",
        value: null,
      },
      {
        label: "Sudah dibalas",
        name: "replied",
        value: true,
      },
      {
        label: "Belum dibalas",
        name: "replied",
        value: false,
      },
    ],
    rating: [
      {
        label: "Semua",
        name: "scores_in",
        value: null,
      },
      {
        label: "5",
        name: "scores_in",
        value: "5",
      },
      {
        label: "4",
        name: "scores_in",
        value: "4",
      },
      {
        label: "3",
        name: "scores_in",
        value: "3",
      },
      {
        label: "2",
        name: "scores_in",
        value: "2",
      },
      {
        label: "1",
        name: "scores_in",
        value: "1",
      },
    ],
  };

  useDebounce(
    () =>
      setFilter({
        ...filter,
        product_name_contains: searchValue,
        page: 1,
      }),
    [searchValue],
    500
  );

  const handleChange = (name, value) => {
    setSelectedFilter((prev) => ({
      ...prev,
      [name]: value,
      page: 1,
    }));
  };

  const handleClickReset = () => {
    setSelectedFilter((prev) => ({
      ...prev,
      page: 1,
      scores_in: null,
      replied: null,
    }));
  };

  const handleClickFilter = () => {
    setFilter({
      ...filter,
      page: 1,
      replied: selectedFilter.replied,
      scores_in: selectedFilter.scores_in,
    });

    setIsFiltered(true);

    if (
      filter.replied !== selectedFilter.replied ||
      filter.scores_in !== selectedFilter.scores_in
    ) {
      setItems([]);
    }

    setIsVisibleDrawer(false);
  };

  const handleOnClose = () => {
    setSelectedFilter((prev) => ({
      ...prev,
      scores_in: filter.scores_in,
      replied: filter.replied,
    }));

    setIsVisibleDrawer(false);
  };

  const countActiveFilter = useMemo(() => {
    let count = 0;
    if (filter.replied !== null) count++;
    if (filter.scores_in !== null) count++;

    return count;
  }, [filter.replied, filter.scores_in]);

  const onChangeSearch = ({ target: { value } }) => {
    setSearchValue(value);
    setIsFiltered(true);
  };

  const handleClickStar = (star) => {
    if (star === null) {
      handleChange("scores_in", null);

      return;
    }
    const starRatingsArray = (selectedFilter?.scores_in || "")
      .split(",")
      .filter((item) => item !== "");

    if (starRatingsArray.includes(star)) {
      const index = starRatingsArray.indexOf(star);
      starRatingsArray.splice(index, 1);
    } else {
      starRatingsArray.push(star);
    }

    starRatingsArray.sort(
      (firstRating, secondRating) => firstRating - secondRating
    );

    const startRatingString = starRatingsArray.join(",");
    handleChange("scores_in", startRatingString);
  };

  const getActiveStar = (star) => {
    if (star === null) {
      return selectedFilter.scores_in === null;
    }

    const starString = star?.toString();
    const starRatingsArray = (selectedFilter.scores_in || "")
      .split(",")
      .filter((item) => item !== "");

    return starRatingsArray.includes(starString);
  };

  return (
    <>
      <Flex
        gap="8px"
        marginX="16px"
        flexDirection="row"
        justifyContent="space-between"
      >
        <StyledFlexInputSearch width="75%" borderRadius="100px" height="40px">
          <Input
            size="small"
            placeholder="Cari nama produk"
            prefix={<Icon name="Search-outline" size={17} color="black50" />}
            onChange={onChangeSearch}
          />
        </StyledFlexInputSearch>
        <StyledFlexInputRounded
          width="25%"
          borderRadius="100px"
          onClick={() => setIsVisibleDrawer(true)}
        >
          <StyledInput
            readOnly
            size="small"
            placeholder="Filter"
            prefix={
              countActiveFilter === 0 ? (
                <Icon name="Filter-solid" size={17} color="black50" />
              ) : (
                <Badge
                  mt="8px"
                  ml="8px"
                  backgroundColor="blue50"
                  count={countActiveFilter}
                />
              )
            }
          />
        </StyledFlexInputRounded>
      </Flex>
      <Drawer
        isVisible={isVisibleDrawer}
        closable={false}
        onClose={handleOnClose}
        placement="bottom"
      >
        <Box margin="-16px">
          <Flex justifyContent="center" px="16px" pt="16px" pb="8px">
            <Flex
              width="48px"
              height="4px"
              borderRadius="4px"
              backgroundColor="black25"
            />
          </Flex>
          <Flex flexDirection="row" justifyContent="space-between" px="16px">
            <Text variant="subtitle" weight="bold">
              Filter
            </Text>
            <Text
              variant="body"
              color="blue50"
              weight="bold"
              onClick={handleClickReset}
            >
              Reset
            </Text>
          </Flex>
          <Divider mt="10px" backgroundColor="black5" />
          <Box p="16px">
            <Text variant="body" weight="bold">
              Kategori
            </Text>
            <Flex flexDirection="row" gap="8px" pt="8px">
              {itemFilter?.categories?.map((category, index) => (
                <Chip
                  key={`chips-category-${index}`}
                  label={category.label}
                  borderRadius="100px"
                  size="small"
                  onClick={() => handleChange(category.name, category.value)}
                  backgroundColor={
                    category.value === selectedFilter.replied
                      ? "blue5"
                      : "white"
                  }
                  color={
                    category.value === selectedFilter.replied
                      ? "blue50"
                      : "black75"
                  }
                  borderColor={
                    category.value === selectedFilter.replied
                      ? "blue50"
                      : "black10"
                  }
                />
              ))}
            </Flex>
          </Box>
          <Divider pt="8px" backgroundColor="black5" />
          <Box px="16px" pt="16px">
            <Text variant="body" weight="bold">
              Rating
            </Text>
            <Flex flexWrap="wrap" gap="8px" py="16px">
              {itemFilter?.rating?.map((rating, index) => (
                <Button
                  key={`chips-rating-${index}`}
                  variant="secondary"
                  size="small"
                  onClick={() => handleClickStar(rating.value)}
                  style={{
                    borderRadius: "100px",
                    fontWeight: "normal",
                    backgroundColor: getActiveStar(rating.value)
                      ? colors.blue5
                      : colors.white,
                    color: getActiveStar(rating.value)
                      ? colors.blue50
                      : colors.black75,
                    border: `1px solid ${
                      getActiveStar(rating.value)
                        ? colors.blue50
                        : colors.black10
                    }`,
                  }}
                >
                  {index !== 0 && (
                    <Icon name="Star-solid" size={14} color="yellow50" />
                  )}
                  {rating.label}
                </Button>
              ))}
            </Flex>
          </Box>
          <Divider mt="5px" mb="14px" backgroundColor="black5" />
          <Box px="16px" pb="16px">
            <Button isBlock size="medium" onClick={handleClickFilter}>
              Terapkan
            </Button>
          </Box>
        </Box>
      </Drawer>
    </>
  );
};

export default Filter;
