import { Flex, Icon, Spinner, useDisclosure, useToast } from "renos-ui";
import { StyledButton, StyledButtonIcon } from "../styled";
import PopupTracking from "./PopupTracking";
import PopupSellerFleetArrive from "./PopupSellerFleetArrive";
import { useRequestPickupInstant } from "services/order-management";
import Popup3pl from "../SendOrder/Popup3pl";
import PopupMore from "../SendOrder/PopupMore";

const OnDelivery = ({ data, toFirstPage }) => {
  const toast = useToast();
  const { store_order_shipment, shipping, order_id } = data;
  const disclosureTrack = useDisclosure({ isOpen: false });
  const disclosureArrive = useDisclosure({ isOpen: false });
  const disclosure3pl = useDisclosure({ isOpen: false });
  const disclosureMore = useDisclosure({ isOpen: false });

  const { mutate: requestPickupInstant, isLoading: isLoadingRequestPickup } =
    useRequestPickupInstant({
      onSuccess: (data) => {
        const { message, frontend_message } = data;
        toFirstPage();
        toast({
          label: frontend_message || message || "Request pickup berhasil",
          placement: "bottom",
        });
      },
      onError: (data) => {
        const {
          response: {
            data: { frontend_message, message },
          },
        } = data;
        toast({
          label: frontend_message || message || "Request pickup gagal",
          placement: "bottom",
          backgroundColor: "red50",
        });
      },
    });

  const sendOrderCheck = () => {
    if (store_order_shipment.request_pickup_datetime_possible) {
      disclosure3pl.onOpen();
    } else {
      handleRequestPickupInstant();
    }
  };

  const handleRequestPickupInstant = () => {
    const params = {
      order_id: order_id,
      pickup: true,
      awb: "",
    };
    requestPickupInstant(params);
  };

  return (
    <Flex gap="8px" alignItems="center">
      {store_order_shipment.retry_booking_possible ? (
        <StyledButton
          size="small"
          onClick={sendOrderCheck}
          disabled={isLoadingRequestPickup}
          preffix={
            isLoadingRequestPickup && <Spinner color="white" size="8px" />
          }
        >
          Request Ulang
        </StyledButton>
      ) : shipping?.is_seller_fleet ? (
        <StyledButton size="small" onClick={disclosureArrive.onOpen}>
          Pesanan Sampai
        </StyledButton>
      ) : (
        <StyledButton size="small" onClick={disclosureTrack.onOpen}>
          Lacak
        </StyledButton>
      )}
      <StyledButtonIcon
        variant="tertiary"
        size="small"
        onClick={disclosureMore.onOpen}
        icon={<Icon size={20} name="Vertical-outline" />}
      />

      <PopupTracking disclosure={disclosureTrack} orderId={order_id} />
      <PopupSellerFleetArrive
        disclosure={disclosureArrive}
        orderId={order_id}
        toFirstPage={toFirstPage}
      />
      <Popup3pl
        disclosure3pl={disclosure3pl}
        toFirstPage={toFirstPage}
        orderId={order_id}
      />
      <PopupMore disclosure={disclosureMore} data={data} />
    </Flex>
  );
};

export default OnDelivery;
