import { Flex, Icon } from "renos-ui";
import { InputStepper } from "./styled";

const Stepper = ({ value, setValue, disabled, min = 0, max = 99999 }) => {
  const handlePlus = () => {
    if (value !== max) setValue(value + 1);
  };

  const handleMinus = () => {
    if (value !== min) setValue(value - 1);
  };

  const handleInput = (value) => {
    setValue(value);
  };

  return (
    <Flex gap="4px" alignItems="center">
      <Icon
        name="Minus-solid"
        size={20}
        color={value === min || disabled ? "black25" : "blue50"}
        onClick={() => !disabled && handleMinus()}
      />
      <Flex>
        <InputStepper
          size="small"
          value={value}
          max={max}
          min={min}
          onChange={handleInput}
        />
      </Flex>
      <Icon
        name="Plus-solid"
        size={20}
        color={value === max || disabled ? "black25" : "blue50"}
        onClick={() => !disabled && handlePlus()}
      />
    </Flex>
  );
};

export default Stepper;
