import React from "react";
import {
  Drawer,
  Flex,
  Text,
  Icon,
  Input,
  Checkbox,
  Button,
  isEmpty,
} from "renos-ui";
import EmptyState from "../components/EmptyState";
import { StyledFlexInputSearch } from "../components/styled";
import useModalProvince from "./hooks/useModalProvince";
import LoadingSpinner from "../components/LoadingSpinner";

const ModalProvince = ({
  isOpen,
  onClose,
  selectedIndex,
  submitProvince,
  isLoadingProvince,
}) => {
  const {
    dataSearch,
    dataModal,
    handleChangeCheckBox,
    handleSearch,
    handleSave,
  } = useModalProvince({
    isOpen,
    onClose,
    selectedIndex,
    submitProvince,
    isLoadingProvince,
  });

  return (
    <Drawer
      isVisible={isOpen}
      onClose={onClose}
      isFullPage
      padding="0"
      header={{
        render: (
          <Flex
            flexDirection="row"
            alignItems="center"
            gap="8px"
            padding="16px"
          >
            <Icon
              name="Back-outline"
              size="24"
              color="black50"
              onClick={onClose}
            />
            <StyledFlexInputSearch width="100%" borderRadius="100%">
              <Input
                size="small"
                placeholder="Cari nama provinsi"
                prefix={
                  <Icon name="Search-outline" size={16} color="black50" />
                }
                onChange={(event) => handleSearch(event)}
              />
            </StyledFlexInputSearch>
          </Flex>
        ),
      }}
    >
      <Flex
        flexDirection="column"
        gap="16px"
        padding="0 16px"
        height="calc(100% - 130px)"
        overflow="auto"
      >
        {isLoadingProvince ? (
          <LoadingSpinner />
        ) : dataSearch?.length === 0 ? (
          <EmptyState />
        ) : (
          dataSearch?.map((province, indexProvince) => (
            <Flex
              gap="8px"
              alignItems="center"
              key={`province-box-${indexProvince}`}
            >
              <Checkbox
                onChange={() => handleChangeCheckBox(province)}
                checked={
                  dataModal?.selectedProvince?.province_id ===
                  province.province_id
                }
              />
              <Text color="black75" lineClamp={1}>
                {province.province_name}
              </Text>
            </Flex>
          ))
        )}
      </Flex>
      <Flex padding="16px">
        <Button
          isBlock
          size="medium"
          onClick={handleSave}
          disabled={isEmpty(dataModal.selectedProvince)}
        >
          Simpan
        </Button>
      </Flex>
    </Drawer>
  );
};

export default ModalProvince;
