import { useEffect, useMemo, useState } from "react";
import { isEmpty } from "renos-ui";
import { useGetSellerFleetCategories } from "services/logistics/sellerFleet";

const useModalCategories = ({
  isOpen,
  onClose,
  dataCategories,
  setDataCategories,
}) => {
  const [dataModal, setDataModal] = useState({
    changed: false,
    categories: [],
  });

  const [countActive, setCountActive] = useState(false);

  const { isLoading, refetch, isRefetching } = useGetSellerFleetCategories({
    onSuccess: ({ data }) => {
      if (!isEmpty(data)) {
        if (dataCategories.length === 0)
          setDataModal({ changed: false, categories: [...data.categories] });
      }
    },
  });

  const isLoadingAll = useMemo(
    () => isLoading || isRefetching,
    [isLoading, isRefetching]
  );

  const handleOnChangeCheckbox = (categoryId) => {
    const tempCategories = dataModal.categories.map((dataCategories) => ({
      ...dataCategories,
      categories: dataCategories.categories.map((dataCategoriesLvl2) => ({
        ...dataCategoriesLvl2,
        categories: dataCategoriesLvl2.categories.map((dataCategoriesLvl3) => ({
          ...dataCategoriesLvl3,
          active:
            dataCategoriesLvl3.category_id === categoryId
              ? !dataCategoriesLvl3.active
              : dataCategoriesLvl3.active,
        })),
      })),
    }));

    const hasActiveItems = tempCategories.some((dataCategories) =>
      dataCategories.categories.some((dataCategoriesLvl2) =>
        dataCategoriesLvl2.categories.some(
          (dataCategoriesLvl3) => dataCategoriesLvl3.active
        )
      )
    );

    setCountActive(hasActiveItems);

    setDataModal({ changed: true, categories: [...tempCategories] });
  };

  const handleSave = () => {
    setDataCategories([...dataModal.categories]);
    onClose();
  };

  const resetCategories = () => {
    setDataCategories([]);
  };

  const handleIsAllChecked = (dataCategories) => {
    const totalActiveCategory = dataCategories.filter(
      (categories) => categories.active
    ).length;
    const checkResult = {
      active: false,
      indeterminate: false,
    };
    if (
      totalActiveCategory === dataCategories.length &&
      dataCategories.length !== 0
    )
      checkResult.active = true;
    if (totalActiveCategory > 0 && !checkResult.active)
      checkResult.indeterminate = true;

    return checkResult;
  };

  const handleCheckAll = (indexCategories, indexCategoriesLvl2) => {
    const tempCategories = dataModal.categories;
    let categoriesLvl3 =
      tempCategories[indexCategories].categories[indexCategoriesLvl2]
        .categories;
    const filterActive = categoriesLvl3.filter(
      (categories) => categories.active
    );

    if (filterActive.length === categoriesLvl3.length) {
      categoriesLvl3 = categoriesLvl3.map((categories) => ({
        ...categories,
        active: false,
      }));
    } else {
      categoriesLvl3 = categoriesLvl3.map((categories) => ({
        ...categories,
        active: true,
      }));
    }

    tempCategories[indexCategories].categories[indexCategoriesLvl2].categories =
      categoriesLvl3;

    const hasActiveItems = tempCategories.some((dataCategories) =>
      dataCategories.categories.some((dataCategoriesLvl2) =>
        dataCategoriesLvl2.categories.some(
          (dataCategoriesLvl3) => dataCategoriesLvl3.active
        )
      )
    );

    setCountActive(hasActiveItems);

    setDataModal({ changed: true, categories: [...tempCategories] });
  };

  useEffect(() => {
    setDataModal({ categories: dataCategories });

    if (dataCategories.length === 0) refetch(); //eslint-disable-next-line
  }, [isOpen]);

  return {
    dataModal,
    isLoadingAll,
    handleOnChangeCheckbox,
    handleCheckAll,
    handleIsAllChecked,
    handleSave,
    countActive,
    resetCategories
  };
};

export default useModalCategories;