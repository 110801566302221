import desktopUrl from "constants/desktopUrl";
import StoreLayoutContext from "providers/StoreLayoutProvider";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Flex, Button, Divider, useToast, range } from "renos-ui";
import { useGetProductsV3 } from "services/product";
import { useUpdateHomePage } from "services/store-layout/homepage";
import Header from "../component/Header";
import NoData from "../component/NoData";
import ProductCard from "../component/ProductCard";
import ProductCardSkeleton from "../component/ProductCardSkeleton";
import { StyledToggle } from "containers/web/store-layout/styled";
import dayjs from "dayjs";

const NewProduct = ({ handleBack, id }) => {
  const toast = useToast();
  const { push } = useHistory();
  const dayEnd = new Date();
  const dayStart = new Date(new Date().setDate(dayEnd.getDate() - 30));
  const [dataNewProduct, setDataNewProduct] = useState({});
  const maxProduct = 12;

  const { dataHomePage, setDataHomePage } = useContext(StoreLayoutContext);

  const { data: dataProduct, isLoading: isProductLoading } = useGetProductsV3({
    order_by: "upload_date",
    page: 1,
    product_status_ids: 1,
    upload_start_date: dayjs(dayStart).format("YYYY-MM-DD"),
    upload_end_date: dayjs(dayEnd).format("YYYY-MM-DD"),
    limit: maxProduct,
  });

  const { mutate, isLoading: isUpdateHomePageLoading } = useUpdateHomePage({
    onSuccess: (data) => {
      const { message, frontend_message } = data;
      toast({
        label: frontend_message || message,
        placement: "top",
      });
      handleBack();
    },
    onError: (data) => {
      const {
        response: {
          data: { frontend_message, message },
        },
      } = data;
      toast({
        label: frontend_message || message,
        placement: "top",
        backgroundColor: "red50",
      });
    },
  });

  const handleToggle = () => {
    const tempDataHomePage = dataHomePage.map((homepage) => ({
      ...homepage,
      isActive: homepage.id === id ? !homepage.isActive : homepage.isActive,
    }));
    setDataHomePage([...tempDataHomePage]);
  };

  const handleSave = () => {
    mutate({
      type: id,
      ids: dataNewProduct.isActive ? [1] : [],
    });
  };

  useEffect(() => {
    const selectedNewPrduct = dataHomePage.filter(
      (homepage) => homepage.id === id
    )[0];
    setDataNewProduct(selectedNewPrduct);
    // eslint-disable-next-line
  }, [dataHomePage]);

  return (
    <Flex flexDirection="column" gap="16px">
      <Header
        rightIcon={
          dataProduct?.data.length !== 0 && (
            <Flex onClick={handleToggle}>
              <StyledToggle checked={dataNewProduct.isActive} />
            </Flex>
          )
        }
        handleBack={handleBack}
        title="Produk Terbaru"
      />
      {dataProduct?.data.length === 0 ? (
        <NoData
          title="Belum Ada Produk Terbaru"
          description="Yuk, tambahkan produk terbaru terlebih dahulu."
          btnText="Tambah Produk"
          onClick={() => push(desktopUrl.PRODUCTS_ADD)}
        />
      ) : (
        <>
          <Flex
            height="284px"
            overflowY="auto"
            flexDirection="column"
            width="100%"
            gap="16px"
          >
            {isProductLoading
              ? range(3).map((_, indexSkeleton) => (
                  <ProductCardSkeleton
                    key={`product-card-skeleton${indexSkeleton}`}
                  />
                ))
              : //eslint-disable-next-line
                dataProduct.data.map((product, indexProduct) => {
                  if (indexProduct < maxProduct)
                    return (
                      <React.Fragment key={`product-card-${indexProduct}`}>
                        <ProductCard data={product} />
                        {indexProduct < 11 &&
                          indexProduct !== dataProduct.data.length && (
                            <Flex>
                              <Divider />
                            </Flex>
                          )}
                      </React.Fragment>
                    );
                })}
          </Flex>
          <Divider />
          <Flex gap="16px" paddingBottom="24px">
            <Flex width="152px">
              <Button
                size="medium"
                variant="tertiary"
                isBlock
                onClick={handleBack}
              >
                Batalkan
              </Button>
            </Flex>
            <Flex width="152px">
              <Button
                size="medium"
                isBlock
                disabled={isUpdateHomePageLoading}
                onClick={handleSave}
              >
                Simpan
              </Button>
            </Flex>
          </Flex>
        </>
      )}
    </Flex>
  );
};

export default NewProduct;
