import axios from "axios";
import Cookies from "universal-cookie";
const cookies = new Cookies();

axios.defaults.withCredentials = true;

const client = axios.create({
  baseURL: process.env.REACT_APP_API,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

client.interceptors.response.use(
  function (response) {
    if (response.data.data && response.data.data.token) {
      cookies.set("session", response.data.data.token, {
        path: "/",
      });
    }

    return response;
  },
  (error) => {
    const notAllowedPath = ["/seller-landing"];

    if (
      (error.response.status === 401 || error.response.status === 403) &&
      !notAllowedPath.includes(window.location.pathname)
    ) {
      window.location.pathname = "/seller-landing";
    }

    return Promise.reject(error);
  }
);

export default client;
