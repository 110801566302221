import { Drawer, Flex, Text, Button, Spinner } from "renos-ui";

const DeleteDrawer = ({ disclosure, handleDelete, isLoadingDelete }) => {
  return (
    <Drawer
      isVisible={disclosure.isOpen}
      closable={true}
      onClose={() => disclosure.onClose()}
      placement="bottom"
    >
      <Flex flexDirection="column" width="100%" gap="24px">
        <Flex flexDirection="column" gap="8px">
          <Text variant="heading3" weight="bold">
            Hapus Lokasi
          </Text>
          <Text>Lokasi toko yang dipilih akan dihapus.</Text>
        </Flex>
        <Flex justifyContent="space-between" gap="16px">
          <Button
            isBlock
            variant="tertiary"
            onClick={() => disclosure.onClose()}
          >
            Tidak, Batal
          </Button>
          <Button
            isBlock
            disabled={isLoadingDelete}
            onClick={handleDelete}
            preffix={isLoadingDelete && <Spinner color="white" size="8px" />}
          >
            Ya, Lanjut
          </Button>
        </Flex>
      </Flex>
    </Drawer>
  );
};

export default DeleteDrawer;
