import { Box, Button, Flex, Text } from "renos-ui";
import { useHistory } from "react-router-dom";
import desktopUrl from "constants/desktopUrl";

const Empty = () => {
  const { push } = useHistory();

  return (
    <Flex
      marginTop="40px"
      flexDirection="column"
      alignItems="center"
      rowGap="16px"
    >
      <img
        src="/assets/images/mobile/no-product.svg"
        alt="no-product-img"
        width="180px"
        height="180px"
      />
      <Flex
        flexDirection="column"
        rowGap="4px"
        textAlign="center"
        alignItems="center"
      >
        <Text weight="bold">Belum ada produk</Text>
        <Text color="black75" variant="caption" width="280px">
          Yuk tambahkan produk ke tokomu agar segera bisa mulai jualan
        </Text>
      </Flex>
      <Box width="280px" marginTop="8px">
        <Button isBlock onClick={() => push(desktopUrl.PRODUCTS_ADD)}>
          Tambah Produk
        </Button>
      </Box>
    </Flex>
  );
};

export default Empty;
