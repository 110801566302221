import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Box, Flex, Icon, Text } from "renos-ui";
import CustomDatepicker from "./CustomDatepicker";
import customParseFormat from "dayjs/plugin/customParseFormat";
import dayjs from "dayjs";
import { useMemo } from "react";
dayjs.extend(customParseFormat);

const CustomHeader = ({ today, selectedDate, setSelectedDate }) => {
  const { goBack } = useHistory();

  const formattedDate = useMemo(() => {
    if (today === true) {
      return "Hari Ini " + dayjs(selectedDate.startDate).format("DD MMMM YYYY");
    } else if (selectedDate.type === "daily") {
      return dayjs(selectedDate.startDate).format("DD MMMM YYYY");
    } else {
      return `${dayjs(selectedDate.startDate).format("DD MMMM YYYY")} - ${dayjs(
        selectedDate.endDate
      ).format("DD MMMM YYYY")} `;
    }
    // eslint-disable-next-line
  }, [selectedDate]);

  return (
    <Box
      p="16px"
      rowGap="16px"
      height="fit-content"
      flexDirection="column"
      boxShadow={"0 0 8px rgba(20, 20, 32, 0.4)"}
    >
      <Flex justifyContent="space-between" alignItems="center">
        <Flex gap="16px" alignItems="center">
          <Icon
            cursor="pointer"
            onClick={() => {
              goBack();
            }}
            name="Back-outline"
            color="black50"
            size={24}
          />

          <Flex flexDirection="column">
            <Text weight="bold" color="black100" variant="subtitle">
              Data Statistik
            </Text>
            <Flex alignItems="center" gap="4px">
              <Text variant="caption" color="black50">
                {formattedDate}
              </Text>
            </Flex>
          </Flex>
        </Flex>
        <CustomDatepicker value={selectedDate} setValue={setSelectedDate} />
      </Flex>
    </Box>
  );
};

export default CustomHeader;
