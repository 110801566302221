import { useMemo, useState } from "react";
import {
  Box,
  Checkbox,
  Flex,
  Icon,
  Input,
  Skeleton,
  Text,
  range,
} from "renos-ui";
import { useGetCourier } from "services/promo-management";
import FailLoadData from "widgets/mobile/FailLoadData";

const Courier = ({ value, setValue }) => {
  const [searchValue, setSearchValue] = useState("");

  const { data, isLoading, isError, refetch, isRefetching } = useGetCourier();

  const isChecked = (id) => {
    return value.map((val) => val.value).includes(id);
  };

  const handleCheck = (newValue) => {
    let tempValue = value;

    if (isChecked(newValue.id)) {
      tempValue = tempValue.filter(
        (dataValue) => dataValue.value !== newValue.id
      );
    } else {
      tempValue.push({ label: newValue.name, value: newValue.id });
    }

    setValue([...tempValue]);
  };

  const isLoadingAll = useMemo(
    () => isLoading || isRefetching,
    [isLoading, isRefetching]
  );

  const dataSource = useMemo(() => {
    let tempDataSource = data?.data || [];

    if (searchValue) {
      const filteredCouriers = [];
      // eslint-disable-next-line
      const filterCouriers = tempDataSource.map((courier) => {
        if (
          courier.name
            .toLocaleLowerCase()
            .includes(searchValue.toLocaleLowerCase())
        ) {
          filteredCouriers.push(courier);
        } else {
          const tempServices = [];
          // eslint-disable-next-line
          const filterServices = courier.services.map((service) => {
            if (
              service.name
                .toLocaleLowerCase()
                .includes(searchValue.toLocaleLowerCase())
            ) {
              tempServices.push(service);
            }
          });

          if (tempServices.length !== 0) {
            filteredCouriers.push({ ...courier, services: tempServices });
          }
        }
      });

      tempDataSource = filteredCouriers;
    }

    return tempDataSource;
  }, [data, searchValue]);

  return (
    <Flex flexDirection="column" gap="24px" height="293px" overflow="auto">
      <Flex flexDirection="column" gap="12px">
        <Text weight="bold" color="black75">
          Pilih Jasa Pengiriman
        </Text>

        <Input
          size="small"
          prefix={<Icon name="Search-outline" size={20} />}
          placeholder="Cari nama layanan pengiriman"
          onChange={(event) => setSearchValue(event.target.value)}
          value={searchValue}
        />
      </Flex>
      {isLoadingAll ? (
        <Flex flexWrap="wrap" rowGap="24px" columnGap="16px">
          {range(2).map((_, indexSkeleton) => (
            <Flex
              flexDirection="column"
              gap="16px"
              width="200px"
              key={`courier-${indexSkeleton}`}
            >
              <Skeleton height="20px" width="150px" />
              <Flex flexDirection="column" gap="12px">
                {range(2).map((_, indexSkeletonService) => (
                  <Flex
                    gap="8px"
                    alignItems="center"
                    key={`service-${indexSkeletonService}`}
                  >
                    <Skeleton height="20px" width="20px" />
                    <Skeleton height="20px" width="120px" />
                  </Flex>
                ))}
              </Flex>
            </Flex>
          ))}
        </Flex>
      ) : isError ? (
        <FailLoadData refetch={refetch} noImg={true} />
      ) : dataSource.length === 0 ? (
        <Box textAlign="center" width="100%">
          <Text variant="caption" color="black75">
            Jasa pengiriman tidak tidak ditemukan
          </Text>
        </Box>
      ) : (
        <Flex flexWrap="wrap" rowGap="24px" columnGap="16px">
          {dataSource.map((courier, indexCourier) => (
            <Flex
              flexDirection="column"
              gap="16px"
              width="200px"
              key={`courier-${indexCourier}`}
            >
              <Text variant="caption" weight="bold" color="black50">
                {courier.name}
              </Text>
              <Flex flexDirection="column" gap="12px">
                {courier.services.map((service, indexService) => (
                  <Flex
                    gap="8px"
                    alignItems="center"
                    key={`service-${indexService}`}
                  >
                    <Checkbox
                      checked={isChecked(service.id)}
                      onChange={() => handleCheck(service)}
                    />
                    <Text variant="caption" color="black75">
                      {service.name}
                    </Text>
                  </Flex>
                ))}
              </Flex>
            </Flex>
          ))}
        </Flex>
      )}
    </Flex>
  );
};

export default Courier;
