import { Box, Flex, Text, Icon, colors } from "renos-ui";
import { useGetShippingInformation } from "services/logistics/courier";
const baseImgUrl = process.env.REACT_APP_ASSETS_IMAGE;

const DeliveryInformation = () => {
  const { data: shippingInformationData } = useGetShippingInformation();

  return (
    <Flex gap="24px" flexDirection="column">
      <Flex gap="8px" alignItems="center">
        <Icon name="Info-outline" />
        <Text variant="heading3" color="black100" weight="bold">
          Informasi Layanan Pengiriman
        </Text>
      </Flex>
      <Flex
        flexDirection="column"
        backgroundColor="white"
        boxShadow="0px 4px 4px rgba(20, 20, 32, 0.05)"
        borderRadius="16px"
        px="24px"
      >
        {shippingInformationData?.data?.map((info, index) => {
          const isWithBorderBottom =
            index + 1 !== shippingInformationData.data.length;

          return (
            <Flex
              key={`container-${index}`}
              gap="16px"
              flexDirection="column"
              borderBottom={isWithBorderBottom && `1px solid ${colors.black10}`}
              padding="24px 0"
            >
              <Flex flexDirection="column">
                <Text variant="subtitle" color="black75" weight="bold">
                  {info.courier_group_name}
                </Text>
                <Text color="black75">{info.description}</Text>
              </Flex>
              <Flex flexDirection="column">
                {info?.courier_services?.length > 0 && (
                  <Text variant="caption" color="black50">
                    Tersedia pada layanan:
                  </Text>
                )}
                <Flex>
                  {info.courier_services.map((services, serviceIndex) => {
                    const isLast =
                      serviceIndex + 1 === info.courier_services.length;

                    return (
                      <Flex
                        padding="8px"
                        border={`1px solid ${colors.black10}`}
                        borderRight={isLast && `1px solid ${colors.black10}`}
                        alignItems="center"
                        gap="8px"
                      >
                        <Box
                          maxWidth="32px"
                          maxHeight="16px"
                          as="img"
                          src={`${baseImgUrl + "/"}${services?.courier_image_url
                            }`}
                          alt="delivery-img"
                        />
                        <Text variant="caption" color="black75" weight="bold">
                          {services.courier_name}
                        </Text>
                      </Flex>
                    );
                  })}
                </Flex>
              </Flex>
            </Flex>
          );
        })}
      </Flex>
    </Flex>
  );
};

export default DeliveryInformation;
