import ENDPOINTS from "constants/endpoints";
import { useMutation, useQuery } from "react-query";
import client from "../client";

const getOrderStatus = async (params) => {
  const { data } = await client.get(ENDPOINTS.order_status, { params });

  return data;
};

export const useGetOrderStatus = (params, extraParams) => {
  return useQuery(["getOrderStatus"], () => getOrderStatus(params), {
    ...extraParams,
  });
};

const getOrder = async (params) => {
  const version = params.version || "";
  const { data } = await client.get(ENDPOINTS[`orders${version}`], {
    params: { ...params, version: undefined },
  });

  return data;
};

export const useGetOrder = (params, extraParams) => {
  return useQuery(["getOrder", params], () => getOrder(params), {
    ...extraParams,
  });
};

const getOrderDetail = async (params) => {
  const { data } = await client.get(ENDPOINTS.orders, {
    params,
  });

  return data;
};

export const useGetOrderDetail = (extraParams) =>
  useMutation((body) => getOrderDetail(body), {
    ...extraParams,
  });

const getListFilterCourier = async (params) => {
  const { data } = await client.get(ENDPOINTS.list_filter_courier, { params });

  return data;
};

export const useGetListFilterCourier = (params, extraParams) => {
  return useQuery(
    ["getListFilterCouirer"],
    () => getListFilterCourier(params),
    {
      ...extraParams,
    }
  );
};

const downloadSalesReport = async (params) => {
  const { data } = await client.get(ENDPOINTS.download_sales_report, {
    params,
  });

  return data;
};

export const useDownloadSalesReport = (extraParams) =>
  useMutation((body) => downloadSalesReport(body), {
    ...extraParams,
  });

const confirmOrder = async (body) => {
  const { data } = await client.post(ENDPOINTS.order_confirm, body, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  return data;
};

export const useConfirmOrder = (extraParams) =>
  useMutation((body) => confirmOrder(body), {
    ...extraParams,
  });

const rejectOrder = async (body) => {
  const { data } = await client.post(ENDPOINTS.order_reject, body, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  return data;
};

export const useRejectOrder = (extraParams) =>
  useMutation((body) => rejectOrder(body), {
    ...extraParams,
  });

const getOrderStatusHistory = async (params) => {
  const { data } = await client.get(ENDPOINTS.store_order_history, { params });

  return data;
};

export const useGetOrderStatusHistory = (params, extraParams) => {
  return useQuery(
    ["getOrderStatusHistory", params],
    () => getOrderStatusHistory(params),
    {
      ...extraParams,
      enabled: false,
    }
  );
};

const trackOrder = async (params) => {
  const { data } = await client.get(ENDPOINTS.tracking_order, { params });

  return data;
};

export const useTrackOrder = (params, extraParams) => {
  return useQuery(["trackOrder", params], () => trackOrder(params), {
    ...extraParams,
    enabled: false,
  });
};

const sendOrder = async (body) => {
  const { data } = await client.post(
    ENDPOINTS.order_seller_fleet_delivery_order,
    body,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );

  return data;
};

export const useSendOrder = (extraParams) =>
  useMutation((body) => sendOrder(body), {
    ...extraParams,
  });

const arriveOrder = async (body) => {
  const { data } = await client.post(
    ENDPOINTS.order_seller_fleet_delivery_arrive,
    body,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );

  return data;
};

export const useArriveOrder = (extraParams) =>
  useMutation((body) => arriveOrder(body), {
    ...extraParams,
  });

const requestPickupInstant = async (body) => {
  const { data } = await client.post(
    ENDPOINTS.store_order_request_pickup,
    body
  );

  return data;
};

export const useRequestPickupInstant = (extraParams) =>
  useMutation((body) => requestPickupInstant(body), {
    ...extraParams,
  });

const requestPickupWithTime = async (params) => {
  const { data } = await client.get(ENDPOINTS.store_order_pickup_datetime, {
    params,
  });

  return data;
};

export const useRequestPickupWithTime = (params, extraParams) => {
  return useQuery(["trackOrder", params], () => requestPickupWithTime(params), {
    ...extraParams,
    enabled: false,
  });
};

const reRequestPickup = async (body) => {
  const { data } = await client.post(ENDPOINTS.store_order_retry_pickup, body);

  return data;
};

export const useReRequestPickup = (extraParams) =>
  useMutation((body) => reRequestPickup(body), {
    ...extraParams,
  });

const getBarcode = async (params) => {
  const { data } = await client.get(ENDPOINTS.store_order_barcode, {
    params,
  });

  return data;
};

export const useGetBarcode = (params, extraParams) => {
  return useQuery(["getBarcode", params], () => getBarcode(params), {
    ...extraParams,
    enabled: false,
  });
};

const getCourierImg = async (params) => {
  const { data } = await client.get(ENDPOINTS.order_print_label_img_courier, {
    params,
  });

  return data;
};

export const useGetCourierImg = (params, extraParams) => {
  return useQuery(["getCourierImg", params], () => getCourierImg(params), {
    ...extraParams,
    enabled: false,
  });
};

const confirmOrderBulk = async (body) => {
  const { data } = await client.post(ENDPOINTS.order_confirm_bulk, body);

  return data;
};

export const useConfirmOrderBulk = (extraParams) =>
  useMutation((body) => confirmOrderBulk(body), {
    ...extraParams,
  });

const getOrderv3 = async (params) => {
  const { data } = await client.get(ENDPOINTS.ordersv3, { params });

  return data;
};

export const useGetOrderv3 = (params, extraParams) => {
  return useQuery(["getOrder", params], () => getOrderv3(params), {
    ...extraParams,
  });
};
