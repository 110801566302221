import ENDPOINTS from "constants/endpoints";
import { useQuery, useMutation } from "react-query";
import client from "../../client";

const getDocument = async () => {
  const { data } = await client.get(ENDPOINTS.document);

  return data;
};

export const useGetDocument = (extraParams) => {
  return useQuery(["getDocument"], getDocument, {
    ...extraParams,
  });
};

const getDownloadFetcher = async (body) => {
  const { data } = await client.get(ENDPOINTS.download_document, {
    params: body,
    responseType: "blob",
  });

  return data;
};

export const useDownloadDocument = (extraParams) =>
  useMutation((params) => getDownloadFetcher(params), {
    ...extraParams,
  });

const uploadStoreImgFetcher = async (body) => {
  const response = await client.post(ENDPOINTS.upload_document, body, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  return response.data;
};

export const useUploadStoreImg = (extraParams) =>
  useMutation((body) => uploadStoreImgFetcher(body), {
    ...extraParams,
  });

const saveNumberFetcher = async (body) => {
  const response = await client.post(ENDPOINTS.document_number, body);

  return response;
};

export const useSaveNumber = (extraParams) =>
  useMutation((body) => saveNumberFetcher(body), {
    ...extraParams,
  });

const getBank = async () => {
  const { data } = await client.get(ENDPOINTS.bank_master);

  return data;
};

export const useGetBank = (extraParams) => {
  return useQuery(["getBank"], getBank, {
    ...extraParams,
    enabled: true,
  });
};
