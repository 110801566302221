import { Flex, Box, Text, Button } from "renos-ui";
import PropTypes from "prop-types";
import ImgEmptyNotes from "assets/images/notes.png";

const EmptyNote = ({ handleAdd }) => {
  return (
    <Flex
      gap="8px"
      justifyContent="center"
      alignItems="center"
      minHeight="500px"
    >
      <Flex flexDirection="column" alignItems="center" gap="24px">
        <Box
          as="img"
          src={ImgEmptyNotes}
          width="200px"
          height="200px"
          alt="Empty Data"
        />
        <Flex flexDirection="column" alignItems="center" gap="8px">
          <Text variant="heading3" weight="bold" color="black100">
            Belum ada catatan di tokomu
          </Text>
          <Text color="black75">
            Tambahkan catatan untuk memberikan informasi
          </Text>
          <Text color="black75">seperti Kebijakan Toko.</Text>
        </Flex>
        <Button size="large" onClick={handleAdd}>
          Tambah Catatan
        </Button>
      </Flex>
    </Flex>
  );
};

EmptyNote.propTypes = {
  handleAdd: PropTypes.func.isRequired,
};

export default EmptyNote;
