import { Divider, Flex, Text } from "renos-ui";
import { StyledText } from "../styled";

const ProductInfo = ({ products }) => {
  return (
    <Flex flexDirection="column">
      <Flex padding="16px" gap="16px" flexDirection="column">
        <Text weight="bold">Detail Produk</Text>
        {products.map((product, indexProduct) => (
          <Flex
            flexDirection="column"
            gap="16px"
            key={`product-${indexProduct}`}
          >
            <Divider height="5px !important" backgroundColor="black5" />
            <Flex gap="16px">
              <Flex
                as="img"
                height="48px"
                width="48px"
                borderRadius="8px"
                src={product.product_image_url}
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = "/assets/images/empty-product.svg";
                }}
              />
              <Flex flexDirection="column" gap="8px" width="100%">
                <Flex flexDirection="column" gap="4px">
                  <Text variant="caption" color="black75" weight="bold">
                    {product.product_name}
                    {product.variant_name && ` - ${product.variant_name}`}
                  </Text>
                  <Text variant="caption" color="black75">
                    {`${product.order_product_quantity} x ${product.formatted_order_product_unit_price}`}
                  </Text>
                </Flex>
                {product.order_note && (
                  <Flex gap="8px" flexDirection="column">
                    <Divider />
                    <StyledText variant="caption" color="black50">
                      “{product.order_note}”
                    </StyledText>
                  </Flex>
                )}
              </Flex>
            </Flex>
          </Flex>
        ))}
      </Flex>
      <Divider height="8px !important" backgroundColor="black5" />
    </Flex>
  );
};

export default ProductInfo;
