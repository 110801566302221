import StoreLayoutContext from "providers/StoreLayoutProvider";
import { useContext, useEffect, useState } from "react";
import { Flex, Text, range } from "renos-ui";
import SwiperContext, { SwiperProvider } from "providers/SwiperProvider";
import { StyledSwiper } from "./styled";
import { SwiperSlide, useSwiper } from "swiper/react";

const StoreFront = ({ isPreviewMode }) => {
  const { dataHomePage, storeFrontRef } = useContext(StoreLayoutContext);
  const [dataStoreFront, setDataStoreFront] = useState({});
  const [swiperInstance, setSwiperInstance] = useState();

  const convertSpace = (str) => {
    return str ? str.replaceAll(" ", "%20") : str;
  };

  useEffect(() => {
    const selectedNewPrduct = dataHomePage.filter(
      (homepage) => homepage.id === 5
    )[0];
    setDataStoreFront(selectedNewPrduct);
  }, [dataHomePage]);

  const renderCard = (storeFront, indexStoreFront, isMaxTwo) => (
    <Flex
      key={`store-front${indexStoreFront}`}
      height={isMaxTwo ? "110px" : "128px"}
      width={isMaxTwo ? "110px" : "128px"}
      padding="8px"
      borderRadius="8px"
      borderColor="black10"
      borderWidth="1px"
      borderStyle="solid"
      background={`linear-gradient(180.57deg, rgba(20, 20, 32, 0) 50.49%, #141420 99.5%), url(${convertSpace(
        storeFront.tag_image_url
      )})`}
      backgroundSize="contain"
      backgroundRepeat="no-repeat"
      alignItems="end"
    >
      <Text variant="caption" color="white" weight="bold" lineClamp={2}>
        {storeFront.tag_name}
      </Text>
    </Flex>
  );

  if (isPreviewMode && !dataStoreFront?.isActive) return <></>;

  return (
    <Flex gap="16px" flexDirection="column" ref={storeFrontRef}>
      <Text weight="bold">Etalase Pilihan</Text>
      {dataStoreFront?.isActive ? (
        dataStoreFront?.data?.length > 2 ? (
          <SwiperProvider value={{ setSwiperInstance, swiperInstance }}>
            <Flex position="relative">
              <StyledSwiper slidesPerView={2} spaceBetween={8}>
                <Flex>
                  {dataStoreFront?.data.map((storeFront, indexStoreFront) => {
                    return (
                      <SwiperSlide key={`swiper-${indexStoreFront}`}>
                        {renderCard(storeFront, indexStoreFront)}
                      </SwiperSlide>
                    );
                  })}
                </Flex>
                <SwiperInstance />
              </StyledSwiper>
            </Flex>
          </SwiperProvider>
        ) : (
          <Flex gap="8px">
            {dataStoreFront?.data.map((storeFront, indexStoreFront) =>
              renderCard(storeFront, indexStoreFront, true)
            )}
          </Flex>
        )
      ) : (
        <Flex gap="8px">
          {range(2).map((_, indexEmpty) => (
            <Flex
              key={`empty-${indexEmpty}`}
              width="128px"
              height="128px"
              borderRadius="8px"
              backgroundColor="black10"
            />
          ))}
        </Flex>
      )}
    </Flex>
  );
};

const SwiperInstance = () => {
  const { setSwiperInstance } = useContext(SwiperContext);
  const swiper = useSwiper();

  useEffect(() => {
    if (swiper) return setSwiperInstance(swiper);
  }, [swiper, setSwiperInstance]);
};

export default StoreFront;
