import { Flex, Icon, Skeleton, Text, Tooltip, range } from "renos-ui";
import { useGetCategories } from "services/product-category";

const MultipleCategorySelector = ({ value, setValue }) => {
  const { data: categories, isLoading } = useGetCategories();

  const TextButton = ({ text, active, onClick, noIcon }) => (
    <Flex
      onClick={onClick && onClick}
      cursor="pointer"
      justifyContent="space-between"
      width="100%"
      alignItems="center"
    >
      <Tooltip label={text}>
        <Text
          cursor="pointer"
          color={active ? "blue50" : "black75"}
          weight={active && "bold"}
          lineClamp={1}
        >
          {text}
        </Text>
      </Tooltip>
      {!noIcon && (
        <Icon
          name="Right-outline"
          color={active ? "blue50" : "black50"}
          size={20}
        />
      )}
    </Flex>
  );

  return (
    <Flex
      borderColor="black10"
      borderStyle="solid"
      borderWidth="1px"
      borderRadius="8px"
      width="100%"
    >
      <Flex
        width={
          value.selectedOne && value.selectedTwo
            ? "33%"
            : value.selectedOne
              ? "50%"
              : "100%"
        }
        p="16px"
        borderRightColor="black10"
        borderRightStyle="solid"
        borderRightWidth="1px"
        borderRightRadius="8px"
        flexDirection="column"
        gap="16px"
        height="104px"
        overflow="auto"
      >
        {isLoading
          ? range(3).map((_, indexSkeleton) => (
              <Skeleton key={`skeleton-${indexSkeleton}`} height="24px" />
            ))
          : categories?.map((category, indexCategory) => (
              <TextButton
                key={`text-button1-${indexCategory}`}
                text={category.category_name}
                active={
                  value?.selectedOne?.category_id === category?.category_id
                }
                onClick={() =>
                  setValue({
                    ...value,
                    selectedOne: category,
                    selectedTwo: null,
                    selectedThree: null,
                  })
                }
              />
            ))}
      </Flex>
      {value?.selectedOne && !isLoading && (
        <Flex
          width={value.selectedOne && value.selectedTwo ? "33%" : "50%"}
          p="16px"
          borderRightColor="black10"
          borderRightStyle="solid"
          borderRightWidth={
            value.selectedOne && value.selectedTwo ? "1px" : "0"
          }
          borderRightRadius="8px"
          flexDirection="column"
          gap="16px"
          height="104px"
          overflow="auto"
        >
          {value?.selectedOne?.options?.map((category, indexCategory) => (
            <TextButton
              key={`text-button2-${indexCategory}`}
              text={category.category_name}
              active={value?.selectedTwo?.category_id === category?.category_id}
              onClick={() =>
                setValue({
                  ...value,
                  selectedTwo: category,
                  selectedThree: null,
                })
              }
            />
          ))}
        </Flex>
      )}
      {value?.selectedTwo && !isLoading && (
        <Flex
          width="calc(33% + 1px)"
          p="16px"
          gap="16px"
          borderRightRadius="8px"
          flexDirection="column"
          height="104px"
          overflow="auto"
        >
          {value?.selectedTwo?.options?.map((category, indexCategory) => (
            <TextButton
              key={`text-button2-${indexCategory}`}
              text={category.category_name}
              active={
                value?.selectedThree?.category_id === category?.category_id
              }
              onClick={() => setValue({ ...value, selectedThree: category })}
              noIcon
            />
          ))}
        </Flex>
      )}
    </Flex>
  );
};

export default MultipleCategorySelector;
