import { useState, useContext, useEffect } from "react";
import StoreContext from "providers/StoreProvider";
import {
  useDisclosure,
  Flex,
  Text,
  Button,
  Icon,
  useToast,
  range,
  isEmpty,
} from "renos-ui";
import CardLocation from "./components/CardLocation";
import {
  useDeleteLocation,
  useGetLocation,
  useUpdateLocation,
} from "services/location";
import {
  useGetCity,
  useGetDistrict,
  useGetProvince,
  useGetSubDistrict,
} from "services/place";
import FormSubmit from "./FormSubmit";
import { setGTM } from "helpers";
import DialogDelete from "./components/DialogDelete";
import { DEFAULT_FORM } from "./constants";
import SkeletonList from "./components/SkeletonList";
import Each from "widgets/global/Each";

const Location = () => {
  const {
    storeData: {
      data: { id: storeId, name: StoreName },
    },
  } = useContext(StoreContext);

  const toast = useToast();
  const [tempIndex, setTempIndex] = useState("");
  const [value, setValue] = useState({
    ...DEFAULT_FORM,
    store_name: StoreName,
  });
  const [tempSelected, setTempSelected] = useState(null);

  const showForm = useDisclosure({});
  const showConfirmation = useDisclosure({ open: false });

  const {
    data: listLocation,
    isLoading: isLoadingLocation,
    refetch: refetchGetLocation,
  } = useGetLocation({
    store_id: storeId,
    order_by: "is_primary",
    ordering: "desc",
    fetch_all: true,
  });

  const { data: listProvince } = useGetProvince();
  const { data: listCity, mutate: getCity } = useGetCity();
  const { data: listDistrict, mutate: getDistrict } = useGetDistrict();
  const { data: listSubDistrict, mutate: getSubDistrict } = useGetSubDistrict();

  const { mutate: updatePrimary } = useUpdateLocation({
    onError: (error) => {
      const {
        response: {
          data: { frontend_message: message },
        },
      } = error;
      toast({
        label: message || "Alamat gagal diubah.",
        placement: "top",
        backgroundColor: "red50",
      });
    },
    onSuccess: (data) => {
      const { frontend_message } = data;
      toast({
        label: frontend_message || "Alamat berhasil diubah.",
        placement: "top",
      });
      refetchGetLocation();
    },
  });

  const { mutate: deleteLocation, isLoading: isLoadingDelete } =
    useDeleteLocation({
      onError: (error) => {
        const {
          response: {
            data: { frontend_message, message },
          },
        } = error;
        toast({
          label: frontend_message || message || "Alamat gagal dihapus.",
          placement: "top",
          backgroundColor: "red50",
        });
      },
      onSuccess: (data) => {
        const { frontend_message, message } = data;
        toast({
          label: frontend_message || message || "Alamat berhasil dihapus.",
          placement: "top",
        });
        setTempIndex("");
        showConfirmation.onClose();
        refetchGetLocation();
      },
    });

  const handleSetPrimary = (value) => {
    const datas = value;
    datas.is_primary = true;
    updatePrimary({ data: datas });
  };

  const handleDeleteConfirm = (index) => {
    setTempIndex(index);
    showConfirmation.onOpen();
  };

  const handleDelete = () => {
    deleteLocation(listLocation.data[tempIndex]?.id);
  };

  const handleEdit = (index) => {
    setTempIndex(index);
    const tempSelected = listLocation.data[index];

    setTempSelected(tempSelected);
    setValue(tempSelected);
    showForm.onOpen();
  };

  const resetForm = () => {
    setValue({ ...DEFAULT_FORM, store_name: StoreName });
    setTempSelected(null);
    setTempIndex("");
  };

  useEffect(() => {
    if (!isEmpty(value.province_id)) {
      getCity({
        params: {
          province_id: value.province_id,
        },
      });
    }

    if (!isEmpty(value.city_id)) {
      getDistrict({
        params: {
          city_id: value.city_id,
        },
      });
    }

    if (!isEmpty(value.district_id)) {
      getSubDistrict({
        params: {
          district_id: value.district_id,
        },
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <Flex flexDirection="column" width="100%">
      <Flex flexDirection="row" gap="16px" alignItems="center" marginY="24px">
        <Flex flexDirection="column" flexGrow="1">
          <Text variant="subtitle" weight="bold" color="black100">
            Lokasi Toko
          </Text>
          <Text color="black75">
            Atur lokasi toko untuk perhitungan ongkos kirim saat pembeli
            berbelanja di tokomu.
          </Text>
        </Flex>
        <Flex>
          <Button
            onClick={() => {
              setGTM("seller_center_section", {
                event_category: "store_settings | location",
                event_action: "click",
                event_label: "add_location",
              });

              resetForm();
              showForm.onOpen();
            }}
            preffix={
              <Flex style={{ pointerEvents: "none" }}>
                <Icon size={20} name="Plus-outline" />
              </Flex>
            }
          >
            Tambah Lokasi
          </Button>
        </Flex>
      </Flex>

      {isLoadingLocation ? (
        <Flex gap="16px" flexWrap="wrap">
          <Each
            of={range(6)}
            render={(_, index) => <SkeletonList key={index} />}
          />
        </Flex>
      ) : (
        <Flex gap="16px" flexWrap="wrap">
          <Each
            of={listLocation?.data}
            render={(row, idx) => (
              <Flex gap="16px" flexBasis="49%">
                <CardLocation
                  {...row}
                  length={listLocation?.data?.length}
                  handleSetPrimary={() => handleSetPrimary(row)}
                  handleDelete={() => handleDeleteConfirm(idx)}
                  handleEdit={() => handleEdit(idx)}
                />
              </Flex>
            )}
          />
        </Flex>
      )}

      <FormSubmit
        show={showForm.isOpen}
        close={showForm.onClose}
        listLocation={listLocation}
        tempSelected={tempSelected}
        value={value}
        setValue={setValue}
        setTempIndex={setTempIndex}
        setTempSelected={setTempSelected}
        listProvince={listProvince}
        listCity={listCity}
        listDistrict={listDistrict}
        listSubDistrict={listSubDistrict}
        refetchGetLocation={refetchGetLocation}
        StoreName={StoreName}
      />
      <DialogDelete
        disclosure={showConfirmation}
        handleDelete={handleDelete}
        isLoadingDelete={isLoadingDelete}
      />
    </Flex>
  );
};

export default Location;
