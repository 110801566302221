import { useState, useEffect } from "react";
import dayjs from "dayjs";
import { useHistory } from "react-router-dom";
import { Box, Flex, Text, Badge, get } from "renos-ui";
import { useCheckStore } from "services/auth";
import {
  useGetOrderStatus,
  useGetNotificationList,
  useUpdateNotification,
} from "services/notification";
import ImgNewOrder from "assets/images/new-order.png";
import ImgReadyDeliver from "assets/images/ready-to-deliver.png";
import ImgOnDeliver from "assets/images/on-deliver.png";
import ImgArrive from "assets/images/arrive.png";
import NotificationItem from "./NotificationItem";
import EmptyNotif from "./EmptyNotif";
import { useQueryClient } from "react-query";

const Card = ({ srcImg, text, count }) => {
  const [firstText, secondText] = text.split(" ");

  return (
    <Flex flexDirection="column" alignItems="center">
      <Flex
        width="48px"
        height="48px"
        justifyContent="center"
        alignItems="center"
        backgroundColor="blue5"
        borderRadius="100%"
      >
        <Badge
          borderRadius="100%"
          count={count}
          backgroundColor="red50"
          showZero={false}
        >
          <Box
            width="28px"
            height="28px"
            as="img"
            src={srcImg}
            alt="card-image"
          />
        </Badge>
      </Flex>
      <Text>{firstText}</Text>
      <Text>{secondText}</Text>
    </Flex>
  );
};

const SaleTab = () => {
  const { data: storeData, isLoading } = useCheckStore();
  const [dataOrderStatus, setDataOrderStatus] = useState([]);
  const queryClient = useQueryClient();

  const { mutate: mutateDataOrder } = useGetOrderStatus({
    onSuccess: (data) => {
      setDataOrderStatus(data);
    },
  });

  const startDate = dayjs(new Date()).format("YYYY-MM-DD");
  const endDate = dayjs(new Date()).format("YYYY-MM-DD");
  const { push } = useHistory();

  useEffect(() => {
    if (!isLoading && storeData) {
      mutateDataOrder({
        store: get(storeData, "data.id"),
        date_start: startDate,
        date_end: endDate,
      });
    }
    // eslint-disable-next-line
  }, [mutateDataOrder, storeData, isLoading]);

  const DATA_ORDER = {
    NEW: 1,
    READY_DELIVER: 2,
    ON_DELIVER: 3,
    ARRIVE: 4,
  };

  const history = useHistory();

  const { mutate: updateNotification } = useUpdateNotification({});

  const [dataNotifList, setDataNotifList] = useState([]);

  const { mutate } = useGetNotificationList({
    onSuccess: (data) => {
      setDataNotifList(data);
    },
  });

  useEffect(() => {
    if (!isLoading && storeData) {
      mutate({
        items_per_page: 2,
        store_id: storeData?.data.id,
        page: 1,
      });
    }
  }, [mutate, storeData, isLoading]);

  const goToPageNotif = () => {
    history.push("/notification");
  };

  const handleShowTransaction = (orderNumber) => {
    push(`/sales/orders?query=${orderNumber}`);
  };

  const handleClickNotif = (notificationId, orderNumber) => {
    const payload = {
      store_id: get(storeData, "data.id"),
      notification_id: notificationId,
    };

    updateNotification(payload);
    handleShowTransaction(orderNumber);
    queryClient.invalidateQueries({
      queryKey: ["getNotification"],
    });
    queryClient.invalidateQueries({
      queryKey: ["notifCount"],
    });
  };

  const handleClickNotifPromo = (notificationId) => {
    const payload = {
      store_id: get(storeData, "data.id"),
      notification_id: notificationId,
    };

    updateNotification(payload);
    queryClient.invalidateQueries({
      queryKey: ["notifCount"],
    });
    history.push("/notification?tab-active=update");
  };

  const dataOrder = (typeID) => {
    return get(dataOrderStatus, `[${typeID}].total`);
  };

  return (
    <Flex
      flexDirection="column"
      width="100%"
      paddingTop="24px"
      gap="16px"
      justifyContent="center"
      alignItems="center"
    >
      <Flex flexDirection="row" justifyContent="space-between" width="100%">
        <Card
          srcImg={ImgNewOrder}
          text="Pesanan Baru"
          count={dataOrder(DATA_ORDER.NEW)}
        />
        <Card
          srcImg={ImgReadyDeliver}
          text="Siap Dikirim"
          count={dataOrder(DATA_ORDER.READY_DELIVER)}
        />
        <Card
          srcImg={ImgOnDeliver}
          text="Sedang Dikirim"
          count={dataOrder(DATA_ORDER.ON_DELIVER)}
        />
        <Card
          srcImg={ImgArrive}
          text="Sampai Tujuan"
          count={dataOrder(DATA_ORDER.ARRIVE)}
        />
      </Flex>

      <Flex flexDirection="column" gap="8px" width="100%">
        {dataNotifList?.length === 0 && <EmptyNotif />}
        {dataNotifList?.map((list, index) => (
          <NotificationItem
            key={`NotifItem-${index}`}
            date={list.date_in}
            title={list.title}
            subTitle={list.message}
            onClick={() =>
              list.notification_type_id === 3
                ? handleClickNotif(list.notification_id, list.order_number)
                : handleClickNotifPromo(list.notification_id)
            }
            isRead={list.is_read}
          />
        ))}
      </Flex>

      <Box cursor="pointer" onClick={goToPageNotif}>
        <Text variant="caption" weight="bold" color="blue50">
          Lihat Selengkapnya
        </Text>
      </Box>
    </Flex>
  );
};

export default SaleTab;
