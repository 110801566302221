import StoreLayoutContext from "providers/StoreLayoutProvider";
import SwiperContext, { SwiperProvider } from "providers/SwiperProvider";
import { useContext, useEffect, useMemo, useState } from "react";
import { Flex, range } from "renos-ui";
import { SwiperSlide, useSwiper } from "swiper/react";
import { StyledSwiper } from "./styled";

const Banner = ({ isPreviewMode, id }) => {
  const { bannerRef, banner2Ref, dataHomePage, isHomePageLoading } =
    useContext(StoreLayoutContext);

  const [dataBanner, setDataBanner] = useState();
  const [swiperInstance, setSwiperInstance] = useState();
  const [activeIndex, setActiveIndex] = useState(0);

  const imageTypeId = useMemo(
    () => dataBanner?.banner?.image_type?.id,
    [dataBanner]
  );

  const refUsed = useMemo(
    () => (id === 1 ? bannerRef : banner2Ref),
    // eslint-disable-next-line
    [id]
  );

  useEffect(() => {
    const selectedBanner = dataHomePage.filter(
      (homepage) => homepage.id === id
    )[0];

    setDataBanner(selectedBanner);
    // eslint-disable-next-line
  }, [dataHomePage]);

  useEffect(() => {
    setActiveIndex(0);
    swiperInstance?.slideTo(0, 100);
    // eslint-disable-next-line
  }, [imageTypeId]);

  const renderPagination = () => (
    <Flex gap="4px" position="absolute" bottom="-12px">
      {dataBanner?.data.map((_, indexPagination) => (
        <Flex
          key={`paging-pils-${indexPagination}`}
          width={indexPagination === activeIndex ? "32px" : "16px"}
          height="6px"
          backgroundColor={
            indexPagination === activeIndex ? "blue50" : "blue10"
          }
          borderRadius="13px"
        />
      ))}
    </Flex>
  );

  if (isPreviewMode && !dataBanner?.isActive) return <></>;

  if (isHomePageLoading) {
    if (imageTypeId === 1)
      return (
        <Flex>
          <Flex
            height="156.5px"
            width="313px"
            backgroundColor="black10"
            borderRadius="10px"
            ref={bannerRef}
          />
        </Flex>
      );
    if (imageTypeId === 2)
      return (
        <Flex
          width="313px"
          borderRadius="10px"
          ref={refUsed}
          gap="12px"
          flexDirection="column"
        >
          <Flex
            height="313px"
            width="313px"
            backgroundColor="black10"
            borderRadius="10px"
          />
          <Flex
            height="313px"
            width="313px"
            backgroundColor="black10"
            borderRadius="10px"
          />
        </Flex>
      );

    return (
      <Flex height="313px" borderRadius="10px" ref={refUsed} gap="12px">
        {range(3).map((_, indexSkeleton) => (
          <Flex
            key={`skeleton-${indexSkeleton}`}
            height="313px"
            width="313px"
            backgroundColor="black10"
            borderRadius="10px"
          />
        ))}
      </Flex>
    );
  }

  return (
    <>
      {imageTypeId === 1 ? (
        <SwiperProvider value={{ setSwiperInstance, swiperInstance }}>
          <Flex borderRadius="10px" ref={refUsed} position="relative">
            <StyledSwiper
              slidesPerView={1}
              onSlideChange={(event) => setActiveIndex(event?.activeIndex)}
            >
              {dataBanner.data.map((banner, indexBanner) => (
                <SwiperSlide key={`swiper-${indexBanner}`}>
                  <Flex
                    as={banner.image_path && "img"}
                    src={banner.image_path}
                    backgroundColor={!banner.image_path && "black10"}
                    height="156.5px"
                    width="313px"
                    borderRadius="10px"
                  />
                </SwiperSlide>
              ))}
              <SwiperInstance />
            </StyledSwiper>
            {renderPagination()}
          </Flex>
        </SwiperProvider>
      ) : imageTypeId === 2 ? (
        <Flex
          ref={refUsed}
          width="313px"
          gap="12px"
          position="relative"
          flexDirection="column"
        >
          {dataBanner?.data?.map((banner, indexBanner) => (
            <Flex
              key={`swiper-${indexBanner}`}
              as={banner?.image_path && "img"}
              src={banner?.image_path}
              backgroundColor={!banner?.image_path && "black10"}
              height="313px"
              width="313px"
              borderRadius="10px"
            />
          ))}
        </Flex>
      ) : (
        <SwiperProvider value={{ setSwiperInstance, swiperInstance }}>
          <Flex ref={refUsed} height="313px" gap="12px" position="relative">
            <StyledSwiper
              slidesPerView={1}
              spaceBetween={0}
              onSlideChange={(event) => setActiveIndex(event?.activeIndex)}
            >
              <Flex>
                {dataBanner?.data.map((banner, indexBanner) => (
                  <SwiperSlide key={`swiper-${indexBanner}`}>
                    <Flex
                      as={banner.image_path && "img"}
                      src={banner.image_path}
                      backgroundColor="black10"
                      height="313px"
                      width="313px"
                      borderRadius="10px"
                    />
                  </SwiperSlide>
                ))}
              </Flex>
              <SwiperInstance />
            </StyledSwiper>
            {renderPagination()}
          </Flex>
        </SwiperProvider>
      )}
    </>
  );
};

const SwiperInstance = () => {
  const { setSwiperInstance } = useContext(SwiperContext);
  const swiper = useSwiper();

  useEffect(() => {
    if (swiper) {
      return setSwiperInstance(swiper);
    }
  }, [swiper, setSwiperInstance]);
};

export default Banner;
