import { Flex } from "renos-ui";
import Banner from "./Banner";
import BestSelling from "./BestSelling";
import NewProduct from "./NewProduct";
import StoreFront from "./StoreFront";
import Voucher from "./Voucher";
import { useContext } from "react";
import StoreLayoutContext from "providers/StoreLayoutProvider";
import ProductDiscount from "./ProductDiscount";
import BannerVideo from "./BannerVideo";

const Home = ({ isPreviewMode }) => {
  const { dataHomePage } = useContext(StoreLayoutContext);

  const renderPage = (id, key) => {
    switch (id) {
      case 1:
      case 8:
        return <Banner isPreviewMode={isPreviewMode} key={key} id={id} />;
      case 2:
        return <Voucher isPreviewMode={isPreviewMode} key={key} />;
      case 3:
        return <BestSelling isPreviewMode={isPreviewMode} key={key} />;
      case 4:
        return <NewProduct isPreviewMode={isPreviewMode} key={key} />;
      case 5:
        return <StoreFront isPreviewMode={isPreviewMode} key={key} />;
      case 6:
        return <ProductDiscount isPreviewMode={isPreviewMode} key={key} />;
      case 7:
        return <BannerVideo isPreviewMode={isPreviewMode} key={key} id={id} />;
      default:
        break;
    }
  };

  return (
    <Flex
      padding="12px 24px"
      gap="24px"
      flexDirection="column"
      overflowY="auto"
      height="509px"
    >
      {dataHomePage?.map((homepage, indexHomepage) =>
        renderPage(homepage.id, indexHomepage)
      )}
    </Flex>
  );
};

export default Home;
