export const productCategoriesToOptions = (categories) => {
  const options = [...categories].map((lvl1) => {
    if (lvl1?.categories.length > 0) {
      lvl1.options = lvl1.categories;

      delete lvl1.categories;
      lvl1.options.map((lvl2) => {
        if (lvl2?.categories.length > 0) {
          lvl2.options = lvl2.categories;
          delete lvl2.categories;
          lvl2.options.map((lvl3) => {
            if (lvl3?.categories.length > 0) {
              lvl3.options = lvl3.categories;
              delete lvl3.categories;
            }
            lvl3.label = lvl3.category_name;
            lvl3.value = lvl3.category_id;

            return lvl3;
          });
        }
        lvl2.label = lvl2.category_name;
        lvl2.value = lvl2.category_id;

        return lvl2;
      });
    }
    lvl1.label = lvl1.category_name;
    lvl1.value = lvl1.category_id;

    return lvl1;
  });

  return options;
};
