import ENDPOINTS from "constants/endpoints";
import { useMutation, useQuery } from "react-query";
import client from "../../client";

const getTemplateFetcher = async (params) => {
  const { data } = await client.get(ENDPOINTS.review_reply_template, {
    params,
  });

  return data;
};

export const useGetTemplate = (payload, extraParams) => {
  return useQuery(["getTemplate"], () => getTemplateFetcher(payload), {
    ...extraParams,
  });
};

const getTemplateAutoFetcher = async () => {
  const { data } = await client.get(ENDPOINTS.review_template_auto);

  return data.data;
};

export const useGetAutoTemplate = (extraParams) => {
  return useQuery(["getTemplateAutoFetcher"], getTemplateAutoFetcher, {
    retry: false,
    ...extraParams,
  });
};

// new

const deleteTemplateFetcher = async (body) => {
  const { data } = await client.delete(
    `${ENDPOINTS.review_reply_template}/${body.id}`
  );

  return data;
};

export const useDeleteTemplates = (extraParams) =>
  useMutation((body) => deleteTemplateFetcher(body), {
    ...extraParams,
  });

// new
const activeAutoTemplateFetcher = async (params) => {
  const { data } = await client.get(ENDPOINTS.review_template_auto_activate, {
    params,
  });

  return data;
};

export const useActiveAutoTemplates = (extraParams) =>
  useMutation((params) => activeAutoTemplateFetcher(params), {
    ...extraParams,
  });

const deactiveAutoTemplateFetcher = async (params) => {
  const { data } = await client.get(ENDPOINTS.review_template_auto_deactivate, {
    params,
  });

  return data;
};

export const useDeactiveAutoTemplates = (extraParams) =>
  useMutation((params) => deactiveAutoTemplateFetcher(params), {
    ...extraParams,
  });

const saveTemplatesFetcher = async (body) => {
  const response = await client.post(
    ENDPOINTS.review_reply_template_save,
    body
  );

  return response;
};

export const useSaveTemplates = (extraParams) =>
  useMutation((body) => saveTemplatesFetcher(body), {
    ...extraParams,
  });
