import styled from "styled-components";
import { Button, Flex, Input } from "renos-ui";

export const StyledTextArea = styled(Input.TextArea)`
  height: 160px;
`;

export const StyledButton = styled(Button)`
  margin: 12px;
`;

export const Wrapper = styled(Flex)`
  word-wrap: break-word;
  display: inline-block;
  box-sizing: border-box;
`;
