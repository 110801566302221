import { useMemo } from "react";
import { Box, Drawer, Flex, Icon, Input, Text, useDisclosure } from "renos-ui";

const SelectSort = ({ value, setValue }) => {
  const disclosure = useDisclosure({ isOpen: false });

  const handleSave = (newValue) => {
    setValue(newValue);
    disclosure.onClose();
  };

  const textValue = useMemo(
    () =>
      value === "desc"
        ? "Paling Baru"
        : value === "asc"
        ? "Paling Lama"
        : "Urutkan",
    [value]
  );

  return (
    <Flex width="100%">
      <Input
        onClick={disclosure.onOpen}
        value={textValue}
        readOnly
        size="small"
        sufix={
          <Icon
            onClick={disclosure.onOpen}
            name="Down-outline"
            color="black75"
            size="20px"
          />
        }
      />
      <Drawer
        isVisible={disclosure.isOpen}
        onClose={disclosure.onClose}
        placement="bottom"
      >
        <Flex flexDirection="column" width="100%" gap="16px" height="100%">
          <Flex justifyContent="center">
            <Flex
              width="40px"
              height="3px"
              borderRadius="16px"
              backgroundColor="black25"
            />
          </Flex>
          <Flex justifyContent="space-between" gap="8px" alignItems="end">
            <Flex gap="8px" alignItems="center">
              <Icon
                name="Close-outline"
                color="black50"
                size="24"
                onClick={disclosure.onClose}
              />
              <Text variant="subtitle" weight="bold">
                Urutkan
              </Text>
            </Flex>
            {value && (
              <Box cursor="pointer" onClick={() => handleSave("")}>
                <Text variant="caption" color="blue50" weight="bold">
                  Reset
                </Text>
              </Box>
            )}
          </Flex>
          <Flex flexDirection="column" paddingTop="6px">
            <Flex
              justifyContent="space-between"
              gap="8px"
              alignItems="center"
              borderBottom="1px"
              borderBottomStyle="solid"
              borderBottomColor="black10"
              paddingBottom="16px"
              paddingTop={0}
              cursor="pointer"
              onClick={() => handleSave("desc")}
            >
              <Text
                variant="subtitle"
                weight={value === "desc" && "bold"}
                color={value === "desc" ? "blue50" : "black75"}
              >
                Paling Baru
              </Text>

              {value === "desc" && (
                <Icon name="Checkmark-outline" color="blue50" size="24px" />
              )}
            </Flex>
            <Flex
              justifyContent="space-between"
              gap="8px"
              alignItems="center"
              py="16px"
              borderBottom="1px"
              borderBottomStyle="solid"
              borderBottomColor="black10"
              cursor="pointer"
              onClick={() => handleSave("asc")}
            >
              <Text
                variant="subtitle"
                weight={value === "asc" && "bold"}
                color={value === "asc" ? "blue50" : "black75"}
              >
                Paling Lama
              </Text>
              {value === "asc" && (
                <Icon name="Checkmark-outline" color="blue50" size="24px" />
              )}
            </Flex>
          </Flex>
        </Flex>
      </Drawer>
    </Flex>
  );
};

export default SelectSort;
