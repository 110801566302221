import { Fragment } from "react";
import { Flex, range, Skeleton, Divider } from "renos-ui";

const SkeletonRating = () => {
  return (
    range(10).map((_, i) => (
      <Fragment key={`skeleton-rating${i}`}>
        <Flex
          alignItems="center"
          gap="16px"
        >
          <Flex
            flexGrow="1"
            alignItems="center"
            gap="16px"
          >
            <Skeleton width="52px" height="52px" />
            <Skeleton />
          </Flex>
          <Flex gap="40px">
            <Flex
              width="100px"
              justifyContent="center"
              gap="10px"
            >
              <Skeleton />
            </Flex>
            <Flex
              width="120px"
              justifyContent="center"
              marginRight="80px"
            >
              <Skeleton />
            </Flex>
          </Flex>
        </Flex>
        <Divider />
      </Fragment>
    )
    ));
};

export default SkeletonRating;