import React from "react";
import { Box, Flex, range, Skeleton } from "renos-ui";

export const TableSkeleton = () => {
  return (
    <Flex flexDirection="column" rowGap="24px" width="100%" overflow="auto">
      <Flex width="100%" columnGap="20px" pb="30px">
        <Skeleton height="36px" width="42px" />
        <Skeleton height="36px" width="250px" />
        <Skeleton height="36px" width="110px" />
        <Skeleton height="36px" width="82px" />
        <Box width="124px" />
      </Flex>
      <Flex flexDirection="column" rowGap="36px">
        {range(10).map((_, i) => (
          <Flex width="100%" columnGap="20px" key={`skeleton-table-${i}`}>
            <Skeleton height="60px" width="42px" />
            <Skeleton height="60px" width="250px" />
            <Skeleton height="60px" width="110px" />
            <Skeleton height="60px" width="82px" />
            <Skeleton height="60px" width="124px" />
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
};

export const TabsSkeleton = () => (
  <Box py="10px" width="80%">
    <Skeleton />
  </Box>
);
