import styled from "styled-components";
import { Flex } from "renos-ui";

export const Container = styled(Flex)`
  display: flex;
  flex-direction: column;
  gap: 16px;
  border: 1px dashed #dcdcdc;
  border-radius: 8px;
  padding: 16px;
  margin: 16px;
  font-size: 14px;
  background: url("/assets/images/mobile/bg-status-store.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-color: white;
`;
