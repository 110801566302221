import styled from "styled-components";
import { Flex, Input, Text, colors } from "renos-ui";

export const StyledFlex = styled(Flex)`
  > div {
    width: 100%;
  }
`;

export const StyledTextTitle = styled(Text)`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const StyledFlexInputRounded = styled(Flex)`
  > div > span {
    border-radius: 100px;
    height: 40px;
  }
`;

export const StyledFlexInputSearch = styled(Flex)`
  > div > span {
    border-color: ${colors.black5};
    border-radius: 100px;
    background-color: ${colors.black5};

    > input {
      background-color: ${colors.black5};
    }
  }
`;

export const StyledInput = styled(Input)`
  ::placeholder {
    color: ${colors.black75};
  }
`;

export const Wrapper = styled(Flex)`
  word-wrap: break-word;
  display: inline-block;
  box-sizing: border-box;
`;
