import desktopUrl from "constants/desktopUrl";
import StoreLayoutContext from "providers/StoreLayoutProvider";
import React, { useContext, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { Flex, Button, Divider, Text, Skeleton, range } from "renos-ui";
import Header from "../component/Header";
import NoData from "../component/NoData";
import CardVoucher from "./CardVoucher";
import { abbreviationNum, currencyFormatter } from "helpers";

const Voucher = ({ handleBack, isLoading }) => {
  const { push } = useHistory();
  const { dataHomePage, refetchHomePage } = useContext(StoreLayoutContext);

  const toVoucher = () => {
    push(desktopUrl.VOUCHER);
  };

  const dataVoucher = useMemo(() => {
    if (dataHomePage) {
      const selectedNewPrduct = dataHomePage.filter(
        (homepage) => homepage.id === 2
      )[0];

      return selectedNewPrduct.data ? selectedNewPrduct.data : [];
    }

    return [];
  }, [dataHomePage]);

  return (
    <Flex flexDirection="column" gap="16px">
      <Header handleBack={handleBack} title="Edit Voucher Toko" />

      {isLoading ? (
        <Flex flexDirection="column" width="100%" gap="8px" marginBottom="24px">
          {range(3).map((_, indexSkeleton) => (
            <Skeleton
              width="212px"
              height="72px"
              key={`loading-${indexSkeleton}`}
            />
          ))}
        </Flex>
      ) : dataVoucher.length === 0 ? (
        <NoData
          title="Ups, Belum Ada Voucher"
          description="Mulai buat voucher tokomu untuk menarik minat pembeli."
          onClick={toVoucher}
          btnText="Tambah Voucher"
          isHasRefetchButton={true}
          refetch={refetchHomePage}
          refetchText="Sudah ada Voucher?"
        />
      ) : (
        <>
          <Flex flexDirection="column" width="100%" gap="16px">
            <Flex
              maxHeight="155px"
              overflowY="auto"
              flexDirection="column"
              width="100%"
              gap="16px"
            >
              <Text color="black75" weight="bold">
                Tampilkan Voucher
              </Text>

              <Flex flexDirection="column" gap="8px">
                {dataVoucher.map((voucher, indexVoucher) => (
                  <CardVoucher
                    minPurchase={`Rp ${currencyFormatter(
                      voucher.minimum_transaction
                    )}`}
                    amount={
                      voucher.discount_type_id === 1
                        ? `${voucher.promo_percentage}%`
                        : `Rp ${abbreviationNum(voucher.promo_amount)}`
                    }
                    key={`card-voucher${indexVoucher}`}
                  />
                ))}
              </Flex>
            </Flex>

            <Flex flexDirection="column">
              <Text variant="caption" color="black50">
                Kelola voucher tokomu di Seller Dashboard.
              </Text>
              <Flex onClick={toVoucher} cursor="pointer">
                <Text variant="caption" color="blue50" weight="bold">
                  Ubah Voucher
                </Text>
              </Flex>
            </Flex>
          </Flex>
          <Divider />
          <Flex gap="16px" paddingBottom="24px">
            <Flex flexGrow={1} flexBasis={0}>
              <Button
                size="medium"
                variant="tertiary"
                isBlock
                onClick={handleBack}
              >
                Kembali
              </Button>
            </Flex>
          </Flex>
        </>
      )}
    </Flex>
  );
};

export default Voucher;
