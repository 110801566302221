import { Checkbox, Flex, Icon, Label, Text, useDisclosure } from "renos-ui";
import PopupMore from "../PopupMore";
import { currencyFormatter } from "helpers";

const Card = ({
  isChecked,
  imgSource,
  productName,
  price,
  stock,
  isNew,
  isVariant,
  isActive,
  isSelectMode,
  onCheck,
  data,
  handleInlineEdit,
}) => {
  const disclosureMore = useDisclosure({ isOpen: false });

  return (
    <Flex
      p="16px"
      borderBottomColor="black5"
      borderBottomWidth="8px"
      borderBottomStyle="solid"
      gap="16px"
      alignItems="center"
    >
      {isSelectMode && <Checkbox checked={isChecked} onChange={onCheck} />}
      <Flex
        as="img"
        src={imgSource}
        height="64px"
        width="64px"
        borderRadius="8px"
        backgroundColor="black5"
        alt="product-img"
        onError={(e) => {
          e.target.onerror = null;
          e.target.src = "/assets/images/empty-product.svg";
        }}
      />

      <Flex width="calc(100% - 116px)" flexDirection="column" gap="2px">
        <Text variant="caption" lineClamp={1} color="black75">
          {productName}
        </Text>
        <Text variant="caption" weight="bold" lineClamp={1} color="black75">
          {price}
        </Text>
        <Flex gap="4px">
          <Text variant="caption" color="black75">
            Stok: {currencyFormatter(stock)}
          </Text>
          <Label variant={isActive ? "success" : "neutral"}>
            {isActive ? "Aktif" : "Nonaktif"}
          </Label>
          {!isNew && <Label>Bekas</Label>}
          {isVariant && (
            <Label variant="none" color="white" backgroundColor="black75">
              Varian
            </Label>
          )}
        </Flex>
      </Flex>
      {!isSelectMode && (
        <Icon
          name="Vertical-outline"
          size={20}
          color="black50"
          cursor="pointer"
          onClick={disclosureMore.onOpen}
        />
      )}

      <PopupMore
        disclosure={disclosureMore}
        data={data}
        handleInlineEdit={handleInlineEdit}
      />
    </Flex>
  );
};

export default Card;
