import { useState, useContext } from "react";
import StoreContext from "providers/StoreProvider";
import {
  Box,
  Flex,
  colors,
  get,
  useToast,
  useDisclosure,
  Divider,
} from "renos-ui";
import {
  useGetTemplate,
  useDeleteTemplates,
  useSaveTemplates,
  useActiveAutoTemplates,
  useGetAutoTemplate,
  useDeactiveAutoTemplates,
} from "services/settings/reply-template";

import EmptyNote from "./component/EmptyTemplate";
import { setGTM } from "helpers";
import ModalAdd from "./component/AddModal";
import DeleteModal from "./component/DeleteModal";
import SelectModal from "./component/SelectModal";
import Header from "./component/header";
import Automatic from "./component/automatic";
import CardTemplate from "./component/CardTemplate";
import Skeleton from "./skeleton";

const Template = () => {
  const toast = useToast();
  const [value, setValue] = useState({
    name: "",
    message: "",
  });
  const [mode, setMode] = useState("add");

  const addModal = useDisclosure({});
  const selectModal = useDisclosure({});
  const deleteModal = useDisclosure({});
  const [tempIndex, setTempIndex] = useState("");
  const [onDefault, setOnDefault] = useState("");
  const {
    storeData: {
      data: { id: storeId },
    },
  } = useContext(StoreContext);

  const [checkedAutomate, setCheckAutomate] = useState();
  const [textAreaCount, setTextAreaCount] = useState(0);
  const [textInputCount, setTextInputCount] = useState(0);

  const {
    data: dataGetTemplates,
    isLoading: isLoadingTemplates,
    refetch: refetchGetTemplate,
  } = useGetTemplate({
    store_id: storeId,
  });

  const templateList = get(dataGetTemplates, "data", []);

  const checkDefault = dataGetTemplates?.data.filter(
    (item) => item.default === true
  );

  useGetAutoTemplate({
    onSuccess: (data) => {
      setCheckAutomate(data?.enabled);
    },
  });

  const { mutate: mutateDeleteTempates, isLoading: isDeleteLoading } =
    useDeleteTemplates({
      onSuccess: () => {
        toast({
          label: "Template balasan ulasan telah dihapus!",
          placement: "top",
        });

        if (onDefault) {
          mutateDeactiveAutoTempates();
        }
        deleteModal.onClose();
        refetchGetTemplate();
      },
      onError: () => {
        toast({
          label: "Template balasan ulasan telah dihapus!",
          placement: "top",
        });
      },
    });

  // active auto
  const { mutate: mutateActiveAutoTempates } = useActiveAutoTemplates({
    onSuccess: (data) => {
      toast({
        label: "Otomatis Template balasan ulasan berhasil diaktifkan!",
        placement: "top",
      });
      setCheckAutomate(!checkedAutomate);
    },
    onError: () => {
      toast({
        label: "Otomatis Template balasan ulasan gagak diaktifkan!",
        placement: "top",
      });
    },
  });

  // deactive auto
  const { mutate: mutateDeactiveAutoTempates } = useDeactiveAutoTemplates({
    onSuccess: () => {
      toast({
        label: "Otomatis Template balasan ulasan berhasil dinonaktifkan!",
        placement: "top",
      });
      setCheckAutomate(!checkedAutomate);
    },
    onError: () => {
      toast({
        label: "Otomatis Template balasan ulasan gagak dinonaktifkan!",
        placement: "top",
      });
    },
  });

  const { mutate: mutateSaveTemplates, isLoading: isSaveLoading } =
    useSaveTemplates({
      onError: () => {
        toast({
          label: `${
            mode === "select"
              ? "Pengaturan template balasan ulasan otomatis gagal disimpan!!"
              : mode === "add"
              ? "Template balasan ulasan gagal dibuat!"
              : "Template balasan ulasan gagal diubah!"
          }`,
          placement: "top",
        });
        addModal.onClose();
        selectModal.onClose();
      },
      onSuccess: () => {
        toast({
          label: `${
            mode === "select"
              ? "Pengaturan template balasan ulasan otomatis berhasil disimpan!!"
              : mode === "add"
              ? "Template balasan ulasan berhasil dibuat!"
              : "Template balasan ulasan berhasil diubah!"
          }`,
          placement: "top",
        });
        addModal.onClose();
        selectModal.onClose();
        refetchGetTemplate();

        if (templateList?.length < 1) {
          mutateActiveAutoTempates();
        }

        if (checkDefault === 0) {
          mutateActiveAutoTempates();
        }
      },
    });

  const handleAdd = () => {
    setGTM("seller_center_section", {
      event_category: "store_settings | reply_templates",
      event_action: "click",
      event_label: "add_template",
    });

    setMode("add");
    addModal.onOpen();
    setValue({
      name: "",
      message: "",
    });
  };

  const handleSelect = (index) => {
    setMode("select");
    setTempIndex(index);
    const tempSelected = templateList[index];
    setValue(tempSelected);
    selectModal.onOpen();
  };

  const handleDelete = async (event) => {
    setTempIndex(event.id);
    setOnDefault(event.default);
    deleteModal.onOpen();
  };

  const handleDisabled = () => {
    toast({
      label: "Harap pilih template balasan otomatis terlebih dahulu!",
      placement: "top",
    });
  };

  const handleOkDelete = () => {
    handleGTM("remove_template");

    mutateDeleteTempates({ id: tempIndex });
  };

  const handleEdit = (index) => {
    setMode("edit");
    setTempIndex(index);
    const tempSelected = templateList[index];
    setValue(tempSelected);
    addModal.onOpen();
  };

  const handleChangeValue = (event) => {
    setIsError({
      name: false,
      message: false,
    });

    if (event.target.name === "message") {
      setTextAreaCount(event.target.value.length);
    }

    if (event.target.name === "name") {
      setTextInputCount(event.target.value.length);
    }

    setValue((prevState) => {
      return {
        ...prevState,
        [event.target.name]: event.target.value,
      };
    });
  };

  const handleCloseModalAdd = () => {
    addModal.onClose();
    setTextAreaCount(0);
    setTextInputCount(0);
    setIsError({
      name: false,
      message: false,
    });
  };

  const handleSave = () => {
    const isHasError = handleError();
    if (isHasError) return false;

    if (mode === "edit") handleGTM("change_template");

    const body = {
      id: value.id,
      name: value.name,
      message: value.message,
      store_id: storeId,
    };
    mutateSaveTemplates(body);
  };

  const handleSaveSelect = () => {
    handleGTM("select_template");
    const body = {
      id: value.id,
      name: value.name,
      message: value.message,
      default: true,
    };
    mutateSaveTemplates(body);
  };

  // handle otomatis
  const handleActiveAuto = () => {
    mutateActiveAutoTempates();
  };

  const handleDeactiveAuto = () => {
    mutateDeactiveAutoTempates();
  };

  const handleGTM = (label) => {
    setGTM("seller_center_section", {
      event_category: "store_settings | reply_templates",
      event_action: "click",
      event_label: label,
    });
  };

  const handleActiveSwitch = () => {
    if (!checkedAutomate) {
      handleGTM("enable_auto_review");
      handleActiveAuto();
    } else {
      handleGTM("unable_auto_review");
      handleDeactiveAuto();
    }
  };

  const [isError, setIsError] = useState({
    name: false,
    message: false,
  });

  const handleError = () => {
    const tempError = isError;
    let countError = 0;

    if (value.name.length < 5) {
      tempError.name = true;
      countError += 1;
    }

    if (value.message.length < 5) {
      tempError.message = true;
      countError += 1;
    }

    setIsError({ ...tempError });

    if (countError !== 0) {
      return true;
    }

    return false;
  };

  return (
    <Box>
      <Header handleAdd={handleAdd} templateList={templateList} />
      {isLoadingTemplates ? (
        <Skeleton itemShow={5} />
      ) : templateList?.length === 0 ? (
        <EmptyNote />
      ) : (
        <Flex flexDirection="column">
          <Automatic
            checkDefault={checkDefault}
            handleActiveSwitch={handleActiveSwitch}
            handleDisabled={handleDisabled}
            checkedAutomate={checkedAutomate}
          />
          <Flex
            py="32px"
            gap="16px"
            px="24px"
            border={`1px solid ${colors.black5}`}
            borderRadius="0px 0px 8px 8px"
            flexDirection="column"
            rowGap="24px"
          >
            {templateList.map((list, index) => (
              <>
                <CardTemplate
                  index={index}
                  list={list}
                  handleEdit={() => handleEdit(index)}
                  handleSelect={() => handleSelect(index)}
                  handleDelete={() => handleDelete(list)}
                  templateList={templateList}
                />
                {index !== 4 && <Divider />}
              </>
            ))}
          </Flex>
        </Flex>
      )}

      <ModalAdd
        addModal={addModal}
        mode={mode}
        handleChangeValue={(event) => handleChangeValue(event)}
        value={value}
        handleSave={handleSave}
        handleSaveSelect={handleSaveSelect}
        templateList={templateList}
        isSaveLoading={isSaveLoading}
        textAreaCount={textAreaCount}
        textInputCount={textInputCount}
        handleClose={handleCloseModalAdd}
        error={isError}
      />

      <DeleteModal
        deleteModal={deleteModal}
        isDeleteLoading={isDeleteLoading}
        handleOkDelete={handleOkDelete}
      />

      <SelectModal
        selectModal={selectModal}
        handleSaveSelect={handleSaveSelect}
        value={value}
        isSaveLoading={isSaveLoading}
      />
    </Box>
  );
};

export default Template;
