import React from "react";
import MobileLayout from "layouts/MobileLayout";
import { Flex, Icon, Text, Input, Button, isEmpty, Divider } from "renos-ui";
import FixedBottomContainer from "../components/FixedBottomContainer";
import useDeliveryRegion from "./hooks/useDeliveryRegion";
import ModalCity from "./ModalCity";
import ModalDistrict from "./ModalDistrict";
import ModalProvince from "./ModalProvince";
import constants from "store/seller-fleet/constants";
import LoadingSpinner from "../components/LoadingSpinner";

const DeliveryRegion = () => {
  const {
    history,
    validation,
    dataCoverage,
    selectedIndex,
    provinceDisclosure,
    cityDisclosure,
    districtDisclosure,
    isAllowRemoveArea,
    handleShowDisclosure,
    handleChangeEstimate,
    handleAddArea,
    handleDeleteCoverage,
    submitProvince,
    submitCity,
    isAllowAddArea,
    isLoadingAll,
    dispatch,
    mutateCity,
    mutateDistrict,
    isLoadingCity,
    isLoadingDistrict,
    isLoadingProvince,
  } = useDeliveryRegion();

  return (
    <MobileLayout
      header={{
        title: "Atur Wilayah Pengiriman",
        withBack: (
          <Icon
            name="Close-outline"
            color="black50"
            cursor="pointer"
            onClick={() => {
              dispatch({
                type: constants.RESET_TO_INTIAL_STATE,
              });
              history.push("/account/list-setting");
            }}
          />
        ),
      }}
    >
      <Flex
        flexDirection="column"
        width="100%"
        height="calc(100vh - 142px)"
        overflow="auto"
      >
        <Flex flexDirection="column" padding="16px">
          {isLoadingAll ? (
            <LoadingSpinner />
          ) : (
            dataCoverage?.map((row, indexCoverage) => {
              return (
                <Flex flexDirection="column" gap="16px" key={indexCoverage}>
                  {isAllowRemoveArea && indexCoverage > 0 && (
                    <Flex alignSelf="flex-end">
                      <Button
                        variant="tertiary"
                        size="small"
                        onClick={() => handleDeleteCoverage(indexCoverage)}
                        icon={<Icon name="Trash-outline" size={14} />}
                      />
                    </Flex>
                  )}

                  <Flex>
                    <Input
                      size="small"
                      label="Provinsi"
                      placeholder={
                        !isEmpty(row.selectedProvince)
                          ? "Terpilih Semua"
                          : "Pilih Provinsi"
                      }
                      prefix={<Icon name="Search-outline" size={16} />}
                      disabled={isLoadingAll || isLoadingProvince}
                      value={row?.selectedProvince?.province_name}
                      readOnly
                      onClick={() =>
                        handleShowDisclosure("province", indexCoverage)
                      }
                    />
                  </Flex>

                  <Flex>
                    <Input
                      size="small"
                      label="Kota/Kabupaten"
                      placeholder={
                        !isEmpty(row.selectedProvince)
                          ? "Terpilih Semua"
                          : "Pilih Kabupaten"
                      }
                      prefix={<Icon name="Search-outline" size={16} />}
                      disabled={isEmpty(row.selectedProvince) || isLoadingAll}
                      value={row?.selectedCity
                        ?.map((cities) => cities.city_name)
                        .join(", ")}
                      readOnly
                      onClick={() =>
                        handleShowDisclosure("city", indexCoverage)
                      }
                    />
                  </Flex>

                  <Flex>
                    <Input
                      size="small"
                      label="Kecamatan"
                      placeholder={
                        !isEmpty(row.selectedProvince)
                          ? "Terpilih Semua"
                          : "Pilih Kecamatan"
                      }
                      prefix={<Icon name="Search-outline" size={16} />}
                      disabled={
                        isEmpty(row.selectedCity) ||
                        isLoadingAll ||
                        isLoadingDistrict
                      }
                      value={row.selectedDistrict
                        .map((district) => district.district_name)
                        .join(", ")}
                      readOnly
                      onClick={() =>
                        handleShowDisclosure("district", indexCoverage)
                      }
                    />
                  </Flex>

                  <Input
                    size="small"
                    label="Estimasi Sampai"
                    placeholder="Contoh : 1-2"
                    isError={
                      validation.dataCoverage[indexCoverage]?.estimateDays
                    }
                    error={
                      validation.dataCoverage[indexCoverage]?.estimateDaysText
                    }
                    onChange={(event) =>
                      handleChangeEstimate(event, indexCoverage)
                    }
                    value={row.estimateDays}
                    sufix={
                      <Text variant="caption" weight="bold" color="black50">
                        Hari kerja
                      </Text>
                    }
                  />
                  <Divider margin="16px 0 24px 0" />
                </Flex>
              );
            })
          )}

          <ModalProvince
            isOpen={provinceDisclosure.isOpen}
            onClose={provinceDisclosure.onClose}
            selectedIndex={selectedIndex}
            submitProvince={(tempData) => submitProvince(tempData)}
            isLoadingProvince={isLoadingProvince}
          />
          <ModalCity
            isOpen={cityDisclosure.isOpen}
            onClose={cityDisclosure.onClose}
            selectedIndex={selectedIndex}
            submitCity={(tempData) => submitCity(tempData)}
            mutateCity={mutateCity}
            isLoadingCity={isLoadingCity}
          />
          <ModalDistrict
            isOpen={districtDisclosure.isOpen}
            onClose={districtDisclosure.onClose}
            selectedIndex={selectedIndex}
            mutateDistrict={mutateDistrict}
            isLoadingDistrict={isLoadingDistrict}
          />
        </Flex>
        <Flex marginBottom="100px" />
        <FixedBottomContainer>
          <Flex flexDirection="column" gap="16px" width="100%" padding="16px">
            <Button
              isBlock
              disabled={!isAllowAddArea || isLoadingAll}
              size="medium"
              variant="secondary"
              onClick={isAllowAddArea ? handleAddArea : () => {}}
            >
              <Text variant="caption" weight="bold">
                Tambah Wilayah
              </Text>
            </Button>
            <Button
              disabled={!isAllowAddArea || isLoadingAll}
              isBlock
              size="medium"
              onClick={() => {
                history.push("/account/set-shipping-rates");
                dispatch({
                  type: constants.SET_IS_BACK,
                  payload: {
                    isBack: true,
                  },
                });
              }}
            >
              <Text variant="caption" weight="bold">
                Selanjutnya
              </Text>
            </Button>
          </Flex>
        </FixedBottomContainer>
      </Flex>
    </MobileLayout>
  );
};

export default DeliveryRegion;
