import React, { useEffect, useMemo, useState } from "react";
import { Flex, Text, Button, Icon, Divider, Checkbox, range } from "renos-ui";
import { useGetProductsV3 } from "services/product";
import { StyledDialog } from "./styled";
import Card from "./Card";
import Filter from "./Filter";
import CardSkeleton from "./CardSkeleton";
import FailLoadData from "widgets/global/FailLoadData";
import InfiniteScroll from "widgets/global/InfiniteScroll";

const ModalAdd = ({ isOpen, onClose, data, setData, handleSetHomePage }) => {
  const [showInfo, setShowInfo] = useState(true);
  const [defaultData, setDefaultData] = useState([]);
  const [searchParam, setSearchParam] = useState("");
  const [page, setPage] = useState(1);
  const [dataList, setDatalist] = useState([]);
  const maxProduct = 12;

  const {
    data: dataProduct,
    isLoading,
    isError,
    isRefetching,
    refetch,
  } = useGetProductsV3(
    {
      keyword: searchParam || "",
      product_status_ids: 1,
      page: page,
      limit: 40,
    },
    {
      onSuccess: (dataSuccess) => {
        if (page === 1) {
          setDatalist([...dataSuccess.data]);
        } else {
          const concatData = dataList.concat(dataSuccess.data);
          setDatalist(concatData);
        }
      },
    }
  );

  const hasNextPage = useMemo(
    () => dataProduct?.pagination?.next_page_url,
    [dataProduct]
  );

  useEffect(() => {
    setPage(1);
  }, [searchParam]);

  const isAllLoading = useMemo(
    () => (isLoading || isRefetching) && page === 1,
    [isLoading, page, isRefetching]
  );

  const isAllChecked = useMemo(() => {
    const totalSelected = data ? data.length : 0;
    const checkedResult = {
      active: false,
      indeterminate: false,
    };

    if (totalSelected > 0 && totalSelected < maxProduct)
      checkedResult.indeterminate = true;
    if (totalSelected === maxProduct) checkedResult.active = true;

    return checkedResult;
  }, [data]);

  const isChecked = (id) => {
    const checkIncludedId = data.filter(
      (filterData) => filterData.product_id === id
    );

    return checkIncludedId.length > 0 && true;
  };

  const handleCheck = (dataCheck) => {
    let tempData = data;

    if (isChecked(dataCheck.product_id)) {
      tempData = tempData.filter(
        (filterCheck) => filterCheck.product_id !== dataCheck.product_id
      );
    } else {
      tempData.push(dataCheck);
    }
    setData([...tempData]);
    handleSetHomePage([...tempData]);
  };

  const handleCheckAll = () => {
    if (data?.length === maxProduct || data?.length === dataList.length) {
      setData([]);
      handleSetHomePage([]);
    } else {
      const tempArray = data;
      const listId = data.map((dataSelected) => dataSelected.product_id);

      for (let i = 0; i < dataList?.length; i++) {
        if (
          !listId.includes(dataList[i].product_id) &&
          tempArray?.length < maxProduct
        )
          tempArray.push(dataList[i]);

        if (tempArray.length === maxProduct) break;
      }

      setData([...tempArray]);
      handleSetHomePage([...tempArray]);
    }
  };

  const handleCancel = () => {
    setData([...defaultData]);
    handleSetHomePage([...defaultData]);
    onClose();
  };

  useEffect(() => {
    setDefaultData([...data]);
    setPage(1);
    // eslint-disable-next-line
  }, [isOpen]);

  return (
    <StyledDialog isVisible={isOpen} verticalCentered width="496px">
      <Flex
        flexDirection="column"
        width="100%"
        gap={showInfo ? "16px" : "24px"}
      >
        <Flex justifyContent="space-between" padding="24px 24px 0 24px">
          <Text variant="heading3" weight="bold">
            Produk Terlaris
          </Text>
          <Icon
            name="Close-outline"
            size={24}
            cursor="pointer"
            onClick={handleCancel}
          />
        </Flex>

        <Flex flexDirection="column" width="100%">
          <Flex flexDirection="column" gap="16px" px="24px" height="375px">
            {showInfo && (
              <Flex
                padding="10px 12px"
                borderRadius="8px"
                backgroundColor="yellow5"
                justifyContent="space-between"
              >
                <Flex gap="8px" alignItems="center">
                  <Icon name="Info-solid" color="yellow50" size={16} />
                  <Text variant="caption" color="yellow100">
                    Maksimal {maxProduct} produk yang dapat ditampilkan.
                  </Text>
                </Flex>
                <Icon
                  name="Close-outline"
                  color="black50"
                  size={20}
                  cursor="pointer"
                  onClick={() => setShowInfo(false)}
                />
              </Flex>
            )}

            <Filter searchParam={searchParam} setSearchParam={setSearchParam} />

            <Flex gap="16px" alignItems="center">
              <Checkbox
                onChange={handleCheckAll}
                checked={isAllChecked.active}
                indeterminate={isAllChecked.indeterminate}
                _checkedException
                disabled={isAllLoading}
              />
              <Text color="black75">
                <Text color="black75" weight="bold">
                  {data.length}
                </Text>{" "}
                dari{" "}
                <Text color="black75" weight="bold">
                  {maxProduct}
                </Text>{" "}
                produk dipilih
              </Text>
            </Flex>
            <Divider />
            <Flex
              height="263px"
              overflowY="auto"
              flexDirection="column"
              width="100%"
              gap="16px"
            >
              {isAllLoading ? (
                range(3).map((_, indexSkeleton) => (
                  <CardSkeleton key={`card-skeleton-${indexSkeleton}`} />
                ))
              ) : isError ? (
                <Flex height="448px" alignItems="center">
                  <FailLoadData onClick={refetch} noImg />
                </Flex>
              ) : dataList.length === 0 ? (
                <Flex
                  height="448px"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Text>Produk tidak ditemukan</Text>
                </Flex>
              ) : (
                <InfiniteScroll
                  page={page}
                  isLoading={isLoading}
                  loader={<CardSkeleton />}
                  hasNextPage={hasNextPage && !isAllLoading}
                  onChange={() => setPage(page + 1)}
                >
                  {dataList.map((product, indexProduct) => (
                    <Card
                      key={`card-product-${indexProduct}`}
                      data={product}
                      isChecked={isChecked(product.product_id)}
                      isHasDivider={true}
                      handleCheck={() => handleCheck(product)}
                      totalSelected={data ? data.length : 0}
                    />
                  ))}
                </InfiniteScroll>
              )}
            </Flex>
          </Flex>
          <Flex
            justifyContent="space-between"
            padding="16px 24px"
            gap="16px"
            boxShadow="0px 0px 8px rgba(20, 20, 32, 0.1)"
          >
            <Button isBlock variant="tertiary" onClick={handleCancel}>
              Batal
            </Button>
            <Button isBlock onClick={onClose}>
              Simpan
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </StyledDialog>
  );
};

export default ModalAdd;
