import React from "react";

import { useFormContext, Controller } from "react-hook-form";

import { get, Input, isEmpty } from "renos-ui";

export const FieldTextAreaInput = ({
  name,
  defaultValue,
  onChange,
  ...restProps
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const error = get(errors, name);

  return (
    <Controller
      name={name}
      defaultValue={defaultValue}
      control={control}
      render={({ field }) => (
        <Input.TextArea
          {...field}
          {...restProps}
          isError={!isEmpty(error)}
          error={error?.message}
          onChange={(event) => {
            if (onChange) return onChange(event);

            return field.onChange(event);
          }}
        />
      )}
    />
  );
};

export default FieldTextAreaInput;
