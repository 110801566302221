import { Divider, Flex, Input, Text } from "renos-ui";

const PriceRange = ({ value, setValue }) => {
  const handleChange = (newValue, property) => {
    setValue({ ...value, [property]: newValue });
  };

  return (
    <Flex flexDirection="column" gap="16px">
      <Flex flexDirection="column">
        <Text weight="bold" color="black75">
          Rentang Harga
        </Text>
        <Text variant="caption" color="black75">
          Atur filter berdasarkan rentang harga.
        </Text>
      </Flex>
      <Flex flexDirection="column" gap="8px">
        <Flex alignItems="center" gap="8px">
          <Flex width="190px">
            <Input.Number
              max={999999999}
              placeholder="Harga Minimum"
              onChange={(newValue) => handleChange(newValue, "start")}
              value={value.start}
              size="small"
              prefix={
                <Text weight="bold" color="black50">
                  Rp
                </Text>
              }
              isError={value.end < value.start}
            />
          </Flex>
          <Flex width="8px">
            <Divider />
          </Flex>
          <Flex width="190px">
            <Input.Number
              max={999999999}
              placeholder="Harga Maksimum"
              onChange={(newValue) => handleChange(newValue, "end")}
              value={value.end}
              size="small"
              prefix={
                <Text weight="bold" color="black50">
                  Rp
                </Text>
              }
              isError={value.end < value.start}
            />
          </Flex>
        </Flex>
        {value.end < value.start && (
          <Text color="red50" variant="caption">
            Harga maksimum harus lebih besar dari harga minimum.
          </Text>
        )}
      </Flex>
    </Flex>
  );
};

export default PriceRange;
