import React, { useMemo } from "react";
import {
  Button,
  Flex,
  Checkbox,
  Text,
  DatePicker,
  Divider,
  Input,
  Icon,
  Spinner,
} from "renos-ui";
import dayjs from "dayjs";

const OpenStore = (props) => {
  const {
    datas,
    setDatas,
    isChangeSchedule,
    setIsChangeSchedule,
    isLoading,
    checkedClose,
    seCheckedClose,
    openDialog,
  } = props;
  const isDisabled = useMemo(() => {
    return isLoading || !isChangeSchedule || datas?.is_closed;
  }, [isLoading, isChangeSchedule, datas]);

  const handleUpdate = () => {
    if (isChangeSchedule || datas?.is_closed) {
      openDialog();
    } else {
      setIsChangeSchedule(!isChangeSchedule);
    }
  };

  return (
    <>
      <Flex
        flexDirection="column"
        gap="24px"
        paddingX="38px"
        marginBottom="24px"
        width="634px"
      >
        <Flex gap="10px" alignItems="center">
          <Checkbox
            checked={checkedClose}
            disabled={isDisabled}
            onChange={() => {
              seCheckedClose(!checkedClose);
              setDatas({
                ...datas,
                date_start: new Date(),
              });
            }}
          />
          <Text>Tutup Sekarang</Text>
        </Flex>
        <Flex gap="24px" width="634px">
          <Flex flexDirection="column" gap="8px" flex="1">
            <Text color="black50" variant="caption" weight="bold">
              Mulai Tutup
            </Text>
            <DatePicker
              value={dayjs(datas.date_start).format("D MMM YYYY")}
              disabled={isDisabled || checkedClose}
              customInput={
                <Input sufix={<Icon name="Calendar-outline" size={20} />} />
              }
              minDate={new Date()}
              onChange={(startDate) => {
                setDatas({
                  ...datas,
                  date_start: startDate,
                  date_end: startDate,
                });
              }}
              placeholderText="Mulai Tanggal"
            />
          </Flex>
          <Flex flexDirection="column" gap="8px" flex="1">
            <Text color="black50" variant="caption" weight="bold">
              Tutup Hingga
            </Text>
            <DatePicker
              minDate={new Date(datas.date_start)}
              disabled={isDisabled}
              customInput={
                <Input sufix={<Icon name="Calendar-outline" size={20} />} />
              }
              value={dayjs(datas.date_end).format("D MMM YYYY")}
              onChange={(endDate) => {
                setDatas({
                  ...datas,
                  date_end: endDate,
                });
              }}
              placeholderText="Mulai Tanggal"
            />
          </Flex>
        </Flex>
        <Flex flexDirection="column" gap="8px">
          <Text color="black50" variant="caption" weight="bold">
            Catatan
          </Text>
          <Input.TextArea
            disabled={isDisabled}
            placeholder="Masukkan alasan toko tutup."
            value={datas.notes}
            onChange={(event) =>
              setDatas({
                ...datas,
                notes: event.currentTarget.value,
              })
            }
          />
        </Flex>
      </Flex>
      <Divider />
      <Flex flexDirection="column" alignItems="center" padding="1rem">
        <Flex width="100%" justifyContent="flex-end">
          <Button onClick={handleUpdate}>
            {isLoading && <Spinner size={20} color="white" />}
            {datas?.is_closed ? (
              <Text weight="bold">Buka Toko</Text>
            ) : (
              <Text weight="bold">
                {isChangeSchedule ? "Simpan" : "Ubah jadwal tutup Toko"}
              </Text>
            )}
          </Button>
        </Flex>
      </Flex>
    </>
  );
};

export default OpenStore;
