import MobileLayout from "layouts/MobileLayout";
import React, { useMemo, useState } from "react";
import { Flex, Tabs } from "renos-ui";
import { StyledFlex } from "./styled";
import TabRating from "./rating";
import useQueryParams from "hooks/useQueryParams";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import mobileUrl from "constants/mobileUrl";
import Review from "./review";

const AccountReview = () => {
  const query = useQueryParams();
  const { push } = useHistory();
  const [activeTabsKey, setActiveTabsKey] = useState(
    query.get("tab-active") || "ulasan"
  );
  const tabsItems = useMemo(() => {
    const isRating = activeTabsKey === "rating";
    const isReview = activeTabsKey === "ulasan";

    return [
      {
        key: "rating",
        label: "Rating Produk",
        width: "50%",
        iconName: isRating ? "Transaction-outline" : "",
        children: TabRating,
      },
      {
        key: "ulasan",
        label: "Ulasan Produk",
        width: "50%",
        iconName: isReview ? "Transaction-outline" : "",
        children: Review,
      },
    ];
  }, [activeTabsKey]);

  const handleChangeTabs = (value) => {
    setActiveTabsKey(value);
    push(`${mobileUrl.ACCOUNT_RATING_REVIEW}?tab-active=${value}`);
  };

  return (
    <MobileLayout
      header={{
        title: "Ulasan Pembeli",
        withBack: true,
        withoutGoBack: true,
        onBack: () => push("/account"),
      }}
    >
      <Flex
        width="100%"
        flexDirection="column"
        backgroundColor={activeTabsKey === "rating" ? "white" : "black5"}
      >
        <StyledFlex
          gap="12px"
          width="100%"
          columnGap="8px"
          alignItems="center"
          backgroundColor="white"
          justifyContent="center"
          style={{ boxSizing: "border-box" }}
        >
          <Tabs
            defaultActiveKey={activeTabsKey}
            onChange={handleChangeTabs}
            items={tabsItems}
          />
        </StyledFlex>
      </Flex>
    </MobileLayout>
  );
};

export default AccountReview;
