import React from "react";
import { Flex } from "renos-ui";
import MobileLayout from "layouts/MobileLayout";
import ToggleStore from "./toggle-store";
import CardCouriers from "./card-couriers";
import { useHistory } from "react-router-dom";
import mobileUrl from "constants/mobileUrl";

const DeliveryService = () => {
  const { push } = useHistory();

  return (
    <MobileLayout
      header={{
        title: "Layaran Pengiriman",
        withBack: true,
        withoutGoBack: true,
        onBack: () => push(mobileUrl.ACCOUNT),
      }}
    >
      <Flex flexDirection="column" width="100%">
        <ToggleStore />
        <CardCouriers />
      </Flex>
    </MobileLayout>
  );
};

export default DeliveryService;
