import styled from "styled-components";
import { Button, Input, colors } from "renos-ui";

export const StyledTextArea = styled(Input.TextArea)`
  resize: none;
`;

export const StyledButton = styled(Button)`
  :disabled {
    span {
      color: ${colors.black25};
    }
  }
`;
