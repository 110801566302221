import { Flex } from "renos-ui";
import { useMemo } from "react";
import ConfirmRejectNewOrder from "./component/ConfirmRejectNewOrder";
import OnDelivery from "./component/OnDelivery";
import SendOrder from "./component/SendOrder";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import mobileUrl from "constants/mobileUrl";

const ActionContainer = (props) => {
  const { push } = useHistory();
  const {
    order_id: orderId,
    order_status_id: orderStatusId,
    refetch,
    store_order_shipment: storeOrderShipment,
    shipping,
  } = props;

  const handleClickChat = () => {
    push({
      pathname: mobileUrl.CHAT,
      query: "",
      state: {
        qiscusId: props.buyer_qiscus_id,
        orderNumber: props.order_number,
        orderStatus: props.order_status_name,
        productImage: props.products[0].product_image_url,
      },
    });
  };

  const isHasButton = useMemo(() => {
    switch (orderStatusId) {
      case 2:
        return (
          <ConfirmRejectNewOrder
            orderId={orderId}
            refetch={refetch}
            onClickChat={handleClickChat}
          />
        );
      case 3:
        return (
          <SendOrder
            storeOrderShipment={storeOrderShipment}
            shipping={shipping}
            orderId={orderId}
            refetch={refetch}
            data={props}
            onClickChat={handleClickChat}
          />
        );
      case 4:
        return (
          <OnDelivery
            storeOrderShipment={storeOrderShipment}
            shipping={shipping}
            orderId={orderId}
            refetch={refetch}
            data={props}
            onClickChat={handleClickChat}
          />
        );
      default:
        return "";
    }
    // eslint-disable-next-line
  }, [orderStatusId]);

  return (
    <Flex
      padding="8px 16px"
      gap="8px"
      boxShadow="0px 0px 12px 0px rgba(20, 20, 32, 0.1)"
    >
      {isHasButton}
    </Flex>
  );
};

export default ActionContainer;
