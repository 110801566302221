import { Button, Dialog, Flex, Text, useToast } from "renos-ui";
import ENDPOINTS from "constants/endpoints";
import { goToUrl } from "helpers";

const ModalMultipleInvoice = ({ isOpen, onClose, data }) => {
  const toast = useToast();

  const handleConfirm = () => {
    const params = data.map((order) => order.order_id).join(",");
    goToUrl(
      `${process.env.REACT_APP_API}/${ENDPOINTS.order_bulk_invoice}?order_id=${params}`,
      "_blank"
    );
    onClose();
    toast({
      label: `${data.length} invoice berhasil dicetak`,
      placement: "top",
    });
  };

  return (
    <Dialog isVisible={isOpen} verticalCentered>
      <Flex width="320px" flexDirection="column" gap="24px">
        <Flex flexDirection="column" gap="8px">
          <Text variant="heading3" weight="bold">
            Cetak invoice sekaligus
          </Text>
          <Text color="black75">
            Kamu akan mencetak <b>{data.length}</b> invoice sekaligus.
          </Text>
        </Flex>

        <Flex gap="16px">
          <Button isBlock variant="tertiary" onClick={onClose}>
            Batal
          </Button>
          <Button isBlock onClick={handleConfirm}>
            Cetak
          </Button>
        </Flex>
      </Flex>
    </Dialog>
  );
};

export default ModalMultipleInvoice;
