import { useMemo, useState } from "react";
import {
  Button,
  Flex,
  Icon,
  Input,
  Skeleton,
  Text,
  range,
  useDebounce,
} from "renos-ui";
import { useGetProductStatus } from "services/product";
import { StyledFlexInputSearch } from "./styled";
import { setGTM } from "helpers";

const Filter = ({ dataFilter, setDataFilter, setCheckedProduct }) => {
  const {
    data: dataProductStatus,
    isLoading,
    isFetching,
    isError,
    refetch,
  } = useGetProductStatus();

  const [searchValue, setSearchValue] = useState("");
  const [tabValue, setTabValue] = useState("");

  const listTabs = [
    {
      name: "Semua",
      property: "all",
      id: "",
    },
    {
      name: "Aktif",
      property: "active",
      id: 1,
    },
    {
      name: "Nonaktif",
      property: "nonActive",
      id: 2,
    },
  ];

  const isAllLoading = useMemo(
    () => isLoading || isFetching,
    [isLoading, isFetching]
  );

  useDebounce(
    () => {
      setDataFilter({
        ...dataFilter,
        page: 1,
        product_status_id: tabValue,
        query: searchValue,
      });

      setCheckedProduct([]);
    },
    [searchValue, tabValue],
    1000
  );

  return (
    <Flex flexDirection="column" rowGap="16px" px="16px" marginBottom="16px">
      <StyledFlexInputSearch width="100%" borderRadius="100px" height="40px">
        <Input
          size="small"
          prefix={<Icon name="Search-outline" color="black50" size="16px" />}
          placeholder="Cari produk atau SKU"
          onChange={(event) => setSearchValue(event.target.value)}
          value={searchValue}
        />
      </StyledFlexInputSearch>
      <Flex columnGap="16px" overflowY="auto">
        {/* <Flex
          px="16px"
          py="6px"
          borderWidth="1px"
          borderStyle="solid"
          borderColor="black10"
          borderRadius="24px"
          cursor="pointer"
        >
          <Flex alignItems="center" columnGap="8px">
            <Icon name="Filter-solid" size={16} color="black75" />
            <Text variant="caption">Filter</Text>
          </Flex>
        </Flex> */}
        {isError ? (
          <Button onClick={refetch} size="medium">
            Muat Ulang
          </Button>
        ) : isAllLoading ? (
          range(3).map((_, indexSkeleton) => (
            <Skeleton
              key={`skeleton-loading-${indexSkeleton}`}
              height="34px"
              width="90px"
              borderRadius="24px"
            />
          ))
        ) : (
          listTabs.map((tab, indexTab) => (
            <Flex
              key={indexTab}
              padding="6px 16px"
              borderWidth="1px"
              borderStyle="solid"
              borderRadius="24px"
              cursor="pointer"
              alignItems="center"
              borderColor={tab.id === tabValue ? "blue50" : "black10"}
              backgroundColor={tab.id === tabValue ? "blue5" : "white"}
              minWidth="fit-content"
              onClick={() => {
                setTabValue(tab.id);
                setGTM("seller_center_section", {
                  event_category: "menu",
                  event_action: "click",
                  event_label: `my_product | ${
                    tab.property === "nonActive" ? "non-active" : tab.property
                  }`,
                });
              }}
            >
              <Text variant="caption">{`${tab.name} (${
                dataProductStatus[tab.property]
              })`}</Text>
            </Flex>
          ))
        )}
      </Flex>
    </Flex>
  );
};

export default Filter;
