import { Divider, Flex, Grid, range, Skeleton } from "renos-ui";

const LoadingModal = () => {
  return (
    <Flex gap="16px" flexDirection="column">
      <Skeleton height="27px" width="200px" />

      <Flex flexDirection="column" gap="12px">
        <Flex gap="12px" alignItems="center">
          <Skeleton height="24px" width="24px" />
          <Skeleton height="24px" width="200px" />
        </Flex>
        <Divider />
        <Grid gap="8px" gridTemplateColumns="196px 196px 196px">
          {range(12).map((_, indexSkeleton) => (
            <Flex
              gap="8px"
              key={`categories-skeleton-${indexSkeleton}`}
              alignItems="center"
            >
              <Skeleton height="24px" width="24px" />
              <Skeleton height="24px" width="100%" />
            </Flex>
          ))}
        </Grid>
      </Flex>
    </Flex>
  );
};

export default LoadingModal;
