import { maskAllName } from "helpers/string-mask";
import { useMemo } from "react";
import {
  Dropdown,
  Flex,
  Icon,
  numberInputChecking,
  Text,
  useDisclosure,
} from "renos-ui";

const ProductInfo = ({ data }) => {
  const notAllowedMask = [2, 3, 4, 5];
  const disclosureToggle = useDisclosure({ isOpen: false });

  const totalPrice = useMemo(() => {
    const sellerFleetAmount = data?.shipping?.is_seller_fleet
      ? parseInt(data?.shipping?.order_shipping_price)
      : 0;
    const voucherAmount =
      data.promo_used.length > 0 ? data.promo_used[0].seller_voucher_disc : 0;

    const commissionFee = data?.commission_fee || 0;

    return (
      data?.total_price + sellerFleetAmount + voucherAmount + commissionFee
    );
  }, [data]);

  return (
    <Flex
      flexDirection="column"
      justifyContent="space-between"
      width="50%"
      gap="40px"
    >
      <Flex gap="16px" flexDirection="column">
        <Flex gap="8px" alignItems="center">
          <Icon name="Profile-solid" color="black50" size={16} />
          <Text color="black50" weight="bold">
            {notAllowedMask.includes(data.order_status_id)
              ? data?.user_name
              : maskAllName(data?.user_name)}
          </Text>
        </Flex>
        {data.products.map((product, indexProduct) => {
          if (!disclosureToggle.isOpen && indexProduct !== 0) return "";

          return (
            <Flex gap="16px" key={`product-${indexProduct}`}>
              <Flex
                borderRadius="8px"
                height="64px"
                width="64px"
                as="img"
                alt="product-img"
                src={product.product_image_url}
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = "/assets/images/empty-product.svg";
                }}
              />

              <Flex flexDirection="column" gap="16px">
                <Flex flexDirection="column">
                  <Text
                    lineClamp={1}
                    variant="subtitle"
                    color="black75"
                    weight="bold"
                  >
                    {product.product_name}
                    {product.variant_name && ` - ${product.variant_name}`}
                  </Text>
                  <Text lineClamp={1} variant="subtitle" color="black75">
                    {product.order_product_quantity} x{" "}
                    {product.formatted_order_product_unit_price}
                  </Text>

                  {product.order_note && (
                    <Flex marginTop="8px" fontStyle="italic">
                      <Text lineClamp={1} color="black50">
                        “{product.order_note}”
                      </Text>
                    </Flex>
                  )}
                </Flex>
              </Flex>
            </Flex>
          );
        })}
        {data.products.length > 1 && (
          <Flex gap="16px">
            <Flex width="64px" />
            <Flex
              gap="8px"
              alignItems="center"
              cursor="pointer"
              onClick={disclosureToggle.onToggle}
            >
              {disclosureToggle.isOpen ? (
                <>
                  <Text color="blue50" weight="bold">
                    Lihat lebih sedikit
                  </Text>
                  <Icon name="Up-outline" color="blue50" size={20} />
                </>
              ) : (
                <>
                  <Text color="blue50" weight="bold">
                    Lihat {data.products.length - 1} Produk Lainnya
                  </Text>
                  <Icon name="Down-outline" color="blue50" size={20} />
                </>
              )}
            </Flex>
          </Flex>
        )}
      </Flex>

      <Flex
        padding="12px 16px"
        justifyContent="space-between"
        backgroundColor="black5"
        borderRadius="8px"
      >
        <Flex gap="8px" alignItems="center">
          <Dropdown trigger="hover">
            <Dropdown.Toggle>
              <Flex>
                <Icon name="Info-solid" color="black50" size={16} />
              </Flex>
            </Dropdown.Toggle>
            <Dropdown.Content positionDropdown="left">
              <Flex
                padding="12px 16px"
                flexDirection="column"
                gap="8px"
                width="300px"
              >
                <Flex
                  justifyContent="space-between"
                  gap="16px"
                  alignItems="center"
                >
                  <Text color="black50">
                    Total Harga ({data.products.length} barang)
                  </Text>
                  <Text color="black75" weight="bold">
                    {data.formatted_total_price}
                  </Text>
                </Flex>
                <Flex justifyContent="space-between" alignItems="center">
                  <Text color="black50">
                    Total Ongkir ({data.order_total_weight} kg)
                  </Text>
                  <Text color="black75" weight="bold">
                    {data.shipping.order_shipping_price_fmt}
                  </Text>
                </Flex>
                <Flex justifyContent="space-between" alignItems="center">
                  <Text color="black50">Commission Fee </Text>
                  <Text color="black75" weight="bold">
                    {data?.formatted_commission_fee}
                  </Text>
                </Flex>
                {!data.shipping.is_seller_fleet && (
                  <Flex justifyContent="space-between" alignItems="center">
                    <Text color="black50">Ongkir diteruskan ke kurir</Text>
                    <Text color="black75" weight="bold">
                      - {data.shipping.order_shipping_price_fmt}
                    </Text>
                  </Flex>
                )}
                {data.promo_used.length > 0 && (
                  <Flex justifyContent="space-between" alignItems="center">
                    <Text color="black50">Diskon Toko</Text>
                    <Text color="black75" weight="bold">
                      {data.promo_used[0].formatted_seller_voucher_disc}
                    </Text>
                  </Flex>
                )}
              </Flex>
            </Dropdown.Content>
          </Dropdown>

          <Text color="black50" weight="bold">
            Total Harga
          </Text>
        </Flex>
        <Text color="black75" weight="bold">
          Rp{" "}
          {numberInputChecking(totalPrice.toString() || "0", 0).formattedValue}
        </Text>
      </Flex>
    </Flex>
  );
};

export default ProductInfo;
