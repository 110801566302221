import { useEffect } from "react";
import { useMemo } from "react";
import { Box, Dialog, Divider, Flex, Text } from "renos-ui";
import { useGetOrderStatusHistory } from "services/order-management";
import Fail from "./Fail";
import ModalLoading from "./ModalLoading";

const ModalOrderStatus = ({ isOpen, onClose, data }) => {
  const {
    data: dataHistory,
    isLoading,
    isFetching,
    isError,
    refetch,
  } = useGetOrderStatusHistory({ order_id: data.order_id });

  const isAllLoading = useMemo(
    () => isLoading || isFetching,
    [isLoading, isFetching]
  );

  useEffect(() => {
    if (isOpen) {
      refetch();
    }
    // eslint-disable-next-line
  }, [isOpen]);

  return (
    <Dialog isVisible={isOpen} onClose={onClose} verticalCentered closable>
      <Flex width="320px" flexDirection="column" gap="24px">
        <Text variant="heading3" weight="bold">
          Status Pesanan
        </Text>

        {isAllLoading ? (
          <ModalLoading />
        ) : isError ? (
          <Fail refetch={refetch} />
        ) : (
          <>
            <Flex flexDirection="column" gap="16px">
              <Divider />
              <Flex flexDirection="column">
                <Text color="black75">
                  {dataHistory?.data[0].date.slice(0, 11)},{" "}
                  {dataHistory?.data[0].date.slice(12, 17)} WIB{" "}
                </Text>
                <Text color="blue50" weight="bold">
                  {data.order_number}
                </Text>
              </Flex>
              <Divider />
            </Flex>

            <Flex flexDirection="column">
              {dataHistory?.data.reverse().map((history, indexHistory) => (
                <Flex key={`history-${indexHistory}`}>
                  <Flex
                    flexDirection="column"
                    paddingRight="18px"
                    width="80px"
                    alignItems="flex-end"
                    borderRightColor="black10"
                    borderRightWidth="1px"
                    borderRightStyle="dashed"
                    position="relative"
                  >
                    <Text variant="caption" color="black50">
                      {history?.date.slice(0, 11)}{" "}
                    </Text>
                    <Text variant="caption" color="black50">
                      {history?.date.slice(12, 17)} WIB
                    </Text>

                    {indexHistory === 0 ? (
                      <Flex position="absolute" right="5.3px" top="5px">
                        <Flex position="relative" alignItems="center">
                          <Box
                            position="absolute"
                            backgroundColor="blue10"
                            height="12px"
                            width="12px"
                            borderRadius="50%"
                          />
                          <Box
                            position="absolute"
                            backgroundColor="blue50"
                            height="6px"
                            width="6px"
                            borderRadius="50%"
                            left="3px"
                          />
                        </Flex>
                      </Flex>
                    ) : (
                      <Box
                        position="absolute"
                        backgroundColor="black25"
                        height="8px"
                        width="8px"
                        borderRadius="50%"
                        right="-4.4px"
                        top="5px"
                      />
                    )}
                  </Flex>

                  <Flex
                    paddingLeft="18px"
                    flexDirection="column"
                    width="calc(100% - 80px)"
                    pb={indexHistory !== dataHistory.data.length - 1 && "24px"}
                  >
                    <Text
                      variant="caption"
                      color="black75"
                      weight={indexHistory === 0 && "bold"}
                    >
                      {history.status}
                    </Text>
                    <Text variant="caption" color="black50">
                      {history.description || "-"}
                    </Text>
                  </Flex>
                </Flex>
              ))}
            </Flex>
          </>
        )}
      </Flex>
    </Dialog>
  );
};

export default ModalOrderStatus;
