import { Flex, Text, Icon } from "renos-ui";
import { StyledDialog } from "../styled";
import LabelDetail from "./LabelDetail";

const ModalPrint = (props) => {
  return (
    <StyledDialog
      isVisible={props.isOpen}
      onClose={props.onClose}
      verticalCentered
    >
      <Flex width="526px" flexDirection="column" gap="24px">
        <Flex flexDirection="column" gap="16px">
          <Flex justifyContent="space-between" alignItems="center">
            <Text variant="heading3" weight="bold">
              Cetak Label
            </Text>
            <Icon
              name="Close-outline"
              cursor="pointer"
              size={24}
              color="black60"
              onClick={props.onClose}
            />
          </Flex>
        </Flex>
        <LabelDetail {...props} />
      </Flex>
    </StyledDialog>
  );
};

export default ModalPrint;
