import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Flex,
  get,
  Icon,
  Input,
  isEmpty,
  Label,
  pick,
  Spinner,
  Text,
  Tooltip,
  Upload,
  useDisclosure,
  useUpload,
} from "renos-ui";
import { useUploadProductImage } from "services/product";
import FormField from "./components/FormField";
import SectionWrapper from "./components/SectionWrapper";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { DragItem, DragItemOverlay, VideoPlayer } from "./styled";
import { useGetYoutubeData } from "services/global/youtube";
import {
  blobToBase64,
  getImageMeta,
  imageTypeChecking,
  imgCompressor,
  reactDndReorder,
  youtubeLinkValidator,
} from "helpers";
import { PRODUCT_SIDES, IMAGE_FILE_TYPE } from "./constants";
import { useFormContext, useWatch } from "react-hook-form";

const ProductAssets = ({ methods, isSubmitted }) => {
  const [productSide, setProductSide] = useState(PRODUCT_SIDES);
  const [imageList, setImageList] = useState([]);
  const [videoList, setVideoList] = useState([]);
  const [youtubeUrl, setYoutubeUrl] = useState();
  const [isVideoUrlInvalid, setIsVideoUrlInvalid] = useState();
  const [isPhotoInvalid, setIsPhotoInvalid] = useState();
  const [invalidMessage, setInvalidMessage] = useState();
  const [selectedVideo, setSelectedVideo] = useState();

  const { isOpen, onOpen, onClose } = useDisclosure({ open: false });

  const initialImages = useWatch({ name: "initialImages" });
  const formVideos = useWatch({ name: "videos" });

  useEffect(() => {
    if (isEmpty(initialImages)) return;
    imageList.length === 0 &&
      setImageList(
        initialImages.map((data) => ({
          ...data,
          imageUrl: data.product_image_url,
          image: data.product_image,
          image_type: data.image_type_id,
          image_id: data.product_image_id,
        }))
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialImages]);

  useEffect(() => {
    if (isEmpty(formVideos)) return;

    if (videoList.length === 0 && Array.isArray(formVideos)) {
      formVideos.forEach((vidData) => {
        getYoutubeData(vidData.product_video_url);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formVideos, videoList]);

  const {
    formState: { errors },
  } = useFormContext();

  const imageError = get(errors, "images");

  useEffect(() => {
    if (imageError) {
      setIsPhotoInvalid(true);
      setInvalidMessage(imageError.message);
    }
  }, [imageError]);

  useEffect(() => {
    let tempImageList = [...imageList];

    if (imageList.length > 0) {
      tempImageList = tempImageList.map((data) =>
        pick(data, ["image", "image_type", "image_id"])
      );
    }

    methods.setValue("images", tempImageList);

    if (isSubmitted) {
      methods.trigger("images");
    }
  }, [imageList, methods, isSubmitted]);

  useEffect(() => {
    let tempVideoList = [...videoList];

    if (videoList.length > 0) {
      tempVideoList = tempVideoList.map((data) => pick(data, ["url"]));
    }

    methods.setValue(
      "videos",
      JSON.stringify(tempVideoList.map((data) => data.url))
    );
  }, [videoList, methods]);

  const { mutate: uploadImage, isLoading: isUploadImageLoading } =
    useUploadProductImage({
      onSuccess: (data) => {
        setImageList((prev) => [
          ...prev,
          {
            imageType: imageList.length + 1,
            imageUrl: data.url,
            image: data.path,
            image_type: data.image_type_id,
            image_id: data.image_id,
          },
        ]);
      },
      onError: () => {
        setIsVideoUrlInvalid(true);
      },
    });
  const { mutate: getYoutubeData } = useGetYoutubeData({
    onSuccess: (data) => {
      const youtubeId = data.thumbnail_url
        .split("https://i.ytimg.com/vi/")[1]
        .split("/")[0];
      const embedUrl = `https://www.youtube.com/embed/${youtubeId}`;
      setVideoList((prev) => [
        ...prev,
        { ...data, url: youtubeUrl || data.url, youtubeEmbedUrl: embedUrl },
      ]);
      setYoutubeUrl("");
    },
  });

  const onAddYoutubeUrl = () => {
    if (youtubeLinkValidator(youtubeUrl)) {
      getYoutubeData(youtubeUrl);
    } else {
      setIsVideoUrlInvalid(true);
    }
  };

  const { register } = useUpload({
    fileType: "image",
    onRequest: async (file) => {
      const deepTypeChecking = await imageTypeChecking(file);

      if (deepTypeChecking) {
        setIsPhotoInvalid();
        setInvalidMessage();
        const result = await blobToBase64(file);
        const { naturalHeight, naturalWidth } = await getImageMeta(result);

        if (naturalHeight < 300 || naturalWidth < 300) {
          setIsPhotoInvalid(true);

          return null;
        }

        const formData = new FormData();
        const compressed =
          file.size > 400000
            ? await imgCompressor(file, {
                maxSizeMB: 0.4,
                maxWidthOrHeight: 1920,
                useWebWorker: true,
              })
            : file;

        formData.append("image", compressed);
        formData.append("image_type_id", imageList.length + 1);
        uploadImage(formData);
      } else {
        setIsPhotoInvalid(true);
        setInvalidMessage("Format gambar tidak sesuai");
      }
    },
    validator: (file) => {
      if (!IMAGE_FILE_TYPE.includes(file.type)) {
        setIsPhotoInvalid(true);
        setInvalidMessage("Format gambar tidak sesuai");

        return {
          message: "Format gambar tidak sesuai",
        };
      }

      return null;
    },
  });

  useEffect(() => {
    setProductSide(PRODUCT_SIDES.slice(imageList.length, PRODUCT_SIDES.length));
  }, [imageList]);

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const items = reactDndReorder(
      imageList,
      result.source.index,
      result.destination.index
    );

    setImageList(items);
  };

  const handleDeleteList = (index, state, setState) => {
    const tempImageList = [...state];
    tempImageList.splice(index, 1);
    setState(tempImageList);
    setIsPhotoInvalid();
    setInvalidMessage();
  };

  const onThumbnailClicked = (embedUrl) => {
    onOpen();
    setSelectedVideo(embedUrl);
  };

  const onDialogClosed = () => {
    setSelectedVideo();
    onClose();
  };

  return (
    <SectionWrapper sectionName="Upload Foto & Video" isError={imageError}>
      <Flex flexDirection="column" rowGap="40px">
        <FormField
          label="Foto Produk"
          isRequired
          hint={[
            <Text>
              Format gambar .jpg .jpeg .png dan ukuran minimum 300 x 300px.
            </Text>,
            <Text>
              Cantumkan min. 3 foto yang menarik agar produk semakin menarik
              pembeli.
            </Text>,
          ]}
        >
          <Flex flexDirection="column" rowGap="8px">
            <Flex position="relative">
              <Flex gap="16px" width="584px" justifyContent="flex-end">
                {productSide.map((side, i) => (
                  <Upload
                    {...register}
                    accept={IMAGE_FILE_TYPE}
                    key={`${side}${i}`}
                  >
                    <Flex
                      borderStyle="dashed"
                      borderWidth="1px"
                      borderColor={isPhotoInvalid ? "red50" : "black25"}
                      width="102px"
                      height="102px"
                      justifyContent="center"
                      alignItems="center"
                      borderRadius={8}
                      cursor={isUploadImageLoading ? "not-allowed" : "pointer"}
                      backgroundColor={
                        isUploadImageLoading && i === 0 ? "black5" : "white"
                      }
                    >
                      <Flex
                        flexDirection="column"
                        alignItems="center"
                        rowGap="8px"
                      >
                        {isUploadImageLoading && i === 0 ? (
                          <>
                            <Spinner />
                            <Text color="black75">loading..</Text>
                          </>
                        ) : (
                          <>
                            <Icon
                              name="Product-solid"
                              color="black50"
                              size={24}
                            />
                            <Text color="black75">{side}</Text>
                          </>
                        )}
                      </Flex>
                    </Flex>
                  </Upload>
                ))}
              </Flex>
              <Flex position="absolute">
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="droppable" direction="horizontal">
                    {(provided) => (
                      <Flex
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                      >
                        {imageList.map((item, index) => (
                          <Draggable
                            id={`item-${index + 1}`}
                            draggableId={`item-${index + 1}`}
                            index={index}
                          >
                            {(provided) => (
                              <DragItem
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                marginRight="16px"
                                id={`item-${index + 1}`}
                                key={`images-${index}`}
                                borderStyle="solid"
                                borderWidth="1px"
                                borderColor="black25"
                                width="102px"
                                height="102px"
                                borderRadius={8}
                                background={`url("${item.imageUrl}")`}
                                backgroundSize="contain"
                                backgroundRepeat="no-repeat"
                                backgroundPosition="center"
                                position="relative"
                                backgroundColor="white"
                              >
                                {index === 0 && (
                                  <Label
                                    position="absolute"
                                    top="8px"
                                    left="8px"
                                    variant="success"
                                    id="label-primary"
                                  >
                                    Utama
                                  </Label>
                                )}
                                <DragItemOverlay
                                  position="absolute"
                                  top="0"
                                  left="0"
                                  width="100%"
                                  height="100%"
                                  zIndex={1}
                                  cursor="default"
                                  alignItems="center"
                                  justifyContent="center"
                                >
                                  <Flex columnGap="4px">
                                    <Flex
                                      {...provided.dragHandleProps}
                                      backgroundColor="white"
                                      borderRadius="4px"
                                      opacity={1}
                                      width={24}
                                      height={24}
                                      alignItems="center"
                                      justifyContent="center"
                                    >
                                      <Tooltip
                                        width={120}
                                        label="Geser gambar untuk mengurutkan"
                                        textAlign="center"
                                        icon={
                                          <Icon name="Drag-outline" size={16} />
                                        }
                                      />
                                    </Flex>
                                    <Flex
                                      backgroundColor="white"
                                      borderRadius="4px"
                                      opacity={1}
                                      width={24}
                                      height={24}
                                      alignItems="center"
                                      justifyContent="center"
                                      cursor="pointer"
                                      onClick={() =>
                                        handleDeleteList(
                                          index,
                                          imageList,
                                          setImageList
                                        )
                                      }
                                    >
                                      <Tooltip
                                        label="Hapus Gambar"
                                        textAlign="center"
                                        width={88}
                                        icon={
                                          <Icon
                                            name="Trash-outline"
                                            size={16}
                                          />
                                        }
                                      />
                                    </Flex>
                                  </Flex>
                                </DragItemOverlay>
                              </DragItem>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </Flex>
                    )}
                  </Droppable>
                </DragDropContext>
              </Flex>
            </Flex>
            {isPhotoInvalid && (
              <Text variant="caption" color="red50" aria-label="input-error">
                {invalidMessage || "Resolusi gambar harus lebih dari 300x300"}
              </Text>
            )}
          </Flex>
        </FormField>
        <FormField
          label="Video Produk"
          hint="Copy link video produk kamu dan paste disini"
        >
          <Flex columnGap="16px">
            <Flex flexDirection="column" rowGap="20px" flex={1}>
              <Input
                placeholder="Masukkan link video produk"
                value={youtubeUrl}
                onChange={(event) => {
                  setIsVideoUrlInvalid(false);
                  setYoutubeUrl(event.target.value);
                }}
                isError={!isEmpty(isVideoUrlInvalid)}
                error="Link video yang kamu masukkan tidak benar"
              />
              {videoList.length > 0 &&
                videoList.map((video, index) => (
                  <Flex
                    padding="16px"
                    borderWidth="1px"
                    borderStyle="solid"
                    borderColor="black10"
                    alignItems="center"
                    borderRadius={8}
                    columnGap="24px"
                  >
                    <Flex
                      flex={1}
                      columnGap="16px"
                      alignItems="center"
                      key={video.thumbnail_url}
                    >
                      <Box
                        as="img"
                        cursor="pointer"
                        width="72px"
                        height="72px"
                        borderRadius="8px"
                        src={video.thumbnail_url}
                        alt="youtube-thumbnail"
                        onClick={() =>
                          onThumbnailClicked(video.youtubeEmbedUrl)
                        }
                      />
                      <Flex flexDirection="column" flex={1}>
                        <Text weight="bold" color="black75" lineClamp={1}>
                          {video.title}
                        </Text>
                        <Text color="black50" lineClamp={1}>
                          {video.url}
                        </Text>
                      </Flex>
                      <Icon
                        name="Close-solid"
                        color="black50"
                        cursor="pointer"
                        size={24}
                        onClick={() =>
                          handleDeleteList(index, videoList, setVideoList)
                        }
                      />
                    </Flex>
                  </Flex>
                ))}
            </Flex>
            <Button
              type="button"
              disabled={videoList.length === 3}
              onClick={onAddYoutubeUrl}
            >
              Tambah
            </Button>
          </Flex>
        </FormField>
      </Flex>
      <VideoPlayer
        padding="0"
        isVisible={isOpen}
        verticalCentered
        onClose={onDialogClosed}
        closable
      >
        <Flex
          width="800px"
          height="444px"
          borderRadius={16}
          as="iframe"
          border="none"
          src={selectedVideo}
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        />
      </VideoPlayer>
    </SectionWrapper>
  );
};

export default ProductAssets;
