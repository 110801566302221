import { pickAndSplitProductStatus } from "helpers";
import React, { useEffect, useMemo, useState } from "react";
import { useQueryClient } from "react-query";
import {
  Box,
  Button,
  Checkbox,
  Dropdown,
  Flex,
  Icon,
  Input,
  Label,
  Pagination,
  Text,
  Toggle,
  Tooltip,
  numberInputChecking,
  pick,
  useDebounce,
  useToast,
} from "renos-ui";
import {
  useDeactivateProduct,
  // useDeleteProduct,
  // useGetProducts,
  useGetProductsV3,
  useUpdateProductInline,
} from "services/product";
import Table from "widgets/desktop/Table";
import FilterModal from "./FilterModal";
import { TableSkeleton } from "./Skeleton";
import { ProductImage } from "./styled";
import ProductNotFound from "./ProductNotFound";
import FailLoadData from "widgets/global/FailLoadData";
import { clearEmpty } from "helpers/clear-empty";

const ProductTable = ({
  page,
  setPage,
  productStatus,
  dataProductStatus,
  activeTab,
  filterDisclosure,
  filterParam,
}) => {
  const queryClient = useQueryClient();
  const toast = useToast();

  const [isCheckAll, setIsCheckAll] = useState(false);
  const [checkedProducts, setCheckedProducts] = useState([]);
  const [listChangedData, setListChangedData] = useState([]);
  const [tempChangedData, setTempChangedData] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [validations, setValidations] = useState([]);

  const {
    data: productResponse,
    isLoading,
    isFetching,
    isError,
    refetch,
  } = useGetProductsV3(
    {
      ...clearEmpty(filterParam),
      page,
      product_status_ids: productStatus,
    },
    {
      onSuccess: (dataSuccess) => {
        const tempData = dataSuccess.data.map((productData) => {
          const { product_type } = productData;

          if (product_type === "variant") {
            productData.isHasMore = true;
          }

          return productData;
        });

        setDataSource([...tempData]);
      },
    }
  );

  const { mutate: deactivateProducts } = useDeactivateProduct({
    onSettled: () => {
      setIsCheckAll(false);
      setCheckedProducts([]);
      queryClient.invalidateQueries({
        queryKey: ["productsv3"],
      });
      queryClient.invalidateQueries({
        queryKey: ["productStatus"],
      });
    },
  });

  const { mutate: updateProductInline, isLoading: isUpdateLoading } =
    useUpdateProductInline({
      onSuccess: ({ body }) => {
        toast({
          label: `${handleTranslate(body.type)} produk berhasil diubah`,
          placement: "top",
        });
        setListChangedData([
          ...listChangedData.filter(
            (tempFilter) => tempFilter.dataEdit.product_id !== body.product_id
          ),
        ]);

        if (body.dataEdit?.product_type === "non_variant") {
          setDataSource([
            ...dataSource.map((product) => {
              const product_status_id =
                body.product_id === product.product_id
                  ? body.product_status_id
                  : product.product_status_id;

              const productPrice = product?.product_skus?.find(
                (sku) => sku.product_sku_id === product.product_sku_id
              )?.product_price;

              const product_price =
                body.product_id === product.product_id
                  ? body.product_price
                  : productPrice;

              const product_stock =
                body.product_id === product.product_id
                  ? body.product_stock
                  : product.product_stock;

              return {
                ...product,
                product_status_id,
                product_price,
                product_stock,
                product_skus:
                  product?.product_type === "non_variant"
                    ? product.product_skus.map((sku) => ({
                        ...sku,
                        product_status_id,
                        product_price,
                        product_stock,
                      }))
                    : product.product_skus,
              };
            }),
          ]);
        } else {
          const tempData = dataSource.map((product) => {
            const {
              product_skus,
              product_id,
              formatted_product_price,
              product_stock,
            } = product;

            if (body.product_id === product_id) {
              let tempProductSkus =
                body.dataEdit?.product_type === undefined
                  ? product_skus.map((sku) => ({
                      ...sku,
                      product_status_id:
                        body.type === "Status" &&
                        body.product_sku_id === sku.product_sku_id
                          ? body.product_status_id
                          : sku.product_status_id,
                      product_price:
                        body.type === "Price" &&
                        body.product_sku_id === sku.product_sku_id
                          ? body.product_price
                          : sku.product_price,
                      product_stock:
                        body.type === "Stock" &&
                        body.product_sku_id === sku.product_sku_id
                          ? body.product_stock
                          : sku.product_stock,
                    }))
                  : product_skus.map((sku) => ({
                      ...sku,
                      product_status_id: body.product_status_id,
                    }));

              let productStatusParent = body.product_status_id;
              let productPrice = formatted_product_price;
              let productStock = product_stock;

              productStatusParent =
                tempProductSkus.filter(
                  (filterSku) => filterSku.product_status_id === 1
                ).length > 0
                  ? 1
                  : 2;

              const isSingleItem = body.payload.product_skus?.length === 1;
              const prevTotalActiveStatus = product_skus.filter(
                (filterSku) => filterSku.product_status_id === 1
              ).length;

              if (
                body.type === "Status" &&
                prevTotalActiveStatus === 0 &&
                isSingleItem
              ) {
                tempProductSkus = tempProductSkus.map((tempSku) => ({
                  ...tempSku,
                  is_default:
                    tempSku.product_sku_id === body.product_sku_id ? 1 : 0,
                }));
              }

              if (body.type === "Stock")
                productStock = tempProductSkus.reduce(
                  (val, { product_stock }) => val + product_stock,
                  0
                );

              if (body.type === "Price") {
                const min = Math.min(
                  ...tempProductSkus.map((sku) => sku.product_price)
                );
                const max = Math.max(
                  ...tempProductSkus.map((sku) => sku.product_price)
                );
                productPrice =
                  min === max
                    ? `Rp ${
                        numberInputChecking(max.toString(), 0).formattedValue
                      }`
                    : `Rp ${
                        numberInputChecking(min.toString(), 0).formattedValue
                      } - ${
                        numberInputChecking(max.toString(), 0).formattedValue
                      }`;
              }

              return {
                ...product,
                product_status_id: productStatusParent,
                formatted_product_price: productPrice,
                product_stock: productStock,
                product_skus: tempProductSkus,
              };
            }

            return product;
          });

          setDataSource([...tempData]);
        }
      },
      onError: ({ body, response }) => {
        toast({
          label: response?.data?.front_end_message || `Produk gagal diubah`,
          placement: "top",
          backgroundColor: "red50",
        });
        const tempData = dataSource.map((product) => {
          const { product_id, product_skus } = product;

          if (body.product_id === product_id) {
            if (product_skus.length > 0) {
              const tempSkus = product_skus.map((sku) => {
                if (sku.product_sku_id === body.product_sku_id)
                  return body.dataEdit;

                return sku;
              });

              return { ...product, product_skus: tempSkus };
            }

            return body.dataEdit;
          }

          return product;
        });

        setDataSource([...tempData]);
      },
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: ["productStatus"],
        });
      },
    });

  // const { mutate: deleteProduct } = useDeleteProduct({
  //   onSettled: (data) => {
  //     queryClient.invalidateQueries({
  //       queryKey: ["products"],
  //     });
  //     queryClient.invalidateQueries({
  //       queryKey: ["productStatus"],
  //     });
  //   },
  // });

  const isNotReady = useMemo(
    () => isLoading || isFetching,
    [isLoading, isFetching]
  );

  const productsData = useMemo(
    () => productResponse?.data || [],
    [productResponse]
  );

  const productPagination = useMemo(
    () => productResponse?.pagination || {},
    [productResponse]
  );

  const onDeactivateProducts = () => {
    const payload = [];

    if (isCheckAll) {
      productsData.forEach((product) => {
        if (activeTab === "all" && product.product_status_id === 2) return;
        payload.push(...pickAndSplitProductStatus(product));
      });
    } else {
      productsData.forEach((product) => {
        if (checkedProducts.includes(product.product_id)) {
          if (activeTab === "all" && product.product_status_id === 2) return;
          payload.push(...pickAndSplitProductStatus(product));
        }
      });
    }

    const zeroProductStock = payload.filter(
      (sku) => sku.product_stock === 0 && sku.product_status_id === 1
    );

    if (zeroProductStock.length > 0 && activeTab === "Nonaktif") {
      return failedActivateStatus();
    }

    deactivateProducts({ product_skus: payload });
  };

  useEffect(() => {
    setIsCheckAll(false);
    setCheckedProducts([]);
    setValidations([]);
  }, [activeTab]);

  useEffect(() => {
    setCheckedProducts([]);
  }, []);

  const onCheckAllClicked = () => {
    if (checkedProducts.length < productsData.length) {
      setCheckedProducts(productsData.map((data) => data.product_id));
    }

    if (checkedProducts.length === productsData.length) {
      setCheckedProducts([]);
    }

    setIsCheckAll((prev) => !prev);
  };

  const onCheckProductClicked = (id) => {
    if (isCheckAll) {
      setIsCheckAll(false);
    }

    if (checkedProducts.includes(id)) {
      const selectedIndex = checkedProducts.findIndex((data) => data === id);

      const tempChecked = [...checkedProducts];
      tempChecked.splice(selectedIndex, 1);

      return setCheckedProducts(tempChecked);
    }
    setCheckedProducts((prev) => [...prev, id]);
  };

  const isInterdeterminate = useMemo(() => {
    if (checkedProducts && productsData) {
      return (
        checkedProducts.length > 0 &&
        checkedProducts.length < productsData.length
      );
    }

    return false;
  }, [checkedProducts, productsData]);

  const tableColumns = [
    {
      title: (
        <Checkbox
          checked={isCheckAll}
          onChange={onCheckAllClicked}
          indeterminate={isInterdeterminate}
        />
      ),
      dataIndex: "check",
      key: "checkBox",
      render: (_, data) => {
        const { product_id: id } = data;

        return (
          <Checkbox
            checked={checkedProducts.includes(id)}
            onChange={() => onCheckProductClicked(id)}
          />
        );
      },
      width: "40px",
      align: "center",
    },
    {
      title: "Informasi Produk",
      dataIndex: "product_information",
      key: "product_information",
      render: (_, data, isVariant) => {
        const {
          product_image_url,
          product_id,
          product_name,
          store_sku_number,
          formatted_variant_value_name,
          total_product_view,
          total_product_wishlist,
          total_sold,
        } = data;

        return (
          <Flex alignItems="center" columnGap="8px">
            <ProductImage
              as="img"
              src={product_image_url}
              alt={`product-img-${product_id}`}
              width="64px"
              height="64px"
              borderRadius="8px"
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = "/assets/images/empty-product.svg";
              }}
            />
            <Flex flexDirection="column" rowGap="4px">
              <Text weight="bold" lineClamp={1}>
                {isVariant ? formatted_variant_value_name : product_name}
              </Text>
              <Text variant="caption" color="black50">
                SKU: {store_sku_number}
              </Text>

              {!isVariant && (
                <Flex columnGap="4px">
                  <Flex alignItems="center" columnGap="4px">
                    <Icon name="Show-solid" color="black25" size={16} />
                    <Text width="40px" color="black50" variant="caption">
                      {total_product_view}
                    </Text>
                  </Flex>
                  <Flex alignItems="center" columnGap="4px">
                    <Icon name="Love-solid" color="black25" size={16} />
                    <Text width="40px" color="black50" variant="caption">
                      {total_product_wishlist}
                    </Text>
                  </Flex>
                  <Flex alignItems="center" columnGap="4px">
                    <Icon name="Cart-solid" color="black25" size={16} />
                    <Text width="40px" color="black50" variant="caption">
                      {total_sold}
                    </Text>
                  </Flex>
                </Flex>
              )}
            </Flex>
          </Flex>
        );
      },
    },
    {
      title: "",
      dataIndex: "is_default",
      key: "is_default",
      width: "100px",
      render: (_, data) => {
        const { is_default } = data;

        return is_default === 1 ? (
          <Label variant="success">Utama</Label>
        ) : (
          <Box />
        );
      },
    },
    {
      title: "Harga",
      dataIndex: "formatted_product_price",
      key: "formatted_product_price",
      render: (_, data, _variant) => {
        const { formatted_product_price, product_type, product_price } = data;

        const isVariant = product_type === "variant";

        const productDiscount = data?.is_product_discount_seasonal_active;

        const validation = validations.find(
          (validation) => validation.product_sku_id === data.product_sku_id
        );

        return !isVariant ? (
          <Box width="210px">
            {productDiscount ? (
              <Tooltip
                label={
                  <Flex>
                    Harga produk tidak bisa diubah karena <br /> produk sudah
                    diatur untuk promosi.
                  </Flex>
                }
                placement="bottom"
              >
                <Input.Number
                  size="small"
                  value={product_price || "0"}
                  prefix={
                    <Text weight="bold" color="black75">
                      Rp
                    </Text>
                  }
                  name="product-price"
                  disabled={true}
                />
              </Tooltip>
            ) : (
              <Input.Number
                size="small"
                value={product_price || "0"}
                prefix={
                  <Text weight="bold" color="black75">
                    Rp
                  </Text>
                }
                isError={validation?.is_error_price}
                error={validation?.message_price}
                name="product-price"
                onChange={(val) => handleEdit(val, data, "Price")}
                max={999999999}
                disabled={isUpdateLoading || productDiscount}
              />
            )}
          </Box>
        ) : (
          <Text color="black75">{formatted_product_price}</Text>
        );
      },
      width: "248px",
    },
    {
      title: "Stok",
      dataIndex: "product_stock",
      key: "product_stock",
      render: (_, data) => {
        const { product_stock, product_type } = data;

        const isVariant = product_type === "variant";
        const validation = validations.find(
          (validation) => validation.product_sku_id === data.product_sku_id
        );

        return !isVariant ? (
          <Box width="82px">
            <Input.Number
              size="small"
              isError={
                data.product_status_id === 1 && validation?.is_error_stock
              }
              error={data.product_status_id === 1 && validation?.message_stock}
              value={product_stock === 0 ? "0" : product_stock}
              onChange={(event) => handleEdit(event, data, "Stock")}
              max={99999}
              disabled={isUpdateLoading}
            />
          </Box>
        ) : (
          <Text color="black75">{data.product_stock}</Text>
        );
      },
      width: "108px",
    },
    {
      title: "Status",
      dataIndex: "product_status_id",
      key: "product_status_id",
      render: (_, data, isVariant) => {
        const isChecked = data.product_status_id === 1;

        return (
          <Box
            onClick={(event) => {
              !isUpdateLoading && handleEdit(event, data, "Status", isVariant);
            }}
          >
            <Toggle checked={isChecked} disabled={isUpdateLoading} />
          </Box>
        );
      },
      width: "80px",
    },
    {
      title: <></>,
      dataIndex: "cta",
      key: "cta",
      render: (_, data) => (
        <Dropdown>
          <Dropdown.Toggle>
            <Flex
              width="92px"
              height="40px"
              alignItems="center"
              justifyContent="space-between"
              border="1px"
              borderColor="black10"
              borderRadius={8}
              borderStyle="solid"
              px="12px"
              marginRight="4px"
              cursor="pointer"
            >
              <Text color="black75">Atur</Text>
              <Icon name="Down-outline" color="black50" size={20} />
            </Flex>
          </Dropdown.Toggle>
          <Dropdown.Content>
            <Dropdown.Item
              onClick={() =>
                (location.href = `/products/add?id=${data.product_id}`)
              }
            >
              <Flex px="16" py="6px">
                Edit Produk
              </Flex>
            </Dropdown.Item>
            {/* <Dropdown.Item onClick={() => deleteProduct(data.product_id)}>
              <Flex px="16" py="6px">
                Hapus Produk
              </Flex>
            </Dropdown.Item> */}
          </Dropdown.Content>
        </Dropdown>
      ),
      width: "100px",
    },
  ];

  const pageCount = useMemo(
    () => !isNotReady && productPagination?.last_page,
    [productPagination, isNotReady]
  );

  const handleTranslate = (type) => {
    switch (type) {
      case "Price":
        return "Harga";
      case "Stock":
        return "Stok";
      default:
        return type;
    }
  };

  const handleEdit = (val, dataEdit, editType, isVariant) => {
    const productPrice = dataEdit?.product_skus?.find(
      (sku) => sku.product_sku_id === dataEdit.product_sku_id
    )?.product_price;

    const rowData = {
      product_sku_id:
        dataEdit.product_sku_id === "-" ? null : dataEdit.product_sku_id,
      product_sku_number:
        dataEdit.product_sku_number === "-"
          ? null
          : dataEdit.product_sku_number,
      product_id: dataEdit.product_id,
      product_price: productPrice || dataEdit.product_price,
      product_stock: dataEdit.product_stock,
      product_status_id: dataEdit.product_status_id,
    };
    const product = dataSource.find(
      (product) => product.product_id === dataEdit.product_id
    );

    switch (editType) {
      case "Price":
        rowData.product_price = val;
        break;

      case "Stock":
        rowData.product_stock = val;
        break;

      case "Status":
        rowData.product_status_id = dataEdit.product_status_id === 1 ? 2 : 1;

        if (isVariant) {
          const productSku = dataSource.filter(
            (filterProduct) => filterProduct.product_id === dataEdit.product_id
          )[0]?.product_skus;
          const totalActive = productSku.filter(
            (filterSku) => filterSku.product_status_id === 1
          );

          if (dataEdit.product_status_id === 1 && dataEdit.is_default === 1) {
            if (totalActive.length !== 1) {
              toast({
                label: "Gagal menonaktifkan, Mohon ubah dulu variant utama.",
                placement: "top",
                backgroundColor: "red50",
              });

              return;
            }
          }
        }
        break;
      default:
        break;
    }

    const filteredData = listChangedData
      .filter(
        (tempFilter) => tempFilter.dataEdit.product_id !== dataEdit.product_id
      )
      .concat([{ ...rowData, type: editType, dataEdit, product, isVariant }]);

    setTempChangedData([...filteredData]);
  };

  const failedActivateStatus = (isVariant) => {
    const message = isVariant
      ? "variant yang stoknya 0."
      : "stok tidak boleh 0.";
    toast({
      label: `Gagal mengaktifkan, Mohon ubah dulu ${message}`,
      placement: "top",
      backgroundColor: "red50",
    });
  };

  useDebounce(
    () => {
      setListChangedData([...tempChangedData]);
      setTempChangedData([]);
      tempChangedData.forEach((changedData) => {
        const {
          type,
          product,
          dataEdit,
          isVariant,
          product_id,
          product_price,
          product_stock,
          product_sku_id,
          product_status_id,
        } = changedData;
        const productSkus = [];

        switch (type) {
          case "Price": {
            if (product_price === 0) {
              const priceValidation = validations.find(
                (validation) => validation.product_sku_id === product_sku_id
              );

              if (priceValidation) {
                priceValidation.is_error_price = true;
                priceValidation.message_price = "Harga wajib diisi.";
                setValidations([...validations]);
              } else {
                setValidations([
                  ...validations,
                  {
                    product_sku_id,
                    is_error_price: true,
                    message_price: "Harga wajib diisi.",
                  },
                ]);
              }

              return;
            } else {
              const priceValidation = validations.find(
                (validation) => validation.product_sku_id === product_sku_id
              );

              if (priceValidation) {
                priceValidation.is_error_price = false;
                priceValidation.message_price = "";
                setValidations([...validations]);
              }
            }
            break;
          }

          case "Stock": {
            if (product_stock === 0 && product_status_id === 1) {
              const stockValidation = validations.find(
                (validation) => validation.product_sku_id === product_sku_id
              );

              if (stockValidation) {
                stockValidation.is_error_stock = true;
                stockValidation.message_stock = "Stok wajib diisi.";
                setValidations([...validations]);
              } else {
                setValidations([
                  ...validations,
                  {
                    product_sku_id: product_sku_id,
                    is_error_stock: true,
                    message_stock: "Stok wajib diisi.",
                  },
                ]);
              }

              return;
            } else {
              const stockValidation = validations.find(
                (validation) => validation.product_sku_id === product_sku_id
              );

              if (stockValidation) {
                stockValidation.is_error_stock = false;
                stockValidation.message_stock = "";
                setValidations([...validations]);
              }
            }
            break;
          }
          default:
            break;
        }

        const prevProducSkus = product?.product_skus?.map((sku) =>
          pick(sku, [
            "product_sku_id",
            "product_status_id",
            "product_stock",
            "product_price",
            "is_default",
          ])
        );

        if (
          type === "Status" &&
          product?.product_type === "variant" &&
          !isVariant
        ) {
          const zeroStock = prevProducSkus.filter(
            (filterSku) => filterSku.product_stock === 0
          );

          if (product_status_id === 1 && zeroStock.length > 0) {
            return failedActivateStatus();
          }

          dataEdit?.product_skus?.forEach((productSku) => {
            productSkus.push({
              product_sku_id: productSku?.product_sku_id,
              product_status_id: product_status_id,
              product_stock: productSku?.product_stock,
              product_price: productSku?.product_price,
              is_default: productSku?.is_default,
            });
          });
        } else {
          const countActiveVariant = prevProducSkus?.filter(
            (sku) => sku.product_status_id === 1
          ).length;
          const sku = prevProducSkus?.find(
            (sku) => sku.product_sku_id === product_sku_id
          );

          if (
            sku.product_status_id === 2 &&
            type === "Status" &&
            sku.product_stock === 0
          ) {
            return failedActivateStatus(isVariant);
          }

          productSkus.push({
            product_sku_id,
            product_stock,
            product_price,
            product_status_id,
            is_default:
              countActiveVariant === 0 &&
              product?.product_type === "variant" &&
              type === "Status"
                ? 1
                : sku.is_default || dataEdit?.is_default,
          });
        }

        const body = {
          type,
          dataEdit,
          version: "v3",
          product_id,
          product_status_id,
          product_price,
          product_stock,
          product_sku_id,
          payload: { product_skus: productSkus },
        };

        updateProductInline(body);
      });
    },
    [tempChangedData],
    500
  );

  return (
    <Flex
      flexDirection="column"
      rowGap="24px"
      width="100%"
      alignItems="flex-end"
      height="100%"
      overflow="hidden"
    >
      {isNotReady ? (
        <TableSkeleton />
      ) : isError ? (
        <FailLoadData onClick={refetch} />
      ) : dataSource.length === 0 ? (
        <ProductNotFound />
      ) : (
        <Table
          columns={tableColumns}
          dataSource={dataSource}
          width="100%"
          minHeight={200}
          overflow="auto"
          customHeader={
            checkedProducts.length > 0 && (
              <Flex columnGap="20px" alignItems="center" pb="12px">
                <Checkbox
                  checked={isCheckAll}
                  onChange={onCheckAllClicked}
                  indeterminate={
                    checkedProducts.length > 0 &&
                    checkedProducts.length < productsData.length
                  }
                />
                <Flex alignItems="center" columnGap="24px">
                  <Text>
                    <strong>{checkedProducts.length}</strong> /{" "}
                    {dataProductStatus[activeTab]} Produk Dipilih
                  </Text>
                  <Flex columnGap="8px">
                    {/* <Button variant="tertiary" size="small">
                      Pindahkan Etalase
                    </Button> */}
                    <Button
                      variant="tertiary"
                      size="small"
                      onClick={onDeactivateProducts}
                    >
                      {activeTab === "nonActive" ? "Aktifkan" : "Nonaktifkan"}
                    </Button>
                    {/* <Button variant="tertiary" size="small">
                      Hapus Produk
                    </Button> */}
                  </Flex>
                </Flex>
              </Flex>
            )
          }
        />
      )}
      {!isError && (
        <Pagination
          current={page}
          onChange={(page) => {
            setPage(page);
          }}
          total={pageCount || Math.ceil(dataProductStatus?.[activeTab] / 40)}
        />
      )}

      <FilterModal disclosure={filterDisclosure} />
    </Flex>
  );
};

export default ProductTable;
