import { Flex, Skeleton, range } from "renos-ui";

const SkeletonList = () => {
  return (
    <Flex flexDirection="row" width="100%" gap="16px">
      {range(2).map((_, index) => (
        <Flex
          flexDirection="column"
          padding="24px"
          width="50%"
          border="1px solid"
          borderColor="black10"
          borderRadius="8px"
          key={index}
        >
          <Flex flexDirection="column" rowGap="8px" color="black75">
            <Flex justifyContent="space-between" gap="16px">
              <Skeleton height="30px" width="80%" />
              <Skeleton height="30px" width="20%" />
            </Flex>

            <Skeleton height="30px" width="40%" />
            <Skeleton height="30px" width="60%" />
            <Skeleton height="30px" width="100%" />
          </Flex>
        </Flex>
      ))}
    </Flex>
  );
};

export default SkeletonList;
