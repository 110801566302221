import { useState, useEffect } from "react";
import { setGTM } from "helpers";
import { useToast } from "renos-ui";
import { useGetCouriers, useUpdateCouriers } from "services/logistics/courier";

const useCouriers = () => {
  const toast = useToast();
  const [listCourier, setListCourier] = useState([]);

  const {
    data: couriersData,
    isLoading: isCouriersLoading,
    isError: isCouriersError,
    refetch: refetchGetCouriers,
    isFetching: isCouriersFetching,
  } = useGetCouriers();

  const { mutate } = useUpdateCouriers({
    onError: (error) => {
      const {
        response: {
          data: { frontend_message: message },
        },
      } = error;

      toast({
        label: message | "Pengaturan pengiriman gagal diubah.",
        placement: "bottom",
        backgroundColor: "red50",
      });
    },
    onSuccess: () => {
      toast({
        label: "Pengaturan pengiriman berhasil diubah.",
        placement: "bottom",
      });
      refetchGetCouriers();
    },
  });

  const handleCheckbox = (listIndex, serviceIndex) => {
    setListCourier((prevState) => {
      const tempList = [...prevState];
      const service = tempList[listIndex].services[serviceIndex];

      // Update the service
      const updatedService = {
        ...service,
        changed: true,
        assigned_to_user_store: !service.assigned_to_user_store,
      };

      // Update the tempList with the updated service
      tempList[listIndex].services[serviceIndex] = updatedService;

      // Perform other actions related to the checkbox
      setGTM("seller_center_section", {
        event_category: "store_settings | delivery",
        event_action: service.assigned_to_user_store ? "uncheck" : "check",
        event_label: `${tempList[listIndex].name} | ${service.name}`,
      });

      return tempList;
    });
  };

  const handleSave = () => {
    setGTM("seller_center_section", {
      event_category: "store_settings | delivery",
      event_action: "click",
      event_label: "save_settings",
    });

    const params = [];
    // eslint-disable-next-line
    listCourier.map((courier) => {
      // eslint-disable-next-line
      courier.services.map((service) => {
        const {
          id,
          changed,
          assigned_to_user_store: assignedToUserStore,
          tempAssigned,
        } = service;

        if (changed && assignedToUserStore !== tempAssigned) {
          params.push({
            mode: assignedToUserStore ? "assign" : "unassign",
            courier_service_id: id,
          });
        }
      });
    });
    const payload = { data: params };
    mutate(payload);
  };

  const changedChecker = () => {
    const findChanged = listCourier
      .map((courier) => ({
        ...courier,
        changedCount: courier.services.filter(
          (ftServices) => ftServices.changed
        ).length,
      }))
      .filter((ftChanged) => ftChanged.changedCount > 0).length;

    return findChanged;
  };

  useEffect(() => {
    if (!isCouriersLoading && !isCouriersError) {
      const modifyCouriersData = () => {
        const tempList = couriersData.data.map((courier) => ({
          ...courier,
          services: courier.services.map((service) => ({
            ...service,
            tempAssigned: service.assigned_to_user_store,
          })),
        }));
        const modulus = 4 - (tempList.length % 4);

        if (modulus) {
          for (let i = 0; i < modulus; i++) {
            tempList.push({ fakeCard: true, services: [] });
          }
        }

        return tempList;
      };

      const modifiedList = modifyCouriersData();
      setListCourier(modifiedList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCouriersLoading, isCouriersFetching]);

  return {
    listCourier,
    isCouriersError,
    isCouriersLoading,
    refetchGetCouriers,
    handleCheckbox,
    handleSave,
    setListCourier,
    changedChecker,
  };
};

export default useCouriers;
