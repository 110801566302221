import { Divider, Flex } from "renos-ui";
import Body from "./Body";
import Footer from "./Footer";
import Header from "./Header";

const Card = ({
  data,
  refetchList,
  refetchStatus,
  handleIsChecked,
  handleOnCheck,
}) => {
  return (
    <Flex
      borderWidth="1px"
      borderColor="black10"
      borderStyle="solid"
      borderRadius="16px"
      flexDirection="column"
    >
      <Header
        data={data}
        handleIsChecked={handleIsChecked}
        handleOnCheck={handleOnCheck}
      />
      <Divider />
      <Body data={data} />
      <Divider />
      <Footer
        data={data}
        refetchList={refetchList}
        refetchStatus={refetchStatus}
      />
    </Flex>
  );
};

export default Card;
