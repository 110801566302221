import useQueryParams from "hooks/useQueryParams";
import { useHistory } from "react-router-dom";
import { Box, Button, Divider, Each, Flex, Icon, Label, Text } from "renos-ui";

import { useGetBiddingMyOffer } from "services/bidding";
import EmptyList from "../EmptyList";
import LoadingMyOffer from "../LoadingMyOffer";
import desktopUrl from "constants/desktopUrl";

const MyOffer = () => {
  const query = useQueryParams();
  const history = useHistory();

  const { data, isFetching } = useGetBiddingMyOffer({
    query: query.get("q"),
    status: query.get("status"),
  });

  const quotations = data?.data || [];

  if (isFetching) return <LoadingMyOffer />;

  if (!quotations.length) return <EmptyList />;

  const formattedEndDate = (timestamp) =>
    new Intl.DateTimeFormat("id-ID", {
      dateStyle: "medium",
    }).format(new Date(timestamp * 1000));

  const quotationStatus = (status) => {
    let variant = "warning";

    switch (status.id) {
      case 2:
      case 6:
        variant = "success";
        break;
      case 3:
        variant = "neutral";
        break;
      default:
        variant = "warning";
        break;
    }

    return (
      <>
        <Label variant={variant} size="small">
          {status.name}
        </Label>
      </>
    );
  };

  return (
    <Flex flexDirection="column" gap="16px" marginTop={16}>
      <Each
        of={quotations}
        render={(quotation) => (
          <Flex
            flexDirection="column"
            gap="24px"
            backgroundColor="white"
            borderRadius={16}
            py={16}
            px={24}
            boxShadow="0px 4px 4px rgba(20, 20, 32, 0.05)"
          >
            <Flex
              gap="8px"
              alignItems="center"
              paddingBottom={24}
              borderBottomWidth={1}
              borderBottomStyle="solid"
              borderBottomColor="black10"
            >
              <Text color="black75">{quotation.request_number}</Text>
              {quotationStatus({
                id: quotation.quotation_status_id,
                name: quotation.quotation_status_remark,
              })}
              <Text color="black75">
                {" "}
                {formattedEndDate(quotation.request_end_date)}
              </Text>
            </Flex>
            <Flex gap="8px">
              <Icon name="Profile-solid" color="black50" size={24} />
              <Text color="black75">
                Penawaran untuk <Text weight="bold">{quotation.user_name}</Text>
              </Text>
            </Flex>
            <Flex justifyContent="space-between">
              <Flex gap="16px">
                <Box
                  as="img"
                  src={quotation.product_image_url}
                  width={90}
                  height={90}
                  borderRadius={8}
                />

                <Flex flexDirection="column" gap="4px">
                  <Text weight="bold">{quotation.product_sku_name}</Text>
                  <Text color="black75">
                    {quotation.formatted_quotation_product_price_per_unit} /
                    unit
                  </Text>
                  <Text color="black75" variant="caption">
                    {quotation.quotation_product_quantity} unit
                  </Text>
                </Flex>
              </Flex>
              <Flex flexDirection="column" textAlign="right">
                <Text variant="caption" color="black75">
                  Harga Penawaran
                </Text>
                <Text variant="subtitle" weight="bold" color="black75">
                  {quotation.formatted_total_quotation_price}
                </Text>
              </Flex>
            </Flex>
            <Divider />
            <Flex justifyContent="space-between" alignItems="center">
              {Boolean(quotation.quotation_other_products) && (
                <Text weight="bold" color="blue50">
                  +{quotation.quotation_other_products} produk lain
                </Text>
              )}
              <Flex flex={1} justifyContent="right">
                <Button
                  variant="secondary"
                  size="medium"
                  onClick={() =>
                    history.push(
                      `${desktopUrl.BIDDING}/${quotation.request_number}`
                    )
                  }
                >
                  Lihat Penawaran
                </Button>
              </Flex>
            </Flex>
          </Flex>
        )}
      />
    </Flex>
  );
};

export default MyOffer;
