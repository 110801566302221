import "./global.css";
import { BrowserRouter as Router } from "react-router-dom";
import RenosApp from "./routes";
import { QueryClient, QueryClientProvider } from "react-query";
import SwiperCore from "swiper";
import { Navigation, Pagination, Autoplay } from "swiper";
import "swiper/css/bundle";
import "swiper/css/navigation";
import "swiper/css/pagination";

import dayjs from "dayjs";
import "dayjs/locale/id"; // import locale
import OnlineStateProvider from "providers/OnlineStateProvider";
dayjs.locale("id"); // use locale

SwiperCore.use([Navigation, Pagination, Autoplay]);

function App() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  });

  return (
    <QueryClientProvider client={queryClient}>
      <OnlineStateProvider>
        <Router>
          <RenosApp />
        </Router>
      </OnlineStateProvider>
    </QueryClientProvider>
  );
}

export default App;
