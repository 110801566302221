import ReactApexCharts from "react-apexcharts";
import { WrapperChart } from "./styled";
import { chartLineConfig, chartBarConfig } from "./chartConfig";
import { useState, useMemo } from "react";
import { useGetNewOrder, useGetCancelOrder } from "services/statistic";
import { Flex, Skeleton, Text, Box } from "renos-ui";
import FailLoadData from "widgets/global/FailLoadData";

const Chart = ({ payload }) => {
  const dateType = payload?.params?.date_type;
  const [selectedLine, setSelectedLine] = useState(chartLineConfig(dateType));
  const [selectedBar, setSelectedBar] = useState(chartBarConfig);
  const [totalNewOrder, setTotalNewOrder] = useState(0);
  const [totalNewCancel, setTotalNewCancel] = useState(0);
  const { isError: isErrorNewOrder, isLoading: isLoadingNewOrder } =
    useGetNewOrder(payload, {
      onSuccess: ({ data }) => {
        const tempSelectedData = chartLineConfig(dateType);

        const listCategories = data.map((newOrder) => newOrder.time);
        tempSelectedData.options.xaxis.categories = listCategories;
        tempSelectedData.options.labels[0].data = data.map(
          (newOrder) => newOrder.date_info
        );
        tempSelectedData.options.labels[1].data = listCategories;

        tempSelectedData.series[0].data = data.map(
          (newOrder) => newOrder.total
        );

        const sums = data.reduce(function (accumulator, curValue) {
          return accumulator + curValue.total;
        }, 0);

        setTotalNewOrder(sums);
        setSelectedLine(tempSelectedData);
      },
    });
  const { isError: isErrorCancelOrder, isLoading: isLoadingCancelOrder } =
    useGetCancelOrder(payload, {
      onSuccess: ({ data }) => {
        const tempSelectedData = chartBarConfig;
        const listCategories = data.slice(0, -1).map((newCancel) => {
          return newCancel.total;
        });
        tempSelectedData.options.xaxis.categories = listCategories;
        const labelBar = data.slice(0, -1).map((newCancel) => {
          return newCancel.order_status_description;
        });

        tempSelectedData.options.xaxis.categories = labelBar;

        tempSelectedData.series[0].data = data
          .slice(0, -1)
          .map((newCancel) => newCancel.total);

        const sums = data.slice(0, -1).reduce(function (accumulator, curValue) {
          return accumulator + curValue.total;
        }, 0);

        setTotalNewCancel(sums);
        setSelectedBar(tempSelectedData);
      },
    });

  const getDateTypeName = (dateType) => {
    switch (dateType) {
      case "monthly":
        return "Bulan";
      case "daily":
        return "Hari";
      default:
        return "Minggu";
    }
  };

  const isAccumulateLoading = useMemo(
    () => isLoadingNewOrder || isLoadingCancelOrder,
    [isLoadingNewOrder, isLoadingCancelOrder]
  );

  const isAccumulateError = useMemo(
    () => isErrorNewOrder || isErrorCancelOrder,
    [isErrorNewOrder, isErrorCancelOrder]
  );

  return (
    <Flex>
      {isAccumulateLoading ? (
        <Skeleton height="420px" />
      ) : isAccumulateError ? (
        <Flex height="420px">
          <FailLoadData
            onClick={() => alert("here")}
            noImg
            isLoading={isAccumulateLoading}
          />
        </Flex>
      ) : (
        <Flex flex="1" gap="1rem">
          <Flex width="50%">
            <WrapperChart>
              <Flex flexDirection="column">
                <Text variant="heading3" weight="bold">
                  Pesanan Baru
                </Text>
                <Box>
                  <Text>
                    Total <b>{totalNewOrder}</b> Pesanan Baru{" "}
                  </Text>
                  <Text weight="bold">{getDateTypeName(dateType)} ini</Text>
                </Box>
              </Flex>
              <ReactApexCharts
                options={selectedLine?.options}
                series={selectedLine?.series}
                type="line"
                height={420}
                width="100%"
              />
            </WrapperChart>
          </Flex>
          <Flex width="50%">
            <WrapperChart>
              <Flex flexDirection="column">
                <Text variant="heading3" weight="bold">
                  Tipe Pembatalan
                </Text>
                <Box>
                  <Text>
                    Total <b>{totalNewCancel}</b> Pesanan Dibatalkan{" "}
                  </Text>
                  <Text weight="bold">{getDateTypeName(dateType)} ini</Text>
                </Box>
              </Flex>
              <ReactApexCharts
                options={selectedBar?.options}
                series={selectedBar?.series}
                type="bar"
                height={420}
                width="100%"
              />
            </WrapperChart>
          </Flex>
        </Flex>
      )}
    </Flex>
  );
};

export default Chart;
