import { Flex, Text } from "renos-ui";

const Empty = () => {
  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      padding="24px"
      gap="40px"
      flexDirection="column"
      textAlign="center"
      marginTop="24px"
    >
      <Flex
        as="img"
        width="200px"
        height="160px"
        src="/assets/images/order-management/no-order.svg"
      />

      <Flex gap="8px" flexDirection="column">
        <Text variant="heading3" weight="bold">
          Belum Ada Pesanan
        </Text>
        <Flex width="284px" marginBottom="43px">
          <Text>
            Jangan menyerah ya, tetap semangat. Rezeki tidak akan kemana.
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Empty;
