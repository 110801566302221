import React from "react";
import { Flex, Text, Label, Icon, Box, Input, colors } from "renos-ui";
import { Wrapper } from "../styled";

const CardLocation = (props) => {
  const {
    item,
    checkedAutomate,
    changeDefault,
    index,
    toggleDrawer,
    handleSelect,
  } = props;

  return (
    <Box>
      <Flex
        flexDirection="column"
        key={`template-${index}`}
        backgroundColor="white"
        p="16px"
        boxSizing="border-box"
        borderRadius="8px"
      >
        <Flex justifyContent="space-between">
          <Flex alignItems="center" gap="16px">
            <Flex flexDirection="column" gap="6px">
              <Flex justifyContent="space-between">
                <Text weight="bold">{item.name}</Text>
              </Flex>
            </Flex>
            <Flex>
              {item?.default === true ? (
                <Label
                  variant={checkedAutomate === true ? "success" : "neutral"}
                >
                  Balasan Otomatis
                </Label>
              ) : (
                <></>
              )}
            </Flex>
          </Flex>

          {changeDefault === true ? (
            <Flex>
              <Input.Radio
                name="default"
                key={index}
                onChange={() => handleSelect(index)}
              />
            </Flex>
          ) : !item.default ? (
            <Flex
              width="24px"
              height="24px"
              border={item.link !== null ? <></> : `solid 1px ${colors.blue50}`}
              borderRadius="100%"
              cursor="pointer"
              alignItems="center"
              justifyContent="center"
              onClick={() => toggleDrawer(item)}
              gap="16px"
            >
              <Icon
                name={item.link !== null ? "Vertical-outline" : "Right-outline"}
                color={item.link !== null ? "black50" : "blue50"}
                size={16}
              />
            </Flex>
          ) : (
            <></>
          )}
        </Flex>
        <Wrapper>
          <Text variant="caption">{item.message}</Text>
        </Wrapper>
      </Flex>
      {index !== 4 && <Flex height="8px" backgroundColor="black5"></Flex>}
    </Box>
  );
};

export default CardLocation;
