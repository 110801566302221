import desktopUrl from "constants/desktopUrl";
import React from "react";
import { useHistory } from "react-router-dom";
import { Button, Dropdown, Flex, Icon, Text } from "renos-ui";

const TopBar = () => {
  const history = useHistory();

  const goTo = (tab) => {
    history.push(`${desktopUrl.PRODUCTS_ADD_MULTIPLE}?tab-active=${tab}`);
  };

  return (
    <Flex
      height="80px"
      backgroundColor="white"
      borderTopLeftRadius="16px"
      borderTopRightRadius="16px"
      boxShadow="0px 4px 4px rgba(20, 20, 32, 0.05)"
      justifyContent="space-between"
      alignItems="center"
      paddingX="24px"
    >
      <Text variant="heading3" weight="bold">
        Daftar Produk
      </Text>
      <Flex columnGap="16px">
        <Dropdown>
          <Dropdown.Toggle>
            <Button
              variant="tertiary"
              size="large"
              suffix={<Icon name="Down-outline" />}
            >
              Atur Sekaligus
            </Button>
          </Dropdown.Toggle>
          <Dropdown.Content>
            <Dropdown.Item onClick={() => goTo("add-product")}>
              <Flex px="16" py="6px">
                Tambah Produk Sekaligus
              </Flex>
            </Dropdown.Item>
            <Dropdown.Item onClick={() => goTo("edit-product")}>
              <Flex px="16" py="6px">
                Ubah Produk Sekaligus
              </Flex>
            </Dropdown.Item>
          </Dropdown.Content>
        </Dropdown>
        <Button
          size="large"
          preffix={<Icon name="Plus-outline" />}
          onClick={() => history.push(desktopUrl.PRODUCTS_ADD)}
        >
          Tambah Produk
        </Button>
      </Flex>
    </Flex>
  );
};

export default TopBar;
