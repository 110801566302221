import { Button, Flex, Text } from "renos-ui";

const DrawerDelete = ({ item, onClose, onDelete, isFetching }) => {
  return (
    <Flex pb="16px" gap="16px" flexDirection="column">
      <Text variant="subtitle" weight="bold">
        Hapus Template ini?
      </Text>
      <Text>Pastikan kamu sudah memilih template yang benar yaa!</Text>
      <Flex justifyContent="space-between" marginTop="7px" gap="16px">
        <Button
          isBlock
          size="medium"
          variant="tertiary"
          disabled={isFetching}
          onClick={() => onDelete(item.id)}
        >
          {isFetching ? "Menghapus..." : "Hapus"}
        </Button>
        <Button
          isBlock
          size="medium"
          variant="primary"
          disabled={isFetching}
          onClick={onClose}
        >
          Kembali
        </Button>
      </Flex>
    </Flex>
  );
};

export default DrawerDelete;
