import { Box, Flex, Text, cloneDeep, useDisclosure, useToast } from "renos-ui";
import Header from "./component/Header";
import Footer from "./component/Footer";
import { useEffect, useMemo, useState } from "react";
import InformationSection from "./component/InformationSection";
import PeriodSection from "./component/PeriodSection";
import {
  useAddVoucher,
  useEditVoucher,
  useGetVoucherById,
  useGetVoucherMaster,
} from "services/voucher-management";
import TypeSection from "./component/TypeSection";
import SettingSection from "./component/SettingSection";
import ProductSection from "./component/ProductSection";
// import Approval from "./component/Approval";
import {
  dataEditFormatter,
  dataPostFormatter,
  handleError,
} from "./useVoucher";
import { useHistory } from "react-router-dom";
import desktopUrl from "constants/desktopUrl";
import ModalSuccess from "./component/ModalSuccess";
import useQueryParams from "hooks/useQueryParams";
import ModalViewVoucher from "./component/ModalViewVoucher";
import { checkWhitespace } from "helpers";

const VoucherManagementAdd = () => {
  const toast = useToast();
  const query = useQueryParams();
  const history = useHistory();
  const { isOpen, onOpen } = useDisclosure({ isOpen: false });

  const {
    isOpen: isOpenView,
    onOpen: onOpenView,
    onClose: onCLoseView,
  } = useDisclosure({ isOpen: false });

  const [promoId, setPromoId] = useState("");
  const [state, setState] = useState("");

  const {
    isLoading: isLoadingMaster,
    data: dataMaster,
    isFetching: isFetchingMaster,
    refetch: refetchMaster,
    isError: isErrorMaster,
  } = useGetVoucherMaster();

  const { mutate: addVoucher, isLoading: isLoadingAdd } = useAddVoucher({
    onSuccess: (data) => {
      onOpen();
    },
    onError: (data) => {
      const {
        response: {
          data: { frontend_message, message, data: dataIds },
        },
      } = data;

      setInvalidProduct(dataIds || []);

      toast({
        label: frontend_message || message || "Gagal membuat voucher",
        placement: "top",
        backgroundColor: "red50",
      });
    },
  });

  const { mutate: editVoucher, isLoading: isLoadingEdit } = useEditVoucher({
    onSuccess: (data) => {
      onOpen();
    },
    onError: (data) => {
      const {
        response: {
          data: { frontend_message, message, data: dataIds },
        },
      } = data;

      setInvalidProduct(dataIds || []);

      toast({
        label: frontend_message || message || "Gagal mengubah voucher",
        placement: "top",
        backgroundColor: "red50",
      });
    },
  });

  const { mutate: getById, isLoading: isGetByIdLoading } = useGetVoucherById({
    onSuccess: (dataSuccess) => {
      setValues(dataEditFormatter(dataSuccess.data));
      const isDuplicate = query.get("duplicate");

      if (isDuplicate) {
        history.replace({
          search: "",
        });
      } else {
        setState(
          dataSuccess?.data?.status === "Berlangsung" ||
            dataSuccess?.data?.status === "Menunggu"
            ? "edit"
            : "ended"
        );
      }
    },
    onError: () => {
      history.push(desktopUrl.VOUCHER);
    },
  });

  const [values, setValues] = useState({
    description: "",
    name: "",
    startDate: "",
    endDate: "",
    voucherTypeId: 1,
    voucherTargetId: 1,
    voucherDiscountTypeId: 1,
    discountTypeId: 2,
    nominalDiscount: "",
    quota: "",
    limitation: "",
    minPurchase: 0,
    // approved: false,
  });
  const [invalidProduct, setInvalidProduct] = useState([]);
  const [errors, setErrors] = useState({});
  const [errorMsg, setErrorMsg] = useState({});

  const handleSave = () => {
    const isError = handleError(values, setErrors, setErrorMsg, isOngoing);

    if (isError) {
      toast({
        label: "Mohon lengkapi semua data wajib diisi!",
        placement: "top",
        backgroundColor: "red50",
      });

      return;
    }

    const params = cloneDeep(values);

    if (checkWhitespace(params.description)) delete params.description;

    if (promoId) {
      editVoucher(dataPostFormatter({ ...params, promoId }));
    } else {
      addVoucher(dataPostFormatter(params));
    }
  };

  const handleView = () => {
    const isError = handleError(values, setErrors, setErrorMsg);

    if (isError) {
      toast({
        label: "Mohon lengkapi semua data wajib diisi untuk melihat tampilan!",
        placement: "top",
        backgroundColor: "red50",
      });

      return;
    }
    onOpenView();
  };

  const isAllLoading = useMemo(
    () => isLoadingAdd || isLoadingMaster || isGetByIdLoading || isLoadingEdit,
    [isLoadingAdd, isLoadingMaster, isGetByIdLoading, isLoadingEdit]
  );

  const disabledAll = useMemo(
    () => state === "ended" || isAllLoading || query.get("view") === "true",
    // eslint-disable-next-line
    [state, isAllLoading]
  );

  const titleText = useMemo(
    () => (!state ? "Buat" : query.get("view") === "true" ? "Lihat" : "Ubah"),
    [state, query]
  );

  const isOngoing = useMemo(() => values?.status === "Berlangsung", [values]);

  useEffect(() => {
    const idVoucher = query.get("id");
    const isDuplicate = query.get("duplicate");

    if (idVoucher) {
      getById(idVoucher);

      if (!isDuplicate) setPromoId(idVoucher);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Flex flexDirection="column" gap="8px" width="952px">
      <Header isLoading={isAllLoading} />
      <Flex
        backgroundColor="white"
        padding="24px"
        gap="24px"
        flexDirection="column"
      >
        <Flex gap="16px" alignContent="center">
          <Box
            backgroundColor="blue50"
            width="4px"
            orientation="vertical"
            borderRadius="8px"
            height="24px"
          />
          <Text variant="subtitle" color="black75" weight="bold">
            {`${titleText} Voucher Toko`}
          </Text>
        </Flex>
        <Flex gap="48px" flexDirection="column">
          <TypeSection
            {...{
              values,
              setValues,
              errors,
              setErrors,
              isLoadingData: isLoadingMaster || isFetchingMaster,
              data: dataMaster,
              refetchData: refetchMaster,
              isGetDataError: isErrorMaster,
              isLoadingAdd: isLoadingAdd || isLoadingEdit,
              disabled: state === "edit" || disabledAll,
            }}
          />
          <InformationSection
            {...{
              values,
              setValues,
              errors,
              setErrors,
              isLoadingData: isLoadingMaster,
              isLoadingAdd: isLoadingAdd || isLoadingEdit,
              disabled: disabledAll,
              isOngoing,
            }}
          />
          <PeriodSection
            {...{
              values,
              setValues,
              errors,
              setErrors,
              errorMsg,
              setErrorMsg,
              isLoadingAdd: isLoadingAdd || isLoadingEdit,
              disabled: disabledAll,
              isOngoing,
            }}
          />
          <SettingSection
            {...{
              values,
              setValues,
              errors,
              setErrors,
              isLoadingData: isLoadingMaster || isFetchingMaster,
              data: dataMaster,
              refetchData: refetchMaster,
              isGetDataError: isErrorMaster,
              isLoadingAdd: isLoadingAdd || isLoadingEdit,
              errorMsg,
              disabled: disabledAll,
              isOngoing,
            }}
          />

          {values.voucherTypeId === 2 && (
            <ProductSection
              disabled={disabledAll}
              isOngoing={isOngoing}
              isError={errors.product}
              value={values.product}
              onChange={(newValue) => {
                setValues({ ...values, product: newValue });
                setErrors({ ...errors, product: false });
              }}
              invalid={invalidProduct}
            />
          )}

          {/* {!disabledAll && (
            <Approval
              isLoading={isLoadingAdd}
              value={values.approved}
              isError={errors.approved}
              onClick={() => {
                setValues({ ...values, approved: !values.approved });
                setErrors({ ...errors, approved: false });
              }}
            />
          )} */}
        </Flex>
      </Flex>
      <Footer
        isLoading={isAllLoading}
        onSave={handleSave}
        onView={handleView}
        onCancel={() => history.push(desktopUrl.VOUCHER)}
        viewMode={disabledAll}
      />
      <ModalSuccess
        {...{
          isOpen,
          startDate: values.startDate,
          endDate: values.endDate,
          isEdit: promoId,
        }}
      />
      <ModalViewVoucher
        {...{
          isOpen: isOpenView,
          onClose: onCLoseView,
          values,
          dataMaster,
        }}
      />
    </Flex>
  );
};

export default VoucherManagementAdd;
