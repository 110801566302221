import styled, { css } from "styled-components";

const Triangle = styled.div`
  position: absolute;
  width: 0;
  height: 0;

  ${({ isMobile }) =>
    isMobile &&
    css`
      left: 50%;
      transform: translate(-50%, 0);
    `}
`;

export const TriangleFirst = styled(Triangle)`
  ${({ isMobile }) =>
    isMobile
      ? css`
          top: 182px;
          border-top: 40px solid white;
          border-right: 20px solid transparent;
          border-left: 20px solid transparent;
        `
      : css`
          top: 81px;
          left: -18%;
          border-right: 50px solid white;
          border-bottom: 24px solid transparent;
          border-top: 24px solid transparent;
        `};
`;

export const TriangleTwo = styled(Triangle)`
  ${({ isMobile }) =>
    isMobile
      ? css`
          top: 174px;
          border-top: 40px solid white;
          border-right: 20px solid transparent;
          border-left: 20px solid transparent;
        `
      : css`
          top: 81px;
          left: -18%;
          border-right: 50px solid white;
          border-bottom: 24px solid transparent;
          border-top: 24px solid transparent;
        `};
`;

export const TriangleThree = styled(Triangle)`
  ${({ isMobile }) =>
    isMobile
      ? css`
          top: -48px;
          right: 24%;
          border-bottom: 50px solid white;
          border-right: 20px solid transparent;
          border-left: 20px solid transparent;
        `
      : css`
          top: 81px;
          right: -18%;
          border-left: 50px solid white;
          border-bottom: 24px solid transparent;
          border-top: 24px solid transparent;
        `};
`;
