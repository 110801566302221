import { useMemo } from "react";
import { Flex, Text, Icon } from "renos-ui";

const ProductCard = ({ data, isBestSeller }) => {
  const selectedData = useMemo(
    () =>
      data?.product_skus?.length > 0 && !isBestSeller
        ? data?.product_skus[0]
        : data,
    // eslint-disable-next-line
    [data]
  );

  return (
    <Flex flexDirection="column" width="115px">
      <Flex
        as="img"
        src={selectedData?.product_image_url}
        height="101px"
        borderTopLeftRadius="4px"
        borderTopRightRadius="4px"
        borderColor="black10"
        borderWidth="1px"
        borderStyle="solid"
        borderBottomWidth="0"
        alt="product-image"
        onError={(e) => {
          e.target.onerror = null;
          e.target.src = "/assets/images/empty-product.svg";
        }}
      />
      <Flex
        flexDirection="column"
        height="70px"
        borderBottomLeftRadius="4px"
        borderBottomRightRadius="4px"
        borderColor="black10"
        borderWidth="1px"
        borderStyle="solid"
        padding="8px"
        justifyContent="space-between"
      >
        <Flex gap="2px" flexDirection="column">
          <Text color="black75" variant="small" lineClamp={1}>
            {selectedData?.product_name}
          </Text>
          <Text color="black100" weight="bold" variant="small">
            {selectedData?.formatted_selling_price}
          </Text>
          {selectedData?.formatted_product_discount_percentage && (
            <Flex gap="4px" alignItems="center">
              <Flex
                padding="1px 4px"
                borderRadius="16px"
                backgroundColor="red5"
              >
                <Text variant="small" color="red50" weight="bold">
                  {selectedData?.formatted_product_discount_percentage}
                </Text>
              </Flex>
              <Text variant="small" strikeThrough color="black25">
                {selectedData?.formatted_product_price}
              </Text>
            </Flex>
          )}
        </Flex>
        <Flex gap="10px" alignItems="center">
          <Flex alignItems="center" gap="2px">
            <Icon name="Star-solid" color="yellow50" size={8} />
            <Text variant="small" color="black50">
              {data?.review_rating}
            </Text>
          </Flex>
          <Text variant="small" color="black50">
            {data?.review_count} ulasan
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default ProductCard;
