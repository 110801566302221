import { Flex, range, Skeleton, Box } from "renos-ui";

const Loading = () => {
  return (
    <Flex gap="24px" height="381px">
      <Flex width="304px" flexDirection="column" justifyContent="space-between">
        <Flex flexDirection="column" gap="24px">
          <Flex flexDirection="column" gap="8px">
            {range(4).map((_, IndexSkeletonSeller) => (
              <Flex key={`skeleton-info${IndexSkeletonSeller}`} gap="24px">
                <Skeleton width="96px" height="24px" />
                <Skeleton width="calc(100% - 120px)" height="24px" />
              </Flex>
            ))}
          </Flex>
          <Flex flexDirection="column" gap="8px">
            {range(2).map((_, IndexSkeletonBuyer) => (
              <Flex
                key={`skeleton-info-seller${IndexSkeletonBuyer}`}
                gap="24px"
              >
                <Skeleton width="96px" height="24px" />
                <Skeleton width="calc(100% - 120px)" height="24px" />
              </Flex>
            ))}
          </Flex>
        </Flex>
      </Flex>
      <Flex
        width="calc(100% - 328px)"
        flexDirection="column"
        borderColor="black10"
        borderWidth="1px"
        borderStyle="solid"
        borderRadius="16px"
        padding="16px"
        overflowX="auto"
      >
        {range(4).map((_, indexSkeleton) => (
          <Flex key={`history-${indexSkeleton}`}>
            <Flex
              flexDirection="column"
              paddingRight="18px"
              width="80px"
              alignItems="flex-end"
              borderRightColor="black10"
              borderRightWidth="1px"
              borderRightStyle="dashed"
              position="relative"
              gap="4px"
              pb={indexSkeleton !== 3 && "24px"}
            >
              <Skeleton height="24px" width="80px" />
              <Skeleton height="24px" width="80px" />
              <Box
                position="absolute"
                backgroundColor="black25"
                height="8px"
                width="8px"
                borderRadius="50%"
                right="-4.4px"
              />
            </Flex>

            <Flex
              paddingLeft="18px"
              flexDirection="column"
              gap="4px"
              width="calc(100% - 80px)"
            >
              <Skeleton height="24px" width="200px" />
              <Skeleton height="24px" width="200px" />
            </Flex>
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
};

export default Loading;
