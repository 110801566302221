import StoreLayoutContext from "providers/StoreLayoutProvider";
import SwiperContext, { SwiperProvider } from "providers/SwiperProvider";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Flex, Icon, range } from "renos-ui";
import { SwiperSlide, useSwiper } from "swiper/react";
import { StyledSwiper } from "./styled";

const Banner = ({ isPreviewMode, id }) => {
  const { bannerRef, banner2Ref, dataHomePage, isHomePageLoading } =
    useContext(StoreLayoutContext);

  const [dataBanner, setDataBanner] = useState();
  const [swiperInstance, setSwiperInstance] = useState();

  const imageTypeId = useMemo(
    () => dataBanner?.banner?.image_type?.id,
    [dataBanner]
  );

  const refUsed = useMemo(
    () => (id === 1 ? bannerRef : banner2Ref),
    // eslint-disable-next-line
    [id]
  );

  useEffect(() => {
    const selectedBanner = dataHomePage.filter(
      (homepage) => homepage.id === id
    )[0];

    setDataBanner(selectedBanner);
    // eslint-disable-next-line
  }, [dataHomePage]);

  if (isPreviewMode && !dataBanner?.isActive) return <></>;

  if (isHomePageLoading) {
    if (imageTypeId === 1)
      return (
        <Flex
          height="365px"
          backgroundColor="black10"
          borderRadius="10px"
          ref={bannerRef}
        />
      );
    if (imageTypeId === 2)
      return (
        <Flex height="215px" borderRadius="10px" ref={refUsed} gap="12px">
          <Flex
            height="215px"
            width="215px"
            backgroundColor="black10"
            borderRadius="10px"
          />
          <Flex
            height="215px"
            width="215px"
            backgroundColor="black10"
            borderRadius="10px"
          />
        </Flex>
      );

    return (
      <Flex height="215px" borderRadius="10px" ref={refUsed} gap="12px">
        {range(3).map((_, indexSkeleton) => (
          <Flex
            key={`skeleton-${indexSkeleton}`}
            height="215px"
            width="215px"
            backgroundColor="black10"
            borderRadius="10px"
          />
        ))}
      </Flex>
    );
  }

  return (
    <>
      {imageTypeId === 1 ? (
        <SwiperProvider value={{ setSwiperInstance, swiperInstance }}>
          <Flex borderRadius="10px" ref={refUsed} position="relative">
            <StyledSwiper slidesPerView={1} spaceBetween={16}>
              {dataBanner.data.map((banner, indexBanner) => (
                <SwiperSlide key={`swiper-${indexBanner}`}>
                  <Flex
                    as={banner.image_path && "img"}
                    src={banner.image_path}
                    backgroundColor={!banner.image_path && "black10"}
                    height="365px"
                    width="730px"
                    borderRadius="10px"
                  />
                </SwiperSlide>
              ))}
              <SwiperInstance />
            </StyledSwiper>
            <NavigationButton prev />
            <NavigationButton />
          </Flex>
        </SwiperProvider>
      ) : imageTypeId === 2 ? (
        <Flex ref={refUsed} height="362px" gap="12px" position="relative">
          {dataBanner?.data?.map((banner, indexBanner) => (
            <Flex
              key={`swiper-${indexBanner}`}
              as={banner?.image_path && "img"}
              src={banner?.image_path}
              backgroundColor={!banner?.image_path && "black10"}
              height="362px"
              width="362px"
              borderRadius="10px"
            />
          ))}
        </Flex>
      ) : (
        <SwiperProvider value={{ setSwiperInstance, swiperInstance }}>
          <Flex ref={refUsed} height="239px" gap="12px" position="relative">
            <StyledSwiper slidesPerView={3} spaceBetween={16}>
              <Flex>
                {dataBanner?.data.map((banner, indexBanner) => (
                  <SwiperSlide key={`swiper-${indexBanner}`}>
                    <Flex
                      as={banner.image_path && "img"}
                      src={banner.image_path}
                      backgroundColor="black10"
                      height="232px"
                      width="232px"
                      borderRadius="10px"
                    />
                  </SwiperSlide>
                ))}
              </Flex>
              <SwiperInstance />
            </StyledSwiper>
            <NavigationButton prev isSquare />
            <NavigationButton isSquare />
          </Flex>
        </SwiperProvider>
      )}
    </>
  );
};

const SwiperInstance = () => {
  const { setSwiperInstance } = useContext(SwiperContext);
  const swiper = useSwiper();

  useEffect(() => {
    if (swiper) return setSwiperInstance(swiper);
  }, [swiper, setSwiperInstance]);
};

const NavigationButton = ({ prev, isSquare }) => {
  const { swiperInstance } = useContext(SwiperContext);

  const onPrevClicked = useCallback(() => {
    if (prev) return swiperInstance.slidePrev();

    swiperInstance.slideNext();
  }, [swiperInstance, prev]);

  return (
    <Flex
      width="20px"
      height="20px"
      position="absolute"
      left={prev && "-10px"}
      right={!prev && "-10px"}
      top={isSquare ? "100px" : "148px"}
      backgroundColor={"white"}
      justifyContent="center"
      alignItems="center"
      borderRadius="50px"
      zIndex={1}
      onClick={onPrevClicked}
      cursor="pointer"
      boxShadow="0px 4px 4px rgba(20, 20, 32, 0.08)"
    >
      <Icon
        name={prev ? "Left-outline" : "Right-outline"}
        color="black50"
        size={14}
      />
    </Flex>
  );
};

export default Banner;
