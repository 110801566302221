import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useToast, isEmpty, useDisclosure } from "renos-ui";
import { useUpdateLocation } from "services/location";
import {
  useGetProvince,
  useGetCity,
  useGetDistrict,
  useGetSubDistrict,
} from "services/place";
import desktopUrl from "constants/desktopUrl";
import { setGTM } from "helpers";

const useLocation = () => {
  const toast = useToast();
  const history = useHistory();
  const [storeName, setStoreName] = useState("");
  const showDisclosure = useDisclosure({});
  const [dataDropdown, setDataDropdown] = useState({
    city: "",
    district: "",
    subDistrict: "",
  });

  const { data: listProvince } = useGetProvince();
  const { data: listCity, mutate: getCity } = useGetCity();
  const { data: listDistrict, mutate: getDistrict } = useGetDistrict();
  const { data: listSubDistrict, mutate: getSubDistrict } = useGetSubDistrict();
  const emptyOptions = {
    label: "",
    value: "",
  };

  const [value, setValue] = useState({
    name: "",
    contact_person: "",
    address: "",
    contact_number: "",
    coordinates: {
      latitude: -6.175392,
      longitude: 106.827153,
    },
    province_id: "",
    province_name: "",
    city_id: "",
    city_name: "",
    district_id: "",
    district_name: "",
    lower_district_id: "",
    lower_district_name: "",
    postal_code: "",
    is_primary: false,
    is_coordinate_changed: false,
  });

  const [isError, setIsError] = useState({
    name: "",
    contact_person: "",
    address: "",
    contact_number: "",
    coordinates: {
      latitude: "",
      longitude: "",
    },
    province_id: "",
    province_name: "",
    city_id: "",
    city_name: "",
    district_id: "",
    district_name: "",
    lower_district_id: "",
    lower_district_name: "",
    postal_code: "",
    is_coordinate_changed: "",
  });

  const { mutate: submitAddLocation, isLoading: isLoadingSave } =
    useUpdateLocation({
      onError: (error) => {
        const {
          response: {
            data: { frontend_message, message },
          },
        } = error;
        toast({
          label: frontend_message || message || "Alamat gagal diubah.",
          placement: "bottom",
          backgroundColor: "red50",
        });
      },
      onSuccess: (data) => {
        const { frontend_message, message } = data;
        toast({
          label: message || frontend_message || "Alamat berhasil diubah.",
          placement: "bottom",
        });

        setTimeout(() => {
          history.push(desktopUrl.STORE_LOCATION);
        }, 1000);
      },
    });

  const handleChangevalue = (e) => {
    if (e.target.name === "contact_number") {
      setValue((prev) => ({
        ...prev,
        [e.target.name]: e.target.value.replace(/[^+\d]/g, "").substring(0, 15),
      }));
    } else {
      setValue({
        ...value,
        [e.target.name]: e.target.value,
      });
    }

    setIsError({
      ...isError,
      [e.target.name]: false,
    });
  };

  const handleError = () => {
    const tempError = isError;
    const listExclude = [
      "is_primary_int",
      "is_primary",
      "coordinates",
      "description",
      "point_address",
    ];
    let countError = 0;

    for (var key in value) {
      if (!listExclude.includes(key)) {
        if (key === "contact_number" && value[key]) {
          const plusPattern = /^\+/;
          const indonesiaPattern = /^\+62\d{0,15}$/;
          const containPlusInMiddle =
            value[key].replace(/[^+]/g, "").length > 0 &&
            !plusPattern.test(value[key]);
          tempError.patternNumber = false;

          if (
            (plusPattern.test(value[key]) &&
              !indonesiaPattern.test(value[key])) ||
            containPlusInMiddle
          ) {
            tempError.patternNumber = true;
            countError++;
          }
        } else if (key === "address" && value[key] && value[key].length < 15) {
          tempError[key] = true;

          countError++;
        } else if (!value[key]) {
          tempError[key] = true;
          countError++;
        }
      }
    }

    setIsError({ ...tempError });

    if (countError !== 0) {
      toast({
        label: "Mohon lengkapi semua data",
        placement: "bottom",
        backgroundColor: "red50",
      });

      return true;
    }

    return false;
  };

  const handleAddLocation = (event) => {
    event.preventDefault();

    setGTM("seller_center_section", {
      event_category: "store_settings | location",
      event_action: "click",
      event_label: "save_location",
    });

    const isHasError = handleError();

    if (isHasError) return false;

    const datas = value;

    delete datas.is_primary_int;

    datas.description = "alamat";

    submitAddLocation({ data: datas });
  };

  useEffect(() => {
    const tempError = isError;

    for (var key in value) {
      tempError[key] = false;
    }

    setIsError({ ...tempError });
    //eslint-disable-next-line
  }, []);

  const handleSelectChange = (selectedOption, propertyToUpdate) => {
    const {
      value: newValue,
      label: newLabel,
      postal_code: newPostalCode,
    } = selectedOption;

    const updatedValue = {
      ...value,
      [propertyToUpdate]: newValue,
      [`${propertyToUpdate}`]: newLabel,
    };

    switch (propertyToUpdate) {
      case "province_name":
        updatedValue.province_id = newValue;
        updatedValue.city_id = "";
        updatedValue.city_name = "";
        updatedValue.district_id = "";
        updatedValue.district_name = "";
        updatedValue.lower_district_id = "";
        updatedValue.lower_district_name = "";
        updatedValue.postal_code = "";

        setDataDropdown({
          city: "",
          district: "",
          subDistrict: "",
        });

        setIsError({
          ...isError,
          province_id: false,
        });
        break;

      case "city_name":
        updatedValue.city_id = newValue;
        updatedValue.district_id = "";
        updatedValue.district_name = "";
        updatedValue.lower_district_id = "";
        updatedValue.lower_district_name = "";
        updatedValue.postal_code = "";

        setDataDropdown({
          city: selectedOption,
          district: "",
          subDistrict: "",
        });

        setIsError({
          ...isError,
          city_id: false,
        });
        break;

      case "district_name":
        updatedValue.district_id = newValue;
        updatedValue.lower_district_name = "";
        updatedValue.postal_code = "";

        setDataDropdown({
          ...dataDropdown,
          district: selectedOption,
          subDistrict: "",
        });

        setIsError({
          ...isError,
          district_id: false,
        });
        break;

      case "lower_district_name":
        updatedValue.lower_district_id = newValue;
        setDataDropdown({
          ...dataDropdown,
          subDistrict: selectedOption,
        });

        setIsError({
          ...isError,
          lower_district_id: false,
        });

        if (newPostalCode) {
          updatedValue.postal_code = newPostalCode;
        }
        break;

      default:
        break;
    }

    setValue(updatedValue);
  };

  useEffect(() => {
    if (!isEmpty(value.province_id)) {
      getCity({
        params: {
          province_id: value.province_id,
        },
      });
    }
  }, [getCity, value.province_id]);

  useEffect(() => {
    if (!isEmpty(value.city_id)) {
      getDistrict({
        params: {
          city_id: value.city_id,
        },
      });
    }
  }, [getDistrict, value.city_id]);

  useEffect(() => {
    if (!isEmpty(value.district_id)) {
      getSubDistrict({
        params: {
          district_id: value.district_id,
        },
      });
    }
  }, [getSubDistrict, value.district_id]);

  return {
    storeName,
    setStoreName,
    value,
    setValue,
    handleChangevalue,
    isError,
    setIsError,
    listProvince,
    listCity,
    listDistrict,
    listSubDistrict,
    emptyOptions,
    dataDropdown,
    handleAddLocation,
    showDisclosure,
    handleSelectChange,
    isLoadingSave,
  };
};

export default useLocation;
