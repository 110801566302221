import * as yup from "yup";

export const registerSchema = yup.object().shape({
  name: yup.string().required("Nama Lokasi harus diisi."),
  address: yup
    .string()
    .required("Alamat Toko harus diisi.")
    .min(15, "Alamatmu terlalu pendek, minimal 15 karakter."),
  contact_person: yup.string().required("Nama PIC Toko harus diisi."),
  contact_number: yup
    .string()
    .required("Nomor kontak harus diisi.")
    .test(
      "is-valid-phone",
      "Format nomor hp Indonesia harus dimulai +62",
      (value) => {
        const plusPattern = /^\+/;
        const indonesiaPattern = /^\+62\d{0,15}$/;

        const containPlusInMiddle =
          value.replace(/[^+]/g, "").length > 0 && !plusPattern.test(value);

        if (
          (plusPattern.test(value) && !indonesiaPattern.test(value)) ||
          containPlusInMiddle
        ) {
          return false;
        }

        return true;
      }
    )
    .max(15, "Maksimal 15 digit angka"),
  province: yup
    .mixed()
    .test("is-province-null", "Provinsi harus dipilih.", (value) => {
      return value !== null;
    })
    .required("Provinsi harus dipilih."),
  city: yup
    .mixed()
    .test("is-city-null", "Kota/Kabupaten harus dipilih.", (value) => {
      return value !== null;
    })
    .required("Kota/Kabupaten harus dipilih."),
  district: yup
    .mixed()
    .test("is-district-null", "Kecamatan harus dipilih.", (value) => {
      return value !== null;
    })
    .required("Kecamatan harus dipilih."),
  sub_district: yup
    .mixed()
    .test("is-sub_district-null", "Kelurahan harus dipilih..", (value) => {
      return value !== null;
    })
    .required("Kelurahan harus dipilih."),
  point_address: yup.string().required("Posisi harus dipilih."),
  coordinates: yup.object().shape({
    latitude: yup
      .string()
      .test("is-empty", "Posisi harus dipilih.", (value) => {
        return value.trim() !== "";
      }),
    longitude: yup
      .string()
      .test("is-empty", "Posisi harus dipilih.", (value) => {
        return value.trim() !== "";
      }),
  }),
});
