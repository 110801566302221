import { colors, Flex, Disclosure } from "renos-ui";
import styled from "styled-components";

export const NotificationItem = styled(Flex)`
  &:hover {
    background-color: ${colors.black5};
  }
`;

export const StyledDisclosure = styled(Disclosure)`
  width: 100%;
`;
