import React from "react";
import { Flex, Text } from "renos-ui";

const EmptyState = ({ title }) => {
  const titleLowerCase = title.toLowerCase();

  return (
    <Flex
      gap="4px"
      width="100%"
      columnGap="8px"
      alignItems="center"
      alignContent="center"
      flexDirection="column"
      backgroundColor="white"
      justifyContent="center"
      height="calc(100vh - 194px)"
      style={{ boxSizing: "border-box" }}
    >
      <Flex
        as="img"
        alt="empty-review"
        src={`/assets/images/mobile/empty-${titleLowerCase}.svg`}
      />
      <Text color="black75" textAlign="center" weight="bold" variant="body">
        Belum ada {titleLowerCase}
      </Text>
      <Flex
        width="236px"
        marginX="60px"
        textAlign="center"
        justifyContent="center"
      >
        <Text color="black50" textAlign="center" variant="caption">
          {title} produkmu dari pembeli akan muncul di halaman ini.
        </Text>
      </Flex>
    </Flex>
  );
};

export default EmptyState;
