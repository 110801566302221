import { Divider, Flex } from "renos-ui";
import Header from "./Header";
import Body from "./Body";
import Footer from "./Footer";
import { useHistory } from "react-router-dom";

const Card = ({
  data,
  toFirstPage,
  isShowFooter = true,
  handleClickDetail,
}) => {
  const { push } = useHistory();

  const toDetail = () => {
    if (handleClickDetail) {
      handleClickDetail(data);
    } else {
      push(`/sales/orders/detail?no=${data.order_number}`);
    }
  };

  return (
    <Flex
      backgroundColor="white"
      padding="16px"
      gap="16px"
      flexDirection="column"
    >
      <Header {...{ data, toDetail }} />
      <Divider />
      <Body {...{ ...data, toDetail }} />
      {isShowFooter && (
        <>
          <Divider />
          <Footer {...{ data, toFirstPage }} />
        </>
      )}
    </Flex>
  );
};

export default Card;
