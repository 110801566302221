import { Flex } from "renos-ui";

const Preview = ({ data, isMobile }) => {
  const mobileWidth = ["1500px", "1550px", "2300px", "1600px", "2100px"];
  const webWidth = ["1150px", "1100px", "1300px", "1000px", "1350px"];

  return (
    <Flex
      height="600px"
      borderRadius="16px"
      backgroundColor="white"
      overflow="scroll"
    >
      <Flex
        as="img"
        width={isMobile ? "360px" : "616px"}
        height={isMobile ? mobileWidth[data.id - 1] : webWidth[data.id - 1]}
        src={isMobile ? data.mobile_image : data.desktop_image}
      />
    </Flex>
  );
};

export default Preview;
