import { Flex } from "renos-ui";
import DeliveryInfo from "./DeliveryInfo";
import ProductInfo from "./ProductInfo";

const Body = ({ data }) => {
  return (
    <Flex padding="24px" gap="24px">
      <ProductInfo data={data} />
      <Flex
        borderRightColor="black10"
        borderRightWidth="1px"
        borderRightStyle="solid"
      />
      <DeliveryInfo data={data} />
    </Flex>
  );
};

export default Body;
