import { Flex, Text } from "renos-ui";

const CustomToast = ({ title, message }) => {
  return (
    <Flex
      backgroundColor="overlay95"
      width="100%"
      borderRadius="8px"
      paddingX="16px"
      paddingY="10px"
      gap="4px"
    >
      <Text variant="caption" color="white" weight="bold">
        {title}
      </Text>
      <Text variant="caption" color="white">
        {message}
      </Text>
    </Flex>
  );
};

export default CustomToast;
