import React, { useState, createContext, useContext, useEffect } from "react";
import {
  Box,
  Button,
  Dialog,
  Divider,
  Flex,
  Icon,
  Spinner,
  Text,
  useDisclosure,
} from "renos-ui";
import { StyledText, StyledTextArea } from "./styled";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { DetailProductListForm } from "./DetailProductListForm";
import StoreContext from "providers/StoreProvider";
import { useSendOfferingQuotation } from "services/bidding";
import { ModalSuccessSent } from "./ModalSuccessSent";
import { useQueryClient } from "react-query";

export const ModalRequestBiddingContext = createContext({});

const LIMIT_PRODUCT_TO_OFFER = 9;

export const ModalRequestBidding = ({
  isOpen,
  onToggle,
  requestId,
  refetch: refetchBiddingDetail,
}) => {
  const {
    storeData: {
      data: { id: storeId },
    },
  } = useContext(StoreContext);
  const queryClient = useQueryClient();
  const { onToggle: sentSuccessOnToggle, isOpen: sentSuccessIsOpen } =
    useDisclosure({
      open: false,
    });

  const [errorMessages, setErrorMessages] = useState({});
  const [isProductValid, setIsProductValid] = useState(false);
  const [productListOffer, setProductListOffer] = useState([
    {
      productQty: "",
      productUOM: "unit",
      productShippingFee: "",
      productShippingEstimate: "",
      productTotalPrice: "",
    },
  ]);
  const [quotationFormValue, setQuotationFormValue] = useState({});

  const { refetch, isLoading: isLoadingSendOffering } =
    useSendOfferingQuotation(
      { ...quotationFormValue },
      {
        enabled: false,
        onSuccess: () => {
          sentSuccessOnToggle();
          refetchBiddingDetail();
          queryClient.invalidateQueries({
            queryKey: ["get-my-bidding"],
          });
          setQuotationFormValue({});
          setProductListOffer([
            {
              productQty: "",
              productUOM: "unit",
              productShippingFee: "",
              productShippingEstimate: "",
              productTotalPrice: "",
            },
          ]);
          onToggle();
        },
      }
    );

  const onClose = () => {
    onToggle();
    setProductListOffer([
      {
        productQty: "",
        productUOM: "unit",
        productShippingFee: "",
        productShippingEstimate: "",
        productTotalPrice: "",
      },
    ]);
  };

  const getValidation = () => {
    const newErrorMessages = {};
    let productErrorMessagesQty = 0;

    if (!quotationFormValue.quotation_remark) {
      newErrorMessages["quotationRemark"] = "Pesan untuk pembeli harus diisi!";
    }

    productListOffer.forEach((item, index) => {
      if (!item.productQty) {
        newErrorMessages[`productQty_${index}`] =
          "Kuantitas produk harus diisi!";
        productErrorMessagesQty += 1;
      }

      if (!item?.product?.product_id) {
        newErrorMessages[`productId_${index}`] =
          "Produk yang ditawarkan harus diisi!";

        productErrorMessagesQty += 1;
      }

      if (!item.productShippingFee) {
        newErrorMessages[`productShippingFee_${index}`] =
          "Biaya pengiriman harus diisi!";
        productErrorMessagesQty += 1;
      }

      if (!item.productShippingEstimate) {
        newErrorMessages[`productShippingEstimate_${index}`] =
          "Estimasi lama pengiriman harus diisi!";
        productErrorMessagesQty += 1;
      }

      if (!item.productTotalPrice) {
        newErrorMessages[`productTotalPrice_${index}`] =
          "Total harga penawaran harus diisi!";
        productErrorMessagesQty += 1;
      }
    });

    setIsProductValid(productErrorMessagesQty === 0);
    setErrorMessages(newErrorMessages);

    if (productErrorMessagesQty === 0) {
      return true;
    }
  };

  useEffect(() => {
    getValidation();

    setQuotationFormValue((prev) => ({
      ...prev,
      products: productListOffer.map((item) => ({
        product_id: item?.product?.product_id,
        product_sku_id: item?.product?.product_sku_id,
        product_quantity: Number(item?.productQty),
        product_uom: item?.productUOM,
        product_shipping_fee: Number(item?.productShippingFee),
        product_shipping_estimate: Number(item?.productShippingEstimate),
        product_total_price: Number(item?.productTotalPrice),
      })),
    }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productListOffer]);

  useEffect(() => {
    setQuotationFormValue(() => ({
      quotation_remark: "",
      bd_request_id: requestId,
      store_id: storeId,
      products: [],
    }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestId]);

  useEffect(() => {
    getValidation();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quotationFormValue]);

  return (
    <ModalRequestBiddingContext.Provider
      value={{ productListOffer, setProductListOffer }}
    >
      <Dialog height="80vh" closable isVisible={isOpen} onClose={onClose}>
        <Flex gap="40px" flexDirection="column">
          <Text variant="heading3" weight="bold">
            Buat Penawaran
          </Text>
          <Flex
            gap="40px"
            overflowY="auto"
            position="relative"
            flexDirection="column"
          >
            <Flex flexDirection="column" gap="8px">
              <Text weight="bold" variant="caption" color="black100">
                Pesan Untuk Pembeli
              </Text>
              <StyledTextArea
                maxLength={1000}
                rows={7}
                error={errorMessages["quotationRemark"]}
                isError={errorMessages["quotationRemark"]}
                placeholder=""
                onChange={({ currentTarget }) =>
                  setQuotationFormValue((prev) => ({
                    ...prev,
                    quotation_remark: currentTarget.value,
                  }))
                }
              />
            </Flex>

            <Box position="relative">
              <Divider />
            </Box>

            {productListOffer.map((productList, index) => (
              <>
                <DetailProductListForm
                  data={productList}
                  index={index}
                  setProductListOffer={setProductListOffer}
                  errorMessages={errorMessages}
                  length={productListOffer?.length || 1}
                />
                {index !== Number(productListOffer.length - 1) && (
                  <Box position="relative">
                    <Divider />
                  </Box>
                )}
              </>
            ))}

            <Box position="relative">
              <Divider />
            </Box>
            <Flex flexDirection="column" gap="24px">
              <Flex flexDirection="column" gap="16px">
                <Button
                  variant="secondary"
                  isBlock
                  disabled={
                    productListOffer.length === LIMIT_PRODUCT_TO_OFFER ||
                    !isProductValid
                  }
                  onClick={() =>
                    setProductListOffer((prev) => [
                      ...prev,
                      {
                        productQty: "",
                        productUOM: "unit",
                        productShippingFee: "",
                        productShippingEstimate: "",
                        productTotalPrice: "",
                      },
                    ])
                  }
                >
                  <Icon name="Plus-outline" /> Tambah Produk
                </Button>
                <Button
                  isBlock
                  disabled={
                    Object.keys(errorMessages).length !== 0 ||
                    isLoadingSendOffering
                  }
                  onClick={refetch}
                >
                  {isLoadingSendOffering ? (
                    <Spinner size="1.5rem" emptyColor="blue10" />
                  ) : (
                    "Kirim Permintaan"
                  )}
                </Button>
              </Flex>
              <StyledText variant="caption">
                Dengan mengklik “Kirim Penawaran” kamu telah membaca, memahami,
                dan menyetujui{" "}
                <Link
                  onClick={() =>
                    window.open(
                      `${process.env.REACT_APP_BUYER_SITE}/bidding/terms-and-conditions`
                    )
                  }
                >
                  Syarat Dan Ketentuan
                </Link>{" "}
                Renos Bidding yang berlaku.
              </StyledText>
            </Flex>
          </Flex>
        </Flex>
      </Dialog>
      <ModalSuccessSent
        onToggle={sentSuccessOnToggle}
        isVisible={sentSuccessIsOpen}
      />
    </ModalRequestBiddingContext.Provider>
  );
};
