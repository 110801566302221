export const modalText = {
  ktp: {
    title: "KTP Penjual",
    inputLabel: "Nomor Induk Kependudukan",
    inputName: "ktp",
    inputPlacholder: "Masukkan NIK",
    uploadPhotoText: "Upload foto KTP",
  },
  npwp: {
    title: "NPWP Perusahaan",
    inputLabel: "No. NPWP",
    inputName: "npwp",
    inputPlacholder: "Masukan NPWP",
    uploadPhotoText: "Upload foto NPWP",
  },
  siup: {
    title: "Nomor Induk Berusaha (NIB)",
    inputLabel: "Nomor Induk Berusaha",
    inputName: "siup",
    inputPlacholder: "Masukan NIB",
    uploadPhotoText: "Upload foto NPWP",
  },
  bank_book: {
    title: "Buku Tabungan",
    inputLabel: "Masukan Nomor Rekening",
    inputName: "bank_book",
    inputPlacholder: "Masukan NIB",
    uploadPhotoText: "Upload foto NPWP",
  },
};

export const IMAGE_FILE_TYPE =
  "image/jpg, image/jpeg, image/png, application/pdf";

export const items = [
  {
    id: "ktp",
    title: "Foto KTP",
    subtitle: "Pastikan foto KTP terlihat jelas.",
    link: null,
  },
  {
    id: "siup",
    title: "Surat Izin Usaha",
    subtitle: "Tidak lebih dari 60 hari.",
    link: null,
  },
  {
    id: "npwp",
    title: "NPWP Perusahaan",
    subtitle: "Salinan Registrasi PPN Bersertifikat",
    link: null,
  },
  {
    id: "bank_book",
    title: "Buku Tabungan",
    subtitle: "Buku Tabungan Perusahaan.",
    link: null,
  },
];
