import StoreLayoutContext from "providers/StoreLayoutProvider";
import SwiperContext, { SwiperProvider } from "providers/SwiperProvider";
import { useCallback, useContext, useEffect, useState } from "react";
import { Flex, range, Text, Grid, Icon } from "renos-ui";
import { SwiperSlide, useSwiper } from "swiper/react";
import ProductCard from "../component/ProductCard";
import ProductCardSkeleton from "../component/ProductCardSkeleton";
import { StyledSwiper } from "./styled";

const BestSelling = ({ isPreviewMode }) => {
  const { bestSellingRef, dataHomePage } = useContext(StoreLayoutContext);
  const [dataBestSelling, setDataBestSelling] = useState({});
  const [swiperInstance, setSwiperInstance] = useState();

  useEffect(() => {
    const selectedBestSelling = dataHomePage.filter(
      (homepage) => homepage.id === 3
    )[0];
    setDataBestSelling(selectedBestSelling);
  }, [dataHomePage]);

  if (isPreviewMode && !dataBestSelling?.isActive) return <></>;

  return (
    <Flex flexDirection="column" gap="8px" ref={bestSellingRef}>
      <Text variant="small" weight="bold">
        Produk Terlaris
      </Text>
      {dataBestSelling?.isActive ? (
        dataBestSelling?.data.length > 6 ? (
          <SwiperProvider value={{ setSwiperInstance, swiperInstance }}>
            <Flex position="relative">
              <StyledSwiper slidesPerView={6} spaceBetween={16}>
                <Flex>
                  {dataBestSelling?.data.map((product, indexProduct) => {
                    return (
                      <SwiperSlide key={`swiper-${indexProduct}`}>
                        <ProductCard
                          data={product}
                          key={`product-card${indexProduct}`}
                          isBestSeller={true}
                        />
                      </SwiperSlide>
                    );
                  })}
                </Flex>
                <SwiperInstance />
              </StyledSwiper>
              <NavigationButton prev />
              <NavigationButton />
            </Flex>
          </SwiperProvider>
        ) : (
          <Grid
            gap="8px"
            gridTemplateColumns="115px 115px 115px 115px 115px 115px"
            overflow="auto"
          >
            {dataBestSelling?.data.map((product, indexProduct) => {
              return (
                <ProductCard
                  data={product}
                  key={`product-card${indexProduct}`}
                  isBestSeller={true}
                />
              );
            })}
          </Grid>
        )
      ) : (
        <Grid
          gap="8px"
          gridTemplateColumns="115px 115px 115px 115px 115px 115px"
          overflow="auto"
        >
          {range(6).map((_, indexEmpty) => (
            <ProductCardSkeleton key={`product-card-skeleton${indexEmpty}`} />
          ))}
        </Grid>
      )}
    </Flex>
  );
};

const SwiperInstance = () => {
  const { setSwiperInstance } = useContext(SwiperContext);
  const swiper = useSwiper();

  useEffect(() => {
    if (swiper) return setSwiperInstance(swiper);
  }, [swiper, setSwiperInstance]);
};

const NavigationButton = ({ prev }) => {
  const { swiperInstance } = useContext(SwiperContext);

  const onPrevClicked = useCallback(() => {
    if (prev) return swiperInstance.slidePrev();

    swiperInstance.slideNext();
  }, [swiperInstance, prev]);

  return (
    <Flex
      width="20px"
      height="20px"
      position="absolute"
      left={prev && "-10px"}
      right={!prev && "-10px"}
      top="72px"
      backgroundColor={"white"}
      justifyContent="center"
      alignItems="center"
      borderRadius="50px"
      zIndex={1}
      onClick={onPrevClicked}
      cursor="pointer"
      boxShadow="0px 4px 4px rgba(20, 20, 32, 0.08)"
    >
      <Icon
        name={prev ? "Left-outline" : "Right-outline"}
        color="black50"
        size={14}
      />
    </Flex>
  );
};

export default BestSelling;
