import { currencyFormatter } from "helpers";
import { Box, Flex, Icon, Input, Text, Tooltip } from "renos-ui";

const InputWithToolTip = ({
  label,
  tooltip,
  value,
  onChange,
  name,
  placeholder,
  prefix,
  sufix,
  isError,
  error,
  max,
  maxLength,
  disabled,
}) => {
  const handleChange = (event) => {
    onChange({
      target: {
        name: name,
        value: event,
      },
    });
  };

  return (
    <Flex flexDirection="column" gap="8px">
      <Flex gap="4px" alignItems="center">
        <Text variant="caption" color="black50" weight="bold">
          {label}
        </Text>
        <Tooltip label={<Box width="143px">{tooltip}</Box>}>
          <Flex>
            <Icon name="Info-outline" size={14} color="black50" />
          </Flex>
        </Tooltip>
      </Flex>

      <Input.Number
        name={name}
        value={currencyFormatter(value)}
        onChange={(event) => handleChange(event)}
        placeholder={placeholder}
        prefix={prefix}
        sufix={sufix}
        isError={isError}
        error={error}
        max={max}
        maxLength={maxLength}
        disabled={disabled}
      />
    </Flex>
  );
};

export default InputWithToolTip;
