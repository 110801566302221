import { Flex, Text, colors } from "renos-ui";

const SquareOne = ({ imageRef, variant, data, setData }) => {
  const handleSetEdit = (type) => {
    setData((prev) => ({ ...prev, active: type }));
  };

  const renderBorder = (type) => {
    return data?.active === type && `1px solid ${colors.violet50}`;
  };

  return (
    <Flex
      flexDirection="column"
      gap="8px"
      paddingTop="50px"
      position="relative"
    >
      <Flex flexDirection="column" position="absolute" left={-84}>
        <Text variant="caption" color="black50" weight="bold">
          Template {variant}
        </Text>
        <Text variant="caption" color="black50">
          Square Banner 1:1 (1080 x 1080px)
        </Text>
      </Flex>
      <Flex style={{ transform: "scale(1.7)" }} mt="150px">
        <Flex
          width="199px"
          height="199px"
          background="#1833B4"
          ref={imageRef}
          p="20px"
          position="relative"
        >
          <Flex
            as="img"
            alt="img"
            width="200px"
            height="200px"
            src="assets/images/store-layout/masking-square.png"
            position="absolute"
            onClick={() => handleSetEdit("imgSquare")}
            cursor="pointer"
            border={renderBorder("imgSquare")}
            style={{ transform: "scale(1.002)" }}
          />

          <Flex
            opacity={data.isDiscountActive ? 1 : 0}
            onClick={() => handleSetEdit("discountSquare")}
            cursor="pointer"
            right={10}
            top={0}
            width="35px"
            height="40px"
            position="absolute"
            background="url('assets/images/store-layout/banner-1x1/discount_ribbon.svg')"
            flexDirection="column"
            alignItems="center"
            backgroundSize="cover"
            border={renderBorder("discountSquare")}
          >
            <Flex
              fontSize="5px"
              fontWeight={400}
              letterSpacing={1.23}
              marginTop="4px"
            >
              {data.discountNameSquare}
            </Flex>
            <Flex gap="1px" alignItems="center">
              <Flex color="black75" fontSize={12} fontWeight={800}>
                {data.discount}
              </Flex>
              <Flex
                color="black75"
                fontSize="7px"
                fontWeight={400}
                letterSpacing={1.23}
              >
                {data.discountSuffix}
              </Flex>
            </Flex>
          </Flex>

          <Flex
            right={0}
            bottom={2}
            position="absolute"
            flexDirection="column"
            alignItems="end"
          >
            <Flex
              onClick={() => handleSetEdit("price")}
              cursor="pointer"
              width="44px"
              height="10.41px"
              background="url('assets/images/store-layout/banner-1x1/template_1_price.svg')"
              alignItems="center"
              backgroundSize="cover"
              color="white"
              fontWeight={400}
              fontSize="7px"
              justifyContent="end"
              pr={9}
              style={{ textDecoration: "line-through" }}
              border={renderBorder("price")}
            >
              {data.price}
            </Flex>

            <Flex
              onClick={() => handleSetEdit("discountPrice")}
              cursor="pointer"
              width="125px"
              height="32px"
              background="url('assets/images/store-layout/banner-1x1/template_1_discount_price.svg')"
              alignItems="center"
              backgroundSize="cover"
              color="white"
              fontWeight={800}
              fontSize={18}
              justifyContent="end"
              pr={9}
              border={renderBorder("discountPrice")}
            >
              {data.discountPrice}
            </Flex>
          </Flex>

          <Flex
            as="img"
            alt="img"
            width="200px"
            height="200px"
            src={data.imgSquare}
            borderRadius="32px"
            onClick={() => handleSetEdit("imgSquare")}
            cursor="pointer"
            border={renderBorder("imgSquare")}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default SquareOne;
