import {
  Dialog,
  Flex,
  Text,
  Spinner,
  Divider,
  Icon,
  DatePicker,
} from "renos-ui";
import { StyledButton, StyledTextAreaOutofStock } from "./styled";
import dayjs from "dayjs";
import ModalCancelOrderContext from "providers/ModalCancelOrder";
import { useContext } from "react";

const ModalRejectStoreClosed = ({ isOpen, onClose }) => {
  const { isLoading, onReject, onOpenParent, payloadReject, setPayloadReject } =
    useContext(ModalCancelOrderContext);

  const onChangeReason = (event) => {
    setPayloadReject((prev) => ({
      ...prev,
      order_cancellation_reason: event.target.value,
    }));
  };

  const onChangeDate = (date) => {
    setPayloadReject((prev) => ({
      ...prev,
      date_off_end: dayjs(date).format("YYYY-MM-DD"),
    }));
  };

  const handleClose = () => {
    onClose();
    onOpenParent();
  };

  return (
    <Dialog isVisible={isOpen} onClose={onClose} verticalCentered>
      <Flex flexDirection="column" gap="24px" width="400px">
        <Flex justifyContent="space-between">
          <Text variant="heading3" weight="bold">
            Atur Tanggal Libur
          </Text>
          <Flex cursor="pointer" onClick={handleClose}>
            <Icon name="Close-outline" size={24} color="black50" />
          </Flex>
        </Flex>
        <Divider />

        <DatePicker
          label="Mulai Libur"
          placeholder="Pilih tanggal"
          value={dayjs(payloadReject.date_off_start).format("DD MMMM YYYY")}
          disabled={true}
        />
        <DatePicker
          label="Buka Kembali"
          placeholder="Pilih tanggal"
          value={dayjs(payloadReject.date_off_end).format("DD MMMM YYYY")}
          onChange={onChangeDate}
          disabled={isLoading}
        />

        <StyledTextAreaOutofStock
          value={payloadReject.order_cancellation_reason}
          onChange={onChangeReason}
          label="Catatan"
          placeholder="Tulis alasan toko tutup"
          disabled={isLoading}
          maxLength={150}
          showCount
        />
        <StyledButton
          isBlock
          onClick={onReject}
          disabled={isLoading || !payloadReject.order_cancellation_reason}
          preffix={isLoading && <Spinner color="white" size="8px" />}
        >
          Tolak Pesanan
        </StyledButton>
      </Flex>
    </Dialog>
  );
};

export default ModalRejectStoreClosed;
