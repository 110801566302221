import { Checkbox, Flex, Text } from "renos-ui";

const Condition = ({ value, setValue }) => {
  const DATA_CONDITION = [
    {
      label: "Produk Baru",
      value: 1,
    },
    {
      label: "Produk Bekas",
      value: 2,
    },
    {
      label: "Pre Order",
      value: 3,
    },
  ];

  const isChecked = (id) => {
    return value.map((conditionValue) => conditionValue.value).includes(id);
  };

  const handleClick = (condition) => {
    let tempValue = value;

    if (isChecked(condition.value)) {
      tempValue = value.filter((idValue) => idValue.value !== condition.value);
    } else {
      tempValue.push(condition);
    }
    setValue([...tempValue]);
  };

  return (
    <Flex flexDirection="column" gap="16px">
      <Flex flexDirection="column">
        <Text weight="bold" color="black75">
          Kondisi Produk
        </Text>
        <Text variant="caption" color="black75">
          Atur filter berdasarkan kondisi produk baru, produk bekas dan produk
          pre order.
        </Text>
      </Flex>
      <Flex flexWrap="wrap" gap="16px">
        {DATA_CONDITION.map((condition, indexCondition) => (
          <Flex
            key={`condition-${indexCondition}`}
            gap="8px"
            alignItems="center"
            width="200px"
          >
            <Checkbox
              checked={isChecked(condition.value)}
              onChange={() => handleClick(condition)}
            />
            <Text variant="caption" color="black75">
              {condition.label}
            </Text>
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
};

export default Condition;
