import ENDPOINTS from "constants/endpoints";
import { useMutation, useQuery } from "react-query";
import client from "../client";
import { isEmpty } from "renos-ui";

const checkAuthFetcher = async () => {
  const { data } = await client.get(ENDPOINTS.check_auth);

  return data;
};

const checkStoreFetcher = async (params) => {
  const processedParams = { ...params };

  if (!isEmpty(params?.need)) {
    processedParams.need = [
      "user",
      "heartbeats",
      ...params.need.split(","),
    ].join(",");
  } else {
    processedParams.need = "user,heartbeats";
  }

  const { data } = await client.get(ENDPOINTS.get_store, {
    params: processedParams,
  });

  return data;
};

const logoutFetcher = async () => {
  const { data } = await client.post(ENDPOINTS.logout);
  document.cookie = "RENOS_SID=; ";
  window.location.href = `${process.env.REACT_APP_SELLER_SITE}/seller-landing`;

  return data;
};

export const useCheckAuth = (extraParams) => {
  return useQuery(["getCheckAuth"], checkAuthFetcher, {
    retry: false,
    ...extraParams,
  });
};

export const useCheckStore = (params, queryName, extraParams) => {
  return useQuery(
    [queryName || "getCheckStore"],
    () => checkStoreFetcher(params),
    {
      retry: false,
      ...extraParams,
    }
  );
};

export const useLogout = (extraParams) =>
  useMutation(logoutFetcher, {
    ...extraParams,
  });
