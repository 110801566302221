import { useState } from "react";
import { Flex, Icon, Input, useDebounce } from "renos-ui";

const Filter = ({ setQueryParams }) => {
  const [searchValue, setSearchValue] = useState("");

  useDebounce(
    () => {
      setQueryParams((prev) => ({
        ...prev,
        keyword: searchValue,
      }));
    },
    [searchValue],
    1000,
  );

  return (
    <Flex
      flexDirection="row"
      justifyContent="space-between"
      gap="8px"
      margin="24px 0"
    >
      {/* <Flex width="280px" height="48px" flexBasis="40%"> */}
      <Input
        placeholder="Cari nama produk atau SKU"
        prefix={<Icon name="Search-outline" size={20} />}
        value={searchValue}
        onChange={(event) => setSearchValue(event.target.value)}
      />
      {/* </Flex> */}
      {/* <Flex flexBasis="30%" width="100%">
                <StyledSelectInput
                    placeholder="Semua Kategori"
                    options={categories}
                    onChange={(values) => {
                        setCategoryID(values?.category_id || "");
                    }}
                    isClearable
                />
            </Flex>
            <Flex flexBasis="30%" width="100%">
                <StyledSelectInput
                    placeholder="Urutkan"
                    options={[
                        {
                            value: null,
                            label: "Paling Sesuai",
                        },
                        {
                            value: "ASC",
                            label: "Harga Terendah",
                        },
                        {
                            value: "DESC",
                            label: "Harga Tertinggi",
                        },
                    ]}
                    onChange={(values) => {
                        setOrdering(values?.value || "");
                    }}
                />
            </Flex> */}
    </Flex>
  );
};

export default Filter;
