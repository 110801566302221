import React from "react";
import {
  Box,
  Checkbox,
  DatePicker,
  Divider,
  Flex,
  Icon,
  Input,
  Text,
} from "renos-ui";
import { translateDay, formatTime, formatTime2 } from "helpers";
import { StyledToggle } from "./styled";

const ScheduleItem = ({ list, setData, data, setIsDisabled }) => {
  const checkedCheckbox = list.is_active === true;

  const handleCheckBox = (isChecked) => {
    const tempList = list;

    if (isChecked) {
      tempList.is_active = true;
    } else {
      tempList.is_active = false;
    }

    const tempData = data;
    tempData.push(tempList);
    const finalData = [
      ...new Map(tempData.map((item) => [item["day_humane"], item])).values(),
    ];

    setData([...finalData]);
  };

  const checkedToggle = list.is_fullday === true;

  const handleToggle = () => {
    const tempList = list;

    if (list.is_fullday) {
      tempList.is_fullday = false;
    } else {
      tempList.is_fullday = true;
      tempList.start = "00:00";
      tempList.end = "23:00";
    }
    const tempData = data;
    tempData.push(tempList);
    const finalData = [
      ...new Map(tempData.map((item) => [item["day_humane"], item])).values(),
    ];
    setData([...finalData]);
  };

  const disabled = !list.is_active || list.is_fullday;

  return (
    <Flex alignItems="center" gap="32px">
      <Flex alignItems="center" gap="8px" width="160px">
        <Checkbox
          checked={checkedCheckbox}
          onChange={(isChecked) => handleCheckBox(isChecked)}
        />
        <Text>{translateDay(list.day_humane)}</Text>
      </Flex>

      <Flex gap="8px" alignItems="center">
        {disabled ? (
          <Box width="150px">
            <Input
              value={formatTime(list.start)}
              sufix={<Icon name="Time-solid" size={20} />}
              disabled={disabled}
            />
          </Box>
        ) : (
          <DatePicker.Time
            onChange={(_, value) => {
              const time = value.split(":")[0];
              const tempList = list;
              tempList.start = formatTime2(time);
              const tempData = data;
              tempData.push(tempList);
              const finalData = [
                ...new Map(
                  tempData.map((item) => [item["day_humane"], item])
                ).values(),
              ];
              setData([...finalData]);
            }}
            customInput={() => {
              return (
                <Box width="150px">
                  <Input
                    value={formatTime(list.start)}
                    sufix={<Icon name="Time-solid" size={20} />}
                    disabled={disabled}
                  />
                </Box>
              );
            }}
            isMinuteZero
          />
        )}

        <Box width="8px">
          <Divider />
        </Box>
        {disabled ? (
          <Box width="150px">
            <Input
              value={formatTime(list.end)}
              sufix={<Icon name="Time-solid" size={20} />}
              disabled={disabled}
            />
          </Box>
        ) : (
          <DatePicker.Time
            onChange={(_, value) => {
              const time = value.split(":")[0];
              const tempList = list;
              tempList.end = formatTime2(time);
              const tempData = data;
              tempData.push(tempList);
              const finalData = [
                ...new Map(
                  tempData.map((item) => [item["day_humane"], item])
                ).values(),
              ];
              setData([...finalData]);
            }}
            customInput={() => (
              <Box width="150px">
                <Input
                  value={formatTime(list.end)}
                  sufix={<Icon name="Time-solid" size={20} />}
                  disabled={disabled}
                />
              </Box>
            )}
            isMinuteZero
          />
        )}
      </Flex>

      <Flex alignItems="center" gap="8px" height="100%">
        <Box onClick={list.is_active ? handleToggle : undefined}>
          <StyledToggle checked={checkedToggle} disabled={!list.is_active} />
        </Box>
        <Text variant="caption" weight="bold" color="black50">
          Buka 24 Jam
        </Text>
      </Flex>
    </Flex>
  );
};

export default ScheduleItem;
