import { useState, useEffect } from "react";
import { Flex, Text } from "renos-ui";
import dayjs from "dayjs";
import Chart from "./Chart";

const OrdersPerformance = ({ dataDate }) => {
  const convertPayload = (tempDataDate) => {
    const { startDate, endDate, type } = tempDataDate;
    const tempStartDate = dayjs(startDate).format("YYYY-MM-DD");
    const tempPayload = {
      date_type: type,
      start_date: tempStartDate,
      end_date: endDate ? dayjs(endDate).format("YYYY-MM-DD") : tempStartDate,
    };

    return { params: tempPayload };
  };

  const [payload, setPayload] = useState(convertPayload(dataDate));

  useEffect(() => {
    setPayload(convertPayload(dataDate));
  }, [dataDate]);

  return (
    <Flex gap="16px" flexDirection="column">
      <Flex justifyContent="space-between">
        <Flex flexDirection="column">
          <Text weight="bold">Performa Pesanan</Text>
          <Text color="black75" variant="caption">
            Pantau rutin perkembangan toko untuk atur strategi penjualan.
          </Text>
        </Flex>
      </Flex>

      <Chart payload={payload} />
    </Flex>
  );
};

export default OrdersPerformance;
