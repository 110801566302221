import { Flex, Text, useUpload, Upload, Icon, useToast } from "renos-ui";
import { StyledButton } from "./styled";
import { useState } from "react";
import { getImageMeta, imageTypeChecking } from "helpers";

const ImgUploader = ({
  value,
  onChange,
  defaultValue,
  minWidth = 0,
  minHeight = 0,
  maxSize = 4,
  description,
  ratio,
}) => {
  const toast = useToast();
  const [showOverlay, setShowOverlay] = useState(false);

  const { register } = useUpload({
    fileType: "image",
    onRequest: async (file) => {
      var reader = new FileReader();
      setShowOverlay(false);

      reader.onload = async function () {
        const dataURL = reader.result;
        const { naturalHeight, naturalWidth } = await getImageMeta(dataURL);
        const allowedType = ["image/jpg", "image/jpeg", "image/png"];
        const deepTypeChecking = await imageTypeChecking(file);

        let errorMsg = "";

        if (naturalWidth < minWidth || naturalHeight < minHeight)
          errorMsg = `Ukuran minimal ${minWidth} x ${minHeight}px`;

        if (naturalWidth / naturalHeight !== 1 && ratio === "square")
          errorMsg = "Rasio gambar harus 1:1";
        else if (
          Math.floor(naturalWidth / naturalHeight) !== 2 &&
          ratio === "landscape"
        )
          errorMsg = "Rasio gambar harus 2:1";

        if (!allowedType.includes(file.type) || !deepTypeChecking) {
          errorMsg = `Format gambar yang diperbolehkan jpg, jpeg & png`;
        }

        if (errorMsg) {
          toast({
            label: errorMsg,
            placement: "top",
            backgroundColor: "red50",
          });
        } else {
          onChange(dataURL);
        }
      };
      reader.readAsDataURL(file);
    },
  });
  if (value !== defaultValue)
    return (
      <Flex
        width="100%"
        position="relative"
        onMouseOver={() => setShowOverlay(true)}
        onMouseOut={() => setShowOverlay(false)}
        justifyContent="center"
      >
        <Flex
          as="img"
          alt="img-banner"
          src={value}
          borderRadius="8px"
          borderWidth="1px"
          borderStyle="solid"
          borderColor="black10"
          backgroundColor="black5"
          height="116px"
          width="241px"
          style={{ objectFit: "contain" }}
        />

        <Flex
          backgroundColor="overlay50"
          height="116px"
          width="241px"
          position="absolute"
          borderRadius="8px"
          alignItems="center"
          justifyContent="center"
          gap="8px"
          zIndex={showOverlay ? 5 : -99}
        >
          <Upload {...register} accept="image/jpg, image/jpeg, image/png">
            <Icon
              name="Edit-outline"
              size={24}
              color="white"
              cursor="pointer"
            />
          </Upload>
          <Icon
            name="Trash-outline"
            size={24}
            color="white"
            cursor="pointer"
            onClick={() => onChange(defaultValue)}
          />
        </Flex>
      </Flex>
    );

  return (
    <Flex
      borderStyle="dashed"
      borderWidth="1px"
      borderColor="black25"
      borderRadius="8px"
      gap="8px"
      p="8px"
      flexDirection="column"
      alignItems="center"
      textAlign="center"
    >
      <Text variant="caption" color="black50" alignItems="center">
        {`Upload file dengan format JPG, JPEG, atau PNG. Ukuran min. 
        ${minWidth} x ${minHeight} px dengan file maks. ${maxSize} MB.`}
        <br />
        {description}
      </Text>
      <Upload {...register} accept="image/jpg, image/jpeg, image/png">
        <StyledButton variant="secondary" size="small">
          Upload Gambar
        </StyledButton>
      </Upload>
    </Flex>
  );
};

export default ImgUploader;
