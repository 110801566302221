import { useEffect, useState, useRef, useMemo } from "react";
import PropTypes from "prop-types";
import { Box, Flex, Button, Text, isEmpty } from "renos-ui";
import { Autocomplete, GoogleMap, useLoadScript } from "@react-google-maps/api";
import ImgLocation from "assets/icons/Icon_Solid_Location.svg";
import ImgShadow from "assets/icons/Shadow_Pin_Point.png";
import { StyledInput } from "./styled";
import {
  API_KEY,
  DEFAULT_MAP_POSITION,
  LIBRARIES as libraries,
} from "constants/googleMap";

const mapContainerStyle = {
  width: "100%",
  height: "100%",
  borderRadius: "8px",
};

const mapOptions = {
  zoomControl: true,
  scaleControl: true,
  rotateControl: true,
  mapTypeControl: false,
  fullscreenControl: false,
  streetViewControl: false,
  mapTypeId: "roadmap",
};

const Map = ({ position, onChange, address }) => {
  const mapRef = useRef(null);
  const autocompleteRef = useRef();
  const [isDragged, setIsDragged] = useState(false);
  const [isEditMode, setIsEditMode] = useState(true);
  const [selectedAddress, setSelectedAddress] = useState(address);

  const defaultMapLatitude = useMemo(() => {
    return isEmpty(position?.lat)
      ? DEFAULT_MAP_POSITION.latitude
      : position.lat;
  }, [position?.lat]);

  const defaultMapLongitude = useMemo(() => {
    return isEmpty(position?.long)
      ? DEFAULT_MAP_POSITION.longitude
      : position.long;
  }, [position?.long]);

  const [currentMapCoordinates, setCurrentMapCoordinates] = useState({
    lat: defaultMapLatitude,
    lng: defaultMapLongitude,
  });

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: API_KEY,
    libraries,
  });

  const onLoadAutocomplete = (autocomplete) => {
    autocompleteRef.current = autocomplete;
  };

  const onKeyDownSearch = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      onPlaceChangeAutocomplete();
    }
  };

  const onPlaceChangeAutocomplete = () => {
    if (autocompleteRef.current) {
      const place = autocompleteRef.current.getPlace();

      if (place?.geometry && place?.geometry?.location) {
        const lat = place.geometry.location.lat();
        const lng = place.geometry.location.lng();
        setCurrentMapCoordinates({ lat, lng });
      }
    }
  };

  const setLocation = async () => {
    setIsEditMode(false);
    const geocoder = new window.google.maps.Geocoder();

    try {
      const response = await geocoder.geocode({
        location: currentMapCoordinates,
      });

      if (response.results[0]) {
        setSelectedAddress(response.results[0].formatted_address);
        onChange(currentMapCoordinates, response.results[0].formatted_address);
      } else {
        onChange(
          currentMapCoordinates,
          "No results found for the given position"
        );
      }
    } catch (e) {
      onChange(currentMapCoordinates, "Error occurred while fetching location");
    }
  };

  const onLoadMap = () => {
    if (mapRef.current) {
      const map = mapRef.current.state.map;
      const marker = new window.google.maps.Marker({
        icon: ImgLocation,
        map,
        animation: window.google.maps.Animation.DROP,
      });

      mapRef.current.mapMarker = marker;
      marker.bindTo("position", map, "center");
    }
  };

  const onDragStart = () => {
    setIsDragged(true);
    mapRef.current.mapMarker.setAnimation(3);
  };

  const onDragEnd = () => {
    setIsDragged(false);
    mapRef.current.mapMarker.setAnimation(4);
    const { lat, lng } = mapRef.current.state.map.getCenter();

    setCurrentMapCoordinates({
      lat: lat(),
      lng: lng(),
    });
  };

  useEffect(() => {
    if (!isEmpty(position.lat)) {
      setTimeout(() => {
        setLocation();
      }, 100);
    }

    // eslint-disable-next-line
  }, []);

  return isLoaded ? (
    <GoogleMap
      zoom={16}
      ref={mapRef}
      onLoad={onLoadMap}
      options={mapOptions}
      onDragEnd={onDragEnd}
      center={currentMapCoordinates}
      onDragStart={onDragStart}
      mapContainerStyle={mapContainerStyle}
    >
      <Autocomplete
        onLoad={onLoadAutocomplete}
        onPlaceChanged={onPlaceChangeAutocomplete}
      >
        <StyledInput
          autoFocus
          placeholder="Cari Lokasi"
          onKeyDown={onKeyDownSearch}
        />
      </Autocomplete>
      {isEditMode && (
        <Box>
          {!isDragged && (
            <Box
              position="absolute"
              top="70px"
              left="50%"
              zIndex="1"
              marginLeft="-68px"
            >
              <Button
                variant="tertiary"
                type="button"
                size="medium"
                onClick={setLocation}
              >
                Pilih Lokasi Ini
              </Button>
            </Box>
          )}
          <Box
            as="img"
            src={ImgShadow}
            alt="img-shadow"
            position="absolute"
            top="143px"
            left="50%"
            zIndex="1"
            marginLeft="-8px"
            marginTop="-2px"
            height="4px"
            width="16px"
            cursor="pointer"
          />
        </Box>
      )}
      {!isEditMode && (
        <Flex
          zIndex="1"
          gap="20px"
          height="100%"
          borderRadius="8px"
          padding="10px 24px"
          alignItems="center"
          position="absolute"
          flexDirection="column"
          justifyContent="center"
          width="-webkit-fill-available"
          backgroundColor="rgba(20, 20, 32, 0.75)"
        >
          <Box width="100%" margin="0px 16px" textAlign="center">
            <Text variant="body" color="white">
              {selectedAddress}
            </Text>
          </Box>
          <Flex
            width="128px"
            justifyContent="center"
            flexDirection="row-reverse"
          >
            <Button
              size="small"
              type="button"
              variant="secondary"
              onClick={() => setIsEditMode(true)}
            >
              Ubah Lokasi
            </Button>
          </Flex>
        </Flex>
      )}
    </GoogleMap>
  ) : (
    <Flex
      height="100%"
      width="100%"
      backgroundColor="black5"
      borderRadius={8}
      justifyContent="center"
      alignItems="center"
    >
      <Text size="small" color="black75">
        Memuat Peta...
      </Text>
    </Flex>
  );
};

Map.defaultProps = {
  config: {},
  position: {},
  onChange: () => {},
};

Map.propTypes = {
  onChange: PropTypes.func.isRequired,
  position: PropTypes.objectOf(PropTypes.number),
};

export default Map;
