import styled from "styled-components";
import { Dialog, Input } from "renos-ui";

export const StyledTextArea = styled(Input.TextArea)`
  height: 176px;
  resize: none;
`;

export const StyledDialog = styled(Dialog)`
  margin: -16px;
  position: relative;
`;
