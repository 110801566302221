import React from "react";
import { Flex, Text, Button } from "renos-ui";
import { useHistory } from "react-router-dom";
import mobileUrl from "constants/mobileUrl";

const EmptyState = () => {
  const history = useHistory();

  const handleAdd = () => {
    history.push(mobileUrl.STORE_LOCATION_FORM, { data: "", mode: "add" });
  };

  return (
    <Flex
      gap="8px"
      width="100%"
      columnGap="8px"
      paddingTop="52px"
      alignItems="center"
      paddingBottom="24px"
      flexDirection="column"
      justifyContent="center"
      boxSizing="border-box"
    >
      <Flex
        as="img"
        alt="empty-note"
        src="/assets/images/mobile/empty-notes.svg"
        width="236px"
        height="236px"
      />
      <Text color="black75" textAlign="center" weight="bold" variant="body">
        Belum ada lokasi di tokomu
      </Text>
      <Flex
        width="236px"
        marginX="60px"
        textAlign="center"
        justifyContent="center"
      >
        <Text color="black50" textAlign="center" variant="caption">
          Atur lokasi toko untuk perhitungan ongkos kirim saat pembeli
          berbelanja di tokomu.
        </Text>
      </Flex>
      <Button size="medium" onClick={handleAdd}>
        Tambah Lokasi
      </Button>
    </Flex>
  );
};

export default EmptyState;
