import React from "react";
import dayjs from "dayjs";
import { Flex, Text } from "renos-ui";
import PropTypes from "prop-types";
import { StyledFlex, Wrapper } from "./styled";

const NotificationItem = ({ date, title, subTitle, onClick, isRead }) => {
  const formattedDate = dayjs(date).format("DD MMM YYYY");

  return (
    <StyledFlex
      justifyContent="start"
      flexDirection="column"
      backgroundColor={!isRead && "blue5"}
      borderRadius="8px"
      width="296px"
      padding="16px"
      gap="8px"
      cursor="pointer"
      onClick={onClick}
    >
      <Text variant="small">{formattedDate}</Text>
      <Flex flexDirection="column" gap="4px">
        <Wrapper>
          <Text variant="caption" color="black100" weight="bold" lineClamp={2}>
            {title}
          </Text>
        </Wrapper>

        <Wrapper>
          <Text variant="caption" lineClamp={2}>
            {subTitle}
          </Text>
        </Wrapper>
      </Flex>
    </StyledFlex>
  );
};

NotificationItem.propTypes = {
  date: PropTypes.oneOfType([
    PropTypes.instanceOf(Date).isRequired,
    PropTypes.string.isRequired,
  ]),
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  isRead: PropTypes.bool.isRequired,
};

export default NotificationItem;
