import StoreLayoutContext from "providers/StoreLayoutProvider";
import { useContext, useEffect, useState } from "react";
import { Flex, Text, range } from "renos-ui";
import SwiperContext, { SwiperProvider } from "providers/SwiperProvider";
import { ExtraSmallText, StyledSwiper } from "./styled";
import { SwiperSlide, useSwiper } from "swiper/react";
import { abbreviationNum, currencyFormatter } from "helpers";

const Voucher = ({ isPreviewMode }) => {
  const { voucherRef, dataHomePage } = useContext(StoreLayoutContext);
  const [dataStoreFront, setDataStoreFront] = useState({});
  const [swiperInstance, setSwiperInstance] = useState();

  useEffect(() => {
    const selectedNewPrduct = dataHomePage.filter(
      (homepage) => homepage.id === 2
    )[0];
    setDataStoreFront(selectedNewPrduct);
  }, [dataHomePage]);

  const renderCard = (voucher, indexVoucher) => (
    <Flex
      background={`url("/assets/images/store-layout/voucher-layout-mobile.svg")`}
      backgroundRepeat="no-repeat"
      backgroundSize="156px"
      width="156px"
      height="60px"
      key={`voucher${indexVoucher}`}
      boxShadow="0px 3.3230769634246826px 6.646153926849365px 0px #1414201A"
    >
      <Flex padding="6px 12px" flexDirection="column">
        <ExtraSmallText variant="small" weight="bold" color="white">
          DISKON
        </ExtraSmallText>
        <Text variant="heading3" weight="bold" color="white">
          {voucher.discount_type_id === 1
            ? `${voucher.promo_percentage}%`
            : `Rp ${abbreviationNum(voucher.promo_amount)}`}
        </Text>
        <ExtraSmallText variant="small" color="white">
          min. pembelian{" "}
          {`Rp ${currencyFormatter(voucher.minimum_transaction)}`}
        </ExtraSmallText>
      </Flex>
    </Flex>
  );

  if (isPreviewMode && !dataStoreFront?.isActive) return <></>;

  return (
    <Flex gap="16px" flexDirection="column" ref={voucherRef}>
      <Text weight="bold">Voucher Toko</Text>
      {dataStoreFront?.isActive ? (
        dataStoreFront?.data?.length > 2 ? (
          <SwiperProvider value={{ setSwiperInstance, swiperInstance }}>
            <Flex position="relative">
              <StyledSwiper slidesPerView={2} spaceBetween={8}>
                <Flex>
                  {dataStoreFront?.data.map((voucher, indexVoucher) => {
                    return (
                      <SwiperSlide key={`swiper-${indexVoucher}`}>
                        {renderCard(voucher, indexVoucher)}
                      </SwiperSlide>
                    );
                  })}
                </Flex>
                <SwiperInstance />
              </StyledSwiper>
            </Flex>
          </SwiperProvider>
        ) : (
          <Flex gap="8px">
            {dataStoreFront?.data.map((voucher, indexVoucher) =>
              renderCard(voucher, indexVoucher)
            )}
          </Flex>
        )
      ) : (
        <Flex gap="8px">
          {range(2).map((_, indexEmpty) => (
            <Flex
              key={`empty-${indexEmpty}`}
              width="156px"
              height="60px"
              borderRadius="10px"
              backgroundColor="black10"
            />
          ))}
        </Flex>
      )}
    </Flex>
  );
};

const SwiperInstance = () => {
  const { setSwiperInstance } = useContext(SwiperContext);
  const swiper = useSwiper();

  useEffect(() => {
    if (swiper) return setSwiperInstance(swiper);
  }, [swiper, setSwiperInstance]);
};

export default Voucher;
