import { Button, Divider, Flex, Input, Text } from "renos-ui";

const ListChoice = ({
  selectedReason,
  setSelectedReason,
  setIsFormMode,
  onReasonClose,
}) => {
  const arrReason = [
    {
      id: 1,
      name: "Stok produk kosong",
    },
    {
      id: 2,
      name: "Toko sedang tutup / libur",
    },
    {
      id: 3,
      name: "Kendala pengiriman",
    },
    {
      id: 4,
      name: "Pembeli tidak merespon diskusi",
    },
    {
      id: 5,
      name: "Lainnya",
    },
  ];

  return (
    <Flex flexDirection="column" width="100%" gap="24px" height="100%">
      <Text variant="subtitle" weight="bold">
        Kenapa kamu menolak pesanan ini?
      </Text>

      <Flex flexDirection="column" gap="16px">
        {arrReason.map((reason, indexReason) => (
          <Flex flexDirection="column" gap="16px" key={`reason-${indexReason}`}>
            <Flex justifyContent="space-between" alignItems="center">
              <Text>{reason.name}</Text>
              <Input.Radio
                checked={selectedReason === reason.id}
                onClick={() => setSelectedReason(reason.id)}
              />
            </Flex>
            {indexReason !== arrReason.length - 1 && <Divider />}
          </Flex>
        ))}
      </Flex>

      <Flex gap="8px">
        <Button
          variant="tertiary"
          size="medium"
          isBlock
          onClick={onReasonClose}
        >
          Batal
        </Button>
        <Button size="medium" isBlock onClick={() => setIsFormMode(true)}>
          Selanjutnya
        </Button>
      </Flex>
    </Flex>
  );
};

export default ListChoice;
