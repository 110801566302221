import React from "react";
import { useHistory } from "react-router-dom";
import { Flex, Text, Icon, Button, Drawer, Divider } from "renos-ui";
import { Container } from "./styles";
import useToggle from "./useToggle";

const ToggleStore = () => {
  const history = useHistory();
  const {
    dataGetSellerFleet,
    isGetSellerFleetLoading,
    refetchGetSellerFleet,
    refetchActivate,
    refetchDeactivate,
    showDisclosure,
    handleGTM,
    isGetSellerFleetError,
  } = useToggle();

  return (
    <Container>
      <Flex alignItems="center" justifyContent="space-between">
        <Text weight="bold" variant="caption">
          Kurir Toko
        </Text>
        {dataGetSellerFleet?.data.active && (
          <Icon
            name="Vertical-outline"
            color="black50"
            onClick={() => showDisclosure.onOpen()}
          />
        )}
      </Flex>
      {isGetSellerFleetError ? (
        <Button
          variant="secondary"
          size="small"
          disabled={isGetSellerFleetLoading}
          onClick={refetchGetSellerFleet}
        >
          Muat Ulang
        </Button>
      ) : dataGetSellerFleet?.data.active ? (
        <Text variant="caption">
          Layanan pengiriman menggunakan kurir tokomu telah aktif.
        </Text>
      ) : (
        <>
          <Text variant="caption">
            Tambahkan opsi pengiriman menggunakan layanan kurir tokomu.
          </Text>
          <Text
            variant="caption"
            weight="bold"
            color="blue50"
            onClick={refetchActivate}
          >
            Aktifkan Sekarang
          </Text>
        </>
      )}

      <Drawer
        isVisible={showDisclosure.isOpen}
        placement="bottom"
        closable={true}
        onClose={() => showDisclosure.onClose()}
      >
        <Flex flexDirection="column" gap="16px" marginTop="16px">
          <Text
            onClick={() => {
              refetchDeactivate();
              showDisclosure.onClose();
            }}
          >
            Matikan layanan
          </Text>
          <Divider />
          <Text
            onClick={() => {
              showDisclosure.onClose();
              handleGTM("arrange");
              history.push("/account/list-setting");
            }}
          >
            Ubah Pengaturan
          </Text>
        </Flex>
      </Drawer>
    </Container>
  );
};

export default ToggleStore;
