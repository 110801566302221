import { useEffect } from "react";
import { useState } from "react";
import {
  Button,
  Checkbox,
  Dialog,
  Divider,
  Flex,
  // Label,
  Spinner,
  Text,
  Upload,
  useToast,
  useUpload,
} from "renos-ui";
import { useSendOrder } from "services/order-management";
import { StyledButton, StyledTextArea } from "./styled";
import { goToUrl } from "helpers";

const ModalSendOrder = ({
  isOpen,
  onClose,
  refetchStatus,
  refetchList,
  orderId,
}) => {
  const toast = useToast();

  const [notes, setNotes] = useState("");
  const [dataImage, setDataImage] = useState({});
  const [check, setCheck] = useState(false);

  const { mutate: sendOrder, isLoading } = useSendOrder({
    onSuccess: (data) => {
      const { message, frontend_message } = data;

      refetchStatus();
      refetchList();
      onClose();

      toast({
        label: frontend_message || message || "Pesanan berhasil dikirim",
        placement: "top",
      });
    },
    onError: (data) => {
      const {
        response: {
          data: { frontend_message, message },
        },
      } = data;
      toast({
        label: frontend_message || message || "Pesanan gagal dikirim",
        placement: "top",
        backgroundColor: "red50",
      });
    },
  });

  const handleSave = () => {
    const formData = new FormData();

    formData.append("order_id", orderId);
    formData.append("delivery_notes", notes);

    if (notes) {
      formData.append("delivery_notes", notes);
    }

    if (dataImage.imageFile) {
      formData.append("file", dataImage.imageFile);
    }
    sendOrder(formData);
  };

  const { register, result } = useUpload({
    fileType: "image",
  });

  useEffect(() => {
    const maxAllowedSize = 10 * 1024 * 1024;
    const allowedType = [
      "image/png",
      "image/jpeg",
      "image/jpg",
      "application/pdf",
    ];

    if (result) {
      let errorCount = 0;

      if (maxAllowedSize < result?.file?.size) {
        toast({
          label: "Ukuran file tidak boleh melebihi dari 10mb.",
          placement: "top",
          backgroundColor: "red50",
        });

        errorCount++;
      }

      if (!allowedType.includes(result?.file?.type)) {
        toast({
          label: "File harus berekstensi jpg/jpeg/png/pdf.",
          placement: "top",
          backgroundColor: "red50",
        });

        errorCount++;
      }

      if (errorCount === 0) {
        setDataImage({
          imageUrl: result?.source,
          imageFile: result?.file,
        });
      }
    }
    // eslint-disable-next-line
  }, [result]);

  return (
    <Dialog isVisible={isOpen} onClose={onClose} verticalCentered closable>
      <Flex flexDirection="column" gap="24px" width="320px">
        <Flex flexDirection="column" gap="16px">
          <Text variant="heading3" weight="bold">
            Kirim Pesanan
          </Text>
          <Divider />
        </Flex>
        <Flex flexDirection="column" gap="8px">
          <Flex flexDirection="column" gap="4px">
            <Flex gap="8px">
              <Text color="black75" weight="bold">
                Upload Dokumen Pengiriman{" "}
              </Text>
              {/* <Label>Wajib</Label> */}
            </Flex>
            <Text color="black75" variant="caption">
              Upload Dokumen Pengiriman kurirmu menggunakan formulir yang dapat
              diunduh di bawah ini.
            </Text>
            <Flex
              cursor="pointer"
              onClick={() =>
                goToUrl(
                  `${process.env.REACT_APP_ASSETS_IMAGE}/templates/dokumen_pengiriman.docx`,
                  "_blank"
                )
              }
            >
              <Text color="blue50" variant="caption">
                <u>Unduh Form Dokumen Pengiriman</u>
              </Text>
            </Flex>
          </Flex>
          <Flex
            height="136px"
            borderColor="black10"
            borderWidth="1px"
            borderStyle="dashed"
            borderRadius="8px"
            justifyContent="center"
            flexDirection="column"
            alignItems="center"
            gap="16px"
          >
            {dataImage?.imageUrl ? (
              <Flex gap="6px">
                {dataImage?.imageFile?.type === "application/pdf" ? (
                  <Flex
                    as="img"
                    alt="preview"
                    width="100px"
                    height="64px"
                    src={"/assets/images/order-management/pdf.png"}
                    style={{ objectFit: "contain" }}
                  />
                ) : (
                  <Flex
                    borderRadius="4px"
                    as="img"
                    alt="preview"
                    width="100px"
                    height="64px"
                    src={dataImage?.imageUrl}
                    style={{ objectFit: "contain" }}
                  />
                )}

                <Flex flexDirection="column" gap="4px">
                  <Flex flexDirection="column">
                    <Text variant="caption" color="black75" weight="bold">
                      Upload Berhasil!
                    </Text>
                    <Text variant="caption" color="black75">
                      File berhasil diperbahurui.
                    </Text>
                  </Flex>
                  <Upload {...register} accept=".jpg, .jpeg, .png, .pdf">
                    <Flex cursor="pointer">
                      <Text variant="caption" color="blue50" weight="bold">
                        Ubah
                      </Text>
                    </Flex>
                  </Upload>
                </Flex>
              </Flex>
            ) : (
              <>
                <Flex width="160px" textAlign="center">
                  <Text variant="caption" color="black50">
                    Upload file dengan format JPG, JPEG, PNG atau PDF.
                  </Text>
                </Flex>

                <Upload {...register} accept=".jpg, .jpeg, .png, .pdf">
                  <Button variant="secondary" size="small">
                    Upload Dokumen
                  </Button>
                </Upload>
              </>
            )}
          </Flex>
        </Flex>
        <StyledTextArea
          label="catatan"
          placeholder="Tulis catatan pengiriman, misalnya nama kurir dan nomor telepon kurir."
          value={notes}
          maxLength={150}
          onChange={(event) => setNotes(event.currentTarget.value)}
        />
        <Divider />
        <Flex gap="16px" alignItems="center">
          <Flex>
            <Checkbox checked={check} onChange={() => setCheck(!check)} />
          </Flex>
          <Flex flexDirection="column" gap="4px">
            <Text variant="caption" color="black75">
              Saya bersedia menanggung segala bentuk komplain jika Dokumen
              Pengiriman tidak sesuai dengan format{" "}
              <span
                onClick={() =>
                  goToUrl(
                    "https://www.renos.id/help-renos/?page_id=773",
                    "_blank"
                  )
                }
                cursor="pointer"
              >
                <Text variant="caption" color="blue50" weight="bold">
                  Bukti Pengiriman Barang.
                </Text>
              </span>
            </Text>
          </Flex>
        </Flex>
        <StyledButton
          isBlock
          disabled={!check || isLoading}
          preffix={isLoading && <Spinner color="white" size="8px" />}
          onClick={handleSave}
        >
          Kirim Pesanan
        </StyledButton>
      </Flex>
    </Dialog>
  );
};

export default ModalSendOrder;
