import React from "react";
import { StyledTextArea } from "../styled";
import { Box, Button, Flex, Text, Dialog, Input } from "renos-ui";

const ModalAdd = ({
  addModal,
  mode,
  handleChangeValue,
  value,
  handleSave,
  isSaveLoading,
}) => {
  return (
    <Dialog
      isVisible={addModal.isOpen}
      closable
      onClose={addModal.onClose}
      verticalCentered
      padding="40px"
    >
      <Flex flexDirection="column" width="100%" gap="24px">
        <Text variant="heading3" weight="bold">
          {mode === "add" ? "Tambah" : "Ubah"} Catatan
        </Text>

        <Flex flexDirection="column" gap="16px">
          <Text variant="caption" color="black50" weight="bold">
            Judul Catatan
          </Text>
          <Box width="100%">
            <Input
              name="title"
              placeholder="Masukkan judul catatan"
              value={value.title}
              onChange={handleChangeValue}
            />
          </Box>
        </Flex>

        <Flex flexDirection="column" gap="16px">
          <Text variant="caption" color="black50" weight="bold">
            Catatan
          </Text>
          <StyledTextArea
            name="note"
            placeholder="Tulis detail catatan..."
            value={value.note}
            onChange={handleChangeValue}
          />
        </Flex>

        <Button
          isBlock
          onClick={handleSave}
          disabled={value.id === "" || value.note === "" || isSaveLoading}
        >
          Simpan
        </Button>
      </Flex>
    </Dialog>
  );
};

export default ModalAdd;
