import dayjs from "dayjs";
import StoreContext from "providers/StoreProvider";
import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { useContext } from "react";
import { Box, range, Flex, Skeleton, Text } from "renos-ui";
import {
  useGetNotification,
  useUpdateNotification,
} from "services/notification";
import { NotificationItem } from "./styled";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useQueryClient } from "react-query";

const NotificationSelling = () => {
  const queryClient = useQueryClient();
  const { push } = useHistory();
  const {
    storeData: {
      data: { id: storeId },
    },
  } = useContext(StoreContext);

  const { data, isLoading, fetchNextPage } = useGetNotification({
    store_id: storeId,
    notification_type_id: 3,
  });

  const observerElem = useRef(null);

  const hasNextPage = useMemo(() => {
    let count = 0;

    if (data?.pages) {
      data.pages.forEach((page) => {
        count += page.data.length;
      });
    }

    return count < data?.pages[0].total;
  }, [data]);

  const handleObserver = useCallback(
    (entries) => {
      const [target] = entries;

      if (target.isIntersecting) {
        fetchNextPage();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fetchNextPage, hasNextPage]
  );
  const isNotifEmpty = useMemo(
    () => !isLoading && data.pages.map((page) => page.data)[0].length === 0,
    [data, isLoading]
  );

  useEffect(() => {
    const element = observerElem.current;

    if (element) {
      const option = { threshold: 0 };

      const observer = new IntersectionObserver(handleObserver, option);
      observer.observe(element);

      return () => observer.unobserve(element);
    }
  }, [fetchNextPage, hasNextPage, handleObserver, observerElem]);

  const { mutate: updateNotification } = useUpdateNotification({});

  const handleShowTransaction = (orderNumber) => {
    push(`/sales/orders?query=${orderNumber}`);
  };

  const onNotificationClicked = (notificationId, orderNumber) => {
    const payload = {
      store_id: storeId,
      notification_id: notificationId,
    };

    updateNotification(payload);
    queryClient.invalidateQueries({
      queryKey: ["notifCount"],
    });
    handleShowTransaction(orderNumber);
  };

  if (isNotifEmpty) {
    return (
      <Flex
        justifyContent="center"
        alignItems="center"
        height="calc(100vh - 270px)"
      >
        <Flex flexDirection="column" rowGap="40px" alignItems="center">
          <img
            src="/assets/images/store-layout/no-product.svg"
            alt="no-notification-img"
            width="200px"
            height="200px"
          />
          <Flex
            flexDirection="column"
            textAlign="center"
            rowGap="8px"
            width="240px"
          >
            <Text variant="heading3" weight="bold">
              Belum Ada Notifikasi
            </Text>
            <Text>Terus pantau update terbaru untuk tokomu di sini.</Text>
          </Flex>
        </Flex>
      </Flex>
    );
  }

  return (
    <Flex
      flexDirection="column"
      rowGap="8px"
      width="100%"
      mt="16px"
      maxHeight="calc(100vh - 270px)"
      overflow="auto"
    >
      {isLoading
        ? range(4).map((_, i) => (
            <Skeleton key={`skeleton-${i}`} height="104px" />
          ))
        : data.pages.map((page) =>
            page.data.map((notificationData) => {
              const {
                notification_id: id,
                title,
                message,
                date_in: date,
                is_read: isRead,
                order_number,
              } = notificationData;

              return (
                <NotificationItem
                  key={id}
                  flexDirection="column"
                  rowGap="4px"
                  padding="16px"
                  borderRadius="16px"
                  backgroundColor={!isRead && "blue5"}
                  cursor="pointer"
                  onClick={() => onNotificationClicked(id, order_number)}
                >
                  <Text variant="caption" color="black50">
                    {dayjs(date).format("DD MMM YYYY")}
                  </Text>
                  <NotificationItem flexDirection="column">
                    <Text weight="bold">{title}</Text>
                    <Text color="black75" lineClamp={2}>
                      {message}
                    </Text>
                  </NotificationItem>
                </NotificationItem>
              );
            })
          )}

      <Box ref={observerElem} width="100%">
        <Skeleton height="104px" />
      </Box>
    </Flex>
  );
};

export default NotificationSelling;
