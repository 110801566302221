import styled from "styled-components";
import { Button, Input } from "renos-ui";

export const StyledButton = styled(Button)`
  border-radius: 4px;
`;

export const StyledButtonIcon = styled(Button)`
  border-radius: 4px;
  padding: 6px;
`;

export const StyledTextArea = styled(Input.TextArea)`
  height: 96px;
  resize: none;
`;

export const StyledTextAreaSellerFleet = styled(Input.TextArea)`
  height: 88px;
`;

export const StyledTextAreaReject = styled(Input.TextArea)`
  height: 72px;
  resize: none;
`;
