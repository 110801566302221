import MobileLayout from "layouts/MobileLayout";
import React, { useMemo } from "react";
import { Box, Icon, Flex, Text, Label, Divider } from "renos-ui";
import { useHistory } from "react-router-dom";
import { settingMenu, orderMenu } from "./constants";
import { useLogout } from "services/auth";
import mobileUrl from "constants/mobileUrl";
import { setGTM } from "helpers";
import { useGetStoreDetail } from "services/store";
import Loading from "./Loading";

const Account = () => {
  const { push } = useHistory();

  const { isLoading, data } = useGetStoreDetail({
    need: "total_product",
  });

  const defaultImage = "/assets/images/default_store.svg";

  const dataStore = useMemo(
    () => (data?.data ? data?.data : {}),

    [data]
  );

  const { mutate: onLogout } = useLogout();

  return (
    <MobileLayout footer>
      <Flex
        width="100%"
        background="white"
        flexDirection="column"
        position="relative"
      >
        <Flex
          background={`url("/assets/images/mobile/account-bg.svg")`}
          height="114px"
          width="100%"
          backgroundRepeat="no-repeat"
          backgroundSize="100%"
          justifyContent="space-between"
          alignItems="center"
        >
          {isLoading ? (
            <Loading />
          ) : (
            <Flex alignItems="center">
              <Flex
                flexDirection="column"
                alignItems="center"
                rowGap="8px"
                px="16px"
              >
                <Flex
                  borderRadius="100px"
                  height="50px"
                  width="50px"
                  border="4px solid #fff"
                >
                  <Flex
                    borderRadius="100px"
                    height="50px"
                    width="50px"
                    as="img"
                    alt="account-img"
                    src={
                      dataStore?.image_path ||
                      dataStore?.image_url ||
                      defaultImage
                    }
                  />
                </Flex>
              </Flex>
              <Flex flexDirection="column" gap="6px">
                <Flex>
                  <Text color="white" weight="bold" lineClamp={1}>
                    {dataStore?.name}
                  </Text>
                </Flex>
                <Flex gap="8px">
                  {dataStore?.is_open?.status ? (
                    <>
                      <Label variant="success">Buka</Label>
                      <Text color="white">
                        {dataStore?.is_open?.hour_schedule}
                      </Text>
                    </>
                  ) : (
                    <Label variant="error">Tutup</Label>
                  )}
                </Flex>
              </Flex>
            </Flex>
          )}
          {/* <Flex px="16px">
            <Button
              size="small"
              icon={<Icon size={16} name="Right-outline" />}
              variant="circular"
              onClick={onClickBuyyer}
            />
          </Flex> */}
        </Flex>

        <Flex
          flexDirection="column"
          background="white"
          borderTopRightRadius="8px"
          borderTopLeftRadius="8px"
          position="absolute"
          top="106px"
          width="100%"
          height="calc(100% - 106px)"
          overflow="auto"
          style={{ boxSizing: "border-box" }}
        >
          {/* <Box gap="16px" padding="16px">
            <Flex justifyContent="space-between">
              <Text variant="caption">Total Saldo Aktif</Text>
              <Text variant="caption" color="blue50" weight="bold">
                Rp.10.523.000
              </Text>
            </Flex>
          </Box> */}

          <Flex>
            <Divider />
          </Flex>

          <Box
            flexDirection="column"
            gap="16px"
            padding="16px"
            background="white"
          >
            <Flex gap="16px" flexDirection="column">
              <Flex>
                <Text weight="bold">Penjualan</Text>
              </Flex>

              {orderMenu.map((menu, index) => (
                <Flex
                  key={index}
                  gap="8px"
                  alignItems="center"
                  onClick={() => {
                    push(menu.path);

                    if (menu.path === mobileUrl.ACCOUNT_RATING_REVIEW)
                      setGTM("seller_center_section", {
                        event_category: "menu",
                        event_action: "click",
                        event_label: "review",
                      });
                  }}
                >
                  <Icon size={16} name={menu.icon} />
                  <Text variant="caption">{menu.name}</Text>
                </Flex>
              ))}
            </Flex>
          </Box>
          <Flex>
            <Divider height="8px !important" backgroundColor="black5" />
          </Flex>
          <Box
            flexDirection="column"
            gap="16px"
            padding="16px"
            background="white"
          >
            <Flex gap="16px" flexDirection="column">
              <Flex>
                <Text weight="bold">Pengaturan</Text>
              </Flex>

              {settingMenu.map((menu, index) => (
                <Flex
                  key={index}
                  gap="8px"
                  alignItems="center"
                  onClick={() => push(menu.path)}
                >
                  <Icon size={16} name={menu.icon} />
                  <Text variant="caption">{menu.name}</Text>
                </Flex>
              ))}
            </Flex>
          </Box>

          <Flex>
            <Divider height="8px !important" backgroundColor="black5" />
          </Flex>
          <Box
            flexDirection="column"
            gap="16px"
            padding="16px"
            background="white"
            onClick={onLogout}
          >
            <Flex gap="16px" flexDirection="column">
              <Flex gap="8px" alignItems="center">
                <Icon size={16} name="Logout-outline" />
                <Text variant="caption">Keluar</Text>
              </Flex>
            </Flex>
          </Box>
        </Flex>
      </Flex>
    </MobileLayout>
  );
};

export default Account;
