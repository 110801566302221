import { createContext, useContext, useReducer } from "react";
import constants from "./constants";

export const productAddInitialState = {
  productImages: [],
  productVideos: [],
  tempProductCategories: [],
  productCategories: [],
  tempProductName: "",
  productName: "",
  description: "",
  productStatus: null,
  shippingType: 1,
  productShipping: new Set([]),
  isPreorder: false,
  uom: 2,
  weight: 0,
  length: 0,
  width: 0,
  height: 0,
  preorderType: 1,
  preorder: 0,
  product_sku: "",
  stock: 0,
  price: 0,
  sku: [],
  isEdit: false,
  productVariants: [{}],
  product_active: true,
  variant: 0,
  insurance: 0,
  product_condition: null,
  tempId: null,
  fromEmpty: false,
  initialFilled: false,
  skeletonFilled: false,
  tempProductVideos: [],
  productDetail: null,
  initialVideoFilled: false,
  calledOnce: false,
  product_attributes: [],
};

export const productAddReducer = (state, action) => {
  switch (action.type) {
    case constants.SAVE_PRODUCT_ASSETS: {
      return {
        ...state,
        ...action.payload,
      };
    }

    case constants.DELETE_IMAGE: {
      const tempProductImages = [...state.productImages];
      tempProductImages.splice(action.payload, 1);

      return {
        ...state,
        productImages: tempProductImages,
      };
    }

    case constants.DELETE_VIDEO: {
      const tempProductVideos = [...state.productVideos];
      tempProductVideos.splice(action.payload, 1);

      return {
        ...state,
        productVideos: tempProductVideos,
      };
    }

    case constants.SAVE_PRODUCT_CATEGORY: {
      return {
        ...state,
        productCategories: action.payload.productCategories,
      };
    }

    case constants.SAVE_VALUE: {
      return {
        ...state,
        ...action.payload,
      };
    }

    default:
      break;
  }
  throw Error("Unknown action: " + action.type);
};

const ProductAddContext = createContext({});

export function useProductAddContext() {
  return useContext(ProductAddContext);
}

const ProductAddProvider = ({ children }) => {
  const [state, dispatch] = useReducer(
    productAddReducer,
    productAddInitialState
  );

  return (
    <ProductAddContext.Provider value={{ state, dispatch }}>
      {children}
    </ProductAddContext.Provider>
  );
};

export default ProductAddProvider;
