import { useEffect, useMemo, useState } from "react";
import { Button, Drawer, Flex, Icon, Spinner, Text, useToast } from "renos-ui";
import { useUpdateProductInline } from "services/product";
import StatusStockInput from "./StatusStockInput";

const StatusStock = ({
  disclosure: { isOpen, onClose },
  data,
  handleOnSuccess,
}) => {
  const {
    product_status_id: productStatusId,
    product_stock: productStock,
    product_skus: productSkus,
    product_sku_id,
    product_sku_number,
    product_id,
  } = data;

  const toast = useToast();
  const isVariant = useMemo(() => productSkus.length > 0, [productSkus]);
  const [value, setValue] = useState(
    isVariant
      ? [{ productStatusId: 2, productStock: 0 }]
      : { productStatusId: 2, productStock: 0 }
  );
  const [isStatusChanged, setIsStatusChanged] = useState(false);
  const [isStockChanged, setIsStockChanged] = useState(false);

  const { mutate: updateProductInline, isLoading } = useUpdateProductInline({
    onSuccess: () => {
      toast({
        label: `${toastTitle} produk berhasil diubah`,
        placement: "bottom",
      });

      let newData = data;

      if (isVariant) {
        const newSkus = newData.product_skus.map((skus, indexSkus) => ({
          ...skus,
          product_status_id: value[indexSkus].productStatusId,
          product_stock: value[indexSkus].productStock,
        }));

        newData = {
          ...newData,
          product_skus: [...newSkus],
          product_stock: newSkus.reduce(
            (partialSum, product) => partialSum + product.product_stock,
            0
          ),
          product_status_id:
            newSkus.filter(
              (filterStatus) => filterStatus.product_status_id === 1
            ).length > 0
              ? 1
              : 2,
        };
      } else {
        newData = {
          ...newData,
          product_status_id: value.productStatusId,
          product_stock: value.productStock,
        };
      }

      handleOnSuccess(newData);
    },
    onError: () => {
      toast({
        label: `${toastTitle} gagal diubah`,
        placement: "bottom",
        backgroundColor: "red50",
      });
    },
  });

  const toastTitle = useMemo(
    () =>
      isStatusChanged && !isStockChanged
        ? "Status"
        : isStockChanged && !isStatusChanged
        ? "Stok"
        : "Status & stok",
    [isStatusChanged, isStockChanged]
  );

  const handleSave = () => {
    let payload = {};

    if (isVariant) {
      payload = {
        data: productSkus.map((sku, indexSku) => ({
          product_id: sku.product_id,
          product_status_id: value[indexSku].productStatusId,
          product_stock: value[indexSku].productStock,
          product_sku_id: sku.product_sku_id,
          product_sku_number: sku.product_sku_number,
        })),
      };
    } else {
      payload = {
        product_id: product_id,
        product_status_id: value.productStatusId,
        product_stock: value.productStock,
        product_sku_id: product_sku_id,
        product_sku_number: product_sku_number,
      };
    }

    updateProductInline(payload);
  };

  useEffect(() => {
    if (isVariant) {
      const listValue = productSkus.map((variant) => ({
        productStatusId: variant.product_status_id,
        productStock: variant.product_stock,
      }));

      setValue([...listValue]);
    } else {
      setValue({ productStatusId, productStock });
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Drawer isVisible={isOpen} onClose={onClose} placement="bottom">
      <Flex flexDirection="column" gap="24px" height="100%">
        <Flex flexDirection="column" gap="16px">
          <Flex gap="8px">
            <Icon
              name="Close-outline"
              size={24}
              color="black50"
              onClick={onClose}
            />
            <Text variant="subtitle" weight="bold">
              Ubah Stok
            </Text>
          </Flex>
          <StatusStockInput
            setIsStatusChanged={setIsStatusChanged}
            setIsStockChanged={setIsStockChanged}
            disabled={isLoading}
            {...{
              value,
              setValue,
              isVariant,
              productSkus,
            }}
          />
        </Flex>

        <Button
          disabled={isLoading}
          size="medium"
          isBlock
          onClick={handleSave}
          preffix={isLoading && <Spinner color="white" size="8px" />}
        >
          Simpan
        </Button>
      </Flex>
    </Drawer>
  );
};

export default StatusStock;
