import React, { useContext, useEffect, useState } from "react";
import StoreLayoutContext from "providers/StoreLayoutProvider";
import {
  Flex,
  Button,
  Divider,
  useToast,
  useDisclosure,
  Box,
  Text,
} from "renos-ui";
import { useUpdateHomePage } from "services/store-layout/homepage";
import Header from "../component/Header";
import NoData from "../component/NoData";
import ModalAdd from "./ModalAdd";
import ModalDelete from "../component/ModalDelete";
import Card from "./ModalAdd/Card";

const ProductDiscount = ({ handleBack, id }) => {
  const toast = useToast();
  const disclosureAdd = useDisclosure({ isOpen: false });
  const disclosureDelete = useDisclosure({ isOpen: false });

  const [dataProductDiscount, setDataProductDiscount] = useState({ data: [] });

  const { dataHomePage, setDataHomePage } = useContext(StoreLayoutContext);

  const { mutate, isLoading: isUpdateHomePageLoading } = useUpdateHomePage({
    onSuccess: (data) => {
      const { message, frontend_message } = data;
      toast({
        label: frontend_message || message,
        placement: "top",
      });
      handleBack();
    },
    onError: (data) => {
      const {
        response: {
          data: { frontend_message, message },
        },
      } = data;
      toast({
        label: frontend_message || message,
        placement: "top",
        backgroundColor: "red50",
      });
    },
  });

  const handleSave = () => {
    const listIds = dataProductDiscount.data.map(
      (product) => product.product_id
    );
    mutate({
      type: id,
      ids: listIds,
    });
  };

  const handleSetHomePage = (newData) => {
    const tempDataHomePage = dataHomePage.map((homepage) => ({
      ...homepage,
      isActive:
        homepage.id === id ? newData.length > 0 && true : homepage.isActive,
      data: homepage.id === id ? newData : homepage.data,
      emptyWithFooter: homepage.id === id && true,
    }));
    setDataHomePage([...tempDataHomePage]);
  };

  const handleReset = () => {
    handleSetHomePage([]);
    disclosureDelete.onClose();
  };

  useEffect(() => {
    const selectedProductDiscount = dataHomePage.filter(
      (homepage) => homepage.id === id
    )[0];
    selectedProductDiscount.data = selectedProductDiscount.data
      ? selectedProductDiscount.data
      : [];
    setDataProductDiscount(selectedProductDiscount); // eslint-disable-next-line
  }, [dataHomePage]);

  return (
    <Flex flexDirection="column" gap="16px">
      <Header
        rightIcon={
          (dataProductDiscount.data.length !== 0 ||
            dataProductDiscount.emptyWithFooter) && (
            <Box cursor="pointer" onClick={disclosureAdd.onToggle}>
              <Text variant="caption" weight="bold" color="blue50">
                {dataProductDiscount.data.length === 0
                  ? "Tambah Produk"
                  : "Ubah Produk"}
              </Text>
            </Box>
          )
        }
        handleBack={handleBack}
        title="Produk Diskon"
      />
      {dataProductDiscount.data?.length === 0 ? (
        <>
          <NoData
            title="Belum Ada Produk Diskon"
            description="Yuk, pilih daftar produk diskon terlebih dahulu."
            btnText="Pilih Produk"
            onClick={
              !dataProductDiscount.emptyWithFooter && disclosureAdd.onToggle
            }
          />
          {dataProductDiscount.emptyWithFooter && (
            <>
              <Divider />
              <Flex
                justifyContent="space-between"
                paddingBottom="16px"
                gap="16px"
              >
                <Button
                  isBlock
                  variant="tertiary"
                  size="medium"
                  onClick={handleBack}
                >
                  Batal
                </Button>
                <Button
                  size="medium"
                  isBlock
                  disabled={isUpdateHomePageLoading}
                  onClick={handleSave}
                >
                  Simpan
                </Button>
              </Flex>
            </>
          )}
        </>
      ) : (
        <>
          <Flex
            height="284px"
            overflowY="auto"
            flexDirection="column"
            width="100%"
            gap="16px"
          >
            {
              // eslint-disable-next-line
              dataProductDiscount?.data.map((product, indexProduct) => {
                if (indexProduct < 15)
                  return (
                    <React.Fragment key={`product-card-${indexProduct}`}>
                      <Card data={product} isHasDivider={true} noCheck />
                    </React.Fragment>
                  );
              })
            }
          </Flex>
          <Divider />
          <Flex gap="16px" paddingBottom="24px">
            {dataProductDiscount.data.length > 0 ? (
              <Flex flexGrow={1}>
                <Button
                  size="medium"
                  variant="tertiary"
                  isBlock
                  onClick={disclosureDelete.onToggle}
                >
                  Hapus Pengaturan
                </Button>
              </Flex>
            ) : (
              <Flex
                flexGrow={1}
                flexBasis={dataProductDiscount.data.length === 0 ? 0 : 1}
              >
                <Button
                  size="medium"
                  variant="tertiary"
                  isBlock
                  onClick={handleBack}
                >
                  Batalkan
                </Button>
              </Flex>
            )}
            <Flex
              flexGrow={1}
              flexBasis={dataProductDiscount.data.length === 0 && 0}
            >
              <Button
                size="medium"
                isBlock
                disabled={isUpdateHomePageLoading}
                onClick={handleSave}
              >
                Simpan
              </Button>
            </Flex>
          </Flex>
        </>
      )}

      {disclosureAdd.isOpen && (
        <ModalAdd
          isOpen={disclosureAdd.isOpen}
          onClose={disclosureAdd.onClose}
          data={dataProductDiscount?.data || []}
          handleSetHomePage={handleSetHomePage}
          setData={(dataModal) =>
            setDataProductDiscount({
              ...dataProductDiscount,
              data: dataModal,
            })
          }
        />
      )}

      <ModalDelete
        isOpen={disclosureDelete.isOpen}
        onClose={disclosureDelete.onClose}
        onConfirm={handleReset}
      />
    </Flex>
  );
};

export default ProductDiscount;
