import React from "react";
import { Flex } from "renos-ui";
import LandingAbout from "./LandingAbout";
import LandingBanner from "./LandingBanner";
import LandingBenefit from "./LandingBenefit";
// import LandingFaq from "./LandingFaq";
// import LandingTestimoni from "./LandingTestimoni";
import LandingTutorial from "./LandingTutorial";

const LandingPage = () => {
  return (
    <Flex flexDirection="column" width="100%">
      <LandingBanner />
      <LandingAbout id="about" />
      <LandingBenefit id="benefit" />
      <LandingTutorial id="tutorial" />
      {/* <LandingTestimoni /> */}
      {/* <LandingFaq /> */}
    </Flex>
  );
};

export default LandingPage;
