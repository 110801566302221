import { Button, Flex, Text } from "renos-ui";
import { useHistory } from "react-router-dom";
import desktopUrl from "constants/desktopUrl";

const Empty = ({ isInside, isFilter }) => {
  const { push } = useHistory();

  return (
    <Flex
      backgroundColor="white"
      boxShadow={!isInside && "0px 4px 4px 0px #1414200D"}
      borderRadius="16px"
      width="100%"
      height="100%"
      flexDirection="column"
      gap="32px"
      alignItems="center"
      justifyContent="center"
      py={isInside && "40px"}
    >
      <Flex
        flexDirection="column"
        gap="8px"
        alignItems="center"
        textAlign="center"
      >
        <Flex
          as="img"
          width="156px"
          height="156px"
          src="/assets/images/promo-management/empty-stock.svg"
        />
        {isFilter ? (
          <>
            <Text variant="heading3" weight="bold">
              Tidak ada voucher
            </Text>
            <Text color="black75">Coba gunakan keyword lain yaa!</Text>
          </>
        ) : (
          <>
            <Text variant="heading3" weight="bold">
              Belum ada voucher
            </Text>
            <Text color="black75">
              Yuk buat voucher toko mu agar pembeli semakin tertarik
              <br /> untuk berbelanja di tokomu!
            </Text>
          </>
        )}
      </Flex>

      {!isInside && (
        <Button onClick={() => push(desktopUrl.VOUCHER_ADD)}>
          Buat Voucher
        </Button>
      )}
    </Flex>
  );
};

export default Empty;
