import React from "react";
import { Flex, Text, Skeleton } from "renos-ui";
import { PanelBody, PanelHeading } from "./Panel";

const SkeletonReply = () => {
  return (
    <PanelBody>
      <PanelHeading>
        <Flex alignItems="center" gap="20px" width="100%">
          <Skeleton width="20px" />
          <Flex alignItems="center" justifyContent="space-between" width="100%">
            <Flex flexDirection="column" gap="4px" maxWidth="600px">
              <Text weight="bold" variant="caption">
                <Skeleton width="300px" />
              </Text>
              <Text lineClamp="2" color="black75" variant="caption">
                <Skeleton width="800px" />
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </PanelHeading>
      <Flex
        padding="24px"
        gap="16px"
        justifyContent="space-between"
        alignItems="center"
      >
        <Flex flexGrow="1" maxWidth="650px">
          <Text lineClamp="2" variant="caption" color="black75">
            <Skeleton width="800px" />
          </Text>
        </Flex>
        <Text variant="caption" color="blue50">
          <Skeleton width="100px" />
        </Text>
      </Flex>
    </PanelBody>
  );
};

export default SkeletonReply;
