const { Flex, Skeleton } = require("renos-ui");

const ProductCardSkeleton = () => {
  return (
    <Flex gap="12px" alignItems="center" width="100%">
      <Skeleton height="64px" width="64px" />
      <Flex flexDirection="column" gap="2px" width="100%">
        <Skeleton height="20px" width="100%" />
        <Skeleton height="20px" width="100px" />
        <Flex gap="4px" alignItems="center">
          <Skeleton height="10px" width="10px" />
          <Skeleton height="10px" width="20px" />
          <Skeleton height="10px" width="50px" />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default ProductCardSkeleton;
