import { focusTo } from "helpers";
import React from "react";
import { Box, Button, Flex, Text, Avatar } from "renos-ui";
import { useCheckAuth, useCheckStore } from "services/auth";
import { NavLink } from "./styled";

const LandingHeaderTutorial = ({ visible }) => {
  const { data: authData } = useCheckAuth();
  const { data: storeData } = useCheckStore({
    onError: (error) => {
      console.error(error);
    },
  });

  const onPromoClicked = () =>
    window.open(`${process.env.REACT_APP_BUYER_SITE}/promo`, "_self");

  const onDashboardClicked = () =>
    window.open(`${process.env.REACT_APP_SELLER_SITE}/`, "_self");

  const onHelpClicked = () => window.open(`https://renos.id/bantuan`, "_self");

  const onLoginClicked = () =>
    window.open(
      `${process.env.REACT_APP_BUYER_SITE}/login?referrer=${window.location.origin}`,
      "_self"
    );

  const onJoinClicked = () => {
    if (authData.status) {
      return history.push("/register");
    }

    return window.open(
      `${process.env.REACT_APP_BUYER_SITE}/register?referrer=${window.location.origin}`,
      "_self"
    );
  };

  return (
    <Flex
      width="100%"
      backgroundColor="white"
      justifyContent="center"
      paddingY="20px"
      position="fixed"
      top={0}
      boxShadow={visible && "0 10px 20px rgb(0 0 0 / 10%)"}
      zIndex="999"
    >
      <Box width="1200px">
        <Flex width="100%" alignItems="center" justifyContent="space-between">
          <Flex alignItems="center" columnGap="32px">
            <Box as="img" src="/assets/images/seller-center.png" />
            <Flex alignItems="center" columnGap="24px">
              <NavLink
                as="a"
                onClick={!authData?.status ? onJoinClicked : onDashboardClicked}
              >
                <Text variant="subtitle" color="black100">
                  Jadi Penjual
                </Text>
              </NavLink>
              <NavLink as="a" onClick={() => focusTo("benefit")}>
                <Text variant="subtitle" color="blue50" weight="bold">
                  Tutorial Berjualan
                </Text>
              </NavLink>
              <NavLink as="a" onClick={onPromoClicked}>
                <Text variant="subtitle" color="black100">
                  Promo
                </Text>
              </NavLink>
              <NavLink as="a" onClick={onHelpClicked}>
                <Text variant="subtitle" color="black100">
                  Pusat Bantuan
                </Text>
              </NavLink>
            </Flex>
          </Flex>
          {!authData?.status ? (
            <Flex alignItems="center" columnGap="40px">
              <Flex alignItems="center" columnGap="16px">
                <Button size="medium" onClick={onLoginClicked}>
                  Masuk
                </Button>
                <Button
                  size="medium"
                  variant="secondary"
                  onClick={onJoinClicked}
                >
                  Daftar
                </Button>
              </Flex>
            </Flex>
          ) : (
            <Flex
              alignItems="center"
              justifyContent="start"
              flexDirection="row"
              width="280px"
              height="48px"
              gap="12px"
            >
              <Avatar marginLeft="16px" src={storeData?.data?.image_url} />
              <Box maxWidth="200px">
                <Text weight="bold" lineClamp={1}>
                  {storeData?.data?.name}
                </Text>
              </Box>
            </Flex>
          )}
        </Flex>
      </Box>
    </Flex>
  );
};

export default LandingHeaderTutorial;
