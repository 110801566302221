import MobileLayout from "layouts/MobileLayout";
import React, { useMemo } from "react";
import { useHistory } from "react-router-dom";
import { Box, Button, Flex, Input, isEmpty, useToast } from "renos-ui";
import constants from "store/productAdd/constants";
import { useProductAddContext } from "store/productAdd/reducer";
import Each from "widgets/global/Each";

const SniInput = () => {
  const { goBack } = useHistory();
  const { state, dispatch } = useProductAddContext();
  const toast = useToast();

  const { product_attributes: productAttributes } = state;

  const isButtonDisabled = useMemo(
    () =>
      productAttributes.filter(
        (item) =>
          item.data_type !== "file" &&
          item.is_required === 1 &&
          isEmpty(item.product_attribute_value)
      ).length > 0,
    [productAttributes]
  );

  const onAttributeChange = (value, index, dataType) => {
    const tempProductAttribute = [...productAttributes];
    let newValue = value;

    if (dataType === "numeric") {
      newValue = value.replace(/\D+/g, "");
    }

    tempProductAttribute[index].product_attribute_value = newValue;

    dispatch({
      type: constants.SAVE_VALUE,
      payload: {
        product_attributes: tempProductAttribute,
      },
    });
  };

  return (
    <MobileLayout
      header={{
        title: "Standarisasi Produk",
        onBack: goBack,
        withBack: true,
        withoutGoBack: true,
        backIconName: "Close-outline",
      }}
    >
      <Flex
        padding={16}
        flexDirection="column"
        rowGap="24px"
        width="100%"
        height="calc(100dvh - 92px)"
        justifyContent="space-between"
      >
        <Flex flexDirection="column" rowGap="16px">
          <Each
            of={productAttributes}
            render={(attribute, attributeIndex) => (
              <Input
                label={attribute.attribute_name}
                maxLength={
                  attribute?.limit && attribute?.limit > 0
                    ? attribute.limit
                    : null
                }
                value={attribute.product_attribute_value}
                onChange={(event) =>
                  onAttributeChange(
                    event.target.value,
                    attributeIndex,
                    attribute.data_type
                  )
                }
                required={attribute.is_required === 1}
              />
            )}
          />
        </Flex>
        <Box position="relative">
          <Button isBlock disabled={isButtonDisabled} onClick={goBack}>
            Simpan
          </Button>
          {isButtonDisabled && (
            <Box
              position="absolute"
              left={0}
              top={0}
              width="100%"
              height="100%"
              onClick={() =>
                toast({
                  label: "Lengkapi data sebelum melanjutkan",
                  placement: "bottom",
                })
              }
            />
          )}
        </Box>
      </Flex>
    </MobileLayout>
  );
};

export default SniInput;
