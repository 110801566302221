import styled from "styled-components";
import { Dropdown, colors } from "renos-ui";

export const SDDownContent = styled(Dropdown.Content)`
  background-color: unset;
  box-shadow: none;
  z-index: 2;
`;

export const Label = styled.label`
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  position: relative;
`;

export const SwitchWrapper = styled.div`
  position: relative;
  width: 166px;
  height: 40px;
  background: ${colors.black5};
  border-radius: 32px;
  padding: 4px;
  transition: 300ms all;

  &:before {
    transition: 300ms all;
    content: "";
    position: absolute;
    width: 80px;
    height: 40px;
    border-radius: 35px;
    top: 50%;
    left: 4px;
    background: ${colors.blue50};
    transform: translate(0, -50%);
  }
`;

export const Input = styled.input`
  opacity: 0;
  position: absolute;

  &:checked + ${SwitchWrapper} {
    background: ${colors.black5};

    &:before {
      transform: translate(85px, -50%);
    }
  }
`;
