import React from "react";
import { Flex, Text } from "renos-ui";

const EmptyState = () => {
  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      gap="8px"
      padding="16px"
      height="100%"
    >
      <Flex
        as="img"
        alt="empty-review"
        src={`/assets/images/mobile/stok-kosong.png`}
      />

      <Flex
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        gap="8px"
        marginBottom="16px"
      >
        <Text weight="bold" variant="regular" color="black50">
          Data tidak ditemukan
        </Text>
      </Flex>
    </Flex>
  );
};

export default EmptyState;
