import React from "react";
import { useHistory } from "react-router-dom";
import { Box, Button, Flex, Text } from "renos-ui";

const OfflinePage = () => {
  const { go } = useHistory();

  return (
    <Flex
      flexDirection="column"
      height="100dvh"
      alignItems="center"
      justifyContent="center"
      rowGap="24px"
    >
      <svg
        width="257"
        height="257"
        viewBox="0 0 257 257"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M167.817 116.453L136.62 106.021C136.62 106.021 136.086 108.454 135.367 111.715C132.925 122.83 128.693 133.475 122.838 143.237C122.079 144.498 121.4 145.631 120.862 146.526C120.633 146.908 120.641 147.389 120.882 147.763C121.127 148.136 121.561 148.337 122.003 148.281H122.011C140.736 145.94 157.264 134.921 166.628 118.537C167.371 117.232 167.817 116.453 167.817 116.453Z"
          fill="#E9E9EF"
        />
        <path
          d="M175.848 136.531C211.333 136.531 240.098 107.766 240.098 72.2812C240.098 36.797 211.333 8.03125 175.848 8.03125C140.364 8.03125 111.598 36.797 111.598 72.2812C111.598 107.766 140.364 136.531 175.848 136.531Z"
          fill="#E9E9EF"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M119.895 103.864C114.614 94.5318 111.598 83.7579 111.598 72.2812C111.598 36.8193 140.39 8.03125 175.848 8.03125C188.04 8.03125 199.444 11.4325 209.162 17.3435C209.355 19.6083 209.451 21.9012 209.451 24.2142C209.451 68.4744 173.519 104.406 129.259 104.406C126.091 104.406 122.967 104.222 119.895 103.864Z"
          fill="#E3E3E8"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M20.034 243.861L117.216 219.767C118.826 219.365 119.814 217.731 119.413 216.121C119.015 214.507 117.377 213.519 115.767 213.92L18.5844 238.014C16.9741 238.416 15.9863 240.05 16.3878 241.66C16.7854 243.275 18.4198 244.262 20.034 243.861Z"
          fill="#E1E1E3"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M117.333 213.953L34.6107 189.859C33.0125 189.393 31.342 190.313 30.8762 191.907C30.4104 193.505 31.33 195.176 32.9242 195.641L115.646 219.735C117.244 220.201 118.919 219.281 119.381 217.687C119.846 216.089 118.927 214.418 117.333 213.953Z"
          fill="#BDBDC4"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M34.6026 195.645L104.077 175.567C105.675 175.105 106.595 173.431 106.133 171.837C105.675 170.239 104.001 169.319 102.406 169.777L32.9321 189.855C31.3339 190.317 30.4143 191.991 30.8761 193.585C31.3379 195.184 33.0084 196.103 34.6026 195.645Z"
          fill="#E1E1E3"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M104.234 169.829L46.8101 149.751C45.24 149.2 43.5213 150.032 42.9712 151.598C42.4251 153.168 43.2523 154.887 44.8224 155.437L102.246 175.515C103.816 176.065 105.535 175.234 106.085 173.668C106.635 172.098 105.804 170.379 104.234 169.829Z"
          fill="#BDBDC4"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M46.7981 155.441L93.3834 139.378C94.9535 138.836 95.7928 137.122 95.2507 135.552C94.7085 133.977 92.9899 133.142 91.4198 133.684L44.8345 149.747C43.2644 150.289 42.4251 152.004 42.9672 153.574C43.5093 155.148 45.228 155.983 46.7981 155.441Z"
          fill="#E1E1E3"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M93.6042 133.768L56.6565 117.706C55.1305 117.043 53.3556 117.742 52.693 119.268C52.0305 120.794 52.7292 122.569 54.2551 123.231L91.2029 139.294C92.7248 139.957 94.4997 139.258 95.1623 137.732C95.8249 136.206 95.1262 134.431 93.6042 133.768Z"
          fill="#BDBDC4"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M56.9817 123.063L84.292 107C85.7256 106.161 86.2034 104.314 85.3642 102.88C84.5209 101.447 82.6737 100.969 81.2401 101.812L53.9258 117.875C52.4963 118.714 52.0144 120.561 52.8577 121.995C53.701 123.428 55.5482 123.906 56.9817 123.063Z"
          fill="#E1E1E3"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M84.3884 101.868L65.507 89.8215C64.1055 88.9261 62.2423 89.3397 61.3508 90.7411C60.4553 92.1386 60.8649 94.0058 62.2664 94.8973L81.1478 106.944C82.5493 107.84 84.4125 107.426 85.304 106.025C86.1995 104.627 85.7859 102.76 84.3884 101.868Z"
          fill="#BDBDC4"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M66.053 94.4514L77.7063 82.4046C78.8588 81.2119 78.8267 79.3045 77.6341 78.148C76.4374 76.9915 74.53 77.0236 73.3735 78.2203L61.7242 90.2672C60.5677 91.4598 60.5998 93.3672 61.7924 94.5237C62.9891 95.6802 64.8965 95.6481 66.053 94.4514Z"
          fill="#E1E1E3"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M128.869 247.64L78.4252 79.4491C78.0437 78.1722 76.8711 77.3008 75.542 77.3008H67.5027C66.1735 77.3008 65.001 78.1722 64.6195 79.4451L14.0146 248.101C13.5367 249.696 14.4442 251.374 16.0344 251.852C17.6286 252.33 19.3072 251.426 19.785 249.836L69.7434 83.3242H73.2972L123.103 249.37C123.577 250.96 125.259 251.864 126.85 251.39C128.444 250.912 129.347 249.23 128.869 247.64Z"
          fill="#BDBDC4"
        />
        <path
          d="M71.442 84.292C79.2042 84.292 85.4967 77.9995 85.4967 70.2373C85.4967 62.4751 79.2042 56.1826 71.442 56.1826C63.6798 56.1826 57.3873 62.4751 57.3873 70.2373C57.3873 77.9995 63.6798 84.292 71.442 84.292Z"
          fill="url(#paint0_linear_9884_225128)"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M141.776 64.0612C155.047 50.7936 174.158 46.8864 190.867 52.3436C192.971 53.0303 195.24 51.8778 195.93 49.7736C196.617 47.6654 195.465 45.3966 193.356 44.7099C173.869 38.3451 151.578 42.9028 136.098 58.3831C134.531 59.9492 134.531 62.4951 136.098 64.0612C137.664 65.6313 140.21 65.6313 141.776 64.0612Z"
          fill="url(#paint1_linear_9884_225128)"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M201.347 57.2386C204.395 59.138 207.274 61.4149 209.925 64.0612C211.491 65.6313 214.037 65.6313 215.603 64.0612C217.169 62.4951 217.169 59.9492 215.603 58.3831C212.515 55.2951 209.154 52.6447 205.6 50.4241C203.721 49.2515 201.243 49.8258 200.07 51.7051C198.894 53.5844 199.468 56.062 201.347 57.2386Z"
          fill="url(#paint2_linear_9884_225128)"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M155.2 77.4855C162.219 70.4622 171.941 67.7717 181.036 69.398C183.217 69.7916 185.305 68.3379 185.695 66.1534C186.088 63.9729 184.634 61.8848 182.45 61.4953C170.861 59.4152 158.469 62.8566 149.518 71.8074C147.952 73.3735 147.952 75.9194 149.518 77.4855C151.088 79.0516 153.63 79.0516 155.2 77.4855Z"
          fill="url(#paint3_linear_9884_225128)"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M191.08 73.2087C193.007 74.3893 194.83 75.8149 196.501 77.4854C198.067 79.0514 200.613 79.0514 202.179 77.4854C203.745 75.9193 203.745 73.3734 202.179 71.8073C200.05 69.679 197.729 67.8639 195.268 66.3581C193.38 65.2016 190.907 65.7999 189.75 67.6872C188.594 69.5786 189.188 72.0522 191.08 73.2087Z"
          fill="url(#paint4_linear_9884_225128)"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M168.62 90.9098C169.431 90.0986 170.343 89.4561 171.315 88.9742C173.298 87.9904 174.113 85.581 173.13 83.5933C172.15 81.6056 169.736 80.7944 167.753 81.7742C166.014 82.6336 164.388 83.7861 162.942 85.2317C161.376 86.7978 161.376 89.3397 162.942 90.9098C164.508 92.4759 167.054 92.4759 168.62 90.9098Z"
          fill="url(#paint5_linear_9884_225128)"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M181 89.3034C181.743 89.7371 182.442 90.2712 183.076 90.9097C184.642 92.4758 187.188 92.4758 188.754 90.9097C190.325 89.3396 190.325 86.7977 188.754 85.2316C187.622 84.0952 186.373 83.1395 185.048 82.3685C183.137 81.2521 180.675 81.8986 179.555 83.8101C178.438 85.7255 179.085 88.1871 181 89.3034Z"
          fill="url(#paint6_linear_9884_225128)"
        />
        <path
          d="M175.848 107.832C179.175 107.832 181.872 105.135 181.872 101.808C181.872 98.4815 179.175 95.7847 175.848 95.7847C172.522 95.7847 169.825 98.4815 169.825 101.808C169.825 105.135 172.522 107.832 175.848 107.832Z"
          fill="url(#paint7_linear_9884_225128)"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M195.914 34.6347L152.349 105.731C151.188 107.623 151.783 110.096 153.674 111.257C155.561 112.413 158.039 111.819 159.195 109.928L202.765 38.831C203.921 36.9397 203.327 34.466 201.436 33.3055C199.548 32.149 197.075 32.7433 195.914 34.6347Z"
          fill="url(#paint8_linear_9884_225128)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_9884_225128"
            x1="80.5001"
            y1="56"
            x2="59.0001"
            y2="84"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.32055" stop-color="#C5C6FF" />
            <stop offset="0.740249" stop-color="#A7A9FF" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_9884_225128"
            x1="165.526"
            y1="41.9282"
            x2="165.526"
            y2="65.2388"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.310826" stop-color="#C6C7FF" />
            <stop offset="1" stop-color="#A8AAFF" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_9884_225128"
            x1="208.118"
            y1="49.8159"
            x2="208.118"
            y2="65.2388"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.310826" stop-color="#C6C7FF" />
            <stop offset="1" stop-color="#A8AAFF" />
          </linearGradient>
          <linearGradient
            id="paint3_linear_9884_225128"
            x1="167.051"
            y1="60.9075"
            x2="167.051"
            y2="78.6601"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.310826" stop-color="#C6C7FF" />
            <stop offset="1" stop-color="#A8AAFF" />
          </linearGradient>
          <linearGradient
            id="paint4_linear_9884_225128"
            x1="196.257"
            y1="65.7686"
            x2="196.257"
            y2="78.6599"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.310826" stop-color="#C6C7FF" />
            <stop offset="1" stop-color="#A8AAFF" />
          </linearGradient>
          <linearGradient
            id="paint5_linear_9884_225128"
            x1="167.657"
            y1="81.3586"
            x2="167.657"
            y2="92.0843"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.310826" stop-color="#C6C7FF" />
            <stop offset="1" stop-color="#A8AAFF" />
          </linearGradient>
          <linearGradient
            id="paint6_linear_9884_225128"
            x1="184.47"
            y1="81.8213"
            x2="184.47"
            y2="92.0843"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.310826" stop-color="#C6C7FF" />
            <stop offset="1" stop-color="#A8AAFF" />
          </linearGradient>
          <linearGradient
            id="paint7_linear_9884_225128"
            x1="175.848"
            y1="95.7847"
            x2="175.848"
            y2="107.832"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.310826" stop-color="#C6C7FF" />
            <stop offset="1" stop-color="#A8AAFF" />
          </linearGradient>
          <linearGradient
            id="paint8_linear_9884_225128"
            x1="177.555"
            y1="32.7151"
            x2="177.555"
            y2="111.847"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.310826" stop-color="#C6C7FF" />
            <stop offset="1" stop-color="#A8AAFF" />
          </linearGradient>
        </defs>
      </svg>

      <Flex
        flexDirection="column"
        alignItems="center"
        textAlign="center"
        mt="8px"
        rowGap="8px"
      >
        <Text variant="heading3" weight="bold">
          Kamu lagi offline, nih
        </Text>
        <Text color="black50" width="416px">
          Server kita nggak bisa nerima data kamu. Coba periksa koneksi kamu
          terus muat ulang screennya yaa!
        </Text>
      </Flex>
      <Box width="416px">
        <Button isBlock onClick={() => go(0)}>
          Muat Ulang
        </Button>
      </Box>
    </Flex>
  );
};

export default OfflinePage;
