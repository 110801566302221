import { goToUrl } from "helpers";
import { maskAllName } from "helpers/string-mask";
import { useMemo } from "react";
import { Avatar, Box, Flex, Icon, Text, useDisclosure } from "renos-ui";

const DeliveryInfo = ({ data }) => {
  const { isOpen, onToggle } = useDisclosure({ isOpen: false });

  const handleMasking = (value, isPreffix) => {
    const notAllowedMask = [2, 3, 4, 5];

    return notAllowedMask.includes(data.order_status_id)
      ? value
      : maskAllName(value, isPreffix);
  };

  const is3Pls = useMemo(
    () =>
      data?.store_order_shipment?.pod &&
      data?.store_order_shipment.pod?.courier_employee_name &&
      data?.store_order_shipment.pod?.courier_employee_phone_number,
    [data],
  );

  const isHasRecipient = useMemo(() => {
    const allowedPage = [5, 6];

    return !is3Pls && allowedPage.includes(data.order_status_id);
  }, [data, is3Pls]);

  return (
    <Flex width="50%" gap="16px" flexDirection="column">
      <Flex flexDirection="column" gap="4px">
        <Text weight="bold">Alamat</Text>
        <Text color="black75">
          {handleMasking(data.shipping.destination_pic_name)} (
          {handleMasking(data.shipping.destination_pic_phone, true)})
        </Text>
        <Text color="black75">
          {handleMasking(data.shipping.destination_address)}
        </Text>
        <Text color="black75">
          {handleMasking(data.shipping.destination_district_name)},{" "}
          {handleMasking(data.shipping.destination_city_name)},{" "}
          {handleMasking(data.shipping.destination_province_name)}{" "}
          {handleMasking(data.shipping.destination_postal_code)}
        </Text>
      </Flex>

      <Flex gap="24px">
        <Flex
          flexDirection="column"
          gap="4px"
          width={is3Pls || isHasRecipient ? "204px" : "100%"}
        >
          <Text weight="bold">Kurir</Text>
          <Text color="black75">
            {`${data.shipping.logistics_courier_name} - ${data.shipping.logistics_courier_service_name}`}
          </Text>
        </Flex>

        {(is3Pls || isHasRecipient) && (
          <Flex
            borderRightColor="black10"
            borderRightWidth="1px"
            borderRightStyle="solid"
          />
        )}

        {is3Pls && (
          <Flex gap="16px">
            <Avatar
              size={40}
              src={
                data.store_order_shipment.pod.courier_employee_photo_url
                  ? data.store_order_shipment.pod.courier_employee_photo_url
                  : data.store_order_shipment.pod
                        .courier_employee_photo_url_external
                    ? data.store_order_shipment.pod
                        .courier_employee_photo_url_external
                    : "/assets/images/default_store.svg"
              }
            />
            <Flex flexDirection="column" gap="4px">
              <Text weight="bold">
                {data.store_order_shipment.pod.courier_employee_name}
              </Text>
              <Text color="black75">
                {data.store_order_shipment.pod.courier_employee_phone_number}
              </Text>
            </Flex>
          </Flex>
        )}
        {isHasRecipient && (
          <Flex flexDirection="column" gap="4px" width="204px">
            <Text weight="bold">Penerima</Text>
            <Text color="black75">
              {data?.store_order_shipment?.pod?.receiver_contact_person ||
                "Nama penerima tidak tersedia"}
            </Text>
          </Flex>
        )}
      </Flex>

      {data.shipping.order_shipping_receipt_number && (
        <Flex flexDirection="column" gap="4px">
          <Text weight="bold">
            {" "}
            {data?.shipping?.is_seller_fleet
              ? "Kode Booking"
              : "Nomor Resi"}{" "}
          </Text>
          <Text color="black75">
            {data.shipping.order_shipping_receipt_number}
          </Text>
        </Flex>
      )}

      {data.store_order_shipment.pod && data.order_status_id > 3 && (
        <>
          <Flex gap="24px">
            <Flex flexDirection="column" gap="4px" width="204px">
              <Text weight="bold">Dokumen Pengiriman</Text>
              {data?.store_order_shipment?.pod?.shipment_deliver_picture_url ? (
                <Flex flexDirection="column">
                  <Flex
                    onClick={() =>
                      goToUrl(
                        data?.store_order_shipment?.pod
                          ?.shipment_deliver_picture_url,
                      )
                    }
                    cursor="pointer"
                  >
                    <Text color="blue50">Unduh dokumen Pengiriman</Text>
                  </Flex>
                </Flex>
              ) : (
                <Text color="black50">Tidak ada dokumen</Text>
              )}
            </Flex>

            {isHasRecipient && (
              <>
                <Flex
                  borderRightColor="black10"
                  borderRightWidth="1px"
                  borderRightStyle="solid"
                />
                <Flex flexDirection="column" gap="4px" width="210px">
                  <Text weight="bold">Bukti Terima</Text>
                  {data.store_order_shipment.pod
                    .shipment_receive_picture_url ? (
                    <Flex
                      onClick={() =>
                        goToUrl(
                          data.store_order_shipment.pod
                            .shipment_receive_picture_url,
                        )
                      }
                      cursor="pointer"
                    >
                      <Text color="blue50">Bukti Terima</Text>
                    </Flex>
                  ) : (
                    <Text color="black75">Bukti Pengiriman Tidak Tersedia</Text>
                  )}
                </Flex>
              </>
            )}
          </Flex>

          <Flex flexDirection="column" gap="4px">
            <Text weight="bold">Catatan Pengiriman</Text>
            <Text
              color={
                data?.store_order_shipment?.pod?.delivery_notes
                  ? "black75"
                  : "black50"
              }
            >
              {data?.store_order_shipment?.pod?.delivery_notes ||
                "Tidak ada catatan"}{" "}
            </Text>
          </Flex>
        </>
      )}

      {data.order_status_id === 7 && (
        <Flex flexDirection="column" gap="4px" width="100%">
          <Text weight="bold">Alasan Pembatalan</Text>
          <Flex width="100%" gap="8px">
            <Flex flexDirection="column">
              <Text color="black75" lineClamp={1}>
                {data.cancellation_reason_name || "-"}
              </Text>
              {data.order_cancellation_reason && (
                <Text
                  color="black50"
                  style={{ wordBreak: "break-word" }}
                  lineClamp={!isOpen && 2}
                >
                  {data.order_cancellation_reason}
                </Text>
              )}
            </Flex>
            {(data.cancellation_reason_id === 1 ||
              data.order_cancellation_reason?.length > 82) && (
              <Flex onClick={onToggle} cursor="pointer">
                <Icon
                  name={`${isOpen ? "Up" : "Down"}-outline`}
                  size={20}
                  color="black75"
                />
              </Flex>
            )}
          </Flex>
          {isOpen &&
            data.cancellation_reason_id === 1 &&
            data.products?.map((product, index) => (
              <Flex gap="16px" key={`product-cancel-${index}`}>
                <Box
                  as="img"
                  width="64px"
                  height="64px"
                  borderRadius="8px"
                  src={product.product_image_url}
                />
                <Flex flexDirection="column">
                  <Text color="black75">
                    {product.product_name}
                    {product.variant_name && ` - ${product.variant_name}`}
                  </Text>
                  <Text variant="subtitle" weight="bold">
                    {product.order_product_quantity} x{" "}
                    {product.formatted_order_product_unit_price}
                  </Text>
                </Flex>
              </Flex>
            ))}
        </Flex>
      )}
    </Flex>
  );
};

export default DeliveryInfo;
