import { currencyFormatter } from "helpers";
import { Button, Divider, Flex, Icon, Text, Tooltip } from "renos-ui";

const Table = ({ value, onChange, disabled, invalid }) => {
  const handleDelete = (skuNumber) => {
    const newData = value.filter(
      (product) => product.product_sku_number !== skuNumber
    );
    onChange(newData);
  };

  const invalidChecker = (skuId) => {
    return invalid.includes(skuId);
  };

  return (
    <Flex flexDirection="column">
      <Flex gap="24px" flexDirection="column">
        <Divider />
        <Flex gap="40px">
          <Flex width="346px">
            <Text color="black50" weight="bold">
              Informasi Produk
            </Text>
          </Flex>
          <Flex width="232px">
            <Text color="black50" weight="bold">
              Harga
            </Text>
          </Flex>
          <Flex width="142px">
            <Text color="black50" weight="bold">
              Stock
            </Text>
          </Flex>
          <Flex width="40px" />
        </Flex>
        <Divider />
      </Flex>

      <Flex maxHeight="214px" overflow="auto" flexDirection="column">
        {value.map((product, indexProduct) => (
          <Flex flexDirection="column" key={`product-${indexProduct}`}>
            <Flex gap="40px" py="24px">
              <Flex
                width="346px"
                gap="16px"
                opacity={invalidChecker(product?.product_sku_id) && "50%"}
              >
                <Flex
                  as="img"
                  height="56px"
                  width="56px"
                  alt="product-img"
                  borderRadius="8px"
                  src={product.product_image_url}
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = "/assets/images/empty-product.svg";
                  }}
                />
                <Flex flexDirection="column">
                  <Tooltip label={product.product_name}>
                    <Text color="black75" weight="bold" lineClamp={1}>
                      {product.product_name}
                    </Text>
                  </Tooltip>
                  <Text color="black50" variant="caption">
                    SKU: {product.store_sku_number}
                  </Text>
                </Flex>
              </Flex>
              <Flex
                width="232px"
                opacity={invalidChecker(product?.product_sku_id) && "50%"}
              >
                <Text color="black75" variant="caption">
                  {product.formatted_product_price}
                </Text>
              </Flex>
              <Flex
                width="142px"
                opacity={invalidChecker(product?.product_sku_id) && "50%"}
              >
                <Text color="black75" variant="caption">
                  {currencyFormatter(product.product_stock)}
                </Text>
              </Flex>
              <Flex width="40px">
                {!disabled && (
                  <Flex>
                    <Button
                      variant="tertiary"
                      size="medium"
                      onClick={() => handleDelete(product.product_sku_number)}
                      icon={
                        <Icon name="Trash-outline" size={14} color="black50" />
                      }
                    />
                  </Flex>
                )}
              </Flex>
            </Flex>
            <Divider />
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
};

export default Table;
