import React from "react";
import {
  Button,
  Flex,
  Checkbox,
  Text,
  DatePicker,
  Input,
  Divider,
  Icon,
  Spinner,
} from "renos-ui";
import dayjs from "dayjs";

const ClosedStore = (props) => {
  const {
    datas,
    setDatas,
    checkedClose,
    seCheckedClose,
    openDialog,
    isLoading,
  } = props;

  return (
    <>
      <Flex
        flexDirection="column"
        gap="24px"
        paddingX="38px"
        marginBottom="24px"
        width="634px"
      >
        <Flex gap="10px" alignItems="center">
          <Checkbox
            checked={checkedClose}
            disabled={isLoading}
            onChange={() => {
              seCheckedClose(!checkedClose);
              setDatas({
                ...datas,
                date_start: new Date(),
              });
            }}
          />
          <Text>Tutup Sekarang</Text>
        </Flex>
        <Flex gap="24px" width="634px">
          <Flex flexDirection="column" gap="8px">
            <Text color="black50" variant="caption" weight="bold">
              Mulai Tutup
            </Text>
            <DatePicker
              minDate={new Date()}
              disabled={checkedClose || isLoading}
              customInput={
                <Input sufix={<Icon name="Calendar-outline" size={20} />} />
              }
              value={dayjs(datas.date_start).format("D MMM YYYY")}
              onChange={(startDate) => {
                setDatas({
                  ...datas,
                  date_start: startDate,
                  date_end: startDate,
                });
              }}
              placeholderText="Mulai Tanggal"
            />
          </Flex>
          <Flex flexDirection="column" gap="8px">
            <Text color="black50" variant="caption" weight="bold">
              Tutup Hingga
            </Text>
            <DatePicker
              minDate={datas.date_start}
              value={dayjs(datas.date_end).format("D MMM YYYY")}
              customInput={
                <Input sufix={<Icon name="Calendar-outline" size={20} />} />
              }
              disabled={isLoading}
              onChange={(endDate) => {
                setDatas({
                  ...datas,
                  date_end: endDate,
                });
              }}
              placeholderText="Mulai Tanggal"
            />
          </Flex>
        </Flex>
        <Flex flexDirection="column" gap="8px">
          <Text color="black50" variant="caption" weight="bold">
            Catatan
          </Text>
          <Input.TextArea
            value={datas.notes}
            disabled={isLoading}
            onChange={(event) =>
              setDatas({
                ...datas,
                notes: event.currentTarget.value,
              })
            }
            placeholder="Masukkan alasan toko tutup."
          />
        </Flex>
      </Flex>
      <Divider />
      <Flex flexDirection="column" alignItems="center" padding="1rem">
        <Flex width="100%" justifyContent="flex-end">
          <Button onClick={openDialog} disabled={isLoading}>
            {isLoading && <Spinner size={20} color="white" />}
            <Text weight="bold">Tutup Toko</Text>
          </Button>
        </Flex>
      </Flex>
    </>
  );
};

export default ClosedStore;
