import { Box, Button, Flex, Icon, Skeleton, Text, Tooltip } from "renos-ui";

const VoucherType = ({
  value,
  onClick,
  list,
  isLoading,
  refetchData,
  isGetDataError,
  disabled,
}) => {
  const handleOnClick = (newValue) => {
    if (!disabled) onClick(newValue);
  };

  return (
    <Flex flexDirection="column" gap="8px">
      <Text variant="caption" color="black50" weight="bold">
        Tipe Voucher
      </Text>
      <Flex gap="24px">
        {isLoading ? (
          <Skeleton height="42px" width="176px" />
        ) : isGetDataError ? (
          <Button onClick={refetchData} size="medium">
            Muat Ulang
          </Button>
        ) : (
          list.map((listType, indexType) => (
            <Flex
              key={`type-${indexType}`}
              padding="8px 12px"
              gap="8px"
              onClick={() => handleOnClick(listType.id)}
              alignItems="center"
              borderWidth="1px"
              borderColor={value === listType.id ? "blue50" : "black10"}
              backgroundColor={value === listType.id ? "blue5" : "white"}
              color={value === listType.id ? "blue50" : "black75"}
              borderStyle="solid"
              borderRadius="12px"
              cursor="pointer"
            >
              <Icon
                name={listType.id === 1 ? "Voucher-outline" : "Card-outline"}
                size={14}
                color={value === listType.id ? "blue50" : "black75"}
              />
              <Text>{listType.name}</Text>
              <Tooltip
                label={
                  <Box>
                    <Text variant="small" color="black75">
                      {listType.description}
                    </Text>
                  </Box>
                }
              >
                <Flex>
                  <Icon
                    name="Info-outline"
                    size={14}
                    color={value === listType.id ? "blue50" : "black75"}
                  />
                </Flex>
              </Tooltip>
            </Flex>
          ))
        )}
      </Flex>
    </Flex>
  );
};

export default VoucherType;
