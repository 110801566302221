import React, { useState } from "react";
import { Box, Flex, Icon, Text, isEmpty } from "renos-ui";
import { useHistory } from "react-router-dom";
import FormLayout from "./FormLayout";
import mobileUrl from "constants/mobileUrl";
import { useProductAddContext } from "store/productAdd/reducer";
import constants from "store/productAdd/constants";
import { useEffect } from "react";
import { useGetYoutubeData } from "services/global/youtube";

const ProductAssets = () => {
  const { push } = useHistory();

  const {
    state: {
      productImages,
      productVideos,
      isEdit,
      tempProductVideos,
      initialVideoFilled,
      calledOnce,
    },
    dispatch,
  } = useProductAddContext();

  const [youtubeUrl] = useState();
  const [videoList, setVideoList] = useState(productVideos || []);

  useEffect(() => {
    if (isEmpty(tempProductVideos) && isEdit) return;
    if (initialVideoFilled) return;

    if (
      !isEmpty(productVideos) &&
      productVideos.filter((item) => item.thumbnail_url).length ===
        tempProductVideos?.length
    ) {
      dispatch({
        type: constants.SAVE_VALUE,
        payload: {
          initialVideoFilled: true,
        },
      });
    }

    if (
      Array.isArray(tempProductVideos) &&
      tempProductVideos.length > 0 &&
      !calledOnce
    ) {
      tempProductVideos.forEach((vidData) => {
        getYoutubeData(vidData.product_video_url);
      });

      dispatch({
        type: constants.SAVE_VALUE,
        payload: {
          calledOnce: true,
        },
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isEdit,
    tempProductVideos,
    productVideos,
    initialVideoFilled,
    calledOnce,
  ]);

  const { mutate: getYoutubeData } = useGetYoutubeData({
    onSuccess: (data) => {
      const youtubeId = data.thumbnail_url
        .split("https://i.ytimg.com/vi/")[1]
        .split("/")[0];
      const embedUrl = `https://www.youtube.com/embed/${youtubeId}`;

      setVideoList((prev) => [
        ...prev,
        { ...data, url: youtubeUrl || data.url, youtubeEmbedUrl: embedUrl },
      ]);
    },
  });

  useEffect(() => {
    dispatch({
      type: constants.SAVE_VALUE,
      payload: { productVideos: videoList },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoList]);

  return (
    <FormLayout
      title="Upload Foto & Video"
      isFilled={productImages.length > 0 || productVideos.length > 0}
      action={() => push(mobileUrl.PRODUCT_ASSETS)}
      required
    >
      <Flex flexDirection="column" rowGap="16px">
        <Flex columnGap="16px">
          {productImages.map((item, index) => (
            <Flex
              key={`${item.imageUrl} - ${index}`}
              bg="black5"
              borderRadius="8px"
              width="56px"
              height="56px"
              cursor="pointer"
              position="relative"
              borderStyle="solid"
              borderWidth="1px"
              borderColor="black25"
              background={`url("${item.imageUrl}")`}
              backgroundSize="contain"
              backgroundRepeat="no-repeat"
              backgroundPosition="center"
            >
              {index === 0 && (
                <Flex
                  width="100%"
                  height="18px"
                  bg="blue5"
                  justifyContent="center"
                  alignItems="center"
                  position="absolute"
                  bottom="0"
                  left="0"
                >
                  <Text variant="small" weight="bold" color="blue50">
                    Utama
                  </Text>
                </Flex>
              )}
              <Box
                position="absolute"
                top="-10px"
                right="-10px"
                cursor="pointer"
                onClick={() =>
                  dispatch({ type: constants.DELETE_IMAGE, payload: index })
                }
              >
                <Icon color="black50" name="Close-solid" />
              </Box>
            </Flex>
          ))}
          {productImages.length < 5 && (
            <Flex
              bg="black5"
              borderRadius="8px"
              justifyContent="center"
              alignItems="center"
              width="56px"
              height="56px"
              borderStyle="dashed"
              borderWidth="1px"
              borderColor="black10"
              cursor="pointer"
              onClick={() => push(mobileUrl.PRODUCT_ASSETS)}
            >
              <Icon color="blue50" name="Plus-solid" />
            </Flex>
          )}
        </Flex>
        {productVideos.map((video, index) => (
          <Flex
            borderColor="black10"
            borderStyle="solid"
            borderWidth="1px"
            borderRadius="8px"
            padding="8px"
            alignItems="center"
            justifyContent="space-between"
            key={`${video.thumbnail_url}-${index}`}
          >
            <Flex columnGap="14px" alignItems="center">
              <Flex
                as="img"
                borderRadius="8px"
                width="56px"
                height="56px"
                position="relative"
                src={video.thumbnail_url}
              />
              <Flex flexDirection="column" width="calc(100dvw - 40dvw)">
                <Text weight="bold" variant="small">
                  {video.title}
                </Text>
                <Text color="black50" variant="small" lineClamp={2}>
                  {video.url}
                </Text>
              </Flex>
            </Flex>
            <Icon
              name="Close-solid"
              color="black60"
              cursor="pointer"
              onClick={() =>
                dispatch({ type: constants.DELETE_VIDEO, payload: index })
              }
            />
          </Flex>
        ))}
      </Flex>
    </FormLayout>
  );
};

export default ProductAssets;
