import { useMemo } from "react";
import { Flex, Grid, Icon, Text } from "renos-ui";
import EmptyList from "./Empty";
import CardProduct from "../../../CardProduct";
import CardProductSkeleton from "../CardProductSkeleton";
import { useGetProductsV3 } from "services/product";
import Voucher from "../Home/component/Voucher";

const Product = ({ isPreviewMode }) => {
  const { data, isLoading } = useGetProductsV3({ page: 1 });

  const dataProduct = useMemo(() => data?.data || [], [data]);

  return (
    <Flex
      flexDirection="column"
      gap="32px"
      px="16px"
      height="500px"
      overflow="auto"
    >
      <Voucher isPreviewMode={isPreviewMode} />

      <Flex flexDirection="column" gap="24px">
        <Flex flexDirection="column" gap="16px">
          <Text weight="bold">Etalase</Text>
          <Flex gap="16px" alignItems="center">
            <Flex
              width="288px"
              borderWidth="1px"
              borderColor="black10"
              borderStyle="solid"
              borderRadius="8px"
              p="4px 12px"
              justifyContent="space-between"
              alignItems="center"
            >
              <Text variant="caption" color="black75">
                Pilih Etalase
              </Text>
              <Icon name="Down-outline" size={24} color="black50" />
            </Flex>
            <Icon name="Filter-outline" size={24} color="black75" />
          </Flex>
        </Flex>
      </Flex>
      {isLoading ? (
        <Flex gap="8px" flexWrap="wrap">
          <CardProductSkeleton />
          <CardProductSkeleton />
        </Flex>
      ) : dataProduct.length === 0 ? (
        <EmptyList />
      ) : (
        <Grid gap="8px" gridTemplateColumns="160px 160px" overflow="auto">
          {dataProduct?.map((product, indexProduct) => (
            <CardProduct data={product} key={`product-card${indexProduct}`} />
          ))}
        </Grid>
      )}
    </Flex>
  );
};

export default Product;
