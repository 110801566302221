import { Divider, Flex, Skeleton, range } from "renos-ui";

const Loading = () => {
  const randomWidthInterval = (min, max) => {
    return `${Math.floor(Math.random() * (max - min + 1) + min)}px`;
  };

  return (
    <Flex height="100%" width="100%" flexDirection="column">
      <Flex flexDirection="column">
        <Flex padding="16px" gap="16px" flexDirection="column">
          <Skeleton height="24px" width="100px" />
          <Divider />
          <Flex gap="8px" flexDirection="column">
            {range(4).map((_, indexSkeleton) => (
              <Flex
                key={`skeleton-${indexSkeleton}`}
                justifyContent="space-between"
                alignItems="center"
              >
                <Skeleton height="20px" width="80px" />
                <Skeleton height="20px" width={randomWidthInterval(80, 120)} />
              </Flex>
            ))}
          </Flex>
        </Flex>
        <Divider height="8px !important" backgroundColor="black5" />
      </Flex>
      <Flex flexDirection="column">
        <Flex padding="16px" gap="16px" flexDirection="column">
          <Skeleton height="24px" width="100px" />
          {range(2).map((_, indexSkeleton) => (
            <Flex
              flexDirection="column"
              gap="16px"
              key={`skeleton-${indexSkeleton}`}
            >
              <Divider height="5px !important" backgroundColor="black5" />
              <Flex gap="16px">
                <Skeleton height="48px" width="48px" />
                <Flex flexDirection="column" gap="8px" width="100%">
                  <Flex flexDirection="column" gap="4px">
                    <Skeleton
                      height="20px"
                      width={randomWidthInterval(100, 150)}
                    />
                    <Skeleton
                      height="20px"
                      width={randomWidthInterval(100, 150)}
                    />
                  </Flex>
                  <Flex gap="8px" flexDirection="column">
                    <Divider />
                    <Skeleton
                      height="20px"
                      width={randomWidthInterval(100, 150)}
                    />
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          ))}
        </Flex>
        <Divider height="8px !important" backgroundColor="black5" />
      </Flex>
      {range(4).map((_, indexSkeleton) => (
        <Flex
          key={`skeleton-${indexSkeleton}`}
          padding="16px"
          gap="16px"
          flexDirection="column"
        >
          <Skeleton height="24px" width={randomWidthInterval(100, 150)} />
          <Flex gap="8px" flexDirection="column">
            <Flex justifyContent="space-between" alignItems="center">
              <Skeleton height="20px" width="88px" />
              <Skeleton height="20px" width={randomWidthInterval(100, 150)} />
            </Flex>
            <Flex justifyContent="space-between" alignItems="center">
              <Skeleton height="20px" width="88px" />
              <Skeleton height="20px" width={randomWidthInterval(100, 150)} />
            </Flex>
          </Flex>
          <Divider />
          <Flex justifyContent="space-between" alignItems="center">
            <Skeleton height="20px" width="88px" />
            <Skeleton height="20px" width={randomWidthInterval(100, 150)} />
          </Flex>
          <Divider height="8px !important" backgroundColor="black5" />
        </Flex>
      ))}
    </Flex>
  );
};

export default Loading;
