import { useEffect } from "react";
import { useState } from "react";
import {
  Button,
  Dialog,
  Divider,
  Flex,
  Input,
  Label,
  Spinner,
  Text,
  Upload,
  useToast,
  useUpload,
} from "renos-ui";
import { useArriveOrder } from "services/order-management";
import { StyledButton } from "./styled";

const ModalArriveOrder = ({
  isOpen,
  onClose,
  refetchStatus,
  refetchList,
  orderId,
}) => {
  const toast = useToast();

  const [dataImage, setDataImage] = useState({});
  const [name, setName] = useState("");

  const { mutate: orderArrive, isLoading } = useArriveOrder({
    onSuccess: (data) => {
      const { message, frontend_message } = data;

      refetchStatus();
      refetchList();
      onClose();

      toast({
        label: frontend_message || message || "Pesanan berhasil diterima",
        placement: "top",
      });
    },
    onError: (data) => {
      const {
        response: {
          data: { frontend_message, message },
        },
      } = data;
      toast({
        label: frontend_message || message || "Pesanan gagal diterima",
        placement: "top",
        backgroundColor: "red50",
      });
    },
  });

  const handleSave = () => {
    const formData = new FormData();
    formData.append("file", dataImage.imageFile);
    formData.append("order_id", orderId);
    formData.append("receiver_contact_person", name);
    orderArrive(formData);
  };

  const { register, result } = useUpload({
    fileType: "image",
  });

  useEffect(() => {
    const maxAllowedSize = 10 * 1024 * 1024;
    const allowedType = [
      "image/png",
      "image/jpeg",
      "image/jpg",
      "application/pdf",
    ];

    if (result) {
      let errorCount = 0;

      if (maxAllowedSize < result?.file?.size) {
        toast({
          label: "Ukuran file tidak boleh melebihi dari 10mb.",
          placement: "top",
          backgroundColor: "red50",
        });

        errorCount++;
      }

      if (!allowedType.includes(result?.file?.type)) {
        toast({
          label: "File harus berekstensi jpg/jpeg/png/pdf.",
          placement: "top",
          backgroundColor: "red50",
        });

        errorCount++;
      }

      if (errorCount === 0) {
        setDataImage({
          imageUrl: result?.source,
          imageFile: result?.file,
        });
      }
    }
    // eslint-disable-next-line
  }, [result]);

  return (
    <Dialog isVisible={isOpen} onClose={onClose} verticalCentered closable>
      <Flex flexDirection="column" gap="24px" width="320px">
        <Flex flexDirection="column" gap="16px">
          <Text variant="heading3" weight="bold">
            Selesaikan Pengiriman
          </Text>
          <Divider />
        </Flex>
        <Flex flexDirection="column" gap="8px">
          <Flex gap="8px">
            <Text variant="caption" color="black50" weight="bold">
              Nama Penerima
            </Text>
            <Label>Wajib</Label>
          </Flex>
          <Input
            placeholder="Tulis nama penerima."
            value={name}
            onChange={(event) => setName(event.target.value)}
          />
        </Flex>
        <Flex flexDirection="column" gap="8px">
          <Flex flexDirection="column">
            <Flex gap="8px">
              <Text color="black75" weight="bold">
                Upload Bukti Pengiriman{" "}
              </Text>
              <Label>Wajib</Label>
            </Flex>
            <Text color="black75">
              Ambil foto penerima bersama dengan paket yang diantarkan. Pastikan
              foto terlihat jelas (tidak blur).
            </Text>
          </Flex>
          <Flex
            height="136px"
            borderColor="black10"
            borderWidth="1px"
            borderStyle="dashed"
            borderRadius="8px"
            justifyContent="center"
            flexDirection="column"
            alignItems="center"
            gap="16px"
          >
            {dataImage?.imageUrl ? (
              <Flex gap="6px">
                {dataImage?.imageFile?.type === "application/pdf" ? (
                  <Flex
                    as="img"
                    alt="preview"
                    width="100px"
                    height="64px"
                    src={"/assets/images/order-management/pdf.png"}
                    style={{ objectFit: "contain" }}
                  />
                ) : (
                  <Flex
                    borderRadius="4px"
                    as="img"
                    alt="preview"
                    width="100px"
                    height="64px"
                    src={dataImage?.imageUrl}
                    style={{ objectFit: "contain" }}
                  />
                )}
                <Flex flexDirection="column" gap="4px">
                  <Flex flexDirection="column">
                    <Text variant="caption" color="black75" weight="bold">
                      Upload Berhasil!
                    </Text>
                    <Text variant="caption" color="black75">
                      File berhasil diperbahurui.
                    </Text>
                  </Flex>
                  <Upload {...register} accept=".jpg, .jpeg, .png, .pdf">
                    <Flex cursor="pointer">
                      <Text variant="caption" color="blue50" weight="bold">
                        Ubah
                      </Text>
                    </Flex>
                  </Upload>
                </Flex>
              </Flex>
            ) : (
              <>
                <Flex width="160px" textAlign="center">
                  <Text variant="caption" color="black50">
                    Upload file dengan format JPG, JPEG, PNG atau PDF.
                  </Text>
                </Flex>

                <Upload {...register} accept=".jpg, .jpeg, .png, .pdf">
                  <Button variant="secondary" size="small">
                    Upload Foto
                  </Button>
                </Upload>
              </>
            )}
          </Flex>
        </Flex>
        <StyledButton
          isBlock
          disabled={!dataImage?.imageUrl || isLoading || !name}
          preffix={isLoading && <Spinner color="white" size="8px" />}
          onClick={handleSave}
        >
          Selesaikan Pengiriman
        </StyledButton>
      </Flex>
    </Dialog>
  );
};

export default ModalArriveOrder;
