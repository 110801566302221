import MobileLayout from "layouts/MobileLayout";
import React from "react";
import { Box, Flex } from "renos-ui";

const SplashScreen = () => {
  return (
    <MobileLayout>
      <Flex
        backgroundColor="blue50"
        width="100%"
        height="100%"
        position="relative"
        justifyContent="center"
        alignItems="center"
      >
        <Box
          as="img"
          src="/assets/images/onboard/background-top.svg"
          position="absolute"
          top="0"
          width="100%"
        />
        <Box as="img" src="/assets/images/onboard/renos-logo.svg" />
        <Box
          as="img"
          src="/assets/images/onboard/background-bottom.svg"
          position="absolute"
          bottom="0"
          width="100%"
        />
      </Flex>
    </MobileLayout>
  );
};

export default SplashScreen;
