import React from "react";
import { useWatch } from "react-hook-form";
import { Box, Flex, Input, Text } from "renos-ui";
import FormField from "./components/FormField";
import SectionWrapper from "./components/SectionWrapper";
import { StyledFieldTextarea } from "./styled";

const ProductDetail = ({ methods }) => {
  const productCondition = useWatch({ name: "product_condition" });

  return (
    <SectionWrapper sectionName="Detail Produk">
      <Flex flexDirection="column" rowGap="40px">
        <FormField label="Kondisi">
          <Flex columnGap="40px">
            <Flex
              alignItems="center"
              columnGap="8px"
              onClick={() => methods.setValue("product_condition", "new")}
            >
              <Input.Radio
                name="product_condition"
                checked={productCondition === "new"}
                value="new"
              />
              <Box as="label">
                <Text variant="body">Baru</Text>
              </Box>
            </Flex>
            <Flex
              alignItems="center"
              columnGap="8px"
              onClick={() => methods.setValue("product_condition", "second")}
            >
              <Input.Radio
                name="product_condition"
                value="second"
                checked={productCondition === "second"}
              />
              <Box as="label">
                <Text variant="body">Bekas</Text>
              </Box>
            </Flex>
          </Flex>
        </FormField>
        <FormField
          label="Deskripsi Produk"
          hint="Pastikan deskripsi produk memuat penjelasan detail terkait produkmu agar pembeli mudah mengerti dan menemukan produkmu."
        >
          <StyledFieldTextarea
            rows={6}
            showCount
            maxLength={2000}
            placeholder="Tulis Deskripsi Produk"
            name="description"
          />
        </FormField>
      </Flex>
    </SectionWrapper>
  );
};

export default ProductDetail;
