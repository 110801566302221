import React from "react";
import {
  Flex,
  Text,
  // , Input, Icon
} from "renos-ui";

const ChatSidebarHeader = ({ newChat }) => {
  const sum = newChat.reduce((total, current) => {
    return total + current.count_notif;
  }, 0);

  return (
    <Flex
      flexDirection="column"
      borderBottom="1px solid"
      borderColor="black10"
      padding="18px 16px"
      // marginBottom="8px"
      gap="12px"
    >
      <Text variant="heading3" weight="bold">
        Chat ({sum})
      </Text>
      {/* <Input
        size="small"
        name="input search"
        prefix={<Icon name="Search-outline" size={20} />}
        placeholder="Cari nama pembeli..."
      /> */}
    </Flex>
  );
};

export default ChatSidebarHeader;