import { Box, Checkbox, Divider, Flex, Text, Icon } from "renos-ui";

const Card = ({
  data,
  handleCheck,
  isChecked,
  isHasDivider,
  totalSelected,
}) => {
  return (
    <>
      <Flex gap="16px" alignItems="center">
        <Checkbox
          onChange={handleCheck}
          checked={isChecked}
          disabled={totalSelected === 12 && !isChecked}
        />
        <Flex gap="12px" alignItems="center">
          <Box
            as="img"
            src={data.product_image_url}
            height="40px"
            width="40px"
            borderRadius="8px"
            alt="img-product"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = "/assets/images/empty-product.svg";
            }}
          />
          <Flex flexDirection="column">
            <Text color="black75" variant="caption" lineClamp={1}>
              {data.product_name}
            </Text>
            <Text weight="bold">{data.formatted_product_price}</Text>
            <Flex gap="4px" alignItems="center">
              <Icon color="yellow50" size={10} name="Star-solid" />
              <Text color="black75" variant="caption" weight="bold">
                {data.review_rating}
              </Text>
              <Text color="black50" variant="caption">
                ({data.review_count} ulasan)
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </Flex>

      <Box>{isHasDivider && <Divider />}</Box>
    </>
  );
};

export default Card;
