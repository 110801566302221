import { Button, Dialog, Flex, Text } from "renos-ui";
import { useHistory } from "react-router-dom";
import desktopUrl from "constants/desktopUrl";
import dayjs from "dayjs";

const ModalSuccess = ({ isOpen, startDate, endDate, isEdit }) => {
  const { push } = useHistory();

  return (
    <Dialog verticalCentered isVisible={isOpen}>
      <Flex width="320px" flexDirection="column" gap="24px">
        <Flex flexDirection="column" gap="8px" alignItems="center">
          <Text variant="heading3" weight="bold">
            Yay! Voucher Berhasil {isEdit ? "Diubah" : "Dibuat"}
          </Text>
          <Flex
            as="img"
            width="200px"
            height="200px"
            src="/assets/images/voucher/voucher-add.svg"
          />
          <Text>
            {`
                Voucher kamu akan aktif pada tanggal ${dayjs(startDate).format(
                  "DD MMMM YYYY"
                )} pukul ${dayjs(startDate).format("HH:mm")} hingga ${dayjs(
              endDate
            ).format("DD MMMM YYYY")} pukul ${dayjs(endDate).format("HH:mm")}`}
          </Text>
        </Flex>
        <Button onClick={() => push(desktopUrl.VOUCHER)} isBlock>
          Oke
        </Button>
      </Flex>
    </Dialog>
  );
};

export default ModalSuccess;
