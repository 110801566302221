import { Flex, Icon, Input, Text } from "renos-ui";
import { StyledSelectInput } from "./styled";

const Stock = ({ value, setValue }) => {
  const handleChange = (newValue, property) => {
    setValue({ ...value, [property]: newValue });
  };

  const CONDITION_DATA = [
    { value: "gt", label: "Lebih dari" },
    { value: "lt", label: "Kurang dari" },
    { value: "eq", label: "Sama dengan" },
  ];

  return (
    <Flex flexDirection="column" gap="16px">
      <Flex flexDirection="column">
        <Text weight="bold" color="black75">
          Stok Produk
        </Text>
        <Text variant="caption" color="black75">
          Atur filter berdasarkan stok.
        </Text>
      </Flex>
      <Flex alignItems="center" gap="8px">
        <Flex width="200px">
          <StyledSelectInput
            options={CONDITION_DATA}
            placeholder="Pilih Kondisi"
            size="small"
            onChange={(newValue) => handleChange(newValue, "condition")}
            value={value.condition}
          />
        </Flex>
        <Flex width="200px">
          <Input.Number
            max={99999}
            placeholder="0"
            onChange={(newValue) => handleChange(newValue, "stock")}
            value={value.stock || 0}
            size="small"
            disabled={!value?.condition?.value}
          />
        </Flex>
      </Flex>
      {value.condition.value && value.stock !== "" ? (
        <Flex
          padding="12px 16px"
          borderRadius="8px"
          backgroundColor="black5"
          alignItems="center"
          gap="8px"
          width="376px"
        >
          <Icon name="Info-solid" size={20} color="black50" />
          <Text variant="caption" color="black75">
            Menampilkan produk dengan{" "}
            <b>
              stok {value?.condition?.label} {value.stock}.
            </b>
          </Text>
        </Flex>
      ) : (
        <></>
      )}
    </Flex>
  );
};

export default Stock;
