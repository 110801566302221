import React from "react";
import { useRef } from "react";
import { useFormContext } from "react-hook-form";
import { Box, Flex, Text } from "renos-ui";

const SectionWrapper = ({ children, sectionName, isError }) => {
  const {
    formState: { isSubmitting },
  } = useFormContext();

  const ref = useRef(null);

  if (isError && isSubmitting) {
    ref.current.scrollIntoView({
      behavior: "smooth",
    });
  }

  return (
    <Flex
      ref={ref}
      borderRadius={16}
      padding={24}
      backgroundColor="white"
      boxShadow="0px 4px 4px rgba(20, 20, 32, 0.05)"
      flexDirection="column"
      rowGap="28px"
    >
      <Flex columnGap="16px" alignItems="center">
        <Box backgroundColor="blue50" borderRadius={2} height={24} width={4} />
        <Text variant="subtitle" weight="bold" color="black75">
          {sectionName}
        </Text>
      </Flex>
      {children}
    </Flex>
  );
};

export default SectionWrapper;
