import { Flex, Icon, Input, Text, useDebounce } from "renos-ui";
import { StyledFlexInputSearch } from "./styled";
import SelectCourier from "./SelectCourier";
import { useEffect, useMemo, useState } from "react";
import SelectSort from "./SelectSort";
import TabSelector from "./TabSelector";
import CustomDatepicker from "./CustomDatepicker";
import dayjs from "dayjs";
import { useHistory } from "react-router-dom";
import { setParamQuery } from "helpers";
import useQueryParams from "hooks/useQueryParams";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

dayjs.extend(customParseFormat);

const Filter = ({ value, setValue }) => {
  const { pathname, state, search } = useLocation();
  const { push } = useHistory();
  const query = useQueryParams();

  const [selectedCourier, setSelectedCourier] = useState([]);
  const [selectedSort, setSelectedSort] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [selectedTab, setSelectedTab] = useState(null);
  const [selectedDate, setSelectedDate] = useState({
    startDate: "",
    endDate: "",
  });

  useDebounce(
    () => {
      const tempData = value;
      tempData.query = searchValue;
      tempData.sort_type = selectedSort;

      if (selectedDate?.startDate) {
        tempData.date_start = dayjs(selectedDate.startDate).format(
          "YYYY-MM-DD"
        );
      } else {
        delete tempData.date_start;
      }

      if (selectedDate?.endDate) {
        tempData.date_end = dayjs(selectedDate.endDate).format("YYYY-MM-DD");
      } else {
        delete tempData.date_end;
      }

      if (!tempData.query) delete tempData.query;

      if (!tempData.sort_type) delete tempData.sort_type;

      if (selectedCourier.length === 0) {
        delete tempData.courier_ids;
      } else {
        tempData.courier_ids = selectedCourier.join(",");
      }

      if (selectedTab) {
        tempData.order_status = selectedTab;
      } else {
        delete tempData.order_status;
      }

      tempData.page = 1;
      setValue({ ...tempData });

      const urlParams = tempData;
      delete urlParams.items_per_page;
      delete urlParams.page;

      push({
        pathname,
        search,
        query: setParamQuery(urlParams),
        state,
      });
    },
    [selectedSort, selectedCourier, searchValue, selectedDate, selectedTab],
    1000
  );

  const formattedDate = useMemo(() => {
    if (selectedDate.startDate === selectedDate.endDate)
      return dayjs(selectedDate.startDate).format("DD MMM YYYY");

    return `${dayjs(selectedDate.startDate).format("DD MMM YYYY")} - ${dayjs(
      selectedDate.endDate
    ).format("DD MMM YYYY")} `;
  }, [selectedDate]);

  useEffect(() => {
    if (query.get("order_status"))
      setSelectedTab(parseInt(query.get("order_status")));

    if (query.get("query")) setSearchValue(query.get("query"));

    if (query.get("sort_type")) setSelectedSort(query.get("sort_type"));

    const isDateStartValid = dayjs(
      query.get("date_start"),
      "YYYY-MM-DD",
      true
    ).isValid();

    if (isDateStartValid) {
      const formattedStart = new Date(query.get("date_start"));
      const formattedEnd = dayjs(
        query.get("date_end"),
        "YYYY-MM-DD",
        true
      ).isValid()
        ? new Date(query.get("date_end"))
        : formattedStart;

      setSelectedDate({
        startDate: formattedStart,
        endDate: formattedStart <= formattedEnd ? formattedEnd : formattedStart,
      });
    }

    if (query.get("courier_ids")) {
      const arrayCourier = query.get("courier_ids").split(/,|%2C|%2c/);

      setSelectedCourier(arrayCourier);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Flex px="16px" flexDirection="column" gap="8px">
      <Flex justifyContent="space-between" alignItems="center" gap="16px">
        <StyledFlexInputSearch width="100%" borderRadius="100px" height="40px">
          <Input
            size="small"
            placeholder="Pencarian..."
            prefix={<Icon name="Search-outline" size={16} color="black50" />}
            onChange={(event) => setSearchValue(event.target.value)}
            value={searchValue}
          />
        </StyledFlexInputSearch>
        <CustomDatepicker value={selectedDate} setValue={setSelectedDate} />
      </Flex>

      {(selectedDate.startDate || selectedDate.endDate) && (
        <Flex
          alignItems="center"
          justifyContent="space-between"
          paddingTop="8px"
        >
          <Text variant="caption" color="black50">
            Tanggal Pemesanan
          </Text>
          <Flex gap="4px" alignItems="center">
            <Text variant="caption" color="black75">
              {formattedDate}
            </Text>
            <Icon
              name="Cancel-outline"
              size={16}
              color="black50"
              onClick={() => setSelectedDate({ startDate: "", endDate: "" })}
            />
          </Flex>
        </Flex>
      )}

      <Flex justifyContent="space-between" alignItems="center" gap="16px">
        <SelectCourier value={selectedCourier} setValue={setSelectedCourier} />
        <SelectSort value={selectedSort} setValue={setSelectedSort} />
      </Flex>
      <TabSelector value={selectedTab} setValue={setSelectedTab} />
    </Flex>
  );
};

export default Filter;
