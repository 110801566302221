import * as yup from "yup";

export const productAddValidationSchema = (isEdit) =>
  yup.object({
    product_name: yup
      .string()
      .max(70, "Nama produk maksimal 70 karakter")
      .required("Nama produk harus di isi"),
    category: yup
      .number()
      .typeError("Kategori harus diisi sampai level 3.")
      .required("Kategori harus diisi sampai level 3."),
    images: yup.array().min(1, "Foto Produk tidak boleh kosong."),
    variant: yup.number().notRequired(),
    price: yup.number().when("variant", {
      is: (variant) => {
        return variant === 1;
      },
      then: (schema) => schema.notRequired(),
      otherwise: (schema) =>
        schema
          .min(1, "Harga Produk tidak boleh kosong.")
          .required("Harga Produk tidak boleh kosong."),
    }),
    stock: isEdit
      ? yup.number().when("variant", {
          is: (variant) => variant === 1,
          then: (schema) => schema.notRequired(),
          otherwise: (schema) =>
            schema.required("Stok Produk tidak boleh kosong."),
        })
      : yup.number().when("variant", {
          is: (variant) => variant === 1,
          then: (schema) => schema.notRequired(),
          otherwise: (schema) =>
            schema
              .min(1, "Stok Produk minimal 1.")
              .required("Stok Produk tidak boleh kosong."),
        }),
    // weight: yup
    //   .number()
    //   .min(1, "Berat tidak boleh kosong.")
    //   .required("Berat tidak boleh kosong."),
    weight: yup.number().when("variant", {
      is: (variant) => variant === 1,
      then: (schema) => schema.notRequired(),
      otherwise: (schema) =>
        schema
          .min(1, "Berat tidak boleh kosong.")
          .required("Berat tidak boleh kosong."),
    }),
    length: yup
      .number()
      .min(1, "Panjang tidak boleh kosong.")
      .required("Panjang tidak boleh kosong."),
    width: yup
      .number()
      .min(1, "Lebar tidak boleh kosong.")
      .required("Lebar tidak boleh kosong."),
    height: yup
      .number()
      .min(1, "Tinggi tidak boleh kosong.")
      .required("Tinggi tidak boleh kosong."),
  });

export const mobileSkuValidationSchema = (obj) => {
  if (!Array.isArray(obj)) {
    return false;
  }

  const requiredFields = [
    "variants",
    "price",
    "image",
    "image_id",
    "status",
    "weight",
    "image_url",
    "number",
  ];

  for (let i = 0; i < obj.length; i++) {
    const item = obj[i];

    for (const field of requiredFields) {
      if (
        !(field in item) ||
        item[field] === undefined ||
        item[field] === null ||
        item[field] === "" ||
        item[field] === 0
      ) {
        return false;
      }
    }
  }

  return true;
};
