import React from "react";
import { Flex, Text, isEmpty } from "renos-ui";
import { useHistory } from "react-router-dom";
import FormLayout from "./FormLayout";
import mobileUrl from "constants/mobileUrl";
import { useProductAddContext } from "store/productAdd/reducer";

const ProductDetail = () => {
  const { push } = useHistory();

  const {
    state: { product_condition, description },
  } = useProductAddContext();

  const isFilled = product_condition !== null || !isEmpty(description);

  return (
    <FormLayout
      title="Detail Produk"
      isFilled={isFilled}
      action={() => push(mobileUrl.PRODUCT_DETAIL)}
    >
      {isFilled && (
        <>
          {description && (
            <Flex
              flexDirection="column"
              rowGap="16px"
              width="calc(100dvw - 10vw)"
            >
              <Flex flexDirection="column" flex={1}>
                <Text variant="small" color="black50">
                  Deskripsi Produk
                </Text>
                <Text variant="caption" lineClamp={1}>
                  {description}
                </Text>
              </Flex>
            </Flex>
          )}
          <Flex flexDirection="column">
            <Text variant="small" color="black50">
              Kondisi Produk
            </Text>
            <Text variant="caption">
              {product_condition === "new" ? "Baru" : "Bekas"}
            </Text>
          </Flex>
        </>
      )}
    </FormLayout>
  );
};

export default ProductDetail;
