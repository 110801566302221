import { Flex, Box, Text, Button } from "renos-ui";
import PropTypes from "prop-types";
import IMGEmptyShowcase from "assets/images/empty-showcase.png";

const EmptyShowcase = ({ handleAdd, title, description, shortDesc }) => {
  return (
    <Flex
      gap="8px"
      justifyContent="center"
      alignItems="center"
      minHeight="500px"
    >
      <Flex flexDirection="column" alignItems="center" gap="24px">
        <Box
          as="img"
          src={IMGEmptyShowcase}
          width="200px"
          height="200px"
          alt="empty-showcase"
        />
        <Flex flexDirection="column" alignItems="center" gap="8px">
          <Text variant="heading3" weight="bold" color="black100">
            {title}
          </Text>
          <Text color="black75">{description}</Text>
          {shortDesc && (
            <Text color="black75">
              agar lebih mudah ditemukan oleh pembeli.
            </Text>
          )}
        </Flex>
        <Button size="large" onClick={handleAdd}>
          Tambah Produk
        </Button>
      </Flex>
    </Flex>
  );
};

EmptyShowcase.propTypes = {
  handleAdd: PropTypes.func.isRequired,
};

export default EmptyShowcase;
