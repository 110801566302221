import MobileLayout from "layouts/MobileLayout";
import React, { useState } from "react";
import { Box, Divider, Flex, Input, Text } from "renos-ui";
import { useProductAddContext } from "store/productAdd/reducer";
import FormLayout from "../../components/FormLayout";
import constants from "store/productAdd/constants";
import { useHistory } from "react-router-dom";
import { StyledTextArea } from "./styled";

const ProductDetail = () => {
  const {
    state: { product_condition, description },
    dispatch,
  } = useProductAddContext();

  const { goBack } = useHistory();

  const [productDescription, setProductDescription] = useState(
    description || "",
  );
  const [status, setStatus] = useState(
    product_condition !== null ? product_condition : "new",
  );

  const onDescriptionChange = (e) => {
    setProductDescription(e.target.value);
  };

  const onFinish = () => {
    dispatch({
      type: constants.SAVE_VALUE,
      payload: {
        product_condition: status,
        description: productDescription,
      },
    });
    goBack();
  };

  return (
    <MobileLayout
      header={{
        title: "Detail Produk",
        isHomepage: true,
        withBack: true,
        suffix: (
          <Flex alignItems="center" cursor="pointer" onClick={onFinish}>
            <Text color="blue75" weight="bold">
              Selesai
            </Text>
          </Flex>
        ),
      }}
    >
      <Flex
        flexDirection="column"
        rowGap="16px"
        padding="16px"
        width="100%"
        height="calc(100dvh - 92px)"
        overflow="auto"
      >
        <FormLayout title="Deskripsi Produk">
          <StyledTextArea
            value={productDescription}
            rows={6}
            showCount
            maxLength={2000}
            placeholder="Tulis Deskripsi Produk"
            onChange={onDescriptionChange}
          />
        </FormLayout>
        <FormLayout title="Kondisi Produk" required>
          <Flex flexDirection="column" rowGap="12px">
            <Flex
              alignItems="center"
              justifyContent="space-between"
              columnGap="8px"
              onClick={() => setStatus("new")}
            >
              <Box as="label">
                <Text variant="body">Baru</Text>
              </Box>
              <Input.Radio
                name="product_condition"
                checked={status === "new"}
                value="new"
                readOnly
              />
            </Flex>
            <Divider />
            <Flex
              alignItems="center"
              justifyContent="space-between"
              columnGap="8px"
              onClick={() => setStatus("second")}
            >
              <Box as="label">
                <Text variant="body">Bekas</Text>
              </Box>
              <Input.Radio
                name="product_condition"
                value="second"
                readOnly
                checked={status === "second"}
              />
            </Flex>
            <Divider />
          </Flex>
        </FormLayout>
      </Flex>
    </MobileLayout>
  );
};

export default ProductDetail;
