import React from "react";
import DesktopRouter from "routes/desktop";
import MobileRouter from "routes/mobile";

const RenosApp = () => {
  const isMobile = window.innerWidth <= 760;

  return isMobile ? <MobileRouter /> : <DesktopRouter />;
};

export default RenosApp;
