import { Flex, Skeleton, range } from "renos-ui";

const ProductSkeleton = ({ itemsShow = 5 }) => {
  return range(itemsShow).map((item) => (
    <Flex
      gap="12px"
      pb="12px"
      alignItems="center"
      borderBottomWidth="1px"
      borderBottomStyle="solid"
      borderBottomColor="black5"
      key={`skeleton-product-${item}`}
    >
      <Flex>
        <Skeleton width="20px" height="20px" />
      </Flex>
      <Flex>
        <Skeleton width="48px" height="48px" />
      </Flex>
      <Flex gap="4px" flexDirection="column" width="90%">
        <Skeleton width="90%" height="20px" />
        <Skeleton width="60%" height="20px" />
      </Flex>
    </Flex>
  ));
};

export default ProductSkeleton;