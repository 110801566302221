import { Drawer, Text, colors, Flex } from "renos-ui";

const TooltipsDrawer = ({ data, disclosure }) => {
  return (
    <Drawer
      isVisible={disclosure.isOpen}
      closable={true}
      onClose={() => disclosure.onClose()}
      placement="bottom"
    >
      {data.map((item, indexTooltip) => (
        <Flex
          padding="16px 0px"
          borderBottom={indexTooltip === 3 ? "" : `1px solid ${colors.black5}`}
          key={`tooltip-${indexTooltip}`}
          flexDirection="column"
          gap="8px"
        >
          <Text variant="subtitle" weight="bold">
            {item.name}
          </Text>
          <Text>{item.tooltip}</Text>
        </Flex>
      ))}
    </Drawer>
  );
};

export default TooltipsDrawer;
