import React from "react";
import { Flex, Skeleton } from "renos-ui";

const LoadingStore = () => {
  return (
    <>
      <Flex flexDirection="column" gap="24px">
        <Flex padding="16px" gap="24px" flexDirection="column">
          <Flex gap="10px" alignItems="center">
            <Skeleton width="10%" height="30px" />
            <Skeleton width="40%" height="30px" />
          </Flex>
          <Flex flexDirection="column" gap="8px">
            <Skeleton width="40%" height="30px" />
            <Skeleton height="30px" />
          </Flex>
          <Flex flexDirection="column" gap="8px">
            <Skeleton width="40%" height="30px" />
            <Skeleton height="30px" />
          </Flex>
          <Flex flexDirection="column" gap="8px">
            <Skeleton width="40%" height="30px" />
            <Skeleton height="30px" />
          </Flex>
        </Flex>

        <Flex
          bottom="0"
          width="100%"
          position="fixed"
          justifyContent="center"
          alignItems="center"
          backgroundColor="white"
          boxShadow="0px 0px 8px 0px rgba(20, 20, 32, 0.05);"
        >
          <Flex width="100%" padding="16px">
            <Skeleton height="30px" />
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};

export default LoadingStore;
