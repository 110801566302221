import { IMAGE_FILE_TYPE } from "containers/web/setting/document/constant";
import {
  blobToBase64,
  getImageMeta,
  imageTypeChecking,
  imgCompressor,
} from "helpers";
import React, { useState } from "react";
import { useMemo } from "react";
import {
  Divider,
  Drawer,
  Flex,
  Icon,
  Input,
  Text,
  Upload,
  isEmpty,
  useDisclosure,
  useToast,
  useUpload,
} from "renos-ui";
import { useUploadProductImage } from "services/product";
import constants from "store/productAdd/constants";
import { useProductAddContext } from "store/productAdd/reducer";

const VariantForm = ({ variantIndex, selectedSku, onFormValueChange }) => {
  const { state, dispatch } = useProductAddContext();
  const { isEdit, uom, sku } = state;

  const isHaveDiscount = useMemo(
    () => selectedSku?.is_product_discount_seasonal_active,
    [selectedSku?.is_product_discount_seasonal_active]
  );

  const { isOpen, onClose, onOpen } = useDisclosure({ open: false });

  const toast = useToast();

  const [imageIndex, setImageIndex] = useState();

  const onDeleteImage = () => {
    dispatch({
      type: constants.SAVE_VALUE,
      sku: sku.map((prev) => {
        if (prev.index === imageIndex) {
          prev.image_id = null;
          prev.image = null;
          prev.image_url = null;
        }

        return prev;
      }),
    });

    onClose();
  };

  const { mutate: uploadImage } = useUploadProductImage({
    onSuccess: (data) => {
      dispatch({
        type: constants.SAVE_VALUE,
        sku: sku.map((prev) => {
          if (prev.index === imageIndex) {
            prev.image_id = data.image_id;
            prev.image = data.path;
            prev.image_url = data.url;
          }

          return prev;
        }),
      });
      onClose();
    },
  });

  const { register } = useUpload({
    fileType: "image",
    onRequest: async (file) => {
      const deepTypeChecking = await imageTypeChecking(file);

      if (deepTypeChecking) {
        const result = await blobToBase64(file);
        const { naturalHeight, naturalWidth } = await getImageMeta(result);

        if (naturalHeight < 300 || naturalWidth < 300) {
          toast({
            label: "Format gambar tidak sesuai",
            placement: "bottom",
          });

          return null;
        }

        const formData = new FormData();
        const compressed =
          file.size > 400000
            ? await imgCompressor(file, {
                maxSizeMB: 0.4,
                maxWidthOrHeight: 1920,
                useWebWorker: true,
              })
            : file;

        formData.append("image", compressed);
        formData.append("image_type_id", 6);
        uploadImage(formData);
      } else {
        toast({
          label: "Format gambar tidak sesuai",
          placement: "bottom",
        });
      }
    },
    validator: (file) => {
      if (!IMAGE_FILE_TYPE.includes(file.type)) {
        toast({
          label: "Format gambar tidak sesuai",
          placement: "bottom",
        });

        return {
          message: "Format gambar tidak sesuai",
        };
      }

      return null;
    },
  });

  const onProductWithDiscountClicked = () => {
    toast({
      label:
        "Harga produk tidak bisa diubah karena produk sudah diatur untuk promosi.",
      placement: "bottom",
      width: "90dvw",
    });
  };

  return (
    <>
      <Flex columnGap="16px">
        <Flex flexDirection="column" rowGap="8px" flex={2}>
          <Text variant="caption" weight="bold">
            Harga
          </Text>
          <Flex position="relative" width="100%">
            <Input.Number
              size="small"
              prefix={
                <Text variant="caption" weight="bold" color="black50">
                  Rp
                </Text>
              }
              max={999999999}
              placeholder="Harga"
              onChange={(value) =>
                onFormValueChange(value, variantIndex, "price")
              }
              value={selectedSku?.price || ""}
              disabled={isHaveDiscount}
            />
            {isHaveDiscount && (
              <Flex
                position="absolute"
                width="100%"
                height="100%"
                zIndex={100}
                onClick={onProductWithDiscountClicked}
              />
            )}
          </Flex>
        </Flex>
        <Flex flexDirection="column" rowGap="8px" width="100px">
          <Text variant="caption" weight="bold">
            Stok
          </Text>
          <Input.Number
            size="small"
            placeholder="Stok"
            max={99999}
            onChange={(e) => onFormValueChange(e, variantIndex, "stock")}
            value={
              selectedSku
                ? selectedSku?.stock === 0
                  ? "0"
                  : selectedSku?.stock
                : selectedSku?.stock
            }
            min={
              selectedSku?.status === 2 && isEdit
                ? 0
                : !isEdit || selectedSku?.default === 1
                ? 1
                : 0
            }
            onFocus={(event) => event.target.select()}
          />
        </Flex>
      </Flex>
      <Flex columnGap="16px">
        <Flex flexDirection="column" rowGap="8px">
          <Text variant="caption" weight="bold">
            Foto
          </Text>
          {!isEmpty(selectedSku?.image_url) ? (
            <Flex
              width="40px"
              height="40px"
              borderRadius="8px"
              cursor="pointer"
              background={`url("${selectedSku?.image_url}")`}
              backgroundRepeat="no-repeat"
              backgroundPosition="center"
              backgroundSize="contain"
              borderWidth="1px"
              borderColor="black5"
              borderStyle="solid"
              onClick={() => {
                onOpen();
                setImageIndex(variantIndex);
              }}
            />
          ) : (
            <Upload {...register} accept={IMAGE_FILE_TYPE}>
              <Flex
                width="40px"
                height="40px"
                borderRadius="8px"
                alignItems="center"
                justifyContent="center"
                cursor="pointer"
                backgroundColor="black5"
                onClick={() => setImageIndex(variantIndex)}
              >
                <Icon name="Plus-solid" size={16} color="blue50" />
              </Flex>
            </Upload>
          )}
        </Flex>
        <Flex flexDirection="column" rowGap="8px" flex={1}>
          <Text variant="caption" weight="bold">
            SKU
          </Text>
          <Input
            size="small"
            placeholder="SKU"
            onChange={(e) =>
              onFormValueChange(e.target.value, variantIndex, "number")
            }
            value={selectedSku?.number || ""}
          />
        </Flex>
        <Flex flexDirection="column" rowGap="8px" width="100px">
          <Text variant="caption" weight="bold">
            Berat
          </Text>
          <Input.Number
            size="small"
            placeholder="Berat"
            max={uom === 1 ? 999 : 999999}
            required
            onChange={(value) =>
              onFormValueChange(value, variantIndex, "weight")
            }
            sufix={
              <Text color="black50" variant="caption" weight="bold">
                {uom === 1 ? "kg" : "g"}
              </Text>
            }
            value={selectedSku?.weight || ""}
            min={1}
            onFocus={(event) => event.target.select()}
          />
        </Flex>
      </Flex>
      <Drawer
        isVisible={isOpen}
        onClose={() => {
          onClose();
          setImageIndex(null);
        }}
        placement="bottom"
        closable
        header={{
          title: "Foto",
        }}
      >
        <Flex flexDirection="column" rowGap="16px" pt={16} mx={-16}>
          <Flex pl={16} onClick={onDeleteImage}>
            <Text>Hapus</Text>
          </Flex>
          <Divider />
          <Upload {...register} accept={IMAGE_FILE_TYPE}>
            <Flex pl={16} onClick={() => setImageIndex(variantIndex)}>
              <Text>Ubah</Text>
            </Flex>
          </Upload>
        </Flex>
      </Drawer>
    </>
  );
};

export default VariantForm;
