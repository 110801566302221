import React, { useContext } from "react";
import FilterProductContext from "providers/FilterProvider";
import {
  Checkbox,
  Flex,
  Icon,
  Input,
  Skeleton,
  Text,
  range,
  Tooltip,
} from "renos-ui";
import { useGetCategories } from "services/product-category";

const FilterCategory = () => {
  const { data, isLoading, isError } = useGetCategories();
  const { categoriesId, setCategoriesId } = useContext(FilterProductContext);

  const handleOnChangeCheckbox = (id) => {
    if (categoriesId.includes(id)) {
      const selectedIndex = categoriesId.findIndex((data) => data === id);

      const tempChecked = [...categoriesId];
      tempChecked.splice(selectedIndex, 1);

      return setCategoriesId(tempChecked);
    }

    setCategoriesId((prev) => [...prev, id]);
  };

  return (
    <Flex padding="24px" flexDirection="column" width="100%" rowGap="16px">
      <Input
        label="Pilih Kategori Produk"
        prefix={<Icon name="Search-outline" />}
        placeholder="Cari nama kategori"
      />
      {isError ? (
        <Flex />
      ) : isLoading ? (
        <Flex flexDirection="column" rowGap="8px">
          {range(12).map((_, idx) => (
            <Flex alignItems="center" gap="8px" key={`skeleton-${idx}`}>
              <Skeleton />
              <Skeleton />
            </Flex>
          ))}
        </Flex>
      ) : (
        data?.map((row) => (
          <Flex
            flexDirection="column"
            rowGap="18px"
            key={`${row.category_id}-${row.category_name}`}
          >
            <Text variant="caption" weight="bold" color="black50">
              {row?.category_name}
            </Text>
            <Flex
              flexWrap="wrap"
              flex="1"
              gap="12px"
              marginBottom="24px"
              justifyContent="space-between"
            >
              {row?.options?.map((row2) =>
                row2?.options?.map((row3) => (
                  <Flex
                    alignItems="center"
                    gap="8px"
                    width="46%"
                    key={`${row2.category_id}-${row3.category_id}-${row2.category_name}`}
                  >
                    <Checkbox
                      checked={categoriesId?.includes(row3.category_id)}
                      onChange={() => handleOnChangeCheckbox(row3.category_id)}
                    />
                    <Tooltip placement="top" label={row3.category_name}>
                      <Text color="black75" variant="caption" lineClamp={1}>
                        {row3.category_name}
                      </Text>
                    </Tooltip>
                  </Flex>
                ))
              )}
            </Flex>
          </Flex>
        ))
      )}
    </Flex>
  );
};

export default FilterCategory;
