import MobileLayout from "layouts/MobileLayout";
import { Box, Flex, range, useDisclosure } from "renos-ui";
import Empty from "containers/mobile/order-management/list/component/Empty";
import Filter from "containers/mobile/order-management/list/component/Filter";
import { useMemo, useState } from "react";
import { useGetOrder } from "services/order-management";
import FailLoadData from "widgets/mobile/FailLoadData";
import Card from "containers/mobile/order-management/list/component/Card";
import { useQueryClient } from "react-query";
import SkeletonLoading from "containers/mobile/order-management/list/component/SkeletonLoading";
import InfiniteScroll from "widgets/global/InfiniteScroll";
import More from "containers/mobile/order-management/list/component/More";
import useQueryParams from "hooks/useQueryParams";
import {
  useLocation,
  useHistory,
} from "react-router-dom/cjs/react-router-dom.min";
import mobileUrl from "constants/mobileUrl";

const ChatAttachOrder = () => {
  const SHOW_CARD_FOOTER = false;
  const query = useQueryParams();
  const location = useLocation();
  const history = useHistory();
  const queryClient = useQueryClient();
  const [dataFilter, setDataFilter] = useState({
    items_per_page: 10,
    page: 1,
    user_id: query.get("user_id"),
    version: "v3",
  });
  const [items, setItems] = useState([]);
  const disclosureMore = useDisclosure({ isOpen: false });

  const {
    data: dataList,
    isLoading,
    isError,
    isFetching,
    refetch,
  } = useGetOrder(dataFilter, {
    onSuccess: (data) => {
      if (dataFilter.page === 1) {
        setItems(data.data);
      } else {
        setItems([...items.concat(data.data)]);
      }
    },
  });

  const toFirstPage = () => {
    queryClient.invalidateQueries({
      queryKey: ["getOrderStatus"],
    });

    if (dataFilter.page === 1) {
      queryClient.invalidateQueries({
        queryKey: ["getOrder"],
      });
    } else {
      setDataFilter({ ...dataFilter, page: 1 });
    }
  };

  const hasNextPage = useMemo(
    () => dataList?.paginator?.has_more_pages,
    [dataList]
  );

  const isOrderLoading = useMemo(
    () => (isLoading || isFetching) && dataFilter.page === 1,
    // eslint-disable-next-line
    [isLoading, isFetching]
  );

  const handleClickDetail = (data) => {
    history.push({
      pathname: mobileUrl.CHAT,
      search: "",
      state: {
        qiscusId: data.buyer_qiscus_id,
        orderNumber: data.order_number,
        orderStatus: data.order_status_name,
        productImage: data.products[0].product_image_url,
        productName: data.products[0].product_name,
        totalPrice: data.formatted_calculated_total_price,
      },
    });
  };

  return (
    <MobileLayout
      header={{
        title: "Lampirkan Pesanan",
        isHomepage: false,
        withBack: true,
        withoutGoBack: true,
        onBack: () => {
          history.push({
            pathname: mobileUrl.CHATROOM,
            search: "",
            state: {
              ...location.state,
              show_attach_popup: true,
            },
          });
        },
      }}
      noBorder
      footer
    >
      <Flex width="100%" flexDirection="column">
        <More disclosure={disclosureMore} />
        <Filter setValue={setDataFilter} value={dataFilter} />
        <Box backgroundColor="black5" height="8px" width="100%" />
        <Flex
          height={
            dataFilter.start_date ? "calc(100% - 190px)" : "calc(100% - 154px)"
          }
          overflow="auto"
          flexDirection="column"
          backgroundColor={
            isOrderLoading || items.length === 0 ? "white" : "black5"
          }
          gap="8px"
        >
          {isOrderLoading ? (
            range(3).map((_, indexSkeleton) => (
              <SkeletonLoading key={`skeleton-${indexSkeleton}`} />
            ))
          ) : isError ? (
            <FailLoadData onClick={refetch} />
          ) : items.length === 0 ? (
            <Empty />
          ) : (
            <InfiniteScroll
              page={dataFilter.page}
              onChange={() => {
                setDataFilter((prev) => ({ ...prev, page: prev.page + 1 }));
              }}
              isLoading={isLoading}
              hasNextPage={hasNextPage && !isOrderLoading}
              isMobile
            >
              {items.map((item, indexItem) => (
                <Card
                  key={`card-${indexItem}`}
                  data={item}
                  toFirstPage={toFirstPage}
                  isShowFooter={SHOW_CARD_FOOTER}
                  handleClickDetail={handleClickDetail}
                />
              ))}
            </InfiniteScroll>
          )}
        </Flex>
      </Flex>
    </MobileLayout>
  );
};

export default ChatAttachOrder;
