import {
  Box,
  Button,
  Dialog,
  Dropdown,
  Flex,
  Icon,
  Text,
  Tooltip,
  isEmpty,
} from "renos-ui";
import styled from "styled-components";
import SearchInput from "../SearchInput";
import { ListSkeletonShowcase } from "../Skeleton";
import EmptyShowcase from "../EmptyShowcase";
import PreviewShowcase from "./PreviewShowcase";
import AddShowcase from "../add-showcase";
import UploadModalShowcase from "../UploadModalShowcase";
import useDashboard from "./hooks/useDashboard";
import FailLoadData from "widgets/global/FailLoadData";

const StyledDropdownContent = styled(Dropdown.Content)`
  transform: translate(-4px, 0);
  height: 100px;
`;

const DashboardShowcase = ({ hasProduct }) => {
  const {
    handleOkDelete,
    isLoadingDeleteShowcase,
    handleOpenPreview,
    handleAdd,
    handleDelete,
    handleOpenUploadImage,
    handleDropdown,
    handleOpenSet,
    handleOnDragEnd,
    setSearchValue,
    mode,
    titleMessage,
    isLoadingProductShowcase,
    DragDropContext,
    Droppable,
    Draggable,
    addShowcaseDisclosure,
    setTitleMessage,
    refetchProductShowcase,
    tempTagId,
    setMode,
    searchValue,
    dataShowcase,
    uploadDisclosure,
    previewDisclosure,
    deleteModal,
    isError,
  } = useDashboard();

  return (
    <>
      {addShowcaseDisclosure.isOpen ? (
        <AddShowcase
          titleMessage={titleMessage}
          setTitleMessage={setTitleMessage}
          addShowcaseDisclosure={addShowcaseDisclosure}
          refetchProductShowcase={refetchProductShowcase}
          tempTagId={tempTagId}
          mode={mode}
          setMode={setMode}
          handleAdd={handleAdd}
          hasProduct={hasProduct}
        />
      ) : (
        <Flex flexDirection="column" gap="4px">
          <Box borderRadius="16px 16px 0 0" background="white" padding="24px">
            <Flex justifyContent="space-between" alignItems="center">
              <Text variant="heading3" color="black100" weight="bold">
                Etalase Produk
              </Text>
              <Flex gap="12px">
                <Box width="184px">
                  <Button
                    variant="tertiary"
                    isBlock
                    disabled={isError || isLoadingProductShowcase}
                    size="large"
                    onClick={handleOpenPreview}
                  >
                    Preview
                  </Button>
                </Box>
                <Box width="184px">
                  <Button
                    isBlock
                    variant="primary"
                    disabled={isError || isLoadingProductShowcase}
                    size="large"
                    preffix={
                      <Icon name="Plus-outline" size={20} color="white" />
                    }
                    onClick={handleAdd}
                  >
                    Tambah Etalase
                  </Button>
                </Box>
              </Flex>
            </Flex>
          </Box>

          <Box background="white" padding="24px">
            <Flex flexDirection="column" gap="24px">
              {(!isError || isLoadingProductShowcase) && (
                <SearchInput
                  searchValue={searchValue}
                  setSearchValue={setSearchValue}
                />
              )}

              {isLoadingProductShowcase ? (
                <ListSkeletonShowcase />
              ) : isError ? (
                <FailLoadData onClick={refetchProductShowcase} />
              ) : isEmpty(dataShowcase) ? (
                <EmptyShowcase
                  title="Belum ada etalase"
                  description="Tambahkan etalase untuk mengelompokkan barangmu"
                  shortDesc="agar lebih mudah ditemukan oleh pembeli."
                  handleAdd={handleAdd}
                />
              ) : (
                <>
                  <Flex alignItems="center" position="sticky">
                    <Flex flexBasis="50%">
                      <Text variant="subtitle" weight="bold">
                        Nama Etalase
                      </Text>
                    </Flex>
                    <Flex justifyContent="start" flexBasis="30%">
                      <Text variant="subtitle" weight="bold">
                        Jumlah Produk
                      </Text>
                    </Flex>
                    <Flex justifyContent="start" flexBasis="20%">
                      <Tooltip
                        placement="bottom"
                        label={
                          <Box textAlign="center">
                            <Text variant="small" color="black75">
                              Atur urutan posisi <br /> etalase produk
                            </Text>
                          </Box>
                        }
                      >
                        <Flex
                          justifyContent="center"
                          alignItems="center"
                          borderRadius="100%"
                          width="20px"
                          height="20px"
                          marginLeft="50px"
                          backgroundColor="black60"
                        >
                          <Text color="white">i</Text>
                        </Flex>
                      </Tooltip>
                    </Flex>
                  </Flex>
                  <DragDropContext onDragEnd={handleOnDragEnd}>
                    <Droppable droppableId="showcases">
                      {(provided) => (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          {dataShowcase?.map((list, index) => {
                            const {
                              tag_image_url: image,
                              tag: { tag_name: name },
                              total_products: stock,
                              tag_store_id: tagStoreId,
                            } = list;

                            return (
                              <Draggable
                                key={list.tag_order}
                                draggableId={String(list.tag_order)}
                                index={index}
                              >
                                {(provided) => (
                                  <Box
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    <Flex
                                      alignItems="center"
                                      key={`List-${index}`}
                                      justifyContent="space-between"
                                    >
                                      <Flex
                                        flexBasis="50%"
                                        gap="24px"
                                        alignItems="center"
                                        border="0"
                                        borderTop="1px"
                                        borderStyle="solid"
                                        borderColor="black10"
                                        height="104px"
                                      >
                                        <Box
                                          as="img"
                                          alt="img-product"
                                          src={image}
                                          width="56px"
                                          height="56px"
                                          borderRadius="8px"
                                          backgroundSize="cover"
                                          onError={(e) => {
                                            e.target.onerror = null;
                                            e.target.src =
                                              "/assets/images/empty-product.svg";
                                          }}
                                        />
                                        <Flex flexDirection="column" gap="4px">
                                          <Text weight="bold">{name}</Text>
                                        </Flex>
                                      </Flex>

                                      <Flex
                                        flexBasis="30%"
                                        justifyContent="start"
                                        alignItems="center"
                                        border="0"
                                        borderTop="1px"
                                        borderStyle="solid"
                                        borderColor="black10"
                                        height="104px"
                                      >
                                        <Text>{stock}</Text>
                                      </Flex>

                                      <Flex
                                        flexBasis="20%"
                                        gap="12px"
                                        justifyContent="end"
                                        alignItems="center"
                                        border="0"
                                        borderTop="1px"
                                        borderStyle="solid"
                                        borderColor="black10"
                                        height="104px"
                                      >
                                        <Flex
                                          width="40px"
                                          height="40px"
                                          justifyContent="center"
                                          alignItems="center"
                                          border="1px"
                                          borderColor="black10"
                                          borderStyle="solid"
                                          borderRadius="8px"
                                        >
                                          <Icon
                                            name="Drag-outline"
                                            color="black50"
                                          />
                                        </Flex>
                                        <Dropdown
                                          onClick={() =>
                                            handleDropdown(tagStoreId, name)
                                          }
                                        >
                                          <Dropdown.Toggle>
                                            <Flex
                                              width="92px"
                                              height="40px"
                                              alignItems="center"
                                              justifyContent="space-between"
                                              border="1px"
                                              borderColor="black10"
                                              borderRadius={8}
                                              borderStyle="solid"
                                              px="12px"
                                              marginRight="4px"
                                              cursor="pointer"
                                            >
                                              <Text color="black75">Atur</Text>
                                              <Icon
                                                name="Down-outline"
                                                color="black50"
                                                size={20}
                                              />
                                            </Flex>
                                          </Dropdown.Toggle>
                                          <StyledDropdownContent>
                                            <Flex
                                              flexDirection="column"
                                              border="1px"
                                              borderStyle="solid"
                                              borderColor="black10"
                                              borderRadius="8px"
                                              padding="8px"
                                              gap="8px"
                                            >
                                              <Box
                                                cursor="pointer"
                                                onClick={handleOpenSet}
                                              >
                                                <Text color="black75">
                                                  Atur Etalase
                                                </Text>
                                              </Box>
                                              <Box
                                                cursor="pointer"
                                                onClick={handleOpenUploadImage}
                                              >
                                                <Text color="black75">
                                                  Pilih Gambar Etalase
                                                </Text>
                                              </Box>
                                              <Box
                                                cursor="pointer"
                                                onClick={handleDelete}
                                              >
                                                <Text color="black75">
                                                  Hapus Etalase
                                                </Text>
                                              </Box>
                                            </Flex>
                                          </StyledDropdownContent>
                                        </Dropdown>
                                      </Flex>
                                    </Flex>
                                  </Box>
                                )}
                              </Draggable>
                            );
                          })}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </>
              )}
            </Flex>
          </Box>

          <PreviewShowcase
            previewDisclosure={previewDisclosure}
            dataProductShowcase={dataShowcase}
          />

          <UploadModalShowcase
            uploadDisclosure={uploadDisclosure}
            tempTagId={tempTagId}
            refetchProductShowcase={refetchProductShowcase}
          />

          <Dialog
            isVisible={deleteModal.isOpen}
            onClose={deleteModal.onClose}
            verticalCentered
            width="300px"
            minHeight="100px"
          >
            <Flex flexDirection="column" width="100%" gap="24px">
              <Flex flexDirection="column" gap="8px">
                <Text variant="heading3" weight="bold">
                  Hapus Etalase
                </Text>
                <Text>Etalase yang kamu pilih akan dihapus. Lanjut hapus?</Text>
              </Flex>
              <Flex justifyContent="space-between" gap="16px">
                <Button
                  isBlock
                  variant="tertiary"
                  onClick={deleteModal.onClose}
                >
                  Tidak, Batal
                </Button>
                <Button
                  isBlock
                  onClick={handleOkDelete}
                  disabled={isLoadingDeleteShowcase}
                >
                  Ya, Hapus
                </Button>
              </Flex>
            </Flex>
          </Dialog>
        </Flex>
      )}
    </>
  );
};

export default DashboardShowcase;
