import ENDPOINTS from "constants/endpoints";
import { useInfiniteQuery, useMutation } from "react-query";
import client from "../client";
import { useQuery } from "react-query";

const getNotificationFetcher = async (params) => {
  const { data } = await client.get(ENDPOINTS.notification_list, {
    params,
  });

  return {
    data: data.data,
    nextPage: params.page,
    total: data.total,
  };
};

const updateNotificationFetcher = async (body) => {
  const { data } = await client.post(ENDPOINTS.notification_update_read, body);

  return { ...data, ...body };
};

const getNotificationCountFetcher = async (params) => {
  const { data } = await client.get(ENDPOINTS.notification_list, {
    params,
  });

  const totalByStatus = Object.values(data?.total_by_status).reduce(
    (accumulator, currentValue) => accumulator + currentValue,
    0
  );

  return totalByStatus;
};

export const useGetNotificationCount = (extraParams) =>
  useMutation((body) => getNotificationCountFetcher(body), { ...extraParams });

export const useGetNotification = (params, extraParams) => {
  return useInfiniteQuery(
    [`getNotification${params.notification_type_id === "1,2" ? "Update" : ""}`],
    ({ pageParam = 1 }) =>
      getNotificationFetcher({ ...params, page: pageParam }),
    {
      getNextPageParam: (lastPage) => {
        if (lastPage.data.length < 10) return undefined;

        return lastPage.nextPage + 1;
      },
      ...extraParams,
    }
  );
};

export const useUpdateNotification = (extraParams) =>
  useMutation((body) => updateNotificationFetcher(body), { ...extraParams });

const getOrderStatus = async (params) => {
  const { data } = await client.get(ENDPOINTS.order_status, {
    params,
  });

  return data.data;
};

export const useGetOrderStatus = (extraParams) =>
  useMutation((body) => getOrderStatus(body), { ...extraParams });

const getNotificationListFetcher = async (params) => {
  const { data } = await client.get(ENDPOINTS.notification_list, {
    params,
  });

  return data.data;
};

export const useGetNotificationList = (extraParams) =>
  useMutation((body) => getNotificationListFetcher(body), { ...extraParams });

const getNotificationCountingFetcher = async (params) => {
  const { data } = await client.get(ENDPOINTS.notification_list, {
    params,
  });

  return data;
};

export const useGetNotificationCounting = (extraParams) => {
  return useQuery(["notifCount"], getNotificationCountingFetcher, {
    ...extraParams,
  });
};
