export const formatterNumber = (value) =>
  new Intl.NumberFormat("id", {
    style: "decimal",
  }).format(value);

export const sanitizeNumber = (value) => {
  if (!value) return undefined;

  return value.replace(/\D/g, "");
};
