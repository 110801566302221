import { Flex, Skeleton, Divider, Each, range } from "renos-ui";

const LoadingMyOffer = () => {
  return (
    <Flex flexDirection="column" gap="24px" marginTop={24}>
      <Each
        of={range(3)}
        render={() => (
          <Flex
            flexDirection="column"
            gap="24px"
            backgroundColor="white"
            borderRadius={16}
            py={16}
            px={24}
            boxShadow="0px 4px 4px rgba(20, 20, 32, 0.05)"
          >
            <Flex
              gap="8px"
              alignItems="center"
              paddingBottom={24}
              borderBottomWidth={1}
              borderBottomStyle="solid"
              borderBottomColor="black10"
            >
              <Skeleton width="80px" height="16px" />
              <Skeleton width="120px" height="16px" />
              <Skeleton width="75px" height="16px" />
            </Flex>
            <Flex gap="8px">
              <Skeleton width="24px" height="18px" />
              <Skeleton width="200px" height="18px" />
            </Flex>
            <Flex justifyContent="space-between">
              <Flex gap="16px">
                <Skeleton.Image width="90px" />

                <Flex flexDirection="column" gap="4px">
                  <Skeleton width="400px" height="16px" />
                  <Skeleton width="250px" height="16px" />
                  <Skeleton width="100px" height="16px" />
                </Flex>
              </Flex>
              <Flex flexDirection="column" textAlign="right" gap="4px">
                <Skeleton width="100px" />
                <Skeleton width="100px" />
              </Flex>
            </Flex>
            <Divider />
            <Flex justifyContent="space-between">
              <Skeleton width="100px" />
              <Skeleton width="160px" height="40px" />
            </Flex>
          </Flex>
        )}
      />
    </Flex>
  );
};

export default LoadingMyOffer;
