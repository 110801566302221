import { useHistory } from "react-router-dom";
import { Box, Flex, Icon, Input, SelectInput, Tabs, Text } from "renos-ui";

import useQueryParams from "hooks/useQueryParams";
import desktopUrl from "constants/desktopUrl";
import { useEffect, useState } from "react";
import { setParamQuery } from "helpers";

const TopBar = ({ tabs }) => {
  const { push } = useHistory();

  const tabItems = Object.values(tabs).map((tab) => ({
    ...tab,
    width: "fit-content",
    children: () => <></>,
  }));

  const query = useQueryParams();
  const activeTab = query.get("tab") || "incoming-request";

  const [search, setSearch] = useState("");
  const [selectedSortBy, setSelectedSortBy] = useState(
    tabs[activeTab]?.sortOptions[0]
  );

  const handleChangeTabActive = (value) => {
    push(`${desktopUrl.BIDDING}?tab=${value}`);
  };

  const handleSelectChange = (option) => {
    setSelectedSortBy(option);

    const queryParams = Object.fromEntries(query);

    if (activeTab === "my-offer") {
      queryParams.status = option.value;
    } else {
      queryParams.order = option.value;
    }

    push({
      pathname: `${desktopUrl.BIDDING}`,
      search: setParamQuery(queryParams),
    });
  };

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  const onKeyDownSearch = (event) => {
    if (event.key !== "Enter") return;

    const queryParams = Object.fromEntries(query);
    const searchParams = new URLSearchParams({
      ...queryParams,
      q: search,
    }).toString();

    push({
      pathname: desktopUrl.BIDDING,
      search: searchParams,
    });
  };

  useEffect(() => {
    setSelectedSortBy(tabs[activeTab]?.sortOptions[0]);
  }, [tabs, activeTab]);

  useEffect(() => {
    if (search) {
      setSearch("");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  return (
    <Flex
      flexDirection="column"
      gap="24px"
      backgroundColor="white"
      borderRadius={16}
      padding={24}
      boxShadow="0px 4px 4px rgba(20, 20, 32, 0.05)"
    >
      <Text variant="heading3" weight="bold">
        Bidding
      </Text>
      <Flex flexDirection="column" gap="16px">
        <Tabs
          onChange={handleChangeTabActive}
          defaultActiveKey={activeTab}
          placement="bottom"
          items={tabItems}
        />
        <Flex gap="16px">
          <Input
            size="small"
            placeholder="Cari permintaan produk"
            prefix={<Icon name="Search-outline" size={20} />}
            onChange={handleSearchChange}
            onKeyDown={onKeyDownSearch}
            value={search}
          />
          <Box width={288}>
            <SelectInput
              options={tabs[activeTab]?.sortOptions}
              size="small"
              onChange={(option) => handleSelectChange(option)}
              value={selectedSortBy}
            />
          </Box>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default TopBar;
