import { useState, useContext, useEffect } from "react";
import {
  Box,
  Button,
  Divider,
  Flex,
  Icon,
  Text,
  useToast,
  isEmpty,
} from "renos-ui";
import {
  useGetStoreOpen,
  useUpdateOpenStore,
  useGetStoreOpenView,
} from "services/settings/status";
import StoreContext from "providers/StoreProvider";
import ScheduleItem from "./ScheduleItem";
import ListOpenStore from "./ListOpenStore";
import { setGTM } from "helpers";

const OpenStore = () => {
  const {
    storeData: {
      data: { id: storeId },
    },
  } = useContext(StoreContext);

  const { refetch } = useGetStoreOpen(
    {
      store_id: storeId,
    },
    {
      onSuccess: (dataGet) => {
        if (!isEmpty(dataGet)) {
          setData(dataGet);
        } else {
          setData([]);
        }
      },
    }
  );

  const [data, setData] = useState([]);
  const toast = useToast();
  const [isEdit, setIsEdit] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const { data: viewData, refetch: refetchViewData } = useGetStoreOpenView({
    store_id: storeId,
  });

  const { mutate: updateOpenStore, isLoading: isSaveLoading } =
    useUpdateOpenStore({
      onError: () => {
        toast({
          label: "Gagal mengubah Jadwal Buka Toko",
          placement: "top",
        });
      },
      onSuccess: () => {
        toast({
          label: "Jadwal operasional berhasil diubah.",
          placement: "top",
        });
        refetchViewData();
      },
    });

  const handleGTM = (label) => {
    setGTM("seller_center_section", {
      event_category: "store_settings | status",
      event_action: "click",
      event_label: label,
    });
  };

  const handleOpenEdit = () => {
    handleGTM("set_open_shop | edit");
    setIsEdit(true);
  };

  const handleSave = (event) => {
    handleGTM("set_open_shop | save_settings");
    event.preventDefault();
    setIsEdit(false);

    const body = {
      data: {
        store_open_details: data,
      },
    };

    updateOpenStore(body);
  };

  const handleReset = () => {
    handleGTM("set_open_shop | reset_settings");
    refetch();
  };

  useEffect(() => {
    let falseCount = 0;

    for (let i = 0; i < data.length; i++) {
      const startTime = Number(data[i].start.split(":")[0]);
      const endTime = Number(data[i].end.split(":")[0]);

      if (endTime <= startTime && data[i].is_active) falseCount++;
    }

    setIsDisabled(falseCount > 0);
  }, [data]);

  return (
    <Flex
      borderStyle="solid"
      borderColor="black10"
      backgroundColor="white"
      borderWidth="1px"
      padding="24px"
      flexDirection="column"
      gap="24px"
      borderRadius="8px"
    >
      <Flex flexDirection="row" gap="8px" width="100%">
        <Flex alignItems="start" width="100%">
          <Box paddingX="8px">
            <Icon color="black50" size={20} name="Time-outline" />
          </Box>
          <Flex flexDirection="column" width="100%">
            <Text weight="bold">Atur buka toko</Text>
            <Flex justifyContent="space-between">
              <Flex flexBasis="70%">
                <Text color="black50">
                  Jadwal hanya digunakan sebagai informasi jam operasional
                  tokomu ke pembeli. Pembeli tetap bisa melakukan pemesenan ke
                  tokomu di luar jam buka toko
                </Text>
              </Flex>
              {!isEdit && (
                <Button
                  size="large"
                  variant="secondary"
                  preffix={
                    <Flex style={{ pointerEvents: "none" }}>
                      <Icon size={20} name="Edit-solid" />
                    </Flex>
                  }
                  onClick={handleOpenEdit}
                >
                  Edit
                </Button>
              )}
            </Flex>
          </Flex>
        </Flex>
      </Flex>

      <Divider />

      {isEdit ? (
        <Flex flexDirection="column" gap="24px">
          <Flex alignItems="center" gap="32px">
            <Box width="160px">
              <Text weight="bold" color="black75">
                Hari
              </Text>
            </Box>
            <Box width="144px">
              <Text weight="bold" color="black75">
                Jam Buka
              </Text>
            </Box>
            <Box width="160px">
              <Text weight="bold" color="black75">
                Jam Tutup
              </Text>
            </Box>
          </Flex>

          {!isEmpty(data) &&
            data?.map((list, index) => {
              return (
                <Flex flexDirection="column">
                  <ScheduleItem
                    list={list}
                    index={index}
                    setData={setData}
                    data={data}
                    setIsDisabled={setIsDisabled}
                  />
                </Flex>
              );
            })}

          <Divider />
          <Flex gap="16px" alignItems="center" justifyContent="end">
            <Button size="large" variant="tertiary" onClick={handleReset}>
              Reset Pengaturan
            </Button>
            <Button
              size="large"
              onClick={handleSave}
              disabled={isSaveLoading || isDisabled}
            >
              Simpan Pengaturan
            </Button>
          </Flex>
        </Flex>
      ) : (
        <Flex flexDirection="column" gap="16px">
          {viewData?.map((list, index) => {
            return (
              list.is_active && (
                <ListOpenStore
                  key={`listOpenStore-${index}`}
                  day={list.day_humane}
                  time={list.time}
                />
              )
            );
          })}
        </Flex>
      )}
    </Flex>
  );
};

export default OpenStore;
