import { Flex, Overlay } from "renos-ui";
// import SearchBox from "./SearchBox";
import NotifBox from "./NotifBox";
import ProfileBox from "./ProfileBox";
import { NavbarProvider } from "providers/NavbarProvider";
import { useState } from "react";
import TimerBox from "./TimerBox";

const DashboardHeader = () => {
  const [overlay, setOverlay] = useState(null);

  return (
    <NavbarProvider value={{ setOverlay, overlay }}>
      <Flex flexDirection="row" justifyContent="space-between" gap="24px">
        {/* <SearchBox placeholder="Cari pesanan atau bantuan" /> */}
        <TimerBox />
        <NotifBox />
        <ProfileBox />
        {overlay && <Overlay />}
      </Flex>
    </NavbarProvider>
  );
};

export default DashboardHeader;
