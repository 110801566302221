import { Flex, Skeleton } from "renos-ui";

const Loading = () => {
  return (
    <Flex alignItems="center">
      <Flex flexDirection="column" alignItems="center" rowGap="8px" px="16px">
        <Skeleton.Avatar size="50px" />
      </Flex>
      <Flex flexDirection="column" gap="6px">
        <Skeleton height="24px" width="100px" />
        <Flex gap="8px">
          <Skeleton height="24px" width="50px" />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Loading;
