import { Flex, Text } from "renos-ui";

const Filter = ({ value, setValue }) => {
  const arrTabs = [
    // "Semua",
    "Penjualan",
    "Update",
  ];

  return (
    <Flex gap="8px" px="16px">
      {arrTabs.map((tab, indexTab) => (
        <Flex
          key={`tabs-${indexTab}`}
          padding="6px 16px"
          borderColor={tab === value ? "blue50" : "black10"}
          borderStyle="solid"
          borderWidth="1px"
          borderRadius="100px"
          backgroundColor={tab === value ? "blue5" : "white"}
          minWidth="fit-content"
          alignItems="center"
          onClick={() => setValue(tab)}
        >
          <Text variant="caption" color={tab === value ? "blue50" : "black75"}>
            {tab}
          </Text>
        </Flex>
      ))}
    </Flex>
  );
};

export default Filter;
