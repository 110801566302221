import React from "react";
import { Button, Flex, Text, Dialog } from "renos-ui";

const DeleteModal = ({ deleteModal, handleOkDelete }) => {
  return (
    <Dialog
      isVisible={deleteModal.isOpen}
      closable
      onClose={deleteModal.onClose}
      verticalCentered
      width="300px"
      minHeight="100px"
    >
      <Flex flexDirection="column" width="100%" gap="24px">
        <Flex flexDirection="column" gap="8px">
          <Text variant="heading3" weight="bold">
            Hapus Catatan
          </Text>
          <Text>Catatan yang dipilih akan dihapus.</Text>
        </Flex>
        <Flex justifyContent="space-between" gap="16px">
          <Button isBlock variant="tertiary" onClick={deleteModal.onClose}>
            Tidak, Batal
          </Button>
          <Button isBlock onClick={handleOkDelete}>
            Ya, Hapus
          </Button>
        </Flex>
      </Flex>
    </Dialog>
  );
};

export default DeleteModal;
