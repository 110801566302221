import FilterRatingContext from "providers/FilterRatingReview";
import { useContext, useState } from "react";
import {
  StyledFlexInputRounded,
  StyledFlexInputSearch,
  StyledInput,
} from "../styled";
import {
  Divider,
  Drawer,
  Flex,
  Icon,
  Input,
  Text,
  useDebounce,
} from "renos-ui";

const Filter = () => {
  const { filter, setFilter, itemsFilter } = useContext(FilterRatingContext);
  const [selectedFilter, setSelectedFilter] = useState(itemsFilter[0]);
  const [isVisibleDrawer, setIsVisibleDrawer] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  useDebounce(
    () =>
      setFilter({
        ...filter,
        product_name_contains: searchValue,
        isFiltered: true,
        page: 1,
      }),
    [searchValue],
    500
  );

  const handleChange = (event) => {
    setSelectedFilter(event);
    setFilter((prev) => ({
      ...prev,
      order_by: event.params.order_by,
      ordering: event.params.ordering,
      isFiltered: true,
      page: 1,
    }));
    setIsVisibleDrawer(false);
  };

  return (
    <>
      <Flex
        gap="8px"
        marginX="16px"
        flexDirection="row"
        justifyContent="space-between"
      >
        <StyledFlexInputSearch width="70%" borderRadius="100px">
          <Input
            size="small"
            placeholder="Cari nama produk"
            prefix={<Icon name="Search-outline" size={17} color="black50" />}
            onChange={(event) => setSearchValue(event.target.value)}
          />
        </StyledFlexInputSearch>
        <StyledFlexInputRounded
          width="30%"
          borderRadius="100px"
          onClick={() => setIsVisibleDrawer(true)}
        >
          <StyledInput
            readOnly
            size="small"
            placeholder="Urutkan"
            sufix={<Icon name="Down-outline" size={17} color="black50" />}
          />
        </StyledFlexInputRounded>
      </Flex>
      <Drawer
        isVisible={isVisibleDrawer}
        closable={true}
        onClose={() => setIsVisibleDrawer(false)}
        placement="bottom"
      >
        <Flex pt="16px" flexDirection="column">
          {itemsFilter.map((filter, index) => (
            <Flex
              pb="16px"
              gap="16px"
              flexDirection="column"
              key={`filter-${filter.value}`}
              onClick={() => handleChange(filter)}
            >
              <Flex flexDirection="row" justifyContent="space-between">
                <Text variant="body">{filter.label}</Text>
                {selectedFilter.value === filter.value && (
                  <Icon name="Checkmark-outline" size={20} />
                )}
              </Flex>
              {index !== itemsFilter.length - 1 && <Divider />}
            </Flex>
          ))}
        </Flex>
      </Drawer>
    </>
  );
};

export default Filter;
