import dayjs from "dayjs";
import { currencyFormatter } from "helpers";
import { useMemo } from "react";
import { Flex, Icon, Text, cloneDeep } from "renos-ui";

const FilterDetail = ({ value, setValue, defaultValue }) => {
  const handleDelete = (data) => {
    const tempValue = {
      ...cloneDeep(value),
      dateRange: value.dateRange,
    };

    switch (data.type) {
      case "category":
        tempValue.category = tempValue.category.filter(
          (filterCategory) => filterCategory.value !== data.value
        );
        break;

      case "courier":
        tempValue.courier = tempValue.courier.filter(
          (filterCourier) => filterCourier.value !== data.value
        );
        break;

      case "condition":
        tempValue.condition = tempValue.condition.filter(
          (filterCondition) => filterCondition.value !== data.value
        );
        break;

      case "dateRange":
        tempValue.dateRange = { start: "", end: "" };
        break;

      case "priceRange":
        tempValue.priceRange = { start: "", end: "" };
        break;

      case "stock":
        tempValue.stock = { condition: "", stock: "" };
        break;

      default:
        break;
    }

    setValue(tempValue);
  };

  const handleReset = () => {
    setValue(defaultValue);
  };

  const dataSource = useMemo(() => {
    const tempSource = [];

    if (value.category.length > 0) {
      // eslint-disable-next-line
      const mappingCategory = value.category.map((category) => {
        tempSource.push({ ...category, type: "category" });
      });
    }

    if (value.condition.length > 0) {
      // eslint-disable-next-line
      const mappingCondition = value.condition.map((condition) => {
        tempSource.push({ ...condition, type: "condition" });
      });
    }

    if (value.courier.length > 0) {
      // eslint-disable-next-line
      const mappingCourier = value.courier.map((courier) => {
        tempSource.push({ ...courier, type: "courier" });
      });
    }

    if (value.stock.condition?.value) {
      const { stock, condition } = value.stock;
      tempSource.push({
        value: condition.value,
        label: `Stok ${condition.label.toLowerCase()} ${stock}`,
        type: "stock",
      });
    }

    if (value.priceRange?.start && value.priceRange?.end) {
      const { start, end } = value.priceRange;

      tempSource.push({
        value: value.priceRange,
        label: `Harga ${currencyFormatter(start)} sampai ${currencyFormatter(
          end
        )}`,
        type: "priceRange",
      });
    }

    if (value.dateRange?.start && value.dateRange?.end) {
      const { start, end } = value.dateRange;

      tempSource.push({
        value: value.dateRange,
        label:
          start.getTime() === end.getTime()
            ? `Tanggal ${dayjs(start).format("DD MMM YYYY")}`
            : `Tanggal ${dayjs(start).format("DD MMM YYYY")} sampai ${dayjs(
                end
              ).format("DD MMM YYYY")}`,
        type: "dateRange",
      });
    }

    return tempSource;
  }, [value]);

  return (
    <Flex gap="24px" alignItems="center">
      <Text variant="caption" color="black50" weight="bold">
        Filter Produk
      </Text>
      <Flex width="calc(100% - 243px)" overflow="auto" gap="8px">
        {dataSource.map((filter, indexFilter) => (
          <Flex
            key={`filter-${indexFilter}`}
            padding="6px 12px"
            gap="8px"
            borderWidth="1px"
            borderColor="black10"
            borderStyle="solid"
            borderRadius="12px"
            style={{ whiteSpace: "nowrap" }}
            alignItems="center"
          >
            <Text variant="caption" color="black75">
              {filter.label}
            </Text>
            <Icon
              onClick={() => handleDelete(filter)}
              name="Close-solid"
              color="black50"
              cursor="pointer"
              size={14}
            />
          </Flex>
        ))}
      </Flex>
      <Flex cursor="pointer" onClick={handleReset}>
        <Text variant="caption" color="blue50" weight="bold">
          Hapus Semua Filter
        </Text>
      </Flex>
    </Flex>
  );
};

export default FilterDetail;
