import { Flex, Text, Button, Box } from "renos-ui";

const EmptyPromo = ({ handleAdd }) => {
  return (
    <Flex
      gap="8px"
      justifyContent="center"
      alignItems="center"
      minHeight="648px"
      borderRadius="16px"
      backgroundColor="white"
      boxShadow="0px 4px 4px rgba(20, 20, 32, 0.05)"
    >
      <Flex flexDirection="column" alignItems="center" gap="24px">
        <Box
          as="img"
          src="/assets/images/promo-management/no-product.svg"
          width="200px"
          height="200px"
          alt="Empty Data"
        />
        <Flex flexDirection="column" alignItems="center" gap="4px">
          <Text variant="heading3" weight="bold" color="black100">
            Belum Ada Daftar Produk Diskon
          </Text>
          <Flex width="330px" textAlign="center">
            <Text color="black75">
              Yuk, mulai tambahkan produk untuk bisa mengatur diskon pada
              produkmu.
            </Text>
          </Flex>
        </Flex>
        <Button size="medium" onClick={handleAdd}>
          Tambah Produk
        </Button>
      </Flex>
    </Flex>
  );
};

export default EmptyPromo;
