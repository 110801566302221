import { useEffect, useState, useContext, useMemo } from "react";
import StoreContext from "providers/StoreProvider";
import { Box, Flex, Input, Text, useToast, Spinner } from "renos-ui";
import { useSaveNotes } from "services/settings/note";
import { setGTM } from "helpers";
import { useHistory } from "react-router-dom";
import MobileLayout from "layouts/MobileLayout";
import mobileUrl from "constants/mobileUrl";
import { StyledButton, StyledTextArea } from "./../styled";
import CustomHeader from "./CustomHeader";

const Note = ({ location }) => {
  const { push } = useHistory();
  const {
    storeData: {
      data: { id: storeId },
    },
  } = useContext(StoreContext);

  const toast = useToast();
  const [mode, setMode] = useState("");
  const [value, setValue] = useState({
    title: "",
    note: "",
  });

  useEffect(() => {
    if (location.state !== undefined) {
      setValue(location.state.data);
      setMode(location.state.mode);
    }
    // eslint-disable-next-line
  }, []);

  const { mutate: mutateSaveNotes, isLoading: isSaveLoading } = useSaveNotes({
    onError: () => {
      toast({
        label: `Catatan gagal ${mode === "add" ? "ditambahkan" : "diubah"}`,
        placement: "bottom",
      });
    },
    onSuccess: () => {
      toast({
        label: `Catatan berhasil ${mode === "add" ? "ditambahkan" : "diubah"}`,
        placement: "bottom",
      });
      setTimeout(() => {
        push(mobileUrl.STORE_NOTES);
      }, 1000);
    },
  });

  const handleChangeValue = (event) => {
    setValue((prevState) => {
      return {
        ...prevState,
        [event.target.name]: event.target.value,
      };
    });
  };

  const handleSave = () => {
    if (mode === "edit") {
      setGTM("seller_center_section", {
        event_category: "store_settings | notes",
        event_action: "click",
        event_label: "change",
      });
    }
    const body = {
      data: {
        id: value.id,
        store_id: storeId,
        title: value.title,
        note: value.note,
        active: true,
      },
    };
    mutateSaveNotes(body);
  };

  const isDisabled = useMemo(
    () =>
      value?.note?.length === 0 || value?.title?.length === 0 || isSaveLoading,
    [isSaveLoading, value]
  );

  return (
    <MobileLayout customHeader={<CustomHeader mode={mode} />}>
      <Flex flexDirection="column" gap="16px" width="100%">
        <Flex flexDirection="column" gap="9px" p="16px">
          <Flex flexDirection="column" gap="20px">
            <Flex flexDirection="column" gap="16px">
              <Text variant="caption" color="black50" weight="bold">
                Judul Catatan
              </Text>
              <Box width="100%">
                <Input
                  name="title"
                  placeholder="Masukan judul catatan"
                  value={value?.title}
                  onChange={(event) => handleChangeValue(event)}
                />
              </Box>
            </Flex>
            <Flex flexDirection="column" gap="16px">
              <Text variant="caption" color="black50" weight="bold">
                Catatan
              </Text>
              <Box width="100%">
                <StyledTextArea
                  name="note"
                  placeholder="Tulis detail catatan..."
                  value={value?.note}
                  onChange={(event) => handleChangeValue(event)}
                />
              </Box>
            </Flex>
          </Flex>
        </Flex>

        <Flex
          bottom="0"
          width="100%"
          position="fixed"
          justifyContent="center"
          alignItems="center"
          boxShadow="0px 0px 8px 0px rgba(20, 20, 32, 0.05);"
        >
          <StyledButton
            isBlock
            disabled={isDisabled}
            onClick={handleSave}
            preffix={isSaveLoading && <Spinner size="16px" color="blue10" />}
          >
            Simpan
          </StyledButton>
        </Flex>
      </Flex>
    </MobileLayout>
  );
};

export default Note;
