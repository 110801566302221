import MobileLayout from "layouts/MobileLayout";
import React from "react";
import { useHistory } from "react-router-dom";
import {
  Box,
  Flex,
  Text,
  Input,
  useToast,
  useUpload,
  Upload,
  colors,
  SelectInput,
} from "renos-ui";
import { FormProvider } from "react-hook-form";
import { useState, useEffect, useMemo } from "react";
import { blobToBase64, imageTypeCheckingDocument, setGTM } from "helpers";
import { modalText, IMAGE_FILE_TYPE } from "./../constant";
import mobileUrl from "constants/mobileUrl";
import { goToUrl, imgCompressor } from "helpers";
import { StyledButton } from "./../styled";
import Warning from "./components/CardWarning";

import {
  useGetDocument,
  useSaveNumber,
  useGetBank,
} from "services/settings/document";
import CustomHeader from "./components/CustomHeader";

const DocumentBankAdd = ({ location }) => {
  const detailDocument = location.state.data;
  const mode = location.state.mode;

  // validation
  const selectedCategory = detailDocument.id;
  const [valueBank, setValueBank] = useState({});

  const [isErrorBank, setIsErrorBank] = useState({
    bank_account_beneficiary_name: false,
    bank_account_number: false,
    bank_branch: false,
    bank_city: false,
    bank_code: false,
    bank_name: false,
  });

  const { push } = useHistory();
  const toast = useToast();
  const [selectedItem, setSelectedItem] = useState({});
  const [images, setImages] = useState({
    ktp: { raw: null, value: "", isImageChange: false },
    npwp: { raw: null, value: "", isImageChange: false },
    siup: { raw: null, value: "", isImageChange: false },
    bank_book: { raw: null, value: "", isImageChange: false },
  });

  useEffect(() => {
    setSelectedItem({
      ...modalText[detailDocument.id],
      title: modalText[detailDocument.id].title,
      imgValue: images[detailDocument.id].value,
      category: detailDocument.id,
      uploadPhotoText: modalText[detailDocument.id].uploadPhotoText,
      inputPlacholder: modalText[detailDocument.id].inputPlacholder,
      inputName: modalText[detailDocument.id].inputName,
      inputLabel: modalText[detailDocument.id].inputLabel,
    });
    // eslint-disable-next-line
  }, []);

  const { register } = useUpload({
    fileType: "image",
    onRequest: async (file) => {
      const deepTypeChecking = await imageTypeCheckingDocument(file);

      if (deepTypeChecking) {
        const compressed =
          file.size > 200000
            ? await imgCompressor(file, {
                maxSizeMB: 0.1,
                maxWidthOrHeight: 1920,
                useWebWorker: true,
              })
            : file;

        const result = await blobToBase64(compressed);
        toast({
          label: "File berhasil diupload!",
          placement: "bottom",
        });

        const selectedCategory = selectedItem?.category;

        if (result.length === 0) {
          setImages({
            ...images,
            [selectedCategory]: { raw: null, value: "" },
          });

          return;
        }
        setImages((prev) => ({
          ...prev,
          [selectedCategory]: {
            raw: result,
            value: compressed,
            isImageChange: true,
          },
        }));
      } else {
        toast({
          label:
            "Gagal upload file. Harap cek kembali jenis file yang akan kamu upload.",
          placement: "bottom",
          backgroundColor: "red50",
          clickLabel: "Oke",
        });
      }
    },
    validator: (file) => {
      if (!IMAGE_FILE_TYPE.includes(file.type)) {
        toast({
          label:
            "Gagal upload file. Harap cek kembali jenis file yang akan kamu upload.",
          placement: "bottom",
          backgroundColor: "red50",
          clickLabel: "Oke",
        });

        return {
          message: "Format gambar tidak sesuai",
        };
      }

      return null;
    },
  });

  // handleValue
  const handleChangeValueBank = (e) => {
    if (e.target.name === "bank_account_number") {
      const maxLengthBank = 16;
      const pattern = /^[\d+]+$/;
      const inputContact = e.target.value;
      const regexProcess = inputContact.match(pattern);
      // eslint-disable-next-line
      const formatNumber = !inputContact
        ? ""
        : regexProcess
        ? regexProcess[0]
        : valueBank.contact_number;

      setValueBank((prev) => ({
        ...prev,
        [e.target.name]: e.target.value
          .replace(/\D/g, "")
          .substring(0, maxLengthBank),
        changed: true,
      }));
    } else {
      setValueBank((prevState) => {
        return {
          ...prevState,
          [e.target.name]: e.target.value,
        };
      });
    }

    setIsErrorBank({
      ...isErrorBank,
      [e.target.name]: false,
    });
  };

  // handle error dan save

  const {
    isLoading: isLoadingDocument,
    refetch,
    data: dataGetDocument,
  } = useGetDocument({
    onError: () => {
      toast({
        label: "Gagal mendapatkan document.",
        placement: "bottom",
      });
    },
  });

  useEffect(() => {
    if (!isLoadingDocument) {
      setValueBank({
        bank_book: dataGetDocument?.data?.bank_book,
        bank_account_beneficiary_name:
          dataGetDocument?.data?.bank_account_beneficiary_name,
        bank_account_number: dataGetDocument?.data?.bank_account_number,
        bank_branch: dataGetDocument?.data?.bank_branch,
        bank_city: dataGetDocument?.data?.bank_city,
        bank_code: dataGetDocument?.data?.bank_code,
        bank_name: dataGetDocument?.data?.bank_name,
      });
    }
    // eslint-disable-next-line
  }, [isLoadingDocument]);

  const { mutate: mutateSaveNumber, isLoading: isSaveLoading } = useSaveNumber({
    onSuccess: (data) => {
      const { message, frontend_message } = data.data;
      refetch();

      if (message === "Conflict") {
        toast({
          label: frontend_message || message,
          placement: "bottom",
          backgroundColor: "red50",
        });

        setValueBank({});
      } else {
        toast({
          label: frontend_message || message,
          placement: "bottom",
        });
        setTimeout(() => {
          push(mobileUrl.STORE_DOCUMENT);
        }, 1000);
      }

      setImages({ ...images, [selectedCategory]: { raw: null, value: "" } });
    },
    onError: (data) => {
      const {
        response: {
          data: { frontend_message, message },
        },
      } = data;
      toast({
        label: frontend_message || message,
        placement: "bottom",
        backgroundColor: "red50",
      });
      setImages({ ...images, [selectedCategory]: { raw: null, value: "" } });
    },
  });

  const { data: listBank } = useGetBank();
  const emptyOptions = {
    label: "",
    value: "",
  };

  const handleErrorBank = () => {
    const tempError = isErrorBank;
    let countError = 0;

    for (var key in valueBank) {
      if (
        !valueBank[key] &&
        key !== "bank_book" &&
        key !== "bank_city" &&
        key !== "bank_branch"
      ) {
        tempError[key] = true;
        countError++;
      }
    }
    setIsErrorBank({ ...tempError });

    if (countError !== 0) {
      toast({
        label: "Mohon lengkapi semua data",
        placement: "bottom",
        backgroundColor: "red50",
      });

      return true;
    }

    return false;
  };

  const handleGTM = (label) => {
    setGTM("seller_center_section", {
      event_category: "store_settings | documents",
      event_action: "click",
      event_label: label,
    });
  };

  const handleSaveBank = (event) => {
    event.preventDefault();
    const isHasError = handleErrorBank();
    if (isHasError) return false;

    const fileUpload = images[selectedCategory].raw
      .replace("data:", "")
      .replace(/^.+,/, "");
    const payload = {
      data: {
        image_base64: fileUpload,
        bank_account_beneficiary_name: valueBank.bank_account_beneficiary_name,
        bank_account_number: valueBank.bank_account_number,
        bank_branch: valueBank.bank_branch,
        bank_city: valueBank.bank_city,
        bank_code: valueBank.bank_code,
        bank_name: valueBank.bank_name,
      },
    };
    handleGTM("edit_company_information");
    mutateSaveNumber(payload);
  };

  const isAccumulateLoading = useMemo(
    () =>
      isSaveLoading ||
      valueBank.bank_name === "" ||
      valueBank.bank_name === null ||
      valueBank.bank_account_number === "" ||
      valueBank.bank_account_number === null ||
      valueBank.bank_account_beneficiary_name === "" ||
      valueBank.bank_account_beneficiary_name === null ||
      images[selectedItem.category]?.raw === null ||
      mode === "showFile",

    [
      isSaveLoading,
      valueBank.bank_name,
      valueBank.bank_account_number,
      valueBank.bank_account_beneficiary_name,
      selectedItem,
      images,
      mode,
    ]
  );

  const isDisabledForm = useMemo(() => mode === "showFile", [mode]);

  return (
    <MobileLayout customHeader={<CustomHeader title={selectedItem.title} />}>
      <FormProvider>
        <Flex
          backgroundColor="black5"
          flexDirection="column"
          rowGap="8px"
          height="calc(100% - 80px)"
          overflow="auto"
          width="100%"
        >
          <Box backgroundColor="white">
            {isDisabledForm && (
              <Warning
                onClick={() =>
                  goToUrl("mailto:sellersupport@renos.id", "_self")
                }
              />
            )}

            <Flex flexDirection="column" rowGap="16px" p="16px">
              <Flex
                borderColor="black10"
                borderStyle="solid"
                borderWidth="1px"
                borderRadius="8px"
                padding="8px"
                alignItems="center"
                justifyContent="space-between"
                border={`1px dashed ${colors.black50}`}
                height="124px"
                gap="8px"
              >
                {images[selectedCategory]?.value !== "" ? (
                  <Flex columnGap="14px" alignItems="center">
                    <Box
                      alt="document-image"
                      as="img"
                      src={images[selectedItem.category]?.raw}
                      width="64px"
                      height="64px"
                    />
                    <Flex flexDirection="column">
                      <Text weight="bold" variant="caption">
                        Upload File
                      </Text>
                      <Text color="black50" variant="caption" lineClamp={2}>
                        Format file .JPG, .JPEG, .PNG atau .PDF.
                      </Text>
                      <Upload
                        {...register}
                        disabled={isDisabledForm}
                        accept=".jpg, .jpeg, .png, .pdf"
                      >
                        <Flex>
                          <Text
                            weight="bold"
                            variant="caption"
                            color="blue50"
                            width="160px"
                          >
                            {location.state.mode === "showFile" ||
                            images[selectedItem.category]?.isImageChange ===
                              true
                              ? "Ubah File"
                              : "Pilih File"}
                          </Text>
                        </Flex>
                      </Upload>
                    </Flex>
                  </Flex>
                ) : (
                  <Flex columnGap="14px" alignItems="center">
                    <Box
                      alt="no-image"
                      as="img"
                      src="/assets/images/setting/document/no-image.png"
                      size="56px"
                    />
                    <Flex flexDirection="column">
                      <Text weight="bold" variant="caption">
                        Upload File
                      </Text>
                      <Text color="black50" variant="caption" lineClamp={2}>
                        Format file .JPG, .JPEG, .PNG atau .PDF.
                      </Text>
                      <Upload
                        {...register}
                        disabled={isDisabledForm}
                        accept=".jpg, .jpeg, .png, .pdf"
                      >
                        <Flex>
                          <Text
                            weight="bold"
                            variant="caption"
                            color="blue50"
                            width="160px"
                          >
                            {location.state.mode === "showFile" ||
                            images[selectedItem.category]?.isImageChange ===
                              true
                              ? "Ubah File"
                              : "Pilih File"}
                          </Text>
                        </Flex>
                      </Upload>
                    </Flex>
                  </Flex>
                )}
              </Flex>
              <Flex flexDirection="column" gap="16px">
                <Text variant="caption" color="black50" weight="bold">
                  Kode Bank
                </Text>
                <SelectInput
                  options={
                    listBank?.data?.map((row) => ({
                      label: row.name + " - " + row.code,
                      value: row.code,
                    })) || emptyOptions
                  }
                  placeholder={
                    isDisabledForm ? valueBank?.bank_code : "Pilih Kode Bank"
                  }
                  onChange={(event) => {
                    setValueBank({
                      ...valueBank,
                      bank_code: event.value,
                      bank_name: event.label,
                    });
                  }}
                  hideSelectedOptions={true}
                  error="Kode Bank harus diisi"
                  isError={isErrorBank.bank_code}
                  isDisabled={isDisabledForm}
                />
              </Flex>
              {/* nomor rekening */}
              <Flex flexDirection="column" gap="16px">
                <Text variant="caption" color="black50" weight="bold">
                  Kota Bank
                </Text>
                <Box width="100%">
                  <Input
                    name="bank_city"
                    placeholder="Masukan Kota"
                    value={valueBank?.bank_city}
                    onChange={(e) => handleChangeValueBank(e)}
                    disabled={isDisabledForm}
                  />
                </Box>
              </Flex>
            </Flex>
          </Box>

          <Box backgroundColor="white" p="16px">
            <Flex flexDirection="column" rowGap="16px">
              <Flex flexDirection="column" gap="16px">
                <Text variant="caption" color="black50" weight="bold">
                  Nama Cabang
                </Text>
                <Box width="100%">
                  <Input
                    name="bank_branch"
                    placeholder="Masukan Cabang"
                    value={valueBank?.bank_branch}
                    onChange={(e) => handleChangeValueBank(e)}
                    disabled={isDisabledForm}
                  />
                </Box>
              </Flex>

              {/* nomor rekening */}
              <Flex flexDirection="column" gap="16px">
                <Text variant="caption" color="black50" weight="bold">
                  Nomor Rekening
                </Text>
                <Box width="100%">
                  <Input
                    name="bank_account_number"
                    placeholder="Masukan Nomor Rekening"
                    value={valueBank?.bank_account_number}
                    onChange={(e) => handleChangeValueBank(e)}
                    error="Nomor Rekening harus diisi"
                    isError={isErrorBank.bank_account_number}
                    disabled={isDisabledForm}
                  />
                </Box>
              </Flex>

              {/* nama pemilik rekening */}
              <Flex flexDirection="column" gap="16px">
                <Text variant="caption" color="black50" weight="bold">
                  Nama Pemilik Rekening
                </Text>
                <Box width="100%">
                  <Input
                    name="bank_account_beneficiary_name"
                    placeholder="Masukan Nama Pemilik Rekening"
                    value={valueBank?.bank_account_beneficiary_name}
                    onChange={(e) => handleChangeValueBank(e)}
                    error="Nama Pemilik rekening harus diisi"
                    isError={isErrorBank.bank_account_beneficiary_name}
                    disabled={isDisabledForm}
                  />
                </Box>
              </Flex>
            </Flex>
          </Box>
        </Flex>
        {mode !== "showFile" && (
          <Flex
            bottom="0"
            width="100%"
            position="fixed"
            justifyContent="center"
            alignItems="center"
            boxShadow="0 0 8px 0 rgba(20, 20, 32, 0.05);"
          >
            <StyledButton
              isBlock
              disabled={isAccumulateLoading}
              onClick={handleSaveBank}
            >
              Simpan
            </StyledButton>
          </Flex>
        )}
      </FormProvider>
    </MobileLayout>
  );
};

export default DocumentBankAdd;
