import React, { createContext, useEffect, useState } from "react";

export const OnlineStateContext = createContext({});

const OnlineStateProvider = ({ children }) => {
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => setIsOnline(false);

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  return (
    <OnlineStateContext.Provider value={{ isOnline }}>
      {children}
    </OnlineStateContext.Provider>
  );
};

export default OnlineStateProvider;
