import React from "react";
import { Flex } from "renos-ui";

const PlainLayout = ({ children }) => {
  return (
    <Flex
      backgroundColor="black5"
      justifyContent="center"
      minHeight="100%"
      py={24}
    >
      {children}
    </Flex>
  );
};

export default PlainLayout;
