import React from "react";
import { Flex, Text, Button } from "renos-ui";
import { useHistory } from "react-router-dom";
import mobileUrl from "constants/mobileUrl";

const EmptyState = ({ value }) => {
  const history = useHistory();

  const handleAdd = () => {
    history.push(mobileUrl.STORE_NOTES_FORM, { data: value, mode: "add" });
  };

  return (
    <Flex
      gap="8px"
      width="100%"
      columnGap="8px"
      paddingTop="52px"
      alignItems="center"
      paddingBottom="24px"
      flexDirection="column"
      justifyContent="center"
      style={{ boxSizing: "border-box" }}
    >
      <Flex
        as="img"
        alt="empty-note"
        src="/assets/images/mobile/empty-notes.svg"
        width="236px"
        height="236px"
      />
      <Text color="black75" textAlign="center" weight="bold" variant="body">
        Belum ada catatan di tokomu
      </Text>
      <Flex
        width="236px"
        marginX="60px"
        textAlign="center"
        justifyContent="center"
      >
        <Text color="black50" textAlign="center" variant="caption">
          Tambahkan catatan untuk memberikan informasi seperti Kebijakan Toko.
        </Text>
      </Flex>
      <Button size="medium" onClick={handleAdd}>
        Tambah Catatan
      </Button>
    </Flex>
  );
};

export default EmptyState;
