import { useState, useEffect } from "react";
import { Button, Flex, Icon, Text, Box, useDisclosure } from "renos-ui";
import { useGetTransactionSummary } from "services/statistic";
import Card from "./Card";
import dayjs from "dayjs";
import Chart from "./Chart";
import Tooltip from "./TooltipsDrawer";
import { SwiperProvider } from "providers/SwiperProvider";
import { SwiperSlide } from "swiper/react";
import { StyledSwiper } from "./styled";
import Skeleton from "./../skeleton";

const transactionType = [
  {
    name: "Pendapatan Bersih",
    summaryProperty: "net_income_short",
    key: "netIncome",
    tooltip: "Didapat dari pesanan baru masuk. Diluar ongkos kirim",
  },
  {
    name: "Produk Dilihat",
    summaryProperty: "product_views",
    key: "seen",
    tooltip:
      "Jumlah kunjungan ke halaman rincian produkmu dalam jangka waktu tertentu",
  },
  {
    name: "Produk Terjual",
    summaryProperty: "total_product",
    key: "soldOrder",
    tooltip: "Jumlah produk terjual dalam jangka waktu tertentu",
  },
  {
    name: "Tingkat Konversi",
    summaryProperty: "conversion_rate",
    key: "convertion",
    tooltip: "Jumlah presentase transaksi sukses dibagi jumlah produk dilihat",
  },
];

const TransactionSummary = () => {
  // swiper
  const [swiperInstance, setSwiperInstance] = useState();
  const tooltipsDrawer = useDisclosure({ isOpen: false });

  const convertPayload = (tempDataDate) => {
    const { startDate, endDate, type } = tempDataDate;
    const tempStartDate = dayjs(startDate).format("YYYY-MM-DD");
    const tempPayload = {
      date_type: type,
      start_date: tempStartDate,
      end_date: endDate ? dayjs(endDate).format("YYYY-MM-DD") : tempStartDate,
    };

    return { params: tempPayload };
  };

  const handleTooltip = () => {
    tooltipsDrawer.onOpen();
  };

  const [dataDatepicker] = useState({
    type: "daily",
    startDate: new Date(),
  });

  const [payload, setPayload] = useState(convertPayload(dataDatepicker));
  const [activeCard, setActiveCard] = useState("netIncome");
  const {
    data: dataTransactionSummary,
    isFetching: isTransactionSummaryFetching,
    isLoading: isTransactionSummaryLoading,
    isError: isTransactionSummaryError,
    refetch: refetchTransactionSummary,
  } = useGetTransactionSummary(payload);

  useEffect(() => {
    setPayload(convertPayload(dataDatepicker));
  }, [dataDatepicker]);

  return (
    <Flex gap="16px" flexDirection="column">
      <Flex justifyContent="space-between">
        <Flex flexDirection="column">
          <Flex gap="8px" alignItems="center">
            <Text weight="bold">Analisis Toko</Text>
            <Flex onClick={() => handleTooltip()}>
              <Icon name="Info-solid" size={16} color="black50" />
            </Flex>
          </Flex>
          <Text color="black75" variant="caption">
            Update terakhir:{" "}
            {dayjs(dataDatepicker.startDate).format("DD MMMM YYYY HH:MM")} WIB
          </Text>
        </Flex>
        {isTransactionSummaryError && (
          <Box>
            <Button
              onClick={refetchTransactionSummary}
              preffix={<Icon name="Reload-outline" size={20} />}
            >
              Muat Ulang
            </Button>
          </Box>
        )}
      </Flex>
      <Flex flexDirection="column" gap="16px">
        <Flex
          gap="16px"
          style={{ boxSizing: "border-box" }}
          className="calculate-step-mobile"
        >
          <SwiperProvider value={{ setSwiperInstance, swiperInstance }}>
            <StyledSwiper slidesPerView={2.3} spaceBetween={8}>
              {isTransactionSummaryLoading ? (
                <Skeleton item={2} />
              ) : (
                transactionType.map((transaction, indexTransaction) => (
                  <SwiperSlide key={indexTransaction}>
                    <Card
                      isLoading={
                        isTransactionSummaryLoading ||
                        isTransactionSummaryFetching
                      }
                      dataTransaction={transaction}
                      key={`transaction-card-${indexTransaction}`}
                      dataTransactionSummary={dataTransactionSummary?.data}
                      isActive={activeCard === transaction.key}
                      setActiveCard={setActiveCard}
                      refetch={refetchTransactionSummary}
                    />
                  </SwiperSlide>
                ))
              )}
            </StyledSwiper>
          </SwiperProvider>
        </Flex>
        <Chart payload={payload} activeChart={activeCard} />
      </Flex>
      <Tooltip disclosure={tooltipsDrawer} data={transactionType} />
    </Flex>
  );
};

export default TransactionSummary;
