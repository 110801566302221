import React from "react";
import { Box, Flex, Icon, Text, useDisclosure } from "renos-ui";
import PropTypes from "prop-types";
import { StyledTable } from "./styled";

const TableCascader = ({ productSkus, rowIndex, columns, dataError }) => {
  const { isOpen, onToggle } = useDisclosure({ open: false });

  return (
    <>
      <tr>
        <Box
          as="td"
          border="0"
          colSpan={columns.length}
          borderBottom="1px"
          borderStyle="solid"
          borderColor="black10"
        />
      </tr>
      {isOpen &&
        productSkus.map((data, rowIndexSku) => (
          <tr key={`table-row-${rowIndexSku}`}>
            {columns.map((column, dataIndex) => {
              const omittedColumn = ["cta", "product_status_id"];

              return omittedColumn.includes(column.key) ? (
                <Box
                  as="td"
                  border="0"
                  borderBottom="1px"
                  borderStyle="solid"
                  borderColor="black10"
                  width={column.width || "auto"}
                />
              ) : (
                <Box
                  as="td"
                  key={`table-data-${rowIndex}-${dataIndex}`}
                  width={column.width || "auto"}
                  py="20px"
                  border="0"
                  borderBottom="1px"
                  borderStyle="solid"
                  borderColor="black10"
                  textAlign="left"
                  paddingLeft={
                    column.key === "checkBox" ? "25px !important" : "12px"
                  }
                >
                  {column.render?.(
                    dataIndex,
                    data,
                    true,
                    dataError[rowIndexSku]
                  ) || data[column.dataIndex]}
                </Box>
              );
            })}
          </tr>
        ))}
      <tr>
        <Box
          as="td"
          key={`table-data-${rowIndex}`}
          py="20px"
          border="0"
          borderBottom="1px"
          borderStyle="solid"
          borderColor="black10"
          textAlign="left"
          colSpan={columns.length}
        >
          <Flex
            alignItems="center"
            columnGap="4px"
            paddingLeft="40px"
            cursor="pointer"
            onClick={onToggle}
          >
            <Text variant="caption" color="blue50" weight="bold">
              {isOpen ? "Tutup" : "Lihat Varian Produk"}
            </Text>
            <Icon
              name={isOpen ? "Up-outline" : "Down-outline"}
              size={16}
              color="blue50"
            />
          </Flex>
        </Box>
      </tr>
    </>
  );
};

const Table = ({ customHeader, columns, dataSource, dataError, ...props }) => {
  const renderTableHeader = () => {
    if (customHeader) {
      return (
        <Box
          as="th"
          border="0"
          borderBottom="1px"
          borderStyle="solid"
          borderColor="black10"
          colSpan={columns.length}
        >
          {customHeader}
        </Box>
      );
    }

    return columns.map((column, i) => (
      <Box
        as="th"
        pt="4px"
        pb="20px"
        border="0"
        borderBottom="1px"
        borderStyle="solid"
        borderColor="black10"
        textAlign={column.align || "left"}
        key={`table-header-${i}`}
        width={column.width || "auto"}
      >
        {typeof column.title === "string" ? (
          <Text color="black50" weight="bold">
            {column.title}
          </Text>
        ) : (
          column.title
        )}
      </Box>
    ));
  };

  return (
    <Box {...props}>
      <StyledTable>
        <Box as="thead">
          <Box as="tr">{renderTableHeader()}</Box>
        </Box>
        <Box as="tbody">
          {dataSource.map((data, rowIndex) => {
            const { isHasMore, product_skus, product_id } = data;

            return isHasMore ? (
              [
                <tr key={`table-row-${rowIndex}`}>
                  <Box
                    as="td"
                    width={columns[0].width || "auto"}
                    py="20px"
                    textAlign="left"
                  >
                    {columns[0].render?.(0, data) || data[columns[0].dataIndex]}
                  </Box>
                  <Box
                    as="td"
                    width={columns[1].width || "auto"}
                    py="20px"
                    textAlign="left"
                  >
                    {columns[1].render?.(1, data) || data[columns[1].dataIndex]}
                  </Box>

                  <Box
                    as="td"
                    width="auto"
                    py="20px"
                    textAlign="left"
                    colSpan={5}
                  >
                    <Flex
                      gap="8px"
                      // width="100%"
                      alignItems="center"
                      padding="12px 16px"
                      borderRadius="8px"
                      backgroundColor="black5"
                    >
                      <Icon name="Info-solid" size={16} color="black50" />
                      <Text variant="caption" color="black75">
                        Untuk pengaturan diskon produk dengan varian, dapat
                        diatur pada masing-masing varian.
                      </Text>
                    </Flex>
                  </Box>

                  <Box
                    as="td"
                    width={columns[7].width || "auto"}
                    py="20px"
                    textAlign="left"
                  >
                    {columns[7].render?.(7, data) || data[columns[7].dataIndex]}
                  </Box>
                </tr>,
                <TableCascader
                  productSkus={product_skus}
                  productId={product_id}
                  columns={columns}
                  rowIndex={rowIndex}
                  dataError={dataError[rowIndex].product_skus}
                  key={`table-cascader-${rowIndex}`}
                />,
              ]
            ) : (
              <tr key={`table-row-${rowIndex}`}>
                {columns.map((column, dataIndex) => {
                  return (
                    <Box
                      as="td"
                      key={`table-data-${rowIndex}-${dataIndex}`}
                      width={column.width || "auto"}
                      py="20px"
                      border="0"
                      borderBottom="1px"
                      borderStyle="solid"
                      borderColor="black10"
                      textAlign="left"
                    >
                      {column.dataIndex !== "status" &&
                        (column.render?.(
                          dataIndex,
                          data,
                          false,
                          dataError[rowIndex]
                        ) ||
                          data[column.dataIndex])}
                    </Box>
                  );
                })}
              </tr>
            );
          })}
        </Box>
      </StyledTable>
    </Box>
  );
};

Table.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
      dataIndex: PropTypes.string,
      key: PropTypes.string,
      render: PropTypes.func,
      align: PropTypes.string,
      width: PropTypes.string,
    })
  ),
  dataSource: PropTypes.arrayOf(PropTypes.object),
  customHeader: PropTypes.node,
};

Table.defaultProps = {
  columns: [],
  dataSource: [],
};

export default Table;
