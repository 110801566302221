import { useEffect, useState } from "react";
import { Button, Flex, Box, Icon, Avatar, Text } from "renos-ui";
import { useGetStoreDetail } from "services/store";

const HeaderProfile = () => {
  const [storeInfo, setStoreInfo] = useState({});

  const { data: storeData } = useGetStoreDetail(
    {
      need: "total_product,ratings",
    },
    "get-store-detail-header-profile"
  );

  useEffect(() => setStoreInfo(storeData?.data), [storeData]);

  return (
    <Flex justifyContent="space-between" width="100%" height="40px">
      <Flex gap="32px">
        <Flex gap="8px" alignItems="center">
          <Avatar
            size={32}
            isBorder
            src={storeInfo?.image_path || "/assets/images/default_store.svg"}
          />
          <Flex gap="4px" flexDirection="column">
            <Text variant="small" weight="bold" lineClamp={1}>
              {storeInfo?.name}
            </Text>
            <Flex alignItems="center" gap="4px">
              <Icon name="Location-solid" size={10} color="black25" />
              <Text variant="small" color="black75">
                {storeInfo?.domicile}
              </Text>
              <Box height="2px" width="2px" backgroundColor="black10" />
              <Text variant="small" color="blue75">
                Sedang Online
              </Text>
            </Flex>
          </Flex>
        </Flex>

        <Box
          borderStyle="solid"
          color="black10"
          borderWidth="1px"
          height="40px"
        />

        <Flex gap="24px">
          <Flex gap="4px" flexDirection="column" justifyContent="center">
            <Text variant="small" color="black75">
              Total Produk
            </Text>
            <Text variant="small" color="black75" weight="bold">
              {storeInfo?.total_product}
            </Text>
          </Flex>
          <Flex gap="4px" flexDirection="column" justifyContent="center">
            <Text variant="small" color="black75">
              Kecepatan Proses
            </Text>
            <Text variant="small" color="black75" weight="bold">
              {storeInfo?.store_process_name || "-"}
            </Text>
          </Flex>
          <Flex gap="4px" flexDirection="column" justifyContent="center">
            <Text variant="small" color="black75">
              Kualitas Produk
            </Text>
            <Flex gap="4px" alignItems="center">
              <Text variant="small" color="black75" weight="bold">
                {storeInfo?.rating_avg}
              </Text>
              <Icon name="Star-solid" size={10} color="yellow50" />
              <Text variant="small" color="black50">
                ({storeInfo?.review_count} ulasan)
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <Flex gap="8px" alignItems="center">
        <Box>
          <Button size="small" isBlock>
            {" "}
            Chat Penjual{" "}
          </Button>
        </Box>
        <Box>
          <Button
            variant="tertiary"
            icon={<Icon name="Share-solid" color="black50" size="12px" />}
            size="small"
          />
        </Box>
      </Flex>
    </Flex>
  );
};

export default HeaderProfile;
