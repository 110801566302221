import { Flex, Icon, Text, Box, Label, Divider, useDisclosure } from "renos-ui";
import { useGetDownloadAttachment } from "services/bidding";

export const DetailBiddingRequest = ({ data, isLoading }) => {
  const { isOpen: isOpenDetail, onToggle: onToggleDetail } = useDisclosure({
    open: false,
  });

  const downAttachment = useGetDownloadAttachment();

  const download = (res) => {
    const url = window.URL.createObjectURL(
      new Blob([res], {
        type: "octet/stream",
      })
    );

    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "Bidding Attachment.zip");
    document.body.appendChild(link);
    link.click();
    link?.parentNode?.removeChild(link);
  };

  const downloadAttachmentMutate = async (productId) => {
    downAttachment.mutate(
      { request_product_id: productId },
      {
        onSuccess: (res) => {
          download(res);
        },
      }
    );
  };

  const statusVariant = (biddingStatus) => {
    let status;

    switch (biddingStatus) {
      case "Berlangsung":
        status = "success";
        break;
      case "Sedang Ditinjau":
        status = "warning";
        break;
      default:
    }

    return (
      <Label variant={status} size="small">
        {biddingStatus}
      </Label>
    );
  };

  const formattedDate = (timestamp) => {
    const [date, timeWithZone] = new Intl.DateTimeFormat("id-ID", {
      dateStyle: "medium",
      timeStyle: "long",
    })
      .format(new Date(timestamp * 1000))
      .split(", ");

    const [time, zone] = timeWithZone.split(" ");
    const [hour, minute] = time.split(".");

    return `${date} - ${hour}:${minute} ${zone}`;
  };

  const isHideName = data.user_address_receiver_phone.includes("*");

  return (
    <Flex
      gap="24px"
      backgroundColor="white"
      borderRadius={8}
      py={16}
      px={24}
      boxShadow="0px 4px 4px rgba(20, 20, 32, 0.05)"
    >
      <Flex gap=".5rem" flexDirection="column" width="100%">
        <Text variant="subtitle" weight="bold" color="black100">
          Informasi Permintaan
        </Text>
        <Flex flexDirection="column">
          <Flex>
            <Flex width="9.5rem">
              <Text color="black75">ID Permintaan</Text>
            </Flex>
            <Text color="black75"> : {data?.request_number}</Text>
          </Flex>
          <Flex>
            <Box width="9.5rem">
              <Text color="black75">Status</Text>
            </Box>
            <Flex gap=".5rem">
              <Text color="black75"> : </Text>
              {statusVariant(data?.request_status_remark || "")}
            </Flex>
          </Flex>
          {!isLoading && (
            <>
              <Flex>
                <Box width="9.5rem">
                  <Text color="black75">Tanggal Dibuat</Text>
                </Box>
                <Text color="black75">
                  : {formattedDate(data?.request_start_date)}
                </Text>
              </Flex>
              <Flex>
                <Box width="9.5rem">
                  <Text color="black75">Tanggal Berakhir</Text>
                </Box>
                <Text color="black75">
                  : {formattedDate(data?.request_end_date)}
                </Text>
              </Flex>
            </>
          )}
        </Flex>
        <Divider marginY=".5rem" />
        <Text variant="subtitle" weight="bold" color="black100">
          Product Yang Diminta
        </Text>
        <Flex flexDirection="column" gap="1rem">
          <Flex>
            <Box width="9.5rem">
              <Text color="black75">Total Anggaran</Text>
            </Box>
            <Text color="black75"> : {data?.total_budget}</Text>
          </Flex>
          {data?.request_products?.map((item, index) => (
            <Flex flexDirection="column" marginTop=".5rem" key={index}>
              <Text variant="subtitle" weight="bold" color="black100">
                Detail Produk {index + 1}
              </Text>
              <Flex marginTop="1rem" gap=".25rem">
                <Flex>
                  <Text width="9.5rem" color="black75">
                    Nama Produk
                  </Text>
                  <Text>:</Text>
                </Flex>
                <Text color="black75"> {item.product_name}</Text>
              </Flex>
              <Flex>
                <Box width="9.5rem">
                  <Text color="black75">Kuantitas</Text>
                </Box>
                <Text color="black75">
                  {" "}
                  : {item.formatted_product_quantity}
                </Text>
              </Flex>
              <Flex>
                <Box width="9.5rem">
                  <Text color="black75">Anggaran Produk</Text>
                </Box>
                <Text color="black75"> : {item.formatted_product_budget}</Text>
              </Flex>
              {isOpenDetail && (
                <Flex flexDirection="column">
                  <Flex gap=".25rem">
                    <Flex>
                      <Text width="9.5rem" color="black75">
                        Detail Produk
                      </Text>
                      <Text>:</Text>
                    </Flex>
                    <Text color="black75">{item.product_description}</Text>
                  </Flex>
                  {item?.product_images?.length !== 0 && (
                    <Flex
                      marginLeft="9.5rem"
                      marginTop="1rem"
                      cursor="pointer"
                      gap=".5rem"
                      onClick={() => downloadAttachmentMutate(item.product_id)}
                    >
                      <Icon name="Download-outline" color="blue50" />
                      <Text color="blue50" weight="bold">
                        Download Lampiran
                      </Text>
                    </Flex>
                  )}
                </Flex>
              )}
            </Flex>
          ))}
          {isOpenDetail && (
            <Flex flexDirection="column">
              <Divider marginY=".5rem" />
              <Flex marginY=".5rem">
                <Text weight="bold" variant="subtitle">
                  Lokasi Pengiriman
                </Text>
              </Flex>
              <Text weight="bold">{data.user_address_name}</Text>
              <Text color={isHideName && "black50"}>
                {data.user_address_receiver_name}
              </Text>
              <Text color={isHideName && "black50"}>
                {data.user_address_receiver_phone}
              </Text>
              <Text color={isHideName && "black50"}>{data.address}</Text>
              <Flex gap=".25rem">
                <Text>{data?.district_name},</Text>
                <Text>{data?.city_name},</Text>
                <Text>{data?.province_name}</Text>
                <Text>{data?.postal_code}</Text>
              </Flex>
              <Divider marginY="1rem" />
              <Flex marginBottom=".5rem">
                <Text weight="bold" variant="subtitle">
                  Informasi Pajak
                </Text>
              </Flex>
              <Flex>
                <Text width="9.5rem">Faktur Pajak</Text>
                <Text>
                  {" "}
                  : {data.request_require_tax_invoice ? "Ya" : "Tidak"}
                </Text>
              </Flex>
              <Flex>
                <Text width="9.5rem">No. NPWP</Text>
                <Text color={isHideName && "black50"}>
                  {" "}
                  : {data.request_tax_number}
                </Text>
              </Flex>
            </Flex>
          )}
          <Divider marginY=".5rem" />
          <Flex
            justifyContent="center"
            alignItems="center"
            gap=".5rem"
            cursor="pointer"
            onClick={onToggleDetail}
          >
            <Icon
              name={!isOpenDetail ? "Down-outline" : "Up-outline"}
              color="blue50"
            />
            <Text color="blue50" weight="bold">
              {!isOpenDetail ? "Lihat Detail" : "Tutup Detail"}
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};
