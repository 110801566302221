import React from "react";
import { Flex, Text, Avatar, colors } from "renos-ui";
import { WrapperItems } from "./WrapperItems";

const ChatSidebarItems = ({ user, handleDetailUser, selectedUser }) => {
  return (
    <WrapperItems
      backgroundColor={
        selectedUser?.id === user?.id ? colors.blue5 : colors.white
      }
      onClick={() => handleDetailUser(user)}
    >
      {}
      <Avatar
        size={28}
        src={
          user.avatar && user.avatar !== "https://images.renos.id/"
            ? user.avatar
            : "https://cccloudstorage.blob.core.windows.net/images/UserLogo.png"
        }
      />
      <Flex flexGrow="1">
        <Text variant="caption" lineClamp="1">
          {user.name}
        </Text>
      </Flex>
      {user.count_notif > 0 && (
        <Flex
          backgroundColor="red50"
          width="8px"
          height="8px"
          borderRadius="100%"
        />
      )}
    </WrapperItems>
  );
};

export default ChatSidebarItems;