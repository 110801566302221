import { Flex, Skeleton } from "renos-ui";

const Loading = () => {
  return (
    <Flex
      flexDirection="column"
      padding="16px"
      borderBottomColor="black10"
      borderBottomStyle="solid"
      borderBottomWidth="1px"
      gap="4px"
    >
      <Skeleton height="14px" width="70px" />
      <Flex flexDirection="column" gap="4px">
        <Skeleton height="24px" width="30%" />
        <Skeleton height="22px" width="80%" />
      </Flex>
    </Flex>
  );
};

export default Loading;
