const { Flex, Box, Text, Icon } = require("renos-ui");

const ProductCard = ({ data }) => {
  return (
    <Flex gap="12px" alignItems="center">
      <Box
        as="img"
        src={data.product_image_url}
        alt="product-img"
        height="64px"
        width="64px"
        borderRadius="4px"
        onError={(e) => {
          e.target.onerror = null;
          e.target.src = "/assets/images/empty-product.svg";
        }}
      />
      <Flex flexDirection="column">
        <Text color="black75" variant="caption" lineClamp={1}>
          {data.product_name}
        </Text>
        <Text weight="bold">{data.formatted_selling_price}</Text>
        <Flex gap="4px" alignItems="center">
          <Icon color="yellow50" size={10} name="Star-solid" />
          <Text color="black75" variant="caption" weight="bold">
            {data.review_rating}
          </Text>
          <Text color="black50" variant="caption">
            ({data.review_count} ulasan)
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default ProductCard;
