import { useEffect, useState, useMemo, useContext } from "react";
import { Box, Flex, Input, Icon, Text, useToast, Spinner } from "renos-ui";
import { useSaveTemplates } from "services/settings/reply-template";
import { useHistory } from "react-router-dom";
import MobileLayout from "layouts/MobileLayout";
import mobileUrl from "constants/mobileUrl";
import { StyledButton, StyledTextArea } from "./../styled";

import { useActiveAutoTemplates } from "services/settings/reply-template";
import StoreContext from "providers/StoreProvider";

const ReplyTemplate = ({ location }) => {
  const { push } = useHistory();
  const [checkedAutomate, setCheckAutomate] = useState();
  const [textAreaCount, setTextAreaCount] = useState(0);
  const [textInputCount, setTextInputCount] = useState(0);

  const {
    storeData: {
      data: { id: storeId },
    },
  } = useContext(StoreContext);

  // active auto
  const { mutate: mutateActiveAutoTempates } = useActiveAutoTemplates({
    onSuccess: (data) => {
      setCheckAutomate(!checkedAutomate);
    },
  });

  const toast = useToast();
  const [mode, setMode] = useState("");
  const [totalData, setTotalData] = useState("");
  const [value, setValue] = useState({
    name: "",
    message: "",
  });
  const [isError, setIsError] = useState({
    name: false,
    message: false,
  });

  useEffect(() => {
    if (location.state !== undefined) {
      setValue(location.state.data);
      setMode(location.state.mode);
      setTotalData(location.state.totalData);
    }
    // eslint-disable-next-line
  }, []);

  const { mutate: mutateSaveTemplates, isLoading: isSaveLoading } =
    useSaveTemplates({
      onError: () => {
        toast({
          label: `Template balasan gagal ${
            mode === "add" ? "ditambahkan" : "diubah"
          }`,
          placement: "bottom",
        });
      },
      onSuccess: () => {
        if (totalData < 1) {
          mutateActiveAutoTempates();
        }

        if (location.state.checkDefault === 0) {
          mutateActiveAutoTempates();
        }

        toast({
          label: `Template balasan berhasil ${
            mode === "add" ? "ditambahkan" : "diubah"
          }`,
          placement: "bottom",
        });

        setTimeout(() => {
          push(mobileUrl.STORE_REPLY_TEMPLATE);
        }, 1000);
      },
    });

  const handleChangeValue = (event) => {
    setIsError({
      name: false,
      message: false,
    });

    if (event.target.name === "message") {
      setTextAreaCount(event.target.value.length);
    }

    if (event.target.name === "name") {
      setTextInputCount(event.target.value.length);
    }
    setValue((prevState) => {
      return {
        ...prevState,
        [event.target.name]: event.target.value,
      };
    });
  };

  const handleError = () => {
    const tempError = isError;
    let countError = 0;

    if (value.name.length < 5) {
      tempError.name = true;
      countError += 1;
    }

    if (value.message.length < 5) {
      tempError.message = true;
      countError += 1;
    }

    setIsError({ ...tempError });

    if (countError !== 0) {
      return true;
    }

    return false;
  };

  const handleSave = () => {
    const isHasError = handleError();
    if (isHasError) return false;

    let body = {};

    if (totalData === 0) {
      body = {
        id: value.id,
        store_id: storeId,
        name: value.name,
        message: value.message,
        default: true,
      };
    } else {
      body = {
        id: value.id,
        store_id: storeId,
        name: value.name,
        message: value.message,
      };
    }
    mutateSaveTemplates(body);
  };

  const isDisabled = useMemo(
    () =>
      value?.name?.length === 0 ||
      value?.message?.length === 0 ||
      isSaveLoading,
    [isSaveLoading, value]
  );

  return (
    <MobileLayout
      header={{
        title: mode === "add" ? "Tambah Template" : "Edit Template",
        withBack: (
          <Icon name="Close-outline" color="black50" cursor="pointer" />
        ),
      }}
    >
      <Flex flexDirection="column" gap="16px" width="100%">
        <Flex flexDirection="column" gap="9px" p="16px">
          <Flex flexDirection="column" gap="20px">
            <Flex flexDirection="column" gap="16px">
              <Text variant="caption" color="black50" weight="bold">
                Nama Template
              </Text>
              <Flex flexDirection="column" gap="8px">
                <Box width="100%">
                  <Input
                    name="name"
                    placeholder="Masukan judul catatan"
                    value={value.name}
                    maxLength={30}
                    onChange={(event) => handleChangeValue(event)}
                  />
                </Box>
                <Flex justifyContent="space-between">
                  <Flex>
                    {isError.name && (
                      <Text color="red50" variant="caption">
                        Nama template minimal 5 karakter
                      </Text>
                    )}
                  </Flex>
                  <Flex>
                    <Text color="black50" variant="caption">
                      {textInputCount}/30
                    </Text>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
            <Flex flexDirection="column" gap="16px">
              <Text variant="caption" color="black50" weight="bold">
                Isi Template
              </Text>
              <Flex flexDirection="column" gap="8px">
                <Box width="100%">
                  <StyledTextArea
                    name="message"
                    placeholder="Tulis detail catatan..."
                    value={value.message}
                    maxLength={200}
                    onChange={(event) => handleChangeValue(event)}
                  />
                </Box>
                <Flex justifyContent="space-between">
                  <Flex>
                    {isError.message && (
                      <Text color="red50" variant="caption">
                        Pesan template minimal 5 karakter
                      </Text>
                    )}
                  </Flex>
                  <Flex>
                    <Text color="black50" variant="caption">
                      {textAreaCount}/200
                    </Text>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Flex>

        <Flex
          bottom="0"
          width="100%"
          position="fixed"
          justifyContent="center"
          alignItems="center"
          boxShadow="0 0 8px 0 rgba(20, 20, 32, 0.05);"
        >
          <StyledButton
            isBlock
            onClick={handleSave}
            disabled={isDisabled}
            preffix={isSaveLoading && <Spinner size="16px" color="white" />}
          >
            Simpan
          </StyledButton>
        </Flex>
      </Flex>
    </MobileLayout>
  );
};

export default ReplyTemplate;
