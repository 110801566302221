import { Flex, Box, Text, colors } from "renos-ui";
import PropTypes from "prop-types";

const EmptyTemplate = () => {
  return (
    <Flex
      flexDirection="column"
      py="32px"
      gap="12px"
      alignContent="center"
      alignItems="center"
      justifyContent="center"
      border={`1px dashed ${colors.black5}`}
      borderRadius="8px"
    >
      <Text variant="subtitle" color="black75" weight="bold">
        Belum ada template balasan ulasan
      </Text>
      <Box>
        <img
          src="/assets/images/setting/empty-folder.svg"
          alt="no-template-img"
          width="128px"
          height="128px"
        />
      </Box>
      <Text color="black50">Yuk buat template balasan ulasanmu sekarang!</Text>
    </Flex>
  );
};

EmptyTemplate.propTypes = {
  handleAdd: PropTypes.func.isRequired,
};

export default EmptyTemplate;
