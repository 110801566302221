import { useState } from "react";
import { Flex, Text, Dialog, Input, Button } from "renos-ui";

const items = [
  {
    id: 1,
    desc: "Ulasan berupa spam",
  },
  {
    id: 2,
    desc: "Mengandung SARA, diskriminasi, vulgar, ancaman, dan pelanggaran nilai/norma sosial",
  },
  {
    id: 3,
    desc: "Menyebarluaskan informasi pribadi orang lain",
  },
  {
    id: 4,
    desc: "Lainnya",
  },
];

const ReportReview = ({ showReport }) => {
  const [showTextArea, setShowTextArea] = useState({
    show: false,
    value: "",
  });

  const handleSelected = (value) => {
    let setSelected = null;

    if (value.desc === "Lainnya") {
      setSelected = value;
      setShowTextArea({
        ...setShowTextArea,
        show: true,
      });
    }
    // eslint-disable-next-line no-unused-vars
    setSelected = value;
  };

  return (
    <Dialog
      isVisible={showReport.isOpen}
      onClose={showReport.onClose}
      verticalCentered
      width="400px"
      closable={true}
    >
      <Flex
        fontFamily="sans-serif"
        flexDirection="column"
        rowGap="24px"
        width="100%"
      >
        <Flex flexDirection="column" rowGap="8px">
          <Text variant="heading3" weight="bold">
            Laporkan
          </Text>
        </Flex>
        <Flex flexDirection="column" rowGap="16px">
          {items.map((row) => (
            <Input.Radio
              name="radioGroup"
              key={row.id}
              onChange={() => handleSelected(row)}
            >
              <Text>{row.desc}</Text>
            </Input.Radio>
          ))}
          {showTextArea.show && (
            <Input.TextArea
              value={showTextArea.value}
              placeholder="Masukkan alasan lainnya..."
              onChange={(event) => {
                setShowTextArea({
                  ...showTextArea,
                  value: event.target.value,
                });
              }}
            />
          )}
        </Flex>
        <Button isBlock onClick={showReport.onClose}>
          Kirim
        </Button>
      </Flex>
    </Dialog>
  );
};

export default ReportReview;
