import { useContext, useMemo } from "react";
import { Flex, Input, Text } from "renos-ui";
import StoreLocationContext from "providers/StoreLocationProvider";
import Map from "widgets/desktop/GoogleMap/Map";

const FormRightSide = () => {
  const { value, isError, setValue, setIsError, handleChangevalue } =
    useContext(StoreLocationContext);

  const mapPosition = useMemo(() => {
    return {
      lat:
        value?.coordinates?.latitude !== "" ? value?.coordinates?.latitude : 0,
      long:
        value?.coordinates?.longitude !== ""
          ? value?.coordinates?.longitude
          : 0,
    };
  }, [value]);

  const onChangeMap = (position, pointAddress) => {
    setValue({
      ...value,
      coordinates: {
        latitude: position.lat,
        longitude: position.lng,
      },
      point_address: pointAddress,
    });
    setIsError({ ...isError, coordinates: false });
  };

  return (
    <Flex flexGrow="1" rowGap="24px" flexDirection="column">
      <Input
        value={value.contact_person}
        label="Nama PIC"
        name="contact_person"
        size="small"
        placeholder="Tulis nama PIC / gudang"
        onChange={handleChangevalue}
        error="nama PIC harus diisi."
        isError={isError.contact_person}
      />
      <Input
        value={value.contact_number}
        label="Nomor HP/Telepon"
        name="contact_number"
        size="small"
        placeholder="Masukkan nomor HP/telepon"
        onChange={handleChangevalue}
        // error={textErrorPhone}
        // isError={isError.contact_number}
        error={
          isError?.patternNumber
            ? "Format nomor hp indonesia harus dimulai +62."
            : "Nomor HP/Telepon harus diisi."
        }
        isError={isError?.contact_number || isError?.patternNumber}
      />
      <Input
        value={value.postal_code}
        label="Kode Pos"
        name="postal_code"
        size="small"
        placeholder="Pilih kode pos"
        disabled
      />
      <Flex width="100%" height="100%" flexDirection="column" gap="8px">
        <Map
          onChange={onChangeMap}
          position={mapPosition}
          address={value.point_address}
        />
        {isError.coordinates && (
          <Text color="red50" variant="caption">
            Posisi harus dipilih.
          </Text>
        )}
      </Flex>
    </Flex>
  );
};

export default FormRightSide;
