import StoreLayoutContext from "providers/StoreLayoutProvider";
import { useContext, useEffect, useMemo, useState } from "react";
import {
  Flex,
  Button,
  useToast,
  range,
  Text,
  SelectInput,
  useDisclosure,
} from "renos-ui";
import { useGetMasterBannerType } from "services/store-layout/banner";
import { useUpdateHomePage } from "services/store-layout/homepage";
import Header from "../component/Header";
import ModalDelete from "../component/ModalDelete";
import Uploader from "./component/Uploader";
import UploaderSkeleton from "./component/Uploader/UploaderSkeleton";
import ProductLink from "./component/ProductLink";
import { generateStoreLayoutTemplate } from "helpers";

const Banner = ({ handleBack, id }) => {
  const toast = useToast();

  const [dataBanner, setDataBanner] = useState({ data: [], banner: {} });
  const [listBannerType, setListBannerType] = useState([]);

  const disclosureDelete = useDisclosure({ isOpen: false });

  const { dataHomePage, setDataHomePage } = useContext(StoreLayoutContext);

  const {
    isLoading: isBannerListLoading,
    isRefetching: isBannerListRefetching,
    isError: isErrorMaster,
    refetch: refetchMaster,
  } = useGetMasterBannerType({
    onSuccess: ({ data }) => {
      const tempListBanner = data.map((listBanner) => ({
        value: listBanner,
        label: listBanner.name,
      }));
      setListBannerType([...tempListBanner]);
    },
  });

  const { mutate, isLoading: isUpdateHomePageLoading } = useUpdateHomePage({
    onSuccess: (data) => {
      const { message, frontend_message } = data;
      toast({
        label: frontend_message || message,
        placement: "top",
      });
      handleBack();
    },
    onError: (data) => {
      const {
        response: {
          data: { frontend_message, message },
        },
      } = data;
      toast({
        label: frontend_message || message,
        placement: "top",
        backgroundColor: "red50",
      });
    },
  });

  const handleSetBannerType = ({ value: selectedValue }) => {
    const newBanner = {
      ...dataBanner,
      banner: selectedValue,
      selectedBanner: { label: selectedValue.name, value: selectedValue },
      data: generateStoreLayoutTemplate(selectedValue.image_type),
    };
    setDataBanner(newBanner);
    handleSetHomePage(newBanner);
  };

  const isLoadingMasterType = useMemo(
    () => isBannerListLoading || isBannerListRefetching,
    [isBannerListLoading, isBannerListRefetching]
  );

  const handleSetHomePage = (newValue) => {
    const tempDataHomePage = dataHomePage.map((homepage) => {
      let tempHomePage = homepage;

      if (homepage.id === id) {
        tempHomePage = {
          ...homepage,
          ...newValue,
        };
      }

      return tempHomePage;
    });
    setDataHomePage([...tempDataHomePage]);
  };

  const handleUpload = (newData, indexData) => {
    const newDataBanner = {
      ...dataBanner,
      data: dataBanner?.data?.map((oldBanner, indexBanner) => {
        if (indexBanner === indexData) {
          return newData;
        }

        return oldBanner;
      }),
    };

    setDataBanner(newDataBanner);
    handleSetHomePage(newDataBanner);
  };

  const handleAddBanner = () => {
    const tempDataBanner = dataBanner;
    tempDataBanner.data.push({
      image_path: "",
      mst_banner_image_type_id: dataBanner.banner.image_type.mst_banner_type_id,
      mst_banner_image_type_name: dataBanner.banner.image_type.name,
    });

    setDataBanner(tempDataBanner);
    handleSetHomePage(tempDataBanner);
  };

  const handleDeleteBanner = (index) => {
    const tempDataBanner = dataBanner.data;
    tempDataBanner.splice(index, 1);
    setDataBanner({ ...dataBanner, data: [...tempDataBanner] });
    handleSetHomePage(tempDataBanner);
  };

  const handleSave = () => {
    const { banner, data } = dataBanner;
    let errorCount = 0;
    const listIds = data.map((banner) => {
      if (!banner.image_path) errorCount++;

      return banner.id;
    });

    if (errorCount > 0 && errorCount !== data.length) {
      toast({
        label: "Gambar Banner Harus diisi semua.",
        placement: "top",
        backgroundColor: "red50",
      });

      return;
    }

    mutate({
      type: id,
      ids: listIds.filter((ids) => ids),
      banner_type: banner?.image_type?.id,
    });
  };

  useEffect(() => {
    const selectedBanner = dataHomePage.filter(
      (homepage) => homepage.id === id
    )[0];

    selectedBanner.selectedBanner = {
      value: selectedBanner.banner,
      label: selectedBanner.banner.name,
    };

    setDataBanner(selectedBanner); // eslint-disable-next-line
  }, []);

  return (
    <Flex flexDirection="column">
      <Header handleBack={handleBack} title="Edit Banner" />
      <Flex
        height="320px"
        width="100%"
        flexDirection="column"
        gap="24px"
        overflowX="auto"
        borderStyle="solid"
        borderWidth="0px 0px 1px"
        borderColor="black10"
        py="24px"
      >
        <Flex flexDirection="column" gap="8px">
          <Text weight="bold" variant="caption" color="black50">
            Desain Banner
          </Text>
          {isErrorMaster ? (
            <Button onClick={refetchMaster}>Muat Ulang</Button>
          ) : (
            <SelectInput
              size="small"
              options={listBannerType}
              placeholder="Pilih jenis banner"
              onChange={handleSetBannerType}
              value={dataBanner.selectedBanner}
              hideSelectedOptions={false}
            />
          )}
        </Flex>
        {isLoadingMasterType ? (
          range(2).map((_, indexSkeleton) => (
            <UploaderSkeleton key={`skeleton-${indexSkeleton}`} />
          ))
        ) : (
          <>
            {dataBanner?.data?.map((banner, indexBanner) => (
              <Flex
                flexDirection="column"
                gap="8px"
                key={`data-banner-${indexBanner}`}
              >
                <Uploader
                  value={banner}
                  setValue={(newData) => handleUpload(newData, indexBanner)}
                  name={`Gambar ${indexBanner + 1}`}
                  isAllowDelete={
                    dataBanner?.data?.length >
                    dataBanner.banner?.image_type?.min_amount
                  }
                  deleteData={() => handleDeleteBanner(indexBanner)}
                />
                <ProductLink
                  value={banner}
                  setValue={(newData) => handleUpload(newData, indexBanner)}
                />
              </Flex>
            ))}

            {dataBanner.data.length <
              dataBanner?.banner?.image_type?.max_amount && (
              <Button
                variant="secondary"
                size="medium"
                onClick={handleAddBanner}
                isBlock
              >
                Tambah Banner
              </Button>
            )}
          </>
        )}
      </Flex>

      <Flex gap="16px" py="16px">
        <Flex width="152px">
          <Button
            size="medium"
            variant="tertiary"
            isBlock
            onClick={disclosureDelete.onToggle}
          >
            Batalkan
          </Button>
        </Flex>
        <Flex width="152px">
          <Button
            size="medium"
            isBlock
            disabled={isUpdateHomePageLoading}
            onClick={handleSave}
          >
            Simpan
          </Button>
        </Flex>
      </Flex>

      <ModalDelete
        isOpen={disclosureDelete.isOpen}
        onClose={disclosureDelete.onClose}
        onConfirm={handleBack}
        title="Keluar Pengaturan"
        description="Semua pengaturan yang kamu buat tidak akan tersimpan. Lanjut keluar?"
        confirmText="Ya, Keluar"
      />
    </Flex>
  );
};

export default Banner;
