import Web from "containers/web/store-layout/Preview/Web";
import { useState } from "react";
import { Flex, Icon, Box } from "renos-ui";
import { StyledDialog } from "./styled";
import Mobile from "containers/web/store-layout/Preview/Mobile";

const ModalPreview = ({ isOpen, onClose }) => {
  const [isWebActive, setIsWebActive] = useState(true);

  return (
    <StyledDialog isVisible={isOpen} onClose={onClose} width="782px">
      <Flex
        justifyContent="space-between"
        position="absolute"
        width="100%"
        marginTop="0px"
        zIndex={999999}
      >
        <Flex gap="8px" />
        <Flex gap="8px">
          <Box
            padding="8px"
            borderRadius="8px"
            backgroundColor="white"
            cursor="pointer"
            onClick={() => setIsWebActive(true)}
            borderStyle="solid"
            borderWidth="1px"
            borderColor={isWebActive ? "blue50" : "white"}
          >
            <Icon color="black100" name="Desktop-outline" size={24} />
          </Box>

          <Box
            padding="8px"
            borderRadius="8px"
            backgroundColor="white"
            cursor="pointer"
            borderStyle="solid"
            borderWidth="1px"
            borderColor={!isWebActive ? "blue50" : "white"}
            onClick={() => setIsWebActive(false)}
          >
            <Icon color="black100" name="Mobile-outline" size={24} />
          </Box>
        </Flex>
        <Flex gap="8px">
          <Box
            padding="8px"
            borderRadius="8px"
            backgroundColor="white"
            cursor="pointer"
            borderStyle="solid"
            borderWidth="1px"
            borderColor="white"
            onClick={onClose}
          >
            <Icon color="black100" name="Close-outline" size={24} />
          </Box>
        </Flex>
      </Flex>

      <Flex pt="64px" pb="24px">
        {isWebActive ? (
          <Web isPreviewMode isDesignActive />
        ) : (
          <Mobile isPreviewMode isDesignActive />
        )}
      </Flex>
    </StyledDialog>
  );
};

export default ModalPreview;
