import ENDPOINTS from "constants/endpoints";
import { useMutation, useQuery } from "react-query";
import client from "../client";

const getProductList = async (params) => {
  const { data } = await client.get(ENDPOINTS.products, {
    params,
  });

  return { data: data.data, pagination: data.pagination };
};

const getProductListV3 = async (params) => {
  const { data } = await client.get(ENDPOINTS.productsv3, {
    params,
  });

  return { data: data.data, pagination: data?.meta?.pagination };
};

const getProductSingle = async (productId) => {
  const { data } = await client.get(ENDPOINTS.detail_products, {
    params: { id: productId },
  });

  return data.data;
};

const deactivateProduct = async (body) => {
  const { data } = await client.put(ENDPOINTS.update_product_inline_v3, body);

  return data.data;
};

const getProductStatus = async () => {
  const { data } = await client.get(ENDPOINTS.product_status);

  let all = 0;
  const mappedResponse = {};
  data.data.forEach((status) => {
    all += status.total;

    switch (status.product_status_id) {
      case 1:
        mappedResponse["active"] = status.total;
        break;

      case 2:
        mappedResponse["nonActive"] = status.total;
        break;

      default:
        mappedResponse["all"] = status.total;
        break;
    }
  });

  return { ...mappedResponse, totalStatus: all };
};

export const uploadImage = async (body) => {
  try {
    const { data } = await client.post(ENDPOINTS.upload_image_product, body, {
      headers: { "Content-Type": "multipart/form-data" },
    });

    return data.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const saveProduct = async (body) => {
  const { data } = await client.post(ENDPOINTS.save_products, body);

  return data.data;
};

export const deleteProduct = async (productId) => {
  const { data } = await client.get(ENDPOINTS.delete_product, {
    params: {
      product_id: productId,
    },
  });

  return data.data;
};

export const useGetProducts = (params, extraParams) => {
  return useQuery(["products", params], () => getProductList({ ...params }), {
    ...extraParams,
  });
};

export const useGetProductsV3 = (params, extraParams) => {
  return useQuery(
    ["productsv3", params],
    () => getProductListV3({ ...params }),
    {
      ...extraParams,
      retry: false,
    }
  );
};

export const useGetProductDetail = (productId, extraParams) => {
  return useQuery(
    ["products-detail", productId],
    () => getProductSingle(productId),
    {
      ...extraParams,
    }
  );
};

export const useGetProductStatus = (extraParams) => {
  return useQuery(["productStatus"], getProductStatus, {
    ...extraParams,
    retry: false,
  });
};

export const useDeactivateProduct = (extraParams) =>
  useMutation((body) => deactivateProduct(body), {
    ...extraParams,
  });

export const useUploadProductImage = (extraParams) =>
  useMutation((body) => uploadImage(body), {
    ...extraParams,
  });

const updateProductInline = async (body) => {
  let endpoint = ENDPOINTS.update_product_inline;

  if (body.version === "v3") {
    endpoint = ENDPOINTS.update_product_inline_v3;
    const { data } = await client.put(endpoint, body.payload);

    return { data: data.data, body };
  } else {
    const { data } = await client.post(endpoint, body);

    return { data: data.data, body };
  }
};

export const useUpdateProductInline = (extraParams) =>
  useMutation((body) => updateProductInline(body), {
    ...extraParams,
  });

export const useSaveProduct = (extraParams) =>
  useMutation((body) => saveProduct(body), {
    ...extraParams,
  });

export const useDeleteProduct = (extraParams) =>
  useMutation((productId) => deleteProduct(productId), {
    ...extraParams,
  });
