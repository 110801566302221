import { goToBuyerPage, goToCustomerService } from "helpers";
import { Button, Drawer, Flex, Text } from "renos-ui";

const DrawerStoreClosed = ({ isOpen }) => {
  return (
    <Drawer closable={false} isVisible={isOpen} placement="bottom">
      <Flex gap="24px" alignItems="center" flexDirection="column">
        <Flex
          as="img"
          alt="forbidden"
          src="/assets/images/forbidden.svg"
          width="150px"
          height="150px"
        />
        <Flex gap="4px" flexDirection="column">
          <Text weight="bold" variant="subtitle">
            Maaf, Toko Ditutup Permanen.
          </Text>
          <Text>
            Tokomu telah ditutup oleh pihak Renos karena pelanggaran{" "}
            <b>peraturan</b> yang berlaku.
          </Text>
        </Flex>
        <Flex gap="8px" width="100%" flexDirection="column">
          <Button
            isBlock
            size="medium"
            variant="primary"
            onClick={goToBuyerPage}
          >
            Kembali ke Homepage
          </Button>
          <Button
            isBlock
            size="medium"
            variant="secondary"
            onClick={goToCustomerService}
          >
            Hubungi Support Renos
          </Button>
        </Flex>
      </Flex>
    </Drawer>
  );
};

export default DrawerStoreClosed;
