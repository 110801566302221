import ReactApexCharts from "react-apexcharts";
import { WrapperChart } from "./styled";
import { chartLineConfig } from "./chartConfig";
import { useEffect, useState, useMemo } from "react";
import {
  useGetNetIncome,
  useGetProductSeen,
  useGetProductSold,
  useGetProductConversion,
} from "services/statistic";
import { Flex, Skeleton } from "renos-ui";
import FailLoadData from "widgets/global/FailLoadData";

const Chart = ({ payload, activeChart }) => {
  const [selectedData, setSelectedData] = useState(chartLineConfig);

  const {
    mutate: mutateNetIncome,
    isError: isNetIncomeError,
    isLoading: isNetIncomeLoading,
  } = useGetNetIncome({
    onSuccess: (data) => {
      setSelectedData(dataChartFormatter(data, "total", true));
    },
  });

  const {
    mutate: mutateProductSeen,
    isError: isProductSeenError,
    isLoading: isProductSeenLoading,
  } = useGetProductSeen({
    onSuccess: (data) => {
      setSelectedData(dataChartFormatter(data, "total_view", "", true));
    },
  });

  const {
    mutate: mutateProductSold,
    isError: isProductSoldError,
    isLoading: isProductSoldLoading,
  } = useGetProductSold({
    onSuccess: (data) => {
      setSelectedData(dataChartFormatter(data, "total", "", "", true));
    },
  });

  const {
    mutate: mutateProductConversion,
    isError: isProductConversionError,
    isLoading: isProductConversionLoading,
  } = useGetProductConversion({
    onSuccess: (data) => {
      setSelectedData(dataChartFormatter(data, "conversion_rate"));
    },
  });

  const dataChartFormatter = (
    data,
    dataPropertyName,
    propertyIncome,
    propertySeen,
    propertySold
  ) => {
    const tempSelectedData = chartLineConfig(
      dataPropertyName === "conversion_rate",
      propertyIncome === true,
      propertySeen === true,
      propertySold === true
    );

    for (let i = 0; i < data.length; i++) {
      const { data: dataList } = data[i];

      if (i === 0) {
        const listCategories = dataList.map((netIncome) => netIncome.time);
        tempSelectedData.options.xaxis.categories = listCategories;
        tempSelectedData.options.labels[0].data = dataList.map(
          (netIncome) => netIncome.date_info
        );
        tempSelectedData.options.labels[1].data = listCategories;
      }
      tempSelectedData.series[i].data = dataList.map(
        (netIncome) => netIncome[dataPropertyName]
      );
    }

    return tempSelectedData;
  };

  const handleMutate = () => {
    switch (activeChart) {
      case "netIncome":
        mutateNetIncome(payload);
        break;
      case "seen":
        mutateProductSeen(payload);
        break;
      case "soldOrder":
        mutateProductSold(payload);
        break;
      case "convertion":
        mutateProductConversion(payload);
        break;
      default:
        break;
    }
  };

  const isAccumulateLoading = useMemo(
    () =>
      isNetIncomeLoading ||
      isProductSeenLoading ||
      isProductSoldLoading ||
      isProductConversionLoading,
    [
      isNetIncomeLoading,
      isProductSeenLoading,
      isProductSoldLoading,
      isProductConversionLoading,
    ]
  );

  const isErrorLoading = useMemo(
    () =>
      isNetIncomeError ||
      isProductSeenError ||
      isProductSoldError ||
      isProductConversionError,
    [
      isNetIncomeError,
      isProductSeenError,
      isProductSoldError,
      isProductConversionError,
    ]
  );

  useEffect(() => {
    handleMutate();
    //eslint-disable-next-line
  }, [activeChart, payload]);

  return (
    <WrapperChart>
      {isAccumulateLoading ? (
        <Skeleton height="420px" />
      ) : isErrorLoading ? (
        <Flex height="420px">
          <FailLoadData
            onClick={handleMutate}
            noImg
            isLoading={isAccumulateLoading}
          />
        </Flex>
      ) : (
        <ReactApexCharts
          options={selectedData.options}
          series={selectedData.series}
          type="line"
          height={420}
          width="100%"
        />
      )}
    </WrapperChart>
  );
};

export default Chart;
