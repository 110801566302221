import styled from "styled-components";
import { Button, Divider, Text, colors } from "renos-ui";

export const StyledDivider = styled(Divider)`
  background-color: ${colors.black5};
`;

export const StyledButtonIcon = styled(Button)`
  padding: 7px;
`;

export const StyledText = styled(Text)`
  word-break: break-all;
`;
