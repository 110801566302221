import { imageTypeChecking } from "helpers";
import { useEffect, useState } from "react";
import {
  Button,
  Drawer,
  Flex,
  Icon,
  Label,
  Spinner,
  Text,
  useToast,
  useUpload,
  Upload,
  Input,
} from "renos-ui";
import { useArriveOrder } from "services/order-management";

const PopupSellerFleetArrive = ({ disclosure, toFirstPage, orderId }) => {
  const toast = useToast();
  const { isOpen, onClose } = disclosure;
  const [dataImage, setDataImage] = useState({});
  const [receiverName, setReceiverName] = useState("");

  const { mutate: orderArrive, isLoading } = useArriveOrder({
    onSuccess: (data) => {
      const { message, frontend_message } = data;

      toFirstPage();
      onClose();

      toast({
        label: frontend_message || message || "Pesanan berhasil diterima",
        placement: "bottom",
      });
    },
    onError: (data) => {
      const {
        response: {
          data: { frontend_message, message },
        },
      } = data;
      toast({
        label: frontend_message || message || "Pesanan gagal diterima",
        placement: "bottom",
        backgroundColor: "red50",
      });
    },
  });

  const handleSave = () => {
    const formData = new FormData();
    formData.append("file", dataImage.imageFile);
    formData.append("order_id", orderId);
    formData.append("receiver_contact_person", receiverName);
    orderArrive(formData);
  };

  const { register, result } = useUpload({
    fileType: "image",
  });

  const uploadFileChecking = async (result) => {
    const deepTypeChecking =
      result?.file?.type === "application/pdf"
        ? true
        : await imageTypeChecking(result.file);

    if (deepTypeChecking) {
      const maxAllowedSize = 10 * 1024 * 1024;
      const allowedType = [
        "image/png",
        "image/jpeg",
        "image/jpg",
        "application/pdf",
      ];

      if (result) {
        if (maxAllowedSize < result?.file?.size) {
          toast({
            label: "Ukuran file tidak boleh melebihi dari 10mb.",
            placement: "bottom",
            backgroundColor: "red50",
          });

          return "";
        }

        if (!allowedType.includes(result?.file?.type)) {
          toast({
            label: "File harus berekstensi jpg/jpeg/png/pdf.",
            placement: "bottom",
            backgroundColor: "red50",
          });

          return "";
        }

        setDataImage({
          imageUrl: result?.source,
          imageFile: result?.file,
        });
      }
    } else {
      toast({
        label: "Format gambar tidak sesuai",
        placement: "bottom",
        backgroundColor: "red50",
      });
    }
  };

  useEffect(() => {
    uploadFileChecking(result);

    // eslint-disable-next-line
  }, [result]);

  return (
    <Drawer isVisible={isOpen} onClose={onClose} placement="bottom">
      <Flex flexDirection="column" width="100%" gap="24px" height="100%">
        <Flex gap="8px" alignItems="center">
          <Icon
            name="Close-outline"
            size={24}
            color="black50"
            onClick={onClose}
          />
          <Text variant="subtitle" weight="bold">
            Kirim Pesanan
          </Text>
        </Flex>
        <Flex flexDirection="column" gap="8px">
          <Flex gap="4px" alignContent="center">
            <Text variant="caption" color="black50" weight="bold">
              Nama Penerima
            </Text>
            <Label>Wajib</Label>
          </Flex>
          <Flex width="100%">
            <Input
              size="small"
              placeholder="Tulis nama penerima."
              value={receiverName}
              onChange={(event) => setReceiverName(event.target.value)}
            />
          </Flex>
        </Flex>
        <Flex flexDirection="column" width="100%" gap="12px">
          <Flex flexDirection="column" width="100%" gap="8px">
            <Flex gap="4px" alignContent="center">
              <Text variant="caption" weight="bold">
                Upload Bukti Pengiriman
              </Text>
              <Label>Wajib</Label>
            </Flex>
            <Text variant="caption" color="black75">
              Ambil foto penerima bersama dengan paket yang diantarkan. Pastikan
              foto terlihat jelas (tidak blur).
            </Text>
          </Flex>

          <Flex
            borderColor="black10"
            borderStyle="dashed"
            borderWidth="1px"
            py="24px"
            borderRadius="8px"
            alignContent="center"
            alignItems="center"
            textAlign="center"
            flexDirection="column"
            gap="16px"
          >
            {dataImage?.imageUrl ? (
              <Flex gap="24px">
                {dataImage?.imageFile?.type === "application/pdf" ? (
                  <Flex
                    as="img"
                    alt="preview"
                    width="100px"
                    height="64px"
                    src={"/assets/images/order-management/pdf.png"}
                    style={{ objectFit: "contain" }}
                  />
                ) : (
                  <Flex
                    borderRadius="4px"
                    as="img"
                    alt="preview"
                    width="100px"
                    height="64px"
                    src={dataImage?.imageUrl}
                    style={{ objectFit: "contain" }}
                  />
                )}
                <Flex flexDirection="column" gap="8px" textAlign="left">
                  <Flex flexDirection="column" gap="4px">
                    <Text variant="caption" color="black75" weight="bold">
                      Upload Berhasil!
                    </Text>
                    <Text variant="caption" color="black75">
                      File berhasil diperbahurui.
                    </Text>
                  </Flex>
                  <Upload {...register} accept=".jpg, .jpeg, .png, .pdf">
                    <Flex cursor="pointer">
                      <Text variant="caption" color="blue50" weight="bold">
                        Ubah
                      </Text>
                    </Flex>
                  </Upload>
                </Flex>
              </Flex>
            ) : (
              <>
                <Text variant="caption" color="black50">
                  Upload file dengan format <br /> JPG, JPEG, PNG atau PDF.
                </Text>
                <Upload {...register} accept=".jpg, .jpeg, .png, .pdf">
                  <Button size="small" variant="secondary">
                    Upload Foto
                  </Button>
                </Upload>
              </>
            )}
          </Flex>
        </Flex>

        <Button
          size="medium"
          isBlock
          onClick={handleSave}
          disabled={!dataImage?.imageUrl || isLoading || !receiverName}
          preffix={isLoading && <Spinner color="white" size="8px" />}
        >
          Konfirmasi
        </Button>
      </Flex>
    </Drawer>
  );
};

export default PopupSellerFleetArrive;
