import MobileLayout from "layouts/MobileLayout";
import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Checkbox,
  Divider,
  Flex,
  Icon,
  Input,
  SelectInput,
  Text,
  isEmpty,
  Toggle,
  useToast,
} from "renos-ui";
import FormLayout from "../../components/FormLayout";
import { useGetCustomShipping } from "services/shipping";
import { useProductAddContext } from "store/productAdd/reducer";
import { StyledDisclosure } from "./styled";
import constants from "store/productAdd/constants";
import { currencyFormatter, imageAssetsLoader } from "helpers";
import { PRODUCT_PREORDER_OPTIONS } from "containers/web/product/add/single/constants";
import { useHistory } from "react-router-dom";
import useFeatureFlag from "hooks/useFeatureFlag";

const ProductShipping = () => {
  const isPreorderEnabled = useFeatureFlag("preorder");

  const { state, dispatch } = useProductAddContext();
  const {
    productShipping,
    isPreorder,
    uom,
    weight,
    length,
    width,
    height,
    preorderType,
    shippingType,
    preorder,
    variant,
    sku,
  } = state;

  const { data: shippingData } = useGetCustomShipping();

  const { goBack } = useHistory();
  const toast = useToast();

  const onShippingTypeChange = (value) => {
    dispatch({
      type: constants.SAVE_VALUE,
      payload: {
        shippingType: value,
      },
    });

    if (value === 1) {
      dispatch({
        type: constants.SAVE_VALUE,
        payload: {
          shippingType: value,
          productShipping: new Set([]),
        },
      });
    }
  };

  const [selectedPreorder, setSelectedPreorder] = useState(
    PRODUCT_PREORDER_OPTIONS[0]
  );

  useEffect(() => {
    if (!isEmpty(preorderType)) {
      setSelectedPreorder(
        PRODUCT_PREORDER_OPTIONS.find((data) => data.value === preorderType)
      );
    }
  }, [preorderType]);

  const onShippingCheckedAll = (serviceId, isCheckAll) => {
    const tempProductShipping = new Set(productShipping);
    serviceId.forEach((id) => {
      if (!isCheckAll) {
        tempProductShipping.add(id);
      } else {
        tempProductShipping.delete(id);
      }
    });
    dispatch({
      type: constants.SAVE_VALUE,
      payload: {
        productShipping: tempProductShipping,
      },
    });
  };

  const onCheckShipping = (id) => {
    if (productShipping?.has(id)) {
      const tempProductShipping = new Set(productShipping);
      tempProductShipping.delete(id);

      return dispatch({
        type: constants.SAVE_VALUE,
        payload: {
          productShipping: tempProductShipping,
        },
      });
    }

    dispatch({
      type: constants.SAVE_VALUE,
      payload: {
        productShipping: productShipping.add(id),
      },
    });
  };

  const onPreorderSwitched = (preorderState) => {
    if (!preorderState) {
      setSelectedPreorder(PRODUCT_PREORDER_OPTIONS[0]);
      dispatch({
        type: constants.SAVE_VALUE,
        payload: {
          preorderValue: PRODUCT_PREORDER_OPTIONS[0].min,
          preorder: PRODUCT_PREORDER_OPTIONS[0].min,
          preorderType: 1,
          isPreorder: !preorderState,
        },
      });

      return;
    }
    dispatch({
      type: constants.SAVE_VALUE,
      payload: {
        preorderValue: 0,
        preorder: 0,
        isPreorder: !preorderState,
      },
    });
  };

  const onPreorderValueChange = (value) => {
    dispatch({
      type: constants.SAVE_VALUE,
      payload: {
        preorder: value,
      },
    });
  };

  const onValueChange = (value, name) => {
    dispatch({
      type: constants.SAVE_VALUE,
      payload: {
        [name]: value,
      },
    });
  };

  const onFinish = () => {
    if ((variant === 0 && !weight) || !width || !height || !length) {
      toast({
        label: "Lengkapi data sebelum melanjutkan",
        placement: "bottom",
      });

      return;
    }

    if (shippingType === 2 && productShipping.size === 0) {
      toast({
        label: "Pilih layanan pengiriman sebelum melanjutkan",
        placement: "bottom",
      });

      return;
    }

    goBack();
  };

  const computedVolume = useMemo(
    () =>
      uom === 1
        ? Math.round((height * width * length) / 6000)
        : Math.round(((height * width * length) / 6000) * 1000),
    [height, width, length, uom]
  );

  const productWeight = useMemo(
    () =>
      variant === 1 ? Math.max(...sku.map((item) => item.weight)) : weight,
    [sku, variant, weight]
  );

  return (
    <MobileLayout
      header={{
        title: "Pengiriman",
        isHomepage: true,
        withBack: true,
        suffix: (
          <Flex alignItems="center" cursor="pointer" onClick={onFinish}>
            <Text color="blue75" weight="bold">
              Selesai
            </Text>
          </Flex>
        ),
      }}
    >
      <Flex
        padding="16px"
        flexDirection="column"
        width="100%"
        rowGap="24px"
        height="calc(100dvh - 92px)"
        overflow="auto"
      >
        {variant === 0 && (
          <FormLayout title="Berat Produk" required>
            <Flex flexDirection="column" rowGap="8px">
              <Input.Number
                placeholder="Berat produk"
                value={weight}
                max={uom === 1 ? 999 : 999999}
                sufix={
                  <Text color="black50" weight="bold">
                    {uom === 1 ? "kg" : "g"}
                  </Text>
                }
                size="small"
                onChange={(value) => onValueChange(value, "weight")}
              />
              <Text color="black50" variant="caption">
                Masukkan berat produk <strong>setelah dikemas</strong>
              </Text>
            </Flex>
          </FormLayout>
        )}
        <FormLayout title="Ukuran Produk" required>
          <Flex flexDirection="column" rowGap="8px">
            <Flex columnGap="12px">
              <Input.Number
                value={length}
                placeholder="Panjang"
                size="small"
                max={100000}
                sufix={
                  <Text weight="bold" color="black50">
                    cm
                  </Text>
                }
                onChange={(value) => onValueChange(value, "length")}
              />
              <Input.Number
                placeholder="Lebar"
                value={width}
                size="small"
                max={100000}
                sufix={
                  <Text weight="bold" color="black50">
                    cm
                  </Text>
                }
                onChange={(value) => onValueChange(value, "width")}
              />
              <Input.Number
                placeholder="Tinggi"
                value={height}
                size="small"
                max={100000}
                sufix={
                  <Text weight="bold" color="black50">
                    cm
                  </Text>
                }
                onChange={(value) => onValueChange(value, "height")}
              />
            </Flex>
            {productWeight < computedVolume && (
              <Flex justifyContent="space-between" columnGap="12px">
                <Flex
                  px="10px"
                  py="8px"
                  borderRadius="8px"
                  backgroundColor="blue5"
                  height="fit-content"
                  width="fit-content"
                >
                  <Text variant="caption" color="blue100">
                    Berat Volume: <br />
                    {currencyFormatter(computedVolume)}{" "}
                    {uom === 1 ? "kg" : "gr"}
                  </Text>
                </Flex>
                <Flex
                  flex={2}
                  padding="12px"
                  borderRadius="8px"
                  backgroundColor="yellow5"
                  height="fit-content"
                >
                  <Flex flexDirection="column" rowGap="4px">
                    <Flex gap="4px" alignItems="center">
                      <Icon name="Info-solid" color="yellow50" size={16} />
                      <Text weight="bold" variant="caption" color="yellow50">
                        Informasi
                      </Text>
                    </Flex>
                    <Text variant="caption" color="yellow100">
                      Ongkos pengiriman akan dihitung menggunakan berat volume
                      karena lebih berat dari berat yang diinput.
                    </Text>
                  </Flex>
                </Flex>
              </Flex>
            )}
            <Text color="black50" variant="caption">
              Masukkan ukuran produk <strong>setelah dikemas</strong>
            </Text>
          </Flex>
        </FormLayout>
        <FormLayout title="Layanan Pengiriman">
          <Flex flexDirection="column" rowGap="12px">
            <Flex
              alignItems="center"
              justifyContent="space-between"
              columnGap="8px"
              onClick={() => onShippingTypeChange(1)}
            >
              <Box as="label">
                <Text>Standar</Text>
              </Box>
              <Input.Radio
                name="shipping_type"
                checked={shippingType === 1}
                value="1"
              />
            </Flex>
            <Divider />
            <Flex
              alignItems="center"
              justifyContent="space-between"
              columnGap="8px"
              onClick={() => onShippingTypeChange(2)}
            >
              <Box as="label">
                <Text>Custom</Text>
              </Box>
              <Input.Radio
                name="shipping_type"
                value="2"
                checked={shippingType === 2}
              />
            </Flex>
            <Divider />
            {shippingType === 2 &&
              shippingData &&
              shippingData.map((service) => {
                const allServiceIndex = service.services.map((data) => data.id);
                const isCheckAll = allServiceIndex.every((data) =>
                  Array.from(productShipping).includes(data)
                );

                const isIndeterminate = allServiceIndex.some((data) =>
                  Array.from(productShipping).includes(data)
                );

                const isSingle = service.services.length === 1;

                return isSingle ? (
                  <Flex columnGap="8px" alignItems="center">
                    <Checkbox
                      checked={productShipping?.has(service.services[0].id)}
                      onChange={() => onCheckShipping(service.services[0].id)}
                    />
                    <Text
                      onClick={() => onCheckShipping(service.services[0].id)}
                    >
                      {service.services[0].name}
                    </Text>
                  </Flex>
                ) : (
                  <StyledDisclosure key={service.id}>
                    {({ isOpen, onToggle }) => (
                      <Flex flexDirection="column" width="100%">
                        <Flex
                          justifyContent="space-between"
                          cursor="pointer"
                          alignItems="center"
                          py="4px"
                        >
                          <Flex flexDirection="column" width="100%">
                            <Flex
                              columnGap="8px"
                              alignItems="center"
                              justifyContent="space-between"
                              width="100%"
                              mb={isOpen && "8px"}
                            >
                              <Flex columnGap="12px" alignItems="center">
                                <Checkbox
                                  checked={isCheckAll}
                                  indeterminate={isIndeterminate}
                                  onClick={() => {
                                    onShippingCheckedAll(
                                      allServiceIndex,
                                      isCheckAll
                                    );

                                    if (!isOpen) {
                                      onToggle();
                                    }
                                  }}
                                />
                                {service?.image_url && (
                                  <Box
                                    as="img"
                                    src={imageAssetsLoader(service.image_url)}
                                    alt="service_image"
                                    width="24px"
                                  />
                                )}
                                <Text weight="bold">{service.name}</Text>
                              </Flex>
                              <Icon
                                name={isOpen ? "Up-outline" : "Down-outline"}
                                size={24}
                                color="black50"
                                cursor="pointer"
                                onClick={onToggle}
                              />
                            </Flex>
                          </Flex>
                        </Flex>
                        {isOpen && (
                          <Flex
                            pl="32px"
                            pt="0"
                            flexDirection="column"
                            rowGap="8px"
                          >
                            {service?.services.map((data) => {
                              const isChecked = productShipping?.has(data.id);

                              return (
                                <Flex columnGap="8px" alignItems="center">
                                  <Checkbox
                                    checked={isChecked}
                                    onChange={() => onCheckShipping(data.id)}
                                  />
                                  <Text
                                    onClick={() => onCheckShipping(data.id)}
                                  >
                                    {data.name}
                                  </Text>
                                </Flex>
                              );
                            })}
                          </Flex>
                        )}
                      </Flex>
                    )}
                  </StyledDisclosure>
                );
              })}
          </Flex>
        </FormLayout>
        {isPreorderEnabled && (
          <FormLayout
            title="Pre Order"
            suffix={
              <Flex onClick={() => onPreorderSwitched(isPreorder)}>
                <Toggle checked={isPreorder} />
              </Flex>
            }
          >
            <Text color="black50" variant="caption">
              Aktifkan preorder jika produkmu membutuhkan waktu produksi sebelum
              dikirimkan.
            </Text>
          </FormLayout>
        )}
        {isPreorderEnabled && isPreorder && (
          <FormLayout title="Waktu Proses" required>
            <Flex columnGap="8px">
              <Input.Number
                name="preorder_value"
                hint={`Maksimum ${selectedPreorder.max} ${selectedPreorder.label}`}
                max={selectedPreorder.max}
                min={1}
                onChange={(value) => onPreorderValueChange(value)}
                size="small"
                placeholder="Waktu proses"
                onFocus={(event) => event.target.select()}
                value={preorder}
              />
              <Box width="160px">
                <SelectInput
                  size="small"
                  options={PRODUCT_PREORDER_OPTIONS}
                  value={selectedPreorder}
                  menuPlacement="top"
                  onChange={(item) => {
                    setSelectedPreorder(item);
                    onPreorderValueChange(item.min);
                    dispatch({
                      type: constants.SAVE_VALUE,
                      payload: {
                        preorderValue: item.min,
                        preorderType: item.value,
                      },
                    });
                  }}
                />
              </Box>
            </Flex>
          </FormLayout>
        )}
      </Flex>
    </MobileLayout>
  );
};

export default ProductShipping;
