import React, { useState } from "react";
import { Box, Flex, isEmpty } from "renos-ui";
import DashboardHeader from "widgets/desktop/DashboardHeader";
import Sidebar from "./Sidebar";
import DialogStoreClosed from "widgets/desktop/DialogStoreClosed/index";
// import { useCheckStore } from "services/auth";
import { useGetStoreDetail } from "services/store";

const DashboardLayout = ({ children }) => {
  const localMinimize = localStorage.getItem("isMinimize");

  const [isMinimized, setIsMinimized] = useState(
    localMinimize ? isEmpty(parseInt(localMinimize)) : false
  );

  const handleClick = () => {
    setIsMinimized((prev) => !prev);
    localStorage.setItem("isMinimize", isEmpty(isMinimized) ? 0 : 1);
  };

  const handleOnMouseLeave = () => {
    if (isEmpty(parseInt(localMinimize))) {
      setIsMinimized(true);
    }
  };

  const { data: storeData, isLoading: isStoreLoading } = useGetStoreDetail();

  return (
    <Flex
      width="100vw"
      height="100vh"
      columnGap="24px"
      backgroundColor="black5"
    >
      <Sidebar
        isMinimized={isMinimized}
        setIsMinimized={setIsMinimized}
        handleClick={handleClick}
        handleOnMouseLeave={handleOnMouseLeave}
      />

      {/* rest 100% - sidebar */}
      <Flex
        width={`calc(100% - ${isMinimized ? "120px" : "278px"})`}
        justifyContent="center"
        paddingY="24px"
        paddingRight="24px"
      >
        {/* wrapper for header and content */}
        <Flex flexDirection="column" rowGap="24px" width="100%" height="100%">
          <DashboardHeader />
          <Box height="100%" overflowY="auto" width="100%">
            {children}
          </Box>
        </Flex>
      </Flex>

      <DialogStoreClosed
        isOpen={!isStoreLoading && storeData && !storeData?.data?.active}
      />
    </Flex>
  );
};

export default DashboardLayout;
