import dayjs from "dayjs";
import { useMemo } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Flex, Icon, Text } from "renos-ui";

const TimerBox = () => {
  const [time, setTime] = useState(new Date());

  const formattedTime = useMemo(
    () =>
      `${dayjs(time).format("dddd")}, ${dayjs(time).format(
        "DD MMMM YYYY"
      )} - ${dayjs(time).format("HH.mm")} WIB`,
    [time]
  );

  useEffect(() => {
    const intervalTimer = setInterval(() => {
      setTime(new Date());
    }, 30000);

    return () => {
      clearInterval(intervalTimer);
    };
  }, []);

  return (
    <Flex
      flexDirection="row"
      alignItems="center"
      width="782px"
      backgroundColor="white"
      padding="12px 16px"
      boxShadow="0px 4px 4px rgba(20, 20, 32, 0.05)"
      borderRadius="8px"
      flexGrow="1"
      gap="14px"
    >
      <Icon name="Calendar-outline" size={24} color="blue50" />
      <Text>{formattedTime}</Text>
    </Flex>
  );
};

export default TimerBox;
