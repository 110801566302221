import ENDPOINTS from "constants/endpoints";
import { useQuery, useMutation } from "react-query";
import client from "services/client";

const getProductShowcase = async (params) => {
  const { data } = await client.get(ENDPOINTS.etalase, {
    params,
  });

  return data.data;
};

export const useGetProductShowcase = (params, extraParams) => {
  return useQuery(
    ["getProductShowcase", params],
    () => getProductShowcase(params),
    {
      ...extraParams,
    }
  );
};

const saveProductShowcaseFetcher = async (body) => {
  const response = await client.post(ENDPOINTS.etalase_save, body);

  return response;
};

export const useSaveProductShowcase = (extraParams) =>
  useMutation((body) => saveProductShowcaseFetcher(body), {
    ...extraParams,
  });

const updateProductShowcaseFetcher = async (body) => {
  const { tag_store_id, ...newBody } = body;
  const response = await client.put(
    `${ENDPOINTS.etalase_save}/${tag_store_id}`,
    newBody
  );

  return response;
};

export const useUpdateProductShowcase = (extraParams) =>
  useMutation((body) => updateProductShowcaseFetcher(body), {
    ...extraParams,
  });

const updateOrderProductShowcaseFetcher = async (body) => {
  const response = await client.put(ENDPOINTS.etalase_update_order, body);

  return response;
};

export const useUpdateOrderProductShowcase = (extraParams) =>
  useMutation((body) => updateOrderProductShowcaseFetcher(body), {
    ...extraParams,
  });

const deleteProductShowcaseFetcher = async (id) => {
  const { data } = await client.delete(`${ENDPOINTS.etalase_delete}/${id}`, id);

  return data;
};

export const useDeleteProductShowcase = (extraParams) =>
  useMutation((id) => deleteProductShowcaseFetcher(id), {
    ...extraParams,
  });

const uploadImageProductShowcase = async (body) => {
  try {
    const { data } = await client.post(ENDPOINTS.etalase_upload_image, body, {
      headers: { "Content-Type": "multipart/form-data" },
    });

    return data.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const useUploadImageProductShowcase = (extraParams) =>
  useMutation((body) => uploadImageProductShowcase(body), {
    ...extraParams,
  });

const saveImageShowcaseFetcher = async (body) => {
  const { tag_store_id, ...newBody } = body;

  const response = await client.put(
    `${ENDPOINTS.etalase_save_image}/${tag_store_id}/update-image`,
    {
      ...newBody,
    }
  );

  return response;
};

export const useSaveImageShowcase = (extraParams) =>
  useMutation((body) => saveImageShowcaseFetcher(body), {
    ...extraParams,
  });

const getProductListImage = async (params) => {
  const { data } = await client.get(ENDPOINTS.productsv3, {
    params,
  });

  return data?.data;
};

export const useGetProductListImage = (params, extraParams) => {
  return useQuery(
    ["productListImage", params],
    () => getProductListImage({ ...params }),
    {
      ...extraParams,
    }
  );
};

const getProductList = async (params) => {
  const { data } = await client.get(ENDPOINTS.productsv3, {
    params,
  });

  return data;
};

export const useGetProducts = (params, extraParams) => {
  return useQuery(["products", params], () => getProductList(params), {
    ...extraParams,
    params,
  });
};

const getProductListModal = async (params) => {
  const { data } = await client.get(ENDPOINTS.productsv3, {
    params,
  });

  return data;
};

export const useGetProductsModal = (params, extraParams) => {
  return useQuery(["products", params], () => getProductListModal(params), {
    ...extraParams,
    params,
  });
};