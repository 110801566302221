import { Flex, Text } from "renos-ui";

const Empty = () => {
  return (
    <Flex
      width="100%"
      height="calc(100% - 16px)"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Flex
        as="img"
        height="200px"
        width="200px"
        alt="no order"
        src="/assets/images/mobile/no-order.svg"
      />
      <Flex
        flexDirection="column"
        gap="4px"
        alignItems="center"
        textAlign="center"
      >
        <Text weight="bold">Belum ada pesanan di tokomu</Text>
        <Text variant="caption" color="black75">
          Pesanan dari pembeli akan ditampilkan <br />
          di halaman ini.
        </Text>
      </Flex>
    </Flex>
  );
};

export default Empty;
