import styled from "styled-components";
import { Button, colors, Dialog } from "renos-ui";

export const StyledButtonIcon = styled(Button)`
  padding: 8px;
`;

export const StyledDialog = styled(Dialog)`
  padding: 24px;
`;

export const StyledButton = styled(Button)`
  :disabled {
    span {
      color: ${colors.black25};
    }
  }
`;
