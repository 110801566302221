import { Fragment } from "react";
import { Flex, range, Skeleton, Divider } from "renos-ui";

const SkeletonReview = () => {
  return (
    range(10).map((_, i) => (
      <Fragment key={`skeleton-review${i}`}>
        <Flex justifyContent="flex-start" gap="24px">
          <Flex
            flexDirection="column"
            gap="8px"
            width="376px"
            maxWidth="376px"
          >
            <Flex gap="8px">
              <Skeleton width="100%" />
            </Flex>
            <Flex gap="10px">
              <Skeleton.Image
                width="36px"
                height="36px"
              />
              <Skeleton width="100%" />
            </Flex>
          </Flex>
          <Divider orientation='vertical' />
          <Flex
            flexDirection="column"
            gap="16px"
            maxWidth="732px"
            flexWrap="wrap"
          >
            <Flex
              flexDirection="column"
              gap="10px"
              flexWrap="wrap"
            >
              <Skeleton width="100%" />
              <Skeleton width="100%" />
            </Flex>
            <Flex gap="10px">
              {range(6).map((_, idx) => (
                <Skeleton.Image
                  key={idx}
                  width="56px"
                  height="56px"
                />
              ))}
            </Flex>
            <Flex width="664px" flexDirection="column">
              <Skeleton
                width="100%"
                height="80px"
              />
            </Flex>
          </Flex>
        </Flex>
      </Fragment>
    )
    ));
};

export default SkeletonReview;