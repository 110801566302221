import styled from "styled-components";
import { Dropdown, Flex, colors } from "renos-ui";

export const StyledDropDownContent = styled(Dropdown.Content)`
  background-color: unset;
  box-shadow: none;
  z-index: 2;

  & > div > div:nth-child(1) {
    justify-content: center;
  }
`;

export const StyledFlex = styled(Flex)`
  &:hover {
    background-color: ${colors.black5};
  }
`;

export const Wrapper = styled(Flex)`
  word-wrap: break-word;
  display: inline-block;
  box-sizing: border-box;
`;
