import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Dropdown,
  Flex,
  Icon,
  Pagination,
  Text,
  useDisclosure,
  useToast,
} from "renos-ui";
import { useGetProducts } from "services/discount";
import { useDeleteProduct } from "services/promo-management";
import Table from "widgets/desktop/TableDiscount";
import { TableSkeleton } from "./Skeleton";
import { ProductImage } from "./styled";
import EmptyData from "./EmptyData";
import dayjs from "dayjs";
import { useHistory } from "react-router-dom";
import desktopUrl from "constants/desktopUrl";
import { useQueryClient } from "react-query";
import FailLoadData from "widgets/global/FailLoadData";
import DeleteConfirmation from "./DeleteConfirmation";

const ProductTable = ({
  page,
  setPage,
  productStatus,
  activeTab,
  filterDisclosure,
  filterParam,
}) => {
  const history = useHistory();
  const queryClient = useQueryClient();
  const toast = useToast();
  const disclosureDelete = useDisclosure({ isOpen: false });

  const {
    data: productResponse,
    isLoading,
    isFetching,
    isError,
    refetch,
  } = useGetProducts({
    ...filterParam,
    page,
    product_discount_statuses: productStatus,
    limit: 10,
  });

  const isNotReady = useMemo(
    () => isLoading || isFetching,
    [isLoading, isFetching]
  );

  const productsData = useMemo(
    () => productResponse?.data || [],
    // eslint-disable-next-line
    [productResponse, isNotReady]
  );

  const productPagination = useMemo(
    () => productResponse?.pagination || [],
    // eslint-disable-next-line
    [productResponse, isNotReady]
  );

  const [isCheckAll, setIsCheckAll] = useState(false);

  const [checkedProducts, setCheckedProducts] = useState([]);
  const [paramDelete, setParamDelete] = useState({});

  useEffect(() => {
    setIsCheckAll(false);
    setCheckedProducts([]);
  }, [activeTab]);

  const onCheckAllClicked = () => {
    if (checkedProducts.length < productsData.length) {
      setCheckedProducts(productsData.map((data) => data.product_id));
    }

    if (checkedProducts.length === productsData.length) {
      setCheckedProducts([]);
    }

    setIsCheckAll((prev) => !prev);
  };

  const onCheckProductClicked = (id) => {
    if (isCheckAll) {
      setIsCheckAll(false);
    }

    if (checkedProducts.includes(id)) {
      const selectedIndex = checkedProducts.findIndex((data) => data === id);

      const tempChecked = [...checkedProducts];
      tempChecked.splice(selectedIndex, 1);

      return setCheckedProducts(tempChecked);
    }
    setCheckedProducts((prev) => [...prev, id]);
  };

  const handleSetDataDelete = (dataProduct) => {
    const payload = [];

    if (dataProduct) {
      dataProduct.product_skus.forEach((discount) => {
        if (discount.active_product_discount.product_discount_id !== null) {
          payload.push(discount.active_product_discount.product_discount_id);
        }
      });
    } else {
      if (isCheckAll) {
        productsData.forEach((product) => {
          product.product_skus.forEach((discount) => {
            if (discount.active_product_discount.product_discount_id !== null) {
              payload.push(
                discount.active_product_discount.product_discount_id
              );
            }
          });
        });
      } else {
        productsData.forEach((product) => {
          if (checkedProducts.includes(product.product_id)) {
            product.product_skus.forEach((discount) => {
              if (
                discount.active_product_discount.product_discount_id !== null
              ) {
                payload.push(
                  discount.active_product_discount.product_discount_id
                );
              }
            });
          }
        });
      }
    }

    setParamDelete({ product_discount_ids: payload });
    disclosureDelete.onOpen();
  };

  const { mutate: deleteProduct, isLoading: isDeleteLoading } =
    useDeleteProduct({
      onSuccess: (data) => {
        const { front_end_message, frontend_message, message } = data;

        toast({
          label:
            front_end_message ||
            frontend_message ||
            message ||
            "Berhasil mengakhiri diskon.",
          placement: "top",
        });
        setIsCheckAll(false);
        setCheckedProducts([]);
        setParamDelete({});
        disclosureDelete.onClose();
      },
      onError: (data) => {
        const {
          response: {
            data: { front_end_message, frontend_message, message },
          },
        } = data;
        toast({
          label:
            front_end_message ||
            frontend_message ||
            message ||
            "Gagal mengakhiri diskon.",
          placement: "top",
          backgroundColor: "red50",
        });
      },
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: ["products"],
        });
        queryClient.invalidateQueries({
          queryKey: ["product_status"],
        });
      },
    });

  const isInterdeterminate = useMemo(() => {
    if (checkedProducts && productsData) {
      return (
        checkedProducts.length > 0 &&
        checkedProducts.length < productsData.length
      );
    }

    return false;
  }, [checkedProducts, productsData]);

  const handleEdit = (isSingle, dataSingle) => {
    let arrEdit = [];

    if (isSingle) {
      arrEdit.push(dataSingle.product_id);
    } else {
      const formattedChecked = dataSource
        .filter((filterProduct) =>
          checkedProducts.includes(filterProduct.product_id)
        )
        .map((product) => {
          return product.product_id;
        });

      arrEdit = arrEdit.concat(formattedChecked);
    }

    history.push(`${desktopUrl.SETDISCOUNT}?id=${arrEdit.join(",")}`);
  };

  const tableColumns = [
    {
      title: (
        <Checkbox
          checked={isCheckAll}
          onChange={onCheckAllClicked}
          indeterminate={isInterdeterminate}
        />
      ),
      dataIndex: "check",
      key: "checkBox",
      render: (_, data) => {
        const { product_id: id } = data;

        return (
          <Checkbox
            checked={checkedProducts.includes(id)}
            onChange={() => onCheckProductClicked(id)}
          />
        );
      },
      width: "40px",
      align: "center",
    },
    {
      title: "Informasi Produk",
      dataIndex: "product_information",
      key: "product_information",
      render: (_, data, isVariant) => {
        const {
          product_image_url,
          product_id,
          product_name,
          product_sku_mpn,
          formatted_variant_value_name,
          product_type,
          product_skus,
        } = data;

        return (
          <Flex alignItems="center" columnGap="8px">
            <ProductImage
              as="img"
              src={product_image_url}
              alt={`product-img-${product_id}`}
              width="64px"
              height="64px"
              borderRadius="8px"
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = "/assets/images/empty-product.svg";
              }}
            />
            <Flex flexDirection="column" rowGap="4px">
              <Text weight="bold" lineClamp={1}>
                {isVariant ? formatted_variant_value_name : product_name}
              </Text>

              <Text variant="caption" color="black50">
                {data.formatted_product_price}
              </Text>

              <Flex gap="12px">
                <Text variant="caption" color="black50">
                  Stok: {data.product_stock}
                </Text>
                <Text variant="caption" color="black50">
                  SKU:{" "}
                  {product_type !== "non_variant"
                    ? product_sku_mpn
                    : product_skus.length > 0
                    ? product_skus[0]?.product_sku_mpn || "-"
                    : "-"}
                </Text>
              </Flex>
            </Flex>
          </Flex>
        );
      },
    },
    {
      title: "Diskon",
      dataIndex: "formatted_product_discount",
      key: "formatted_product_discount",
      render: (_, data, isVariant) => {
        const {
          formatted_product_discount_percentage,
          active_product_discount,
        } = data;

        return isVariant ? (
          <Text color="red50" weight="bold">
            {active_product_discount?.formatted_product_discount_percentage}
          </Text>
        ) : (
          <Text color="red50" weight="bold">
            {formatted_product_discount_percentage}
          </Text>
        );
      },
      width: "120px",
    },

    {
      title: "Harga Diskon",
      dataIndex: "formatted_product_price",
      isTooltip: true,
      tooltip: "Harga produk setelah dipotong diskon.",
      key: "formatted_product_price",
      render: (_, data, isVariant) => {
        const { active_product_discount, formatted_product_discount_price } =
          data;

        return isVariant ? (
          active_product_discount ? (
            <Text color="black75">
              {active_product_discount?.formatted_product_discount_price}
            </Text>
          ) : (
            <Text color="black75">{formatted_product_discount_price}</Text>
          )
        ) : (
          <Text color="black75">{formatted_product_discount_price}</Text>
        );
      },
      width: "210px",
    },

    {
      title: "Kuota",
      dataIndex: "product_stock",
      isTooltip: true,
      tooltip: "Jumlah stok produk yang diberikan diskon.",
      key: "product_stock",
      render: (_, data, isVariant, product_type) => {
        const { active_product_discount, product_skus } = data;

        return isVariant ? (
          <Text color="black75">
            <b>{active_product_discount?.product_discount_quota_used}</b>/
            {active_product_discount?.product_discount_quota}
          </Text>
        ) : !isVariant && product_type === "non_variant" ? (
          <Text color="black75">
            <b>
              {product_skus.length > 0
                ? product_skus[0].active_product_discount
                    ?.product_discount_quota_used
                : 0}
            </b>
            /
            {product_skus.length > 0
              ? product_skus[0].active_product_discount?.product_discount_quota
              : 0}
          </Text>
        ) : (
          <></>
        );
      },
      width: "100px",
    },

    {
      title: "Periode Diskon",
      dataIndex: "formatted_product_price",
      isTooltip: true,
      tooltip: "Atur waktu mulai dan berakhirnya diskon.",
      key: "formatted_product_price",
      render: (_, data, isVariant, product_type) => {
        const { active_product_discount, product_skus } = data;

        return isVariant ? (
          <>
            <Box>
              <Text color="black75">
                {dayjs(
                  active_product_discount?.formatted_product_discount_date_start
                ).format("DD MMM YYYY (HH:mm)")}{" "}
                -
              </Text>
            </Box>
            <Box>
              <Text color="black75">
                {dayjs(
                  active_product_discount?.formatted_product_discount_date_end
                ).format("DD MMM YYYY (HH:mm)")}
              </Text>
            </Box>
          </>
        ) : !isVariant && product_type === "non_variant" ? (
          <>
            <Box>
              <Text color="black75">
                {product_skus.length > 0 &&
                  dayjs(
                    product_skus[0].active_product_discount
                      ?.formatted_product_discount_date_start
                  ).format("DD MMM YYYY (HH:mm)")}{" "}
                -
              </Text>
            </Box>
            <Box>
              <Text color="black75">
                {product_skus.length > 0 &&
                  dayjs(
                    product_skus[0].active_product_discount
                      ?.formatted_product_discount_date_end
                  ).format("DD MMM YYYY (HH:mm)")}
              </Text>
            </Box>
          </>
        ) : (
          <Flex />
        );
      },
      width: "148px",
    },
    {
      title: "Aksi",
      dataIndex: "cta",
      key: "cta",
      render: (_, data) => {
        return (
          <Dropdown>
            <Dropdown.Toggle>
              <Flex
                height="40px"
                alignItems="center"
                justifyContent="space-between"
                border="1px"
                borderColor="black10"
                borderRadius={8}
                borderStyle="solid"
                px="12px"
                marginRight="4px"
                cursor="pointer"
              >
                <Icon name="Vertical-outline" color="black50" size={20} />
              </Flex>
            </Dropdown.Toggle>

            <Dropdown.Content>
              {activeTab === "created" && (
                <Dropdown.Item>
                  <Flex px="16" py="6px" onClick={() => handleEdit(true, data)}>
                    Atur Diskon
                  </Flex>
                </Dropdown.Item>
              )}
              <Dropdown.Item onClick={() => handleSetDataDelete(data)}>
                <Flex px="16" py="6px">
                  Akhiri Diskon
                </Flex>
              </Dropdown.Item>
            </Dropdown.Content>
          </Dropdown>
        );
      },
      width: "72px",
    },
  ];

  const pageCount = useMemo(
    () =>
      productPagination
        ? Math.ceil(
            productPagination.total / (productPagination.per_page || 10)
          )
        : 1,

    [productPagination]
  );

  const dataSource =
    !isNotReady &&
    productsData.map((productData) => {
      const { product_type } = productData;

      if (product_type === "variant") {
        productData.isHasMore = true;
      }

      return productData;
    });

  useEffect(() => {
    setIsCheckAll(false);
    setCheckedProducts([]);
  }, [filterParam]);

  return (
    <Flex
      flexDirection="column"
      rowGap="24px"
      width="100%"
      alignItems="center"
      height="100%"
      overflow="hidden"
    >
      {isNotReady ? (
        <TableSkeleton />
      ) : isError ? (
        <FailLoadData onClick={refetch} />
      ) : dataSource.length < 1 ? (
        <EmptyData />
      ) : (
        <Table
          columns={tableColumns}
          dataSource={dataSource}
          width="100%"
          overflow="auto"
          customHeader={
            checkedProducts.length > 0 && (
              <Flex columnGap="20px" alignItems="center" pb="12px">
                <Checkbox
                  checked={isCheckAll}
                  onChange={onCheckAllClicked}
                  indeterminate={
                    checkedProducts.length > 0 &&
                    checkedProducts.length < productsData.length
                  }
                />
                <Flex alignItems="center" columnGap="24px">
                  <Text>
                    <strong>{checkedProducts.length}</strong> /{" "}
                    {dataSource.length} Produk Dipilih
                  </Text>
                  <Flex columnGap="8px">
                    {/* <Button variant="tertiary" size="small">
                      Pindahkan Etalase
                    </Button> */}
                    {/* <Button variant="tertiary" size="small">
                          {activeTab === "nonActive"
                            ? "Aktifkan"
                            : "Nonaktifkan"}
                        </Button> */}
                    <Button
                      variant="tertiary"
                      size="small"
                      onClick={() => {
                        setCheckedProducts([]);
                        setIsCheckAll(false);
                      }}
                    >
                      Batal
                    </Button>
                    <Button
                      variant="tertiary"
                      size="small"
                      onClick={() => handleSetDataDelete(false)}
                    >
                      Akhiri Diskon
                    </Button>
                    {activeTab === "created" && (
                      <Button
                        variant="tertiary"
                        size="small"
                        onClick={() => handleEdit(false)}
                      >
                        Atur Diskon
                      </Button>
                    )}
                  </Flex>
                </Flex>
              </Flex>
            )
          }
        />
      )}
      <Flex
        flexDirection="column"
        rowGap="24px"
        width="100%"
        alignItems="flex-end"
        height="100%"
        overflow="hidden"
      >
        <Pagination
          current={page}
          onChange={(page) => {
            setPage(page);
            setIsCheckAll(false);
            setCheckedProducts([]);
          }}
          total={pageCount}
        />
      </Flex>

      <DeleteConfirmation
        isOpen={disclosureDelete.isOpen}
        onClose={disclosureDelete.onClose}
        onReject={() => deleteProduct(paramDelete)}
        isLoading={isDeleteLoading}
      />
    </Flex>
  );
};

export default ProductTable;
