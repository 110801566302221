import mobileUrl from "constants/mobileUrl";
import { getDayAsHumane, getTimeAsHumane } from "helpers";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  Box,
  Button,
  Divider,
  Drawer,
  Flex,
  Icon,
  Input,
  Spinner,
  Text,
} from "renos-ui";

const FormDrawer = ({
  form,
  visible,
  onClose,
  onSubmit,
  loading,
  storeInformation,
  handleChangeInput,
}) => {
  const { push } = useHistory();

  const handleClickAturJamOperasional = () => {
    push(mobileUrl.STORE_STATUS);
  };

  return (
    <Drawer
      isFullPage
      onClose={onClose}
      placement="bottom"
      isVisible={visible}
      padding="0"
      header={{
        render: (
          <Flex
            gap="16px"
            padding="16px"
            boxShadow="0 0 8px 0 rgba(20, 20, 32, 0.10)"
          >
            <Flex onClick={onClose}>
              <Icon name="Close-outline" color="black50" />
            </Flex>
            <Flex>
              <Text variant="subtitle" weight="bold">
                {form?.title}
              </Text>
            </Flex>
          </Flex>
        ),
      }}
    >
      <Flex gap="16px" flexDirection="column" padding="16px">
        <Flex>
          <Input.TextArea
            rows="6"
            showCount
            size="small"
            maxLength={200}
            label="Isi Template"
            disabled={loading}
            onChange={handleChangeInput}
            placeholder="Masukan isi template"
            value={form?.templateMessage || ""}
          />
        </Flex>

        {form?.templateType === 2 && (
          <Flex
            padding="16px"
            borderRadius="8px"
            backgroundColor="black5"
            flexDirection="column"
          >
            <Text variant="caption" color="black75" weight="bold">
              Jam operasional toko kamu
            </Text>
            <Box marginY="8px">
              {storeInformation?.isLoading && <Spinner size={12} />}
              {storeInformation?.items?.map((schedule, index) => (
                <Flex key={`operational-hour-${index}`}>
                  <Flex width="40%">
                    <Text variant="caption" color="black75">
                      {getDayAsHumane(schedule.day_humane)}
                    </Text>
                  </Flex>
                  <Divider orientation="vertical" marginRight="16px" />
                  <Flex alignItems="flex-start">
                    <Text variant="caption" color="black75">
                      {getTimeAsHumane(schedule.time)}
                    </Text>
                  </Flex>
                </Flex>
              ))}
            </Box>
            <Flex onClick={handleClickAturJamOperasional}>
              <Text variant="caption" color="blue50" weight="bold">
                Atur Jam Operasional
              </Text>
            </Flex>
          </Flex>
        )}
      </Flex>
      <Flex
        left="0"
        right="0"
        bottom="0"
        padding="16px"
        position="fixed"
        backgroundColor="white"
        boxShadow="0 0 8px 0 rgba(0, 0, 0, 0.05)"
      >
        <Button
          isBlock
          size="medium"
          variant="primary"
          disabled={loading}
          onClick={onSubmit}
        >
          {loading ? "Menyimpan Perubahan..." : "Simpan Perubahan"}
        </Button>
      </Flex>
    </Drawer>
  );
};

export default FormDrawer;
