import dayjs from "dayjs";
import { useEffect } from "react";
import { useMemo, useState } from "react";
import {
  Button,
  DatePicker,
  Divider,
  Dropdown,
  Flex,
  Icon,
  Text,
  useDisclosure,
} from "renos-ui";
import { StyledInputRange, Wrapper } from "./styled";

const InputDatepicker = ({ value, setValue, defaultToday, setChanged }) => {
  const disclosurePopup = useDisclosure({ isOpen: false });

  const [selectedDate, setSelectedDate] = useState(value);

  const handleSave = () => {
    setValue(selectedDate);
    disclosurePopup.onToggle();
    setChanged(true);
  };

  const handleReset = () => {
    setSelectedDate({ startDate: "", endDate: "" });
    setValue({ startDate: "", endDate: "" });
    disclosurePopup.onToggle();
    setChanged(true);
  };

  const getMaxDate = (tempDate) => {
    if (tempDate)
      return new Date(tempDate.getTime() + 29 * 24 * 60 * 60 * 1000);

    return defaultToday.endDate;
  };

  const maxDateRange = useMemo(
    () => getMaxDate(selectedDate.startDate), // eslint-disable-next-line
    [selectedDate],
  );

  const maxDate = useMemo(
    () =>
      maxDateRange.getTime() < defaultToday.endDate.getTime()
        ? maxDateRange
        : defaultToday.endDate,
    [maxDateRange, defaultToday],
  );

  const formattedDate = useMemo(() => {
    if (value.startDate && value.endDate)
      return `${dayjs(value.startDate).format("DD MMM YYYY")} - ${dayjs(
        value.endDate,
      ).format("DD MMM YYYY")}`;

    return "";
  }, [value]);

  useEffect(() => setSelectedDate(value), [value]);

  return (
    <Dropdown control={disclosurePopup}>
      <Dropdown.Toggle>
        <StyledInputRange
          size="small"
          sufix={
            <Icon
              name="Calendar-outline"
              size={20}
              color={disclosurePopup.isOpen ? "blue50" : "black50"}
            />
          }
          value={formattedDate}
          readOnly
          placeholder="Pilih tanggal"
        />
      </Dropdown.Toggle>
      <Dropdown.Content>
        <Flex padding="20px 24px 0 24px" flexDirection="column">
          <Wrapper>
            <DatePicker.Range
              startDate={selectedDate.startDate}
              endDate={selectedDate.endDate}
              onChange={([startDate, endDate]) =>
                setSelectedDate({ startDate, endDate })
              }
              maxDate={maxDate}
              dateFormat="dd MMM yyyy"
              inline
            />
          </Wrapper>
          <Divider />
          <Flex
            pt="16px"
            pb="12px"
            justifyContent="space-between"
            alignItems="center"
          >
            <Text color="black50" variant="caption">
              *Kamu hanya bisa memilih maksimal 30 hari
            </Text>
            <Flex gap="12px">
              <Button variant="tertiary" size="small" onClick={handleReset}>
                Reset
              </Button>
              <Button
                size="small"
                onClick={handleSave}
                disabled={!selectedDate.endDate}
              >
                Simpan
              </Button>
            </Flex>
          </Flex>
        </Flex>
      </Dropdown.Content>
    </Dropdown>
  );
};

export default InputDatepicker;
