import ENDPOINTS from "constants/endpoints";
import { productCategoriesToOptions } from "helpers/attribute-mapper/product-category";
import { useQuery } from "react-query";
import client from "../client";

const getCategories = async () => {
  const { data } = await client.get(ENDPOINTS.categories);

  const categoryData = productCategoriesToOptions(data.data);

  return categoryData;
};

export const useGetCategories = (extraParams) => {
  return useQuery(["categories"], getCategories, {
    ...extraParams,
  });
};
