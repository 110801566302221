import { useState } from "react";
import { useDeliveryContext } from "store/seller-fleet/reducer";
import { isEmpty, useToast } from "renos-ui";
import { currencyFormatter } from "helpers";

const useValidation = () => {
  const { state } = useDeliveryContext();
  const { dataRates, dataCoverage } = state;
  const toast = useToast();
  const [validation, setValidation] = useState({
    dataCoverage: [
      {
        selectedProvince: false,
        estimateDays: false,
        estimateDaysText: "",
      },
    ],
    dataRates: {
      multiple: {
        weight: false,
        price: false,
        weightText: "Kelipatan berat harus diisi",
        weightTextPrice: "Tarif harus diisi",
      },
      group: [{ min: false, max: false, price: false }],
      advancedOptions: [
        {
          condition_type_id: false,
          condition_weight: false,
          condition_base_id: false,
          condition_base_weight_value: false,
          condition_base_tarif: false,
          condition_base_weight_start: false,
          condition_base_weight_end: false,
        },
      ],
    },
  });

  const handleError = () => {
    let countError = 0;

    const validateDataCoverage = dataCoverage.map((coverage, indexCoverage) => {
      const { selectedProvince, estimateDays } = coverage;
      const checkProvince = isEmpty(selectedProvince);
      let checkRange = false;

      const [firstVal, secondVal] = estimateDays.split("-");
      const singleNumber = estimateDays.split("-").length;
      const arrVal = estimateDays.split("");
      const hasTilde = arrVal.includes("~");
      const hasBacktick = arrVal.includes("`");

      if (hasTilde
        || hasBacktick
        || singleNumber <= 1
        || (estimateDays && estimateDays.length === 0)
        || Number(secondVal) - Number(firstVal) < 1
        || arrVal[0] === "-"
        || Number(firstVal) === 0
      ) {
        checkRange = true;
      }

      if (checkProvince) countError++;

      if (checkRange) countError++;

      return {
        selectedProvince: checkProvince,
        estimateDays: checkRange,
        estimateDaysText:
          validation.dataCoverage[indexCoverage]?.estimateDaysText ||
          "Estimasi sampai harus diisi",
      };
    });

    const validateDataRates = {
      multiple: {
        weight: false,
        price: false,
        weightText: "Kelipatan berat harus diisi",
        weightTextPrice: "Tarif harus diisi",
      },
      group: [{ max: false, price: false }],
      advancedOptions: [
        {
          condition_weight: false,
        },
      ],
    };

    if (dataRates.calculation.type === "multiple") {
      if (
        dataRates?.calculation?.multiple?.weight === 0 ||
        !dataRates?.calculation?.multiple?.weight
      ) {
        if (dataRates?.calculation?.multiple?.weight === 0) {
          validateDataRates.multiple.weightText =
            "Kelipatan berat harus lebih dari 0";
        }

        validateDataRates.multiple.weight = true;
        countError++;
      }

      if (
        dataRates?.calculation?.multiple?.price !== 0 &&
        !dataRates?.calculation?.multiple?.price
      ) {
        validateDataRates.multiple.weightTextPrice = "Tarif harus diisi";
        validateDataRates.multiple.price = true;
        countError++;
      }

      if (dataRates?.calculation?.multiple?.weight > 500000) {
        validateDataRates.multiple.weightText =
          "Berat maksimal adalah 500.000 g";
        validateDataRates.multiple.weight = true;
        countError++;
      }

      if (dataRates?.calculation?.multiple?.price > 100000000) {
        validateDataRates.multiple.weightTextPrice =
          "Tarif maksimal adalah Rp 100.000.000";
        validateDataRates.multiple.price = true;
        countError++;
      }
    } else if (dataRates.calculation.type === "group") {
      validateDataRates.group = dataRates?.calculation?.group.map(
        (group, indexGroup) => {
          const { min, max, price } = group;
          const arrGroup = dataRates?.calculation?.group;

          let maxError = max !== 0 && (!max || false);
          let minError = min <= 0 && (!min || false);
          let priceError = price !== 0 && (!price || false);
          let maxText = "Berat maksimum harus diisi";
          let minText = "Berat minimum harus lebih dari 0";
          let priceText = "Tarif harus diisi";

          if (max <= min) {
            maxError = true;
            maxText = "Berat maksimum harus lebih besar dari berat minimum";
          }

          if (min > 500000) {
            minError = true;
            minText = "Berat maksimal adalah 500.000 g";
          }

          if (max > 500000) {
            maxError = true;
            maxText = "Berat maksimal adalah 500.000 g";
          }

          if (price > 100000000) {
            priceError = true;
            priceText = "Tarif maksimal adalah Rp 100.000.000";
          }

          if (indexGroup !== 0) {
            if (group.price < arrGroup[indexGroup - 1].price) {
              priceError = true;
              priceText =
                "Tarif harus lebih besar atau sama dari tarif sebelumnya";
            }
          }

          if (maxError || priceError || minError) {
            countError++;
          }

          return {
            max: maxError,
            maxText: maxText,
            min: minError,
            minText: minText,
            price: priceError,
            priceText: priceText,
          };
        }
      );
    } else if (dataRates.calculation.type === "advanced_options") {
      validateDataRates.advancedOptions =
        dataRates?.calculation?.advanced_options.map((advancedOption) => {
          if (isEmpty(dataRates?.calculation?.advanced_options[0]?.condition_type_id) || dataRates?.calculation?.advanced_options[0]?.condition_weight === false) {
            toast({
              label: "Beberapa kondisi harus diisi",
              placement: "bottom",
              backgroundColor: "red50",
            });
          }

          const {
            condition_weight: conditionWeight,
            condition_type_id: conditionTypeId,
            condition_base_id: conditionBaseId,
            condition_base_tarif: conditionBaseTarif,
            condition_base_weight_end: conditionBaseWeightEnd,
            condition_base_weight_start: conditionBaseWeightStart,
            condition_base_weight_value: conditionBaseWeightValue,
          } = advancedOption;

          let conditionBaseTarifErrorText = "Tarif harus diisi";
        
          const conditionWeightError = conditionWeight === 0 
            || !conditionWeight 
            || conditionWeight > 500000 
            || false;

          const conditionWeightText = conditionWeight === 0
              ? "Berat harus lebih dari 0"
              : conditionWeight > 500000
              ? "Berat maksimal adalah 500.000 g"
              : "Berat harus diisi";

          let conditionBaseTarifError = false;
          let conditionSelectError = false;

          let conditionBaseWeightValueError;
          let conditionBaseWeightValueErrorText;
          let conditionBaseWeightStartError = false;
          let conditionBaseWeightStartErrorText;
          let conditionBaseWeightEndError = false;
          let conditionBaseWeightEndErrorText;

          let conditionSelectText = "";

          if (conditionTypeId === null) {
            conditionSelectText = "Kondisi harus dipilih";
            conditionSelectError = true;
          }

          if (conditionBaseTarif > 100000000) {
            conditionBaseTarifErrorText =
              "Tarif maksimal adalah Rp 100.000.000";
            conditionBaseTarifError = true;
          }

          if (conditionBaseTarif === undefined) {
            conditionBaseTarifErrorText = "Tarif harus diisi";
            conditionBaseTarifError = true;
          }

          if (conditionTypeId === 1) {
            if (conditionBaseId === 1) {
              if (conditionBaseWeightValue >= conditionWeight) {
                conditionBaseWeightValueErrorText = `Kelipatan berat harus kurang dari ${currencyFormatter(
                  conditionWeight
                )}`;
                conditionBaseWeightValueError = true;
              } else if (
                conditionBaseWeightValue === 0 ||
                conditionBaseWeightValue === undefined
              ) {
                conditionBaseWeightValueErrorText =
                  "Kelipatan berat harus lebih dari 0";
                conditionBaseWeightValueError = true;
              }
            } else {
              if (
                conditionBaseWeightStart === undefined ||
                conditionWeight === undefined
              ) {
                conditionBaseWeightStartErrorText = "Berat minimum harus diisi";
                conditionBaseWeightStartError = true;
              }

              if (
                conditionBaseWeightStart === 0
              ) {
                conditionBaseWeightStartErrorText = "Kelipatan berat harus lebih dari 0";
                conditionBaseWeightStartError = true;
              }

              if (
                conditionBaseWeightStart === undefined &&
                conditionBaseWeightEnd === 0
              ) {
                conditionBaseWeightStartErrorText = "Berat minimum harus diisi";
                conditionBaseWeightStartError = true;
              }

              if (conditionBaseWeightStart > 500000) {
                conditionBaseWeightStartErrorText =
                  "Berat minimum maksimal 500.000 g";
                conditionBaseWeightStartError = true;
              }

              if (
                conditionBaseWeightStart >= conditionBaseWeightEnd &&
                conditionWeight !== undefined &&
                conditionWeight < 500000 &&
                conditionBaseWeightEnd > 0
              ) {
                conditionBaseWeightStartErrorText =
                  "Berat minimum harus kurang dari berat maksimum";
                conditionBaseWeightStartError = true;
              }

              if (
                conditionBaseWeightEnd === false ||
                conditionBaseWeightEnd === 0
              ) {
                conditionBaseWeightEndErrorText = "Berat maksimum harus diisi";
                conditionBaseWeightEndError = true;
              } 

              if(conditionBaseWeightEnd === false || conditionWeight > 500000) {
                conditionBaseWeightEndErrorText = 'Berat maksimal adalah 500.000 g';
                conditionBaseWeightEndError = true;
              }
            }
          } else if (conditionTypeId === 2) {
            if (conditionBaseId === 1) {
              if (conditionBaseWeightValue > conditionWeight) {
                conditionBaseWeightValueErrorText = `Kelipatan berat harus kurang dari atau sama dengan ${currencyFormatter(
                  conditionWeight
                )}`;
                conditionBaseWeightValueError = true;
              } else if (
                conditionBaseWeightValue === 0 ||
                conditionBaseWeightValue === undefined
              ) {
                conditionBaseWeightValueErrorText =
                  "Kelipatan berat harus lebih dari 0";
                conditionBaseWeightValueError = true;
              }
            } else {

              if (conditionBaseWeightStart >= conditionBaseWeightEnd) {
                conditionBaseWeightStartErrorText = `Berat minimum harus kurang dari ${currencyFormatter(
                  conditionBaseWeightEnd
                )}`;
                conditionBaseWeightStartError = true;
              } else if (
                conditionBaseWeightStart === 0 ||
                conditionBaseWeightStart === undefined ||
                conditionWeight === undefined ||
                conditionWeight === 0 ||
                conditionBaseWeightEnd === false ||
                conditionBaseWeightEnd === 0
              ) {
                conditionBaseWeightStartErrorText = "Berat minimum harus diisi";
                conditionBaseWeightStartError = true;
                conditionBaseWeightEndErrorText = "Berat maksimum harus diisi";
                conditionBaseWeightEndError = true;
              }
            }
          } else if (conditionTypeId === 3) {
            if (conditionBaseId === 1) {
              if (
                conditionBaseWeightValue < conditionWeight ||
                conditionBaseWeightValue === conditionWeight
              ) {
                conditionBaseWeightValueErrorText = `Kelipatan berat harus lebih dari ${currencyFormatter(
                  conditionWeight
                )}`;
                conditionBaseWeightValueError = true;
              } else if (conditionBaseWeightValue > 500000) {
                conditionBaseWeightValueErrorText = `Kelipatan berat maksimal 500.000 g`;
                conditionBaseWeightValueError = true;
              } else if (
                conditionBaseWeightValue === 0 ||
                conditionBaseWeightValue === undefined
              ) {
                conditionBaseWeightValueErrorText =
                  "Kelipatan berat harus lebih dari 0";
                conditionBaseWeightValueError = true;
              }
            } else {
              if (conditionBaseWeightEnd === undefined) {
                conditionBaseWeightEndErrorText = "Berat maksimum harus diisi";
                conditionBaseWeightEndError = true;
              } else if (conditionBaseWeightEnd < conditionBaseWeightStart) {
                conditionBaseWeightEndErrorText =
                  "Berat maksimum harus lebih besar dari berat minimum";
                conditionBaseWeightEndError = true;
              } else if (conditionBaseWeightStart === conditionBaseWeightEnd) {
                conditionBaseWeightEndErrorText =
                  "Berat maksimum harus lebih besar dari berat minimum";
                conditionBaseWeightEndError = true;
              } else if (conditionBaseWeightEnd > 500000) {
                conditionBaseWeightEndErrorText =
                  "Berat maksimum maksimal 500.000 g";
                conditionBaseWeightEndError = true;
              }
            }
          } else {
            if (conditionBaseId === 1) {
              if (conditionBaseWeightValue < conditionWeight) {
                conditionBaseWeightValueErrorText = `Kelipatan berat harus lebih dari atau sama dengan ${currencyFormatter(
                  conditionWeight
                )}`;
                conditionBaseWeightValueError = true;
              } else if (conditionBaseWeightValue > 500000) {
                conditionBaseWeightValueErrorText = `Kelipatan berat maksimal 500.000 g`;
                conditionBaseWeightValueError = true;
              } else if (
                conditionBaseWeightValue === 0 ||
                conditionBaseWeightValue === undefined
              ) {
                conditionBaseWeightValueErrorText =
                  "Kelipatan berat harus lebih dari 0";
                conditionBaseWeightValueError = true;
              }
            } else {
              if (conditionBaseWeightEnd === undefined) {
                conditionBaseWeightEndErrorText = "Berat maksimum harus diisi";
                conditionBaseWeightEndError = true;
              } else if (conditionBaseWeightEnd < conditionBaseWeightStart) {
                conditionBaseWeightEndErrorText =
                  "Berat maksimum harus lebih besar dari berat minimum";
                conditionBaseWeightEndError = true;
              } else if (conditionBaseWeightStart === conditionBaseWeightEnd) {
                conditionBaseWeightEndErrorText =
                  "Berat maksimum harus lebih besar dari berat minimum";
                conditionBaseWeightEndError = true;
              } else if (conditionBaseWeightEnd > 500000) {
                conditionBaseWeightEndErrorText =
                  "Berat maksimum maksimal 500.000 g";
                conditionBaseWeightEndError = true;
              }
            }
          }

          if (
            conditionWeightError ||
            conditionBaseWeightValueError ||
            conditionBaseWeightStartError ||
            conditionBaseWeightEndError ||
            conditionBaseTarifError ||
            conditionSelectError
          ) {
            countError++;
          }

          return {
            condition_weight: conditionWeightError,
            condition_weightText: conditionWeightText,
            condition_base_weight_value: conditionBaseWeightValueError,
            condition_base_weight_valueText: conditionBaseWeightValueErrorText,
            condition_base_tarif: conditionBaseTarifError,
            condition_base_tarifText: conditionBaseTarifErrorText,
            condition_base_weight_start: conditionBaseWeightStartError,
            condition_base_weight_startText: conditionBaseWeightStartErrorText,
            condition_base_weight_end: conditionBaseWeightEndError,
            condition_base_weight_endText: conditionBaseWeightEndErrorText,
            condition_select_text: conditionSelectText,
            condition_select_error: conditionSelectError,
          };
        });
    }

    setValidation({
      ...validation,
      dataCoverage: [...validateDataCoverage],
      dataRates: validateDataRates,
    });

    return countError;
  };

  return {
    validation,
    setValidation,
    handleError,
  };
};

export default useValidation;