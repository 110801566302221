import React, { Fragment, useState, useContext } from "react";
import {
  Flex,
  Text,
  Toggle,
  useToast,
  range,
  useDisclosure,
  Button,
  Dialog,
  isEmpty,
  Input,
} from "renos-ui";
import {
  useEditAutoReply,
  useGetListAutoReply,
  useGetStoreOpenView,
  useToggleTemplate,
} from "services/chat";
import StoreContext from "providers/StoreProvider";
import { PanelBody, PanelHeading } from "./Panel";
import SkeletonReply from "./SkeletonReply";
import ListOpenStore from "./ListOpenStore";
import { useHistory } from "react-router-dom";

const ReplyChat = () => {
  const [state, setState] = useState({
    welcomeData: {
      template_type: 1,
      is_active: false,
      template_message: "",
    },
    outHourData: {
      template_type: 2,
      is_active: false,
      template_message: "",
    },
    closedData: {
      template_type: 2,
      is_active: false,
      template_message: "",
    },
  });

  const AUTO_REPLY = {
    WELCOME: 1,
    OUT_HOUR: 2,
    CLOSED: 3,
  };

  const getObjectByTypeId = (array, typeID) =>
    array.find((key) => key.template_type === typeID);

  const {
    data,
    isLoading: isLoadingList,
    refetch,
  } = useGetListAutoReply({
    onSuccess: ({ data }) => {
      if (data.length > 0) {
        setState({
          ...state,
          welcomeData: getObjectByTypeId(data, AUTO_REPLY.WELCOME),
          outHourData: getObjectByTypeId(data, AUTO_REPLY.OUT_HOUR),
          closedData: getObjectByTypeId(data, AUTO_REPLY.CLOSED),
        });
      } else {
        setState({
          ...state,
          welcomeData: {
            template_type: 1,
            is_active: false,
            template_message: "",
          },
          outHourData: {
            template_type: 2,
            is_active: false,
            template_message: "",
          },
          closedData: {
            template_type: 3,
            is_active: false,
            template_message: "",
          },
        });
      }
    },
  });

  return (
    <Flex flexDirection="column" gap="24px" marginY="24px">
      <Flex alignItems="center">
        <Flex flexDirection="column" gap="4px">
          <Text weight="bold">Pengaturan Balasan Otomatis</Text>
          <Text color="black50" variant="caption">
            Balasan Otomatis dapat membantu kamu membalas chat dari pembeli
            dalam kondisi sebagai berikut:
          </Text>
        </Flex>
      </Flex>
      {isLoadingList ? (
        range(2).map((_, i) => <SkeletonReply key={i} />)
      ) : data?.data.length > 0 ? (
        <Fragment>
          <List
            label="Ucapan selamat datang"
            description="Atur isi pesan selamat datang untuk menyapa pembeli di jam operasional"
            data={state.welcomeData}
            refetch={refetch}
            setState={setState}
            state={state}
          />
          <List
            label="Pesan di luar jam operasional"
            description="Pasang Balasan Otomatis saat toko libur atau di luar jam operasional dan produk bisa dibeli"
            data={state.outHourData}
            refetch={refetch}
            setState={setState}
            state={state}
          />
          <List
            label="Pesan saat toko sedang libur"
            description="Pasang Balasan Otomatis saat toko libur dan produk tidak bisa dibeli"
            data={state.closedData}
            refetch={refetch}
            setState={setState}
            state={state}
          />
        </Fragment>
      ) : (
        <Text>empty list</Text>
      )}
    </Flex>
  );
};

const List = (props) => {
  const history = useHistory();
  const [tempSelected, setTempSelected] = useState({});
  const showForm = useDisclosure({});
  const {
    storeData: {
      data: { id: storeId },
    },
  } = useContext(StoreContext);

  const { data, label, refetch, description, setState, state } = props;

  const toast = useToast();

  const { mutate: mutateToggle } = useToggleTemplate({
    onError: () => {
      toast({
        label: "Gagal mengaktifkan template balasan",
        placement: "top",
        backgroundColor: "red50",
      });
    },
    onSuccess: () => {
      toast({
        label: "Template balasan otomatis berhasil disimpan!",
        placement: "top",
      });
      refetch();
    },
  });

  const { mutate: editAutoReply } = useEditAutoReply({
    onSuccess: () => {
      toast({
        label: "Template balasan otomatis berhasil disimpan!",
        placement: "top",
      });
      refetch();
      showForm.onClose();
    },
    onError: () => {
      toast({
        label: "Templat Balasan harus memiliki panjang minimal 10 karakter",
        placement: "top",
        backgroundColor: "red50",
      });
    },
  });

  const { data: listStore, mutate: openStore } = useGetStoreOpenView();

  const handleToggle = (active, template_type) => {
    if (template_type === 1) {
      setState({
        ...state,
        welcome: {
          ...state.welcome,
          is_active: false,
        },
      });
    } else if (template_type === 2) {
      setState({
        ...state,
        outHourData: {
          ...state.outHourData,
          is_active: false,
        },
      });
    } else {
      setState({
        ...state,
        closedData: {
          ...state.closedData,
          is_active: false,
        },
      });
    }

    mutateToggle({
      type: template_type,
      is_active: !active,
      message: data?.template_message,
    });
  };

  const handleModal = (data) => {
    if (data?.template_type === 2) {
      openStore({ store_id: storeId });
    }
    showForm.onOpen();
    setTempSelected(data);
  };

  const handleEdit = () => {
    const { is_active, template_message, template_type } = tempSelected;

    editAutoReply({
      is_active: is_active,
      message: template_message,
      type: template_type,
    });
  };

  return (
    <PanelBody>
      <PanelHeading>
        <Flex alignItems="center" gap="20px" width="100%">
          <Flex
            onClick={() => handleToggle(data?.is_active, data?.template_type)}
          >
            <Toggle checked={data?.is_active} id={data?.id} />
          </Flex>

          <Flex alignItems="center" justifyContent="space-between" width="100%">
            <Flex flexDirection="column" gap="4px" maxWidth="600px">
              <Text weight="bold" variant="caption">
                {label}
              </Text>
              <Text lineClamp="2" color="black75" variant="caption">
                {description}
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </PanelHeading>
      <Flex
        padding="24px"
        gap="16px"
        justifyContent="space-between"
        alignItems="center"
      >
        <Flex flexGrow="1" maxWidth="650px">
          <Text lineClamp="2" variant="caption" color="black75">
            {data?.template_message || "Pesan belum diatur."}
          </Text>
        </Flex>
        <Flex cursor="pointer">
          <Text
            variant="caption"
            color="blue50"
            onClick={() => handleModal(props?.data)}
          >
            Ubah
          </Text>
        </Flex>
      </Flex>

      <Dialog
        isVisible={showForm.isOpen}
        onClose={showForm.onClose}
        closable
        verticalCentered
        width="340px"
      >
        <Flex flexDirection="column" width="100%" gap="24px">
          <Text variant="heading3" weight="bold">
            {data?.template_type === 1
              ? "Ucapan selamat datang"
              : data?.template_type === 2
              ? "Pesan di luar jam operasional"
              : "Pesan saat toko sedang libur"}
          </Text>

          <Flex flexDirection="column" gap="16px">
            <Input.TextArea
              label="Isi Template"
              name="note"
              placeholder="Masukan template balasan"
              value={tempSelected?.template_message}
              showCount
              maxLength={200 || 0}
              rows="8"
              style={{
                resize: "none",
                fontSize: "12px",
              }}
              onChange={(event) =>
                setTempSelected({
                  ...tempSelected,
                  template_message: event.target.value,
                })
              }
            />
          </Flex>
          {data?.template_type === 2 && (
            <Flex flexDirection="column" gap="16px">
              <Text variant="caption" weight="bold" color="black50">
                Jam Operasional Toko Kamu
              </Text>
              <Flex
                backgroundColor="black5"
                padding="16px"
                borderRadius="8px"
                gap="16px"
                flexDirection="column"
              >
                {listStore?.map((list, index) => {
                  return (
                    list.is_active && (
                      <ListOpenStore
                        key={`listOpenStore-${index}`}
                        day={list.day_humane}
                        time={list.time}
                      />
                    )
                  );
                })}
                {listStore?.length === 0 && (
                  <Text variant="caption" color="black25">
                    Belum diatur
                  </Text>
                )}

                <Flex
                  marginTop="-8px"
                  cursor="pointer"
                  onClick={() => history.push("/setting?tab-active=status")}
                >
                  <Text variant="caption" color="blue50">
                    Atur Jam Operasional Toko
                  </Text>
                </Flex>
              </Flex>
            </Flex>
          )}

          <Flex justifyContent="space-between" gap="16px">
            <Button isBlock variant="tertiary" onClick={showForm.onClose}>
              Batal
            </Button>
            <Button
              isBlock
              disabled={isEmpty(tempSelected?.template_message)}
              onClick={handleEdit}
            >
              <Text
                weight="bold"
                color={
                  isEmpty(tempSelected?.template_message) ? "black50" : "white"
                }
              >
                Simpan
              </Text>
            </Button>
          </Flex>
        </Flex>
      </Dialog>
    </PanelBody>
  );
};

export default ReplyChat;
