import ENDPOINTS from "constants/endpoints";
import { useQuery, useMutation } from "react-query";
import client from "../client";

const getLocationFetcher = async (payload) => {
  const { data } = await client.get(ENDPOINTS.location, {
    params: payload,
  });

  return data;
};

export const useGetLocation = (payload, extraParams) => {
  return useQuery(["getLocation"], () => getLocationFetcher(payload), {
    ...extraParams,
  });
};

const updateLocationFetcher = async (body) => {
  const response = await client.post(ENDPOINTS.location, body);

  return response.data;
};

export const useUpdateLocation = (extraParams) =>
  useMutation((body) => updateLocationFetcher(body), {
    ...extraParams,
  });

const updateLocationFetcherv3 = async (body) => {
  const response = await client.post(ENDPOINTS.location, body);

  return response.data;
};

export const useUpdateLocationv3 = (extraParams) =>
  useMutation((body) => updateLocationFetcherv3(body), {
    ...extraParams,
  });

const deletLocationFetcher = async (id) => {
  const { data } = await client.delete(`${ENDPOINTS.location}/${id}`, id);

  return data;
};

export const useDeleteLocation = (extraParams) =>
  useMutation((body) => deletLocationFetcher(body), {
    ...extraParams,
  });

const updateFetcherRegister = async (body) => {
  const response = await client.post(ENDPOINTS.register, body);

  return response.data;
};

export const useMutateRegister = (extraParams) =>
  useMutation((body) => updateFetcherRegister(body), {
    ...extraParams,
  });
