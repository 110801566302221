import styled from "styled-components";
import { Input, Flex } from "renos-ui";

export const StyledInput = styled(Input)`
  z-index: 1;
  width: 94%;
  padding: 10px 12px;
  margin: 12px;
  height: 32px;
  border-radius: 100px;
  background-color: #f5f5f6;
`;

export const MapsContainer = styled(Flex)`
  height: 100%;
  width: 100%;
  border-radius: 0 !important;
`;

export const StyledDatalist = styled.datalist`
  width: 100%;
  ::-webkit-calendar-picker-indicator {
    display: none;
  }
`;
