import styled from "styled-components";
import { Dialog, SelectInput } from "renos-ui";
import { Swiper } from "swiper/react";

export const StyledDialog = styled(Dialog)`
  padding: 16px;
`;

export const StyledSwiper = styled(Swiper)`
  .swiper-wrapper {
    position: relative;
    width: 100%;
    min-width: 900px;
    div {
      box-sizing: border-box;
    }
  }
`;

export const StyledSelectInput = styled(SelectInput)`
  width: 100%;
`;

export const StyledDialogUpload = styled(Dialog)`
  margin: -16px;
  padding: 0;
`;
