import React from "react";
import { Flex, Text, Dialog } from "renos-ui";
import { Wrapper } from "../styled";

const PreviewModal = ({ previewModal, title, note }) => {
  return (
    <Dialog
      isVisible={previewModal.isOpen}
      closable
      onClose={previewModal.onClose}
      verticalCentered
      width="400px"
      minHeight="100px"
    >
      <Flex flexDirection="column" width="100%">
        <Flex flexDirection="column" gap="16px">
          <Text variant="heading3" weight="bold">
            {title}
          </Text>
          <Wrapper color="black75">{note}</Wrapper>
        </Flex>
      </Flex>
    </Dialog>
  );
};

export default PreviewModal;
