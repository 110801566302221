import StoreLayoutContext from "providers/StoreLayoutProvider";
import { useContext, useEffect, useState } from "react";
import { Flex, range, Text, Grid } from "renos-ui";

const StoreFront = ({ isPreviewMode }) => {
  const { dataHomePage, storeFrontRef } = useContext(StoreLayoutContext);
  const [dataStoreFront, setDataStoreFront] = useState({});

  const convertSpace = (str) => {
    return str ? str.replaceAll(" ", "%20") : str;
  };

  useEffect(() => {
    const selectedNewPrduct = dataHomePage.filter(
      (homepage) => homepage.id === 5
    )[0];
    setDataStoreFront(selectedNewPrduct);
  }, [dataHomePage]);

  if (isPreviewMode && !dataStoreFront?.isActive) return <></>;

  return (
    <Flex flexDirection="column" gap="8px" ref={storeFrontRef}>
      <Text variant="small" weight="bold">
        Etalase
      </Text>
      <Grid gap="8px" gridTemplateColumns="115px 115px 115px 115px 115px 115px">
        {dataStoreFront?.isActive
          ? dataStoreFront.data.map((storeFront, indexStoreFront) => (
              <Flex
                key={`store-front${indexStoreFront}`}
                height="101px"
                padding="8px"
                borderRadius="4px"
                borderColor="black10"
                borderWidth="1px"
                borderStyle="solid"
                background={`linear-gradient(180.57deg, rgba(20, 20, 32, 0) 50.49%, #141420 99.5%), url(${convertSpace(
                  storeFront.tag_image_url
                )})`}
                backgroundSize="contain"
                backgroundRepeat="no-repeat"
                alignItems="end"
              >
                <Text
                  variant="caption"
                  color="white"
                  weight="bold"
                  lineClamp={1}
                >
                  {storeFront?.tag?.tag_name || storeFront?.tag_name}
                </Text>
              </Flex>
            ))
          : range(6).map((_, indexEmpty) => (
              <Flex
                key={`empty-state${indexEmpty}`}
                flexBasis={0}
                flexGrow={1}
                borderRadius="4px"
                height="101px"
                backgroundColor="black10"
                background="url('/assets/images/store-layout/no-img.svg') no-repeat 50% 50%"
              />
            ))}
      </Grid>
    </Flex>
  );
};

export default StoreFront;
