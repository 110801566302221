import { useMemo } from "react";
import { Divider, Flex, Text, numberInputChecking } from "renos-ui";

const PaymentInfo = ({
  formatted_total_price: formattedTotalPrice,
  total_price: totalPrice,
  order_total_weight: orderTotalWeight,
  promo_used: promoUsed,
  shipping,
  products,
  formatted_commission_fee: formattedCommissionFee,
  commission_fee: commissionFee,
}) => {
  const totalAllPrice = useMemo(() => {
    const sellerFleetAmount = shipping?.is_seller_fleet
      ? parseInt(shipping?.order_shipping_price)
      : 0;
    const voucherAmount =
      promoUsed.length > 0 ? promoUsed[0].seller_voucher_disc : 0;

    return totalPrice + sellerFleetAmount + voucherAmount + commissionFee;
  }, [shipping, totalPrice, promoUsed, commissionFee]);

  return (
    <Flex padding="16px" gap="16px" flexDirection="column">
      <Text weight="bold">Detail Penjualan</Text>
      <Flex gap="8px" flexDirection="column">
        <Flex justifyContent="space-between" alignItems="center">
          <Text variant="caption" color="black50">
            Total Harga ({products.length} barang)
          </Text>
          <Text variant="caption" color="black75">
            {formattedTotalPrice}
          </Text>
        </Flex>
        <Flex justifyContent="space-between" alignItems="center">
          <Text variant="caption" color="black50">
            Total Ongkir ({orderTotalWeight} kg)
          </Text>
          <Text variant="caption" color="black75">
            {shipping.order_shipping_price_fmt}
          </Text>
        </Flex>
        {!shipping.is_seller_fleet && (
          <Flex justifyContent="space-between" alignItems="center">
            <Text variant="caption" color="black50">
              Ongkir diteruskan ke kurir
            </Text>
            <Text variant="caption" color="black75">
              - {shipping.order_shipping_price_fmt}
            </Text>
          </Flex>
        )}
        <Flex justifyContent="space-between" alignItems="center">
          <Text variant="caption" color="black50">
            Commission Fee
          </Text>
          <Text variant="caption" color="black75">
            {formattedCommissionFee}
          </Text>
        </Flex>
        {promoUsed.length > 0 && (
          <Flex justifyContent="space-between" alignItems="center">
            <Text variant="caption" color="black50">
              Diskon Toko
            </Text>
            <Text variant="caption" color="black75">
              {promoUsed[0].formatted_seller_voucher_disc}
            </Text>
          </Flex>
        )}
      </Flex>
      <Divider />
      <Flex justifyContent="space-between" alignItems="center">
        <Text>Total Penjualan</Text>
        <Text color="black75" weight="bold">
          {`Rp ${
            numberInputChecking(totalAllPrice.toString() || "0", 0)
              .formattedValue
          }`}
        </Text>
      </Flex>
    </Flex>
  );
};

export default PaymentInfo;
