import PopupSellerFleetArrive from "containers/mobile/order-management/list/component/Card/Footer/component/OnDelivery/PopupSellerFleetArrive";
import PopupTracking from "containers/mobile/order-management/list/component/Card/Footer/component/OnDelivery/PopupTracking";
import Popup3pl from "containers/mobile/order-management/list/component/Card/Footer/component/SendOrder/Popup3pl";
import PopupMore from "containers/mobile/order-management/list/component/Card/Footer/component/SendOrder/PopupMore";
import { Button, useDisclosure, useToast, Spinner, Icon } from "renos-ui";
import { useRequestPickupInstant } from "services/order-management";
import { StyledButtonIcon } from "../../../styled";

const OnDelivery = ({
  storeOrderShipment,
  shipping,
  orderId,
  refetch,
  data,
  onClickChat,
}) => {
  const toast = useToast();
  const disclosureTrack = useDisclosure({ isOpen: false });
  const disclosureArrive = useDisclosure({ isOpen: false });
  const disclosure3pl = useDisclosure({ isOpen: false });
  const disclosureMore = useDisclosure({ isOpen: false });

  const { mutate: requestPickupInstant, isLoading: isLoadingRequestPickup } =
    useRequestPickupInstant({
      onSuccess: (data) => {
        const { message, frontend_message } = data;
        refetch();
        toast({
          label: frontend_message || message || "Request pickup berhasil",
          placement: "bottom",
        });
      },
      onError: (data) => {
        const {
          response: {
            data: { frontend_message, message },
          },
        } = data;
        toast({
          label: frontend_message || message || "Request pickup gagal",
          placement: "bottom",
          backgroundColor: "red50",
        });
      },
    });

  const sendOrderCheck = () => {
    if (storeOrderShipment.request_pickup_datetime_possible) {
      disclosure3pl.onOpen();
    } else {
      handleRequestPickupInstant();
    }
  };

  const handleRequestPickupInstant = () => {
    const params = {
      order_id: orderId,
      pickup: true,
      awb: "",
    };
    requestPickupInstant(params);
  };

  return (
    <>
      <StyledButtonIcon
        variant="tertiary"
        size="small"
        onClick={disclosureMore.onOpen}
        icon={<Icon size={24} name="Vertical-outline" />}
      />
      <StyledButtonIcon
        variant="tertiary"
        size="small"
        onClick={onClickChat}
        icon={<Icon size={24} name="Chat-outline" />}
      />
      {storeOrderShipment.retry_booking_possible ? (
        <Button
          size="medium"
          isBlock
          onClick={sendOrderCheck}
          disabled={isLoadingRequestPickup}
          preffix={
            isLoadingRequestPickup && <Spinner color="white" size="8px" />
          }
        >
          Request Ulang
        </Button>
      ) : shipping?.is_seller_fleet ? (
        <Button size="medium" isBlock onClick={disclosureArrive.onOpen}>
          Pesanan Sampai
        </Button>
      ) : (
        <Button size="medium" isBlock onClick={disclosureTrack.onOpen}>
          Lacak
        </Button>
      )}
      <PopupTracking disclosure={disclosureTrack} orderId={orderId} />
      <PopupSellerFleetArrive
        disclosure={disclosureArrive}
        orderId={orderId}
        toFirstPage={refetch}
      />
      <Popup3pl
        disclosure3pl={disclosure3pl}
        toFirstPage={refetch}
        orderId={orderId}
      />
      <PopupMore disclosure={disclosureMore} data={data} />
    </>
  );
};

export default OnDelivery;
