import desktopUrl from "constants/desktopUrl";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Flex, Icon, Text, isEmpty } from "renos-ui";

const ChatBuyer = ({ data }) => {
  const { push } = useHistory();

  const handleClick = () => {
    let options = {};

    if (data?.buyer_qiscus_id) {
      options = {
        qiscusId: data?.buyer_qiscus_id,
        orderNumber: data?.order_number,
        orderStatus: data?.order_status_name,
      };

      if (!isEmpty(data.products)) {
        options = {
          ...options,
          productImage: data.products[0].product_image_url,
        };
      }
    }

    push({
      pathname: desktopUrl.CHAT,
      query: "",
      state: options,
    });
  };

  return (
    <Flex gap="8px" alignItems="center" cursor="pointer" onClick={handleClick}>
      <Icon name="Chat-outline" color="black75" size={16} />
      <Text color="black75" weight="bold">
        Chat Pembeli
      </Text>
    </Flex>
  );
};

export default ChatBuyer;
