import { StoreLayoutProvider } from "providers/StoreLayoutProvider";
import StoreContext from "providers/StoreProvider";
import { useContext, useMemo, useRef, useState } from "react";
import { Flex, Text, useDisclosure, useToast } from "renos-ui";
import {
  useActivateHomePageStatus,
  useGetHomePage,
  useGetHomePageStatus,
} from "services/store-layout/homepage";
import { StyledToggle } from "../setting/status/open-store/styled";
import DesignSetting from "./DesignSetting";
import ModalConfirmation from "./ModalConfirmation";
import useQueryParams from "hooks/useQueryParams";
import Preview from "./Preview";
import { useHistory } from "react-router-dom";
import desktopUrl from "constants/desktopUrl";
import { generateStoreLayoutTemplate } from "helpers";

const StoreLayout = () => {
  const toast = useToast();
  const { push } = useHistory();
  const query = useQueryParams();
  const disclosureConfirm = useDisclosure({ isOpen: false });
  const [activeTab, setActiveTab] = useState(query.get("tab-active") || "web");

  const {
    storeData: {
      data: { id: storeId },
    },
  } = useContext(StoreContext);

  const [dataHomePage, setDataHomePage] = useState([]);
  const [isDesignActive, setIsDesignActive] = useState(true);

  const bannerRef = useRef(null);
  const voucherRef = useRef(null);
  const newProductRef = useRef(null);
  const bestSellingRef = useRef(null);
  const storeFrontRef = useRef(null);
  const discountRef = useRef(null);
  const banner2Ref = useRef(null);
  const bannerVideoRef = useRef(null);

  const {
    isLoading,
    isFetching,
    refetch: refetchHomePage,
    isError,
  } = useGetHomePage(
    { params: { store_id: storeId } },
    {
      onSuccess: (data) => {
        if (data?.data) {
          const tempData = data.data.map((list) => {
            let tempListData = {
              ...list,
              isActive: !!list.data && list.data.length !== 0,
              isDraft: list.status === "Draft",
            };

            if (list.id === 1 || list.id === 8) {
              tempListData = {
                ...tempListData,
                data:
                  tempListData?.data?.length > 0
                    ? tempListData?.data
                    : generateStoreLayoutTemplate(list?.banner?.image_type),
              };
            }

            return tempListData;
          });

          setDataHomePage([...tempData]);
        } else {
          setDataHomePage([]);
        }
      },
    }
  );

  const { isLoading: isGetStatusLoading, data: dataHomePageStatus } =
    useGetHomePageStatus({
      onSuccess: (data) => {
        setIsDesignActive(data?.data?.is_active);
        if (!data?.data?.template?.id) push(desktopUrl.STORE_LAYOUT_TEMPLATE);
      },
    });

  const {
    isLoading: isActivateHomepageLoading,
    mutate: mutateActivateHomepage,
  } = useActivateHomePageStatus({
    onSuccess: (dataSuccess) => {
      const { data: dataStatus } = dataSuccess;

      toast({
        label: `Desain tampilan berhasil ${
          isDesignActive ? "Dinonaktifkan" : "diaktifkan"
        }!`,
        placement: "top",
      });
      setIsDesignActive(dataStatus.is_active);
      disclosureConfirm.onClose();
    },
    onError: () => {
      toast({
        label: `Desain tampilan gagal ${
          isDesignActive ? "Dinonaktifkan" : "diaktifkan"
        }!`,
        placement: "top",
        backgroundColor: "red50",
      });
    },
  });

  const isStatusLoading = useMemo(
    () => isGetStatusLoading || isActivateHomepageLoading,
    [isGetStatusLoading, isActivateHomepageLoading]
  );

  const isHomePageLoading = useMemo(
    () => isLoading || isFetching,
    [isLoading, isFetching]
  );

  const handleDesignActive = () => {
    mutateActivateHomepage({ is_active: !isDesignActive });
  };

  const isHomePageError = useMemo(
    () => isError || dataHomePage.length === 0,
    [isError, dataHomePage]
  );

  return (
    <StoreLayoutProvider
      value={{
        dataHomePage,
        setDataHomePage,
        isHomePageLoading,
        refetchHomePage,
        bannerRef,
        voucherRef,
        newProductRef,
        bestSellingRef,
        storeFrontRef,
        isHomePageError,
        discountRef,
        banner2Ref,
        bannerVideoRef,
      }}
    >
      <Flex flexDirection="column" gap="24px">
        <Flex justifyContent="space-between">
          <Flex flexDirection="column">
            <Text variant="heading3" weight="bold">
              Ubah Tampilan Toko
            </Text>
            <Text color="black75">
              Yuk, kreasikan tampilan toko untuk menarik perhatian pembeli.
            </Text>
          </Flex>
          <Flex
            padding="12px 16px"
            boxShadow="0px 4px 4px rgba(20, 20, 32, 0.05)"
            backgroundColor="white"
            borderRadius="8px"
            gap="12px"
            alignItems="center"
          >
            <Flex width="174px">
              <Text color="black75">
                Desain Tampilan {isDesignActive ? "Aktif" : "Nonaktif"}
              </Text>
            </Flex>
            <Flex onClick={disclosureConfirm.onToggle}>
              <StyledToggle
                checked={isDesignActive}
                disabled={isStatusLoading}
              />
            </Flex>
          </Flex>
        </Flex>
        <Flex gap="16px">
          <DesignSetting {...{ activeTab, setActiveTab }} />
          <Preview
            {...{ activeTab, setActiveTab, isDesignActive, dataHomePageStatus }}
          />
        </Flex>
      </Flex>
      <ModalConfirmation
        isOpen={disclosureConfirm.isOpen}
        onClose={disclosureConfirm.onClose}
        onConfirm={handleDesignActive}
        isLoading={isStatusLoading}
        isActivate={!isDesignActive}
      />
    </StoreLayoutProvider>
  );
};

export default StoreLayout;
