import ENDPOINTS from "constants/endpoints";
import { useQuery, useMutation } from "react-query";
import client from "../client";
import { isEmpty } from "renos-ui";

const getStoreFetcher = async () => {
  const { data } = await client.get(ENDPOINTS.get_store);

  return data;
};

export const useGetStore = (extraParams) => {
  return useQuery(["getStore"], getStoreFetcher, {
    ...extraParams,
  });
};

const getStoreDetailFetcher = async (params) => {
  const processedParams = { ...params };

  if (!isEmpty(params?.need)) {
    processedParams.need = [
      "user",
      "heartbeats",
      ...params.need.split(","),
    ].join(",");
  } else {
    processedParams.need = "user,heartbeats";
  }

  const { data } = await client.get(`${ENDPOINTS.get_store_detail}`, {
    params: processedParams,
  });

  return data;
};

export const useGetStoreDetail = (params, queryName, extraParams) => {
  return useQuery(
    [queryName || "getStoreDetail"],
    () => getStoreDetailFetcher(params),
    {
      ...extraParams,
    }
  );
};

const uploadStoreImgFetcher = async (body) => {
  const response = await client.post(ENDPOINTS.upload_store_img, body, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  return response.data;
};

export const useUploadStoreImg = (extraParams) =>
  useMutation((body) => uploadStoreImgFetcher(body), {
    ...extraParams,
  });

const updateStoreFetcher = async (body) => {
  const resp = await client.patch(ENDPOINTS.update_store, body.data);

  return resp;
};

export const useUpdateStore = (extraParams) =>
  useMutation((body) => updateStoreFetcher(body), { ...extraParams });
