import React from "react";
import { Box, Flex, Label, Text } from "renos-ui";

const FormField = ({ label, isRequired, hint, children }) => {
  return (
    <Flex justifyContent="space-between">
      <Flex flexDirection="column" rowGap="4px" width="240px">
        <Flex alignItems="center" columnGap="8px">
          <Text color="black75" weight="bold">
            {label}
          </Text>
          {isRequired && <Label variant="neutral">Wajib</Label>}
        </Flex>
        <Flex
          fontSize="14px"
          flexDirection="column"
          rowGap="16px"
          color="black50"
          lineHeight="24px"
        >
          {hint}
        </Flex>
      </Flex>
      <Box width="584px">{children}</Box>
    </Flex>
  );
};

export default FormField;
