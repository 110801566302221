import { Divider, Flex, range, Skeleton } from "renos-ui";
import CardSkeleton from "./List/CardSkeleton";

const LoadingStatus = () => {
  return (
    <Flex flexDirection="column" gap="24px" width="100%">
      <Flex gap="22px">
        {range(5).map((_, indexSkeleton) => (
          <Skeleton
            key={`skeleton-status-${indexSkeleton}`}
            width="138px"
            height="44px"
          />
        ))}
      </Flex>
      <Divider />

      <Flex gap="16px">
        <Skeleton width="328px" height="40px" />
        <Skeleton width="272px" height="40px" />
        <Skeleton width="144px" height="40px" />
        <Skeleton width="144px" height="40px" />
        <Skeleton width="144px" height="40px" />
      </Flex>

      <CardSkeleton />
    </Flex>
  );
};

export default LoadingStatus;
