import React from "react";
import { Box, Button, Dialog, Flex, Text } from "renos-ui";

export const ModalSuccessSent = ({ isVisible, onToggle }) => {
  return (
    <Dialog isVisible={isVisible} width="400px" verticalCentered>
      <Flex flexDirection="column" gap="24px" alignItems="center">
        <Box
          width="240px"
          height="240px"
          as="img"
          src="/assets/images/success-sent-bidding-request.svg"
        />
        <Flex gap="16px" flexDirection="column">
          <Text variant="heading3" weight="bold">
            Penawaranmu Berhasil Dikirim
          </Text>
          <Text>
            Penawaranmu telah dikirim ke pembeli. Jika penawaranmu diterima,
            pesanan terkait penawaran akan muncul di daftar pesananmu.
          </Text>
        </Flex>
        <Button isBlock onClick={onToggle}>
          Ok
        </Button>
      </Flex>
    </Dialog>
  );
};
