import { Dialog, Flex, Text, Spinner, Icon } from "renos-ui";
import { StyledButton, StyledTextArea } from "./styled";
import ModalCancelOrderContext from "providers/ModalCancelOrder";
import { useContext } from "react";

const ModalRejectOtherReason = ({ isOpen, onClose }) => {
  const { isLoading, onReject, onOpenParent, payloadReject, setPayloadReject } =
    useContext(ModalCancelOrderContext);

  const onChangeReason = (event) => {
    setPayloadReject((prev) => ({
      ...prev,
      order_cancellation_reason: event.target.value,
    }));
  };

  const handleClose = () => {
    onClose();
    onOpenParent();
  };

  return (
    <Dialog isVisible={isOpen} onClose={onClose} verticalCentered>
      <Flex flexDirection="column" gap="24px" width="320px">
        <Flex justifyContent="space-between">
          <Text variant="heading3" weight="bold">
            Detail Penolakan
          </Text>
          <Flex cursor="pointer" onClick={handleClose}>
            <Icon name="Close-outline" size={24} color="black50" />
          </Flex>
        </Flex>

        <StyledTextArea
          value={payloadReject.order_cancellation_reason}
          onChange={onChangeReason}
          placeholder="Jelaskan alasan penolakan yang kamu pilih secara detail"
          disabled={isLoading}
          maxLength={150}
          showCount
        />
        <StyledButton
          isBlock
          onClick={onReject}
          disabled={isLoading || !payloadReject.order_cancellation_reason}
          preffix={isLoading && <Spinner color="white" size="8px" />}
        >
          Tolak Pesanan
        </StyledButton>
      </Flex>
    </Dialog>
  );
};

export default ModalRejectOtherReason;
