import { initialFormBatch } from "containers/web/product/add/single/constants";
import { IMAGE_FILE_TYPE } from "containers/web/setting/document/constant";
import {
  blobToBase64,
  getImageMeta,
  imageTypeChecking,
  imgCompressor,
} from "helpers";
import {
  formattedBatchSku,
  variantToSkuFormat,
} from "helpers/attribute-mapper/product-variant";
import React, { useMemo } from "react";
import {
  Button,
  Checkbox,
  Divider,
  Drawer,
  Flex,
  Icon,
  Input,
  Text,
  Toggle,
  Upload,
  isEmpty,
  useDisclosure,
  useToast,
  useUpload,
} from "renos-ui";
import { useUploadProductImage } from "services/product";
import constants from "store/productAdd/constants";
import { useProductAddContext } from "store/productAdd/reducer";

const VariantFormBatch = ({
  isCheckAll,
  filteredCount,
  checkedVariant,
  formBatch,
  setFormBatch,
  setIsCheckAll,
  setFilterData,
  setCheckedVariant,
  filterData,
}) => {
  const toast = useToast();

  const { state, dispatch } = useProductAddContext();
  const { productVariants, sku, uom, isEdit } = state;

  const { isOpen, onClose, onOpen } = useDisclosure({ open: false });

  const productWithValue = useMemo(
    () =>
      productVariants.filter((variant) => variant?.variant_values?.length > 0),
    [productVariants]
  );

  const checkCount = useMemo(() => {
    const formattedData = variantToSkuFormat(productWithValue);

    if (checkedVariant.length > 0) {
      return checkedVariant.length;
    }
    let count = 0;

    formattedData.forEach((prev) => {
      let annotator = 0;

      Object.keys(filterData).forEach((key) => {
        if (prev.id[key] === filterData[key]) {
          annotator++;
        }
      });

      if (annotator === filteredCount && filteredCount > 0) {
        count++;
      }
    });

    return count;
  }, [productWithValue, filterData, checkedVariant, filteredCount]);

  const { mutate: uploadBatchImage } = useUploadProductImage({
    onSuccess: (data) => {
      setFormBatch((prev) => ({
        ...prev,
        image_id: data.image_id,
        image: data.path,
        image_url: data.url,
      }));

      onClose();
    },
  });

  const onDeleteImage = () => {
    setFormBatch((prev) => ({
      ...prev,
      image_id: null,
      image: null,
      image_url: null,
    }));

    onClose();
  };

  const onFormFilterChange = (key, value) => {
    if (value) {
      setFormBatch((prev) => ({ ...prev, [key]: value }));
    } else {
      const tempFormBatch = { ...formBatch };
      delete tempFormBatch[key];
      setFormBatch(tempFormBatch);
    }
  };

  const onCheckAll = () => {
    if (isCheckAll) {
      setFormBatch(initialFormBatch);
      setCheckedVariant([]);
      setFilterData({});
    } else {
      setCheckedVariant(sku.map((data) => data.index));
    }
    setIsCheckAll((prev) => !prev);
  };

  const { register: registerUploadBatch } = useUpload({
    fileType: "image",
    onRequest: async (file) => {
      const deepTypeChecking = await imageTypeChecking(file);

      if (deepTypeChecking) {
        const result = await blobToBase64(file);
        const { naturalHeight, naturalWidth } = await getImageMeta(result);

        if (naturalHeight < 300 || naturalWidth < 300) {
          toast({
            label: "Format gambar tidak sesuai",
            placement: "bottom",
          });

          return null;
        }
        const formData = new FormData();
        const compressed =
          file.size > 400000
            ? await imgCompressor(file, {
                maxSizeMB: 0.4,
                maxWidthOrHeight: 1920,
                useWebWorker: true,
              })
            : file;

        formData.append("image", compressed);
        formData.append("image_type_id", 6);
        uploadBatchImage(formData);
      } else {
        toast({
          label: "Format gambar tidak sesuai",
          placement: "bottom",
        });
      }
    },
    validator: (file) => {
      if (!IMAGE_FILE_TYPE.includes(file.type)) {
        toast({
          label: "Format gambar tidak sesuai",
          placement: "bottom",
        });

        return {
          message: "Format gambar tidak sesuai",
        };
      }

      return null;
    },
  });

  const onBatchApply = () => {
    const applyBatchResult = formattedBatchSku(
      sku,
      filterData,
      formBatch,
      checkedVariant
    );
    dispatch({
      type: constants.SAVE_PRODUCT_ASSETS,
      payload: { sku: applyBatchResult },
    });
  };

  const onFormBatchToggleChange = () => {
    const tempFormBatcn = { ...formBatch };
    tempFormBatcn.status = formBatch.status === 1 ? 0 : 1;
    setFormBatch(tempFormBatcn);
  };

  return (
    <>
      {productVariants &&
        ((!isEmpty(productVariants[0]?.variant_values) &&
          productVariants?.[0]?.variant_values.length > 1) ||
          (!isEmpty(productVariants[1]?.variant_values) &&
            productVariants?.[1]?.variant_values.length > 1)) && (
          <Flex alignItems="center">
            <Flex flexDirection="column" rowGap="8px" flex={1}>
              <Flex columnGap="8px" alignItems="center">
                <Checkbox
                  checked={isCheckAll}
                  onClick={onCheckAll}
                  indeterminate={filteredCount > 0 || checkedVariant.length > 0}
                />
                <Text weight="bold">Daftar Varian</Text>
              </Flex>
              <Text variant="caption" color="black50">
                {checkCount > 0
                  ? `${checkCount}/${sku.length} varian dipilih`
                  : "Kamu bisa mengatur seluruh produk varian sekaligus dengan menceklis pilihan produk varian yang ingin diatur."}
              </Text>
            </Flex>
            {checkCount > 0 && (
              <Flex columnGap="16px">
                <Button
                  variant="tertiary"
                  size="small"
                  onClick={() => {
                    setIsCheckAll(false);
                    setFilterData({});
                    setFormBatch({ status: 1 });
                    setCheckedVariant([]);
                  }}
                >
                  Batal
                </Button>
                <Button variant="secondary" size="small" onClick={onBatchApply}>
                  Terapkan
                </Button>
              </Flex>
            )}
          </Flex>
        )}
      {checkCount > 0 && (
        <Flex
          backgroundColor="blue5"
          padding="16px"
          borderRadius="8px"
          flexDirection="column"
          mt="-24px"
        >
          <Flex justifyContent="space-between">
            <Text variant="caption" weight="bold">
              Status
            </Text>
            <Flex onClick={onFormBatchToggleChange}>
              <Toggle checked={formBatch.status === 1} />
            </Flex>
          </Flex>
          <Flex columnGap="16px">
            <Flex flexDirection="column" rowGap="8px" flex={2}>
              <Text variant="caption" weight="bold">
                Harga
              </Text>
              <Input.Number
                size="small"
                prefix={
                  <Text variant="caption" weight="bold" color="black50">
                    Rp
                  </Text>
                }
                placeholder="Harga"
                onChange={(value) => onFormFilterChange("price", value)}
                value={formBatch?.price}
                max={999999999}
              />
            </Flex>
            <Flex flexDirection="column" rowGap="8px" width="100px">
              <Text variant="caption" weight="bold">
                Stok
              </Text>
              <Input.Number
                size="small"
                placeholder="Stok"
                max={99999}
                onChange={(value) => onFormFilterChange("stock", value || 1)}
                min={!isEdit && 1}
                value={formBatch?.stock === 0 ? "0" : formBatch?.stock}
                onFocus={(event) => event.target.select()}
              />
            </Flex>
          </Flex>
          <Flex columnGap="16px">
            <Flex flexDirection="column" rowGap="8px">
              <Text variant="caption" weight="bold">
                Foto
              </Text>
              {!isEmpty(formBatch?.image_url) ? (
                <Flex
                  width="40px"
                  height="40px"
                  borderRadius="8px"
                  cursor="pointer"
                  background={`url("${formBatch?.image_url}")`}
                  backgroundRepeat="no-repeat"
                  backgroundPosition="center"
                  backgroundSize="contain"
                  borderWidth="1px"
                  borderColor="black5"
                  borderStyle="solid"
                  backgroundColor="white"
                  onClick={onOpen}
                />
              ) : (
                <Upload
                  {...registerUploadBatch}
                  accept="image/jpg, image/jpeg, image/png"
                  onOpen
                >
                  <Flex
                    width="40px"
                    height="40px"
                    borderRadius="8px"
                    alignItems="center"
                    justifyContent="center"
                    cursor="pointer"
                    backgroundColor="black5"
                  >
                    <Icon name="Plus-solid" size={16} color="blue50" />
                  </Flex>
                </Upload>
              )}
            </Flex>
            <Flex flexDirection="column" rowGap="8px" flex={1}>
              <Text variant="caption" weight="bold">
                SKU
              </Text>
              <Input
                size="small"
                placeholder="SKU"
                onChange={(event) =>
                  onFormFilterChange("number", event.target.value)
                }
                value={formBatch?.number || ""}
              />
            </Flex>
            <Flex flexDirection="column" rowGap="8px" width="100px">
              <Text variant="caption" weight="bold">
                Berat
              </Text>
              <Input.Number
                size="small"
                placeholder="Berat"
                max={uom === 1 ? 999 : 999999}
                required
                onChange={(value) => onFormFilterChange("weight", value)}
                sufix={
                  <Text color="black50" variant="caption" weight="bold">
                    {uom === 1 ? "kg" : "g"}
                  </Text>
                }
                value={formBatch?.weight || ""}
                min={1}
                onFocus={(event) => event.target.select()}
              />
            </Flex>
          </Flex>
        </Flex>
      )}

      <Drawer
        isVisible={isOpen}
        onClose={onClose}
        placement="bottom"
        closable
        header={{
          title: "Foto",
        }}
      >
        <Flex flexDirection="column" rowGap="16px" pt={16} mx={-16}>
          <Flex pl={16} onClick={onDeleteImage}>
            <Text>Hapus</Text>
          </Flex>
          <Divider />
          <Upload {...registerUploadBatch} accept={IMAGE_FILE_TYPE}>
            <Flex pl={16}>
              <Text>Ubah</Text>
            </Flex>
          </Upload>
        </Flex>
      </Drawer>
    </>
  );
};

export default VariantFormBatch;
