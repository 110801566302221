import { useMemo, useState } from "react";
import {
  Box,
  Checkbox,
  Flex,
  Icon,
  Input,
  Skeleton,
  Text,
  range,
} from "renos-ui";
import { useGetCategory } from "services/promo-management";
import FailLoadData from "widgets/mobile/FailLoadData";

const Category = ({ value, setValue }) => {
  const [searchValue, setSearchValue] = useState("");

  const { data, isLoading, isError, refetch, isRefetching } = useGetCategory();

  const isChecked = (id) => {
    return value.map((val) => val.value).includes(id);
  };

  const handleCheck = (newValue) => {
    let tempValue = value;

    if (isChecked(newValue.category_id)) {
      tempValue = tempValue.filter(
        (dataValue) => dataValue.value !== newValue.category_id
      );
    } else {
      tempValue.push({
        label: newValue.category_name,
        value: newValue.category_id,
      });
    }

    setValue([...tempValue]);
  };

  const isLoadingAll = useMemo(
    () => isLoading || isRefetching,
    [isLoading, isRefetching]
  );

  const dataSource = useMemo(() => {
    let tempDataSource = data?.data || [];

    if (searchValue) {
      const filteredCategories = [];
      // eslint-disable-next-line
      const filterCategories = tempDataSource.map((category) => {
        if (
          category.category_name
            .toLocaleLowerCase()
            .includes(searchValue.toLocaleLowerCase())
        ) {
          filteredCategories.push(category);
        } else {
          const tempCategories = [];
          // eslint-disable-next-line
          const filterServices = category.child_categories.map(
            // eslint-disable-next-line
            (categoriesChild) => {
              if (
                categoriesChild.category_name
                  .toLocaleLowerCase()
                  .includes(searchValue.toLocaleLowerCase())
              ) {
                tempCategories.push(categoriesChild);
              }
            }
          );

          if (tempCategories.length !== 0) {
            filteredCategories.push({
              ...category,
              categories: tempCategories,
            });
          }
        }
      });

      tempDataSource = filteredCategories;
    }

    return tempDataSource;
  }, [data, searchValue]);

  return (
    <Flex flexDirection="column" gap="24px" height="293px" overflow="auto">
      <Flex flexDirection="column" gap="12px">
        <Text weight="bold" color="black75">
          Pilih Kategori Produk
        </Text>

        <Input
          size="small"
          prefix={<Icon name="Search-outline" size={20} />}
          placeholder="Cari nama Kategori"
          onChange={(event) => setSearchValue(event.target.value)}
          value={searchValue}
        />
      </Flex>
      {isLoadingAll ? (
        <Flex flexDirection="column" rowGap="16px">
          <Skeleton height="20px" width="150px" />
          {range(3).map((_, indexSkeleton) => (
            <Flex flexDirection="column" key={`category-${indexSkeleton}`}>
              <Flex flexWrap="wrap" gap="12px">
                {range(2).map((_, indexSkeletonService) => (
                  <Flex
                    gap="8px"
                    alignItems="center"
                    key={`service-${indexSkeletonService}`}
                    width="200px"
                  >
                    <Skeleton height="20px" width="20px" />
                    <Skeleton height="20px" width="120px" />
                  </Flex>
                ))}
              </Flex>
            </Flex>
          ))}
        </Flex>
      ) : isError ? (
        <FailLoadData refetch={refetch} noImg={true} />
      ) : dataSource.length === 0 ? (
        <Box textAlign="center" width="100%">
          <Text variant="caption" color="black75">
            Jasa pengiriman tidak tidak ditemukan
          </Text>
        </Box>
      ) : (
        <Flex flexDirection="column" rowGap="24px">
          {dataSource.map((category, indexCategory) => (
            <Flex
              gap="16px"
              key={`category-${indexCategory}`}
              flexDirection="column"
            >
              <Text variant="caption" weight="bold" color="black50">
                {category.category_name}
              </Text>
              <Flex columnGap="16px" flexWrap="wrap" rowGap="12px">
                {category.child_categories.map(
                  (categoryChild, indexCategoryChild) => (
                    <Flex
                      gap="8px"
                      alignItems="center"
                      key={`service-${indexCategoryChild}`}
                      width="200px"
                    >
                      <Checkbox
                        checked={isChecked(categoryChild.category_id)}
                        onChange={() => handleCheck(categoryChild)}
                      />
                      <Text variant="caption" color="black75">
                        {categoryChild.category_name}
                      </Text>
                    </Flex>
                  )
                )}
              </Flex>
            </Flex>
          ))}
        </Flex>
      )}
    </Flex>
  );
};

export default Category;
