import {
  Dropdown,
  Flex,
  Icon,
  Input,
  Label,
  Pagination,
  Text,
  useDisclosure,
} from "renos-ui";
import Table from "widgets/desktop/TableVoucherList";
import Filter from "./component/Filter";
import { useMemo, useState } from "react";
import { currencyFormatter } from "helpers";
import dayjs from "dayjs";
import { useGetVoucher } from "services/voucher-management";
import Empty from "../Empty";
import FailLoadData from "widgets/global/FailLoadData";
import Loading from "./component/Loading";
import { useHistory } from "react-router-dom";
import desktopUrl from "constants/desktopUrl";
import ModalConfirmation from "./component/ModalConfirmation";

const List = ({ dataFilter, setDataFilter }) => {
  const { push } = useHistory();
  const { isOpen, onClose, onOpen } = useDisclosure({ isOpen: false });
  const [selected, setSelected] = useState({});

  const {
    data: dataVoucher,
    isLoading,
    isRefetching,
    isError,
    refetch,
  } = useGetVoucher({
    ...dataFilter,
    status:
      dataFilter?.custom_status === 1
        ? "berlangsung"
        : dataFilter?.custom_status === 2
          ? "menunggu"
          : parseInt(dataFilter?.status) === 2
            ? "berakhir"
            : "",
  });

  const handleEndVoucher = (newData) => {
    setSelected(newData);
    onOpen();
  };

  const tableColumns = [
    {
      title: "Informasi Voucher",
      dataIndex: "check",
      key: "checkBox",
      // width: "30%",
      render: (_, data) => {
        const {
          promo_name: promoName,
          voucher_specific_type_name: voucherSpecific,
          voucher_target_name: voucherTarget,
          discount_type_id: discountTypeId,
          promo_percentage: promoPercentage,
          promo_amount: promoAmount,
          promo_maximum_amount: promoMaxAmount,
          minimum_transaction: minimumTransaction,
          voucher_specific_type_id: voucherSpecificId,
        } = data;

        return (
          <Flex alignItems="center" columnGap="16px">
            <Flex
              flexDirection="column"
              gap="2px"
              borderRadius="8px"
              backgroundColor="blue5"
              alignItems="center"
              justifyContent="center"
              width="64px"
              height="64px"
              textAlign="center"
            >
              <Icon
                name={voucherSpecificId === 1 ? "Store-solid" : "Product-solid"}
                color="blue50"
                size={24}
              />
              <Text color="blue50" variant="small" weight="bold">
                {voucherSpecific}
              </Text>
            </Flex>

            <Flex gap="4px" flexDirection="column" maxWidth="calc(100% - 80px)">
              <Flex style={{ wordBreak: "break-all" }}>
                <Text weight="bold">{promoName}</Text>
              </Flex>
              <Text
                variant="caption"
                color="black75"
              >{`${voucherSpecific} - ${voucherTarget}`}</Text>
              <Text variant="caption" color="black75">{`Diskon ${
                discountTypeId === 1
                  ? `${promoPercentage}%`
                  : `Rp.${currencyFormatter(promoAmount)}`
              } ${
                discountTypeId === 1
                  ? `max Rp.${currencyFormatter(promoMaxAmount)}`
                  : `min Rp.${currencyFormatter(minimumTransaction)}`
              }`}</Text>
            </Flex>
          </Flex>
        );
      },
    },
    {
      title: "Kode Voucher",
      dataIndex: "promo_code",
      key: "promo_code",
      render: (_, data) => {
        const { promo_code } = data;

        return (
          <Text variant="caption" color="black75" weight="bold">
            {promo_code}
          </Text>
        );
      },
    },
    {
      title: "Kuota & Penggunaan",
      dataIndex: "product_information",
      key: "product_information",
      render: (_, data) => {
        const {
          promo_user_limit: userUseLimit,
          promo_quota: promoQuota,
          total_used: totalUsed,
        } = data;

        return (
          <Flex gap="4px" flexDirection="column">
            <Text variant="caption" color="black75">
              Voucher tersedia: <b>{promoQuota}</b>
            </Text>
            <Text variant="caption" color="black75">
              Batas penggunaan: <b>{userUseLimit}</b>
            </Text>
            <Text variant="caption" color="black75">
              Voucher terpakai: <b>{totalUsed}</b>
            </Text>
          </Flex>
        );
      },
    },
    {
      title: "Periode",
      dataIndex: "formatted_product_discount",
      key: "formatted_product_discount",
      render: (_, data) => {
        const { promo_date_start: dateStart, promo_date_end: dateEnd } = data;

        return (
          <Flex flexDirection="column">
            <Text variant="caption" color="black75">
              {`${dayjs(dateStart).format("DD MMM YYYY (HH:mm)")} -`}
            </Text>
            <Text variant="caption" color="black75">
              {dayjs(dateEnd).format("DD MMM YYYY (HH:mm)")}
            </Text>
          </Flex>
        );
      },
    },

    {
      title: "Status",
      dataIndex: "formatted_product_price",
      key: "formatted_product_price",
      render: (_, data) => {
        const { status } = data;

        switch (status) {
          case "Berakhir":
            return <Label>Berakhir</Label>;

          default:
            return (
              <Label variant={status === "Berlangsung" ? "success" : "warning"}>
                {status}
              </Label>
            );
        }
      },
    },
    {
      title: "",
      dataIndex: "cta",
      key: "cta",
      width: "96px",
      render: (_, data) => {
        return (
          <Flex>
            <Dropdown>
              <Dropdown.Toggle>
                <Flex cursor="pointer">
                  <Input
                    size="small"
                    readOnly
                    value="Atur"
                    sufix={
                      <Icon name="Down-outline" size={20} color="black50" />
                    }
                  />
                </Flex>
                <Dropdown.Content>
                  <Dropdown.Item
                    onClick={() =>
                      push(
                        `${desktopUrl.VOUCHER_ADD}?id=${data.promo_id}&view=true`,
                      )
                    }
                  >
                    <Flex py="8px">Lihat</Flex>
                  </Dropdown.Item>

                  {dataFilter.status === "2" && (
                    <Dropdown.Item
                      onClick={() =>
                        push(
                          `${desktopUrl.VOUCHER_ADD}?id=${data.promo_id}&duplicate=true`,
                        )
                      }
                    >
                      <Flex py="8px">Duplikat</Flex>
                    </Dropdown.Item>
                  )}

                  {dataFilter.status !== "2" && (
                    <>
                      <Dropdown.Item
                        onClick={() =>
                          push(`${desktopUrl.VOUCHER_ADD}?id=${data.promo_id}`)
                        }
                      >
                        <Flex py="8px">Ubah</Flex>
                      </Dropdown.Item>

                      <Dropdown.Item onClick={() => handleEndVoucher(data)}>
                        <Flex py="8px">
                          {data.status === "Berlangsung"
                            ? "Akhiri"
                            : "Batalkan"}
                        </Flex>
                      </Dropdown.Item>
                    </>
                  )}
                </Dropdown.Content>
              </Dropdown.Toggle>
            </Dropdown>
          </Flex>
        );
      },
    },
  ];

  const pageCount = useMemo(
    () =>
      dataVoucher?.pagination?.total
        ? Math.ceil(
            dataVoucher?.pagination?.total / dataVoucher?.pagination?.per_page,
          )
        : 1,
    [dataVoucher],
  );

  const dataSource = useMemo(() => dataVoucher?.data || [], [dataVoucher]);

  const isAllLoading = useMemo(
    () => isLoading || isRefetching,
    [isLoading, isRefetching],
  );

  return (
    <Flex paddingTop="24px" flexDirection="column" gap="24px">
      <Filter data={dataFilter} setData={setDataFilter} />
      {isAllLoading ? (
        <Loading />
      ) : isError ? (
        <FailLoadData onClick={refetch} />
      ) : dataSource.length === 0 ? (
        <Empty isInside isFilter={dataFilter.search_value} />
      ) : (
        <>
          <Table
            columns={tableColumns}
            dataSource={dataSource}
            width="100%"
            overflow="auto"
          />
          <Flex justifyContent="flex-end">
            <Pagination
              current={dataFilter.page}
              onChange={(page) => {
                setDataFilter({ ...dataFilter, page: page });
              }}
              total={pageCount}
            />
          </Flex>
          <ModalConfirmation
            {...{ data: selected, isOpen, onClose, dataFilter, setDataFilter }}
          />
        </>
      )}
    </Flex>
  );
};

export default List;
