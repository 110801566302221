import React from "react";
import { Divider, Flex, Skeleton } from "renos-ui";

const SkeletonChatRoom = () => {
  return (
    <Flex flexDirection="column">
      <Flex gap="16px" padding="16px">
        <Flex alignItems="start">
          <Skeleton.Avatar size="40px" />
        </Flex>
        <Flex width="100%" flexDirection="column" gap="8px">
          <Skeleton width="50%" height="20px" />
          <Skeleton width="80%" height="20px" />
        </Flex>
      </Flex>
      <Divider />
    </Flex>
  );
};

export default SkeletonChatRoom;
