import { Drawer, Box, Text } from "renos-ui";

const ActionDrawer = ({ disclosure, onClick, type }) => {
  return (
    <Drawer
      isVisible={disclosure.isOpen}
      closable={true}
      onClose={() => disclosure.onClose()}
      placement="bottom"
    >
      <Box py="16px" onClick={onClick}>
        <Text>{type.id === "bank_book" ? "Lihat File" : "Edit"}</Text>
      </Box>
    </Drawer>
  );
};

export default ActionDrawer;
