import { Button, Icon, useDisclosure } from "renos-ui";
import { StyledButtonIcon } from "../../../styled";
import PopupConfirm from "containers/mobile/order-management/list/component/Card/Footer/component/ConfirmRejectNewOrder/PopupConfirm";
import PopupReject from "containers/mobile/order-management/list/component/Card/Footer/component/ConfirmRejectNewOrder/PopupReject";

const ConfirmRejectNewOrder = ({ orderId, refetch, onClickChat }) => {
  const disclosureConfirm = useDisclosure({ isOpen: false });
  const disclosureReject = useDisclosure({ isOpen: false });

  return (
    <>
      <StyledButtonIcon
        variant="tertiary"
        size="small"
        onClick={disclosureReject.onOpen}
        icon={<Icon size={24} name="Vertical-outline" />}
      />
      <StyledButtonIcon
        variant="tertiary"
        size="small"
        onClick={onClickChat}
        icon={<Icon size={24} name="Chat-outline" />}
      />
      <Button size="medium" isBlock onClick={disclosureConfirm.onOpen}>
        Terima Pesanan
      </Button>

      <PopupConfirm
        disclosureConfirm={disclosureConfirm}
        toFirstPage={refetch}
        orderId={orderId}
      />
      <PopupReject
        disclosureReject={disclosureReject}
        toFirstPage={refetch}
        orderId={orderId}
      />
    </>
  );
};

export default ConfirmRejectNewOrder;
