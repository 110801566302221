import React, { useState, useEffect, useCallback, useContext } from "react";
import SwiperContext from "providers/SwiperProvider";
import { SwiperProvider } from "providers/SwiperProvider";
import { SwiperSlide, useSwiper } from "swiper/react";
import { Box, Dialog, Divider, Flex, Icon, Text, Tooltip } from "renos-ui";
import { truncate } from "helpers";
import { StyledSwiper } from "../styled";

const PreviewShowcase = ({ previewDisclosure, dataProductShowcase }) => {
  const [swiperInstance, setSwiperInstance] = useState();

  const Card = ({ tagName, imageUrl }) => (
    <Flex
      width="141px"
      height="141px"
      borderRadius="12px"
      background={`linear-gradient(180deg, rgba(0, 0, 0, 0) 37.98%, rgba(0, 0, 0, 0.8) 84.31%), url(${imageUrl})`}
      backgroundSize="cover"
    >
      <Box position="relative" width="100%" height="100%">
        <Box width="100%" position="absolute" bottom="12px" left="12px">
          {tagName?.length > 20 ? (
            <Tooltip placement="top" label={tagName}>
              <Text weight="bold" color="white">
                {truncate(tagName)}
              </Text>
            </Tooltip>
          ) : (
            <Text weight="bold" color="white">
              {tagName}
            </Text>
          )}
        </Box>
      </Box>
    </Flex>
  );

  return (
    <Dialog
      isVisible={previewDisclosure.isOpen}
      closable
      onClose={previewDisclosure.onClose}
      width="900px"
      height="280px"
      verticalCentered
    >
      <Flex flexDirection="column" width="100%" gap="24px">
        <Flex flexDirection="column">
          <Text variant="body" weight="bold">
            Preview Etalase
          </Text>

          <Text variant="caption">
            Bagian etalase pada halaman tokomu akan terlihat seperti tampilan di
            bawah ini.
          </Text>
        </Flex>
        <Flex>
          <Divider />
        </Flex>
        <Flex flexDirection="column" gap="24px">
          <Text variant="heading3" weight="bold">
            Etalase Pilihan
          </Text>
          <Flex gap="12px">
            <SwiperProvider value={{ setSwiperInstance, swiperInstance }}>
              {dataProductShowcase.length > 6 && <NavigationButton prev />}

              <StyledSwiper spaceBetween={2} slidesPerView={6}>
                {dataProductShowcase?.map((list, index) => (
                  <Box key={`List-${index}`}>
                    <SwiperSlide>
                      <Card
                        tagName={list.tag?.tag_name}
                        imageUrl={list?.tag_image_url}
                      />
                    </SwiperSlide>
                  </Box>
                ))}

                <SwiperInstance />
              </StyledSwiper>
              {dataProductShowcase.length > 6 && <NavigationButton />}
            </SwiperProvider>
          </Flex>
        </Flex>
      </Flex>
    </Dialog>
  );
};

const SwiperInstance = () => {
  const { setSwiperInstance } = useContext(SwiperContext);
  const swiper = useSwiper();

  useEffect(() => {
    if (swiper) return setSwiperInstance(swiper);
  }, [swiper, setSwiperInstance]);
};

const NavigationButton = ({ prev }) => {
  const { swiperInstance } = useContext(SwiperContext);

  const onPrevClicked = useCallback(() => {
    if (prev) return swiperInstance.slidePrev();

    swiperInstance.slideNext();
  }, [swiperInstance, prev]);

  return (
    <Flex
      width="32px"
      height="32px"
      position="absolute"
      left={prev && "20px"}
      right={!prev && "20px"}
      top="240px"
      backgroundColor={"white"}
      justifyContent="center"
      alignItems="center"
      borderRadius="50px"
      zIndex={1000}
      onClick={onPrevClicked}
      cursor="pointer"
      boxShadow="0px 4px 4px rgba(20, 20, 32, 0.08)"
    >
      <Icon
        name={prev ? "Left-outline" : "Right-outline"}
        color="black50"
        size={16}
      />
    </Flex>
  );
};

export default PreviewShowcase;
