import { Checkbox, Flex, Text } from "renos-ui";

const ProductItem = ({ item, checked, handleCheckProduct, disabledCheck }) => {
  return (
    <Flex
      gap="16px"
      pb="12px"
      alignItems="center"
      borderBottomWidth="1px"
      borderBottomStyle="solid"
      borderBottomColor="black5"
      width="100%"
    >
      <Flex>
        <Checkbox
          size="small"
          checked={checked}
          onChange={() => handleCheckProduct(item)}
          disabled={disabledCheck}
        />
      </Flex>
      <Flex
        as="img"
        width="56px"
        height="56px"
        borderRadius="8px"
        backgroundSize="cover"
        alt="produt-image"
        src={item.product_image_url}
        onError={(e) => {
          e.target.onerror = null;
          e.target.src = "/assets/images/empty-product.svg";
        }}
      />
      <Flex gap="4px" flexDirection="column">
        <Text color="black75" lineClamp={3} style={{ wordBreak: "break-all" }}>
          {item?.product_name}
        </Text>
        <Text weight="bold">{item?.formatted_selling_price}</Text>
      </Flex>
    </Flex>
  );
};

export default ProductItem;
