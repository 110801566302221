import ENDPOINTS from "constants/endpoints";
import { useMutation, useQuery } from "react-query";
import client from "../../client";

const getCouriersFetcher = async () => {
  const { data } = await client.get(ENDPOINTS.couriers);

  return data;
};

export const useGetCouriers = (extraParams) => {
  return useQuery(["getCouriers"], getCouriersFetcher, {
    staleTime: 0,
    ...extraParams,
  });
};

const updateCouriersFetcher = async (body) => {
  const response = await client.post(ENDPOINTS.couriers_bulk_update, body);

  return response.data;
};

export const useUpdateCouriers = (extraParams) =>
  useMutation((body) => updateCouriersFetcher(body), {
    ...extraParams,
  });

const getShippingInformation = async () => {
  const { data } = await client.get(ENDPOINTS.shipping_information);

  return data;
};

export const useGetShippingInformation = (extraParams) => {
  return useQuery(["getShippingInformation"], getShippingInformation, {
    staleTime: 0,
    ...extraParams,
  });
};
