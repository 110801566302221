import {
  Dialog,
  Flex,
  Input,
  Text,
  isEmpty,
  useDisclosure,
  useToast,
} from "renos-ui";
import { StyledButton } from "./styled";
import { useState } from "react";
import { useRejectOrder } from "services/order-management";
import ModalRejectOtherReason from "./ModalRejectOtherReason";
import ModalRejectOutOfStock from "./ModalRejectOutOfStock";
import dayjs from "dayjs";
import ModalRejectStoreClosed from "./ModalRejectStoreClosed";
import { ModalCancelOrderProvider } from "providers/ModalCancelOrder";

const ModalReject = ({
  orderId,
  products,
  disclosure,
  refetchList,
  refetchStatus,
}) => {
  const toast = useToast();
  const disclosureOtherReason = useDisclosure({ isOpen: false });
  const disclosureOutOfStock = useDisclosure({ isOpen: false });
  const disclosureStoreClosed = useDisclosure({ isOpen: false });
  const [selectedRejectReason, setSelectedRejectReason] = useState({});
  const [rejectPayload, setRejectPayload] = useState({
    id: [orderId],
    order_cancellation_reason_id: selectedRejectReason.key,
    order_cancellation_reason: "",
    order_product_id: products.map((item) => item.order_product_id),
    date_off_start: "",
    date_off_end: "",
  });

  const rejectReasonItems = [
    {
      key: "1",
      value: "Stok Produk Kosong",
    },
    {
      key: "2",
      value: "Toko Sedang Libur/Tutup",
    },
    {
      key: "3",
      value: "Kendala Pengiriman",
    },
    {
      key: "4",
      value: "Pembeli Tidak Merespon",
    },
    {
      key: "5",
      value: "Lainnya",
    },
  ];

  const { mutate: rejectOrder, isLoading } = useRejectOrder({
    onSuccess: (data) => {
      const { message, frontend_message } = data;

      refetchStatus();
      refetchList();
      disclosure.onClose();

      toast({
        label: frontend_message || message || "Pesanan berhasil ditolak",
        placement: "top",
      });
    },
    onError: (data) => {
      const {
        response: {
          data: { frontend_message, message },
        },
      } = data;
      toast({
        label: frontend_message || message || "Pesanan gagal ditolak",
        placement: "top",
        backgroundColor: "red50",
      });
    },
  });

  const handleConfirm = () => {
    disclosure.onClose();
    setRejectPayload((prev) => ({
      ...prev,
      order_cancellation_reason_id: selectedRejectReason.key,
    }));

    switch (selectedRejectReason.key) {
      case "1":
        disclosureOutOfStock.onOpen();
        break;
      case "2":
        setRejectPayload((prev) => ({
          ...prev,
          date_off_start: dayjs().format("YYYY-MM-DD"),
          date_off_end: dayjs().add(1, "days").format("YYYY-MM-DD"),
        }));
        disclosureStoreClosed.onOpen();
        break;
      default:
        disclosureOtherReason.onOpen();
        break;
    }
  };

  const handleReject = () => {
    const formData = new FormData();

    for (const item of Object.keys(rejectPayload)) {
      const value = rejectPayload[item];

      if (value) {
        if (item === "date_off_start" || item === "date_off_end") {
          formData.append(item, dayjs(value).format("YYYY-MM-DD"));
        } else {
          formData.append(item, value);
        }
      }
    }

    rejectOrder(formData);
  };

  return (
    <ModalCancelOrderProvider
      value={{
        isLoading,
        products,
        onReject: handleReject,
        payloadReject: rejectPayload,
        onOpenParent: disclosure.onOpen,
        setPayloadReject: setRejectPayload,
      }}
    >
      <Dialog
        isVisible={disclosure.isOpen}
        onClose={disclosure.onClose}
        verticalCentered
        closable
      >
        <Flex flexDirection="column" gap="24px" width="320px">
          <Text variant="heading3" weight="bold">
            Tolak Pesanan
          </Text>
          <Flex flexDirection="column" gap="24px">
            {rejectReasonItems.map((item) => (
              <Flex
                gap="8px"
                cursor="pointer"
                alignItems="center"
                key={`reject-reason-${item.key}`}
                onClick={() => setSelectedRejectReason(item)}
              >
                <Input.Radio checked={selectedRejectReason.key === item.key} />
                <Text>{item.value}</Text>
              </Flex>
            ))}
          </Flex>
          <StyledButton
            isBlock
            onClick={handleConfirm}
            disabled={isEmpty(selectedRejectReason)}
          >
            Konfirmasi
          </StyledButton>
        </Flex>
      </Dialog>

      <ModalRejectOtherReason
        isOpen={disclosureOtherReason.isOpen}
        onClose={disclosureOtherReason.onClose}
      />
      <ModalRejectOutOfStock
        isOpen={disclosureOutOfStock.isOpen}
        onClose={disclosureOutOfStock.onClose}
      />
      <ModalRejectStoreClosed
        isOpen={disclosureStoreClosed.isOpen}
        onClose={disclosureStoreClosed.onClose}
      />
    </ModalCancelOrderProvider>
  );
};

export default ModalReject;
