import { Flex, Skeleton } from "renos-ui";

const CardProductSkeleton = () => {
  return (
    <Flex
      flexDirection="column"
      borderRadius="8px"
      boxShadow="0px 2px 4px 0px #14142026"
      width="160px"
    >
      <Skeleton height="160px" width="160px" />
      <Flex
        flexDirection="column"
        gap="4px"
        p="12px"
        // height="154px"
        justifyContent="space-between"
      >
        <Flex flexDirection="column" gap="4px">
          <Skeleton height="20px" width="120px" />
          <Skeleton height="20px" width="120px" />
        </Flex>

        <Flex flexDirection="column" gap="4px">
          <Skeleton height="20px" width="120px" />
          <Skeleton height="20px" width="120px" />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default CardProductSkeleton;
