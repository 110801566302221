import { useEffect, useState, useRef, useMemo } from "react";
import PropTypes from "prop-types";
import { Box, Flex, Text, isEmpty } from "renos-ui";
import ImgLocation from "assets/icons/Icon_Solid_Location.svg";
import { StyledInput, MapsContainer, StyledDatalist } from "./styled";
import { GoogleMap, useLoadScript } from "@react-google-maps/api";
import {
  API_KEY,
  DEFAULT_MAP_POSITION,
  LIBRARIES as libraries,
} from "constants/googleMap";
import ImgShadow from "assets/icons/Shadow_Pin_Point.png";

const mapContainerStyle = {
  width: "100%",
  height: "100%",
  borderRadius: "8px",
};

const mapOptions = {
  mapTypeControl: false,
  zoomControl: false,
  scaleControl: true,
  streetViewControl: false,
  rotateControl: true,
  fullscreenControl: false,
  mapTypeId: "roadmap",
};

const MapMobile = ({ onChange, position }) => {
  const mapRef = useRef(null);
  const [isDragged, setIsDragged] = useState(false);
  const [autocompleteOptions, setAutocompleteOptions] = useState([]);
  const defaultMapLatitude = useMemo(() => {
    return isEmpty(position?.lat)
      ? DEFAULT_MAP_POSITION.latitude
      : position.lat;
  }, [position?.lat]);

  const defaultMapLongitude = useMemo(() => {
    return isEmpty(position?.long)
      ? DEFAULT_MAP_POSITION.longitude
      : position.long;
  }, [position?.long]);

  const [currentMapCoordinates, setCurrentMapCoordinates] = useState({
    lat: defaultMapLatitude,
    lng: defaultMapLongitude,
  });

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: API_KEY,
    libraries,
  });

  const setLocation = async () => {
    const geocoder = new window.google.maps.Geocoder();

    try {
      const response = await geocoder.geocode({
        location: currentMapCoordinates,
      });

      if (response.results[0]) {
        onChange(currentMapCoordinates, response.results[0].formatted_address);
      } else {
        onChange(
          currentMapCoordinates,
          "No address found for the given coordinates."
        );
      }
    } catch (error) {
      onChange(
        currentMapCoordinates,
        "No address found for the given coordinates."
      );
    }
  };

  const searchHandler = async (event) => {
    const STATUS_OK = "OK";
    const input = event.target.value;

    try {
      const autocompleteService =
        new window.google.maps.places.AutocompleteService();
      const predictions = await new Promise((resolve, reject) =>
        autocompleteService.getPlacePredictions(
          { input, componentRestrictions: { country: "id" } },
          (predictions, status) =>
            status === STATUS_OK ? resolve(predictions) : reject(status)
        )
      );

      if (predictions && predictions.length > 0) {
        const options = predictions.map((prediction) => prediction.description);
        setAutocompleteOptions(options);

        const placeId = predictions[0].place_id;
        const geocoder = new window.google.maps.Geocoder();
        const results = await new Promise((resolve, reject) =>
          geocoder.geocode({ placeId }, (results, status) =>
            status === STATUS_OK ? resolve(results) : reject(status)
          )
        );

        if (results && results.length > 0) {
          const lat = results[0].geometry.location.lat();
          const lng = results[0].geometry.location.lng();
          setCurrentMapCoordinates({ lat, lng });

          const googleMap = mapRef.current.state.map;
          googleMap.setCenter({ lat, lng });
          setLocation();
        }
      } else {
        setAutocompleteOptions([]);
      }
    } catch (error) {
      setAutocompleteOptions([]);
    }
  };

  const onLoadMap = () => {
    if (mapRef.current) {
      const map = mapRef.current.state.map;
      const marker = new window.google.maps.Marker({
        icon: ImgLocation,
        map,
        animation: window.google.maps.Animation.DROP,
      });

      mapRef.current.mapMarker = marker;
      marker.bindTo("position", map, "center");
    }
  };

  const onDragStart = () => {
    setIsDragged(true);
    mapRef.current.mapMarker.setAnimation(3);
  };

  const onDragEnd = () => {
    mapRef.current.mapMarker.setAnimation(4);
    const { lat, lng } = mapRef.current.state.map.getCenter();

    setCurrentMapCoordinates({
      lat: lat(),
      lng: lng(),
    });

    setIsDragged(false);
  };

  const onCenterChanged = () => {
    if (!isDragged) {
      setLocation();
    }
  };

  useEffect(() => {
    if (!isEmpty(position.lat)) {
      setTimeout(() => {
        setLocation();
      }, 100);
    }

    // eslint-disable-next-line
  }, []);

  return (
    <MapsContainer width="100%" position="relative" flexDirection="column">
      <StyledInput
        type="search"
        onChange={searchHandler}
        placeholder="Cari Lokasi"
        list="autocompleteOptions"
      />
      <StyledDatalist id="autocompleteOptions">
        {autocompleteOptions.map((option, index) => (
          <option key={index} value={option} />
        ))}
      </StyledDatalist>

      <Box minHeight="calc(100% - 260px)" width="100%" position="relative">
        {isLoaded ? (
          <GoogleMap
            zoom={16}
            ref={mapRef}
            onLoad={onLoadMap}
            options={mapOptions}
            onDragEnd={onDragEnd}
            onDragStart={onDragStart}
            onCenterChanged={onCenterChanged}
            center={currentMapCoordinates}
            mapContainerStyle={mapContainerStyle}
          >
            <Box
              as="img"
              src={ImgShadow}
              alt="img-shadow"
              position="absolute"
              top="30dvh"
              left="50%"
              zIndex="1"
              margin="auto"
              marginLeft="-8px"
              height="4px"
              width="16px"
              cursor="pointer"
            />
          </GoogleMap>
        ) : (
          <Flex
            height="100%"
            width="100%"
            backgroundColor="black5"
            borderRadius={8}
            justifyContent="center"
            alignItems="center"
          >
            <Text size="small" color="black75">
              Memuat Peta...
            </Text>
          </Flex>
        )}
      </Box>
    </MapsContainer>
  );
};

MapMobile.defaultProps = {
  config: {},
  position: {},
  onChange: () => {},
};

MapMobile.propTypes = {
  config: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  position: PropTypes.objectOf(PropTypes.number),
};

export default MapMobile;
