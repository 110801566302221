import { useState, useContext, useRef, useEffect } from "react";
import {
  Text,
  Flex,
  useDisclosure,
  Divider,
  Pagination,
  useToast,
  Box,
  Icon,
} from "renos-ui";
import EmptyList from "./EmptyList";
import FilterRating from "./FilterRating";
import { useGetRating, useGetRatingAverage } from "services/sales";
import StoreContext from "providers/StoreProvider";
import { FilterRatingProvider } from "providers/FilterRatingReview";
import SkeletonRating from "./SkeletonRating";

const listFilter = [
  {
    label: "Ulasan Terbanyak",
    value: "reviews_count",
    params: {
      order_by: "reviews_count",
      ordering: "desc",
    },
  },
  {
    label: "Ulasan Tersedikit",
    value: "average_score",
    params: {
      order_by: "reviews_count",
      ordering: "asc",
    },
  },
  {
    label: "Rating Tertinggi",
    value: "asc",
    params: {
      order_by: "score",
      ordering: "desc",
    },
  },
  {
    label: "Rating Terendah",
    value: "desc",
    params: {
      order_by: "score",
      ordering: "asc",
    },
  },
];

const Rating = () => {
  const toast = useToast();
  const filterDisclosure = useDisclosure({ open: false });
  const {
    storeData: {
      data: { id: storeId },
    },
  } = useContext(StoreContext);
  const containerRef = useRef();
  const [pagination, setPagination] = useState({});
  const [filter, setFilter] = useState({
    page: pagination?.current_page || 1,
    store_id: storeId,
    items_per_page: 10,
    product_name_contains: "",
    include_pagination: true,
    ...listFilter[0].params,
  });
  const { data: ratingAverage } = useGetRatingAverage({
    params: {
      store_id: storeId,
    },
  });

  const {
    data = { data: [] },
    isLoading,
    isError,
  } = useGetRating(
    {
      params: filter,
    },
    {
      onError: (error) => {
        const {
          response: {
            data: { frontend_message: message },
          },
        } = error;

        toast({
          label: message || "Ada masalah dari server.",
          placement: "top",
          backgroundColor: "red50",
        });
      },
      onSuccess: ({ pagination }) => {
        setPagination(pagination);
      },
    }
  );

  useEffect(() => {
    containerRef.current.scrollIntoView({
      behavior: "smooth",
    });
  }, [pagination]);

  return (
    <FilterRatingProvider
      value={{
        filter,
        setFilter,
        listFilter,
      }}
    >
      <Flex
        flexDirection="column"
        gap="16px"
        paddingTop="16px"
        ref={containerRef}
      >
        <Flex
          columnGap="16px"
          alignItems="center"
          justifyContent="space-between"
        >
          <Flex width="300px">
            <Flex flexDirection="column" gap="8px" alignItems="center">
              <Flex alignItems="flex-start">
                <Text color="black75">
                  Rata-rata rating {ratingAverage?.products_count} produk
                </Text>
              </Flex>
              <Flex gap="8px" alignItems="center">
                <Icon size={20} name="Star-solid" color="yellow50" />
                <Text weight="bold" variant="heading2">
                  {ratingAverage?.average_score}
                </Text>
                <Box>
                  <Text color="black50">dari</Text>{" "}
                  <Text weight="bold">
                    {ratingAverage?.reviews_count} ulasan
                  </Text>
                </Box>
              </Flex>
            </Flex>
          </Flex>
          <FilterRating filterDisclosure={filterDisclosure} />
        </Flex>
        <Divider />
        <Flex gap="16px">
          <Flex flexGrow="1">
            <Text color="black50" weight="bold">
              Nama Produk
            </Text>
          </Flex>
          <Flex gap="40px">
            <Flex width="100px" justifyContent="center">
              <Text color="black50" weight="bold">
                Rating
              </Text>
            </Flex>
            <Flex width="120px" justifyContent="center" marginRight="80px">
              <Text color="black50" weight="bold">
                Total Ulasan
              </Text>
            </Flex>
          </Flex>
        </Flex>
        <Divider />
        {isError ? (
          <EmptyList
            title=" Belum Ada Rating"
            description="Rating produkmu dari pembeli akan muncul di halaman ini."
          />
        ) : isLoading ? (
          <SkeletonRating />
        ) : (
          <>
            {data?.data?.length === 0 ? (
              <EmptyList
                title=" Belum Ada Rating"
                description="Rating produkmu dari pembeli akan muncul di halaman ini."
              />
            ) : (
              data?.data?.map((row) => (
                <>
                  <Flex
                    alignItems="center"
                    gap="16px"
                    key={`list-rating-${row.product_id}`}
                  >
                    <Flex flexGrow="1" alignItems="center" gap="16px">
                      <Flex>
                        <Flex
                          borderRadius="8px"
                          height="40px"
                          width="40px"
                          as="img"
                          alt="product-img"
                          src={encodeURI(row?.product_data?.product_image_url)}
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = "/assets/images/empty-product.svg";
                          }}
                        />
                      </Flex>
                      <Text>{row?.product_data?.product_name}</Text>
                    </Flex>
                    <Flex gap="40px">
                      <Flex width="70px" gap="10px" alignItems="center">
                        <Icon
                          size={14}
                          name="Star-solid"
                          color={
                            Math.floor(row.score) <= 2 ? "red50" : "yellow50"
                          }
                        />
                        <Text weight="bold">{row.score}</Text>
                      </Flex>
                      <Flex
                        width="120px"
                        justifyContent="center"
                        marginRight="80px"
                      >
                        <Text>{row.reviews_count}</Text>
                      </Flex>
                    </Flex>
                  </Flex>
                  <Divider />
                </>
              ))
            )}
          </>
        )}

        {data?.data?.length > 0 && (
          <Flex flexDirection="row-reverse">
            <Pagination
              current={pagination.current_page}
              onChange={(val) => {
                setPagination({
                  ...pagination,
                  current_page: val,
                });
                setFilter({
                  ...filter,
                  page: val,
                });
              }}
              total={pagination.total_pages}
            />
          </Flex>
        )}
      </Flex>
    </FilterRatingProvider>
  );
};

export default Rating;
