import React from "react";
import { useFormContext, useWatch } from "react-hook-form";
// import { useMemo } from "react";
// import { useState } from "react";
// import { colors, Flex, isEmpty, SelectInput, useDebounce } from "renos-ui";
import { Box, Flex, get, Icon, isEmpty, SelectInput, Text } from "renos-ui";
import { useGetCategories } from "services/product-category";
// import { useGetProductTags } from "services/product-tag";
import { FieldTextInput } from "widgets/global/HookForm";
import FormField from "./components/FormField";
import SectionWrapper from "./components/SectionWrapper";
import useQueryParams from "hooks/useQueryParams";
import { goToCustomerService } from "helpers";

const ProductInformation = ({ methods }) => {
  const { data: categories } = useGetCategories();

  const {
    formState: { errors },
  } = useFormContext();

  const categoryError = get(errors, "category");

  const productCategory = useWatch({ name: "product_category" });

  // const [recomendationCategory, setRecomendationCategory] = useState();

  const query = useQueryParams();

  // const [searchValue, setSearchValue] = useState();
  // const [tagsQuery, setTagsQuery] = useState("");

  // useDebounce(
  //   () => searchValue && setTagsQuery(searchValue),
  //   [searchValue],
  //   500
  // );
  // const { data: tags, isLoading: isTagloading } = useGetProductTags(
  //   {
  //     keyword: tagsQuery,
  //     limit: 10,
  //   },
  //   {
  //     enabled: !!tagsQuery,
  //   }
  // );

  // const isTagAvailable = useMemo(
  //   () => !isTagloading && !isEmpty(tags),
  //   [isTagloading, tags]
  // );

  return (
    <SectionWrapper sectionName="Informasi Produk" isError={categoryError}>
      <Flex flexDirection="column" rowGap="40px">
        <FormField
          label="Nama Produk"
          isRequired
          hint={`Produkmu akan lebih mudah ditemukan pembeli dengan format: Jenis Produk + Merek + Keterangan`}
        >
          <FieldTextInput
            maxLength={70}
            showCount
            name="product_name"
            placeholder="Contoh: Lemari Pakaian Renos Bahan Kayu Jati"
          />
        </FormField>
        <FormField
          label="Kategori Produk"
          isRequired
          hint={`Pilih kategori yang sesuai dengan produkmu. Jika kurang sesuai, maka kategori akan diubah oleh Renos.`}
        >
          <Flex flexDirection="column" gap="8px">
            <Flex flexDirection="column">
              <SelectInput.Cascader
                isError={categoryError}
                options={categories}
                placeholder="Pilih Kategori Produk"
                value={productCategory}
                disabledLevel={query.get("id") ? 1 : 0}
                onSelect={(value) => {
                  if (isEmpty(value?.category_attributes)) {
                    methods.setValue("product_attributes", []);
                  }

                  const excludeFile = value?.category_attributes.filter(
                    (item) => item.data_type !== "file"
                  );

                  methods.setValue(
                    "product_attributes",
                    excludeFile.length >= 0
                      ? excludeFile.map((item) => ({
                          ...item,
                          product_attribute_value: "",
                        }))
                      : []
                  );
                }}
                onChange={(values) => {
                  methods.setValue("product_category", values);

                  if (values?.value.length === 3) {
                    methods.setValue(
                      "category",
                      values?.value[values?.value.length - 1]
                    );
                  } else {
                    methods.setValue("category", null);
                  }
                  methods.trigger("category");
                }}
              />
              {!isEmpty(categoryError) && (
                <Box mt="8px">
                  <Text
                    variant="caption"
                    color="red50"
                    aria-label="input-error"
                  >
                    {categoryError?.message}
                  </Text>
                </Box>
              )}
            </Flex>
            {query.get("id") && (
              <Flex
                p="12px 16px"
                gap="8px"
                backgroundColor="yellow5"
                borderRadius="8px"
                alignItems="center"
              >
                <Icon name="Info-solid" size={40} color="yellow50" />
                <Text color="yellow100">
                  Kamu tidak dapat mengubah kategori utama dari produk ini.
                  Segera hubungi{" "}
                  <Box
                    as="b"
                    onClick={goToCustomerService}
                    style={{ textDecoration: "underline" }}
                    cursor="pointer"
                  >
                    Customer Service Renos.id
                  </Box>{" "}
                  jika ingin melakukan pengubahan kategori utama.
                </Text>
              </Flex>
            )}
          </Flex>
        </FormField>
        {/* <FormField
          label="Kategori Ruangan"
          isRequired
          hint={`Pilih penempatan ruangan yang sesuai dengan kategori produkmu.`}
        >
          <SelectInput
            placeholder="Pilih Kategori Ruangan"
            options={[{ label: "", value: "" }]}
            styles={{
              placeholder: (base) => ({
                ...base,
                fontSize: "14px",
                color: colors.black75,
              }),
            }}
          />
        </FormField> */}
        {/* <FormField
          label="Etalase"
          hint={`Kamu bisa menambah etalase baru atau memilih etalase yang sesuai.`}
        >
          <SelectInput
            isMulti
            options={
              isTagAvailable
                ? tags
                : [{ value: "", label: "Ketik nama Etalase", disabled: true }]
            }
            menuIsOpen={!isEmpty(tagsQuery) && !isEmpty(searchValue)}
            isCreatable={isEmpty(tags)}
            placeholder="Ketik Nama Etalase Sesuai"
            styles={{
              placeholder: (base) => ({
                ...base,
                fontSize: "14px",
                color: colors.black75,
              }),
              multiValue: (base) => ({
                ...base,
                backgroundColor: "transparent",
                border: `1px solid ${colors.black10}`,
                borderRadius: "8px",
                display: "flex",
                alignItems: "center",
                height: "32px",
              }),
              control: (base) => ({
                ...base,
                padding: "2px 16px !important",
                height: "48px",
              }),
            }}
            onInputChange={(val) => val && setSearchValue(val)}
            onLeave={() => {
              setSearchValue();
            }}
            onChange={(values) => methods.setValue("tags", values)}
            isOptionDisabled={(options) => options.disabled}
          />
        </FormField> */}
      </Flex>
    </SectionWrapper>
  );
};

export default ProductInformation;
