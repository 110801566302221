import { Box, Divider, Flex, Icon, Text, Toggle } from "renos-ui";

const BalasanOtomatisItem = ({
  items,
  handleOpenInformation,
  handleToggleActivate,
  handleOpenFormEdit,
}) => {
  return (
    <>
      {items?.map((item, index) => (
        <Flex
          p="16px"
          gap="12px"
          borderRadius="8px"
          flexDirection="column"
          backgroundColor="white"
          key={`auto-reply-${index}`}
        >
          <Flex justifyContent="space-between" width="100%">
            <Flex gap="8px" width="100%" alignItems="center">
              <Flex onClick={() => handleOpenInformation(item)}>
                <Icon name="Info-solid" size={16} color="black50" />
              </Flex>
              <Text color="black75" weight="bold" variant="caption">
                {item.title}
              </Text>
            </Flex>
            <Box onClick={() => handleToggleActivate(item)}>
              <Toggle checked={item.isActive} />
            </Box>
          </Flex>
          <Divider />
          <Flex>
            <Text variant="caption" color="black50">
              {item?.templateMessage?.length === 0
                ? "Belum ada template"
                : item?.templateMessage}
            </Text>
          </Flex>
          <Flex onClick={() => handleOpenFormEdit(item, index)}>
            <Text variant="caption" color="blue50" weight="bold">
              Ubah Template
            </Text>
          </Flex>
        </Flex>
      ))}
    </>
  );
};

export default BalasanOtomatisItem;
