import { Flex, Text, colors } from "renos-ui";

const SquareThree = ({ imageRef, variant, data, setData }) => {
  const handleSetEdit = (type) => {
    setData((prev) => ({ ...prev, active: type }));
  };

  const renderBorder = (type) => {
    return data?.active === type && `1px solid ${colors.violet50}`;
  };

  return (
    <Flex
      flexDirection="column"
      gap="8px"
      paddingTop="50px"
      position="relative"
    >
      <Flex flexDirection="column" position="absolute" left={-84}>
        <Text variant="caption" color="black50" weight="bold">
          Template {variant}
        </Text>
        <Text variant="caption" color="black50">
          Square Banner 1:1 (1080 x 1080px)
        </Text>
      </Flex>
      <Flex style={{ transform: "scale(1.7)" }} mt="150px">
        <Flex
          width="239px"
          height="239px"
          background="#1833B4"
          ref={imageRef}
          position="relative"
        >
          <Flex
            opacity={data.isDiscountActive ? 1 : 0}
            onClick={() => handleSetEdit("discountRound")}
            cursor="pointer"
            left="6px"
            top="6px"
            width="33px"
            height="33px"
            position="absolute"
            borderRadius="50%"
            backgroundColor="yellow50"
            flexDirection="column"
            alignItems="center"
            backgroundSize="cover"
            border={renderBorder("discountRound")}
            justifyContent="center"
          >
            <Flex gap="1px" alignItems="center">
              <Flex color="black75" fontSize={12} fontWeight={800}>
                {data.discount}
              </Flex>
              <Flex
                color="black75"
                fontSize="7px"
                fontWeight={400}
                letterSpacing={1.23}
              >
                {data.discountSuffix}
              </Flex>
            </Flex>
          </Flex>
          <Flex
            width="80px"
            height="16px"
            background="url('assets/images/store-layout/banner-1x1/template_1_discount_price.svg')"
            alignItems="center"
            backgroundSize="cover"
            color="white"
            justifyContent="end"
            pr="4px"
            right={0}
            top={1}
            position="absolute"
            onClick={() => handleSetEdit("newProduct")}
            cursor="pointer"
            fontWeight={800}
            fontSize="10px"
            border={renderBorder("newProduct")}
          >
            {data.newProduct || "Produk Baru!"}
          </Flex>

          <Flex
            width="145px"
            height="48px"
            background="url('assets/images/store-layout/banner-1x1/template_3_right_footer.svg')"
            alignItems="center"
            backgroundSize="cover"
            right={0}
            bottom={0}
            position="absolute"
            fontWeight={600}
            fontSize="6px"
            justifyContent="end"
            pr="4px"
            color="blue75"
            style={{ textDecoration: "underline" }}
          >
            <Flex
              cursor="pointer"
              onClick={() => handleSetEdit("textSeeProduct")}
              border={renderBorder("textSeeProduct")}
              marginTop="24px"
              zIndex={1}
            >
              {data.textSeeProduct}
            </Flex>
          </Flex>

          <Flex
            width="236px"
            height="48px"
            background="url('assets/images/store-layout/banner-1x1/template_3_left_footer.svg')"
            backgroundSize="cover"
            color="white"
            left={0}
            bottom={0}
            position="absolute"
            justifyContent="center"
            flexDirection="column"
            pl="4px"
          >
            <Flex
              onClick={() => handleSetEdit("price")}
              cursor="pointer"
              border={renderBorder("price")}
              fontSize="8px"
              width="190px"
              style={{ textDecoration: "line-through" }}
            >
              {data.price}
            </Flex>
            <Flex
              onClick={() => handleSetEdit("discountPrice")}
              cursor="pointer"
              border={renderBorder("discountPrice")}
              fontWeight={800}
              fontSize="15px"
              width="190px"
            >
              {data.discountPrice}
            </Flex>
          </Flex>

          <Flex
            as="img"
            alt="img"
            width="239px"
            height="239px"
            src={data.imgSquareZoomout}
            onClick={() => handleSetEdit("imgSquareZoomout")}
            cursor="pointer"
            border={renderBorder("imgSquareZoomout")}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default SquareThree;
