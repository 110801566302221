import { useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  Icon,
  Text,
  range,
  Dialog,
} from "renos-ui";
import Table from "widgets/desktop/TableVoucherAdd";
import Filter from "./Filter";
import CardSkeleton from "./CardSkeleton";
import InfiniteScroll from "widgets/global/InfiniteScroll";
import EmptyData from "./EmptyData";
import {
  isChecked,
  isParentChecked,
  onCheckAllClicked,
  onCheckProductClicked,
  onCheckProductParentClicked,
} from "./useProductPicker";
import { useGetProductsV3 } from "services/product";
import { currencyFormatter } from "helpers";

const ProductPicker = ({
  addDisclosure: { isOpen, onClose },
  onChange,
  value,
}) => {
  const maxProduct = 50;
  const [dataList, setDatalist] = useState([]);
  const [page, setPage] = useState(1);
  const [queryParams, setQueryParams] = useState({
    keyword: "",
    category_id: "",
    ordering: "",
  });
  const [checkedProducts, setCheckedProducts] = useState([]);

  const {
    data: dataProduct,
    isLoading: isLoadingDataProduct,
    isRefetching: isRefetchingDataProduct,
    refetch: refetchProduct,
  } = useGetProductsV3(
    {
      ...queryParams,
      page: page,
      product_status_ids: 1,
    },
    {
      onSuccess: (dataSuccess) => {
        const tempData = dataSuccess.data.map((productData) => {
          const { product_type, product_skus } = productData;

          if (product_type === "variant" && product_skus.length !== 0) {
            productData.isHasMore = true;
          }

          return productData;
        });

        if (page === 1) {
          setDatalist([...tempData]);
        } else {
          const concatData = dataList.concat(tempData);
          setDatalist(concatData);
        }
      },
    },
  );

  const hasNextPage = useMemo(
    () =>
      dataProduct?.pagination?.next_page_url !== "" &&
      dataProduct?.pagination?.next_page_url,
    // eslint-disable-next-line
    [dataList],
  );

  useEffect(() => {
    setPage(1);
  }, [queryParams]);

  const isAllLoadingDataProduct = useMemo(
    () => (isLoadingDataProduct || isRefetchingDataProduct) && page === 1,
    [isLoadingDataProduct, isRefetchingDataProduct, page],
  );

  const isCheckedAll = useMemo(
    () => checkedProducts.length === maxProduct,
    // eslint-disable-next-line
    [checkedProducts, dataList],
  );

  const isIndeterminate = useMemo(
    () => checkedProducts.length > 0 && checkedProducts.length < maxProduct,
    // eslint-disable-next-line
    [checkedProducts, dataList],
  );

  const tableColumns = [
    {
      title: (
        <Checkbox
          checked={isCheckedAll}
          onChange={() =>
            onCheckAllClicked(
              checkedProducts,
              maxProduct,
              setCheckedProducts,
              dataList,
            )
          }
          indeterminate={isIndeterminate}
        />
      ),
      dataIndex: "check",
      key: "checkBox",
      render: (_, data, isParent, isHasChild) => {
        const { product_sku_number: skuNumber, product_skus: productSkus } =
          data;

        const checkDisabled =
          checkedProducts.length < maxProduct
            ? false
            : !isChecked(skuNumber, checkedProducts);

        return (
          <Flex>
            <Checkbox
              checked={
                isParent && isHasChild
                  ? isParentChecked(productSkus, checkedProducts)
                  : isChecked(skuNumber, checkedProducts)
              }
              indeterminate={
                isParent &&
                isHasChild &&
                isParentChecked(productSkus, checkedProducts, true)
              }
              onChange={() =>
                isParent && isHasChild
                  ? onCheckProductParentClicked(
                      productSkus,
                      checkedProducts,
                      setCheckedProducts,
                    )
                  : onCheckProductClicked(
                      data,
                      checkedProducts,
                      setCheckedProducts,
                    )
              }
              disabled={checkDisabled}
            />
          </Flex>
        );
      },
      width: "32px",
      align: "center",
    },
    {
      title: "Produk",
      dataIndex: "product",
      key: "product",
      render: (_, data, isParent, isChild) => {
        const {
          product_image_url: image,
          product_name: name,
          store_sku_number: skuNumber,
          total_product_view: totalSeen,
          total_product_wishlist: totalWishlist,
          total_sold: totalSold,
          formatted_variant_value_name: variantName,
        } = data;

        return (
          <Flex gap="24px">
            <Box
              as="img"
              src={image}
              alt="product-img"
              width="56px"
              height="56px"
              borderRadius="8px"
              backgroundSize="cover"
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = "/assets/images/empty-product.svg";
              }}
            />

            <Flex flexDirection="column">
              <Text weight="bold" lineClamp={1}>
                {`${name}${variantName ? ` - ${variantName}` : ""}`}
              </Text>
              <Text color="black50" variant="caption">
                SKU: {skuNumber}
              </Text>
              {isParent && !isChild && (
                <Flex justifyContent="space-between" width="180px">
                  <Flex gap="4px" alignItems="center">
                    <Icon name="Show-solid" size={16} color="black25" />
                    <Text color="black50" variant="caption">
                      {totalSeen}
                    </Text>
                  </Flex>
                  <Flex gap="4px" alignItems="center">
                    <Icon name="Love-solid" size={16} color="black25" />
                    <Text color="black50" variant="caption">
                      {totalWishlist}
                    </Text>
                  </Flex>
                  <Flex gap="4px" alignItems="center">
                    <Icon name="Cart-solid" size={16} color="black25" />
                    <Text color="black50" variant="caption">
                      {totalSold}
                    </Text>
                  </Flex>
                </Flex>
              )}
            </Flex>
          </Flex>
        );
      },
    },
    {
      title: "Harga",
      dataIndex: "formatted_product_price",
      key: "formatted_product_price",
      width: "184px",
      render: (_, data) => {
        const { formatted_product_price } = data;

        return <Text>{formatted_product_price}</Text>;
      },
    },
    {
      title: "Stok",
      dataIndex: "total_sold",
      key: "total_sold",
      width: "72px",
      render: (_, data) => {
        const { product_stock } = data;

        return <Text>{currencyFormatter(product_stock)}</Text>;
      },
    },
  ];

  const handleSave = () => {
    onChange(checkedProducts);
    onClose();
  };

  useEffect(() => {
    setCheckedProducts(value);

    if (isOpen) {
      if (page === 1) {
        refetchProduct();
      } else {
        setPage(1);
      }
    }
    // eslint-disable-next-line
  }, [isOpen]);

  return (
    <Dialog isVisible={isOpen} onClose={onClose} width="660px" verticalCentered>
      <Flex flexDirection="column" width="100%" justifyContent="space-between">
        <Flex justifyContent="space-between" alignContent="center">
          <Flex
            alignContent="center"
            justifyItems="center"
            alignItems="center"
            gap="8px"
          >
            <Text variant="heading3" weight="bold">
              Tambah Produk
            </Text>
            <Text color="black50">
              (maksimum {maxProduct} produk, termasuk varian)
            </Text>
          </Flex>
          <Icon
            name="Close-outline"
            cursor="pointer"
            size={24}
            color="black60"
            onClick={onClose}
          />
        </Flex>
        <Flex flexDirection="column" margin="24px 0">
          <Divider />

          <Filter setQueryParams={setQueryParams} />
          <Flex
            height="263px"
            overflowY="auto"
            flexDirection="column"
            width="100%"
            gap="16px"
          >
            <InfiniteScroll
              page={page}
              onChange={() => setPage(page + 1)}
              isLoading={isLoadingDataProduct}
              hasNextPage={!isAllLoadingDataProduct && hasNextPage}
              loader={
                <Box>
                  <CardSkeleton />
                </Box>
              }
            >
              {isAllLoadingDataProduct ? (
                range(5).map((_, indexSkeleton) => (
                  <CardSkeleton key={`card-skeleton-${indexSkeleton}`} />
                ))
              ) : dataList.length === 0 ? (
                <EmptyData />
              ) : (
                <Table
                  width="100%"
                  columns={tableColumns}
                  dataSource={dataList}
                  customHeader={
                    checkedProducts.length > 0 && (
                      <Flex columnGap="20px" alignItems="center" pb="12px">
                        <Flex gap="15px" width="379px">
                          <Checkbox
                            checked={isCheckedAll}
                            onChange={() =>
                              onCheckAllClicked(
                                checkedProducts,
                                maxProduct,
                                setCheckedProducts,
                                dataList,
                              )
                            }
                            indeterminate={
                              checkedProducts.length > 0 &&
                              checkedProducts.length < dataList.length
                            }
                          />
                          <Text>
                            {`${checkedProducts.length} / ${maxProduct} Produk Dipilih`}
                          </Text>
                        </Flex>

                        <Flex width="155px">
                          <Text color="black50" weight="bold">
                            Harga
                          </Text>
                        </Flex>

                        <Flex>
                          <Text color="black50" weight="bold">
                            Stok
                          </Text>
                        </Flex>
                      </Flex>
                    )
                  }
                />
              )}
            </InfiniteScroll>
          </Flex>
        </Flex>

        <Flex justifyContent="space-between">
          <Flex />
          <Flex alignSelf="end" width="160px">
            <Button size="medium" isBlock width="160px" onClick={handleSave}>
              Simpan
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </Dialog>
  );
};

export default ProductPicker;
