import {
  blobToBase64,
  getImageMeta,
  imageTypeChecking,
  imgCompressor,
} from "helpers";
import {
  formattedBatchSku,
  variantToSkuFormat,
} from "helpers/attribute-mapper/product-variant";
import React, { useEffect, useMemo, useState } from "react";
import { useWatch } from "react-hook-form";
import {
  Button,
  Checkbox,
  colors,
  Flex,
  Icon,
  Input,
  isEmpty,
  SelectInput,
  Text,
  Toggle,
  Tooltip,
  Upload,
  useToast,
  useUpload,
} from "renos-ui";
import { useUploadProductImage } from "services/product";
import { IMAGE_FILE_TYPE, initialFormBatch } from "../../constants";
import { VariantImage, VariantImageOvelay } from "../styled";
import TableVariant from "./TableVariant";

const VariantForm = ({
  productVariant,
  methods,
  productDetail,
  isEdit,
  sku,
}) => {
  const toast = useToast();

  const filteredProductVariant = useMemo(
    () =>
      productVariant.filter((variant) => variant?.variant_values?.length > 1),
    [productVariant]
  );

  const productWithValue = useMemo(
    () =>
      productVariant.filter((variant) => variant?.variant_values?.length > 0),
    [productVariant]
  );

  const fromEmpty = useWatch({ name: "fromEmpty" });
  const uom = useWatch({ name: "uom" });

  const [checkedVariant, setCheckedVariant] = useState([]);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [filterData, setFilterData] = useState({});
  const [formBatch, setFormBatch] = useState(initialFormBatch);
  const [initialFilled, setinitialFilled] = useState(false);

  useEffect(() => {
    if ((isEmpty(productDetail) && sku?.length === 0) || fromEmpty) return;

    const fetchedSkus = productDetail?.product_skus;

    const formattedSku = sku.map((skuData) => {
      skuData.variant_name = skuData.variants.map((data) => data.label);

      return skuData;
    });

    if (fetchedSkus && !isEmpty(formattedSku) && !initialFilled) {
      const skuLength = fetchedSkus.length;
      const tempSku = formattedSku.map((skuData) => {
        const fetchedData = fetchedSkus.find((data) =>
          skuData.variant_name.every((variant) =>
            data.variant_name.includes(variant)
          )
        );

        if (skuLength === 1 && fetchedData.is_default === 0) {
          fetchedData.is_default = 1;
        }

        const productWeight = parseInt(fetchedData?.product_weight);

        skuData.price = fetchedData?.product_price;
        skuData.stock = fetchedData?.product_stock;
        skuData.default = fetchedData?.is_default;
        skuData.status = fetchedData?.product_status_id;
        skuData.weight = productWeight;
        skuData.number = fetchedData?.product_sku_mpn;
        skuData.image_id = fetchedData?.product_image_id;
        skuData.image_url = fetchedData?.product_image_url;
        skuData.image = fetchedData?.product_image_path;
        skuData.product_sku_id = fetchedData?.product_sku_id;

        return skuData;
      });

      methods.setValue("sku", tempSku);
      setinitialFilled(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productDetail, sku, initialFilled, fromEmpty]);

  useEffect(() => {
    if (isEmpty(productWithValue)) return;
    setIsCheckAll(false);
    setCheckedVariant([]);
    setFilterData({});
    setFormBatch(initialFormBatch);
  }, [productWithValue]);

  useEffect(() => {
    if (checkedVariant.length === 0) {
      setFormBatch(initialFormBatch);
    }
  }, [checkedVariant]);

  const onFormFilterChange = (key, value) => {
    if (value) {
      setFormBatch((prev) => ({ ...prev, [key]: value }));
    } else {
      const tempFormBatch = { ...formBatch };
      delete tempFormBatch[key];
      setFormBatch(tempFormBatch);
    }
  };

  const { mutate: uploadBatchImage } = useUploadProductImage({
    onSuccess: (data) => {
      setFormBatch((prev) => ({
        ...prev,
        image_id: data.image_id,
        image: data.path,
        image_url: data.url,
      }));
    },
  });

  const { register: registerUploadBatch } = useUpload({
    fileType: "image",
    onRequest: async (file) => {
      const deepTypeChecking = await imageTypeChecking(file);

      if (deepTypeChecking) {
        const result = await blobToBase64(file);
        const { naturalHeight, naturalWidth } = await getImageMeta(result);

        if (naturalHeight < 300 || naturalWidth < 300) {
          toast({
            label: "Format gambar tidak sesuai",
            placement: "top",
          });

          return null;
        }
        const formData = new FormData();
        const compressed =
          file.size > 400000
            ? await imgCompressor(file, {
                maxSizeMB: 0.4,
                maxWidthOrHeight: 1920,
                useWebWorker: true,
              })
            : file;

        formData.append("image", compressed);
        formData.append("image_type_id", 6);
        uploadBatchImage(formData);
      } else {
        toast({
          label: "Format gambar tidak sesuai",
          placement: "top",
        });
      }
    },
    validator: (file) => {
      if (!IMAGE_FILE_TYPE.includes(file.type)) {
        toast({
          label: "Format gambar tidak sesuai",
          placement: "top",
        });

        return {
          message: "Format gambar tidak sesuai",
        };
      }

      return null;
    },
  });

  const onImageBatchDelete = () => {
    const tempFormBatch = { ...formBatch };
    delete tempFormBatch.image_id;
    delete tempFormBatch.image;
    delete tempFormBatch.image_url;

    setFormBatch(tempFormBatch);
  };

  const filteredCount = useMemo(
    () => Object.keys(filterData).length,
    [filterData]
  );

  const onBatchApply = () => {
    const applyBatchResult = formattedBatchSku(
      sku,
      filterData,
      formBatch,
      checkedVariant
    );
    methods.setValue("sku", applyBatchResult);
  };

  const onFormBatchToggleChange = () => {
    const tempFormBatcn = { ...formBatch };
    tempFormBatcn.status = formBatch.status === 1 ? 0 : 1;
    setFormBatch(tempFormBatcn);
  };

  const onCheckAll = () => {
    if (isCheckAll) {
      setFormBatch(initialFormBatch);
      setCheckedVariant([]);
      setFilterData({});
    } else {
      setCheckedVariant(sku.map((data) => data.index));
    }
    setIsCheckAll((prev) => !prev);
  };

  const checkCount = useMemo(() => {
    const formattedData = variantToSkuFormat(productWithValue);

    if (checkedVariant.length > 0) {
      return checkedVariant.length;
    }
    let count = 0;

    formattedData.forEach((prev) => {
      let annotator = 0;

      Object.keys(filterData).forEach((key) => {
        if (prev.id[key] === filterData[key]) {
          annotator++;
        }
      });

      if (annotator === filteredCount && filteredCount > 0) {
        count++;
      }
    });

    return count;
  }, [productWithValue, filterData, checkedVariant, filteredCount]);

  return (
    <Flex flexDirection="column" rowGap="16px">
      <Text color="black75" weight="bold">
        Tabel Varian
      </Text>
      <Flex alignItems="center" columnGap="16px">
        {productVariant &&
          ((!isEmpty(productVariant[0]?.variant_values) &&
            productVariant?.[0]?.variant_values.length > 1) ||
            (!isEmpty(productVariant[1]?.variant_values) &&
              productVariant?.[1]?.variant_values.length > 1)) && (
            <>
              <Flex
                alignItems="center"
                height="48px"
                px="16px"
                justifyContent="center"
                columnGap="8px"
                borderStyle="solid"
                borderWidth="1px"
                borderColor="black10"
                cursor="pointer"
                width="fit-content"
                borderRadius="8px"
              >
                <Checkbox
                  checked={isCheckAll}
                  onClick={onCheckAll}
                  indeterminate={filteredCount > 0 || checkedVariant.length > 0}
                />
                <Text>
                  {checkCount > 0
                    ? `${checkCount} Varian Dipilih`
                    : "Pilih Semua Varian"}
                </Text>
              </Flex>
              {checkedVariant.length === 0 &&
                !isCheckAll &&
                filteredProductVariant.map(
                  (variant) =>
                    !isEmpty(variant?.variant_values) && (
                      <Flex columnGap="16px" alignItems="center">
                        <Flex
                          flexDirection="column"
                          width="184px"
                          flexShrink={0}
                        >
                          <SelectInput
                            styles={{
                              placeholder: (base) => ({
                                ...base,
                                fontSize: "14px",
                                padding: "1px 0",
                                color: colors.black75,
                              }),
                            }}
                            value={variant.variant_values.filter(
                              (data) =>
                                data.variant_value_id ===
                                filterData[data.variant_name]
                            )}
                            options={variant.variant_values}
                            placeholder={variant.variant_name}
                            isClearable
                            onChange={(value) => {
                              if (value) {
                                setFilterData((prev) => ({
                                  ...prev,
                                  [value.variant_name]: value.variant_value_id,
                                }));
                                setCheckedVariant([]);
                              } else {
                                const tempFilter = { ...filterData };
                                delete tempFilter[variant.variant_name];
                                setFilterData(tempFilter);
                              }
                            }}
                          />
                        </Flex>
                      </Flex>
                    )
                )}
              {(checkedVariant.length > 0 ||
                !isEmpty(filterData) ||
                isCheckAll) && (
                <>
                  <Button
                    type="button"
                    variant="tertiary"
                    onClick={() => {
                      setIsCheckAll(false);
                      setFilterData({});
                      setFormBatch({ status: 1 });
                      setCheckedVariant([]);
                    }}
                  >
                    Batal
                  </Button>
                  <Button
                    type="button"
                    variant="secondary"
                    onClick={onBatchApply}
                  >
                    Terapkan
                  </Button>
                </>
              )}
            </>
          )}
      </Flex>

      {(checkedVariant.length > 0 || !isEmpty(filterData) || isCheckAll) && (
        <Flex
          borderRadius="8px"
          backgroundColor="black5"
          padding="24px"
          alignItems="center"
          columnGap="16px"
        >
          <Text weight="bold" color="black75">
            Atur Varian Sekaligus
          </Text>
          <Flex bg="white" borderRadius={8}>
            <Input.Number
              size="small"
              max={999999999}
              prefix={
                <Text weight="bold" color="black50">
                  Rp
                </Text>
              }
              placeholder="Harga"
              value={formBatch?.price}
              onChange={(value) => onFormFilterChange("price", value)}
            />
          </Flex>
          <Flex bg="white" borderRadius={8}>
            <Input.Number
              size="small"
              max={99999}
              placeholder="Stok"
              onChange={(value) => onFormFilterChange("stock", value || 1)}
              min={!isEdit && 1}
              value={formBatch?.stock}
            />
          </Flex>
          <Flex bg="white" borderRadius={8}>
            <Input
              size="small"
              placeholder="SKU"
              onChange={(event) =>
                onFormFilterChange("number", event.target.value)
              }
              value={formBatch?.number || ""}
            />
          </Flex>
          <Flex bg="white" borderRadius={8}>
            <Input.Number
              max={uom === 1 ? 999 : 999999}
              required
              size="small"
              placeholder="Berat"
              onChange={(value) => onFormFilterChange("weight", value)}
              sufix={
                <Text color="black50" weight="bold">
                  {uom === 1 ? "kg" : "g"}
                </Text>
              }
              min={1}
              onFocus={(event) => event.target.select()}
              value={formBatch?.weight}
            />
          </Flex>
          {formBatch?.image_url ? (
            <VariantImage
              width="40px"
              height="40px"
              borderRadius="8px"
              background={`url("${formBatch.image_url}")`}
              backgroundRepeat="no-repeat"
              backgroundPosition="center"
              cursor="pointer"
              position="relative"
              backgroundSize="contain"
            >
              <VariantImageOvelay
                position="absolute"
                top="0"
                left="0"
                width="100%"
                height="100%"
                zIndex={10}
                cursor="default"
                alignItems="center"
                justifyContent="center"
              >
                <Tooltip
                  width={60}
                  label="Hapus Gambar"
                  textAlign="center"
                  icon={
                    <Icon
                      name="Trash-outline"
                      size={20}
                      cursor="pointer"
                      color="white"
                      onClick={onImageBatchDelete}
                    />
                  }
                />
              </VariantImageOvelay>
            </VariantImage>
          ) : (
            <Upload
              {...registerUploadBatch}
              accept="image/jpg, image/jpeg, image/png"
              onOpen
            >
              <Flex
                width="40px"
                height="40px"
                borderStyle="dashed"
                borderWidth="1px"
                borderColor="black25"
                borderRadius="8px"
                alignItems="center"
                justifyContent="center"
                cursor="pointer"
                background="white"
                // onClick={() => setImageIndex(data.index)}
              >
                <Icon name="Plus-solid" color="black60" />
              </Flex>
            </Upload>
          )}
          <Flex onClick={onFormBatchToggleChange}>
            <Toggle checked={formBatch.status === 1} />
          </Flex>
        </Flex>
      )}
      <TableVariant
        sku={sku}
        productWithValue={productWithValue}
        methods={methods}
        checkedVariant={checkedVariant}
        setCheckedVariant={setCheckedVariant}
        isCheckAll={isCheckAll}
        setIsCheckAll={setIsCheckAll}
        filterData={filterData}
        setFilterData={setFilterData}
        filteredCount={filteredCount}
        isEdit={isEdit}
      />
    </Flex>
  );
};

export default VariantForm;
