import { Divider, Drawer, Flex, Text, useDisclosure } from "renos-ui";
import Deactivate from "./component/Deactivate";
import Activate from "./component/Activate";

const PopupSetAtOnce = ({
  disclosure: { isOpen, onClose },
  data,
  toFirstPage,
  status,
}) => {
  const handleCloseAll = () => {
    toFirstPage();
    onClose();
    onCloseDeactive();
    onCloseActivate();
  };

  const {
    isOpen: isOpenDeactive,
    onClose: onCloseDeactive,
    onOpen: onOpenDeactive,
  } = useDisclosure({ isOpen: false });

  const {
    isOpen: isOpenActivate,
    onClose: onCloseActivate,
    onOpen: onOpenActive,
  } = useDisclosure({ isOpen: false });

  return (
    <>
      <Drawer isVisible={isOpen} onClose={onClose} closable placement="bottom">
        <Flex
          flexDirection="column"
          width="100%"
          gap="16px"
          height="100%"
          paddingTop="16px"
        >
          {status !== 1 && (
            <Flex cursor="pointer" onClick={onOpenActive}>
              <Text color="black75">Aktifkan Produk </Text>
            </Flex>
          )}

          {status !== 2 && status !== 1 && <Divider />}

          {status !== 2 && (
            <Flex cursor="pointer" onClick={onOpenDeactive}>
              <Text color="black75">Nonaktifkan Produk </Text>
            </Flex>
          )}
        </Flex>
      </Drawer>

      {isOpenActivate && (
        <Activate
          {...{
            isOpenActivate,
            onCloseActivate,
            data,
            handleCloseAll,
          }}
        />
      )}

      {isOpenDeactive && (
        <Deactivate
          {...{
            handleCloseAll,
            isOpenDeactive,
            data,
            onCloseDeactive,
          }}
        />
      )}
    </>
  );
};

export default PopupSetAtOnce;
