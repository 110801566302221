import { Flex, Box, Text } from "renos-ui";

const EmptyData = () => {
  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      gap="8px"
      padding="16px"
      width="280px"
      height="176px"
    >
      <Flex
        as="img"
        alt="empty-review"
        width="104px"
        height="104px"
        src={`/assets/images/mobile/stok-kosong.png`}
      />

      <Flex
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        gap="8px"
        marginBottom="16px"
      >
        <Text weight="bold" color="black100">
          Data Tidak Ditemukan
        </Text>
        <Box fontSize="12px" textAlign="center" color="black75">
          Pastikan data yang kamu masukkan sudah benar atau coba cari dengan
          keyword lain.
        </Box>
      </Flex>
    </Flex>
  );
};

export default EmptyData;