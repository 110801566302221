import MobileLayout from "layouts/MobileLayout";
import React from "react";
import { Flex, Icon, useToast, useDisclosure } from "renos-ui";
import Empty from "./component/empty";
import { useState, useContext } from "react";
import StoreContext from "providers/StoreProvider";
import { useHistory } from "react-router-dom";
import mobileUrl from "constants/mobileUrl";
import {
  useDeleteLocation,
  useGetLocation,
  useUpdateLocation,
} from "services/location";
import CardLocation from "./component/CardLocation";
import SkeletonLocation from "./component/SkeletonLocation";
import HandleDrawer from "./component/HandleDrawer";
import DeleteDrawer from "./component/DeleteDrawer";

const AccountLocation = () => {
  const showDisclosure = useDisclosure({ isOpen: false });
  const deleteDisclosure = useDisclosure({ isOpen: false });
  const [selectedItem, setSelectedItem] = useState({});
  const toast = useToast();
  const history = useHistory();

  const toggleDrawerMenu = (item) => {
    setSelectedItem(item);
    showDisclosure.onOpen();
  };

  const {
    storeData: {
      data: { id: storeId },
    },
  } = useContext(StoreContext);

  const {
    data: listLocation,
    isLoading: isLoadingLocation,
    refetch: refetchGetLocation,
  } = useGetLocation({
    store_id: storeId,
    order_by: "is_primary",
    ordering: "desc",
    fetch_all: true,
  });

  const handleAdd = () => {
    history.push(mobileUrl.STORE_LOCATION_FORM, { data: "", mode: "add" });
  };

  const handleSetPrimary = (value) => {
    const datas = value;
    datas.is_primary = true;
    updatePrimary({ data: datas });
  };

  const handleDeleteConfirm = (index) => {
    deleteDisclosure.onOpen();
  };

  const handleDelete = () => {
    deleteLocation(selectedItem?.id);
  };

  const handleEdit = (index) => {
    showDisclosure.onClose();
    const tempSelected = selectedItem;
    history.push(mobileUrl.STORE_LOCATION_FORM, {
      data: tempSelected,
      mode: "edit",
    });
  };

  const { mutate: updatePrimary } = useUpdateLocation({
    onError: (error) => {
      const {
        response: {
          data: { frontend_message, message },
        },
      } = error;
      toast({
        label: frontend_message || message || "Alamat gagal diubah.",
        placement: "bottom",
        backgroundColor: "red50",
      });
    },
    onSuccess: (data) => {
      const { frontend_message, message } = data;
      toast({
        label: message || frontend_message || "Alamat berhasil diubah.",
        placement: "bottom",
      });
      showDisclosure.onClose();
      refetchGetLocation();
    },
  });

  const { mutate: deleteLocation, isLoading: isLoadingDelete } =
    useDeleteLocation({
      onError: (error) => {
        const {
          response: {
            data: { frontend_message, message },
          },
        } = error;
        toast({
          label: frontend_message || message || "Alamat gagal dihapus.",
          placement: "bottom",
          backgroundColor: "red50",
        });
      },
      onSuccess: (data) => {
        const { frontend_message, message } = data;
        toast({
          label: frontend_message || message || "Alamat berhasil dihapus.",
          placement: "bottom",
        });
        showDisclosure.onClose();
        deleteDisclosure.onClose();
        refetchGetLocation();
      },
    });

  return (
    <MobileLayout
      header={{
        title: "Lokasi Toko",
        isHomepage: true,
        withBack: true,
        withoutGoBack: true,
        onBack: () => history.push(mobileUrl.ACCOUNT),
        suffix: (
          <Flex alignItems="center" cursor="pointer" onClick={handleAdd}>
            <Icon name="Plus-outline" color="black50" cursor="pointer" />
          </Flex>
        ),
      }}
    >
      {isLoadingLocation ? (
        <SkeletonLocation itemShow={6} />
      ) : listLocation?.data.length === 0 ? (
        <Empty />
      ) : (
        <Flex
          flexDirection="column"
          width="100%"
          backgroundColor="black5"
          p="16px"
          gap="8px"
          height="100%"
          overflow="auto"
        >
          {listLocation?.data?.map((row, indexRow) => (
            <CardLocation
              key={`location-${indexRow}`}
              {...row}
              item={row}
              length={listLocation?.data?.length}
              handleSetPrimary={() => handleSetPrimary(row)}
              handleDelete={() => handleDeleteConfirm(indexRow)}
              handleEdit={() => handleEdit(indexRow)}
              toggleDrawer={toggleDrawerMenu}
            />
          ))}
        </Flex>
      )}

      {/* handle action */}
      <HandleDrawer
        total={listLocation?.data.length || 1}
        isPrimary={selectedItem.is_primary}
        disclosure={showDisclosure}
        handleEdit={handleEdit}
        handleSetPrimary={() => handleSetPrimary(selectedItem)}
        handleDelete={deleteDisclosure}
      />

      {/* hadledelete */}
      <DeleteDrawer
        disclosure={deleteDisclosure}
        handleDelete={handleDelete}
        isLoadingDelete={isLoadingDelete}
      />
    </MobileLayout>
  );
};

export default AccountLocation;
