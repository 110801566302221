import React from "react";
import { useHistory } from "react-router-dom";
import { Button, Flex, Text } from "renos-ui";
import desktopUrl from "constants/desktopUrl";

const TopBar = () => {
  const history = useHistory();

  return (
    <Flex
      height="80px"
      backgroundColor="white"
      borderTopLeftRadius="16px"
      borderTopRightRadius="16px"
      boxShadow="0px 4px 4px rgba(20, 20, 32, 0.05)"
      justifyContent="space-between"
      alignItems="center"
      paddingX="24px"
    >
      <Text variant="heading3" weight="bold">
        Ulasan Pembeli
      </Text>
      <Flex columnGap="16px">
        <Button
          size="large"
          onClick={() =>
            history.push(`${desktopUrl.SETTING}?tab-active=replyTemplate`)
          }
        >
          Atur Template Balasan
        </Button>
      </Flex>
    </Flex>
  );
};

export default TopBar;
