import { Flex, Text } from "renos-ui";

const ProductEmpty = () => {
  return (
    <Flex
      marginTop="40px"
      flexDirection="column"
      alignItems="center"
      rowGap="16px"
    >
      <img
        src="/assets/images/mobile/no-product.svg"
        alt="no-product-img"
        width="180px"
        height="180px"
      />
      <Flex
        flexDirection="column"
        rowGap="4px"
        textAlign="center"
        alignItems="center"
      >
        <Text weight="bold">Belum ada produk</Text>
        <Text color="black75" variant="caption" width="280px">
          Yuk tambahkan produk ke tokomu untuk mengirimkan produk ke pembeli
        </Text>
      </Flex>
    </Flex>
  );
};

export default ProductEmpty;
