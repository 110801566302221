import { Flex, Grid, range } from "renos-ui";
import { useGetProductsV3 } from "services/product";
import ProductCard from "../Home/component/ProductCard";
import ProductCardSkeleton from "../Home/component/ProductCardSkeleton";
import EmptyList from "./Empty";

const Product = () => {
  const { data: dataProduct, isLoading } = useGetProductsV3({
    order_by: "upload_date",
    page: 1,
    product_status_ids: 1,
  });

  return (
    <Flex
      flexDirection="column"
      gap="8px"
      padding="8px 24px"
      overflowY="auto"
      height="509px"
    >
      {dataProduct?.data?.length === 0 && !isLoading ? (
        <EmptyList />
      ) : (
        <Grid
          gap="8px"
          gridTemplateColumns="115px 115px 115px 115px 115px 115px"
          overflow="auto"
        >
          {isLoading
            ? range(6).map((_, indexEmpty) => (
                <ProductCardSkeleton
                  key={`product-card-skeleton${indexEmpty}`}
                />
              ))
            : dataProduct?.data?.map((product, indexProduct) => {
                return (
                  <ProductCard
                    data={product}
                    key={`product-card${indexProduct}`}
                  />
                );
              })}
        </Grid>
      )}
    </Flex>
  );
};

export default Product;
