import { Draggable } from "react-beautiful-dnd";
import { Divider, Drawer, Flex, Icon, Text, useDisclosure } from "renos-ui";

const TemplateItem = ({
  item,
  index,
  itemCounts,
  setSelectedItem,
  toggleDrawer,
  onDelete,
  isSingleItem,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure({ isOpen: false });

  const handleClickOption = () => {
    setSelectedItem({
      id: item.id,
      title: item.title,
      message: item.template_message,
    });
    onOpen();
  };

  const handleDelete = () => {
    onDelete();
  };

  return (
    <>
      <Draggable index={index} draggableId={String(item.id)}>
        {(provided) => (
          <>
            <Flex
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              paddingY="16px"
              key={`list-template-chat-${item.id}`}
            >
              <Flex gap="20px" width="100%">
                <Icon size={20} name="Move-outline" />

                <Flex
                  alignItems="center"
                  justifyContent="space-between"
                  width="100%"
                >
                  <Flex flexDirection="column" gap="8px">
                    <Text variant="caption" weight="bold">
                      {item.title}
                    </Text>
                    <Text
                      color="black75"
                      variant="caption"
                      style={{ whiteSpace: "pre-line" }}
                    >
                      {item.template_message}
                    </Text>
                  </Flex>
                  <Flex onClick={handleClickOption}>
                    <Icon name="Vertical-outline" size="20px" />
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
            {itemCounts !== index && <Divider />}
          </>
        )}
      </Draggable>
      <Drawer
        onClose={onClose}
        isVisible={isOpen}
        closable={true}
        placement="bottom"
      >
        <Flex pb="16px" gap="16px" flexDirection="column">
          <Text onClick={() => toggleDrawer()}>Ubah Template</Text>
          <Divider />
          {!isSingleItem && <Text onClick={handleDelete}>Hapus</Text>}
        </Flex>
      </Drawer>
    </>
  );
};

export default TemplateItem;
