import React from "react";
import { Flex, Spinner } from "renos-ui";

const LoadingSpinner = () => {
  return (
    <Flex
      flexDirection="column"
      width="100%"
      height="calc(100vh - 142px)"
      overflow="auto"
      justifyContent="center"
      alignItems="center"
    >
      <Spinner size="16px" color="black100" />
    </Flex>
  );
};

export default LoadingSpinner;
