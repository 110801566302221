import {
  Flex,
  Icon,
  Text,
  Tooltip,
  colors,
  Skeleton,
  Button,
  Box,
} from "renos-ui";

const Card = ({
  dataTransactionSummary,
  dataTransaction,
  isActive,
  setActiveCard,
  isLoading,
  isError,
  refetch,
}) => {
  return (
    <Flex
      gap="16px"
      height="78px"
      borderRadius="8px"
      cursor="pointer"
      flexGrow={1}
      flexBasis={0}
      backgroundColor="white"
      padding="16px"
      boxShadow="0 4px 4px rgba(20, 20, 32, 0.05)"
      alignContent="center"
      borderBottom={isActive && `6px solid ${colors.blue50}`}
      onClick={() => setActiveCard(dataTransaction.key)}
    >
      <Flex flexDirection="column" position="absolute">
        <Text variant="subtitle" weight="bold" marginBottom="4px">
          {isLoading ? (
            <Skeleton height="32.5px" width="120px" />
          ) : isError ? (
            <Box>
              <Button
                size="small"
                onClick={refetch}
                preffix={<Icon name="Reload-outline" size={10} />}
              >
                <Text variant="caption" weight="bold">
                  Muat Ulang
                </Text>
              </Button>
            </Box>
          ) : dataTransaction.summaryProperty === "product_views" ? (
            dataTransactionSummary[dataTransaction.summaryProperty] + " x"
          ) : (
            dataTransactionSummary[dataTransaction.summaryProperty]
          )}
        </Text>
        <Flex gap="8px" alignItems="center">
          <Text variant="caption" lineClamp={1}>
            {" "}
            {dataTransaction.name}
          </Text>
          <Tooltip
            width={160}
            label={dataTransaction.tooltip}
            textAlign="center"
            placement="bottom-right"
            icon={<Icon name="Info-solid" size={16} color="black50" />}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Card;
