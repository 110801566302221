import { Button, Flex, Text } from "renos-ui";

const Fail = ({ refetch }) => {
  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      textAlign="center"
      justifyContent="space-between"
    >
      <Flex
        as="img"
        width="180px"
        height="180px"
        src="/assets/images/store-layout/error-layout.svg"
      />
      <Flex gap="24px" flexDirection="column">
        <Flex gap="8px" flexDirection="column">
          <Text variant="subtitle" weight="bold">
            Gagal memuat halaman
          </Text>
          <Text variant="caption" color="black50">
            Jangan khawatir, silahkan refresh halaman ini.
          </Text>
        </Flex>
        <Button isBlock onClick={refetch}>
          Refresh Halaman
        </Button>
      </Flex>
    </Flex>
  );
};

export default Fail;
