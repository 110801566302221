import StoreLayoutContext from "providers/StoreLayoutProvider";
import { useContext } from "react";
import { Flex } from "renos-ui";
import BestSelling from "./component/BestSelling";
import NewProduct from "./component/NewProduct";
import Voucher from "./component/Voucher";
import StoreFront from "./component/StoreFront";
import BannerVideo from "./component/BannerVideo";
import Banner from "./component/Banner";
import ProductDiscount from "./component/ProductDiscount";

const Home = ({ isPreviewMode }) => {
  const { dataHomePage } = useContext(StoreLayoutContext);

  const renderPage = (id, key) => {
    switch (id) {
      case 1:
      case 8:
        return <Banner isPreviewMode={isPreviewMode} key={key} id={id} />;
      case 2:
        return <Voucher isPreviewMode={isPreviewMode} key={key} />;
      case 3:
        return <BestSelling isPreviewMode={isPreviewMode} key={key} />;
      case 4:
        return <NewProduct isPreviewMode={isPreviewMode} key={key} />;
      case 5:
        return <StoreFront isPreviewMode={isPreviewMode} key={key} />;
      case 6:
        return <ProductDiscount isPreviewMode={isPreviewMode} key={key} />;
      case 7:
        return <BannerVideo isPreviewMode={isPreviewMode} key={key} />;
      default:
        break;
    }
  };

  return (
    <Flex
      flexDirection="column"
      gap="32px"
      px="16px"
      height="500px"
      overflow="auto"
    >
      {dataHomePage?.map((homepage, indexHomepage) =>
        renderPage(homepage.id, indexHomepage)
      )}
    </Flex>
  );
};

export default Home;
