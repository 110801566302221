import styled from "styled-components";
import { Input } from "renos-ui";

export const StyledInput = styled(Input)`
  position: absolute;
  left: 0;
  z-index: 1;
  width: 240px;
  padding: 10px 12px;
  margin: 12px 0px 0px 12px;
`;
