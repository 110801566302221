import { Drawer, Flex, Text } from "renos-ui";

const DrawerChatTemplate = ({ disclosure, handleTemplateClick, quickChat }) => {
  const { isOpen, onClose } = disclosure;

  return (
    <Drawer
      isVisible={isOpen}
      closable={true}
      placement="bottom"
      onClose={onClose}
    >
      <Flex flexDirection="column" gap="16px" py="16px">
        <Text variat="body" weight="bold">
          Pilih Template
        </Text>
        {quickChat?.data?.templates?.map((template) => (
          <Flex cursor="pointer" key={template.id}>
            <Text
              variant="caption"
              color="black75"
              style={{ whiteSpace: "pre-line" }}
              onClick={() => handleTemplateClick(template)}
            >
              {template.template_message?.split("\n").join(" ")}
            </Text>
          </Flex>
        ))}
      </Flex>
    </Drawer>
  );
};

export default DrawerChatTemplate;
