import { Box, Input, Text } from "renos-ui";
import styled from "styled-components";

export const StyledTextArea = styled(Input.TextArea)`
  width: 640px;
  resize: none;
`;

export const StyledText = styled(Text)`
  text-align: center;
  word-wrap: break-word;
  width: 640px;
`;

export const StyledProductImage = styled(Box)`
  object-fit: cover;
  border-radius: 8px;
`;
