import { Flex, Skeleton, range } from "renos-ui";

const LoadingCheckBox = () => {
  return range(10).map((_, i) => (
    <Flex
      p="16px"
      borderBottomColor="black5"
      borderBottomWidth="8px"
      borderBottomStyle="solid"
      gap="16px"
      alignItems="center"
    >
      <Flex width="100%" gap="16px" key={i}>
        <Skeleton width="24px" height="24px" />
        <Skeleton width="90%" height="24px" />
      </Flex>
    </Flex>
  ));
};

export default LoadingCheckBox;
