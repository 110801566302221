import React from "react";
import { Button, Flex, Text } from "renos-ui";
import { useHistory } from "react-router-dom";
import desktopUrl from "constants/desktopUrl";

const EmptyState = () => {
  const { push } = useHistory();

  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      gap="8px"
      padding="16px"
      height="100%"
    >
      <Flex
        as="img"
        alt="empty-review"
        src={`/assets/images/voucher/no-product.svg`}
      />

      <Flex
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        gap="8px"
        marginBottom="16px"
        width="255px"
        textAlign="center"
      >
        <Text weight="bold" variant="heading3" color="black100">
          Belum ada produk
        </Text>
        <Text color="black75">
          Silahkan tambahkan produk diskon terlebih dahulu.
        </Text>
        <Button size="medium" onClick={() => push(desktopUrl.DISCOUNT)}>
          Tambah Produk
        </Button>
      </Flex>
    </Flex>
  );
};

export default EmptyState;
