import { goToBuyerPage, goToCustomerService } from "helpers";
import { Button, Dialog, Flex, Text } from "renos-ui";

const DialogStoreClosed = ({ isOpen }) => {
  return (
    <Dialog isVisible={isOpen} verticalCentered>
      <Flex gap="4px" alignItems="center" flexDirection="column">
        <Text variant="heading3" weight="bold">
          Maaf, Toko Ditutup Permanen.
        </Text>
        <Flex
          as="img"
          alt="forbidden"
          src="/assets/images/forbidden.svg"
          width="200px"
          height="200px"
        />
        <Flex textAlign="center" width="356px">
          <Text>{`Tokomu telah ditutup oleh pihak Renos karena pelanggaran peraturan yang berlaku.`}</Text>
        </Flex>
        <Flex width="450px" mt="24px" gap="24px" justifyContent="space-between">
          <Button
            isBlock
            size="medium"
            variant="secondary"
            onClick={goToCustomerService}
          >
            Hubungi Support Renos
          </Button>
          <Button
            isBlock
            size="medium"
            variant="primary"
            onClick={goToBuyerPage}
          >
            Kembali ke Homepage
          </Button>
        </Flex>
      </Flex>
    </Dialog>
  );
};

export default DialogStoreClosed;
