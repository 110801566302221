import { Divider, Flex, Skeleton, range } from "renos-ui";

const SkeletonBalasanOtomatis = ({ itemShow = 3 }) => {
  return range(itemShow).map((item) => (
    <Flex
      p="16px"
      gap="16px"
      borderRadius="8px"
      flexDirection="column"
      backgroundColor="white"
      key={`skeleton-auto-reply-${item}`}
    >
      <Flex justifyContent="space-between" width="100%">
        <Flex gap="8px" width="100%">
          <Skeleton borderRadius="50%" width="24px" />
          <Skeleton width="70%" />
        </Flex>
        <Skeleton width="60px" borderRadius="100px" />
      </Flex>
      <Divider />
      <Skeleton width="40%" />
      <Skeleton width="60%" />
    </Flex>
  ));
};

export default SkeletonBalasanOtomatis;
