import { useMemo, useState } from "react";
import { Flex, range } from "renos-ui";
import Filter from "./component/Filter";
import CardSkeleton from "./component/CardSkeleton";
import EmptyData from "./component/EmptyData";
import Card from "./component/Card";
import FailLoadData from "widgets/global/FailLoadData";
import { useGetShowcase } from "services/promo-management";

const Etalase = ({ value, setValue }) => {
  const [params, setParams] = useState({ keyword: "" });

  const { isLoading, isRefetching, isError, refetch, data } =
    useGetShowcase(params);

  const isAllLoading = useMemo(
    () => isLoading || isRefetching,
    [isLoading, isRefetching]
  );

  return (
    <Flex flexDirection="column" mt="16px">
      <Filter setValue={setParams} />
      <Flex
        maxHeight="287px"
        overflowY="auto"
        overflowX="clip"
        flexDirection="column"
        width="100%"
      >
        {isAllLoading ? (
          range(5).map((_, indexSkeleton) => (
            <CardSkeleton key={`card-skeleton-${indexSkeleton}`} />
          ))
        ) : isError ? (
          <Flex p="24px">
            <FailLoadData noImg onClick={refetch} />
          </Flex>
        ) : data.data.length === 0 ? (
          <EmptyData />
        ) : (
          data.data.map((list, indexList) => (
            <Card
              key={`list-${indexList}`}
              data={list}
              onClick={() => setValue("etalase", list)}
              isActive={list.tag_store_id === value?.etalase_id}
            />
          ))
        )}
      </Flex>
    </Flex>
  );
};

export default Etalase;
