import useQueryParams from "hooks/useQueryParams";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Flex, Tabs, Text } from "renos-ui";
import Note from "./note";
import Delivery from "./delivery";
import DeliveryInformation from "./delivery/deliveryInformation";
import Location from "./location";
import Information from "./information";
import Status from "./status";
import Template from "./reply-template";
import Document from "./document";
import { setGTM } from "helpers";

const Setting = () => {
  const query = useQueryParams();
  const [activeTab, setActiveTab] = useState(
    query.get("tab-active") || "information"
  );
  const { push } = useHistory();

  const handleChangeTabActive = (value) => {
    setGTM("seller_center_section", {
      event_category: "store_settings",
      event_action: "click",
      event_label: items.filter((filterItem) => filterItem.key === value)[0]
        .label,
    });

    setActiveTab(value);
    push(`/setting?tab-active=${value}`);
  };

  const items = [
    {
      label: "Informasi",
      key: "information",
      children: () => <Information />,
    },
    {
      label: "Status",
      key: "status",
      children: Status,
    },
    {
      label: "Catatan",
      key: "note",
      children: () => <Note />,
    },
    {
      label: "Lokasi",
      key: "location",
      children: () => (
        <Location handleChangeTabActive={handleChangeTabActive} />
      ),
    },
    {
      label: "Pengiriman",
      key: "delivery",
      children: () => (
        <Delivery handleChangeTabActive={handleChangeTabActive} />
      ),
    },
    {
      label: "Template Balasan",
      key: "replyTemplate",
      children: () => <Template />,
    },
    {
      label: "Dokumen",
      key: "document",
      children: () => (
        <Document handleChangeTabActive={handleChangeTabActive} />
      ),
    },
  ];

  return (
    <Flex gap="40px" flexDirection="column" marginBottom="24px">
      <Flex flexDirection="column" rowGap="24px">
        <Text variant="heading3" weight="bold">
          Pengaturan Toko
        </Text>
        <Flex
          flexDirection="column"
          px="24px"
          py="16px"
          borderRadius="16px"
          backgroundColor="white"
        >
          <Tabs
            onChange={handleChangeTabActive}
            defaultActiveKey={activeTab}
            placement="bottom"
            items={items}
          />
        </Flex>
      </Flex>
      {activeTab === "delivery" && <DeliveryInformation />}
    </Flex>
  );
};

export default Setting;
