import dayjs from "dayjs";

export const dataPostFormatter = (data) => {
  const params = {
    voucher_specific_type_id: data.voucherTypeId,
    voucher_target_id: data.voucherTargetId,
    promo_name: data.name,
    promo_description: data.description,
    promo_date_start: dayjs(data.startDate).format("YYYY-MM-DD HH:mm:ss"),
    promo_date_end: dayjs(data.endDate).format("YYYY-MM-DD HH:mm:ss"),
    voucher_type_id: data.voucherDiscountTypeId,
    discount_type_id: data.discountTypeId,
    promo_percentage: data.percentageDiscount,
    promo_maximum_amount: data.maxDiscount,
    promo_user_limit: data.limitation,
    minimum_transaction: data.minPurchase,
    promo_amount: data.nominalDiscount,
    promo_limit: data.quota,
    product_sku_numbers:
      data?.product &&
      data.product.map((product) => product.product_sku_number),
    promo_code: data.code,
  };

  if (data.voucherTypeId === 1) {
    delete params.product_sku_numbers;
  }

  if (data.discountTypeId === 1) {
    delete params.promo_amount;
  } else {
    delete params.promo_percentage;
    delete params.promo_maximum_amount;
  }

  if (data.promoId) {
    params.promo_id = data.promoId;
  }

  return params;
};

export const dataEditFormatter = (data) => {
  const params = {
    description: data.promo_description || "",
    name: data.promo_name,
    startDate: new Date(data.promo_date_start),
    endDate: new Date(data.promo_date_end),
    voucherTypeId: data.voucher_specific_type_id,
    voucherTargetId: data.voucher_target_id,
    voucherDiscountTypeId: data.voucher_type_id,
    discountTypeId: data.discount_type_id,
    nominalDiscount: data.promo_amount,
    quota: data.promo_limit,
    limitation: data.promo_user_limit,
    minPurchase: data.minimum_transaction,
    // approved: false,
    product: data.products_data || [],
    maxDiscount: data.promo_maximum_amount,
    percentageDiscount: data.promo_percentage,
    code: data.promo_code,
    status: data.status,
  };

  if (params.voucherTypeId === 1) {
    delete params.product;
  } else {
    params.product = params.product.map((product) => ({
      product_image_url: product.product_image_url,
      product_sku_number: product.product_sku_number,
      product_sku_id: product.product_sku_id,
      product_stock: product.product_stock,
      formatted_product_price: product.formatted_product_price,
      store_sku_number:
        product.store_sku_number ||
        product.product_sku_seller ||
        product.product_sku_mpn,
      product_name: product.product_name,
    }));
  }

  if (params.discountTypeId === 1) {
    delete params.nominalDiscount;
  } else {
    delete params.percentageDiscount;
    delete params.maxDiscount;
  }

  return params;
};

export const handleError = (values, setErrors, setErrorMsg, isOngoing) => {
  let countError = 0;
  const objError = {};

  Object.entries(values).forEach(([key, value]) => {
    let minutes = 60;
    let isStartToday = false;
    let errorText = "";

    if (values.startDate || values.endDate) {
      const today = new Date();
      const tempStart = new Date(values.startDate);

      minutes = Math.round((tempStart - today) / 1000 / 60);
      isStartToday =
        tempStart.setHours(0, 0, 0, 0) === today.setHours(0, 0, 0, 0);
    }

    switch (key) {
      case "startDate" || "endDate":
        if (isOngoing) {
          objError[key] = false;
          errorText = "";
        } else if (!value) {
          objError[key] = true;
          errorText = "Periode wajib diisi!";
          countError++;
        } else if (isStartToday && minutes < 30) {
          objError[key] = true;
          errorText =
            "Periode Mulai untuk hari ini harus minimal 30 menit dari jam sekarang";
          countError++;
        } else if (values.endDate - values.startDate < 3600000) {
          objError[key] = true;
          errorText =
            "Periode Berakhir Voucher minimal adalah 1 jam setelah Periode Mulai";
          countError++;
        }

        setErrorMsg((prev) => ({
          ...prev,
          [key]: errorText,
        }));

        break;
      case "minPurchase":
        // if (!value) {
        //   objError[key] = true;
        //   errorText = "Minimal pembelian wajib diisi!";
        //   countError++;
        // } else
        // if (
        //   values.discountTypeId === 1 &&
        //   (values[key] < 10000 || values[key] > 100000000)
        // ) {
        //   objError[key] = true;
        //   errorText = "Minimal pembelian Rp10.000 dan maksimal Rp99.999.999";
        //   countError++;
        // }
        // else if (
        //   values.discountTypeId === 2 &&
        //   values[key] < values.nominalDiscount
        // ) {
        //   objError[key] = true;
        //   errorText = "Harus lebih tinggi dari nominal potongan";
        //   countError++;
        // }

        setErrorMsg((prev) => ({
          ...prev,
          [key]: errorText,
        }));

        break;
      case "limitation":
        if (!value || values[key] === 0) {
          objError[key] = true;
          countError++;
        }

        break;
      case "maxDiscount":
        if (!value || values[key] < 5000 || values[key] > 99999999) {
          objError[key] = true;
          countError++;
        }

        break;
      case "percentageDiscount":
        if (!value || values[key] < 2 || values[key] > 99) {
          objError[key] = true;
          countError++;
        }

        break;
      case "product":
        if (values[key].length === 0) {
          objError[key] = true;
          countError++;
        }

        break;

      case "description":
        objError[key] = false;

        break;
      // case "approved":
      //   if (!isView && !value) {
      //     objError[key] = true;
      //     countError++;
      //   }

      // break;

      default:
        if (!value) {
          objError[key] = true;
          countError++;
        }

        break;
    }
  });
  setErrors(objError);

  return countError > 0;
};
