import { useMemo } from "react";
import {
  Flex,
  Box,
  Text,
  Toggle,
  Input,
  Icon,
  Divider,
  useDisclosure,
} from "renos-ui";
import CalculateMethod from "./CalculateMethod";
import ModalCategory from "./modalCategory";
import FreeOngkir from "assets/images/free-ongkir.png";

const ShippingRates = ({
  dataRates,
  setDataRates,
  isLoading,
  validation,
  setValidation,
  conditionType,
  conditionBaseType,
}) => {
  const disclosureCategory = useDisclosure({ isOpen: false });

  const isDataFilled = useMemo(() => {
    let totalActive = 0;
    // eslint-disable-next-line
    const tempCategories = dataRates.categories.map((dataCategories) => ({
      ...dataCategories,
      categories: dataCategories.categories.map((dataCategoriesLvl2) => ({
        ...dataCategoriesLvl2,
        categories: dataCategoriesLvl2.categories.map((dataCategoriesLvl3) => {
          if (dataCategoriesLvl3.active) totalActive++;

          return dataCategoriesLvl3;
        }),
      })),
    }));
    if (totalActive > 0) return true;

    return false;
  }, [dataRates]);

  return (
    <Flex
      gap="16px"
      padding="24px"
      backgroundColor="white"
      boxShadow="0px 4px 4px rgba(20, 20, 32, 0.05)"
      borderTopLeftRadius="16px"
      borderTopRightRadius="16px"
    >
      <ModalCategory
        isOpen={disclosureCategory.isOpen}
        onClose={disclosureCategory.onClose}
        dataCategories={dataRates.categories}
        setDataCategories={(dataCategory) =>
          setDataRates({ ...dataRates, categories: [...dataCategory] })
        }
        setDataRates={setDataRates}
        dataRates={dataRates}
      />
      <Flex flexDirection="column" gap="24px" flex={1}>
        <Flex gap="16px" alignContent="center" justifyContent="space-between">
          <Flex gap="16px" flexDirection="column">
            <Flex gap="16px">
              <Box
                backgroundColor="blue50"
                width="4px"
                orientation="vertical"
                borderRadius="8px"
                height="24px"
              />
              <Text variant="subtitle" color="black75" weight="bold">
                Tarif Pengiriman
              </Text>
            </Flex>

            <Flex flexDirection="column" gap="16px">
              <Text color="black75" weight="bold">
                Tentukan kategori produk
              </Text>
              <Flex gap="8px">
                <Box
                  onClick={() => {
                    setDataRates({
                      ...dataRates,
                      active: !dataRates.active,
                      categories: dataRates.active ? [] : dataRates.categories,
                    });
                  }}
                >
                  <Toggle checked={dataRates.active} />
                </Box>
                <Flex flexDirection="column" gap="16px">
                  <Flex flexDirection="column" gap="4px">
                    <Text color="black75">
                      {dataRates.active
                        ? "Beberapa Kategori"
                        : "Semua Kategori"}
                    </Text>
                    <Text color="black50">
                      {dataRates.active
                        ? "Terapkan tarif untuk kategori berikut:"
                        : "Terapkan tarif untuk semua kategori."}
                    </Text>
                  </Flex>
                  {dataRates.active && (
                    <Box
                      width="414px"
                      onClick={disclosureCategory.onToggle}
                      cursor="pointer"
                    >
                      <Input
                        value={
                          isDataFilled
                            ? "Beberapa kategori telah dipilih"
                            : "Pilih Kategori"
                        }
                        readOnly
                        sufix={
                          <Icon
                            name={isDataFilled ? "Edit-solid" : "Right-outline"}
                            size={20}
                            color={isDataFilled ? "blue50" : "black50"}
                          />
                        }
                      />
                    </Box>
                  )}
                </Flex>
              </Flex>
            </Flex>
          </Flex>

          <Flex
            padding="24px"
            gap="8px"
            backgroundColor="yellow5"
            borderRadius="8px"
            flexDirection="column"
            height="84px"
          >
            <Flex gap="8px" alignItems="center">
              <Box as="img" src={FreeOngkir} alt="free-ogkir" />
              <Text variant="subtitle" color="yellow75" weight="bold">
                Badge Gratis Ongkir
              </Text>
            </Flex>
            <Text color="yellow100">
              Jika kamu memberikan tarif ongkir Rp 0, maka <br />
              produkmu akan mendapatkan Badge Gratis Ongkir.
            </Text>
          </Flex>
        </Flex>
        <Flex>
          <Divider />
        </Flex>
        <CalculateMethod
          isLoading={isLoading}
          dataRates={dataRates}
          dataCalculate={dataRates.calculation}
          setDataCalculate={(dataCalculate) =>
            setDataRates({ ...dataRates, calculation: dataCalculate })
          }
          setDataRates={setDataRates}
          validation={validation}
          setValidation={setValidation}
          conditionType={conditionType}
          conditionBaseType={conditionBaseType}
        />
      </Flex>
    </Flex>
  );
};

export default ShippingRates;
