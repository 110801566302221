import ENDPOINTS from "constants/endpoints";
import { useQuery, useMutation } from "react-query";
import client from "../../client";

const getNotesFetcher = async (payload) => {
  const { data } = await client.get(ENDPOINTS.store_note, { params: payload });

  return data;
};

export const useGetNotes = (payload, extraParams) => {
  return useQuery(["getNotes"], () => getNotesFetcher(payload), {
    ...extraParams,
  });
};

const deleteNoteFetcher = async ({ params }) => {
  const { data } = await client.delete(
    `${ENDPOINTS.delete_note}/${params.note_id}`
  );

  return data;
};

export const useDeleteNotes = (extraParams) =>
  useMutation((body) => deleteNoteFetcher(body), {
    ...extraParams,
  });

const saveNotesFetcher = async ({ data: body }) => {
  const response = await client.post(ENDPOINTS.save_note, body);

  return response;
};

export const useSaveNotes = (extraParams) =>
  useMutation((body) => saveNotesFetcher(body), {
    ...extraParams,
  });
