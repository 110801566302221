import { useEffect, useMemo, useState } from "react";
import { Flex, Text, Icon, Box } from "renos-ui";
import {
  StyledDisclosure,
  NavLink,
  StyledBadge,
  StyledBadgeMinimized,
} from "./styled";
import { useHistory, useLocation } from "react-router-dom";
import { goToUrl, setGTM } from "helpers";

const SidebarItem = ({ item, isMinimized, setIsMinimized }) => {
  const [activePath, setActivePath] = useState("");
  const history = useHistory();
  const location = useLocation();

  const handleClick = (path, title, subtitle) => {
    setGTM("seller_center_section", {
      event_category: "menu",
      event_action: "click",
      event_label: subtitle ? `${title} | ${subtitle}` : title,
    });

    if (title === "Tutorial Berjualan") {
      goToUrl(path);
    } else {
      history.push(path);
      setActivePath(path);
    }
  };

  useEffect(() => {
    setActivePath(location.pathname);
  }, [location]);

  const isDefaultOpen = useMemo(() => {
    if (item?.childrens && activePath) {
      const allChildPath = item.childrens.map((child) => child.path);
      const isDefaultOpen = allChildPath.includes(activePath);

      return isDefaultOpen;
    }
  }, [activePath, item]);

  if (activePath) {
    if (item.childrens) {
      const isActive = activePath.includes(item.basePath);

      return (
        <Flex flexDirection="column">
          <StyledDisclosure defaultOpen={isDefaultOpen}>
            {({ isOpen, onToggle }) => (
              <Flex flexDirection="column" width="100%">
                <Flex
                  justifyContent="space-between"
                  onClick={onToggle}
                  cursor="pointer"
                  alignContent="center"
                  alignItems="center"
                  padding="10px 16px"
                  position="relative"
                  onMouseEnter={() => isMinimized && setIsMinimized(false)}
                >
                  <Flex alignContent="center" cursor="pointer" columnGap="8px">
                    {isActive && isMinimized && (
                      <Box
                        width="3px"
                        backgroundColor={isActive ? "blue50" : "white"}
                        borderTopRightRadius="8px"
                        borderBottomRightRadius="5px"
                        borderLeftWidth="10px"
                        position="absolute"
                        left="-10px"
                        top="8px"
                        height="24px"
                      />
                    )}
                    <Flex style={{ pointerEvents: "none" }}>
                      <Icon
                        name={
                          isActive && isMinimized ? item.iconActive : item.icon
                        }
                        size={20}
                        color={isActive && isMinimized ? "blue50" : "black100"}
                      />
                    </Flex>
                    {!isMinimized && (
                      <Text variant="caption" weight="bold">
                        {item.title}
                      </Text>
                    )}
                  </Flex>
                  {!isMinimized && (
                    <Flex style={{ pointerEvents: "none" }}>
                      <Icon
                        name={isOpen ? "Up-outline" : "Down-outline"}
                        size={20}
                        color="black100"
                      />
                    </Flex>
                  )}
                </Flex>
                {isOpen && !isMinimized && (
                  <Flex
                    flexDirection="column"
                    columnGap="2px"
                    position="relative"
                  >
                    {item.childrens.map((child, childIndex) => {
                      const isChildActive = child.path === activePath;

                      return (
                        <Flex key={childIndex} py="8px" position="relative">
                          <Box
                            backgroundColor={isChildActive ? "blue50" : "white"}
                            borderTopRightRadius="8px"
                            borderBottomRightRadius="5px"
                            borderLeftWidth="10px"
                            position="absolute"
                            left={0}
                            top="8px"
                            height="24px"
                            width="3px"
                          />
                          <NavLink
                            as="a"
                            onClick={() =>
                              handleClick(child.path, item.title, child.title)
                            }
                          >
                            <Text
                              color={isChildActive ? "blue50" : "black100"}
                              weight={isChildActive && "bold"}
                              variant="caption"
                            >
                              {child.title}
                            </Text>
                          </NavLink>
                        </Flex>
                      );
                    })}
                  </Flex>
                )}
              </Flex>
            )}
          </StyledDisclosure>
        </Flex>
      );
    } else {
      const isActive = item.path === activePath;

      return (
        <Flex
          justifyContent={isMinimized ? "center" : "space-between"}
          alignContent="center"
          cursor="pointer"
          position="relative"
          py="10px"
          width="100%"
          onClick={() => handleClick(item.path, item.title)}
          onMouseEnter={() => isMinimized && setIsMinimized(false)}
        >
          <Box
            width="3px"
            backgroundColor={isActive ? "blue50" : "white"}
            borderTopRightRadius="8px"
            borderBottomRightRadius="5px"
            borderLeftWidth="10px"
            position="absolute"
            left={0}
            top="8px"
            height="24px"
          />
          <Flex
            alignItems="center"
            columnGap="8px"
            paddingLeft={!isMinimized && "16px"}
          >
            <Flex style={{ pointerEvents: "none" }}>
              <StyledBadgeMinimized count={(isMinimized && item.badge) || 0}>
                <Icon
                  name={isActive ? item.iconActive : item.icon}
                  size={20}
                  color={isActive ? "blue50" : "black100"}
                />
              </StyledBadgeMinimized>
            </Flex>
            {!isMinimized && (
              <StyledBadge count={item.badge || 0}>
                <Text
                  color={isActive ? "blue50" : "black100"}
                  variant="caption"
                  weight="bold"
                >
                  {item.title}
                </Text>
              </StyledBadge>
            )}
          </Flex>
        </Flex>
      );
    }
  }
};

export default SidebarItem;
