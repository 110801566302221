import MobileLayout from "layouts/MobileLayout";
import { Flex, Text, Grid } from "renos-ui";
import Store from "./store";
import Analysis from "./analysis";
import Skeleton from "./skeleton";
import { useContext, useEffect, useMemo, useState } from "react";

import {
  useGetNewOrder,
  useGetNewReview,
  useGetChecklistStore,
} from "services/dashboard";
import dayjs from "dayjs";

import StoreContext from "providers/StoreProvider";
import { useCheckStore } from "services/auth";
import { useTour } from "@reactour/tour";

const Dashboard = () => {
  const [isCheckLoading, setIsCheckLoading] = useState(true);
  const { data } = useCheckStore();
  const startDate = dayjs(new Date()).format("YYYY-MM-DD");
  const endDate = dayjs(new Date()).format("YYYY-MM-DD");

  const { isLoading: loadingChecklist, data: checklist } = useGetChecklistStore(
    {
      onSuccess: () => {
        setIsCheckLoading(!isCheckLoading);
      },

      onError: () => setIsCheckLoading(false),
    }
  );
  const checkDone = useMemo(
    () => checklist?.checklist?.filter((ft) => ft.completed === false),
    [checklist]
  );

  const {
    storeData: {
      data: { id: storeId },
    },
  } = useContext(StoreContext);

  const { data: dataReview, isLoading: loadingNewReview } = useGetNewReview({
    store_id: storeId,
    date_start: startDate,
    date_end: endDate,
  });

  const { data: dataNewOrder, isLoading: loadingNewOrder } = useGetNewOrder({
    store: storeId,
    date_start: startDate,
    date_end: endDate,
  });

  const newActivity = [
    {
      id: "1",
      note: "Pesanan Baru",
    },
    {
      id: "2",
      note: "Siap Dikirim",
    },
    // {
    //   id: "3",
    //   note: "Dikomplain",
    // },
    {
      id: "4",
      note: "Ulasan baru",
    },
  ];

  const { setIsOpen } = useTour();

  useEffect(() => {
    const isOpen = checklist?.checklist?.some((ft) => ft.completed === false);
    setIsOpen(false);
    setIsCheckLoading(false);

    if (isOpen) {
      setIsOpen(true);
    } else {
      localStorage.removeItem("currentStepUpdate");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checklist]);

  return (
    <MobileLayout
      header={{
        title: data?.data?.name,
        isHomepage: true,
      }}
      footer
    >
      <Flex
        width="100%"
        backgroundColor="black5"
        flexDirection="column"
        padding="16px"
        gap="24px"
        boxSizing="border-box"
        height="100%"
        overflow="auto"
      >
        {loadingChecklist ? (
          <Skeleton itemShow={2} />
        ) : checkDone?.length === 0 ? (
          <></>
        ) : (
          <Flex
            flexDirection="column"
            gap="8px"
            className="complete-step-mobile"
          >
            <Flex flexDirection="column">
              <Text weight="bold">Yuk, Lengkapi Tokomu!</Text>
              <Text variant="caption" color="black50">
                Lengkapi semua data untuk mulai berjualan.
              </Text>
            </Flex>
            <Flex width="365px" rowGap="24px" flexDirection="column">
              <Store />
              {/* <Article data={article} /> */}
            </Flex>
          </Flex>
        )}

        <Flex
          flexDirection="column"
          gap="8px"
          width="100%"
          style={{ boxSizing: "border-box" }}
        >
          <Flex flexDirection="column">
            <Text weight="bold">Aktivitas Hari Ini</Text>
            <Text variant="caption" color="black50">
              Aktivitas penting yang terjadi di tokomu hari ini.
            </Text>
          </Flex>
          <Grid gap="16px" gridTemplateColumns="repeat(2,minmax(0,1fr))">
            {loadingNewOrder && loadingNewReview ? (
              <Skeleton itemShow={2} />
            ) : (
              newActivity.map((info, indexNewActivity) => {
                return (
                  !loadingNewOrder &&
                  !loadingNewReview && (
                    <Flex
                      key={`new-activity${indexNewActivity}`}
                      backgroundColor="white"
                      flexDirection="column"
                      justifyContent="center"
                      position="relative"
                      borderRadius="8px"
                      padding="16px"
                    >
                      <Text variant="subtitle" weight="bold">
                        {info.id === "1"
                          ? dataNewOrder[1]?.total
                          : info.id === "2"
                          ? dataNewOrder[2]?.total
                          : info.id === "4"
                          ? dataReview?.total_newest_review
                          : 0}
                      </Text>
                      <Text>{info.note}</Text>
                    </Flex>
                  )
                );
              })
            )}
          </Grid>
        </Flex>
        <Flex flexDirection="column">
          <Analysis />
        </Flex>
      </Flex>
    </MobileLayout>
  );
};

export default Dashboard;
