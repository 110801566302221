import { useEffect, useMemo, useState } from "react";
import { Button, Drawer, Flex, Icon, Spinner, Text, useToast } from "renos-ui";
import PriceInput from "./PriceInput";
import { useUpdateProductInline } from "services/product";
import { currencyFormatter, numberFormatter } from "helpers";

const Price = ({ disclosure: { isOpen, onClose }, data, handleOnSuccess }) => {
  const {
    product_price,
    product_skus,
    product_id,
    product_sku_id,
    product_sku_number,
    is_product_discount_seasonal_active,
  } = data;

  const isHaveDiscount = useMemo(
    () => is_product_discount_seasonal_active,
    [is_product_discount_seasonal_active]
  );

  const toast = useToast();
  const isVariant = useMemo(() => product_skus.length > 0, [product_skus]);
  const [value, setValue] = useState(isVariant ? [] : "");
  const [valueError, setValueError] = useState(isVariant ? [] : "");

  const { mutate: updateProductInline, isLoading } = useUpdateProductInline({
    onSuccess: () => {
      toast({
        label: `Harga produk berhasil diubah`,
        placement: "bottom",
      });

      let newData = data;

      if (isVariant) {
        const min = Math.min(...value.map((sku) => numberFormatter(sku)));
        const max = Math.max(...value.map((sku) => numberFormatter(sku)));
        const productPrice =
          min === max
            ? `Rp ${currencyFormatter(max)}`
            : `Rp ${currencyFormatter(min)} - ${currencyFormatter(max)}`;

        const newSkus = newData.product_skus.map((skus, indexSkus) => ({
          ...skus,
          product_price: value[indexSkus],
        }));

        newData = {
          ...newData,
          product_skus: [...newSkus],
          formatted_product_price: productPrice,
        };
      } else {
        newData = {
          ...newData,
          product_price: value ? numberFormatter(value) : 0,
          formatted_product_price: `Rp ${currencyFormatter(value)}`,
        };
      }

      handleOnSuccess(newData);
    },
    onError: () => {
      toast({
        label: `Harga produk gagal diubah`,
        placement: "bottom",
        backgroundColor: "red50",
      });
    },
  });

  const handleError = () => {
    const listFalse = [0, "00", "0", ""];

    if (isVariant) {
      const arrError = value.map((priceValue) =>
        listFalse.includes(priceValue)
      );

      setValueError([...arrError]);

      return arrError.filter((filterError) => filterError).length > 0;
    }

    const isNonVarError = listFalse.includes(value);

    if (isNonVarError) setValueError(true);

    return isNonVarError;
  };

  const handleSave = () => {
    let payload = {};
    const isHasError = handleError();

    if (isHasError) return;

    if (isVariant) {
      payload = {
        data: product_skus.map((sku, indexSku) => ({
          product_id: sku.product_id,
          product_price: numberFormatter(value[indexSku]),
          product_sku_id: sku.product_sku_id,
          product_sku_number: sku.product_sku_number,
        })),
      };
    } else {
      payload = {
        product_id: product_id,
        product_price: numberFormatter(value),
        product_sku_id: product_sku_id,
        product_sku_number: product_sku_number,
      };
    }

    updateProductInline(payload);
  };

  useEffect(() => {
    if (isVariant) {
      const listPrice = product_skus.map((variant) => variant.product_price);
      const listError = product_skus.map((_) => false);

      setValue([...listPrice]);
      setValueError([...listError]);
    } else {
      setValue(product_price);
      setValueError(false);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Drawer isVisible={isOpen} onClose={onClose} placement="bottom">
      <Flex flexDirection="column" gap="24px" height="100%">
        <Flex flexDirection="column" gap="16px">
          <Flex gap="8px">
            <Icon
              name="Close-outline"
              size={24}
              color="black50"
              onClick={onClose}
            />
            <Text variant="subtitle" weight="bold">
              Ubah Harga
            </Text>
          </Flex>
          <PriceInput
            disabled={isLoading}
            {...{
              value,
              setValue,
              isVariant,
              product_skus,
              valueError,
              setValueError,
              isHaveDiscount,
            }}
          />
        </Flex>

        <Button
          disabled={isLoading}
          size="medium"
          isBlock
          onClick={handleSave}
          preffix={isLoading && <Spinner color="white" size="8px" />}
        >
          Simpan
        </Button>
      </Flex>
    </Drawer>
  );
};

export default Price;
