import { Divider, Drawer, Flex, Text } from "renos-ui";

const ActionDrawer = ({
  id,
  isVisible,
  toggle,
  allowReply,
  onClickReply,
  onClickDetailTransaction,
  allowEditAndDelete,
  onDelete,
}) => {
  return (
    <Drawer
      closable={true}
      onClose={toggle}
      placement="bottom"
      isVisible={isVisible}
    >
      <Flex py="16px" gap="16px" flexDirection="column">
        {allowReply && (
          <>
            <Text variant="body" onClick={onClickReply}>
              Tulis Balasan
            </Text>
            <Divider />
          </>
        )}
        <Flex onClick={onClickDetailTransaction}>
          <Text variant="body"> Lihat Transaksi </Text>
        </Flex>
        {allowEditAndDelete && (
          <>
            <Divider />
            <Text variant="body" onClick={onClickReply}>
              Edit Balasan
            </Text>
            <Divider />
            <Text
              variant="body"
              onClick={onDelete}
            >
              Hapus Balasan
            </Text>
          </>
        )}
      </Flex>
    </Drawer>
  );
};

export default ActionDrawer;
