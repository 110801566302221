import MobileLayout from "layouts/MobileLayout";
import React from "react";
import {
  Box,
  Button,
  Flex,
  Icon,
  useToast,
  get,
  useDisclosure,
  Divider,
  Spinner,
} from "renos-ui";
import Empty from "./component/empty";
import SkeletonTemplate from "./component/skeleton";
import CardTemplate from "./component/CardTemplate";
import HandleDrawer from "./component/HandleDrawer";
import DeleteDrawer from "./component/DeleteDrawer";
import InfoDrawer from "./component/InfoDrawer";
import AutomateTemplate from "./component/AutomateTemplate";
import Total from "./component/total";
import Warning from "./component/warning";
import { useState, useContext } from "react";
import StoreContext from "providers/StoreProvider";
import { useHistory } from "react-router-dom";
import mobileUrl from "constants/mobileUrl";
import {
  useGetTemplate,
  useDeleteTemplates,
  useSaveTemplates,
  useActiveAutoTemplates,
  useGetAutoTemplate,
  useDeactiveAutoTemplates,
} from "services/settings/reply-template";

const AccountReplyTemplate = () => {
  const showDisclosure = useDisclosure({ isOpen: false });
  const deleteDisclosure = useDisclosure({ isOpen: false });
  const drawerInfo = useDisclosure({ isOpen: false });
  const history = useHistory();
  const toast = useToast();
  const [mode, setMode] = useState("add");
  const [checkedAutomate, setCheckAutomate] = useState();
  // eslint-disable-next-line
  const [tempIndex, setTempIndex] = useState("");
  const [changeDefault, setChangeDefault] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const {
    storeData: {
      data: { id: storeId },
    },
  } = useContext(StoreContext);
  const [value, setValue] = useState({
    name: "",
    message: "",
  });

  const {
    data: dataGetTemplates,
    isLoading: isLoadingTemplates,
    refetch: refetchGetTemplate,
  } = useGetTemplate({
    store_id: storeId,
  });

  const handleAdd = () => {
    history.push(mobileUrl.STORE_REPLY_TEMPLATE_FORM, {
      data: value,
      totalData: templateList?.length,
      mode: "add",
    });
  };

  const toggleDrawerMenu = (item) => {
    setSelectedItem(item);
    showDisclosure.onOpen();
  };

  const handleSelect = (index) => {
    setMode("select");
    setTempIndex(index);
    const tempSelected = templateList[index];
    setValue(tempSelected);
  };

  const { mutate: mutateDeleteTemplates, isLoading: isLoadingDelete } =
    useDeleteTemplates({
      onSuccess: () => {
        toast({
          label: "Template balasan berhasil dihapus",
          placement: "bottom",
        });
        showDisclosure.onClose();
        deleteDisclosure.onClose();
        refetchGetTemplate();
      },
      onError: () => {
        toast({
          label: "Template balasan gagal dihapus",
          placement: "bottom",
        });
      },
    });

  const handleSetDefault = () => {
    setChangeDefault(true);
  };

  const handleCancelDefault = () => {
    setChangeDefault(false);
  };

  const checkDefault = dataGetTemplates?.data.filter(
    (item) => item.default === true
  );

  const templateList = get(dataGetTemplates, "data", []);

  useGetAutoTemplate({
    onSuccess: (data) => {
      setCheckAutomate(data?.enabled);
    },
  });

  // active auto
  const { mutate: mutateActiveAutoTempates } = useActiveAutoTemplates({
    onSuccess: (data) => {
      toast({
        label: "Otomatis Template balasan ulasan berhasil diaktifkan!",
        placement: "bottom",
      });
      setCheckAutomate(!checkedAutomate);
    },
    onError: () => {
      toast({
        label: "Otomatis Template balasan ulasan gagak diaktifkan!",
        placement: "bottom",
      });
    },
  });

  // deactive auto
  const { mutate: mutateDeactiveAutoTempates } = useDeactiveAutoTemplates({
    onSuccess: () => {
      toast({
        label: "Otomatis Template balasan ulasan berhasil dinonaktifkan!",
        placement: "bottom",
      });
      setCheckAutomate(!checkedAutomate);
    },
    onError: () => {
      toast({
        label: "Otomatis Template balasan ulasan gagak dinonaktifkan!",
        placement: "bottom",
      });
    },
  });

  const { mutate: mutateSaveTemplates, isLoading: isSaveLoading } =
    useSaveTemplates({
      onError: () => {
        toast({
          label: `${
            mode === "select"
              ? "Pengaturan template balasan ulasan otomatis gagal disimpan!!"
              : mode === "add"
              ? "Template balasan ulasan gagal dibuat!"
              : "Template balasan ulasan gagal diubah!"
          }`,
          placement: "bottom",
        });
      },
      onSuccess: () => {
        toast({
          label: `${
            mode === "select"
              ? "Pengaturan template balasan ulasan otomatis berhasil disimpan!!"
              : mode === "add"
              ? "Template balasan ulasan berhasil dibuat!"
              : "Template balasan ulasan berhasil diubah!"
          }`,
          placement: "bottom",
        });
        setChangeDefault(false);
        refetchGetTemplate();

        if (templateList?.length < 1) {
          mutateActiveAutoTempates();
        }

        if (checkDefault === 0) {
          mutateActiveAutoTempates();
        }
      },
    });

  const handleDisabled = () => {
    toast({
      label: "Harap pilih template balasan otomatis terlebih dahulu!",
      placement: "top",
    });
  };

  const handleSaveSelect = () => {
    const body = {
      id: value.id,
      name: value.name,
      message: value.message,
      default: true,
    };
    mutateSaveTemplates(body);
  };

  // handle otomatis
  const handleActiveAuto = () => {
    mutateActiveAutoTempates();
  };

  const handleDeactiveAuto = () => {
    mutateDeactiveAutoTempates();
  };

  const handleActiveSwitch = () => {
    if (!checkedAutomate) {
      handleActiveAuto();
    } else {
      handleDeactiveAuto();
    }
  };

  const handleDeleteConfirm = (index) => {
    deleteDisclosure.onOpen();
  };

  const handleDelete = () => {
    mutateDeleteTemplates({ id: selectedItem?.id });
  };

  const handleEdit = (index) => {
    showDisclosure.onClose();
    const tempSelected = selectedItem;
    history.push(mobileUrl.STORE_REPLY_TEMPLATE_FORM, {
      data: tempSelected,
      mode: "edit",
    });
  };

  return (
    <MobileLayout
      header={{
        title: "Template Balasan",
        isHomepage: true,
        withBack: true,
        withoutGoBack: true,
        onBack: () => history.push("/account"),
        suffix:
          templateList.length === 5 ? (
            <></>
          ) : (
            <Flex alignItems="center" cursor="pointer" onClick={handleAdd}>
              <Icon name="Plus-outline" color="black50" cursor="pointer" />
            </Flex>
          ),
      }}
    >
      {isLoadingTemplates ? (
        <SkeletonTemplate itemShow={5} />
      ) : templateList?.length === 0 ? (
        <Empty />
      ) : (
        <Flex backgroundColor="black5" flexDirection="column" width="100%">
          {templateList.length === 5 && <Warning />}
          <Box p="16px" backgroundColor="white">
            <AutomateTemplate
              checked={checkedAutomate}
              handleDisabled={handleDisabled}
              handleActiveSwitch={handleActiveSwitch}
              drawer={drawerInfo}
            />
          </Box>
          <Box backgroundColor="white" mt="8px" height="100vh">
            <Total
              data={templateList}
              handleCancelDefault={handleCancelDefault}
              checkedAutomate={checkedAutomate}
              changeDefault={changeDefault}
              handleSetDefault={handleSetDefault}
            />
            <Divider />
            {templateList?.map((list, indexTemplate) => (
              <CardTemplate
                key={`template-${indexTemplate}`}
                {...list}
                item={list}
                length={templateList?.data?.length}
                checkedAutomate={checkedAutomate}
                changeDefault={changeDefault}
                index={indexTemplate}
                toggleDrawer={toggleDrawerMenu}
                handleSelect={handleSelect}
                handleDelete={() => handleDeleteConfirm(indexTemplate)}
                handleEdit={() => handleEdit(indexTemplate)}
              />
            ))}
          </Box>
          <Box>
            {changeDefault === true && (
              <Box bg="white" p="16px">
                <Button
                  disabled={isSaveLoading}
                  isBlock
                  onClick={handleSaveSelect}
                  preffix={
                    isSaveLoading && <Spinner size="16px" color="white" />
                  }
                >
                  Simpan
                </Button>
              </Box>
            )}
          </Box>
        </Flex>
      )}

      <HandleDrawer
        disclosure={showDisclosure}
        handleEdit={handleEdit}
        handleDelete={deleteDisclosure}
      />
      <DeleteDrawer
        disclosure={deleteDisclosure}
        isLoadingDelete={isLoadingDelete}
        handleDelete={handleDelete}
      />
      <InfoDrawer disclosure={drawerInfo} />
    </MobileLayout>
  );
};

export default AccountReplyTemplate;
