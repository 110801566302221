import React from "react";
import {
  Flex,
  Text,
  Button,
  Dialog,
  useToast,
  Divider,
  Spinner,
  Box,
  Icon,
} from "renos-ui";
import { useUpdateLocation } from "services/location";
import { useState } from "react";
import { useEffect } from "react";
import { setGTM } from "helpers";
import { DEFAULT_FORM } from "./constants";
import FormLeftSide from "./components/FormLeftSide";
import { StoreLocationProvider } from "providers/StoreLocationProvider";
import FormRightSide from "./components/FormRightSide";

const FormSubmit = ({
  value,
  setValue,
  show,
  close,
  refetchGetLocation,
  listProvince,
  listCity,
  listDistrict,
  listSubDistrict,
  tempSelected,
  setTempSelected,
  setTempIndex,
  StoreName,
}) => {
  const toast = useToast();
  const [isError, setIsError] = useState({
    ...DEFAULT_FORM,
    store_name: StoreName,
  });

  // const [textErrorPhone, setTextErrorPhone] = useState('Nomor HP/Telepon harus diisi.');

  const { mutate: submitAddLocation, isLoading } = useUpdateLocation({
    onError: (error) => {
      const {
        response: {
          data: { frontend_message, message },
        },
      } = error;
      toast({
        label:
          frontend_message || message || "Pengaturan lokasi gagal ditambah.",
        placement: "top",
        backgroundColor: "red50",
      });
    },
    onSuccess: (data) => {
      const { frontend_message, message } = data;
      toast({
        label: message || frontend_message || "Alamat berhasil diubah.",
        placement: "top",
      });
      setTempSelected(null);
      setTempIndex("");

      close();
      refetchGetLocation();
    },
  });

  const handleChangevalue = (e) => {
    if (e.target.name === "contact_number") {
      setValue({
        ...value,
        [e.target.name]: e.target.value.replace(/[^+\d]/g, "").substring(0, 15),
      });
    } else {
      setValue({
        ...value,
        [e.target.name]: e.target.value,
      });
    }

    setIsError({
      ...isError,
      [e.target.name]: false,
    });
  };

  const handleError = () => {
    const tempError = isError;
    const listExclude = [
      "is_primary_int",
      "is_primary",
      "point_address",
      "description",
    ];
    let countError = 0;

    for (var key in value) {
      if (!listExclude.includes(key)) {
        if (!value[key] && key !== "coordinates") {
          tempError[key] = true;
          countError++;
        }

        if (key === "coordinates" && !value.coordinates.latitude) {
          tempError[key] = true;
          countError++;
        }

        if (key === "contact_number" && value[key]) {
          const plusPattern = /^\+/;
          const indonesiaPattern = /^\+62\d{0,15}$/;
          const containPlusInMiddle =
            value[key].replace(/[^+]/g, "").length > 0 &&
            !plusPattern.test(value[key]);
          tempError.patternNumber = false;

          if (
            (plusPattern.test(value[key]) &&
              !indonesiaPattern.test(value[key])) ||
            containPlusInMiddle
          ) {
            tempError.patternNumber = true;
            countError++;
          }
        }

        if (key === "address" && value[key] && value[key].length < 15) {
          tempError[key] = true;
          countError++;
        }
      }
    }

    setIsError({ ...tempError });

    if (countError !== 0) {
      toast({
        label: "Mohon lengkapi semua data",
        placement: "top",
        backgroundColor: "red50",
      });

      return true;
    }

    return false;
  };

  const handleAddLocation = (event) => {
    event.preventDefault();

    setGTM("seller_center_section", {
      event_category: "store_settings | location",
      event_action: "click",
      event_label: "save_location",
    });

    const isHasError = handleError();

    if (isHasError) return false;

    const datas = value;
    datas.description = datas.description || "alamat";
    delete datas.is_primary_int;

    submitAddLocation({ data: datas });
  };

  useEffect(() => {
    const tempError = isError;

    for (var key in value) {
      tempError[key] = false;
    }

    setIsError({ ...tempError });
    //eslint-disable-next-line
  }, [show]);

  return (
    <Dialog
      isVisible={show}
      onClose={close}
      verticalCentered
      width="720px"
      height="575px"
    >
      <Flex flexDirection="column" rowGap="16px" width="100%">
        <Flex flexDirection="row" justifyContent="space-between">
          <Text variant="heading3" weight="bold">
            {tempSelected ? "Ubah" : "Tambah"} Lokasi Toko
          </Text>
          <Flex>
            <Box cursor="pointer" onClick={close}>
              <Icon name="Close-outline" color="black50" />
            </Box>
          </Flex>
        </Flex>
        <Divider />
        <form onSubmit={handleAddLocation}>
          <StoreLocationProvider
            value={{
              value,
              isError,
              setValue,
              setIsError,
              listProvince,
              listCity,
              listDistrict,
              listSubDistrict,
              handleChangevalue,
            }}
          >
            <Flex flexDirection="column" height="500px" overflow="auto">
              <Flex flexDirection="row" columnGap="24px">
                <FormLeftSide />
                <FormRightSide />
              </Flex>
              <Flex marginTop="24px">
                <Button
                  isBlock
                  size="large"
                  type="submit"
                  variant="primary"
                  disabled={isLoading}
                  preffix={isLoading && <Spinner color="white" size="8px" />}
                >
                  <Text weight="bold">Simpan Lokasi</Text>
                </Button>
              </Flex>
            </Flex>
          </StoreLocationProvider>
        </form>
      </Flex>
    </Dialog>
  );
};

export default FormSubmit;