import { Button, Flex, Text, Spinner } from "renos-ui";
import { StyledTextAreaReject } from "../../../styled";
import { useMemo, useState } from "react";
import Datepicker from "widgets/mobile/Datepicker";
import dayjs from "dayjs";

const Form = ({
  setIsFormMode,
  handleReject,
  orderId,
  isLoading,
  selectedReason,
}) => {
  const [values, setValues] = useState({
    startDate: new Date(),
    endDate: "",
  });

  const today = new Date();

  const onReject = () => {
    const formData = new FormData();
    formData.append("id", orderId);
    formData.append("order_cancellation_reason_id", selectedReason);
    formData.append("order_cancellation_reason", values.reason);

    if (selectedReason === 2) {
      formData.append(
        "date_off_start",
        dayjs(values.startDate).format("YYYY-MM-DD")
      );
      formData.append(
        "date_off_end",
        dayjs(values.endDate).format("YYYY-MM-DD")
      );
    }

    handleReject(formData);
  };

  const renderButton = () => {
    return (
      <Flex gap="8px">
        <Button
          variant="tertiary"
          size="medium"
          isBlock
          onClick={() => setIsFormMode(false)}
        >
          Sebelumnya
        </Button>
        <Button
          size="medium"
          isBlock
          onClick={onReject}
          disabled={isDisabled}
          preffix={isLoading && <Spinner color="white" size="8px" />}
        >
          Tolak Pesanan
        </Button>
      </Flex>
    );
  };

  const isDisabled = useMemo(() => {
    if (selectedReason === 2) {
      return (
        !values.reason || !values.startDate || !values.endDate || isLoading
      );
    }

    return !values.reason || isLoading;
  }, [isLoading, values, selectedReason]);

  if (selectedReason === 2)
    return (
      <Flex flexDirection="column" width="100%" gap="24px" height="100%">
        <Flex flexDirection="column" gap="8px">
          <Text variant="subtitle" weight="bold">
            Atur tanggal tutup tokomu
          </Text>
          <Text variant="caption" color="black75">
            Hal ini untuk menghindari pembeli melakukan pesanan ketika tokomu
            sedang tutup / libur.
          </Text>
        </Flex>
        <Flex flexDirection="column" gap="16px">
          <Datepicker
            min={today}
            value={values.startDate}
            onChange={(newValue) => {
              setValues({
                ...values,
                startDate: newValue,
                endDate:
                  values.endDate && newValue >= values.endDate
                    ? ""
                    : values.endDate,
              });
            }}
            label="Mulai dari tanggal"
            placeholder="Tanggal mulai"
            disabled
          />
          <Datepicker
            min={today}
            value={values.endDate}
            onChange={(newValue) => setValues({ ...values, endDate: newValue })}
            label="Sampai dengan tanggal"
            placeholder="Tanggal selesai"
          />
          <Flex flexDirection="column" gap="8px">
            <Text variant="caption" color="black50" weight="bold">
              Catatan
            </Text>
            <StyledTextAreaReject
              name="note"
              placeholder="Tuliskan catatan / alasan tokomu tutup"
              value={values.reason}
              maxLength={150}
              onChange={(event) =>
                setValues({
                  ...values,
                  reason: event.target.value,
                })
              }
            />
          </Flex>
        </Flex>
        {renderButton()}
      </Flex>
    );

  return (
    <Flex flexDirection="column" width="100%" gap="24px" height="100%">
      <Text variant="subtitle" weight="bold">
        Jelaskan alasanmu menolak pesanan
      </Text>
      <StyledTextAreaReject
        name="note"
        placeholder="Tuliskan catatan / alasan menolak pesanan"
        value={values.reason}
        maxLength={150}
        onChange={(event) =>
          setValues({
            ...values,
            reason: event.target.value,
          })
        }
      />
      {renderButton()}
    </Flex>
  );
};

export default Form;
