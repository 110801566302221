import React from "react";
import {
  Flex,
  Text,
  Input,
  Button,
  Box,
  Icon,
  SelectInput,
  isEmpty,
} from "renos-ui";
import Map from "widgets/desktop/GoogleMap/Map";
import TermCondition from "./components/TermCondition";
import useRegister from "./hooks/useRegister";
import { StyledInput } from "./styled";

const RegisterDetail = (props) => {
  const {
    handleSubmit,
    onSubmitForm,
    listProvince,
    isChecked,
    setChecked,
    listCity,
    getCity,
    isLoadingCity,
    listDistrict,
    getDistrict,
    isLoadingDistrict,
    listSubDistrict,
    getSubDistrict,
    isLoadingSubDistrict,
    emptyOptions,
    errors,
    control,
    getValues,
    setValue,
    Controller,
    isLoadingSubmit,
  } = useRegister();

  const onChangeMap = (position, pointAddress) => {
    setValue("point_address", pointAddress);
    setValue("coordinates.latitude", position.lat);
    setValue("coordinates.longitude", position.lng);
  };

  return (
    <Flex
      width="80%"
      margin="auto"
      background={`url(/assets/images/renos-register-detail.svg)`}
      backgroundRepeat="no-repeat"
      backgroundSize="cover"
      backgroundPosition="center center"
      alignItems="center"
      justifyContent="center"
    >
      <Flex flexDirection="column" rowGap="40px" marginY="40px">
        <Flex flexDirection="row" alignItems="center">
          <Flex
            width="32px"
            height="32px"
            borderRadius="100%"
            cursor="pointer"
            alignItems="center"
            justifyContent="center"
            onClick={() => props.setStep(1)}
            boxShadow="0px 4px 10px rgba(20, 20, 32, 0.08)"
          >
            <Icon name="Left-outline" color="black50" size={16} />
          </Flex>
          <Box
            as="img"
            margin="auto"
            src="/assets/images/renos-primary.svg"
            alt="renos-primary"
          />
        </Flex>
        <Flex
          width="720px"
          height="auto"
          padding="24px"
          borderRadius="16px"
          flexDirection="column"
          rowGap="24px"
          zIndex="10"
          backgroundColor="white"
          boxShadow="0px 4px 10px rgba(20, 20, 32, 0.08)"
        >
          <Flex flexDirection="column" rowGap="8px">
            <Text variant="heading3" weight="bold">
              Isi Lokasi Toko
            </Text>
            <Text>
              Lengkapi data lokasi tokomu untuk menyelesaikan pendaftaran.
            </Text>
          </Flex>

          <form onSubmit={handleSubmit(onSubmitForm)}>
            <Flex flexDirection="row" columnGap="24px">
              <Flex width="50%" rowGap="24px" flexDirection="column">
                <Controller
                  name="name"
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      label="Nama Lokasi"
                      showCount
                      placeholder="Contoh: Gudang, Toko, Cabang, dll."
                      maxLength={60}
                      isError={!!errors.name}
                      error={errors.name?.message}
                    />
                  )}
                />
                <Controller
                  name="address"
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      label="Alamat Toko"
                      placeholder="Tulis alamat lengkap toko"
                      isError={!!errors.address}
                      error={errors.address?.message}
                    />
                  )}
                />

                <Flex flexDirection="column" rowGap="8px">
                  <Text weight="bold" variant="caption" color="black50">
                    Provinsi
                  </Text>

                  <Controller
                    name="province"
                    control={control}
                    render={({ field }) => (
                      <SelectInput
                        placeholder="Pilih Provinsi"
                        hideSelectedOptions={true}
                        {...field}
                        options={listProvince?.data?.map((row) => ({
                          value: row.province_id,
                          label: row.province_name,
                        }))}
                        isSearchable={true}
                        onChange={(value) => {
                          field.onChange(value);
                          setValue("city", null);
                          setValue("district", null);
                          setValue("sub_district", null);
                          getCity({
                            params: {
                              province_id: value.value,
                            },
                          });
                        }}
                        isError={!!errors?.province}
                      />
                    )}
                  />
                  {!!errors.province && (
                    <Text variant="caption" color="red50">
                      {errors.province?.message}
                    </Text>
                  )}
                </Flex>

                <Flex flexDirection="column" rowGap="8px">
                  <Text weight="bold" variant="caption" color="black50">
                    Kota/Kabupaten
                  </Text>

                  <Controller
                    name="city"
                    control={control}
                    render={({ field }) => (
                      <SelectInput
                        options={
                          listCity?.data?.map((row) => ({
                            value: row.city_id,
                            label: row.city_name,
                          })) || emptyOptions
                        }
                        hideSelectedOptions={true}
                        isSearchable={!isEmpty(listCity)}
                        placeholder="Pilih Kota/Kabupaten"
                        {...field}
                        isDisabled={
                          isLoadingCity || isEmpty(getValues("province"))
                        }
                        onChange={(value) => {
                          field.onChange(value);
                          setValue("district", null);
                          setValue("sub_district", null);
                          getDistrict({
                            params: {
                              city_id: value.value,
                            },
                          });
                        }}
                        isError={!!errors?.city}
                      />
                    )}
                  />
                  {!!errors.city && (
                    <Text variant="caption" color="red50">
                      {errors.city?.message}
                    </Text>
                  )}
                </Flex>

                <Flex flexDirection="column" rowGap="8px">
                  <Text weight="bold" variant="caption" color="black50">
                    Kecamatan
                  </Text>

                  <Controller
                    name="district"
                    control={control}
                    render={({ field }) => (
                      <SelectInput
                        options={
                          listDistrict?.data?.map((row) => ({
                            value: row.district_id,
                            label: row.district_name,
                          })) || emptyOptions
                        }
                        hideSelectedOptions={true}
                        placeholder="Pilih Kecamatan"
                        isSearchable={!isEmpty(listDistrict)}
                        {...field}
                        isDisabled={
                          isLoadingDistrict || isEmpty(getValues("city"))
                        }
                        onChange={(value) => {
                          field.onChange(value);
                          setValue("sub_district", null);
                          setValue("postal_code", "");
                          getSubDistrict({
                            params: {
                              district_id: value.value,
                            },
                          });
                        }}
                        isError={!!errors?.district}
                      />
                    )}
                  />
                  {!!errors.district && (
                    <Text variant="caption" color="red50">
                      {errors.district?.message}
                    </Text>
                  )}
                </Flex>

                <Flex flexDirection="column" rowGap="8px">
                  <Text weight="bold" variant="caption" color="black50">
                    Kelurahan
                  </Text>

                  <Controller
                    name="sub_district"
                    control={control}
                    render={({ field }) => (
                      <SelectInput
                        options={
                          listSubDistrict?.data?.map((row) => ({
                            value: row.lower_district_id,
                            label: row.lower_district_name,
                            data: row,
                          })) || emptyOptions
                        }
                        hideSelectedOptions={true}
                        isSearchable={!isEmpty(listSubDistrict)}
                        placeholder="Pilih Kecamatan"
                        {...field}
                        isDisabled={
                          isLoadingSubDistrict || isEmpty(getValues("district"))
                        }
                        onChange={(value) => {
                          setValue("postal_code", value.data.postal_code);
                          field.onChange(value);
                        }}
                        isError={!!errors?.sub_district}
                      />
                    )}
                  />
                  {!!errors.sub_district && (
                    <Text variant="caption" color="red50">
                      {errors.sub_district?.message}
                    </Text>
                  )}
                </Flex>
              </Flex>

              <Flex width="50%" rowGap="24px" flexDirection="column">
                <Controller
                  name="contact_person"
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      label="Nama PIC Toko"
                      placeholder="Tulis nama PIC toko / gudang"
                      isError={!!errors.contact_person}
                      error={errors.contact_person?.message}
                    />
                  )}
                />

                <Flex flexDirection="column" rowGap="8px">
                  <Text weight="bold" variant="caption" color="black50">
                    Nomor HP/Telepon
                  </Text>
                  <Controller
                    name="contact_number"
                    control={control}
                    render={({ field }) => (
                      <StyledInput
                        {...field}
                        type="text"
                        value={getValues("contact_number")}
                        name="contact_number"
                        placeholder="Masukkan nomor HP/telepon"
                        onChange={(e) =>
                          setValue(
                            "contact_number",
                            e.target.value
                              .replace(/[^+\d]/g, "")
                              .substring(0, 15)
                          )
                        }
                        isError={!!errors.contact_number}
                      />
                    )}
                  />

                  {!!errors.contact_number && (
                    <Text variant="caption" color="red50">
                      {errors.contact_number?.message ||
                        "Nomor hp/telepon harus diisi."}
                    </Text>
                  )}
                </Flex>

                <Controller
                  name="postal_code"
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      label="Kode Pos"
                      placeholder="Kode pos"
                      value={getValues("postal_code")}
                      disabled
                    />
                  )}
                />

                <Flex
                  width="100%"
                  flexDirection="column"
                  gap="8px"
                  height="100%"
                >
                  <Map
                    onChange={onChangeMap}
                    position={getValues("coordinates")}
                  />
                  {!!errors.point_address && (
                    <Text variant="caption" color="red50">
                      {errors?.point_address?.message}
                    </Text>
                  )}
                </Flex>
              </Flex>
            </Flex>

            <TermCondition onChange={() => setChecked(!isChecked)} />

            <Button
              isBlock
              size="large"
              type="submit"
              disabled={!isChecked || isLoadingSubmit}
              variant={isChecked ? "primary" : "secondary"}
            >
              <Text weight="bold">Buka Toko</Text>
            </Button>
          </form>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default RegisterDetail;
