import React from "react";
import { Box, Button, Flex, Icon, Label, Text, colors } from "renos-ui";
import { StyledProductImage } from "./styled";

const ProductCard = ({ data, onClick }) => {
  const {
    product_image_url,
    product_id,
    isVariant,
    formatted_variant_value_name,
    product_name,
    formatted_selling_price,
    formatted_product_discount_percentage_end,
    formatted_product_price,
    is_product_discount_active,
    total_sold,
    review_rating,
  } = data?.product || {};

  return (
    <Flex
      p="16px"
      justifyContent="space-between"
      border={`${colors.black5} solid 1px`}
      borderRadius="8px"
      alignItems="center"
      boxShadow={`0px 2px 8px 0px ${colors.black10}`}
    >
      <Flex gap="16px">
        <StyledProductImage
          src={product_image_url}
          as="img"
          alt={`product-img-${product_id}`}
          width="64px"
          height="64px"
          borderRadius="8px"
        />
        <Flex flexDirection="column" gap="4px">
          <Text variant="caption" lineClamp={1}>
            {isVariant ? formatted_variant_value_name : product_name}
          </Text>
          <Flex>
            <Text weight="bold">{formatted_selling_price}</Text>
            {is_product_discount_active && (
              <Flex gap="1px" ml="4px">
                <Label variant="error">
                  {formatted_product_discount_percentage_end}
                </Label>
                <Text strikeThrough variant="caption" color="black50">
                  {formatted_product_price}
                </Text>
              </Flex>
            )}
          </Flex>
          <Flex gap="8px" alignItems="center">
            {review_rating && (
              <>
                <Flex alignItems="center" gap="4px">
                  <Icon size="10px" name="Star-solid" color="yellow75" />
                  <Text variant="caption" color="black25">
                    {(Math.round(review_rating * 100) / 100).toFixed(1)}
                  </Text>
                </Flex>
                <Box
                  backgroundColor="black10"
                  borderRadius="100%"
                  width="4px"
                  height="4px"
                />
              </>
            )}
            {total_sold !== 0 && (
              <Text variant="caption" color="black25">
                Terjual {total_sold}
              </Text>
            )}
          </Flex>
        </Flex>
      </Flex>
      <Button variant="secondary" size="small" onClick={onClick}>
        Ubah
      </Button>
    </Flex>
  );
};

export default ProductCard;
