import MobileLayout from "layouts/MobileLayout";
import { Flex } from "renos-ui";
import OrderInfo from "./component/OrderInfo";
import { useEffect, useMemo, useState } from "react";
import { useGetOrderDetail } from "services/order-management";
import { useHistory } from "react-router-dom";
import useQueryParams from "hooks/useQueryParams";
import ProductInfo from "./component/ProductInfo";
import DeliveryInfo from "./component/DeliveryInfo";
import PaymentInfo from "./component/PaymentInfo";
import ActionContainer from "./component/ActionContainer";
import Loading from "./component/Loading";
import mobileUrl from "constants/mobileUrl";

const OrderManagementDetail = () => {
  const query = useQueryParams();
  const { push } = useHistory();

  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const toList = () => {
    push(mobileUrl.ORDERS_LIST);
  };

  const { mutate: getDetail } = useGetOrderDetail({
    onSuccess: (data) => {
      if (data.data.length === 0) {
        toList();
      } else {
        setData(data.data[0]);
        setIsLoading(false);
      }
    },
    onError: () => {
      toList();
    },
  });

  const handleRefetch = () => {
    const selectedNumber = query.get("no");
    getDetail({ query: selectedNumber });
  };

  const isAllowAction = useMemo(() => {
    const arrAllowed = [2, 3, 4];

    return arrAllowed.includes(data.order_status_id);
  }, [data.order_status_id]);

  useEffect(() => {
    const selectedNumber = query.get("no");

    if (!selectedNumber) {
      toList();
    }

    getDetail({ query: selectedNumber });
    // eslint-disable-next-line
  }, []);

  return (
    <MobileLayout
      header={{
        title: "Detail Pesanan",
        withBack: true,
      }}
    >
      {isLoading ? (
        <Loading />
      ) : (
        <Flex height="100%" width="100%" flexDirection="column">
          <Flex
            flexDirection="column"
            height={isAllowAction ? "calc(100% - 56px)" : "auto"}
            overflow="auto"
            width="100%"
          >
            <OrderInfo {...data} dataPrint={data} />
            <ProductInfo {...data} />
            <DeliveryInfo {...data} />
            <PaymentInfo {...data} />
          </Flex>
          {isAllowAction && (
            <ActionContainer {...data} refetch={handleRefetch} />
          )}
        </Flex>
      )}
    </MobileLayout>
  );
};

export default OrderManagementDetail;
