import React, { useRef, useEffect, useState } from "react";
import { dateWithDayFormat } from "helpers";
import {
  Flex,
  Text,
  Input,
  Icon,
  Box,
  Spinner,
  isEmpty,
  Upload,
} from "renos-ui";
import useViewPort from "./useViewPort";
import CardProduct from "./CardProduct";
import { useGetQuickChat } from "services/chat";
import { QISCUS } from "hooks/qiscus";
import ProductList from "./ProductList";
import OrderList from "./OrderList";

const ChatRoomBody = ({
  loadBefore,
  messageList,
  qiscusInfo,
  getTime,
  textMessage,
  setTextMessage,
  sendMessage,
  refChat,
  handleLoadMessage,
  attachment,
  setAttachment,
  registerUpload,
  attachmentDisclosure,
  attachProductDisclosure,
  seletedAttachProduct,
  setSelectedAttachProduct,
  handleAttachProduct,
  attachOrderDisclosure,
  selectedAttachOrder,
  setSelectedAttachOrder,
  handleAttachOrder,
  buyerInfo,
  // slug,
  // storeId,
}) => {
  const [open, setOpen] = useState(false);
  const refTop = useRef(null);
  const TopViewport = useViewPort(refTop);
  const { data: quickChat } = useGetQuickChat();

  // const isShowListAttachment = useMemo(() => {
  //   return !isEmpty(attachment) && attachment.qiscusId?.toString();
  // }, [attachment]);

  useEffect(() => {
    if (TopViewport) handleLoadMessage();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [TopViewport]);

  useEffect(() => {
    setSelectedAttachOrder([]);
    setSelectedAttachProduct([]);
    attachOrderDisclosure.onClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buyerInfo]);

  const handleRemoveProductAttachment = (id, origins) => {
    const tempAttachment = { ...attachment };
    const selectedProducts =
      tempAttachment?.payload?.content?.selectedProducts || [];

    if (id && origins !== "ORDER") {
      const index = selectedProducts.findIndex(
        (product) => product.product_id === id,
      );

      if (index > -1) {
        selectedProducts.splice(index, 1);
      }
    }

    if (selectedProducts.length === 0) {
      setAttachment({});
    } else {
      setAttachment(tempAttachment);
    }
  };

  return (
    <Flex
      flex="8"
      flexDirection="column"
      justifyContent="space-between"
      height="80%"
      width="100%"
      position="relative"
    >
      {loadBefore && (
        <Flex
          justifyContent="center"
          alignItems="center"
          marginTop="24px"
          width="100%"
          height="100%"
        >
          <Spinner size="18px" emptyColor="blue10" />
        </Flex>
      )}
      <Flex flexDirection="column" height="100%" overflowY="auto">
        <Flex flexDirection="column" padding="16px" gap="16px" ref={refChat}>
          <Box ref={refTop} />

          {messageList.formated.map((chat, index) =>
            chat.map((newChat, index2) =>
              index2 === 0 ? (
                <Flex justifyContent="center" key={`${index}-${index2}`}>
                  <Text variant="caption" color="black50">
                    {dateWithDayFormat(newChat, true)}
                  </Text>
                </Flex>
              ) : (
                newChat.map((userChat, index3) =>
                  Number(userChat.email) !== Number(qiscusInfo.userId) ? (
                    <Flex
                      flexDirection="column"
                      gap="16px"
                      key={`${userChat.id}-${index3}`}
                    >
                      {!isEmpty(userChat.payload) && (
                        <CardProduct userChat={userChat} />
                      )}
                      {userChat.message && (
                        <Flex alignSelf="flex-start" gap="8px">
                          <Flex
                            border="1px solid"
                            borderColor="black10"
                            padding="12px 16px"
                            width="fit-content"
                            borderRadius="16px"
                            borderTopLeftRadius="0"
                          >
                            <Text variant="caption">{userChat.message}</Text>
                          </Flex>
                          <Flex alignItems="flex-end" gap="8px">
                            <Text variant="small" color="black50">
                              {getTime(userChat.timestamp)}
                            </Text>
                          </Flex>
                        </Flex>
                      )}
                    </Flex>
                  ) : (
                    <Flex
                      flexDirection="column"
                      gap="16px"
                      key={`${userChat.id}-${index3}`}
                    >
                      {!isEmpty(userChat.payload) && (
                        <CardProduct userChat={userChat} placement="right" />
                      )}
                      <Flex alignSelf="flex-end" gap="8px">
                        <Flex alignItems="flex-end" gap="8px">
                          <Text variant="small" color="black50">
                            {getTime(userChat.timestamp)}
                          </Text>
                          {userChat.status === "read" && (
                            <Icon
                              name="DoubleCheck-outline"
                              size={12}
                              color="blue50"
                            />
                          )}
                          {userChat.status === "delivered" && (
                            <Icon
                              name="DoubleCheck-outline"
                              size={12}
                              color="black100"
                            />
                          )}
                          {userChat.status === "sent" && (
                            <Icon name="Checkmark-outline" size={12} />
                          )}
                          {userChat.status === "pending" && (
                            <Icon name="Time-outline" size={12} />
                          )}
                        </Flex>

                        {!isEmpty(userChat.message) && (
                          <Flex
                            width="fit-content"
                            backgroundColor="black5"
                            padding="12px 16px"
                            borderRadius="16px"
                            borderTopRightRadius="0"
                          >
                            <Text
                              variant="caption"
                              style={{ whiteSpace: "pre-line" }}
                            >
                              {userChat.message}
                            </Text>
                          </Flex>
                        )}
                      </Flex>
                    </Flex>
                  ),
                )
              ),
            ),
          )}

          <Box id="div-end" />
          <Flex height="100px" />
        </Flex>
      </Flex>

      <Flex width="100%" margin="auto">
        <Flex
          padding="0 16px 0 16px"
          position="absolute"
          bottom="0"
          width="100%"
          backgroundColor="white"
          flexDirection="column"
          borderTopWidth="1px"
          borderColor="black10"
          borderTopStyle="solid"
          borderBottomRightRadius="8px"
        >
          {(!isEmpty(attachment?.payload?.content?.orderNumber) ||
            seletedAttachProduct.length > 0) && (
            <Flex
              padding="12px 12px 0 0"
              gap="8px"
              width="100%"
              overflow="auto"
              backgroundColor="white"
            >
              <CardProduct
                isAttachment
                removeAttachment={handleRemoveProductAttachment}
                userChat={{ ...attachment }}
              />
            </Flex>
          )}

          <Flex flexDirection="column" gap="16px" width="100%">
            <Flex
              gap="12px"
              height="80px"
              alignItems="center"
              width="100%"
              position="relative"
            >
              {/* <Flex width={attachmentDisclosure.isOpen ? "calc(100% - 40px)" : "100%"} cursor="pointer"></Flex> */}
              <Flex width="88%" cursor="pointer">
                <Input
                  value={textMessage}
                  size="regular"
                  prefix={
                    <Icon
                      name="Chat-solid"
                      size={20}
                      onClick={() => setOpen(!open)}
                    />
                  }
                  sufix={
                    <Icon
                      name="Plus-solid"
                      size={20}
                      onClick={() => {
                        if (attachmentDisclosure.isOpen) {
                          attachmentDisclosure.onClose();
                        } else {
                          attachmentDisclosure.onOpen();
                        }
                      }}
                    />
                  }
                  placeholder="Tuliskan pesan..."
                  onKeyPress={(e) => {
                    e.key === "Enter" && sendMessage();
                    setOpen(false);
                  }}
                  onChange={(e) => setTextMessage(e.target.value)}
                />
              </Flex>
              <Flex width="12%">
                <Icon
                  name="Send-solid"
                  size={40}
                  color="blue50"
                  cursor="pointer"
                  onClick={() => {
                    setOpen(false);
                    sendMessage();
                  }}
                />
              </Flex>

              {open && (
                <Flex
                  borderRadius="16px"
                  boxShadow="0px 4px 4px rgba(20, 20, 32, 0.05)"
                  backgroundColor="white"
                  width="fit-content"
                  position="absolute"
                  bottom="54px"
                  left="0"
                  padding="16px"
                  gap="16px"
                  flexDirection="column"
                >
                  {quickChat.data.templates.map((list) => (
                    <Flex cursor="pointer" key={list.id}>
                      <Text
                        variant="caption"
                        color="black75"
                        onClick={() => {
                          setTextMessage(list.template_message);
                          QISCUS.publishTyping(1);
                        }}
                      >
                        {list.template_message}
                      </Text>
                    </Flex>
                  ))}
                </Flex>
              )}

              {attachmentDisclosure.isOpen && (
                <Flex
                  borderRadius="16px"
                  boxShadow="0px 4px 4px rgba(20, 20, 32, 0.05)"
                  backgroundColor="white"
                  width="133px"
                  position="absolute"
                  bottom="54px"
                  right="56px"
                  padding="16px"
                  gap="16px"
                  flexDirection="column"
                >
                  <Flex cursor="pointer" gap="16px" flexDirection="column">
                    <Upload
                      {...registerUpload}
                      accept="image/jpg, image/jpeg, image/png"
                      onInvalid={(event) =>
                        event.target.setCustomValidity(
                          "Gambar tidak boleh kosong.",
                        )
                      }
                      onInput={(event) => event.target.setCustomValidity("")}
                    >
                      <Text variant="caption" color="black75">
                        Gambar
                      </Text>
                    </Upload>
                    <Flex onClick={() => attachProductDisclosure.onOpen()}>
                      <Text variant="caption" color="black75">
                        Produk
                      </Text>
                    </Flex>
                    <Flex onClick={() => attachOrderDisclosure.onOpen()}>
                      <Text variant="caption" color="black75">
                        Daftar Pesanan
                      </Text>
                    </Flex>
                  </Flex>
                </Flex>
              )}

              {attachProductDisclosure.isOpen && (
                <ProductList
                  disclosure={attachProductDisclosure}
                  selectedAttachProduct={seletedAttachProduct}
                  setSelectedAttachProduct={setSelectedAttachProduct}
                  handleAttachProduct={handleAttachProduct}
                />
              )}

              {attachOrderDisclosure.isOpen && (
                <OrderList
                  disclosure={attachOrderDisclosure}
                  selectedAttachOrder={selectedAttachOrder}
                  buyerInfo={buyerInfo}
                  setSelectedAttachOrder={setSelectedAttachOrder}
                  handleAttachOrder={(value) => handleAttachOrder(value)}
                />
              )}
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default ChatRoomBody;
