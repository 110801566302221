import {
  Checkbox,
  Flex,
  Text,
  Icon,
  Button,
  Input,
  Toggle,
  Tooltip,
  Box,
} from "renos-ui";
import { useState, useMemo } from "react";
import { StyledTable, ProductImage } from "../styled";
import PeriodPicker from "./PeriodPicker";
import { useHistory } from "react-router-dom";
import CustomHeader from "./CustomHeader";
import {
  findTotalChecked,
  handleChangeInput,
  isChecked,
  onCheckAllClicked,
  onCheckProductClicked,
  parentVariantIsChecked,
  handleDelete,
} from "../hooks/useTable";

const TableAdd = ({
  dataTable,
  setDataTable,
  setDefaultData,
  defaultData,
  dataError,
  setDataError,
}) => {
  const history = useHistory();
  const [checkedProducts, setCheckedProducts] = useState([]);

  const isCheckedAll = useMemo(
    () => findTotalChecked(checkedProducts) === dataTable.length,
    [checkedProducts, dataTable]
  );

  const isIndeterminate = useMemo(() => {
    if (checkedProducts) {
      const totalChecked = findTotalChecked(checkedProducts, true);
      let totalAllData = 0;
      // eslint-disable-next-line
      dataTable.map((product) => {
        totalAllData += product.product_skus.length;
      });

      return totalChecked > 0 && totalChecked < totalAllData;
    }

    return false;
  }, [checkedProducts, dataTable]);

  const uncheckAll = () => {
    setCheckedProducts([]);
  };

  const handleCheckAll = () => {
    onCheckAllClicked({ checkedProducts, dataTable, setCheckedProducts });
  };

  const tableColumns = [
    {
      title: (
        <Checkbox
          checked={isCheckedAll}
          onChange={handleCheckAll}
          indeterminate={isIndeterminate}
        />
      ),
      dataIndex: "check",
      key: "checkBox",
      render: (_, data, isVariant) => {
        const { product_id, product_sku_id, isHasMore } = data;

        return (
          <Checkbox
            checked={
              isHasMore
                ? parentVariantIsChecked(product_id, false, checkedProducts)
                : isChecked(
                    product_id,
                    isVariant,
                    product_sku_id,
                    checkedProducts
                  )
            }
            onChange={() =>
              onCheckProductClicked(
                data,
                isVariant,
                checkedProducts,
                setCheckedProducts,
                defaultData
              )
            }
            indeterminate={
              isHasMore &&
              parentVariantIsChecked(product_id, true, checkedProducts)
            }
          />
        );
      },
      width: "40px",
      align: "center",
    },
    {
      title: "Informasi Produk",
      dataIndex: "product_information",
      key: "product_information",
      render: (_, data, isVariant) => {
        const {
          product_image_url,
          product_id,
          product_name,
          store_sku_number,
          product_sku_mpn,
          product_stock,
          formatted_variant_value_name,
          formatted_product_price,
        } = data;

        return (
          <Flex alignItems="center" columnGap="8px">
            <ProductImage
              as="img"
              src={product_image_url}
              alt={`product - img - ${product_id}`}
              width="64px"
              height="64px"
              borderRadius="8px"
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = "/assets/images/empty-product.svg";
              }}
            />
            <Flex flexDirection="column" rowGap="4px">
              <Tooltip
                label={isVariant ? formatted_variant_value_name : product_name}
              >
                <Text weight="bold" lineClamp={1}>
                  {isVariant ? formatted_variant_value_name : product_name}
                </Text>
              </Tooltip>

              <Text variant="caption" color="black50">
                {formatted_product_price}
              </Text>

              <Flex gap="12px">
                <Text variant="caption" color="black50">
                  Stok: {product_stock || "-"}
                </Text>
                <Text variant="caption" color="black50">
                  SKU: {store_sku_number || product_sku_mpn || "-"}
                </Text>
              </Flex>
            </Flex>
          </Flex>
        );
      },
    },
    {
      title: "Diskon",
      dataIndex: "diskon",
      key: "diskon",
      width: "72px",
      render: (_, data, isVariant, isDataError) => {
        const {
          product_id,
          product_discount_percentage,
          product_discount_percentage_start,
          product_sku_id,
          isActive,
        } = data;
        const propertyName = isVariant
          ? "product_discount_percentage"
          : "product_discount_percentage_start";
        const discountValue = isVariant
          ? product_discount_percentage
          : product_discount_percentage_start;

        return (
          <Flex position="relative">
            <Input.Number
              disabled={isVariant && !isActive}
              max={100}
              size="small"
              isError={isDataError[propertyName]}
              onChange={(e) =>
                handleChangeInput(
                  e,
                  propertyName,
                  product_id,
                  isVariant,
                  product_sku_id,
                  dataError,
                  setDataError,
                  defaultData,
                  setDefaultData
                )
              }
              value={discountValue}
              sufix={
                <Text color="black50" weight="bold">
                  %
                </Text>
              }
            />
            {isDataError[propertyName] && (
              <Flex bottom="-24px" position="absolute">
                <Text color="red50" variant="caption">
                  {discountValue > 90 ? "Maksimal 90" : "Minimal 1."}
                </Text>
              </Flex>
            )}
          </Flex>
        );
      },
    },
    {
      title: (
        <Flex gap="4px" alignItems="center">
          <Text color="black50" weight="bold">
            Harga Diskon
          </Text>
          <Tooltip
            label={
              <Box textAlign="center">
                <Text variant="small" color="black75">
                  Harga produk setelah <br /> dipotong diskon.
                </Text>
              </Box>
            }
          >
            <Icon name="Info-solid" size={16} color="black50" />
          </Tooltip>
        </Flex>
      ),
      dataIndex: "hargaDiskon",
      key: "hargaDiskon",
      width: "160px",
      render: (_, data, isVariant, isDataError) => {
        const {
          product_id,
          product_discount_amount,
          product_discount_amount_start,
          product_sku_id,
          isActive,
          product_price,
          product_price_start,
        } = data;
        const propertyName = isVariant
          ? "product_discount_amount"
          : "product_discount_amount_start";
        const discountPrice = isVariant
          ? product_discount_amount
          : product_discount_amount_start;

        return (
          <Flex position="relative">
            <Input.Number
              // disabled={isVariant && !isActive} disable temporary
              disabled={true}
              onChange={(e) =>
                handleChangeInput(
                  e,
                  propertyName,
                  product_id,
                  isVariant,
                  product_sku_id,
                  dataError,
                  setDataError,
                  defaultData,
                  setDefaultData
                )
              }
              max={isVariant ? product_price : product_price_start}
              isError={isDataError[propertyName]}
              value={discountPrice || (!isVariant || isActive ? "0" : 0)}
              size="small"
              prefix={
                <Text color="black50" weight="bold">
                  Rp
                </Text>
              }
            />
            {isDataError[propertyName] && (
              <Flex bottom="-24px" position="absolute">
                <Text color="red50" variant="caption">
                  Harus diisi.
                </Text>
              </Flex>
            )}
          </Flex>
        );
      },
    },
    {
      title: (
        <Flex gap="4px" alignItems="center">
          <Text color="black50" weight="bold">
            Kuota
          </Text>
          <Tooltip
            label={
              <Box textAlign="center">
                <Text variant="small" color="black75">
                  Jumlah stok produk yang <br /> diberikan diskon.
                </Text>
              </Box>
            }
          >
            <Icon name="Info-solid" size={16} color="black50" />
          </Tooltip>
        </Flex>
      ),
      dataIndex: "kuota",
      key: "kuota",
      width: "64px",
      render: (_, data, isVariant, isDataError) => {
        const { product_id, product_discount_quota, product_sku_id, isActive } =
          data;
        const propertyName = "product_discount_quota";

        return (
          <Flex position="relative">
            <Input.Number
              disabled={isVariant && !isActive}
              max={9999}
              size="small"
              isError={isDataError[propertyName]}
              onChange={(e) =>
                handleChangeInput(
                  e,
                  propertyName,
                  product_id,
                  isVariant,
                  product_sku_id,
                  dataError,
                  setDataError,
                  defaultData,
                  setDefaultData
                )
              }
              value={product_discount_quota}
            />
            {isDataError[propertyName] && (
              <Flex bottom="-24px" position="absolute">
                <Text color="red50" variant="caption">
                  Minimal 1.
                </Text>
              </Flex>
            )}
          </Flex>
        );
      },
    },
    {
      title: (
        <Flex gap="4px" alignItems="center">
          <Text color="black50" weight="bold">
            Periode Diskon
          </Text>
          <Tooltip
            label={
              <Box textAlign="center">
                <Text variant="small" color="black75">
                  Atur waktu mulai dan <br /> berakhirnya diskon.
                </Text>
              </Box>
            }
          >
            <Icon name="Info-solid" size={16} color="black50" />
          </Tooltip>
        </Flex>
      ),
      dataIndex: "periodeDiskon",
      key: "periodeDiskon",
      width: "140px",
      render: (_, data, isVariant, isDataError) => {
        const {
          product_id,
          product_sku_id,
          isActive,
          product_discount_date_start,
          product_discount_date_end,
        } = data;
        const propertyName = "period";

        return (
          <Flex position="relative">
            <PeriodPicker
              value={{ product_discount_date_start, product_discount_date_end }}
              onChange={(e) =>
                handleChangeInput(
                  e,
                  propertyName,
                  product_id,
                  isVariant,
                  product_sku_id,
                  dataError,
                  setDataError,
                  defaultData,
                  setDefaultData
                )
              }
              disabled={isVariant && !isActive}
              isError={
                isDataError["product_discount_date_start"] ||
                isDataError["product_discount_date_end"]
              }
            />
            {(isDataError["product_discount_date_start"] ||
              isDataError["product_discount_date_end"]) && (
              <Flex bottom="-24px" position="absolute">
                <Text color="red50" variant="caption">
                  Harus diisi.
                </Text>
              </Flex>
            )}
          </Flex>
        );
      },
    },
    {
      title: (
        <Flex gap="4px" alignItems="center">
          <Text color="black50" weight="bold">
            Status
          </Text>
          <Tooltip
            label={
              <Box textAlign="center">
                <Text variant="small" color="black75">
                  Atur untuk aktif/ <br /> nonaktifkan promo <br /> pada produk.
                </Text>
              </Box>
            }
          >
            <Icon name="Info-solid" size={16} color="black50" />
          </Tooltip>
        </Flex>
      ),
      dataIndex: "status",
      key: "status",
      align: "center",
      width: "65px",
      render: (_, data, isVariant) => {
        const { product_id, product_sku_id, isActive } = data;
        const propertyName = "isActive";

        return (
          <Flex
            justifyContent="center"
            onClick={() =>
              handleChangeInput(
                !isActive,
                propertyName,
                product_id,
                isVariant,
                product_sku_id,
                dataError,
                setDataError,
                defaultData,
                setDefaultData
              )
            }
          >
            <Toggle checked={isActive} />
          </Flex>
        );
      },
    },
    {
      title: "Aksi",
      dataIndex: "cta",
      key: "cta",
      align: "center",
      width: "52px",
      render: (_, data) => {
        const { product_id: productId } = data;

        return (
          <Flex justifyContent="center">
            <Button
              variant="tertiary"
              size="small"
              onClick={() =>
                handleDelete({
                  productId,
                  dataTable,
                  setDataTable,
                  defaultData,
                  setDefaultData,
                  history,
                  dataError,
                  setDataError,
                })
              }
              icon={<Icon name="Trash-outline" size={18} color="black50" />}
            />
          </Flex>
        );
      },
    },
  ];

  return (
    <Flex>
      <StyledTable
        columns={tableColumns}
        dataError={dataError}
        dataSource={dataTable}
        width="100%"
        overflow="auto"
        customHeader={
          checkedProducts.length > 0 && (
            <CustomHeader
              selected={checkedProducts}
              isCheckedAll={isCheckedAll}
              onCheckAllClicked={handleCheckAll}
              isIndeterminate={isIndeterminate}
              uncheckAll={uncheckAll}
              defaultData={defaultData}
              setDefaultData={setDefaultData}
            />
          )
        }
      />
    </Flex>
  );
};

export default TableAdd;
