import { Divider, Flex, Skeleton, range } from "renos-ui";

const SkeletonNote = ({ itemShow = 1, showFilter = true }) => {
  return (
    <Flex flexDirection="column" width="100%">
      {showFilter && (
        <>
          <Flex p="16px" gap="16px" justifyContent="space-between">
            <Skeleton height="60px" width="100%" />
          </Flex>
          <Divider />
        </>
      )}
      {range(itemShow).map((item) => (
        <Flex
          key={`skeleton-rating-${item}`}
          gap="16px"
          pt="16px"
          px="16px"
          flexDirection="row"
        >
          <Flex width="100%" flexDirection="column" gap="8px" pt="4px">
            <Skeleton height="20px" width="100%" />
            <Skeleton height="15px" width="20%" />
          </Flex>
        </Flex>
      ))}
    </Flex>
  );
};

export default SkeletonNote;
