import { useEffect, useState } from "react";
import {
  Box,
  Flex,
  Text,
  colors,
  useUpload,
  Spinner,
  useToast,
  Icon,
} from "renos-ui";
import RespondError from "../../component/RespondError";
import RespondSuccess from "../../component/RespondSuccess";
import InitialInput from "../../component/InitialInput";
import client from "services/client";
import ENDPOINTS from "constants/endpoints";
import { goToUrl } from "helpers";

const ImageSelector = () => {
  const toast = useToast();
  const { register, result } = useUpload({
    fileType: "image",
  });

  const [imageFile, setImageFile] = useState();
  const [status, setStatus] = useState();
  const [failedData, setFailedData] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isWarning, setIsWarning] = useState(false);

  const handleCheckStatus = (uuid) => {
    client
      .get(`${ENDPOINTS.status_bulk_edit_template}/${uuid}`)
      .then((dataGet) => {
        setIsWarning(false);

        if (
          dataGet?.data?.data?.status !== "succeed" &&
          dataGet?.data?.data?.status !== "failed"
        ) {
          setTimeout(() => {
            handleCheckStatus(uuid);
            setIsLoading(true);
          }, 3000);
        } else if (dataGet?.data?.data?.status === "failed") {
          setStatus("error");
          setIsLoading(false);
          setFailedData(dataGet?.data?.data?.fail_file);
        } else {
          setStatus("success");
          setIsLoading(false);

          if (dataGet?.data?.data?.warning_file) {
            setIsWarning(true);
            goToUrl(dataGet?.data?.data?.warning_file);
          }
        }
      })
      .catch((error) => {
        toast({
          label:
            error?.response?.data?.front_end_message ||
            "Gagal mengunduh file excel.",
          placement: "top",
          backgroundColor: "red50",
        });
        setStatus("error");
        setFailedData("");
        setIsLoading(false);
      });
  };

  const handleDownload = async (data) => {
    client
      .post(ENDPOINTS.upload_bulk_edit_product, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((dataGet) => {
        handleCheckStatus(dataGet?.data?.data?.uuid);
        setIsLoading(true);
      })
      .catch((error) => {
        toast({
          label:
            error?.response?.data?.front_end_message ||
            "Gagal mengunduh file excel.",
          placement: "top",
          backgroundColor: "red50",
        });
        setStatus("error");
        setFailedData("");
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (imageFile) {
      const formData = new FormData();
      formData.append("file", imageFile);

      handleDownload(formData);

      // mutateUpload(formData);
    }

    // eslint-disable-next-line
  }, [imageFile]);

  useEffect(() => {
    setImageFile(result?.file);
  }, [result]);

  return (
    <Flex
      borderBottomLeftRadius="16px"
      borderBottomRightRadius="16px"
      boxShadow="0px 4px 4px rgba(20, 20, 32, 0.05)"
      backgroundColor="white"
      padding="24px"
      height={
        (status === "success" && isWarning) || !status ? "494px" : "398px"
      }
      gap="24px"
      flexDirection="column"
      justifyContent="space-between"
      width="488px"
    >
      <Flex gap="20px" alignItems="center">
        <Flex
          backgroundColor="violet5"
          alignItems="center"
          py="6px"
          px="14px"
          borderRadius="100px"
        >
          <Text variant="heading3" weight="bold" color="blue50">
            2
          </Text>
        </Flex>
        <Flex>
          <Text variant="heading3" weight="bold">
            Upload File Excel
          </Text>
        </Flex>
      </Flex>

      {((status === "success" && isWarning) || !status) && (
        <Flex
          p="12px 16px"
          borderRadius="8px"
          backgroundColor="yellow5"
          gap="8px"
          alignItems="center"
        >
          <Icon color="yellow50" name="Info-solid" size={24} />
          <Flex width="calc(100% - 32px)">
            <Text color="yellow100">
              {status
                ? `Ada beberapa produk yang gagal diupdate karena sedang berada dalam
                periode promosi atau diskon. Harap cek file download kamu untuk
                melihat daftar produk tersebut.`
                : `Produk yang sedang berada dalam periode promosi atau diskon tidak dapat diatur secara sekaligus.`}
            </Text>
          </Flex>
        </Flex>
      )}

      {isLoading === true ? (
        <Flex
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          py="39px"
          px="78px"
          gap="24px"
          height="286px"
          border={`1px dashed ${colors.black10}`}
          borderRadius="8px"
        >
          <Spinner size="72px" emptyColor="blue10" />

          <Flex gap="8px">
            <Text variant="title" weight="bold">
              Proses Uploading..
            </Text>
          </Flex>
          <Box textAlign="center">
            <Text variant="body" color="black50">
              File sedang di upload, harap menunggu.
            </Text>
          </Box>
        </Flex>
      ) : status === "error" ? (
        <RespondError register={register} linkError={failedData} />
      ) : status === "success" ? (
        <RespondSuccess register={register} isHasWarning={isWarning} />
      ) : (
        <InitialInput register={register} />
      )}
    </Flex>
  );
};

export default ImageSelector;
