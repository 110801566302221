import { Flex, Box } from "renos-ui";

const EmptyCard = () => {
  return (
    <Flex
      flexDirection="column"
      borderRadius="8px"
      boxShadow="0px 2px 4px 0px #14142026"
      width="128px"
    >
      <Flex
        height="128px"
        width="128px"
        backgroundColor="black10"
        background="url('/assets/images/store-layout/no-img.svg') no-repeat 50% 50%"
        borderTopLeftRadius="4px"
        borderTopRightRadius="4px"
      />

      <Flex
        flexDirection="column"
        gap="4px"
        p="12px"
        // height="154px"
        justifyContent="space-between"
      >
        <Flex flexDirection="column" gap="4px">
          <Box
            height="20px"
            width="100px"
            borderRadius="100px"
            backgroundColor="black10"
          />
          <Box
            height="20px"
            width="100px"
            borderRadius="100px"
            backgroundColor="black10"
          />
        </Flex>

        <Flex flexDirection="column" gap="4px">
          <Box
            height="20px"
            width="100px"
            borderRadius="100px"
            backgroundColor="black10"
          />
          <Box
            height="20px"
            width="100px"
            borderRadius="100px"
            backgroundColor="black10"
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default EmptyCard;
