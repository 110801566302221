import { Flex, Text, Box } from "renos-ui";

import useQueryParams from "hooks/useQueryParams";

const INCOMING_REQUEST = "incoming-request";

const EmptyList = () => {
  const query = useQueryParams();
  const activeTab = query.get("tab") || INCOMING_REQUEST;

  let title = "Belum ada penawaran";
  let description =
    "Belum ada penawaran yang kamu berikan kepada pembeli. Lihat tab “Permintaan Masuk”.";

  if (activeTab === INCOMING_REQUEST) {
    title = "Belum ada permintaan";
    description =
      "Permintaan produk dari pembeli akan tampil pada halaman ini.";
  }

  return (
    <Flex
      borderRadius="8px"
      boxShadow="0px 4px 4px rgba(20, 20, 32, 0.05)"
      backgroundColor="white"
      padding={40}
      justifyContent="center"
      alignItems="center"
      maxHeight={387}
      marginTop={24}
    >
      <Flex flexDirection="column" rowGap="40px" alignItems="center">
        <Box
          as="img"
          src="/assets/images/empty-bidding.svg"
          alt="empty-bidding"
        />
        <Flex flexDirection="column" alignItems="center" rowGap="24px">
          <Flex
            flexDirection="column"
            textAlign="center"
            rowGap="8px"
            width={328}
          >
            <Text variant="heading3" weight="bold">
              {title}
            </Text>
            <Text>{description}</Text>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default EmptyList;
