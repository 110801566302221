import React, { useContext, useEffect } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import desktopUrl from "constants/desktopUrl";
import Dashboard from "containers/web/dashboard";
import LandingPage from "containers/web/landing";
import NotFoundPage from "containers/web/error/not-found";
import Setting from "containers/web/setting";
import SettingSellerFleetList from "containers/web/setting/delivery/sellerFleetSetting/list";
import SettingSellerFleetAdd from "containers/web/setting/delivery/sellerFleetSetting/add";
import DashboardLayout from "layouts/DashboardLayout";
import LandingLayout from "layouts/LandingLayout";
import PrivateRoute from "widgets/global/PrivateRoute";
import Register from "containers/web/register";
import Notification from "containers/web/notification";
import ProductList from "containers/web/product/list";
import AddProductMultiple from "containers/web/product/add/multiple";
import Statistic from "containers/web/statistic";
import Showcase from "containers/web/product/showcase";
import ProductAdd from "containers/web/product/add/single";
import PlainLayout from "layouts/PlainLayout";
import RatingReview from "containers/web/sales/rating-review";
import StoreLayout from "containers/web/store-layout";
import OrderManagement from "containers/web/order-management";
import Tutorial from "containers/web/tutorial";
import TutorialLayout from "layouts/TutorialLayout";
import ChatRoom from "containers/web/chat/chat-room";
import ChatSetting from "containers/web/chat/chat-setting";
import PrintInvoice from "containers/web/order-management/List/Card/Header/PrintInvoice";
import PrintLayout from "layouts/PrintLayout";
import InternalError from "containers/web/error/internal-error";
import { QISCUS } from "hooks/qiscus";
// import Ewallet from "containers/web/e-wallet";
import PromoManagement from "containers/web/promo-management";
import AddPromo from "containers/web/promo-management/add";
import VoucherManagement from "containers/web/voucher-management";
import TopToast from "./TopToast";
import { OnlineStateContext } from "providers/OnlineStateProvider";
import OfflinePage from "containers/web/error/offline";
import VoucherManagementAdd from "containers/web/voucher-management/add";
import useCustomTour, { stepTourWeb, styles } from "hooks/useCustomTour";
import { TourProvider } from "@reactour/tour";
import AddTemplate from "containers/web/store-layout/AddTemplate";
import Bidding from "containers/web/bidding/list";
import DetailBidding from "containers/web/bidding/detail";

const DesktopRouter = () => {
  const { isOnline } = useContext(OnlineStateContext);
  const location = useLocation();
  const isDashboardPage = location.pathname === desktopUrl.DASHBOARD;

  useEffect(() => {
    QISCUS.init();
  }, []);

  return isOnline ? (
    <>
      {!isOnline && <TopToast />}
      <Switch>
        {/* route with landing layout */}
        <Route path={[desktopUrl.LANDING]}>
          <LandingLayout>
            <Switch>
              <PrivateRoute
                isLanding
                path={desktopUrl.LANDING}
                component={LandingPage}
              />
            </Switch>
          </LandingLayout>
        </Route>

        {/* route with dashboard layout */}
        <Route
          path={[
            desktopUrl.DASHBOARD,
            desktopUrl.SETTING,
            desktopUrl.NOTIFICATION,
            desktopUrl.PRODUCTS_LIST,
            desktopUrl.STATISTICS,
            desktopUrl.PRODUCTS_ADD_MULTIPLE,
            desktopUrl.SHOWCASE,
            desktopUrl.STORE_LAYOUT,
            desktopUrl.SETTING_LIST_SELLER_FLEET,
            desktopUrl.SALES_RATING_REVIEW,
            desktopUrl.ORDERS_LIST,
            desktopUrl.CHAT,
            desktopUrl.CHAT_SETTING,
            desktopUrl.WALLET,
            desktopUrl.DISCOUNT,
            desktopUrl.SETDISCOUNT,
            desktopUrl.VOUCHER,
            desktopUrl.BIDDING,
            desktopUrl.BIDDING_DETAIL,
          ]}
          exact
        >
          <DashboardLayout>
            {isDashboardPage && (
              <TourProvider
                steps={stepTourWeb}
                ContentComponent={useCustomTour}
                showDots
                styles={styles}
              >
                <PrivateRoute
                  path={desktopUrl.DASHBOARD}
                  component={Dashboard}
                  exact
                />
              </TourProvider>
            )}

            <PrivateRoute
              path={`${desktopUrl.SETTING}`}
              component={Setting}
              exact
            />
            <PrivateRoute
              path={`${desktopUrl.NOTIFICATION}`}
              component={Notification}
            />
            <PrivateRoute
              path={`${desktopUrl.PRODUCTS_LIST}`}
              component={ProductList}
            />
            <PrivateRoute
              path={`${desktopUrl.PRODUCTS_ADD_MULTIPLE}`}
              component={AddProductMultiple}
            />
            <PrivateRoute
              path={`${desktopUrl.STATISTICS}`}
              component={Statistic}
            />
            <PrivateRoute
              path={`${desktopUrl.STORE_LAYOUT}`}
              component={StoreLayout}
            />
            <PrivateRoute
              path={`${desktopUrl.SHOWCASE}`}
              component={Showcase}
              storeParams={{
                params: {
                  need: "total_product",
                },
                query: "get-store-detail-showcase",
              }}
            />
            <PrivateRoute
              path={`${desktopUrl.SETTING_LIST_SELLER_FLEET}`}
              component={SettingSellerFleetList}
              exact
            />
            <PrivateRoute
              path={`${desktopUrl.SALES_RATING_REVIEW}`}
              component={RatingReview}
            />

            <PrivateRoute
              path={`${desktopUrl.ORDERS_LIST}`}
              component={OrderManagement}
              exact
            />

            <PrivateRoute
              path={`${desktopUrl.CHAT}`}
              component={ChatRoom}
              withQiscus={true}
              exact
            />

            <PrivateRoute
              path={`${desktopUrl.CHAT_SETTING}`}
              component={ChatSetting}
              exact
            />
            {/* <PrivateRoute
              path={`${desktopUrl.WALLET}`}
              component={Ewallet}
              exact
            /> */}
            <PrivateRoute
              path={`${desktopUrl.DISCOUNT}`}
              component={PromoManagement}
              exact
            />
            <PrivateRoute
              path={`${desktopUrl.SETDISCOUNT}`}
              component={AddPromo}
              exact
            />
            <PrivateRoute
              path={`${desktopUrl.VOUCHER}`}
              component={VoucherManagement}
              exact
            />

            <PrivateRoute
              path={`${desktopUrl.BIDDING}`}
              component={Bidding}
              exact
            />
            <PrivateRoute
              path={`${desktopUrl.BIDDING_DETAIL}`}
              component={DetailBidding}
              exact
            />
          </DashboardLayout>
        </Route>

        {/* route without  layout */}
        <Route
          path={[
            desktopUrl.PRODUCTS_ADD,
            desktopUrl.SETTING_SELLER_FLEET_ADD,
            desktopUrl.VOUCHER_ADD,
            desktopUrl.STORE_LAYOUT_TEMPLATE,
          ]}
          exact
        >
          <PlainLayout>
            <PrivateRoute
              path={desktopUrl.PRODUCTS_ADD}
              component={ProductAdd}
            />
            <PrivateRoute
              path={`${desktopUrl.SETTING_SELLER_FLEET_ADD}`}
              component={SettingSellerFleetAdd}
              exact
            />
            <PrivateRoute
              path={`${desktopUrl.VOUCHER_ADD}`}
              component={VoucherManagementAdd}
              exact
            />
            <PrivateRoute
              path={`${desktopUrl.STORE_LAYOUT_TEMPLATE}`}
              component={AddTemplate}
              exact
            />
          </PlainLayout>
        </Route>

        <Route path={[desktopUrl.SELLER_TUTORIAL]} exact>
          <TutorialLayout>
            <PrivateRoute
              path={desktopUrl.SELLER_TUTORIAL}
              exact
              component={Tutorial}
            />
          </TutorialLayout>
        </Route>

        <Route path={[desktopUrl.PRINT_INVOICE]} exact>
          <PrintLayout>
            <PrivateRoute
              path={`${desktopUrl.PRINT_INVOICE}`}
              component={PrintInvoice}
              exact
            />
          </PrintLayout>
        </Route>

        <Route path={desktopUrl.REGISTER} exact component={Register} />

        <Route path={desktopUrl.ERROR_500} exact component={InternalError} />

        <Route path="*">
          <DashboardLayout>
            <PrivateRoute path="*" component={NotFoundPage} exact />
          </DashboardLayout>
        </Route>
      </Switch>
    </>
  ) : (
    <OfflinePage />
  );
};

export default DesktopRouter;
