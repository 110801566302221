import React from "react";
import { Flex, Text } from "renos-ui";

const ChatSidebarEmpty = () => {
  return (
    <Flex justifyContent="center" alignItems="center" height="100%">
      <Text variant="caption">Belum ada pesan</Text>
    </Flex>
  );
};

export default ChatSidebarEmpty;