import { Flex, Text, colors } from "renos-ui";

const SquareFour = ({ imageRef, variant, data, setData }) => {
  const handleSetEdit = (type) => {
    setData((prev) => ({ ...prev, active: type }));
  };

  const renderBorder = (type) => {
    return data?.active === type && `1px solid ${colors.violet50}`;
  };

  return (
    <Flex
      flexDirection="column"
      gap="8px"
      paddingTop="50px"
      position="relative"
    >
      <Flex flexDirection="column" position="absolute" left={-84}>
        <Text variant="caption" color="black50" weight="bold">
          Template {variant}
        </Text>
        <Text variant="caption" color="black50">
          Square Banner 1:1 (1080 x 1080px)
        </Text>
      </Flex>
      <Flex style={{ transform: "scale(1.7)" }} mt="150px">
        <Flex
          p="15px"
          width="209px"
          height="209px"
          background="#1833B4"
          ref={imageRef}
          position="relative"
        >
          <Flex
            position="absolute"
            as="img"
            alt="img"
            width="80px"
            height="68px"
            left={0}
            top={0}
            src="assets/images/store-layout/banner-1x1/confetti_left.svg"
          />
          <Flex
            position="absolute"
            as="img"
            alt="img"
            width="79px"
            height="68px"
            right={0}
            top={0}
            src="assets/images/store-layout/banner-1x1/confetti_right.svg"
          />
          <Flex
            position="absolute"
            width="126px"
            height="27px"
            top={0}
            left="57px"
            background={
              "url('assets/images/store-layout/banner-1x1/template_4_header.svg')"
            }
          >
            <Flex
              onClick={() => handleSetEdit("textEvent")}
              cursor="pointer"
              border={renderBorder("textEvent")}
              width="100%"
              alignItems="center"
              justifyContent="center"
              fontSize="18px"
              fontWeight={800}
              color="white"
            >
              {data.textEvent || "10.10!"}
            </Flex>
          </Flex>

          <Flex
            position="absolute"
            width="64px"
            height="13px"
            bottom="18px"
            right="18px"
            backgroundColor="yellow50"
          >
            <Flex
              onClick={() => handleSetEdit("textEventFooter")}
              cursor="pointer"
              border={renderBorder("textEventFooter")}
              alignItems="center"
              justifyContent="center"
              width="100%"
              fontSize="6px"
              fontWeight={700}
              color="black75"
            >
              {data.textEventFooter}
            </Flex>
          </Flex>

          <Flex
            as="img"
            alt="img"
            width="210px"
            height="210px"
            src={data.imgSquare}
            onClick={() => handleSetEdit("imgSquare")}
            cursor="pointer"
            border={renderBorder("imgSquare")}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default SquareFour;
