import { useContext, useState } from "react";
import { Flex, useDisclosure, useToast } from "renos-ui";
import {
  useEditAutoReply,
  useGetListAutoReply,
  useGetStoreOpenView,
  useToggleTemplate,
} from "services/chat";
import SkeletonBalasanOtomatis from "./SkeletonBalasanOtomatis";
import BalasanOtomatisItem from "./BalasanOtomatisItem";
import StoreContext from "providers/StoreProvider";
import InformationDrawer from "./components/InformationDrawer";
import FormDrawer from "./components/FormDrawer";
import CustomToast from "./components/CustomToast";

const BalasanOtomatis = () => {
  const toast = useToast();
  const [selectedItem, setSelectedItem] = useState(null);
  const formEditDisclosure = useDisclosure({ isOpen: false });
  const informationDisclosure = useDisclosure({ isOpen: false });
  const {
    storeData: {
      data: { id: storeId },
    },
  } = useContext(StoreContext);
  const [items, setItems] = useState([
    {
      templateType: 1,
      isActive: false,
      templateMessage: "",
      title: "Ucapan selamat datang",
      description:
        "Atur isi pesan selamat datang untuk menyapa pembeli di jam operasional",
    },
    {
      templateType: 2,
      isActive: false,
      templateMessage: "",
      title: "Pesan di luar jam operasional",
      description:
        "Pasang Balasan Otomatis saat toko libur atau di luar jam operasional dan <b>produk bisa dibeli</b>",
    },
    {
      templateType: 3,
      isActive: false,
      templateMessage: "",
      title: "Pesan saat toko sedang libur",
      description:
        "Pasang Balasan Otomatis saat toko libur dan <b>produk tidak bisa dibeli</b>",
    },
  ]);

  const { isLoading } = useGetListAutoReply({
    onSuccess: ({ data }) => {
      const temp = [...items];
      data?.forEach((item) => {
        const findIndex = temp.findIndex(
          (i) => i.templateType === item.template_type
        );

        if (findIndex !== -1) {
          temp[findIndex] = {
            ...temp[findIndex],
            isActive: item.is_active,
            templateMessage: item.template_message,
          };
        }
      });

      setItems(temp);
    },
  });

  const {
    data: storeOpenSchedule,
    mutate: getStoreOpenSchedule,
    isLoading: isLoadingStoreOpenSchedule,
  } = useGetStoreOpenView();

  const { mutate: mutateToggle } = useToggleTemplate({
    onError: () => {
      toast({
        label: "Gagal mengaktifkan template balasan",
        placement: "bottom",
        backgroundColor: "red50",
      });
    },
    onSuccess: () => {
      toast({
        placement: "bottom",
        render: () => (
          <CustomToast
            title={selectedItem?.title}
            message={selectedItem?.isActive ? "dinonaktifkan." : "diaktifkan."}
          />
        ),
      });
    },
  });

  const { mutate: editAutoReply, isLoading: isSaving } = useEditAutoReply({
    onSuccess: () => {
      formEditDisclosure.onClose();
      toast({
        placement: "bottom",
        render: () => (
          <CustomToast
            title={selectedItem?.title}
            message="berhasil diperbaharui."
          />
        ),
      });
    },
  });

  const handleToggleActivate = (item) => {
    setTimeout(() => {
      mutateToggle({
        type: item.templateType,
        is_active: !item.isActive,
        message: item.templateMessage,
      });
    });

    setSelectedItem(item);
    const temp = [...items];
    const findIndex = temp.findIndex(
      (tempItem) => tempItem.templateType === item.templateType
    );

    if (findIndex !== -1) {
      temp[findIndex] = {
        ...temp[findIndex],
        isActive: !item.isActive,
      };
    }
    setItems(temp);
  };

  const handleChangeItem = (index, value) => {
    const temp = [...items];
    temp[index] = {
      ...temp[index],
      templateMessage: value,
    };
    setItems(temp);
  };

  const handleClickSave = () => {
    const payload = {
      type: selectedItem?.templateType,
      is_active: selectedItem?.isActive,
      message: selectedItem?.templateMessage,
    };

    editAutoReply(payload);
  };

  const handleOpenInformation = (item) => {
    informationDisclosure.onOpen();
    setSelectedItem(item);
  };

  const handleCloseInformation = () => {
    informationDisclosure.onClose();
    setSelectedItem(null);
  };

  const handleOpenFormEdit = (item, index) => {
    if (item.templateType === 2) {
      getStoreOpenSchedule({ store_id: storeId });
    }
    setSelectedItem({ ...item, index });
    formEditDisclosure.onOpen();
  };

  const handleCloseFormEdit = () => {
    formEditDisclosure.onClose();
    setSelectedItem(null);
  };

  const handleChangeInput = (e) => {
    const { value } = e.target;

    setSelectedItem({
      ...selectedItem,
      templateMessage: value,
    });

    handleChangeItem(selectedItem?.index, value);
  };

  return (
    <Flex
      gap="16px"
      padding="16px"
      backgroundColor="black5"
      flexDirection="column"
    >
      {isLoading ? (
        <SkeletonBalasanOtomatis />
      ) : (
        <BalasanOtomatisItem
          items={items}
          handleOpenFormEdit={handleOpenFormEdit}
          handleToggleActivate={handleToggleActivate}
          handleOpenInformation={handleOpenInformation}
        />
      )}

      <InformationDrawer
        disclosure={informationDisclosure}
        item={selectedItem}
        onClose={handleCloseInformation}
      />

      <FormDrawer
        loading={isSaving}
        form={selectedItem}
        onSubmit={handleClickSave}
        onClose={handleCloseFormEdit}
        visible={formEditDisclosure.isOpen}
        storeInformation={{
          items: storeOpenSchedule,
          get: () => getStoreOpenSchedule({ store_id: storeId }),
          isLoading: isLoadingStoreOpenSchedule,
        }}
        handleChangeInput={handleChangeInput}
      />
    </Flex>
  );
};

export default BalasanOtomatis;
