import { useQuery, useMutation } from "react-query";
import ENDPOINTS from "constants/endpoints";
import client from "../client";

const getRatingFetcher = async (body) => {
  const { data } = await client.get(ENDPOINTS.ratings, body);

  return {
    data: data?.data?.product_ratings || [],
    pagination: data.pagination,
  };
};

export const useGetRating = (params, extraParams) => {
  return useQuery(["getRating", params], () => getRatingFetcher(params), {
    ...extraParams,
    keepPreviousData: true,
  });
};

const getRatingAverageFetcher = async (body) => {
  const { data } = await client.get(ENDPOINTS.rating_average, body);

  return data.data;
};

export const useGetRatingAverage = (params, extraParams) => {
  return useQuery(
    ["getRatingAverage", params],
    () => getRatingAverageFetcher(params),
    {
      ...extraParams,
      keepPreviousData: true,
    }
  );
};

const getReviewFetcher = async (body) => {
  const { data } = await client.get(ENDPOINTS.reviews, body);

  return data;
};

export const useGetReview = (params, extraParams) => {
  return useQuery(["getReview", params], () => getReviewFetcher(params), {
    ...extraParams,
    keepPreviousData: true,
  });
};

const getTemplateListFetcher = async (body) => {
  const { data } = await client.get(ENDPOINTS.review_reply_template, body);

  return data;
};

export const useGetListTemplate = (extraParams) =>
  useMutation((body) => getTemplateListFetcher(body), { ...extraParams });

const getDeleteReplyFetcher = async (body) => {
  const { data } = await client.delete(
    `${ENDPOINTS.review_reply_template_delete}/${body.id}`
  );

  return data;
};

export const useGetDeleteReply = (extraParams) =>
  useMutation((body) => getDeleteReplyFetcher(body), { ...extraParams });

const saveReplyTemplate = async (body) => {
  const { data } = await client.post(ENDPOINTS.review_reply_save, body);

  return data;
};

export const useSaveReplyTemplate = (extraParams) =>
  useMutation((body) => saveReplyTemplate(body), { ...extraParams });

const updateReplyTemplate = async (body) => {
  const { data } = await client.post(ENDPOINTS.review_reply, body);

  return data;
};

export const useUpdateReplyTemplate = (extraParams) =>
  useMutation((body) => updateReplyTemplate(body), { ...extraParams });
