import { Divider, Flex, range, Skeleton, Box } from "renos-ui";

const ModalLoading = () => {
  return (
    <Flex width="100%" flexDirection="column" gap="24px">
      <Flex flexDirection="column" gap="16px" width="100%">
        <Divider />
        <Flex flexDirection="column" gap="8px">
          <Skeleton height="24px" width="150px" />
          <Skeleton height="24px" width="150px" />
        </Flex>
        <Divider />
      </Flex>
      <Flex flexDirection="column">
        {range(3).map((_, indexSkeleton) => (
          <Flex key={`history-${indexSkeleton}`}>
            <Flex
              flexDirection="column"
              paddingRight="18px"
              width="80px"
              alignItems="flex-end"
              borderRightColor="black10"
              borderRightWidth="1px"
              borderRightStyle="dashed"
              position="relative"
              gap="4px"
              pb={indexSkeleton !== 2 && "24px"}
            >
              <Skeleton height="24px" width="80px" />
              <Skeleton height="24px" width="80px" />
              <Box
                position="absolute"
                backgroundColor="black25"
                height="8px"
                width="8px"
                borderRadius="50%"
                right="-4.4px"
              />
            </Flex>

            <Flex
              paddingLeft="18px"
              flexDirection="column"
              gap="4px"
              width="calc(100% - 80px)"
            >
              <Skeleton height="24px" width="200px" />
              <Skeleton height="24px" width="200px" />
            </Flex>
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
};

export default ModalLoading;
