import mobileUrl from "constants/mobileUrl";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Avatar, Box, Flex, Icon, Text, useDisclosure, Drawer } from "renos-ui";

const CustomHeader = ({
  user,
  online,
  typing,
  onlineStatus,
  attachment,
  handleDeleteChat,
}) => {
  const { push } = useHistory();
  const avatarSource =
    user.avatar && user.avatar !== "https://images.renos.id/"
      ? user.avatar
      : null;

  const disclosureDelete = useDisclosure({ isOpen: false });

  return (
    <Box
      p="16px"
      rowGap="16px"
      height="fit-content"
      flexDirection="column"
      boxShadow={"0 0 8px rgba(20, 20, 32, 0.1)"}
    >
      <Flex justifyContent="space-between">
        <Flex gap="16px" alignItems="center">
          <Icon
            cursor="pointer"
            onClick={() => {
              push({
                pathname: mobileUrl.CHAT,
                query: "",
                state: {
                  ...attachment.payload,
                  isStay: true,
                },
              });
            }}
            name="Back-outline"
            color="black50"
            size={24}
          />
          <Flex>
            <Avatar
              width="36px"
              height="36px"
              src={avatarSource}
              icon={<Icon name="Profile-outline" />}
            />
          </Flex>
          <Flex flexDirection="column">
            <Text
              weight="bold"
              color="black100"
              variant="caption"
              lineClamp={1}
            >
              {user.name}
            </Text>
            <Flex alignItems="center" gap="4px">
              <Text weight="bold" color="blue50" variant="caption">
                {typing
                  ? "Sedang mengetik..."
                  : online.isOnline
                  ? "Online"
                  : online.lastOnline
                  ? onlineStatus()
                  : ""}
              </Text>
              {online.isOnline && !typing && (
                <Flex
                  width="8px"
                  height="8px"
                  borderRadius="100px"
                  backgroundColor="blue50"
                />
              )}
            </Flex>
          </Flex>
        </Flex>
        <Flex columnGap="16px" alignItems="center">
          <Icon
            name="Vertical-outline"
            onClick={() => disclosureDelete.onOpen()}
          />
        </Flex>
      </Flex>

      <Drawer
        isVisible={disclosureDelete.isOpen}
        onClose={disclosureDelete.onClose}
        placement="bottom"
      >
        <Flex
          width="100%"
          fontFamily="sans-serif"
          flexDirection="column"
          rowGap="24px"
        >
          <Flex flexDirection="column" rowGap="8px" flexWrap="wrap">
            <Flex justifyContent="space-between" gap="8px" alignItems="end">
              <Flex gap="8px" alignItems="center">
                <Icon
                  name="Close-outline"
                  color="black50"
                  size="24"
                  onClick={disclosureDelete.onClose}
                />
                <Text variant="subtitle" weight="bold">
                  Lainnya
                </Text>
              </Flex>
            </Flex>

            <Text onClick={handleDeleteChat}>Hapus Chat</Text>
          </Flex>
        </Flex>
      </Drawer>
    </Box>
  );
};

export default CustomHeader;
