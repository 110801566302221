import { Flex, Input, Text } from "renos-ui";
import { StyledTextArea } from "./styled";

const InformationSection = ({
  values,
  setValues,
  errors,
  setErrors,
  disabled,
  isOngoing,
}) => {
  const handleChangeValue = (event) => {
    setValues({ ...values, [event.target.name]: event.target.value });
    setErrors({ ...errors, [event.target.name]: false });
  };

  return (
    <Flex>
      <Flex flexDirection="column" gap="4px" width="320px">
        <Text color="black75" weight="bold">
          Informasi Voucher
        </Text>
        <Text color="black50">
          Tambah keterangan agar vouchermu <br /> dapat dipahami oleh pembeli.
        </Text>
      </Flex>
      <Flex flexDirection="column" gap="24px" width="584px">
        <Input
          name="name"
          label="Nama Voucher"
          placeholder="Contoh: Promo Set Kerja"
          error="Nama voucher wajib diisi!"
          isError={errors.name}
          value={values.name || ""}
          onChange={(event) => handleChangeValue(event)}
          disabled={isOngoing || disabled}
          showCount
          maxLength={60}
        />
        <Flex flexDirection="column" gap="8px">
          <Text variant="caption" color="black50" weight="bold">
            Deskripsi Voucher
          </Text>
          <StyledTextArea
            // name="message"
            placeholder="Masukkan deskripsi vouchermu disini..."
            value={values.description || ""}
            showCount
            maxLength={300}
            onChange={(event) => handleChangeValue(event)}
            error="Deskripsi voucher wajib diisi!"
            isError={errors.description}
            name="description"
            disabled={isOngoing || disabled}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default InformationSection;
