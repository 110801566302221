import { Flex, Input, SelectInput, Text } from "renos-ui";
import { EMPTY_OPTION } from "../constants";
import { useContext } from "react";
import StoreLocationContext from "providers/StoreLocationProvider";

const FormLeftSide = () => {
  const {
    value,
    isError,
    setValue,
    setIsError,
    listProvince,
    listCity,
    listDistrict,
    listSubDistrict,
    handleChangevalue,
  } = useContext(StoreLocationContext);

  const provincies =
    listProvince?.data?.map((row) => ({
      label: row.province_name,
      value: row.province_id,
    })) || EMPTY_OPTION;
  const cities =
    listCity?.data?.map((row) => ({
      label: row.city_name,
      value: row.city_id,
    })) || EMPTY_OPTION;
  const districts =
    listDistrict?.data?.map((row) => ({
      label: row.district_name,
      value: row.district_id,
    })) || EMPTY_OPTION;
  const subDistrics =
    listSubDistrict?.data?.map((row) => ({
      label: row.lower_district_name,
      value: row.lower_district_id,
      postal_code: row.postal_code,
    })) || EMPTY_OPTION;

  return (
    <Flex flexGrow="1" rowGap="24px" flexDirection="column">
      <Input
        value={value.name}
        label="Nama Lokasi"
        showCount
        name="name"
        placeholder="Contoh: Gudang, Toko, Cabang, dll."
        maxLength={60}
        size="small"
        onChange={handleChangevalue}
        error="Nama Lokasi harus diisi."
        isError={isError.name}
      />
      <Input
        value={value.address}
        name="address"
        label="Alamat Toko"
        size="small"
        placeholder="Tulis alamat lengkap toko"
        onChange={handleChangevalue}
        error={
          value?.address && value?.address?.length < 15
            ? "Alamatmu terlalu pendek, minimal 15 karakter."
            : "Alamat harus diisi."
        }
        isError={isError.address}
      />
      <Flex flexDirection="column" rowGap="8px" width="100%">
        <Text weight="bold" variant="caption" color="black50">
          Provinsi
        </Text>
        <SelectInput
          size="small"
          options={provincies}
          value={{
            label: value.province_name,
            value: value.province_id,
          }}
          placeholder="Pilih Provinsi"
          onChange={(event) => {
            setValue({
              ...value,
              province_id: event.value,
              province_name: event.label,
              city_id: "",
              city_name: "",
              district_id: "",
              district_name: "",
              lower_district_id: "",
              lower_district_name: "",
              postal_code: "",
            });
            setIsError({
              ...isError,
              province_id: false,
            });
          }}
          hideSelectedOptions={true}
          isError={isError.province_id}
        />
        {isError.province_id && (
          <Text color="red50" variant="caption">
            Provinsi harus dipilih.
          </Text>
        )}
      </Flex>
      <Flex flexDirection="column" rowGap="8px">
        <Text weight="bold" variant="caption" color="black50">
          Kota/Kabupaten
        </Text>
        <SelectInput
          size="small"
          options={cities}
          value={{
            label: value.city_name,
            value: value.city_id,
          }}
          placeholder="Pilih Kota/Kabupaten"
          onChange={(event) => {
            setValue({
              ...value,
              city_id: event.value,
              city_name: event.label,
              district_id: "",
              district_name: "",
              lower_district_id: "",
              lower_district_name: "",
              postal_code: "",
            });
            setIsError({
              ...isError,
              city_id: false,
            });
          }}
          isDisabled={!value.province_id}
          hideSelectedOptions={true}
          isError={isError.city_id}
        />
        {isError.city_id && (
          <Text color="red50" variant="caption">
            Kota/Kabupaten harus dipilih.
          </Text>
        )}
      </Flex>
      <Flex flexDirection="column" rowGap="8px">
        <Text weight="bold" variant="caption" color="black50">
          Kecamatan
        </Text>
        <SelectInput
          size="small"
          options={districts}
          value={{
            label: value.district_name,
            value: value.district_id,
          }}
          placeholder="Pilih Kecamatan"
          onChange={(event) => {
            setValue({
              ...value,
              district_id: event.value,
              district_name: event.label,
              lower_district_id: "",
              lower_district_name: "",
              postal_code: "",
            });
            setIsError({
              ...isError,
              district_id: false,
            });
          }}
          isDisabled={!value.city_id}
          hideSelectedOptions={true}
          isError={isError.district_id}
        />
        {isError.district_id && (
          <Text color="red50" variant="caption">
            Kecamatan harus dipilih.
          </Text>
        )}
      </Flex>
      <Flex flexDirection="column" rowGap="8px">
        <Text weight="bold" variant="caption" color="black50">
          Kelurahan
        </Text>
        <SelectInput
          size="small"
          options={subDistrics}
          value={{
            label: value.lower_district_name,
            value: value.lower_district_id,
          }}
          placeholder="Pilih Kelurahan"
          onChange={(event) => {
            setValue({
              ...value,
              lower_district_id: event.value,
              lower_district_name: event.label,
              postal_code: event.postal_code,
            });
            setIsError({
              ...isError,
              lower_district_id: false,
            });
          }}
          isDisabled={!value.district_id}
          hideSelectedOptions={true}
          isError={isError.lower_district_id}
        />
        {isError.lower_district_id && (
          <Text color="red50" variant="caption">
            Kelurahan harus dipilih.
          </Text>
        )}
      </Flex>
    </Flex>
  );
};

export default FormLeftSide;