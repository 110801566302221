import { Dialog, Flex, Text, colors, Box, Divider } from "renos-ui";

const DialogDetail = ({ isOpen, onClose, coverageDetail }) => {
  return (
    <Dialog isVisible={isOpen} onClose={onClose} verticalCentered closable>
      <Flex
        width="100%"
        fontFamily="sans-serif"
        flexDirection="column"
        rowGap="24px"
      >
        <Text variant="heading3" weight="bold">
          Detail Informasi
        </Text>
        <Flex
          flexDirection="column"
          px="16px"
          border={`1px solid ${colors.black10}`}
          borderRadius="8px"
          height="264px"
        >
          <Flex py="12px" gap="24px">
            <Box width="104px">
              <Text variant="caption" weight="bold">
                Provinsi
              </Text>
            </Box>
            <Box width="145px">
              <Text variant="caption" weight="bold">
                Kota/Kabupaten
              </Text>
            </Box>
            <Box width="145px">
              <Text variant="caption" weight="bold">
                Kecamatan
              </Text>
            </Box>
            <Box width="145px">
              <Text variant="caption" weight="bold">
                Estimasi Tiba
              </Text>
            </Box>
          </Flex>
          <Divider />
          <Box height="calc(100% - 45px)" overflowX="auto">
            {coverageDetail?.map((coverage, indexCoverage) => (
              <Box key={`row-container${indexCoverage}`}>
                {indexCoverage !== 0 && <Divider />}
                <Flex padding="16px 0" gap="24px">
                  <Box width="104px">
                    <Text variant="caption">{coverage.province}</Text>
                  </Box>
                  <Box width="145px">
                    <Text variant="caption">{coverage.cities || "-"}</Text>
                  </Box>
                  <Box width="145px">
                    <Text variant="caption">{coverage.districts || "-"}</Text>
                  </Box>
                  <Box width="145px">
                    <Text variant="caption">
                      {coverage.estimated_shipping_days} hari kerja
                    </Text>
                  </Box>
                </Flex>
              </Box>
            ))}
          </Box>
        </Flex>
      </Flex>
    </Dialog>
  );
};

export default DialogDetail;
