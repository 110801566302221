import { Flex, Icon, useDisclosure } from "renos-ui";
import { StyledButton, StyledButtonIcon } from "../styled";
import PopupConfirm from "./PopupConfirm";
import PopupReject from "./PopupReject";

const ConfirmRejectNewOrder = ({
  data: { order_id: orderId },
  toFirstPage,
}) => {
  const disclosureConfirm = useDisclosure({ isOpen: false });
  const disclosureReject = useDisclosure({ isOpen: false });

  return (
    <Flex gap="8px" alignItems="center">
      <StyledButton size="small" onClick={disclosureConfirm.onOpen}>
        Terima Pesanan
      </StyledButton>
      <StyledButtonIcon
        variant="tertiary"
        size="small"
        onClick={disclosureReject.onOpen}
        icon={<Icon size={20} name="Vertical-outline" />}
      />

      <PopupConfirm
        disclosureConfirm={disclosureConfirm}
        toFirstPage={toFirstPage}
        orderId={orderId}
      />
      <PopupReject
        disclosureReject={disclosureReject}
        toFirstPage={toFirstPage}
        orderId={orderId}
      />
    </Flex>
  );
};

export default ConfirmRejectNewOrder;
