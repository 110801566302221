import { Drawer, Flex, Text, Button } from "renos-ui";

const DeleteDrawer = ({ disclosure, handleSave, handleCancelSave }) => {
  return (
    <Drawer
      isVisible={disclosure.isOpen}
      closable={true}
      onClose={handleCancelSave}
      placement="bottom"
    >
      <Flex flexDirection="column" width="100%" gap="24px">
        <Flex flexDirection="column" gap="8px">
          <Text variant="heading3" weight="bold">
            Ubah Nama Toko
          </Text>
          <Text>
            Kamu tidak dapat mengubah kembali nama tokomu setelah ini. Lanjut
            ubah?
          </Text>
        </Flex>
        <Flex justifyContent="space-between" gap="16px">
          <Button isBlock variant="tertiary" onClick={handleCancelSave}>
            Batal
          </Button>
          <Button onClick={handleSave} isBlock>
            Ya, Ubah
          </Button>
        </Flex>
      </Flex>
    </Drawer>
  );
};

export default DeleteDrawer;
