import ENDPOINTS from "constants/endpoints";
import { useQuery } from "react-query";
import client from "../client";

const getNewReview = async (params) => {
  const { data } = await client.get(ENDPOINTS.newest_review, {
    params,
  });

  return data.data;
};

const getNewOrder = async (params) => {
  const { data } = await client.get(ENDPOINTS.order_status, {
    params,
  });

  return data.data;
};

export const useGetNewReview = (params, extraParams) => {
  return useQuery(["getNewReview"], () => getNewReview(params), {
    ...extraParams,
  });
};

export const useGetNewOrder = (params, extraParams) => {
  return useQuery(["getNewOrder"], () => getNewOrder(params), {
    ...extraParams,
  });
};

const getChecklistStore = async () => {
  const { data } = await client.get(ENDPOINTS.store_checklist);

  return data.data;
};

export const useGetChecklistStore = (extraParams) => {
  return useQuery(["getChecklistStore"], getChecklistStore, {
    retry: false,
    ...extraParams,
  });
};
