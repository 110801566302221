import { useState } from "react";
import {
  Flex,
  Text,
  useUpload,
  Upload,
  Icon,
  Spinner,
  useDisclosure,
  useToast,
} from "renos-ui";
import { useUploadBanner } from "services/store-layout/banner";
import { StyledButtonBanner } from "./styled";
import BannerCreator from "./BannerCreator";
import { blobToBase64, getImageMeta, imageTypeChecking } from "helpers";

const Uploader = ({ name, value, setValue, isAllowDelete, deleteData }) => {
  const toast = useToast();
  const minWidth = value.mst_banner_image_type_id === 1 ? 1000 : 500;
  const minHeight = 500;

  const [showOverlay, setShowOverlay] = useState(false);
  const { isOpen, onClose, onOpen } = useDisclosure({ isOpen: false });
  const [uploadError, setUploadError] = useState({
    active: false,
    text: "Ups, file terlalu besar. Ukuran gambar harus kurang dari 2.1 MB",
  });

  const { mutate: uploadBanner, isLoading } = useUploadBanner({
    onSuccess: ({ data }) => {
      setShowOverlay(false);
      setValue({ ...value, ...data });
      setUploadError({
        active: false,
        text: "Ups, file terlalu besar. Ukuran gambar harus kurang dari 2 MB",
      });

      onClose();
    },
    onError: ({ response }) => {
      setShowOverlay(false);
      setUploadError({ active: true, text: response?.data?.frontend_message });
      toast({
        label: response?.data?.frontend_message || "Gagal upload gambar.",
        placement: "top",
        backgroundColor: "red50",
      });
    },
  });

  const handleUpload = (file) => {
    const formData = new FormData();
    // if (value.id) formData.append("id", value.id); //temporary until next info
    if (value.product_id) formData.append("product_id", value.product_id);
    if (value.etalase_id) formData.append("etalase_id", value.etalase_id);
    formData.append("file", file);
    formData.append("image_type", value.mst_banner_image_type_id);
    const maxAllowedSize = 2 * 1024 * 1024;

    if (file.size > maxAllowedSize) {
      setUploadError({
        active: true,
        text: "Ups, file terlalu besar. Ukuran gambar harus kurang dari 2 MB",
      });

      return;
    }
    uploadBanner(formData);
  };

  const { register } = useUpload({
    fileType: "image",
    onRequest: async (file) => {
      const result = await blobToBase64(file);
      const { naturalHeight, naturalWidth } = await getImageMeta(result);
      const allowedType = ["image/jpg", "image/jpeg", "image/png"];
      const deepTypeChecking = await imageTypeChecking(file);
      let errorMsg = "";

      if (naturalWidth < minWidth || naturalHeight < minHeight)
        errorMsg = `Ukuran minimal ${minWidth} x ${minHeight}px`;

      if (
        naturalWidth / naturalHeight !== 1 &&
        value.mst_banner_image_type_id !== 1
      )
        errorMsg = "Rasio gambar harus 1:1";
      else if (
        Math.floor(naturalWidth / naturalHeight) !== 2 &&
        value.mst_banner_image_type_id === 1
      )
        errorMsg = "Rasio gambar harus 2:1";

      if (!allowedType.includes(file.type) || !deepTypeChecking) {
        errorMsg = `Format gambar yang diperbolehkan jpg, jpeg & png`;
      }

      if (errorMsg) {
        toast({
          label: errorMsg,
          placement: "top",
          backgroundColor: "red50",
        });

        return;
      }

      handleUpload(file);
    },
  });

  const handleDelete = () => {
    const tempValue = value;
    delete tempValue.id;
    delete tempValue.image_path;
    delete tempValue.product_id;
    setValue(tempValue);
  };

  return (
    <Flex flexDirection="column" gap="8px" width="100%">
      <Flex gap="16px" alignItems="center">
        <Text weight="bold" variant="caption" color="black50">
          {name}
        </Text>
        {isAllowDelete && (
          <Flex cursor="pointer" onClick={deleteData}>
            <Text weight="bold" variant="small" color="blue50">
              Hapus
            </Text>
          </Flex>
        )}
      </Flex>

      {isLoading ? (
        <Flex
          height="160px"
          borderRadius="8px"
          borderWidth="1px"
          borderStyle="dashed"
          borderColor="black10"
          alignItems="center"
          justifyContent="center"
        >
          <Spinner size="24px" color="blue50" />
        </Flex>
      ) : uploadError.active ? (
        <Flex
          padding="26px"
          borderRadius="8px"
          borderWidth="1px"
          borderStyle="dashed"
          borderColor="black10"
          maxHeight="160px"
          alignItems="center"
          flexDirection="column"
          gap="16px"
        >
          <Flex
            alignItems="center"
            width="208px"
            textAlign="center"
            flexDirection="column"
            gap="8px"
          >
            <Icon name="Close-solid" size={32} color="red50" />
            <Text variant="caption" color="black50">
              {uploadError.text}
            </Text>
          </Flex>

          <Flex gap="16px" justifyContent="center">
            <Upload {...register} accept="image/jpg, image/jpeg, image/png">
              <StyledButtonBanner variant="secondary" size="small">
                Upload Lagi
              </StyledButtonBanner>
            </Upload>
            <StyledButtonBanner size="small" onClick={onOpen}>
              Banner Kreator
            </StyledButtonBanner>
          </Flex>
        </Flex>
      ) : value?.image_path ? (
        <Flex
          width="100%"
          position="relative"
          onMouseOver={() => setShowOverlay(true)}
          onMouseLeave={() => setShowOverlay(false)}
          justifyContent="center"
        >
          {value.mst_banner_image_type_id === 1 ? (
            <Flex
              as="img"
              alt="img-banner"
              src={value?.image_path}
              borderRadius="8px"
              height="162px"
              width="100%"
            />
          ) : (
            <Flex
              as="img"
              alt="img-banner"
              src={value?.image_path}
              borderRadius="8px"
              height="162px"
              width="162px"
            />
          )}

          <Flex
            backgroundColor="overlay75"
            width="100%"
            height="162px"
            position="absolute"
            borderRadius="8px"
            alignItems="center"
            justifyContent="center"
            gap="8px"
            zIndex={showOverlay ? 5 : -99}
          >
            <Upload {...register} accept="image/jpg, image/jpeg, image/png">
              <Icon
                name="Upload-outline"
                size={24}
                color="white"
                cursor="pointer"
              />
            </Upload>
            <Icon
              name="Edit-outline"
              size={24}
              color="white"
              cursor="pointer"
              onClick={onOpen}
            />
            <Icon
              name="Trash-outline"
              size={24}
              color="white"
              cursor="pointer"
              onClick={handleDelete}
            />
          </Flex>
        </Flex>
      ) : (
        <Flex
          padding="26px"
          borderRadius="8px"
          borderWidth="1px"
          borderStyle="dashed"
          borderColor="black10"
          maxHeight="160px"
          alignItems="center"
          flexDirection="column"
          gap="16px"
        >
          <Flex alignItems="center" width="208px" textAlign="center">
            <Text variant="caption" color="black50">
              {`Upload file dengan format JPG, JPEG, atau PNG. Ukuran min. ${minWidth} x ${minHeight} px dengan file maks. 2 MB`}
            </Text>
          </Flex>
          <Flex gap="16px" justifyContent="center">
            <Upload {...register} accept="image/jpg, image/jpeg, image/png">
              <StyledButtonBanner variant="secondary" size="small">
                Upload Banner
              </StyledButtonBanner>
            </Upload>
            <StyledButtonBanner size="small" onClick={onOpen}>
              Banner Kreator
            </StyledButtonBanner>
          </Flex>
        </Flex>
      )}

      <BannerCreator
        {...{
          isOpen,
          onClose,
          handleUpload,
          isLoading,
          isSquare: value.mst_banner_image_type_id !== 1,
        }}
      />
    </Flex>
  );
};

export default Uploader;
