import { Box, Button, Flex, Text, Upload } from "renos-ui";

const InitialInput = ({ register, isLoading }) => {
  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      py="39px"
      px="78px"
      gap="24px"
      height="286px"
      borderWidth="1px"
      borderColor="black10"
      borderStyle="dashed"
      borderRadius="8px"
    >
      <Box
        alt="excel-image"
        as="img"
        src="/assets/images/add-multiple/excel.svg"
      />
      <Box textAlign="center">
        <Text variant="body" color="black50">
          Pastikan format file .xls atau .xlsx dan nama file{" "}
          <b>tidak diubah.</b>
        </Text>
      </Box>
      <Flex>
        <Upload {...register} accept=".xlsx, .xls, .csv">
          <Box width="160px">
            <Button isBlock variant="secondary" disabled={isLoading}>
              Pilih File
            </Button>
          </Box>
        </Upload>
      </Flex>
    </Flex>
  );
};

export default InitialInput;
