import styled from "styled-components";
import { Button, Input, colors } from "renos-ui";

export const StyledTextArea = styled(Input.TextArea)`
  resize: none;
`;

export const StyledButton = styled(Button)`
  :disabled {
    span {
      color: ${colors.black25};
    }
  }
`;

export const StyledInput = styled.input`
  box-sizing: border-box;
  width: 100%;
  line-height: 20px;
  padding: 12px 16px;
  border-radius: 8px;
  border: 1px solid
    ${(props) => (props.isError ? colors.red50 : colors.black25)};
  color: #333346;
  appearance: none;
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &::placeholder {
    color: ${colors.black25};
    font-size: "14px";
  }
  &:focus,
  &:hover {
    border-color: ${(props) => (props.isError ? colors.red50 : colors.blue50)};
    outline: none;
  }
`;
