import { useState, useMemo } from "react";
import {
  Button,
  Flex,
  Icon,
  Input,
  Text,
  isEmpty,
  useDebounce,
  Label,
} from "renos-ui";
import {
  StyledCardOrder,
  StyledDialog,
  WrapCard,
  StyledSelectInput,
} from "./styled";
import { useGetOrderv3 } from "services/order-management";
import InfiniteScroll from "widgets/global/InfiniteScroll";
import OrderSkeleton from "./OrderSkeleton";

const OrderList = ({ disclosure, handleAttachOrder, buyerInfo }) => {
  const { isOpen, onClose } = disclosure;
  const [searchValue, setSearchValue] = useState("");
  const [items, setItems] = useState([]);
  const [dataFilter, setDataFilter] = useState({
    items_per_page: 10,
    page: 1,
    query: "",
    sort_type: "",
    order_status: "",
    user_id: buyerInfo?.user_id,
  });
  const [tempSelected, setTempSelected] = useState({});
  const {
    data: dataList,
    isLoading,
    isError,
    isFetching,
    refetch,
  } = useGetOrderv3(dataFilter, {
    onSuccess: (data) => {
      if (dataFilter.page === 1) {
        setItems(data.data);
      } else {
        setItems([...items.concat(data.data)]);
      }
    },
  });

  const dataSort = [
    {
      label: "Terbaru",
      value: "desc",
    },
    {
      label: "Terlama",
      value: "asc",
    },
  ];

  const dataStatus = [
    {
      label: "Semua",
      value: "",
    },
    {
      label: "Pesanan Baru",
      value: 2,
    },
    {
      label: "Belum Dikirim",
      value: 3,
    },
    {
      label: "Dikirim",
      value: 4,
    },
    {
      label: "Selesai",
      value: 6,
    },
    {
      label: "Dibatalkan",
      value: 7,
    },
  ];

  const badgeColor = (orderStatusId) => {
    switch (orderStatusId) {
      case 2:
      case 3:
      case 4:
        return "warning";
      case 7:
        return "error";
      case 6:
        return "success";
      default:
        return "";
    }
  };

  useDebounce(
    () =>
      setDataFilter({
        ...dataFilter,
        query: searchValue,
        page: 1,
      }),
    [searchValue],
    500
  );

  const hasNextPage = useMemo(
    () => dataList?.paginator?.has_more_pages,
    [dataList]
  );

  const isOrderLoading = useMemo(
    () => (isLoading || isFetching) && dataFilter.page === 1,
    // eslint-disable-next-line
    [isLoading, isFetching]
  );

  const handleOrderChange = (name) => {
    switch (name.label) {
      case "Terlama":
      case "Terbaru":
        setDataFilter({
          ...dataFilter,
          sort_type: name.value,
          page: 1,
        });
        break;
      default:
        break;
    }
  };

  const handleOrderStatus = (name) => {
    switch (name.label) {
      case "Semua":
      case "Pesanan Baru":
      case "Belum Dikirim":
      case "Dikirim":
      case "Selesai":
      case "Dibatalkan":
        setDataFilter({
          ...dataFilter,
          order_status: name.value,
          page: 1,
        });
        break;
      default:
        break;
    }
  };

  return (
    <StyledDialog
      onClose={onClose}
      isVisible={isOpen}
      verticalCentered
      width="669px"
      height="664px"
    >
      <Flex flexDirection="column" padding="16px" width="100%" gap="32px">
        <Flex justifyContent="space-between" alignContent="center">
          <Flex
            alignContent="center"
            justifyItems="center"
            alignItems="center"
            gap="8px"
          >
            <Text variant="heading3" weight="bold">
              Lampirkan Pesanan
            </Text>
          </Flex>
          <Icon
            name="Close-outline"
            cursor="pointer"
            size={24}
            color="black60"
            onClick={onClose}
          />
        </Flex>

        <Flex gap="16px">
          <Input
            placeholder="Cari nama pembeli, produk, nomor resi"
            prefix={<Icon name="Search-outline" size={20} color="black50" />}
            onChange={(event) => setSearchValue(event.target.value)}
          />

          <Flex flexBasis="34%" width="100%">
            <StyledSelectInput
              placeholder="Urutkan"
              options={dataSort}
              onChange={(name) => handleOrderChange(name)}
            />
          </Flex>
          <Flex flexBasis="34%" width="100%">
            <StyledSelectInput
              placeholder="Status"
              options={dataStatus}
              onChange={(name) => handleOrderStatus(name)}
            />
          </Flex>
        </Flex>

        {isOrderLoading ? (
          <OrderSkeleton />
        ) : isError ? (
          <Text onClick={refetch}>error</Text>
        ) : isEmpty(items) ? (
          <Flex
            width="100%"
            height="100%"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <Flex
              as="img"
              height="200px"
              width="200px"
              alt="no-order"
              src="/assets/images/mobile/no-order.svg"
            />
            <Flex
              flexDirection="column"
              gap="4px"
              alignItems="center"
              textAlign="center"
            >
              <Text weight="bold">Belum ada pesanan di tokomu</Text>
              <Text variant="caption" color="black75">
                Pesanan dari pembeli akan ditampilkan <br />
                di halaman ini.
              </Text>
            </Flex>
          </Flex>
        ) : (
          <>
            <Flex
              overflow="auto"
              flexDirection="column"
              // gap="16px"
              border="1px solid"
              borderRadius="8px"
              borderColor="black10"
              maxHeight="calc(100vh - 310px)"
            >
              <InfiniteScroll
                page={dataFilter.page}
                onChange={() => {
                  setDataFilter((prev) => ({ ...prev, page: prev.page + 1 }));
                }}
                isLoading={isLoading}
                loader={<OrderSkeleton itemsShow={1} />}
                hasNextPage={hasNextPage && !isOrderLoading}
              >
                {items?.map((item, index) => (
                  <WrapCard
                    key={index}
                    isSelected={
                      tempSelected?.order_number === item.order_number
                    }
                  >
                    <StyledCardOrder onClick={() => setTempSelected(item)}>
                      <Flex
                        as="img"
                        alt="img-order"
                        width="67px"
                        height="67px"
                        borderRadius="8px"
                        src={item.products[0]?.product_image_url}
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = "/assets/images/empty-product.svg";
                        }}
                      />
                      <Flex flexDirection="column" width="312px">
                        <Text color="black50">INV/{item.order_number}</Text>
                        <Text lineClamp={1}>
                          {item.products[0]?.product_name}
                        </Text>
                        <Text weight="bold">{item.formatted_total_price}</Text>
                      </Flex>
                      <Flex position="absolute" right="20px">
                        <Label variant={badgeColor(item.order_status_id)}>
                          {item.order_status_name}
                        </Label>
                      </Flex>
                    </StyledCardOrder>
                  </WrapCard>
                ))}
              </InfiniteScroll>
            </Flex>
            <Flex position="absolute" bottom="10px" width="95%">
              <Button
                isBlock
                size="medium"
                disabled={isEmpty(tempSelected)}
                variant="primary"
                onClick={() => {
                  if (!isEmpty(tempSelected)) {
                    handleAttachOrder(tempSelected);
                  }
                }}
              >
                Lampirkan
              </Button>
            </Flex>
          </>
        )}
      </Flex>
    </StyledDialog>
  );
};

export default OrderList;
