import { useEffect } from "react";
import { useHistory } from "react-router-dom";

import useQueryParams from "hooks/useQueryParams";
import desktopUrl from "constants/desktopUrl";
import TopBar from "widgets/web/bidding/list/TopBar";
import IncomingRequest from "widgets/web/bidding/list/IncomingRequest";
import MyOffer from "widgets/web/bidding/list/MyOffer";

const INCOMING_REQUEST_SORT_OPTIONS = [
  {
    label: "Terbaru",
    value: "desc",
  },
  {
    label: "Terlama",
    value: "asc",
  },
];

const MY_OFFER_SORT_OPTIONS = [
  { label: "Semua Status", value: "" },
  {
    label: "Menunggu Response",
    value: 1,
    name: "waiting",
  },
  {
    label: "Dalam Negosiasi",
    name: "negotiated",
    value: 4,
  },
  {
    label: "Diterima",
    name: "accepted",
    value: 2,
  },
  {
    label: "Dibayar",
    name: "paid",
    value: 6,
  },
  {
    label: "Ditolak",
    name: "rejected",
    value: 3,
  },
];

const INCOMING_REQUEST_TAB = {
  label: "Permintaan Masuk",
  key: "incoming-request",
  sortOptions: INCOMING_REQUEST_SORT_OPTIONS,
};

const MY_OFFER_TAB = {
  label: "Penawaran Saya",
  key: "my-offer",
  sortOptions: MY_OFFER_SORT_OPTIONS,
};

const TABS = {
  [INCOMING_REQUEST_TAB.key]: INCOMING_REQUEST_TAB,
  [MY_OFFER_TAB.key]: MY_OFFER_TAB,
};

const Bidding = () => {
  const { push } = useHistory();

  const query = useQueryParams();
  const activeTab = query.get("tab") || INCOMING_REQUEST_TAB.key;

  useEffect(() => {
    const isValidActiveTab = Object.values(TABS)
      .map(({ key }) => key)
      .includes(activeTab);

    if (!isValidActiveTab) {
      push(`${desktopUrl.BIDDING}?tab=${INCOMING_REQUEST_TAB.key}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  return (
    <>
      <TopBar tabs={TABS} />
      {activeTab === INCOMING_REQUEST_TAB.key ? (
        <IncomingRequest />
      ) : (
        <MyOffer />
      )}
    </>
  );
};

export default Bidding;
