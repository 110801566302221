import React from "react";
import { Box, Flex, Text } from "renos-ui";
import { translateDay } from "helpers";

const ListOpenStore = ({ day, time }) => {
  let renderDay;

  if (typeof day === "string") {
    renderDay = translateDay(day);
  } else {
    renderDay = `${translateDay(day[0])} - ${translateDay(
      day[day.length - 1]
    )}`;
  }

  let renderTime;
  const fullDay = "00:00 - 23:00";

  if (time === fullDay) {
    renderTime = "Buka 24 Jam";
  } else {
    renderTime = time;
  }

  return (
    <Flex gap="16px">
      <Box minWidth="140px">
        <Text variant="caption" color="black75">
          {renderDay}
        </Text>
      </Box>
      <Text variant="caption" color="black75">
        :
      </Text>
      <Text variant="caption" color="black75">
        {renderTime}
      </Text>
    </Flex>
  );
};

export default ListOpenStore;
