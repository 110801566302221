import { useState, useContext, Fragment, useRef, useEffect } from "react";
import {
  Flex,
  useDisclosure,
  Divider,
  useToast,
  Text,
  Avatar,
  Progress,
  Pagination,
  Button,
  isEmpty,
  Input,
  Dropdown,
  Icon,
} from "renos-ui";
import EmptyList from "./EmptyList";
import FilterReview from "./FilterReview";
import StoreContext from "providers/StoreProvider";
import { FilterRatingProvider } from "providers/FilterRatingReview";
import {
  useGetDeleteReply,
  useGetListTemplate,
  useGetReview,
  useSaveReplyTemplate,
  useUpdateReplyTemplate,
} from "services/sales";
import dayjs from "dayjs";
import SkeletonReview from "./SkeletonReview";
import ReportReview from "./ReportReview";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Wrapper } from "./styled";

const Review = () => {
  const toast = useToast();
  const { push } = useHistory();
  const filterDisclosure = useDisclosure({ open: false });
  const showReport = useDisclosure({ open: false });
  const containerRef = useRef();
  const {
    storeData: {
      data: { id: storeId },
    },
  } = useContext(StoreContext);
  const [dataState, setDataState] = useState([]);
  const [selectedReport, setSelectedReport] = useState({});
  const [listTemplate, setListTemplate] = useState([]);
  const [pagination, setPagination] = useState({});
  const [filters, setFilters] = useState({
    store_ids: storeId,
    include_pagination: true,
    ordering: "desc",
    scores_in: null,
    page: 1,
    order_by: "created_date",
  });
  const {
    data,
    isFetching,
    isError,
    refetch: refetchReview,
  } = useGetReview(
    {
      params: filters,
    },
    {
      onError: (error) => {
        const {
          response: {
            data: { frontend_message: message },
          },
        } = error;

        toast({
          label: message | "Ada masalah dari server.",
          placement: "top",
          backgroundColor: "red50",
        });
      },
      onSuccess: ({ data, pagination }) => {
        setPagination(pagination);
        setDataState(data);
        setFilters({
          ...filters,
          page: pagination?.current_page,
        });
      },
    }
  );

  const handleShowTransaction = (orderNumber) => {
    push(`/sales/orders?query=${orderNumber}`);
  };

  const { mutate: getListTemplate } = useGetListTemplate({
    onError: (error) => {
      const {
        response: {
          data: { frontend_message: message },
        },
      } = error;
      toast({
        label: message | "Error get list template.",
        placement: "top",
        backgroundColor: "red50",
      });
    },
    onSuccess: ({ data }) => {
      setListTemplate(data);
    },
  });

  const { mutate: deleteReply } = useGetDeleteReply({
    onSuccess: () => {
      toast({
        label: "Balasan berhasil dihapus",
        placement: "top",
      });
      refetchReview();
    },
    onError: (data) => {
      const {
        response: {
          data: { frontend_message, message },
        },
      } = data;
      toast({
        label: frontend_message || message,
        placement: "top",
        backgroundColor: "red50",
      });
    },
  });

  const { mutate: saveReply } = useSaveReplyTemplate({
    onError: (data) => {
      const {
        response: {
          data: { frontend_message, message },
        },
      } = data;
      toast({
        label: frontend_message || message,
        placement: "top",
        backgroundColor: "red50",
      });
    },
    onSuccess: () => {
      refetchReview();
      toast({
        label: "Balasan berhasil dikirim",
        placement: "top",
      });
    },
  });

  const { mutate: updateReply } = useUpdateReplyTemplate({
    onError: (data) => {
      const {
        response: {
          data: { frontend_message, message },
        },
      } = data;
      toast({
        label: frontend_message || message,
        placement: "top",
        backgroundColor: "red50",
      });
    },
    onSuccess: () => {
      refetchReview();
      toast({
        label: "Balasan berhasil dikirim",
        placement: "top",
      });
    },
  });

  const handleSaveReply = (index) => {
    const item = dataState[index];

    if (item.tempReply.length > 1000) {
      toast({
        label: "Balasan tidak boleh lebih dari 1000 karakter",
        placement: "top",
        backgroundColor: "red50",
      });

      return;
    }

    const data = {
      message: item.tempReply.trim(),
      primary: false,
      product_review_id: item.id,
    };

    if (item.messages.length > 1) {
      data.id = item?.messages?.find(
        (message) => message.primary === false
      )?.id;

      updateReply({ data });
    } else {
      saveReply({ data });
    }
  };

  const handleCancelReply = (index) => {
    const tempReply = dataState;
    tempReply[index].editState = false;
    tempReply[index].tempReply =
      tempReply[index].messages.length > 1
        ? tempReply[index].messages?.find(
            (message) => message.primary === false
          )?.message
        : "";
    setDataState([...tempReply]);
  };

  const handleShowReplyContainer = (index) => {
    const tempReply = dataState;

    if (isEmpty(dataState)) {
      // eslint-disable-next-line no-const-assign
      tempReply = data;
    }
    getListTemplate({ params: { store_id: storeId } });
    tempReply[index].editState = true;
    tempReply[index].tempReply =
      tempReply[index].messages.length > 1
        ? tempReply[index].messages.find((message) => message.primary === false)
            .message
        : "";
    setDataState([...tempReply]);
  };

  const handleSetTemplate = (value, index) => {
    const tempReply = dataState;
    const limit = 1000;
    const data = (tempReply[index].tempReply += ` ${value}`);

    if (data.length < limit) {
      tempReply[index].tempReply += ` ${value}`;
      setDataState([...tempReply]);
    } else {
      <></>;
    }
  };

  const handleShowReport = (index) => {
    showReport.onOpen();
    setSelectedReport(dataState[index]);
  };

  const resetFilter = () => {
    setFilters({
      store_ids: storeId,
      include_pagination: true,
      ordering: "desc",
      scores_in: null,
      page: 1,
      order_by: "created_date",
    });
  };

  useEffect(() => {
    containerRef.current.scrollIntoView({
      behavior: "smooth",
    });
  }, [pagination]);

  const findAndGet = (data, key) => {
    if (data?.length > 1) {
      const selected = data.find((message) => message.primary === false);

      return selected ? selected[key] : "";
    }

    return "";
  };

  return (
    <FilterRatingProvider
      value={{
        filters,
        setFilters,
        resetFilter,
      }}
    >
      <Flex
        ref={containerRef}
        flexDirection="column"
        gap="1rem"
        paddingTop="16px"
      >
        <Flex flexDirection="column" gap="16px">
          <FilterReview filterDisclosure={filterDisclosure} />
          <Divider />
        </Flex>
        {isError ? (
          <Text>Error get list review</Text>
        ) : isFetching ? (
          <SkeletonReview />
        ) : (
          <Fragment>
            {data?.data?.length === 0 ? (
              <EmptyList
                title="Belum Ada Ulasan"
                description="Ulasan produkmu dari pembeli akan muncul di halaman ini"
              />
            ) : (
              data?.data?.map((row, index) => (
                <Fragment key={row.id}>
                  <Flex justifyContent="flex-start" gap="24px">
                    <Flex flexDirection="column" gap="8px" width="376px">
                      <Flex gap="8px">
                        <Text variant="caption" weight="bold">
                          {dayjs(row.created_date).format("MMM")}
                        </Text>
                        <Flex marginTop="-4px">
                          <Text color="black25" variant="heading2">
                            &#x2022;
                          </Text>
                        </Flex>
                        <Text variant="caption" color="black50">
                          {dayjs(row.created_date).format("D MMM YYYY")}
                        </Text>
                      </Flex>
                      <Flex gap="10px">
                        <Flex>
                          <Flex
                            borderRadius="8px"
                            height="56px"
                            width="56px"
                            as="img"
                            alt="product-img"
                            src={encodeURI(row?.product?.product_image_url)}
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = "/assets/images/empty-product.svg";
                            }}
                          />
                        </Flex>
                        <Text variant="caption" lineClamp={10}>
                          {row?.product?.product_name}
                        </Text>
                      </Flex>
                      <Text
                        color="blue50"
                        weight="bold"
                        variant="caption"
                        onClick={() =>
                          handleShowTransaction(row?.order?.order_number)
                        }
                      >
                        Lihat Transaksi
                      </Text>
                    </Flex>
                    <Divider orientation="vertical" />
                    <Flex
                      flexDirection="column"
                      gap="16px"
                      flex={8}
                      flexWrap="wrap"
                    >
                      <Flex
                        flexDirection="column"
                        gap="10px"
                        flexWrap="wrap"
                        width="647px"
                      >
                        <Progress.Rating
                          readOnly
                          count={row?.score}
                          value={row?.score}
                        />
                        {row?.messages?.length > 0 && (
                          <Wrapper>
                            <Text
                              variant="caption"
                              lineClamp={10}
                              style={{ wordBreak: "break-all" }}
                            >
                              {
                                row?.messages.find(
                                  (message) => message.primary === true
                                )?.message
                              }
                            </Text>
                          </Wrapper>
                        )}
                      </Flex>
                      <Flex gap="10px">
                        {row?.images?.map((rowImg) => (
                          <Avatar
                            shape="square"
                            size={40}
                            src={
                              process.env.REACT_APP_IMAGE_AWS +
                              encodeURI(rowImg?.review_image)
                            }
                          />
                        ))}
                      </Flex>
                      {row?.messages?.length === 1 &&
                      !dataState[index]?.editState ? (
                        <Button
                          variant="secondary"
                          size="small"
                          onClick={() => handleShowReplyContainer(index)}
                        >
                          Tulis Balasan
                        </Button>
                      ) : !dataState[index]?.editState &&
                        row?.messages?.length === 2 ? (
                        <Fragment>
                          <Flex
                            flexDirection="column"
                            backgroundColor="black5"
                            width="647px"
                            borderRadius="8px"
                            paddingY="16px"
                            paddingX="16px"
                            style={{ borderLeft: "4px solid #BDBDC4" }}
                          >
                            <Flex gap="8px">
                              <Text weight="bold" variant="caption">
                                Balasan Darimu
                              </Text>
                              <Flex marginTop="-6px">
                                <Text color="black25" variant="heading2">
                                  &#x2022;
                                </Text>
                              </Flex>
                              <Text variant="caption">
                                {dayjs(
                                  findAndGet(row.messages, "created_date")
                                ).format("D MMM YYYY")}
                              </Text>
                            </Flex>
                            <Wrapper>
                              <Text
                                variant="caption"
                                color="black75"
                                style={{ wordBreak: "break-all" }}
                              >
                                {row?.messages?.length > 1 &&
                                  row?.messages.find(
                                    (message) => message.primary === false
                                  )?.message}
                              </Text>
                            </Wrapper>
                          </Flex>
                          <Flex
                            justifyContent="space-between"
                            cursor="pointer"
                            alignItems="center"
                          >
                            <Flex gap="24px">
                              <Text
                                color="blue50"
                                weight="bold"
                                variant="caption"
                                onClick={() => handleShowReplyContainer(index)}
                              >
                                Edit Balasan
                              </Text>
                              <Flex>
                                <Text
                                  color="black50"
                                  weight="bold"
                                  variant="caption"
                                  onClick={() =>
                                    deleteReply({
                                      id: row?.messages?.find(
                                        (message) => message.primary === false
                                      )?.id,
                                    })
                                  }
                                >
                                  Hapus
                                </Text>
                              </Flex>
                            </Flex>

                            <Flex display="none">
                              <Dropdown>
                                <Dropdown.Toggle>
                                  <Button
                                    variant="tertiary"
                                    size="small"
                                    icon={
                                      <Icon size={20} name="Vertical-outline" />
                                    }
                                  />
                                </Dropdown.Toggle>
                                <Dropdown.Content>
                                  <Dropdown.Item
                                    onClick={() => handleShowReport(index)}
                                  >
                                    Laporkan
                                  </Dropdown.Item>
                                </Dropdown.Content>
                              </Dropdown>
                            </Flex>
                          </Flex>
                        </Fragment>
                      ) : null}

                      {dataState[index]?.editState && (
                        <Fragment>
                          <Flex width="664px" flexDirection="column">
                            <Input.TextArea
                              maxLength={1000}
                              showCount
                              placeholder="Tulis balasanmu untuk ulasan ini ..."
                              label="Balasan"
                              value={dataState[index].tempReply}
                              style={{
                                borderBottomLeftRadius: "0",
                                borderBottomRightRadius: "0",
                              }}
                              onChange={(event) => {
                                const tempReply = dataState;

                                if (event?.target?.velue?.length < 1) {
                                  tempReply[index].tempReply =
                                    event.target.value.trim();
                                } else {
                                  const tempReply = dataState;
                                  tempReply[index].tempReply =
                                    event.target.value;
                                }

                                setDataState([...tempReply]);
                              }}
                            />
                            <Flex
                              padding="16px"
                              style={{
                                border: "1px solid #E1E1E3",
                                // borderTopLeftRadius: '8px'
                              }}
                              gap="8px"
                              flexWrap="wrap"
                            >
                              {listTemplate?.map((list) => (
                                <Flex
                                  cursor="pointer"
                                  borderRadius="8px"
                                  key={list.id}
                                  paddingX="16px"
                                  paddingY="8px"
                                  style={{ border: "1px solid #E1E1E3" }}
                                  onClick={() =>
                                    handleSetTemplate(list.message, index)
                                  }
                                >
                                  <Text variant="caption">{list?.name}</Text>
                                </Flex>
                              ))}
                            </Flex>
                            <Flex gap="8px" paddingY="16px">
                              <Flex width="120px">
                                <Button
                                  variant="tertiary"
                                  size="small"
                                  isBlock
                                  onClick={() => handleCancelReply(index)}
                                >
                                  Batal
                                </Button>
                              </Flex>
                              <Flex width="120px">
                                <Button
                                  variant={
                                    isEmpty(dataState[index].tempReply)
                                      ? "tertiary"
                                      : "primary"
                                  }
                                  size="small"
                                  isBlock
                                  onClick={() => handleSaveReply(index)}
                                  disabled={isEmpty(dataState[index].tempReply)}
                                >
                                  Kirim
                                </Button>
                              </Flex>
                            </Flex>
                          </Flex>
                        </Fragment>
                      )}
                    </Flex>
                  </Flex>
                  <Divider />
                </Fragment>
              ))
            )}
          </Fragment>
        )}

        {data?.data?.length > 0 && (
          <Flex flexDirection="row-reverse">
            <Pagination
              current={pagination?.current_page}
              onChange={(val) => {
                setPagination({
                  ...pagination,
                  current_page: val,
                });
                setFilters({
                  ...filters,
                  page: val,
                });
              }}
              total={pagination?.total_pages}
            />
          </Flex>
        )}
      </Flex>

      {showReport && selectedReport?.id && (
        <ReportReview showReport={showReport} />
      )}
    </FilterRatingProvider>
  );
};

export default Review;
