const desktopUrl = {
  DASHBOARD: "/",
  LOGIN: "/login",
  REGISTER: "/register",
  REGISTBYEMAIL: "/register/regis-byemail",
  PROFILE: "/profile",
  ADDRESS: "/address",
  CHAT: "/chat",
  CHATROOM: "/chat-room",
  CHAT_SETTING: "/chat/settings",
  PRODUCTS: "/products",
  PRODUCTS_LIST: "/products/list",
  PRODUCTS_ADD: "/products/add",
  PRODUCTS_ADD_MULTIPLE: "/products/add-multiple",
  EDIT_PRODUCT: "/products/edit-product",
  SHOWCASE: "/products/showcase",
  ORDERS: "/sales",
  ORDERS_LIST: "/sales/orders",
  ORDERS_DETAIL: "/sales/orders/detail",
  REVIEWS: "/sales/review",
  REVIEWS_EDIT: "/sales/review/edit",
  STATISTICS: "/sales/statistics",
  CONFIGURATION: "/config",
  SETTINGS_INFORMATION: "/settings/information",
  SETTING: "/setting",
  PROMO: "/promo",
  DISCOUNT: "/promo/discount-management",
  PROMO_PRODUCT_LIST: "/promo/product-list",
  SETDISCOUNT: "/promo/discount-management/set-discount",
  WALLET: "/wallet",
  LANDING: "/seller-landing",
  ACCOUNT: "/account",
  ACCOUNT_RATING_REVIEW: "/ulasan-pembeli",
  STORE_INFORMATION: "/account/information",
  STORE_NOTES: "/account/note",
  STORE_LOCATION: "/account/location",
  STORE_PAYMENT_LIST: "/daftar-rekening",
  STORE_DOCUMENT: "/account/document",
  STORE_BALANCE: "/detail-saldo",
  STORE_REPLY_TEMPLATE: "/account/template-reply",
  STORE_SELLER_FLEET: "/account/delivery-service",
  NOTIFICATION: "/notification",
  STORE_STATUS: "/store-status",
  PRINT_INVOICE: "/sales/orders/print-invoice",
  PRINT_LABEL: "/sales/orders/print-label",
  SELLER_TUTORIAL: "/seller-tutorial",
  STORE_LAYOUT: "/store-layout",
  STORE_LAYOUT_TEMPLATE: "/store-layout/template",
  SETTING_LIST_SELLER_FLEET: "/setting/seller-fleet",
  SETTING_SELLER_FLEET_ADD: "/setting/seller-fleet/add",
  SALES_RATING_REVIEW: "/sales/rating-review",
  ERROR_500: "/internal-error",
  VOUCHER: "/promo/voucher-management",
  VOUCHER_ADD: "/promo/voucher-management/add",
  BIDDING: "/bidding",
  BIDDING_DETAIL: "/bidding/:request_number",
};

export default desktopUrl;
