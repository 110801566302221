import ENDPOINTS from "constants/endpoints";
import { useMutation, useQuery } from "react-query";
import client from "../client";

const saveDiscountManagement = async (body) => {
  const { data } = await client.post(ENDPOINTS.discount_edit, body);

  return data;
};

export const useSaveDiscountManagement = (extraParams) =>
  useMutation((body) => saveDiscountManagement(body), {
    ...extraParams,
  });

const editDiscountManagement = async (body) => {
  const { data } = await client.post(ENDPOINTS.discount_edit, body);

  return data;
};

export const useEditDiscountManagement = (extraParams) =>
  useMutation((body) => editDiscountManagement(body), {
    ...extraParams,
  });

const getProductDiscount = async (params) => {
  const { data } = await client.get(ENDPOINTS.productsv3, { params });

  return data;
};

export const useGetProductDiscount = (params, extraParams) => {
  return useQuery(
    ["getProductDiscount", params],
    () => getProductDiscount({ ...params }),
    {
      ...extraParams,
      retry: false,
    }
  );
};

export const getProductDiscountById = async (params) => {
  const { data } = await client.get(ENDPOINTS.discount_get_active, { params });

  return data;
};

export const useGetProductDiscountById = (extraParams) =>
  useMutation((body) => getProductDiscountById(body), {
    ...extraParams,
  });

export const deleteProduct = async (body) => {
  const { data } = await client.post(ENDPOINTS.discount_delete, body);

  return data;
};

export const useDeleteProduct = (extraParams) =>
  useMutation((body) => deleteProduct(body), {
    ...extraParams,
  });

const getShowcaseFetcher = async (params) => {
  const { data } = await client.get(ENDPOINTS.showcase, { params });

  return data;
};

export const useGetShowcase = (params, extraParams) => {
  return useQuery(
    ["getShowcase", params],
    () => getShowcaseFetcher({ ...params }),
    {
      ...extraParams,
      retry: false,
    }
  );
};

const getCourierFetcher = async () => {
  const { data } = await client.get(ENDPOINTS.couriers, {
    params: { include_private: true },
  });

  return data;
};

export const useGetCourier = (extraParams) => {
  return useQuery(["getCourier"], () => getCourierFetcher(), {
    ...extraParams,
    retry: false,
  });
};

const getCategoryFetcher = async () => {
  const { data } = await client.get(ENDPOINTS.categories_level_2);

  return data;
};

export const useGetCategory = (extraParams) => {
  return useQuery(["getCategory"], () => getCategoryFetcher(), {
    ...extraParams,
    retry: false,
  });
};
