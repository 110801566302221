import React, { useEffect } from "react";
import { Flex, Text } from "renos-ui";
import { useCheckAuth } from "services/auth";
import Loading from "widgets/global/Loading";

const InternalError = () => {
  const { data: authData, isLoading } = useCheckAuth();

  useEffect(() => {
    if (authData?.code === 200 && !isLoading) {
      window.location.pathname = "/";
    }
    // eslint-disable-next-line
  }, [authData]);

  if (isLoading || authData?.code !== 200) {
    return <Loading />;
  }

  return (
    <Flex
      width="100%"
      height="100vh"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      gap="24px"
    >
      <Flex
        as="img"
        alt="not-found"
        src="/assets/images/error-state/state-server-error.svg"
      />
      <Flex flexDirection="column" width="416px" textAlign="center" gap="8px">
        <Text variant="heading3" weight="bold">
          Internal Server Error
        </Text>
        <Text color="black50">
          Maaf, terjadi kesalahan pada server kami :( <br />
          Tim terbaik kami sedang mengatasinya. Sabar dulu yaa!
        </Text>
      </Flex>
    </Flex>
  );
};

export default InternalError;
