import { goToCustomerService, goToUrl } from "helpers";
import React from "react";
import { Box, Divider, Flex, Icon, Text } from "renos-ui";
import { NavLink } from "./styled";

const LandingFooter = () => {
  return (
    <Flex
      flexDirection="column"
      width="100%"
      alignItems="center"
      rowGap="48px"
      paddingBottom="24px"
    >
      <Divider />
      <Box width="1200px">
        <Flex width="100%" justifyContent="space-between">
          <Flex flexDirection="column" rowGap="24px" width="218px">
            <Flex flexDirection="column" rowGap="8px">
              <Text variant="heading3" weight="bold">
                Butuh Bantuan?
              </Text>
              <Text color="black75">
                Kamu bisa menghubungi layanan pelanggan kami di:
              </Text>
            </Flex>
            <Flex flexDirection="column" rowGap="16px">
              <Flex
                columnGap="12px"
                alignItems="center"
                cursor="pointer"
                onClick={goToCustomerService}
              >
                <Flex
                  width="32px"
                  height="32px"
                  backgroundColor="blue10"
                  justifyContent="center"
                  alignItems="center"
                  borderRadius="20px"
                >
                  <Icon name="Whatsapp-solid" color="blue50" size="20px" />
                </Flex>
                <Text color="black75">0821-1087-5464</Text>
              </Flex>
              <Flex
                columnGap="12px"
                alignItems="center"
                cursor="pointer"
                onClick={() => goToUrl("mailto:support@renos.id", "_self")}
              >
                <Flex
                  width="32px"
                  height="32px"
                  backgroundColor="blue10"
                  justifyContent="center"
                  alignItems="center"
                  borderRadius="20px"
                >
                  <Icon name="Message-solid" color="blue50" size="20px" />
                </Flex>
                <Text color="black75">support@renos.id</Text>
              </Flex>
            </Flex>
          </Flex>

          <Flex flexDirection="column" rowGap="16px">
            <Text variant="heading3" weight="bold">
              Renos
            </Text>
            <Flex flexDirection="column" rowGap="8px">
              <NavLink as="a" href="https://renos.id/about">
                <Text color="black50">Tentang Renos</Text>
              </NavLink>
              <NavLink as="a" href="https://www.renos.id/privacy">
                <Text color="black50">Syarat & Ketentuan</Text>
              </NavLink>
              <NavLink as="a" href="https://www.renos.id/terms">
                <Text color="black50">Kebijakan Privasi</Text>
              </NavLink>
              <NavLink as="a" href="https://www.renos.id/blog/">
                <Text color="black50">Artikel</Text>
              </NavLink>
            </Flex>
          </Flex>

          <Flex flexDirection="column" rowGap="16px">
            <Text variant="heading3" weight="bold">
              Pembeli
            </Text>
            <Flex flexDirection="column" rowGap="8px">
              <NavLink as="a" href="https://www.renos.id/bantuan/">
                <Text color="black50">Pusat Bantuan</Text>
              </NavLink>
              <NavLink as="a">
                <Text color="black50">Cara Belanja</Text>
              </NavLink>
              <NavLink as="a">
                <Text color="black50">Pembayaran</Text>
              </NavLink>
              <NavLink as="a">
                <Text color="black50">Pengiriman</Text>
              </NavLink>
              <NavLink as="a" href="https://www.renos.id/promo/">
                <Text color="black50">Promo</Text>
              </NavLink>
            </Flex>
          </Flex>

          <Flex flexDirection="column" rowGap="16px">
            <Text variant="heading3" weight="bold">
              Penjual
            </Text>
            <Flex flexDirection="column" rowGap="8px">
              <NavLink as="a" href="/seller-landing">
                <Text color="black50">Jadi Penjual</Text>
              </NavLink>
            </Flex>
          </Flex>

          <Flex flexDirection="column" rowGap="16px">
            <Text variant="heading3" weight="bold">
              Ikuti Kami
            </Text>
            <Flex columnGap="16px">
              <NavLink
                as="a"
                href="https://twitter.com/renos_id?t=nR3_m8Xa3n4vPDzeQjICcg&s=08"
                target="_blank"
                rel="noreferrer"
              >
                <Icon name="Twitter" size={40} />
              </NavLink>
              <NavLink
                as="a"
                href="https://www.tiktok.com/@renos_id?_d=secCgwIARCbDRjEFSADKAESPgo8C0NW8RB689vmdkyKFth6Mbh6kLfLn9OU2P21LV3P4sBcLpXMq%2FEUcGTm7HPD%2B%2BmnWNioWtqpT1cilL31GgA%3D&language=en&sec_uid=MS4wLjABAAAAqc4h0gpOcsVgVBHQTcn1_otuRJUkdVpPsW9Bs_P9UVyrsXyND7zvzLW7LOpUSno3&sec_user_id=MS4wLjABAAAAAnJfH5F1LNkEb2kSIJ22oO3nIgg60WVdxRCIPdhkFFjD8pV8ul8kNTZX7ZD0J1LH&share_app_id=1180&share_author_id=6940092264896365569&share_link_id=18fcaa22-4bff-4fd1-817f-607270f182c5&timestamp=1642491412&u_code=dagcclfdkkfhkb&user_id=6787384335249458177&utm_campaign=client_share&utm_medium=android&utm_source=copy&source=h5_t&_r=1"
                target="_blank"
                rel="noreferrer"
              >
                <Icon name="TikTok" size={40} />
              </NavLink>
              <NavLink
                as="a"
                href="https://www.instagram.com/renos_id"
                target="_blank"
                rel="noreferrer"
              >
                <Icon name="Instagram" size={40} />
              </NavLink>
              <NavLink
                as="a"
                href="https://web.facebook.com/Renosmarketplace/?_rdc=1&_rdr"
                target="_blank"
                rel="noreferrer"
              >
                <Icon name="Facebook" size={40} />
              </NavLink>
              <NavLink
                as="a"
                href="https://www.youtube.com/channel/UCDC5I4y9ECi1ypTfhM3lRIA"
                target="_blank"
                rel="noreferrer"
              >
                <Icon name="Youtube" size={40} />
              </NavLink>
            </Flex>
          </Flex>
        </Flex>
      </Box>
      <Divider />
      <Text color="black75">©2023 Renos.id. Segala hak cipta dilindungi.</Text>
    </Flex>
  );
};

export default LandingFooter;
