import React from "react";
import { Flex, range, Skeleton } from "renos-ui";

export const ListSkeletonProduct = () => {
  return (
    <Flex flexDirection="column" rowGap="24px" width="100%" overflow="auto">
      <Flex flexDirection="column" rowGap="16px">
        {range(10).map((_, i) => (
          <Flex width="100%" columnGap="16px" key={`skeleton-table-${i}`}>
            <Skeleton height="48px" width="42px" />
            <Skeleton height="48px" width="350px" />
            <Skeleton height="48px" width="135px" />
            <Skeleton height="48px" width="135px" />
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
};

export const ListSkeletonShowcase = () => {
  return (
    <Flex flexDirection="column" rowGap="24px" width="100%" overflow="auto">
      <Flex flexDirection="column" rowGap="16px">
        {range(10).map((_, i) => (
          <Flex width="100%" columnGap="16px" key={`skeleton-table-${i}`}>
            <Skeleton height="58px" width="42px" />
            <Skeleton height="58px" width="650px" />
            <Skeleton height="58px" width="210px" />
            <Skeleton height="58px" width="224px" />
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
};

export const CardSkeleton = () => {
  return (
    <Flex flexDirection="column" rowGap="24px" width="100%" overflow="auto">
      <Flex flexDirection="column" rowGap="16px">
        <Flex width="100%" columnGap="16px">
          <Skeleton height="48px" width="42px" />
          <Skeleton height="48px" width="350px" />
          <Skeleton height="48px" width="110px" />
          <Skeleton height="48px" width="150px" />
        </Flex>
      </Flex>
    </Flex>
  );
};

export const ImageSkeleton = () => {
  return (
    <Flex flexDirection="column" rowGap="24px" width="100%" overflow="auto">
      <Flex flexDirection="column" rowGap="16px">
        <Flex width="100%" columnGap="16px">
          {range(6).map((_, i) => (
            <Skeleton
              width="104px"
              height="104px"
              borderRadius="16px"
              key={i}
            />
          ))}
        </Flex>
        <Flex width="100%" columnGap="16px">
          {range(6).map((_, i) => (
            <Skeleton
              width="104px"
              height="104px"
              borderRadius="16px"
              key={i}
            />
          ))}
        </Flex>
      </Flex>
    </Flex>
  );
};
