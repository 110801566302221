import useQueryParams from "hooks/useQueryParams";
import StoreContext from "providers/StoreProvider";
import { useContext, useMemo } from "react";
import { Box, Button, Flex, Skeleton, Text, range } from "renos-ui";
import { useGetOrderStatus } from "services/order-management";

const TabSelector = ({ value, setValue }) => {
  const query = useQueryParams();
  const {
    storeData: {
      data: { id: storeId },
    },
  } = useContext(StoreContext);

  const {
    isLoading,
    isError,
    data: dataStatus,
    refetch,
  } = useGetOrderStatus({ store: storeId, user_id: query.get("user_id") });

  const items = useMemo(() => {
    let tempData = [];

    if (dataStatus?.data) {
      tempData = dataStatus.data.map((status) => ({
        label: status.formatted_order_status_name,
        id: status.order_status_id,
      }));
    }

    return tempData;
  }, [dataStatus]);

  return (
    <Flex overflowY="auto" gap="8px" py="8px">
      {isLoading ? (
        range(5).map((_, indexSkeleton) => (
          <Box key={`skeleton-${indexSkeleton}`}>
            <Skeleton height="36px" width="100px" borderRadius="100px" />
          </Box>
        ))
      ) : isError ? (
        <Button onClick={refetch} size="medium">
          Muat Ulang
        </Button>
      ) : (
        items.map((item, indexItem) => (
          <Flex
            key={`tabs-${indexItem}`}
            padding="6px 16px"
            borderColor={item.id === value ? "blue50" : "black10"}
            borderStyle="solid"
            borderWidth="1px"
            borderRadius="100px"
            backgroundColor={item.id === value ? "blue5" : "white"}
            minWidth="fit-content"
            alignItems="center"
            onClick={() => {
              setValue(item.id);
              refetch();
            }}
          >
            <Text
              variant="caption"
              color={item.id === value ? "blue50" : "black75"}
            >
              {item.label}
            </Text>
          </Flex>
        ))
      )}
    </Flex>
  );
};

export default TabSelector;
