import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Box,
  Divider,
  Flex,
  Icon,
  Text,
  useDisclosure,
  useToast,
} from "renos-ui";
import Filter from "./Filter";
import RatingItem from "./RatingItem";
import StoreContext from "providers/StoreProvider";
import SkeletonRating from "./SkeletonRating";
import { FilterRatingProvider } from "providers/FilterRatingReview";
import EmptyState from "../components/EmptyState";
import { useGetRating, useGetRatingAverage } from "services/sales";

const Rating = () => {
  const toast = useToast();
  const observerElem = useRef(null);
  const [page, setPage] = useState(1);
  const [items, setItems] = useState([]);
  const [reObserveElement, setReObserveElement] = useState(0);
  const filterDisclosure = useDisclosure({ open: false });
  const {
    storeData: {
      data: { id: storeId },
    },
  } = useContext(StoreContext);

  const [filter, setFilter] = useState({
    page,
    isFiltered: false,
    store_id: storeId,
    items_per_page: 10,
    include_pagination: true,
    product_name_contains: "",
    order_by: "reviews_count",
    ordering: "desc",
  });

  const itemsFilter = [
    {
      label: "Ulasan Terbanyak",
      value: "most_reviews_count",
      params: {
        order_by: "reviews_count",
        ordering: "desc",
      },
    },
    {
      label: "Ulasan Tersedikit",
      value: "less_reviews_count",
      params: {
        order_by: "reviews_count",
        ordering: "asc",
      },
    },
    {
      label: "Rating Tertinggi",
      value: "most_average_score",
      params: {
        order_by: "score",
        ordering: "desc",
      },
    },
    {
      label: "Rating Terendah",
      value: "less_average_score",
      params: {
        order_by: "score",
        ordering: "asc",
      },
    },
  ];

  /**
   * @title Get Data Rating Average
   * @description Get data rating average from API
   */
  const { data: ratingAverage } = useGetRatingAverage({
    params: {
      store_id: storeId,
    },
  });

  /**
   * @title Get Data Rating
   * @description Get data rating from API
   * @param {Object} filter
   */
  const {
    data: dataRating = { data: [] },
    isLoading,
    // isError,
    isFetching,
  } = useGetRating(
    {
      params: {
        ...filter,
        isFiltered: undefined,
      },
    },
    {
      onError: (error) => {
        const {
          response: {
            data: { frontend_message: message },
          },
        } = error;

        // temporary hide always true till API running well on search
        const hide = true;

        if (!hide) {
          toast({
            label: message || "Ada masalah dari server.",
            placement: "bottom",
            backgroundColor: "red50",
          });
        }
        setItems([]);
      },
      onSuccess: ({ pagination, data }) => {
        setPage(pagination.current_page);

        if (pagination.current_page === 1) {
          setItems([...data]);
        } else {
          if (data && data.length > 0) {
            const concatData = items.concat(data);
            setItems(concatData);
          }
        }
      },
    }
  );

  const isLoadingDataRating = useMemo(
    () => isLoading && page === 1,
    [isLoading, page]
  );

  const hasNextPage = useMemo(
    () =>
      dataRating?.pagination?.current_page !==
      dataRating?.pagination?.total_pages,
    // eslint-disable-next-line
    [items]
  );

  const handleObserver = useCallback(
    (entries) => {
      const [target] = entries;

      if (target.isIntersecting) {
        setFilter((prev) => ({
          ...prev,
          page: prev.page + 1,
        }));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hasNextPage]
  );

  useEffect(() => {
    const element = observerElem.current;

    setTimeout(() => {
      if (!element) setReObserveElement((prev) => prev + 1);
    }, 1000);

    if (element) {
      const option = { threshold: 0 };

      const observer = new IntersectionObserver(handleObserver, option);
      observer.observe(element);

      return () => observer.unobserve(element);
    }
  }, [page, hasNextPage, handleObserver, observerElem, reObserveElement]);

  return (
    <FilterRatingProvider
      value={{
        filter,
        setFilter,
        itemsFilter,
      }}
    >
      <Flex
        gap="4px"
        justifyContent="center"
        flexDirection="column"
        style={{ boxSizing: "border-box" }}
      >
        {isLoading || (isFetching && items?.length === 0) ? (
          <SkeletonRating itemShow={6} />
        ) : dataRating?.data?.length === 0 && !filter.isFiltered ? (
          <EmptyState title="Rating" />
        ) : (
          <Flex flexDirection="column">
            <Flex pt="16px" gap="16px" flexDirection="column">
              <Filter filterDisclosure={filterDisclosure} />
              <Flex gap="4px" flex="row" marginX="16px" alignItems="center">
                <Icon name="Star-solid" size="16px" color="yellow50" />
                <Text variant="caption" color="black75" weight="bold">
                  {ratingAverage?.average_score}
                </Text>
                <Text variant="caption" color="black75">
                  dari
                </Text>
                <Text variant="caption" color="black75" weight="bold">
                  {ratingAverage?.reviews_count} Ulasan
                </Text>
                <Icon name="Info-solid" size="12px" color="black50" />
              </Flex>
              <Divider />
            </Flex>
            <Flex
              flexDirection="column"
              backgroundColor="black5"
              gap="8px"
              pb="8px"
            >
              {dataRating?.data?.length === 0 && <EmptyState title="Rating" />}
              {items?.map((row, index) => (
                <RatingItem
                  rating={row?.score}
                  review={row?.reviews_count}
                  title={row?.product_data?.product_name}
                  image={row?.product_data?.product_image_url}
                  key={`rating-list-${index}`}
                />
              ))}
              {hasNextPage && !isLoadingDataRating && items?.length > 9 && (
                <Box
                  backgroundColor="white"
                  ref={observerElem}
                  mb="16px"
                  pb="16px"
                >
                  <SkeletonRating showFilter={false} />
                </Box>
              )}
            </Flex>
          </Flex>
        )}
      </Flex>
    </FilterRatingProvider>
  );
};

export default Rating;
