import React from "react";
import { Drawer, Flex, Text } from "renos-ui";

const InfoDrawer = ({ disclosure }) => {
  return (
    <Drawer
      isVisible={disclosure.isOpen}
      closable={true}
      onClose={() => disclosure.onClose()}
      placement="bottom"
    >
      <Flex flexDirection="column" width="100%" gap="24px">
        <Flex flexDirection="column" gap="8px">
          <Text variant="subtitle" weight="bold">
            Balasan Ulasan Otomatis
          </Text>
          <Text>
            Kamu bisa membuat maksimal 5 template balasan otomatis untuk
            membalas ulasan pembeli dengan rating 4 & 5.
          </Text>
        </Flex>
      </Flex>
    </Drawer>
  );
};

export default InfoDrawer;
