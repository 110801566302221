import { Flex, Divider, Skeleton, range } from "renos-ui";
import React from "react";

const Loading = () => {
  return (
    <Flex flexDirection="column">
      {range(5).map((_, index) => (
        <React.Fragment key={`menu-${index}`}>
          <Flex
            width="100%"
            justifyContent="space-between"
            alignItems="center"
            cursor="pointer"
            py="16px"
          >
            <Skeleton width="290px" height="24px" />
            <Skeleton width="24px" height="24px" />
          </Flex>
          <Divider />
        </React.Fragment>
      ))}
    </Flex>
  );
};

export default Loading;
