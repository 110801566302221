import React, { useMemo, useState } from "react";
import { Box, Checkbox, Flex, Icon, Text, Toggle, isEmpty } from "renos-ui";
import { useProductAddContext } from "store/productAdd/reducer";
import { StyledDisclosure } from "./styled";
import VariantForm from "./components/VariantForm";
import constants from "store/productAdd/constants";
import { initialFormBatch } from "containers/web/product/add/single/constants";
import VariantFormBatch from "./components/VariantFormBatch";

const VariantTable = () => {
  const { state, dispatch } = useProductAddContext();
  const { productVariants, sku } = state;

  const [filterData, setFilterData] = useState({});
  const [checkedVariant, setCheckedVariant] = useState([]);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [formBatch, setFormBatch] = useState(initialFormBatch);

  const filteredCount = useMemo(
    () => Object.keys(filterData).length,
    [filterData]
  );

  const onParentVariantChecked = (firstIndex, secondVariant, isActive) => {
    const tempCheckedVariant = [...checkedVariant];
    const restChecked = tempCheckedVariant.filter(
      (data) => parseInt(data.split("-")[0]) !== firstIndex
    );

    if (!isActive) {
      setCheckedVariant(restChecked);
      setIsCheckAll(false);
    } else {
      const newCheckVariant = [
        ...restChecked,
        ...secondVariant.variant_values.map(
          (_, index) => `${firstIndex}-${index}`
        ),
      ];
      const isAllChecked = newCheckVariant.length === sku.length;

      if (isAllChecked) {
        setIsCheckAll(true);
      }
      setCheckedVariant(newCheckVariant);
    }
  };

  const onVariantChecked = (index) => {
    setFilterData({});

    if (checkedVariant.includes(index)) {
      const tempChecked = [...checkedVariant];
      const selectedIndex = checkedVariant.findIndex((data) => data === index);
      tempChecked.splice(selectedIndex, 1);
      setCheckedVariant(tempChecked);
      setIsCheckAll(false);
    } else {
      if (checkedVariant.length === sku.length - 1) {
        setIsCheckAll(true);
      }
      setCheckedVariant((prev) => [...prev, index]);
    }
  };

  const onFormValueChange = (value, index, key) => {
    if (sku.length > 0) {
      const selectedIndex = sku.findIndex((value) => value.index === index);

      if (selectedIndex >= 0) {
        const listActiveProduct = sku.filter((data) => data.status === 1);

        sku[selectedIndex][key] = value;

        if (key === "status") {
          if (sku[selectedIndex]?.default === 1) {
            const nextActiveIndex = sku.findIndex((data) => data.status === 1);

            if (nextActiveIndex >= 0) {
              sku[selectedIndex].default = 0;
              sku[nextActiveIndex].default = 1;

              if (
                sku[nextActiveIndex].stock === 0 ||
                isEmpty(sku[nextActiveIndex].stock)
              ) {
                sku[nextActiveIndex].stock = 1;
              }
            }
          }

          if (
            listActiveProduct.length === 0 &&
            sku[selectedIndex]?.default !== 1
          ) {
            const prevDefaultIndex = sku.findIndex(
              (data) => data?.default === 1
            );
            sku[prevDefaultIndex].default = 0;
            sku[selectedIndex].default = 1;

            if (
              sku[selectedIndex].stock === 0 ||
              isEmpty(sku[selectedIndex].stock)
            ) {
              sku[selectedIndex].stock = 1;
            }
          }
        }
      } else {
        sku.push({
          index,
          [key]: value,
        });
      }
    } else {
      sku.push({
        index,
        [key]: value,
      });
    }

    dispatch({ type: constants.SAVE_VALUE, sku });
  };

  if (productVariants.length > 1) {
    const [firstVariant, secondVariant] = productVariants;

    return (
      <Flex flexDirection="column" rowGap="40px" pb="36px">
        <VariantFormBatch
          {...{
            isCheckAll,
            filteredCount,
            checkedVariant,
            formBatch,
            setFormBatch,
            setIsCheckAll,
            setFilterData,
            setCheckedVariant,
            filterData,
          }}
        />
        {firstVariant.variant_values_minify?.map(
          (productVariant, firstVariantIndex) => {
            const firstCheckedIndex = checkedVariant?.map((item) =>
              parseInt(item.split("-")[0])
            );

            const isIndeterminated =
              firstCheckedIndex.includes(firstVariantIndex);

            const variantCheckedCount = firstCheckedIndex.filter(
              (item) => item === firstVariantIndex
            ).length;

            const isChecked =
              secondVariant.variant_values_minify.length ===
              variantCheckedCount;

            return (
              <StyledDisclosure
                defaultOpen={true}
                key={`${firstVariant.variant_name} - ${productVariant.label}  - ${firstVariantIndex}`}
              >
                {({ isOpen, onToggle }) => (
                  <Flex flexDirection="column" rowGap="16px">
                    <Flex justifyContent="space-between" alignItems="center">
                      <Flex alignItems="center" columnGap="8px">
                        <Box
                          width={6}
                          height={20}
                          borderTopRightRadius={8}
                          borderBottomRightRadius={8}
                          backgroundColor="blue50"
                        />
                        <Checkbox
                          checked={isChecked}
                          indeterminate={isIndeterminated}
                          onChange={(value) =>
                            onParentVariantChecked(
                              firstVariantIndex,
                              secondVariant,
                              value
                            )
                          }
                        />
                        <Text variant="caption" weight="bold">
                          {`${firstVariant.variant_name} - ${productVariant.label}`}
                        </Text>
                      </Flex>
                      <Icon
                        name={isOpen ? "Down-outline" : "Up-outline"}
                        size={20}
                        color="black50"
                        cursor="pointer"
                        onClick={onToggle}
                      />
                    </Flex>
                    {isOpen &&
                      secondVariant.variant_values_minify?.map(
                        (secondProductVariant, secondVariantIndex) => {
                          const selectedSku = sku.find(
                            (variant) =>
                              variant.index ===
                              `${firstVariantIndex}-${secondVariantIndex}`
                          );

                          const skuIndex = `${firstVariantIndex}-${secondVariantIndex}`;

                          const filtered = [];
                          Object.keys(filterData).forEach((key) => {
                            if (filterData[key] === selectedSku.id[key]) {
                              filtered.push(true);
                            }
                          });

                          const isFiltered =
                            filteredCount > 0
                              ? filtered.length === filteredCount
                              : false;

                          return (
                            <React.Fragment
                              key={`${secondVariant.variant_name} - ${secondProductVariant.label} - ${secondVariantIndex}`}
                            >
                              <Flex flexDirection="column" rowGap="16px">
                                <Flex
                                  justifyContent="space-between"
                                  alignItems="center"
                                >
                                  <Flex alignItems="center" columnGap="8px">
                                    <Checkbox
                                      checked={
                                        isFiltered ||
                                        isCheckAll ||
                                        checkedVariant.includes(skuIndex)
                                      }
                                      onChange={() =>
                                        onVariantChecked(skuIndex)
                                      }
                                    />
                                    <Text variant="caption" weight="bold">
                                      {`${secondVariant.variant_name} - ${secondProductVariant.label}`}
                                    </Text>
                                    {selectedSku?.default === 1 && (
                                      <Flex
                                        borderRadius="8px"
                                        bg="blue5"
                                        px="8px"
                                        py="2px"
                                        alignItems="center"
                                        justifyContent="center"
                                        borderWidth="1px"
                                        borderColor="blue10"
                                        borderStyle="solid"
                                      >
                                        <Text variant="small" color="blue50">
                                          utama
                                        </Text>
                                      </Flex>
                                    )}
                                  </Flex>
                                  <Flex
                                    onClick={() =>
                                      onFormValueChange(
                                        selectedSku?.status === 1 ||
                                          selectedSku?.status === undefined
                                          ? 2
                                          : 1,
                                        `${firstVariantIndex}-${secondVariantIndex}`,
                                        "status"
                                      )
                                    }
                                  >
                                    <Toggle
                                      checked={
                                        selectedSku?.status === undefined ||
                                        selectedSku?.status === 1
                                      }
                                    />
                                  </Flex>
                                </Flex>
                              </Flex>
                              <VariantForm
                                {...{
                                  variantIndex: `${firstVariantIndex}-${secondVariantIndex}`,
                                  selectedSku,
                                  onFormValueChange,
                                }}
                              />
                            </React.Fragment>
                          );
                        }
                      )}
                  </Flex>
                )}
              </StyledDisclosure>
            );
          }
        )}
      </Flex>
    );
  }

  return (
    <Flex flexDirection="column" rowGap="40px">
      <VariantFormBatch
        {...{
          isCheckAll,
          filteredCount,
          checkedVariant,
          formBatch,
          setFormBatch,
          setIsCheckAll,
          setFilterData,
          setCheckedVariant,
          filterData,
        }}
      />
      {productVariants[0].variant_values_minify?.map(
        (productVariant, variantIndex) => {
          const selectedSku = sku.find((data) => data.index === variantIndex);

          const filtered = [];
          Object.keys(filterData).forEach((key) => {
            if (filterData[key] === selectedSku.id[key]) {
              filtered.push(true);
            }
          });

          const isFiltered =
            filteredCount > 0 ? filtered.length === filteredCount : false;

          return (
            <Flex
              flexDirection="column"
              rowGap="16px"
              key={`${productVariants[0].variant_name} - ${productVariant.label} - ${variantIndex}`}
            >
              <Flex flexDirection="column" rowGap="16px">
                <Flex justifyContent="space-between" alignItems="center">
                  <Flex alignItems="center" columnGap="8px">
                    <Box
                      width={6}
                      height={20}
                      borderTopRightRadius={8}
                      borderBottomRightRadius={8}
                      backgroundColor="blue50"
                    />
                    <Checkbox
                      checked={
                        isFiltered ||
                        isCheckAll ||
                        checkedVariant.includes(variantIndex)
                      }
                      onChange={() => onVariantChecked(variantIndex)}
                    />
                    <Text variant="caption" weight="bold">
                      {`${productVariants[0].variant_name} - ${productVariant.label}`}
                    </Text>
                    {selectedSku?.default === 1 && (
                      <Flex
                        borderRadius="8px"
                        bg="blue5"
                        px="8px"
                        py="2px"
                        alignItems="center"
                        justifyContent="center"
                        borderWidth="1px"
                        borderColor="blue10"
                        borderStyle="solid"
                      >
                        <Text variant="small" color="blue50">
                          utama
                        </Text>
                      </Flex>
                    )}
                  </Flex>
                  <Flex
                    onClick={() =>
                      onFormValueChange(
                        selectedSku?.status === 1 ||
                          selectedSku?.status === undefined
                          ? 2
                          : 1,
                        variantIndex,
                        "status"
                      )
                    }
                  >
                    <Toggle
                      checked={
                        selectedSku?.status === undefined ||
                        selectedSku?.status === 1
                      }
                    />
                  </Flex>
                </Flex>

                <VariantForm
                  {...{
                    variantIndex,
                    selectedSku,
                    onFormValueChange,
                  }}
                />
              </Flex>
            </Flex>
          );
        }
      )}
    </Flex>
  );
};

export default VariantTable;
