import { useEffect } from "react";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  Icon,
  Input,
  Text,
  isEmpty,
} from "renos-ui";
import Table from "widgets/desktop/TableShowcase";
import { StyledDialogUpload, StyledSelectInput } from "../styled";
import EmptyProduct from "../EmptyProduct";
import { CardSkeleton, ListSkeletonProduct } from "../Skeleton";
import desktopUrl from "constants/desktopUrl";
import useAddModalShowcase from "./hooks/useAddModal";
import InfiniteScroll from "widgets/global/InfiniteScroll";

const AddModalShowcase = ({
  addDisclosure,
  isLoadingDataProduct,
  setParamsIds,
  paramsIds,
  checkedParent,
}) => {
  
  const {
    push,
    handleSave,
    tableColumns,
    checkedProducts,
    isCheckedAll,
    onCheckAllClicked,
    maxData,
    hasNextPage,
    handleFilterChange,
    handleOrderChange,
    dataModal,
    isLoadingModal,
    searchValue,
    setSearchValue,
    dataSort,
    isLoadingAll,
    dataFilter,
    fetchDataModal,
    page,
    setPage,
    searchParam
  } = useAddModalShowcase({
    addDisclosure,
    setParamsIds,
    checkedParent,
    paramsIds,  
  });

  const onClose = () => {
    addDisclosure.onClose();
  };

  const onOpen = () => {
    setPage(1);
    addDisclosure.isOpen();
  };

  useEffect(() => {
    setPage(1);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParam]);

  return (
    <StyledDialogUpload
      isVisible={onOpen}
      closable
      onClose={onClose}
      width="720px"
      height="583px"
      verticalCentered
    >
      <Flex flexDirection="column" width="100%">
        <Flex flexDirection="column" gap="24px" padding="0">
          <Text variant="heading3" weight="bold">
            Tambah Produk
          </Text>
          <Divider />
        </Flex>

        <Flex flexDirection="column" height="100%" padding="0">
          <Flex
            flexDirection="row"
            justifyContent="space-between"
            gap="8px"
            margin="24px 0"
          >
            <Flex width="280px" height="48px" flexBasis="40%">
              <Input
                placeholder="Cari nama produk atau SKU"
                prefix={<Icon name="Search-outline" size={20} />}
                value={searchValue}
                onChange={(event) => {
                  setSearchValue(event.target.value);
                }}
              />
            </Flex>
            <Flex flexBasis="30%" width="100%">
              <StyledSelectInput
                placeholder="Filter"
                options={dataFilter}
                onChange={(values) => {
                  handleFilterChange(values);
                  setPage(1);
                }}
              />
            </Flex>
            <Flex flexBasis="30%" width="100%">
              <StyledSelectInput
                placeholder="Urutkan"
                options={dataSort}
                onChange={(name, values) => {
                  handleOrderChange(name, values);
                  setPage(1);
                }}
              />
            </Flex>
          </Flex>

          <Divider />
          <br />

          <Flex
            height="360px"
            overflowY="auto"
            flexDirection="column"
            width="100%"
            gap="16px"
          >
            <InfiniteScroll
              page={page}
              onChange={() => setPage(page + 1)}
              isLoading={isLoadingModal || fetchDataModal}
              hasNextPage={hasNextPage}
              loader={
                <Box>
                  <CardSkeleton />
                </Box>
              }
            >
              {isLoadingAll ? (
                <ListSkeletonProduct />
              ) : dataModal?.length === 0 ? (
                <EmptyProduct />
              ) : (
                <Table
                  width="100%"
                  columns={tableColumns}
                  dataSource={dataModal}
                  position="relative"
                  customHeader={
                    checkedProducts.length > 0 && (
                      <Flex columnGap="20px" alignItems="center" pb="12px">
                        <Flex gap="16px" width="352px">
                          <Checkbox
                            checked={isCheckedAll}
                            onChange={onCheckAllClicked}
                            indeterminate={
                              checkedProducts.length > 0 &&
                              checkedProducts.length < dataModal.length
                            }
                          />
                          <Text weight="bold">
                            {`${checkedProducts.length} / ${maxData} Produk Dipilih`}
                          </Text>
                        </Flex>

                        <Flex flexBasis="20%">
                          <Text>
                            <strong>Harga</strong>
                          </Text>
                        </Flex>

                        <Flex flexBasis="20%">
                          <Text>
                            <strong>Terjual</strong>
                          </Text>
                        </Flex>
                      </Flex>
                    )
                  }
                />
              )}
            </InfiniteScroll>
          </Flex>
        </Flex>
        <Flex flexDirection="column" marginTop="20px" padding="0">
          <Flex justifyContent="end">
            {isEmpty(dataModal) ? (
              <Box width="180px">
                <Button
                  size="medium"
                  isBlock
                  disabled={
                    isLoadingAll ||
                    isLoadingModal ||
                    isLoadingDataProduct
                  }
                  onClick={() => push(desktopUrl.PRODUCTS_ADD)}
                >
                  <Text weight="bold" color="white">
                    Tambah Produk
                  </Text>
                </Button>
              </Box>
            ) : (
              <Flex width="180px" justifyContent="flex-end">
                <Button
                  size="medium"
                  isBlock
                  disabled={
                    checkedProducts.length === 0 ||
                    isLoadingModal ||
                    isLoadingDataProduct
                  }
                  onClick={handleSave}
                >
                  <Text
                    weight="bold"
                    color={checkedProducts.length === 0 ? "black25" : "white"}
                  >
                    Simpan
                  </Text>
                </Button>
              </Flex>
            )}
          </Flex>
        </Flex>
      </Flex>
    </StyledDialogUpload>
  );
};

export default AddModalShowcase;
