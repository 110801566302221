import { Button, Flex, Input, Skeleton, Text } from "renos-ui";

const DiscountType = ({
  value,
  onClick,
  list,
  isLoading,
  refetchData,
  isGetDataError,
  disabled,
}) => {
  const handleOnClick = (newValue) => {
    if (!disabled) onClick(newValue);
  };

  return (
    <Flex flexDirection="column" gap="8px">
      <Text variant="caption" color="black50" weight="bold">
        Tipe Potongan
      </Text>
      <Flex gap="24px">
        {isLoading ? (
          <Skeleton height="42px" width="176px" />
        ) : isGetDataError ? (
          <Button onClick={refetchData} size="medium">
            Muat Ulang
          </Button>
        ) : (
          list.map((listType, indexType) => (
            <Flex
              key={`type-${indexType}`}
              gap="4px"
              onClick={() => handleOnClick(listType.id)}
              cursor="pointer"
            >
              <Input.Radio checked={value === listType.id} />
              <Text color="black75">{listType.name}</Text>
            </Flex>
          ))
        )}
      </Flex>
    </Flex>
  );
};

export default DiscountType;
