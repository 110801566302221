import { Flex, Button, Icon } from "renos-ui";
import { useTour } from "@reactour/tour";
import { TriangleFirst, TriangleTwo, TriangleThree } from "./styled";
import { useEffect } from "react";

const isMobile = window.innerWidth <= 760;

const WelcomeStep = () => (
  <Flex flexDirection="column" gap="8px" width="286px">
    <h3>
      Selamat Datang di Seller <br /> Center Renos!
    </h3>
    <small>
      Navigasi utama Toko ada di sebelah kiri. Semua informasi terkait
      penjualanmu bisa diakses di sini.
    </small>
  </Flex>
);

const CalculateSalesStep = () => (
  <Flex flexDirection="column" gap="8px">
    <h3 style={{ margin: 0 }}>Hitung penjualanmu di Renos!</h3>
    <div>
      <small>
        Analisis Toko membantumu menghitung
        <br /> pendapatan bersih dan akumulasi produk yang <br /> terjual{" "}
      </small>
    </div>
  </Flex>
);

const CompleteDataStep = () => (
  <Flex flexDirection="column" gap="8px">
    <h3 style={{ margin: 0 }}>
      Terakhir, lengkapin ini dulu <br /> yuk!
    </h3>
    <small>
      Kamu harus melengkapi beberapa data dulu <br /> untuk bisa mulai berjualan
      di Renos. Cuma <br /> sebentar kok!
    </small>
  </Flex>
);

const SuccessStep = () => (
  <Flex flexDirection="column" gap="8px">
    <h3>
      Selamat Berjualan dan Semoga <br /> Sukses!
    </h3>
    <small>
      Kami senang dengan antusiasme kamu untuk <br /> berjualan di Renos. Kami
      sangat berharap agar kamu <br /> bisa sukses seperti penjual lainnya!
    </small>
  </Flex>
);

const WelcomeStepMobile = () => (
  <Flex flexDirection="column" gap="8px" width="258px">
    <h5 style={{ margin: 0 }}>
      Selamat Datang di Seller Center <br /> Renos!
    </h5>
    <small style={{ fontSize: "12px", lineHeight: "20px" }}>
      Navigasi utama Toko ada di sebelah kiri. Semua informasi terkait
      penjualanmu bisa diakses di sini.
    </small>
  </Flex>
);

const CalculateSalesStepMobile = () => (
  <Flex flexDirection="column" gap="8px">
    <h5 style={{ margin: 0 }}>Hitung penjualanmu di Renos!</h5>
    <div>
      <small style={{ fontSize: "12px", lineHeight: "20px" }}>
        Analisis Toko membantumu menghitung
        <br /> pendapatan bersih dan akumulasi produk yang <br /> terjual{" "}
      </small>
    </div>
  </Flex>
);

const CompleteDataStepMobile = () => (
  <Flex flexDirection="column" gap="8px">
    <h5 style={{ margin: 0 }}>
      Terakhir, lengkapin ini dulu <br /> yuk!
    </h5>
    <small style={{ fontSize: "12px", lineHeight: "20px" }}>
      Kamu harus melengkapi beberapa data dulu <br /> untuk bisa mulai berjualan
      di Renos. Cuma <br /> sebentar kok!
    </small>
  </Flex>
);

const SuccessStepMobile = () => (
  <Flex flexDirection="column" gap="8px">
    <h5 style={{ margin: 0 }}>
      Selamat Berjualan dan Semoga <br /> Sukses!
    </h5>
    <small style={{ fontSize: "12px", lineHeight: "20px" }}>
      Kami senang dengan antusiasme kamu untuk <br /> berjualan di Renos. Kami
      sangat berharap agar kamu <br /> bisa sukses seperti penjual lainnya!
    </small>
  </Flex>
);

export const stepTourWeb = [
  {
    selector: ".welcome-step",
    content: <WelcomeStep />,
    position: [200, 100],
    padding: 0,
    totalSteps: 0,
    showBadge: false,
  },
  {
    selector: ".calculate-step",
    position: "top",
    content: <CalculateSalesStep />,
    padding: 0,
    totalSteps: 0,
    showBadge: false,
  },
  {
    selector: ".complete-step",
    content: <CompleteDataStep />,
    position: "top",
    disableInteraction: false,
    padding: 0,
    totalSteps: 0,
    showBadge: false,
  },
  {
    selector: ".success-step",
    content: <SuccessStep />,
    position: "center",
    padding: 0,
    totalSteps: 0,
    showBadge: false,
  },
];

export const stepTourMobile = [
  {
    selector: ".welcome-step-mobile",
    content: <WelcomeStepMobile />,
    position: "bottom",
    padding: 0,
    totalSteps: 0,
    showBadge: false,
  },
  {
    selector: ".calculate-step-mobile",
    content: <CalculateSalesStepMobile />,
    position: [40, 20],
    padding: 0,
    totalSteps: 0,
    showBadge: false,
  },
  {
    selector: ".complete-step-mobile",
    content: <CompleteDataStepMobile />,
    position: "bottom",
    padding: 0,
    totalSteps: 0,
    showBadge: false,
  },
  {
    selector: ".success-step-mobile",
    content: <SuccessStepMobile />,
    position: [-20, 200],
    padding: 0,
    totalSteps: 0,
    showBadge: false,
  },
];

const useCustomTour = () => {
  const storedStep = parseInt(localStorage.getItem("currentStepUpdate")) || 0;
  const { currentStep, steps, setIsOpen, setCurrentStep } = useTour();
  const isLastStep = currentStep === steps.length - 1;
  const content = steps[currentStep]?.content;

  const changeStep = (index) => {
    localStorage.setItem("currentStepUpdate", index);
    setCurrentStep(index);
  };

  useEffect(() => {
    setCurrentStep(storedStep);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setCurrentStep]);

  useEffect(() => {
    if (currentStep !== 3) {
      localStorage.setItem("currentStepUpdate", currentStep);
    }
  }, [currentStep]);

  return isMobile ? (
    <Flex flexDirection="column" position="relative">
      {currentStep === 0 && <TriangleFirst isMobile />}
      {currentStep === 1 && <TriangleTwo isMobile />}
      {currentStep === 2 && <TriangleThree isMobile />}

      <Flex flexDirection="column">
        {content}
        {!isLastStep && (
          <Flex margin="16px 0">
            {steps.slice(0, 3).map((_, index) => (
              <Flex
                key={index}
                width="12px"
                height="12px"
                cursor="pointer"
                margin="0 5px"
                borderRadius="50%"
                background={index === currentStep ? "#2327BE" : "#E1E1E3"}
                onClick={() => setCurrentStep(index)}
              />
            ))}
          </Flex>
        )}

        {!isLastStep ? (
          <Flex justifyContent="space-between" alignItems="center">
            <Button
              size="small"
              variant="secondary"
              onClick={() => {
                localStorage.setItem("currentStepUpdate", 0);
                setCurrentStep(3);
              }}
            >
              Lewati
            </Button>
            <Flex gap="8px">
              <Button
                size="small"
                style={{
                  background: currentStep === 0 ? "#E1E1E3" : "#2327BE",
                }}
                icon={<Icon size={14} name="Left-outline" color="white" />}
                variant="circular"
                onClick={() => {
                  setCurrentStep((s) => Math.max(0, s - 1));
                }}
                disabled={currentStep === 0}
              />

              <Button
                size="small"
                style={{ background: "#2327BE", color: "white" }}
                icon={<Icon size={14} name="Right-outline" color="white" />}
                variant="circular"
                onClick={() => {
                  if (isLastStep) {
                    setIsOpen(false);
                  } else {
                    setCurrentStep((s) => s + 1);
                  }
                }}
              />
            </Flex>
          </Flex>
        ) : (
          <Flex margin="16px 0 0 0">
            <Button
              isBlock
              size="small"
              variant="secondary"
              onClick={() => {
                setIsOpen(false);
              }}
            >
              Terimakasih
            </Button>
          </Flex>
        )}
      </Flex>
    </Flex>
  ) : (
    <Flex flexDirection="column" position="relative">
      {currentStep === 0 && <TriangleFirst />}
      {currentStep === 1 && <TriangleTwo />}
      {currentStep === 2 && <TriangleThree />}

      <Flex flexDirection="column">
        {content}
        {!isLastStep && (
          <Flex margin="16px 0">
            {steps.slice(0, 3).map((_, index) => (
              <Flex
                key={index}
                width="12px"
                height="12px"
                cursor="pointer"
                margin="0 5px"
                borderRadius="50%"
                background={index === currentStep ? "#2327BE" : "#E1E1E3"}
                onClick={() => changeStep(index)}
              />
            ))}
          </Flex>
        )}

        {!isLastStep ? (
          <Flex justifyContent="space-between" alignItems="center">
            <Button
              size="small"
              variant="secondary"
              onClick={() => {
                localStorage.setItem("currentStepUpdate", 0);
                setCurrentStep(3);
              }}
            >
              Lewati
            </Button>
            <Flex gap="8px">
              <Button
                size="small"
                style={{
                  background: currentStep === 0 ? "#E1E1E3" : "#2327BE",
                }}
                icon={<Icon size={14} name="Left-outline" color="white" />}
                variant="circular"
                onClick={() => {
                  setCurrentStep((s) => Math.max(0, s - 1));
                }}
                disabled={currentStep === 0}
              />

              <Button
                size="small"
                style={{ background: "#2327BE", color: "white" }}
                icon={<Icon size={14} name="Right-outline" color="white" />}
                variant="circular"
                onClick={() => {
                  if (isLastStep) {
                    setIsOpen(false);
                  } else {
                    setCurrentStep((s) => s + 1);
                  }
                }}
              />
            </Flex>
          </Flex>
        ) : (
          <Flex margin="16px 0 0 0">
            <Button
              isBlock
              size="small"
              variant="secondary"
              onClick={() => {
                setIsOpen(false);
              }}
            >
              Terimakasih
            </Button>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};

export default useCustomTour;

export const getStoredStep = () => {
  return parseInt(localStorage.getItem("currentStepUpdate")) || 0;
};

const getMarginLeftValue = () => {
  if (isMobile) {
    switch (getStoredStep()) {
      case 0:
        return 40;
      case 2:
        return 40;
      default:
        return 0;
    }
  } else {
    switch (getStoredStep()) {
      case 0:
        return 80;
      case 1:
        return 50;
      case 2:
        return -50;
      default:
        return 1;
    }
  }
};

const getMarginTopValue = () => {
  if (isMobile) {
    switch (getStoredStep()) {
      case 0:
        return -40;
      case 2:
        return 40;
      default:
        return 0;
    }
  } else {
    return 0;
  }
};

export const styles = {
  popover: (base) => {
    const updatedStyles = {
      ...base,
      "--reactour-accent": "transparent",
      borderRadius: 16,
      marginLeft: getMarginLeftValue(),
      marginTop: getMarginTopValue(),
      padding: "16px 24px",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    };

    return updatedStyles;
  },
  maskArea: (base) => ({ ...base, rx: 16 }),
};
