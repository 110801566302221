import { Flex, Skeleton, range } from "renos-ui";

const LoadingOpenStore = () => {
  return range(7).map((_, i) => (
    <Flex alignItems="center">
      <Flex width="100%" gap="16px" key={i}>
        <Skeleton width="34px" height="34px" />
        <Skeleton width="90%" height="34px" />
      </Flex>
    </Flex>
  ));
};

export default LoadingOpenStore;
