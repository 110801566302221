import { Flex, Box, Text, Toggle } from "renos-ui";

const Automatic = ({
  checkDefault,
  handleActiveSwitch,
  handleDisabled,
  checkedAutomate,
}) => {
  return (
    <Flex
      padding="24px"
      alignItems="center"
      backgroundColor="black5"
      borderRadius="8px 8px 0px 0px"
      gap="16px"
      alignContent="center"
    >
      <Box
        onClick={
          checkDefault?.length === 0 ? handleDisabled : handleActiveSwitch
        }
      >
        <Toggle checked={checkedAutomate} />
      </Box>

      <Text color="black75" weight="bold">
        Aktifkan template balasan ulasan otomatis otomatis
      </Text>
      {checkedAutomate && (
        <Text color="black50">
          (untuk ulasan dengan rating bintang 4 dan 5)
        </Text>
      )}
    </Flex>
  );
};

export default Automatic;
