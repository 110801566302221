import { useEffect, useMemo, useState } from "react";
import {
  Button,
  Checkbox,
  Dialog,
  Divider,
  Flex,
  Grid,
  Icon,
  Input,
  Text,
  cloneDeep,
} from "renos-ui";
import EmptyData from "./EmptyData";

const ModalCity = ({
  isOpen,
  onClose,
  selectedIndex,
  dataCoverage,
  setDataCoverage,
  mutateDistrict,
  mutateCity,
  isLoadingCity,
}) => {
  const [dataModal, setDataModal] = useState({
    listCity: [],
    selectedCity: [],
    changed: false,
  });
  const [dataSearch, setDataSearch] = useState([]);

  const isIncludeSelected = (idCity) => {
    const { selectedCity } = dataModal;
    const dataChecker = selectedCity.filter(
      (filterCity) => filterCity.city_id === idCity
    );

    return dataChecker.length > 0 && true;
  };

  const handleChangeCheckBox = (dataCity) => {
    let tempSelectedCity = dataModal.selectedCity;

    if (isIncludeSelected(dataCity.city_id)) {
      tempSelectedCity = tempSelectedCity.filter(
        (filterSelected) => filterSelected.city_id !== dataCity.city_id
      );
    } else {
      tempSelectedCity.push(dataCity);
    }
    setDataModal((prevState) => ({
      ...prevState,
      selectedCity: tempSelectedCity,
      changed: true,
    }));
  };

  const handleSave = () => {
    const tempCoverage = dataCoverage;
    tempCoverage[selectedIndex].selectedCity = dataModal.selectedCity;

    const arrSelectedCity = tempCoverage[selectedIndex].selectedCity.map(
      (item) => item.city_id
    );

    const filteredDistrict = tempCoverage[
      selectedIndex
    ].selectedDistrict.filter((item) => arrSelectedCity.includes(item.city_id));
    tempCoverage[selectedIndex].selectedDistrict = filteredDistrict;

    setDataCoverage([...tempCoverage]);

    const payload = `[${dataModal.selectedCity
      .map((cities) => cities.city_id)
      .join(",")}]`;
    mutateDistrict({ city_id: payload, indexCoverage: selectedIndex });
    onClose();
  };

  const isCheckedAll = useMemo(() => {
    const listCityId = dataSearch?.map((cities) => cities.city_id);
    const filterSelected = dataModal?.selectedCity?.filter((filterCity) =>
      listCityId?.includes(filterCity.city_id)
    );
    const checkResult = {
      active: false,
      indeterminate: false,
    };
    if (filterSelected?.length >= listCityId?.length) checkResult.active = true;
    if (filterSelected?.length > 0 && !checkResult.active)
      checkResult.indeterminate = true;

    return checkResult;
  }, [dataModal, dataSearch]);

  const handleCheckAll = () => {
    let tempSelectedCity = dataModal.selectedCity;

    if (!isCheckedAll.active && !isCheckedAll.indeterminate) {
      tempSelectedCity = dataSearch;
    } else if (isCheckedAll.indeterminate) {
      const filterNotSelected = dataSearch.filter(
        (filterCity) => !isIncludeSelected(filterCity)
      );
      tempSelectedCity = tempSelectedCity.concat(filterNotSelected);
      const filterDuplicate = [
        ...new Map(
          tempSelectedCity.map((cities) => [cities.city_id, cities])
        ).values(),
      ];
      tempSelectedCity = filterDuplicate;
    } else {
      const listCityId = dataSearch.map((cities) => cities.city_id);
      const filterSelected = tempSelectedCity.filter(
        (filterCity) => !listCityId.includes(filterCity.city_id)
      );
      tempSelectedCity = filterSelected;
    }
    setDataModal((prevState) => ({
      ...prevState,
      selectedCity: tempSelectedCity,
      changed: true,
    }));
  };

  const handleSearch = (event) => {
    const filterCity = dataModal.listCity.filter((cities) =>
      cities.city_name.toLowerCase().includes(event.target.value)
    );
    setDataSearch([...filterCity]);
  };

  useEffect(() => {
    const selectedCoverage = dataCoverage[selectedIndex];

    if (selectedCoverage.listCity.length === 0) {
      mutateCity({
        ...selectedCoverage.selectedProvince,
        indexCoverage: selectedIndex,
      });
    }
    // eslint-disable-next-line
  }, [isOpen]);

  useEffect(() => {
    const selectedCoverage = dataCoverage[selectedIndex]
      ? dataCoverage[selectedIndex]
      : dataCoverage[selectedIndex - 1];

    if (!isLoadingCity) {
      setDataModal(cloneDeep(selectedCoverage));
      setDataSearch(cloneDeep(selectedCoverage?.listCity));
    }
    // eslint-disable-next-line
  }, [dataCoverage, isOpen, isLoadingCity]);

  return (
    <Dialog isVisible={isOpen} onClose={onClose} verticalCentered closable>
      <Flex width="100%" flexDirection="column" rowGap="24px">
        <Text variant="heading3" weight="bold">
          Pilih Kota / Kabupaten
        </Text>
        <Input
          placeholder="Cari nama kota/kabupaten"
          prefix={<Icon name="Search-outline" size={20} />}
          onChange={(event) => handleSearch(event)}
        />

        <Flex
          flexDirection="column"
          borderColor="black10"
          borderStyle="solid"
          borderWidth="1px"
          borderRadius="8px"
          px="16px"
        >
          <Flex gap="8px" py="16px" alignItems="center">
            <Checkbox
              checked={isCheckedAll.active}
              indeterminate={isCheckedAll.indeterminate}
              onChange={handleCheckAll}
            />
            <Text color="black75">Pilih Semua</Text>
          </Flex>
          <Divider />

          {dataSearch.length > 0 ? (
            <Grid
              gap="16px"
              gridTemplateColumns="196px 196px 196px"
              gridTemplateRows="24px 24px 24px 24px  24px"
              height="208px"
              overflowX="auto"
              width="100%"
              py="16px"
            >
              {dataSearch?.map((city, indexCity) => (
                <Flex
                  gap="8px"
                  alignItems="center"
                  width="196px"
                  height="24px"
                  key={`city-box-${indexCity}`}
                >
                  <Checkbox
                    checked={isIncludeSelected(city.city_id)}
                    onChange={() => handleChangeCheckBox(city)}
                  />
                  <Text color="black75" width="168px" lineClamp={1}>
                    {city.city_name}
                  </Text>
                </Flex>
              ))}
            </Grid>
          ) : (
            <Flex width="624px" height="236px" flexDirection="column" alignItems="center" justifyContent="center">
              <EmptyData />
            </Flex>
          )}
        </Flex>
        <Flex alignSelf="end" width="160px">
          <Button size="medium" isBlock onClick={handleSave}>
            Simpan
          </Button>
        </Flex>
      </Flex>
    </Dialog>
  );
};

export default ModalCity;