import { Flex, Button, Text, Icon } from "renos-ui";

const Header = ({ handleAdd, templateList }) => {
  return (
    <Flex
      justifyContent="space-between"
      alignItems="center"
      gap="24px"
      py="24px"
    >
      <Flex flexDirection="column" flexGrow="1">
        <Text variant="subtitle" weight="bold">
          Daftar Template Balasan Ulasan
        </Text>
        <Text color="black50">
          Kamu bisa membuat maksimal 5 template balasan untuk membalas ulasan
          pembeli
        </Text>
      </Flex>
      <Button
        preffix={
          <Flex style={{ pointerEvents: "none" }}>
            <Icon size={20} name="Plus-outline" />
          </Flex>
        }
        variant="secondary"
        onClick={handleAdd}
        disabled={templateList.length === 5}
      >
        Tambah Template
      </Button>
    </Flex>
  );
};

export default Header;
