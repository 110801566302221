import { Box, Divider, Flex, Text } from "renos-ui";

const Card = ({ data, isActive, onClick }) => {
  return (
    <Flex flexDirection="column" onClick={onClick} cursor="pointer">
      <Flex py="8px">
        <Flex
          gap="16px"
          alignItems="center"
          py="8px"
          backgroundColor={isActive ? "blue5" : "white"}
          width="100%"
          px="24px"
          borderLeftColor="blue50"
          borderLeftStyle="solid"
          borderLeftWidth={isActive ? "3px" : 0}
        >
          <Box
            as="img"
            src={data.tag_image_url}
            height="56px"
            width="56px"
            borderRadius="8px"
            alt="img-product"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = "/assets/images/empty-product.svg";
            }}
          />
          <Flex flexDirection="column" width="calc(100% - 80px)">
            <Flex style={{ wordBreak: "break-all" }}>
              <Text weight="bold" variant="subtitle" lineClamp="1">
                {data.tag.tag_name}
              </Text>
            </Flex>
            <Text color="black75" lineClamp={1}>
              {data.total_products} produk
            </Text>
          </Flex>
        </Flex>
      </Flex>
      <Flex px="24px">
        <Divider />
      </Flex>
    </Flex>
  );
};

export default Card;
