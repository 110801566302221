import React, { useState } from "react";
import {
  Box,
  Checkbox,
  DatePicker,
  Divider,
  Flex,
  Icon,
  Input,
  Text,
  useToast,
} from "renos-ui";
import { translateDay, formatTime, formatTime2 } from "helpers";
import { StyledToggle } from "./styled";

const ScheduleItem = ({ list, setData, data, setIsDisabled, index }) => {
  const [isShowTime, setShowTime] = useState(Array(list.length).fill(false));
  const checkedCheckbox = list.is_active === true;
  const toast = useToast();

  const handleCheckBox = (isChecked) => {
    const updatedList = { ...list, is_active: isChecked };
    const updatedIndex = data.findIndex(
      (item) => item.day_humane === updatedList.day_humane
    );
    const updatedData = [...data];
    updatedData[updatedIndex] = updatedList;
    setData(updatedData);
  };

  const checkedToggle = list.is_fullday === true;

  const handleToggle = () => {
    const updatedList = { ...list };
    updatedList.is_fullday = !updatedList.is_fullday;

    if (updatedList.is_fullday) {
      updatedList.start = "00:00";
      updatedList.end = "23:00";
    }
    const updatedIndex = data.findIndex(
      (item) => item.day_humane === updatedList.day_humane
    );

    const updatedData = [...data];
    updatedData[updatedIndex] = updatedList;
    setData(updatedData);
  };

  const startTime = Number(list.start.split(":")[0]);
  const endTime = Number(list.end.split(":")[0]);
  setIsDisabled(endTime <= startTime);

  const disabled = !list.is_active || list.is_fullday;

  const handleAccordion = (index) => {
    setShowTime((prevShowTime) => {
      const newShowTime = [...prevShowTime];
      newShowTime[index] = !newShowTime[index];

      return newShowTime;
    });
  };

  return (
    <Flex flexDirection="column" gap="16px" width="100%">
      <Flex alignItems="center" gap="16px">
        <Flex alignItems="center" gap="16px" width="100%">
          <Checkbox
            checked={checkedCheckbox}
            onChange={(isChecked) => handleCheckBox(isChecked)}
          />
          <Text weight="bold" color="black50">
            {translateDay(list.day_humane)}
          </Text>
        </Flex>

        <Flex>
          <Icon
            name={isShowTime[index] ? "Up-outline" : "Down-outline"}
            onClick={() => handleAccordion(index)}
            color="black50"
          />
        </Flex>
      </Flex>

      {!isShowTime[index] && <Divider />}

      {isShowTime[index] && (
        <>
          <Flex gap="8px" alignItems="center">
            {disabled ? (
              <Box width="150px">
                <Input
                  size="small"
                  value={formatTime(list.start)}
                  sufix={<Icon name="Time-solid" size={20} />}
                  disabled={disabled}
                />
              </Box>
            ) : (
              <DatePicker.Time
                onChange={(_, value) => {
                  const time = value.split(":")[0];
                  const tempTime = Number(value.split(":")[0]);
                  const tempList = { ...list };
                  tempList.start = formatTime2(time);

                  if (tempList.end && tempList.end < tempList.start) {
                    tempList.end = formatTime2(tempTime + 1);
                  }

                  const tempData = data.concat(tempList);
                  const finalData = Array.from(
                    new Map(
                      tempData.map((item) => [item.day_humane, item])
                    ).values()
                  );
                  setData(finalData);
                }}
                customInput={() => {
                  return (
                    <Box width="150px">
                      <Input
                        size="small"
                        value={formatTime(list.start)}
                        sufix={<Icon name="Time-solid" size={20} />}
                        disabled={disabled}
                        readOnly={true}
                      />
                    </Box>
                  );
                }}
                isMinuteZero
              />
            )}

            <Box width="8px">
              <Divider />
            </Box>
            {disabled ? (
              <Box width="150px">
                <Input
                  size="small"
                  value={formatTime(list.end)}
                  sufix={<Icon name="Time-solid" size={20} />}
                  disabled={disabled}
                />
              </Box>
            ) : (
              <DatePicker.Time
                onChange={(_, value) => {
                  const time = value.split(":")[0];
                  const tempList = { ...list };
                  tempList.end = formatTime2(time);

                  if (tempList.start && tempList.end < tempList.start) {
                    tempList.start = tempList.end;

                    return toast({
                      label: "Jam Tutup Toko minimal 1 jam dari Jam Buka",
                      placement: "bottom",
                      backgroundColor: "red50",
                    });
                  }

                  const tempData = data.concat(tempList);
                  const finalData = Array.from(
                    new Map(
                      tempData.map((item) => [item.day_humane, item])
                    ).values()
                  );
                  setData(finalData);
                }}
                customInput={() => (
                  <Box width="150px">
                    <Input
                      size="small"
                      value={formatTime(list.end)}
                      sufix={<Icon name="Time-solid" size={20} />}
                      disabled={disabled}
                      readOnly={true}
                    />
                  </Box>
                )}
                isMinuteZero
              />
            )}
          </Flex>

          <Flex alignItems="center" gap="8px" height="100%">
            <Box onClick={list.is_active ? handleToggle : undefined}>
              <StyledToggle
                checked={checkedToggle}
                disabled={!list.is_active}
              />
            </Box>
            <Text variant="caption" weight="bold" color="black50">
              Buka 24 Jam
            </Text>
          </Flex>

          <Divider />
        </>
      )}
    </Flex>
  );
};

export default ScheduleItem;
