import styled from "styled-components";
import { Flex, colors } from "renos-ui";

export const StyledFlexInputSearch = styled(Flex)`
  > div > span {
    border-radius: 100px;
    border: none;
    background-color: ${colors.black5};

    > input {
      background-color: ${colors.black5};
    }
  }
`;
