import { Button, Dialog, Flex, Text, Spinner } from "renos-ui";

const DialogDelete = ({ disclosure, handleDelete, isLoadingDelete }) => {
  const { isOpen, onClose } = disclosure;

  return (
    <Dialog isVisible={isOpen} onClose={onClose} verticalCentered width="400px">
      <Flex
        width="100%"
        rowGap="24px"
        fontFamily="sans-serif"
        flexDirection="column"
      >
        <Flex flexDirection="column" rowGap="8px">
          <Text variant="heading3" weight="bold">
            Hapus Alamat?
          </Text>
          <Text variant="body">Alamat yang dipilih akan dihapus.</Text>
        </Flex>
        <Flex flexDirection="row" justifyContent="space-between" gap="1rem">
          <Button isBlock size="large" variant="tertiary" onClick={onClose}>
            Tidak, Batal
          </Button>
          <Button
            disabled={isLoadingDelete}
            isBlock
            size="large"
            variant="primary"
            onClick={handleDelete}
            preffix={isLoadingDelete && <Spinner color="white" size="8px" />}
          >
            Ya, Lanjut
          </Button>
        </Flex>
      </Flex>
    </Dialog>
  );
};

export default DialogDelete;
