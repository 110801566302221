import { useMemo } from "react";
import {
  Drawer,
  Flex,
  Icon,
  Input,
  Spinner,
  Text,
  isEmpty,
  useDisclosure,
} from "renos-ui";

const Picker = ({
  value,
  setValue,
  list = [],
  placeholder,
  label,
  isDisabled,
  setIsLowerParent,
  isLoading,
}) => {
  const { isOpen, onClose, onOpen } = useDisclosure({ isOpen: false });

  const handleShow = () => {
    if (!isDisabled && !isLoading) {
      onOpen();
      setIsLowerParent(true);
    }
  };

  const handleHide = () => {
    onClose();
    setIsLowerParent(false);
  };

  const handleOnSelect = (item) => {
    setValue(item);
    handleHide();
  };

  const textValue = useMemo(() => (isEmpty(value) ? "" : value.label), [value]);

  return (
    <Flex>
      <Input
        size="small"
        value={textValue}
        placeholder={placeholder}
        label={label}
        onClick={handleShow}
        disabled={isLoading || isDisabled}
        readOnly
        sufix={
          isLoading ? (
            <Spinner size="20px" color="white" />
          ) : (
            <Icon
              onClick={handleShow}
              name="Down-outline"
              size={20}
              color="black50"
            />
          )
        }
      />

      <Drawer isVisible={isOpen} onClose={handleHide} placement="bottom">
        <Flex flexDirection="column" width="100%" gap="16px" height="100%">
          <Flex gap="8px" alignItems="center">
            <Icon
              name="Close-outline"
              size={24}
              color="black50"
              onClick={handleHide}
            />
            <Text variant="subtitle" weight="bold">
              Waktu Pick Up
            </Text>
          </Flex>
          <Flex flexDirection="column">
            {list.map((item, indexItem) => (
              <Flex
                key={`picker-${indexItem}`}
                padding="16px"
                borderBottomWidth="1px"
                borderBottomColor="black10"
                borderBottomStyle="solid"
                onClick={() => handleOnSelect(item)}
              >
                <Text variant="subtitle" color="black75">
                  {item.label}
                </Text>
              </Flex>
            ))}
          </Flex>
        </Flex>
      </Drawer>
    </Flex>
  );
};

export default Picker;
