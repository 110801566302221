import ENDPOINTS from "constants/endpoints";
import { useQuery } from "react-query";
import client from "../client";

const getheartbeatFetcher = async () => {
  const { data } = await client.put(ENDPOINTS.heartbeat);

  return data;
};

export const useGetHeartBeat = (extraParams) => {
  return useQuery(["getheartbeat"], getheartbeatFetcher, {
    ...extraParams,
    refetchInterval: 300000,
  });
};
