import desktopUrl from "constants/desktopUrl";
import useQueryParams from "hooks/useQueryParams";
import { FilterProvider } from "providers/FilterProvider";
import React, { useMemo, useState } from "react";
import { useRef } from "react";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Button, Flex, Tabs, Text, useDisclosure } from "renos-ui";
import { useGetProductStatus } from "services/product";
import ProductTable from "./ProductTable";
import { TabsSkeleton } from "./Skeleton";
import TopBar from "./TopBar";
import Filter from "widgets/desktop/ProductFilter";

const ProductList = () => {
  const history = useHistory();
  const containerRef = useRef();
  const [page, setPage] = useState(1);
  const [filterParam, setFilterParam] = useState({
    keyword: "",
    ordering: "",
    order_by: "",
  });

  useEffect(() => {
    containerRef.current.scrollIntoView({
      behavior: "smooth",
    });
  }, [page]);

  const filterDisclosure = useDisclosure({ open: false });

  const getProductStatus = (activeTab) => {
    switch (activeTab) {
      case "active":
        return 1;

      case "nonActive":
        return 2;

      default:
        return null;
    }
  };

  const query = useQueryParams();

  const [activeTab, setActiveTab] = useState(query.get("tab-active") || "all");
  const [productStatus, setProductStatus] = useState(
    getProductStatus(activeTab) || null
  );

  const { push } = useHistory();

  const {
    data: dataProductStatus,
    isLoading: isStatusLoading,
    isFetching: isStatusFetching,
  } = useGetProductStatus();

  const isStatusNotReady = useMemo(
    () => isStatusLoading || isStatusFetching,
    [isStatusFetching, isStatusLoading]
  );

  const items = [
    {
      label: isStatusNotReady
        ? TabsSkeleton
        : `Semua Produk (${dataProductStatus?.all || 0})`,
      key: "all",
      width: "fit-content",
      children: () => <></>,
    },
    {
      label: isStatusNotReady
        ? TabsSkeleton
        : `Aktif (${dataProductStatus?.active || 0})`,
      key: "active",
      children: () => <></>,
    },
    {
      label: isStatusNotReady
        ? TabsSkeleton
        : `Nonaktif (${dataProductStatus?.nonActive || 0})`,
      key: "nonActive",
      children: () => <></>,
    },
  ];

  const handleChangeTabActive = (value) => {
    setActiveTab(value);
    setPage(1);
    setFilterParam({
      keyword: "",
      ordering: "desc",
      order_by: "product_discount_date_up",
    });
    setProductStatus(getProductStatus(value));
    push(`/products/list?tab-active=${value}`);
  };

  const [categoriesId, setCategoriesId] = useState([]);
  const [shippingIds, setShippingIds] = useState([]);

  useEffect(() => {
    setPage(1);
  }, [filterParam]);

  if (!isStatusNotReady && dataProductStatus?.totalStatus === 0)
    return (
      <Flex
        borderBottomLeftRadius="16px"
        borderBottomRightRadius="16px"
        boxShadow="0px 4px 4px rgba(20, 20, 32, 0.05)"
        backgroundColor="white"
        padding="24px"
        flexDirection="column"
        rowGap="24px"
        zIndex={0}
        justifyContent="center"
        alignItems="center"
        height="calc(100vh - 170px)"
      >
        <Flex flexDirection="column" rowGap="40px" alignItems="center">
          <img
            src="/assets/images/store-layout/no-product.svg"
            alt="no-notification-img"
            width="200px"
            height="200px"
          />
          <Flex flexDirection="column" alignItems="center" rowGap="24px">
            <Flex
              flexDirection="column"
              textAlign="center"
              rowGap="8px"
              width="300px"
            >
              <Text variant="heading3" weight="bold">
                Belum Ada Daftar Produk
              </Text>
              <Text>
                Yuk, tambahkan produk pertamamu untuk mulai berjualan.
              </Text>
            </Flex>
            <Button
              size="large"
              onClick={() => history.push(desktopUrl.PRODUCTS_ADD)}
            >
              Tambah Produk
            </Button>
          </Flex>
        </Flex>
      </Flex>
    );

  return (
    <FilterProvider
      value={{
        categoriesId,
        setCategoriesId,
        shippingIds,
        setShippingIds,
      }}
    >
      <Flex
        ref={containerRef}
        flexDirection="column"
        rowGap="8px"
        marginTop="20px"
      >
        <TopBar />
        <Flex
          borderBottomLeftRadius="16px"
          borderBottomRightRadius="16px"
          boxShadow="0px 4px 4px rgba(20, 20, 32, 0.05)"
          backgroundColor="white"
          padding="24px"
          flexDirection="column"
          rowGap="24px"
          zIndex={0}
        >
          <Tabs
            onChange={handleChangeTabActive}
            defaultActiveKey={activeTab}
            placement="bottom"
            items={items}
          />
          <Filter
            filterDisclosure={filterDisclosure}
            setFilterParam={setFilterParam}
            activeTab={activeTab}
          />
          <ProductTable
            productStatus={productStatus}
            dataProductStatus={dataProductStatus}
            activeTab={activeTab}
            page={page}
            setPage={setPage}
            filterDisclosure={filterDisclosure}
            filterParam={filterParam}
          />
        </Flex>
      </Flex>
    </FilterProvider>
  );
};

export default ProductList;
