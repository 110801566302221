import { Flex, Text, Button, Box, useToast, Divider } from "renos-ui";
import PickupLocation from "./pickupLocation";
import { useGetCouriers, useUpdateCouriers } from "services/logistics/courier";
import { useEffect } from "react";
import { useState } from "react";
import CourierCard from "./courierCard";
import SellerFleetStatus from "./sellerFleetStatus";
import { setGTM } from "helpers";

const Delivery = ({ handleChangeTabActive }) => {
  const toast = useToast();
  const [listCourier, setListCourier] = useState([]);
  const {
    data: couriersData,
    isLoading: isCouriersLoading,
    isError: isCouriersError,
    refetch: refetchGetCouriers,
    isFetching: isCouriersFetching,
  } = useGetCouriers();

  const { mutate, isLoading: isUpdateLoading } = useUpdateCouriers({
    onError: (error) => {
      const {
        response: {
          data: { frontend_message: message },
        },
      } = error;

      toast({
        label: message | "Pengaturan pengiriman gagal diubah.",
        placement: "top",
        backgroundColor: "red50",
      });
    },
    onSuccess: () => {
      toast({
        label: "Pengaturan pengiriman berhasil diubah.",
        placement: "top",
      });
      refetchGetCouriers();
    },
  });

  const handleSave = () => {
    setGTM("seller_center_section", {
      event_category: "store_settings | delivery",
      event_action: "click",
      event_label: "save_settings",
    });

    const params = [];
    // eslint-disable-next-line
    listCourier.map((courier) => {
      // eslint-disable-next-line
      courier.services.map((service) => {
        const {
          id,
          changed,
          assigned_to_user_store: assignedToUserStore,
          tempAssigned,
        } = service;

        if (changed && assignedToUserStore !== tempAssigned) {
          params.push({
            mode: assignedToUserStore ? "assign" : "unassign",
            courier_service_id: id,
          });
        }
      });
    });
    const payload = { data: params };
    mutate(payload);
  };

  const handleReset = () => {
    setGTM("seller_center_section", {
      event_category: "store_settings | delivery",
      event_action: "click",
      event_label: "reset_settings",
    });

    const tempList = listCourier.map((courier) => ({
      ...courier,
      services: courier.services.map((services) => ({
        ...services,
        assigned_to_user_store: services.tempAssigned,
        changed: false,
      })),
    }));

    setListCourier([...tempList]);
  };

  const changedChecker = () => {
    const findChanged = listCourier
      .map((courier) => ({
        ...courier,
        changedCount: courier.services.filter(
          (ftServices) => ftServices.changed
        ).length,
      }))
      .filter((ftChanged) => ftChanged.changedCount > 0).length;

    return findChanged;
  };

  useEffect(() => {
    if (!isCouriersLoading && !isCouriersError) {
      const tempList = couriersData.data.map((courier) => ({
        ...courier,
        services: courier.services.map((service) => ({
          ...service,
          tempAssigned: service.assigned_to_user_store,
        })),
      }));
      const modulus = 4 - (tempList.length % 4);

      if (modulus) {
        for (let i = 0; i < modulus; i++) {
          tempList.push({ fakeCard: true, services: [] });
        }
      }
      setListCourier(tempList);
    }
    // eslint-disable-next-line
  }, [isCouriersLoading, isCouriersFetching]);

  return (
    <Flex flexDirection="column" gap="24px" paddingTop="24px">
      <Flex flexDirection="column" gap="16px">
        <Flex flexDirection="column">
          <Text variant="subtitle" weight="bold" color="black100">
            Layanan Pengiriman
          </Text>
          <Text color="black75">
            Pilih layanan pengiriman yang tersedia di tokomu.
          </Text>
        </Flex>

        <SellerFleetStatus />
        <CourierCard
          list={listCourier}
          setList={setListCourier}
          isLoading={isCouriersLoading || isCouriersFetching}
          isError={isCouriersError}
          refetch={refetchGetCouriers}
        />
      </Flex>
      <PickupLocation handleChangeTabActive={handleChangeTabActive} />
      <Divider />
      <Flex paddingBottom="24px" flexDirection="row-reverse" gap="16px">
        <Box>
          <Button
            onClick={handleSave}
            size="large"
            disabled={isCouriersLoading || !changedChecker() || isUpdateLoading}
          >
            Simpan Pengaturan
          </Button>
        </Box>
        {changedChecker() ? (
          <Box>
            <Button
              variant="tertiary"
              onClick={handleReset}
              size="large"
              disabled={isUpdateLoading}
            >
              Reset Pengaturan
            </Button>
          </Box>
        ) : (
          ""
        )}
      </Flex>
    </Flex>
  );
};

export default Delivery;
