import { Flex, Text, colors } from "renos-ui";

const LandscapeOne = ({ imageRef, variant, data, setData }) => {
  const handleSetEdit = (type) => {
    setData((prev) => ({ ...prev, active: type }));
  };

  const renderBorder = (type) => {
    return data?.active === type && `1px solid ${colors.violet50}`;
  };

  return (
    <Flex flexDirection="column" gap="8px" paddingTop="100px">
      <Flex flexDirection="column">
        <Text variant="caption" color="black50" weight="bold">
          Template {variant}
        </Text>
        <Text variant="caption" color="black50">
          Slider Banner 2:1 (1208 x 604 px)
        </Text>
      </Flex>
      <Flex
        width="473px"
        height="239px"
        background="#1833B4"
        gap="24px"
        ref={imageRef}
      >
        <Flex
          width="50%"
          p="20px 0px 20px 20px"
          flexDirection="column"
          alignItems="baseline"
        >
          <Flex
            as="img"
            height="16px"
            src={data.logo}
            marginBottom="20px"
            onClick={() => handleSetEdit("logo")}
            cursor="pointer"
            border={renderBorder("logo")}
          />
          <Flex
            marginBottom="8px"
            onClick={() => handleSetEdit("title")}
            border={renderBorder("title")}
            cursor="pointer"
            width="190px"
          >
            <Text variant="heading3" weight="bold" color="white">
              {data.title}
            </Text>
          </Flex>
          <Flex
            marginBottom="16px"
            onClick={() => handleSetEdit("description")}
            cursor="pointer"
            style={{ wordBreak: "break-all" }}
            border={renderBorder("description")}
          >
            <Text variant="small" color="white" lineClamp={2}>
              {data.description}
            </Text>
          </Flex>

          <Flex
            p="6px 16px"
            borderRadius="6px"
            background="#FFB521"
            onClick={() => handleSetEdit("textButton")}
            border={renderBorder("textButton")}
            cursor="pointer"
            style={{ wordBreak: "break-all" }}
          >
            <Text color="black75" variant="small" weight="bold" lineClamp={1}>
              {data.textButton || "Belanja Sekarang!"}
            </Text>
          </Flex>

          <Flex
            marginTop="24px"
            onClick={() => handleSetEdit("tags")}
            border={renderBorder("tags")}
            cursor="pointer"
            style={{ wordBreak: "break-all" }}
          >
            <Text variant="small" color="white" lineClamp={2}>
              {data.tags}
            </Text>
          </Flex>
        </Flex>
        <Flex width="50%" p="14px" position="relative">
          <Flex
            as="img"
            alt="img"
            width="211px"
            height="211px"
            src="assets/images/store-layout/masking-square.png"
            position="absolute"
            onClick={() => handleSetEdit("img")}
            cursor="pointer"
          />
          <Flex
            right={0}
            top={0}
            position="absolute"
            width="83px"
            height="82px"
            opacity={data.isDiscountActive ? 1 : 0}
            backgroundImage="url('assets/images/store-layout/banner-2x1/ellipse_discount.svg')"
            cursor="pointer"
            onClick={() => handleSetEdit("discount")}
            border={renderBorder("discount")}
            backgroundSize="cover"
            alignItems="center"
            flexDirection="column"
          >
            <Flex fontSize="8px !important" marginLeft="8px" marginTop="12px">
              {data.discountName}
            </Flex>
            <Flex
              alignItems="center"
              gap="1px"
              marginTop="-8px"
              marginLeft="8px"
            >
              <Flex fontSize="37px !important" color="black75" fontWeight="800">
                {data.discount}
              </Flex>
              <Flex fontSize="13px !important" color="black75" fontWeight="800">
                {data.discountSuffix}
              </Flex>
            </Flex>
          </Flex>
          <Flex
            as="img"
            alt="img"
            width="211px"
            height="211px"
            src={data.img}
            borderRadius="32px"
            onClick={() => handleSetEdit("img")}
            cursor="pointer"
            border={renderBorder("img")}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default LandscapeOne;
