import { useMemo } from "react";
import { Flex, Text } from "renos-ui";
import PeriodPicker from "./PeriodPicker";

const PeriodSection = ({
  values,
  setValues,
  errors,
  setErrors,
  errorMsg,
  disabled,
  isOngoing,
}) => {
  const handleChange = (event) => {
    setValues({ ...values, ...event });
    setErrors({ ...errors, startDate: false, endDate: false });
  };

  const value = useMemo(() => {
    return { startDate: values.startDate, endDate: values.endDate };
  }, [values.startDate, values.endDate]);

  const isError = useMemo(() => errors.startDate || errors.endDate, [errors]);
  const errorText = useMemo(
    () => errorMsg.startDate || errorMsg.endDate,
    [errorMsg]
  );

  return (
    <Flex>
      <Flex flexDirection="column" gap="4px" width="320px">
        <Text color="black75" weight="bold">
          Periode Voucher
        </Text>
        <Text color="black50">
          Atur kapan waktu Vouchermu dapat <br /> digunakan.
        </Text>
      </Flex>
      <Flex gap="8px" width="584px" flexDirection="column">
        <Flex gap="24px">
          <PeriodPicker
            value={value}
            onChange={handleChange}
            isError={isError}
            disabled={isOngoing || disabled}
          />
        </Flex>
        {isError && (
          <Text variant="caption" color="red50">
            {errorText}
          </Text>
        )}
      </Flex>
    </Flex>
  );
};

export default PeriodSection;
