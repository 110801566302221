import { useQuery, useMutation } from "react-query";
import ENDPOINTS from "constants/endpoints";
import client from "../client";
import { formatTime, bouncer } from "helpers";

const getListFetcher = async (body) => {
  const { data } = await client.get(ENDPOINTS.chat_template, body);

  return data;
};

export const useGetListTemplate = (body, extraParams) => {
  return useQuery(["getListTempate"], () => getListFetcher(body), {
    ...extraParams,
    keepPreviousData: true,
  });
};

const saveTemplateChat = async (body) => {
  const response = await client.post(ENDPOINTS.chat_template, body);

  return response;
};

export const useSaveTemplate = (extraParams) =>
  useMutation((body) => saveTemplateChat(body), {
    ...extraParams,
  });

const saveTemplateActive = async (body) => {
  const response = await client.post(`${ENDPOINTS.chat_template}/toggle`, body);

  return response;
};

export const useActiveTemplate = (extraParams) =>
  useMutation((body) => saveTemplateActive(body), {
    ...extraParams,
  });

const deleteTemplateChat = async (body) => {
  const response = await client.delete(`${ENDPOINTS.chat_template}/${body}`);

  return response;
};

export const useDeleteTemplate = (extraParams) =>
  useMutation((body) => deleteTemplateChat(body), {
    ...extraParams,
  });

const orderTemplateChat = async (body) => {
  const response = await client.post(`${ENDPOINTS.chat_template}/order`, body);

  return response;
};

export const useOrderTemplate = (extraParams) =>
  useMutation((body) => orderTemplateChat(body), {
    ...extraParams,
  });

const getListAutoReply = async () => {
  const { data } = await client.get(ENDPOINTS.auto_reply);

  return data;
};

export const useGetListAutoReply = (extraParams) => {
  return useQuery(["getListAutoReply"], () => getListAutoReply(), {
    ...extraParams,
    keepPreviousData: true,
  });
};

const toggleTemplate = async (body) => {
  const response = await client.post(ENDPOINTS.auto_reply, body);

  return response;
};

export const useToggleTemplate = (extraParams) =>
  useMutation((body) => toggleTemplate(body), {
    ...extraParams,
  });

const editAutoReply = async (body) => {
  const response = await client.post(ENDPOINTS.auto_reply, body);

  return response;
};

export const useEditAutoReply = (extraParams) =>
  useMutation((body) => editAutoReply(body), {
    ...extraParams,
  });

const getStoreOpenViewFetcher = async (params) => {
  const { data } = await client.get(ENDPOINTS.hour_schedule, { params });
  const mappedData = data.data?.map((list) => {
    const { day_humane, start, end, id, is_active } = list;

    return (
      is_active && {
        day_humane,
        time: `${formatTime(start)} - ${formatTime(end)}`,
        id,
        is_active,
      }
    );
  });

  const bouncedData = bouncer(mappedData);

  const reducedData = bouncedData.reduce((accumulator, currentValue) => {
    if (accumulator[currentValue.time]) {
      accumulator[currentValue.time].day_humane = [
        accumulator[currentValue.time].day_humane,
        currentValue.day_humane,
      ]
        .join(",")
        .split(",");
    } else {
      accumulator[currentValue.time] = currentValue;
    }

    return accumulator;
  }, {});

  return Object.values(reducedData);
};

export const useGetStoreOpenView = (extraParams) =>
  useMutation((body) => getStoreOpenViewFetcher(body), {
    ...extraParams,
  });

const getUserQiscus = async (store_id) => {
  const body = {
    params: {
      store_id,
    },
  };

  const { data } = await client.get(ENDPOINTS.chat_info, body);

  return data;
};

export const useGetUserQiscus = (extraParams) =>
  useMutation((body) => getUserQiscus(body), {
    ...extraParams,
  });

const getListQuickChat = async () => {
  const { data } = await client.get(ENDPOINTS.chat_template);

  return data;
};

export const useGetQuickChat = (extraParams) => {
  return useQuery(["getListQuickChat"], () => getListQuickChat(), {
    ...extraParams,
    keepPreviousData: true,
  });
};

const getChatCountFetcher = async (params) => {
  const { data } = await client.get(ENDPOINTS.chat_unread_total, {
    params,
  });

  return data?.data?.total_unread;
};

export const useGetChatCount = (params, extraParams) =>
  useQuery(["getChatCountFetcher"], () => getChatCountFetcher(params), {
    ...extraParams,
    refetchInterval: 0.1 * 60 * 1000,
  });

const getResetChatCountFetcher = async (params) => {
  const { data } = await client.get(
    `${ENDPOINTS.reset_chat_unread}/${params.room_id}/reset`,
    {
      params,
    }
  );

  return data?.data?.total_unread;
};

export const useGetResetChatCount = (extraParams) =>
  useMutation((body) => getResetChatCountFetcher(body), { ...extraParams });

export const uploadImage = async (body) => {
  try {
    const { data } = await client.post(ENDPOINTS.upload_image_chat, body, {
      headers: { "Content-Type": "multipart/form-data" },
    });

    return data.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const useUploadChatImage = (extraParams) =>
  useMutation((body) => uploadImage(body), {
    ...extraParams,
  });

const getUserByQiscusId = async (user_qiscus_id) => {
  const body = {
    params: {
      user_qiscus_id,
    },
  };

  const { data } = await client.get(ENDPOINTS.chat_user_detail, body);

  return data;
};

export const useGetUserByQiscusId = (extraParams) =>
  useMutation((body) => getUserByQiscusId(body), {
    ...extraParams,
  });
