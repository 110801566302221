import { Flex, Input, colors } from "renos-ui";
import styled from "styled-components";

export const StyledFlexInputSearch = styled(Flex)`
  > div > span {
    border-color: ${colors.black5};
    // border-radius: 100px;
    background-color: ${colors.black5};
    width: 448px;
    padding: 9px 12px;
    > input {
      background-color: ${colors.black5};
    }
  }
`;

export const StyledInputBorderLess = styled(Input)`
  border: 0;
  width: 448px;
`;
