import {
  Button,
  Flex,
  Spinner,
  Text,
  colors,
  range,
  useDisclosure,
} from "renos-ui";
import { StyledDialog } from "./styled";
import { useEffect, useRef, useState } from "react";
import LandscapeOne from "./template/landscape/LandscapeOne";
import Editor from "./Editor";
import { toBlob } from "html-to-image";
import LandscapeTwo from "./template/landscape/LandscapeTwo";
import ModalConfirm from "../../../../component/ModalDelete";
import { imgCompressor } from "helpers";
import LandscapeThree from "./template/landscape/LandscapeThree";
import SquareOne from "./template/square/SquareOne";
import SquareTwo from "./template/square/SquareTwo";
import SquareThree from "./template/square/SquareThree";
import SquareFour from "./template/square/SquareFour";
import SquareFive from "./template/square/SquareFive";
// import SquareSix from "./template/square/SquareSix";

const BannerCreator = ({
  isOpen,
  onClose,
  isSquare,
  isLoading,
  handleUpload,
}) => {
  const imageRef = useRef(null);
  const defaultData = {
    logo: "/assets/images/renos-white.svg",
    img: "/assets/images/store-layout/default_square.png",
    imgSquare: "/assets/images/store-layout/default_square.png",
    imgSquareTwo: "/assets/images/store-layout/default_square_2.jpg",
    imgSquareZoomout:
      "/assets/images/store-layout/default_square_2_zoomout.jpeg",
    imgLandscape: "/assets/images/store-layout/default_landscape.png",
    discountSuffix: "%",
    discount: "50",
    discountName: "Diskon Hingga",
    discountNameSquare: "Diskon",
    title: "One-Stop Home Living Solution",
    description: "Lorem ipsum dolor sit amet",
    descriptionProduct: "Lorem ipsum dolor sit amet",
    textButton: "Belanja Sekarang!",
    tags: "#BestieRumahmu",
    active: "",
    price: "Rp 500.000",
    discountPrice: "Rp 250.000",
    isDiscountActive: true,
    productName: "Bangku Cafe Kayu",
    textPeriod: "Periode Promosi: 12 - 13 Januari",
    newProduct: "Produk Baru!",
    textSeeProduct: "Lihat Produk",
    textEvent: "10.10!",
    textEventFooter: "Belanja sekarang!",
    textInfo: "Informasi Penting!",
    textLong: `Syarat dan Ketentuan Return Barang: \n 
Pelanggan dapat melakukan pengembalian barang apabila : 
a. Barang pesanan diterima dalam keadaan cacat, rusak (kain yang bolong/ sobek, ada noda, aksesoris lepas) yang terlihat jelas dan menggangu.
b. Barang yang diterima tidak sesuai pesanan (produk atau warna). 
c. Konfirmasi retur dapat dilakukan dalam jangka waktu 1 x 24 jam sejak barang diterima dan menghubungi langsung Customer Service kami dengan mengirimkan keluhan dan bukti foto cacat ataupun rusak. 
d. Barang yang diretur harus memenuhi syarat sebagai berikut :
   - Barang yang di retur dalam keadaan sama seperti barang yang diterima 
   - Tidak melepas kelengkapan barang, seperti merk, hang tag, aksesoris lainnya. 
   - Belum pernah digunakan beraktifitas (kecuali dicoba atau tes pakai) 
e. Biaya retur barang sepenuhnya ditanggung oleh kami, kecuali kesalahan bukan terjadi karena cacat barang, salah kirim produk dan warna dari pihak kami. 
f. Jika barang yang diretur habis, bisa diganti dengan warna yang lain dengan produk serupa atau dengan refund uang sesuai dengan harga barang dan kesepakatan. `,
  };

  const [data, setData] = useState(defaultData);
  const [selected, setSelected] = useState(1);
  const [tempSelected, setTemplSelected] = useState(1);
  const [isLoadingImg, setIsloadingImg] = useState(false);
  const {
    isOpen: isOpenConfirm,
    onClose: onCloseConfirm,
    onOpen: onOpenConfirm,
  } = useDisclosure({ isOpen: false });

  const handleSave = async () => {
    setData((prev) => ({ ...prev, active: "" }));
    setIsloadingImg(true);
    const blobImg = await toBlob(imageRef.current, {
      canvasWidth: 1208,
      canvasHeight: 604,
    }).then((newImage) => newImage);
    const fileImg = new File([blobImg], `template-${selected}`, {
      type: "image/png",
    });

    const compressed =
      fileImg.size > 2 * 1024 * 1024
        ? await imgCompressor(fileImg, {
            maxSizeMB: 2,
            maxWidthOrHeight: 1920,
            useWebWorker: true,
          })
        : fileImg;

    handleUpload(compressed, setIsloadingImg);
    setIsloadingImg(false);
  };

  const handleChangeTemplate = (indexTemplate) => {
    const newIndex = indexTemplate + 1;
    setTemplSelected(newIndex);
    if (
      JSON.stringify(data) === JSON.stringify(defaultData) ||
      newIndex === selected
    )
      setSelected(newIndex);
    else onOpenConfirm();
  };

  const renderImageContainer = () => {
    const componentProperty = {
      data,
      setData,
      variant: selected,
      imageRef,
    };

    if (isSquare) {
      switch (selected) {
        case 1:
          return <SquareOne {...componentProperty} />;
        case 2:
          return <SquareTwo {...componentProperty} />;
        case 3:
          return <SquareThree {...componentProperty} />;
        case 4:
          return <SquareFour {...componentProperty} />;
        case 5:
          return <SquareFive {...componentProperty} />;
        // case 6:
        //   return <SquareSix {...componentProperty} />;
        default:
          return <Flex></Flex>;
      }
    }

    switch (selected) {
      case 1:
        return <LandscapeOne {...componentProperty} />;
      case 2:
        return <LandscapeTwo {...componentProperty} />;
      case 3:
        return <LandscapeThree {...componentProperty} />;
      default:
        return <Flex></Flex>;
    }
  };

  useEffect(() => {
    setData(defaultData);
    // eslint-disable-next-line
  }, [selected, isOpen]);

  return (
    <StyledDialog isVisible={isOpen} verticalCentered>
      <Flex flexDirection="column" width="100%">
        <Flex
          padding="24px 32px"
          justifyContent="space-between"
          borderBottomStyle="solid"
          borderBottomWidth="1px"
          borderBottomColor="black25"
        >
          <Text variant="heading3" weight="bold">
            Banner Kreator
          </Text>
          <Flex gap="16px">
            <Button size="medium" onClick={onClose} variant="secondary">
              Keluar
            </Button>
            <Button
              size="medium"
              onClick={handleSave}
              disabled={isLoadingImg || isLoading}
              preffix={
                (isLoadingImg || isLoading) && (
                  <Spinner size="16px" color="white" />
                )
              }
            >
              Simpan
            </Button>
          </Flex>
        </Flex>
        <Flex>
          <Flex
            width={isSquare ? "266px" : "calc(308px - 18px)"}
            flexDirection="column"
            height="546px"
            overflow="auto"
          >
            <Flex p="16px 24px">
              <Text variant="subtitle" weight="bold">
                Pilih Template
              </Text>
            </Flex>
            <Flex
              flexDirection="column"
              gap="8px"
              px={isSquare ? "49px" : "24px"}
              pb="16px"
            >
              {range(isSquare ? 5 : 3).map((_, indexTemplate) => (
                <Flex
                  flexDirection="column"
                  gap="8px"
                  key={`template-${indexTemplate}`}
                >
                  <Text variant="caption" color="black50" weight="bold">
                    Template {indexTemplate + 1}
                  </Text>
                  <Flex
                    onClick={() => {
                      handleChangeTemplate(indexTemplate);
                    }}
                    cursor="pointer"
                    src={`assets/images/store-layout/banner-${
                      isSquare ? "1x1" : "2x1"
                    }/template_${indexTemplate + 1}.svg`}
                    as="img"
                    alt="img-template"
                    width={isSquare ? "168px" : "242px"}
                    height={isSquare ? "168px" : "121px"}
                    backgroundColor="black10"
                    border={
                      indexTemplate + 1 === selected &&
                      `1px solid ${colors.violet50}`
                    }
                  />
                </Flex>
              ))}
            </Flex>
          </Flex>
          <Flex
            width="818px"
            justifyContent="center"
            backgroundColor="black5"
            gap={isSquare ? "143px" : "32px"}
          >
            {data.active && !isLoading && <Flex />}
            {renderImageContainer()}

            {data.active && !isLoading && (
              <Editor {...{ data, setData, defaultData, isSquare }} />
            )}
          </Flex>
        </Flex>
      </Flex>

      <ModalConfirm
        isOpen={isOpenConfirm}
        onClose={onCloseConfirm}
        onConfirm={() => {
          setSelected(tempSelected);
          onCloseConfirm();
        }}
        title="Ganti Template?"
        description="Semua pengaturan yang kamu buat tidak akan tersimpan. Yakin mau ganti template?"
        confirmText="Ya, Ganti"
      />
    </StyledDialog>
  );
};

export default BannerCreator;
