import React from "react";
import { Flex } from "renos-ui";

export const PanelBody = (props) => {
  return (
    <Flex flexDirection="column" height="auto">
      <Flex
        borderRadius="16px"
        border="0.5px solid"
        borderColor="black10"
        flexDirection="column"
        overflow="auto"
      >
        {props.children}
      </Flex>
    </Flex>
  );
};

export const PanelHeading = (props) => {
  return (
    <Flex padding="24px" backgroundColor="black5">
      {props.children}
    </Flex>
  );
};
