import {
  Flex,
  Icon,
  Text,
  colors,
  Tooltip,
  Skeleton,
  Box,
  Button,
} from "renos-ui";

const Card = ({
  dataTransactionSummary,
  dataTransaction,
  isActive,
  setActiveCard,
  isLoading,
  isError,
  refetch,
}) => {
  return (
    <Flex
      gap="16px"
      height="96px"
      width="162px"
      borderRadius="16px"
      cursor="pointer"
      flexGrow={1}
      flexBasis={0}
      backgroundColor="white"
      padding="26px 24px"
      boxShadow="0 4px 4px rgba(20, 20, 32, 0.05)"
      alignContent="center"
      borderBottom={isActive && `6px solid ${colors.blue50}`}
      onClick={() => setActiveCard(dataTransaction.key)}
    >
      <Flex flexDirection="column">
        <Text variant="heading3" weight="bold" marginBottom="4px">
          {isLoading ? (
            <Skeleton height="32.5px" width="120px" />
          ) : isError ? (
            <Box>
              <Button
                size="small"
                onClick={refetch}
                preffix={<Icon name="Reload-outline" size={15} />}
              >
                Muat Ulang
              </Button>
            </Box>
          ) : dataTransaction.summaryProperty === "product_views" ? (
            dataTransactionSummary[dataTransaction.summaryProperty] + " x"
          ) : (
            dataTransactionSummary[dataTransaction.summaryProperty]
          )}
        </Text>
        <Flex gap="8px" alignItems="center">
          <Text variant="caption"> {dataTransaction.name}</Text>

          <Tooltip placement="top-left" label={dataTransaction.tooltip}>
            <Icon name="Info-solid" size={16} color="black50" />
          </Tooltip>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Card;
