import { Box, Flex, Icon, Text, Upload, Button } from "renos-ui";

const RespondError = ({ register, isLoading, linkError }) => {
  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      py="39px"
      px="78px"
      gap="24px"
      height="286px"
      borderWidth="1px"
      borderColor="black10"
      borderStyle="dashed"
      borderRadius="8px"
    >
      <Flex gap="8px">
        <Icon color="red" name="Close-solid" size="20px" />
        <Text variant="title" weight="bold">
          Upload Gagal
        </Text>
      </Flex>
      <Box textAlign="center">
        <Text variant="body" color="black50">
          Download dan cek kembali format pengisian file yang kurang sesuai, ya.
        </Text>
      </Box>
      <Flex justifyContent="space-between" gap="16px">
        <Flex>
          <Upload {...register} accept=".xlsx, .xls, .csv">
            <Box width={linkError ? "160px" : "100%"}>
              <Button isBlock variant="secondary" disabled={isLoading}>
                Upload Lagi
              </Button>
            </Box>
          </Upload>
        </Flex>

        {linkError && (
          <Flex>
            <Box width="160px">
              <Button
                isBlock
                onClick={() => window.open(linkError, "_blank")}
                variant="primary"
                disabled={isLoading}
              >
                Download File
              </Button>
            </Box>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};

export default RespondError;
