import ENDPOINTS from "constants/endpoints";
import { useMutation } from "react-query";
import client from "../client";

const checkStoreFetcherName = async (name) => {
  const { data } = await client.get(ENDPOINTS.check_store, {
    params: name,
  });

  return data;
};

export const useCheckStoreName = (extraParams) =>
  useMutation(checkStoreFetcherName, {
    ...extraParams,
    enable: false,
  });
