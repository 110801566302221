import { Drawer, Flex, Text } from "renos-ui";
import { Wrapper } from "./../styled";

const PreviewDrawer = ({ disclosure, value }) => {
  return (
    <Drawer
      isVisible={disclosure.isOpen}
      height={"calc(100dvh - 100px)"}
      closable={true}
      onClose={() => disclosure.onClose()}
      placement="bottom"
      header={{
        title: value.title,
      }}
    >
      <Flex flexDirection="column" width="100%" pt="10px">
        <Wrapper>
          <Text>{value.note}</Text>
        </Wrapper>
      </Flex>
    </Drawer>
  );
};

export default PreviewDrawer;
