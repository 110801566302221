import * as yup from "yup";

export const StoreInfoSchema = yup.object().shape({
  contact_person: yup
    .string()
    .max(50, "Maksimal 50 karakter")
    .matches(/^[a-zA-Z ]*$/, "Nama tidak dapat mengandung angka atau simbol.")
    .required("Nama PIC Toko harus diisi."),
  email: yup
    .string()
    .max(50, "Maksimal 50 karakter")
    .email("Format email tidak sesuai.")
    .required("Alamat email harus diisi."),
  phone_number: yup
    .string()
    .required("Nomor kontak harus diisi.")
    .test(
      "is-valid-phone",
      "Format nomor hp Indonesia harus dimulai +62",
      (value) => {
        const plusPattern = /^\+/;
        const indonesiaPattern = /^\+62\d{0,15}$/;

        const containPlusInMiddle =
          value.replace(/[^+]/g, "").length > 0 && !plusPattern.test(value);

        if (
          (plusPattern.test(value) && !indonesiaPattern.test(value)) ||
          containPlusInMiddle
        ) {
          return false;
        }

        return true;
      }
    )
    .min(10, "Minimal 10 digit angka")
    .max(15, "Maksimal 15 digit angka"),
  description: yup.string().max(140, "Maksimal 140 karakter"),
});
