import { useState, useMemo } from "react";
import {
  Box,
  Checkbox,
  Flex,
  Icon,
  Text,
  useToast,
  useDisclosure,
  isEmpty,
  useDebounce,
} from "renos-ui";
import {
  useSaveProductShowcase,
  useUpdateProductShowcase,
  useGetProducts,
} from "services/product-showcase";

const useAddShowcase = ({
  titleMessage,
  setTitleMessage,
  addShowcaseDisclosure,
  refetchProductShowcase,
  tempTagId,
  mode,
}) => {
  const toast = useToast();
  const [searchParam, setSearchParam] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [isError, setIsError] = useState(false);
  /* eslint-disable no-unused-vars */
  const [_, setOrdering] = useState(null);
  const [dataProduct, setDataProduct] = useState({});
  const addDisclosure = useDisclosure({});
  const [isCheckedAll, setIsCheckedAll] = useState(false);
  const [checkedProducts, setCheckedProducts] = useState([]);
  const [paramsIds, setParamsIds] = useState("");
  const [tempTag, setTempTag] = useState(null);
  const [order, setOrder] = useState({
    order_by: "",
    ordering: "",
  });

  const [filters, setFilters] = useState({
    product_condition_ids: null,
    product_stock_value: null,
    product_variant_only: null,
    product_stock_comparison_value: null,
  });

  const dataFilter = [
    {
      value: null,
      label: "Semua",
    },
    {
      value: "product_condition_id 1",
      label: "Baru",
    },
    {
      value: "product_condition_id 2",
      label: "Bekas",
    },
    {
      value: "eq",
      label: "Stok Kosong",
    },
    {
      value: "varian",
      label: "Dengan varian",
    },
  ];

  const dataSort = [
    {
      value: null,
      label: "Terlaris",
    },
    {
      value: "name",
      label: "Nama A - Z",
    },
    {
      value: "name 2",
      label: "Nama Z - A",
    },
    {
      value: "desc",
      label: "Harga Tertinggi",
    },
    {
      value: "asc",
      label: "Harga Terendah",
    },
    {
      value: "stock",
      label: "Stok Tersedikit",
    },
    {
      value: "stock 2",
      label: "Stok Terbanyak",
    },
  ];

  const { isLoading: isLoadingDataProduct, isFetching } = useGetProducts(
    // {
    //   enabled: !isEmpty(tempTagId)
    // },
    {
      keyword: searchParam,
      product_condition_ids: filters.product_condition_ids,
      stock: filters.product_stock_value,
      product_stock_comparison_value: filters.product_stock_comparison_value,
      product_variant_only: filters.product_variant_only,
      order_by: order.order_by,
      ordering: order.ordering,
      tag_store_id:
        isFirstLoad && mode === "edit" ? tempTag || tempTagId : undefined,
      product_ids: paramsIds,
      limit: 50,
    },
    {
      onSuccess: (data) => {
        if (mode === "edit") {
          if (isFirstLoad) {
            setTempTag(tempTagId);
            setIsFirstLoad(false);
            const productIds = data?.data
              .map((item) => item.product_id)
              .join(",");
            setParamsIds(productIds);
            setDataProduct(data);
          } else {
            setCheckedProducts([]);
            setIsCheckedAll(false);
            setDataProduct(data);
            setTempTag(null);
          }
        } else {
          setCheckedProducts([]);
          setIsCheckedAll(false);
          setParamsIds(paramsIds);
          setDataProduct(data);
        }
      },
    }
  );

  useDebounce(() => setSearchParam(searchValue), [searchValue], 500);

  const { mutate: mutateSaveShowcase, isLoading: isLoadingSave } =
    useSaveProductShowcase({
      onError: (error) => {
        toast({
          label: error?.response?.data?.front_end_message,
          placement: "top",
        });
      },
      onSuccess: () => {
        toast({
          label:
            mode === "edit"
              ? "Berhasil mengubah etalase"
              : "Berhasil membuat etalase",
          placement: "top",
        });
        addShowcaseDisclosure.onClose();
        refetchProductShowcase();
      },
    });

  const { mutate: mutateUpdateShowcase, isLoading: isLoadingUpdate } =
    useUpdateProductShowcase({
      onError: (error) => {
        toast({
          label: error?.response?.data?.front_end_message,
          placement: "top",
        });
      },
      onSuccess: () => {
        toast({
          label: "Berhasil mengubah etalase",
          placement: "top",
        });
        addShowcaseDisclosure.onClose();
        refetchProductShowcase();
      },
    });

  const handleModal = () => {
    addDisclosure.onOpen();
  };

  const isNotReady = useMemo(
    () => isLoadingDataProduct || isFetching,
    [isLoadingDataProduct, isFetching]
  );

  const handleDelete = (productId) => {
    const tempIdsArray = paramsIds.split(",");

    const filteredIdsArray =
      productId instanceof Array
        ? tempIdsArray.filter((item) => !productId.includes(parseInt(item)))
        : tempIdsArray.filter((item) => parseInt(item) !== productId);

    productId instanceof Array && setCheckedProducts([]);
    setCheckedProducts([]);
    setIsCheckedAll(false);

    setParamsIds(filteredIdsArray.join(","));
    setDataProduct((prevState) => {
      return {
        ...prevState,
        data: dataProduct?.data?.filter((item) => {
          if (productId instanceof Array) {
            return !productId.includes(item.product_id);
          } else {
            return item.product_id !== productId;
          }
        }),
      };
    });
  };

  const onCheckAllClicked = () => {
    if (checkedProducts.length < dataProduct?.data.length) {
      setCheckedProducts(dataProduct?.data.map((data) => data.product_id));
    }

    if (checkedProducts.length === dataProduct?.data.length) {
      setCheckedProducts([]);
    }

    setIsCheckedAll((prev) => !prev);
  };

  const onCheckProductClicked = (id) => {
    if (isCheckedAll) {
      setIsCheckedAll(false);
    }

    if (checkedProducts.includes(id)) {
      const selectedIndex = checkedProducts.findIndex((data) => data === id);

      const tempChecked = [...checkedProducts];
      tempChecked.splice(selectedIndex, 1);

      return setCheckedProducts(tempChecked);
    }
    setCheckedProducts((prev) => [...prev, id]);
  };

  const isInterdeterminate = useMemo(() => {
    if (checkedProducts && dataProduct?.data) {
      return (
        checkedProducts.length > 0 &&
        checkedProducts.length < dataProduct?.data.length
      );
    }

    return false;
  }, [checkedProducts, dataProduct?.data]);

  const tableColumns = [
    {
      title: (
        <Checkbox
          checked={isCheckedAll}
          onChange={onCheckAllClicked}
          indeterminate={isInterdeterminate}
        />
      ),
      dataIndex: "check",
      key: "checkBox",
      render: (_, data) => {
        const { product_id: id } = data;

        return (
          <Flex>
            <Checkbox
              checked={checkedProducts.includes(id)}
              onChange={() => onCheckProductClicked(id)}
            />
          </Flex>
        );
      },
      width: "5%",
      align: "center",
    },
    {
      title: (
        <Text color="black75" weight="bold">
          Informasi Produk
        </Text>
      ),
      dataIndex: "product",
      key: "product",
      width: "50%",
      render: (_, data) => {
        const {
          product_image_url: image,
          product_name: name,
          product_skus: variant,
          product_type,
        } = data;

        const totalVariant = variant.length > 5 ? "+5" : variant.length;

        return (
          <Flex gap="16px">
            <Box
              as="img"
              src={image}
              minWidth="56px"
              height="56px"
              borderRadius="8px"
              backgroundSize="cover"
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = "/assets/images/empty-product.svg";
              }}
            />
            <Flex flexDirection="column" gap="4px">
              <Text weight="bold">{name}</Text>
              <Flex flexDirection="column">
                {!isEmpty(data?.product_skus[0]?.product_sku_mpn) && (
                  <Text color="black50" variant="caption">
                    SKU: {data?.product_skus[0]?.product_sku_mpn}
                  </Text>
                )}
                {product_type === "variant" && (
                  <Flex
                    justifyContent="center"
                    alignItems="center"
                    backgroundColor="black5"
                    borderRadius="100px"
                    width="62px"
                    height="16px"
                  >
                    <Text color="black50" weight="bold" variant="small">
                      {totalVariant} varian
                    </Text>
                  </Flex>
                )}
              </Flex>
            </Flex>
          </Flex>
        );
      },
    },
    {
      title: (
        <Text color="black75" weight="bold">
          Harga
        </Text>
      ),
      dataIndex: "formatted_product_price",
      key: "formatted_product_price",
      width: "20%",
      render: (_, data) => {
        const { formatted_product_price } = data;

        return <Text>{formatted_product_price}</Text>;
      },
    },
    {
      title: (
        <Text color="black75" weight="bold">
          Terjual
        </Text>
      ),
      dataIndex: "total_sold",
      key: "total_sold",
      width: "20%",
      render: (_, data) => {
        const { total_sold } = data;

        return <Text>{total_sold}</Text>;
      },
    },
    {
      title: "",
      dataIndex: "delete",
      key: "delete",
      width: "4%",
      render: (_, data) => {
        return (
          <Box>
            <Icon
              name="Trash-outline"
              size={20}
              cursor="pointer"
              onClick={() => handleDelete(data.product_id)}
            />
          </Box>
        );
      },
    },
  ];

  const handleBack = () => {
    addShowcaseDisclosure.onClose();
    setParamsIds("");
  };

  const handleChangeTitle = (event) => {
    setTitleMessage(event.target.value);
  };

  const handleSaveShowcase = () => {
    // kemungkinan akan digunakan kembali
    // const productIds = dataProduct?.data.map((item) => item.product_id);

    if (isEmpty(titleMessage)) {
      return setIsError(true);
    }

    if (isEmpty(paramsIds)) {
      return toast({
        label: "Produk belum dipilih",
        placement: "top",
      });
    }

    if (mode === "edit") {
      const body = {
        tag_name: titleMessage,
        tag_store_id: tempTagId,
        product_ids: paramsIds.split(",").map(Number),
      };
      mutateUpdateShowcase(body);
    } else {
      const body = {
        tag_name: titleMessage,
        product_ids: paramsIds.split(",").map(Number),
      };
      mutateSaveShowcase(body);
    }
  };

  const handleOrderChange = (name, values) => {
    switch (name.label) {
      case "Terlaris":
        setOrder({
          order_by: "total_sold",
          ordering: "desc",
        });
        break;
      case "Nama A - Z":
        setOrder({
          order_by: "product_name",
          ordering: "asc",
        });
        break;
      case "Nama Z - A":
        setOrder({
          order_by: "product_name",
          ordering: "desc",
        });
        break;
      case "Harga Tertinggi":
        setOrder({
          ordering: "desc",
          order_by: "product_price",
        });
        break;
      case "Harga Terendah":
        setOrder({
          ordering: "asc",
          order_by: "product_price",
        });
        break;
      case "Stok Tersedikit":
        setOrder({
          ordering: "asc",
          order_by: "product_stock",
        });
        break;
      case "Stok Terbanyak":
        setOrder({
          ordering: "desc",
          order_by: "product_stock",
        });
        break;
      default:
        setOrder({
          order_by: "",
          ordering: "",
        });
    }

    setOrdering(values?.value || "");
  };

  const handleFilterChange = (values) => {
    switch (values.label) {
      case "Baru":
        setFilters({
          product_condition_ids: 1,
        });
        break;
      case "Bekas":
        setFilters({
          product_condition_ids: 2,
        });
        break;
      case "Stok Kosong":
        setFilters({
          product_stock_comparison_value: "eq",
          product_stock_value: 0,
        });
        break;
      case "Dengan varian":
        setFilters({
          product_variant_only: true,
        });
        break;
      default:
        setFilters({
          product_condition_ids: null,
          product_stock_value: null,
          product_variant_only: null,
          product_stock_comparison_value: null,
        });
    }
  };

  return {
    tableColumns,
    handleBack,
    handleChangeTitle,
    handleSaveShowcase,
    handleOrderChange,
    handleFilterChange,
    isNotReady,
    isCheckedAll,
    onCheckAllClicked,
    handleModal,
    isLoadingSave,
    dataFilter,
    isLoadingDataProduct,
    dataSort,
    addDisclosure,
    paramsIds,
    isError,
    dataProduct,
    searchValue,
    setSearchValue,
    checkedProducts,
    setCheckedProducts,
    setParamsIds,
    filters,
    setFilters,
    setIsCheckedAll,
    handleDelete,
    isLoadingUpdate,
  };
};

export default useAddShowcase;
