import SwiperContext from "providers/SwiperProvider";
import { SwiperProvider } from "providers/SwiperProvider";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { Flex, Icon, Text, Button } from "renos-ui";
import { SwiperSlide, useSwiper } from "swiper/react";
import { StyledSwiper } from "./styled";

import { useHistory } from "react-router-dom";
import { useGetChecklistStore } from "services/dashboard";

const Store = () => {
  const [swiperInstance, setSwiperInstance] = useState();
  const history = useHistory();
  const { data, isLoading } = useGetChecklistStore();

  return (
    <SwiperProvider value={{ setSwiperInstance, swiperInstance }}>
      <Flex>
        <Flex
          height="100px"
          alignItems="center"
          position="relative"
          justifyContent="space-between"
        >
          <Flex position="relative">
            <StyledSwiper slidesPerView={1.3} spaceBetween={16}>
              {!isLoading &&
                data?.checklist.map((item, indexChecklist) => (
                  <SwiperSlide key={indexChecklist}>
                    <Flex
                      key={indexChecklist}
                      background={`url("/assets/images/mobile/account-bg.svg")`}
                      height="96px"
                      borderRadius="8px"
                      width="100%"
                      backgroundRepeat="no-repeat"
                      backgroundSize="100%"
                      justifyContent="space-between"
                      alignItems="center"
                      opacity={item.completed === true ? "50%" : null}
                    >
                      <Flex alignItems="center">
                        <Flex
                          flexDirection="column"
                          alignItems="center"
                          rowGap="8px"
                          px="16px"
                        ></Flex>
                        <Flex flexDirection="column" gap="6px">
                          <Flex flexDirection="column" gap="4px">
                            <Text color="white" weight="bold">
                              {item.title}
                            </Text>
                            <Text variant="caption" color="white">
                              {item.copywrite}
                            </Text>
                          </Flex>
                        </Flex>
                      </Flex>
                      <Flex px="16px">
                        <Button
                          size="small"
                          icon={
                            <Icon
                              color="blue"
                              size={16}
                              name={
                                item.completed === true
                                  ? "Checkmark-outline"
                                  : "Right-outline"
                              }
                            />
                          }
                          variant="circular"
                          disabled={item.completed === true}
                          onClick={() =>
                            history.push(`${item.completion_link_mobile}`)
                          }
                        />
                      </Flex>
                    </Flex>
                  </SwiperSlide>
                ))}
              <SwiperInstance />
            </StyledSwiper>
          </Flex>
        </Flex>
      </Flex>
    </SwiperProvider>
  );
};

const SwiperInstance = () => {
  const { setSwiperInstance } = useContext(SwiperContext);
  const swiper = useSwiper();

  useEffect(() => {
    if (swiper) return setSwiperInstance(swiper);
  }, [swiper, setSwiperInstance]);
};

export default Store;
