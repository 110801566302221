import { DatePicker, Divider, Flex, Icon, Input, Text } from "renos-ui";

const DateRange = ({ value, setValue }) => {
  const handleChange = (newValue, property) => {
    const tempValue = { ...value, [property]: newValue };

    if (value.end && property === "start") {
      if (newValue > value.end) tempValue.end = "";
    }

    if (value.start && property === "end") {
      if (newValue < value.start) tempValue.start = "";
    }

    setValue(tempValue);
  };

  return (
    <Flex flexDirection="column" gap="16px">
      <Flex flexDirection="column">
        <Text weight="bold" color="black75">
          Rentang Tanggal
        </Text>
        <Text variant="caption" color="black75">
          Atur filter produk berdasarkan rentang tanggal produk diupload.
        </Text>
      </Flex>
      <Flex alignItems="center" gap="8px">
        <Flex width="190px">
          <DatePicker
            selected={value.start}
            value={value.start}
            onChange={(newValue) => handleChange(newValue, "start")}
            dateFormat="dd MMM yyyy"
            placeholder="Mulai Tanggal"
            customInput={
              <Input
                placeholder="Mulai Tanggal"
                size="small"
                prefix={
                  <Icon name="Calendar-outline" size={20} color="black50" />
                }
              />
            }
          />
        </Flex>
        <Flex width="8px">
          <Divider />
        </Flex>
        <Flex width="190px">
          <DatePicker
            selected={value.end}
            value={value.end}
            onChange={(newValue) => handleChange(newValue, "end")}
            minDate={value.start}
            dateFormat="dd MMM yyyy"
            placeholder="Sampai Tanggal"
            customInput={
              <Input
                size="small"
                placeholder="Sampai Tanggal"
                prefix={
                  <Icon name="Calendar-outline" size={20} color="black50" />
                }
              />
            }
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default DateRange;
