import React, { useContext, useEffect, useState } from "react";
import StoreLayoutContext from "providers/StoreLayoutProvider";
import {
  Flex,
  Button,
  Divider,
  useToast,
  useDisclosure,
  Box,
  Text,
} from "renos-ui";
import { useUpdateHomePage } from "services/store-layout/homepage";
import Header from "../component/Header";
import NoData from "../component/NoData";
import ProductCard from "../component/ProductCard";
import ModalAdd from "./ModalAdd";
import ModalDelete from "../component/ModalDelete";

const BestSelling = ({ handleBack, id }) => {
  const toast = useToast();
  const disclosureAdd = useDisclosure({ isOpen: false });
  const disclosureDelete = useDisclosure({ isOpen: false });

  const [dataBestSelling, setDataBestSelling] = useState({ data: [] });

  const { dataHomePage, setDataHomePage } = useContext(StoreLayoutContext);

  const { mutate, isLoading: isUpdateHomePageLoading } = useUpdateHomePage({
    onSuccess: (data) => {
      const { message, frontend_message } = data;
      toast({
        label: frontend_message || message,
        placement: "top",
      });
      handleBack();
    },
    onError: (data) => {
      const {
        response: {
          data: { frontend_message, message },
        },
      } = data;
      toast({
        label: frontend_message || message,
        placement: "top",
        backgroundColor: "red50",
      });
    },
  });

  const handleSave = () => {
    const listIds = dataBestSelling.data.map((product) => product.product_id);
    mutate({
      type: id,
      ids: listIds,
    });
  };

  const handleSetHomePage = (newData) => {
    const tempDataHomePage = dataHomePage.map((homepage) => ({
      ...homepage,
      isActive:
        homepage.id === id ? newData.length > 0 && true : homepage.isActive,
      data: homepage.id === id ? newData : homepage.data,
      emptyWithFooter: homepage.id === id && true,
    }));
    setDataHomePage([...tempDataHomePage]);
  };

  const handleReset = () => {
    handleSetHomePage([]);
    disclosureDelete.onClose();
  };

  useEffect(() => {
    const selectedBestSelling = dataHomePage.filter(
      (homepage) => homepage.id === id
    )[0];
    selectedBestSelling.data = selectedBestSelling.data
      ? selectedBestSelling.data
      : [];
    setDataBestSelling(selectedBestSelling); // eslint-disable-next-line
  }, [dataHomePage]);

  return (
    <Flex flexDirection="column" gap="16px">
      <Header
        rightIcon={
          (dataBestSelling.data.length !== 0 ||
            dataBestSelling.emptyWithFooter) && (
            <Box cursor="pointer" onClick={disclosureAdd.onToggle}>
              <Text variant="caption" weight="bold" color="blue50">
                {dataBestSelling.data.length === 0
                  ? "Tambah Produk"
                  : "Ubah Produk"}
              </Text>
            </Box>
          )
        }
        handleBack={handleBack}
        title="Produk Terlaris"
      />
      {dataBestSelling.data?.length === 0 ? (
        <>
          <NoData
            title="Belum Ada Produk Terlaris"
            description="Yuk, pilih daftar produk terlaris terlebih dahulu."
            btnText="Tambah Produk"
            onClick={!dataBestSelling.emptyWithFooter && disclosureAdd.onToggle}
          />
          {dataBestSelling.emptyWithFooter && (
            <>
              <Divider />
              <Flex
                justifyContent="space-between"
                paddingBottom="16px"
                gap="16px"
              >
                <Button
                  isBlock
                  variant="tertiary"
                  size="medium"
                  onClick={handleBack}
                >
                  Batal
                </Button>
                <Button
                  size="medium"
                  isBlock
                  disabled={isUpdateHomePageLoading}
                  onClick={handleSave}
                >
                  Simpan
                </Button>
              </Flex>
            </>
          )}
        </>
      ) : (
        <>
          <Flex
            height="284px"
            overflowY="auto"
            flexDirection="column"
            width="100%"
            gap="16px"
          >
            {
              // eslint-disable-next-line
              dataBestSelling?.data.map((product, indexProduct) => {
                if (indexProduct < 12)
                  return (
                    <React.Fragment key={`product-card-${indexProduct}`}>
                      <ProductCard data={product} />
                      {indexProduct < 11 &&
                        indexProduct !== dataBestSelling.data.length && (
                          <Flex>
                            <Divider />
                          </Flex>
                        )}
                    </React.Fragment>
                  );
              })
            }
          </Flex>
          <Divider />
          <Flex gap="16px" paddingBottom="24px">
            {dataBestSelling.data.length > 0 ? (
              <Flex flexGrow={1}>
                <Button
                  size="medium"
                  variant="tertiary"
                  isBlock
                  onClick={disclosureDelete.onToggle}
                >
                  Hapus Pengaturan
                </Button>
              </Flex>
            ) : (
              <Flex
                flexGrow={1}
                flexBasis={dataBestSelling.data.length === 0 ? 0 : 1}
              >
                <Button
                  size="medium"
                  variant="tertiary"
                  isBlock
                  onClick={handleBack}
                >
                  Batalkan
                </Button>
              </Flex>
            )}
            <Flex
              flexGrow={1}
              flexBasis={dataBestSelling.data.length === 0 && 0}
            >
              <Button
                size="medium"
                isBlock
                disabled={isUpdateHomePageLoading}
                onClick={handleSave}
              >
                Simpan
              </Button>
            </Flex>
          </Flex>
        </>
      )}

      {disclosureAdd.isOpen && (
        <ModalAdd
          isOpen={disclosureAdd.isOpen}
          onClose={disclosureAdd.onClose}
          data={dataBestSelling.data}
          handleSetHomePage={handleSetHomePage}
          setData={(dataModal) =>
            setDataBestSelling({
              ...dataBestSelling,
              data: dataModal,
            })
          }
        />
      )}

      <ModalDelete
        isOpen={disclosureDelete.isOpen}
        onClose={disclosureDelete.onClose}
        onConfirm={handleReset}
      />
    </Flex>
  );
};

export default BestSelling;
