import LabelDetail from "containers/web/order-management/List/Card/Footer/component/PrintLabel/ModalPrint/LabelDetail";
import { useEffect, useRef, useState } from "react";
import ReactToPrint from "react-to-print";
import { Button, Divider, Flex, isEmpty } from "renos-ui";

const PrintLabel = () => {
  const [data, setData] = useState({});
  const componentRef = useRef();

  useEffect(() => {
    const dataSession = sessionStorage.getItem("label-prod-renos");

    if (dataSession) {
      setData(JSON.parse(dataSession));
    } else {
      window.location.href = "/sales/orders";
    }
  }, []);

  return (
    <Flex flexDirection="column" width="100%" justifyContent="center">
      {!isEmpty(data) && (
        <>
          <Flex padding="16px 24px" justifyContent="end">
            <ReactToPrint
              trigger={() => <Button>Cetak Label</Button>}
              content={() => componentRef.current}
              documentTitle={`renos_pesanan_${data.order_number}`}
              copyStyles={true}
            />
          </Flex>
          <Divider />
          <Flex
            style={{ transform: "scale(0.6)" }}
            marginLeft="-66px"
            marginTop="-99px"
          >
            <LabelDetail data={data} isOpen={true} refMobile={componentRef} />
          </Flex>
        </>
      )}
    </Flex>
  );
};

export default PrintLabel;
