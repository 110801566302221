import { useMemo } from "react";
import { Button, Drawer, Flex, Input, Text, useDisclosure } from "renos-ui";

const FormTemplate = ({
  mode,
  item,
  onCancel,
  disclosure,
  onSubmit,
  isFetching,
  setSelectedItem,
}) => {
  let title = "Template Chat";

  if (mode === "edit") {
    title = `Ubah ${title}`;
  } else if (mode === "add") {
    title = `Buat ${title}`;
  }

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setSelectedItem((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const { isOpen, onClose, onOpen } = useDisclosure({ isOpen: false });
  const isDisabledSaveButton = useMemo(
    () => isFetching || item?.title?.length < 5 || item?.message?.length < 5,
    [isFetching, item]
  );

  return (
    <>
      <Flex flexDirection="column" gap="16px" display={isOpen ? "none" : ""}>
        <Flex>
          <Text variant="subtitle" weight="bold">
            {title}
          </Text>
        </Flex>
        <Flex>
          <Input
            size="small"
            showCount
            maxLength={30}
            name="title"
            label="Nama Template"
            value={item?.title}
            onChange={handleChangeInput}
            disabled={isFetching}
            placeholder="Tulis template baru..."
          />
        </Flex>
        <Flex>
          <Input.TextArea
            rows={5}
            size="small"
            showCount
            maxLength={200}
            name="message"
            minLength={5}
            label="Isi Template"
            value={item?.message}
            onChange={handleChangeInput}
            disabled={isFetching}
            placeholder="Tulis template baru..."
          />
        </Flex>
        <Flex gap="16px" justifyContent="space-between">
          <Button
            isBlock
            size="medium"
            disabled={isFetching}
            onClick={mode === "edit" ? disclosure.onClose : onCancel}
            variant="tertiary"
          >
            Batal
          </Button>
          <Button
            isBlock
            size="medium"
            variant="primary"
            disabled={isDisabledSaveButton}
            onClick={mode === "edit" ? onOpen : onSubmit}
          >
            <Text
              weight="bold"
              color={isDisabledSaveButton ? "black25" : "white"}
            >
              {isFetching ? "Menyimpan..." : "Simpan"}
            </Text>
          </Button>
        </Flex>

        <Drawer
          isVisible={isOpen}
          onClose={onClose}
          placement="bottom"
          closable
        >
          <Flex flexDirection="column" gap="16px">
            <Flex>
              <Text variant="subtitle" weight="bold">
                Ubah Template Ini?
              </Text>
            </Flex>
            <Flex>
              <Text>
                Simpan pengubahan yang sudah kamu lakukan untuk template ini?
              </Text>
            </Flex>
            <Flex justifyContent="space-between" gap="16px" width="100%">
              <Button
                isBlock
                size="medium"
                variant="tertiary"
                onClick={onClose}
              >
                <Text weight="bold">Kembali</Text>
              </Button>
              <Button
                isBlock
                size="medium"
                variant="primary"
                onClick={onSubmit}
              >
                <Text weight="bold">Ya, Ubah</Text>
              </Button>
            </Flex>
          </Flex>
        </Drawer>
      </Flex>
    </>
  );
};

export default FormTemplate;
