import { colors, Input } from "renos-ui";
import styled from "styled-components";

export const InputStepper = styled(Input.Number)`
  padding: 0px;
  font-size: 10px;
  width: 36px;
  height: 22px;
  font-weight: bold;
  border-radius: 4px;
  text-align: center;
  background: ${colors.black5};
`;
