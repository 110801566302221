import { Flex, Icon, Input, Text, Toggle } from "renos-ui";
import { StyledLongTextArea, StyledTextArea } from "./styled";
import ImgUploader from "./component/ImgUploader";

const Editor = ({ data, setData, defaultData, isSquare }) => {
  const handleChange = (type, newValue) => {
    setData({
      ...data,
      [type]: newValue,
    });
  };

  const translateTitle = () => {
    switch (data?.active) {
      case "logo":
      case "imgSquare":
        return "Edit Gambar 1 (Logo)";
      case "imgSquareTwo":
      case "imgSquareZoomout":
        return "Edit Gambar 1 (Produk)";
      case "img":
      case "imgLandscape":
        return "Edit Gambar 2 (Produk)";
      case "title":
      case "price":
      case "productName":
      case "textEvent":
      case "textInfo":
        return "Edit Teks 1";
      case "description":
      case "descriptionProduct":
      case "discountPrice":
      case "textEventFooter":
      case "textLong":
        return "Edit Teks 2";
      case "textButton":
      case "textPeriod":
      case "newProduct":
        return "Edit Teks 3";
      case "tags":
      case "textSeeProduct":
        return "Edit Teks 4";
      case "discount":
      case "discountSquare":
      case "discountRound":
        return "Komponen 1";
      default:
        return "";
    }
  };

  const countMaxLength = () => {
    switch (data?.active) {
      case "description":
        return 65;
      case "textPeriod":
        return 50;
      case "title":
        return 32;
      case "descriptionProduct":
        return 30;
      case "textButton":
      case "textInfo":
        return 20;
      case "tags":
        return 20;
      case "productName":
        return 18;
      case "textEventFooter":
        return 17;
      case "textSeeProduct":
        return 16;
      case "newProduct":
        return 15;
      case "price":
      case "discountPrice":
        return 12;
      case "textEvent":
        return 6;
      default:
        return 0;
    }
  };

  const renderComponent = () => {
    switch (data?.active) {
      case "logo":
        return (
          <ImgUploader
            value={data[data?.active]}
            onChange={(newValue) => handleChange(data?.active, newValue)}
            defaultValue={defaultData[data?.active]}
            minWidth={150}
            minHeight={32}
            maxSize={2}
            description="(Rekomendasi logo berwarna putih dengan latar transparan.)"
          />
        );
      case "img":
        return (
          <ImgUploader
            value={data[data?.active]}
            onChange={(newValue) => handleChange(data?.active, newValue)}
            defaultValue={defaultData[data?.active]}
            minWidth={500}
            minHeight={500}
            ratio="square"
            maxSize={2}
          />
        );
      case "imgSquare":
      case "imgSquareTwo":
      case "imgSquareZoomout":
        return (
          <ImgUploader
            value={data[data?.active]}
            onChange={(newValue) => handleChange(data?.active, newValue)}
            defaultValue={defaultData[data?.active]}
            minWidth={300}
            minHeight={300}
            ratio="square"
            maxSize={2}
          />
        );
      case "imgLandscape":
        return (
          <ImgUploader
            value={data[data?.active]}
            onChange={(newValue) => handleChange(data?.active, newValue)}
            defaultValue={defaultData[data?.active]}
            minWidth={1208}
            minHeight={604}
            maxSize={1}
            ratio="landscape"
          />
        );
      case "title":
      case "description":
      case "textButton":
      case "tags":
      case "price":
      case "discountPrice":
      case "productName":
      case "textPeriod":
      case "textSeeProduct":
      case "newProduct":
      case "textEvent":
      case "textEventFooter":
      case "textInfo":
      case "descriptionProduct":
        return (
          <StyledTextArea
            value={data[data?.active]}
            showCount
            maxLength={countMaxLength()}
            onChange={(event) =>
              handleChange(data?.active, event.currentTarget.value)
            }
            placeholder=""
          />
        );
      case "textLong":
        return (
          <StyledLongTextArea
            value={data.textLong}
            showCount
            maxLength={1200}
            onChange={(event) =>
              handleChange("textLong", event.currentTarget.value)
            }
            placeholder=""
          />
        );
      case "discount":
      case "discountSquare":
      case "discountRound":
        return (
          <Flex flexDirection="column" gap="32px" width="100%">
            <Flex justifyContent="space-between">
              <Text variant="caption" color="black50" weight="bold">
                Komponen
              </Text>
              <Flex
                onClick={() =>
                  handleChange("isDiscountActive", !data.isDiscountActive)
                }
              >
                <Toggle checked={data.isDiscountActive} />
              </Flex>
            </Flex>
            <Flex flexDirection="column" gap="24px">
              {data?.active !== "discountRound" && (
                <Input
                  value={
                    data[
                      data?.active === "discountSquare"
                        ? "discountNameSquare"
                        : "discountName"
                    ]
                  }
                  label="Teks 1"
                  onChange={(event) =>
                    handleChange(
                      data?.active === "discountSquare"
                        ? "discountNameSquare"
                        : "discountName",
                      event.currentTarget.value
                    )
                  }
                  showCount
                  maxLength={data?.active === "discountSquare" ? 6 : 17}
                />
              )}
              <Flex flexDirection="column" gap="8px" alignItems="flex-end">
                <Input.Number
                  value={data["discount"]}
                  label={`Teks ${data?.active === "discountRound" ? 1 : 2}`}
                  onChange={(event) =>
                    handleChange("discount", event < 99 ? event : 99)
                  }
                  maxLength={2}
                />
                <Text variant="caption" color="black50">
                  {data?.discount > 0 ? data?.discount.toString().length : 0}/2
                </Text>
              </Flex>
              <Input
                value={data["discountSuffix"]}
                label={`Teks ${data?.active === "discountRound" ? 2 : 3}`}
                onChange={(event) =>
                  handleChange("discountSuffix", event.currentTarget.value)
                }
                showCount
                maxLength={1}
              />
            </Flex>
          </Flex>
        );
      default:
        return <></>;
    }
  };

  return (
    <Flex
      width={isSquare ? "289px" : "282px"}
      backgroundColor="white"
      flexDirection="column"
      alignItems="normal"
    >
      <Flex
        justifyContent="space-between"
        alignItems="center"
        padding="16px 24px"
      >
        <Text variant="subtitle" weight="bold">
          {translateTitle()}
        </Text>
        <Icon
          onClick={() => handleChange("active", "")}
          name="Close-outline"
          color="black25"
          size={24}
          cursor="pointer"
        />
      </Flex>

      <Flex p="24px">{renderComponent()}</Flex>
    </Flex>
  );
};

export default Editor;
