import { colors, Box, Dialog } from "renos-ui";
import styled from "styled-components";

export const StyledDialog = styled(Dialog)`
  padding: 24px 24px 0 24px;
  position: relative;
`;

export const Wrapper = styled(Box)`
  width: 100%;
  margin-top: 16px;
  margin-bottom: 24px;
  .react-datepicker {
    border: none;
    box-shadow: none;
    width: 100%;
    padding: 0;
  }
  .react-datepicker__day--today {
    color: ${colors.black75};
    font-weight: normal;
    background-color: ${colors.white};
  }
  .react-datepicker__day--in-range {
    color: ${colors.black75};
    background-color: ${colors.blue5};
  }
  .react-datepicker__day--selected,
  .react-datepicker__day--range-end,
  .react-datepicker__day:hover {
    color: ${colors.white};
    background-color: ${colors.blue50} !important;
  }

  .react-datepicker__day-name,
  .react-datepicker__time-name {
    display: flex;
    width: 36px;
    height: 24px;
    text-align: center;
    align-items: center;
    justify-content: center;
    align-content: center;
  }

  .react-datepicker__day--disabled {
    color: ${colors.black25};
    cursor: not-allowed;
  }
`;
