import { useContext, useEffect } from "react";
import { Flex, useDisclosure } from "renos-ui";
import EmptyShowcase from "./EmptyShowcase";
import StoreContext from "providers/StoreProvider";
import DashboardShowcase from "./dashboard";
import { useHistory } from "react-router-dom";
import desktopUrl from "constants/desktopUrl";

const Showcase = () => {
  const addShowcaseDisclosure = useDisclosure({});
  const { push } = useHistory();

  const {
    storeData: {
      data: { has_product: hasProduct },
    }, refetchCheckStore
  } = useContext(StoreContext);

  useEffect(() => {
    refetchCheckStore();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAdd = () => {
    addShowcaseDisclosure.onOpen();
  };

  return hasProduct ? (
    <DashboardShowcase hasProduct={hasProduct} />
  ) : (
    <Flex
      padding="16px 24px"
      borderRadius="16px"
      backgroundColor="white"
      height="100%"
      justifyContent="center"
      alignItems="center"
    >
      <EmptyShowcase
        title="Belum ada etalase"
        description="Tambahkan etalase untuk mengelompokkan barangmu"
        shortDesc="agar lebih mudah ditemukan oleh pembeli."
        handleAdd={
          !hasProduct ? () => push(desktopUrl.PRODUCTS_ADD) : handleAdd
        }
      />
    </Flex>
  );
};

export default Showcase;
