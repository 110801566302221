import dayjs from "dayjs";
import { goToUrl } from "helpers";
import { Divider, Flex, Text, Icon, Tooltip } from "renos-ui";

const ListTemplate = ({ data }) => {
  return (
    <Flex
      borderBottomLeftRadius="16px"
      borderBottomRightRadius="16px"
      boxShadow="0px 4px 4px rgba(20, 20, 32, 0.05)"
      backgroundColor="white"
      padding="24px 24px 40px 24px"
      flexDirection="column"
    >
      <Flex flexDirection="column" gap="40px">
        <Flex flexDirection="column" gap="12px">
          <Flex flexDirection="column" gap="4px">
            <Text weight="bold" variant="subtitle">
              Daftar Template
            </Text>
            <Text color="black50" variant="caption">
              Daftar akan hilang jika kamu refresh atau keluar halaman ini.
            </Text>
          </Flex>

          <Flex flexDirection="column" gap="16px">
            {/* 1 */}
            <Flex
              backgroundColor="black5"
              width="100%"
              height="36px"
              borderRadius="4px"
              justifyContent="space-between"
              alignItems="center"
            >
              <Flex px="8px" width="96px">
                <Text weight="bold" variant="caption">
                  Waktu
                </Text>
              </Flex>
              <Flex px="8px" width="104px">
                <Text weight="bold" variant="caption">
                  Jenis Template
                </Text>
              </Flex>
              <Flex px="8px" width="104px">
                <Text weight="bold" variant="caption">
                  Kategori
                </Text>
              </Flex>
              <Flex px="8px" width="56px">
                <Text weight="bold" variant="caption">
                  Total
                </Text>
              </Flex>
              <Flex px="8px" width="40px">
                <></>
              </Flex>
            </Flex>
            {/* 2 */}

            {data.map((template, index) => (
              <Flex
                justifyContent="space-between"
                alignItems="center"
                key={`row-template${index}`}
              >
                <Flex px="8px" width="98px">
                  <Text variant="caption">
                    {dayjs(template.date).format("DD/MM/YY - HH:mm")}
                  </Text>
                </Flex>
                <Flex px="8px" width="103px">
                  <Tooltip label={template.type}>
                    <Text variant="caption" lineClamp={1}>
                      {template.type}
                    </Text>
                  </Tooltip>
                </Flex>
                <Flex px="8px" width="103px">
                  <Tooltip label={template.category}>
                    <Text variant="caption" lineClamp={1}>
                      {template.category}
                    </Text>
                  </Tooltip>
                </Flex>
                <Flex px="8px" width="56px">
                  <Text variant="caption">{template.totalSKU} SKU</Text>
                </Flex>
                <Flex px="8px" width="40px">
                  <Icon
                    width="20px"
                    height="20px"
                    name="Download-outline"
                    onClick={() => goToUrl(template.link)}
                    cursor="pointer"
                  />
                </Flex>
              </Flex>
            ))}
          </Flex>
        </Flex>
      </Flex>
      <Flex py="24px">
        <Divider />
      </Flex>
    </Flex>
  );
};

export default ListTemplate;
