export const maskName = (name) => {
  // Split the name into parts
  const parts = name.split(" ");

  // Mask the first part (first name)
  if (parts.length > 0) {
    parts[0] = maskString(parts[0]);
  }

  // Mask the last part (last name)
  if (parts.length > 1) {
    parts[parts.length - 1] = maskString(parts[parts.length - 1]);
  }

  // Join the parts back together

  return parts.join(" ");
};

export const maskString = (str, notMarked = 1, isSuffix) => {
  // Mask character based on not marked count
  const notMarkedWord =
    notMarked < 2
      ? str[notMarked]
      : isSuffix
      ? str.substr(-notMarked)
      : str.substring(0, notMarked);

  if (isSuffix)
    return "*".repeat(str.length - notMarkedWord.length) + notMarkedWord;

  return notMarkedWord + "*".repeat(str.length - notMarkedWord.length);
};

export const maskEmail = (email) => {
  let str = email;
  str = str.split("");
  const finalArr = [];
  const len = str.indexOf("@");
  str.forEach((item, pos) => {
    pos >= 1 && pos <= len - 2 ? finalArr.push("*") : finalArr.push(str[pos]);
  });

  return finalArr.join("");
};

export const maskAllName = (name, isSuffix) => {
  // Split the name into parts
  const parts = name?.split(" ") || [];

  for (let i = 0; i < parts.length; i++) {
    //mask part with 3 words not masked
    parts[i] = maskString(parts[i], 3, isSuffix);
  }

  // Join the parts back together
  return parts.join(" ");
};
