import React, { useMemo, useState, useContext } from "react";
import { Box, Button, Dialog, Flex, Text } from "renos-ui";
import listFilter from "./listFilter";
import FilterProductContext from "providers/FilterProvider";
import IconChecked from "./IconChecked";

const FilterModal = ({ disclosure }) => {
  const { isOpen, onClose } = disclosure;
  const [activeTab, setActiveTab] = useState("category");
  const { categoriesId, shippingIds, setCategoriesId, setShippingIds } =
    useContext(FilterProductContext);

  const selectedFilter = useMemo(
    () => listFilter.find((data) => data.value === activeTab),
    [activeTab]
  );

  const handleClose = () => {
    onClose();
    setCategoriesId([]);
    setShippingIds([]);
  };

  return (
    <Dialog
      isVisible={isOpen}
      onClose={handleClose}
      verticalCentered
      width="720px"
      height="435px"
      closable
    >
      <Flex flexDirection="column" rowGap="24px" width="100%" height="100%">
        <Text variant="heading3" weight="bold">
          Filter produk
        </Text>
        <Flex
          borderStyle="solid"
          borderColor="black10"
          borderWidth="1px"
          borderRadius="8px"
          flexGrow={1}
          overflow="auto"
          height="288px"
        >
          <Flex flexDirection="column">
            {listFilter.map((data) => {
              const isActive = activeTab === data.value;

              return (
                <Flex
                  key={data.value}
                  width="192px"
                  px="24px"
                  py="12px"
                  borderWidth="0px"
                  borderColor="black10"
                  borderStyle="solid"
                  borderRightWidth={!isActive && "1px"}
                  borderBottomWidth="1px"
                  flex={1}
                  alignItems="center"
                  position="relative"
                  cursor="pointer"
                  onClick={() => setActiveTab(data.value)}
                >
                  {isActive && (
                    <Box
                      position="absolute"
                      left="0"
                      height="24px"
                      width="4px"
                      borderRadius="0px 8px 8px 0px"
                      backgroundColor="blue50"
                    />
                  )}
                  <Flex
                    flex="1"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Text weight="bold">{data.label}</Text>
                    {data.label === "Kategory" && categoriesId.length > 0 && (
                      <IconChecked />
                    )}
                    {data.label === "Jasa Pengiriman" &&
                      shippingIds.length > 0 && <IconChecked />}
                  </Flex>
                </Flex>
              );
            })}
          </Flex>
          {selectedFilter.component}
        </Flex>
        <Flex columnGap="12px" width="100%" justifyContent="flex-end">
          <Button variant="tertiary">Reset</Button>
          <Button variant="primary" onClick={onClose}>
            Terapkan
          </Button>
        </Flex>
      </Flex>
    </Dialog>
  );
};

export default FilterModal;
