import { Flex, Box } from "renos-ui";

const ProductCardSkeleton = () => {
  return (
    <Flex flexDirection="column">
      <Flex
        height="101px"
        backgroundColor="black10"
        background="url('/assets/images/store-layout/no-img.svg') no-repeat 50% 50%"
        borderTopLeftRadius="4px"
        borderTopRightRadius="4px"
      />
      <Flex
        gap="6px"
        flexDirection="column"
        height="67px"
        borderBottomLeftRadius="4px"
        borderBottomRightRadius="4px"
        borderColor="black10"
        borderWidth="1px"
        borderStyle="solid"
        padding="8px"
      >
        <Box
          width="62px"
          height="6px"
          borderRadius="100px"
          backgroundColor="black10"
        />
        <Box
          width="79px"
          height="6px"
          borderRadius="100px"
          backgroundColor="black10"
        />
        <Box
          width="62px"
          height="6px"
          borderRadius="100px"
          backgroundColor="black10"
        />
      </Flex>
    </Flex>
  );
};

export default ProductCardSkeleton;
