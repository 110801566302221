import { Flex, Skeleton, range } from "renos-ui";

const OrderSkeleton = ({ itemsShow = 4 }) => {
  return range(itemsShow).map((item) => (
    <Flex
      gap="12px"
      padding="16px"
      alignItems="center"
      borderBottomWidth="1px"
      borderBottomStyle="solid"
      borderBottomColor="black5"
      key={`skeleton-product-${item}`}
    >
      <Flex>
        <Skeleton width="67px" height="67px" />
      </Flex>
      <Flex gap="4px" flexDirection="column" width="90%">
        <Skeleton width="60%" height="20px" />
        <Skeleton width="20%" height="20px" />
        <Skeleton width="60%" height="20px" />
      </Flex>
      <Flex gap="4px" flexDirection="column" width="30%">
        <Skeleton width="100%" height="20px" />
      </Flex>
    </Flex>
  ));
};

export default OrderSkeleton;