import { Button, Flex, Text } from "renos-ui";
import { useHistory } from "react-router-dom";
import { useGetSellerFleetSetting } from "services/logistics/sellerFleet";

import MainAddress from "./mainAddress";
import TableSetting from "./tableSetting";

const SettingSellerFleetList = () => {
  const history = useHistory();

  const {
    isError,
    isLoading,
    isFetching,
    refetch,
    data: dataSellerFleet,
  } = useGetSellerFleetSetting();

  return (
    <>
      <Flex flexDirection="column" gap="24px">
        <Flex flexDirection="column">
          <Text variant="heading3" weight="bold" color="black100">
            Atur Kurir Toko
          </Text>
          <Text color="black75">
            Atur harga berdasarkan kelipatan atau kelompok berat.
          </Text>
        </Flex>
        <Flex
          padding="24px"
          backgroundColor="white"
          borderRadius="16px"
          gap="16px"
          flexDirection="column"
          flexWrap="wrap"
        >
          <MainAddress />
          <TableSetting
            dataSetting={dataSellerFleet}
            isError={isError}
            refetch={refetch}
            isLoading={isLoading}
            isFetching={isFetching}
          />
          <Flex gap="16px" alignSelf="end">
            <Button
              variant="tertiary"
              onClick={() => history.push("/setting?tab-active=delivery")}
            >
              Kembali
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};

export default SettingSellerFleetList;
