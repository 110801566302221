import React, { useContext, useState } from "react";
import {
  Flex,
  Text,
  Progress,
  SelectInput,
  DatePicker,
  Input,
  Icon,
} from "renos-ui";
import FilterRatingContext from "providers/FilterRatingReview";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import duration from "dayjs/plugin/duration";

dayjs.extend(localizedFormat);
dayjs.extend(duration);

/* 
  untuk filter range date dijadikan display none karena belum tau untuk default value nya,
  untuk filter image dijadikan display none karena harus dipastikan dulu
*/

const listFilter = [
  {
    label: "Terbaru",
    value: true,
  },
  {
    label: "Rating Tertinggi",
    value: 5,
  },
  {
    label: "Rating Terendah",
    value: 1,
  },
];

const FilterReview = () => {
  const { filters, setFilters, resetFilter } = useContext(FilterRatingContext);
  const [selectedRating, setSelectedRating] = useState([]);

  const handleChange = (event) => {
    // setSelectedRating(null);
    setFilters({
      ...filters,
      images: false,
    });

    switch (event.label) {
      case "Rating Tertinggi":
        return setFilters({
          ...filters,
          order_by: "score",
          ordering: "desc",
        });
      case "Rating Terendah":
        return setFilters({
          ...filters,
          order_by: "score",
          ordering: "asc",
        });
      case "Terbaru":
        return setFilters({
          ...filters,
          order_by: "created_date",
          ordering: "desc",
        });
      default:
        return null;
    }
  };

  const formatDate = (date) => dayjs(date).format("D MMM YYYY");

  const filterReset = () => {
    setSelectedRating([]);
    resetFilter();
  };

  const handleClickStar = (star) => {
    const starString = star?.toString();
    const starRatingsArray = (filters?.scores_in || "")
      .split(",")
      .filter((item) => item !== "");

    if (starRatingsArray.includes(starString)) {
      const index = starRatingsArray.indexOf(starString);
      starRatingsArray.splice(index, 1);
    } else {
      starRatingsArray.push(starString);
    }

    starRatingsArray.sort(
      (firstRating, secondRating) => firstRating - secondRating
    );
    setSelectedRating(starRatingsArray);

    const startRatingString = starRatingsArray.join(",");
    setFilters({
      ...filters,
      scores_in: startRatingString || undefined,
    });
  };

  const getActiveStar = (star) => {
    const starString = star?.toString();
    const starRatingsArray = (filters?.scores_in || "")
      .split(",")
      .filter((item) => item !== "");

    return starRatingsArray.includes(starString);
  };

  const handleClickFilterImage = () => {
    if (filters.images) {
      const { images, ...rest } = filters;
      setFilters(rest);
    } else {
      setFilters({
        ...filters,
        images: true,
      });
    }
  };

  const handleCLickFilterReplied = () => {
    if (!filters.replied && filters.replied !== undefined) {
      const { replied, ...rest } = filters;
      setFilters(rest);
    } else {
      setFilters({
        ...filters,
        replied: false,
      });
    }
  };

  return (
    <Flex justifyContent="space-between">
      <Flex alignItems="center" gap="8px">
        <Flex marginRight="16px">
          <Text color="black50" weight="bold">
            Filter
          </Text>
        </Flex>
        {Array.from({ length: 5 }).map((_, index) => (
          <Flex
            gap="10px"
            border="1px solid"
            borderRadius="10px"
            borderColor={getActiveStar(index + 1) ? "blue50" : "black10"}
            backgroundColor={getActiveStar(index + 1) ? "blue5" : "white"}
            paddingY="8px"
            paddingX="16px"
            alignItems="center"
            key={index}
            height="30px"
            cursor="pointer"
            onClick={() => handleClickStar(index + 1)}
          >
            <Progress.Rating readOnly count={1} value={index + 1} />
            <Text weight="600">{index + 1}</Text>
          </Flex>
        ))}
        <Flex
          gap="9.5px"
          border="1px solid"
          borderColor={
            !filters.images || filters.images === undefined
              ? "black10"
              : "blue50"
          }
          backgroundColor={
            !filters.images || filters.images === undefined ? "white" : "blue5"
          }
          borderRadius="10px"
          paddingY="8px"
          paddingX="1rem"
          height="30px"
          cursor="pointer"
          alignItems="center"
          onClick={handleClickFilterImage}
        >
          <Text weight="600">Dengan Foto & Video</Text>
        </Flex>
        <Flex
          gap="9.5px"
          border="1px solid"
          borderColor={
            filters.replied || filters.replied === undefined
              ? "black10"
              : "blue50"
          }
          backgroundColor={
            filters.replied || filters.replied === undefined ? "white" : "blue5"
          }
          borderRadius="10px"
          paddingY="8px"
          paddingX="1rem"
          alignItems="center"
          cursor="pointer"
          height="30px"
          onClick={handleCLickFilterReplied}
        >
          <Text weight="600">Belum Dibalas</Text>
        </Flex>
        {selectedRating !== null ||
        filters.images !== undefined ||
        filters.replied !== undefined ? (
          <Flex marginLeft="8px" cursor="pointer" onClick={filterReset}>
            <Text weight="bold" color="blue50">
              Reset Filter
            </Text>
          </Flex>
        ) : null}

        <Flex display="none">
          <DatePicker.Range
            startDate={filters.date_start}
            endDate={filters.date_end}
            customInput={
              <Flex width="268px">
                <Input
                  value={`${formatDate(filters.date_start)} - ${formatDate(
                    filters.date_end
                  )}`}
                  sufix={<Icon name="Calendar-outline" size={20} />}
                />
              </Flex>
            }
            onChange={([date_start, date_end]) =>
              setFilters({
                ...filters,
                date_start,
                date_end,
              })
            }
          />
        </Flex>
      </Flex>
      <Flex>
        <SelectInput
          defaultValue={listFilter[0]}
          options={listFilter.map((row) => ({
            label: row.label,
            value: row.value,
          }))}
          onChange={(event) => handleChange(event)}
        />
      </Flex>
    </Flex>
  );
};

export default FilterReview;
