export const conditionType = [
  {
    label: "Kurang dari",
    value: 1,
  },
  {
    label: "Kurang dari sama dengan",
    value: 2,
  },
  {
    label: "Lebih dari",
    value: 3,
  },
  {
    label: "Lebih dari sama dengan",
    value: 4,
  },
];

export const conditionBaseType = [
  {
    label: "Berdasarkan Kelipatan Berat",
    value: 1,
  },
  {
    label: "Berdasarkan Kelompok Berat",
    value: 2,
  },
];

export const showModalData = {
  categoriesActive: {
    title: "Kategori Pilihan",
    description: "Terapkan tarif untuk kategori pilihan.",
  },
  kelipatanBerat: {
    title: "Berdasarkan Kelipatan Berat",
    description: "Atur ongkos kirim berdasarkan kelipatan berat.",
  },
  kelompokBerat: {
    title: "Berdasarkan Kelompok Berat",
    description: "Atur ongkos kirim berdasarkan kelompok berat.",
  },
  opsiLanjutan: {
    title: "Opsi Lanjutan",
    description:
      "Atur ongkos kirim berdasarkan campuran kelipatan berat dan kelompok berat.",
  },
  default: {
    title: "Semua Kategori",
    description: "Terapkan tarif untuk semua kategori.",
  },
};
