import mobileUrl from "constants/mobileUrl";

export const orderMenu = [
  {
    name: "Statistik",
    icon: "Statistic-outline",
    path: mobileUrl.STATISTICS,
  },
  {
    name: "Ulasan",
    icon: "Star-outline",
    path: mobileUrl.ACCOUNT_RATING_REVIEW,
  },
];

export const settingMenu = [
  {
    name: "Informasi Toko",
    icon: "Profile-outline",
    path: "/account/information",
  },
  {
    name: "Status Toko",
    icon: "Time-outline",
    path: mobileUrl.STORE_STATUS,
  },
  {
    name: "Catatan Toko",
    icon: "Edit-outline",
    path: "/account/note",
  },
  {
    name: "Lokasi Toko",
    icon: "Location-outline",
    path: "/account/location",
  },
  {
    name: "Layanan Pengiriman",
    icon: "Delivery-outline",
    path: "/account/delivery-service",
  },
  {
    name: "Template Balasan",
    icon: "Chat-outline",
    path: "/account/template-reply",
  },
  {
    name: "Dokumen",
    icon: "Transaction-outline",
    path: "/account/document",
  },
];
