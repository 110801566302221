import React, { useEffect } from "react";
import { Flex, Text, Input, Button, Box, Icon } from "renos-ui";
import { useHistory } from "react-router-dom";
import { useCheckStoreName } from "services/register/check-store";

import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { StyledInput } from "../register-detail/styled";
import { StoreInfoSchema } from "./storeInfo-schema";

const RegisterStoreName = ({ setStep }) => {
  const history = useHistory();

  const {
    control,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    setError,
  } = useForm({
    resolver: yupResolver(StoreInfoSchema),
    defaultValues: {
      name: "",
      email: "",
      description: "",
      contact_person: "",
      phone_number: "",
    },
  });

  const { mutate: submitRegister } = useCheckStoreName({
    onSuccess: (data) => {
      if (data.data.name_already_exist) {
        setError("name", {
          type: "manual",
          message: data?.frontend_message || "Nama sudah diambil",
        });
      } else {
        const storeValues = {
          name: getValues("name"),
          email: getValues("email"),
          description: getValues("description"),
          contact_person: getValues("contact_person"),
          phone_number: getValues("phone_number"),
        };

        const storeInformation = JSON.stringify(storeValues);

        localStorage.setItem(
          "storeInformation",
          JSON.stringify(storeInformation)
        );
        setStep(2);
      }
    },
    onError: (error) => {
      console.log("error", error);
    },
  });

  const onSubmit = () => {
    submitRegister({ name: getValues("name") });
  };

  useEffect(() => {
    const storedJsonString = localStorage.getItem("storeInformation");
    const storedObject = JSON.parse(storedJsonString);

    setValue("name", JSON.parse(storedObject)?.name);
    setValue("email", JSON.parse(storedObject)?.email);
    setValue("description", JSON.parse(storedObject)?.description);
    setValue("contact_person", JSON.parse(storedObject)?.contact_person);
    setValue("phone_number", JSON.parse(storedObject)?.phone_number);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setStep]);

  return (
    <Flex
      width="80%"
      margin="auto"
      background={`url(/assets/images/renos-register-detail.svg)`}
      backgroundRepeat="no-repeat"
      backgroundSize="cover"
      backgroundPosition="center center"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      height="100vh"
    >
      <Flex flexDirection="column" rowGap="40px" marginY="40px">
        <Flex flexDirection="row" alignItems="center">
          <Flex
            width="32px"
            height="32px"
            borderRadius="100%"
            cursor="pointer"
            alignItems="center"
            justifyContent="center"
            onClick={history.goBack}
            boxShadow="0px 4px 10px rgba(20, 20, 32, 0.08)"
          >
            <Icon name="Left-outline" color="black50" size={16} />
          </Flex>
          <Box
            as="img"
            margin="auto"
            src="/assets/images/renos-primary.svg"
            alt="renos-primary"
          />
        </Flex>

        <Flex
          width="720px"
          height="auto"
          padding="24px"
          borderRadius="16px"
          flexDirection="column"
          rowGap="24px"
          zIndex="10"
          backgroundColor="white"
          boxShadow="0px 4px 10px rgba(20, 20, 32, 0.08)"
        >
          <Flex flexDirection="column" rowGap="8px">
            <Text variant="heading3" weight="bold">
              Informasi Toko
            </Text>
            <Text>
              Buat nama tokomu yang unik dan berbeda dengan yang lain kemudian
              harap masukkan data lainnya sesuai dengan data asli yaa!
            </Text>
          </Flex>

          <form onSubmit={handleSubmit(onSubmit)}>
            <Flex flexDirection="row" columnGap="24px">
              <Flex width="100%" flexDirection="column">
                <Controller
                  name="name"
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      label="Nama Toko"
                      showCount
                      placeholder="Ketik nama toko"
                      maxLength={60}
                      isError={!!errors.name}
                      error={errors.name?.message}
                    />
                  )}
                />
                <Controller
                  name="contact_person"
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      label="Nama Contact Person"
                      maxLength={50}
                      placeholder="Ketik nama Contact Person"
                      isError={!!errors.contact_person}
                      error={errors.contact_person?.message}
                    />
                  )}
                />
              </Flex>
              <Flex
                width="100%"
                rowGap="30px"
                flexDirection="column"
                justifyContent="flex-start"
              >
                <Controller
                  name="email"
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      label="Email Toko"
                      maxLength={50}
                      placeholder="Ketik email toko"
                      isError={!!errors.email}
                      error={errors.email?.message}
                    />
                  )}
                />

                <Flex flexDirection="column" rowGap="8px">
                  <Text weight="bold" variant="caption" color="black50">
                    No. HP Contact Person
                  </Text>
                  <Controller
                    name="phone_number"
                    control={control}
                    render={({ field }) => (
                      <StyledInput
                        {...field}
                        type="text"
                        value={getValues("phone_number")}
                        name="phone_number"
                        placeholder="Masukkan nomor HP/telepon"
                        onChange={(e) =>
                          setValue(
                            "phone_number",
                            e.target.value
                              .replace(/[^+\d]/g, "")
                              .substring(0, 15)
                          )
                        }
                        isError={!!errors.phone_number}
                      />
                    )}
                  />

                  {!!errors.phone_number && (
                    <Text variant="caption" color="red50">
                      {errors.phone_number?.message ||
                        "No. HP Contact Person harus diisi."}
                    </Text>
                  )}
                </Flex>
              </Flex>
            </Flex>

            <Flex pt="40px">
              <Button
                isBlock
                size="large"
                type="submit"
                // disabled={!isChecked || isLoadingSubmit}
                variant="primary"
              >
                <Text weight="bold">Simpan</Text>
              </Button>
            </Flex>
          </form>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default RegisterStoreName;
