import { goToUrl } from "helpers";
import { maskAllName } from "helpers/string-mask";
import { useMemo } from "react";
import { Avatar, Divider, Flex, Icon, Text, useToast } from "renos-ui";

const DeliveryInfo = ({
  shipping,
  order_status_id: orderStatusId,
  store_order_shipment: storeOrderShipment,
}) => {
  const toast = useToast();
  const notAllowedMask = [2, 3, 4, 5];

  const handleMasking = (value, isPreffix) => {
    return notAllowedMask.includes(orderStatusId)
      ? value
      : isPreffix
      ? maskAllName(value, true)
      : maskAllName(value);
  };

  const address = `${handleMasking(
    shipping.destination_address
  )}, ${handleMasking(shipping.destination_district_name)}, ${handleMasking(
    shipping.destination_city_name
  )}, ${handleMasking(shipping.destination_province_name)} ${handleMasking(
    shipping.destination_postal_code
  )}`;
  const listShowDelivery = [4, 5, 6];

  const handleCopy = () => {
    const formatCopy = `${shipping.destination_pic_name} (${shipping.destination_pic_phone}), ${address}`;
    navigator.clipboard.writeText(formatCopy);

    toast({
      label: "Berhasil salin alamat.",
      placement: "bottom",
    });
  };

  const isShowCourierInfo = useMemo(
    () =>
      storeOrderShipment.pod &&
      storeOrderShipment.pod?.courier_employee_name &&
      storeOrderShipment.pod?.courier_employee_phone_number,
    [storeOrderShipment]
  );

  return (
    <Flex flexDirection="column">
      <Flex padding="16px" gap="16px" flexDirection="column">
        <Text weight="bold">Info Pengiriman</Text>
        <Flex gap="16px" flexDirection="column">
          <Flex gap="24px">
            <Flex width="88px">
              <Text variant="caption" color="black50">
                Kurir
              </Text>
            </Flex>
            <Flex flexDirection="column" width="calc(100% - 112px)" gap="8px">
              <Text variant="caption" color="black75">
                {`${shipping.logistics_courier_name} - ${shipping.logistics_courier_service_name}`}
              </Text>
              {!shipping.is_seller_fleet && isShowCourierInfo && (
                <Flex
                  padding="12px 16px"
                  backgroundColor="black5"
                  borderRadius="8px"
                  gap="8px"
                  alignItems="center"
                >
                  <Avatar
                    size={32}
                    src={
                      storeOrderShipment.pod.courier_employee_photo_url
                        ? storeOrderShipment.pod.courier_employee_photo_url
                        : storeOrderShipment.pod
                            .courier_employee_photo_url_external
                        ? storeOrderShipment.pod
                            .courier_employee_photo_url_external
                        : ""
                    }
                  />
                  <Flex
                    width="calc(100% - 40px)"
                    flexDirection="column"
                    gap="4px"
                  >
                    <Text color="black50" variant="small" lineClamp={1}>
                      {storeOrderShipment?.pod?.courier_employee_name}
                    </Text>
                    <Text color="black50" variant="small">
                      {storeOrderShipment?.pod?.courier_employee_phone_number}
                    </Text>
                  </Flex>
                </Flex>
              )}
            </Flex>
          </Flex>

          {(shipping.is_seller_fleet &&
            listShowDelivery.includes(orderStatusId)) > 0 && (
            <>
              <Flex gap="24px" alignItems="center">
                <Flex width="88px">
                  <Text variant="caption" color="black50">
                    Dokumen Pengiriman
                  </Text>
                </Flex>
                {storeOrderShipment?.pod?.shipment_deliver_picture_url ? (
                  <Flex
                    onClick={() =>
                      goToUrl(
                        storeOrderShipment?.pod?.shipment_deliver_picture_url
                      )
                    }
                    alignItems="center"
                  >
                    <Text variant="caption" color="blue50">
                      <u>Unduh dokumen pengiriman</u>
                    </Text>
                  </Flex>
                ) : (
                  <Flex width="calc(100% - 112px)">
                    <Text variant="caption" color="black50">
                      Tidak ada dokumen
                    </Text>
                  </Flex>
                )}
              </Flex>

              <Flex gap="24px" alignItems="center">
                <Flex width="88px">
                  <Text variant="caption" color="black50">
                    Catatan Pengiriman
                  </Text>
                </Flex>
                <Flex width="calc(100% - 112px)">
                  <Text
                    variant="caption"
                    color={
                      !storeOrderShipment?.pod?.delivery_notes && "black50"
                    }
                  >
                    {storeOrderShipment?.pod?.delivery_notes ||
                      "Tidak ada catatan"}
                  </Text>
                </Flex>
              </Flex>
            </>
          )}

          {storeOrderShipment.pod && orderStatusId !== 4 && (
            <>
              <Flex gap="24px">
                <Flex width="88px">
                  <Text variant="caption" color="black50">
                    Penerima
                  </Text>
                </Flex>
                <Flex width="calc(100% - 112px)">
                  <Text variant="caption" color="black75">
                    {storeOrderShipment.pod.receiver_contact_person ||
                      "Tidak tersedia"}
                  </Text>
                </Flex>
              </Flex>
              <Flex gap="24px">
                <Flex width="88px">
                  <Text variant="caption" color="black50">
                    Bukti Penerima
                  </Text>
                </Flex>
                {storeOrderShipment.pod.shipment_receive_picture_url ? (
                  <Flex
                    onClick={() =>
                      goToUrl(
                        storeOrderShipment?.pod?.shipment_receive_picture_url
                      )
                    }
                    alignItems="center"
                  >
                    <Text variant="caption" color="blue50">
                      <u>Bukti Terima</u>
                    </Text>
                  </Flex>
                ) : (
                  <Text variant="caption" color="black75">
                    Tidak Tersedia
                  </Text>
                )}
              </Flex>
            </>
          )}

          {shipping.order_shipping_receipt_number && (
            <Flex gap="24px">
              <Flex width="88px">
                <Text variant="caption" color="black50">
                  {shipping.is_seller_fleet ? "Kode Booking" : "Nomor Resi"}
                </Text>
              </Flex>
              <Text variant="caption" color="black75">
                {shipping.order_shipping_receipt_number || "-"}
              </Text>
            </Flex>
          )}

          <Flex gap="24px">
            <Flex width="88px" alignItems="baseline">
              <Flex gap="12px" alignItems="center">
                <Text variant="caption" color="black50">
                  Alamat
                </Text>
                {notAllowedMask.includes(orderStatusId) && (
                  <Icon
                    size={16}
                    name="Copy-solid"
                    color="blue50"
                    onClick={handleCopy}
                  />
                )}
              </Flex>
            </Flex>

            <Flex flexDirection="column" width="calc(100% - 112px)">
              <Text variant="caption" color="black75" weight="bold">
                {handleMasking(shipping.destination_pic_name)}
              </Text>
              <Text variant="caption" color="black75">
                {handleMasking(shipping.destination_pic_phone, true)}
              </Text>
              <Text variant="caption" color="black75">
                {address}
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <Divider height="8px !important" backgroundColor="black5" />
    </Flex>
  );
};

export default DeliveryInfo;
