export const modalText = {
  ktp: {
    title: "KTP Penjual",
    inputLabel: "Nomor Induk Kependudukan",
    inputName: "ktp",
    inputPlacholder: "Masukkan NIK",
    uploadPhotoText: "Upload foto KTP",
  },
  npwp: {
    title: "NPWP Perusahaan",
    inputLabel: "No. NPWP",
    inputName: "npwp",
    inputPlacholder: "Masukan NPWP",
    uploadPhotoText: "Upload foto NPWP",
  },
  siup: {
    title: "Nomor Induk Berusaha (NIB)",
    inputLabel: "Nomor Induk Berusaha",
    inputName: "siup",
    inputPlacholder: "Masukan NIB",
    uploadPhotoText: "Upload foto NPWP",
  },
  bank_book: {
    title: "Buku Tabungan",
    inputLabel: "Masukan Nomor Rekening",
    inputName: "bank_book",
    inputPlacholder: "Masukan NIB",
    uploadPhotoText: "Upload foto NPWP",
  },
};

export const IMAGE_FILE_TYPE =
  "image/jpg, image/jpeg, image/png, application/pdf";
