import { useToast } from "renos-ui";
import { useHistory } from "react-router-dom";
import {
  useGetProvince,
  useGetCity,
  useGetDistrict,
  useGetSubDistrict,
} from "services/place";
import desktopUrl from "constants/desktopUrl";
import { useMutateRegister } from "services/location";

import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { registerSchema } from "../register-schema";

const useRegister = () => {
  const toast = useToast();
  const history = useHistory();

  const emptyOptions = {
    label: "",
    value: "",
  };
  const { data: listProvince } = useGetProvince();
  const {
    data: listCity,
    mutate: getCity,
    isLoading: isLoadingCity,
  } = useGetCity();
  const {
    data: listDistrict,
    mutate: getDistrict,
    isLoading: isLoadingDistrict,
  } = useGetDistrict();
  const {
    data: listSubDistrict,
    mutate: getSubDistrict,
    isLoading: isLoadingSubDistrict,
  } = useGetSubDistrict();
  const { mutate: submitRegister, isLoading: isLoadingSubmit } =
    useMutateRegister({
      onSuccess: () => {
        toast({
          label: "Berhasil melakukan registrasi.",
          placement: "top",
        });
        localStorage.removeItem("storeInformation");
        setTimeout(() => {
          history.push(desktopUrl.DASHBOARD);
        }, 2000);
      },
      onError: (error) => {
        toast({
          label:
            error?.response?.data?.frontend_message ||
            error?.response?.data?.message ||
            "Gagal melakukan registrasi.",
          placement: "top",
          backgroundColor: "red50",
        });
      },
    });

  const {
    control,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(registerSchema),
    defaultValues: {
      name: "",
      address: "",
      province: null,
      city: null,
      district: null,
      sub_district: null,
      contact_number: "",
      postal_code: "",
      contact_person: "",
      point_address: "",
      coordinates: {
        latitude: -6.175392,
        longitude: 106.827153,
      },
    },
  });

  const onSubmit = (data) => {
    const subDistrictData = getValues("sub_district.data");
    const descriptionData = { description: data.address };
    const storedJsonString = localStorage.getItem("storeInformation");
    const storedObject = JSON.parse(storedJsonString);

    const {
      coordinates: { latitude, longitude },
    } = data;

    const numericLatitude = Number(latitude);
    const numericLongitude = Number(longitude);

    const mergedData = {
      name: JSON.parse(storedObject)?.name,
      description: JSON.parse(storedObject)?.description || "",
      email: JSON.parse(storedObject)?.email,
      contact_person: JSON.parse(storedObject)?.contact_person,
      phone_number: JSON.parse(storedObject)?.phone_number,
      location: {
        ...data,
        ...subDistrictData,
        ...descriptionData,
        coordinates: {
          latitude: numericLatitude,
          longitude: numericLongitude,
        },
        is_primary: true,
      },
    };

    delete mergedData.location.district;
    delete mergedData.location.city;
    delete mergedData.location.province;
    delete mergedData.location.sub_district;

    submitRegister(mergedData);
  };

  return {
    handleSubmit,
    onSubmit,
    listProvince,
    listCity,
    getCity,
    isLoadingCity,
    listDistrict,
    getDistrict,
    isLoadingDistrict,
    listSubDistrict,
    getSubDistrict,
    isLoadingSubDistrict,
    emptyOptions,
    errors,
    control,
    getValues,
    setValue,
    Controller,
    isLoadingSubmit,
  };
};

export default useRegister;
