import React from "react";

import { useFormContext, Controller } from "react-hook-form";

import { get, Input, isEmpty } from "renos-ui";

export const FieldNumberInput = ({
  name,
  defaultValue,
  onChange,
  ...restProps
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const error = get(errors, name);

  return (
    <Controller
      name={name}
      defaultValue={defaultValue}
      control={control}
      render={({ field }) => (
        <Input.Number
          {...field}
          {...restProps}
          isError={!isEmpty(error)}
          error={error?.message}
          onChange={(value) => {
            const finalValue = value || 0;
            if (onChange) return onChange(finalValue);

            return field.onChange(finalValue);
          }}
        />
      )}
    />
  );
};

export default FieldNumberInput;
