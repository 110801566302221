import { Dialog, Flex, Text, Button, Spinner } from "renos-ui";

const ModalConfirmation = ({
  isOpen,
  onClose,
  onConfirm,
  isLoading,
  isActivate,
}) => {
  return (
    <Dialog
      isVisible={isOpen}
      onClose={onClose}
      verticalCentered
      minHeight="100px"
    >
      <Flex flexDirection="column" width="100%" gap="24px">
        <Flex flexDirection="column" gap="24px" width="320px">
          <Text variant="heading3" weight="bold">
            {isActivate ? "Aktifkan " : "Nonaktifkan "}Desain Tampilan?
          </Text>
          <Text>
            {isActivate
              ? "Pengaturan desain tampilan kamu akan ditampilkan di halaman utama Tokomu. Pastikan semua pengaturan sudah benar yaa!"
              : "Halaman Beranda Tokomu tidak akan ditampilkan."}
          </Text>
        </Flex>
        <Flex justifyContent="space-between" gap="16px">
          <Button isBlock variant="tertiary" onClick={onClose}>
            Kembali
          </Button>
          <Button
            isBlock
            onClick={onConfirm}
            disabled={isLoading}
            preffix={isLoading && <Spinner color="white" size="8px" />}
          >
            {isActivate ? "Aktifkan" : "Nonaktifkan"}
          </Button>
        </Flex>
      </Flex>
    </Dialog>
  );
};

export default ModalConfirmation;
