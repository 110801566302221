import { useEffect, useMemo, useState } from "react";
import { Box, Flex, range } from "renos-ui";
import { useGetProductsV3 } from "services/product";
import Filter from "./component/Filter";
import InfiniteScroll from "widgets/global/InfiniteScroll";
import EmptyData from "./component/EmptyData";
import Card from "./component/Card";
import CardSkeleton from "./component/CardSkeleton";
import FailLoadData from "widgets/global/FailLoadData";

const Product = ({ setValue, value, tabValue }) => {
  const [dataList, setDatalist] = useState([]);
  const [page, setPage] = useState(1);
  const [params, setParams] = useState({ keyword: "", tag_store_id: "" });

  const {
    data: dataProduct,
    isLoading,
    isFetching,
    isError,
    refetch,
  } = useGetProductsV3(
    {
      ...params,
      page,
      product_status_ids: 1,
    },
    {
      onSuccess: (dataSuccess) => {
        if (page === 1) {
          setDatalist([...dataSuccess.data]);
        } else {
          const concatData = dataList.concat(dataSuccess.data);
          setDatalist(concatData);
        }
      },
    }
  );

  const hasNextPage = useMemo(
    () =>
      dataProduct?.pagination?.next_page_url !== "" &&
      dataProduct?.pagination?.next_page_url,
    // eslint-disable-next-line
    [dataList]
  );

  const isAllLoading = useMemo(
    () => (isLoading || isFetching) && page === 1,
    [isLoading, isFetching, page]
  );

  useEffect(() => {
    setPage(1);
  }, [params]);

  return (
    <Flex flexDirection="column" mt="16px">
      <Filter setValue={setParams} />
      <Flex
        maxHeight="287px"
        overflowY="auto"
        overflowX="clip"
        flexDirection="column"
        width="100%"
      >
        <InfiniteScroll
          page={page}
          onChange={() => setPage(page + 1)}
          isLoading={isLoading}
          hasNextPage={hasNextPage}
          loader={
            <Box>
              <CardSkeleton />
            </Box>
          }
        >
          {isAllLoading ? (
            range(5).map((_, indexSkeleton) => (
              <CardSkeleton key={`card-skeleton-${indexSkeleton}`} />
            ))
          ) : isError ? (
            <Flex p="24px">
              <FailLoadData noImg onClick={refetch} />
            </Flex>
          ) : dataList.length === 0 ? (
            <EmptyData />
          ) : (
            dataList.map((list, indexList) => (
              <Card
                key={`list-${indexList}`}
                data={list}
                onClick={() => setValue("product", list)}
                isActive={list.product_id === value?.product_id}
              />
            ))
          )}
        </InfiniteScroll>
      </Flex>
    </Flex>
  );
};

export default Product;
