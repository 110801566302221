import React from "react";
import { Box, Flex, Grid, Text } from "renos-ui";

const LandingBenefit = (props) => {
  return (
    <Flex
      background="url(/assets/images/landing/how-to-wave.png)"
      height="640px"
      width="100%"
      backgroundPosition="top"
      backgroundRepeat="no-repeat"
      backgroundSize="100% 100%"
      alignItems="flex-end"
      justifyContent="center"
      {...props}
    >
      <Flex
        width="1200px"
        columnGap="140px"
        alignItems="center"
        marginBottom="36px"
      >
        <Flex rowGap="16px" flexDirection="column" width="360px">
          <Text variant="heading1" weight="bold">
            Gabung Renos Banyak Untungnya!
          </Text>
          <Text variant="subtitle" color="black75">
            Temukan berbagai keuntungan dan kemudahan bersama Renos.
          </Text>
        </Flex>
        <Grid gap="16px" gridTemplateColumns="repeat(2,minmax(0,1fr))">
          <Flex
            width="304px"
            background="linear-gradient(93.7deg, #6D29F0 -16.21%, #2327BE 116.42%)"
            height="200px"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            rowGap="4px"
            position="relative"
            textAlign="center"
            color="white"
            borderRadius="16px"
          >
            <Box
              as="img"
              src="/assets/images/landing/free-open-store.svg"
              position="absolute"
              top="-120px"
              left="-66px"
            />

            <Text variant="subtitle" weight="bold">
              Gratis Buka Toko
            </Text>
            <Box width="216px">
              <Text>Mulai berjualan tanpa dikenakan biaya apapun.</Text>
            </Box>
          </Flex>
          <Flex
            width="304px"
            background="linear-gradient(93.7deg, #6D29F0 -16.21%, #2327BE 116.42%)"
            height="200px"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            rowGap="4px"
            position="relative"
            textAlign="center"
            color="white"
            borderRadius="16px"
          >
            <Box
              as="img"
              src="/assets/images/landing/segmented-target.svg"
              position="absolute"
              top="-120px"
              right="-28px"
            />
            <Text variant="subtitle" weight="bold">
              Segmentasi Pasar
            </Text>
            <Box width="216px">
              <Text>
                Online marketplace yang berfokus pada produk dan jasa kebutuhan
                rumah.
              </Text>
            </Box>
          </Flex>
          <Flex
            width="304px"
            background="linear-gradient(93.7deg, #6D29F0 -16.21%, #2327BE 116.42%)"
            height="200px"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            rowGap="4px"
            position="relative"
            textAlign="center"
            color="white"
            borderRadius="16px"
          >
            <Box
              as="img"
              src="/assets/images/landing/ship-nation-wide.svg"
              position="absolute"
              bottom="-50px"
              left="-79px"
            />
            <Text variant="subtitle" weight="bold">
              Pengiriman Luas
            </Text>
            <Box width="216px">
              <Text>
                Melayani pengiriman produk jumlah besar maupun kecil ke seluruh
                daerah.
              </Text>
            </Box>
          </Flex>
          <Flex
            width="304px"
            background="linear-gradient(93.7deg, #6D29F0 -16.21%, #2327BE 116.42%)"
            height="200px"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            rowGap="4px"
            position="relative"
            textAlign="center"
            color="white"
            borderRadius="16px"
          >
            <Box
              as="img"
              src="/assets/images/landing/support-feature.svg"
              position="absolute"
              bottom="-50px"
              right="-82px"
            />
            <Text variant="subtitle" weight="bold">
              Fitur Pendukung
            </Text>
            <Box width="216px">
              <Text>Tersedia fitur pilihan untuk bantu kembangkan tokomu.</Text>
            </Box>
          </Flex>
        </Grid>
      </Flex>
    </Flex>
  );
};

export default LandingBenefit;
