import { Dialog, Flex, colors } from "renos-ui";
import styled from "styled-components";

export const StyledDialog = styled(Dialog)`
  margin: -40px;
`;

export const StyledFlexInputSearch = styled(Flex)`
  > div > span {
    border-radius: 8px;
    border: none;
    background-color: ${colors.black5};

    > input {
      background-color: ${colors.black5};
    }
  }
`;
