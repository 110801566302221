import Fail from "containers/web/store-layout/DesignSetting/Menu/Fail";
import { useMemo } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Dialog, Divider, Flex, Spinner, Text, useToast } from "renos-ui";
import {
  useRequestPickupInstant,
  useRequestPickupWithTime,
} from "services/order-management";
import { StyledButton, StyledSelectInput } from "./styled";

const ModalRequestPickup = ({
  isOpen,
  onClose,
  orderId,
  refetchList,
  refetchStatus,
}) => {
  const toast = useToast();
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const [listDay, setListDay] = useState([]);
  const [listTime, setListTime] = useState([]);

  const [selectedDay, setSelectedDay] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);

  const {
    data: dataTime,
    isLoading,
    isFetching,
    refetch,
    isError,
  } = useRequestPickupWithTime({ order_id: orderId, tz: timezone });

  const { mutate: requestPickupInstant, isLoading: isLoadingRequestPickup } =
    useRequestPickupInstant({
      onSuccess: (data) => {
        const { message, frontend_message } = data;

        refetchStatus();
        refetchList();

        toast({
          label: frontend_message || message || "Request pickup berhasil",
          placement: "top",
        });
      },
      onError: (data) => {
        const {
          response: {
            data: { frontend_message, message },
          },
        } = data;
        toast({
          label: frontend_message || message || "Request pickup gagal",
          placement: "top",
          backgroundColor: "red50",
        });
      },
    });

  const handleRequestPickupInstant = () => {
    const params = {
      order_id: orderId,
      pickup_datetime: selectedTime.value.submit_value,
      pickup_tz: timezone,
    };

    requestPickupInstant(params);
  };

  const isAllLoading = useMemo(
    () => isLoading || isFetching,
    [isLoading, isFetching]
  );

  const handleChangeDay = (dataDay) => {
    const tempListTime = dataDay.value.hour_options.map((time) => ({
      value: time,
      label: time.hour_label,
    }));
    setSelectedDay(dataDay);
    setSelectedTime(null);
    setListTime([...tempListTime]);
  };

  useEffect(() => {
    if (isOpen) {
      refetch();
    }
    // eslint-disable-next-line
  }, [isOpen]);

  useEffect(() => {
    if (!isLoading && !isFetching && dataTime?.data) {
      const tempListDay = dataTime?.data.map((day) => ({
        value: day,
        label: day.day_label,
      }));
      setListDay([...tempListDay]);
      setSelectedDay(null);
      setSelectedTime(null);
      setListTime([]);
    }

    // eslint-disable-next-line
  }, [isLoading, isFetching]);

  return (
    <Dialog isVisible={isOpen} onClose={onClose} verticalCentered closable>
      <Flex flexDirection="column" gap="24px" width="320px">
        <Flex flexDirection="column" gap="16px">
          <Text variant="heading3" weight="bold">
            Pilih Waktu Pick Up
          </Text>
          <Divider />
          {isError ? (
            <Fail refetch={refetch} />
          ) : (
            <Flex flexDirection="column" gap="8px">
              <Text variant="caption" weight="bold" color="black50">
                Tanggal Pick Up
              </Text>
              <Flex gap="16px">
                <StyledSelectInput
                  options={listDay}
                  placeholder="Pilih hari"
                  size="small"
                  onChange={(event) => handleChangeDay(event)}
                  value={selectedDay}
                  hideSelectedOptions={false}
                  isDisabled={listDay.length === 0 || isAllLoading}
                />

                <StyledSelectInput
                  options={listTime}
                  placeholder="Pilih waktu"
                  size="small"
                  onChange={(event) => setSelectedTime(event)}
                  value={selectedTime}
                  hideSelectedOptions={false}
                  isDisabled={listTime.length === 0 || isAllLoading}
                />
              </Flex>
            </Flex>
          )}
        </Flex>

        {!isError && (
          <StyledButton
            isBlock
            disabled={
              isAllLoading ||
              !selectedDay ||
              !selectedTime?.value ||
              isLoadingRequestPickup
            }
            preffix={
              (isAllLoading || isLoadingRequestPickup) && (
                <Spinner color="white" size="8px" />
              )
            }
            onClick={handleRequestPickupInstant}
          >
            Kirim Pesanan
          </StyledButton>
        )}
      </Flex>
    </Dialog>
  );
};

export default ModalRequestPickup;
