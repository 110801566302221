import { Dialog, Flex, Text, Button } from "renos-ui";

const ModalDelete = ({
  isOpen,
  onClose,
  onConfirm,
  title,
  description,
  confirmText,
}) => {
  return (
    <Dialog
      isVisible={isOpen}
      closable
      onClose={onClose}
      verticalCentered
      width="300px"
      minHeight="100px"
    >
      <Flex flexDirection="column" width="100%" gap="24px">
        <Flex flexDirection="column" gap="8px">
          <Text variant="heading3" weight="bold">
            {title || "Hapus Pengaturan"}
          </Text>
          <Text>
            {description ||
              "Semua pengaturan yang kamu buat akan dihapus. Lanjut hapus?"}
          </Text>
        </Flex>
        <Flex justifyContent="space-between" gap="16px">
          <Button isBlock variant="tertiary" onClick={onClose}>
            Tidak, Batal
          </Button>
          <Button isBlock onClick={onConfirm}>
            {confirmText || "Ya, Hapus"}
          </Button>
        </Flex>
      </Flex>
    </Dialog>
  );
};

export default ModalDelete;
