import { Button, Dialog, Flex, Spinner, Text, useToast } from "renos-ui";
import { useMemo } from "react";
import { useEndVoucher } from "services/voucher-management";
import { useQueryClient } from "react-query";

const ModalConfirmation = ({
  isOpen,
  data,
  onClose,
  setDataFilter,
  dataFilter,
}) => {
  const toast = useToast();
  const queryClient = useQueryClient();

  const { mutate: endVoucher, isLoading } = useEndVoucher({
    onSuccess: (data) => {
      const { message, frontend_message } = data;

      queryClient.invalidateQueries({
        queryKey: ["voucherStatus"],
      });

      if (dataFilter.page === 1) {
        queryClient.invalidateQueries({
          queryKey: ["getVoucher"],
        });
      } else {
        setDataFilter({ ...dataFilter, page: 1 });
      }

      onClose();
      toast({
        label:
          frontend_message || message || `Berhasil ${textDescription} voucher`,
        placement: "top",
      });
    },
    onError: (data) => {
      const {
        response: {
          data: { frontend_message, message },
        },
      } = data;
      toast({
        label:
          frontend_message || message || `Gagal ${textDescription} voucher`,
        placement: "top",
        backgroundColor: "red50",
      });
    },
  });

  const textLabel = useMemo(
    () => (data.status === "Berlangsung" ? "Akhiri" : "Batalkan"),
    [data]
  );
  const textDescription = useMemo(
    () => (data.status === "Berlangsung" ? "mengakhiri" : "membatalkan"),
    [data]
  );

  return (
    <Dialog verticalCentered isVisible={isOpen}>
      <Flex width="320px" flexDirection="column" gap="24px" margin="-16px">
        <Flex flexDirection="column" gap="8px">
          <Text variant="heading3" weight="bold">
            {textLabel} Voucher?
          </Text>

          <Text>Apakah kamu mau {textDescription} voucher ini?</Text>
        </Flex>
        <Flex gap="16px">
          <Button variant="tertiary" isBlock onClick={onClose}>
            Batal
          </Button>
          <Button
            isBlock
            onClick={() => endVoucher(data.promo_id)}
            disabled={isLoading}
            preffix={isLoading && <Spinner color="white" size="8px" />}
          >
            Ya, {textLabel}
          </Button>
        </Flex>
      </Flex>
    </Dialog>
  );
};

export default ModalConfirmation;
