import useQueryParams from "hooks/useQueryParams";
import React, { useMemo, useState } from "react";
import { useRef } from "react";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Button,
  // Dropdown,
  Flex,
  Tabs,
  Text,
  useDisclosure,
  Icon,
  Divider,
} from "renos-ui";
import { useGetProductStatus } from "services/discount";
import Filter from "widgets/desktop/ProductFilter";
import ProductTable from "./component/ProductTable";
import { TabsSkeleton } from "./component/Skeleton";
import desktopUrl from "constants/desktopUrl";
import EmptyPromo from "./component/EmptyPromo";

const ProductList = ({ handleAdd }) => {
  const containerRef = useRef();
  const filterDisclosure = useDisclosure({ open: false });
  const query = useQueryParams();
  const { push } = useHistory();

  const [page, setPage] = useState(1);
  const [filterParam, setFilterParam] = useState({
    keyword: "",
    ordering: "desc",
    order_by: "product_discount_date_up",
  });

  const [activeTab, setActiveTab] = useState(
    query.get("tab-active") || "on_going"
  );

  const getProductStatus = (activeTab) => {
    switch (activeTab) {
      case "on_going":
        return "on_going";

      case "created":
        return "created";

      case "ended":
        return "ended";

      default:
        return null;
    }
  };

  const [productStatus, setProductStatus] = useState(
    getProductStatus(activeTab) || null
  );

  const {
    data: dataProductStatus,
    isLoading: isStatusLoading,
    isFetching: isStatusFetching,
    refetch: isStatusRefetch,
  } = useGetProductStatus();

  const isStatusNotReady = useMemo(
    () => isStatusLoading || isStatusFetching,
    [isStatusFetching, isStatusLoading]
  );

  const items = [
    {
      label: isStatusNotReady
        ? TabsSkeleton
        : `Berlangsung (${dataProductStatus?.on_going})`,
      key: "on_going",
      width: "fit-content",
      children: () => <></>,
    },
    {
      label: isStatusNotReady
        ? TabsSkeleton
        : `Akan Datang (${dataProductStatus?.created})`,
      key: "created",
      children: () => <></>,
    },
  ];

  const handleChangeTabActive = (value) => {
    setActiveTab(value);
    setPage(1);
    setFilterParam({
      keyword: "",
      ordering: "desc",
      order_by: "product_discount_date_up",
    });
    setProductStatus(getProductStatus(value));
    push(`${desktopUrl.DISCOUNT}?tab-active=${value}`);
    isStatusRefetch();
  };

  useEffect(() => {
    containerRef.current.scrollIntoView({
      behavior: "smooth",
    });
  }, [page]);

  if (!isStatusNotReady && dataProductStatus?.totalStatus === 0)
    return <EmptyPromo handleAdd={handleAdd} />;

  return (
    <Flex
      ref={containerRef}
      flexDirection="column"
      rowGap="8px"
      marginTop="20px"
    >
      <Flex
        height="80px"
        backgroundColor="white"
        borderTopLeftRadius="16px"
        borderTopRightRadius="16px"
        boxShadow="0px 4px 4px rgba(20, 20, 32, 0.05)"
        justifyContent="space-between"
        alignItems="center"
        paddingX="24px"
      >
        <Text variant="heading3" weight="bold">
          Daftar Diskon Produk
        </Text>
        <Flex columnGap="16px">
          {/* <Dropdown>
            <Dropdown.Toggle>
              <Button
                variant="tertiary"
                size="large"
                suffix={<Icon name="Down-outline" />}
              >
                Atur Sekaligus
              </Button>
            </Dropdown.Toggle>
            <Dropdown.Content>
              <Dropdown.Item onClick={handleAdd}>
                <Flex px="16" py="6px">
                  Tambah Produk
                </Flex>
              </Dropdown.Item>
            </Dropdown.Content>
          </Dropdown> */}
          <Button
            size="large"
            preffix={<Icon name="Plus-outline" />}
            onClick={handleAdd}
          >
            Tambah Produk
          </Button>
        </Flex>
      </Flex>
      <Flex
        borderBottomLeftRadius="16px"
        borderBottomRightRadius="16px"
        boxShadow="0px 4px 4px rgba(20, 20, 32, 0.05)"
        backgroundColor="white"
        padding="24px"
        flexDirection="column"
        rowGap="24px"
        zIndex={0}
      >
        <Tabs
          onChange={handleChangeTabActive}
          defaultActiveKey={activeTab}
          placement="bottom"
          items={items}
        />
        <Filter
          filterDisclosure={filterDisclosure}
          setFilterParam={setFilterParam}
          filterParam={filterParam}
          activeTab={activeTab}
          setPage={setPage}
          isPromo={true}
        />
        <Divider />
        <ProductTable
          productStatus={productStatus}
          dataProductStatus={dataProductStatus}
          activeTab={activeTab}
          page={page}
          setPage={setPage}
          filterParam={filterParam}
        />
      </Flex>
    </Flex>
  );
};

export default ProductList;
