import { Divider, Flex, Skeleton, Text, range } from "renos-ui";

const Loading = () => {
  return (
    <Flex gap="24px" flexDirection="column">
      <Flex gap="24px">
        <Flex width="calc(100% - 712px)">
          <Text color="black50" weight="bold">
            Informasi Vocher
          </Text>
        </Flex>
        <Flex width="180px">
          <Text color="black50" weight="bold">
            Kode Voucher
          </Text>
        </Flex>
        <Flex width="220px">
          <Text color="black50" weight="bold">
            Kuota & Penggunaan
          </Text>
        </Flex>
        <Flex width="132px">
          <Text color="black50" weight="bold">
            Periode
          </Text>
        </Flex>
        <Flex width="132px">
          <Text color="black50" weight="bold">
            Status
          </Text>
        </Flex>
        <Flex width="132px" />
      </Flex>
      <Divider />

      {range(4).map((_, indexSkeleton) => (
        <Flex gap="24px" key={`skeleton-${indexSkeleton}`}>
          <Flex width="calc(100% - 712px)" alignItems="center" columnGap="16px">
            <Skeleton width="64px" height="64px" />

            <Flex gap="4px" flexDirection="column" width="calc(100% - 80px)">
              <Skeleton width="100%" height="24px" />
              <Skeleton width="70%" height="20px" />
              <Skeleton width="80%" height="20px" />
            </Flex>
          </Flex>
          <Flex gap="4px" flexDirection="column" width="180px">
            <Skeleton width="100%" height="24px" />
            <Skeleton width="70%" height="20px" />
            <Skeleton width="80%" height="20px" />
          </Flex>
          <Flex gap="4px" flexDirection="column" width="220px">
            <Skeleton width="100%" height="24px" />
            <Skeleton width="70%" height="20px" />
            <Skeleton width="80%" height="20px" />
          </Flex>
          <Flex width="132px">
            <Skeleton width="100%" height="20px" />
          </Flex>
          <Flex width="132px">
            <Skeleton width="60px" height="20px" />
          </Flex>
          <Flex width="132px">
            <Skeleton width="100%" height="20px" />
          </Flex>
        </Flex>
      ))}
    </Flex>
  );
};

export default Loading;
