import React, { useState } from "react";
import { useCheckAuth, useCheckStore } from "services/auth";
import { useEffect } from "react";
import { ToastProvider, isEmpty } from "renos-ui";
import StoreInformation from "./store-information";
import DetailInformation from "./detail-information";

const Register = () => {
  const [step, setStep] = useState(1);
  const { data: authData } = useCheckAuth();
  const { data: storeData } = useCheckStore();

  useEffect(() => {
    if (isEmpty(authData)) return;

    if (!authData?.status) {
      window.location.pathname = "/seller-landing";
    }
  }, [authData]);

  useEffect(() => {
    if (isEmpty(storeData)) return;

    if (storeData?.status) {
      window.location.pathname = "/";
    }
  }, [storeData]);

  return (
    <ToastProvider zIndex={10000}>
      {step === 1 ? (
        <StoreInformation setStep={setStep} />
      ) : (
        <DetailInformation setStep={setStep} />
      )}
    </ToastProvider>
  );
};

export default Register;
