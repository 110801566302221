import { Flex, useDisclosure, useToast, Spinner, Icon } from "renos-ui";
import { StyledButton, StyledButtonIcon } from "../styled";
import { useMemo } from "react";
import Popup3pl from "./Popup3pl";
import PopupSellerFleet from "./PopupSellerFleet";
import { useRequestPickupInstant } from "services/order-management";
import PopupMore from "./PopupMore";

const SendOrder = ({ data, toFirstPage }) => {
  const toast = useToast();
  const { shipping, order_id, store_order_shipment } = data;
  const disclosure3pl = useDisclosure({ isOpen: false });
  const disclosureSellerFleet = useDisclosure({ isOpen: false });
  const disclosureMore = useDisclosure({ isOpen: false });

  const { mutate: requestPickupInstant, isLoading: isLoadingRequestPickup } =
    useRequestPickupInstant({
      onSuccess: (data) => {
        const { message, frontend_message } = data;
        toFirstPage();
        toast({
          label: frontend_message || message || "Request pickup berhasil",
          placement: "bottom",
        });
      },
      onError: (data) => {
        const {
          response: {
            data: { frontend_message, message },
          },
        } = data;
        toast({
          label: frontend_message || message || "Request pickup gagal",
          placement: "bottom",
          backgroundColor: "red50",
        });
      },
    });

  const handleRequestPickupInstant = () => {
    const params = {
      order_id: order_id,
      pickup: true,
      awb: "",
    };
    requestPickupInstant(params);
  };

  const onclickButton = () => {
    if (shipping?.is_seller_fleet) {
      disclosureSellerFleet.onOpen();
    } else {
      if (store_order_shipment.request_pickup_datetime_possible) {
        disclosure3pl.onOpen();
      } else {
        handleRequestPickupInstant();
      }
    }
  };

  const labelButton = useMemo(
    () => (shipping?.is_seller_fleet ? "Kirim Pesanan" : "Request Pick Up"),
    [shipping]
  );

  return (
    <Flex gap="8px" alignItems="center">
      <StyledButton
        size="small"
        isLoading={isLoadingRequestPickup}
        preffix={isLoadingRequestPickup && <Spinner color="white" size="8px" />}
        onClick={onclickButton}
        disabled={isLoadingRequestPickup}
      >
        {isLoadingRequestPickup ? "Loading..." : labelButton}
      </StyledButton>
      <StyledButtonIcon
        variant="tertiary"
        size="small"
        onClick={disclosureMore.onOpen}
        icon={<Icon size={20} name="Vertical-outline" />}
      />
      <Popup3pl
        disclosure3pl={disclosure3pl}
        toFirstPage={toFirstPage}
        orderId={order_id}
      />
      <PopupSellerFleet
        disclosure={disclosureSellerFleet}
        toFirstPage={toFirstPage}
        orderId={order_id}
      />
      <PopupMore disclosure={disclosureMore} data={data} />
    </Flex>
  );
};

export default SendOrder;
