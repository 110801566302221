import React from "react";
import { Flex } from "renos-ui";
import VariantSelector from "./VariantSelector";
import ImageSelector from "./ImageSelector";

const Set = () => {
  return (
    <Flex gap="16px">
      <VariantSelector />
      <ImageSelector />
    </Flex>
  );
};

export default Set;
