import dayjs from "dayjs";
import { useMemo, useState } from "react";
import {
  Button,
  DatePicker,
  Divider,
  Flex,
  Spinner,
  Text,
  useToast,
} from "renos-ui";
import { StyledDialog, Wrapper } from "./styled";
import { useEffect } from "react";
import { goToUrl } from "helpers";
import ENDPOINTS from "constants/endpoints";
import { useDownloadSalesReport } from "services/order-management";

const ModalDownload = ({ isOpen, onClose }) => {
  const toast = useToast();

  const [selectedDate, setSelectedDate] = useState({
    startDate: new Date(),
    endDate: new Date(),
  });

  const { mutate, isLoading } = useDownloadSalesReport({
    onSuccess: () => {
      onClose();

      toast({
        label: "Berhasil mengunduh laporan penjualan.",
        placement: "top",
      });

      goToUrl(
        `${process.env.REACT_APP_API}/${
          ENDPOINTS.download_sales_report
        }?sort_type=asc&date_start=${dayjs(selectedDate.startDate).format(
          "YYYY-MM-DD"
        )}&date_end=${dayjs(selectedDate.endDate).format("YYYY-MM-DD")}`,
        "_blank"
      );
    },
    onError: (data) => {
      const {
        response: {
          data: { frontend_message },
        },
      } = data;
      toast({
        label: frontend_message || "Gagal mengunduh laporan penjualan.",
        placement: "top",
        backgroundColor: "red50",
      });
    },
  });

  const getMaxDate = (tempDate) => {
    return new Date(tempDate.getTime() + 29 * 24 * 60 * 60 * 1000);
  };

  const maxDateRange = useMemo(
    () => getMaxDate(selectedDate.startDate),
    [selectedDate]
  );

  const maxDate = useMemo(() => {
    const today = new Date();

    return maxDateRange.getTime() < today.getTime() ? maxDateRange : today;
  }, [maxDateRange]);

  const handleDownload = () => {
    mutate({
      sort_type: "asc",
      date_start: dayjs(selectedDate.startDate).format("YYYY-MM-DD"),
      date_end: dayjs(selectedDate.endDate).format("YYYY-MM-DD"),
    });
  };

  const isDisabled = useMemo(
    () => isLoading || !selectedDate.startDate || !selectedDate.endDate,
    [isLoading, selectedDate]
  );

  useEffect(() => {
    setSelectedDate({ startDate: new Date(), endDate: new Date() });
  }, [isOpen]);

  return (
    <StyledDialog
      isVisible={isOpen}
      onClose={onClose}
      verticalCentered
      width="592px"
      closable
    >
      <Flex flexDirection="column" width="100%">
        <Flex flexDirection="column" gap="8px" pb="16px">
          <Text variant="heading3" weight="bold">
            Download Laporan Penjualan
          </Text>
          <Text>
            Atur rentang tanggal dan download laporan untuk cek ringkasan
            penjualan.
          </Text>
        </Flex>
        <Divider />
        <Wrapper>
          <DatePicker.Range
            startDate={selectedDate.startDate}
            endDate={selectedDate.endDate}
            onChange={([startDate, endDate]) =>
              setSelectedDate({ startDate, endDate })
            }
            maxDate={maxDate}
            dateFormat="dd MMM yyyy"
            inline
          />
        </Wrapper>
        <Divider />
        <Flex py="16px" alignItems="center" justifyContent="space-between">
          <Text variant="caption" color="black50">
            *Kamu hanya bisa memilih maksimal 30 hari
          </Text>
          <Button
            size="medium"
            onClick={handleDownload}
            disabled={isDisabled}
            preffix={isLoading && <Spinner color="white" size="8px" />}
          >
            Download Laporan
          </Button>
        </Flex>
      </Flex>
    </StyledDialog>
  );
};

export default ModalDownload;
