import React, { useContext, useState } from "react";
import {
  Checkbox,
  Flex,
  Icon,
  Input,
  Skeleton,
  Text,
  range,
  Tooltip,
} from "renos-ui";
import { useGetCouriers } from "services/logistics/courier";
import FilterProductContext from "providers/FilterProvider";

const FilterShipping = () => {
  const { data, isLoading, isError } = useGetCouriers();
  const { shippingIds, setShippingIds } = useContext(FilterProductContext);
  const [query, setQuery] = useState("");

  const handleOnChangeCheckbox = (id) => {
    if (shippingIds.includes(id)) {
      const selectedIndex = shippingIds.findIndex((data) => data === id);

      const tempChecked = [...shippingIds];
      tempChecked.splice(selectedIndex, 1);

      return setShippingIds(tempChecked);
    }

    setShippingIds((prev) => [...prev, id]);
  };

  const handleChange = (event) => {
    setQuery(event.target.value);
    const results = data?.data
      .map((obj) => ({
        ...obj,
        services: obj.services.filter((nestedObj) =>
          nestedObj.name.toLowerCase().includes(query.toLowerCase())
        ),
      }))
      .filter((obj) => obj.services.length > 0);
    data.data = results;
  };

  return (
    <Flex
      padding="24px"
      flexDirection="column"
      width="100%"
      rowGap="16px"
      marginBottom="16px"
    >
      <Input
        label="Pilih Jasa Pengiriman"
        prefix={<Icon name="Search-outline" />}
        placeholder="Cari nama layanan pengiriman"
        onChange={handleChange}
        value={query}
      />
      {isError ? (
        <Flex />
      ) : isLoading ? (
        <Flex flexDirection="column" rowGap="8px">
          {range(12).map((_, idx) => (
            <Flex alignItems="center" gap="8px" key={`indexSkeleton-${idx}`}>
              <Skeleton />
              <Skeleton />
            </Flex>
          ))}
        </Flex>
      ) : (
        <Flex
          flexWrap="wrap"
          justifyContent="space-between"
          width="100%"
          rowGap="16px"
        >
          {data.data.map((row) => (
            <Flex
              rowGap="18px"
              flexDirection="column"
              width="calc(100% / 2)"
              marginBottom="24px"
              key={`${row.id}-${row.name}`}
            >
              <Text variant="caption" weight="bold" color="black50">
                {row.name}
              </Text>
              {row?.services?.map((level2) => (
                <Flex
                  gap="8px"
                  key={`${level2.id}-${level2.api_reference_name}`}
                >
                  <Flex alignItems="center" gap="8px">
                    <Checkbox
                      checked={shippingIds?.includes(level2.id)}
                      onChange={() => handleOnChangeCheckbox(level2.id)}
                    />
                    <Tooltip placement="top" label={level2?.name}>
                      <Text color="black75" variant="caption" lineClamp={1}>
                        {level2?.name}
                      </Text>
                    </Tooltip>
                  </Flex>
                </Flex>
              ))}
            </Flex>
          ))}
        </Flex>
      )}
    </Flex>
  );
};

export default FilterShipping;
