import { useMemo } from "react";
import { Flex, Icon, Text } from "renos-ui";

const CardProduct = ({ data, isBestSeller }) => {
  const selectedData = useMemo(
    () =>
      !data
        ? []
        : data?.product_skus?.length > 0 && !isBestSeller
        ? data?.product_skus[0]
        : data,
    // eslint-disable-next-line
    [data]
  );

  return (
    <Flex
      flexDirection="column"
      borderRadius="8px"
      boxShadow="0px 2px 4px 0px #14142026"
      width="160px"
    >
      <Flex
        as="img"
        alt="img-product"
        height="160px"
        width="160px"
        borderTopLeftRadius="8px"
        borderTopRightRadius="8px"
        src={selectedData.product_image_url}
        onError={(e) => {
          e.target.onerror = null;
          e.target.src = "/assets/images/empty-product.svg";
        }}
      />
      <Flex flexDirection="column" gap="4px" p="12px">
        <Text variant="caption" color="black75" lineClamp={2}>
          {selectedData.product_name}
        </Text>

        {selectedData?.formatted_product_discount_percentage && (
          <Flex gap="4px" alignItems="center">
            <Flex padding="1px 6px" borderRadius="16px" backgroundColor="red5">
              <Text variant="small" color="red50" weight="bold">
                {selectedData.formatted_product_discount_percentage}
              </Text>
            </Flex>
            <Text variant="small" color="black50" strikeThrough>
              {selectedData.formatted_product_price}
            </Text>
          </Flex>
        )}

        <Text variant="body" color="black75" weight="bold">
          {selectedData.formatted_selling_price}
        </Text>

        <Flex gap="4px" alignItems="center">
          <Icon name="Location-solid" size={12} color="black50" />
          <Text variant="caption" color="black50" lineClamp={1}>
            Jakarta Pusat
          </Text>
        </Flex>

        <Flex gap="8px" alignItems="center">
          {data?.review_rating > 0 && (
            <Flex gap="4px" alignItems="center">
              <Icon name="Star-solid" size={12} color="yellow50" />
              <Text variant="caption" color="black50" lineClamp={1}>
                {data?.review_rating}
              </Text>
            </Flex>
          )}

          <Icon name="Circle-solid" size={4} color="black10" />
          <Text variant="caption" color="black50" lineClamp={1}>
            Terjual {data?.total_sold}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default CardProduct;
