import { getUniqueListBy } from "helpers";
import React, { useMemo, useState } from "react";
import { useEffect } from "react";
import { useWatch } from "react-hook-form";
import {
  Button,
  colors,
  Flex,
  groupBy,
  Icon,
  Input,
  isEmpty,
  SelectInput,
  Text,
  Toggle,
  Tooltip,
  // isEmpty,
} from "renos-ui";
import {
  useGetProductVariant,
  useGetVariantValue,
} from "services/product-variant";
import { FieldNumberInput, FieldTextInput } from "widgets/global/HookForm";
import FormField from "../components/FormField";
import SectionWrapper from "../components/SectionWrapper";
import { StyledSelectInput } from "./styled";
import VariantForm from "./VariantForm";
import Each from "widgets/global/Each";

const ProductVariant = ({ methods, productDetail, isEdit }) => {
  const [productVariant, setProductVariant] = useState();

  const productIsActive = useWatch({ name: "product_active" });
  const productStock = useWatch({ name: "stock" });
  const sku = useWatch({ name: "sku" });
  const isHavePromo = useWatch({ name: "is_have_promo" });
  const productAttributes = useWatch({ name: "product_attributes" });

  // const productMaterials = useWatch({ name: "product_materials" });
  // const productColors = useWatch({ name: "product_colors" });

  const isProductVariantsHaveOptions = productVariant
    ? productVariant.every((data) => Object.keys(data).includes("options"))
    : false;

  useEffect(() => {
    if (isProductVariantsHaveOptions && !isEmpty(productDetail)) {
      const flattenSku = productDetail.product_skus
        .map((sku) => sku.variants)
        .flat();

      const reducedSku = getUniqueListBy(flattenSku, "variant_value");

      const groupedSku = groupBy(reducedSku, (sku) => sku.variant_label);

      const tempProductVariant = [...productVariant];

      Object.keys(groupedSku).forEach((key) => {
        const exposedIndex = tempProductVariant.findIndex(
          (variant) => variant?.variant_name === key
        );

        groupedSku[key].forEach((variant) =>
          tempProductVariant[exposedIndex]?.variant_values.push({
            variant_value_id: variant?.variant_value,
            variant_id: variant?.variant,
            variant_name: variant?.variant_label,
            variant_value: variant?.label,
            label: variant?.label,
            value: variant?.variant_value,
          })
        );
      });

      setProductVariant(tempProductVariant);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isProductVariantsHaveOptions, productDetail]);

  const selectedVariant = useMemo(
    () => productVariant && productVariant.map((variant) => variant.variant_id),
    [productVariant]
  );

  useEffect(() => {
    if (isEmpty(productDetail)) return;

    if (productDetail?.product_skus.length > 0) {
      const isVariant = productDetail?.product_skus[0].variants.length > 0;

      if (isVariant) {
        const tempProductVariant = productDetail?.product_skus[0].variants.map(
          (data) => ({
            variant_id: data.variant,
            variant_name: data.variant_label,
            variant_values: [],
            isEdit: true,
          })
        );

        setProductVariant(tempProductVariant);
        setTimeout(() => {
          tempProductVariant.forEach((data) => {
            const params = {
              "variant[]": data.variant_id,
            };
            getVariantValue(params);
          });
        }, 250);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productDetail]);

  const { data: variants, isLoading: isLoadingVariants } = useGetProductVariant(
    {
      enabled: !!productVariant,
    }
  );

  // const { data: colorVariants } = useVariantValue({
  //   "variant[]": 2,
  // });

  // const { data: materialVariants } = useVariantValue({
  //   "variant[]": 1,
  // });

  const { mutate: getVariantValue } = useGetVariantValue({
    onSuccess: (data) => {
      const tempProductVariant = [...productVariant];
      const selectedIndex = tempProductVariant.findIndex(
        (variant) => variant.variant_id === data[0].variant_id
      );
      tempProductVariant[selectedIndex].options = data;

      const allVariantValues = productVariant.map(
        (variant) => variant.variant_values
      );

      const newData = [];

      allVariantValues.forEach((data, i) => {
        const reFlatted = [
          ...new Map(data.map((m) => [m.variant_value_id, m])).values(),
        ];

        newData.push({
          ...tempProductVariant[i],
          variant_values_minify: reFlatted,
        });
      });

      setProductVariant(newData);
    },
  });

  const onVariantChose = (value, selectedIndex) => {
    const params = {
      "variant[]": value.variant_id,
    };
    const tempProductVariant = [...productVariant];

    tempProductVariant[selectedIndex].variant_values = [];
    tempProductVariant[selectedIndex].variant_id = value.variant_id;
    tempProductVariant[selectedIndex].variant_name = value.variant_name;
    tempProductVariant[selectedIndex].isEdit = false;

    const allVariantValues = productVariant.map(
      (variant) => variant.variant_values
    );

    const newData = [];

    allVariantValues.forEach((data, i) => {
      const reFlatted = [
        ...new Map(data.map((m) => [m.variant_value_id, m])).values(),
      ];

      newData.push({
        ...tempProductVariant[i],
        variant_values_minify: reFlatted,
      });
    });

    setProductVariant(newData);
    getVariantValue(params);
  };

  const onVariantValueChange = (value, selectedIndex, variant) => {
    const tempProductVariant = [...productVariant];

    tempProductVariant[selectedIndex].variant_values = value.map((data, i) => {
      if (data.__isNew__) {
        data.variant_id = variant.variant_id;
        data.variant_name = variant.variant_name;
        data.variant_value = data.value;
        data.variant_value_id = `new-${i}`;
        delete data.__isNew__;
      }

      return data;
    });

    tempProductVariant[selectedIndex].variant_values_minify = value.map(
      (data, i) => {
        if (data.__isNew__) {
          data.variant_id = variant.variant_id;
          data.variant_name = variant.variant_name;
          data.variant_value = data.value;
          data.variant_value_id = `new-${i}`;
          delete data.__isNew__;
        }

        return data;
      }
    );
    setProductVariant(tempProductVariant);
  };

  const onVariantDelete = (index) => {
    const tempProductVariant = [...productVariant];
    tempProductVariant.splice(index, 1);

    if (tempProductVariant.length === 0) {
      methods.setValue("product_active", 1);
      // methods.setValue("product_materials", null);
      // methods.setValue("product_colors", null);
      methods.setValue("price", 0);
      methods.setValue("stock", isEdit ? 0 : 1);
      methods.setValue("variant", 0);

      return setProductVariant();
    }

    return setProductVariant(tempProductVariant);
  };

  // const isColorSelected = useMemo(
  //   () =>
  //     productVariant &&
  //     !isEmpty(
  //       productVariant.find(
  //         (variant) => variant?.variant_name?.toLowerCase() === "warna"
  //       )
  //     ),
  //   [productVariant]
  // );

  // const isMaterialSelected = useMemo(
  //   () =>
  //     productVariant &&
  //     !isEmpty(
  //       productVariant.find(
  //         (variant) => variant?.variant_name?.toLowerCase() === "material"
  //       )
  //     ),
  //   [productVariant]
  // );

  const onAttributeChange = (value, index, dataType) => {
    const tempProductAttribute = [...productAttributes];
    let newValue = value;

    if (dataType === "numeric") {
      newValue = value.replace(/\D+/g, "");
    }

    tempProductAttribute[index].product_attribute_value = newValue;

    methods.setValue("product_attributes", tempProductAttribute);
  };

  const renderVariantSelect = (variant, i) => {
    if (!isEmpty(variant?.options)) {
      if (variant.isEdit) {
        if (variant.variant_values.length > 0) {
          return (
            <Flex position="relative" flexDirection="column">
              <StyledSelectInput
                isMulti
                options={variant?.options}
                placeholder={`Ketik atau pilih tipe varian ${variant.variant_name.toLowerCase()}`}
                isCreatable
                variantValues={
                  !isEmpty(variant?.variant_values_minify)
                    ? variant.variant_values_minify
                    : variant?.variant_values
                }
                onChange={(value) => onVariantValueChange(value, i, variant)}
                value={
                  !isEmpty(variant?.variant_values_minify)
                    ? variant.variant_values_minify
                    : variant?.variant_values
                }
                styles={{
                  placeholder: (base) => ({
                    ...base,
                    fontSize: "14px",
                    color: colors.black75,
                  }),
                  multiValue: (base) => ({
                    ...base,
                    backgroundColor: "transparent",
                    border: `1px solid ${colors.black10}`,
                    borderRadius: "8px",
                    display: "flex",
                    alignItems: "center",
                    height: "32px",
                  }),
                  control: (base) => ({
                    ...base,
                    padding: "2px 16px !important",
                  }),
                  menu: (base) => ({
                    ...base,
                    zIndex: 2,
                  }),
                }}
              />
              <Flex
                as="input"
                type="text"
                position="absolute"
                width="100%"
                height="100%"
                zIndex="-1"
                required
                value={variant?.variant_values?.length > 0 ? 1 : null}
              />
            </Flex>
          );
        } else {
          return <Input disabled placeholder="Ketik atau pilih tipe varian" />;
        }
      } else {
        return (
          <Flex position="relative" flexDirection="column">
            <StyledSelectInput
              isMulti
              options={variant?.options}
              placeholder={`Ketik atau pilih tipe varian ${variant.variant_name.toLowerCase()}`}
              isCreatable
              variantValues={variant.variant_values}
              onChange={(value) => onVariantValueChange(value, i, variant)}
              value={variant?.variant_values}
              styles={{
                placeholder: (base) => ({
                  ...base,
                  fontSize: "14px",
                  color: colors.black75,
                }),
                multiValue: (base) => ({
                  ...base,
                  backgroundColor: "transparent",
                  border: `1px solid ${colors.black10}`,
                  borderRadius: "8px",
                  display: "flex",
                  alignItems: "center",
                  height: "32px",
                }),
                control: (base) => ({
                  ...base,
                  padding: "2px 16px !important",
                }),
                menu: (base) => ({
                  ...base,
                  zIndex: 2,
                }),
              }}
            />
            <Flex
              as="input"
              type="text"
              position="absolute"
              width="100%"
              height="100%"
              zIndex="-1"
              required
              value={variant?.variant_values?.length > 0 ? 1 : null}
            />
          </Flex>
        );
      }
    }

    return <Input disabled placeholder="Ketik atau pilih tipe varian" />;
  };

  return (
    <SectionWrapper sectionName="Spesifikasi Produk">
      <FormField
        label="Varian Produk"
        hint="Tambah varian untuk produkmu seperti pilihan warna, ukuran, atau lainnya. Maksimum 2 tipe varian, ya."
      >
        {productVariant && !isLoadingVariants ? (
          <Flex flexDirection="column" rowGap="24px">
            {productVariant.map((variant, i) => {
              const selectValue = variants.find(
                (data) => data.variant_id === variant.variant_id
              );

              return (
                <Flex columnGap="16px" alignItems="center">
                  <Flex
                    flexDirection="column"
                    width="184px"
                    flexShrink={0}
                    position="relative"
                  >
                    <SelectInput
                      styles={{
                        placeholder: (base) => ({
                          ...base,
                          fontSize: "14px",
                          padding: "1px 0",
                          color: colors.black75,
                        }),
                      }}
                      value={selectValue}
                      options={
                        selectedVariant
                          ? variants.filter(
                              (data) =>
                                !selectedVariant.includes(data.variant_id)
                            )
                          : variants
                      }
                      placeholder="Pilih varian"
                      onChange={(value) => onVariantChose(value, i)}
                    />
                    <Flex
                      as="input"
                      type="text"
                      position="absolute"
                      width="100%"
                      height="100%"
                      zIndex="-1"
                      required
                      value={selectValue?.variant_id}
                    />
                  </Flex>
                  <Flex width="100%" flexDirection="column">
                    {renderVariantSelect(variant, i)}
                  </Flex>
                  <Flex
                    width="32px"
                    height="32px"
                    justifyContent="center"
                    alignItems="center"
                    borderStyle="solid"
                    borderWidth="1px"
                    borderColor="black10"
                    borderRadius="4px"
                    cursor="pointer"
                    flexShrink={0}
                    onClick={() => onVariantDelete(i)}
                  >
                    <Icon size={16} name="Trash-solid" />
                  </Flex>
                </Flex>
              );
            })}
            {productVariant.length < 2 && (
              <Flex
                columnGap="8px"
                alignItems="center"
                cursor="pointer"
                onClick={() => setProductVariant((prev) => [...prev, {}])}
              >
                <Icon name="Plus-solid" color="blue50" />
                <Text weight="bold" color="blue50">
                  Tambah Varian
                </Text>
              </Flex>
            )}
          </Flex>
        ) : (
          <Button
            type="button"
            variant="tertiary"
            size="large"
            preffix={<Icon name="Plus-outline" />}
            onClick={() => {
              setProductVariant([{}]);
              methods.setValue("fromEmpty", true);
              methods.setValue("variant", 1);
            }}
          >
            Tambah Varian
          </Button>
        )}
      </FormField>
      {productVariant && (
        <VariantForm
          sku={sku}
          isEdit={isEdit}
          methods={methods}
          productVariant={productVariant}
          productDetail={productDetail}
        />
      )}
      {/* <FormField
        label="Spesifikasi Produk"
        hint="Tambah keterangan supaya produkmu makin mudah dikenali pembeli (tidak wajib diisi)."
      >
        <Flex gap="16px" flexWrap="wrap">
          {!isColorSelected && (
            <Box width="284px">
              <Flex flexDirection="column" rowGap="8px">
                <Text variant="caption" color="black50" weight="bold">
                  Warna
                </Text>
                <StyledSelectInput
                  isMulti
                  options={colorVariants}
                  placeholder="Pilih Warna"
                  isCreatable
                  variantValues={productColors}
                  onChange={(value) =>
                    methods.setValue("product_colors", value)
                  }
                  styles={{
                    placeholder: (base) => ({
                      ...base,
                      fontSize: "14px",
                      color: colors.black75,
                    }),
                    multiValue: (base) => ({
                      ...base,
                      backgroundColor: "transparent",
                      border: `1px solid ${colors.black10}`,
                      borderRadius: "8px",
                      display: "flex",
                      alignItems: "center",
                      height: "32px",
                    }),
                    control: (base) => ({
                      ...base,
                      padding: "2px 16px !important",
                    }),
                  }}
                />
              </Flex>
            </Box>
          )}
          <Box width="284px">
            <Flex flexDirection="column" rowGap="8px">
              <FieldNumberInput
                label="Merek"
                name="brand"
                placeholder="Pilih atau Ketik Merek"
              />
            </Flex>
          </Box>
          {!isMaterialSelected && (
            <Flex width="284px" flexDirection="column" rowGap="8px">
              <Text variant="caption" color="black50" weight="bold">
                Material
              </Text>
              <StyledSelectInput
                isMulti
                options={materialVariants}
                variantValues={productMaterials}
                placeholder="Pilih Material"
                isCreatable
                onChange={(value) =>
                  methods.setValue("product_materials", value)
                }
                styles={{
                  placeholder: (base) => ({
                    ...base,
                    fontSize: "14px",
                    color: colors.black75,
                  }),
                  multiValue: (base) => ({
                    ...base,
                    backgroundColor: "transparent",
                    border: `1px solid ${colors.black10}`,
                    borderRadius: "8px",
                    display: "flex",
                    alignItems: "center",
                    height: "32px",
                  }),
                  control: (base) => ({
                    ...base,
                    padding: "2px 16px !important",
                  }),
                }}
              />
            </Flex>
          )}
          <Box width="284px">
            <FieldTextInput
              label="Dimensi Produk"
              name="dimensions"
              placeholder="Ketik Dimensi"
            />
          </Box>
        </Flex>
      </FormField> */}
      {!isEmpty(productAttributes) && (
        <FormField
          label="Standarisasi Produk"
          hint="Tambahkan nomor SNI produkmu apabila tersedia."
        >
          <Flex flexDirection="column" rowGap="16px">
            <Each
              of={productAttributes}
              render={(attribute, attributeIndex) => (
                <Flex width={284}>
                  <Input
                    label={attribute.attribute_name}
                    maxLength={
                      attribute?.limit && attribute?.limit > 0
                        ? attribute.limit
                        : null
                    }
                    value={attribute.product_attribute_value}
                    onChange={(event) =>
                      onAttributeChange(
                        event.target.value,
                        attributeIndex,
                        attribute.data_type
                      )
                    }
                    required={attribute.is_required === 1}
                  />
                </Flex>
              )}
            />
          </Flex>
        </FormField>
      )}
      {!productVariant && (
        <>
          <FormField label="Harga Produk" isRequired>
            <Flex position="relative">
              {isEdit && isHavePromo ? (
                <Tooltip
                  label={
                    <Flex>
                      Harga produk tidak bisa diubah karena <br /> produk sudah
                      diatur untuk promosi.
                    </Flex>
                  }
                  placement="bottom-left"
                  containerWidth="100%"
                >
                  <FieldNumberInput
                    name="price"
                    placeholder="Masukkan Harga"
                    max={999999999}
                    prefix={
                      <Text color="black50" weight="bold">
                        Rp
                      </Text>
                    }
                    disabled={isHavePromo}
                  />
                </Tooltip>
              ) : (
                <FieldNumberInput
                  name="price"
                  placeholder="Masukkan Harga"
                  max={999999999}
                  prefix={
                    <Text color="black50" weight="bold">
                      Rp
                    </Text>
                  }
                />
              )}
            </Flex>
          </FormField>
          <FormField label="Stok Produk" isRequired>
            <FieldNumberInput
              name="stock"
              placeholder="Masukkan Jumlah Stok"
              max={99999}
              value={productStock === 0 ? "0" : productStock}
            />
          </FormField>
          <FormField
            label="SKU (Stock Keeping Unit)"
            hint="Gunakan kode unik SKU jika kamu ingin menandai produkmu."
          >
            <FieldTextInput
              name="product_sku"
              placeholder="Masukkan Kode SKU"
            />
          </FormField>

          <FormField
            label="Status Produk"
            hint="Jika status aktif, produkmu dapat dicari oleh calon pembeli."
          >
            <Flex
              alignItems="start"
              columnGap="8px"
              onClick={() =>
                methods.setValue("product_active", !productIsActive)
              }
              cursor="pointer"
              width="fit-content"
            >
              <Toggle checked={productIsActive} />
              {productIsActive ? (
                <Text weight="bold" color="blue50">
                  Aktif
                </Text>
              ) : (
                <Text weight="bold" color="black50">
                  Nonaktif
                </Text>
              )}
            </Flex>
          </FormField>
        </>
      )}
    </SectionWrapper>
  );
};

export default ProductVariant;
