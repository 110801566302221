import { Flex, range } from "renos-ui";
import Card from "./component/Card";
import Loading from "./component/Loading";
import { useContext, useEffect, useMemo, useState } from "react";
import StoreContext from "providers/StoreProvider";
import {
  useGetNotification,
  useUpdateNotification,
} from "services/notification";
import dayjs from "dayjs";
import FailLoadData from "widgets/mobile/FailLoadData";
import { useQueryClient } from "react-query";
import InfiniteScroll from "widgets/global/InfiniteScroll";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const List = ({ selected }) => {
  const {
    storeData: {
      data: { id: storeId },
    },
  } = useContext(StoreContext);
  const { push } = useHistory();
  const queryClient = useQueryClient();
  const [hasNextPage, setHasNextPage] = useState(true);

  const [dataNotif, setDataNotif] = useState({
    page: 1,
    items_per_page: 10,
    store_id: storeId,
    notification_type_id: 3,
  });

  const { data, isLoading, isFetching, fetchNextPage } = useGetNotification(
    dataNotif,
    {
      onSuccess: (data) => {
        setFirstLoading(false);
      },
    }
  );

  // eslint-disable-next-line no-unused-vars
  const [firstLoading, setFirstLoading] = useState(true);

  const isNotifLoading = useMemo(
    () => (isLoading || isFetching) && dataNotif.page === 1,
    [isLoading, isFetching, dataNotif]
  );

  useEffect(() => {
    fetchNextPage(dataNotif);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataNotif]);

  useEffect(() => {
    let count = 0;

    if (data?.pages.length > 1) {
      data.pages.forEach((page) => {
        count += page.data.length;
      });
    }
    setHasNextPage(count < data?.pages[0].total);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.pages.length]);

  const isNotifEmpty = useMemo(
    () => !isLoading && data.pages.map((page) => page.data)[0].length === 0,
    [data, isLoading]
  );

  const { mutate: updateNotification } = useUpdateNotification({});

  const handleShowTransaction = (orderNumber) => {
    push(`/sales/orders?query=${orderNumber}`);
  };

  const onNotificationClicked = (notificationId, orderNumber) => {
    const payload = {
      store_id: storeId,
      notification_id: notificationId,
    };
    updateNotification(payload);
    queryClient.invalidateQueries({
      queryKey: ["getNotification"],
    });
    queryClient.invalidateQueries({
      queryKey: ["notifCount"],
    });
    handleShowTransaction(orderNumber);
  };

  if (isNotifEmpty)
    return (
      <Flex paddingTop="50px" width="100%">
        <FailLoadData
          imgSrc="/assets/images/mobile/no-notification.svg"
          title="Belum Ada Notifikasi"
          description={`Terus pantau update terbaru untuk tokomu di sini.`}
          noButton
        />
      </Flex>
    );

  return (
    <Flex
      flexDirection="column"
      width="100%"
      maxHeight="calc(100% - 50px)"
      overflow="auto"
    >
      <InfiniteScroll
        page={dataNotif.page}
        onChange={() => {
          setDataNotif((prev) => ({ ...prev, page: prev.page + 1 }));
        }}
        hasNextPage={hasNextPage && !isFetching}
        isLoading={isFetching}
        loader={<Loading />}
      >
        {isNotifLoading
          ? range(5).map((_, indexSkeleton) => (
              <Loading key={`skeleton-${indexSkeleton}`} />
            ))
          : data.pages.map((page) =>
              page.data.map((notificationData, indexNotification) => {
                const {
                  notification_id: id,
                  title,
                  message,
                  date_in: date,
                  is_read: isRead,
                  order_number,
                } = notificationData;

                return (
                  <Card
                    key={`card-${indexNotification}`}
                    date={dayjs(date).format("DD MMMM YYYY")}
                    title={title}
                    message={message}
                    onClick={() => onNotificationClicked(id, order_number)}
                    isRead={isRead}
                  />
                );
              })
            )}
      </InfiniteScroll>
    </Flex>
  );
};

export default List;
