import StoreLayoutContext from "providers/StoreLayoutProvider";
import React, { useContext, useEffect, useState } from "react";
import { Flex, Button, Divider, useToast, Text, Input, Icon } from "renos-ui";
import { useUpdateHomePage } from "services/store-layout/homepage";
import Header from "../component/Header";
import { useGetYoutubeData } from "services/global/youtube";
import { youtubeLinkValidator } from "helpers";

const BannerVideo = ({ handleBack, id }) => {
  const toast = useToast();

  const [dataBannerVideo, setDataBannerVideo] = useState();
  const [linkVideo, setLinkVideo] = useState();
  const [firstLoad, setFirstLoad] = useState(true);

  const { dataHomePage, setDataHomePage } = useContext(StoreLayoutContext);

  const { mutate, isLoading: isUpdateHomePageLoading } = useUpdateHomePage({
    onSuccess: (data) => {
      const { message, frontend_message } = data;
      toast({
        label: frontend_message || message,
        placement: "top",
      });
      handleBack();
    },
    onError: (data) => {
      const {
        response: {
          data: { frontend_message, message },
        },
      } = data;
      toast({
        label: frontend_message || message,
        placement: "top",
        backgroundColor: "red50",
      });
    },
  });

  const { mutate: getYoutubeData } = useGetYoutubeData({
    onSuccess: (data) => {
      setDataBannerVideo({
        ...data,
        url: linkVideo || data.url,
      });

      handleSetHomepage([{ url: linkVideo || data.url }]);
      setFirstLoad(false);
    },
    onError: () => {
      toast({
        label: "Format link youtube belum sesuai.",
        placement: "top",
        backgroundColor: "red50",
      });
    },
  });

  const handleSetVideo = () => {
    if (youtubeLinkValidator(linkVideo)) getYoutubeData(linkVideo);
    else
      toast({
        label: "Format link youtube belum sesuai.",
        placement: "top",
        backgroundColor: "red50",
      });
  };

  const handleSave = () => {
    mutate({
      type: id,
      values: [dataBannerVideo.url],
    });
  };

  const handleDelete = () => {
    handleSetHomepage("");
    setLinkVideo("");
    setDataBannerVideo("");
  };

  useEffect(() => {
    const selectedBanner = dataHomePage.filter(
      (homepage) => homepage.id === id
    )[0];

    const tempVideo =
      selectedBanner?.data?.length > 0 && selectedBanner?.data[0].url;

    if (tempVideo && firstLoad) getYoutubeData(tempVideo);
    else if (firstLoad) setDataBannerVideo("");
    // eslint-disable-next-line
  }, [dataHomePage]);

  const handleSetHomepage = (newData) => {
    const tempDataHomePage = dataHomePage.map((homepage) => ({
      ...homepage,
      data: homepage.id === id ? newData : homepage.data,
    }));

    setDataHomePage([...tempDataHomePage]);
  };

  return (
    <Flex flexDirection="column" gap="16px">
      <Header handleBack={handleBack} title="Edit Banner" />
      <Flex
        height="184px"
        overflowY="auto"
        flexDirection="column"
        width="100%"
        gap="8px"
      >
        <Text variant="caption" weight="bold" color="black50">
          Link Video
        </Text>
        {dataBannerVideo ? (
          <Flex
            alignItems="center"
            justifyContent="space-between"
            borderRadius="8px"
            borderWidth="1px"
            borderColor="black10"
            borderStyle="solid"
            p="16px 12px"
            gap="2px"
          >
            <Flex gap="8px" width="calc(100% - 46px)">
              <Flex
                as="img"
                height="72px"
                width="72px"
                alt="img"
                borderRadius="8px"
                src={dataBannerVideo.thumbnail_url}
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = "/assets/images/empty-product.svg";
                }}
              />
              <Flex flexDirection="column" width="calc(100% - 80px)">
                <Text weight="bold" color="black75" lineClamp={1}>
                  {dataBannerVideo.title}
                </Text>
                <Text variant="caption" color="black50" lineClamp={2}>
                  {dataBannerVideo.url}
                </Text>
              </Flex>
            </Flex>

            <Icon
              name="Close-solid"
              color="black60"
              size={24}
              cursor="pointer"
              onClick={handleDelete}
            />
          </Flex>
        ) : (
          <Flex gap="16px">
            <Input
              size="small"
              placeholder="Masukan link youtube"
              value={linkVideo}
              onChange={(event) =>
                setLinkVideo(event.target.value.replace(" ", ""))
              }
            />
            <Button
              size="medium"
              onClick={handleSetVideo}
              disabled={!linkVideo}
            >
              Tambah
            </Button>
          </Flex>
        )}
      </Flex>
      <Divider />
      <Flex gap="16px" paddingBottom="24px">
        <Flex width="152px">
          <Button size="medium" variant="tertiary" isBlock onClick={handleBack}>
            Batalkan
          </Button>
        </Flex>
        <Flex width="152px">
          <Button
            size="medium"
            isBlock
            disabled={isUpdateHomePageLoading}
            onClick={handleSave}
          >
            Simpan
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default BannerVideo;
