import { Drawer, Box, Text, colors } from "renos-ui";

const HandleDrawer = ({
  handleEdit,
  handleDelete,
  handleSetPrimary,
  disclosure,
  isPrimary,
}) => {
  return (
    <Drawer
      isVisible={disclosure.isOpen}
      closable={true}
      onClose={() => disclosure.onClose()}
      placement="bottom"
    >
      <Box
        padding="16px 0px"
        borderBottom={`1px solid ${colors.black5}`}
        onClick={handleEdit}
      >
        <Text>Ubah Lokasi</Text>
      </Box>

      {!isPrimary && (
        <>
          <Box
            padding="16px 0px"
            borderBottom={`1px solid ${colors.black5}`}
            onClick={handleSetPrimary}
          >
            <Text>Jadikan Utama</Text>
          </Box>

          <Box paddingTop="16px" onClick={() => handleDelete.onOpen()}>
            <Text>Hapus</Text>
          </Box>
        </>
      )}
    </Drawer>
  );
};

export default HandleDrawer;
