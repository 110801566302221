import styled from "styled-components";
import { Button, colors, Input, SelectInput } from "renos-ui";

export const StyledButtonIcon = styled(Button)`
  padding: 8px;
`;

export const StyledButton = styled(Button)`
  :disabled {
    span {
      color: ${colors.black25};
    }
  }
`;

export const StyledTextArea = styled(Input.TextArea)`
  resize: none;
  height: 92px;
`;

export const StyledSelectInput = styled(SelectInput)`
  width: 156px;
  color: ${colors.black75};
`;
