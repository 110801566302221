const constants = {
  GET_LIST_SETTING_BY_ID: "GET_LIST_SETTING_BY_ID",
  ADD_COVERAGE: "ADD_COVERAGE",
  DELETE_COVERAGE: "DELETE_COVERAGE",
  GET_PROVINCE: "GET_PROVINCE",
  GET_CITY: "GET_CITY",
  GET_DISTRICT: "GET_DISTRICT",
  GET_PROVINCE_BY_INDEX: "GET_PROVINCE_BY_INDEX",
  GET_CITY_BY_INDEX: "GET_CITY_BY_INDEX",
  GET_DISTRICT_BY_INDEX: "GET_DISTRICT_BY_INDEX",
  ADD_ESTIMATE_BY_INDEX: "ADD_ESTIMATE_BY_INDEX",
  SET_TOGGLE_CATEGORIES: "SET_TOGGLE_CATEGORIES",
  SET_ACTIVE_CATEGORIES: "SET_ACTIVE_CATEGORIES",
  SET_TYPE_CALCULATION: "SET_TYPE_CALCULATION",
  SET_MULTIPLE_OF_WEIGHT: "SET_MULTIPLE_OF_WEIGHT",
  SET_WEIGHT_GROUP: "SET_WEIGHT_GROUP",
  DELETE_WEIGHT_GROUP_BY_INDEX: "DELETE_WEIGHT_GROUP_BY_INDEX",
  RESET_WEIGHT_GROUP: "RESET_WEIGHT_GROUP",
  RESET_ADD_WEIGHT_GROUP: "RESET_ADD_WEIGHT_GROUP",
  RESET_SHIPPING_AREA: "RESET_SHIPPING_AREA",
  RESET_TO_INTIAL_STATE: "RESET_TO_INTIAL_STATE",
  SET_RESPONSE_EDIT: "SET_RESPONSE_EDIT",
  GET_QUERY_PARAM_ID: "GET_QUERY_PARAM_ID",
  SET_ADD_GROUP: "SET_ADD_GROUP",
  SET_IS_BACK: "SET_IS_BACK",
  ADD_ADVANCE_OPTIONS: "ADD_ADVANCE_OPTIONS",
  DELETE_ADVANCE_OPTIONS: "DELETE_ADVANCE_OPTIONS",
  HANDLE_CHANGE_ADVANCE_OPTIONS: "HANDLE_CHANGE_ADVANCE_OPTIONS",
};

export default constants;