import { useContext, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Box,
  Button,
  Flex,
  Icon,
  isEmpty,
  Text,
  useToast,
  Spinner,
} from "renos-ui";
import DeliveryArea from "./DeliveryArea";
import ShippingRates from "./ShippingRates";
import {
  useGetSellerFleet,
  useGetSellerFleetById,
  useSingleSaveSellerFleet,
} from "services/logistics/sellerFleet";
import StoreContext from "providers/StoreProvider";
import useQueryParams from "hooks/useQueryParams";
import { currencyFormatter } from "helpers";

const SettingSellerFleetAdd = () => {
  const {
    storeData: {
      data: { id: storeId },
    },
  } = useContext(StoreContext);

  const query = useQueryParams();
  const selectedId = query.get("id");
  const history = useHistory();
  const toast = useToast();

  const conditionType = [
    {
      label: "Kurang dari",
      value: 1,
    },
    {
      label: "Kurang dari sama dengan",
      value: 2,
    },
    {
      label: "Lebih dari",
      value: 3,
    },
    {
      label: "Lebih dari sama dengan",
      value: 4,
    },
  ];
  const conditionBaseType = [
    {
      label: "Berdasarkan Kelipatan Berat",
      value: 1,
    },
    {
      label: "Berdasarkan Kelompok Berat",
      value: 2,
    },
  ];

  const [isError, setIsError] = useState(false);
  const [validation, setValidation] = useState({
    dataCoverage: [
      {
        selectedProvince: false,
        estimateDays: false,
        estimateDaysText: "",
      },
    ],
    dataRates: {
      multiple: {
        weight: false,
        price: false,
        weightText: "Kelipatan berat harus diisi.",
      },
      group: [{ min: false, max: false, price: false }],
      advancedOptions: [
        {
          condition_type_id: false,
          condition_weight: false,
          condition_base_id: false,
          condition_base_weight_value: false,
          condition_base_tarif: false,
          condition_base_weight_start: false,
          condition_base_weight_end: false,
        },
      ],
    },
  });

  const [dataCoverage, setDataCoverage] = useState([
    {
      selectedProvince: {},
      selectedCity: [],
      listCity: [],
      selectedDistrict: [],
      listDistrict: [],
      estimateDays: "",
    },
  ]);

  const [dataRates, setDataRates] = useState({
    categories: [],
    active: false,
    calculation: {
      type: "multiple",
      multiple: {},
      group: [{ min: 1 }],
      advanced_options: [
        {
          condition_type_id: 1,
          condition: "Kurang Dari",
          condition_base_id: 1,
        },
      ],
    },
  });

  const { data: dataGetSellerFleet, isLoading: isLoadingGetSellerFleet } =
    useGetSellerFleet();

  const { mutate: getSellerFleetById, isLoading: isGetSellerFleetByIdLoading } =
    useGetSellerFleetById({
      onSuccess: (data) => {

        setDataRates({
          categories: [],
          active: false,
          calculation: {
            type: "multiple",
            multiple: {},
            group: [{ min: 1 }],
            advanced_options: [
              {
                condition_type_id: 1,
                condition: "Kurang Dari",
                condition_base_id: 1,
              },
            ],
          },
        });

        const dataGet = data?.data;

        const categoryFilled = dataGet.categories.some(
          (categoryLvl0) =>
            categoryLvl0.active ||
            categoryLvl0.categories.some(
              (categoryLvl1) =>
                categoryLvl1.active ||
                categoryLvl1.categories.some(
                  (categoryLvl2) => categoryLvl2.active
                )
            )
        );

        const tempDataRates = {
          categories: dataGet.categories,
          active: categoryFilled,
          calculation: {
            type:
              dataGet.advanced_options.length !== 0
                ? "advanced_options"
                : dataGet.weight_groups.length === 0
                  ? "multiple"
                  : "group",
            multiple:
              dataGet.weight_groups.length === 0 &&
                dataGet.advanced_options.length === 0
                ? {
                  weight: dataGet.weight_multiple.base_weight || "0",
                  price: dataGet.weight_multiple.base_price || "0",
                }
                : {},
            group:
              dataGet.weight_groups.length !== 0 &&
                dataGet.advanced_options.length === 0
                ? dataGet.weight_groups.map((weightGroup) => ({
                  min: weightGroup.minimum_weight || 1,
                  max: weightGroup.maximum_weight || 0,
                  price: weightGroup.shipping_cost || "0",
                }))
                : [{ min: 1 }],
            advanced_options: dataGet.advanced_options?.map((advanced) => {
              const condition = conditionType.find(
                (condition) => condition.label === advanced?.condition
              );
              const conditionBase = conditionBaseType.find(
                (conditionbase) =>
                  conditionbase.label === advanced?.condition_base
              );

              return {
                ...advanced,
                condition_type_id:
                  advanced?.condition_type_id || condition?.value,
                condition_base_id:
                  advanced?.condition_base_id || conditionBase?.value,
              };
            }),
          },
        };

        if (tempDataRates.calculation.advanced_options.length === 0) {
          tempDataRates.calculation.advanced_options = [
            {
              condition_type_id: 1,
              condition: "Kurang Dari",
              condition_base_id: 1,
            },
          ];
        }

        const tempDataCoverage = dataGet.coverages.map((coverage) => {
          const tempSelectedCity = [];
          const tempSelectedDistrict = [];
          coverage.cities.map((city) => {
            tempSelectedCity.push({
              province_id: coverage.province_id,
              province_name: coverage.province_name,
              city_id: city.city_id,
              city_name: city.city_name,
              check: true,
            });
            city.districts.map((district) => {
              tempSelectedDistrict.push({
                district_name: district.district_name,
                district_id: district.district_id,
                province_id: coverage.province_id,
                province_name: coverage.province_name,
                city_id: city.city_id,
                city_name: city.city_name,
                check: true,
              });

              return district;
            });

            return city;
          });

          return {
            selectedProvince: {
              province_id: coverage.province_id,
              province_name: coverage.province_name,
            },
            selectedCity: tempSelectedCity,
            listCity: [],
            selectedDistrict: tempSelectedDistrict,
            listDistrict: [],
            estimateDays: `${coverage.estimate_days_start}-${coverage.estimate_days_end}`,
          };
        });

        const tempValidationCoverage = new Array(tempDataCoverage.length).fill({
          selectedProvince: false,
          estimateDays: false,
          estimateDaysText: "",
        });

        const tempValidationRates = {
          multiple: {
            weight: false,
            price: false,
            weightText: "Kelipatan berat harus diisi.",
          },
          group: new Array(tempDataRates.calculation.group.length).fill({
            max: false,
            price: false,
          }),
          advancedOptions: new Array(
            tempDataRates.calculation.advanced_options.length
          ).fill({
            max: false,
            price: false,
          }),
        };

        setDataRates(tempDataRates);
        setValidation({
          dataCoverage: tempValidationCoverage,
          dataRates: tempValidationRates,
        });
        setDataCoverage([...tempDataCoverage]);
      },
      onError: (error) => {
        history.push("/setting/seller-fleet/add");
      },
    });

  const { mutate: singleSave, isLoading: isSingleSaveLoading } =
    useSingleSaveSellerFleet({
      onSuccess: (data) => {
        const { message, frontend_message } = data;

        if (data.data.status === true) {
          toast({
            label:
              frontend_message ||
              message ||
              "Pengaturan kurir toko berhasil disimpan.",
            placement: "top",
          });
          setTimeout(() => {
            history.push("/setting/seller-fleet");
          }, 300);
        } else {
          toast({
            label: data.data.message || "Pengaturan kurir toko gagal disimpan.",
            placement: "top",
            backgroundColor: "red50",
          });
        }

      },
      onError: (data) => {
        const {
          response: {
            data: { frontend_message, message },
          },
        } = data;
        toast({
          label:
            frontend_message ||
            message ||
            "Pengaturan kurir toko gagal disimpan.",
          placement: "top",
          backgroundColor: "red50",
        });
      },
    });

  const handleScrollError = ({ dataCoverageError, dataRatesError }) => {
    const { multiple } = dataRatesError;

    if (multiple.price || multiple.weight) {
      scrollToError(`multiple`);
    }

    for (let i = 0; i < dataCoverageError.length; i++) {
      if (
        dataCoverageError[i].selectedProvince ||
        dataCoverageError[i].estimateDays
      ) {
        scrollToError(`selectedProvince${i}`);
        break;
      }
    }
  };

  const scrollToError = (idError) => {
    const element = document.getElementById(idError);
    element.scrollIntoView();
  };

  const handleError = () => {
    let countError = 0;

    const validateDataCoverage = dataCoverage.map((coverage, indexCoverage) => {
      const { selectedProvince, estimateDays } = coverage;

      const checkProvince = isEmpty(selectedProvince);
      let checkRange = false;

      const [firstVal, secondVal] = estimateDays.split("-");
      const singleNumber = estimateDays.split("-").length;
      const arrVal = estimateDays.split("");
      const hasTilde = arrVal.includes("~");
      const hasBacktick = arrVal.includes("`");

      if (hasTilde
        || hasBacktick
        || singleNumber <= 1
        || (estimateDays && estimateDays.length === 0)
        || Number(secondVal) - Number(firstVal) < 1
        || arrVal[0] === "-"
        || Number(firstVal) === 0
      ) {
        checkRange = true;
      }

      if (checkProvince) countError++;

      if (checkRange) countError++;

      return {
        selectedProvince: checkProvince,
        estimateDays: checkRange,
        estimateDaysText:
          validation.dataCoverage[indexCoverage]?.estimateDaysText ||
          "Estimasi sampai harus diisi",
      };
    });

    const validateDataRates = {
      multiple: {
        weight: false,
        price: false,
        weightText: "Kelipatan berat harus diisi",
        weightTextPrice: "Tarif harus diisi",
      },
      group: [{ max: false, price: false }],
      advancedOptions: [
        {
          condition_weight: false,
        },
      ],
    };

    if (dataRates.calculation.type === "multiple") {
      if (
        dataRates?.calculation?.multiple?.weight === 0 ||
        !dataRates?.calculation?.multiple?.weight
      ) {
        if (dataRates?.calculation?.multiple?.weight === 0) {
          validateDataRates.multiple.weightText =
            "Kelipatan berat harus lebih dari 0";
        }

        validateDataRates.multiple.weight = true;
        countError++;
      }

      if (
        dataRates?.calculation?.multiple?.price !== 0 &&
        !dataRates?.calculation?.multiple?.price
      ) {
        validateDataRates.multiple.weightTextPrice = "Tarif harus diisi";
        validateDataRates.multiple.price = true;
        countError++;
      }

      if (dataRates?.calculation?.multiple?.weight > 500000) {
        validateDataRates.multiple.weightText =
          "Berat maksimal adalah 500.000 g";
        validateDataRates.multiple.weight = true;
        countError++;
      }

      if (dataRates?.calculation?.multiple?.price > 100000000) {
        validateDataRates.multiple.weightTextPrice =
          "Tarif maksimal adalah Rp 100.000.000";
        validateDataRates.multiple.price = true;
        countError++;
      }
    } else if (dataRates.calculation.type === "group") {
      validateDataRates.group = dataRates?.calculation?.group.map(
        (group, indexGroup) => {
          const { min, max, price } = group;
          const arrGroup = dataRates?.calculation?.group;

          let maxError = max !== 0 && (!max || false);
          let minError = min <= 0 && (!min || false);
          let priceError = price !== 0 && (!price || false);
          let maxText = "Berat maksimum harus diisi";
          let minText = "Berat minimum harus lebih dari 0";
          let priceText = "Tarif harus diisi";

          if (max <= min) {
            maxError = true;
            maxText = "Berat maksimum harus lebih besar dari berat minimum";
          }

          if (min > 500000) {
            minError = true;
            minText = "Berat maksimal adalah 500.000 g";
          }

          if (max > 500000) {
            maxError = true;
            maxText = "Berat maksimal adalah 500.000 g";
          }

          if (price > 100000000) {
            priceError = true;
            priceText = "Tarif maksimal adalah Rp 100.000.000";
          }

          if (indexGroup !== 0) {
            if (group.price < arrGroup[indexGroup - 1].price) {
              priceError = true;
              priceText =
                "Tarif harus lebih besar atau sama dari tarif sebelumnya";
            }
          }

          if (maxError || priceError || minError) {
            countError++;
          }

          return {
            max: maxError,
            maxText: maxText,
            min: minError,
            minText: minText,
            price: priceError,
            priceText: priceText,
          };
        }
      );
    } else if (dataRates.calculation.type === "advanced_options") {
      validateDataRates.advancedOptions =
        dataRates?.calculation?.advanced_options.map((advancedOption) => {
          const {
            condition_weight: conditionWeight,
            condition_type_id: conditionTypeId,
            condition_base_id: conditionBaseId,
            condition_base_tarif: conditionBaseTarif,
            condition_base_weight_end: conditionBaseWeightEnd,
            condition_base_weight_start: conditionBaseWeightStart,
            condition_base_weight_value: conditionBaseWeightValue,
          } = advancedOption;

          let conditionBaseTarifErrorText = "Tarif harus diisi";

          const conditionWeightError = conditionWeight === 0
            || !conditionWeight
            || conditionWeight > 500000
            || false;
          const conditionWeightText = conditionWeight === 0
            ? "Berat harus lebih dari 0"
            : conditionWeight > 500000
              ? "Berat maksimal adalah 500.000 g"
              : "Berat harus diisi";

          let conditionBaseTarifError = false;
          let conditionBaseWeightValueError;
          let conditionBaseWeightValueErrorText;
          let conditionBaseWeightStartError = false;
          let conditionBaseWeightStartErrorText;
          let conditionBaseWeightEndError = false;
          let conditionBaseWeightEndErrorText;

          if (conditionBaseTarif > 100000000) {
            conditionBaseTarifErrorText = "Tarif maksimal adalah Rp 100.000.000";
            conditionBaseTarifError = true;
          }

          if (conditionBaseTarif === undefined) {
            conditionBaseTarifErrorText = "Tarif harus diisi";
            conditionBaseTarifError = true;
          }

          if (conditionTypeId === 1) {
            if (conditionBaseId === 1) {
              if (conditionBaseWeightValue >= conditionWeight) {
                conditionBaseWeightValueErrorText = `Kelipatan berat harus kurang dari ${currencyFormatter(conditionWeight)}`;
                conditionBaseWeightValueError = true;
              } else if (conditionBaseWeightValue === 0 || conditionBaseWeightValue === undefined) {
                conditionBaseWeightValueErrorText = 'Kelipatan berat harus lebih dari 0';
                conditionBaseWeightValueError = true;
              }
            } else {
              if (conditionBaseWeightStart === undefined || conditionWeight === undefined) {
                conditionBaseWeightStartErrorText = 'Berat minimum harus diisi';
                conditionBaseWeightStartError = true;
              }

              if (conditionBaseWeightStart === 0) {
                conditionBaseWeightStartErrorText = 'Berat minimum harus lebih dari 0';
                conditionBaseWeightStartError = true;
              }

              if (conditionBaseWeightStart === undefined && conditionBaseWeightEnd === 0) {
                conditionBaseWeightStartErrorText = 'Berat minimum harus diisi';
                conditionBaseWeightStartError = true;
              }

              if (conditionBaseWeightStart > 500000) {
                conditionBaseWeightStartErrorText = 'Berat minimum maksimal 500.000 g';
                conditionBaseWeightStartError = true;
              }

              if (conditionBaseWeightStart >= conditionBaseWeightEnd && conditionWeight !== undefined && conditionWeight < 500000 && conditionBaseWeightEnd > 0) {
                conditionBaseWeightStartErrorText = 'Berat minimum harus kurang dari berat maksimum';
                conditionBaseWeightStartError = true;
              }

              if (conditionBaseWeightEnd === false || conditionBaseWeightEnd === 0) {
                conditionBaseWeightEndErrorText = 'Berat maksimum harus diisi';
                conditionBaseWeightEndError = true;
              }

              if (conditionBaseWeightEnd === false || conditionWeight > 500000) {
                conditionBaseWeightEndErrorText = 'Berat maksimal adalah 500.000 g';
                conditionBaseWeightEndError = true;
              }
            }
          } else if (conditionTypeId === 2) {
            if (conditionBaseId === 1) {
              if (conditionBaseWeightValue > conditionWeight) {
                conditionBaseWeightValueErrorText = `Kelipatan berat harus kurang dari atau sama dengan ${currencyFormatter(conditionWeight)}`;
                conditionBaseWeightValueError = true;
              } else if (conditionBaseWeightValue === 0 || conditionBaseWeightValue === undefined) {
                conditionBaseWeightValueErrorText = 'Kelipatan berat harus lebih dari 0';
                conditionBaseWeightValueError = true;
              }
            } else {
              if (conditionBaseWeightStart >= conditionBaseWeightEnd) {
                conditionBaseWeightStartErrorText = `Berat minimum harus kurang dari ${currencyFormatter(conditionBaseWeightEnd)}`;
                conditionBaseWeightStartError = true;
              } else if (conditionBaseWeightStart === 0 || conditionBaseWeightStart === undefined || conditionWeight === undefined || conditionWeight === 0 || conditionBaseWeightEnd === false || conditionBaseWeightEnd === 0) {
                conditionBaseWeightStartErrorText = 'Berat minimum harus lebih dari 0';
                conditionBaseWeightStartError = true;
              }
            }
          } else if (conditionTypeId === 3) {
            if (conditionBaseId === 1) {
              if (conditionBaseWeightValue < conditionWeight || conditionBaseWeightValue === conditionWeight) {
                conditionBaseWeightValueErrorText = `Kelipatan berat harus lebih dari ${currencyFormatter(conditionWeight)}`;
                conditionBaseWeightValueError = true;
              } else if (conditionBaseWeightValue > 500000) {
                conditionBaseWeightValueErrorText = `Kelipatan berat maksimal 500.000 g`;
                conditionBaseWeightValueError = true;
              } else if (conditionBaseWeightValue === 0 || conditionBaseWeightValue === undefined) {
                conditionBaseWeightValueErrorText = 'Kelipatan berat harus lebih dari 0';
                conditionBaseWeightValueError = true;
              }
            } else {
              if (conditionBaseWeightEnd === undefined) {
                conditionBaseWeightEndErrorText = 'Berat maksimum harus diisi';
                conditionBaseWeightEndError = true;
              } else if (conditionBaseWeightEnd < conditionBaseWeightStart) {
                conditionBaseWeightEndErrorText = 'Berat maksimum harus lebih besar dari berat minimum';
                conditionBaseWeightEndError = true;
              } else if (conditionBaseWeightStart === conditionBaseWeightEnd) {
                conditionBaseWeightEndErrorText = 'Berat maksimum harus lebih besar dari berat minimum';
                conditionBaseWeightEndError = true;
              } else if (conditionBaseWeightEnd > 500000) {
                conditionBaseWeightEndErrorText = 'Berat maksimum maksimal 500.000 g';
                conditionBaseWeightEndError = true;
              }
            }
          } else {
            if (conditionBaseId === 1) {
              if (conditionBaseWeightValue < conditionWeight) {
                conditionBaseWeightValueErrorText = `Kelipatan berat harus lebih dari atau sama dengan ${currencyFormatter(conditionWeight)}`;
                conditionBaseWeightValueError = true;
              } else if (conditionBaseWeightValue > 500000) {
                conditionBaseWeightValueErrorText = `Kelipatan berat maksimal 500.000 g`;
                conditionBaseWeightValueError = true;
              } else if (conditionBaseWeightValue === 0 || conditionBaseWeightValue === undefined) {
                conditionBaseWeightValueErrorText = 'Kelipatan berat harus lebih dari 0';
                conditionBaseWeightValueError = true;
              }
            } else {
              if (conditionBaseWeightEnd === undefined) {
                conditionBaseWeightEndErrorText = 'Berat maksimum harus diisi';
                conditionBaseWeightEndError = true;
              } else if (conditionBaseWeightEnd < conditionBaseWeightStart) {
                conditionBaseWeightEndErrorText = 'Berat maksimum harus lebih besar dari berat minimum';
                conditionBaseWeightEndError = true;
              } else if (conditionBaseWeightStart === conditionBaseWeightEnd) {
                conditionBaseWeightEndErrorText = 'Berat maksimum harus lebih besar dari berat minimum';
                conditionBaseWeightEndError = true;
              } else if (conditionBaseWeightEnd > 500000) {
                conditionBaseWeightEndErrorText = 'Berat maksimum maksimal 500.000 g';
                conditionBaseWeightEndError = true;
              }
            }
          }

          if (
            conditionWeightError
            || conditionBaseWeightValueError
            || conditionBaseWeightStartError
            || conditionBaseWeightEndError
            || conditionBaseTarifError
          ) {
            countError++;
          }

          return {
            condition_weight: conditionWeightError,
            condition_weightText: conditionWeightText,
            condition_base_weight_value: conditionBaseWeightValueError,
            condition_base_weight_valueText: conditionBaseWeightValueErrorText,
            condition_base_tarif: conditionBaseTarifError,
            condition_base_tarifText: conditionBaseTarifErrorText,
            condition_base_weight_start: conditionBaseWeightStartError,
            condition_base_weight_startText: conditionBaseWeightStartErrorText,
            condition_base_weight_end: conditionBaseWeightEndError,
            condition_base_weight_endText: conditionBaseWeightEndErrorText,
          };

        });
    }

    setValidation({
      ...validation,
      dataCoverage: [...validateDataCoverage],
      dataRates: validateDataRates,
    });

    handleScrollError({
      dataCoverageError: validateDataCoverage,
      dataRatesError: validateDataRates,
    });

    return countError;
  };

  const handleSaveAdd = () => {
    const isHasError = handleError();

    if (dataRates.active && dataRates.categories.length === 0) {
      return toast({
        label: "Kategori produk belum dipilih",
        placement: "top",
        backgroundColor: "red50",
      });
    }

    if (!isHasError) {
      const formattedCoverages = dataCoverage.map((coverage) => ({
        estimate_days_end: parseInt(coverage.estimateDays.split("-").pop()),
        estimate_days_start: parseInt(coverage.estimateDays.split("-").shift()),
        province_id: coverage.selectedProvince.province_id,
        province_name: coverage.selectedProvince.province_name,
        cities: coverage.selectedCity.map((city) => ({
          ...city,
          districts: coverage.selectedDistrict
            .filter((filterDistrict) => filterDistrict.city_id === city.city_id)
            .map((district) => ({
              ...district,
              lower_districts: [],
            })),
        })),
      }));

      const formattedPayload = {
        courier_service_id: dataGetSellerFleet?.data?.id,
        settings: {
          categories: dataRates.categories,
          courier_service_id: dataGetSellerFleet?.data?.id,
          coverages: formattedCoverages,
          store_id: storeId,
        },
      };

      if (selectedId) {
        formattedPayload.id = parseInt(selectedId);
      }

      if (dataRates.calculation.type === "multiple") {
        formattedPayload.settings.weight_multiple = {
          base_price: parseInt(dataRates.calculation.multiple.price),
          base_weight: dataRates.calculation.multiple.weight,
        };
      } else if (dataRates.calculation.type === "group") {
        formattedPayload.settings.weight_groups =
          dataRates.calculation.group.map((group) => ({
            maximum_weight: group.max,
            minimum_weight: group.min,
            shipping_cost: parseInt(group.price),
          }));
      } else {
        formattedPayload.settings.advanced_options =
          dataRates.calculation.advanced_options.map((advanced, index) => ({
            ...advanced,
            courier_service_setting_id: parseInt(selectedId || "0"),
            condition: conditionType[advanced?.condition_type_id - 1]?.label,
            id: advanced?.id || index + 1,
          }));
      }

      singleSave(formattedPayload);
    }
  };

  const handleCancel = () => {
    history.push("/setting/seller-fleet");
  };

  const isLoadingAll = useMemo(
    () =>
      isSingleSaveLoading ||
      isLoadingGetSellerFleet ||
      isGetSellerFleetByIdLoading,
    [isSingleSaveLoading, isLoadingGetSellerFleet, isGetSellerFleetByIdLoading]
  );

  useEffect(() => {
    if (selectedId) {
      getSellerFleetById({ courier_service_setting_id: selectedId });
    }
    //eslint-disable-next-line
  }, []);

  return (
    <Flex flexDirection="column" gap="8px" width="952px">
      <Flex
        gap="12px"
        padding="22px 23px 24px 23px"
        backgroundColor="white"
        borderTopLeftRadius="16px"
        borderTopRightRadius="16px"
        boxShadow="0px 4px 4px rgba(20, 20, 32, 0.05)"
        alignItems="center"
      >
        <Icon
          name="Back-outline"
          size={24}
          onClick={handleCancel}
          cursor="pointer"
        />
        <Text variant="heading3" weight="bold">
          Tambah Pengaturan
        </Text>
        {isLoadingAll && <Spinner size="16px" color="black100" />}
      </Flex>
      <DeliveryArea
        dataCoverage={dataCoverage}
        setDataCoverage={setDataCoverage}
        isError={isError}
        setIsError={setIsError}
        isLoading={isLoadingAll}
        validation={validation.dataCoverage}
        setValidation={(newData) =>
          setValidation({ ...validation, dataCoverage: newData })
        }
      />
      <ShippingRates
        isLoading={isLoadingAll}
        dataRates={dataRates}
        setDataRates={setDataRates}
        validation={validation.dataRates}
        setValidation={(newData) =>
          setValidation({ ...validation, dataRates: newData })
        }
        conditionType={conditionType}
        conditionBaseType={conditionBaseType}
      />
      <Flex
        gap="16px"
        padding="16px 24px"
        backgroundColor="white"
        boxShadow="0px 4px 4px rgba(20, 20, 32, 0.05)"
        borderBottomLeftRadius="16px"
        borderBottomRightRadius="16px"
        justifyContent="end"
      >
        <Box width="160px">
          <Button variant="tertiary" isBlock onClick={handleCancel}>
            Batal
          </Button>
        </Box>
        <Box width="160px">
          <Button
            isBlock
            onClick={handleSaveAdd}
            disabled={isLoadingAll}
            preffix={isLoadingAll && <Spinner size="16px" color="white" />}
          >
            Simpan
          </Button>
        </Box>
      </Flex>
    </Flex>
  );
};

export default SettingSellerFleetAdd;