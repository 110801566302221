import { Flex, Text, colors } from "renos-ui";

const SquareTwo = ({ imageRef, variant, data, setData }) => {
  const handleSetEdit = (type) => {
    setData((prev) => ({ ...prev, active: type }));
  };

  const renderBorder = (type) => {
    return data?.active === type && `1px solid ${colors.violet50}`;
  };

  return (
    <Flex
      flexDirection="column"
      gap="8px"
      paddingTop="50px"
      position="relative"
    >
      <Flex flexDirection="column" position="absolute" left={-84}>
        <Text variant="caption" color="black50" weight="bold">
          Template {variant}
        </Text>
        <Text variant="caption" color="black50">
          Square Banner 1:1 (1080 x 1080px)
        </Text>
      </Flex>
      <Flex style={{ transform: "scale(1.7)" }} mt="150px">
        <Flex
          width="239px"
          height="239px"
          background="#1833B4"
          ref={imageRef}
          position="relative"
        >
          <Flex
            opacity={data.isDiscountActive ? 1 : 0}
            onClick={() => handleSetEdit("discountSquare")}
            cursor="pointer"
            right={10}
            top={0}
            width="35px"
            height="40px"
            position="absolute"
            background="url('assets/images/store-layout/banner-1x1/discount_ribbon.svg')"
            flexDirection="column"
            alignItems="center"
            backgroundSize="cover"
            border={renderBorder("discountSquare")}
          >
            <Flex
              fontSize="5px"
              fontWeight={400}
              letterSpacing={1.23}
              marginTop="4px"
            >
              {data.discountNameSquare}
            </Flex>
            <Flex gap="1px" alignItems="center">
              <Flex color="black75" fontSize={12} fontWeight={800}>
                {data.discount}
              </Flex>
              <Flex
                color="black75"
                fontSize="7px"
                fontWeight={400}
                letterSpacing={1.23}
              >
                {data.discountSuffix}
              </Flex>
            </Flex>
          </Flex>
          <Flex
            width="125px"
            height="32px"
            background="url('assets/images/store-layout/banner-1x1/template_1_discount_price.svg')"
            alignItems="center"
            backgroundSize="cover"
            color="white"
            justifyContent="end"
            pr={9}
            left={0}
            top={1}
            position="absolute"
            style={{ transform: "scaleX(-1)" }}
          >
            <Flex
              position="relative"
              style={{ transform: "scaleX(-1)" }}
              width="125px"
              flexDirection="column"
              gap="1px"
              height="32px"
              marginTop="4px"
            >
              <Flex
                onClick={() => handleSetEdit("productName")}
                cursor="pointer"
                fontWeight={800}
                fontSize="10px"
                border={renderBorder("productName")}
              >
                {data.productName}
              </Flex>
              <Flex
                onClick={() => handleSetEdit("descriptionProduct")}
                cursor="pointer"
                fontSize="8px"
                border={renderBorder("descriptionProduct")}
              >
                {data.descriptionProduct}
              </Flex>
            </Flex>
          </Flex>

          <Flex
            position="absolute"
            bottom={11}
            justifyContent="center"
            alignItems="center"
            onClick={() => handleSetEdit("textPeriod")}
            width="100%"
            cursor="pointer"
          >
            <Flex
              color="blue50"
              fontSize="6px"
              border={renderBorder("textPeriod")}
              backgroundColor="yellow50"
              width="160px"
              height="14px"
              borderRadius="8.6px"
              justifyContent="center"
              alignItems="center"
            >
              {data.textPeriod}
            </Flex>
          </Flex>

          <Flex
            as="img"
            alt="img"
            width="239px"
            height="239px"
            src={data.imgSquareTwo}
            onClick={() => handleSetEdit("imgSquareTwo")}
            cursor="pointer"
            border={renderBorder("imgSquareTwo")}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default SquareTwo;
