import { Box, Flex, Icon, Overlay } from "renos-ui";
import Sidebar from "./component/Sidebar";
import Preview from "./component/Preview";
import { useState } from "react";

const DialogTemplate = ({ onClose, data, idGet }) => {
  const [isMobile, setIsMobile] = useState(false);

  return (
    <>
      <Overlay />

      <Flex
        gap="8px"
        position="fixed"
        zIndex="11"
        top="calc(50% - 335px)"
        left="50%"
        style={{ transform: "translate(-50%, -50%)" }}
      >
        <Box
          padding="8px"
          borderRadius="8px"
          backgroundColor="white"
          cursor="pointer"
          onClick={() => setIsMobile(false)}
          borderStyle="solid"
          borderWidth="1px"
          borderColor={!isMobile ? "blue50" : "black50"}
        >
          <Icon
            color={!isMobile ? "blue50" : "black50"}
            name="Desktop-outline"
            size={24}
          />
        </Box>

        <Box
          padding="8px"
          borderRadius="8px"
          backgroundColor="white"
          cursor="pointer"
          borderStyle="solid"
          borderWidth="1px"
          borderColor={isMobile ? "blue50" : "white"}
          onClick={() => setIsMobile(true)}
        >
          <Icon
            color={isMobile ? "blue50" : "black50"}
            name="Mobile-outline"
            size={24}
          />
        </Box>
      </Flex>

      <Flex
        gap="16px"
        position="fixed"
        zIndex="10"
        // backgroundColor="white"
        top="50%"
        left="50%"
        style={{ transform: "translate(-50%, -50%)" }}
      >
        <Preview {...{ onClose, data, idGet, isMobile }} />
        <Sidebar {...{ onClose, data, idGet }} />
      </Flex>
    </>
  );
};

export default DialogTemplate;
