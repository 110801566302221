import { Drawer, Flex, Text, useDisclosure } from "renos-ui";

import PopupReason from "./PopupReason";

const PopupReject = ({ orderId, disclosureReject, toFirstPage }) => {
  const {
    isOpen: isReasonOpen,
    onClose: onReasonClose,
    onOpen: onReasonOpen,
  } = useDisclosure({ isOpen: false });

  const handleOpen = () => {
    disclosureReject.onClose();
    onReasonOpen();
  };

  return (
    <>
      <Drawer
        isVisible={disclosureReject.isOpen}
        onClose={disclosureReject.onClose}
        closable
        placement="bottom"
      >
        <Flex flexDirection="column" width="100%" gap="24px" height="100%">
          <Flex cursor="pointer" onClick={handleOpen}>
            <Text color="black75">Tolak Pesanan</Text>
          </Flex>
        </Flex>
      </Drawer>

      {isReasonOpen && (
        <PopupReason
          {...{
            isReasonOpen,
            onReasonClose,
            onReasonOpen,
            toFirstPage,
            orderId,
          }}
        />
      )}
    </>
  );
};

export default PopupReject;
