import { useHistory } from "react-router-dom";
import desktopUrl from "constants/desktopUrl";
import { Box, Button, Flex, Icon, Text, Upload } from "renos-ui";

const RespondSuccess = ({ register, isLoading, isHasWarning }) => {
  const history = useHistory();

  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      py="39px"
      px="78px"
      gap="24px"
      height="286px"
      borderWidth="1px"
      borderColor="black10"
      borderStyle="dashed"
      borderRadius="8px"
    >
      <Flex gap="8px" alignItems="center">
        <Flex
          width="16px"
          height="16px"
          borderRadius="100%"
          cursor="pointer"
          alignItems="center"
          justifyContent="center"
          backgroundColor="blue50"
        >
          <Icon name="Checkmark-outline" color="white" size={8} />
        </Flex>
        <Text variant="title" weight="bold">
          Upload Berhasil
        </Text>
      </Flex>
      <Box textAlign="center">
        <Text variant="body" color="black50">
          Produk akan muncul pada daftar produk dalam beberapa saat.
        </Text>
      </Box>
      <Flex justifyContent="space-between" gap="16px">
        <Flex>
          <Upload {...register} accept=".xlsx, .xls, .csv">
            <Box width="160px">
              <Button isBlock variant="secondary" disabled={isLoading}>
                Upload Lagi
              </Button>
            </Box>
          </Upload>
        </Flex>

        <Flex>
          <Box width="200px">
            <Button
              isBlock
              onClick={() => history.push(`${desktopUrl.PRODUCTS_LIST}`)}
              variant="primary"
              disabled={isLoading}
            >
              Ke Daftar Produk
            </Button>
          </Box>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default RespondSuccess;
