import { goToUrl } from "helpers";
import { useState } from "react";
import {
  Flex,
  Button,
  Text,
  Divider,
  Input,
  useToast,
  Checkbox,
  Spinner,
} from "renos-ui";
import { useDownloadBulkProductTemplate } from "services/product-add-multiple";
import MultipleCategorySelector from "widgets/desktop/MultipleCategorySelector";

const VariantSelector = ({ template, setTemplate }) => {
  const toast = useToast();

  const [isCategory, setIsCategory] = useState(false);
  const [category, setCategory] = useState({
    selectedOne: null,
    selectedTwo: null,
    selectedThree: null,
  });

  const onOptionChange = (event) => {
    setTemplate(event.target.value);
  };

  const { mutate: downloadTemplate, isLoading } =
    useDownloadBulkProductTemplate({
      onSuccess: (data) => {
        goToUrl(data?.data?.data?.product_upload_file_template_url);
      },
      onError: (error) => {
        toast({
          label:
            error?.response?.data?.front_end_message ||
            "Gagal mengunduh template, mohon coba beberapa saat lagi.",
          placement: "top",
          backgroundColor: "red50",
        });
      },
    });

  const handleDownloadTemplate = () => {
    downloadTemplate({
      template_type: template,
      category_id: isCategory ? category?.selectedThree?.category_id : 0,
    });
  };

  return (
    <Flex
      borderBottomLeftRadius="16px"
      borderBottomRightRadius="16px"
      boxShadow="0px 4px 4px rgba(20, 20, 32, 0.05)"
      backgroundColor="white"
      padding="24px 24px 40px 24px"
      flexDirection="column"
      width="488px"
    >
      <Flex gap="12px" alignItems="center">
        <Flex
          width="40px"
          height="40px"
          backgroundColor="violet5"
          borderRadius="100%"
          alignItems="center"
          justifyContent="center"
        >
          <Text variant="heading3" weight="bold" color="blue50">
            1
          </Text>
        </Flex>
        <Flex>
          <Text variant="heading3" weight="bold">
            Download & Isi File Excel
          </Text>
        </Flex>
      </Flex>
      <Flex py="24px">
        <Divider />
      </Flex>

      <Flex flexDirection="column" rowGap="40px">
        <Flex gap="12px">
          <Input.Radio
            value="non_variant"
            id="non_variant"
            checked={template === "non_variant"}
            name="template"
            onChange={onOptionChange}
          />
          <Flex flexDirection="column" gap="16px" paddingTop="4px">
            <Flex flexDirection="column" gap="8px">
              <Text variant="title" weight="bold" color="black75">
                Template Tanpa Varian
              </Text>
              <Text variant="body" color="black50">
                Gunakan template ini untuk tambah produk di beragam kategori.
              </Text>
            </Flex>
          </Flex>
        </Flex>

        <Flex gap="12px">
          <Input.Radio
            value="variant"
            id="variant"
            checked={template === "variant"}
            name="template"
            onChange={onOptionChange}
          />
          <Flex flexDirection="column" gap="16px" paddingTop="4px">
            <Flex flexDirection="column" gap="8px">
              <Text variant="title" weight="bold" color="black75">
                Template Dengan Varian
              </Text>
              <Text variant="body" color="black50">
                Gunakan template ini untuk tambah produk dengan varian pada
                kategori yang sama.
              </Text>
            </Flex>
          </Flex>
        </Flex>

        <Flex flexDirection="column" gap="16px">
          <Flex width="100%" gap="12px" alignItems="center">
            <Flex width="32px" justifyContent="end" marginLeft="-6px">
              <Checkbox onChange={() => setIsCategory(!isCategory)} />
            </Flex>
            <Text color="black75">Pilih kategori produk tertentu</Text>
          </Flex>
          {isCategory && (
            <Flex gap="12px" width="100%">
              <Flex width="20px" />
              <Flex width="calc(100% - 32px)" flexDirection="column">
                <MultipleCategorySelector
                  value={category}
                  setValue={setCategory}
                />
              </Flex>
            </Flex>
          )}
          <Flex marginLeft="34px" flexDirection="column" gap="12px">
            {isCategory && (
              <Flex>
                <Text color="black75" weight="bold">
                  Mohon untuk tidak mengubah nama file ketika menggunakan
                  template kategori.
                </Text>
              </Flex>
            )}
            <Button
              variant="secondary"
              onClick={handleDownloadTemplate}
              disabled={isLoading || (isCategory && !category?.selectedThree)}
              preffix={isLoading && <Spinner size="16px" color="blue50" />}
            >
              Download Template
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default VariantSelector;
