import { Dialog, Flex, Text, Button } from "renos-ui";

const ModalUnderDevelopment = ({ isOpen, onClose }) => {
  return (
    <Dialog isVisible={isOpen} onClose={onClose} verticalCentered>
      <Flex flexDirection="column" width="320px" gap="16px">
        <Flex flexDirection="column" gap="8px">
          <Text variant="heading3" weight="bold">
            Maaf, halaman ini masih dalam perbaikan
          </Text>
          <Flex justifyContent="center">
            <Flex
              as="img"
              src="/assets/images/delivery/under-maintenance.svg"
              alt="maintenance"
            />
          </Flex>
          <Text>
            Sabar yaa, kamu akan bisa mengunjungi halaman ini dalam beberapa
            waktu lagi.
          </Text>
        </Flex>
        <Button isBlock onClick={onClose}>
          Oke
        </Button>
      </Flex>
    </Dialog>
  );
};

export default ModalUnderDevelopment;
