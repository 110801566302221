import React from "react";
import {
  Button,
  Flex,
  Checkbox,
  Text,
  DatePicker,
  Input,
  Icon,
  useToast,
  isEmpty,
  Box,
} from "renos-ui";
import dayjs from "dayjs";

const ClosedStore = (props) => {
  const {
    datas,
    setDatas,
    checkedClose,
    seCheckedClose,
    openDialog,
    has_product,
  } = props;

  const toast = useToast();

  const handleSubmit = () => {
    if (isEmpty(datas.date_start)) {
      return toast({
        label: "Tanggal mulai harus di isi.",
        placement: "bottom",
        backgroundColor: "red50",
      });
    }

    if (isEmpty(datas.date_end)) {
      return toast({
        label: "Tanggal berakhir harus di isi.",
        placement: "bottom",
        backgroundColor: "red50",
      });
    }
    openDialog();
  };

  return (
    <>
      <Flex flexDirection="column" gap="24px">
        <Flex padding="16px" gap="24px" flexDirection="column">
          <Flex gap="10px" alignItems="center">
            <Checkbox
              checked={checkedClose}
              onChange={() => {
                seCheckedClose(!checkedClose);
                setDatas({
                  ...datas,
                  date_start: new Date(),
                });
              }}
            />
            <Text variant="caption">Tutup Sekarang</Text>
          </Flex>
          <Flex flexDirection="column" gap="8px">
            <Text color="black50" variant="caption" weight="bold">
              Tanggal Mulai
            </Text>
            <DatePicker
              onChange={(startDate) => {
                setDatas({
                  ...datas,
                  date_start: startDate,
                  date_end: startDate,
                });
              }}
              minDate={new Date()}
              disabled={checkedClose}
              customInput={
                <Box>
                  <Input
                    placeholder="Mulai Tanggal"
                    size="small"
                    readOnly={true}
                    required={true}
                    sufix={<Icon name="Calendar-solid" size={20} />}
                    value={
                      datas.date_start
                        ? dayjs(datas.date_start).format("D MMM YYYY")
                        : ""
                    }
                  />
                </Box>
              }
            />
          </Flex>
          <Flex flexDirection="column" gap="8px">
            <Text color="black50" variant="caption" weight="bold">
              Tanggal Berakhir
            </Text>
            <DatePicker
              onChange={(endDate) => {
                setDatas({
                  ...datas,
                  date_end: endDate,
                });
              }}
              minDate={datas?.date_start || new Date()}
              customInput={
                <Box>
                  <Input
                    placeholder="Tanggal Berakhir"
                    size="small"
                    readOnly={true}
                    required={true}
                    sufix={<Icon name="Calendar-solid" size={20} />}
                    value={
                      datas.date_end
                        ? dayjs(datas.date_end).format("D MMM YYYY")
                        : ""
                    }
                  />
                </Box>
              }
            />
          </Flex>
          <Flex flexDirection="column" gap="8px">
            <Text color="black50" variant="caption" weight="bold">
              Catatan
            </Text>
            <Input.TextArea
              value={datas.notes}
              onChange={(event) =>
                setDatas({
                  ...datas,
                  notes: event.currentTarget.value,
                })
              }
              placeholder="Masukkan alasan toko tutup."
            />
          </Flex>
        </Flex>

        <Flex
          bottom="0"
          width="100%"
          position="fixed"
          justifyContent="center"
          alignItems="center"
          backgroundColor="white"
          boxShadow="0px 0px 8px 0px rgba(20, 20, 32, 0.05);"
        >
          <Flex width="100%" padding="16px">
            <Button onClick={handleSubmit} size="medium" isBlock>
              {!has_product ? "Simpan" : "Tutup Toko"}
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};

export default ClosedStore;
