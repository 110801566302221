import { currencyFormatter, imageAssetsLoader } from "helpers";
import React, { useEffect, useMemo, useState } from "react";
import { useWatch } from "react-hook-form";
import { useHistory } from "react-router-dom";
import {
  Box,
  Checkbox,
  Disclosure as StyledDisclosure,
  Divider,
  Flex,
  Icon,
  Input,
  // Label,
  // SelectInput,
  Text,
  isEmpty,
  Toggle,
  Label,
  SelectInput,
  // Toggle,
  // isEmpty,
} from "renos-ui";
import { useGetCustomShipping } from "services/shipping";
import { FieldNumberInput } from "widgets/global/HookForm";
import FormField from "../components/FormField";
import SectionWrapper from "../components/SectionWrapper";
import useFeatureFlag from "hooks/useFeatureFlag";
import { PRODUCT_PREORDER_OPTIONS } from "../constants";

const ProductShipping = ({ methods }) => {
  const isPreorderEnabled = useFeatureFlag("preorder");

  const history = useHistory();

  const shippingType = useWatch({ name: "shipping_type" });
  const productShipping = useWatch({ name: "product_shipping" });

  // const shippingInsurance = useWatch({ name: "insurance" });
  const isHaveVariant = useWatch({ name: "variant" });
  const isPreorder = useWatch({ name: "isPreorder" });
  const preorderType = useWatch({ name: "preorder_type" });
  const uom = useWatch({ name: "uom" });
  const height = useWatch({ name: "height" });
  const width = useWatch({ name: "width" });
  const length = useWatch({ name: "length" });
  const weight = useWatch({ name: "weight" });
  const sku = useWatch({ name: "sku" });

  const computedVolume = useMemo(
    () =>
      uom === 1
        ? Math.round((height * width * length) / 6000)
        : Math.round(((height * width * length) / 6000) * 1000),
    [height, width, length, uom]
  );

  const productWeight = useMemo(
    () =>
      isHaveVariant === 1
        ? Math.max(...sku.map((item) => item.weight))
        : weight,
    [sku, isHaveVariant, weight]
  );

  const { data: shippingData } = useGetCustomShipping();

  const [selectedPreorder, setSelectedPreorder] = useState(
    PRODUCT_PREORDER_OPTIONS[0]
  );

  useEffect(() => {
    if (!isEmpty(preorderType)) {
      setSelectedPreorder(
        PRODUCT_PREORDER_OPTIONS.find((data) => data.value === preorderType)
      );
    }
  }, [preorderType]);

  const onShippingCheckedAll = (serviceId, isCheckAll) => {
    const tempProductShipping = new Set(productShipping);
    serviceId.forEach((id) => {
      if (!isCheckAll) {
        tempProductShipping.add(id);
      } else {
        tempProductShipping.delete(id);
      }
    });
    methods.setValue("product_shipping", tempProductShipping);
  };

  const onCheckShipping = (id) => {
    if (productShipping?.has(id)) {
      const tempProductShipping = new Set(productShipping);
      tempProductShipping.delete(id);

      return methods.setValue("product_shipping", tempProductShipping);
    }

    methods.setValue("product_shipping", productShipping.add(id));
  };

  const onPreorderSwitched = (preorderState) => {
    methods.setValue("isPreorder", !preorderState);

    if (!preorderState) {
      setSelectedPreorder(PRODUCT_PREORDER_OPTIONS[0]);
      methods.setValue("preorder_value", PRODUCT_PREORDER_OPTIONS[0].min);
      methods.setValue("preorder", PRODUCT_PREORDER_OPTIONS[0].min);
      methods.setValue("preorder_type", 1);
    } else {
      methods.setValue("preorder_value", 0);
      methods.setValue("preorder", 0);
    }
  };

  const onPreorderValueChange = (value) => {
    methods.setValue("preorder", value);
  };

  return (
    <SectionWrapper sectionName="Pengiriman">
      <Flex flexDirection="column" rowGap="40px">
        {!isHaveVariant && (
          <FormField
            label="Berat Produk"
            isRequired
            hint={
              <span>
                Masukkan berat dengan menimbang produk{" "}
                <Text color="black50" weight="bold">
                  setelah dikemas.
                </Text>
              </span>
            }
          >
            <Box width="184px">
              <FieldNumberInput
                name="weight"
                placeholder="Berat"
                max={uom === 1 ? 999 : 999999}
                sufix={
                  <Text color="black50" weight="bold">
                    {uom === 1 ? "kg" : "g"}
                  </Text>
                }
              />
            </Box>
          </FormField>
        )}

        <FormField
          label="Ukuran Produk"
          isRequired
          hint={
            <span>
              Masukkan ukuran produk{" "}
              <Text color="black50" weight="bold">
                setelah dikemas
              </Text>{" "}
              untuk menghitung berat volume.
            </span>
          }
        >
          <Flex flexDirection="column" rowGap="16px">
            <Flex columnGap="16px">
              <Box width="184px">
                <FieldNumberInput
                  name="length"
                  placeholder="Panjang"
                  max={100000}
                  sufix={
                    <Text color="black50" weight="bold">
                      cm
                    </Text>
                  }
                />
              </Box>
              <Box width="184px">
                <FieldNumberInput
                  name="width"
                  placeholder="Lebar"
                  max={100000}
                  sufix={
                    <Text color="black50" weight="bold">
                      cm
                    </Text>
                  }
                />
              </Box>
              <Box width="184px">
                <FieldNumberInput
                  name="height"
                  placeholder="Tinggi"
                  max={100000}
                  sufix={
                    <Text color="black50" weight="bold">
                      cm
                    </Text>
                  }
                />
              </Box>
            </Flex>
            {productWeight < computedVolume && (
              <Flex justifyContent="space-between" columnGap="16px">
                <Flex
                  flex={1}
                  padding={16}
                  borderRadius={8}
                  backgroundColor="blue5"
                  height="fit-content"
                >
                  <Text color="blue100">
                    Berat Volume: <br />
                    {currencyFormatter(computedVolume)}{" "}
                    {uom === 1 ? "kg" : "gr"}
                  </Text>
                </Flex>
                <Flex
                  flex={2}
                  padding={24}
                  borderRadius={8}
                  backgroundColor="yellow5"
                >
                  <Flex flexDirection="column" rowGap="8px">
                    <Flex gap="8px" alignItems="center">
                      <Icon name="Info-solid" color="yellow50" />
                      <Text weight="bold" variant="subtitle" color="yellow50">
                        Informasi
                      </Text>
                    </Flex>
                    <Text color="yellow100">
                      Ongkos pengiriman akan dihitung menggunakan berat volume
                      karena lebih berat dari berat yang diinput.
                    </Text>
                  </Flex>
                </Flex>
              </Flex>
            )}
          </Flex>
        </FormField>
        <FormField
          label="Layanan Pengiriman"
          hint="Atur layanan pengiriman sesuai jenis produkmu."
        >
          <Flex flexDirection="column" rowGap="40px">
            <Flex justifyContent="space-between" columnGap="40px">
              <Flex flexDirection="column" rowGap="8px">
                <Flex
                  columnGap="8px"
                  alignItems="center"
                  cursor="pointer"
                  width="fit-content"
                  onClick={() => {
                    methods.setValue("product_shipping", new Set([]));
                    methods.setValue("shipping_type", 1);
                  }}
                >
                  <Input.Radio
                    value={1}
                    name="shipping_type"
                    checked={shippingType === 1}
                  />
                  <Text color="black75">Standar</Text>
                </Flex>
                <Flex width="240px" paddingLeft="40px">
                  <Text color="black50">
                    Layanan pengiriman produk ini akan mengikuti{" "}
                    <Box
                      color="blue50"
                      fontWeight="bold"
                      cursor="pointer"
                      display="inline-block"
                      onClick={() =>
                        history.push("/setting?tab-active=delivery")
                      }
                    >
                      pengaturan pengiriman.
                    </Box>
                  </Text>
                </Flex>
              </Flex>
              <Flex
                flexDirection="column"
                rowGap="8px"
                onClick={() => methods.setValue("shipping_type", 2)}
              >
                <Flex
                  columnGap="8px"
                  alignItems="center"
                  cursor="pointer"
                  width="fit-content"
                  onClick={() => methods.setValue("shipping_type", 2)}
                >
                  <Input.Radio
                    value={2}
                    name="shipping_type"
                    checked={shippingType === 2}
                  />
                  <Text color="black75">Custom</Text>
                </Flex>
                <Flex width="240px" paddingLeft="40px">
                  <Text color="black50">
                    Atur layanan pengiriman khusus untuk produk ini.
                  </Text>
                </Flex>
              </Flex>
            </Flex>
            <Flex flexWrap="wrap" gap="16px">
              {shippingType === 2 &&
                shippingData &&
                shippingData.map((service) => {
                  const allServiceIndex = service.services.map(
                    (data) => data.id
                  );
                  const isCheckAll = allServiceIndex.every((data) =>
                    Array.from(productShipping).includes(data)
                  );

                  const isIndeterminate = allServiceIndex.some((data) =>
                    Array.from(productShipping).includes(data)
                  );

                  return (
                    <StyledDisclosure key={service.id}>
                      {({ isOpen, onToggle }) => (
                        <Flex
                          flexDirection="column"
                          width="276px"
                          borderStyle="solid"
                          borderColor="black10"
                          borderWidth="1px"
                          borderRadius="8px"
                        >
                          <Flex
                            justifyContent="space-between"
                            cursor="pointer"
                            alignItems="center"
                            p="16px"
                          >
                            <Flex flexDirection="column" width="100%">
                              <Flex
                                columnGap="8px"
                                alignItems="center"
                                justifyContent="space-between"
                                width="100%"
                                mb={isOpen && "8px"}
                              >
                                <Flex columnGap="12px" alignItems="center">
                                  <Checkbox
                                    checked={isCheckAll}
                                    indeterminate={isIndeterminate}
                                    onClick={() => {
                                      onShippingCheckedAll(
                                        allServiceIndex,
                                        isCheckAll
                                      );

                                      if (!isOpen) {
                                        onToggle();
                                      }
                                    }}
                                  />
                                  {service?.image_url && (
                                    <Box
                                      as="img"
                                      src={imageAssetsLoader(service.image_url)}
                                      alt="service_image"
                                      width="40px"
                                    />
                                  )}
                                  <Text weight="bold">{service.name}</Text>
                                </Flex>
                                <Icon
                                  name={isOpen ? "Up-outline" : "Down-outline"}
                                  size={24}
                                  color="black50"
                                  cursor="pointer"
                                  onClick={onToggle}
                                />
                              </Flex>
                              {isOpen && <Divider />}
                            </Flex>
                          </Flex>
                          {isOpen && (
                            <Flex
                              p="16px"
                              pt="0"
                              flexDirection="column"
                              rowGap="20px"
                            >
                              {service?.services.map((data) => {
                                const isChecked = productShipping?.has(data.id);

                                return (
                                  <Flex columnGap="8px" alignItems="center">
                                    <Checkbox
                                      checked={isChecked}
                                      onChange={() => onCheckShipping(data.id)}
                                    />
                                    <Text
                                      onClick={() => onCheckShipping(data.id)}
                                    >
                                      {data.name}
                                    </Text>
                                  </Flex>
                                );
                              })}
                            </Flex>
                          )}
                        </Flex>
                      )}
                    </StyledDisclosure>
                  );
                })}
            </Flex>
          </Flex>
        </FormField>
        {/* <FormField
          label="Asuransi Pengiriman"
          hint="Menanggung pengembalian dana produk & ongkir untuk penjual maupun pembeli jika terjadi kerusakan / kehilangan saat pengiriman."
        >
          <Flex justifyContent="space-between" columnGap="40px">
            <Flex
              columnGap="8px"
              cursor="pointer"
              onClick={() => methods.setValue("insurance", 0)}
            >
              <Input.Radio
                value={0}
                name="insurance"
                checked={shippingInsurance === 0}
              />
              <Flex flexDirection="column" rowGap="8px" width="240px" pt="4px">
                <Text color="black75">Wajib</Text>
                <Text color="black50">
                  Kamu <strong>mewajibkan</strong> pembeli untuk mengaktifkan
                  asuransi pengiriman.
                </Text>
              </Flex>
            </Flex>
            <Flex
              columnGap="8px"
              cursor="pointer"
              onClick={() => methods.setValue("insurance", 1)}
            >
              <Input.Radio
                value={1}
                name="insurance"
                checked={shippingInsurance === 1}
              />
              <Flex flexDirection="column" rowGap="8px" width="240px" pt="4px">
                <Text color="black75">Opsional</Text>
                <Text color="black50">
                  Kamu memberikan <strong>opsi</strong> kepada pembeli untuk
                  mengaktifkan asuransi pengiriman.
                </Text>
              </Flex>
            </Flex>
          </Flex>
        </FormField> */}
        {isPreorderEnabled && (
          <FormField
            label="Pre Order"
            hint={
              <span>
                Aktifkan preorder jika produkmu membutuhkan waktu produksi
                sebelum dikirimkan.{" "}
                <Box as="span" cursor="pointer">
                  <Text color="blue50" weight="bold">
                    Pelajari Selengkapnya
                  </Text>
                </Box>
              </span>
            }
          >
            <Flex flexDirection="column" rowGap="24px">
              <Flex
                alignItems="start"
                columnGap="8px"
                onClick={() => onPreorderSwitched(isPreorder)}
                cursor="pointer"
                width="fit-content"
              >
                <Toggle checked={isPreorder} />
                {isPreorder ? (
                  <Text weight="bold" color="blue50">
                    Aktif
                  </Text>
                ) : (
                  <Text weight="bold" color="black50">
                    Nonaktif
                  </Text>
                )}
              </Flex>
              {isPreorder && (
                <Flex alignItems="center" columnGap="8px">
                  <Box width="156px">
                    <FieldNumberInput
                      name="preorder_value"
                      hint={`Maksimum ${selectedPreorder.max} ${selectedPreorder.label}`}
                      max={selectedPreorder.max}
                      min={1}
                      onChange={(value) => onPreorderValueChange(value)}
                      label={
                        <Flex alignItems="center" columnGap="8px">
                          <Text variant="caption" weight="bold" color="black50">
                            Waktu Proses
                          </Text>
                          <Label variant="neutral">Wajib</Label>
                        </Flex>
                      }
                      size="small"
                      placeholder="Waktu proses"
                      onFocus={(event) => event.target.select()}
                    />
                  </Box>
                  <SelectInput
                    size="small"
                    options={PRODUCT_PREORDER_OPTIONS}
                    value={selectedPreorder}
                    onChange={(item) => {
                      setSelectedPreorder(item);
                      onPreorderValueChange(item.min);
                      methods.setValue("preorder_value", item.min);
                      methods.setValue("preorder_type", item.value);
                    }}
                  />
                </Flex>
              )}
            </Flex>
          </FormField>
        )}
      </Flex>
    </SectionWrapper>
  );
};

export default ProductShipping;
