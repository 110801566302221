import React from "react";
import { useState } from "react";
import { Flex } from "renos-ui";
import LandingFooter from "widgets/desktop/LandingFooter";
import LandingHeader from "widgets/desktop/LandingHeader";

const LandingLayout = ({ children }) => {
  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;

    if (scrolled > 100) {
      setVisible(true);
    } else if (scrolled <= 100) {
      setVisible(false);
    }
  };

  window.addEventListener("scroll", toggleVisible);

  return (
    <Flex width="100%" alignItems="center" flexDirection="column">
      <LandingHeader visible={visible} />
      {children}
      <LandingFooter />
    </Flex>
  );
};

export default LandingLayout;
