import { currencyFormatter } from "helpers";
import { useMemo } from "react";
import { Avatar, Button, Flex, Icon, Text } from "renos-ui";
import { useGetStoreDetail } from "services/store";

const StoreInfo = () => {
  const { data: storeData } = useGetStoreDetail(
    {
      need: "total_product,ratings",
    },
    "get-store-detail-header-profile"
  );

  const dataStore = useMemo(() => storeData?.data || {}, [storeData]);

  return (
    <Flex
      backgroundColor="white"
      p="16px"
      gap="24px"
      flexDirection="column"
      borderTopColor="black5"
      borderTopStyle="solid"
      borderTopWidth="1px"
    >
      <Flex gap="16px">
        <Avatar
          isBorder
          src={dataStore?.image_path || "/assets/images/default_store.svg"}
          size={40}
        />
        <Flex flexDirection="column">
          <Text variant="subtitle" weight="bold" lineClamp={1}>
            {dataStore?.name}
          </Text>
          <Flex gap="9px" alignItems="center">
            <Flex gap="4px" alignItems="center">
              <Icon name="Location-solid" size={16} color="black25" />
              <Text variant="caption" color="black75" lineClamp={1}>
                {dataStore.domicile}
              </Text>
            </Flex>
            <Icon name="Circle-solid" size={6} color="black10" />
            <Flex gap="4px" alignItems="center">
              <Text variant="caption" color="blue50">
                Sedang Online
              </Text>
              <Icon name="Circle-solid" size={10} color="blue50" />
            </Flex>
          </Flex>
        </Flex>
      </Flex>

      <Flex gap="16px" alignItems="center">
        <Flex width="72px" flexDirection="column">
          <Text color="black75" weight="bold">
            {currencyFormatter(dataStore?.total_product)}
          </Text>
          <Text color="black75" variant="caption">
            Total Produk
          </Text>
        </Flex>

        <Flex height="25px" width="1px" backgroundColor="black10" />

        <Flex width="100px" flexDirection="column">
          <Text color="black75" weight="bold">
            {dataStore?.store_process_name || "-"}
          </Text>
          <Text color="black75" variant="caption">
            Kecepatan Proses
          </Text>
        </Flex>

        <Flex height="25px" width="1px" backgroundColor="black10" />

        <Flex width="88px" flexDirection="column">
          <Flex alignItems="center" gap="2px">
            <Text color="black75" weight="bold">
              {dataStore?.rating_avg}
            </Text>
            <Icon name="Star-solid" size={14} color="yellow50" />
          </Flex>
          <Text color="black75" variant="caption">
            Kualitas Produk
          </Text>
        </Flex>
      </Flex>

      <Button size="small" isBlock>
        Chat Penjual
      </Button>
    </Flex>
  );
};

export default StoreInfo;
