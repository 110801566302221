import desktopUrl from "constants/desktopUrl";
import { useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { Flex, Icon, Spinner, Text, range, useDisclosure } from "renos-ui";
import {
  useGetHomePageStatus,
  useGetLayoutTemplate,
} from "services/store-layout/homepage";
import FailLoadData from "widgets/global/FailLoadData";
import Loader from "./component/Loader";
import Card from "./component/Card";
import DialogSelect from "./component/DialogSelect";
import DialogTemplate from "./component/DialogTemplate";

const AddTemplate = () => {
  const { push } = useHistory();
  const { isOpen, onClose, onOpen } = useDisclosure({ isOpen: false });
  const {
    isOpen: isOpenTemplate,
    onClose: onCloseTemplate,
    onOpen: onOpenTemplate,
  } = useDisclosure({ isOpen: false });
  const [idGet, setIdGet] = useState();
  const [idHover, setIdHover] = useState();
  const [dataSave, setDataSave] = useState();

  const {
    isLoading: isGetStatusLoading,
    isError: isGetStatusError,
    refetch: refetchStatus,
    isRefetching: isStatusRefetch,
  } = useGetHomePageStatus({
    onSuccess: (dataSuccess) => {
      setIdGet(dataSuccess?.data?.template?.id || "");
    },
  });

  const {
    isLoading: isGetLayoutLoading,
    isError: isGetLayoutError,
    refetch: refetchLayout,
    isRefetching: isLayoutRefetch,
    data,
  } = useGetLayoutTemplate();

  const handleRefetch = () => {
    refetchStatus();
    refetchLayout();
  };

  const isLoading = useMemo(
    () =>
      isGetStatusLoading ||
      isGetLayoutLoading ||
      isLayoutRefetch ||
      isStatusRefetch,
    [isGetStatusLoading, isGetLayoutLoading, isLayoutRefetch, isStatusRefetch]
  );

  const isError = useMemo(
    () => isGetStatusError || isGetLayoutError,
    [isGetStatusError, isGetLayoutError]
  );

  return (
    <Flex
      flexDirection="column"
      gap="8px"
      width="952px"
      height="calc(100% - 48px)"
      position="relative"
    >
      <Flex
        gap="12px"
        padding="22px 23px 24px 23px"
        backgroundColor="white"
        borderTopLeftRadius="16px"
        borderTopRightRadius="16px"
        boxShadow="0px 4px 4px rgba(20, 20, 32, 0.05)"
        alignItems="center"
      >
        <Icon
          name="Back-outline"
          size={24}
          onClick={() => push(desktopUrl.STORE_LAYOUT)}
          cursor="pointer"
        />
        <Text variant="heading3" weight="bold">
          Pilih Template
        </Text>
        {isLoading && <Spinner size="16px" color="black100" />}
      </Flex>

      <Flex
        height="calc(100% - 73.5px)"
        gap="8px"
        padding="24px"
        backgroundColor="white"
        boxShadow="0px 4px 4px rgba(20, 20, 32, 0.05)"
        borderBottomLeftRadius="16px"
        borderBottomRightRadius="16px"
        flexDirection="column"
      >
        <Text color="black75" weight="bold">
          Pilih template yang sesuai dengan kebutuhan tokomu.
        </Text>
        <Flex gap="16px" flexWrap="wrap">
          {isLoading ? (
            range(5).map((_, indexSkeleton) => (
              <Loader key={`tempalte-${indexSkeleton}`} />
            ))
          ) : isError ? (
            <Flex
              alignItems="center"
              justifyItems="center"
              py="12%"
              width="100%"
            >
              <FailLoadData onClick={handleRefetch} />
            </Flex>
          ) : (
            data?.data?.map((template, indextemplate) => (
              <Card
                key={`tempalte-${indextemplate}`}
                {...{
                  template,
                  setIdHover,
                  idHover,
                  idGet,
                  setDataSave,
                  onOpenTemplate,
                  handleOpen: () => {
                    if (idGet === idHover) push(desktopUrl.STORE_LAYOUT);
                    onOpen();
                  },
                }}
              />
            ))
          )}
        </Flex>
      </Flex>

      <DialogSelect {...{ isOpen, onClose, data: dataSave }} />

      {isOpenTemplate && (
        <DialogTemplate
          {...{
            onClose: onCloseTemplate,
            data: dataSave,
            idGet,
          }}
        />
      )}
    </Flex>
  );
};

export default AddTemplate;
