import { Flex, Text } from "renos-ui";

const ProductNotFound = () => {
  return (
    <Flex
      flexDirection="column"
      rowGap="40px"
      alignItems="center"
      width="100%"
      height="400px"
      justifyContent="center"
    >
      <Flex
        as="img"
        src="/assets/images/store-layout/no-product.svg"
        alt="no-notification-img"
        width="200px"
        height="200px"
      />
      <Flex flexDirection="column" alignItems="center" rowGap="24px">
        <Flex
          flexDirection="column"
          textAlign="center"
          rowGap="8px"
          width="300px"
        >
          <Text variant="heading3" weight="bold">
            Produk tidak ditemukan
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default ProductNotFound;
