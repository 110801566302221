import { Flex, Icon, Text, useDisclosure } from "renos-ui";
import ModalOrderStatus from "./ModalOrderStatus";

const OrderStatus = ({ data }) => {
  const disclosure = useDisclosure({ isOpen: false });

  return (
    <Flex>
      <Flex
        gap="8px"
        alignItems="center"
        cursor="pointer"
        onClick={disclosure.onToggle}
      >
        <Icon name="Transaction-outline" color="black75" size={16} />
        <Text color="black75" weight="bold">
          Status Pesanan
        </Text>
      </Flex>
      <ModalOrderStatus
        onClose={disclosure.onClose}
        isOpen={disclosure.isOpen}
        data={data}
      />
    </Flex>
  );
};

export default OrderStatus;
