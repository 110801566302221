import { numberInputChecking } from "renos-ui";

const chartLineConfig = (isConversion, isNetIncome, isSeen) => {
  const title = isConversion
    ? "Tingkat Konversi"
    : isSeen
    ? "Produk Dilihat"
    : isNetIncome
    ? "Periode Sekarang"
    : "Produk Terjual";

  return {
    series: [
      {
        name: title,
        data: [],
      },
      {
        name: "Periode sebelumnya",
        data: [],
      },
    ],
    options: {
      colors: ["#2327be", "#8d8d97"],
      labels: [
        {
          name: "Date",
          data: [],
        },
        {
          name: "Time",
          data: [],
        },
      ],
      chart: {
        height: 350,
        type: "line",
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: [2, 2, 2],
        curve: "straight",
        dashArray: [0, 8, 5],
      },
      title: {
        text: "",
        align: "left",
      },
      legend: {
        tooltipHoverFormatter: function (val, opts) {
          const hoverValue =
            opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex];

          return `${val}:  ${
            numberInputChecking(hoverValue.toString() || "0", 0).formattedValue
          }`;
        },
        horizontalAlign: "left",
      },
      markers: {
        size: 0,
        hover: {
          sizeOffset: 6,
        },
      },
      xaxis: {
        categories: [],
      },
      yaxis: {
        labels: {
          show: true,
          formatter: (value) => {
            return numberInputChecking(value.toString() || "0", 0)
              .formattedValue;
          },
        },
      },
      tooltip: {
        custom: function ({ dataPointIndex, w }) {
          const data = w.globals.initialSeries;
          const label = w.globals.initialConfig;
          const title = isConversion
            ? "Tingkat Konversi"
            : isSeen
            ? "Produk Dilihat"
            : isNetIncome
            ? "Periode Sekarang"
            : "Produk Terjual";

          return `
            <div class="arrow_box">
              <span class="date-time">
                ${label.labels[0].data[dataPointIndex]}  (${
            label.labels[1].data[dataPointIndex]
          } WIB)
               </span> </br>
              <span class="period"> 
              ${title} : ${
            isConversion
              ? data[0].data[dataPointIndex] + "%"
              : isNetIncome
              ? "Rp. " +
                numberInputChecking(
                  data[0].data[dataPointIndex].toString() || "0",
                  0
                ).formattedValue
              : isSeen
              ? numberInputChecking(
                  data[0].data[dataPointIndex].toString() || "0",
                  0
                ).formattedValue + " x"
              : numberInputChecking(
                  data[0].data[dataPointIndex].toString() || "0",
                  0
                ).formattedValue
          }
              </span > </br >
              <span class="period"> 
                Periode sebelumnya : ${
                  isConversion
                    ? data[1].data[dataPointIndex] + "%"
                    : isNetIncome
                    ? "Rp. " +
                      numberInputChecking(
                        data[1].data[dataPointIndex].toString() || "0",
                        0
                      ).formattedValue
                    : isSeen
                    ? numberInputChecking(
                        data[1].data[dataPointIndex].toString() || "0",
                        0
                      ).formattedValue + " x"
                    : numberInputChecking(
                        data[1].data[dataPointIndex].toString() || "0",
                        0
                      ).formattedValue
                }
              </span >  
            </div>
            `;
        },
      },
      grid: {
        borderColor: "#f1f1f1",
      },
    },
  };
};

export { chartLineConfig };
