import { Divider, Flex, Skeleton } from "renos-ui";

const CardSkeleton = ({ indexSkeleton }) => {
  return (
    <>
      <Flex gap="16px" alignItems="center">
        <Skeleton height="20px" width="20px" />
        <Flex gap="12px" width="100%">
          <Skeleton height="40px" width="40px" />
          <Flex flexDirection="column" width="80%" gap="4px">
            <Skeleton height="12px" width="80%" />
            <Skeleton height="12px" width="50%" />
          </Flex>
        </Flex>
      </Flex>
      {indexSkeleton !== 2 && <Divider />}
    </>
  );
};

export default CardSkeleton;
