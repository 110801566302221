import React from "react";
import { Flex, Skeleton } from "renos-ui";

const SkeletonTemplate = () => {
  return (
    <Flex padding="8px 24px">
      <Flex alignItems="center" gap="20px" width="100%">
        <Skeleton width="18px" height="18px" />
        <Flex alignItems="center" justifyContent="space-between" width="100%">
          <Flex flexDirection="column" gap="4px" maxWidth="600px">
            <Skeleton width="200px" />
            <Skeleton width="400px" />
          </Flex>
          <Flex gap="24px" cursor="pointer" color="blue50">
            <Skeleton width="100px" />
            <Skeleton width="100px" />
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default SkeletonTemplate;
