import { useState, useRef, useMemo, useEffect } from "react";
import {
  Box,
  Button,
  Divider,
  Flex,
  Icon,
  Input,
  Spinner,
  Tabs,
  Text,
  Upload,
  useUpload,
  useToast,
  isEmpty,
} from "renos-ui";
import {
  useSaveImageShowcase,
  useGetProductListImage,
  useUploadImageProductShowcase,
} from "services/product-showcase";
import IMGNoImg from "assets/images/no-img.png";
import {
  blobToBase64,
  encodeUrl,
  getImageMeta,
  imgCompressor,
  imageTypeChecking,
} from "helpers";
import { StyledDialogUpload } from "./styled";
import { IMAGE_FILE_TYPE } from "containers/web/setting/document/constant";
import { ImageSkeleton } from "./Skeleton";

const UploadModalShowcase = ({
  uploadDisclosure,
  tempTagId,
  refetchProductShowcase,
}) => {
  const toast = useToast();
  const containerRef = useRef();
  const [loadingUpload, setLoadingUpload] = useState(false);
  const [imgPreview, setImgPreview] = useState("");
  const [imgFile, setImgFile] = useState(0);
  const [imgName, setImgName] = useState("image-showcase");
  const [isSuccess, setIsSuccess] = useState(false);
  const [invalidMessage, setInvalidMessage] = useState("");
  const [checkedId, setCheckedId] = useState(0);
  const { mutate: uploadImage } = useUploadImageProductShowcase({
    onSuccess: (data) => {
      const { url, path, id } = data;
      setInvalidMessage("");
      setIsSuccess(true);
      setImgPreview(url);
      setImgFile(id);
      setImgName(path);
      setLoadingUpload(true);
      toast({
        label: "Upload Berhasil!",
        placement: "top",
      });
      setTimeout(() => {
        setLoadingUpload(false);
      }, 5000);
    },
    onError: () => {
      setLoadingUpload(true);
      setTimeout(() => {
        setLoadingUpload(false);
      }, 5000);
      toast({
        label: "Upload Gagal",
        placement: "top",
      });
    },
  });

  const { data: showcaseImage, isLoading: isLoadingImage } = useGetProductListImage({
      tag_store_id: tempTagId,
    });

  // submit after upload image
  const { mutate: saveImage, isLoading: isLoadingSave } = useSaveImageShowcase({
    onSuccess: () => {
      toast({
        label: "Berhasil memperbarui Etalase Toko",
        placement: "top",
      });
      setTimeout(() => {
        refetchProductShowcase();
      }, 1000);
      uploadDisclosure.onClose();
      setImgPreview("");
      setImgFile(0);
      setImgName("image-showcase");
      setIsSuccess(false);
    },
    onError: () => {
      toast({
        label: "Gagal memperbarui Etalase Toko",
        placement: "top",
      });
    },
  });

  const handleRadioClick = useMemo(
    () => (index, imagePath, imageName, event) => {

      event.preventDefault();
      setCheckedId(index);
      setImgFile(imagePath ?? imageName);
      setImgName(imageName);

      return false;
    },
    [setCheckedId, setImgFile, setImgName]
  );

  const scrollToRadioInput = (index) => {
    const container = containerRef.current;

    if (!container) {
      return;
    }

    const radioInput = container.querySelector(
      `#produk-etalase-radio-${index}`
    );

    if (!radioInput) {
      return;
    }

    const containerRect = container.getBoundingClientRect();
    const radioInputRect = radioInput.getBoundingClientRect();
    const offset = radioInputRect.top - containerRect.top;

    if (typeof radioInput.scrollIntoViewIfNeeded === "function") {
      radioInput.scrollIntoViewIfNeeded(true);
    } else {
      radioInput.scrollIntoView({
        behavior: "smooth",
        top: offset,
      });
    }
  };

  useEffect(() => {
    if (checkedId !== null ?? null) {
      scrollToRadioInput(checkedId);
    }
  }, [checkedId]);

  const { register } = useUpload({
    fileType: "image",
    onRequest: async (file) => {
      const deepTypeChecking = await imageTypeChecking(file);
      setLoadingUpload(true);

      if (deepTypeChecking) {
        const result = await blobToBase64(file);
        const { naturalHeight, naturalWidth } = await getImageMeta(result);

        if (naturalWidth < 300 || naturalHeight < 300) {
          toast({
            label:
              "Gagal upload file. Harap cek kembali kesesuaian file yang akan kamu upload.",
            placement: "top",
            backgroundColor: "red50",
          });

          setInvalidMessage("Resolusi gambar harus lebih dari 300x300");
          setTimeout(() => {
            setLoadingUpload(false);
          }, 3000);

          return null;
        } else {
          const formData = new FormData();
          const compressed =
            file.size > 400000
              ? await imgCompressor(file, {
                  maxSizeMB: 0.4,
                  maxWidthOrHeight: 1920,
                  useWebWorker: true,
                })
              : file;

          formData.append("file", compressed);
          formData.append("file_name", compressed?.name || "image-showcase");

          uploadImage(formData);
          setTimeout(() => {
            setLoadingUpload(false);
          }, 3000);
        }
      } else {
        toast({
          label:
            "Gagal upload file. Harap cek kembali kesesuaian file yang akan kamu upload.",
          placement: "top",
          backgroundColor: "red50",
        });
        setInvalidMessage("");
        setTimeout(() => {
          setLoadingUpload(false);
        }, 3000);
      }
    },
    validator: (file) => {
      if (!IMAGE_FILE_TYPE.includes(file.type)) {
        toast({
          label:
            "Gagal upload file. Harap cek kembali kesesuaian file yang akan kamu upload.",
          placement: "top",
          backgroundColor: "red50",
        });

        setLoadingUpload(true);
        
        setTimeout(() => {
          setLoadingUpload(false);
        }, 2000);

        return true;
      }

      return false;
    },
  });

  const tabItems = [
    {
      label: ({ isActive }) => (
        <Box padding="10px">
          <Text
            color={isActive ? "blue50" : "black50"}
            weight={isActive ? "bold" : "regular"}
          >
            Upload Gambar
          </Text>
        </Box>
      ),
      children: () =>
        isSuccess ? (
          <Flex
            padding="24px 8px"
            marginTop="16px"
            rowGap="24px"
            gap="24px"
            flexWrap="wrap"
            height="230px"
            borderColor="black10"
            border="1px dashed"
            borderRadius="8px"
            marginBottom="16px"
          >
            <Flex gap="32px" justifyContent="center" alignItems="center">
              <Flex flexBasis="50%" justifyContent="end">
                <Box
                  as="div"
                  background={`url(${encodeUrl(imgPreview)})`}
                  width="160px"
                  height="160px"
                  backgroundSize="cover"
                  borderRadius="8px"
                />
              </Flex>
              <Flex flexDirection="column" flexBasis="50%" gap="8px">
                <Flex gap="4px">
                  <Icon name="Checkmark-outline" color="blue50" />
                  <Text color="black75" variant="subtitle" weight="bold">
                    Upload Berhasil
                  </Text>
                </Flex>
                <Flex>
                  <Text color="black50">
                    Simpan gambar untuk membuat perubahan pada tampilan.
                  </Text>
                </Flex>
                <Flex>
                  <Upload {...register}>
                    <Button
                      variant="secondary"
                      disabled={loadingUpload}
                      preffix={
                        loadingUpload && <Spinner color="white" size="8px" />
                      }
                    >
                      Ubah Gambar
                    </Button>
                  </Upload>
                </Flex>

                {!isEmpty(invalidMessage) && (
                  <Text
                    variant="caption"
                    color="red50"
                    aria-label="input-error"
                  >
                    {invalidMessage}
                  </Text>
                )}
              </Flex>
            </Flex>
          </Flex>
        ) : (
          <Box
            border="1px"
            borderStyle="dashed"
            borderColor="black10"
            width="100%"
            height="280px"
            borderRadius="8px"
            margin="16px 0"
            position="unset"
          >
            <Flex
              flexDirection="column"
              gap="30px"
              justifyContent="center"
              alignItems="center"
              padding="20px"
            >
              <Box as="img" src={IMGNoImg} alt="no-image" width="60px" />
              <Flex gap="4px" flexDirection="column" alignItems="center">
                <Text color="black50">
                  Ukuran optimal 500x500 pixels (max. 2mb)
                </Text>
                <Text color="black50">
                  dengan format file{" "}
                  <Text weight="bold">.JPG, .JPEG, .PNG</Text>
                </Text>
              </Flex>
              <Box width="160px">
                <Upload {...register}>
                  <Button
                    isBlock
                    variant="secondary"
                    size="large"
                    disabled={loadingUpload}
                    preffix={
                      loadingUpload && <Spinner color="white" size="8px" />
                    }
                  >
                    Pilih File
                  </Button>
                </Upload>
              </Box>
            </Flex>
          </Box>
        ),
      key: "upload-gambar",
      width: "20%",
    },
    {
      label: ({ isActive }) => (
        <Box padding="10px">
          <Text
            color={isActive ? "blue50" : "black50"}
            weight={isActive ? "bold" : "regular"}
          >
            Produk Etalase
          </Text>
        </Box>
      ),
      children: () => (
        <Flex
          ref={containerRef}
          padding="16px"
          marginTop="16px"
          // rowGap="24px"
          gap="12px"
          flexWrap="wrap"
          height="230px"
          overflowY="scroll"
          borderColor="black10"
          border="1px dashed"
          borderRadius="8px"
          marginBottom="16px"
        >
          {isLoadingImage ? (
            <ImageSkeleton />
          ) : (
            showcaseImage?.map((list, index) => {
              return (
                <Flex
                  position="relative"
                  key={`List-${list.product_image_name}+${index}`}
                >
                  <Flex
                    width="104px"
                    height="104px"
                    borderRadius="16px"
                    background={`url(${list?.product_image?.product_image_url})`}
                    backgroundSize="cover"
                  />
                  <Flex position="absolute" top="4px" left="12px">
                    <Input.Radio
                      id={`produk-etalase-radio-${index}`}
                      checked={Number(checkedId) === index}
                      onChange={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                        handleRadioClick(
                          index,
                          list?.product_image?.image_id,
                          list?.product_image?.product_image,
                          event
                        );
                      }}
                      value={index}
                    />
                  </Flex>
                </Flex>
              );
            })
          )}
        </Flex>
      ),
      key: "produk-etalase",
      width: "20%",
    },
  ];

  const handleSave = () => {
    saveImage({
      image_id: imgFile,
      image_name: imgName,
      tag_store_id: tempTagId,
    });
  };

  const handleCancel = () => {
    uploadDisclosure.onClose();
    setImgPreview("");
    setImgFile(0);
    setImgName("image-showcase");
    setIsSuccess(false);
  };

  return (
    <StyledDialogUpload
      isVisible={uploadDisclosure.isOpen}
      closable
      onClose={handleCancel}
      verticalCentered
      width="720px"
      height="470px"
    >
      <Flex flexDirection="column" width="100%">
        <Flex flexDirection="column" marginBottom="16px">
          <Text variant="heading3" weight="bold">
            Pilih Gambar Etalase
          </Text>
        </Flex>

        <Flex>
          <Divider />
        </Flex>

        {/* <Flex> */}
        <Tabs
          defaultActiveKey="upload-gambar"
          placement="bottom"
          items={tabItems}
        />
        {/* </Flex> */}

        <Flex>
          <Divider />
        </Flex>

        <Flex justifyContent="end" gap="16px" margin="16px 0 16px 16px">
          <Box width="144px">
            <Button
              isBlock
              size="large"
              variant="tertiary"
              onClick={handleCancel}
            >
              Batal
            </Button>
          </Box>
          <Box width="144px">
            <Button
              isBlock
              size="large"
              onClick={handleSave}
              disabled={isEmpty(imgFile) || isLoadingSave}
            >
              Simpan
            </Button>
          </Box>
        </Flex>
      </Flex>
    </StyledDialogUpload>
  );
};

export default UploadModalShowcase;
