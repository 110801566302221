import ENDPOINTS from "constants/endpoints";
import { goToUrl } from "helpers";
import { useMemo } from "react";
import { Box, Flex, Text, useToast } from "renos-ui";

const Header = ({ data, toDetail }) => {
  const {
    order_status_id: orderStatusId,
    order_status_name: orderStatusName,
    order_number: orderNumber,
    formatted_order_date: formattedOrderDate,
    order_cancellation_reason: cancelReason,
    order_type_id: orderTypeId,
    order_type_icon_url: orderTypeIconUrl,
    order_type_name: orderTypeName,
    order_id: orderId,
  } = data;

  const toast = useToast();

  const showInvoice = () => {
    goToUrl(
      `${process.env.REACT_APP_API}/${ENDPOINTS.order_bulk_invoice}?order_id=${orderId}`,
      "_blank"
    );
    toast({
      label: `Invoice ${orderNumber} berhasil dicetak`,
      placement: "bottom",
    });
  };

  const badgeColor = useMemo(() => {
    switch (orderStatusId) {
      case 2:
      case 3:
      case 4:
        return "yellow50";
      case 7:
        return "red50";
      case 5:
        return "blue50";
      case 6:
        return "black50";
      default:
        return "";
    }
  }, [orderStatusId]);

  const orderTypeColor = useMemo(() => {
    switch (orderTypeId) {
      case 1:
        return {
          text: "black50",
          background: "black5",
        };
      case 2:
        return {
          text: "blue50",
          background: "blue5",
        };
      default:
        return {};
    }
  }, [orderTypeId]);

  return (
    <Flex onClick={toDetail} flexDirection="column" gap="16px">
      <Flex justifyContent="space-between" alignItems="center">
        <Flex gap="8px" height="100%">
          <Box
            height="100%"
            width="4px"
            backgroundColor={badgeColor}
            borderRadius="8px"
          />
          <Flex flexDirection="column" gap="4px">
            <Flex gap="4px" alignItems="center">
              <Flex
                gap="2px"
                p="2px 4px"
                alignItems="center"
                backgroundColor={orderTypeColor?.background}
                borderRadius="4px"
              >
                <Flex
                  as="img"
                  alt="icon"
                  src={orderTypeIconUrl}
                  width="16px"
                  height="16px"
                />
                <Text
                  variant="small"
                  weight="bold"
                  color={orderTypeColor?.text}
                >
                  {orderTypeName}
                </Text>
              </Flex>
              <Text variant="caption" weight="bold">
                {orderStatusId === 8 && "Pesanan "}
                {orderStatusName}
              </Text>
            </Flex>

            <Flex onClick={showInvoice}>
              <Text variant="small" color="blue50">
                {orderNumber}
              </Text>
            </Flex>
          </Flex>
        </Flex>

        <Text variant="small" color="black50">
          {formattedOrderDate}
        </Text>
      </Flex>
      {cancelReason && (
        <Text
          variant="small"
          color="black50"
          style={{ wordBreak: "break-all" }}
        >
          {cancelReason}
        </Text>
      )}
    </Flex>
  );
};

export default Header;
