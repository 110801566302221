import { colors, Dropdown, Flex, SelectInput, Upload } from "renos-ui";
import styled from "styled-components";
import Table from "widgets/desktop/Table";

export const StyledSelectInput = styled(SelectInput)`
  .select__control {
    height: ${(props) =>
      props.variantValues && props.variantValues?.length >= 3
        ? "fit-content"
        : "48px"};
    padding: ${(props) =>
      props.variantValues ? "5px 12px !important" : "13px 16px !important"};
  }
`;

export const VariantTable = styled(Table)`
  padding-left: ${(props) => props.dataSource.length === 1 && "16px"};
  min-height: 90px;
  thead {
    position: relative;
    z-index: 0;
    th {
      border-radius: 8px;
      padding: 12px 0;
      p {
        color: black;
      }
    }
  }
  tbody {
    tr:last-child {
      td {
        border: none;
      }
    }
  }
`;

export const StyledDropdownContent = styled(Dropdown.Content)`
  min-width: 127px !important;
`;

export const VariantImageOvelay = styled(Flex)`
  display: none;
  transition: all 1s;
  background-color: ${colors.black100}70;
  border-radius: 8px;
`;

export const VariantImage = styled(Flex)`
  &:hover ${VariantImageOvelay} {
    display: flex;
  }
`;

export const StyledUpload = styled(Upload)`
  position: absolute;
  width: 40px;
  height: 40px;
  margin-left: 10px;
  top: 0;

  display: block;
  opacity: 0;
`;
