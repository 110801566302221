import { Flex, Box, Text, Button } from "renos-ui";

const NoData = ({
  title,
  description,
  onClick,
  btnText,
  isHasRefetchButton,
  refetch,
  refetchText,
}) => {
  return (
    <Flex
      padding={onClick ? "26px 40px 40px 40px" : "26px 40px 0 40px"}
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      textAlign="center"
    >
      <Box
        as="img"
        alt="no-product"
        height="160px"
        width="160px"
        src="assets/images/store-layout/no-product.svg"
      />
      <Flex
        flexDirection="column"
        gap="4px"
        marginTop="24px"
        marginBottom={onClick && "16px"}
      >
        <Text variant="subtitle" color="black75" weight="bold">
          {title}
        </Text>
        <Text color="black75">{description}</Text>
      </Flex>
      <Flex gap="4px" flexDirection="column">
        {onClick && (
          <Box width="186px">
            <Button onClick={onClick} size="medium" isBlock>
              {btnText}
            </Button>
          </Box>
        )}
        {isHasRefetchButton && (
          <Box>
            <Text variant="caption" color="black50">
              {refetchText}{" "}
            </Text>
            <Box cursor="pointer" onClick={refetch} display="inline">
              <Text color="blue50" variant="caption" weight="bold">
                Muat Ulang
              </Text>
            </Box>
          </Box>
        )}
      </Flex>
    </Flex>
  );
};

export default NoData;
