import { Flex, Icon, Input, Text, useDebounce, useDisclosure } from "renos-ui";
import ModalFilter from "./ModalFilter";
import { useMemo, useState } from "react";

const Filter = ({ data, setData }) => {
  const { isOpen, onClose, onOpen } = useDisclosure({ isOpen: false });
  const [searchValue, setSearchValue] = useState("");

  const handleChange = (event) => {
    setSearchValue(event.target.value);
  };

  useDebounce(
    () => {
      const tempData = data;
      tempData.search_value = searchValue;
      setData({ ...tempData });
    },
    [searchValue],
    1000
  );

  const filterCount = useMemo(() => {
    let count = 0;
    if (data.custom_status) count++;

    if (data.jenis_voucher) count++;

    if (data.tipe_potongan) count++;

    return count;
  }, [data]);

  return (
    <Flex gap="32px">
      <Input
        prefix={<Icon name="Search-outline" size={20} color="black50" />}
        placeholder="Cari nama voucher"
        size="small"
        value={searchValue}
        onChange={handleChange}
      />
      <Flex
        padding="7px 12px"
        gap="10px"
        cursor="pointer"
        alignItems="center"
        borderColor="black10"
        borderWidth="1px"
        borderStyle="solid"
        borderRadius="8px"
        onClick={onOpen}
      >
        {filterCount > 0 ? (
          <Flex
            width="20px"
            height="20px"
            borderRadius="16px"
            backgroundColor="blue50"
            alignItems="center"
            justifyContent="center"
          >
            <Text variant="caption" color="white" weight="bold">
              {filterCount}
            </Text>
          </Flex>
        ) : (
          <Icon name="Filter-outline" size={20} />
        )}

        <Text color="black75">Filter</Text>
      </Flex>
      {isOpen && (
        <ModalFilter
          {...{
            isOpen,
            onClose,
            onOpen,
            status: data.status,
            value: data,
            setValue: setData,
          }}
        />
      )}
    </Flex>
  );
};

export default Filter;
