import React, { useRef } from "react";
import { Flex, Text, Box, Icon } from "renos-ui";
import { useHistory } from "react-router-dom";
import { useGetChecklistStore } from "services/dashboard";
import { SwiperSlide, useSwiper } from "swiper/react";
import { Navigation } from "swiper";
import { StyledSwiper } from "./styled";

import SwiperContext from "providers/SwiperProvider";
import { SwiperProvider } from "providers/SwiperProvider";
import { useState } from "react";
import { useEffect } from "react";
import { useCallback } from "react";
import { useContext } from "react";

const StoreChecklist = (props) => {
  const history = useHistory();
  const { data, isLoading } = useGetChecklistStore();
  const swiperRef = useRef();
  const [swiperInstance, setSwiperInstance] = useState();
  const [swiperObject, setSwiperObject] = useState();

  return (
    <SwiperProvider
      value={{
        setSwiperInstance,
        swiperInstance,
        swiperObject,
        setSwiperObject,
      }}
    >
      <Flex
        background="white"
        height="429px"
        width="100%"
        justifyContent="center"
        borderRadius="16px"
        boxShadow="0px 4px 4px rgba(20, 20, 32, 0.05)"
        flexDirection="column"
        className="complete-step"
        {...props}
      >
        <Flex
          height="311px"
          position="relative"
          flexDirection="column"
          padding="24px"
          gap="24px"
        >
          <Flex flexDirection="column">
            <Text variant="subtitle" weight="bold">
              Yuk, Lengkapi Data Tokomu
            </Text>
            <Text variant="caption">
              Agar produk dan tokomu lebih mudah ditemukan oleh pembeli.
            </Text>
          </Flex>

          <Flex position="relative">
            <Flex flexDirection="column" gap="20px">
              <StyledSwiper
                modules={[Navigation]}
                onBeforeInit={(swiper) => {
                  swiperRef.current = swiper;
                }}
                direction="vertical"
                slidesPerView={1.5}
                spaceBetween={16}
              >
                {!isLoading &&
                  data?.checklist.map((item, indexChecklist) => (
                    <SwiperSlide key={indexChecklist}>
                      <Flex
                        justifyContent="space-between"
                        backgroundColor={
                          item.completed === true ? "black5" : "blue5"
                        }
                        borderRadius="8px"
                        key={indexChecklist}
                        gap="16px"
                        padding="24px"
                      >
                        <Box
                          alt="article-image"
                          width="52px"
                          height="52px"
                          as="img"
                          opacity={item.completed === true ? "50%" : null}
                          src={item.image_url}
                          borderRadius="8px"
                        />
                        <Flex flexDirection="column" gap="16px">
                          <Flex flexDirection="column" gap="4px">
                            <Text
                              variant="caption"
                              weight="bold"
                              color={
                                item.completed === true ? "black50" : "black75"
                              }
                            >
                              {item.title}
                            </Text>
                            <Text
                              variant="caption"
                              color={
                                item.completed === true ? "black50" : "black75"
                              }
                            >
                              {item.copywrite}
                            </Text>
                          </Flex>
                          <Flex>
                            <Flex gap="8px" alignItems="center">
                              {item.completed === true ? (
                                <Flex
                                  gap="8px"
                                  alignItems="center"
                                  cursor="pointer"
                                >
                                  <Flex
                                    width="16px"
                                    height="16px"
                                    borderRadius="100%"
                                    alignItems="center"
                                    justifyContent="center"
                                    backgroundColor="blue50"
                                    onClick={() => history.goBack()}
                                  >
                                    <Icon
                                      name="Checkmark-outline"
                                      color="white"
                                      size={8}
                                    />
                                  </Flex>
                                  <Text
                                    variant="caption"
                                    weight="bold"
                                    color="blue50"
                                  >
                                    Data Sudah Dilengkapi
                                  </Text>
                                </Flex>
                              ) : (
                                <Box
                                  onClick={() =>
                                    history.push(`${item.completion_link}`)
                                  }
                                  cursor="pointer"
                                >
                                  <Text
                                    variant="caption"
                                    weight="bold"
                                    color="blue50"
                                  >
                                    Lengkapi Data Ini
                                  </Text>
                                </Box>
                              )}
                            </Flex>
                          </Flex>
                        </Flex>
                      </Flex>
                    </SwiperSlide>
                  ))}
                <SwiperInstance />
              </StyledSwiper>

              <NavigationButton prev />
              <NavigationButton />
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </SwiperProvider>
  );
};

const SwiperInstance = () => {
  const { setSwiperInstance, setSwiperObject } = useContext(SwiperContext);
  const swiper = useSwiper();

  useEffect(() => {
    if (swiper) {
      setSwiperObject({ ...swiper });
      setSwiperInstance(swiper);
    }
  }, [swiper, setSwiperInstance, setSwiperObject]);
};

const NavigationButton = ({ prev = false }) => {
  const { swiperInstance, setSwiperObject, swiperObject } =
    useContext(SwiperContext);

  const [isActive, setIsActive] = useState(true);

  useEffect(() => {
    if (swiperObject) {
      if (prev) {
        setIsActive(!swiperObject.isBeginning);
      } else {
        setIsActive(
          swiperObject.isEnd && swiperObject.isBeginning
            ? true
            : !swiperObject.isEnd
        );
      }
    }

    // eslint-disable-next-line
  }, [swiperObject]);

  const onPrevClicked = useCallback(() => {
    if (prev) {
      swiperInstance.slidePrev();
    } else {
      swiperInstance.slideNext();
    }
    setSwiperObject({ ...swiperInstance });
    // eslint-disable-next-line
  }, [prev, swiperInstance]);

  if (!isActive) return <></>;

  return (
    <Flex
      width="40px"
      height="40px"
      position="absolute"
      top={prev && "-18px"}
      bottom={!prev && "-18px"}
      left="45%"
      backgroundColor={"white"}
      justifyContent="center"
      alignItems="center"
      borderRadius="50px"
      zIndex={8}
      onClick={onPrevClicked}
      cursor="pointer"
      boxShadow="0px 4px 4px rgba(20, 20, 32, 0.08)"
    >
      <Icon
        name={prev ? "Up-outline" : "Down-outline"}
        color="black50"
        size={16}
      />
    </Flex>
  );
};

export default StoreChecklist;
