const featureFlag = {
  staging: {
    preorder: true,
  },
  production: {
    preorder: true,
  },
};

const useFeatureFlag = (feature) => {
  const env = process.env.REACT_APP_ENV;

  return featureFlag[env][feature];
};

export default useFeatureFlag;
