import React from "react";
import { Flex, Text, Box, Label, Divider, Button, Spinner } from "renos-ui";
import { useGetMyBdding } from "services/bidding";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import { goToUrl } from "helpers";

export const MyBidding = ({ showOfferButtonDisclosure, setHasBidding }) => {
  const { request_number } = useParams();
  const { data, isLoading } = useGetMyBdding(
    {
      request_number: request_number,
    },
    {
      onSuccess: (res) => {
        const hasProductsToOffer = Boolean(
          res?.quotation_incoming?.products?.length
        );

        if (
          res.quotation_accepted !== null ||
          res.quotation_incoming !== null
        ) {
          setHasBidding(true);
        }

        if (!hasProductsToOffer) {
          showOfferButtonDisclosure.onToggle();
        }
      },
    }
  );

  const statusVariant = (biddingStatus) => {
    let status;

    switch (biddingStatus) {
      case "Berlangsung":
        status = "success";
        break;
      case "Disetujui":
        status = "success";
        break;
      case "Sedang Ditinjau":
        status = "warning";
        break;
      case "Menunggu Response":
        status = "warning";
        break;
      case "Dalam Negosiasi":
        status = "warning";
        break;
      case "Diterima":
        status = "success";
        break;
      case "Dibayar":
        status = "success";
        break;
      default:
    }

    return (
      <Label variant={status} size="small">
        {biddingStatus}
      </Label>
    );
  };

  const onSeeProduct = (id) =>
    goToUrl(
      `${process.env.REACT_APP_BUYER_SITE}/bidding/request/${request_number}/${id}`,
      "_blank"
    );

  const formattedCreateDate = (timestamp) =>
    new Intl.DateTimeFormat("id-ID", {
      dateStyle: "medium",
    }).format(new Date(timestamp * 1000));

  return (
    <Flex flexDirection="column" gap="1.5rem">
      {data?.quotation_accepted !== null && (
        <Flex
          key={data?.quotation_accepted?.request_number}
          gap="24px"
          backgroundColor="white"
          borderRadius={8}
          py={16}
          px={24}
          boxShadow="0px 4px 4px rgba(20, 20, 32, 0.05)"
          flexDirection="column"
        >
          {isLoading && <Spinner />}
          <Flex flexDirection="column" gap=".5rem">
            <Text weight="bold" variant="subtitle" color="black100">
              Penawaran Diterima
            </Text>
            <Flex gap=".5rem">
              <Text>{data?.quotation_accepted?.request_number}</Text>
              {statusVariant(data?.quotation_accepted?.quotation_status_remark)}
              {data?.quotation_accepted && (
                <Text>
                  {formattedCreateDate(
                    data?.quotation_accepted?.quotation_created_date
                  )}
                </Text>
              )}
            </Flex>
            <Text>{data?.quotation_accepted?.quotation_remark}</Text>
          </Flex>
          {data?.quotation_accepted?.products?.map((product) => (
            <Flex flexDirection="column" key={product.product_sku.id}>
              <Flex gap="1.5rem">
                <Flex
                  padding="1rem"
                  borderRadius=".5rem"
                  borderColor="white"
                  border="1px solid"
                  boxShadow="0rem 0rem 0.25rem rgba(20, 20, 32, 0.16)"
                  gap=".5rem"
                  alignItems="center"
                  width="60%"
                  height="94px"
                >
                  <Box
                    alt="document-image"
                    as="img"
                    src={product.product_sku.image_url}
                    width="96px"
                    height="96px"
                    borderRadius={8}
                  />
                  <Flex justifyContent="space-between" width="100%">
                    <Flex flexDirection="column">
                      <Text>{product.product_sku.name}</Text>
                    </Flex>
                    <Button
                      variant="secondary"
                      onClick={() => onSeeProduct(product.quotation_product.id)}
                    >
                      Lihat Produk
                    </Button>
                  </Flex>
                </Flex>
                <Flex
                  padding="1.5rem 1rem"
                  borderRadius=".5rem"
                  borderColor="black10"
                  border="1px solid"
                  flexDirection="column"
                  width="35%"
                  gap=".25rem"
                >
                  <Flex>
                    <Text width="60%" variant="caption">
                      Harga Satuan
                    </Text>
                    <Text>
                      {product.quotation_product.formatted_price_per_unit}
                    </Text>
                  </Flex>
                  <Flex>
                    <Text width="60%" variant="caption">
                      Kuantitas Produk
                    </Text>
                    <Text variant="caption">
                      {product.quotation_product.quantity} Unit
                    </Text>
                  </Flex>
                  <Flex>
                    <Text width="60%" variant="caption">
                      Total Harga Produk
                    </Text>
                    <Text variant="caption">
                      {product.quotation_product.formatted_price}
                    </Text>
                  </Flex>
                  <Divider marginY=".5rem" />
                  <Flex>
                    <Text width="60%" variant="caption">
                      Biaya Pengiriman
                    </Text>
                    <Text variant="caption">
                      {product.quotation_product.formatted_shipping_price}
                    </Text>
                  </Flex>
                  <Flex>
                    <Text width="60%" variant="caption">
                      Estimasi Pengiriman
                    </Text>
                    <Text variant="caption">
                      {product.quotation_product.shipping_estimated} Hari
                    </Text>
                  </Flex>
                  <Divider marginY=".5rem" />
                  <Flex>
                    <Text width="60%">Total Harga Penawaran</Text>
                    <Text weight="bold">
                      {product.quotation_product.formatted_total_price}
                    </Text>
                  </Flex>
                </Flex>
              </Flex>
              <Divider marginTop="2rem" marginBottom="1rem" />
            </Flex>
          ))}
        </Flex>
      )}
      {data?.quotation_incoming !== null && (
        <Flex
          key={data?.quotation_incoming?.request_number}
          gap="24px"
          backgroundColor="white"
          borderRadius={8}
          py={16}
          px={24}
          boxShadow="0px 4px 4px rgba(20, 20, 32, 0.05)"
          flexDirection="column"
        >
          {isLoading && <Spinner />}
          <Flex flexDirection="column" gap=".5rem">
            <Text weight="bold" variant="subtitle" color="black100">
              Penawaran Saya
            </Text>
            <Flex gap=".5rem">
              <Text>{data?.quotation_incoming?.request_number}</Text>
              {statusVariant(data?.quotation_incoming?.quotation_status_remark)}
              {data?.quotation_incoming && (
                <Text>
                  {formattedCreateDate(
                    data?.quotation_incoming?.quotation_created_date
                  )}
                </Text>
              )}
            </Flex>
            <Text>{data?.quotation_incoming?.quotation_remark}</Text>
          </Flex>
          {data?.quotation_incoming?.products?.map((product) => (
            <Flex flexDirection="column" key={product.product_sku.id}>
              <Flex gap="1.5rem">
                <Flex
                  padding="1rem"
                  borderRadius=".5rem"
                  borderColor="white"
                  border="1px solid"
                  boxShadow="0rem 0rem 0.25rem rgba(20, 20, 32, 0.16)"
                  gap=".5rem"
                  alignItems="center"
                  width="60%"
                  height="94px"
                >
                  <Box
                    alt="document-image"
                    as="img"
                    src={product.product_sku.image_url}
                    width="96px"
                    height="96px"
                    borderRadius={8}
                  />
                  <Flex justifyContent="space-between" width="100%">
                    <Flex flexDirection="column">
                      <Text>{product.product_sku.name}</Text>
                    </Flex>
                    <Button
                      variant="secondary"
                      onClick={() => onSeeProduct(product.quotation_product.id)}
                    >
                      Lihat Produk
                    </Button>
                  </Flex>
                </Flex>
                <Flex
                  padding="1.5rem 1rem"
                  borderRadius=".5rem"
                  borderColor="black10"
                  border="1px solid"
                  flexDirection="column"
                  width="35%"
                  gap=".25rem"
                >
                  <Flex>
                    <Text width="60%" variant="caption">
                      Harga Satuan
                    </Text>
                    <Text>
                      {product.quotation_product.formatted_price_per_unit}
                    </Text>
                  </Flex>
                  <Flex>
                    <Text width="60%" variant="caption">
                      Kuantitas Produk
                    </Text>
                    <Text variant="caption">
                      {product.quotation_product.quantity} Unit
                    </Text>
                  </Flex>
                  <Flex>
                    <Text width="60%" variant="caption">
                      Total Harga Produk
                    </Text>
                    <Text variant="caption">
                      {product.quotation_product.formatted_price}
                    </Text>
                  </Flex>
                  <Divider marginY=".5rem" />
                  <Flex>
                    <Text width="60%" variant="caption">
                      Biaya Pengiriman
                    </Text>
                    <Text variant="caption">
                      {product.quotation_product.formatted_shipping_price}
                    </Text>
                  </Flex>
                  <Flex>
                    <Text width="60%" variant="caption">
                      Estimasi Pengiriman
                    </Text>
                    <Text variant="caption">
                      {product.quotation_product.shipping_estimated} Hari
                    </Text>
                  </Flex>
                  <Divider marginY=".5rem" />
                  <Flex>
                    <Text width="60%">Total Harga Penawaran</Text>
                    <Text weight="bold">
                      {product.quotation_product.formatted_total_price}
                    </Text>
                  </Flex>
                </Flex>
              </Flex>
              <Divider marginTop="2rem" marginBottom="1rem" />
            </Flex>
          ))}
        </Flex>
      )}
    </Flex>
  );
};
