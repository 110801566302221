import { Button, Flex, Label, Text } from "renos-ui";

const Card = ({
  template,
  setIdHover,
  idHover,
  idGet,
  handleOpen,
  setDataSave,
  onOpenTemplate,
}) => {
  return (
    <Flex
      gap="16px"
      borderRadius="8px"
      boxShadow="0px 4px 4px 0px #1414200D"
      width="290px"
      height="553px"
      flexDirection="column"
      onMouseEnter={() => {
        setIdHover(template.id);
        setDataSave(template);
      }}
      onMouseLeave={() => setIdHover("")}
    >
      <Flex
        as="img"
        alt="img-preview"
        width="290px"
        height="365px"
        src={template.preview_image}
      />

      {template.id === idHover ? (
        <Flex
          height="172px"
          flexDirection="column"
          gap="16px"
          px="16px"
          justifyContent="center"
        >
          <Button size="medium" isBlock onClick={handleOpen}>
            {template.id === idGet ? "Tetap Gunakan" : "Gunakan Template"}
          </Button>
          <Button
            size="medium"
            variant="tertiary"
            isBlock
            onClick={onOpenTemplate}
          >
            Lihat Template
          </Button>
        </Flex>
      ) : (
        <Flex height="172px" flexDirection="column" gap="4px" p="10px">
          <Flex gap="4px">
            <Text weight="bold" color="black75">
              {template.name}
            </Text>
            {template.id === idGet && (
              <Label variant="warning">Sedang Digunakan </Label>
            )}
          </Flex>
          <Text color="black50">{template.description}</Text>
        </Flex>
      )}
    </Flex>
  );
};

export default Card;
