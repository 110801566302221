import { Box, Disclosure } from "renos-ui";
import styled, { keyframes } from "styled-components";
import { Swiper } from "swiper/react";

const storeIconAnimation = keyframes`
    100% {
      bottom: 44px;
    }
`;

export const LandingScrollIcon = styled(Box)`
  transform: translate(-50%, -50%);
  position: absolute;
  z-index: 1000;
  bottom: 24px;
  left: 50%;
  animation: ${storeIconAnimation} 1s alternate infinite;
`;

export const StyledDisclosure = styled(Disclosure)`
  width: 100%;
`;

export const StyledSwiper = styled(Swiper)`
  .swiper-wrapper {
    position: relative;
    width: 768px;
    div {
      box-sizing: border-box;
    }
  }
`;
