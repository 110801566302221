import { Flex, Box, Checkbox, Text, Label, Icon, useToast } from "renos-ui";
import { useMemo } from "react";
import { goToUrl } from "helpers";
import ENDPOINTS from "constants/endpoints";

const Header = ({ data, handleIsChecked, handleOnCheck }) => {
  const toast = useToast();

  const showInvoice = () => {
    goToUrl(
      `${process.env.REACT_APP_API}/${ENDPOINTS.order_bulk_invoice}?order_id=${data.order_id}`,
      "_blank"
    );
    toast({
      label: `Invoice ${data.order_number} berhasil dicetak`,
      placement: "top",
    });
  };

  const isShowTimerText = useMemo(() => {
    switch (data.order_status_id) {
      case 2:
        return "Respon Sebelum";
      case 3:
        return "Kirim Sebelum";
      case 5:
        return "Selesai Otomatis";
      default:
        return "";
    }
  }, [data]);

  const isShowTimer = useMemo(() => {
    let labelStatus = "";

    switch (data.order_status_id) {
      case 2:
        labelStatus = "warning";
        break;
      case 3:
        labelStatus = "error";
        break;
      case 5:
        labelStatus = "gray";
        break;
      default:
        break;
    }

    if (labelStatus === "gray")
      return (
        <Label preffix={<Icon size={10} name="Time-outline" />}>
          {data.formatted_order_expired_date}
        </Label>
      );

    if (labelStatus)
      return (
        <Label
          preffix={<Icon size={10} name="Time-outline" />}
          variant={labelStatus}
        >
          {data.formatted_order_expired_date}
        </Label>
      );

    return "";
  }, [data]);

  const badgeColor = useMemo(() => {
    switch (data.order_status_id) {
      case 2:
      case 3:
      case 4:
        return "yellow50";
      case 7:
        return "red50";
      case 5:
        return "blue50";
      case 6:
        return "black50";
      default:
        return "";
    }
  }, [data]);

  const orderTypeColor = useMemo(() => {
    switch (data.order_type_id) {
      case 1:
        return {
          text: "black50",
          background: "black5",
        };
      case 2:
        return {
          text: "blue50",
          background: "blue5",
        };
      default:
        return {};
    }
  }, [data]);

  return (
    <Flex
      position="relative"
      justifyContent="space-between"
      padding="16px 24px"
    >
      <Box
        position="absolute"
        width="12px"
        height="28px"
        borderRadius="2px 10px 10px 2px"
        backgroundColor={badgeColor}
        left="0"
        top="14px"
      />
      <Flex gap="8px" alignItems="center">
        <Checkbox
          checked={() => handleIsChecked(data.order_id)}
          onChange={() => handleOnCheck(data)}
        />
        <Flex
          gap="2px"
          p="2px 4px"
          alignItems="center"
          backgroundColor={orderTypeColor?.background}
          borderRadius="4px"
        >
          <Flex
            as="img"
            alt="icon"
            src={data?.order_type_icon_url}
            width="16px"
            height="16px"
          />
          <Text variant="caption" weight="bold" color={orderTypeColor?.text}>
            {data?.order_type_name}
          </Text>
        </Flex>
        <Text weight="bold" color="black75">
          {data.order_status_name}
        </Text>
        <Box
          backgroundColor="black10"
          width="4px"
          height="4px"
          borderRadius="50%"
        />
        <Box cursor="pointer" onClick={showInvoice}>
          <Text color="blue50">{data.order_number}</Text>
        </Box>
        <Box
          backgroundColor="black10"
          width="4px"
          height="4px"
          borderRadius="50%"
        />
        <Text color="black50">{data.formatted_order_date}</Text>
      </Flex>

      {data?.store_order_shipment.retry_booking_possible ? (
        <Flex gap="8px" alignItems="center">
          <Label variant="error">Gagal pickup</Label>
        </Flex>
      ) : (
        <Flex gap="8px" alignItems="center">
          <Text color="black50">{isShowTimerText}</Text>
          {isShowTimer}
        </Flex>
      )}
    </Flex>
  );
};

export default Header;
