import { getDiscountAmount, getPercentage } from "./useCustomHeader";

export const onCheckAllClicked = ({
  checkedProducts,
  dataTable,
  setCheckedProducts,
}) => {
  let tempCheckedProducts = checkedProducts;
  const totalChecked = findTotalChecked(checkedProducts);

  if (totalChecked < dataTable.length) {
    // eslint-disable-next-line
    dataTable.map((product) => {
      if (!isChecked(product.product_id, false, false, checkedProducts)) {
        tempCheckedProducts.push(product);
      }

      tempCheckedProducts = tempCheckedProducts.map((product) => ({
        ...product,
        product_skus: product.product_skus.map((skus) => ({
          ...skus,
          isChecked: true,
        })),
      }));
    });
  } else {
    tempCheckedProducts = [];
  }

  setCheckedProducts([...tempCheckedProducts]);
};

export const findTotalChecked = (listChecked, isNotEqual) => {
  let totalChecked = 0;
  // eslint-disable-next-line
  listChecked.map((product) => {
    if (product.isHasMore) {
      if (isNotEqual) {
        totalChecked += product.product_skus.filter(
          (filterSku) => filterSku.isChecked
        ).length;
      } else {
        if (
          product?.product_skus.filter((filterSKU) => filterSKU.isChecked)
            .length === product.product_skus.length
        )
          totalChecked++;
      }
    } else {
      totalChecked++;
    }
  });

  return totalChecked;
};

export const isChecked = (id, isVariant, productSKUId, checkedProducts) => {
  const listOfId = checkedProducts.map((product) => product.product_id);

  if (isVariant) {
    let isVariantChecked = false;
    // eslint-disable-next-line
    checkedProducts.map((product) => {
      if (product.product_id === id) {
        // eslint-disable-next-line
        product?.product_skus.map((productSKU) => {
          if (productSKU.product_sku_id === productSKUId) {
            isVariantChecked = productSKU.isChecked;
          }
        });
      }
    });

    return isVariantChecked;
  }

  return listOfId.includes(id);
};

export const onCheckProductClicked = (
  dataProduct,
  isVariant,
  checkedProducts,
  setCheckedProducts,
  defaultData
) => {
  let tempCheckedProducts = checkedProducts;

  if (isVariant && !dataProduct.isHasMore) {
    if (!isChecked(dataProduct.product_id, false, false, checkedProducts)) {
      tempCheckedProducts = defaultData
        .filter(
          (filterProduct) => filterProduct.product_id === dataProduct.product_id
        )
        .concat(tempCheckedProducts);
    }

    let isRemove = false;

    tempCheckedProducts = tempCheckedProducts.map((product) => {
      const checkSKU = product.product_skus.map((sku) => {
        return {
          ...sku,
          isChecked:
            dataProduct.product_sku_id === sku.product_sku_id
              ? !sku.isChecked
              : sku.isChecked,
        };
      });

      if (
        product.product_id === dataProduct.product_id &&
        checkSKU.filter((sku) => sku.isChecked).length === 0
      )
        isRemove = true;

      return {
        ...product,
        product_skus: checkSKU,
      };
    });

    if (isRemove)
      tempCheckedProducts = tempCheckedProducts.filter(
        (product) => product.product_id !== dataProduct.product_id
      );
  } else if (dataProduct.isHasMore) {
    if (!isChecked(dataProduct.product_id, false, false, checkedProducts)) {
      tempCheckedProducts = defaultData
        .filter(
          (filterProduct) => filterProduct.product_id === dataProduct.product_id
        )
        .map((product) => ({
          ...product,
          product_skus: product.product_skus.map((sku) => ({
            ...sku,
            isChecked: true,
          })),
        }))
        .concat(tempCheckedProducts);
    } else {
      const selectedProduct = tempCheckedProducts.filter(
        (filterProduct) => filterProduct.product_id === dataProduct.product_id
      )[0];

      if (
        selectedProduct.product_skus.filter((filterSku) => filterSku.isChecked)
          .length < selectedProduct.product_skus.length
      ) {
        tempCheckedProducts = tempCheckedProducts.map((product) => ({
          ...product,
          product_skus:
            dataProduct.product_id === product.product_id
              ? product.product_skus.map((sku) => ({
                  ...sku,
                  isChecked: true,
                }))
              : product.product_skus,
        }));
      } else {
        tempCheckedProducts = tempCheckedProducts.filter(
          (filterProduct) => filterProduct.product_id !== dataProduct.product_id
        );
      }
    }
  } else {
    if (isChecked(dataProduct.product_id, false, false, checkedProducts)) {
      tempCheckedProducts = tempCheckedProducts.filter(
        (productFilter) => productFilter.product_id !== dataProduct.product_id
      );
    } else {
      tempCheckedProducts.push(dataProduct);
    }
  }

  setCheckedProducts([...tempCheckedProducts]);
};

export const handleChangeInput = (
  newValue,
  propertyName,
  productId,
  isVariant,
  productSKUId,
  dataError,
  setDataError,
  defaultData,
  setDefaultData
) => {
  const tempError = dataError;
  const tempData = defaultData.map((product, productIndex) => {
    const tempProduct = product;

    if (tempProduct.product_id === productId) {
      if (isVariant) {
        tempProduct.product_skus = tempProduct.product_skus.map(
          (productsku, skuIndex) => {
            const tempProductSKU = {
              ...productsku,
              [propertyName]:
                productsku.product_sku_id === productSKUId
                  ? propertyName === "isActive"
                    ? newValue
                    : newValue || "0"
                  : productsku[propertyName],
            };

            if (productsku.product_sku_id === productSKUId) {
              if (propertyName === "product_discount_percentage") {
                tempProductSKU.product_discount_amount = getDiscountAmount(
                  newValue,
                  productsku.product_price
                );
                tempError[productIndex].product_skus[
                  skuIndex
                ].product_discount_percentage = false;
                tempError[productIndex].product_skus[
                  skuIndex
                ].product_discount_amount = false;
              }

              if (propertyName === "product_discount_amount") {
                tempProductSKU.product_discount_percentage = getPercentage(
                  newValue,
                  productsku.product_price
                );
              }

              if (propertyName === "product_discount_quota")
                tempError[productIndex].product_skus[
                  skuIndex
                ].product_discount_quota = false;

              if (propertyName === "period") {
                tempProductSKU.product_discount_date_start =
                  newValue.product_discount_date_start;
                tempProductSKU.product_discount_date_end =
                  newValue.product_discount_date_end;

                tempError[productIndex].product_skus[
                  skuIndex
                ].product_discount_date_start = false;
                tempError[productIndex].product_skus[
                  skuIndex
                ].product_discount_date_end = false;
              }

              if (propertyName === "isActive") {
                tempError[productIndex].product_skus[skuIndex] = {
                  product_discount_date_start: false,
                  product_discount_date_end: false,
                  product_discount_percentage: false,
                  product_discount_amount: false,
                  product_discount_quota: false,
                };
              }
            }

            return tempProductSKU;
          }
        );
      } else {
        tempProduct[propertyName] =
          propertyName === "isActive" ? newValue : newValue || "0";

        if (propertyName === "product_discount_percentage_start") {
          tempProduct.product_discount_amount_start = getDiscountAmount(
            newValue,
            product.product_price_start
          );
          tempError[productIndex].product_discount_amount_start = false;
          tempError[productIndex].product_discount_percentage_start = false;
        }

        if (propertyName === "product_discount_amount_start") {
          tempProduct.product_discount_percentage_start = getPercentage(
            newValue,
            product.product_price_start
          );
          tempError[productIndex].product_discount_amount_start = false;
          tempError[productIndex].product_discount_percentage_start = false;
        }

        if (propertyName === "product_discount_quota")
          tempError[productIndex].product_discount_quota = false;

        if (propertyName === "period") {
          tempProduct.product_discount_date_start =
            newValue.product_discount_date_start;
          tempProduct.product_discount_date_end =
            newValue.product_discount_date_end;

          tempError[productIndex].product_discount_date_start = false;
          tempError[productIndex].product_discount_date_end = false;
        }
      }
    }

    return tempProduct;
  });

  setDataError([...tempError]);
  setDefaultData([...tempData]);
};

export const parentVariantIsChecked = (
  productId,
  isIndeterminate,
  checkedProducts
) => {
  const listOfId = checkedProducts.map((product) => product.product_id);

  if (listOfId.includes(productId)) {
    const selectedProduct = checkedProducts.filter(
      (filterProduct) => filterProduct.product_id === productId
    );
    const filteredCheck = selectedProduct[0].product_skus.filter(
      (filterSku) => filterSku.isChecked
    );

    if (isIndeterminate) {
      return (
        filteredCheck.length < selectedProduct[0].product_skus.length &&
        filteredCheck.length !== 0
      );
    }

    return filteredCheck.length === selectedProduct[0].product_skus.length;
  }

  return false;
};

export const handleDelete = ({
  productId,
  dataTable,
  setDataTable,
  defaultData,
  setDefaultData,
  history,
  dataError,
  setDataError,
}) => {
  const indexError = dataTable.map((e) => e.product_id).indexOf(productId);
  const tempDataError = dataError;

  if (indexError > -1) {
    tempDataError.splice(indexError, 1);
  }

  setDataError(tempDataError);

  const tempDataTable = dataTable.filter(
    (dataFilter) => dataFilter.product_id !== productId
  );
  setDataTable([...tempDataTable]);

  const tempDefaultData = defaultData.filter(
    (dataFilter) => dataFilter.product_id !== productId
  );
  setDefaultData([...tempDefaultData]);

  if (tempDefaultData.length === 0) history.goBack();
};
