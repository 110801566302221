import {
  Box,
  Button,
  Checkbox,
  Flex,
  Icon,
  Input,
  Text,
  Toggle,
  Tooltip,
} from "renos-ui";
import PeriodPicker from "./PeriodPicker";
import { useState } from "react";
import {
  dataApplyFormatter,
  handleChangeInput,
} from "../hooks/useCustomHeader";

const CustomHeader = ({
  selected,
  isCheckedAll,
  onCheckAllClicked,
  isIndeterminate,
  uncheckAll,
  setDefaultData,
  defaultData,
}) => {
  const [value, setValue] = useState({
    date_start: "",
    date_end: "",
    quota: "",
    amount: "",
    percentage: "",
    isActive: true,
  });

  const handleReset = () => {
    setValue({
      date_start: "",
      date_end: "",
      quota: "",
      amount: "",
      percentage: "",
      isActive: true,
    });
  };

  const handleApply = () => {
    const tempData = dataApplyFormatter({ selected, defaultData, value });

    setDefaultData([...tempData]);
  };

  return (
    <Flex flexDirection="column" gap="16px" margin="0px -12px">
      <Flex gap="24px" alignItems="center">
        <Flex gap="14px" alignItems="center">
          <Checkbox
            checked={isCheckedAll}
            onChange={onCheckAllClicked}
            indeterminate={isIndeterminate}
          />
          <Text color="black75">
            <b>{selected.length}</b> /{defaultData.length} Produk Dipilih
          </Text>
        </Flex>

        <Button size="medium" variant="tertiary" onClick={uncheckAll}>
          Batal
        </Button>
      </Flex>
      <Flex
        justifyContent="space-between"
        alignItems="center"
        padding="24px"
        borderWidth="1px"
        borderColor="black10"
        borderStyle="solid"
        borderRadius="16px"
      >
        <Flex flexDirection="column" gap="16px">
          <Flex alignItems="start">
            <Text color="black75">Atur Diskon Sekaligus</Text>
          </Flex>
          <Flex gap="24px">
            <Flex
              flexDirection="column"
              gap="14px"
              width="72px"
              alignItems="start"
            >
              <Text weight="bold" color="black50">
                Diskon
              </Text>
              <Input.Number
                max={90}
                disabled={value.amountActive}
                size="small"
                onChange={(e) =>
                  handleChangeInput(e, "percentage", value, setValue)
                }
                value={value.percentage}
                sufix={
                  <Text color="black50" weight="bold">
                    %
                  </Text>
                }
              />
            </Flex>
            <Flex flexDirection="column" gap="14px" width="164px">
              <Flex gap="4px" alignItems="start">
                <Text color="black50" weight="bold">
                  Harga Diskon
                </Text>
                <Tooltip
                  label={
                    <Box textAlign="center">
                      <Text variant="small" color="black75">
                        Harga produk setelah <br /> dipotong diskon.
                      </Text>
                    </Box>
                  }
                >
                  <Icon name="Info-solid" size={16} color="black50" />
                </Tooltip>
              </Flex>
              <Input.Number
                onChange={(e) =>
                  handleChangeInput(e, "amount", value, setValue)
                }
                // disabled={value.percentageActive} temporary
                disabled={true}
                value={value.amount}
                size="small"
                prefix={
                  <Text color="black50" weight="bold">
                    Rp
                  </Text>
                }
              />
            </Flex>
            <Flex flexDirection="column" gap="14px" width="64px">
              <Flex gap="4px" alignItems="start">
                <Text color="black50" weight="bold">
                  Kuota
                </Text>
                <Tooltip
                  label={
                    <Box textAlign="center">
                      <Text variant="small" color="black75">
                        Jumlah stok produk yang <br /> diberikan diskon.
                      </Text>
                    </Box>
                  }
                >
                  <Icon name="Info-solid" size={16} color="black50" />
                </Tooltip>
              </Flex>
              <Input.Number
                onChange={(e) => handleChangeInput(e, "quota", value, setValue)}
                value={value.quota}
                size="small"
                max={9999}
              />
            </Flex>
            <Flex flexDirection="column" gap="14px" width="140px">
              <Flex gap="4px" alignItems="start">
                <Text color="black50" weight="bold">
                  Periode Diskon
                </Text>
                <Tooltip
                  label={
                    <Box textAlign="center">
                      <Text variant="small" color="black75">
                        Atur waktu mulai dan <br /> berakhirnya diskon.
                      </Text>
                    </Box>
                  }
                >
                  <Icon name="Info-solid" size={16} color="black50" />
                </Tooltip>
              </Flex>
              <PeriodPicker
                value={{
                  product_discount_date_start: value.date_start,
                  product_discount_date_end: value.date_end,
                }}
                onChange={(e) => handleChangeInput(e, "date", value, setValue)}
              />
            </Flex>
            <Flex flexDirection="column" gap="14px">
              <Flex gap="4px" alignItems="start">
                <Text color="black50" weight="bold">
                  Status
                </Text>
                <Tooltip
                  label={
                    <Box textAlign="center">
                      <Text variant="small" color="black75">
                        Atur untuk aktif/ <br /> nonaktifkan promo <br /> pada
                        produk.
                      </Text>
                    </Box>
                  }
                >
                  <Icon name="Info-solid" size={16} color="black50" />
                </Tooltip>
              </Flex>

              <Flex height="40px" alignItems="center" justifyContent="center">
                <Flex
                  onClick={(e) =>
                    handleChangeInput(
                      !value.isActive,
                      "isActive",
                      value,
                      setValue
                    )
                  }
                >
                  <Toggle checked={value.isActive} />
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Flex>

        <Flex gap="16px">
          <Button variant="tertiary" size="medium" onClick={handleReset}>
            Reset
          </Button>
          <Button variant="secondary" size="medium" onClick={handleApply}>
            Terapkan Pengaturan
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default CustomHeader;
