import React, { useState, useContext } from "react";
import { Button, Flex, Text, Drawer, useToast, useDisclosure } from "renos-ui";
import StoreContext from "providers/StoreProvider";
import {
  useGetStoreClose,
  useUpdateCloseStore,
  useOpenStoreNow,
} from "services/settings/status";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import duration from "dayjs/plugin/duration";
import OpenStore from "./OpenStore";
import ClosedStore from "./CloseStore";
import { setGTM } from "helpers";
import LoadingStore from "./LoadingStore";
import { useGetStoreDetail } from "services/store";

dayjs.extend(localizedFormat);
dayjs.extend(duration);

const CloseStore = () => {
  const toast = useToast();
  const {
    storeData: {
      data: { id: storeId, has_product },
    },
  } = useContext(StoreContext);

  const { refetch: refetchGetStoreDetail } = useGetStoreDetail();

  const [isEdit, setIsEdit] = useState(true);
  const [checkedClose, seCheckedClose] = useState(false);
  const showConfirmation = useDisclosure({ open: false });
  const [datas, setDatas] = useState({
    date_end: "",
    date_start: "",
    notes: "",
  });
  const {
    data: storeClose,
    refetch: refetchGetStore,
    isLoading: isLoadingData,
  } = useGetStoreClose(
    {
      store_id: storeId,
      is_active: true,
    },
    {
      onSuccess: (data) => {
        if (data.length === 0) {
          setDatas({
            date_end: null,
            date_start: null,
          });
        } else {
          setIsEdit(true);
          setDatas({
            id: data[0]?.id,
            date_end: data[0]?.date_end,
            date_start: data[0]?.date_start,
            notes: data[0]?.notes,
            is_closed: data[0]?.is_closed,
          });
        }
        refetchGetStoreDetail();
      },
    }
  );

  const { mutate: storeCloseSchedule } = useUpdateCloseStore({
    onError: (error) => {
      const {
        response: {
          data: { frontend_message: message },
        },
      } = error;
      toast({
        label: message | "Toko gagal ditutup.",
        placement: "bottom",
        backgroundColor: "red50",
      });
    },
    onSuccess: () => {
      toast({
        label: !checkedClose
          ? "Berhasil ubah jadwal toko"
          : "Toko berhasil ditutup.",
        placement: "bottom",
      });
      showConfirmation.onClose();
      setIsEdit(!isEdit);
      refetchGetStore();
      refetchGetStoreDetail();
    },
  });

  const { mutate: storeOpen } = useOpenStoreNow({
    onError: (error) => {
      const {
        response: {
          data: { frontend_message: message },
        },
      } = error;
      toast({
        label: message | "Toko gagal dibuka.",
        placement: "bottom",
        backgroundColor: "red50",
      });
    },
    onSuccess: () => {
      toast({
        label: "Toko berhasil dibuka kembali.",
        placement: "bottom",
      });
      showConfirmation.onClose();
      setIsEdit(!isEdit);
      seCheckedClose(false);
      refetchGetStore();
    },
  });

  const submitUpdate = () => {
    handleGTM("set_holiday_dates | close_shop");
    const data = {
      date_end: dayjs(datas.date_end).format("YYYY-MM-DD"),
      date_start: dayjs(datas.date_start).format("YYYY-MM-DD"),
      notes: datas.notes,
    };
    storeCloseSchedule({ data });
  };

  const submitStoreOpen = () => storeOpen();

  const listLength = storeClose?.length > 0;

  const handleGTM = (label) => {
    setGTM("seller_center_section", {
      event_category: "store_settings | status",
      event_action: "click",
      event_label: label,
    });
  };

  return (
    <Flex flexDirection="column" gap="24px">
      <Flex flexDirection="column">
        {listLength ? (
          isLoadingData ? (
            <LoadingStore />
          ) : (
            <OpenStore
              datas={datas}
              setDatas={setDatas}
              checkedClose={checkedClose}
              seCheckedClose={seCheckedClose}
              openDialog={() => showConfirmation.onOpen()}
              submitStoreOpen={submitStoreOpen}
              isEdit={isEdit}
              submitUpdate={submitUpdate}
              setIsEdit={setIsEdit}
            />
          )
        ) : (
          <>
            {isLoadingData ? (
              <LoadingStore />
            ) : (
              <ClosedStore
                datas={datas}
                setDatas={setDatas}
                checkedClose={checkedClose}
                seCheckedClose={seCheckedClose}
                openDialog={() => showConfirmation.onOpen()}
                has_product={has_product}
              />
            )}
          </>
        )}
      </Flex>

      <Drawer
        isVisible={showConfirmation.isOpen}
        onClose={showConfirmation.onClose}
        placement="bottom"
        closable={true}
      >
        <Flex
          width="100%"
          fontFamily="sans-serif"
          flexDirection="column"
          rowGap="24px"
        >
          <Flex flexDirection="column" rowGap="8px" flexWrap="wrap">
            <Text weight="bold">
              {storeClose && storeClose.length === 0
                ? "Terapkan Perubahan?"
                : "Buka Toko"}
            </Text>
            <Flex width="300px">
              <Text variant="caption">
                {storeClose && storeClose.length === 0
                  ? "Tanggal buka tutup toko akan diperbaharui"
                  : "Buka toko sekarang?"}
              </Text>
            </Flex>
          </Flex>
          <Flex flexDirection="row" justifyContent="space-between" gap="16px">
            <Button
              isBlock
              size="medium"
              variant="tertiary"
              onClick={() => {
                showConfirmation.onClose();
              }}
            >
              Batal
            </Button>
            {storeClose && storeClose.length === 0 && (
              <Button
                isBlock
                size="medium"
                variant="primary"
                onClick={submitUpdate}
              >
                Ya, Terapkan
              </Button>
            )}
            {listLength && (
              <Button
                isBlock
                size="medium"
                variant="primary"
                onClick={submitStoreOpen}
              >
                Ya, Buka
              </Button>
            )}
          </Flex>
        </Flex>
      </Drawer>
    </Flex>
  );
};

export default CloseStore;
