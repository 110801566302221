import React from "react";
import { Divider, Drawer, Flex, Text, isEmpty } from "renos-ui";

const VariantDrawer = ({
  disclosure,
  variants,
  productVariant,
  activeVariantIndex,
  onVariantChoose,
}) => {
  const { isOpen, onClose } = disclosure;

  return (
    isOpen && (
      <Drawer
        isVisible={isOpen}
        onClose={onClose}
        closable={true}
        placement="bottom"
        header={{
          title: "Pilih Varian",
        }}
      >
        <Flex
          py="32px"
          flexDirection="column"
          rowGap="16px"
          height="70vh"
          overflow="auto"
        >
          {variants?.map((variant) => {
            const activeProductVariant = productVariant[activeVariantIndex];
            const isActive =
              variant.variant_id === activeProductVariant.variant_id;

            const anotherVariant =
              productVariant[activeVariantIndex === 0 ? 1 : 0];

            const isAnotherFilled =
              productVariant.length === 2 && !isEmpty(anotherVariant);
            const anotherValueId = isAnotherFilled && anotherVariant.variant_id;

            return (
              variant.variant_id !== anotherValueId && (
                <Flex
                  flexDirection="column"
                  rowGap="16px"
                  flexShrink={0}
                  onClick={() => onVariantChoose(variant)}
                  key={variant.variant_id}
                >
                  <Text
                    variant="caption"
                    color={isActive ? "blue50" : "black100"}
                    weight={isActive && "bold"}
                  >
                    {variant.variant_name}
                  </Text>
                  <Divider />
                </Flex>
              )
            );
          })}
        </Flex>
      </Drawer>
    )
  );
};

export default VariantDrawer;
