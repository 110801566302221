import styled from "styled-components";
import { Swiper } from "swiper/react";

export const StyledSwiper = styled(Swiper)`
  .swiper-wrapper {
    position: relative;
    div {
      box-sizing: border-box;
    }
    padding-bottom: 1px;

    .swiper-slide {
      width: 128px !important;
    }
  }
  .swiper {
    margin-left: unset;
    margin-right: unset;
  }
`;
