import MobileLayout from "layouts/MobileLayout";
import { Flex, isEmpty, useDisclosure, useToast, useUpload } from "renos-ui";
import CustomHeader from "./CustomHeader";
import { useContext, useEffect, useRef, useState } from "react";
import StoreContext from "providers/StoreProvider";
import { QISCUS, event } from "hooks/qiscus";
import {
  arrMonth,
  blobToBase64,
  getImageMeta,
  getTime,
  getTimestamp,
  imageTypeChecking,
  imgCompressor,
} from "helpers";
import ChatRoomBody from "./ChatRoomBody";
import {
  useLocation,
  useHistory,
} from "react-router-dom/cjs/react-router-dom.min";
import dayjs from "dayjs";
import { useGetUserByQiscusId, useUploadChatImage } from "services/chat";
import { IMAGE_FILE_TYPE } from "containers/web/setting/document/constant";

const ChatRoomItems = () => {
  const toast = useToast();
  const refChat = useRef(null);
  const location = useLocation();
  const {
    qiscusInfo,
    storeData: {
      data: { id: storeId, slug },
    },
  } = useContext(StoreContext);

  const { push, replace } = useHistory();
  const [firstOpen, setFirstOpen] = useState(true);
  const [isTyping, setIsTyping] = useState(false);
  const [textMessage, setTextMessage] = useState("");
  const [emitterStatus, setEmitterStatus] = useState({});
  const [messageList, setMessageList] = useState([]);
  const [isLoadBefore, setIsLoadBefore] = useState(false);
  const [isLoadFirst, setIsLoadFirst] = useState(true);
  const [selectedUser, setSelectedUser] = useState({
    id: location?.state?.selectedUser?.id,
    name: location?.state?.selectedUser?.name,
    avatar: location?.state?.selectedUser?.avatar,
    participants: location?.state?.selectedUser?.participants || [],
    unique_id: location?.state?.selectedUser?.unique_id,
  });
  const attachmentDisclosure = useDisclosure({ isOpen: false });
  const attachProductDisclosure = useDisclosure({ isOpen: false });
  const [seletedAttachProduct, setSelectedAttachProduct] = useState([]);
  const [attachment, setAttachment] = useState({
    qiscusId: location?.state?.qiscusId,
    extras: {
      key: location?.state?.orderNumber ? "ORDER" : "",
      slug,
      storeId,
    },
    payload: {
      type: "custom-text",
      content: {
        orderNumber: location?.state?.orderNumber,
        orderStatus: location?.state?.orderStatus,
        origins: "ORDER",
        productImage: location?.state?.productImage,
        productName: location?.state?.productName,
        totalPrice: location?.state?.totalPrice,
      },
    },
  });
  const [online, setOnline] = useState({
    isOnline: false,
    lastOnline: "",
  });
  const [userLoading, setUserLoading] = useState({
    load: true,
    fail: false,
    isFirst: false,
  });

  const [buyerInfo, setBuyerInfo] = useState({});

  const formatYYYYMMDD = (timestamp) => {
    const fullDate = dayjs(timestamp);
    const formatedDate = fullDate.format("YYYY-MM-DD");

    return formatedDate;
  };

  const groupByDates = (tempDate) => {
    return tempDate.reduce((items, currValue) => {
      const date = formatYYYYMMDD(currValue.timestamp);
      items[date] = items[date] || [];
      items[date].push(currValue);

      return items;
    }, {});
  };

  const msgFormat = (dateTime) => {
    const tempDate = dateTime.map((item) => ({
      ...item,
      dates: formatYYYYMMDD(item.timestamp),
    }));

    const groupedByDates = groupByDates(tempDate);

    return Object.entries(groupedByDates);
  };

  const scrollToBottom = (toId) => {
    const elem = document.getElementById(toId?.toString());

    if (elem) {
      elem.scrollIntoView({
        top: refChat.current.scrollHeight,
        behavior: "auto",
      });
    }
  };

  const sendMessage = async (attachmentImage) => {
    try {
      if (
        !textMessage &&
        !attachmentImage &&
        attachment?.extras?.key !== "PRODUCTS" &&
        attachment?.extras?.key !== "ORDER"
      )
        return;
      const type = "custom";
      let uniqueId = getTimestamp();

      if (
        attachment.qiscusId?.toString() !== selectedUser?.id?.toString() &&
        !attachmentImage
      ) {
        uniqueId = null;
      }
      let tempAttachment = attachment;

      if (attachmentImage) {
        tempAttachment = attachmentImage;
      }

      if (tempAttachment?.extras?.key === "PRODUCTS") {
        tempAttachment = {
          extras: attachment.extras,
          payload: {
            content: {
              origins: attachment.payload.content.origins,
              selectedProducts:
                attachment?.payload?.content?.selectedProducts.map((row) => {
                  return {
                    discount: row?.product_skus[0]?.product_discount_percentage,
                    fullPrice: row?.product_skus[0]?.formatted_product_price,
                    origins: "PRODUCTS",
                    productImage: row?.product_skus[0]?.product_image_url,
                    productName: row?.product_skus[0]?.product_name,
                    productURL: `/p/${slug}/${row?.product_slug}`,
                    sellingPrice: row?.product_skus[0]?.formatted_selling_price,
                    skuId: row?.product_skus[0]?.product_sku_id,
                    slug: row?.product_skus[0]?.product_slug,
                  };
                }),
            },
            type: "custom-text",
          },
          qiscusId: attachment.qiscusId,
        };
      }

      await QISCUS.sendMessage(
        selectedUser.id,
        textMessage,
        uniqueId,
        type,
        tempAttachment.payload,
        tempAttachment.extras
      );

      setAttachment({});
      setSelectedAttachProduct([]);
      setTextMessage("");
      getDetail(selectedUser);
      const locationState = location.state;
      delete locationState.orderNumber;
      delete locationState.orderStatus;
      delete locationState.productImage;
      delete locationState.productName;
      delete locationState.totalPrice;
      delete locationState.qiscusId;
      replace({
        ...location,
        state: locationState,
      });
      attachmentDisclosure.onClose();
    } catch (error) {
      toast(error?.message || "Gagal mengirim pesan, silakan coba lagi nanti.");
    }
  };

  const { mutate: uploadImage } = useUploadChatImage({
    onSuccess: (data) => {
      const attachment = {
        extras: { key: "IMAGE" },
        payload: {
          type: "custom-text",
          content: {
            origins: "IMAGE",
            productImage: data,
          },
        },
      };
      sendMessage(attachment);
    },
  });

  const { register: registerUpload } = useUpload({
    fileType: "image",
    onRequest: async (file) => {
      const deepTypeChecking = await imageTypeChecking(file);

      if (deepTypeChecking) {
        const result = await blobToBase64(file);
        const { naturalHeight, naturalWidth } = await getImageMeta(result);

        if (naturalHeight < 300 || naturalWidth < 300) {
          toast({
            label: "Format gambar tidak sesuai",
            placement: "bottom",
          });

          return null;
        }

        const formData = new FormData();
        const compressed =
          file.size > 400000
            ? await imgCompressor(file, {
                maxSizeMB: 0.4,
                maxWidthOrHeight: 1920,
                useWebWorker: true,
              })
            : file;

        formData.append("file", compressed);
        uploadImage(formData);
      } else {
        toast({
          label: "Format gambar tidak sesuai",
          placement: "bottom",
        });
      }
    },
    validator: (file) => {
      if (!IMAGE_FILE_TYPE.includes(file.type)) {
        toast({
          label: "Format gambar tidak sesuai",
          placement: "bottom",
        });

        return {
          message: "Format gambar tidak sesuai",
        };
      }

      attachmentDisclosure.onClose();

      return null;
    },
  });

  const handleLoadMessage = async () => {
    try {
      if (!isEmpty(selectedUser)) {
        const firstNormalMessage = messageList.normal[0];
        const lastNormalMessage =
          messageList.normal[messageList.normal.length - 1];

        if (
          firstNormalMessage?.comment_before_id ||
          firstNormalMessage?.before_id
        ) {
          const options = {
            last_comment_id: firstNormalMessage.id,
            after: false,
            limit: 20,
          };
          setIsLoadBefore(true);
          const comments = await QISCUS.loadComments(selectedUser.id, options);
          const tempMsg = [...comments, ...messageList.normal];
          const tempMsgFormatted = msgFormat(tempMsg);
          setMessageList(() => ({
            formated: [...tempMsgFormatted],
            normal: [...tempMsg],
          }));
          setIsLoadBefore(false);

          if (isLoadFirst) {
            setTimeout(() => {
              scrollToBottom(lastNormalMessage?.id);
            }, 100);
          } else {
            setTimeout(() => {
              scrollToBottom(tempMsg[comments.length - 1]?.id);
            }, 100);
          }
          setIsLoadFirst(false);
        }
      }
    } catch (error) {
      toast(
        error?.message || "Gagal mendapatkan pesan, silakan coba lagi nanti."
      );
      setIsLoadBefore(false);
    }
  };

  const getDetail = async (data, isStay) => {
    try {
      setSelectedUser(data);
      setOnline({
        isOnline: false,
        lastOnline: "",
      });
      const room = await QISCUS.getRoomById(data.id);
      let tempMsgList = {};

      if (isStay) {
        let tempComment = messageList.normal?.concat(room.comments);
        tempComment = tempComment.filter(
          (currValue, index, items) =>
            items.findIndex((item) => item.id === currValue.id) === index
        );
        tempMsgList = {
          formated: [...msgFormat(tempComment)],
          normal: [...tempComment],
        };
      } else {
        // eslint-disable-next-line prefer-const
        let tempComment = room.comments;
        tempMsgList = {
          formated: [...msgFormat(tempComment)],
          normal: [...tempComment],
        };
      }

      setMessageList({ ...tempMsgList });
      setUserLoading((prev) => ({
        ...prev,
        load: false,
        isFirst: false,
        fail: false,
        page: 1,
      }));

      //   setTimeout(() => {
      //     scrollToBottom(tempMsgList.normal[tempMsgList.normal.length - 1]?.id);
      //   }, 1000);
    } catch (error) {
      //   toast(
      //     error?.message || "Gagal mendapatkan pesan, silakan coba lagi nanti."
      //   );
      setUserLoading({
        ...userLoading,
        load: false,
        isFirst: false,
        fail: true,
        page: 1,
      });
    }
  };

  const onlineStatus = () => {
    const today = new Date();
    let lastSeen = "";

    if (today.toDateString() === online.lastOnline.toDateString()) {
      lastSeen = getTime(online.lastOnline);
    } else {
      lastSeen = `${online.lastOnline.getDate()} ${
        arrMonth[online.lastOnline.getDay]
      } ${
        today.getFullYear() !== online.lastOnline.getFullYear()
          ? today.getFullYear()
          : ""
      }`;
    }

    return lastSeen;
  };

  useEffect(() => {
    const { kind, data } = emitterStatus;

    const handleNewMessage = () => {
      if (!isEmpty(selectedUser)) {
        const filteredParticipants = selectedUser.participants.filter(
          (p) => p.email !== qiscusInfo.userId.toString()
        );
        const filterId =
          filteredParticipants.length !== 0
            ? filteredParticipants[0].email.toString()
            : "";

        if (
          data[0].email === filterId ||
          data[0].email === qiscusInfo.userId.toString()
        ) {
          getDetail(selectedUser, true);
        }
      }
    };

    const handleTyping = () => {
      if (data.username !== qiscusInfo.userId.toString()) {
        const typing = data.message === "1";
        setIsTyping(typing);
      }
    };

    const handleOnlinePresence = () => {
      if (
        !isEmpty(selectedUser) &&
        data.idUser !== qiscusInfo.userId.toString()
      ) {
        const filteredParticipants = selectedUser.participants.filter(
          (participant) => participant.email === data.idUser
        );

        if (filteredParticipants.length !== 0) {
          setOnline({ ...data });
        }
      }
    };

    const handleCommentRead = () => {
      const tempComment = [...messageList.normal]
        .reverse()
        .map((item, index) => {
          let statusCheck = data.comment.id === item.id ? "read" : item.status;

          if (index !== 0) {
            statusCheck =
              messageList.normal[index - 1].status === "read"
                ? "read"
                : statusCheck;
          }

          return { ...item, status: statusCheck };
        })
        .reverse();

      setMessageList({
        formated: [...msgFormat(tempComment)],
        normal: [...tempComment],
      });
    };

    const handleDeliver = () => {
      getDetail(selectedUser, true);
      let tempComment = [];
      tempComment = messageList.normal.map((message) => ({
        ...message,
        status:
          emitterStatus.data.comment.id === message.id
            ? "delivered"
            : message.status,
      }));
      setMessageList({
        formated: [...msgFormat(tempComment)],
        normal: [...tempComment],
      });
    };

    const handleReconnecting = () => {
      QISCUS.login(qiscusInfo);
    };

    const handleLoginSuccess = () => {
      getDetail(selectedUser, false);
    };

    switch (kind) {
      case "new-message":
        handleNewMessage();
        break;
      case "typing":
        handleTyping();
        break;
      case "online-presence":
        handleOnlinePresence();
        break;
      case "comment-read":
        handleCommentRead();
        break;
      case "comment-delivered":
        handleDeliver();
        break;
      case "reconnecting":
        handleReconnecting();
        break;
      case "login-success":
        handleLoginSuccess();
        break;
      default:
        break;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emitterStatus]);
  const { mutate: getUserByQiscusId } = useGetUserByQiscusId({
    onSuccess: ({ data }) => {
      setBuyerInfo(data);
    },
  });

  useEffect(() => {
    event.on("event", function (data) {
      setEmitterStatus((emmit) => ({
        ...emmit,
        kind: data.kind,
        data: data.data,
      }));
    });

    if (firstOpen) {
      setEmitterStatus({
        kind: "reconnecting",
        data: null,
      });
      setFirstOpen(false);

      const qiscusUser = selectedUser?.participants?.find(
        (user) => user.username === selectedUser?.name
      );

      getUserByQiscusId(qiscusUser?.email);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAttachProduct = () => {
    setAttachment({
      qiscusId: selectedUser?.id,
      extras: {
        key: "PRODUCTS",
        slug,
        storeId,
      },
      payload: {
        type: "custom-text",
        content: {
          origins: "PRODUCTS",
          selectedProducts: seletedAttachProduct.map((item) => ({
            ...item,
            store_product_slug: `${slug}/${item.product_slug}`,
            store_sku_name: item.product_name,
            product_title: item.product_name,
          })),
        },
      },
    });
    attachProductDisclosure.onClose();
    attachmentDisclosure.onClose();
  };

  const handleDeleteChat = () => {
    QISCUS.deleteChatRoom(selectedUser.unique_id)
      .then(() => {
        toast({
          label: "Chat berhasil dihapus.",
          placement: "bottom",
        });
        setTimeout(() => {
          push("/chat");
        }, 1000);
      })
      .catch((error) => {
        console.error("Error deleting chat room", error);
      });
  };

  useEffect(() => {
    if (location?.state?.show_attach_popup) {
      attachmentDisclosure.onOpen();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <MobileLayout
      customHeader={
        <CustomHeader
          user={selectedUser}
          online={online}
          typing={isTyping}
          onlineStatus={onlineStatus}
          attachment={attachment}
          handleDeleteChat={handleDeleteChat}
        />
      }
    >
      <Flex flexDirection="column" gap="16px">
        {!userLoading.load && (
          <ChatRoomBody
            loadBefore={isLoadBefore}
            messageList={messageList}
            qiscusInfo={qiscusInfo}
            getTime={getTime}
            refChat={refChat}
            textMessage={textMessage}
            setTextMessage={setTextMessage}
            sendMessage={sendMessage}
            handleLoadMessage={handleLoadMessage}
            attachment={attachment}
            setAttachment={setAttachment}
            selectedUser={selectedUser}
            registerUpload={registerUpload}
            attachmentDisclosure={attachmentDisclosure}
            attachProductDisclosure={attachProductDisclosure}
            seletedAttachProduct={seletedAttachProduct}
            setSelectedAttachProduct={setSelectedAttachProduct}
            handleAttachProduct={handleAttachProduct}
            locationState={location.state}
            buyerInfo={buyerInfo}
          />
        )}
      </Flex>
    </MobileLayout>
  );
};

export default ChatRoomItems;
