import ENDPOINTS from "constants/endpoints";
import { useMutation, useQuery } from "react-query";
import client from "../../client";

const getHomePageFetcher = async (params) => {
  const { data } = await client.get(ENDPOINTS.homepage, params);

  return data;
};

export const useGetHomePage = (params, extraParams) => {
  return useQuery(["getHomePage"], () => getHomePageFetcher(params), {
    ...extraParams,
  });
};

const updateHomePageFetcher = async (body) => {
  const response = await client.post(ENDPOINTS.homepage, body);

  return response.data;
};

export const useUpdateHomePage = (extraParams) =>
  useMutation((body) => updateHomePageFetcher(body), {
    ...extraParams,
  });

const applyHomepage = async () => {
  const response = await client.get(ENDPOINTS.apply_homepage);

  return response.data;
};

export const useApplyHomepage = (extraParams) =>
  useMutation(() => applyHomepage(), {
    ...extraParams,
  });

const getHomePageStatus = async (params) => {
  const { data } = await client.get(ENDPOINTS.homepage_status, params);

  return data;
};

export const useGetHomePageStatus = (extraParams) => {
  return useQuery(["getHomePageStatus"], getHomePageStatus, {
    ...extraParams,
  });
};

const activateHomePageStatus = async (body) => {
  const response = await client.post(ENDPOINTS.homepage_status, body);

  return response.data;
};

export const useActivateHomePageStatus = (extraParams) =>
  useMutation((body) => activateHomePageStatus(body), {
    ...extraParams,
  });

const swapHomepage = async (body) => {
  const response = await client.post(ENDPOINTS.homepage_reorder, body);

  return response.data;
};

export const useSwapHomepage = (extraParams) =>
  useMutation((body) => swapHomepage(body), {
    ...extraParams,
  });

const getLayoutTemplate = async (params) => {
  const { data } = await client.get(ENDPOINTS.homepage_template, params);

  return data;
};

export const useGetLayoutTemplate = (extraParams) => {
  return useQuery(["getLayoutTemplate"], getLayoutTemplate, {
    ...extraParams,
  });
};

const setTemplate = async (id) => {
  const response = await client.post(`${ENDPOINTS.homepage_template}/${id}`);

  return response.data;
};

export const useSetTemplate = (extraParams) =>
  useMutation((body) => setTemplate(body), {
    ...extraParams,
  });
