import React from "react";
import { Flex, Text, Label } from "renos-ui";
import VerticalElipsButton from "./VerticalElipsButton";

const CardLocation = (props) => {
  const {
    name,
    contact_number,
    address,
    lower_district_name,
    district_name,
    city_name,
    province_name,
    length,
    postal_code,
    is_primary,
    id,
    handleSetPrimary,
    handleDelete,
    handleEdit,
  } = props;

  return (
    <Flex
      flexDirection="column"
      padding="24px"
      width="100%"
      border="1px solid"
      borderColor="black10"
      borderRadius="8px"
      key={id}
    >
      <Flex columnGap="12px" alignItems="center" justifyContent="space-between">
        <Flex>
          <Text weight="bold">{name}</Text>
        </Flex>
        {is_primary && (
          <Flex flexGrow="1">
            <Label variant="success">Alamat Utama</Label>
          </Flex>
        )}
        <VerticalElipsButton
          length={length}
          isPrimary={is_primary}
          handleEdit={handleEdit}
          handleDelete={handleDelete}
          handleSetPrimary={handleSetPrimary}
        />
      </Flex>
      <Flex flexDirection="column" rowGap="8px" color="black75">
        <Text>{contact_number}</Text>
        <Text>{address}</Text>
        <Text>
          {`${lower_district_name}, ${district_name}, ${city_name}, ${province_name} ${postal_code}`}
        </Text>
      </Flex>
    </Flex>
  );
};

export default CardLocation;
