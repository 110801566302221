import { Box, Flex, Icon, Text, Tooltip } from "renos-ui";

const TargetCard = ({ isActive, onClick, title, description, disabled }) => {
  return (
    <Flex
      padding="8px 12px"
      gap="8px"
      onClick={disabled ? () => {} : onClick}
      alignItems="center"
      borderWidth="1px"
      borderColor={isActive ? "blue50" : "black10"}
      backgroundColor={isActive ? "blue5" : disabled ? "black5" : "white"}
      color={isActive ? "blue50" : "black75"}
      borderStyle="solid"
      borderRadius="12px"
      cursor={disabled ? "not-allowed" : "pointer"}
    >
      <Text>{title}</Text>
      {description && (
        <Tooltip
          label={
            <Box>
              <Text variant="small" color="black75">
                {description}
              </Text>
            </Box>
          }
        >
          <Icon
            name="Info-outline"
            size={14}
            color={isActive ? "blue50" : "black75"}
          />
        </Tooltip>
      )}
    </Flex>
  );
};

export default TargetCard;
