import {
  Button,
  Flex,
  Icon,
  Input,
  Text,
  useDebounce,
  useToast,
} from "renos-ui";
import { StyledDialog } from "./styled";
import ProductEmpty from "./ProductEmpty";
import ProductItem from "./ProductItem";
import ProductSkeleton from "./ProductSkeleton";
import InfiniteScroll from "widgets/global/InfiniteScroll";
import { useMemo, useState } from "react";
import { useGetProductsV3 } from "services/product";

const ProductList = ({
  disclosure,
  selectedAttachProduct,
  setSelectedAttachProduct,
  handleAttachProduct,
}) => {
  const MAX_SELECTED_ITEM = 3;
  const { isOpen, onClose } = disclosure;
  const toast = useToast();
  const [items, setItems] = useState([]);
  const [filter, setFilter] = useState({
    limit: 20,
    page: 1,
    keyword: "",
    "product_status_ids[]": 1,
  });
  const [searchValue, setSearchValue] = useState("");

  const {
    data: dataList,
    isLoading,
    isFetching,
  } = useGetProductsV3(filter, {
    onSuccess: (data) => {
      const items =
        data?.data?.filter((item) => item.product_status_id === 1) || [];

      if (filter.page === 1) {
        setItems(items);
      } else {
        setItems((prev) => [...prev, ...items]);
      }
    },
  });

  useDebounce(
    () =>
      setFilter({
        ...filter,
        keyword: searchValue,
        page: 1,
      }),
    [searchValue],
    500
  );

  const handleCheckProduct = (item) => {
    if (
      selectedAttachProduct.find(
        (product) => product.product_id === item.product_id
      )
    ) {
      setSelectedAttachProduct(
        selectedAttachProduct.filter(
          (product) => product.product_id !== item.product_id
        )
      );
    } else {
      if (selectedAttachProduct.length < MAX_SELECTED_ITEM) {
        setSelectedAttachProduct([...selectedAttachProduct, item]);
      }
    }
  };

  const hasNextPage = useMemo(
    () => dataList?.pagination?.current_page < dataList?.pagination?.last_page,
    [dataList]
  );

  const isProductLoading = useMemo(
    () => (isLoading || isFetching) && filter.page === 1,
    // eslint-disable-next-line
    [isLoading, isFetching]
  );

  const validationSelectedProduct = () => {
    if (selectedAttachProduct.length === 0) {
      return toast({
        label: "Silahkan pilih beberapa produk",
        placement: "top",
        backgroundColor: "red50",
      });
    }

    handleAttachProduct();
  };

  return (
    <StyledDialog
      onClose={onClose}
      isVisible={isOpen}
      verticalCentered
      width="486px"
      height="640px"
    >
      <Flex flexDirection="column" padding="16px" width="100%" gap="32px">
        <Flex justifyContent="space-between" alignContent="center">
          <Flex
            alignContent="center"
            justifyItems="center"
            alignItems="center"
            gap="8px"
          >
            <Text variant="heading3" weight="bold">
              Lampirkan Produk
            </Text>
          </Flex>
          <Icon
            name="Close-outline"
            cursor="pointer"
            size={24}
            color="black60"
            onClick={onClose}
          />
        </Flex>

        <Input
          placeholder="Cari nama"
          prefix={<Icon name="Search-outline" size={20} color="black50" />}
          onChange={(event) => setSearchValue(event.target.value)}
        />
        {isProductLoading ? (
          <ProductSkeleton />
        ) : items.length === 0 ? (
          <ProductEmpty />
        ) : (
          <>
            <Flex
              overflow="auto"
              width="422px"
              flexDirection="column"
              gap="16px"
              padding="16px"
              border="1px solid"
              borderRadius="8px"
              borderColor="black10"
              maxHeight="calc(100vh - 221px)"
            >
              <InfiniteScroll
                page={filter.page}
                isLoading={isLoading}
                loader={<ProductSkeleton itemsShow={1} />}
                hasNextPage={hasNextPage && !isProductLoading}
                onChange={() => {
                  setFilter((prev) => ({ ...prev, page: prev.page + 1 }));
                }}
              >
                {items.map((item, indexItem) => (
                  <ProductItem
                    item={item}
                    key={`product-item-${indexItem}`}
                    handleCheckProduct={handleCheckProduct}
                    checked={selectedAttachProduct.some(
                      (product) => product.product_id === item.product_id
                    )}
                    disabledCheck={
                      selectedAttachProduct.length >= MAX_SELECTED_ITEM &&
                      !selectedAttachProduct.includes(item)
                    }
                  />
                ))}
              </InfiniteScroll>
            </Flex>
            <Flex>
              <Button
                isBlock
                size="medium"
                disabled={selectedAttachProduct.length === 0}
                variant="primary"
                onClick={validationSelectedProduct}
              >
                {`Lampirkan (${selectedAttachProduct.length}/${MAX_SELECTED_ITEM})`}
              </Button>
            </Flex>
          </>
        )}
      </Flex>
    </StyledDialog>
  );
};

export default ProductList;
