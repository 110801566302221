import MobileLayout from "layouts/MobileLayout";
import React from "react";
import { Divider, Flex, Icon, Input, Text, isEmpty, useToast } from "renos-ui";
import FormLayout from "../../components/FormLayout";
import { useHistory } from "react-router-dom";
import mobileUrl from "constants/mobileUrl";
import { useProductAddContext } from "store/productAdd/reducer";
import constants from "store/productAdd/constants";

const ProductInformation = () => {
  const { state, dispatch } = useProductAddContext();
  const { tempProductCategories, tempProductName } = state;

  const { push, goBack } = useHistory();
  const toast = useToast();

  const onProductNameChange = (value) => {
    dispatch({
      type: constants.SAVE_VALUE,
      payload: {
        tempProductName: value.replace(/[^\p{L}\p{N}\p{P}\p{Z}^$\n]/gu, ""),
      },
    });
  };

  const categoryName =
    !isEmpty(tempProductCategories) &&
    Array.from(tempProductCategories)
      .slice()
      .reverse()
      .map((each) => each.category_name)
      .join(" / ");

  const onFinish = () => {
    if (!tempProductName) {
      toast({
        label: "Nama Produk tidak boleh kosong",
        placement: "bottom",
      });

      return;
    }

    if (!categoryName) {
      toast({
        label: "Kategori tidak boleh kosong",
        placement: "bottom",
      });

      return;
    }

    dispatch({
      type: constants.SAVE_VALUE,
      payload: {
        productName: tempProductName,
        productCategories: tempProductCategories,
      },
    });
    goBack();
  };

  return (
    <MobileLayout
      header={{
        title: "Informasi Produk",
        isHomepage: true,
        withBack: true,
        suffix: (
          <Flex alignItems="center" cursor="pointer" onClick={onFinish}>
            <Text color="blue75" weight="bold">
              Selesai
            </Text>
          </Flex>
        ),
      }}
    >
      <Flex
        flexDirection="column"
        padding="16px"
        rowGap="24px"
        width="100%"
        height="calc(100dvh - 92px)"
        overflow="auto"
      >
        <FormLayout title="Apa nama produkmu?" required>
          <Input
            placeholder="Tulis nama produk"
            hint="Tips pengisian: Merek + Jenis Produk + Keterangan Tambahan"
            showCount
            maxLength={70}
            value={tempProductName}
            onChange={(e) => onProductNameChange(e.target.value)}
          />
        </FormLayout>
        <FormLayout title="Kategori" required>
          {categoryName ? (
            <Flex width="100%" flexDirection="column" rowGap="8px">
              <Flex
                width="100%"
                justifyContent="space-between"
                alignItems="center"
                columnGap="8px"
              >
                <Text>{categoryName}</Text>
                <Input.Radio checked />
              </Flex>
              <Divider />
              <Flex
                cursor="pointer"
                onClick={() => push(mobileUrl.PRODUCT_CATEGORY)}
              >
                <Text variant="caption" color="blue50" weight="bold">
                  Pilih kategori lainnya
                </Text>
              </Flex>
            </Flex>
          ) : (
            <Input
              placeholder="Pilih Kategori"
              sufix={<Icon name="Right-outline" />}
              onClick={() => push(mobileUrl.PRODUCT_CATEGORY)}
            />
          )}
        </FormLayout>
      </Flex>
    </MobileLayout>
  );
};

export default ProductInformation;
