import { Button, Flex, useDisclosure, useToast } from "renos-ui";
import { useConfirmOrder } from "services/order-management";
import ModalConfirmation from "../ModalConfirmation";
import ModalReject from "./ModalReject";

const ConfirmRejectNewOrder = ({
  data: { order_id, products },
  refetchStatus,
  refetchList,
}) => {
  const toast = useToast();
  const disclosureConfirm = useDisclosure({ isOpen: false });
  const disclosureReject = useDisclosure({ isOpen: false });

  const { mutate: confirmOrder, isLoading: isConfirmLoading } = useConfirmOrder(
    {
      onSuccess: (data) => {
        const { message, frontend_message } = data;

        refetchStatus();
        refetchList();
        disclosureConfirm.onClose();

        toast({
          label: frontend_message || message || "Pesanan berhasil diterima",
          placement: "top",
        });
      },
      onError: (data) => {
        const {
          response: {
            data: { frontend_message, message },
          },
        } = data;
        toast({
          label: frontend_message || message || "Pesanan gagal diterima",
          placement: "top",
          backgroundColor: "red50",
        });
      },
    }
  );

  const handleConfirm = () => {
    const formData = new FormData();
    formData.append("id", order_id);

    confirmOrder(formData);
  };

  return (
    <Flex gap="16px">
      <ModalConfirmation
        isOpen={disclosureConfirm.isOpen}
        onClose={disclosureConfirm.onClose}
        onConfirm={handleConfirm}
        isLoading={isConfirmLoading}
      />

      <ModalReject
        orderId={order_id}
        products={products}
        disclosure={disclosureReject}
        refetchStatus={refetchStatus}
        refetchList={refetchList}
      />

      <Button
        size="medium"
        variant="tertiary"
        onClick={disclosureReject.onToggle}
      >
        Tolak Pesanan
      </Button>
      <Button size="medium" onClick={disclosureConfirm.onToggle}>
        Terima Pesanan
      </Button>
    </Flex>
  );
};

export default ConfirmRejectNewOrder;
