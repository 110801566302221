import styled from "styled-components";
import { Button, Input } from "renos-ui";

export const StyledTextArea = styled(Input.TextArea)`
  resize: none;
  height: 120px;
`;

export const StyledLongTextArea = styled(Input.TextArea)`
  resize: none;
  height: 405px;
`;

export const StyledButton = styled(Button)`
  border-radius: 4px;
`;
