import MobileLayout from "layouts/MobileLayout";
import React, { useState } from "react";
import {
  Button,
  Drawer,
  Flex,
  Grid,
  Icon,
  Text,
  isEmpty,
  pick,
  useDisclosure,
  useToast,
} from "renos-ui";
import FormLayout from "../../../components/FormLayout";
import {
  useGetProductVariant,
  useGetVariantValue,
} from "services/product-variant";
import { useProductAddContext } from "store/productAdd/reducer";
import constants from "store/productAdd/constants";
import { useMemo } from "react";
import VariantDrawer from "./VariantDrawer";
import VariantTypeDrawer from "./VariantTypeDrawer";
import { variantToSkuFormat } from "helpers/attribute-mapper/product-variant";
import { useHistory } from "react-router-dom";

const VariantSelection = () => {
  const { goBack } = useHistory();
  const { state, dispatch } = useProductAddContext();

  const toast = useToast();

  const { isEdit, productVariants, temp_sku: tempSku } = state;

  const clonedInitialProductVariant = JSON.parse(
    JSON.stringify(productVariants)
  );

  const [productVariant, setProductVariant] = useState(
    !isEmpty(clonedInitialProductVariant) ? clonedInitialProductVariant : [{}]
  );
  const [activeVariantIndex, setActiveVariantIndex] = useState();
  const [deletedVariantIndex, setDeletedVariantIndex] = useState();

  const productWithValue = useMemo(
    () =>
      productVariant.filter((variant) => variant?.variant_values?.length > 0),
    [productVariant]
  );

  const { data: variants } = useGetProductVariant();

  const variantDisclosure = useDisclosure({
    open: false,
  });
  const variantTypeDisclosure = useDisclosure({
    open: false,
  });

  const variantDeleteDisclosure = useDisclosure({ open: false });

  const { mutate: getVariantValue } = useGetVariantValue({
    onSuccess: (data) => {
      const tempProductVariant = [...productVariant];
      const selectedIndex = tempProductVariant.findIndex(
        (variant) => variant.variant_id === data[0].variant_id
      );
      tempProductVariant[selectedIndex].options = data;

      const allVariantValues = productVariant.map(
        (variant) => variant.variant_values
      );

      const newData = [];

      allVariantValues.forEach((data, i) => {
        const reFlatted = [
          ...new Map(data.map((m) => [m.variant_value_id, m])).values(),
        ];

        newData.push({
          ...tempProductVariant[i],
          variant_values_minify: reFlatted,
        });
      });

      setProductVariant(newData);
    },
  });

  const variantActive = useMemo(
    () => productVariant[activeVariantIndex],
    [activeVariantIndex, productVariant]
  );

  const onVariantChoose = (value) => {
    const params = {
      "variant[]": value.variant_id,
    };
    const tempProductVariant = [...productVariant];

    tempProductVariant[activeVariantIndex].variant_values = [];
    tempProductVariant[activeVariantIndex].variant_id = value.variant_id;
    tempProductVariant[activeVariantIndex].variant_name = value.variant_name;
    tempProductVariant[activeVariantIndex].isEdit = false;

    const allVariantValues = productVariant.map(
      (variant) => variant.variant_values
    );

    const newData = [];

    allVariantValues.forEach((data, i) => {
      const reFlatted = [
        ...new Map(data.map((m) => [m.variant_value_id, m])).values(),
      ];

      newData.push({
        ...tempProductVariant[i],
        variant_values_minify: reFlatted,
      });
    });

    setProductVariant(newData);
    getVariantValue(params);
    variantDisclosure.onClose();
  };

  const onVariantValueChange = (tempValue, selectedIndex, variant) => {
    const tempProductVariant = [...productVariant];
    const selectedVariant = tempProductVariant[selectedIndex];
    let value = [];

    if (tempProductVariant[selectedIndex].variant_values.length === 0) {
      value.push(tempValue);
    } else {
      value = selectedVariant.variant_values;
      const currentIndex = value.findIndex(
        (item) => item.variant_value_id === tempValue.variant_value_id
      );

      if (currentIndex >= 0) {
        value.splice(currentIndex, 1);
      } else {
        value.push(tempValue);
      }
    }

    tempProductVariant[selectedIndex].variant_values = value.map((data, i) => {
      if (data.__isNew__) {
        data.variant_id = variant.variant_id;
        data.variant_name = variant.variant_name;
        data.variant_value = data.value;
        data.variant_value_id = `new-${i}`;
        delete data.__isNew__;
      }

      return data;
    });

    tempProductVariant[selectedIndex].variant_values_minify = value.map(
      (data, i) => {
        if (data.__isNew__) {
          data.variant_id = variant.variant_id;
          data.variant_name = variant.variant_name;
          data.variant_value = data.value;
          data.variant_value_id = `new-${i}`;
          delete data.__isNew__;
        }

        return data;
      }
    );

    setProductVariant(tempProductVariant);
  };

  const onVariantDelete = (index) => {
    variantDeleteDisclosure.onOpen();
    setDeletedVariantIndex(index);
  };

  const variantDeleteConfirm = () => {
    const tempProductVariant = [...productVariant];
    tempProductVariant.splice(deletedVariantIndex, 1);

    if (tempProductVariant.length === 0) {
      dispatch({
        type: constants.SAVE_VALUE,
        payload: {
          product_active: 1,
          price: 0,
          stock: isEdit ? 0 : 1,
          variant: 0,
        },
      });

      setProductVariant([{}]);

      return variantDeleteDisclosure.onClose();
    }

    setProductVariant(tempProductVariant);

    return variantDeleteDisclosure.onClose();
  };

  const onDrawerOpen = (index) => {
    setActiveVariantIndex(index);
    variantDisclosure.onOpen();
  };

  const onFinish = (isSave) => {
    if (isSave) {
      const formattedData = variantToSkuFormat(productWithValue, isEdit);

      let formattedSku = formattedData.map((data) =>
        pick(data, [
          "index",
          "id",
          "variants",
          "price",
          "stock",
          "status",
          "default",
          "number",
          "weight",
        ])
      );

      if (tempSku) {
        const tempFormattedSku = [...formattedSku];

        const isVariantHaveDefault =
          tempFormattedSku.filter((item) => item.default === 1).length > 0;

        formattedSku = tempFormattedSku.map((data) => {
          const varName =
            data.variants.length > 1
              ? data.variants.map((variant) => variant.label)
              : data.variants[0].label;

          const selected = tempSku.find((sku) =>
            data.variants.length > 1
              ? sku.variant_name === varName.join("|") ||
                sku.variant_name === varName.reverse().join("|")
              : sku.variant_name === varName
          );

          if (selected) {
            const newData = { ...data };
            const productWeight = parseInt(selected?.product_weight);

            newData.price = selected?.product_price;
            newData.stock = selected?.product_stock;
            newData.status = selected?.product_status_id;
            newData.default = isVariantHaveDefault
              ? selected.is_default
              : selected?.is_default || data.default;
            newData.weight = productWeight;
            newData.number = selected?.product_sku_mpn;
            newData.image_id = selected?.product_image_id;
            newData.image_url = selected?.product_image_url;
            newData.image = selected?.product_image_path;
            newData.product_sku_id = selected?.product_sku_id;

            return newData;
          }

          return data;
        });
      }

      if (
        productWithValue.length <
        productVariant.filter((item) => !isEmpty(item)).length
      ) {
        toast({
          label: "Pilih tipe varian untuk melanjutkan",
          placement: "bottom",
        });

        return;
      }

      if (
        !isEmpty(formattedSku) &&
        formattedSku.filter((item) => item.default).length === 0
      ) {
        formattedSku[0].default = 1;
      }

      dispatch({
        type: constants.SAVE_VALUE,
        payload: {
          sku: formattedSku,
          productVariants: productWithValue,
          variant: isEmpty(formattedSku) ? 0 : 1,
          skeletonFilled: true,
        },
      });
    }

    goBack();
  };

  return (
    <MobileLayout
      header={{
        title: "Variant Product",
        isHomepage: true,
        withBack: true,
        onBack: onFinish,
        withoutGoBack: true,
        suffix: (
          <Flex
            alignItems="center"
            cursor="pointer"
            onClick={() =>
              productVariant.length < 2 &&
              setProductVariant((prev) => [...prev, {}])
            }
          >
            <Text
              color={productVariant.length === 2 ? "black25" : "blue75"}
              weight="bold"
            >
              Tambah
            </Text>
          </Flex>
        ),
      }}
    >
      <Flex
        padding="16px"
        width="100%"
        height="calc(100dvh - 92px)"
        flexDirection="column"
        rowGap="24px"
        justifyContent="space-between"
      >
        <Flex
          flexDirection="column"
          rowGap="24px"
          height="calc(100dvh - 158px)"
        >
          <Flex flexDirection="column">
            <Text weight="bold">Tipe Varian</Text>
            <Text color="black50" variant="caption">
              Kamu bisa menambahkan maksimal 2 tipe varian.
            </Text>
          </Flex>
          <Flex
            height="70vh"
            flexDirection="column"
            rowGap="24px"
            overflow="auto"
          >
            {productVariant?.map((item, variantIndex) => (
              <FormLayout
                title={`Varian ${variantIndex + 1}`}
                key={`Varian ${variantIndex + 1}`}
              >
                <Flex flexDirection="column" rowGap="16px">
                  <Flex columnGap="8px" alignItems="center">
                    <Flex
                      p="8px"
                      borderStyle="solid"
                      borderRadius="8px"
                      borderColor="black10"
                      borderWidth="1px"
                      onClick={() => onDrawerOpen(variantIndex)}
                      justifyContent="space-between"
                      alignItems="center"
                      flex={1}
                    >
                      <Text color={!isEmpty(item) ? "black100" : "black25"}>
                        {item.variant_name || "Pilih Variant"}
                      </Text>
                      <Icon name="Down-outline" color="black50" size={20} />
                    </Flex>

                    <Flex
                      borderStyle="solid"
                      borderWidth={1}
                      borderColor="black10"
                      borderRadius={8}
                      alignItems="center"
                      justifyContent="center"
                      width={40}
                      height={40}
                      onClick={() => onVariantDelete(variantIndex)}
                    >
                      <Icon name="Trash-outline" color="black50" size={24} />
                    </Flex>
                  </Flex>
                  <Flex
                    p="8px"
                    borderStyle="solid"
                    borderRadius="8px"
                    borderColor="black10"
                    borderWidth="1px"
                    onClick={() => {
                      if (!isEmpty(item)) {
                        variantTypeDisclosure.onOpen();
                        setActiveVariantIndex(variantIndex);
                      }
                    }}
                    backgroundColor={isEmpty(item) && "black5"}
                  >
                    <Text color="black25">Ketik atau pilih tipe varian</Text>
                  </Flex>
                  <Grid gap="8px" gridTemplateColumns="repeat(2,minmax(0,1fr))">
                    {item?.variant_values_minify?.map((option) => (
                      <Flex
                        key={option.variant_value}
                        borderRadius="100px"
                        borderColor="black10"
                        borderWidth="1px"
                        borderStyle="solid"
                        justifyContent="space-between"
                        alignItems="center"
                        py="6px"
                        px="16px"
                      >
                        <Text variant="caption">{option.variant_value}</Text>
                        <Icon
                          name="Close-solid"
                          size={16}
                          color="black50"
                          cursor="pointer"
                          onClick={() =>
                            onVariantValueChange(
                              option,
                              variantIndex,
                              variantActive
                            )
                          }
                        />
                      </Flex>
                    ))}
                  </Grid>
                </Flex>
              </FormLayout>
            ))}
          </Flex>
        </Flex>

        <Button isBlock onClick={() => onFinish(true)}>
          Simpan
        </Button>
      </Flex>
      <VariantDrawer
        disclosure={variantDisclosure}
        variants={variants}
        productVariant={productVariant}
        activeVariantIndex={activeVariantIndex}
        onVariantChoose={onVariantChoose}
      />
      <VariantTypeDrawer
        disclosure={variantTypeDisclosure}
        variantActive={variantActive}
        onVariantValueChange={onVariantValueChange}
        activeVariantIndex={activeVariantIndex}
        item={productVariant[activeVariantIndex]}
      />

      <Drawer
        isVisible={variantDeleteDisclosure.isOpen}
        onClose={variantDeleteDisclosure.onClose}
        placement="bottom"
        closable
      >
        <Flex flexDirection="column" rowGap="24px" flex={1}>
          <Flex flexDirection="column" rowGap="4px">
            <Text variant="subtitle" weight="bold">
              Hapus varian ini?
            </Text>
            <Text color="black75">
              Data yang ada pada varian ini akan terhapus, kamu yakin?
            </Text>
          </Flex>
          <Flex columnGap="16px">
            <Button
              variant="secondary"
              isBlock
              onClick={variantDeleteDisclosure.onClose}
            >
              Batalkan
            </Button>
            <Button
              isBlock
              onClick={() => variantDeleteConfirm(deletedVariantIndex)}
            >
              Ya, Hapus
            </Button>
          </Flex>
        </Flex>
      </Drawer>
    </MobileLayout>
  );
};

export default VariantSelection;
