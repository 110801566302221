import { Divider, Flex, Icon, useDebounce } from "renos-ui";
import { StyledFlexInputSearch, StyledInputBorderLess } from "./styled";
import { useState } from "react";

const Filter = ({ setValue }) => {
  const [searchValue, setSearchValue] = useState("");

  useDebounce(
    () => {
      const params = {
        keyword: searchValue,
      };

      setValue(params);
    },
    [searchValue],
    1000
  );

  return (
    <Flex flexDirection="column" gap="16px" px="24px">
      <Flex gap="16px">
        <StyledFlexInputSearch>
          <StyledInputBorderLess
            size="small"
            value={searchValue}
            placeholder="Cari etalase"
            onChange={(event) => setSearchValue(event.target.value)}
            prefix={<Icon size={15} name="Search-outline" color="black50" />}
          />
        </StyledFlexInputSearch>
      </Flex>
      <Divider />
    </Flex>
  );
};

export default Filter;
