import { Flex, Text } from "renos-ui";
import Stepper from "./Stepper";
import { StyledToggle } from "../styled";

const StatusStockInput = ({
  value,
  setValue,
  isVariant,
  productSkus,
  disabled,
  setIsStatusChanged,
  setIsStockChanged,
}) => {
  const { productStock, productStatusId } = value;

  const handleVariant = (newValue, indexVariant, property) => {
    if (property === "productStatusId") setIsStatusChanged(true);

    if (property === "productStock") setIsStockChanged(true);

    const arrNewVariant = value.map((val, indexVal) => {
      if (indexVariant === indexVal) return { ...val, [property]: newValue };

      return val;
    });

    setValue([...arrNewVariant]);
  };

  const handleNonVariant = (isStatus, newValue) => {
    if (isStatus) {
      setValue({
        ...value,
        productStatusId: productStatusId === 1 ? 2 : 1,
      });
      setIsStatusChanged(true);
    } else {
      setValue({ ...value, productStock: newValue });
      setIsStockChanged(true);
    }
  };

  if (isVariant)
    return (
      <Flex
        gap="24px"
        flexDirection="column"
        maxHeight="calc(100dvh - 139px)"
        overflow="auto"
      >
        {value.map((val, indexValue) => (
          <Flex gap="16px" key={`variant-${indexValue}`} alignItems="center">
            <Flex
              alignItems="center"
              onClick={() =>
                !disabled &&
                handleVariant(
                  val.productStatusId === 1 ? 2 : 1,
                  indexValue,
                  "productStatusId"
                )
              }
            >
              <StyledToggle checked={val.productStatusId === 1} />
            </Flex>
            <Flex width="calc(100% - 78px)">
              <Text lineClamp={1}>
                {productSkus[indexValue].formatted_variant_value_name}
              </Text>
            </Flex>
            <Stepper
              disabled={disabled}
              value={val.productStock}
              setValue={(newValue) =>
                handleVariant(newValue, indexValue, "productStock")
              }
            />
          </Flex>
        ))}
      </Flex>
    );

  return (
    <Flex gap="24px" flexDirection="column">
      <Flex justifyContent="space-between" alignItems="center">
        <Text variant="caption" weight="bold">
          Status Produk
        </Text>
        <Flex gap="17px" alignItems="center">
          <Text variant="caption" color="blue50" weight="bold">
            Aktif
          </Text>
          <Flex onClick={() => !disabled && handleNonVariant(true)}>
            <StyledToggle checked={productStatusId === 1} />
          </Flex>
        </Flex>
      </Flex>
      <Flex justifyContent="space-between" alignItems="center">
        <Text variant="caption" weight="bold">
          Stok Produk
        </Text>
        <Stepper
          disabled={disabled}
          value={productStock}
          setValue={(newValue) => handleNonVariant(false, newValue)}
        />
      </Flex>
    </Flex>
  );
};

export default StatusStockInput;
