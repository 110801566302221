import { useEffect, useMemo, useState } from "react";
import {
  Button,
  DatePicker,
  Drawer,
  Flex,
  Icon,
  Text,
  useDisclosure,
} from "renos-ui";
import { Wrapper } from "./styled";
import { getRangeWeek } from "helpers";

const CustomDatepicker = ({ value, setValue }) => {
  const disclosure = useDisclosure({ isOpen: false });
  const tabItems = ["Per Hari", "Per Minggu", "Per Bulan"];
  const [selectedTab, setSelectedTab] = useState("Per Hari");
  const [selectedDate, setSelectedDate] = useState(value);

  const handleSave = () => {
    setValue({ ...selectedDate });
    disclosure.onClose();
  };

  const handleOnChangeCalendar = (dateValue) => {
    let tempStartDate = selectedTab === "Per Hari" ? dateValue : dateValue[0];
    let tempEndDate = null;
    const selectedYear = tempStartDate.getFullYear();
    const selectedMonth = tempStartDate.getMonth();
    const thisMonth = new Date().getMonth();
    const thisDay = new Date().getDate();
    const lastDate = new Date(selectedYear, selectedMonth + 1, 0).getDate();

    if (selectedTab === "Per Bulan") {
      tempStartDate.setDate(1);
      tempEndDate = new Date(
        selectedYear,
        selectedMonth,
        thisMonth === selectedMonth ? thisDay : lastDate
      );
    } else if (selectedTab === "Per Minggu") {
      const thisWeek = getRangeWeek(tempStartDate);
      tempStartDate = thisWeek[0];
      tempEndDate = thisWeek[1];
    }

    setSelectedDate({
      startDate: tempStartDate,
      endDate: selectedTab === "Per Hari" ? tempStartDate : tempEndDate,
      type: selectedTab,
    });
  };

  const isActive = useMemo(() => value.starDate || value.endDate, [value]);

  const renderDatepicker = useMemo(() => {
    if (selectedTab === "Per Hari") {
      return (
        <Wrapper>
          <DatePicker
            inline
            selected={selectedDate.startDate}
            onChange={(vals) => handleOnChangeCalendar(vals)}
            maxDate={new Date()}
          />
        </Wrapper>
      );
    }

    return (
      <Wrapper>
        <DatePicker
          inline
          selected={selectedDate.startDate}
          startDate={selectedDate.startDate}
          endDate={selectedDate.endDate}
          selectsRange
          onChange={(vals) => handleOnChangeCalendar(vals)}
          maxDate={new Date()}
        />
      </Wrapper>
    );
    // eslint-disable-next-line
  }, [selectedTab, selectedDate]);

  useEffect(() => {
    setSelectedDate(value);
    // eslint-disable-next-line
  }, [disclosure.isOpen]);

  return (
    <Flex>
      <Icon
        name="Calendar-outline"
        size={24}
        onClick={disclosure.onOpen}
        color={isActive ? "blue50" : "black50"}
      />
      {disclosure.isOpen && (
        <Drawer
          isVisible={disclosure.isOpen}
          onClose={disclosure.onClose}
          closable
          placement="bottom"
        >
          <Flex flexDirection="column" width="100%" height="100%">
            <Flex gap="16px" flexDirection="column">
              <Text variant="subtitle" weight="bold">
                Atur Rentang Tanggal
              </Text>

              <Flex gap="16px">
                {tabItems.map((item, indexItem) => (
                  <Flex
                    key={`tabs-${indexItem}`}
                    padding="6px 16px"
                    borderColor={selectedTab === item ? "blue50" : "black10"}
                    borderStyle="solid"
                    borderWidth="1px"
                    borderRadius="100px"
                    backgroundColor={selectedTab === item ? "blue5" : "white"}
                    minWidth="fit-content"
                    alignItems="center"
                    onClick={() => setSelectedTab(item)}
                  >
                    <Text
                      variant="caption"
                      color={selectedTab === item ? "blue50" : "black75"}
                    >
                      {item}
                    </Text>
                  </Flex>
                ))}
              </Flex>
              {renderDatepicker}
            </Flex>

            <Flex paddingTop="16px">
              <Button py="12px" size="medium" isBlock onClick={handleSave}>
                Terapkan
              </Button>
            </Flex>
          </Flex>
        </Drawer>
      )}
    </Flex>
  );
};

export default CustomDatepicker;
