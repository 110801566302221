import { Button, Flex, Spinner, useDisclosure, useToast } from "renos-ui";
import ModalArriveOrder from "./ModalArriveOrder";
import ModalTrack from "./ModalTrack";
import { useRequestPickupInstant } from "services/order-management";
import ModalRequestPickup from "../SendOrder/ModalRequestPickup";
// import { StyledButtonIcon } from "./styled";

const OnDelivery = ({ data, refetchStatus, refetchList }) => {
  const toast = useToast();
  const disclosureTrack = useDisclosure({ isOpen: false });
  const disclosureArrive = useDisclosure({ isOpen: false });
  const disclosureRequestPicup = useDisclosure({ isOpen: false });

  const { mutate: requestPickupInstant, isLoading } = useRequestPickupInstant({
    onSuccess: (data) => {
      const { message, frontend_message } = data;

      refetchStatus();
      refetchList();

      toast({
        label: frontend_message || message || "Request pickup berhasil",
        placement: "top",
      });
    },
    onError: (data) => {
      const {
        response: {
          data: { frontend_message, message },
        },
      } = data;
      toast({
        label: frontend_message || message || "Request pickup gagal",
        placement: "top",
        backgroundColor: "red50",
      });
    },
  });

  const handleRequestPickupInstant = () => {
    const params = {
      order_id: data.order_id,
      pickup: true,
      awb: "",
    };
    requestPickupInstant(params);
  };

  const sendOrderCheck = () => {
    if (data.store_order_shipment.request_pickup_datetime_possible) {
      disclosureRequestPicup.onToggle();
    } else {
      handleRequestPickupInstant();
    }
  };

  return (
    <Flex gap="16px">
      {/* <Dropdown>
        <Dropdown.Toggle>
          <StyledButtonIcon
            variant="tertiary"
            size="medium"
            icon={<Icon name="Horizontal-outline" size={24} color="black50" />}
          />
        </Dropdown.Toggle>
        <Dropdown.Content minWidth="127px !important" positionDropdown="right">
          <Dropdown.Item>
            <Text color="black75">Batalkan Pesanan</Text>
          </Dropdown.Item>
        </Dropdown.Content>
      </Dropdown> */}

      {data?.store_order_shipment.retry_booking_possible ? (
        <Button
          size="medium"
          onClick={sendOrderCheck}
          disabled={isLoading}
          preffix={isLoading && <Spinner color="white" size="8px" />}
        >
          Request Ulang
        </Button>
      ) : data?.shipping?.is_seller_fleet ? (
        <Button size="medium" onClick={disclosureArrive.onToggle}>
          Pesanan Sampai
        </Button>
      ) : (
        <Flex width="144px">
          <Button
            variant="secondary"
            size="medium"
            isBlock
            onClick={disclosureTrack.onToggle}
          >
            Lacak
          </Button>
        </Flex>
      )}
      <ModalTrack
        isOpen={disclosureTrack.isOpen}
        onClose={disclosureTrack.onClose}
        orderId={data.order_id}
      />
      <ModalArriveOrder
        isOpen={disclosureArrive.isOpen}
        onClose={disclosureArrive.onClose}
        orderId={data.order_id}
        refetchStatus={refetchStatus}
        refetchList={refetchList}
      />

      {disclosureRequestPicup.isOpen && (
        <ModalRequestPickup
          refetchStatus={refetchStatus}
          refetchList={refetchList}
          isOpen={disclosureRequestPicup.isOpen}
          onClose={disclosureRequestPicup.onClose}
          orderId={data.order_id}
        />
      )}
    </Flex>
  );
};

export default OnDelivery;
