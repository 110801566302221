import { goToUrl } from "helpers";
import StoreContext from "providers/StoreProvider";
import { useContext } from "react";
import { Drawer, Flex, Text } from "renos-ui";

const PopupMore = ({ disclosure: { isOpen, onClose }, data }) => {
  const { storeData: storeInfo } = useContext(StoreContext);

  const showLabel = () => {
    const tempData = {
      ...data,
      storeInfo,
    };
    sessionStorage.setItem("label-prod-renos", JSON.stringify(tempData));
    goToUrl("/sales/orders/print-label");
  };

  return (
    <Drawer isVisible={isOpen} onClose={onClose} closable placement="bottom">
      <Flex flexDirection="column" width="100%" gap="24px" height="100%">
        <Flex cursor="pointer" onClick={showLabel}>
          <Text color="black75">Cetak Label</Text>
        </Flex>
      </Flex>
    </Drawer>
  );
};

export default PopupMore;
