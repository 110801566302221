import desktopUrl from "./desktopUrl";

const sidebarData = [
  {
    title: "Dashboard",
    icon: "Home-outline",
    iconActive: "Home-solid",
    path: "/",
  },
  {
    title: "Chat",
    icon: "Chat-outline",
    iconActive: "Chat-solid",
    path: desktopUrl.CHAT,
  },
  {
    title: "Produk",
    icon: "Product-outline",
    iconActive: "Product-solid",
    basePath: "products",
    childrens: [
      {
        title: "Tambah Produk",
        path: desktopUrl.PRODUCTS_ADD,
      },
      {
        title: "Daftar Produk",
        path: desktopUrl.PRODUCTS_LIST,
      },
      {
        title: "Etalase",
        path: desktopUrl.SHOWCASE,
      },
    ],
  },
  {
    title: "Penjualan",
    icon: "Transaction-outline",
    iconActive: "Transaction-solid",
    childrens: [
      {
        title: "Daftar Penjualan",
        path: desktopUrl.ORDERS_LIST,
      },
      {
        title: "Ulasan Pembeli",
        path: desktopUrl.SALES_RATING_REVIEW,
      },
      {
        title: "Statistik",
        path: desktopUrl.STATISTICS,
      },
    ],
  },
  {
    title: "Bidding",
    icon: "Transaction-outline",
    iconActive: "Transaction-solid",
    childrens: [
      {
        title: "Projek Bidding",
        path: desktopUrl.BIDDING,
      },
    ],
  },
  {
    title: "Promosi",
    icon: "Voucher-outline",
    iconActive: "Voucher-solid",
    childrens: [
      {
        title: "Diskon Produk",
        path: desktopUrl.DISCOUNT,
      },
      {
        title: "Voucher Toko",
        path: desktopUrl.VOUCHER,
      },
    ],
  },
  {
    title: "Tampilan Toko",
    icon: "Store-outline",
    iconActive: "Store-solid",
    path: desktopUrl.STORE_LAYOUT,
  },
  {
    title: "Tutorial Berjualan",
    icon: "Play-outline",
    iconActive: "Play-solid",
    path: "https://www.renos.id/help-renos/?page_id=733",
  },
  // {
  //   title: "Pusat Bantuan",
  //   icon: "Support-outline",
  //   iconActive: "Support-solid",
  //   path: "/support",
  // },
  {
    title: "Pengaturan",
    icon: "Setting-outline",
    iconActive: "Setting-solid",
    childrens: [
      {
        title: "Pengaturan Toko",
        path: "/setting",
      },
      // {
      //   title: "Pengaturan Admin",
      //   path: "/setting-admin",
      // },
    ],
  },
];

export default sidebarData;
