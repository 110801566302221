import desktopUrl from "constants/desktopUrl";
import useQueryParams from "hooks/useQueryParams";
import React from "react";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { Flex, Tabs, Text } from "renos-ui";
import Add from "./add";
import Set from "./set";

const AddProductMultiple = () => {
  const query = useQueryParams();
  const [activeTab, setActiveTab] = useState(
    query.get("tab-active") || "add-product",
  );
  const { push } = useHistory();

  const handleChangeTabActive = (value) => {
    setActiveTab(value);
    push(`${desktopUrl.PRODUCTS_ADD_MULTIPLE}?tab-active=${value}`);
  };

  const items = [
    {
      width: "250px",
      label: "Tambah Produk Sekaligus",
      key: "add-product",
      children: () => <></>,
    },

    {
      width: "250px",
      label: "Atur Produk Sekaligus",
      key: "edit-product",
      children: () => <></>,
    },
  ];

  return (
    <Flex gap="24px" flexDirection="column" paddingTop="20px" width="1088px">
      <Flex flexDirection="column" gap="4px">
        <Text variant="heading3" weight="bold">
          Tambah & Atur Produk Sekaligus
        </Text>
        <Flex gap="2px">
          <Text color="black75">
            Kamu bisa menambah banyak produk sekaligus. Kamu bisa menambah atau
            mengatur banyak produk sekaligus.
          </Text>
        </Flex>
      </Flex>
      <Flex gap="16px" flexDirection="column">
        <Flex
          flexDirection="column"
          height="88px"
          rowGap="24px"
          backgroundColor="white"
          borderTopLeftRadius="16px"
          borderTopRightRadius="16px"
          boxShadow="0px 4px 4px rgba(20, 20, 32, 0.05)"
        >
          <Flex
            flexDirection="column"
            px="24px"
            py="16px"
            borderRadius="16px"
            backgroundColor="white"
          >
            <Tabs
              onChange={handleChangeTabActive}
              defaultActiveKey={activeTab}
              placement="bottom"
              items={items}
            />
          </Flex>
        </Flex>
        {activeTab === "add-product" ? <Add /> : <Set />}
      </Flex>
    </Flex>
  );
};

export default AddProductMultiple;
