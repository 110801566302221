import { Flex, Skeleton } from "renos-ui";

const CardSkeleton = () => {
  return (
    <Flex width="100%" columnGap="20px" alignItems="center">
      <Skeleton height="20px" width="20px" />
      <Flex flexGrow={1}>
        <Skeleton height="40px" width="100%" />
      </Flex>
      <Skeleton height="40px" width="150px" />
      <Skeleton height="40px" width="150px" />
    </Flex>
  );
};

export default CardSkeleton;
