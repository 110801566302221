import { isEmpty } from "renos-ui";

const defaultRestSkuValue = {
  price: 0,
  number: "",
  image: null,
  image_id: "",
  status: 1,
  weight: 1,
  image_url: null,
};

export const variantToSkuFormat = (productWithValue, isEdit) => {
  const tempData = [];

  if (productWithValue && productWithValue.length > 0) {
    const [firstVariant, secondVariant] = productWithValue;
    const {
      variant_values: tempVariantValue,
      variant_values_minify: firstVariantValueMinify,
    } = firstVariant;

    const firstVariantValue = !isEmpty(firstVariantValueMinify)
      ? firstVariantValueMinify
      : tempVariantValue;

    if (firstVariantValue) {
      firstVariantValue.forEach((variant1, variant1Index) => {
        if (secondVariant && secondVariant?.variant_values) {
          const {
            variant_values: tempSecondVariantValue,
            variant_values_minify: secondVariantValueMinify,
          } = secondVariant;

          const secondVariantValue = !isEmpty(secondVariantValueMinify)
            ? secondVariantValueMinify
            : tempSecondVariantValue;

          secondVariantValue.forEach((variant2, variant2Index) => {
            tempData.push({
              index: `${variant1Index}-${variant2Index}`,
              id: {
                [variant1.variant_name]: variant1.variant_value_id,
                [variant2.variant_name]: variant2.variant_value_id,
              },
              variants: [
                {
                  variant: variant1.variant_id,
                  variant_value: variant1.variant_value_id,
                  label: variant1.variant_value,
                },
                {
                  variant: variant2.variant_id,
                  variant_value: variant2.variant_value_id,
                  label: variant2.variant_value,
                },
              ],
              default: variant1Index === 0 && variant2Index === 0 ? 1 : 0,
              stock: isEdit
                ? variant1Index === 0 && variant2Index === 0
                  ? 1
                  : 0
                : 1,
              ...defaultRestSkuValue,
            });
          });
        } else {
          tempData.push({
            index: variant1Index,
            id: {
              [variant1.variant_name]: variant1.variant_value_id,
            },
            variants: [
              {
                variant: variant1.variant_id,
                variant_value: variant1.variant_value_id,
                label: variant1.variant_value,
              },
            ],
            default: variant1Index === 0 ? 1 : 0,
            stock: isEdit ? (variant1Index === 0 ? 1 : 0) : 1,
            ...defaultRestSkuValue,
          });
        }
      });
    }
  }

  return tempData;
};

export const formattedBatchSku = (
  sku,
  filterData,
  formBatch,
  checkedVariant
) => {
  const tempSku = [...sku];
  const filteredBy = !isEmpty(filterData) ? "filter" : "checked";
  const isHaveActiveProduct =
    tempSku.filter((data) => data.status === 1).length > 1;

  const prevDefaultIndex = tempSku.findIndex((data) => data.default === 1);

  let isDefaultApplied = false;

  sku.forEach((source, index) => {
    const isSourceHasDiscount =
      tempSku[index]?.is_product_discount_seasonal_active;

    if (filteredBy === "checked") {
      if (checkedVariant.includes(source.index)) {
        if (!isSourceHasDiscount) {
          tempSku[index].price = formBatch?.price;
        }

        tempSku[index] = {
          ...source,
          stock: formBatch?.stock,
          number: formBatch?.number,
          status: formBatch?.status,
          weight: formBatch?.weight,
        };

        if (
          !isHaveActiveProduct &&
          formBatch.status === 1 &&
          !isDefaultApplied
        ) {
          isDefaultApplied = true;
          tempSku[index].default = 1;
          tempSku[prevDefaultIndex].default = 0;
        }

        if (
          !isEmpty(formBatch?.image_id) &&
          !isEmpty(formBatch?.image_url) &&
          !isEmpty(formBatch?.image)
        ) {
          tempSku[index] = {
            ...tempSku[index],
            image_id: formBatch?.image_id,
            image_url: formBatch?.image_url,
            image: formBatch?.image,
          };
        }
      }
    } else {
      const sourceId = Object.values(source.id);
      const filteredId = Object.values(filterData);
      const isSame =
        sourceId.length === filteredId.length
          ? sourceId.every((data) => filteredId.includes(data))
          : sourceId.some((data) => filteredId.includes(data));

      if (isSame) {
        if (!isSourceHasDiscount) {
          tempSku[index].price = formBatch?.price;
        }

        tempSku[index] = {
          ...source,
          price: formBatch?.price,
          stock: formBatch?.stock,
          number: formBatch?.number,
          status: formBatch?.status,
          weight: formBatch?.weight,
        };

        if (
          !isHaveActiveProduct &&
          formBatch.status === 1 &&
          !isDefaultApplied
        ) {
          isDefaultApplied = true;
          tempSku[index].default = 1;
          tempSku[prevDefaultIndex].default = 0;
        }

        if (
          !isEmpty(formBatch?.image_id) &&
          !isEmpty(formBatch?.image_url) &&
          !isEmpty(formBatch?.image)
        ) {
          tempSku[index] = {
            ...tempSku[index],
            image_id: formBatch?.image_id,
            image_url: formBatch?.image_url,
            image: formBatch?.image,
          };
        }
      }
    }
  });

  return tempSku;
};
