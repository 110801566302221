import { Drawer, Flex, Text, Button } from "renos-ui";

const DeleteDrawer = ({ disclosure, handleDelete }) => {
  return (
    <Drawer
      isVisible={disclosure.isOpen}
      closable={true}
      onClose={() => disclosure.onClose()}
      placement="bottom"
    >
      <Flex flexDirection="column" width="100%" gap="24px">
        <Flex flexDirection="column" gap="8px">
          <Text variant="heading3" weight="bold">
            Hapus Catatan
          </Text>
          <Text>Catatan yang dipilih akan dihapus.</Text>
        </Flex>
        <Flex justifyContent="space-between" gap="16px">
          <Button
            isBlock
            variant="tertiary"
            onClick={() => disclosure.onClose()}
          >
            Batal
          </Button>
          <Button onClick={handleDelete} isBlock>
            Ya, Hapus
          </Button>
        </Flex>
      </Flex>
    </Drawer>
  );
};

export default DeleteDrawer;
