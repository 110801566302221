import { Box, Button, Flex, Text, Icon } from "renos-ui";

const FailLoadData = ({
  onClick,
  title,
  description,
  isLoading,
  noImg,
  noButton,
  imgSrc,
}) => {
  return (
    <Flex
      gap="24px"
      flexDirection="column"
      width="328px"
      marginLeft="auto"
      marginRight="auto"
      justifyContent="center"
      alignItems="center"
      textAlign="center"
    >
      {!noImg && (
        <Box
          as="img"
          src={imgSrc || "/assets/images/fail-get-data.png"}
          height="240px"
          width="240px"
          alt="img-fail"
        />
      )}
      <Flex flexDirection="column">
        <Text weight="bold" color="black100">
          {title}
        </Text>
        <Text variant="caption" color="black75">
          {description}
        </Text>
      </Flex>

      {!noButton && (
        <Button
          onClick={onClick}
          disabled={isLoading}
          width="100%"
          isBlock
          size="medium"
          preffix={<Icon name="Reload-outline" size={20} />}
        >
          Muat Ulang
        </Button>
      )}
    </Flex>
  );
};

FailLoadData.defaultProps = {
  onClick: () => {},
  title: "Yah, gagal memuat datamu",
  description:
    "Pastikan koneksi internet kamu stabil dan coba klik tombol di bawah untuk memuat ulang",
  isLoading: false,
  noImg: false,
  noButton: false,
};

export default FailLoadData;
