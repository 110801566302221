import { Flex, Skeleton, range } from "renos-ui";

const Loader = () => {
  return (
    <Flex
      gap="16px"
      borderRadius="8px"
      boxShadow="0px 4px 4px 0px #1414200D"
      width="290px"
      height="553px"
      flexDirection="column"
    >
      <Skeleton width="290px" height="365px" />
      <Flex height="172px" flexDirection="column" gap="4px" padding="16px">
        <Skeleton width="200px" height="20px" />
        {range(4).map((_, indexSkeleton) => (
          <Skeleton
            key={`skeleton${indexSkeleton}`}
            width="260px"
            height="20px"
          />
        ))}
      </Flex>
    </Flex>
  );
};

export default Loader;
