import ENDPOINTS from "constants/endpoints";
import { useQuery } from "react-query";
import client from "../client";

const getProductCustomShipping = async () => {
  const { data } = await client.get(ENDPOINTS.custom_shipping);

  return data.data.reverse();
};

export const useGetCustomShipping = (extraParams) => {
  return useQuery(["custom-shipping"], getProductCustomShipping, {
    ...extraParams,
  });
};
