import { Flex, Text } from "renos-ui";
import { ExtraSmallText } from "./styled";

const CardVoucher = ({ minPurchase, amount }) => {
  return (
    <Flex
      background={`url("/assets/images/store-layout/voucher-layout.svg")`}
      backgroundRepeat="no-repeat"
      backgroundSize="212px"
      width="212px"
      height="72px"
    >
      <Flex padding="9px 16px 10px 16px" flexDirection="column" gap="1px">
        <ExtraSmallText variant="small" weight="bold" color="white">
          DISKON
        </ExtraSmallText>
        <Text variant="heading3" weight="bold" color="white">
          {amount}
        </Text>
        <ExtraSmallText variant="small" color="white">
          min. pembelian {minPurchase}
        </ExtraSmallText>
      </Flex>
    </Flex>
  );
};

export default CardVoucher;
