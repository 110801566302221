import { useHistory } from "react-router-dom";
import {
  Box,
  Button,
  Divider,
  Flex,
  Text,
  Icon,
  useDisclosure,
} from "renos-ui";
import DialogDelete from "./dialogDelete";
import DialogDetail from "./dialogDetail";
import { StyledBox } from "./styled";
import { currencyFormatter } from "helpers";
import FreeOngkir from "assets/images/free-ongkir.png";

const SettingRow = ({ dataRow, refetchSetting }) => {
  const {
    coverage_summary,
    coverage_detail,
    pricing,
    estimated_shipping_days,
    id,
    advanced_option_pricing: { constraints: advancedOption = [] },
  } = dataRow;

  const deleteDisclosure = useDisclosure({ isOpen: false });
  const detailDisclosure = useDisclosure({ isOpen: false });

  const history = useHistory();

  const handleEdit = (rowId) => {
    history.push(`/setting/seller-fleet/add?id=${rowId}`);
  };

  const handleSettingType = () => {
    switch (true) {
      case advancedOption?.length > 0:
        return (
          <Flex width="300px" flexDirection="column" gap="16px">
            {advancedOption.map((advanced, index) => (
              <Flex
                flexDirection="column"
                gap="4px"
                key={`advanced-description-${index}`}
              >
                <Text variant="caption" color="black50">
                  Berat {advanced.condition}
                </Text>
                <Text color="black75" weight="bold">
                  {advanced.condition_weight} Gram
                </Text>
                {advanced.condition_base_id === 1 && (
                  <Flex width="300px" justifyContent="space-between" gap="8px">
                    <Flex flexDirection="column" width="50%" gap="4px">
                      <Text variant="caption" color="black50">
                        Kelipatan Berat
                      </Text>
                      <Text color="black75" weight="bold">
                        {advanced.condition_base_weight_value} Gram
                      </Text>
                    </Flex>
                    <Flex flexDirection="column" width="50%" gap="4px">
                      <Text variant="caption" color="black50">
                        Ongkos Kirim
                      </Text>
                      <Flex gap="8px">
                        <Text color="black75" weight="bold">
                          Rp {currencyFormatter(advanced.condition_base_tarif)}
                        </Text>
                        {currencyFormatter(advanced.condition_base_tarif) ===
                          "0" && (
                          <Box as="img" src={FreeOngkir} alt="free-ogkir" />
                        )}
                      </Flex>
                    </Flex>
                  </Flex>
                )}
                {advanced.condition_base_id === 2 && (
                  <Flex width="300px" justifyContent="space-between" gap="8px">
                    <Flex flexDirection="column" width="50%" gap="4px">
                      <Text variant="caption" color="black50">
                        Rentang Berat
                      </Text>
                      <Text color="black75" weight="bold">
                        {`${advanced.condition_base_weight_start} - ${advanced.condition_base_weight_end}`}{" "}
                        Gram
                      </Text>
                    </Flex>
                    <Flex flexDirection="column" width="50%" gap="4px">
                      <Text variant="caption" color="black50">
                        Ongkos Kirim
                      </Text>
                      <Flex gap="8px">
                        <Text color="black75" weight="bold">
                          Rp {currencyFormatter(advanced.condition_base_tarif)}
                        </Text>
                        {currencyFormatter(advanced.condition_base_tarif) ===
                          "0" && (
                          <Box as="img" src={FreeOngkir} alt="free-ogkir" />
                        )}
                      </Flex>
                    </Flex>
                  </Flex>
                )}
                {index !== advancedOption.length - 1 && <Divider mt={16} />}
              </Flex>
            ))}
          </Flex>
        );
      default:
        return (
          <>
            {pricing.constraints?.map((constraint, indexConstraint) => (
              <Flex width="300px" gap="8px" key={`constraint-${indexConstraint}`}>
                <Flex flexDirection="column" width="50%">
                  <Text variant="caption" color="black50">
                    {constraint.label}
                  </Text>
                  <Text color="black75" weight="bold">
                    {constraint.weight_value}
                  </Text>
                </Flex>
                <Flex flexDirection="column" width="50%">
                  <Text variant="caption" color="black50">
                    Ongkos Kirim
                  </Text>
                  <Flex gap="8px">
                    <Text color="black75" weight="bold">
                      Rp {currencyFormatter(constraint.shipping_cost)}
                    </Text>
                    {currencyFormatter(constraint.shipping_cost) === "0" && (
                      <Box as="img" src={FreeOngkir} alt="free-ogkir" />
                    )}
                  </Flex>
                </Flex>
              </Flex>
            ))}
          </>
        );
    }
  };

  return (
    <>
      <DialogDelete
        isOpen={deleteDisclosure.isOpen}
        onClose={deleteDisclosure.onClose}
        refetchSetting={refetchSetting}
        id={id}
      />
      <DialogDetail
        isOpen={detailDisclosure.isOpen}
        onClose={detailDisclosure.onClose}
        onApprove={detailDisclosure.onClose}
        coverageDetail={coverage_detail}
      />
      <Divider />
      <Flex gap="24px">
        <Flex width="328px" flexDirection="column" gap="16px">
          <Flex flexDirection="column">
            <Text variant="caption" color="black50">
              Cara Pengaturan
            </Text>
            <Text color="black75" weight="bold">
              {advancedOption?.length > 0 ? "Opsi Lanjutan" : pricing.label}
            </Text>
          </Flex>
          <Flex flexDirection="column">
            <Text variant="caption" color="black50">
              Cakupan Wilayah
            </Text>
            <Flex gap="8px">
              <Text color="black75" weight="bold" lineClamp={1}>
                {coverage_summary}
              </Text>
              <StyledBox onClick={detailDisclosure.onOpen} cursor="pointer">
                <Text weight="bold" color="blue50">
                  Lihat Detail
                </Text>
              </StyledBox>
            </Flex>
          </Flex>
          <Flex flexDirection="column">
            <Text variant="caption" color="black50">
              Estimasi Tiba
            </Text>
            <Text color="black75" weight="bold">
              {estimated_shipping_days} hari kerja
            </Text>
          </Flex>
        </Flex>
        <Divider orientation="vertical" />
        <Flex flexDirection="column" gap="16px">
          {handleSettingType()}
        </Flex>
        <Flex gap="12px" flexGrow={1} justifyContent="flex-end">
          <Box>
            <Button
              variant="tertiary"
              size="medium"
              onClick={() => handleEdit(id)}
            >
              Ubah Pengaturan
            </Button>
          </Box>
          <Box>
            <Button
              variant="tertiary"
              size="medium"
              onClick={deleteDisclosure.onOpen}
              icon={<Icon name="Trash-solid" size={16} />}
            />
          </Box>
        </Flex>
      </Flex>
    </>
  );
};

export default SettingRow;