import React, { useState } from "react";
import {
  Flex,
  Skeleton,
  ToastProvider,
  Icon,
  Text,
  Button,
  useDisclosure,
} from "renos-ui";
import { useHistory } from "react-router-dom";
import { useGetDetailBidding } from "services/bidding";
import { DetailBiddingRequest } from "./detailBiddingRequest";
import { MyBidding } from "./myBiddingList";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import { ModalRequestBidding } from "widgets/web/bidding/ModalRequestBidding";

const DetailBidding = () => {
  const history = useHistory();
  const { request_number } = useParams();
  const { isOpen, onToggle } = useDisclosure({ open: false });
  const showOfferButtonDisclosure = useDisclosure({ open: true });
  const [hasBidding, setHasBidding] = useState(false);

  const { data, isLoading, refetch } = useGetDetailBidding({
    request_number: request_number,
  });

  return (
    <>
      <ToastProvider placement="top" zIndex={1000000}>
        <Flex gap="1.5rem" flexDirection="column">
          {isLoading ? (
            <SkeletonBidding />
          ) : (
            <>
              <Flex
                flex={1}
                gap="1.5rem"
                backgroundColor="white"
                borderRadius={16}
                py={16}
                px={24}
                boxShadow="0px 4px 4px rgba(20, 20, 32, 0.05)"
                alignItems="center"
              >
                <Icon
                  name="Back-outline"
                  color="black50"
                  cursor="pointer"
                  onClick={() => history.push("/bidding")}
                />
                <Flex
                  justifyContent="space-between"
                  width="100%"
                  alignItems="center"
                >
                  <Text variant="heading3" weight="bold" color="black100">
                    Detail Permintaan
                  </Text>
                  {data?.request_status_remark === "Berlangsung" && (
                    <Button
                      disabled={hasBidding}
                      onClick={onToggle}
                      size="medium"
                    >
                      Berikan Penawaran
                    </Button>
                  )}
                </Flex>
              </Flex>
              <DetailBiddingRequest data={data} isLoading={isLoading} />
              <MyBidding
                showOfferButtonDisclosure={showOfferButtonDisclosure}
                setHasBidding={setHasBidding}
              />
            </>
          )}
        </Flex>
        <ModalRequestBidding
          isOpen={isOpen}
          onToggle={onToggle}
          requestId={data?.bd_request_id}
          refetch={refetch}
        />
      </ToastProvider>
    </>
  );
};

function SkeletonBidding() {
  return (
    <Flex flexDirection="column" marginTop="1rem" width="100%" gap="3.5rem">
      <Skeleton width="100%" height="1.5rem" />
      <Flex flexDirection="column" gap=".75rem" marginLeft="1rem">
        <Flex marginBottom=".5rem">
          <Skeleton width="20%" height="1rem" />
        </Flex>
        {Array.from({ length: 4 }).map((_, index) => (
          <Skeleton key={index} width="40%" height=".75rem" />
        ))}
      </Flex>
      <Flex flexDirection="column" gap=".75rem" marginLeft="1rem">
        <Flex marginBottom=".5rem">
          <Skeleton width="20%" height="1rem" />
        </Flex>
        <Skeleton width="40%" height=".75rem" />
      </Flex>
    </Flex>
  );
}

export default DetailBidding;
