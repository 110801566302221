import { useState } from "react";
import {
  Button,
  Flex,
  Text,
  Input,
  Icon,
  Drawer,
  Box,
  useToast,
  useDisclosure,
  Divider,
} from "renos-ui";
import FixedBottomContainer from "../components/FixedBottomContainer";
import { useDeliveryContext } from "store/seller-fleet/reducer";
import constants from "store/seller-fleet/constants";
import useValidation from "../hooks/useValidation";
import { conditionBaseType, conditionType } from "../utlis";

const ModalAdvanceOption = ({ isOpen, onClose, submitGroup }) => {
  const { state, dispatch } = useDeliveryContext();
  const { dataRates } = state;
  const disclosureDelete = useDisclosure({});
  const disclosureCondition = useDisclosure({});
  const [selectedIndex, setSelectedIndex] = useState(null);
  const { validation, setValidation, handleError } = useValidation();
  const toast = useToast();

  const handleAddAdvancedOption = () => {
    const tempDataCalculate = dataRates?.calculation;
    const tempValidation = validation.dataRates.advancedOptions;
    tempDataCalculate.advanced_options.push({
      condition_type_id: null,
      condition_base_id: 1,
    });
    tempValidation.push({ condition_type_id: false, condition_base_id: false });

    setValidation({
      ...validation,
      dataRates: {
        ...validation.dataRates,
        advancedOptions: [...tempValidation],
      },
    });

    dispatch({
      type: constants.ADD_ADVANCE_OPTIONS,
      payload: {
        ...dataRates,
        calculation: {
          ...dataRates.calculation,
          advanced_options: { ...tempDataCalculate },
        },
      },
    });
  };

  const handleChangeTypeAdvancedOption = (name, value, index) => {
    const tempDataCalculate = { ...dataRates.calculation };
    const tempValidationAdvancedOption = [
      ...validation.dataRates.advancedOptions,
    ];

    if (!tempDataCalculate.advanced_options[index]) {
      tempDataCalculate.advanced_options[index] = {
        condition_type_id: 1,
        condition_base_id: 1,
      };
    }

    if (!tempValidationAdvancedOption[index]) {
      tempValidationAdvancedOption[index] = {
        condition_type_id: false,
        condition_weight: false,
        condition_base_id: false,
        condition_base_weight_value: false,
        condition_base_tarif: false,
        condition_base_weight_start: false,
        condition_base_weight_end: false,
      };
    }

    tempDataCalculate.advanced_options[index][name] = value;
    tempValidationAdvancedOption[index][name] = false;

    const weight = tempDataCalculate.advanced_options[index].condition_weight;
    let startWeight =
      name === "condition_type_id"
        ? 0
        : tempDataCalculate.advanced_options[index].condition_base_weight_start;
    let endWeight =
      name === "condition_type_id"
        ? 0
        : tempDataCalculate.advanced_options[index].condition_base_weight_end;

    if (name === "condition_type_id") {
      tempDataCalculate.advanced_options[index].condition =
        conditionType[value - 1].label;
    }

    if (name === "condition_base_id") {
      tempDataCalculate.advanced_options[index].condition_base =
        conditionBaseType[value - 1].label;

      if (
        tempDataCalculate.advanced_options[index].condition_base_id === 1 ||
        tempDataCalculate.advanced_options[index].condition_base_id === 2
      ) {
        tempDataCalculate.advanced_options[index].condition_base_weight_value =
          undefined;
        tempDataCalculate.advanced_options[index].condition_base_tarif =
          undefined;
        startWeight = undefined;
      } else {
        tempDataCalculate.advanced_options[index].condition_base_weight_start =
          undefined;
        tempDataCalculate.advanced_options[index].condition_base_weight_end =
          undefined;
        tempDataCalculate.advanced_options[index].condition_base_tarif =
          undefined;
        endWeight = undefined;
      }
    }

    switch (tempDataCalculate.advanced_options[index].condition_type_id) {
      case 1:
        tempDataCalculate.advanced_options[index].condition_base_weight_end =
          weight - 1;
        tempDataCalculate.advanced_options[index].condition_base_weight_start =
          startWeight;
        tempValidationAdvancedOption[index].condition_base_weight_end = false;
        break;
      case 2:
        tempDataCalculate.advanced_options[index].condition_base_weight_end =
          weight;
        tempDataCalculate.advanced_options[index].condition_base_weight_start =
          startWeight;
        tempValidationAdvancedOption[index].condition_base_weight_end = false;
        break;
      case 3:
        tempDataCalculate.advanced_options[index].condition_base_weight_start =
          weight + 1;
        tempDataCalculate.advanced_options[index].condition_base_weight_end =
          endWeight;
        tempValidationAdvancedOption[index].condition_base_weight_end = false;
        break;
      case 4:
        tempDataCalculate.advanced_options[index].condition_base_weight_start =
          weight;
        tempDataCalculate.advanced_options[index].condition_base_weight_end =
          endWeight;
        tempValidationAdvancedOption[index].condition_base_weight_end = false;

        break;

      default:
        break;
    }

    setValidation({
      ...validation,
      dataRates: {
        ...validation.dataRates,
        advancedOptions: [...tempValidationAdvancedOption],
      },
    });

    dispatch({
      type: constants.HANDLE_CHANGE_ADVANCE_OPTIONS,
      payload: {
        ...dataRates,
        calculation: {
          ...dataRates.calculation,
          advanced_options: { ...tempDataCalculate },
        },
      },
    });
  };

  const handleShowDelete = (indexGroup) => {
    setSelectedIndex(indexGroup);
    disclosureDelete.onOpen();
  };

  const handleShowCondition = (indexGroup) => {
    setSelectedIndex(indexGroup);
    disclosureCondition.onOpen();
  };

  const handleRemoveAdvancedOption = () => {
    const tempDataCalculate = dataRates.calculation;
    tempDataCalculate.advanced_options.splice(selectedIndex, 1);

    const tempValidation = validation?.dataRates?.advancedOptions;
    tempValidation.splice(selectedIndex, 1);

    dispatch({
      type: constants.DELETE_ADVANCE_OPTIONS,
      payload: {
        ...dataRates,
        calculation: {
          ...dataRates.calculation,
          advanced_options: { ...tempDataCalculate },
        },
      },
    });

    setValidation({
      ...validation,
      dataRates: {
        ...validation.dataRates,
        advancedOptions: [...tempValidation],
      },
    });

    setSelectedIndex(null);

    toast({
      label: "Satu kondisi telah dihapus.",
      placement: "bottom",
      backgroundColor: "black100",
    });

    disclosureDelete.onClose();
  };

  const handleSubmit = () => {
    const isHasError = handleError();

    if (!isHasError) {
      submitGroup();
    }
  };

  return (
    <Drawer
      isVisible={isOpen}
      onClose={onClose}
      isFullPage
      padding="0"
      header={{
        render: (
          <Flex
            flexDirection="row"
            alignItems="center"
            gap="8px"
            padding="16px"
          >
            <Icon
              name="Back-outline"
              size="24"
              color="black50"
              onClick={onClose}
            />
            <Text weight="bold" variant="regular">
              Opsi Lanjutan
            </Text>
          </Flex>
        ),
      }}
    >
      <Flex
        flexDirection="column"
        height="calc(100% - 130px)"
        overflow="scroll"
        backgroundColor="black5"
      >
        {dataRates?.calculation?.advanced_options?.map(
          (advancedOption, indexAdvancedOption) => (
            <Flex
              flexDirection="column"
              gap="16px"
              key={`row-group${indexAdvancedOption}`}
              id={`group${indexAdvancedOption}`}
              padding="16px"
              backgroundColor="white"
              position="relative"
              marginBottom="10px"
            >
              <Flex alignItems="center" justifyContent="space-between">
                <Text weight="bold" variant="caption">
                  Kondisi {indexAdvancedOption + 1}
                </Text>
                {indexAdvancedOption > 0 && (
                  <Flex position="absolute" right="6px" top="15px">
                    <Icon
                      name="Vertical-outline"
                      color="black50"
                      size={22}
                      onClick={() => handleShowDelete(indexAdvancedOption)}
                    />
                  </Flex>
                )}
              </Flex>

              <Divider />

              <Text weight="bold" variant="caption" color="black50">
                Jika Berat
              </Text>

              <Flex gap="16px" mt="-10px" alignContent="flex-start">
                <Flex width="300px">
                  <Input
                    size="small"
                    placeholder="Pilih kondisi"
                    sufix={
                      <Icon
                        name={"Right-outline"}
                        color={"black50"}
                        size={16}
                      />
                    }
                    isError={
                      validation?.dataRates?.advancedOptions[
                        indexAdvancedOption
                      ]?.condition_select_text
                    }
                    error={
                      validation?.dataRates?.advancedOptions[
                        indexAdvancedOption
                      ]?.condition_select_error
                    }
                    onClick={() => handleShowCondition(indexAdvancedOption)}
                    value={advancedOption?.condition || ""}
                    readOnly
                  />
                </Flex>

                <Flex>
                  <Input.Number
                    size="small"
                    isError={
                      validation?.dataRates?.advancedOptions[
                        indexAdvancedOption
                      ]?.condition_weight
                    }
                    error={
                      validation?.dataRates?.advancedOptions[
                        indexAdvancedOption
                      ]?.condition_weightText
                    }
                    value={advancedOption?.condition_weight}
                    onChange={(event) =>
                      handleChangeTypeAdvancedOption(
                        "condition_weight",
                        event,
                        indexAdvancedOption
                      )
                    }
                    sufix={
                      <Text color="black50" weight="bold">
                        g
                      </Text>
                    }
                    placeholder="Berat"
                  />
                </Flex>
              </Flex>

              <Text variant="caption" color="black50">
                Maka hitung ongkos kirim berdasarkan:
              </Text>

              <Flex flexDirection="column" gap="16px">
                {conditionBaseType.map(
                  (dataconditionBaseType, indexConditionBaseType) => (
                    <Flex
                      flexDirection="column"
                      gap="16px"
                      key={`condition-base-${indexConditionBaseType}-${indexAdvancedOption}`}
                    >
                      <Flex ml="-8px">
                        <Input.Radio
                          size="small"
                          name={`condition-base-${indexAdvancedOption}`}
                          checked={
                            advancedOption?.condition_base_id ===
                            dataconditionBaseType.value
                          }
                          onClick={() =>
                            handleChangeTypeAdvancedOption(
                              "condition_base_id",
                              dataconditionBaseType.value,
                              indexAdvancedOption
                            )
                          }
                        >
                          <Text variant="caption" color="black50" weight="bold">
                            {dataconditionBaseType.label}
                          </Text>
                        </Input.Radio>
                      </Flex>

                      {advancedOption?.condition_base_id ===
                        dataconditionBaseType.value &&
                        dataconditionBaseType.value === 1 && (
                          <>
                            <Input.Number
                              size="small"
                              sufix={
                                <Text color="black50" weight="bold">
                                  g
                                </Text>
                              }
                              placeholder="Masukkan Kelipatan Berat"
                              isError={
                                validation?.dataRates?.advancedOptions[
                                  indexAdvancedOption
                                ]?.condition_base_weight_value
                              }
                              error={
                                validation?.dataRates?.advancedOptions[
                                  indexAdvancedOption
                                ]?.condition_base_weight_valueText
                              }
                              value={
                                advancedOption?.condition_base_weight_value
                              }
                              onChange={(value) =>
                                handleChangeTypeAdvancedOption(
                                  "condition_base_weight_value",
                                  value,
                                  indexAdvancedOption
                                )
                              }
                            />

                            <Input.Number
                              size="small"
                              label="Ongkos Kirim"
                              value={advancedOption?.condition_base_tarif?.toString()}
                              isError={
                                validation?.dataRates?.advancedOptions[
                                  indexAdvancedOption
                                ]?.condition_base_tarif
                              }
                              error={
                                validation?.dataRates?.advancedOptions[
                                  indexAdvancedOption
                                ]?.condition_base_tarifText
                              }
                              onChange={(value) =>
                                handleChangeTypeAdvancedOption(
                                  "condition_base_tarif",
                                  value,
                                  indexAdvancedOption
                                )
                              }
                              prefix={
                                <Text
                                  color="black50"
                                  weight="bold"
                                  variant="caption"
                                >
                                  Rp
                                </Text>
                              }
                              placeholder="Masukkan Tarif"
                            />
                          </>
                        )}
                      {advancedOption?.condition_base_id ===
                        dataconditionBaseType.value &&
                        dataconditionBaseType.value === 2 && (
                          <Flex flexDirection="column" gap="16px">
                            <Flex
                              justifyContent="space-between"
                              gap="10px"
                              alignContent="flex-start"
                            >
                              <Input.Number
                                size="small"
                                sufix={
                                  <Text color="black50" weight="bold">
                                    g
                                  </Text>
                                }
                                placeholder="Berat minimum"
                                value={
                                  advancedOption?.condition_base_weight_start
                                }
                                isError={
                                  validation?.dataRates?.advancedOptions[
                                    indexAdvancedOption
                                  ]?.condition_base_weight_start
                                }
                                error={
                                  validation?.dataRates?.advancedOptions[
                                    indexAdvancedOption
                                  ]?.condition_base_weight_startText
                                }
                                disabled={
                                  advancedOption?.condition_type_id >= 3
                                }
                                onChange={(value) =>
                                  handleChangeTypeAdvancedOption(
                                    "condition_base_weight_start",
                                    value,
                                    indexAdvancedOption
                                  )
                                }
                              />
                              <Box width="6px" mt="20px">
                                <Divider />
                              </Box>
                              <Input.Number
                                size="small"
                                sufix={
                                  <Text color="black50" weight="bold">
                                    g
                                  </Text>
                                }
                                placeholder="Berat maksimum"
                                value={
                                  advancedOption?.condition_base_weight_end
                                }
                                isError={
                                  validation?.dataRates.advancedOptions[
                                    indexAdvancedOption
                                  ]?.condition_base_weight_end
                                }
                                error={
                                  validation?.dataRates?.advancedOptions[
                                    indexAdvancedOption
                                  ]?.condition_base_weight_endText
                                }
                                disabled={advancedOption?.condition_type_id < 3}
                                onChange={(value) =>
                                  handleChangeTypeAdvancedOption(
                                    "condition_base_weight_end",
                                    value,
                                    indexAdvancedOption
                                  )
                                }
                              />
                            </Flex>

                            <Flex>
                              <Input.Number
                                label="Ongkos kirim"
                                size="small"
                                prefix={
                                  <Text color="black50" weight="bold">
                                    Rp
                                  </Text>
                                }
                                value={advancedOption?.condition_base_tarif?.toString()}
                                isError={
                                  validation?.dataRates?.advancedOptions[
                                    indexAdvancedOption
                                  ]?.condition_base_tarif
                                }
                                error={
                                  validation?.dataRates?.advancedOptions[
                                    indexAdvancedOption
                                  ]?.condition_base_tarifText
                                }
                                onChange={(value) =>
                                  handleChangeTypeAdvancedOption(
                                    "condition_base_tarif",
                                    value,
                                    indexAdvancedOption
                                  )
                                }
                                placeholder="Masukkan Tarif"
                              />
                            </Flex>
                          </Flex>
                        )}
                    </Flex>
                  )
                )}
              </Flex>
            </Flex>
          )
        )}
        <Flex marginBottom="60px" />
      </Flex>
      <FixedBottomContainer>
        <Flex flexDirection="column" gap="16px" width="100%" padding="16px">
          <Button
            isBlock
            size="medium"
            variant="secondary"
            // disabled={isAllowAddCondition}
            onClick={handleAddAdvancedOption}
          >
            <Text variant="caption" weight="bold">
              Tambah Kondisi
            </Text>
          </Button>
          <Button
            isBlock
            size="medium"
            onClick={handleSubmit}
            // disabled={!isAllowAddCondition}
          >
            <Text variant="caption" weight="bold">
              Simpan
            </Text>
          </Button>
        </Flex>
      </FixedBottomContainer>

      <Drawer
        isVisible={disclosureDelete.isOpen}
        placement="bottom"
        isNestedDrawer={true}
        closable={true}
        zIndex={11}
        onClose={() => disclosureDelete.onClose()}
      >
        <Flex padding="16px 0" onClick={handleRemoveAdvancedOption}>
          <Text variant="caption">Hapus</Text>
        </Flex>
      </Drawer>

      <Drawer
        isVisible={disclosureCondition.isOpen}
        placement="bottom"
        isNestedDrawer={true}
        closable={true}
        zIndex={11}
        onClose={() => disclosureCondition.onClose()}
      >
        <Flex flexDirection="column" gap="16px" padding="16px 0">
          {conditionType.map((row, indexConditionType) => (
            <Flex
              flexDirection="column"
              gap="8px"
              key={`condition-base-${indexConditionType}`}
              onClick={() => {
                handleChangeTypeAdvancedOption(
                  "condition_type_id",
                  row.value,
                  selectedIndex
                );
                disclosureCondition.onClose();
              }}
            >
              <Text variant="caption" weight="semi-bold">
                {row.label}
              </Text>
              {indexConditionType < conditionType.length - 1 && <Divider />}
            </Flex>
          ))}
        </Flex>
      </Drawer>
    </Drawer>
  );
};

export default ModalAdvanceOption;
