import { useState, useEffect, useMemo } from "react";
import { useDeliveryContext } from "store/seller-fleet/reducer";
import constants from "store/seller-fleet/constants";
import { cloneDeep } from "renos-ui";

const useModalCity = ({
  isOpen,
  onClose,
  selectedIndex,
  submitCity,
  mutateCity,
  isLoadingCity,
}) => {
  const { state, dispatch } = useDeliveryContext();
  const { dataCoverage } = state;
  const [dataModal, setDataModal] = useState({
    listCity: [],
    selectedCity: [],
  });
  const [dataSearch, setDataSearch] = useState([]);

  const handleSearch = (event) => {
    const filterCity = dataModal.listCity.filter((cities) =>
      cities.city_name.toLowerCase().includes(event.target.value.toLowerCase())
    );
    setDataSearch([...filterCity]);
  };

  const isIncludeSelected = (idCity) => {
    const { selectedCity } = dataModal;
    const dataChecker = selectedCity.filter(
      (filterCity) => filterCity.city_id === idCity
    );

    return dataChecker.length > 0 && true;
  };

  const handleChangeCheckBox = (dataCity) => {
    let tempSelectedCity = dataModal.selectedCity;

    if (isIncludeSelected(dataCity.city_id)) {
      tempSelectedCity = tempSelectedCity.filter(
        (filterSelected) => filterSelected.city_id !== dataCity.city_id
      );
    } else {
      tempSelectedCity.push(dataCity);
    }
    setDataModal((prevState) => ({
      ...prevState,
      selectedCity: tempSelectedCity,
      changed: true,
    }));
  };

  const isCheckedAll = useMemo(() => {
    const listCityId = dataSearch?.map((cities) => cities.city_id);
    const filterSelected = dataModal.selectedCity.filter((filterCity) =>
      listCityId?.includes(filterCity.city_id)
    );
    const checkResult = {
      active: false,
      indeterminate: false,
    };
    if (filterSelected?.length >= listCityId?.length) checkResult.active = true;
    if (filterSelected?.length > 0 && !checkResult.active)
      checkResult.indeterminate = true;

    return checkResult;
  }, [dataModal, dataSearch]);

  const handleCheckAll = () => {
    let tempSelectedCity = dataModal.selectedCity;

    if (!isCheckedAll.active && !isCheckedAll.indeterminate) {
      tempSelectedCity = dataSearch;
    } else if (isCheckedAll.indeterminate) {
      const filterNotSelected = dataSearch.filter(
        (filterCity) => !isIncludeSelected(filterCity)
      );
      tempSelectedCity = tempSelectedCity.concat(filterNotSelected);
      const filterDuplicate = [
        ...new Map(
          tempSelectedCity.map((cities) => [cities.city_id, cities])
        ).values(),
      ];
      tempSelectedCity = filterDuplicate;
    } else {
      const listCityId = dataSearch.map((cities) => cities.city_id);
      const filterSelected = tempSelectedCity.filter(
        (filterCity) => !listCityId.includes(filterCity.city_id)
      );
      tempSelectedCity = filterSelected;
    }
    setDataModal((prevState) => ({
      ...prevState,
      selectedCity: tempSelectedCity,
      changed: true,
    }));
  };

  const handleSave = () => {
    const tempCoverage = dataCoverage;
    tempCoverage[selectedIndex].selectedCity = dataModal.selectedCity;

    const arrSelectedCity = tempCoverage[selectedIndex].selectedCity.map(
      (item) => item.city_id
    );

    const filteredDistrict = tempCoverage[
      selectedIndex
    ].selectedDistrict.filter((item) => arrSelectedCity.includes(item.city_id));
    tempCoverage[selectedIndex].selectedDistrict = filteredDistrict;

    dispatch({
      type: constants.GET_CITY_BY_INDEX,
      payload: {
        dataCoverage: tempCoverage,
      },
    });

    submitCity(`[${arrSelectedCity.join(",")}]`);
    onClose();
  };

  useEffect(() => {
    const selectedCoverage = dataCoverage[selectedIndex];

    if (selectedCoverage.listCity.length === 0 && isOpen) {
      mutateCity({
        ...selectedCoverage.selectedProvince,
        indexCoverage: selectedIndex,
      });
    }
    // eslint-disable-next-line
  }, [isOpen]);

  useEffect(() => {
    const selectedCoverage = dataCoverage[selectedIndex]
      ? dataCoverage[selectedIndex]
      : dataCoverage[selectedIndex - 1];

    if (!isLoadingCity) {
      setDataModal(cloneDeep(selectedCoverage));
      setDataSearch(cloneDeep(selectedCoverage?.listCity));
    }
    // eslint-disable-next-line
  }, [dataCoverage, isOpen, isLoadingCity]);


  return {
    state,
    dispatch,
    dataModal,
    setDataModal,
    dataSearch,
    setDataSearch,
    handleSearch,
    isIncludeSelected,
    handleChangeCheckBox,
    handleCheckAll,
    handleSave,
    isCheckedAll,
  };
};

export default useModalCity;