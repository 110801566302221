import { useMemo } from "react";
import { Button, Flex, Input, Text } from "renos-ui";
import VoucherCard from "./component/VoucherCard";
import TargetCard from "./component/TargetCard";
import Loading from "./component/Loading";

const TypeSection = ({
  values,
  setValues,
  isLoadingData,
  data,
  refetchData,
  isGetDataError,
  errors,
  setErrors,
  disabled,
}) => {
  const handleChangeVoucherType = (newId) => {
    if (disabled) return;

    const tempValue = { ...values, voucherTypeId: newId };

    if (newId === 1) {
      delete tempValue.product;
    } else {
      tempValue.product = [];
    }

    setValues(tempValue);
  };

  const handleChangeVoucherTarget = (newId) => {
    const tempValue = { ...values, voucherTargetId: newId };

    if (newId === 1) {
      delete tempValue.code;
    } else {
      tempValue.code = "";
    }

    setValues(tempValue);
  };

  const dataVoucherType = useMemo(
    () =>
      data?.data?.voucher_specific_type
        ? data?.data?.voucher_specific_type.map((voucher) => ({
            id: voucher.voucher_specific_type_id,
            name: voucher.voucher_specific_type_name,
            description: voucher.voucher_specific_type_description,
          }))
        : [],
    [data],
  );

  const dataVoucherTarget = useMemo(
    () =>
      data?.data?.voucher_target
        ? data?.data?.voucher_target.map((voucher) => ({
            id: voucher.voucher_target_id,
            name: voucher.voucher_target_name,
            description: voucher.voucher_target_description,
          }))
        : [],
    [data],
  );

  return (
    <Flex>
      <Flex flexDirection="column" gap="4px" width="320px">
        <Text color="black75" weight="bold">
          Jenis Voucher
        </Text>
        <Text color="black50">
          Pilih jenis voucher yang mau kamu <br /> buat.
        </Text>
      </Flex>
      {isLoadingData ? (
        <Loading />
      ) : isGetDataError ? (
        <Button onClick={refetchData}>Muat Ulang</Button>
      ) : (
        <Flex flexDirection="column" gap="24px" width="584px">
          <Flex flexDirection="column" gap="8px">
            <Text variant="caption" color="black50" weight="bold">
              Jenis Voucher
            </Text>
            <Flex gap="24px">
              {dataVoucherType.map((voucherType, indexVoucherType) => (
                <VoucherCard
                  key={`voucher-card${indexVoucherType}`}
                  isActive={values.voucherTypeId === voucherType.id}
                  onClick={() => handleChangeVoucherType(voucherType.id)}
                  title={voucherType.name}
                  description={voucherType.description}
                  isVoucherStore={voucherType.id === 1}
                  disabled={disabled}
                />
              ))}
            </Flex>
          </Flex>
          <Flex flexDirection="column" gap="8px">
            <Text variant="caption" color="black50" weight="bold">
              Target Voucher
            </Text>
            <Flex gap="24px">
              {dataVoucherTarget.map((voucherTarget, indexTarget) => (
                <TargetCard
                  key={`voucher-card${indexTarget}`}
                  isActive={values.voucherTargetId === voucherTarget.id}
                  onClick={() => handleChangeVoucherTarget(voucherTarget.id)}
                  title={voucherTarget.name}
                  description={voucherTarget.description}
                  disabled={disabled}
                />
              ))}
            </Flex>
          </Flex>

          {values.voucherTargetId === 2 && (
            <Input
              name="code"
              label="Kode Voucher"
              placeholder="Contoh:KODEPROMO"
              error="Nama voucher wajib diisi!"
              isError={errors.code}
              value={values.code || ""}
              onChange={(event) => {
                const textCode = event.target.value.replace(/ /g, "");

                setValues({ ...values, code: textCode.toUpperCase() });
                setErrors({ ...errors, code: false });
              }}
              maxLength={12}
              showCount
              disabled={disabled}
            />
          )}
        </Flex>
      )}
    </Flex>
  );
};

export default TypeSection;
