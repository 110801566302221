import React from "react";
import { Text, Flex, Icon, Grid, useDisclosure, Button, Box } from "renos-ui";
import MobileLayout from "layouts/MobileLayout";
import { useGetSellerFleetSetting } from "services/logistics/sellerFleet";
import MainAddress from "./MainAddress";
import { useDeliveryContext } from "store/seller-fleet/reducer";
import constants from "store/seller-fleet/constants";
import DrawerById from "./DrawerById";
import { useHistory } from "react-router-dom";
import LoadingList from "../components/LoadingList";
import mobileUrl from "constants/mobileUrl";

const ListSetting = () => {
  const history = useHistory();
  const { dispatch } = useDeliveryContext();
  const {
    isError,
    isLoading,
    isFetching,
    refetch,
    data: list,
  } = useGetSellerFleetSetting();

  const showDisclosure = useDisclosure({});

  const openDrawer = (row) => {
    dispatch({
      type: constants.RESET_TO_INTIAL_STATE,
    });
    dispatch({
      type: constants.GET_LIST_SETTING_BY_ID,
      payload: {
        listDetail: row,
      },
    });
    showDisclosure.onOpen();
  };

  return (
    <MobileLayout
      header={{
        title: "Daftar Pengaturan",
        withBack: true,
        isHomepage: true,
        withoutGoBack: true,
        onBack: () => history.push(mobileUrl.STORE_SELLER_FLEET),
        suffix: (
          <Flex
            alignItems="center"
            cursor="pointer"
            onClick={() => {
              dispatch({
                type: constants.RESET_TO_INTIAL_STATE,
              });
              history.push("/account/set-delivery-region");
            }}
          >
            <Icon name="Plus-outline" color="black50" cursor="pointer" />
          </Flex>
        ),
      }}
    >
      <Flex
        flexDirection="column"
        backgroundColor="black5"
        height="100vh"
        overflow="scroll"
      >
        <Flex
          backgroundColor="yellow5"
          gap="16px"
          padding="16px"
          alignItems="center"
        >
          <Flex>
            <Icon name="Info-solid" color="yellow50" />
          </Flex>
          <Text color="yellow100" variant="caption">
            Jika kamu ganti alamat utama, maka tarif yang tersedia harus diganti
            secara manual.
          </Text>
        </Flex>

        <MainAddress />

        <Flex flexDirection="column" gap="8px">
          {isError ? (
            <Text onClick={refetch}>muat ulang</Text>
          ) : isLoading || isFetching ? (
            <LoadingList />
          ) : list?.data.length === 0 ? (
            <Flex
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              gap="8px"
              padding="16px"
            >
              <Flex
                as="img"
                alt="empty-review"
                src={`/assets/images/mobile/stok-kosong.png`}
              />

              <Flex
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                gap="8px"
                marginBottom="16px"
              >
                <Text weight="bold" variant="regular" color="black70">
                  Belum ada pengaturan kurir
                </Text>
                <Box fontSize="12px" textAlign="center">
                  Tambahkan pengaturan kurir agar pembeli dapat memilih layanan
                  pengiriman yang tersedia.
                </Box>
              </Flex>
              <Button
                isBlock
                size="small"
                onClick={() => history.push("/account/set-delivery-region")}
              >
                Tambah Pengaturan
              </Button>
            </Flex>
          ) : (
            list?.data?.map((row, indexRow) => {
              const {
                advanced_option_pricing: { constraints: advancedOption = [] },
              } = row;

              return advancedOption?.length > 0 ? (
                <Flex
                  padding="16px"
                  gap="20px"
                  flexDirection="column"
                  background="white"
                  key={`fragment-list-${row.id}-${indexRow}`}
                >
                  <Flex justifyContent="space-between" alignItems="center">
                    <Flex flexDirection="column" gap="4px" flexGrow="1">
                      <Text variant="caption" color="black50">
                        Cara Pengaturan
                      </Text>
                      <Text variant="caption" color="black75" weight="bold">
                        Opsi Lanjutan
                      </Text>
                    </Flex>

                    <Icon
                      name="Vertical-outline"
                      color="black50"
                      onClick={() => openDrawer(row)}
                    />
                  </Flex>
                  <Grid gridTemplateColumns="50% 50%">
                    <Flex flexDirection="column" gap="4px" flexGrow="1">
                      <Text variant="caption" color="black50">
                        Cakupan Wilayah
                      </Text>
                      <Text
                        variant="caption"
                        color="black75"
                        weight="bold"
                        lineClamp={1}
                      >
                        {row?.coverage_summary}
                      </Text>
                    </Flex>
                    <Flex flexDirection="column" gap="4px" flexGrow="1">
                      <Text variant="caption" color="black50">
                        Estimasi Tiba
                      </Text>
                      <Text
                        variant="caption"
                        color="black75"
                        weight="bold"
                        lineClamp={1}
                      >
                        {row.estimated_shipping_days} hari kerja
                      </Text>
                    </Flex>
                  </Grid>
                </Flex>
              ) : (
                <Flex
                  padding="16px"
                  gap="20px"
                  flexDirection="column"
                  background="white"
                  key={`fragment-list-${row.id}-${indexRow}`}
                >
                  <Flex justifyContent="space-between" alignItems="center">
                    <Flex flexDirection="column" gap="4px" flexGrow="1">
                      <Text variant="caption" color="black50">
                        Cara Pengaturan
                      </Text>
                      <Text variant="caption" color="black75" weight="bold">
                        {row.pricing.label}
                      </Text>
                    </Flex>

                    <Icon
                      name="Vertical-outline"
                      color="black50"
                      onClick={() => openDrawer(row)}
                    />
                  </Flex>

                  <Grid gridTemplateColumns="50% 50%">
                    <Flex flexDirection="column" gap="4px" flexGrow="1">
                      <Text variant="caption" color="black50">
                        Cakupan Wilayah
                      </Text>
                      <Text
                        variant="caption"
                        color="black75"
                        weight="bold"
                        lineClamp={1}
                      >
                        {row?.coverage_summary}
                      </Text>
                    </Flex>
                    <Flex flexDirection="column" gap="4px" flexGrow="1">
                      <Text variant="caption" color="black50">
                        Estimasi Tiba
                      </Text>
                      <Text
                        variant="caption"
                        color="black75"
                        weight="bold"
                        lineClamp={1}
                      >
                        {row.estimated_shipping_days} hari kerja
                      </Text>
                    </Flex>
                  </Grid>
                </Flex>
              );
            })
          )}
        </Flex>
      </Flex>

      {showDisclosure.isOpen && (
        <DrawerById
          isVisible={showDisclosure.isOpen}
          onClose={() => showDisclosure.onClose()}
          refetch={refetch}
        />
      )}
    </MobileLayout>
  );
};

export default ListSetting;
