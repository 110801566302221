import { Button, Flex, Icon, Text, useDisclosure } from "renos-ui";
import Empty from "./Empty";
import ProductPicker from "./ProductPicker";
import Table from "./Table";

const ProductSection = ({
  value,
  onChange,
  isError,
  disabled,
  isOngoing,
  invalid,
}) => {
  const addDisclosure = useDisclosure({ isOpen: false });

  return (
    <Flex flexDirection="column" gap="48px">
      <Flex gap="8px" flexDirection="column">
        <Flex alignItems="center" justifyContent="space-between">
          <Flex gap="16px">
            <Flex
              height="24px"
              width="4px"
              backgroundColor="blue50"
              borderBottomLeftRadius="8px"
              borderBottomRightRadius="8px"
            />
            <Text variant="subtitle" weight="bold" color="black75">
              Daftar Produk
            </Text>
          </Flex>
          <Button
            preffix={<Icon name="Plus-outline" size={20} color="blue50" />}
            variant="secondary"
            onClick={addDisclosure.onOpen}
            disabled={isOngoing || disabled}
          >
            Tambah Produk
          </Button>
        </Flex>

        <Text color="black50">Jumlah Produk ({value.length}/50 Produk)</Text>
        {isError && (
          <Text variant="caption" color="red50">
            Mohon pilih produkmu terlebih dahulu
          </Text>
        )}
      </Flex>

      {value.length === 0 ? (
        <Empty />
      ) : (
        <Table
          value={value}
          onChange={onChange}
          disabled={isOngoing || disabled}
          invalid={invalid}
        />
      )}

      <ProductPicker
        value={value}
        addDisclosure={addDisclosure}
        onChange={onChange}
      />
    </Flex>
  );
};

export default ProductSection;
