import styled from "styled-components";
import { colors, Input, SelectInput } from "renos-ui";

export const StyledInput = styled(Input)`
  font-size: 14px;
  color: ${colors.black75} !important;
`;

export const StyledSelectInput = styled(SelectInput)`
  width: 270px;
  color: ${colors.black75} !important;
  font-size: 14px !important;

  .select__control {
    padding: 9px 12px;
  }
`;
