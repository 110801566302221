import StoreLayoutContext from "providers/StoreLayoutProvider";
import { useContext, useEffect, useState } from "react";
import { Flex, Text, range } from "renos-ui";
import EmptyCard from "../EmptyCard";
import CardProduct from "../CardProduct";
import SwiperContext, { SwiperProvider } from "providers/SwiperProvider";
import { StyledSwiper } from "./styled";
import { SwiperSlide, useSwiper } from "swiper/react";

const ProductDiscount = ({ isPreviewMode }) => {
  const { dataHomePage, discountRef } = useContext(StoreLayoutContext);
  const [dataProductDiscount, setDataProductDiscount] = useState({});
  const [swiperInstance, setSwiperInstance] = useState();

  useEffect(() => {
    const selectedNewPrduct = dataHomePage.filter(
      (homepage) => homepage.id === 6
    )[0];
    setDataProductDiscount(selectedNewPrduct);
  }, [dataHomePage]);

  if (isPreviewMode && !dataProductDiscount?.isActive) return <></>;

  return (
    <Flex gap="16px" flexDirection="column" ref={discountRef}>
      <Flex justifyContent="space-between" alignItems="center">
        <Text weight="bold">Produk Diskon</Text>
        {dataProductDiscount?.isActive && (
          <Text variant="caption" weight="bold" color="blue50">
            Lihat Semua
          </Text>
        )}
      </Flex>
      {dataProductDiscount?.isActive &&
      dataProductDiscount?.data?.length !== 0 ? (
        dataProductDiscount?.data?.length > 2 ? (
          <SwiperProvider value={{ setSwiperInstance, swiperInstance }}>
            <Flex position="relative">
              <StyledSwiper slidesPerView={2} spaceBetween={8}>
                <Flex>
                  {dataProductDiscount?.data.map((product, indexProduct) => {
                    return (
                      <SwiperSlide key={`swiper-${indexProduct}`}>
                        <CardProduct
                          data={product}
                          key={`product-card${indexProduct}`}
                          isDiscount
                        />
                      </SwiperSlide>
                    );
                  })}
                </Flex>
                <SwiperInstance />
              </StyledSwiper>
            </Flex>
          </SwiperProvider>
        ) : (
          <Flex gap="8px">
            {dataProductDiscount?.data.map((product, indexProduct) => {
              return (
                <CardProduct
                  data={product}
                  key={`product-card${indexProduct}`}
                  isDiscount
                />
              );
            })}
          </Flex>
        )
      ) : (
        <Flex gap="8px">
          {range(2).map(() => (
            <EmptyCard />
          ))}
        </Flex>
      )}
    </Flex>
  );
};

const SwiperInstance = () => {
  const { setSwiperInstance } = useContext(SwiperContext);
  const swiper = useSwiper();

  useEffect(() => {
    if (swiper) return setSwiperInstance(swiper);
  }, [swiper, setSwiperInstance]);
};

export default ProductDiscount;
