import { Divider, Flex, Skeleton, range } from "renos-ui";

const SkeletonReview = ({ itemShow = 1, showFilter = true }) => {
  return (
    <Flex flexDirection="column">
      {showFilter && (
        <>
          <Flex p="16px" gap="16px" justifyContent="space-between">
            <Skeleton height="38px" width="75%" borderRadius="100px" />
            <Skeleton height="38px" width="25%" borderRadius="100px" />
          </Flex>
          <Divider />
        </>
      )}
      <Flex backgroundColor="black5" gap="16px" p="16px" flexDirection="column">
        {range(itemShow).map((item) => (
          <Flex
            key={`skeleton-review-${item}`}
            gap="16px"
            flexDirection="row"
            p="16px"
            backgroundColor="white"
            borderRadius="8px"
          >
            <Flex width={"100%"} flexDirection="column" gap="8px" pt="4px">
              <Skeleton height="20px" width="45%" />
              <Skeleton height="15px" width="20%" />
              <Skeleton height="15px" width="70%" />
              <Skeleton height="15px" width="50%" />
              <Flex flexDirection="row" gap="16px">
                <Skeleton height="40px" width="40px" />
                <Skeleton height="40px" width="40px" />
              </Flex>
            </Flex>
            <Flex>
              <Skeleton height="20px" width="30px" />
            </Flex>
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
};

export default SkeletonReview;
