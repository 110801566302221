import { useState, useContext } from "react";
import { Button, Flex, Text, useToast, useDisclosure, Drawer } from "renos-ui";
import {
  useGetStoreOpen,
  useUpdateOpenStore,
  useGetStoreOpenView,
} from "services/settings/status";
import StoreContext from "providers/StoreProvider";
import ScheduleItem from "./ScheduleItem";
import { setGTM } from "helpers";
import LoadingOpenStore from "./LoadingOpenStore";
import { useHistory } from "react-router-dom";

const OpenStore = () => {
  const {
    storeData: {
      data: { id: storeId, has_product },
    },
  } = useContext(StoreContext);
  const history = useHistory();
  const toast = useToast();
  const [data, setData] = useState([]);
  const { isLoading: isLoadData, isFetching } = useGetStoreOpen(
    {
      store_id: storeId,
    },
    {
      onSuccess: (data) => {
        setData(data);
        // ini nanti dipakai lagi
        // const checkNewStore = data.some(
        //   (item) =>
        //     item.id === 0 &&
        //     item.is_active === false &&
        //     item.is_fullday === false
        // );

        if (!has_product) {
          showDisclosure.onOpen();
        }
      },
    }
  );
  // eslint-disable-next-line no-unused-vars
  const [isDisabled, setIsDisabled] = useState(false);
  const showDisclosure = useDisclosure({});

  const { refetch: refetchViewData } = useGetStoreOpenView({
    store_id: storeId,
  });

  const { mutate: updateOpenStore, isLoading: isSaveLoading } =
    useUpdateOpenStore({
      onError: () => {
        toast({
          label: "Gagal mengubah Jadwal Buka Toko",
          placement: "bottom",
        });
      },
      onSuccess: () => {
        toast({
          label: "Jadwal operasional berhasil diubah.",
          placement: "bottom",
        });
        refetchViewData();
      },
    });

  const handleGTM = (label) => {
    setGTM("seller_center_section", {
      event_category: "store_settings | status",
      event_action: "click",
      event_label: label,
    });
  };

  const handleSave = (event) => {
    handleGTM("set_open_shop | save_settings");
    event.preventDefault();

    const body = {
      data: {
        store_open_details: data,
      },
    };

    updateOpenStore(body);
  };

  return (
    <Flex backgroundColor="white" flexDirection="column" overflow="auto">
      <Flex flexDirection="column" gap="24px" width="100%" flexWrap="wrap">
        <Flex flexDirection="column" padding="16px" gap="16px">
          {isLoadData || isFetching ? (
            <LoadingOpenStore />
          ) : (
            <>
              <Text weight="bold" variant="body">
                Jadwal Operasional
              </Text>
              {data?.map((list, index) => {
                return (
                  <ScheduleItem
                    list={list}
                    index={index}
                    setData={setData}
                    data={data}
                    setIsDisabled={setIsDisabled}
                  />
                );
              })}

              <Drawer
                isVisible={showDisclosure.isOpen}
                placement="bottom"
                closable={true}
                onClose={() => showDisclosure.onClose()}
              >
                <Flex flexDirection="column" gap="16px" marginTop="16px">
                  <Text variant="caption" weight="bold">
                    Tokomu Belum Memiliki Produk Yang Aktif
                  </Text>
                  <Text variant="caption">
                    Tokomu masih dianggap tutup karena belum memiliki produk
                    yang aktif. Yuk, tambahkan produk pertamamu dan mulai
                    berjualan.
                  </Text>
                  <Button
                    size="medium"
                    isBlock
                    onClick={() => history.push("/products/list")}
                  >
                    Tambah Produk
                  </Button>
                </Flex>
              </Drawer>
            </>
          )}
        </Flex>

        <Flex
          bottom="0"
          width="100%"
          position="fixed"
          justifyContent="center"
          alignItems="center"
          backgroundColor="white"
          boxShadow="0px 0px 8px 0px rgba(20, 20, 32, 0.05);"
        >
          <Flex width="100%" padding="16px">
            <Button
              size="medium"
              isBlock
              onClick={handleSave}
              disabled={isLoadData || isSaveLoading}
            >
              Simpan
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default OpenStore;
