import { StyledTextArea } from "../styled";
import { Box, Button, Flex, Text, Dialog, Input, Spinner } from "renos-ui";

const ModalAdd = ({
  addModal,
  mode,
  handleChangeValue,
  value,
  handleSaveSelect,
  templateList,
  handleSave,
  isSaveLoading,
  textAreaCount,
  textInputCount,
  handleClose,
  error,
}) => {
  return (
    <Dialog
      isVisible={addModal.isOpen}
      closable
      onClose={handleClose}
      verticalCentered
      width="432px"
      height="auto"
      padding="40px"
    >
      <Flex flexDirection="column" width="100%" gap="24px">
        <Text variant="heading3" weight="bold">
          {mode === "add" ? "Buat" : "Ubah"} Template
        </Text>

        <Flex flexDirection="column" gap="16px">
          <Text variant="caption" color="black50" weight="bold">
            Nama Template
          </Text>
          <Box width="100%">
            <Flex flexDirection="column" gap="8px">
              <Input
                maxLength={30}
                name="name"
                placeholder="Masukan nama template"
                value={value?.name}
                onChange={handleChangeValue}
              />
              <Flex justifyContent="space-between">
                <Flex>
                  {error.name === true && (
                    <Text color="red50" variant="body">
                      Nama template minimal 5 karakter
                    </Text>
                  )}
                </Flex>
                <Flex>
                  <Text color="black50" variant="body">
                    {textInputCount}/30
                  </Text>
                </Flex>
              </Flex>
            </Flex>
          </Box>
        </Flex>

        <Flex flexDirection="column" gap="16px">
          <Text variant="caption" color="black50" weight="bold">
            Isi Template
          </Text>
          <Flex flexDirection="column" gap="8px">
            <StyledTextArea
              maxLength={200}
              name="message"
              placeholder="Masukan template balasan"
              value={value?.message}
              onChange={(event) => handleChangeValue(event)}
            />
            <Flex justifyContent="space-between">
              <Flex>
                {error.message && (
                  <Text color="red50" variant="body">
                    Pesan template minimal 5 karakter
                  </Text>
                )}
              </Flex>
              <Flex>
                <Text color="black50" variant="body">
                  {textAreaCount}/200
                </Text>
              </Flex>
            </Flex>
          </Flex>
        </Flex>

        <Flex justifyContent="space-between" gap="16px">
          <Button isBlock variant="secondary" onClick={handleClose}>
            Batal
          </Button>
          <Button
            isBlock
            onClick={templateList?.length === 0 ? handleSaveSelect : handleSave}
            disabled={
              value?.id === "" || value?.message === "" || isSaveLoading
            }
            preffix={isSaveLoading && <Spinner size="16px" color="blue10" />}
          >
            Simpan
          </Button>
        </Flex>
      </Flex>
    </Dialog>
  );
};

export default ModalAdd;
