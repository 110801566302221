import React, { useEffect, useState } from "react";
import {
  Button,
  Divider,
  Drawer,
  Flex,
  Icon,
  Input,
  Text,
  colors,
  isEmpty,
} from "renos-ui";

const VariantTypeDrawer = ({
  disclosure,
  variantActive,
  onVariantValueChange,
  activeVariantIndex,
  item,
}) => {
  const { isOpen, onClose } = disclosure;

  const [options, setOptions] = useState(variantActive?.options);
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    if (isEmpty(variantActive) && isEmpty(variantActive?.options)) return;

    setOptions(variantActive.options);
  }, [variantActive]);

  const onSearch = (event) => {
    if (event.target.value) {
      const tempOptions = [...options];
      const filteredOptions = tempOptions.filter((item) =>
        item.label.toLowerCase().includes(event.target.value.toLowerCase())
      );

      setOptions(filteredOptions);
      setSearchValue(event.target.value);

      return;
    } else {
      setSearchValue("");
    }

    setOptions(variantActive.options);
  };

  const onDrawerClose = () => {
    setSearchValue("");
    setOptions(variantActive?.options);
    onClose();
  };

  return (
    isOpen && (
      <Drawer
        isVisible={isOpen}
        onClose={onDrawerClose}
        closable={true}
        placement="bottom"
        header={{
          title: variantActive.variant_name,
        }}
      >
        <Flex height="50vh" flexDirection="column" pt="32px" rowGap="16px">
          <Input
            size="small"
            prefix={<Icon name="Search-outline" color="black50" size={16} />}
            placeholder="Ketik atau pilih tipe varian "
            onChange={onSearch}
            style={{
              backgroundColor: colors.black5,
            }}
          />

          <Flex flexDirection="column" rowGap="16px" overflow="auto" flex={1}>
            {options?.map((variant) => {
              const isActive = variantActive.variant_values
                .map((item) => item?.variant_value_id)
                .includes(variant.variant_value_id);

              return (
                <Flex
                  flexDirection="column"
                  rowGap="16px"
                  flexShrink={0}
                  onClick={() =>
                    onVariantValueChange(
                      variant,
                      activeVariantIndex,
                      variantActive
                    )
                  }
                  key={`variant-item-${variant.variant_value_id}`}
                >
                  <Flex justifyContent="space-between" alignItems="center">
                    <Text
                      variant="caption"
                      color={isActive ? "blue50" : "black100"}
                      weight={isActive && "bold"}
                    >
                      {variant.label}
                    </Text>
                    {isActive && (
                      <Icon name="Checkmark-outline" color="blue50" size={20} />
                    )}
                  </Flex>
                  <Divider />
                </Flex>
              );
            })}
          </Flex>
          <Divider />
          {options?.length === 0 && (
            <Button
              isBlock
              onClick={() => {
                onVariantValueChange(
                  {
                    label: searchValue,
                    value: searchValue,
                    variant_id: variantActive.variant_id,
                    variant_name: variantActive.variant_name,
                    variant_value: searchValue,
                    __isNew__: true,
                  },
                  activeVariantIndex,
                  variantActive
                );
                onDrawerClose();
              }}
            >
              Tambahkan
            </Button>
          )}
        </Flex>
      </Drawer>
    )
  );
};

export default VariantTypeDrawer;
