import React from "react";
import { useHistory } from "react-router-dom";
import { Button, Flex, Text } from "renos-ui";

const NotFoundPage = () => {
  const { push } = useHistory();

  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="100dvh"
      rowGap="24px"
      px="28px"
    >
      <Flex
        as="img"
        alt="not-found"
        src="/assets/images/error-state/state-404.svg"
      />
      <Flex
        flexDirection="column"
        alignItems="center"
        textAlign="center"
        rowGap="8px"
        mb="8px"
      >
        <Text variant="subtitle" weight="bold">
          Maaf, tujuanmu nggak ada
        </Text>
        <Text color="black50">
          Wah, sepertinya halaman yang kamu cari tidak ada atau salah. Yuk,
          kembali ke beranda.
        </Text>
      </Flex>
      <Button isBlock onClick={() => push("/")}>
        Kembali
      </Button>
    </Flex>
  );
};

export default NotFoundPage;
