import { Button, Drawer, Flex, Spinner, Text, useToast } from "renos-ui";
import { useConfirmOrder } from "services/order-management";

const PopupConfirm = ({
  disclosureConfirm: { isOpen, onClose },
  orderId,
  toFirstPage,
}) => {
  const toast = useToast();

  const { mutate: confirmOrder, isLoading } = useConfirmOrder({
    onSuccess: (data) => {
      const { message, frontend_message } = data;
      toFirstPage();
      onClose();
      toast({
        label: frontend_message || message || "Pesanan berhasil diterima",
        placement: "bottom",
      });
    },
    onError: (data) => {
      const {
        response: {
          data: { frontend_message, message },
        },
      } = data;
      toast({
        label: frontend_message || message || "Pesanan gagal diterima",
        placement: "bottom",
        backgroundColor: "red50",
      });
    },
  });

  const handleConfirm = () => {
    const formData = new FormData();
    formData.append("id", orderId);

    confirmOrder(formData);
  };

  return (
    <Drawer isVisible={isOpen} onClose={onClose} closable placement="bottom">
      <Flex flexDirection="column" width="100%" gap="24px" height="100%">
        <Flex gap="4px" flexDirection="column">
          <Text variant="subtitle" weight="bold">
            Terima Pesanan ini?
          </Text>
          <Text color="black75">
            Pastikan terlebih dahulu stok produk ini masih tersedia sebelum
            menerima pesanan pembeli.
          </Text>
        </Flex>

        <Flex gap="8px">
          <Button variant="tertiary" size="medium" isBlock onClick={onClose}>
            Kembali
          </Button>
          <Button
            size="medium"
            isBlock
            onClick={handleConfirm}
            disabled={isLoading}
            preffix={isLoading && <Spinner color="white" size="8px" />}
          >
            Terima
          </Button>
        </Flex>
      </Flex>
    </Drawer>
  );
};

export default PopupConfirm;
