import { Badge, Box, Disclosure, Flex } from "renos-ui";
import styled from "styled-components";

export const StyledDisclosure = styled(Disclosure)`
  width: 100%;
`;

export const NavLink = styled(Box)`
  text-decoration: none;
  cursor: pointer;
  padding-left: 44px;
`;

export const SidebarWrapper = styled(Flex)`
  transition: all 0.2s ease;
`;

export const StyledBadge = styled(Badge)`
  > div {
    right: -24px;
    top: 5px;
  }
`;

export const StyledBadgeMinimized = styled(Badge)`
  > div {
    right: -17px;
    top: -9px;
  }
`;
