import React from "react";
import { Box, Flex, Text } from "renos-ui";

const FormLayout = ({
  children,
  title,
  isFilled,
  required,
  action,
  suffix,
  rowGap = "16px",
}) => {
  return (
    <Flex rowGap={rowGap} flexDirection="column">
      <Flex justifyContent="space-between" width="100%">
        <Flex columnGap="4px">
          <Text weight="bold">{title}</Text>
          {required && <Text color="red50">*</Text>}
        </Flex>
        {action && (
          <Box onClick={() => action?.()}>
            <Text color="blue50">{isFilled ? "Ubah" : "Tambah"}</Text>
          </Box>
        )}
        {suffix}
      </Flex>
      {children}
    </Flex>
  );
};

export default FormLayout;
