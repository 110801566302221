import { Drawer, useToast } from "renos-ui";
import { useState } from "react";
import { useRejectOrder } from "services/order-management";
import ListChoice from "./ListChoice";
import Form from "./Form";

const PopupReason = ({ isReasonOpen, onReasonClose, toFirstPage, orderId }) => {
  const toast = useToast();
  const [selectedReason, setSelectedReason] = useState(1);
  const [isFormMode, setIsFormMode] = useState(false);

  const { mutate: rejectOrder, isLoading } = useRejectOrder({
    onSuccess: (data) => {
      const { message, frontend_message } = data;
      toFirstPage();
      onReasonClose();

      toast({
        label: frontend_message || message || "Pesanan berhasil ditolak",
        placement: "bottom",
      });
    },
    onError: (data) => {
      const {
        response: {
          data: { frontend_message, message },
        },
      } = data;
      toast({
        label: frontend_message || message || "Pesanan gagal ditolak",
        placement: "bottom",
        backgroundColor: "red50",
      });
    },
  });

  const handleReject = (cancellationParam) => {
    rejectOrder(cancellationParam);
  };

  // const renderChoice = useMemo(() => {}, [selectedReason]);

  return (
    <Drawer
      isVisible={isReasonOpen}
      onClose={onReasonClose}
      closable
      placement="bottom"
    >
      {isFormMode ? (
        <Form
          {...{
            selectedReason,
            setIsFormMode,
            handleReject,
            orderId,
            isLoading,
          }}
        />
      ) : (
        <ListChoice
          {...{
            selectedReason,
            setSelectedReason,
            setIsFormMode,
            onReasonClose,
          }}
        />
      )}
    </Drawer>
  );
};

export default PopupReason;
