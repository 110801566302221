/* eslint-disable no-unused-vars */
import { useMemo, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Box, Checkbox, Flex, Text, isEmpty, useDebounce } from "renos-ui";
import { useGetProductsModal } from "services/product-showcase";

const useAddModalShowcase = ({
  addDisclosure,
  setParamsIds,
  checkedParent,
}) => {
  const [checkedProducts, setCheckedProducts] = useState(checkedParent);
  const maxData = 50;
  const { push } = useHistory();
  const [__, setOrdering] = useState(null);
  const [searchParam, setSearchParam] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [page, setPage] = useState(1);
  const [dataModal, setDataModal] = useState([]);
  const [filters, setFilters] = useState({
    product_condition_ids: null,
    product_stock_value: null,
    product_variant_only: null,
    product_stock_comparison_value: null,
  });

  const [order, setOrder] = useState({
    order_by: "",
    ordering: "",
  });

  const dataFilter = [
    {
      value: null,
      label: "Semua",
    },
    {
      value: "product_condition_id 1",
      label: "Baru",
    },
    {
      value: "product_condition_id 2",
      label: "Bekas",
    },
    {
      value: "eq",
      label: "Stok Kosong",
    },
    {
      value: "varian",
      label: "Dengan varian",
    },
  ];

  const dataSort = [
    {
      value: null,
      label: "Terlaris",
    },
    {
      value: "name",
      label: "Nama A - Z",
    },
    {
      value: "name 2",
      label: "Nama Z - A",
    },
    {
      value: "desc",
      label: "Harga Tertinggi",
    },
    {
      value: "asc",
      label: "Harga Terendah",
    },
    {
      value: "stock",
      label: "Stok Tersedikit",
    },
    {
      value: "stock 2",
      label: "Stok Terbanyak",
    },
  ];

  const {
    data: dataTempModal,
    isLoading: isLoadingModal,
    isRefetching: fetchDataModal,
  } = useGetProductsModal(
    {
      keyword: searchParam,
      product_condition_ids: filters.product_condition_ids,
      stock: filters.product_stock_value,
      product_stock_comparison_value: filters.product_stock_comparison_value,
      product_variant_only: filters.product_variant_only,
      order_by: order.order_by,
      ordering: order.ordering,
      page: page,
      limit: 50,
    },
    {
      onSuccess: (data) => {
        if (page === 1) {
          setDataModal([...data.data]);
        } else {
          const concatData = dataModal?.concat(data.data);
          setDataModal(concatData);
        }
      },
      onError: (error) => {
        console.error("error", error);
      },
    }
  );

  const hasNextPage = useMemo(
    () =>
      dataTempModal?.meta?.pagination?.next_page_url !== "" &&
      dataTempModal?.meta?.pagination?.next_page_url,
    // eslint-disable-next-line
    [dataModal]
  );

  const handleFilterChange = (values) => {
    switch (values.label) {
      case "Baru":
        setFilters({
          product_condition_ids: 1,
        });
        break;
      case "Bekas":
        setFilters({
          product_condition_ids: 2,
        });
        break;
      case "Stok Kosong":
        setFilters({
          product_stock_comparison_value: "eq",
          product_stock_value: 0,
        });
        break;
      case "Dengan varian":
        setFilters({
          product_variant_only: true,
        });
        break;
      default:
        setFilters({
          product_condition_ids: null,
          product_stock_value: null,
          product_variant_only: null,
          product_stock_comparison_value: null,
        });
    }
  };

  const handleOrderChange = (name, values) => {
    switch (name.label) {
      case "Terlaris":
        setOrder({
          order_by: "total_sold",
          ordering: "desc",
        });
        break;
      case "Nama A - Z":
        setOrder({
          order_by: "product_name",
          ordering: "asc",
        });
        break;
      case "Nama Z - A":
        setOrder({
          order_by: "product_name",
          ordering: "desc",
        });
        break;
      case "Harga Tertinggi":
        setOrder({
          ordering: "desc",
          order_by: "product_price",
        });
        break;
      case "Harga Terendah":
        setOrder({
          ordering: "asc",
          order_by: "product_price",
        });
        break;
      case "Stok Tersedikit":
        setOrder({
          ordering: "asc",
          order_by: "product_stock",
        });
        break;
      case "Stok Terbanyak":
        setOrder({
          ordering: "desc",
          order_by: "product_stock",
        });
        break;
      default:
        setOrder({
          order_by: "",
          ordering: "",
        });
    }

    setOrdering(values?.value || "");
  };

  const isChecked = (id, data) => {
    return data.includes(id);
  };

  const onCheckAllClicked = () => {
    let tempCheckedProducts = checkedProducts;

    if (tempCheckedProducts.length < maxData) {
      // eslint-disable-next-line
      const calculateChecked = dataModal.map((products) => {
        if (
          tempCheckedProducts.length !== maxData &&
          !isChecked(products.product_id, checkedProducts)
        ) {
          tempCheckedProducts.push(products.product_id);
        }
      });
    } else {
      tempCheckedProducts = [];
    }

    setCheckedProducts([...tempCheckedProducts]);
  };

  const isIndeterminate = useMemo(() => {
    if (checkedProducts && dataModal) {
      return (
        checkedProducts.length > 0 && checkedProducts.length < dataModal?.length
      );
    }

    return false;
  }, [checkedProducts, dataModal]);

  const onCheckProductClicked = (
    dataProduct,
    checkedProducts,
    setCheckedProducts
  ) => {
    let tempCheckedProducts = checkedProducts;

    if (isChecked(dataProduct.product_id, checkedProducts)) {
      tempCheckedProducts = tempCheckedProducts.filter(
        (productFilter) => productFilter !== dataProduct.product_id
      );
    } else {
      tempCheckedProducts.push(dataProduct.product_id);
    }
    setCheckedProducts([...tempCheckedProducts]);
  };

  const isCheckDisabled = (id, data, maxProduct) => {
    return data.length < maxProduct ? false : !isChecked(id, data);
  };

  const isCheckedAll = useMemo(
    () => checkedProducts.length === maxData,
    // eslint-disable-next-line
    [checkedProducts, dataModal]
  );

  const tableColumns = [
    {
      title: (
        <Checkbox
          checked={isCheckedAll || isIndeterminate}
          onChange={onCheckAllClicked}
          indeterminate={isIndeterminate}
        />
      ),
      dataIndex: "check",
      key: "checkBox",
      render: (_, data) => {
        const { product_id: id } = data;

        return (
          <Flex>
            <Checkbox
              checked={isChecked(id, checkedProducts)}
              onChange={() =>
                onCheckProductClicked(data, checkedProducts, setCheckedProducts)
              }
              disabled={isCheckDisabled(id, checkedProducts, maxData)}
            />
          </Flex>
        );
      },
      width: "5%",
      align: "center",
    },
    {
      title: (
        <Text color="black75" weight="bold">
          Produk
        </Text>
      ),
      dataIndex: "product",
      key: "product",
      width: "50%",
      render: (_, data) => {
        const {
          product_image_url: image,
          product_name: name,
          product_type,
          product_skus: variant,
        } = data;

        const totalVariant =
          variant !== null
            ? variant?.length > 5
              ? "+5"
              : variant?.length
            : [];

        return (
          <Flex gap="24px">
            <Box
              as="img"
              src={image}
              width="56px"
              height="56px"
              borderRadius="8px"
              backgroundSize="cover"
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = "/assets/images/empty-product.svg";
              }}
            />
            <Flex flexDirection="column" gap="4px">
              <Text
                weight="bold"
                style={{
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  width: "240px",
                }}
              >
                {name}
              </Text>
              <Flex flexDirection="column">
                {data?.product_skus !== null && (
                  <Text color="black50" variant="caption">
                    SKU: {data?.product_skus[0]?.product_sku_mpn}
                  </Text>
                )}
              </Flex>
              {product_type === "variant" && (
                <Flex
                  justifyContent="center"
                  alignItems="center"
                  backgroundColor="black5"
                  borderRadius="100px"
                  width="62px"
                  height="16px"
                >
                  <Text color="black50" weight="bold" variant="small">
                    {totalVariant} varian
                  </Text>
                </Flex>
              )}
            </Flex>
          </Flex>
        );
      },
    },
    {
      title: (
        <Text color="black75" weight="bold">
          Harga
        </Text>
      ),
      dataIndex: "formatted_product_price",
      key: "formatted_product_price",
      width: "22%",
      render: (_, data) => {
        const { formatted_product_price } = data;
        const regex = /\s*-\s*/;
        const numb = formatted_product_price.split(regex);

        if (numb.length > 1) {
          return (
            <Flex flexDirection="column">
              <Text>{numb[0]} -</Text>
              <Text>{numb[1]}</Text>
            </Flex>
          );
        } else {
          return <Text>{formatted_product_price}</Text>;
        }
      },
    },
    {
      title: (
        <Text color="black75" weight="bold">
          Terjual
        </Text>
      ),
      dataIndex: "total_sold",
      key: "total_sold",
      width: "22%",
      render: (_, data) => {
        const { total_sold } = data;

        return <Text>{total_sold}</Text>;
      },
    },
  ];

  const handleSave = () => {
    const tempChecked = checkedProducts.join(",");
    setParamsIds(tempChecked);
    setSearchValue("");
    addDisclosure.onClose();
  };

  const isLoadingAll = useMemo(
    () => (isLoadingModal || fetchDataModal) && page === 1,
    [isLoadingModal, fetchDataModal, page]
  );

  useEffect(() => {
    const tempArray = checkedParent
      .concat(checkedProducts)
      .filter((v, i, a) => a.findIndex((v2) => v2 === v) === i);

    setCheckedProducts([...tempArray]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useDebounce(() => setSearchParam(searchValue), [searchValue], 500);

  return {
    push,
    handleSave,
    tableColumns,
    checkedProducts,
    isCheckedAll,
    onCheckAllClicked,
    maxData,
    hasNextPage,
    handleFilterChange,
    handleOrderChange,
    dataModal,
    isLoadingModal,
    searchValue,
    setSearchValue,
    dataSort,
    dataFilter,
    page,
    setPage,
    fetchDataModal,
    isLoadingAll,
    searchParam,
  };
};

export default useAddModalShowcase;
