import { Dialog, Flex, Text, Button, Spinner } from "renos-ui";

const ModalConfirmation = ({
  isOpen,
  onClose,
  onConfirm,
  title,
  description,
  textReject,
  textApprove,
  isLoading,
}) => {
  return (
    <Dialog
      isVisible={isOpen}
      onClose={onClose}
      verticalCentered
      minHeight="100px"
    >
      <Flex flexDirection="column" width="100%" gap="24px">
        <Flex flexDirection="column" gap="8px" width="320px">
          <Text variant="heading3" weight="bold">
            {title || "Konfirmasi Terima Pesanan"}
          </Text>
          <Text>
            {description ||
              "Pastikan terlebih dahulu stok produkmu masih tersedia sebelum menerima pesanan."}
          </Text>
        </Flex>
        <Flex justifyContent="space-between" gap="16px">
          <Button isBlock variant="tertiary" onClick={onClose}>
            {textReject || "Batal"}
          </Button>
          <Button
            isBlock
            onClick={onConfirm}
            disabled={isLoading}
            preffix={isLoading && <Spinner color="white" size="8px" />}
          >
            {textApprove || "Terima"}
          </Button>
        </Flex>
      </Flex>
    </Dialog>
  );
};

export default ModalConfirmation;
