import styled from "styled-components";
import { Box, Flex, colors } from "renos-ui";

export const StyledFlexInputSearch = styled(Flex)`
  > div > span {
    border-radius: 100px;
    border: none;
    height: 40px;
    background-color: ${colors.black5};

    > input {
      background-color: ${colors.black5};
    }
  }
`;

export const Wrapper = styled(Box)`
  width: 100%;
  .react-datepicker {
    border: none;
    box-shadow: none;
    width: 100%;
    padding: 0;
  }
  .react-datepicker__header {
    padding: 0 0 8px 0;
  }
  .react-datepicker__month-container {
    width: 100%;
  }
  //   .react-datepicker__day {
  //     display: flex;
  //     width: 36px;
  //     height: 36px;
  //     align-items: center;
  //     justify-content: center;
  //     border-radius: 8px;
  //   }

  .react-datepicker__day--today {
    color: ${colors.black75};
    font-weight: normal;
    background-color: ${colors.white};
  }
  .react-datepicker__day--in-range {
    color: ${colors.black75};
    background-color: ${colors.blue5};
  }
  .react-datepicker__day--selected,
  .react-datepicker__day--range-end,
  .react-datepicker__day:hover {
    color: ${colors.white};
    background-color: ${colors.blue50} !important;
  }

  .react-datepicker__day-name,
  .react-datepicker__time-name {
    display: flex;
    width: 36px;
    height: 24px;
    text-align: center;
    align-items: center;
    justify-content: center;
    align-content: center;
  }

  .react-datepicker__day--disabled {
    color: ${colors.black25};
    cursor: not-allowed;
  }
`;
