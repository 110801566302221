import React, { useState, useEffect } from "react";
import { Box, Text } from "renos-ui";
import styled, { css } from "styled-components";
import { Flex } from "renos-ui";

const styling = css`
  justify-content: center;
  position: fixed;
  align-items: center;
  text-align: center;
  gap: 16px;
  flex-direction: column;
  width: fit-content;
`;

const StyledFlexWrapper = styled(Flex)`
  ${styling}
`;

const StyledFlexToast = styled(Flex)`
  visibility: visible;
  animation: fadein 0.5s;
  background-color: red;
  color: white;
  border-radius: 8px;
  padding: 12px 16px;
  position: fixed;
  display: flex;
  justify-content: space-between;
  top: 24px;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 30%;

  @keyframes fadein {
    from {
      bottom: -30px;
      opacity: 0;
    }
    to {
      bottom: 0px;
      opacity: 1;
    }
  }
`;

const TopToast = () => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsVisible(false);
    }, 6000);

    return () => clearTimeout(timeout);
  }, []);

  if (!isVisible) {
    return null;
  }

  return (
    <StyledFlexWrapper>
      <StyledFlexToast aria-label="toast-wrapper">
        <Box aria-label="toast-label">
          <Text>Mohon cek WiFi atau jaringan internet anda.</Text>
        </Box>
      </StyledFlexToast>
    </StyledFlexWrapper>
  );
};

export default TopToast;
