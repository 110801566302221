import { Box, Icon, Input } from "renos-ui";

const SearchInput = ({ searchValue, setSearchValue }) => {
  return (
    <Box width="560px">
      <Input
        placeholder="Cari nama etalase"
        prefix={<Icon name="Search-outline" size={20} />}
        value={searchValue}
        onChange={(event) => setSearchValue(event.target.value)}
      />
    </Box>
  );
};

export default SearchInput;
