import React, { useContext } from "react";
import { Flex, Text, Label, Icon, Box } from "renos-ui";
import StoreContext from "providers/StoreProvider";
import desktopUrl from "constants/desktopUrl";
import { StyledChipDiscount } from "./styled";

const CardProduct = ({
  userChat,
  placement = "left",
  item,
  isAttachment = false,
  removeAttachment,
}) => {
  const {
    storeData: {
      data: { slug: storeSlug },
    },
  } = useContext(StoreContext);
  const content = item?.content;
  const productURL = content?.productURL || content?.product_url;

  const handleClickPDP = (url) => {
    if (!productURL && !url) {
      return;
    }
    let fullUrl = productURL || url;

    if (!fullUrl?.includes("https://")) {
      fullUrl = `${process.env.REACT_APP_BUYER_SITE}${fullUrl}`;
    }

    window.open(fullUrl, "_blank").focus();
  };

  const handleLabel = () => {
    switch (userChat?.payload?.content?.orderStatus) {
      case "Diproses":
        return (
          <Label variant="success">
            {userChat?.payload?.content?.orderStatus}
          </Label>
        );
      case "Dibatalkan":
        return (
          <Label variant="error">
            {userChat?.payload?.content?.orderStatus}
          </Label>
        );
      default:
        return (
          <Label variant="warning">
            {userChat?.payload?.content?.orderStatus}
          </Label>
        );
    }
  };

  return (
    <Flex
      padding={isAttachment ? "0" : "12px 16px"}
      width="fit-content"
      border={
        ["ORDER", "PDP"].includes(userChat?.payload?.content?.origins) &&
        "1px solid"
      }
      borderColor="black10"
      borderRadius="16px"
      backgroundColor={
        isAttachment
          ? content?.selectedProducts?.length === 0
            ? "black5"
            : "white"
          : "white"
      }
      alignSelf={!isAttachment && placement === "right" && "flex-end"}
      borderTopRightRadius={!isAttachment && placement === "right" && "0"}
      borderTopLeftRadius={!isAttachment && placement === "left" && "0"}
    >
      {userChat?.payload?.content?.origins === "PDP" && (
        <Flex
          gap="16px"
          cursor="pointer"
          maxWidth="256px"
          onClick={() => {
            const productURL = userChat?.payload?.content?.productURL;

            if (!productURL) {
              return;
            }

            const baseUrl = process.env.REACT_APP_BUYER_SITE;
            const url = `${baseUrl}${productURL}`;

            window.open(url, "_blank");
          }}
        >
          <Box
            as="img"
            width="68px"
            height="68px"
            borderRadius="8px"
            alt="product-image"
            src={userChat?.payload?.content?.productImage}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = "/assets/images/empty-product.svg";
            }}
          />

          <Flex flexDirection="column" justifyContent="space-between">
            <Text variant="caption" color="black50" lineClamp={2}>
              {userChat?.payload?.content?.productName}
            </Text>
            {userChat.payload?.content?.discount > 0 && (
              <Flex gap="4px" alignItems="center">
                <Flex>
                  <StyledChipDiscount
                    color="red50"
                    fontWeight="bold"
                    backgroundColor="red5"
                    label={`${userChat.payload?.content?.discount}%`}
                  />
                </Flex>
                <Flex>
                  <Text variant="small" strikeThrough>
                    {userChat.payload?.content?.fullPrice}
                  </Text>
                </Flex>
              </Flex>
            )}
            <Flex>
              <Text weight="bold">
                {userChat?.payload?.content?.sellingPrice}
              </Text>
            </Flex>
          </Flex>
        </Flex>
      )}

      {userChat?.payload?.content?.origins === "ORDER" && (
        <Flex gap="16px" position="relative">
          <Flex
            gap={isAttachment ? "8px" : "16px"}
            maxWidth="256px"
            cursor="pointer"
            padding={isAttachment ? "12px" : "0"}
            onClick={() => {
              const url = `${desktopUrl.ORDERS_LIST}?query=${userChat?.payload?.content?.orderNumber}`;
              window.open(url, "_blank");
            }}
          >
            <Flex
              as="img"
              minWidth={isAttachment ? 40 : 68}
              height={isAttachment ? 40 : 68}
              borderRadius="8px"
              alt="product-image"
              src={userChat?.payload?.content?.productImage}
              cursor="pointer"
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = "/assets/images/empty-product.svg";
              }}
            />
            <Flex flexDirection="column" gap={isAttachment ? "8px" : "16px"}>
              {handleLabel()}
              <Text variant="caption" color="black50" lineClamp={2}>
                {userChat?.payload?.content?.orderNumber}
              </Text>
            </Flex>
          </Flex>
          {removeAttachment && (
            <Flex
              position="absolute"
              cursor="pointer"
              right="8px"
              top="8px"
              onClick={() => removeAttachment(null, "ORDER")}
            >
              <Icon name="Close-solid" size={20} color="black50" />
            </Flex>
          )}
        </Flex>
      )}

      {userChat?.payload?.content?.origins === "IMAGE" && (
        <Flex
          gap="16px"
          cursor="pointer"
          onClick={() =>
            window.open(userChat.payload.content.productImage, "_blank").focus()
          }
        >
          <Flex
            as="img"
            height={160}
            borderRadius="8px"
            alt="image-attachment"
            src={userChat?.payload?.content?.productImage}
            cursor="pointer"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = "/assets/images/no-img.png";
            }}
          />
          <Flex flexDirection="column" justifyContent="space-between">
            <Text>{userChat?.payload?.content?.orderStatus}</Text>
            <Text variant="caption" color="black50">
              {userChat?.payload?.content?.orderNumber}
            </Text>
          </Flex>
        </Flex>
      )}

      {/* ini belum di remove karena kemungkinan digunakan lagi */}
      {/* {userChat?.payload?.content?.origins === "PRODUCTS" && (
        <Flex flexDirection={`${isAttachment ? "row" : "column"} `} gap="16px">
          {userChat?.payload?.content?.selectedProducts.map(
            (product, index) => (
              <Flex
                padding="16px"
                borderRadius="8px"
                width="252px"
                key={`chat-product-${index}`}
                backgroundColor="blue5"
                position="relative"
              >
                <Flex gap="10px">
                  <Flex
                    as="img"
                    minWidth={isAttachment ? "40px" : "80px"}
                    height={isAttachment ? "40px" : "80px"}
                    borderRadius="8px"
                    alt="product-image"
                    src={product.product_image_url}
                    cursor="pointer"
                    onClick={() =>
                      handleClickPDP(`/p/${storeSlug}/${product?.product_slug}`)
                    }
                  />
                  <Flex
                    flexDirection="column"
                    // justifyContent="space-between"
                    cursor="pointer"
                    width="100%"
                    flexWrap="wrap"
                    onClick={() =>
                      handleClickPDP(`/p/${storeSlug}/${product?.product_slug}`)
                    }
                  >
                    <Text
                      variant="caption"
                      color="black50"
                      lineClamp={2}
                      style={{ wordBreak: "break-all" }}
                    >
                      {product.product_name}
                    </Text>

                    {(product?.formatted_discount_percentage ||
                      product?.discount_type_id) &&
                      !isAttachment && (
                        <Flex gap="4px" alignItems="center">
                          <Flex>
                            <StyledChipDiscount
                              color="red50"
                              fontWeight="bold"
                              backgroundColor="red5"
                              label={`${
                                product?.formatted_discount_percentage ||
                                product?.formatted_product_discount_percentage
                              }%`}
                            />
                          </Flex>
                          <Flex>
                            <Text variant="small" strikeThrough>
                              {product.formatted_product_price}
                            </Text>
                          </Flex>
                        </Flex>
                      )}

                    {!isAttachment && (
                      <Flex>
                        <Text weight="bold">
                          {product?.formatted_selling_price}
                        </Text>
                      </Flex>
                    )}
                  </Flex>

                  {isAttachment && (
                    <Flex
                      position="absolute"
                      right="10px"
                      cursor="pointer"
                      onClick={() => removeAttachment(product.product_id)}
                    >
                      <Icon name="Close-solid" size={20} color="black50" />
                    </Flex>
                  )}
                </Flex>
              </Flex>
            )
          )}
        </Flex>
      )} */}

      {userChat?.payload?.content?.origins === "PRODUCTS" && (
        <Flex flexDirection={`${isAttachment ? "row" : "column"} `} gap="16px">
          {userChat?.payload?.content?.selectedProducts.map((product, index) =>
            !isAttachment ? (
              <Flex
                padding="16px"
                borderRadius="8px"
                width="252px"
                key={`chat-product-${index}`}
                backgroundColor="blue5"
                position="relative"
              >
                <Flex gap="10px">
                  <Flex
                    as="img"
                    minWidth="80px"
                    height="80px"
                    borderRadius="8px"
                    alt="product-image"
                    src={product.productImage}
                    cursor="pointer"
                    onClick={() =>
                      handleClickPDP(`/p/${storeSlug}/${product?.slug}`)
                    }
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = "/assets/images/empty-product.svg";
                    }}
                  />
                  <Flex
                    flexDirection="column"
                    // justifyContent="space-between"
                    cursor="pointer"
                    width="100%"
                    flexWrap="wrap"
                    onClick={() =>
                      handleClickPDP(`/p/${storeSlug}/${product?.slug}`)
                    }
                  >
                    <Text
                      variant="caption"
                      color="black50"
                      lineClamp={2}
                      style={{ wordBreak: "break-all" }}
                    >
                      {product.productName}
                    </Text>
                    {product.discount > 0 && (
                      <Flex gap="4px" alignItems="center">
                        <Flex>
                          <StyledChipDiscount
                            color="red50"
                            fontWeight="bold"
                            backgroundColor="red5"
                            label={`${product?.discount}%`}
                          />
                        </Flex>
                        <Flex>
                          <Text variant="small" strikeThrough>
                            {product.fullPrice}
                          </Text>
                        </Flex>
                      </Flex>
                    )}
                    <Flex>
                      <Text weight="bold">{product?.sellingPrice}</Text>
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>
            ) : (
              <Flex
                padding="16px"
                borderRadius="8px"
                width="252px"
                key={`chat-product-${index}`}
                backgroundColor="blue5"
                position="relative"
              >
                <Flex gap="10px">
                  <Flex
                    as="img"
                    minWidth={isAttachment ? "40px" : "80px"}
                    height={isAttachment ? "40px" : "80px"}
                    borderRadius="8px"
                    alt="product-image"
                    src={product.product_image_url}
                    cursor="pointer"
                    onClick={() =>
                      handleClickPDP(`/p/${storeSlug}/${product?.product_slug}`)
                    }
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = "/assets/images/empty-product.svg";
                    }}
                  />
                  <Flex
                    flexDirection="column"
                    // justifyContent="space-between"
                    cursor="pointer"
                    width="100%"
                    flexWrap="wrap"
                    onClick={() =>
                      handleClickPDP(`/p/${storeSlug}/${product?.product_slug}`)
                    }
                  >
                    <Text
                      variant="caption"
                      color="black50"
                      lineClamp={2}
                      style={{ wordBreak: "break-all" }}
                    >
                      {product.product_name}
                    </Text>

                    {(product?.formatted_discount_percentage ||
                      product?.discount_type_id) &&
                      !isAttachment && (
                        <Flex gap="4px" alignItems="center">
                          <Flex>
                            <StyledChipDiscount
                              color="red50"
                              fontWeight="bold"
                              backgroundColor="red5"
                              label={`${
                                product?.formatted_discount_percentage ||
                                product?.formatted_product_discount_percentage
                              }%`}
                            />
                          </Flex>
                          <Flex>
                            <Text variant="small" strikeThrough>
                              {product.formatted_product_price}
                            </Text>
                          </Flex>
                        </Flex>
                      )}

                    {!isAttachment && (
                      <Flex>
                        <Text weight="bold">
                          {product?.formatted_selling_price}
                        </Text>
                      </Flex>
                    )}
                  </Flex>

                  {isAttachment && (
                    <Flex
                      position="absolute"
                      right="10px"
                      cursor="pointer"
                      onClick={() => removeAttachment(product.product_id)}
                    >
                      <Icon name="Close-solid" size={20} color="black50" />
                    </Flex>
                  )}
                </Flex>
              </Flex>
            )
          )}
        </Flex>
      )}
    </Flex>
  );
};

export default CardProduct;
