import styled from "styled-components";
import { colors, SelectInput } from "renos-ui";

export const StyledSelectInput = styled(SelectInput)`
  width: 200px;
  color: ${colors.black75};

  .select__control {
    padding: 9px 12px;
  }
`;
