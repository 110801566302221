import { useState } from "react";
import { useToast, get, useDebounce, useDisclosure } from "renos-ui";
import { reactDndReorder } from "helpers";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  useUpdateOrderProductShowcase,
  useGetProductShowcase,
  useDeleteProductShowcase,
} from "services/product-showcase";

const useDashboard = () => {
  const [dataShowcase, setDataShowcase] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [searchParam, setSearchParam] = useState("");
  const [tempTagId, setTempTagId] = useState(0);
  const [mode, setMode] = useState("add");
  const [tempTagName, setTempTagName] = useState("");
  const [titleMessage, setTitleMessage] = useState("");

  const toast = useToast();
  const previewDisclosure = useDisclosure({});
  const addShowcaseDisclosure = useDisclosure({});
  const uploadDisclosure = useDisclosure({});
  const deleteModal = useDisclosure({});
  const {
    isLoading: isLoadingProductShowcase,
    refetch: refetchProductShowcase,
    isError,
  } = useGetProductShowcase(
    {
      keyword: searchParam,
    },
    {
      onSuccess: (data) => {
        setDataShowcase(data);
      },
    }
  );

  const handleOnDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const draggableId = Number(get(result, "source.index"));
    const reOrderIndex = get(result, "destination.index");

    const items = reactDndReorder(dataShowcase, draggableId, reOrderIndex);

    const tempTagStoreIds = items.map((list) => list.tag_store_id);

    const body = {
      tag_store_ids: tempTagStoreIds,
    };

    setDataShowcase(items);

    const timer = setTimeout(() => {
      mutateUpdateOrder(body);
    }, 200);

    return () => clearTimeout(timer);
  };

  const { mutate: mutateUpdateOrder } = useUpdateOrderProductShowcase({
    onSuccess: () => {
      toast({
        label: "Berhasil menyusun ulang Etalase Toko",
        placement: "top",
      });
    },
    onError: () => {
      toast({
        label: "Gagal menyusun ulang Etalase Toko",
        placement: "top",
      });
    },
  });

  const handleOpenSet = () => {
    addShowcaseDisclosure.onOpen();
    setMode("edit");
    setTitleMessage(tempTagName);
  };

  const handleDropdown = (id, name) => {
    setTempTagId(id);
    setTempTagName(name);
  };

  const handleOpenUploadImage = () => {
    uploadDisclosure.onOpen();
  };

  const handleDelete = () => {
    deleteModal.onOpen();
  };

  const handleAdd = () => {
    addShowcaseDisclosure.onOpen();
    setMode("add");
    setTitleMessage("");
  };

  const handleOpenPreview = () => {
    previewDisclosure.onOpen();
  };

  const { mutate: mutateDeleteShowcase, isLoading: isLoadingDeleteShowcase } =
    useDeleteProductShowcase({
      onSuccess: () => {
        toast({
          label: "Berhasil menghapus Etalase",
          placement: "top",
        });
        deleteModal.onClose();
        refetchProductShowcase();
      },
      onError: () => {
        toast({
          label: "Gagal menghapus Etalase",
          placement: "top",
        });
      },
    });

  const handleOkDelete = () => {
    mutateDeleteShowcase(tempTagId);
  };

  useDebounce(() => setSearchParam(searchValue), [searchValue], 500);

  return {
    handleOkDelete,
    isLoadingDeleteShowcase,
    handleOpenPreview,
    handleAdd,
    handleDelete,
    handleOpenUploadImage,
    handleDropdown,
    handleOpenSet,
    handleOnDragEnd,
    setSearchValue,
    mode,
    titleMessage,
    isLoadingProductShowcase,
    DragDropContext,
    Droppable,
    Draggable,
    addShowcaseDisclosure,
    setTitleMessage,
    refetchProductShowcase,
    tempTagId,
    setMode,
    searchValue,
    dataShowcase,
    uploadDisclosure,
    previewDisclosure,
    deleteModal,
    isError,
  };
};

export default useDashboard;
