import { Button, Flex, Icon, Tabs, Text, useToast } from "renos-ui";
import { StyledDialog } from "./styled";
import Product from "./Product";
import Etalase from "./Etalase";
import { useEffect, useMemo, useState } from "react";
import { useUploadBanner } from "services/store-layout/banner";

const ProductPicker = ({ isOpen, onClose, setValue, value }) => {
  const toast = useToast();

  const [tabValue, setTabValue] = useState("product");
  const [tempValue, setTempValue] = useState({});

  const items = [
    {
      label: "Produk",
      key: "product",
      children: () => <></>,
      width: "248px",
    },
    {
      label: "Etalase",
      key: "etalase",
      children: () => <></>,
      width: "248px",
    },
  ];

  const { mutate: uploadBanner, isLoading } = useUploadBanner({
    onSuccess: () => {
      setValue(tempValue);
      onClose();
    },
    onError: () => {
      toast({
        label: `Gagal memilih ${
          tabValue === "product" ? "produk" : "etalase"
        }.`,
        placement: "top",
        backgroundColor: "red50",
      });
    },
  });

  const handleChangeTab = (newValue) => {
    setTabValue(newValue);
  };

  const handleChangeValue = (type, newValue) => {
    setTempValue({
      ...tempValue,
      ...(type === "product"
        ? { product_id: newValue.product_id, etalase_id: null }
        : { etalase_id: newValue.tag_store_id, product_id: null }),
      product: newValue.product_id ? newValue : null,
      etalase: newValue.tag_store_id ? newValue : null,
    });
  };

  const handleSave = () => {
    const formData = new FormData();
    formData.append("image_type", tempValue.mst_banner_image_type_id);

    if (tempValue.id) formData.append("id", tempValue.id);
    if (tempValue.product_id)
      formData.append("product_id", tempValue.product_id);
    if (tempValue.etalase_id)
      formData.append("etalase_id", tempValue.etalase_id);

    uploadBanner(formData);
  };

  const isDisabled = useMemo(
    () =>
      (!tempValue.product_id && tabValue === "product") ||
      (!tempValue.etalase_id && tabValue === "etalase") ||
      isLoading,
    [tempValue, tabValue, isLoading]
  );

  useEffect(() => {
    setTabValue(value.etalase_id ? "etalase" : "product");
    setTempValue(value || {});
  }, [value]);

  return (
    <StyledDialog
      isVisible={isOpen}
      onClose={onClose}
      width="496px"
      verticalCentered
    >
      <Flex flexDirection="column" width="100%" gap="24px">
        <Flex
          justifyContent="space-between"
          alignContent="center"
          px="24px"
          paddingTop="24px"
        >
          <Text variant="heading3" weight="bold">
            Link Produk
          </Text>
          <Icon
            name="Close-outline"
            cursor="pointer"
            size={24}
            color="black60"
            onClick={onClose}
          />
        </Flex>

        <Flex flexDirection="column">
          <Tabs
            onChange={handleChangeTab}
            defaultActiveKey={tabValue}
            placement="bottom"
            items={items}
          />
          {tabValue === "product" ? (
            <Product
              {...{ setValue: handleChangeValue, value: tempValue, tabValue }}
            />
          ) : (
            <Etalase
              {...{ setValue: handleChangeValue, value: tempValue, tabValue }}
            />
          )}
          <Flex
            padding="16px 24px"
            boxShadow="0px 0px 8px 0px rgba(20, 20, 32, 0.10)"
            gap="16px"
          >
            <Button variant="tertiary" isBlock onClick={onClose}>
              Batal
            </Button>
            <Button isBlock disabled={isDisabled} onClick={handleSave}>
              Simpan
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </StyledDialog>
  );
};

export default ProductPicker;
