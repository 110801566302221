import { getYoutubeId } from "helpers";
import StoreLayoutContext from "providers/StoreLayoutProvider";
import { useContext, useEffect, useState } from "react";
import { Flex } from "renos-ui";

const BannerVideo = ({ isPreviewMode }) => {
  const { bannerVideoRef, dataHomePage, isHomePageLoading } =
    useContext(StoreLayoutContext);

  const [dataBannerVideo, setDataBannerVideo] = useState();
  const [isActive, setIsActive] = useState();

  useEffect(() => {
    const selectedBanner = dataHomePage.filter(
      (homepage) => homepage.id === 7
    )[0];

    let linkEmbed = "";
    setIsActive(selectedBanner?.isActive);

    if (selectedBanner?.data?.length > 0) {
      const youtubeId = getYoutubeId(selectedBanner?.data[0].url);
      linkEmbed = `https://www.youtube.com/embed/${youtubeId}`;
    }

    setDataBannerVideo(linkEmbed);
  }, [dataHomePage]);

  if (isPreviewMode && !isActive) return <></>;

  if (isHomePageLoading || !dataBannerVideo)
    return (
      <Flex ref={bannerVideoRef}>
        <Flex
          height="180px"
          width="360px"
          backgroundColor="black10"
          borderRadius="10px"
        />
      </Flex>
    );

  return (
    <Flex ref={bannerVideoRef}>
      <iframe
        height="180px"
        width="360px"
        data-testid="video-player"
        allowFullScreen
        src={`${dataBannerVideo}?autoplay=0`}
        title="video-player"
        style={{ borderWidth: "0" }}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      />
    </Flex>
  );
};

export default BannerVideo;
