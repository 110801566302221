import { Box, Button, Flex, Spinner } from "renos-ui";

const Footer = ({ onCancel, onSave, isLoading, viewMode, onView }) => {
  return (
    <Flex
      gap="16px"
      padding="16px 24px"
      backgroundColor="white"
      boxShadow="0px 4px 4px rgba(20, 20, 32, 0.05)"
      borderBottomLeftRadius="16px"
      borderBottomRightRadius="16px"
      justifyContent="space-between"
    >
      <Box width="160px">
        <Button variant="tertiary" isBlock onClick={onCancel}>
          Batal
        </Button>
      </Box>
      {!viewMode && (
        <Flex gap="16px">
          <Box width="160px">
            <Button
              isBlock
              variant="secondary"
              onClick={onView}
              disabled={isLoading}
            >
              Lihat
            </Button>
          </Box>
          <Box width="160px">
            <Button
              isBlock
              onClick={onSave}
              disabled={isLoading}
              preffix={isLoading && <Spinner size="16px" color="white" />}
            >
              Simpan
            </Button>
          </Box>
        </Flex>
      )}
    </Flex>
  );
};

export default Footer;
