import { useEffect, useMemo, useState } from "react";
import {
  Button,
  Checkbox,
  Dialog,
  Divider,
  Flex,
  Grid,
  Icon,
  Input,
  Text,
  cloneDeep,
} from "renos-ui";
import EmptyData from "./EmptyData";

const ModalDistrict = ({
  isOpen,
  onClose,
  selectedIndex,
  dataCoverage,
  setDataCoverage,
  mutateDistrict,
  isLoadingDistrict,
}) => {
  const [dataModal, setDataModal] = useState({
    listDistrict: [],
    selectedDistrict: [],
    changed: false,
  });
  const [dataSearch, setDataSearch] = useState([]);

  const isIncludeSelected = (idDistrict) => {
    const { selectedDistrict } = dataModal;
    const dataChecker = selectedDistrict.filter(
      (filterDistrict) => filterDistrict.district_id === idDistrict
    );

    return dataChecker.length > 0 && true;
  };

  const handleChangeCheckBox = (dataDistrict) => {
    let tempSelectedDistrict = dataModal.selectedDistrict;

    if (isIncludeSelected(dataDistrict.district_id)) {
      tempSelectedDistrict = tempSelectedDistrict.filter(
        (filterSelected) =>
          filterSelected.district_id !== dataDistrict.district_id
      );
    } else {
      tempSelectedDistrict.push(dataDistrict);
    }
    setDataModal((prevState) => ({
      ...prevState,
      selectedDistrict: tempSelectedDistrict,
      changed: true,
    }));
  };

  const handleSave = () => {
    const tempCoverage = dataCoverage;
    tempCoverage[selectedIndex].selectedDistrict = dataModal.selectedDistrict;
    setDataCoverage([...tempCoverage]);

    onClose();
  };

  const isCheckedAll = useMemo(() => {
    const listDistrictId = dataSearch.map((district) => district.district_id);
    const filterSelected = dataModal?.selectedDistrict?.filter(
      (filterDistrict) => listDistrictId.includes(filterDistrict.district_id)
    );
    const checkResult = {
      active: false,
      indeterminate: false,
    };
    if (filterSelected.length >= listDistrictId.length)
      checkResult.active = true;
    if (filterSelected.length > 0 && !checkResult.active)
      checkResult.indeterminate = true;

    return checkResult;
  }, [dataModal, dataSearch]);

  const handleCheckAll = () => {
    let tempSelectedDistrict = dataModal.selectedDistrict;

    if (!isCheckedAll.active && !isCheckedAll.indeterminate) {
      tempSelectedDistrict = dataSearch;
    } else if (isCheckedAll.indeterminate) {
      const filterNotSelected = dataSearch.filter(
        (filterDistrict) => !isIncludeSelected(filterDistrict)
      );
      tempSelectedDistrict = tempSelectedDistrict.concat(filterNotSelected);
      const filterDuplicate = [
        ...new Map(
          tempSelectedDistrict.map((district) => [
            district.district_id,
            district,
          ])
        ).values(),
      ];
      tempSelectedDistrict = filterDuplicate;
    } else {
      const listDistrictId = dataSearch.map((district) => district.district_id);
      const filterSelected = tempSelectedDistrict.filter(
        (filterDistrict) => !listDistrictId.includes(filterDistrict.district_id)
      );
      tempSelectedDistrict = filterSelected;
    }
    setDataModal((prevState) => ({
      ...prevState,
      selectedDistrict: tempSelectedDistrict,
      changed: true,
    }));
  };

  const handleSearch = (event) => {
    const filterDistrict = dataModal.listDistrict.filter((district) =>
      district.district_name.toLowerCase().includes(event.target.value)
    );
    setDataSearch([...filterDistrict]);
  };

  useEffect(() => {
    const selectedCoverage = dataCoverage[selectedIndex];

    if (selectedCoverage.listDistrict.length === 0) {
      const payload = `[${selectedCoverage.selectedCity
        .map((cities) => cities.city_id)
        .join(",")}]`;
      mutateDistrict({ city_id: payload, indexCoverage: selectedIndex });
    }

    // eslint-disable-next-line
  }, [isOpen]);

  useEffect(() => {
    const selectedCoverage = dataCoverage[selectedIndex]
      ? dataCoverage[selectedIndex]
      : dataCoverage[selectedIndex - 1];

    if (!isLoadingDistrict) {
      setDataModal(cloneDeep(selectedCoverage));
      setDataSearch(cloneDeep(selectedCoverage?.listDistrict));
    }
    //eslint-disable-next-line
  }, [dataCoverage, isOpen, isLoadingDistrict]);

  return (
    <Dialog isVisible={isOpen} onClose={onClose} verticalCentered closable>
      <Flex width="100%" flexDirection="column" rowGap="24px">
        <Text variant="heading3" weight="bold">
          Pilih Kecamatan
        </Text>
        <Input
          placeholder="Cari nama kecamatan"
          prefix={<Icon name="Search-outline" size={20} />}
          onChange={(event) => handleSearch(event)}
        />

        <Flex
          flexDirection="column"
          borderColor="black10"
          borderStyle="solid"
          borderWidth="1px"
          borderRadius="8px"
          px="16px"
        >
          <Flex gap="8px" py="16px" alignItems="center">
            <Checkbox
              checked={isCheckedAll.active}
              indeterminate={isCheckedAll.indeterminate}
              onChange={handleCheckAll}
            />
            <Text color="black75">Pilih Semua</Text>
          </Flex>
          <Divider />
          {dataSearch.length > 0 ? (
            <Grid
              gap="16px"
              gridTemplateColumns="196px 196px 196px"
              gridTemplateRows="24px 24px 24px 24px  24px"
              height="208px"
              overflowX="auto"
              py="16px"
            >
              {dataSearch.map((district, indexDistrict) => (
                <Flex
                  gap="8px"
                  alignItems="center"
                  width="196px"
                  height="24px"
                  key={`district-box-${indexDistrict}`}
                >
                  <Checkbox
                    checked={isIncludeSelected(district.district_id)}
                    onChange={() => handleChangeCheckBox(district)}
                  />
                  <Text color="black75" width="168px" lineClamp={1}>
                    {district.district_name}
                  </Text>
                </Flex>
              ))}
            </Grid>
          ) : (
            <Flex width="624px" height="236px" flexDirection="column" alignItems="center" justifyContent="center">
              <EmptyData />
            </Flex>
          )}

        </Flex>
        <Flex alignSelf="end" width="160px">
          <Button size="medium" isBlock onClick={handleSave}>
            Simpan
          </Button>
        </Flex>
      </Flex>
    </Dialog>
  );
};

export default ModalDistrict;