import React from "react";
import { Flex, Text, Icon, colors } from "renos-ui";
import { Wrapper } from "./../styled";

const CardNotes = (props) => {
  const { item, toggleDrawer } = props;

  return (
    <Flex
      flexDirection="column"
      padding="16px"
      borderRadius="8px"
      backgroundColor="white"
      boxSizing="border-box"
    >
      <Flex justifyContent="space-between" boxSizing="border-box">
        <Flex alignItems="center">
          <Flex flexDirection="column" gap="6px">
            <Flex justifyContent="space-between">
              <Text weight="bold">{item.title}</Text>
            </Flex>
          </Flex>
        </Flex>

        <Flex
          width="24px"
          height="24px"
          border={item.link !== null ? <></> : `solid 1px ${colors.blue50}`}
          borderRadius="100%"
          cursor="pointer"
          alignItems="center"
          justifyContent="center"
          onClick={() => toggleDrawer(item)}
          gap="16px"
          boxSizing="border-box"
        >
          <Icon
            name={item.link !== null ? "Vertical-outline" : "Right-outline"}
            color={item.link !== null ? "black50" : "blue50"}
            size={16}
          />
        </Flex>
      </Flex>
      <Wrapper>
        <Text variant="caption">{item.note}</Text>
      </Wrapper>
    </Flex>
  );
};

export default CardNotes;
