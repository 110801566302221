import { useState } from "react";
import { Flex, Tabs, Text } from "renos-ui";
import HeaderProfile from "./HeaderProfile";
import Home from "./Home";
import Product from "./Product";

const Web = ({ isDesignActive, isPreviewMode }) => {
  const [activeTab, setActiveTab] = useState("home");

  const items = [
    {
      label: ({ isActive }) => (
        <Flex gap="8px" alignItems="center" marginBottom="8px">
          <Text
            color={isActive ? "blue50" : "black50"}
            weight={isActive && "bold"}
            variant="small"
          >
            Beranda
          </Text>
        </Flex>
      ),
      key: "home",
      width: "96px",
      children: () => <Home isPreviewMode={isPreviewMode} />,
    },
    {
      label: ({ isActive }) => (
        <Flex gap="8px" alignItems="center" marginBottom="8px">
          <Text
            color={isActive ? "blue50" : "black50"}
            weight={isActive && "bold"}
            variant="small"
          >
            Produk
          </Text>
        </Flex>
      ),
      key: "produk",
      width: "96px",
      children: () => <Product />,
    },
  ];

  const handleChangeTabActive = (value) => {
    setActiveTab(value);
  };

  return (
    <Flex
      boxShadow={!isPreviewMode && "0px 4px 4px rgba(20, 20, 32, 0.05)"}
      backgroundColor="white"
      borderRadius="16px"
      py={!isPreviewMode && "24px"}
      width="778px"
      flexDirection="column"
      opacity={isDesignActive ? "100%" : "25%"}
      gap="24px"
    >
      <Flex px="24px">
        <HeaderProfile />
      </Flex>

      <Tabs
        onChange={handleChangeTabActive}
        defaultActiveKey={activeTab}
        // placement="bottom"
        items={items}
      />
    </Flex>
  );
};

export default Web;
