import { numberInputChecking } from "renos-ui";

const chartLineConfig = (type) => {
  return {
    series: [
      {
        name: "Pesanan Baru",
        data: [],
      },
    ],
    options: {
      colors: ["#2327be", "#8d8d97"],
      labels: [
        {
          name: "Date",
          data: [],
        },
        {
          name: "Time",
          data: [],
        },
      ],
      chart: {
        height: 350,
        type: "line",
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: [2, 2, 2],
        curve: "straight",
        dashArray: [0, 8, 5],
      },
      title: {
        text: "",
        align: "left",
      },
      legend: {
        tooltipHoverFormatter: function (val, opts) {
          const hoverValue =
            opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex];

          return `${val}:  ${
            numberInputChecking(hoverValue.toString() || "0", 0).formattedValue
          }`;
        },
        horizontalAlign: "left",
      },
      markers: {
        size: 0,
        hover: {
          sizeOffset: 6,
        },
      },
      xaxis: {
        categories: [],
      },
      yaxis: {
        labels: {
          show: true,
          formatter: (value) => {
            return numberInputChecking(value.toString() || "0", 0)
              .formattedValue;
          },
        },
      },
      tooltip: {
        custom: function (value) {
          const label = value.w.globals.initialConfig;

          return `
          <div class="arrow_box">
            <span class="date-time">
            ${
              ["weekly", "daily"].includes(type)
                ? `${value.w.globals.categoryLabels[value.dataPointIndex]} (${
                    label.labels[1].data[value.dataPointIndex]
                  } WIB)`
                : ""
            }
             </span> </br>
            <span class="period"> 
            Pesanan Baru : ${value.series[0][value.dataPointIndex]}
          </div>
          `;
        },
      },
      grid: {
        borderColor: "#f1f1f1",
      },
    },
  };
};

const chartBarConfig = {
  series: [
    {
      data: [],
    },
  ],
  options: {
    colors: ["#2327be", "#8d8d97"],
    chart: {
      height: 350,
      type: "line",
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: [2, 2, 2],
      curve: "straight",
      dashArray: [0, 8, 5],
    },
    title: {
      text: "",
      align: "left",
    },
    legend: {
      tooltipHoverFormatter: function (val, opts) {
        return (
          val +
          " - " +
          opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] +
          ""
        );
      },
    },
    labels: [
      {
        name: "Date",
        data: [],
      },
      {
        name: "Time",
        data: [],
      },
    ],
    markers: {
      size: 0,
      hover: {
        sizeOffset: 6,
      },
    },
    xaxis: {
      categories: [],
    },
    yaxis: {
      labels: {
        show: true,
        formatter: (value) => {
          return numberInputChecking(value.toString() || "0", 0).formattedValue;
        },
      },
    },
    tooltip: {
      custom: function (value) {
        return `
          <div class="arrow_box">
            <span class="period"> 
            ${value.w.globals.labels[value.dataPointIndex]} : ${
          value.series[0][value.dataPointIndex]
        }
          </div>
          `;
      },
    },
    grid: {
      borderColor: "#f1f1f1",
    },
  },
  total: 0,
};

export { chartLineConfig, chartBarConfig };
