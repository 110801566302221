import { Flex } from "renos-ui";
import SearchBar from "./component/SearchBar";
import StoreInfo from "./component/StoreInfo";
import StoreMenu from "./component/StoreMenu";

const Mobile = ({ isDesignActive, isPreviewMode }) => {
  return (
    <Flex
      boxShadow={!isPreviewMode && "0px 4px 4px rgba(20, 20, 32, 0.05)"}
      py={!isPreviewMode && "24px"}
      backgroundColor="white"
      borderRadius="16px"
      width="778px"
      flexDirection="column"
      alignItems="center"
      gap="24px"
      style={isPreviewMode && { overflowY: "auto", height: "504px" }}
    >
      <Flex
        width="360px"
        borderWidth="20px 12px 32px 12px"
        borderStyle="solid"
        style={{
          borderImageSource:
            "linear-gradient(188.2deg, #212587 -6.06%, #111452 108.07%)",
          top: "335px",
          left: "813px",
          background:
            "linear-gradient(188.2deg, #212587 -6.06%, #111452 108.07%),linear-gradient(0deg, #FFFFFF, #FFFFFF)",
        }}
        flexDirection="column"
        borderRadius="16px"
      >
        <SearchBar />
        <StoreInfo />
        <StoreMenu {...{ isDesignActive, isPreviewMode }} />
      </Flex>
    </Flex>
  );
};

export default Mobile;
