import { getUniqueListBy } from "helpers";
import { useMemo, useState } from "react";
import {
  Checkbox,
  Flex,
  Pagination,
  range,
  Text,
  useDisclosure,
} from "renos-ui";
import { useGetOrder } from "services/order-management";
import FailLoadData from "widgets/global/FailLoadData";
import Card from "./Card";
import CardSkeleton from "./CardSkeleton";
import Empty from "./Empty";
import Filter from "./Filter";
import ModalMultipleApprove from "./ModalMultipleApprove";
import { useEffect } from "react";
import ModalMultipleInvoice from "./ModalMultipleInvoice";

const List = ({ statusId, refetchStatus }) => {
  const defaultFilter = {
    items_per_page: 10,
    page: 1,
    order_status: statusId !== "1" ? statusId : "",
    sort_type: "desc",
  };
  const [dataFilter, setDataFilter] = useState(defaultFilter);
  const [totalPage, setTotalPage] = useState(1);
  const [listSelected, setListSelected] = useState([]);

  const disclosureApprove = useDisclosure({ isOpen: false });
  const disclosureInvoice = useDisclosure({ isOpen: false });

  const {
    isLoading,
    isError,
    isFetching,
    refetch,
    data: dataOrder,
  } = useGetOrder(dataFilter, {
    onSuccess: (data) => {
      const tempTotal = Math.ceil(
        data.paginator.total / data.paginator.per_page
      );
      setTotalPage(tempTotal);
    },
  });

  const handleChangePage = (nextPage) => {
    setDataFilter({ ...dataFilter, page: nextPage });
    setListSelected([]);
  };

  const handleCheckAll = () => {
    const defaultData = dataOrder?.data || [];
    let tempListSelected = listSelected;

    if (listSelected.length < defaultData.length) {
      tempListSelected = getUniqueListBy(
        tempListSelected.concat(defaultData),
        "order_id"
      );
    } else {
      tempListSelected = [];
    }

    setListSelected([...tempListSelected]);
  };

  const handleOnCheck = (dataOrder) => {
    let tempSelected = listSelected;

    if (handleIsChecked(dataOrder.order_id)) {
      tempSelected = listSelected.filter(
        (order) => order.order_id !== dataOrder.order_id
      );
    } else {
      tempSelected.push(dataOrder);
    }

    setListSelected([...tempSelected]);
  };

  const BtnMultipleApprove = useMemo(
    () =>
      listSelected.filter((order) => order.order_status_id === 2).length >
        0 && (
        <Flex
          padding="6px 12px"
          borderRadius="12px"
          borderColor="black10"
          borderWidth="1px"
          borderStyle="solid"
          cursor="pointer"
          onClick={disclosureApprove.onToggle}
        >
          <Text variant="caption" color="black75">
            Terima Pesanan (
            {listSelected.filter((order) => order.order_status_id === 2).length}
            )
          </Text>
        </Flex>
      ),
    // eslint-disable-next-line
    [listSelected]
  );

  const btnMultipleInvoice = useMemo(
    () =>
      listSelected.length > 0 && (
        <Flex
          padding="6px 12px"
          borderRadius="12px"
          borderColor="black10"
          borderWidth="1px"
          borderStyle="solid"
          cursor="pointer"
          onClick={disclosureInvoice.onToggle}
        >
          <Text variant="caption" color="black75">
            Cetak Invoice ({listSelected.length})
          </Text>
        </Flex>
      ),
    // eslint-disable-next-line
    [listSelected]
  );

  const handleIsChecked = (orderId) => {
    const listIds = listSelected.map((order) => order.order_id);

    return listIds.includes(orderId);
  };

  const allChecked = useMemo(() => {
    const tempChecked = { checked: false, indeterminate: false };
    const defaultData = dataOrder?.data || [];

    if (defaultData.length !== 0 && listSelected.length !== 0) {
      if (listSelected.length < defaultData.length)
        tempChecked.indeterminate = true;
      if (listSelected.length === defaultData.length)
        tempChecked.checked = true;
    }

    return tempChecked;
    // eslint-disable-next-line
  }, [listSelected]);

  const isOrderLoading = useMemo(
    () => isLoading || isFetching,
    [isLoading, isFetching]
  );

  useEffect(() => {
    setListSelected([]);
  }, [dataFilter]);

  return (
    <Flex py="24px" gap="24px" flexDirection="column">
      <Filter
        data={dataFilter}
        setData={setDataFilter}
        defaultData={defaultFilter}
      />

      <Flex justifyContent="space-between" alignItems="center">
        <Flex gap="24px">
          <Flex gap="8px" alignItems="center" cursor="pointer">
            <Checkbox
              checked={allChecked.checked}
              onChange={handleCheckAll}
              indeterminate={allChecked.indeterminate}
              disabled={isOrderLoading}
            />
            <Text color="black75">
              {listSelected.length > 0 ? (
                <b>{listSelected.length} Pesanan Dipilih</b>
              ) : (
                "Pilih Semua"
              )}
            </Text>
          </Flex>
          {BtnMultipleApprove}
          {btnMultipleInvoice}
        </Flex>
        <Pagination
          total={totalPage}
          current={dataFilter.page}
          onChange={(nextPage) => handleChangePage(nextPage)}
        />
      </Flex>

      <Flex flexDirection="column" gap="16px">
        {isOrderLoading ? (
          range(3).map((_, indexSkeleton) => (
            <CardSkeleton key={`card-skeleton${indexSkeleton}`} />
          ))
        ) : isError ? (
          <FailLoadData onClick={refetch} />
        ) : dataOrder?.data.length === 0 ? (
          <Empty />
        ) : (
          dataOrder?.data.map((order, indexOrder) => (
            <Card
              data={order}
              key={`card-order${indexOrder}`}
              refetchList={refetch}
              refetchStatus={refetchStatus}
              handleIsChecked={handleIsChecked}
              handleOnCheck={handleOnCheck}
            />
          ))
        )}
      </Flex>
      <ModalMultipleApprove
        onClose={disclosureApprove.onClose}
        isOpen={disclosureApprove.isOpen}
        data={listSelected}
        refetchList={refetch}
        refetchStatus={refetchStatus}
      />

      <ModalMultipleInvoice
        onClose={disclosureInvoice.onClose}
        isOpen={disclosureInvoice.isOpen}
        data={listSelected}
      />
    </Flex>
  );
};

export default List;
