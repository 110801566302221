import MobileLayout from "layouts/MobileLayout";
import React from "react";
import { Flex } from "renos-ui";
import Resume from "./resume";
import { useState } from "react";
import CustomHeader from "./customHeader";
import OrdersPerformance from "./orders-performance";

const Statistic = () => {
  const [selectedDate, setSelectedDate] = useState({
    type: "daily",
    startDate: new Date(),
    today: true,
  });

  const today = selectedDate.today;

  return (
    <MobileLayout
      customHeader={
        <CustomHeader
          today={today}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
        />
      }
    >
      <Flex
        width="100%"
        backgroundColor="black5"
        flexDirection="column"
        padding="16px"
        gap="24px"
        boxSizing="border-box"
        height="100%"
        overflow="auto"
      >
        <Flex flexDirection="column" gap="20px">
          <Resume dataDate={selectedDate} />
          <OrdersPerformance dataDate={selectedDate} />
        </Flex>
      </Flex>
    </MobileLayout>
  );
};

export default Statistic;
