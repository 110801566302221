import ENDPOINTS from "constants/endpoints";
import { useQuery } from "react-query";
import client from "../../client";

const getEtalaseFetcher = async () => {
  const { data } = await client.get(ENDPOINTS.etalase);

  return data;
};

export const useGetEtalase = (extraParams) => {
  return useQuery(["getEtalase"], () => getEtalaseFetcher(), {
    ...extraParams,
  });
};
