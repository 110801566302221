const mobileUrl = {
  DASHBOARD: "/",
  LOGIN: "/login",
  REGISTER: "/register",
  REGISTBYEMAIL: "/register/regis-byemail",
  PROFILE: "/profile",
  ADDRESS: "/address",
  CHAT: "/chat",
  CHATROOM: "/chat-room",
  CHAT_SETTING: "/chat/settings",
  CHAT_ORDER: "/chat/attach-order",
  PRODUCTS: "/products",
  PRODUCTS_LIST: "/products/list",
  PRODUCTS_ADD: "/products/add",
  PRODUCT_ASSETS: "/products/add/assets",
  PRODUCT_INFORMATION: "/products/add/information",
  PRODUCT_DETAIL: "/products/add/detail",
  PRODUCT_SPECIFICATION: "/products/add/specification",
  VARIANT_FORM: "/products/add/variant-form",
  PRODUCT_SHIPPING: "/products/add/shipping",
  SNI_INPUT: "/products/add/sni-input",
  PRODUCTS_ADD_MULTIPLE: "/products/add-multiple",
  PRODUCT_CATEGORY: "/products/add/category",
  EDIT_PRODUCT: "/products/edit-product",
  SHOWCASE: "/products/showcase",
  ORDERS: "/sales",
  ORDERS_LIST: "/sales/orders",
  ORDERS_DETAIL: "/sales/orders/detail",
  REVIEWS: "/sales/review",
  REVIEWS_EDIT: "/sales/review/edit",
  STATISTICS: "/sales/statistics",
  CONFIGURATION: "/config",
  SETTINGS_INFORMATION: "/settings/information",
  SETTING: "/setting",
  PROMO: "/promo",
  DISCOUNT: "/promo/discount-management",
  DISCOUNT_ADD: "/promo/discount-management/add",
  DISCOUNT_ADD_VARIANT: "/promo/discount-management/add-variant",
  DISCOUNT_ADD_SINGLE: "/promo/discount-management/add-single",
  DISCOUNT_ADD_BULK: "/promo/discount-management/add-bulk",
  PROMO_PRODUCT_LIST: "/promo/product-list",
  SETDISCOUNT: "/promo/discount-management/set-discount",
  WALLET: "/wallet",
  LANDING: "/seller-landing",
  ACCOUNT: "/account",
  ACCOUNT_RATING_REVIEW: "/sales/rating-review",
  STORE_INFORMATION: "/account/information",
  STORE_NOTES: "/account/note",
  STORE_LOCATION: "/account/location",
  STORE_PAYMENT_LIST: "/daftar-rekening",
  STORE_DOCUMENT: "/account/document",
  STORE_BALANCE: "/detail-saldo",
  STORE_REPLY_TEMPLATE: "/account/template-reply",
  STORE_SELLER_FLEET: "/account/delivery-service",
  STORE_SELLER_FLEET_LIST: "/account/list-setting",
  STORE_SELLER_FLEET_LIST_DETAIL: "/account/list-setting-detail",
  STORE_SELLER_FLEET_DELIVERY_REGION: "/account/set-delivery-region",
  STORE_SELLER_FLEET_SHIPPING_RATES: "/account/set-shipping-rates",
  NOTIFICATION: "/notification",
  STORE_STATUS: "/store-status",
  PRINT_INVOICE: "/sales/orders/print-invoice",
  SELLER_TUTORIAL: "/seller-tutorial",
  STORE_LAYOUT: "/store-layout",
  SETTING_LIST_SELLER_FLEET: "/setting/seller-fleet",
  SETTING_SELLER_FLEET_ADD: "/setting/seller-fleet/add",
  SALES_RATING_REVIEW: "/sales/rating-review",
  ERROR_500: "/internal-error",
  DOCUMENT_FORM: "/account/document/form",
  DOCUMENT_FORM_BANK: "/account/document/form_bank",
  STORE_NOTES_FORM: "/account/note/add",
  STORE_REPLY_TEMPLATE_FORM: "/account/template-reply/add",
  STORE_LOCATION_FORM: "/account/location/add",
};

export default mobileUrl;
