import { Box, Checkbox, Divider, Flex, Text, Icon } from "renos-ui";

const Card = ({
  data,
  handleCheck,
  isChecked,
  isHasDivider,
  totalSelected,
  noCheck,
}) => {
  return (
    <>
      <Flex gap="16px" alignItems="center">
        {!noCheck && (
          <Flex width="20px">
            <Checkbox
              onChange={handleCheck}
              checked={isChecked}
              disabled={totalSelected === 15 && !isChecked}
            />
          </Flex>
        )}

        <Flex gap="12px" alignItems="center">
          <Box
            as="img"
            src={data.product_image_url}
            height="84px"
            width="84px"
            borderRadius="8px"
            alt="img-product"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = "/assets/images/empty-product.svg";
            }}
          />
          <Flex flexDirection="column">
            <Text color="black75" variant="caption" lineClamp={1}>
              {data.product_name}
            </Text>
            {data?.formatted_product_discount_percentage && (
              <Flex gap="4px" alignItems="center">
                <Flex
                  padding="1px 6px"
                  borderRadius="16px"
                  backgroundColor="red5"
                >
                  <Text variant="small" color="red50" weight="bold">
                    {data.formatted_product_discount_percentage}
                  </Text>
                </Flex>
                <Text variant="small" color="black25" strikeThrough>
                  {data.formatted_product_price}
                </Text>
              </Flex>
            )}
            <Text weight="bold">{data.formatted_selling_price}</Text>
            <Flex gap="4px" alignItems="center">
              <Icon color="yellow50" size={10} name="Star-solid" />
              <Text color="black75" variant="caption" weight="bold">
                {data.review_rating || 0}
              </Text>
              <Text color="black50" variant="caption">
                ({data.review_count || 0} ulasan)
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </Flex>

      <Box>{isHasDivider && <Divider />}</Box>
    </>
  );
};

export default Card;
