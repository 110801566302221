import { useEffect, useMemo, useState } from "react";
import { Box, Button, Checkbox, Flex, Icon, Text, range } from "renos-ui";
import Table from "widgets/desktop/TableDiscountPopup";
// import { useGetCategories } from "services/product-category";
import { useHistory } from "react-router-dom";
import desktopUrl from "constants/desktopUrl";
import { useGetProductDiscount } from "services/promo-management";
import { StyledDialog } from "./styled";
import Filter from "./component/Filter";
import CardSkeleton from "./component/CardSkeleton";
import InfiniteScroll from "widgets/global/InfiniteScroll";
import EmptyData from "./component/EmptyData";
import {
  dataColumn,
  dataSaveFormatter,
  onCheckAllClicked,
} from "./hooks/useAddModalPromo";

const AddModalPromo = ({ addDisclosure }) => {
  const { isOpen, onClose } = addDisclosure;
  const history = useHistory();
  // const { data: categories } = useGetCategories();
  const maxProduct = 50;
  const [dataList, setDatalist] = useState([]);
  const [page, setPage] = useState(1);
  const [queryParams, setQueryParams] = useState({
    query: "",
    category_id: "",
    ordering: "",
  });
  const [checkedProducts, setCheckedProducts] = useState([]);

  const {
    data: dataProduct,
    isLoading: isLoadingDataProduct,
    isRefetching: isRefetchingDataProduct,
  } = useGetProductDiscount(
    {
      ...queryParams,
      active_product_discount: 2,
      page: page,
    },
    {
      onSuccess: (dataSuccess) => {
        if (page === 1) {
          setDatalist([...dataSuccess.data]);
        } else {
          const concatData = dataList.concat(dataSuccess.data);
          setDatalist(concatData);
        }
      },
    }
  );

  const hasNextPage = useMemo(
    () =>
      dataProduct?.meta?.pagination?.next_page_url !== "" &&
      dataProduct?.meta?.pagination?.next_page_url,
    // eslint-disable-next-line
    [dataList]
  );

  useEffect(() => {
    setPage(1);
  }, [queryParams]);

  const isAllLoadingDataProduct = useMemo(
    () => (isLoadingDataProduct || isRefetchingDataProduct) && page === 1,
    [isLoadingDataProduct, isRefetchingDataProduct, page]
  );

  const isCheckedAll = useMemo(
    () => checkedProducts.length === maxProduct,
    // eslint-disable-next-line
    [checkedProducts, dataProduct]
  );

  const isIndeterminate = useMemo(() => {
    if (checkedProducts) {
      return checkedProducts.length > 0 && checkedProducts.length < maxProduct;
    }

    return false;
    // eslint-disable-next-line
  }, [checkedProducts, dataProduct]);

  const handleClickAll = () => {
    onCheckAllClicked(
      dataList,
      checkedProducts,
      setCheckedProducts,
      maxProduct
    );
  };

  const tableColumns = dataColumn({
    isCheckedAll,
    isIndeterminate,
    handleClickAll,
    maxProduct,
    checkedProducts,
    setCheckedProducts,
  });

  const handleSave = () => {
    const tempCheckedProducts = dataSaveFormatter(checkedProducts);
    history.push(desktopUrl.SETDISCOUNT, { data: tempCheckedProducts });
  };

  return (
    <StyledDialog
      isVisible={isOpen}
      onClose={onClose}
      width="680px"
      // height="448px"
      verticalCentered
    >
      <Flex flexDirection="column" width="100%" justifyContent="space-between">
        <Flex justifyContent="space-between" alignContent="center">
          <Flex
            alignContent="center"
            justifyItems="center"
            alignItems="center"
            gap="8px"
          >
            <Text variant="heading3" weight="bold">
              Tambah Produk
            </Text>
            <Text color="black50">(maksimum 50 produk, termasuk varian)</Text>
          </Flex>
          <Icon
            name="Close-outline"
            cursor="pointer"
            size={24}
            color="black60"
            onClick={onClose}
          />
        </Flex>
        <Flex flexDirection="column" mb="24px">
          <Filter setQueryParams={setQueryParams} />
          <Flex
            padding="16px"
            borderColor="black10"
            borderStyle="solid"
            borderWidth="1px"
            borderRadius="8px"
          >
            <Flex
              height="263px"
              overflowY="auto"
              flexDirection="column"
              width="100%"
              gap="16px"
            >
              <InfiniteScroll
                page={page}
                onChange={() => setPage(page + 1)}
                isLoading={isLoadingDataProduct}
                hasNextPage={hasNextPage}
                loader={
                  <Box>
                    <CardSkeleton />
                  </Box>
                }
              >
                {isAllLoadingDataProduct ? (
                  range(5).map((_, indexSkeleton) => (
                    <CardSkeleton key={`card-skeleton-${indexSkeleton}`} />
                  ))
                ) : dataList.length === 0 ? (
                  <EmptyData />
                ) : (
                  <Table
                    width="100%"
                    columns={tableColumns}
                    dataSource={dataList}
                    customHeader={
                      checkedProducts.length > 0 && (
                        <Flex columnGap="20px" alignItems="center" pb="12px">
                          <Flex gap="15px" flexBasis="55%">
                            <Checkbox
                              checked={isCheckedAll}
                              onChange={handleClickAll}
                              indeterminate={
                                checkedProducts.length > 0 &&
                                checkedProducts.length < dataList.length
                              }
                            />
                            <Text weight="bold">
                              {`${checkedProducts.length} / ${maxProduct} Produk Dipilih`}
                            </Text>
                          </Flex>

                          <Flex flexBasis="20%">
                            <Text weight="bold">Harga</Text>
                          </Flex>

                          <Flex flexBasis="20%">
                            <Text weight="bold">Stok</Text>
                          </Flex>
                        </Flex>
                      )
                    }
                  />
                )}
              </InfiniteScroll>
            </Flex>
          </Flex>
        </Flex>

        <Flex justifyContent="space-between">
          <Flex
            alignItems="center"
            backgroundColor="black5"
            borderRadius="8px"
            columnGap="8px"
            px="16px"
            py="10px"
          >
            <Icon name="Info-solid" color="black50" size={20} />
            <Text color="black75" variant="caption">
              Jika ingin mengatur produk lebih dari 50, gunakan Atur Diskon
              Sekaligus
            </Text>
          </Flex>
          <Flex alignSelf="end" width="160px">
            <Button
              size="medium"
              isBlock
              width="160px"
              disabled={checkedProducts.length === 0}
              onClick={handleSave}
            >
              Simpan
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </StyledDialog>
  );
};

export default AddModalPromo;
