import React from "react";
import { Flex, Icon, Box, Text } from "renos-ui";

const CardWarning = ({ onClick }) => {
  return (
    <Flex
      padding="14px 16px"
      alignItems="center"
      backgroundColor="yellow5"
      borderRadius="8px"
      columnGap="8px"
      width="100%"
      height="64px"
      gap="12px"
      boxSizing="border-box"
    >
      <Icon name="Info-solid" color="yellow50" size={20} />
      <Text variant="caption" color="yellow100">
        <>
          Harap hubungi{" "}
          <Box
            color="yellow100"
            as="a"
            onClick={onClick}
            cursor="pointer"
            display="inline"
          >
            <Text weight="bold" variant="caption">
              support@renos.id{" "}
            </Text>
          </Box>{" "}
          untuk mengedit informasi buku tabungan perusahaan.
        </>
      </Text>
    </Flex>
  );
};

export default CardWarning;
