import React, { useMemo } from "react";
import { Flex, Text, Avatar, Divider, Icon } from "renos-ui";

const ChatItem = ({ user, handleDetailUser }) => {
  const avatarSource =
    user.avatar && user.avatar !== "https://images.renos.id/"
      ? user.avatar
      : null;

  const userName = user?.name;
  const userLastComment = user.last_comment.username;
  const lastMessageKey = user?.last_comment?.extras?.key;

  const lastCommentAttachment = useMemo(() => {
    const messageMap = {
      IMAGE: "Foto",
      PRODUCTS: "Produk",
      PDP: "Produk",
      ORDER: "Pesanan",
    };

    let message =
      userLastComment === userName ? "Kamu menerima " : "Kamu mengirim ";

    if (messageMap[lastMessageKey]) {
      message += messageMap[lastMessageKey];
    }

    return message;
  }, [userName, userLastComment, lastMessageKey]);

  return (
    <Flex flexDirection="column" onClick={() => handleDetailUser(user)}>
      <Flex gap="16px" padding="16px">
        <Flex alignItems="start">
          <Avatar
            size={40}
            src={avatarSource}
            icon={<Icon name="Profile-outline" />}
          />
        </Flex>
        <Flex width="100%" flexDirection="column" gap="2px">
          <Text
            lineClamp="1"
            weight={user.count_notif > 0 && "bold"}
            style={{ wordBreak: "break-all" }}
          >
            {user.name}
          </Text>
          <Text variant="caption" lineClamp="1">
            {user.last_comment_message || lastCommentAttachment}
          </Text>
        </Flex>
        {user.count_notif > 0 && (
          <Flex
            backgroundColor="red50"
            width="10px"
            height="8px"
            borderRadius="100px"
          />
        )}
      </Flex>
      <Divider />
    </Flex>
  );
};

export default ChatItem;
