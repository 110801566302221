import ENDPOINTS from "constants/endpoints";
import { useQuery, useMutation } from "react-query";
import client from "services/client";

const sendOfferingQuotation = async (body) => {
  try {
    const { data } = await client.post(ENDPOINTS.offer_quotation_form, body);

    return data;
  } catch (error) {
    throw new Error(error);
  }
};

const getBiddingIncomingRequests = async (params) => {
  const { data } = await client.get(ENDPOINTS.bidding_incoming_request, {
    params,
  });

  return data;
};

const getBiddingMyOffer = async (params) => {
  const { data } = await client.get(ENDPOINTS.bidding_my_offer, {
    params,
  });

  return data;
};

export const useSendOfferingQuotation = (params, extraParams) => {
  return useQuery(
    ["quotation", params],
    () => sendOfferingQuotation({ ...params }),
    {
      ...extraParams,
    }
  );
};

const getDetailBidding = async (params) => {
  const { data } = await client.get(ENDPOINTS.bidding_detail, {
    params,
  });

  return data.data;
};

export const useGetDetailBidding = (params, extraParams) => {
  return useQuery(
    ["get-detail-bidding", params],
    () => getDetailBidding(params),
    {
      ...extraParams,
      staleTime: 0,
    }
  );
};

const getMyBidding = async (params) => {
  const { data } = await client.get(ENDPOINTS.my_bidding, {
    params,
  });

  return data.data;
};

export const useGetMyBdding = (params, extraParams) => {
  return useQuery(["get-my-bidding", params], () => getMyBidding(params), {
    ...extraParams,
    staleTime: 0,
  });
};

const getDownloadAttachment = async (params) => {
  const { data } = await client.get(ENDPOINTS.download_attachment, {
    params,
    responseType: "arraybuffer",
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  return data;
};

export const useGetDownloadAttachment = (extraParams) => {
  return useMutation((body) => getDownloadAttachment(body), {
    ...extraParams,
  });
};

export const useGetBiddingIncomingRequests = (params, extraParams) => {
  return useQuery(
    ["bidding-incoming-requests", params],
    () => getBiddingIncomingRequests({ ...params }),
    {
      ...extraParams,
    }
  );
};

export const useGetBiddingMyOffer = (params, extraParams) => {
  return useQuery(
    ["bidding-my-offer", params],
    () => getBiddingMyOffer({ ...params }),
    {
      ...extraParams,
    }
  );
};
