import { Flex, Skeleton, range, Grid } from "renos-ui";

const LoadingList = () => {
  return range(10).map((_, i) => (
    <Flex
      padding="16px"
      gap="20px"
      flexDirection="column"
      background="white"
      key={`fragment-list-${i}`}
    >
      <Flex flexDirection="column" gap="8px">
        <Skeleton width="30%" />
        <Skeleton width="70%" />
      </Flex>

      <Grid gridTemplateColumns="50% 50%">
        <Flex flexDirection="column" gap="16px" flexGrow="1">
          <Skeleton width="80%" />
          <Skeleton width="80%" />
        </Flex>
        <Flex flexDirection="column" gap="16px" flexGrow="1">
          <Skeleton width="80%" />
          <Skeleton width="80%" />
        </Flex>
      </Grid>
    </Flex>
  ));
};

export default LoadingList;
