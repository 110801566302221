import ENDPOINTS from "constants/endpoints";
import { useMutation, useQuery } from "react-query";
import client from "services/client";
import { formatTime, bouncer } from "helpers";

const getStoreOpenFetcher = async (params) => {
  const { data } = await client.get(ENDPOINTS.hour_schedule, { params });

  return data.data;
};

export const useGetStoreOpen = (params, extraParams) => {
  return useQuery(["getStoreFetcher"], () => getStoreOpenFetcher(params), {
    ...extraParams,
  });
};

const getStoreOpenViewFetcher = async (params) => {
  const { data } = await client.get(ENDPOINTS.hour_schedule, { params });

  const mappedData = data.data?.map((list) => {
    const { day_humane, start, end, id, is_active } = list;

    return (
      is_active && {
        day_humane,
        time: `${formatTime(start)} - ${formatTime(end)}`,
        id,
        is_active,
      }
    );
  });

  const bouncedData = bouncer(mappedData);

  const reducedData = bouncedData.reduce((accumulator, currentValue) => {
    if (accumulator[currentValue.time]) {
      accumulator[currentValue.time].day_humane = [
        accumulator[currentValue.time].day_humane,
        currentValue.day_humane,
      ]
        .join(",")
        .split(",");
    } else {
      accumulator[currentValue.time] = currentValue;
    }

    return accumulator;
  }, {});

  return Object.values(reducedData);
};

export const useGetStoreOpenView = (params, extraParams) => {
  return useQuery(
    ["useGetStoreOpenVie"],
    () => getStoreOpenViewFetcher(params),
    {
      ...extraParams,
    }
  );
};

const updateOpenStoreFetcher = async (body) => {
  const { data } = await client.post(ENDPOINTS.hour_schedule, body);

  return data;
};

export const useUpdateOpenStore = (extraParams) =>
  useMutation((body) => updateOpenStoreFetcher(body), { ...extraParams });

const getStoreCloseFetcher = async (params) => {
  const { data } = await client.get(ENDPOINTS.store_close, { params });

  return data.data;
};

export const useGetStoreClose = (params, extraParams) => {
  return useQuery(
    ["getCloseStoreFetcher"],
    () => getStoreCloseFetcher(params),
    {
      ...extraParams,
    }
  );
};

const updateCloseStoreFetcher = async (body) => {
  const { data } = await client.post(ENDPOINTS.store_close_save, body);

  return data;
};

export const useUpdateCloseStore = (extraParams) =>
  useMutation((body) => updateCloseStoreFetcher(body), { ...extraParams });

const getOpenStoreFetcher = async () => {
  const { data } = await client.get(ENDPOINTS.store_open_now);

  return data;
};

export const useOpenStoreNow = (extraParams) =>
  useMutation((body) => getOpenStoreFetcher(body), { ...extraParams });
