import { useState } from "react";
import {
  colors,
  Flex,
  Text,
  Icon,
  Box,
  Tabs,
  DatePicker,
  Divider,
  useDisclosure,
} from "renos-ui";
import dayjs from "dayjs";
import { getRangeWeek } from "helpers";
import { Wrapper } from "./styled";

const DatepickerRange = ({ value, onChange }) => {
  const popupDisclosure = useDisclosure({ isOpen: false });
  const { type, startDate, endDate } = value;
  const [activeTab, setActiveTab] = useState(value.type);
  const tabItems = [
    {
      label: "Per Hari",
      key: "daily",
      children: () => <RenderDatepicker />,
      width: "132px",
    },
    {
      label: "Per Minggu",
      key: "weekly",
      children: () => <RenderDatepicker />,
      width: "132px",
    },
    {
      label: "Per Bulan",
      key: "monthly",
      children: () => <RenderDatepicker />,
      width: "132px",
    },
  ];

  const handleOnChangeCalendar = (dateValue) => {
    let tempStartDate = activeTab === "daily" ? dateValue : dateValue[0];
    let tempEndDate = null;
    const selectedYear = tempStartDate.getFullYear();
    const selectedMonth = tempStartDate.getMonth();
    const thisMonth = new Date().getMonth();
    const thisDay = new Date().getDate();
    const lastDate = new Date(selectedYear, selectedMonth + 1, 0).getDate();

    if (activeTab === "monthly") {
      tempStartDate.setDate(1);
      tempEndDate = new Date(
        selectedYear,
        selectedMonth,
        thisMonth === selectedMonth ? thisDay : lastDate,
      );
    } else if (activeTab === "weekly") {
      const thisWeek = getRangeWeek(tempStartDate);
      tempStartDate = thisWeek[0];
      tempEndDate = thisWeek[1];
    }

    onChange({
      startDate: tempStartDate,
      endDate: tempEndDate,
      type: activeTab,
    });

    popupDisclosure.onClose();
  };

  const handleChangeTabActive = (activeValue) => {
    setActiveTab(activeValue);
  };

  const getFormattedTypeName = (isWithRealtime) => {
    if (type === "daily") {
      return (
        dayjs().isSame(dayjs(startDate), "day") &&
        `Hari ini ${isWithRealtime ? "(Real Time)" : ""} `
      );
    }

    if (type === "monthly") {
      return dayjs().isSame(dayjs(startDate), "month") && "Bulan ini";
    }

    const thisWeek = getRangeWeek(new Date());

    return (
      dayjs(thisWeek[0]).isSame(dayjs(startDate), "day") &&
      dayjs(thisWeek[1]).isSame(dayjs(endDate), "day") &&
      "Minggu ini"
    );
  };

  const getFormattedDate = () => {
    const dateFormat = dayjs(value.startDate).isSame(dayjs(endDate), "year")
      ? "DD MMM"
      : "DD MMM YYYY";

    const formattedDate =
      type === "daily"
        ? dayjs(startDate).format("DD MMM YYYY")
        : `${dayjs(startDate).format(dateFormat)} - 
          ${dayjs(endDate).format("DD MMM YYYY")}`;

    return formattedDate;
  };

  const RenderDatepicker = () => {
    if (activeTab === "daily") {
      return (
        <Wrapper>
          <DatePicker
            inline
            selected={value.startDate}
            onChange={(vals) => handleOnChangeCalendar(vals)}
            maxDate={new Date()}
          />
        </Wrapper>
      );
    }

    return (
      <Wrapper>
        <DatePicker
          inline
          selected={value.startDate}
          startDate={value.startDate}
          endDate={value.endDate}
          selectsRange
          onChange={(vals) => handleOnChangeCalendar(vals)}
          maxDate={new Date()}
        />
      </Wrapper>
    );
  };

  return (
    <Flex position="relative">
      <Flex
        padding="12px 16px"
        gap="10px"
        borderRadius="8px"
        border={`1px solid ${colors.black10}`}
        alignContent="center"
        alignItems="center"
        cursor="pointer"
        maxHeight="48px"
        onClick={() => popupDisclosure.onToggle(!popupDisclosure.isOpen)}
      >
        <Box width="380px">
          <Text color="black75" weight="bold">
            {getFormattedTypeName(true)}
          </Text>
          <Text color="black75"> {getFormattedDate()}</Text>
        </Box>
        <Icon
          name="Calendar-outline"
          color={popupDisclosure.isOpen ? "blue50" : "black50"}
          size={20}
        />
      </Flex>
      {popupDisclosure.isOpen && (
        <Flex
          position="absolute"
          marginTop="48px"
          backgroundColor="white"
          boxShadow="0px 4px 12px rgba(51, 51, 70, 0.1);"
          border={`1px solid ${colors.black10}`}
          borderRadius="8px"
          padding="24px"
          gap="16px"
          flexDirection="column"
          zIndex={2}
        >
          <Text variant="subtitle" color="black75" weight="bold">
            Atur Rentang Tanggal
          </Text>
          <Tabs
            onChange={handleChangeTabActive}
            defaultActiveKey={activeTab}
            placement="bottom"
            items={tabItems}
          />
          <Divider />
          <Flex justifyContent="space-between">
            <Text color="black50" weight="bold">
              {getFormattedTypeName()}
            </Text>
            <Text color="black50">{getFormattedDate()}</Text>
          </Flex>
        </Flex>
      )}
    </Flex>
  );
};

DatepickerRange.defaultProps = {
  value: { type: "daily", startDate: new Date(), endDate: new Date() },
  onChange: () => {},
};

export default DatepickerRange;
