import ENDPOINTS from "constants/endpoints";
import { useMutation, useQuery } from "react-query";
import client from "../client";

const voucherMasterFetcher = async () => {
  const { data } = await client.get(ENDPOINTS.voucher_master);

  return data;
};

export const useGetVoucherMaster = (extraParams) => {
  return useQuery(["voucherMaster"], voucherMasterFetcher, {
    ...extraParams,
  });
};

const getVoucherFetcher = async (params) => {
  const { data } = await client.get(ENDPOINTS.voucher, { params });

  return data;
};

export const useGetVoucher = (params, extraParams) => {
  return useQuery(["getVoucher", params], () => getVoucherFetcher(params), {
    ...extraParams,
  });
};

const voucherStatusFetcher = async () => {
  const { data } = await client.get(ENDPOINTS.voucher_status);

  return data;
};

export const useGetVoucherStatus = (extraParams) => {
  return useQuery(["voucherStatus"], voucherStatusFetcher, {
    ...extraParams,
  });
};

const addVoucherFetcher = async (body) => {
  const { data } = await client.post(ENDPOINTS.voucher, body);

  return data;
};

export const useAddVoucher = (extraParams) =>
  useMutation((body) => addVoucherFetcher(body), {
    ...extraParams,
  });

const editVoucherFetcher = async (body) => {
  const { data } = await client.put(ENDPOINTS.voucher, body);

  return data;
};

export const useEditVoucher = (extraParams) =>
  useMutation((body) => editVoucherFetcher(body), {
    ...extraParams,
  });

const getVoucherByIdFetcher = async (id) => {
  const { data } = await client.get(`${ENDPOINTS.voucher}/${id}`);

  return data;
};

export const useGetVoucherById = (extraParams) =>
  useMutation((id) => getVoucherByIdFetcher(id), {
    ...extraParams,
  });

const endVoucherFetcher = async (id) => {
  const { data } = await client.post(`${ENDPOINTS.voucher}/end/${id}`);

  return data;
};

export const useEndVoucher = (extraParams) =>
  useMutation((id) => endVoucherFetcher(id), {
    ...extraParams,
  });
