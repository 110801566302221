export const isChecked = (id, checkedProducts) => {
  const listNumber = checkedProducts.map(
    (product) => product.product_sku_number
  );

  return listNumber.includes(id);
};

export const isParentChecked = (
  productSkus,
  checkedProducts,
  isIndeterminate
) => {
  const listNumber = checkedProducts.map(
    (product) => product.product_sku_number
  );
  const totalProduct = productSkus.filter((product) =>
    listNumber.includes(product.product_sku_number)
  ).length;

  if (isIndeterminate) {
    return totalProduct > 0;
  }

  return totalProduct === productSkus.length;
};

export const onCheckAllClicked = (
  checkedProducts,
  maxProduct,
  setCheckedProducts,
  dataList
) => {
  let tempCheckedProducts = checkedProducts;

  if (
    (dataList.length < maxProduct &&
      dataList.length <= tempCheckedProducts.length) ||
    tempCheckedProducts.length === maxProduct
  ) {
    tempCheckedProducts = [];
  } else {
    // eslint-disable-next-line
    const calculateChecked = dataList.map((products) => {
      if (
        tempCheckedProducts.length !== maxProduct &&
        !isChecked(products.product_sku_number, checkedProducts) &&
        products.product_skus.length === 0
      ) {
        tempCheckedProducts.push({
          product_image_url: products.product_image_url,
          product_sku_number: products.product_sku_number,
          product_sku_id: products.product_sku_id,
          product_stock: products.product_stock,
          formatted_product_price: products.formatted_product_price,
          store_sku_number: products.store_sku_number,
          product_name: `${products.product_name}`,
        });
      } else {
        // eslint-disable-next-line
        const findInSKus = products.product_skus.map((sku) => {
          if (
            tempCheckedProducts.length !== maxProduct &&
            !isChecked(sku.product_sku_number, checkedProducts)
          ) {
            tempCheckedProducts.push({
              product_image_url: sku.product_image_url,
              product_sku_number: sku.product_sku_number,
              product_sku_id: sku.product_sku_id,
              product_stock: sku.product_stock,
              formatted_product_price: sku.formatted_product_price,
              store_sku_number: sku.store_sku_number,
              product_name: `${sku.product_name}${
                sku.formatted_variant_value_name
                  ? ` - ${sku.formatted_variant_value_name}`
                  : ""
              }`,
            });
          }
        });
      }
    });
  }

  setCheckedProducts([...tempCheckedProducts]);
};

export const onCheckProductClicked = (
  dataProduct,
  checkedProducts,
  setCheckedProducts
) => {
  let tempCheckedProducts = checkedProducts;

  if (isChecked(dataProduct.product_sku_number, checkedProducts)) {
    tempCheckedProducts = tempCheckedProducts.filter(
      (productFilter) =>
        productFilter.product_sku_number !== dataProduct.product_sku_number
    );
  } else {
    tempCheckedProducts.push({
      product_image_url: dataProduct.product_image_url,
      product_sku_number: dataProduct.product_sku_number,
      product_sku_id: dataProduct.product_sku_id,
      product_stock: dataProduct.product_stock,
      formatted_product_price: dataProduct.formatted_product_price,
      store_sku_number: dataProduct.store_sku_number,
      product_name: `${dataProduct.product_name}${
        dataProduct.formatted_variant_value_name
          ? ` - ${dataProduct.formatted_variant_value_name}`
          : ""
      }`,
    });
  }

  setCheckedProducts([...tempCheckedProducts]);
};

export const onCheckProductParentClicked = (
  productSkus,
  checkedProducts,
  setCheckedProducts
) => {
  let tempCheckedProducts = checkedProducts;
  const listNumber = productSkus.map((sku) => sku.product_sku_number);
  // eslint-disable-next-line
  const listCheckedNumber = checkedProducts.map(
    (sku) => sku.product_sku_number
  );

  if (isParentChecked(productSkus, checkedProducts)) {
    tempCheckedProducts = tempCheckedProducts.filter(
      (filterProduct) => !listNumber.includes(filterProduct.product_sku_number)
    );
  } else if (isParentChecked(productSkus, checkedProducts, true)) {
    // eslint-disable-next-line
    const calculateChecked = productSkus.map((sku) => {
      if (!isChecked(sku.product_sku_number, checkedProducts))
        tempCheckedProducts.push({
          product_image_url: sku.product_image_url,
          product_sku_number: sku.product_sku_number,
          product_sku_id: sku.product_sku_id,
          product_stock: sku.product_stock,
          formatted_product_price: sku.formatted_product_price,
          store_sku_number: sku.store_sku_number,
          product_name: `${sku.product_name}${
            sku.formatted_variant_value_name
              ? ` - ${sku.formatted_variant_value_name}`
              : ""
          }`,
        });
    });
  } else {
    // eslint-disable-next-line
    const calculateChecked = productSkus.map((sku) => {
      tempCheckedProducts.push({
        product_image_url: sku.product_image_url,
        product_sku_number: sku.product_sku_number,
        product_sku_id: sku.product_sku_id,
        product_stock: sku.product_stock,
        formatted_product_price: sku.formatted_product_price,
        store_sku_number: sku.store_sku_number,
        product_name: `${sku.product_name}${
          sku.formatted_variant_value_name
            ? ` - ${sku.formatted_variant_value_name}`
            : ""
        }`,
      });
    });
  }

  setCheckedProducts([...tempCheckedProducts]);
};
