import { createContext, useContext, useReducer } from "react";
import constants from "./constants";

const initDeliveryState = {
  listDetail: {},
  isBack: false,
  selectedId: null,
  dataCoverage: [
    {
      listProvince: [],
      selectedProvince: {},
      listCity: [],
      selectedCity: [],
      listDistrict: [],
      selectedDistrict: [],
      estimateDays: "",
    },
  ],
  dataRates: {
    categories: [],
    active: false,
    calculation: {
      type: "",
      multiple: {},
      group: [{ min: 1 }],
      advanced_options: [
        {
          condition_type_id: null,
          condition: "",
          condition_base_id: 1,
          condition_weight: false,
          condition_base_weight_value: false,
          condition_base_tarif: false,
          condition_base_weight_start: false,
          condition_base_weight_end: false,
        },
      ],
    },
  },
};

const DeliveryContext = createContext({});

export function useDeliveryContext() {
  return useContext(DeliveryContext);
}

export const deliveryReducer = (state, action) => {
  switch (action.type) {
    case constants.GET_LIST_SETTING_BY_ID:
    case constants.ADD_COVERAGE:
    case constants.DELETE_COVERAGE:
    case constants.GET_PROVINCE_BY_INDEX:
    case constants.GET_CITY_BY_INDEX:
    case constants.GET_DISTRICT_BY_INDEX:
    case constants.ADD_ESTIMATE_BY_INDEX:
    case constants.SET_RESPONSE_EDIT:
      return {
        ...state,
        ...action.payload,
      };

    case constants.GET_PROVINCE: {
      const { indexCoverage, dataProvince } = action.payload;
      const newDataCoverage = state.dataCoverage.map((coverage, index) => {
        if (index === indexCoverage) {
          return {
            ...coverage,
            listProvince: dataProvince,
          };
        }

        return coverage;
      });

      return {
        ...state,
        dataCoverage: newDataCoverage,
      };
    }

    case constants.SET_TOGGLE_CATEGORIES:
    case constants.SET_ACTIVE_CATEGORIES:
    case constants.SET_MULTIPLE_OF_WEIGHT:
    case constants.SET_WEIGHT_GROUP:
    case constants.DELETE_WEIGHT_GROUP_BY_INDEX:
    case constants.SET_ADD_GROUP:
      return {
        ...state,
        dataRates: action.payload,
      };

    case constants.SET_TYPE_CALCULATION: {
      return {
        ...state,
        dataRates: action.payload,
      };
    }

    case constants.SET_IS_BACK:
      return {
        ...state,
        ...action.payload,
      };

    case constants.ADD_ADVANCE_OPTIONS: {
      return {
        ...state,
        ...action.payload,
      };
    }

    case constants.HANDLE_CHANGE_ADVANCE_OPTIONS: {
      return {
        ...state,
        ...action.payload,
      };
    }

    case constants.DELETE_ADVANCE_OPTIONS: {
      return {
        ...state,
        ...action.payload,
      };
    }

    case constants.RESET_TO_INTIAL_STATE: {

      return {
        listDetail: {},
        isBack: false,
        selectedId: null,
        dataCoverage: [
          {
            listProvince: [],
            selectedProvince: {},
            listCity: [],
            selectedCity: [],
            listDistrict: [],
            selectedDistrict: [],
            estimateDays: "",
          },
        ],
        dataRates: {
          categories: [],
          active: false,
          calculation: {
            type: "",
            multiple: {},
            group: [{ min: 1 }],
            advanced_options: [
              {
                condition_type_id: null,
                condition: "",
                condition_base_id: 1,
                condition_weight: false,
                condition_base_weight_value: false,
                condition_base_tarif: false,
                condition_base_weight_start: false,
                condition_base_weight_end: false,
              },
            ],
          },
        },
      };
    }

    default:
      return state;
  }
};

const DeliveryProvider = ({ children }) => {
  const [state, dispatch] = useReducer(deliveryReducer, initDeliveryState);

  return (
    <DeliveryContext.Provider value={{ state, dispatch }}>
      {children}
    </DeliveryContext.Provider>
  );
};

export default DeliveryProvider;