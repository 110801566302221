import React, { useState } from "react";
import RegisterStoreName from "./check-store-name";
import RegisterDetail from "./register-detail";
import { useCheckAuth, useCheckStore } from "services/auth";
import { useEffect } from "react";
import { ToastProvider } from "renos-ui";

const Register = () => {
  const [step, setStep] = useState(1);
  const { data: authData } = useCheckAuth();
  const { data: storeData } = useCheckStore();

  useEffect(() => {
    if (!authData?.status) {
      window.location.pathname = "/seller-landing";
    }
  }, [authData]);

  useEffect(() => {
    if (storeData?.status) {
      window.location.pathname = "/";
    }
  }, [storeData]);

  return (
    <ToastProvider zIndex={10000}>
      {step === 1 ? (
        <RegisterStoreName setStep={setStep} />
      ) : (
        <RegisterDetail setStep={setStep} />
      )}
    </ToastProvider>
  );
};

export default Register;