import useQueryParams from "hooks/useQueryParams";
import React from "react";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { Box, Flex, Tabs, Text } from "renos-ui";
import NotificationSelling from "./NotificationSelling";
import NotificationUpdate from "./NotificationUpdate";

const Notification = () => {
  const query = useQueryParams();

  const { push } = useHistory();

  const [activeTab, setactiveTab] = useState(
    query.get("tab-active") || "selling"
  );

  const handleChangeTabActive = (value) => {
    setactiveTab(value);
    push(`/notification?tab-active=${value}`);
  };

  const items = [
    {
      label: "Penjualan",
      key: "selling",
      children: () => <NotificationSelling />,
    },
    {
      label: "Update",
      key: "update",
      children: () => <NotificationUpdate />,
    },
  ];

  return (
    <Flex flexDirection="column" rowGap="24px" height="100%">
      <Text variant="heading3" weight="bold">
        Notifikasi Toko
      </Text>
      <Box
        padding="16px 24px"
        borderRadius="16px"
        backgroundColor="white"
        height="100%"
      >
        <Tabs
          onChange={handleChangeTabActive}
          defaultActiveKey={activeTab}
          placement="bottom"
          items={items}
        />
      </Box>
    </Flex>
  );
};

export default Notification;
