import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Flex,
  Input,
  Icon,
  Text,
  isEmpty,
  SelectInput,
  useDisclosure,
  Spinner,
  Divider,
  Checkbox,
} from "renos-ui";
import MobileLayout from "layouts/MobileLayout";
import LocationDrawer from "./LocationDrawer";
import useLocation from "./useLocation";
import CustomHeader from "./CustomeHeader";

const Location = ({ location }) => {
  const {
    value,
    setValue,
    handleChangevalue,
    isError,
    setIsError,
    listProvince,
    listCity,
    listDistrict,
    listSubDistrict,
    emptyOptions,
    handleAddLocation,
    handleSelectChange,
    isLoadingSave,
  } = useLocation();

  const showMap = useDisclosure({ isOpen: false });
  const [mode, setMode] = useState("");
  const [isEditPrimary, setIsEditPrimary] = useState(false);

  useEffect(() => {
    if (location.state !== undefined) {
      setMode(location.state.mode);

      if (location?.state?.data) {
        const dataGet = location.state.data;

        if (!dataGet.coordinates.latitude || !dataGet.coordinates.longitude) {
          dataGet.coordinates = { latitude: -6.175392, longitude: 106.827153 };
        }

        dataGet.is_coordinate_changed = true;

        const {
          is_primary: isDataPrimary,
          lower_district_name: lowerDistrictName,
          district_name: districtName,
          city_name: cityName,
          province_name: provinceName,
          postal_code: postalCode,
        } = dataGet;

        dataGet.point_address =
          dataGet.point_address ||
          `${lowerDistrictName}, ${districtName}, ${cityName}, ${provinceName} ${postalCode}`;

        setValue({ ...dataGet });

        if (isDataPrimary) setIsEditPrimary(true);
      }
    }
    // eslint-disable-next-line
  }, []);

  const checkedCheckbox = value?.is_primary === true;

  const handleCheckBox = (isChecked) => {
    setValue((prev) => ({
      ...prev,
      is_primary: isChecked,
    }));
  };

  return (
    <MobileLayout customHeader={<CustomHeader mode={mode} />}>
      <Flex width="100%" flexDirection="column">
        <Flex
          style={{ height: "calc(100% - 80px)", overflow: "auto" }}
          marginBottom="10px"
        >
          <Flex flexDirection="column" rowGap="16px" width="100%" p="16px">
            <Flex flexDirection="column" gap="20px">
              <Flex flexDirection="column" gap="16px">
                <Box width="100%">
                  <Input
                    value={value?.name}
                    label="Nama Lokasi"
                    name="name"
                    placeholder="Contoh: Gudang, Toko, Cabang, dll."
                    maxLength={60}
                    size="small"
                    onChange={handleChangevalue}
                    error="Nama Lokasi harus diisi."
                    isError={isError?.name}
                  />
                </Box>
              </Flex>
              <Flex flexDirection="column" gap="16px">
                <Box width="100%">
                  <Input
                    value={value?.address}
                    name="address"
                    label="Alamat Toko"
                    size="small"
                    placeholder="Tulis alamat lengkap toko"
                    onChange={handleChangevalue}
                    error={
                      value?.address && value?.address?.length < 15
                        ? "Alamatmu terlalu pendek, minimal 15 karakter."
                        : "Alamat harus diisi."
                    }
                    isError={isError?.address}
                  />
                </Box>
              </Flex>
              <Flex>
                <Divider height="8px !important" backgroundColor="black5" />
              </Flex>
              <Flex flexDirection="column" rowGap="8px">
                <Text weight="bold" variant="caption" color="black50">
                  Provinsi
                </Text>
                <SelectInput
                  size="small"
                  options={listProvince?.data?.map((row) => ({
                    label: row.province_name,
                    value: row.province_id,
                  }))}
                  placeholder="Pilih Provinsi"
                  onChange={(event) =>
                    handleSelectChange(event, "province_name")
                  }
                  isError={isError?.province_id}
                  hideSelectedOptions={true}
                  value={{
                    label: value?.province_name,
                    value: value?.province_id,
                  }}
                />
                {isError?.province_id && (
                  <Text variant="caption" color="red50">
                    Provinsi harus dipilih.
                  </Text>
                )}
              </Flex>
              <Flex flexDirection="column" rowGap="8px">
                <Text weight="bold" variant="caption" color="black50">
                  Kota/Kabupaten
                </Text>
                <SelectInput
                  size="small"
                  options={
                    listCity?.data?.map((row) => ({
                      label: row.city_name,
                      value: row.city_id,
                    })) || emptyOptions
                  }
                  placeholder="Pilih Kota/Kabupaten"
                  onChange={(event) => handleSelectChange(event, "city_name")}
                  isDisabled={!value.province_id}
                  value={{
                    label: value?.city_name,
                    value: value?.city_id,
                  }}
                  hideSelectedOptions={true}
                  isError={isError?.city_id}
                />
                {isError?.city_id && (
                  <Text variant="caption" color="red50">
                    Kota harus dipilih.
                  </Text>
                )}
              </Flex>
              <Flex flexDirection="column" rowGap="8px">
                <Text weight="bold" variant="caption" color="black50">
                  Kecamatan
                </Text>
                <SelectInput
                  size="small"
                  options={
                    listDistrict?.data?.map((row) => ({
                      label: row.district_name,
                      value: row.district_id,
                    })) || emptyOptions
                  }
                  placeholder="Pilih Kecamatan"
                  onChange={(event) =>
                    handleSelectChange(event, "district_name")
                  }
                  isDisabled={!value.city_id}
                  value={{
                    label: value?.district_name,
                    value: value?.district_id,
                  }}
                  hideSelectedOptions={true}
                  isError={isError?.district_id}
                />
                {isError?.district_id && (
                  <Text variant="caption" color="red50">
                    Kecamatan harus dipilih.
                  </Text>
                )}
              </Flex>
              <Flex flexDirection="column" rowGap="8px">
                <Text weight="bold" variant="caption" color="black50">
                  Kelurahan
                </Text>
                <SelectInput
                  size="small"
                  options={
                    listSubDistrict?.data?.map((row) => ({
                      label: row.lower_district_name,
                      value: row.lower_district_id,
                      postal_code: row.postal_code,
                    })) || emptyOptions
                  }
                  placeholder="Pilih Kelurahan"
                  onChange={(event) =>
                    handleSelectChange(event, "lower_district_name")
                  }
                  isDisabled={!value.district_id}
                  value={{
                    label: value?.lower_district_name,
                    value: value?.lower_district_id,
                  }}
                  hideSelectedOptions={true}
                  isError={isError?.lower_district_id}
                />
                {isError?.lower_district_id && (
                  <Text variant="caption" color="red50">
                    Kelurahan harus dipilih.
                  </Text>
                )}
              </Flex>

              <Flex flexDirection="column" gap="16px">
                <Box width="100%">
                  <Input
                    value={value?.contact_person}
                    label="Nama PIC"
                    name="contact_person"
                    size="small"
                    placeholder="Tulis nama PIC / gudang"
                    onChange={handleChangevalue}
                    error="nama PIC harus diisi."
                    isError={isError?.contact_person}
                  />
                </Box>
              </Flex>

              <Flex flexDirection="column" gap="16px">
                <Box width="100%">
                  <Input
                    value={value?.contact_number}
                    label="Nomor HP/Telepon"
                    name="contact_number"
                    size="small"
                    placeholder="Masukkan nomor HP/telepon"
                    onChange={handleChangevalue}
                    error={
                      isError?.patternNumber
                        ? "Format nomor hp indonesia harus dimulai +62."
                        : "Nomor HP/Telepon harus diisi."
                    }
                    isError={isError?.contact_number || isError?.patternNumber}
                  />
                </Box>
              </Flex>

              <Flex flexDirection="column" gap="16px">
                <Box width="100%">
                  <Input
                    value={value?.postal_code}
                    label="Kode Pos"
                    name="postal_code"
                    size="small"
                    placeholder="Pilih kode pos"
                    disabled
                  />
                </Box>
              </Flex>

              {!isEmpty(value.postal_code) && (
                <Flex
                  width="100%"
                  flexDirection="column"
                  gap="8px"
                  marginBottom="8px"
                >
                  <Flex justifyContent="space-between">
                    <Flex>
                      <Text variant="caption" color="black50" weight="bold">
                        Pin Lokasi
                      </Text>
                    </Flex>
                    <Flex>
                      {value.point_address && (
                        <Flex onClick={() => showMap.onOpen()}>
                          <Text variant="caption" color="blue50" weight="bold">
                            Ubah
                          </Text>
                        </Flex>
                      )}
                    </Flex>
                  </Flex>

                  <Flex
                    gap="16px"
                    alignItems="center"
                    onClick={() => showMap.onOpen()}
                  >
                    <Flex
                      background={`url("/assets/images/mobile/bg-map.svg")`}
                      height="48px"
                      minWidth="48px"
                      borderRadius="8px"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Icon
                        name="Location-solid"
                        color="blue50"
                        cursor="pointer"
                        width
                      />
                    </Flex>
                    <Flex>
                      {value?.point_address ? (
                        <Text variant="small">{value.point_address}</Text>
                      ) : (
                        <Text color="blue50" variant="small" weight="bold">
                          Set Lokasi
                        </Text>
                      )}
                    </Flex>
                  </Flex>

                  <Flex>
                    <Text variant="caption" color="black25">
                      Pastikan lokasi yang kamu tandai di peta sesuai dngan
                      alamat yang kamu isi di atas.
                    </Text>
                  </Flex>
                  {isError?.is_coordinate_changed && (
                    <Text color="red50" variant="caption">
                      Posisi harus dipilih.
                    </Text>
                  )}
                </Flex>
              )}

              <Flex gap="8px" alignItems="center" width="196px" height="24px">
                <Checkbox
                  disabled={isEditPrimary || isLoadingSave}
                  checked={checkedCheckbox}
                  onChange={(isChecked) => handleCheckBox(isChecked)}
                />
                <Text color="black75" variant="caption">
                  Jadikan Utama
                </Text>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
        <Flex
          left="0"
          right="0"
          gap="16px"
          bottom="0"
          padding="16px"
          backgroundColor="white"
          boxShadow="0 0 8px 0 rgba(0, 0, 0, 0.05)"
        >
          <Button
            isBlock
            size="large"
            type="submit"
            onClick={handleAddLocation}
            disabled={isLoadingSave}
            preffix={isLoadingSave && <Spinner color="white" size="8px" />}
          >
            <Text weight="bold">Simpan Lokasi</Text>
          </Button>
        </Flex>

        <LocationDrawer
          isOpenDrawer={showMap.isOpen}
          setCloseDrawer={showMap.onClose}
          value={value}
          setValue={setValue}
          isError={isError}
          setIsError={setIsError}
        />
      </Flex>
    </MobileLayout>
  );
};

export default Location;
