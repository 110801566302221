import { useMemo } from "react";
import {
  Flex,
  Box,
  Text,
  Input,
  Divider,
  Icon,
  Button,
  SelectInput,
  isEmpty,
} from "renos-ui";
import { FlexRadioWrap } from "../styled";

const CalculateMethod = ({
  dataCalculate,
  setDataCalculate,
  isLoading,
  validation,
  setValidation,
  conditionType,
  conditionBaseType,
}) => {
  const handleChangeType = (type) => {
    setDataCalculate({ ...dataCalculate, type: type });
  };

  const handleChangeMultiple = (name, value) => {

    setValidation({
      ...validation,
      multiple: {
        ...validation.multiple,
        [name]: false,
      },
    });

    setDataCalculate({
      ...dataCalculate,
      multiple: {
        ...dataCalculate.multiple,
        [name]: value,
      },
    });
  };

  const handleChangeGroup = (name, value, indexGroup) => {
    const tempGroup = dataCalculate.group;
    const tempValidationGroup = validation.group;
    tempValidationGroup[indexGroup][name] = false;

    const tempValue = Number.isNaN(value) ? 0 : value;

    if (tempGroup[indexGroup + 1] !== undefined && name === "max") {
      tempGroup[indexGroup + 1].min = tempValue + 1;
    }

    setValidation({
      ...validation,
      group: [...tempValidationGroup],
    });

    tempGroup[indexGroup][name] = tempValue;
    setDataCalculate({
      ...dataCalculate,
      group: [...tempGroup],
    });
  };

  const handleAddGroup = () => {
    const tempGroup = dataCalculate.group;
    const tempValidationGroup = validation.group;

    tempGroup.push({ min: tempGroup[tempGroup.length - 1].max + 1 });
    tempValidationGroup.push({ price: false, max: false });

    setValidation({
      ...validation,
      group: [...tempValidationGroup],
    });

    setDataCalculate({
      ...dataCalculate,
      group: [...tempGroup],
    });
  };

  const handleDeleteGroup = (indexGroup) => {
    const tempGroup = dataCalculate.group.filter(
      (dataFilter) => dataFilter.min !== dataCalculate.group[indexGroup].min
    );
    const validateTempGroup = tempGroup.map((group, indexGroup) => {
      let tempMin = group.min;

      if (indexGroup !== 0 && indexGroup !== tempGroup.length) {
        tempMin = tempGroup[indexGroup - 1].max + 1;
      }

      return {
        ...group,
        min: tempMin,
      };
    });

    validateTempGroup[0].min =
      validateTempGroup[0].min !== 1 ? 1 : validateTempGroup[0].min;

    setDataCalculate({
      ...dataCalculate,
      group: [...validateTempGroup],
    });
  };

  const isAllowAddGroup = useMemo(() => {
    const filterMaxWeight = dataCalculate?.group?.filter(
      (groupFilter) => groupFilter.max === undefined || !groupFilter.max
    );

    const filterPrice = dataCalculate?.group?.filter(
      (groupFilter) =>
        groupFilter.price === undefined ||
        (groupFilter.price !== 0 && !groupFilter.price)
    );

    return filterMaxWeight?.length === 0 && filterPrice?.length === 0;
  }, [dataCalculate]);

  const handleChangeTypeAdvancedOption = (name, value, index) => {

    const tempDataCalculate = dataCalculate;
    tempDataCalculate.advanced_options[index][name] = value;
    const tempValidationAdvancedOption = validation.advancedOptions;
    tempValidationAdvancedOption[index][name] = false;

    const weight = tempDataCalculate.advanced_options[index].condition_weight;

    let startWeight =
      name === "condition_type_id"
        ? 0
        : tempDataCalculate.advanced_options[index].condition_base_weight_start;
    let endWeight =
      name === "condition_type_id"
        ? 0
        : tempDataCalculate.advanced_options[index].condition_base_weight_end;

    if (name === "condition_type_id") {
      tempDataCalculate.advanced_options[index].condition =
        conditionType[value - 1].label;
    }

    if (name === "condition_base_id") {
      tempDataCalculate.advanced_options[index].condition_base =
        conditionBaseType[value - 1].label;

      if (tempDataCalculate.advanced_options[index].condition_base_id === 1 || tempDataCalculate.advanced_options[index].condition_base_id === 2) {
        tempDataCalculate.advanced_options[index].condition_base_weight_value =
          undefined;
        tempDataCalculate.advanced_options[index].condition_base_tarif =
          undefined;
        startWeight = undefined;
      } else {
        tempDataCalculate.advanced_options[index].condition_base_weight_start =
          undefined;
        tempDataCalculate.advanced_options[index].condition_base_weight_end =
          undefined;
        tempDataCalculate.advanced_options[index].condition_base_tarif =
          undefined;
        endWeight = undefined;
      }
    }

    switch (tempDataCalculate.advanced_options[index].condition_type_id) {
      case 1:
        tempDataCalculate.advanced_options[index].condition_base_weight_end = weight - 1;
        tempDataCalculate.advanced_options[index].condition_base_weight_start =
          startWeight;
        tempValidationAdvancedOption[index].condition_base_weight_end = false;
        break;
      case 2:
        tempDataCalculate.advanced_options[index].condition_base_weight_end =
          weight;
        tempDataCalculate.advanced_options[index].condition_base_weight_start =
          startWeight;
        tempValidationAdvancedOption[index].condition_base_weight_end = false;
        break;
      case 3:
        tempDataCalculate.advanced_options[index].condition_base_weight_start =
          weight + 1;
        tempDataCalculate.advanced_options[index].condition_base_weight_end =
          endWeight;
        tempValidationAdvancedOption[index].condition_base_weight_end = false;
        break;
      case 4:
        tempDataCalculate.advanced_options[index].condition_base_weight_start =
          weight;
        tempDataCalculate.advanced_options[index].condition_base_weight_end =
          endWeight;
        tempValidationAdvancedOption[index].condition_base_weight_end = false;

        break;

      default:
        break;
    }

    setDataCalculate({ ...tempDataCalculate });
    setValidation({
      ...validation,
      advanced_options: [...tempValidationAdvancedOption],
    });
  };

  const isAllowAddCondition = useMemo(() => {
    const lastCondition =
      dataCalculate?.advanced_options?.[
      dataCalculate?.advanced_options?.length - 1
      ];

    if (
      lastCondition?.condition_type_id &&
      lastCondition?.condition_base_id &&
      (lastCondition?.condition_base_tarif ||
        lastCondition?.condition_base_tarif === 0)
    ) {
      if (lastCondition?.condition_base_id === 1) {
        return !isEmpty(lastCondition?.condition_base_weight_value);
      } else {
        return (
          !isEmpty(lastCondition?.condition_base_weight_start) &&
          !isEmpty(lastCondition?.condition_base_weight_end)
        );
      }
    }

    return false;
  }, [dataCalculate]);

  const handleAddAdvancedOption = () => {
    const tempDataCalculate = dataCalculate;
    const tempValidation = validation.advancedOptions;
    tempDataCalculate.advanced_options.push({
      condition_type_id: 1,
      condition_base_id: 1,
    });
    tempValidation.push({ condition_type_id: false, condition_base_id: false });

    setDataCalculate({ ...tempDataCalculate });
    setValidation({
      ...validation,
      advancedOptions: [...tempValidation],
    });
  };

  const handleRemoveAdvancedOption = (index) => {
    const tempDataCalculate = dataCalculate;
    tempDataCalculate.advanced_options.splice(index, 1);

    const tempValidation = validation.advancedOptions;
    tempValidation.splice(index, 1);

    setDataCalculate({ ...tempDataCalculate });
    setValidation({
      ...validation,
      advancedOptions: [...tempValidation],
    });
  };

  return (
    <Flex flexDirection="column" gap="16px">
      <Text color="black75" weight="bold">
        Pilih Cara Perhitungan
      </Text>
      <Flex gap="16px" flexDirection="column">
        <Flex gap="8px">
          <Input.Radio
            name="calculateType"
            checked={dataCalculate.type === "multiple"}
            onClick={() => handleChangeType("multiple")}
          />
          <Flex gap="16px" flexDirection="column">
            <Flex gap="4px" flexDirection="column">
              <Text color="black75">Berdasarkan kelipatan berat</Text>
              <Text color="black50">
                Atur ongkos kirim berdasarkan kelipatan berat.
              </Text>
            </Flex>
            {dataCalculate.type === "multiple" && (
              <Flex gap="16px" id="multiple">
                <Box width="420px">
                  <Input.Number
                    isError={validation.multiple.weight}
                    error={validation.multiple.weightText}
                    onChange={(event) => handleChangeMultiple("weight", event)}
                    name="weight"
                    value={dataCalculate.multiple.weight}
                    sufix={
                      <Text color="black50" weight="bold">
                        g
                      </Text>
                    }
                    disabled={isLoading}
                    placeholder="Masukkan Kelipatan Berat"
                  />
                </Box>
                <Box width="420px">
                  <Input.Number
                    isError={validation.multiple.price}
                    error={validation.multiple.weightTextPrice}
                    disabled={isLoading}
                    onChange={(event) => handleChangeMultiple("price", event)}
                    name="price"
                    value={dataCalculate.multiple.price?.toString()}
                    prefix={
                      <Text color="black50" weight="bold">
                        Rp
                      </Text>
                    }
                    placeholder="Masukkan Tarif"
                  />
                </Box>
              </Flex>
            )}
          </Flex>
        </Flex>

        <Flex gap="8px">
          <Input.Radio
            name="calculateType"
            checked={dataCalculate.type === "group"}
            onClick={() => handleChangeType("group")}
          />
          <Flex gap="16px" flexDirection="column">
            <Flex gap="4px" flexDirection="column">
              <Text color="black75">Berdasarkan Kelompok berat</Text>
              <Text color="black50">
                Atur ongkos kirim berdasarkan kelompok berat.
              </Text>
            </Flex>
            {dataCalculate.type === "group" && (
              <>
                {dataCalculate.group.map((dataGroup, indexGroup) => (
                  <Flex
                    gap="16px"
                    key={`row-group${indexGroup}`}
                    id={`group${indexGroup}`}
                  >
                    <Box width="190px">
                      <Input.Number
                        onChange={(event) =>
                          handleChangeGroup("min", event, indexGroup)
                        }
                        name="min"
                        isError={validation?.group[indexGroup]?.min}
                        error={validation?.group[indexGroup]?.minText}
                        value={dataGroup.min}
                        sufix={
                          <Text color="black50" weight="bold">
                            g
                          </Text>
                        }
                        placeholder="Berat Minumum"
                        disabled={indexGroup !== 0}
                      />
                    </Box>
                    <Box width="8px" marginTop="20px">
                      <Divider />
                    </Box>
                    <Box width="190px">
                      <Input.Number
                        disabled={isLoading}
                        isError={validation?.group[indexGroup]?.max}
                        error={validation?.group[indexGroup]?.maxText}
                        onChange={(event) =>
                          handleChangeGroup("max", event, indexGroup)
                        }
                        name="max"
                        value={dataGroup.max}
                        sufix={
                          <Text color="black50" weight="bold">
                            g
                          </Text>
                        }
                        placeholder="Berat Maksimum"
                      />
                    </Box>
                    <Box
                      width={dataCalculate.group.length > 1 ? "370px" : "420px"}
                    >
                      <Input.Number
                        isError={validation?.group[indexGroup]?.price}
                        error={validation?.group[indexGroup]?.priceText}
                        disabled={isLoading}
                        onChange={(event) =>
                          handleChangeGroup("price", event, indexGroup)
                        }
                        name="price"
                        // value={dataGroup.price}
                        value={dataGroup.price?.toString()}
                        prefix={
                          <Text color="black50" weight="bold">
                            Rp
                          </Text>
                        }
                        placeholder="Masukkan Tarif"
                      />
                    </Box>
                    {dataCalculate.group.length > 1 && (
                      <Flex position="relative">
                        <Button
                          style={{ position: "absolute" }}
                          onClick={() => handleDeleteGroup(indexGroup)}
                          variant="tertiary"
                          icon={
                            <Icon
                              name="Trash-outline"
                              size={24}
                              color="black50"
                            />
                          }
                        />
                      </Flex>
                    )}
                  </Flex>
                ))}
                <Flex
                  gap="8px"
                  alignSelf="start"
                  cursor={isAllowAddGroup ? "pointer" : "not-allowed"}
                  onClick={isAllowAddGroup ? handleAddGroup : () => { }}
                  alignItems="center"
                >
                  <Icon
                    name="Plus-solid"
                    size={20}
                    color={isAllowAddGroup ? "blue50" : "black50"}
                  />
                  <Text
                    weight="bold"
                    color={isAllowAddGroup ? "blue50" : "black50"}
                  >
                    Tambah Kelompok Berat
                  </Text>
                </Flex>
              </>
            )}
          </Flex>
        </Flex>

        <Flex gap="8px">
          <Input.Radio
            name="calculateType"
            checked={dataCalculate.type === "advanced_options"}
            onClick={() => handleChangeType("advanced_options")}
          />

          <Flex gap="16px" flexDirection="column" flex={1}>
            <Flex gap="4px" flexDirection="column">
              <Text color="black75">Opsi Lanjutan</Text>
              <Text color="black50">
                Atur ongkos kirim berdasarkan campuran kelipatan berat dan
                kelompok berat.
              </Text>
            </Flex>
            {dataCalculate.type === "advanced_options" && (
              <>
                {dataCalculate?.advanced_options?.map(
                  (advancedOption, indexAdvancedOption) => (
                    <Flex
                      gap="16px"
                      padding="24px"
                      borderWidth="1px"
                      borderRadius="8px"
                      borderStyle="solid"
                      borderColor="black10"
                      flexDirection="column"
                      key={`row-advanced_options${indexAdvancedOption}`}
                    >
                      <Flex
                        gap="16px"
                        alignContent="center"
                        justifyContent="space-between"
                      >
                        <Flex gap="16px" alignContent="center">
                          <Box
                            backgroundColor="blue50"
                            width="4px"
                            orientation="vertical"
                            borderRadius="8px"
                            height="24px"
                          />
                          <Text color="black75" weight="bold">
                            Kondisi {indexAdvancedOption + 1}
                          </Text>
                        </Flex>
                        {indexAdvancedOption > 0 && (
                          <Flex cursor="pointer">
                            <Icon
                              name="Trash-outline"
                              size={20}
                              color="red50"
                              onClick={() =>
                                handleRemoveAdvancedOption(indexAdvancedOption)
                              }
                            />
                          </Flex>
                        )}
                      </Flex>
                      <Flex gap="16px" flexDirection="column">
                        <Text variant="caption" color="black50" weight="bold">
                          Jika Berat
                        </Text>
                        <Flex gap="16px">
                          <Flex flexDirection="column" gap="16px" flex={1}>
                            <SelectInput
                              isDisabled={isLoading}
                              options={conditionType}
                              value={conditionType.find(
                                (dataConditionType) =>
                                  dataConditionType.value ===
                                  advancedOption?.condition_type_id
                              )}
                              onChange={({ value }) =>
                                handleChangeTypeAdvancedOption(
                                  "condition_type_id",
                                  value,
                                  indexAdvancedOption
                                )
                              }
                            />
                          </Flex>
                          <Flex flex={1}>
                            <Input.Number
                              sufix={
                                <Text color="black50" weight="bold">
                                  g
                                </Text>
                              }
                              placeholder="Berat"
                              isError={
                                validation.advancedOptions[indexAdvancedOption]
                                  ?.condition_weight
                              }
                              error={
                                validation.advancedOptions[indexAdvancedOption]
                                  ?.condition_weightText
                              }
                              disabled={isLoading}
                              value={advancedOption?.condition_weight}
                              onChange={(event) =>
                                handleChangeTypeAdvancedOption(
                                  "condition_weight",
                                  event,
                                  indexAdvancedOption
                                )
                              }
                            />
                          </Flex>
                        </Flex>

                      </Flex>
                      <Flex>
                        <Text variant="caption" color="black50" weight="bold">
                          Maka hitung ongkos kirim berdasarkan
                        </Text>
                      </Flex>
                      <Flex flexDirection="column" gap="8px" flex={1}>
                        {conditionBaseType.map(
                          (dataconditionBaseType, indexConditionBaseType) => (
                            <Box
                              key={`condition-base-${indexConditionBaseType}-${indexAdvancedOption}`}
                            >
                              <FlexRadioWrap gap="8px" alignItems="center">
                                <Input.Radio
                                  disabled={isLoading}
                                  name={`condition-base-${indexAdvancedOption}`}
                                  checked={
                                    advancedOption?.condition_base_id ===
                                    dataconditionBaseType.value
                                  }
                                  onClick={() =>
                                    handleChangeTypeAdvancedOption(
                                      "condition_base_id",
                                      dataconditionBaseType.value,
                                      indexAdvancedOption
                                    )
                                  }
                                />
                                <Text color="black75">
                                  {dataconditionBaseType.label}
                                </Text>
                              </FlexRadioWrap>
                              {advancedOption?.condition_base_id ===
                                dataconditionBaseType.value &&
                                dataconditionBaseType.value === 1 && (
                                  <Flex
                                    gap="16px"
                                    ml="36px"
                                  >
                                    <Flex flex={1}>
                                      <Input.Number
                                        sufix={
                                          <Text color="black50" weight="bold">
                                            g
                                          </Text>
                                        }
                                        placeholder="Masukkan Kelipatan Berat"
                                        disabled={isLoading}
                                        isError={
                                          validation.advancedOptions[
                                            indexAdvancedOption
                                          ]?.condition_base_weight_value
                                        }
                                        error={
                                          validation.advancedOptions[
                                            indexAdvancedOption
                                          ]?.condition_base_weight_valueText
                                        }
                                        value={
                                          advancedOption?.condition_base_weight_value
                                        }
                                        onChange={(value) =>
                                          handleChangeTypeAdvancedOption(
                                            "condition_base_weight_value",
                                            value,
                                            indexAdvancedOption
                                          )
                                        }
                                      />
                                    </Flex>
                                    <Box flex={1}>
                                      <Input.Number
                                        prefix={
                                          <Text color="black50" weight="bold">
                                            Rp
                                          </Text>
                                        }
                                        disabled={isLoading}
                                        value={advancedOption?.condition_base_tarif?.toString()}
                                        isError={
                                          validation.advancedOptions[
                                            indexAdvancedOption
                                          ]?.condition_base_tarif
                                        }
                                        error={
                                          validation.advancedOptions[
                                            indexAdvancedOption
                                          ]?.condition_base_tarifText
                                        }
                                        onChange={(value) =>
                                          handleChangeTypeAdvancedOption(
                                            "condition_base_tarif",
                                            value,
                                            indexAdvancedOption
                                          )
                                        }
                                        placeholder="Masukkan Tarif"
                                      />
                                    </Box>
                                  </Flex>
                                )}
                              {advancedOption?.condition_base_id ===
                                dataconditionBaseType.value &&
                                dataconditionBaseType.value === 2 && (
                                  <Flex
                                    gap="16px"
                                    ml="36px"
                                  >
                                    <Box>
                                      <Input.Number
                                        sufix={
                                          <Text color="black50" weight="bold">
                                            g
                                          </Text>
                                        }
                                        placeholder="Berat Minumum"
                                        value={
                                          advancedOption?.condition_base_weight_start
                                        }
                                        isError={
                                          validation.advancedOptions[
                                            indexAdvancedOption
                                          ]?.condition_base_weight_start
                                        }
                                        error={
                                          validation.advancedOptions[
                                            indexAdvancedOption
                                          ]?.condition_base_weight_startText
                                        }
                                        disabled={
                                          isLoading ||
                                          advancedOption?.condition_type_id >= 3
                                        }
                                        onChange={(value) =>
                                          handleChangeTypeAdvancedOption(
                                            "condition_base_weight_start",
                                            value,
                                            indexAdvancedOption
                                          )
                                        }
                                      />
                                    </Box>
                                    <Box width="8px" mt="20px">
                                      <Divider />
                                    </Box>
                                    <Box>
                                      <Input.Number
                                        sufix={
                                          <Text color="black50" weight="bold">
                                            g
                                          </Text>
                                        }
                                        placeholder="Berat Maksimum"
                                        value={
                                          advancedOption?.condition_base_weight_end
                                        }
                                        isError={
                                          validation.advancedOptions[
                                            indexAdvancedOption
                                          ]?.condition_base_weight_end
                                        }
                                        error={
                                          validation.advancedOptions[
                                            indexAdvancedOption
                                          ]?.condition_base_weight_endText
                                        }
                                        disabled={
                                          isLoading ||
                                          advancedOption?.condition_type_id < 3
                                        }
                                        onChange={(value) =>
                                          handleChangeTypeAdvancedOption(
                                            "condition_base_weight_end",
                                            value,
                                            indexAdvancedOption
                                          )
                                        }
                                      />
                                    </Box>
                                    <Box flex={1}>
                                      <Input.Number
                                        prefix={
                                          <Text color="black50" weight="bold">
                                            Rp
                                          </Text>
                                        }
                                        disabled={isLoading}
                                        value={advancedOption?.condition_base_tarif?.toString()}
                                        isError={
                                          validation.advancedOptions[
                                            indexAdvancedOption
                                          ]?.condition_base_tarif
                                        }
                                        error={
                                          validation.advancedOptions[
                                            indexAdvancedOption
                                          ]?.condition_base_tarifText
                                        }
                                        onChange={(value) =>
                                          handleChangeTypeAdvancedOption(
                                            "condition_base_tarif",
                                            value,
                                            indexAdvancedOption
                                          )
                                        }
                                        placeholder="Masukkan Tarif"
                                      />
                                    </Box>
                                  </Flex>
                                )}
                            </Box>
                          )
                        )}
                      </Flex>
                      {dataCalculate.advanced_options.length - 1 ===
                        indexAdvancedOption &&
                        !isLoading && (
                          <>
                            <Divider />
                            <Flex
                              gap="8px"
                              cursor={
                                isAllowAddCondition ? "pointer" : "not-allowed"
                              }
                              alignItems="center"
                              alignSelf="self-start"
                              onClick={
                                isAllowAddCondition
                                  ? handleAddAdvancedOption
                                  : () => { }
                              }
                            >
                              <Icon
                                size={20}
                                name="Plus-solid"
                                color={
                                  isAllowAddCondition ? "blue50" : "black50"
                                }
                              />
                              <Text
                                color={
                                  isAllowAddCondition ? "blue50" : "black50"
                                }
                                weight="bold"
                              >
                                Tambah Kondisi
                              </Text>
                            </Flex>
                          </>
                        )}
                    </Flex>
                  )
                )}
              </>
            )}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default CalculateMethod;
