import { Flex, Text, colors } from "renos-ui";

const SquareFive = ({ imageRef, variant, data, setData }) => {
  const handleSetEdit = (type) => {
    setData((prev) => ({ ...prev, active: type }));
  };

  const renderBorder = (type) => {
    return data?.active === type && `1px solid ${colors.violet50}`;
  };

  const handleReplaceEnter = (value) => {
    return value ? value.replace(/\n/g, "<br />") : "";
  };

  return (
    <Flex
      flexDirection="column"
      gap="8px"
      paddingTop="50px"
      position="relative"
    >
      <Flex flexDirection="column" position="absolute" left={-84}>
        <Text variant="caption" color="black50" weight="bold">
          Template {variant}
        </Text>
        <Text variant="caption" color="black50">
          Square Banner 1:1 (1080 x 1080px)
        </Text>
      </Flex>
      <Flex style={{ transform: "scale(1.7)" }} mt="150px">
        <Flex
          width="209px"
          height="209px"
          style={{
            background:
              "linear-gradient(225.47deg, #2327BE -0.84%, #272A92 100%)",
          }}
          background="#1833B4"
          ref={imageRef}
          position="relative"
          p="15px"
        >
          <Flex
            position="absolute"
            width="109px"
            height="19px"
            left="-1px"
            top="7px"
            backgroundImage="url('assets/images/store-layout/banner-1x1/template_5_info.svg')"
            backgroundSize="cover"
            style={{ wordBreak: "break-all" }}
          >
            <Flex
              fontSize="9px"
              color="blue50"
              fontWeight={700}
              alignItems="center"
              justifyContent="center"
              px="6px"
              onClick={() => handleSetEdit("textInfo")}
              cursor="pointer"
              border={renderBorder("textInfo")}
              style={{ textWrap: "nowrap" }}
            >
              {data.textInfo || "Informasi Penting!"}
            </Flex>
          </Flex>

          <Flex
            width="210px"
            height="186px"
            backgroundColor="white"
            onClick={() => handleSetEdit("textLong")}
            cursor="pointer"
            border={renderBorder("textLong")}
            borderTopRightRadius="30px"
            borderBottomLeftRadius="30px"
            p="16px 10px 8px 9px"
            fontSize="5px"
            lineHeight="7.53px"
            style={{ wordBreak: "break-all" }}
            dangerouslySetInnerHTML={{
              __html: `${handleReplaceEnter(data.textLong)}`,
            }}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default SquareFive;
