import { useEffect, useState } from "react";
import React from "react";
import {
  blobToBase64,
  imageTypeCheckingDocument,
  imgCompressor,
  setGTM,
} from "helpers";
import {
  Box,
  Button,
  Dialog,
  Flex,
  Input,
  Text,
  colors,
  useDisclosure,
  Divider,
  Upload,
  useUpload,
  Icon,
  useToast,
  SelectInput,
  Spinner,
} from "renos-ui";
import { modalText, IMAGE_FILE_TYPE } from "./constant";
import {
  useGetDocument,
  useDownloadDocument,
  useSaveNumber,
  useGetBank,
} from "services/settings/document";
import { ImagePreview } from "./styled";
import { goToUrl } from "helpers";

const Document = () => {
  const { isOpen, onOpen, onClose } = useDisclosure({ open: false });
  const [item, setItem] = useState([
    {
      id: "ktp",
      title: "Foto KTP",
      subtitle: "Pastikan foto KTP terlihat jelas.",
      link: null,
    },
    {
      id: "siup",
      title: "Nomor Induk Berusaha (NIB)",
      subtitle: "Tidak lebih dari 60 hari.",
      link: null,
    },
    {
      id: "npwp",
      title: "NPWP Perusahaan",
      subtitle: "Salinan Registrasi PPN Bersertifikat",
      link: null,
    },
    {
      id: "bank_book",
      title: "Buku Tabungan",
      subtitle: "Buku Tabungan Perusahaan.",
      link: null,
    },
  ]);

  const { register } = useUpload({
    fileType: "image",
    onRequest: async (file) => {
      const deepTypeChecking = await imageTypeCheckingDocument(file);

      if (deepTypeChecking) {
        const compressed =
          file.size > 200000
            ? await imgCompressor(file, {
                maxSizeMB: 0.5,
                maxWidthOrHeight: 1920,
                useWebWorker: true,
              })
            : file;

        const result = await blobToBase64(compressed);
        const selectedCategory = selectedItem?.category;

        if (result.length === 0) {
          setImages({
            ...images,
            [selectedCategory]: { raw: null, value: "" },
          });

          return;
        }
        setImages((prev) => ({
          ...prev,
          [selectedCategory]: {
            raw: result,
            value: file,
            isImageChange: true,
          },
        }));
      } else {
        toast({
          label:
            "Gagal upload file. Harap cek kembali jenis file yang akan kamu upload.",
          placement: "top",
          backgroundColor: "red50",
          clickLabel: "Oke",
          onClick: () => addModal.isOpen,
        });
      }
    },
    validator: (file) => {
      if (!IMAGE_FILE_TYPE.includes(file.type)) {
        toast({
          label:
            "Gagal upload file. Harap cek kembali jenis file yang akan kamu upload.",
          placement: "top",
          backgroundColor: "red50",
          clickLabel: "Oke",
          onClick: () => addModal.isOpen,
        });

        return {
          message: "Format gambar tidak sesuai",
        };
      }

      return null;
    },
  });

  const toast = useToast();

  const {
    isLoading: isLoadingDocument,
    refetch,
    data: dataGetDocument,
  } = useGetDocument({
    onError: () => {
      toast({
        label: "Gagal mendapatkan document.",
        placement: "top",
      });
    },
  });

  const handleDownload = (category) => {
    handleGTM(`download_${category}_document`);
    mutateDownload({ doctype: category });
  };

  useEffect(() => {
    if (!isLoadingDocument) {
      const tempLinkDoc = {
        ktp: dataGetDocument?.data?.ktp,
        npwp: dataGetDocument?.data?.npwp,
        siup: dataGetDocument?.data?.siup,
        bank_book: dataGetDocument?.data?.bank_book,
      };
      const updatedItems = [];
      item.forEach((item) => {
        const key = item.id;
        updatedItems.push({ ...item, link: tempLinkDoc[key] });
      });
      setItem([...updatedItems]);

      setValue({
        ktp: dataGetDocument?.data?.ktp_number,
        npwp: dataGetDocument?.data?.npwp_number,
        siup: dataGetDocument?.data?.nib_number,
      });
      setValueBank({
        bank_book: dataGetDocument?.data?.bank_book,
        bank_account_beneficiary_name:
          dataGetDocument?.data?.bank_account_beneficiary_name,
        bank_account_number: dataGetDocument?.data?.bank_account_number,
        bank_branch: dataGetDocument?.data?.bank_branch,
        bank_city: dataGetDocument?.data?.bank_city,
        bank_code: dataGetDocument?.data?.bank_code,
        bank_name: dataGetDocument?.data?.bank_name,
      });
    }
    // eslint-disable-next-line
  }, [isLoadingDocument]);

  const addModal = useDisclosure({});
  const saveModal = useDisclosure({});
  const bankModal = useDisclosure({});
  const exitModal = useDisclosure({});
  const previewModal = useDisclosure({});
  const deleteModal = useDisclosure({});
  const detailImageModal = useDisclosure({});
  const editBankModal = useDisclosure({});
  const confirmBankModal = useDisclosure({});
  const saveModalBank = useDisclosure({});
  const [mode, setMode] = useState("add");
  const [modalOpen, setModalOpen] = useState("ktp");

  const [selectedItem, setSelectedItem] = useState({});
  const [images, setImages] = useState({
    ktp: { raw: null, value: "", isImageChange: false },
    npwp: { raw: null, value: "", isImageChange: false },
    siup: { raw: null, value: "", isImageChange: false },
    bank_book: { raw: null, value: "", isImageChange: false },
  });
  const [selectedCategory, setSelectedCategory] = useState("");
  const [value, setValue] = useState({});
  const [selectedImage, setSelectedImage] = useState();
  const [valueBank, setValueBank] = useState({});

  const [isError, setIsError] = useState({
    ktp: false,
    siup: false,
    npwp: false,
  });

  const [isErrorBank, setIsErrorBank] = useState({
    bank_account_beneficiary_name: false,
    bank_account_number: false,
    bank_branch: false,
    bank_city: false,
    bank_code: false,
    bank_name: false,
  });

  const handleConfirmSaveBank = (event) => {
    event.preventDefault();
    const isHasError = handleErrorBank();
    if (isHasError) return false;
    confirmBankModal.onOpen();
    addModal.onClose();
  };

  const handleModalSaveBank = () => {
    saveModalBank.onOpen();
  };

  const handleModal = (type) => {
    if (type) {
      selectItem(type);
      setSelectedCategory(type);
      setModalOpen(type);
    }
    setMode("add");
    addModal.onOpen();
  };

  const handleModalEdit = (type) => {
    if (type) {
      selectItem(type);
      setSelectedCategory(type);
      setModalOpen(type);
    }
    setMode("edit");
    addModal.onOpen();
  };

  const handleModalSave = () => {
    saveModal.onOpen();
  };

  const handleModalBank = (type) => {
    if (type) {
      selectItem(type);
      setSelectedCategory(type);
    }
    bankModal.onOpen();
  };

  const handleDelete = () => {
    deleteModal.onOpen();
  };

  const handleExit = () => {
    exitModal.onOpen();
  };

  const handleExitConfirm = () => {
    confirmBankModal.onClose();
  };

  const handleCloseModal = () => {
    exitModal.onClose();
    addModal.onClose();
    bankModal.onClose();
    setImages({ ...images, [selectedCategory]: { raw: null, value: "" } });
    setValue({});
    setValueBank({});
    setIsError({
      ktp: false,
      siup: false,
      npwp: false,
    });
  };

  const handleCloseModalEdit = () => {
    exitModal.onClose();
    addModal.onClose();
    bankModal.onClose();
    setImages({ ...images, [selectedCategory]: { raw: null, value: "" } });
    setValueBank({});
    setValue({
      ktp: dataGetDocument?.data?.ktp_number,
      npwp: dataGetDocument?.data?.npwp_number,
      siup: dataGetDocument?.data?.nib_number,
    });
    setIsError({
      ktp: false,
      siup: false,
      npwp: false,
    });
  };

  const handleNoClose = () => {
    exitModal.onClose();
  };

  const handleDeleteImage = () => {
    setImages({ ...images, [selectedCategory]: { raw: null, value: "" } });
    deleteModal.onClose();
  };

  const handleEditBank = () => {
    editBankModal.onOpen();
  };

  const selectItem = (type) => {
    setSelectedItem({
      ...modalText[type],
      title: modalText[type].title,
      imgValue: images[type].value,
      category: type,
      uploadPhotoText: modalText[type].uploadPhotoText,
      inputPlacholder: modalText[type].inputPlacholder,
      inputName: modalText[type].inputName,
      inputLabel: modalText[type].inputLabel,
    });
  };

  const handleChangeValue = (e, category) => {
    const maxLengthKTP = 16;
    const maxLengthNPWP = 16;
    const maxLengthSIUP = 13;
    const maxLength =
      category === "ktp"
        ? maxLengthKTP
        : category === "npwp"
          ? maxLengthNPWP
          : maxLengthSIUP;
    setValue((prev) => ({
      ...prev,
      [e.target.name]: e.target.value
        .replace(/\D/g, "")
        .substring(0, maxLength),
    }));

    setIsError({
      ...isError,
      [e.target.name]: false,
    });
  };

  const handleChangeValueBank = (e) => {
    if (e.target.name === "bank_account_number") {
      const maxLengthBank = 16;
      const pattern = /^[\d+]+$/;
      const inputContact = e.target.value;
      const regexProcess = inputContact.match(pattern);
      const formatNumber = !inputContact
        ? ""
        : regexProcess
          ? regexProcess[0]
          : value.contact_number;
      setValueBank({
        ...valueBank,
        [e.target.name]: formatNumber.substring(0, maxLengthBank),
      });
    } else {
      setValueBank((prevState) => {
        return {
          ...prevState,
          [e.target.name]: e.target.value,
        };
      });
    }

    setIsErrorBank({
      ...isErrorBank,
      [e.target.name]: false,
    });
  };

  const handleSaveBank = (event) => {
    event.preventDefault();
    const isHasError = handleErrorBank();
    if (isHasError) return false;

    const fileUpload = images[selectedCategory].raw
      .replace("data:", "")
      .replace(/^.+,/, "");
    const payload = {
      data: {
        image_base64: fileUpload,
        bank_account_beneficiary_name: valueBank.bank_account_beneficiary_name,
        bank_account_number: valueBank.bank_account_number,
        bank_branch: valueBank.bank_branch,
        bank_city: valueBank.bank_city,
        bank_code: valueBank.bank_code,
        bank_name: valueBank.bank_name,
      },
    };
    handleGTM("edit_company_information");
    mutateSaveNumber(payload);
  };

  const { mutate: mutateDownload } = useDownloadDocument({
    onSuccess: (data) => {
      let typeBook;

      switch (data.type) {
        case "image/jpeg":
          typeBook = `Dokumen Renos.jpeg`;
          break;
        case "image/jpg":
          typeBook = `Dokumen Renos.jpg`;
          break;
        case "image/png":
          typeBook = `Dokumen Renos.png`;
          break;
        case "application/pdf":
          typeBook = `Dokumen Renos.pdf`;
          break;
        default:
          break;
      }
      const outputFilename = typeBook;
      const url = URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", outputFilename);
      document.body.appendChild(link);
      link.click();
    },
    onError: () => {
      toast({
        label: "Data dokumen gagal didownload",
        placement: "top",
      });
    },
  });

  const { mutate: mutateSaveNumber, isLoading: isSaveLoading } = useSaveNumber({
    onSuccess: (data) => {
      const { message, frontend_message } = data.data;
      refetch();

      if (message === "Conflict") {
        toast({
          label: frontend_message || message,
          placement: "top",
          backgroundColor: "red50",
        });
        setValueBank({});
        setValue({
          ktp: dataGetDocument?.data?.ktp_number,
          npwp: dataGetDocument?.data?.npwp_number,
          siup: dataGetDocument?.data?.nib_number,
        });
      } else {
        toast({
          label: frontend_message || message,
          placement: "top",
        });
        const tempLinkDoc = {
          ktp: data?.data?.data?.ktp,
          npwp: data?.data?.data?.npwp,
          siup: data?.data?.data?.siup,
          bank_book: data?.data?.data?.bank_book,
        };
        const updatedItems = [];
        item.forEach((item) => {
          const key = item.id;
          updatedItems.push({ ...item, link: tempLinkDoc[key] });
        });
        setItem(updatedItems);
      }

      saveModal.onClose();
      addModal.onClose();
      saveModalBank.onClose();
      bankModal.onClose();
      confirmBankModal.onClose();
      setImages({ ...images, [selectedCategory]: { raw: null, value: "" } });
    },
    onError: (data) => {
      const {
        response: {
          data: { frontend_message, message },
        },
      } = data;
      toast({
        label: frontend_message || message,
        placement: "top",
        backgroundColor: "red50",
      });
      setImages({ ...images, [selectedCategory]: { raw: null, value: "" } });
      saveModal.onClose();
      addModal.onClose();
    },
  });

  const lengthValidator = {
    ktp: 16,
    npwp: 15,
    siup: 13,
  };

  const handleError = (modalOpen) => {
    const tempError = isError;
    let countError = 0;

    if (modalOpen === "npwp") {
      if (
        value?.[modalOpen]?.length < lengthValidator[modalOpen] ||
        value?.[modalOpen]?.length > 16
      ) {
        tempError[modalOpen] = true;
        countError++;
      }
    } else {
      if (value?.[modalOpen]?.length < lengthValidator[modalOpen]) {
        tempError[modalOpen] = true;
        countError++;
      }
    }

    setIsError({ ...tempError });

    if (countError !== 0) {
      toast({
        label: `${
          isError.ktp === true
            ? "Nomor KTP tidak boleh kurang dari 16 digit"
            : isError.npwp === true
              ? "Nomor NPWP tidak boleh kurang dari 15 atau 16 digit"
              : isError.siup === true
                ? "Nomor NIB tidak boleh kurang dari 13 digit"
                : ""
        }`,
        placement: "top",
        backgroundColor: "red50",
      });
      saveModal.onClose();

      return true;
    }

    return false;
  };

  const handleErrorBank = () => {
    const tempError = isErrorBank;
    let countError = 0;

    for (var key in valueBank) {
      if (
        !valueBank[key] &&
        key !== "bank_book" &&
        key !== "bank_city" &&
        key !== "bank_branch"
      ) {
        tempError[key] = true;
        countError++;
      }
    }
    setIsErrorBank({ ...tempError });

    if (countError !== 0) {
      toast({
        label: "Mohon lengkapi semua data",
        placement: "top",
        backgroundColor: "red50",
      });
      saveModalBank.onClose();

      return true;
    }

    return false;
  };

  const handleGTM = (label) => {
    setGTM("seller_center_section", {
      event_category: "store_settings | documents",
      event_action: "click",
      event_label: label,
    });
  };

  const handleSave = (event) => {
    handleGTM("edit_company_information");
    event.preventDefault();
    const isHasError = handleError(modalOpen);
    if (isHasError) return false;

    const keyType = {
      ktp: "ktp_number",
      npwp: "npwp_number",
      siup: "nib_number",
    };

    const fileUpload = images[selectedCategory].raw
      .replace("data:", "")
      .replace(/^.+,/, "");
    const payload = {
      data: {
        image_base64: fileUpload,
        [keyType[selectedCategory]]: value[selectedCategory],
      },
    };

    mutateSaveNumber(payload);
  };

  const onThumbnailClicked = (image) => {
    onOpen();
    setSelectedImage(image);
  };

  const { data: listBank } = useGetBank();
  const emptyOptions = {
    label: "",
    value: "",
  };

  const onDialogClosed = () => {
    setSelectedImage();
    onClose();
  };

  return (
    <Box>
      <Flex padding="24px" alignItems="center">
        <Flex flexDirection="column">
          <Text variant="subtitle" weight="bold" color="black100">
            Informasi Perusahaan
          </Text>
          <Text color="black75">
            Upload dokumen dengan format .JPG, .JPEG, .PNG, atau .PDF
          </Text>
        </Flex>
      </Flex>
      <Flex
        width="50%"
        flexDirection="column"
        gap="24px"
        border={`1px solid ${colors.black10}`}
        borderRadius="16px"
        padding="24px"
      >
        {item.map((list, indexDoc) => (
          <Flex flexDirection="column" gap="20px" key={indexDoc}>
            <Flex
              justifyContent="space-between"
              alignContent="center"
              alignItems="center"
            >
              <Flex alignContent="center" flexDirection="column">
                <Text weight="bold">{list.title}</Text>
                <Text variant="caption" color="black50">
                  {list.subtitle}
                </Text>
                <Box
                  onClick={() => handleDownload(list.id)}
                  target="_blank"
                  cursor="pointer"
                >
                  {list.link !== null && (
                    <Text width="100px" color="blue50">
                      {list.id === "ktp" && dataGetDocument?.data?.ktp !== null
                        ? "Unduh Dokumen KTP"
                        : list.id === "npwp" &&
                            dataGetDocument?.data?.npwp !== null
                          ? "Unduh Dokumen NPWP"
                          : list.id === "siup" &&
                              dataGetDocument?.data?.siup !== null
                            ? "Unduh Dokumen NIB"
                            : list.id === "bank_book" &&
                                dataGetDocument?.data?.bank_book !== null
                              ? "Unduh Dokumen Bank"
                              : ""}
                    </Text>
                  )}
                </Box>
              </Flex>
              <Flex>
                {!isLoadingDocument && list.link === null ? (
                  <Button
                    onClick={() =>
                      list.id === "bank_book"
                        ? handleModalBank(list.id)
                        : handleModal(list.id)
                    }
                    size="small"
                    variant="secondary"
                  >
                    Lengkapi Data
                  </Button>
                ) : (
                  <Button
                    onClick={() =>
                      list.id === "bank_book"
                        ? handleEditBank()
                        : handleModalEdit(list.id)
                    }
                    size="small"
                    variant="tertiary"
                    color={list.id === "bank_book" ? "black25" : "black100"}
                  >
                    <Icon
                      name="Edit-outline"
                      size={16}
                      color={list.id === "bank_book" ? "black25" : "black100"}
                    />
                    <Text
                      color={list.id === "bank_book" ? "black25" : "black100"}
                      weight="bold"
                    >
                      {" "}
                      Edit
                    </Text>
                  </Button>
                )}
              </Flex>
            </Flex>
            {list.id !== "bank_book" && <Divider />}
          </Flex>
        ))}
      </Flex>

      <Dialog
        isVisible={addModal.isOpen}
        closable
        onClose={handleExit}
        verticalCentered
        width="400px"
      >
        <Flex flexDirection="column" gap="24px" width="400px">
          <Text variant="heading3" weight="bold">
            {selectedItem.title}
          </Text>

          <Flex
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            padding={
              images[selectedCategory]?.isImageChange === true ? "16px" : "66px"
            }
            gap="24px"
            border={`1px dashed ${colors.black50}`}
            borderRadius="8px"
          >
            {images[selectedCategory]?.value !== "" ? (
              <>
                <Box
                  alt="document-image"
                  as="img"
                  src={images[selectedItem.category]?.raw}
                  width="350px"
                  height="220px"
                  onClick={() =>
                    onThumbnailClicked(images[selectedItem.category]?.raw)
                  }
                />
                <Flex
                  alignItems="center"
                  textAlign="center"
                  justifyContent="center"
                  gap="4px"
                >
                  <Text color="blue50">
                    {images[selectedCategory]?.value.name}
                  </Text>
                  <Icon
                    onClick={() => handleDelete()}
                    name="Cancel-solid"
                    color="black60"
                    size={16}
                  />
                </Flex>
                <Flex>
                  <Upload {...register} accept=".jpg, .jpeg, .png, .pdf">
                    <Box width="160px">
                      <Button isBlock variant="secondary">
                        Ubah File
                      </Button>
                    </Box>
                  </Upload>
                </Flex>
              </>
            ) : (
              <>
                <Box
                  alt="no-image"
                  as="img"
                  src="/assets/images/setting/document/no-image.png"
                  size="56px"
                />
                <Box textAlign="center">
                  <Text variant="body" color="black50">
                    Upload foto dengan format file{" "}
                    <b> .JPG, .JPEG, .PNG, atau .PDF </b>
                  </Text>
                </Box>
                <Flex>
                  <Upload {...register} accept=".jpg, .jpeg, .png, .pdf">
                    <Box width="160px">
                      <Button isBlock variant="secondary">
                        Pilih File
                      </Button>
                    </Box>
                  </Upload>
                </Flex>
              </>
            )}
          </Flex>

          <Flex flexDirection="column" gap="16px">
            <Text variant="caption" color="black50" weight="bold">
              {selectedItem.inputLabel}
            </Text>
            <Box width="100%">
              <Input
                name={selectedItem.inputName}
                placeholder={selectedItem.inputPlacholder}
                value={value[selectedCategory]}
                onChange={(e) => handleChangeValue(e, selectedItem.category)}
                error={
                  selectedItem.inputName === "ktp"
                    ? "KTP harus terdiri dari 16 digit"
                    : selectedItem.inputName === "siup"
                      ? "NIB harus terdiri dari 13 digit"
                      : selectedItem.inputName === "npwp"
                        ? "NPWP harus terdiri 15 atau 16 digit"
                        : ""
                }
                isError={
                  selectedItem.inputName === "ktp"
                    ? isError.ktp
                    : selectedItem.inputName === "siup"
                      ? isError.siup
                      : selectedItem.inputName === "npwp"
                        ? isError.npwp
                        : ""
                }
              />
            </Box>
          </Flex>

          <Button
            disabled={
              isSaveLoading ||
              value[selectedCategory] === "" ||
              images[selectedCategory]?.value === ""
            }
            isBlock
            onClick={handleModalSave}
          >
            Simpan
          </Button>
        </Flex>
      </Dialog>

      {/* bank */}
      <Dialog
        isVisible={bankModal.isOpen}
        closable
        onClose={handleExit}
        verticalCentered
        width="904px"
        padding="40px"
      >
        <Flex flexDirection="column" width="100%" gap="24px">
          <Text variant="heading3" weight="bold">
            Buku Tabungan
          </Text>
          <Flex gap="24px">
            <Flex
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              px="66px"
              py="24px"
              gap="24px"
              border={`1px dashed ${colors.black10}`}
              borderRadius="8px"
            >
              {images[selectedCategory]?.value !== "" ? (
                <>
                  <Box
                    alt="document-image"
                    as="img"
                    src={images[selectedItem.category]?.raw}
                    width="350px"
                    height="220px"
                    onClick={() =>
                      onThumbnailClicked(images[selectedItem.category]?.raw)
                    }
                  />
                  <Flex
                    alignItems="center"
                    textAlign="center"
                    justifyContent="center"
                    gap="4px"
                  >
                    <Text color="blue50">
                      {images[selectedCategory]?.value.name}
                    </Text>
                    <Icon
                      onClick={() => handleDelete()}
                      name="Cancel-solid"
                      color="black60"
                      size={16}
                    />
                  </Flex>
                  <Flex>
                    <Upload {...register} accept=".jpg, .jpeg, .png, .pdf">
                      <Box width="160px">
                        <Button isBlock variant="secondary">
                          Ubah File
                        </Button>
                      </Box>
                    </Upload>
                  </Flex>
                </>
              ) : (
                <>
                  <Box
                    alt="no-image"
                    as="img"
                    src="/assets/images/setting/document/no-image.png"
                    size="56px"
                  />
                  <Box textAlign="center">
                    <Text variant="body" color="black50">
                      Upload foto dengan format file{" "}
                      <b> .JPG, .JPEG, .PNG, atau .PDF </b>
                    </Text>
                  </Box>
                  <Flex>
                    <Upload {...register} accept=".jpg, .jpeg, .png, .pdf">
                      <Box width="160px">
                        <Button isBlock variant="secondary">
                          Pilih File
                        </Button>
                      </Box>
                    </Upload>
                  </Flex>
                </>
              )}
            </Flex>
            <Flex flexDirection="column" width="50%" gap="20px">
              <Flex flexDirection="column" gap="16px">
                <Text variant="caption" color="black50" weight="bold">
                  Kode Bank
                </Text>
                <SelectInput
                  options={
                    listBank?.data?.map((row) => ({
                      label: row.name + " - " + row.code,
                      value: row.code,
                    })) || emptyOptions
                  }
                  placeholder="Pilih Kode Bank"
                  onChange={(event) => {
                    setValueBank({
                      ...valueBank,
                      bank_code: event.value,
                      bank_name: event.label,
                    });
                  }}
                  hideSelectedOptions={true}
                  error="Kode Bank harus diisi"
                  isError={isErrorBank.bank_code}
                />
              </Flex>

              {/* kota bank */}
              <Flex gap="24px">
                <Flex flexDirection="column" gap="16px">
                  <Text variant="caption" color="black50" weight="bold">
                    Kota Bank
                  </Text>
                  <Box width="100%">
                    <Input
                      name="bank_city"
                      placeholder="Masukan Kota"
                      value={valueBank?.bank_city}
                      onChange={(e) => handleChangeValueBank(e)}
                    />
                  </Box>
                </Flex>
                <Flex flexDirection="column" gap="16px">
                  <Text variant="caption" color="black50" weight="bold">
                    Cabang
                  </Text>
                  <Box width="100%">
                    <Input
                      name="bank_branch"
                      placeholder="Masukan Cabang"
                      value={valueBank?.bank_branch}
                      onChange={(e) => handleChangeValueBank(e)}
                    />
                  </Box>
                </Flex>
              </Flex>

              {/* nomor rekening */}
              <Flex flexDirection="column" gap="16px">
                <Text variant="caption" color="black50" weight="bold">
                  Nomor Rekening
                </Text>
                <Box width="100%">
                  <Input
                    name="bank_account_number"
                    placeholder="Masukan Nomor Rekening"
                    value={valueBank?.bank_account_number}
                    onChange={(e) => handleChangeValueBank(e)}
                    error="Nomor Rekening harus diisi"
                    isError={isErrorBank.bank_account_number}
                  />
                </Box>
              </Flex>

              {/* nama pemilik rekening */}
              <Flex flexDirection="column" gap="16px">
                <Text variant="caption" color="black50" weight="bold">
                  Nama Pemilik Rekening
                </Text>
                <Box width="100%">
                  <Input
                    name="bank_account_beneficiary_name"
                    placeholder="Masukan Nama Pemilik Rekening"
                    value={valueBank?.bank_account_beneficiary_name}
                    onChange={(e) => handleChangeValueBank(e)}
                    error="Nama Pemilik rekening harus diisi"
                    isError={isErrorBank.bank_account_beneficiary_name}
                  />
                </Box>
              </Flex>

              <Flex>
                <Button
                  isBlock
                  onClick={handleConfirmSaveBank}
                  disabled={
                    isSaveLoading ||
                    valueBank?.bank_name === "" ||
                    valueBank?.bank_name === null ||
                    valueBank?.bank_account_number === "" ||
                    valueBank?.bank_account_number === null ||
                    valueBank?.bank_account_beneficiary_name === "" ||
                    valueBank?.bank_account_beneficiary_name === null ||
                    images[selectedCategory]?.value === ""
                  }
                >
                  Lanjutkan
                </Button>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Dialog>

      {/* end */}

      {/* konfirmasi simpan bank */}
      <Dialog
        isVisible={confirmBankModal.isOpen}
        onClose={handleExitConfirm}
        verticalCentered
        width="796px"
        padding="40px"
      >
        <Flex flexDirection="column" width="100%" gap="24px">
          <Text variant="heading3" weight="bold">
            Simpan Dokumen?
          </Text>
          <Flex flexDirection="column" width="100%" gap="40px">
            <Flex
              padding="14px 16px"
              alignContent="center"
              backgroundColor="yellow5"
              borderRadius="8px"
              flexDirection="column"
            >
              <Flex columnGap="8px">
                <Icon name="Info-solid" color="yellow50" size={20} />
                <Text color="yellow100" weight="bold">
                  Informasi Penting
                </Text>
              </Flex>
              <Text>
                Dokumen ini tidak bisa diubah setelah kamu simpan. Pastikan data
                yang kamu masukan sudah benar.
              </Text>
            </Flex>

            <Flex gap="24px">
              <Flex
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
              >
                <Box
                  alt="document-image"
                  as="img"
                  src={images[selectedItem.category]?.raw}
                  width="293px"
                  height="184px"
                  borderRadius="8px"
                  onClick={() =>
                    onThumbnailClicked(images[selectedItem.category]?.raw)
                  }
                />
              </Flex>
              <Flex
                flexDirection="column"
                width="50%"
                gap="16px"
                justifyContent="center"
              >
                <Flex gap="16px">
                  <Flex flexDirection="column" width="50%">
                    <Text variant="caption" color="black50">
                      Kode Bank
                    </Text>
                  </Flex>
                  <Flex flexDirection="column" width="50%">
                    <Text variant="caption">{valueBank?.bank_name}</Text>
                  </Flex>
                </Flex>
                {/* kota bank */}
                <Flex gap="16px">
                  <Flex flexDirection="column" width="50%">
                    <Text variant="caption" color="black50">
                      Kota Bank
                    </Text>
                  </Flex>
                  <Flex flexDirection="column" width="50%">
                    <Text variant="caption">{valueBank?.bank_city}</Text>
                  </Flex>
                </Flex>
                {/* bank cabang */}
                <Flex gap="16px">
                  <Flex flexDirection="column" width="50%">
                    <Text variant="caption" color="black50">
                      Bank Cabang
                    </Text>
                  </Flex>
                  <Flex flexDirection="column" width="50%">
                    <Text variant="caption">{valueBank?.bank_branch}</Text>
                  </Flex>
                </Flex>
                {/* Nomor Rekening */}
                <Flex gap="16px">
                  <Flex flexDirection="column" width="50%">
                    <Text variant="caption" color="black50">
                      Nomor Rekening
                    </Text>
                  </Flex>
                  <Flex flexDirection="column" width="50%">
                    <Text variant="caption">
                      {valueBank?.bank_account_number}
                    </Text>
                  </Flex>
                </Flex>

                {/* Nama Pemiliki Rekening */}
                <Flex gap="16px">
                  <Flex flexDirection="column" width="50%">
                    <Text variant="caption" color="black50">
                      Nama Pemilik Rekening
                    </Text>
                  </Flex>
                  <Flex flexDirection="column" width="50%">
                    <Text variant="caption">
                      {valueBank.bank_account_beneficiary_name}
                    </Text>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>

            <Flex justifyContent="space-between" gap="16px" px="118px">
              <Button isBlock variant="tertiary" onClick={handleExitConfirm}>
                Kembali
              </Button>
              <Button isBlock onClick={handleModalSaveBank}>
                Simpan
              </Button>
            </Flex>
            <Flex justifyContent="center" textAlign="center" px="118px">
              <Text>
                Dengan mengklik tombol Simpan, saya menyetujui bahwa data yang
                saya masukkan sudah benar dan sesuai.
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </Dialog>
      {/* end */}

      <Dialog
        isVisible={previewModal.isOpen}
        closable
        onClose={previewModal.onClose}
        verticalCentered
        width="400px"
        minHeight="100px"
      >
        <Flex flexDirection="column" width="100%">
          <Flex flexDirection="column" gap="16px">
            <Text variant="heading3" weight="bold">
              {value.title}
            </Text>
            <Text>{value.note}</Text>
          </Flex>
        </Flex>
      </Dialog>

      <Dialog
        isVisible={saveModal.isOpen}
        closable
        onClose={saveModal.onClose}
        verticalCentered
        width="300px"
        minHeight="100px"
      >
        <Flex flexDirection="column" width="100%" gap="24px">
          <Flex flexDirection="column" gap="8px">
            <Text variant="heading3" weight="bold">
              Simpan Dokumen
            </Text>
            <Text>Harap pastikan seluruh data sudah sesuai.</Text>
          </Flex>
          <Flex justifyContent="space-between" gap="16px">
            <Button isBlock variant="tertiary" onClick={saveModal.onClose}>
              Kembali
            </Button>
            <Button
              isBlock
              onClick={handleSave}
              disabled={isSaveLoading}
              preffix={isSaveLoading && <Spinner size="16px" color="white" />}
            >
              Simpan
            </Button>
          </Flex>
        </Flex>
      </Dialog>

      <Dialog
        isVisible={deleteModal.isOpen}
        onClose={deleteModal.onClose}
        verticalCentered
        width="300px"
        minHeight="100px"
      >
        <Flex flexDirection="column" width="100%" gap="24px">
          <Flex flexDirection="column" gap="8px">
            <Text variant="heading3" weight="bold">
              Anda yakin ingin menghapus file ini?
            </Text>
          </Flex>
          <Flex justifyContent="space-between" gap="16px">
            <Button isBlock variant="tertiary" onClick={deleteModal.onClose}>
              Kembali
            </Button>
            <Button isBlock onClick={handleDeleteImage}>
              Ya, Hapus
            </Button>
          </Flex>
        </Flex>
      </Dialog>

      <Dialog
        isVisible={exitModal.isOpen}
        onClose={exitModal.onClose}
        verticalCentered
        width="300px"
        minHeight="100px"
      >
        <Flex flexDirection="column" width="100%" gap="24px">
          <Flex flexDirection="column" gap="8px">
            <Text variant="heading3" weight="bold">
              Keluar dari halaman edit?
            </Text>
            <Text>
              Dokumen dan data yang telah Anda masukkan tidak akan tersimpan.
            </Text>
          </Flex>
          <Flex justifyContent="space-between" gap="16px">
            <Button
              isBlock
              variant="tertiary"
              onClick={mode === "add" ? handleCloseModal : handleCloseModalEdit}
            >
              Ya, Keluar
            </Button>
            <Button isBlock onClick={handleNoClose}>
              Tidak
            </Button>
          </Flex>
        </Flex>
      </Dialog>
      <Dialog
        isVisible={editBankModal.isOpen}
        onClose={editBankModal.onClose}
        verticalCentered
        width="348px"
        minHeight="100px"
        closable
      >
        <Flex flexDirection="column" width="100%" gap="24px">
          <Flex flexDirection="column" gap="8px">
            <Text variant="heading3" weight="bold">
              Edit Buku Tabungan
            </Text>
            <Text>
              Silahkan hubungi Seller Support kami untuk mengganti informasi
              Buku Tabungan
            </Text>
          </Flex>
          <Flex justifyContent="space-between" gap="16px">
            <Button
              isBlock
              onClick={() => goToUrl("mailto:sellersupport@renos.id", "_self")}
            >
              Hubungi Seller Support
            </Button>
          </Flex>
        </Flex>
      </Dialog>

      <ImagePreview
        padding="0"
        isVisible={isOpen}
        verticalCentered
        onClose={onDialogClosed}
        closable
      >
        <Box
          alt="document-image"
          as="img"
          src={images[selectedItem.category]?.raw}
        />
      </ImagePreview>

      <Dialog
        isVisible={detailImageModal.isOpen}
        onClose={detailImageModal.onClose}
        verticalCentered
        closable
        minHeight="100px"
        backgroundColor="black10"
      >
        <Flex flexDirection="column" width="100%" gap="24px">
          <Flex justifyContent="space-between" gap="16px">
            <Box alt="document-image" as="img" src={selectedImage} />
          </Flex>
        </Flex>
      </Dialog>

      <Dialog
        isVisible={saveModalBank.isOpen}
        closable
        onClose={saveModalBank.onClose}
        verticalCentered
        width="300px"
        minHeight="100px"
      >
        <Flex flexDirection="column" width="100%" gap="24px">
          <Flex flexDirection="column" gap="8px">
            <Text variant="heading3" weight="bold">
              Simpan Dokumen
            </Text>
            <Text>Harap pastikan seluruh data sudah sesuai.</Text>
          </Flex>
          <Flex justifyContent="space-between" gap="16px">
            <Button isBlock variant="tertiary" onClick={saveModalBank.onClose}>
              Kembali
            </Button>
            <Button
              isBlock
              onClick={handleSaveBank}
              disabled={isSaveLoading}
              preffix={isSaveLoading && <Spinner size="16px" color="white" />}
            >
              Simpan
            </Button>
          </Flex>
        </Flex>
      </Dialog>
    </Box>
  );
};

export default Document;
