import { Flex, Icon, Input, Text } from "renos-ui";

const VoucherCard = ({
  isActive,
  onClick,
  title,
  description,
  isVoucherStore,
  disabled,
}) => {
  return (
    <Flex
      py="24px"
      justifyContent="center"
      gap="16px"
      onClick={disabled && !isActive ? () => {} : onClick}
      alignItems="center"
      borderWidth="1px"
      borderColor={isActive ? "blue50" : "black10"}
      backgroundColor={isActive ? "blue5" : disabled ? "black5" : "white"}
      borderStyle="solid"
      borderRadius="8px"
      width="280px"
      cursor={disabled ? "not-allowed" : "pointer"}
    >
      <Input.Radio
        checked={isActive}
        disabled={isActive ? false : disabled}
        // onChange={onOptionChange}
      />

      <Flex
        width="60px"
        height="60px"
        backgroundColor={disabled && !isActive ? "black10" : "blue5"}
        borderRadius="8px"
        alignItems="center"
        justifyContent="center"
      >
        <Icon
          name={isVoucherStore ? "Store-solid" : "Product-solid"}
          size={32}
          color={disabled && !isActive ? "black25" : "blue50"}
        />
      </Flex>

      <Flex flexDirection="column" gap="4px" width="144px">
        <Flex style={{ whiteSpace: "nowrap" }} whiteSpace="nowrap">
          <Text variant="subtitle" weight="bold" color="black75">
            {title}
          </Text>
        </Flex>
        <Text variant="small" color="black50">
          {description}
        </Text>
      </Flex>
    </Flex>
  );
};

export default VoucherCard;
