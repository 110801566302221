import { Flex, Text } from "renos-ui";
import VoucherType from "./component/VoucherType";
import { useMemo } from "react";
import DiscountType from "./component/DiscountType";
import InputWithToolTip from "./component/InputWithTooltip";

const SettingSection = ({
  values,
  setValues,
  errors,
  setErrors,
  isLoadingData,
  data,
  refetchData,
  isGetDataError,
  errorMsg,
  disabled,
  isOngoing,
}) => {
  const handleChangeValue = (event) => {
    setValues({ ...values, [event.target.name]: event.target.value });
    setErrors({ ...errors, [event.target.name]: false });
  };

  const dataVoucherType = useMemo(
    () =>
      data?.data?.voucher_type
        ? data?.data?.voucher_type.map((voucher) => ({
            id: voucher.voucher_type_id,
            name: voucher.voucher_type_name,
            description: voucher.voucher_type_description,
          }))
        : [],
    [data]
  );

  const dataDiscountType = useMemo(
    () =>
      data?.data?.discount_type
        ? data?.data?.discount_type.map((voucher) => ({
            id: voucher.discount_type_id,
            name: voucher.discount_type_name,
          }))
        : [],
    [data]
  );

  return (
    <Flex>
      <Flex flexDirection="column" gap="4px" width="320px">
        <Text color="black75" weight="bold">
          Pengaturan Voucher
        </Text>
      </Flex>
      <Flex flexDirection="column" gap="24px" width="337px">
        <VoucherType
          value={values.voucherDiscountTypeId}
          onClick={(newValue) =>
            setValues({ ...values, voucherDiscountTypeId: newValue })
          }
          isLoading={isLoadingData}
          refetchData={refetchData}
          isGetDataError={isGetDataError}
          list={dataVoucherType}
          disabled={isOngoing || disabled}
        />
        <DiscountType
          disabled={isOngoing || disabled}
          value={values.discountTypeId}
          onClick={(newValue) => {
            const tempValue = { ...values, discountTypeId: newValue };

            if (newValue === 1) {
              tempValue.percentageDiscount = "";
              delete tempValue.nominalDiscount;
              tempValue.maxDiscount = "";
            } else {
              tempValue.nominalDiscount = "";
              delete tempValue.percentageDiscount;
              delete tempValue.maxDiscount;
            }

            setValues(tempValue);
          }}
          isLoading={isLoadingData}
          refetchData={refetchData}
          isGetDataError={isGetDataError}
          list={dataDiscountType}
        />
        {values.discountTypeId === 1 ? (
          <InputWithToolTip
            name="percentageDiscount"
            label="Persentase Potongan"
            tooltip={
              <Text variant="small" color="black75">
                Jumlah persentase potongan <br /> yang akan didapatkan pembeli
              </Text>
            }
            onChange={(event) => handleChangeValue(event)}
            placeholder="0"
            sufix={
              <Text color="black50" weight="bold">
                %
              </Text>
            }
            value={values.percentageDiscount}
            isError={errors.percentageDiscount}
            error="Minimal persentase potongan adalah 2% dan maksimal 99%"
            maxLength={3}
            max={100}
            disabled={isOngoing || disabled}
          />
        ) : (
          <InputWithToolTip
            name="nominalDiscount"
            label="Nominal Potongan"
            tooltip={
              <Text variant="small" color="black75">
                Jumlah nominal potongan <br /> yang akan didapatkan pembeli
              </Text>
            }
            onChange={(event) => handleChangeValue(event)}
            placeholder="0"
            prefix={
              <Text color="black50" weight="bold">
                Rp
              </Text>
            }
            value={values.nominalDiscount}
            isError={errors.nominalDiscount}
            error="Nominal potongan wajib diisi!"
            disabled={isOngoing || disabled}
            max={99999999}
          />
        )}

        <InputWithToolTip
          name="quota"
          label="Kuota Voucher"
          tooltip={
            <Text variant="small" color="black75">
              Jumlah voucher yang tersedia <br /> untuk digunakan pembeli
            </Text>
          }
          onChange={(event) => handleChangeValue(event)}
          placeholder="0"
          value={values.quota}
          isError={errors.quota}
          error="Kuota voucher wajib diisi!"
          disabled={disabled}
          max={99999999}
        />

        <InputWithToolTip
          name="limitation"
          label="Batas Penggunaan"
          tooltip={
            <Text variant="small" color="black75">
              Jumlah penggunaan voucher <br /> yang bisa digunakan oleh <br />{" "}
              Pembeli
            </Text>
          }
          onChange={(event) => handleChangeValue(event)}
          placeholder="0"
          value={values.limitation}
          isError={errors.limitation}
          error="Batas Penggunaan minimal adalah 1"
          disabled={isOngoing || disabled}
          max={99999999}
        />

        <InputWithToolTip
          name="minPurchase"
          label="Minimal Pembelian"
          tooltip={
            <Text variant="small" color="black75">
              Nominal pembelian minimum <br /> agar pembeli dapat <br />{" "}
              menggunakan voucher ini
            </Text>
          }
          prefix={
            <Text color="black50" weight="bold">
              Rp
            </Text>
          }
          onChange={(event) => handleChangeValue(event)}
          value={values.minPurchase}
          isError={errors.minPurchase}
          error={errorMsg.minPurchase}
          disabled={isOngoing || disabled}
          max={99999999}
        />

        {values.discountTypeId === 1 && (
          <InputWithToolTip
            name="maxDiscount"
            label="Maksimal Potongan"
            tooltip={
              <Text variant="small" color="black75">
                Nominal potongan maksimal <br /> yang dapat diterima oleh <br />{" "}
                pembeli
              </Text>
            }
            prefix={
              <Text color="black50" weight="bold">
                Rp
              </Text>
            }
            onChange={(event) => handleChangeValue(event)}
            placeholder="Harus lebih tinggi dari potongan"
            value={values.maxDiscount}
            isError={errors.maxDiscount}
            error="Minimal Rp5.000 dan maksimal Rp99.999.999"
            disabled={isOngoing || disabled}
            max={99999999}
          />
        )}
      </Flex>
    </Flex>
  );
};

export default SettingSection;
