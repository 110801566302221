import { StoreProvider } from "providers/StoreProvider";
import React, { useEffect, useMemo, useState } from "react";
import { QISCUS } from "hooks/qiscus";
import { Route } from "react-router-dom";
import { ToastProvider, isEmpty } from "renos-ui";
import { useCheckAuth, useCheckStore } from "services/auth";
import { useGetUserQiscus } from "services/chat";
import { useGetHeartBeat } from "services/heartbeat";
import Loading from "../Loading";

const PrivateRoute = ({
  component: Component,
  withQiscus,
  isLanding,
  isMobile,
  storeParams,
  ...restProps
}) => {
  const { data: authData, isLoading: isAuthLoading } = useCheckAuth();
  const { data: storeData, isLoading: isStoreLoading, refetch: refetchCheckStore } = useCheckStore(
    storeParams?.params,
    storeParams?.query
  );  

  const [courierServiceId, setCourierServiceId] = useState();
  const [qiscusInfo, setQiscusInfo] = useState();

  const { mutate: getUserQiscus } = useGetUserQiscus({
    onSuccess: ({ data }) => {
      const qiscusInfo = {
        userId: data.user_qiscus_id,
        userKey: data.password,
        username: data.username,
        avatarURL: data.avatar_url,
      };
      setQiscusInfo(qiscusInfo);
      QISCUS.login(qiscusInfo);
    },
  });

  useGetHeartBeat();

  const codeStatus = useMemo(() => authData?.code, [authData]);

  useEffect(() => {
    const storeId = storeData?.data?.id;

    if (isEmpty(qiscusInfo) && withQiscus && storeId) {
      getUserQiscus(storeId);
    }

    //eslint-disable-next-line
  }, [isStoreLoading]);

  const renderComponent = (props) => {
    if (codeStatus !== undefined && codeStatus !== 200) {
      window.location.pathname = "/internal-error";
    }

    if (isLanding) {
      if (!isAuthLoading && !isStoreLoading) {
        if (!storeData?.status && authData?.status && isMobile) {
          window.location.pathname = "/register";
        }

        if (authData?.status && storeData?.status) {
          window.location.pathname = "/";
        }
      }
    } else {
      if (!isAuthLoading) {
        if (!authData?.status) {
          window.location.pathname = "/seller-landing";
        }
      }

      if (!isStoreLoading) {
        if (!storeData?.status) {
          window.location.pathname = "/seller-landing";
        }
      }
    }

    if (!isLanding && (isAuthLoading || isStoreLoading)) {
      return <Loading />;
    }

    return <Component {...props} />;
  };

  return (
    <Route
      render={(props) => (
        <StoreProvider
          value={{
            storeData,
            qiscusInfo,
            courierServiceId,
            setCourierServiceId,
            refetchCheckStore
          }}
        >
          <ToastProvider zIndex={10000}>{renderComponent(props)}</ToastProvider>
        </StoreProvider>
      )}
      {...restProps}
    />
  );
};

export default PrivateRoute;
