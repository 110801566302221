import React from "react";
import { Flex, Text } from "renos-ui";

const EmptyState = () => {
  return (
    <Flex
      gap="8px"
      width="100%"
      columnGap="8px"
      alignItems="center"
      flexDirection="column"
      justifyContent="center"
      style={{ boxSizing: "border-box" }}
    >
      <Flex
        as="img"
        alt="empty-note"
        src="/assets/images/mobile/no-template.svg"
        width="128px"
        height="128px"
      />
      <Text color="black75" textAlign="center" weight="bold" variant="body">
        Belum ada template balasan ulasan
      </Text>
      <Flex
        width="236px"
        marginX="60px"
        textAlign="center"
        justifyContent="center"
      >
        <Text color="black50" textAlign="center" variant="caption">
          Yukk buat template balasan ulasanmu sekarang!
        </Text>
      </Flex>
    </Flex>
  );
};

export default EmptyState;
