import { useState } from "react";
import { Flex, Icon, Input, useDebounce } from "renos-ui";
import { StyledFlexInputSearch } from "./styled";

const Filter = ({ setSearchParam }) => {
  const [tempParams, setTempParams] = useState("");

  useDebounce(() => setSearchParam(tempParams), [tempParams], 500);

  return (
    <Flex gap="16px">
      <StyledFlexInputSearch width="100%" borderRadius="8px" height="40px">
        <Input
          prefix={<Icon name="Search-outline" size={20} />}
          size="small"
          placeholder="Cari produk"
          value={tempParams}
          onChange={(event) => setTempParams(event.target.value)}
        />
      </StyledFlexInputSearch>

      {/* backend not ready for filter by etalase */}
    </Flex>
  );
};

export default Filter;
