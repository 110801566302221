export const onboardItems = [
  {
    title: `Gabung Renos, Banyak Untungnya!`,
    subTitle: `Temukan berbagai keuntungan dan kemudahan bersama Renos.`,
  },
  {
    title: `Gratis Buka Toko`,
    subTitle: `Kamu bisa mulai berjualan tanpa dikenakan biaya apapun.`,
  },
  {
    title: `Segmentasi Pasar`,
    subTitle: `Online marketplace yang berfokus pada produk dan jasa kebutuhan rumah.`,
  },
  {
    title: `Pengiriman Luas`,
    subTitle: `Melayani pengiriman produk jumlah besar maupun kecil ke seluruh daerah.`,
  },
  {
    title: `Fitur Pendukung`,
    subTitle: `Tersedia fitur pilihan untuk bantu kembangkan tokomu.`,
  },
];
