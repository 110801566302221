import StoreContext from "providers/StoreProvider";
import { useContext, useMemo } from "react";
import {
  Flex,
  Dropdown,
  Icon,
  useDisclosure,
  Checkbox,
  Text,
  Skeleton,
  range,
  Button,
} from "renos-ui";
import { useGetListFilterCourier } from "services/order-management";
import { StyledInput } from "./styled";

const InputCourier = ({ selected, setSelected }) => {
  const disclosureDropdown = useDisclosure({ isOpen: false });

  const {
    storeData: {
      data: { id: storeId },
    },
  } = useContext(StoreContext);

  const {
    data: dataCourier,
    isLoading,
    isFetching,
    isError,
    refetch,
  } = useGetListFilterCourier({ available_courier_store: storeId });

  const isAllLoading = useMemo(
    () => isLoading || isFetching,
    [isLoading, isFetching]
  );

  const includeChecker = (courierId) => {
    return selected.includes(courierId);
  };

  const handleCheck = (courierId) => {
    let tempSelected = selected.concat([courierId]);

    if (includeChecker(courierId)) {
      tempSelected = selected.filter(
        (filterSelected) => filterSelected !== courierId
      );
    }
    setSelected([...tempSelected]);
  };

  return (
    <Flex width="144px">
      <Dropdown
        onOpen={disclosureDropdown.onToggle}
        onBlur={disclosureDropdown.onToggle}
      >
        <Dropdown.Toggle>
          <StyledInput
            onClick={disclosureDropdown.onToggle}
            sufix={
              <Icon
                name="Down-outline"
                size={20}
                color="black50"
                cursor="pointer"
                onClick={disclosureDropdown.onToggle}
              />
            }
            value={
              selected.length > 0 ? `${selected.length} Kurir dipilih` : "Kurir"
            }
            size="small"
            readOnly
            cursor="pointer"
          />
        </Dropdown.Toggle>
        <Dropdown.Content>
          {isAllLoading ? (
            <Flex gap="4px" flexDirection="column" padding="4px 16px">
              {range(3).map((_, indexSkeleton) => (
                <Flex gap="8px" key={`skeleton-${indexSkeleton}`}>
                  <Skeleton height="20px" width="20px" />
                  <Skeleton height="24px" width="100px" />
                </Flex>
              ))}
            </Flex>
          ) : isError ? (
            <Flex height="100px" alignItems="center" justifyContent="center">
              <Button size="small" onClick={refetch}>
                Muat ulang
              </Button>
            </Flex>
          ) : (
            dataCourier.data.map((courier, indexCourier) => (
              <Dropdown.Item key={`dropdown-${indexCourier}`}>
                <Flex alignItems="center" gap="8px">
                  <Checkbox
                    checked={includeChecker(courier.id)}
                    onChange={() => handleCheck(courier.id)}
                  />
                  <Flex onClick={() => handleCheck(courier.id)}>
                    <Text color="black75">{courier.name}</Text>
                  </Flex>
                </Flex>
              </Dropdown.Item>
            ))
          )}
        </Dropdown.Content>
      </Dropdown>
    </Flex>
  );
};

export default InputCourier;
