import mobileUrl from "constants/mobileUrl";
import ChatRoomEmpty from "containers/web/chat/chat-room/ChatRoomEmpty";
import { QISCUS, event } from "hooks/qiscus";
import MobileLayout from "layouts/MobileLayout";
import StoreContext from "providers/StoreProvider";
import { useContext, useEffect, useState } from "react";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { Flex, Icon, range } from "renos-ui";
import SkeletonChatRoom from "./SkeletonChatRoom";
import ChatItem from "./ChatItem";
import { useGetResetChatCount } from "services/chat";

const ChatRoom = () => {
  const location = useLocation();
  const { push, replace } = useHistory();
  const [userItems, setUserItems] = useState([]);
  const [firstOpen, setFirstOpen] = useState(true);
  const [emitterStatus, setEmitterStatus] = useState({});
  const [userLoading, setUserLoading] = useState({
    load: true,
    fail: false,
    isFirst: false,
  });

  // handle back broweser
  useEffect(() => {
    window.onpopstate = (e) => {
      replace({ state: { ...e?.state?.state, isStay: true } });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  /**
   * Context Qisqus
   * @param {*} StoreContext
   */
  const { qiscusInfo } = useContext(StoreContext);

  const handleClickMenu = (url) => {
    push(url);
  };

  /**
   * Service Communication
   */
  const getRoom = async () => {
    try {
      const userParam = {
        page: 1,
        limit: 100,
        show_participants: true,
        show_empty: false,
      };
      const qiscus = await QISCUS.loadRooms(userParam);
      setUserItems([...qiscus]);
      setUserLoading({
        ...userLoading,
        load: false,
      });

      const qiscusId = location?.state?.qiscusId;
      const selectedUser = qiscus.find((user) =>
        user.participants.some(
          (participant) =>
            participant.email?.toString() === qiscusId?.toString()
        )
      );

      if (selectedUser && !location?.state?.isStay) {
        handleDetailUser(selectedUser, selectedUser.id);
      }

      if (!selectedUser && !location?.state?.isStay && qiscusId) {
        const room = await QISCUS.createRoom(qiscusId);

        handleDetailUser(room, room.id?.toString());
      }
    } catch (error) {
      setUserLoading({
        ...userLoading,
        load: false,
        isFirst: false,
        fail: true,
      });
    }
  };

  const { mutate } = useGetResetChatCount();

  const handleDetailUser = (data, qiscusId) => {
    mutate({ room_id: data.id });
    push({
      pathname: mobileUrl.CHATROOM,
      search: "",
      state: {
        ...(location.state || {}),
        qiscusId,
        selectedUser: {
          id: data.id,
          avatar: data.avatar,
          name: data.name,
          participants: data.participants,
          unique_id: data.unique_id,
        },
      },
    });
  };

  useEffect(() => {
    const { kind, data } = emitterStatus;

    const message = data?.length > 0 ? data[0] : {};

    const handleReconnecting = () => {
      QISCUS.login(qiscusInfo);
    };

    const handleLoginSuccess = () => {
      getRoom();
    };

    const handleNewMessage = () => {
      const users = [...userItems];
      const userIndex = users.findIndex((user) =>
        user.participants.some(
          (participant) => participant.email === message.email
        )
      );

      if (userIndex !== -1) {
        const user = users[userIndex];
        user.last_comment.extras = message.extras;
        user.last_comment.username = message.username;
        user.last_comment_message = message.message;
        user.last_comment_message_created_at = message.unix_timestamp;
        user.count_notif = parseInt(user?.count_notif || "0") + 1;

        users.splice(userIndex, 1);
        users.unshift(user);
      } else {
        const newUser = {
          id: message.topic_id_str,
          participants: message?.participants || [],
          unique_id: message.unique_temp_id,
          avatar: message.user_avatar_url,
          name: message.username,
          last_comment: {
            username: message.username,
            extras: message.extras,
          },
          last_comment_message: message.message,
          count_notif: 1,
        };
        users.unshift(newUser);
      }

      setUserItems(users);
    };

    switch (kind) {
      case "reconnecting":
        handleReconnecting();
        break;
      case "login-success":
        handleLoginSuccess();
        break;
      case "new-message":
        handleNewMessage();
        break;
      default:
        break;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emitterStatus]);

  useEffect(() => {
    event.on("event", function (data) {
      setEmitterStatus((emmit) => ({
        ...emmit,
        kind: data.kind,
        data: data.data,
      }));
    });

    if (firstOpen) {
      setEmitterStatus({
        kind: "reconnecting",
        data: null,
      });
      setFirstOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MobileLayout
      header={{
        title: "Chat",
        withBack: false,
        isHomepage: true,
        suffix: (
          <>
            {/* <Icon name="Search-outline" cursor="pointer" display="none" />
            <Icon name="Filter-outline" cursor="pointer" display="none" /> */}
            <Icon
              onClick={() => handleClickMenu(mobileUrl.CHAT_SETTING)}
              name="Setting-outline"
            />
          </>
        ),
      }}
      footer
    >
      <Flex flexDirection="column" width="100%">
        {userLoading.load ? (
          range(8).map((_, index) => (
            <SkeletonChatRoom key={`skeleton-chat-room-${index}`} />
          ))
        ) : userItems.length > 0 ? (
          userItems.map((user) => (
            <ChatItem
              key={`user-${user.id}`}
              user={user}
              handleDetailUser={handleDetailUser}
            />
          ))
        ) : (
          <ChatRoomEmpty user={userItems} />
        )}
      </Flex>
    </MobileLayout>
  );
};

export default ChatRoom;
