import { Divider, Flex, Skeleton } from "renos-ui";

const CardSkeleton = () => {
  return (
    <Flex flexDirection="column" px="24px">
      <Flex gap="16px" alignItems="center" py="16px">
        <Flex gap="16px" width="100%" alignItems="center">
          <Skeleton height="56px" width="56px" />
          <Flex flexDirection="column" width="80%" gap="4px">
            <Skeleton height="12px" width="80%" />
            <Skeleton height="12px" width="50%" />
          </Flex>
        </Flex>
      </Flex>
      <Divider />
    </Flex>
  );
};

export default CardSkeleton;
