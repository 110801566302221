import dayjs from "dayjs";
import { useState } from "react";
import { Box, Divider, Flex, Icon, Progress, Text } from "renos-ui";
import { Wrapper } from "../styled";

const ReviewItem = ({ item, toggleDrawer, index }) => {
  const [showMore, setShowMore] = useState([]);
  const baseImgUrl = process.env.REACT_APP_IMAGE_AWS;

  const handleClickShowMore = (index, isMessageSeller) => {
    setShowMore((prevShowMore) => {
      const newShowMore = [...prevShowMore];

      if (isMessageSeller) {
        newShowMore[index] = {
          statusSellerMessage: !newShowMore[index]?.statusSellerMessage,
        };
      } else {
        newShowMore[index] = {
          status: !newShowMore[index]?.status,
        };
      }

      return newShowMore;
    });
  };

  return (
    <Flex
      gap="16px"
      borderRadius="8px"
      flexDirection="row"
      backgroundColor="white"
    >
      <Flex width={"100%"} flexDirection="column" gap="8px">
        <Flex flexDirection="column" gap="9px" px="16px" pt="16px">
          <Flex flexDirection="row" justifyContent="space-between">
            <Flex flexDirection="row" gap="8px">
              <Text weight="bold" variant="body" lineClamp={1}>
                {item?.user?.user_name}
              </Text>
              <Flex flexDirection="row" alignItems="center" gap="4px">
                <Icon name="Circle-solid" color="black25" size="4px" />
                <Text variant="caption" color="black75" width="80px">
                  {dayjs(
                    item?.messages?.find((message) => message.primary === true)
                      ?.created_date
                  ).format("D MMM YYYY")}
                </Text>
              </Flex>
            </Flex>
            <Flex onClick={() => toggleDrawer(item)}>
              <Icon name="Vertical-outline" size="20px" color="black50" />
            </Flex>
          </Flex>
          <Text variant="caption" color="black50" lineClamp={1}>
            {item?.product?.product_name}
          </Text>
          <Progress.Rating
            readOnly
            size={16}
            gap="4px"
            count={item.score}
            value={item.score}
          />
          <Wrapper>
            <Text variant="caption" lineClamp={showMore[index]?.status ? 0 : 2}>
              {
                item?.messages?.find((message) => message.primary === true)
                  ?.message
              }
            </Text>
          </Wrapper>
        </Flex>
        {showMore[index]?.status && (
          <Flex flexDirection="row" gap="8px" px="16px">
            {item?.images?.map((image, indexImg) => (
              <Box
                as="img"
                height="40px"
                width="40px"
                borderRadius="8px"
                alt="produt-image"
                key={`product-review-image-${indexImg}`}
                src={`${baseImgUrl}${encodeURI(image?.review_image)}`}
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = "/assets/images/empty-product.svg";
                }}
              />
            ))}
          </Flex>
        )}
        <Flex
          flexDirection="row"
          px="16px"
          pb="16px"
          gap="8px"
          alignItems="center"
          onClick={() => handleClickShowMore(index)}
        >
          <Text color="blue50" weight="bold" variant="caption">
            {showMore[index]?.status ? "Sembunyikan" : "Lihat Detail"}
          </Text>
          <Icon
            name={`${showMore[index]?.status ? "Up" : "Down"}-outline`}
            color="blue50"
            size="12px"
          />
        </Flex>
        {item?.messages?.length > 1 && (
          <>
            <Divider />
            <Flex flexDirection="column" px="16px" pb="16px" pt="6px">
              <Flex
                backgroundColor="black5"
                p="12px"
                flexDirection="column"
                gap="4px"
                borderRadius="8px"
              >
                <Flex flexDirection="row" gap="8px">
                  <Text variant="caption" color="black50">
                    Balasan Darimu
                  </Text>
                  <Flex flexDirection="row" alignItems="center" gap="4px">
                    <Icon name="Circle-solid" color="black25" size="4px" />
                    <Text variant="caption" color="black50">
                      {dayjs(
                        item?.messages.find(
                          (message) => message.primary === false
                        )?.created_date
                      ).format("D MMM YYYY")}
                    </Text>
                  </Flex>
                </Flex>
                <Wrapper>
                  <Text
                    variant="caption"
                    color="black75"
                    lineClamp={showMore[index]?.statusSellerMessage ? 0 : 2}
                  >
                    {
                      item?.messages.find(
                        (message) => message.primary === false
                      )?.message
                    }
                  </Text>
                </Wrapper>
                <Flex
                  gap="8px"
                  flexDirection="row"
                  alignItems="center"
                  onClick={() => handleClickShowMore(index, true)}
                >
                  <Text color="blue50" weight="bold" variant="caption">
                    {showMore[index]?.statusSellerMessage
                      ? "Tutup Balasan"
                      : "Lihat Balasan"}
                  </Text>
                  <Icon
                    name={`${
                      showMore[index]?.statusSellerMessage ? "Up" : "Down"
                    }-outline`}
                    color="blue50"
                    size="12px"
                  />
                </Flex>
              </Flex>
            </Flex>
          </>
        )}
      </Flex>
    </Flex>
  );
};

export default ReviewItem;
