import React from "react";
import {
  Drawer,
  Flex,
  Icon,
  Input,
  Button,
  isEmpty,
  Checkbox,
  Text,
  Divider,
} from "renos-ui";
import EmptyState from "../components/EmptyState";
import { StyledFlexInputSearch } from "../components/styled";
import useModalDistrict from "./hooks/useModalDistrict";
import LoadingSpinner from "../components/LoadingSpinner";

const ModalDistrict = ({
  isOpen,
  onClose,
  selectedIndex,
  mutateDistrict,
  isLoadingDistrict,
}) => {
  const {
    dataModal,
    isCheckedAll,
    dataSearch,
    handleCheckAll,
    isIncludeSelected,
    handleChangeCheckBox,
    handleSearch,
    handleSave,
  } = useModalDistrict({
    isOpen,
    onClose,
    selectedIndex,
    mutateDistrict,
    isLoadingDistrict,
  });

  return (
    <Drawer
      isVisible={isOpen}
      onClose={onClose}
      isFullPage
      padding="0"
      header={{
        render: (
          <Flex
            flexDirection="row"
            alignItems="center"
            gap="8px"
            padding="16px"
          >
            <Icon
              name="Back-outline"
              size="24"
              color="black50"
              onClick={onClose}
            />
            <StyledFlexInputSearch width="100%" borderRadius="100%">
              <Input
                size="small"
                placeholder="Cari nama kecamatan"
                prefix={
                  <Icon name="Search-outline" size={16} color="black50" />
                }
                onChange={(event) => handleSearch(event)}
              />
            </StyledFlexInputSearch>
          </Flex>
        ),
      }}
    >
      <Flex
        flexDirection="column"
        gap="16px"
        padding="0 16px"
        height="calc(100% - 130px)"
        overflow="auto"
      >
        {isLoadingDistrict ? (
          <LoadingSpinner />
        ) : dataSearch.length === 0 ? (
          <EmptyState />
        ) : (
          <>
            <Flex gap="8px" alignItems="center">
              <Checkbox
                checked={isCheckedAll.active}
                indeterminate={isCheckedAll.indeterminate}
                onChange={handleCheckAll}
              />
              <Text color="black75">Pilih Semua</Text>
            </Flex>
            <Flex>
              <Divider />
            </Flex>

            {dataSearch.map((district, indexDistrict) => (
              <Flex
                gap="8px"
                alignItems="center"
                width="196px"
                height="24px"
                key={`district-box-${indexDistrict}`}
              >
                <Checkbox
                  checked={isIncludeSelected(district.district_id)}
                  onChange={() => handleChangeCheckBox(district)}
                />
                <Text color="black75" width="168px" lineClamp={1}>
                  {district.district_name}
                </Text>
              </Flex>
            ))}
          </>
        )}
      </Flex>
      <Flex padding="16px">
        <Button
          isBlock
          size="medium"
          onClick={handleSave}
          disabled={isEmpty(dataModal.selectedDistrict)}
        >
          Simpan
        </Button>
      </Flex>
    </Drawer>
  );
};

export default ModalDistrict;
