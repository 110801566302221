import ENDPOINTS from "constants/endpoints";
import { useMutation, useQuery } from "react-query";
import client from "../client";

const uploadFileFetcher = async (body) => {
  const response = await client.post(ENDPOINTS.upload_bulk_product, body, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  return response.data;
};

export const useUploadFile = (extraParams) =>
  useMutation((body) => uploadFileFetcher(body), {
    ...extraParams,
  });

const downloadBulkProductTemplate = async (body) => {
  const response = await client.post(
    ENDPOINTS.download_bulk_upload_template,
    body
  );

  return response;
};

export const useDownloadBulkProductTemplate = (extraParams) =>
  useMutation((body) => downloadBulkProductTemplate(body), {
    ...extraParams,
  });

const getBulkUploadInfo = async (id) => {
  const response = await client.get(ENDPOINTS.get_bulk_upload_info + id);

  return response.data;
};

export const useGetBulkUploadInfo = (extraParams) =>
  useMutation((id) => getBulkUploadInfo(id), {
    ...extraParams,
  });

const getShowcaseFetcher = async (params) => {
  const { data } = await client.get(ENDPOINTS.showcase, { params });

  return data;
};

export const useGetShowcase = (params, extraParams) => {
  return useQuery(
    ["getShowcase", params],
    () => getShowcaseFetcher({ ...params }),
    {
      ...extraParams,
    }
  );
};

const generateEditTemplate = async (params) => {
  const response = await client.post(
    ENDPOINTS.generate_bulk_edit_template,
    params
  );

  return response.data;
};

export const useGenerateEditTemplate = (extraParams) =>
  useMutation((params) => generateEditTemplate(params), {
    ...extraParams,
  });

const getStatusEditTemplate = async (uuid) => {
  const response = await client.get(
    `${ENDPOINTS.status_bulk_edit_template}/${uuid}`
  );

  return response.data;
};

export const useGetStatusEditTemplate = (extraParams) =>
  useMutation((uuid) => getStatusEditTemplate(uuid), {
    ...extraParams,
  });

const uploadFileEditFetcher = async (body) => {
  const response = await client.post(ENDPOINTS.upload_bulk_edit_product, body, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  return response.data;
};

export const useUploadFileEdit = (extraParams) =>
  useMutation((body) => uploadFileEditFetcher(body), {
    ...extraParams,
  });
