import { Button, Flex, Text, Dialog } from "renos-ui";

const ConfirmationModal = ({
  showConfirmation,
  isEdit,
  datas,
  setIsEdit,
  submitUpdate,
  submitStoreOpen,
}) => {
  return (
    <Dialog
      isVisible={showConfirmation.isOpen}
      onClose={showConfirmation.onClose}
      verticalCentered
      width="300px"
    >
      <Flex
        width="100%"
        fontFamily="sans-serif"
        flexDirection="column"
        rowGap="24px"
      >
        <Flex flexDirection="column" rowGap="8px" flexWrap="wrap">
          <Text variant="heading3" weight="bold">
            {isEdit ? "Terapkan Perubahan?" : "Buka Toko Sekarang?"}
          </Text>
          <Flex width="300px">
            <Text variant="body">
              {isEdit
                ? "Tanggal buka tutup toko akan diperbaharui sesuai pengaturan tanggal libur tokomu."
                : "Tokomu akan kembali dibuka dan dapat menerima pesanan dari pembeli."}
            </Text>
          </Flex>
        </Flex>
        <Flex flexDirection="row" justifyContent="space-between" gap="16px">
          <Button
            isBlock
            size="large"
            variant="tertiary"
            onClick={() => {
              setIsEdit(!isEdit);
              showConfirmation.onClose();
            }}
          >
            Tidak, Batal
          </Button>
          {isEdit && !datas?.is_closed && (
            <Button
              isBlock
              size="large"
              variant="primary"
              onClick={submitUpdate}
            >
              Ya, Terapkan
            </Button>
          )}
          {isEdit && datas?.is_closed && (
            <Button
              isBlock
              size="large"
              variant="primary"
              onClick={submitStoreOpen}
            >
              Ya, Buka
            </Button>
          )}
        </Flex>
      </Flex>
    </Dialog>
  );
};

export default ConfirmationModal;
