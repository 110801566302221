import React, { useState } from "react";
import { Flex, Text, Icon, Tabs } from "renos-ui";
import { useHistory } from "react-router-dom";
import TemplateChat from "./TemplateChat";
import ReplyChat from "./ReplyChat";
import useQueryParams from "hooks/useQueryParams";
import desktopUrl from "constants/desktopUrl";

const ChatSetting = () => {
  const history = useHistory();
  const query = useQueryParams();
  const { push } = useHistory();

  const [activeTab, setActiveTab] = useState(
    query.get("tab-active") || "template-chat"
  );

  const items = [
    {
      label: "Template Chat",
      key: "template-chat",
      width: "fit-content",
      children: () => <TemplateChat />,
    },
    {
      label: "Balasan Chat Otomatis",
      key: "balas-chat-otomatis",
      width: "fit-content",
      children: () => <ReplyChat />,
    },
  ];

  const handleChangeTabActive = (value) => {
    setActiveTab(value);
    push(`/chat/settings?tab-active=${value}`);
  };

  return (
    <Flex flexDirection="column" gap="24px">
      <Flex gap="12px" alignItems="center">
        <Icon
          name="Back-outline"
          size={20}
          color="black25"
          cursor="pointer"
          onClick={() => history.push(desktopUrl.CHAT)}
        />
        <Text weight="bold" variant="heading3">
          Pengaturan Chat
        </Text>
      </Flex>

      <Flex
        flexDirection="column"
        px="24px"
        py="16px"
        borderRadius="16px"
        backgroundColor="white"
      >
        <Tabs
          onChange={handleChangeTabActive}
          defaultActiveKey={activeTab}
          placement="bottom"
          items={items}
        />
      </Flex>
    </Flex>
  );
};

export default ChatSetting;
