import useQueryParams from "hooks/useQueryParams";
import {
  Box,
  Button,
  Divider,
  Flex,
  Icon,
  Skeleton,
  Tabs,
  Text,
} from "renos-ui";
import { useHistory } from "react-router-dom";
import { useMemo, useState } from "react";
import desktopUrl from "constants/desktopUrl";
import List from "./List";
import { useGetVoucherStatus } from "services/voucher-management";
import Empty from "./Empty";

const VoucherManagement = () => {
  const query = useQueryParams();
  const { push } = useHistory();
  const [dataFilter, setDataFilter] = useState({
    status: query.get("status") || "1",
    page: 1,
    search_value: "",
  });

  const { data, isLoading, isRefetching, isError, refetch } =
    useGetVoucherStatus();

  const handleChangeTabActive = (value) => {
    setDataFilter({ status: value, page: 1, search_value: "" });
    push(`${desktopUrl.VOUCHER}?status=${value}`);
    refetch();
  };

  const dataSource = useMemo(() => {
    if (data?.data) {
      return data?.data.map((dataStatus, indexStatus) => ({
        label: dataStatus.formatted_voucher_status_name,
        key: String(indexStatus + 1),
        width: "200px",
        children: () => <></>,
      }));
    } else {
      return [
        {
          label: () => "Voucher Aktif (0)",
          key: "1",
          children: () => <></>,
        },
      ];
    }
    //eslint-disable-next-line
  }, [isLoading, isRefetching, data]);

  const isEmpty = useMemo(() => {
    if (data?.data)
      return (
        data?.data.reduce((finalTotal, { total }) => finalTotal + total, 0) ===
        0
      );

    return false;
  }, [data]);

  if (isEmpty) return <Empty />;

  return (
    <Flex flexDirection="column" gap="8px">
      <Flex
        justifyContent="space-between"
        padding="24px"
        alignItems="center"
        borderTopLeftRadius="16px"
        borderTopRightRadius="16px"
        backgroundColor="white"
        boxShadow="0px 4px 4px rgba(20, 20, 32, 0.05)"
      >
        <Text variant="heading3" weight="bold">
          Daftar Voucher
        </Text>
        <Flex width="184px">
          <Button
            size="medium"
            preffix={<Icon name="Plus-outline" size={20} />}
            isBlock
            onClick={() => push(desktopUrl.VOUCHER_ADD)}
          >
            Buat Voucher
          </Button>
        </Flex>
      </Flex>

      <Box
        padding="24px"
        borderBottomLeftRadius="16px"
        borderBottomRightRadius="16px"
        backgroundColor="white"
        boxShadow="0px 4px 4px rgba(20, 20, 32, 0.05)"
      >
        {isLoading ? (
          <Flex flexDirection="column" gap="10px">
            <Flex gap="10px">
              <Skeleton width="160px" height="40px" />
              <Skeleton width="160px" height="40px" />
            </Flex>
            <Divider />
          </Flex>
        ) : isError ? (
          <Button onClick={refetch} size="medium">
            Muat Ulang Status
          </Button>
        ) : (
          <Tabs
            onChange={handleChangeTabActive}
            defaultActiveKey={dataFilter?.status}
            placement="bottom"
            items={dataSource}
          />
        )}

        <List dataFilter={dataFilter} setDataFilter={setDataFilter} />
      </Box>
    </Flex>
  );
};

export default VoucherManagement;
