import styled from "styled-components";
import { Box, SelectInput } from "renos-ui";
import Table from "widgets/desktop/TableDiscountAdd";

export const StyledSelectInput = styled(SelectInput)`
  width: 156px;

  .select__control {
    padding: 9px 12px;
  }
`;

export const StyledTable = styled(Table)`
  th {
    padding: 0px 12px 24px 12px;
  }

  td {
    padding-left: 12px;
    padding-right: 12px;
  }
`;

export const ProductImage = styled(Box)`
  object-fit: cover;
`;
