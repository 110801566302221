import ENDPOINTS from "constants/endpoints";
import { useMutation, useQuery } from "react-query";
import client from "../../client";

const getSellerFleetFetcher = async () => {
  const { data } = await client.get(ENDPOINTS.seller_fleet, {
    params: { humane_regions: true },
  });

  return data;
};

export const useGetSellerFleet = (extraParams) => {
  return useQuery(["getSellerFleet"], getSellerFleetFetcher, {
    ...extraParams,
  });
};

const activateSellerFleetFetcher = async () => {
  const { data } = await client.post(ENDPOINTS.seller_fleet_activate);

  return data;
};

export const useActivateSellerFleet = (extraParams) => {
  return useQuery(["activateSellerFleet"], activateSellerFleetFetcher, {
    ...extraParams,
    refetchOnWindowFocus: false,
    enabled: false,
  });
};

const deactivateSellerFleetFetcher = async () => {
  const { data } = await client.post(ENDPOINTS.seller_fleet_deactive);

  return data;
};

export const useDeactivateSellerFleet = (extraParams) => {
  return useQuery(["deactivateSellerFleet"], deactivateSellerFleetFetcher, {
    ...extraParams,
    refetchOnWindowFocus: false,
    enabled: false,
  });
};

const getSellerFleetSettingFetcher = async () => {
  const { data } = await client.get(
    `${ENDPOINTS.seller_fleet_setting_list}?items_per_page=9999`
  );

  return data;
};

export const useGetSellerFleetSetting = (extraParams) => {
  return useQuery(["getSellerFleetSetting"], getSellerFleetSettingFetcher, {
    ...extraParams,
  });
};

const deleteSellerFleetSettingFetcher = async (params) => {
  const { data } = await client.post(ENDPOINTS.seller_fleet_delete, params);

  return data;
};

export const useDeleteSellerFleetSetting = (extraParams) =>
  useMutation((body) => deleteSellerFleetSettingFetcher(body), {
    ...extraParams,
  });

const getSellerFleetCategoriesFetcher = async () => {
  const { data } = await client.get(ENDPOINTS.seller_fleet_categories);

  return data;
};

export const useGetSellerFleetCategories = (extraParams) => {
  return useQuery(
    ["getSellerFleetCategories"],
    getSellerFleetCategoriesFetcher,
    {
      ...extraParams,
    }
  );
};

const bulkSaveSellerFleet = async (body) => {
  const response = await client.post(
    ENDPOINTS.seller_fleet_setting_bulk_save,
    body
  );

  return response;
};

export const useBulkSaveSellerFleet = (extraParams) =>
  useMutation((body) => bulkSaveSellerFleet(body), {
    ...extraParams,
  });

const bulkDeleteSellerFleet = async (body) => {
  const response = await client.post(
    ENDPOINTS.seller_fleet_setting_bulk_delete,
    body
  );

  return response;
};

export const useBulkDeleteSellerFleet = (extraParams) =>
  useMutation((body) => bulkDeleteSellerFleet(body), {
    ...extraParams,
  });

const getSellerFleetByIdFetcher = async (params) => {
  const { data } = await client.get(ENDPOINTS.seller_fleet_setting_by_id, {
    params,
  });

  return data;
};

export const useGetSellerFleetById = (extraParams) =>
  useMutation((body) => getSellerFleetByIdFetcher(body), { ...extraParams });

const singleSaveSellerFleet = async (body) => {
  const response = await client.post(
    ENDPOINTS.seller_fleet_setting_update,
    body
  );

  return response;
};

export const useSingleSaveSellerFleet = (extraParams) =>
  useMutation((body) => singleSaveSellerFleet(body), {
    ...extraParams,
  });
