import { Box, Checkbox, Flex, Text } from "renos-ui";

const ProductItem = ({ item, checked, handleCheckProduct, disabledCheck }) => {
  return (
    <Flex
      gap="12px"
      pb="12px"
      alignItems="center"
      borderBottomWidth="1px"
      borderBottomStyle="solid"
      borderBottomColor="black5"
    >
      <Flex>
        <Checkbox
          size="small"
          checked={checked}
          onChange={() => handleCheckProduct(item)}
          disabled={disabledCheck}
        />
      </Flex>
      <Flex>
        <Box
          as="img"
          width="48px"
          height="48px"
          borderRadius="8px"
          src={item.product_image_url}
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = "/assets/images/empty-product.svg";
          }}
        />
      </Flex>
      <Flex gap="4px" flexDirection="column" style={{ wordBreak: "break-all" }}>
        <Text color="black75" lineClamp={1}>
          {item?.product_name}
        </Text>
        <Text weight="bold">{item?.formatted_selling_price}</Text>
      </Flex>
    </Flex>
  );
};

export default ProductItem;
