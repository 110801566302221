import MobileLayout from "layouts/MobileLayout";
import React, { useState } from "react";
import {
  Box,
  Button,
  Flex,
  Icon,
  Input,
  Text,
  Upload,
  isEmpty,
  useDisclosure,
  useToast,
  useUpload,
} from "renos-ui";
import constants from "store/productAdd/constants";
import { useProductAddContext } from "store/productAdd/reducer";
import FormLayout from "../../components/FormLayout";
import { useUploadProductImage } from "services/product";
import {
  blobToBase64,
  getImageMeta,
  imageTypeChecking,
  imgCompressor,
  youtubeLinkValidator,
} from "helpers";
import { IMAGE_FILE_TYPE } from "containers/web/setting/document/constant";
import { useGetYoutubeData } from "services/global/youtube";
import { VideoPlayer } from "containers/web/product/add/single/styled";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import desktopUrl from "constants/desktopUrl";

const ProductAssets = () => {
  const { state, dispatch } = useProductAddContext();
  const { productImages, productVideos, isEdit } = state;

  const { push, goBack } = useHistory();
  const toast = useToast();

  const [imageList, setImageList] = useState(productImages || []);
  const [videoList, setVideoList] = useState(productVideos || []);
  const [isPhotoInvalid, setIsPhotoInvalid] = useState();
  const [invalidMessage, setInvalidMessage] = useState();
  const [youtubeUrl, setYoutubeUrl] = useState();
  const [isVideoUrlInvalid, setIsVideoUrlInvalid] = useState();
  const [selectedVideo, setSelectedVideo] = useState();

  const { isOpen, onOpen, onClose } = useDisclosure({ open: false });

  const { mutate: uploadImage, isLoading: isUploadImageLoading } =
    useUploadProductImage({
      onSuccess: (data) => {
        setImageList((prev) => [
          ...prev,
          {
            imageType: imageList.length + 1,
            imageUrl: data.url,
            image: data.path,
            image_type: data.image_type_id,
            image_id: data.image_id,
          },
        ]);
      },
    });

  const { mutate: getYoutubeData } = useGetYoutubeData({
    onSuccess: (data) => {
      const youtubeId = data.thumbnail_url
        .split("https://i.ytimg.com/vi/")[1]
        .split("/")[0];
      const embedUrl = `https://www.youtube.com/embed/${youtubeId}`;
      setVideoList((prev) => [
        ...prev,
        { ...data, url: youtubeUrl || data.url, youtubeEmbedUrl: embedUrl },
      ]);
      setYoutubeUrl("");
    },
  });

  const { register } = useUpload({
    fileType: "image",
    onRequest: async (file) => {
      const deepTypeChecking = await imageTypeChecking(file);

      if (deepTypeChecking) {
        setIsPhotoInvalid();
        setInvalidMessage();
        const result = await blobToBase64(file);
        const { naturalHeight, naturalWidth } = await getImageMeta(result);

        if (naturalHeight < 300 || naturalWidth < 300) {
          setIsPhotoInvalid(true);

          return null;
        }

        const formData = new FormData();
        const compressed =
          file.size > 400000
            ? await imgCompressor(file, {
                maxSizeMB: 0.4,
                maxWidthOrHeight: 1920,
                useWebWorker: true,
              })
            : file;

        formData.append("image", compressed);
        formData.append("image_type_id", imageList.length + 1);
        uploadImage(formData);
      } else {
        setIsPhotoInvalid(true);
        setInvalidMessage("Format gambar tidak sesuai");
      }
    },
    validator: (file) => {
      if (!IMAGE_FILE_TYPE.includes(file.type)) {
        setIsPhotoInvalid(true);
        setInvalidMessage("Format gambar tidak sesuai");

        return {
          message: "Format gambar tidak sesuai",
        };
      }

      return null;
    },
  });

  const deleteImage = (index) => {
    const tempList = [...imageList];
    tempList.splice(index, 1);
    setImageList(tempList);
  };

  const onAddYoutubeUrl = () => {
    if (youtubeLinkValidator(youtubeUrl)) {
      getYoutubeData(youtubeUrl);
    } else {
      setIsVideoUrlInvalid(true);
    }
  };

  const handleDeleteList = (index, state, setState) => {
    const tempImageList = [...state];
    tempImageList.splice(index, 1);
    setState(tempImageList);
    setIsPhotoInvalid();
    setInvalidMessage();
  };

  const onThumbnailClicked = (embedUrl) => {
    onOpen();
    setSelectedVideo(embedUrl);
  };

  const onDialogClosed = () => {
    setSelectedVideo();
    onClose();
  };

  const onFinish = () => {
    if (imageList.length === 0) {
      toast({
        label: "Gambar tidak boleh kosong",
        placement: "bottom",
      });

      return;
    }
    dispatch({
      type: constants.SAVE_PRODUCT_ASSETS,
      payload: {
        productImages: imageList,
        productVideos: videoList,
      },
    });

    if (isEdit) {
      goBack();
    } else {
      push(desktopUrl.PRODUCTS_ADD);
    }
  };

  return (
    <MobileLayout
      header={{
        title: "Upload Foto & Video",
        isHomepage: true,
        withBack: true,
        suffix: (
          <Flex alignItems="center" cursor="pointer" onClick={onFinish}>
            <Text color="blue75" weight="bold">
              Selesai
            </Text>
          </Flex>
        ),
      }}
    >
      <Flex
        flexDirection="column"
        padding={16}
        rowGap="32px"
        width="100dvw"
        boxSizing="border-box"
      >
        <FormLayout title="Foto Produk" required>
          <Flex flexWrap="wrap" gap="8px">
            {imageList.map((item, index) => (
              <Flex
                key={item.imageUrl}
                width="104px"
                height="104px"
                borderRadius="8px"
                alignItems="flex-end"
                position="relative"
                borderStyle="solid"
                borderWidth="1px"
                borderColor="black25"
                background={`url("${item.imageUrl}")`}
                backgroundSize="contain"
                backgroundRepeat="no-repeat"
                backgroundPosition="center"
              >
                {index === 0 && (
                  <Flex
                    width="100%"
                    justifyContent="center"
                    py="2px"
                    backgroundColor="blue5"
                    borderRadius="8px"
                  >
                    <Text variant="caption" weight="bold" color="blue50">
                      Utama
                    </Text>
                  </Flex>
                )}

                <Box
                  position="absolute"
                  top="4px"
                  right="4px"
                  cursor="pointer"
                  onClick={() => deleteImage(index)}
                >
                  <Icon name="Close-solid" size={24} color="black50" />
                </Box>
              </Flex>
            ))}
            {imageList.length < 5 && (
              <Upload {...register} accept={IMAGE_FILE_TYPE}>
                <Flex
                  width="104px"
                  height="104px"
                  borderRadius="8px"
                  alignItems="center"
                  justifyContent="center"
                  borderStyle="dashed"
                  borderColor={isPhotoInvalid ? "red50" : "black10"}
                  borderWidth="2px"
                  cursor={isUploadImageLoading ? "not-allowed" : "pointer"}
                  backgroundColor={isUploadImageLoading ? "black5" : "white"}
                >
                  <Icon name="Plus-solid" size={42} color="blue50" />
                </Flex>
              </Upload>
            )}
          </Flex>
          {isPhotoInvalid && (
            <Text variant="caption" color="red50" aria-label="input-error">
              {invalidMessage || "Resolusi gambar harus lebih dari 300x300"}
            </Text>
          )}
        </FormLayout>
        <FormLayout title="Video Produk">
          <Flex rowGap="8px" flexDirection="column">
            <Flex columnGap="8px">
              <Flex flex={1} flexDirection="column" rowGap="8px">
                <Input
                  placeholder="URL youtube"
                  value={youtubeUrl}
                  onChange={(event) => {
                    setIsVideoUrlInvalid(false);
                    setYoutubeUrl(event.target.value);
                  }}
                  isError={!isEmpty(isVideoUrlInvalid)}
                  error="Link video yang kamu masukkan tidak benar"
                />
                <Text variant="caption" color="black50">
                  Copy link video produk kamu dan paste disini
                </Text>
              </Flex>
              <Button
                type="button"
                disabled={videoList.length === 3}
                onClick={onAddYoutubeUrl}
              >
                Tambah
              </Button>
            </Flex>
            {videoList.length > 0 &&
              videoList.map((video, index) => (
                <Flex
                  padding="16px"
                  borderWidth="1px"
                  borderStyle="solid"
                  borderColor="black10"
                  alignItems="center"
                  borderRadius={8}
                  columnGap="24px"
                  flex={1}
                >
                  <Flex
                    columnGap="16px"
                    alignItems="center"
                    key={video.thumbnail_url}
                  >
                    <Box
                      as="img"
                      cursor="pointer"
                      width="72px"
                      height="72px"
                      borderRadius="8px"
                      src={video.thumbnail_url}
                      alt="youtube-thumbnail"
                      onClick={() => onThumbnailClicked(video.youtubeEmbedUrl)}
                    />
                    <Flex flexDirection="column" width="calc(100dvw - 50dvw)">
                      <Text weight="bold" color="black75" lineClamp={1}>
                        {video.title}
                      </Text>
                      <Text color="black50" lineClamp={1}>
                        {video.url}
                      </Text>
                    </Flex>
                    <Icon
                      name="Close-solid"
                      color="black50"
                      cursor="pointer"
                      size={24}
                      onClick={() =>
                        handleDeleteList(index, videoList, setVideoList)
                      }
                    />
                  </Flex>
                </Flex>
              ))}
          </Flex>
        </FormLayout>
      </Flex>
      <VideoPlayer
        padding="0"
        isVisible={isOpen}
        verticalCentered
        onClose={onDialogClosed}
        closable
      >
        <Flex
          width="350px"
          height="444px"
          borderRadius={16}
          as="iframe"
          border="none"
          src={selectedVideo}
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        />
      </VideoPlayer>
    </MobileLayout>
  );
};

export default ProductAssets;
