import StoreContext from "providers/StoreProvider";
import { useContext } from "react";
import { useHistory } from "react-router-dom";
import { Flex, Text, Label, Icon } from "renos-ui";

const MainAddress = () => {
  const history = useHistory();
  const { storeData } = useContext(StoreContext);
  const storeAddress = storeData?.data?.locations?.find(
    (location) => location.is_primary
  );

  return (
    <Flex gap="16px" width="100%">
      <Flex
        justifyContent="space-between"
        borderColor="black10"
        borderStyle="dashed"
        borderWidth="1px"
        borderRadius="8px"
        padding="24px 40px 24px 24px"
        alignItems="center"
        width="100%"
      >
        <Flex gap="16px" flexDirection="column" maxWidth="464px">
          <Flex flexDirection="column" gap="8px">
            <Flex gap="12px" alignItems="center">
              <Text color="black75" weight="bold">
                {storeAddress?.name}
              </Text>
              <Label variant="success">Alamat Utama</Label>
            </Flex>
            <Text color="black75">{storeAddress?.contact_number}</Text>
            <Text color="black75">{storeAddress?.address}</Text>
            <Text
              variant="body"
              color="black75"
            >{`${storeAddress?.lower_district_name}, ${storeAddress?.district_name}, ${storeAddress?.city_name}, ${storeAddress?.province_name} - ${storeAddress?.postal_code}`}</Text>
          </Flex>
          <Flex
            cursor="pointer"
            onClick={() => history.push("/setting?tab-active=location")}
          >
            <Text color="blue50" weight="bold">
              Ubah Alamat Utama
            </Text>
          </Flex>
        </Flex>

        <Flex
          padding="24px"
          gap="8px"
          backgroundColor="yellow5"
          borderRadius="8px"
          maxWidth="368px"
          flexDirection="column"
        >
          <Flex gap="8px" alignItems="center">
            <Icon name="Info-solid" size={20} color="yellow50" />
            <Text variant="subtitle" color="yellow75" weight="bold">
              Peringatan
            </Text>
          </Flex>
          <Text color="yellow100">
            Jika kamu ganti alamat utama, maka tarif yang tersedia harus diganti
            secara manual.
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default MainAddress;
