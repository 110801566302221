import { Drawer, Flex, Text } from "renos-ui";

const InformationDrawer = ({ item, disclosure, onClose }) => {
  return (
    <Drawer
      closable={true}
      isVisible={disclosure.isOpen}
      onClose={onClose}
      placement="bottom"
    >
      <Flex gap="4px" flexDirection="column" py="16px">
        <Text variant="subtitle" weight="bold">
          {item?.title}
        </Text>
        <Text
          color="black75"
          dangerouslySetInnerHTML={{ __html: `${item?.description}` }}
        />
      </Flex>
    </Drawer>
  );
};

export default InformationDrawer;
