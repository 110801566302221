import useFeatureFlag from "hooks/useFeatureFlag";
import constants from "store/productAdd/constants";

export const setProductInitialValue = (methods, productDetail, id) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const isPreorderEnable = useFeatureFlag("preorder");

  methods.setValue("product_name", productDetail.product_name);
  const category = {
    label: productDetail.categories
      .reverse()
      .map((data) => data.category_name)
      .join(" / "),
    value: productDetail.categories.map((data) => data.category_id),
  };

  const productWeight = parseInt(productDetail.product_weight);

  methods.setValue("id", parseInt(id));
  methods.setValue("product_category", category);
  methods.setValue("category", productDetail.category_id);
  methods.setValue(
    "initialImages",
    productDetail.product_images.map((image) => ({
      ...image,
      image: image.product_image,
      image_type: image.image_type_id,
      image_id: image.product_image_id,
    }))
  );
  methods.setValue("videos", productDetail.product_video);
  methods.setValue("description", productDetail.product_description);
  methods.setValue("width", parseInt(productDetail.product_width));
  methods.setValue("length", parseInt(productDetail.product_length));
  methods.setValue("height", parseInt(productDetail.product_height));
  methods.setValue(
    "product_condition",
    productDetail.product_condition_id === 1 ? "new" : "second"
  );
  methods.setValue("weight", productWeight);
  methods.setValue("uom", productDetail.uom_id);
  methods.setValue("shipping_type", productDetail.shipping_type_id);

  //filter categories
  const selectedCategory = productDetail.categories.filter(
    (category) => category.category_id === productDetail.category_id
  )[0];

  const syncedProductAttribute = productDetail.product_attributes.map(
    (attribute) => {
      const filterAttribute = selectedCategory.category_attributes.filter(
        (categoryAttribute) =>
          categoryAttribute.attribute_id === attribute.attribute_id
      );
      let tempAttribute = attribute;

      if (filterAttribute.length > 0) {
        tempAttribute = {
          ...attribute,
          ...filterAttribute[0],
        };
      }

      return tempAttribute;
    }
  );

  methods.setValue(
    "product_attributes",
    productDetail.product_attributes.length > 0
      ? syncedProductAttribute
      : selectedCategory.category_attributes
  );

  if (productDetail.shipping_type_id === 2) {
    methods.setValue(
      "product_shipping",
      new Set(productDetail.product_shipping)
    );
  }

  if (isPreorderEnable) {
    methods.setValue("isPreorder", productDetail.is_preorder);

    if (productDetail.is_preorder) {
      methods.setValue("preorder", productDetail.preorder_day);
      methods.setValue("preorder_value", productDetail.preorder_day);
      methods.setValue(
        "preorder_type",
        productDetail?.product_preorder_type_id || 1
      );
    }
  }

  const isVariant = productDetail.product_skus[0].variants.length > 0;

  if (!isVariant) {
    methods.setValue("price", productDetail.product_skus[0]?.product_price);
    methods.setValue("stock", productDetail.product_skus[0]?.product_stock);
    methods.setValue(
      "product_sku_id",
      productDetail.product_skus[0]?.product_sku_id
    );
    methods.setValue(
      "product_sku",
      productDetail.product_skus[0]?.product_sku_mpn
    );
    methods.setValue(
      "product_active",
      productDetail.product_skus[0]?.product_status_id === 1
    );
    methods.setValue(
      "is_have_promo",
      productDetail.product_skus[0]?.is_product_discount_seasonal_active
    );
  } else {
    methods.setValue("variant", 1);
  }

  methods.setValue("temp_sku", productDetail?.product_skus);
};

export const setProductMobileInitialValue = (dispatch, productDetail, id) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const isPreorderEnable = useFeatureFlag("preorder");

  const productWeight = parseInt(productDetail.product_weight);

  const isVariant = productDetail.product_skus[0].variants.length > 0;

  //filter categories
  const selectedCategory = productDetail.categories.filter(
    (category) => category.category_id === productDetail.category_id
  )[0];

  const syncedProductAttribute = productDetail.product_attributes.map(
    (attribute) => {
      const filterAttribute = selectedCategory.category_attributes.filter(
        (categoryAttribute) =>
          categoryAttribute.attribute_id === attribute.attribute_id
      );
      let tempAttribute = attribute;

      if (filterAttribute.length > 0) {
        tempAttribute = {
          ...attribute,
          ...filterAttribute[0],
        };
      }

      return tempAttribute;
    }
  );

  const payload = {
    productName: productDetail.product_name,
    tempProductName: productDetail.product_name,
    id: parseInt(id),
    tempId: parseInt(id),
    productCategories: productDetail.categories,
    tempProductCategories: productDetail.categories,
    category: productDetail.category_id,
    productImages: productDetail.product_images.map((image) => ({
      ...image,
      image: image.product_image,
      image_type: image.image_type_id,
      image_id: image.product_image_id,
      imageUrl: image.product_image_url,
    })),
    tempProductVideos: productDetail.product_video,
    description: productDetail.product_description,
    width: parseInt(productDetail.product_width),
    length: parseInt(productDetail.product_length),
    height: parseInt(productDetail.product_height),
    product_condition:
      productDetail.product_condition_id === 1 ? "new" : "second",
    weight: productWeight,
    uom: productDetail.uom_id,
    shippingType: productDetail.shipping_type_id,
    isPreorder: isPreorderEnable ? productDetail.is_preorder : null,
    temp_sku: productDetail?.product_skus,
    productDetail,
    isEdit: true,
    product_attributes:
      productDetail.product_attributes.length > 0
        ? syncedProductAttribute
        : selectedCategory.category_attributes,
  };

  if (productDetail.shipping_type_id === 2) {
    payload.productShipping = new Set(productDetail.product_shipping);
  }

  if (isPreorderEnable && productDetail.is_preorder) {
    payload.preorder = productDetail.preorder_day;
    payload.preorder_value = productDetail.preorder_day;
    payload.preorderType = productDetail?.product_preorder_type_id || 1;
  }

  if (!isVariant) {
    payload.price = productDetail.product_skus[0]?.product_price;
    payload.stock = productDetail.product_skus[0]?.product_stock;
    payload.product_sku_id = productDetail.product_skus[0]?.product_sku_id;
    payload.product_sku = productDetail.product_skus[0]?.product_sku_mpn;
    payload.product_active =
      productDetail.product_skus[0]?.product_status_id === 1;
    payload.is_product_discount_seasonal_active =
      productDetail.product_skus[0]?.is_product_discount_seasonal_active;
  } else {
    payload.variant = 1;
  }

  dispatch({
    type: constants.SAVE_VALUE,
    payload,
  });
};
