import React from "react";
import { Flex, Text, isEmpty } from "renos-ui";
import { useHistory } from "react-router-dom";
import FormLayout from "./FormLayout";
import mobileUrl from "constants/mobileUrl";
import { useProductAddContext } from "store/productAdd/reducer";

const ProductInformations = () => {
  const {
    state: { productName, productCategories },
  } = useProductAddContext();

  const { push } = useHistory();

  const categoryName =
    !isEmpty(productCategories) &&
    Array.from(productCategories)
      .slice()
      .reverse()
      .map((each) => each.category_name)
      .join(" / ");

  const isFilled = !isEmpty(productName) || !isEmpty(categoryName);

  return (
    <FormLayout
      title="Informasi Produk"
      isFilled={isFilled}
      action={() => push(mobileUrl.PRODUCT_INFORMATION)}
    >
      {isFilled && (
        <>
          <Flex flexDirection="column" rowGap="16px">
            <Flex flexDirection="column">
              <Text variant="small" color="black50">
                Nama Produk
              </Text>
              <Text variant="caption">{productName}</Text>
            </Flex>
          </Flex>
          <Flex flexDirection="column">
            <Text variant="small" color="black50">
              Kategori Produk
            </Text>
            <Text variant="caption">{categoryName}</Text>
          </Flex>
        </>
      )}
    </FormLayout>
  );
};

export default ProductInformations;
