import MobileLayout from "layouts/MobileLayout";
import React, { useState } from "react";
import { Flex, Tabs } from "renos-ui";
import { StyledFlex } from "./styled";
import useQueryParams from "hooks/useQueryParams";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import mobileUrl from "constants/mobileUrl";
import TemplateChat from "./template";
import BalasanOtomatis from "./balasan-otomatis";

const ChatSetting = () => {
  const query = useQueryParams();
  const { push } = useHistory();
  const [activeTabsKey, setActiveTabsKey] = useState(
    query.get("tab-active") || "template-chat"
  );

  const tabsItems = [
    {
      key: "template-chat",
      label: "Template Chat",
      width: "50%",
      children: TemplateChat,
    },
    {
      key: "balasan-otomatis",
      label: "Balasan Otomatis",
      width: "50%",
      children: BalasanOtomatis,
    },
  ];

  const handleChangeTabs = (value) => {
    setActiveTabsKey(value);
    push(`${mobileUrl.CHAT_SETTING}?tab-active=${value}`);
  };

  return (
    <MobileLayout
      header={{
        title: "Pengaturan Chat",
        withBack: true,
      }}
    >
      <Flex
        width="100%"
        flexDirection="column"
        backgroundColor={activeTabsKey === "template-chat" ? "white" : "black5"}
      >
        <StyledFlex
          gap="12px"
          width="100%"
          columnGap="8px"
          alignItems="center"
          backgroundColor="white"
          justifyContent="center"
        >
          <Tabs
            defaultActiveKey={activeTabsKey}
            onChange={handleChangeTabs}
            items={tabsItems}
          />
        </StyledFlex>
      </Flex>
    </MobileLayout>
  );
};

export default ChatSetting;
