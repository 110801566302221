import { mobileSkuValidationSchema } from "helpers/validation-schema/product-add";
import useFeatureFlag from "hooks/useFeatureFlag";
import { isEmpty, omit, pick } from "renos-ui";

const valuesPreprocessing = (values) => {
  values.sku = values.sku.map((sku) => ({
    ...sku,
    status: parseInt(sku.stock) === 0 ? 2 : sku.status,
  }));

  if (values.temp_sku) {
    values.temp_sku = values.temp_sku.map((tempSKU) => ({
      ...tempSKU,
      product_image_url: tempSKU.product_image_url || "empty",
    }));
  }

  if (
    values.sku.filter((filterSKU) => filterSKU.default).length === 0 &&
    values.sku.length !== 0
  )
    values.sku[0].default = 1;

  // if (
  //   values.sku.filter(
  //     (filterSKU) => filterSKU.default && filterSKU.status === 2
  //   ).length > 0
  // ) {
  //   let isFilled = false;
  //   values.sku = values.sku.map((sku) => {
  //     const tempSKU = {
  //       ...sku,
  //       default: sku.default !== 0 && sku.status === 2 ? 0 : 1,
  //     };

  //     if (sku.status === 1) isFilled = true;

  //     return tempSKU;
  //   });
  // }

  return values;
};

export const formValueToJson = (values, isEdit, isMobile = false) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const isPreorderEnabled = useFeatureFlag("preorder");

  const data = valuesPreprocessing(values);

  const {
    product_name,
    images,
    product_sku,
    product_shipping,
    product_condition,
    product_active,
    sku,
    videos,
    product_sku_id,
    shipping_type,
    variant,
    temp_sku,
    product_attributes,
  } = data;

  const payload = omit(data, [
    "product_category",
    "isPreorder",
    "product_active",
    "product_condition",
    "product_name",
    "product_shipping",
    "sku",
    "product_sku",
    "variant",
    "images",
    "product_colors",
    "product_materials",
    "preorder_value",
    "videos",
    "product_sku_id",
    "initialImages",
    "temp_sku",
    "productVideos",
    "tempProductCategories",
    "productCategories",
    "productImages",
    "productShipping",
    "tempProductName",
    "productVariants",
    "productName",
    "descriptions",
    "preorderType",
    "shippingType",
    "tempId",
    "productDetail",
    "initialFilled",
    "productStatus",
    "fromEmpty",
    "skeletonFilled",
    "isEdit",
    "tempProductVideos",
  ]);

  const imageList = images || data?.productImages;
  let videoList = videos;

  payload.images = JSON.stringify(imageList);
  payload.name = product_name;

  if (product_attributes.length > 0) {
    payload.product_attributes = product_attributes.map((item) =>
      pick(item, ["attribute_id", "product_attribute_value"])
    );
  } else {
    payload.product_attributes = [];
  }

  if (isMobile) {
    payload.product_category = data?.productCategories;
    payload.category =
      data?.productCategories[0].value ||
      data?.productCategories[0].category_id;
    payload.preorder_type = isPreorderEnabled ? data?.preorderType : null;
    payload.name = data?.productName;
    payload.shipping_type = data?.shippingType;
    videoList = data?.productVideos.map((item) => item.url);

    if (data?.shippingType === 2) {
      payload.product_shipping = JSON.stringify(
        Array.from(data.productShipping)
      );
    }
  }

  payload.number = product_sku;
  payload.product_condition = product_condition === "new" ? 1 : 2;
  payload.product_status_id = product_active ? 1 : 2;
  payload.tags = "[]";
  payload.video =
    typeof videoList === "string" ? videoList : JSON.stringify(videoList);
  payload.price = parseInt(payload.price);
  payload.stock = parseInt(payload.stock);

  if (shipping_type === 2) {
    payload.product_shipping = JSON.stringify(Array.from(product_shipping));
  }

  if (variant === 0) {
    const tempSku = [
      {
        variants: [],
        price: payload.price,
        default: 1,
        number: payload.number,
        status: product_active ? 1 : 2,
        stock: payload.stock,
        image: imageList[0].image,
        image_id: imageList[0].image_id,
      },
    ];

    if (isEdit) {
      tempSku[0].product_sku_id = product_sku_id;
    }

    if (temp_sku && temp_sku.length > 0 && isEdit) {
      const isSingle = temp_sku[0].product_sku_id === tempSku[0].product_sku_id;

      if (!isSingle) {
        temp_sku.forEach((sku) =>
          tempSku.push({
            is_deleted: true,
            variants: sku.variants,
            default: 0,
            price: sku.product_price,
            stock: sku.product_stock,
            number: sku.product_sku_mpn,
            image: sku.product_image_url,
            image_id: sku.product_image_id,
            status: sku.product_status_id,
            product_sku_id: sku.product_sku_id,
          })
        );
      }
    }

    payload.sku = JSON.stringify(tempSku);
  } else {
    let formattedSku = sku.map((eachSku) => {
      const newSkuId = {};
      Object.keys(eachSku.id).forEach((key) => {
        newSkuId[key] =
          eachSku.id[key] && eachSku.id[key].toString().includes("new")
            ? null
            : eachSku.id[key];
      });

      eachSku.id = newSkuId;

      eachSku.variants = eachSku.variants.map((variant) => {
        if (variant.variant_value) {
          const isIncludedNew = variant.variant_value
            .toString()
            .includes("new");

          if (isIncludedNew) {
            variant.variant_value = null;
          }
        }

        return variant;
      });

      return eachSku;
    });

    if (temp_sku && temp_sku.length > 1 && isEdit) {
      let counter = 0;
      formattedSku.forEach((sku) => {
        const varName =
          sku.variants.length > 1
            ? sku.variants.map((variant) => variant.label).join("|")
            : sku.variants[0].label;

        const selected = temp_sku.find((sku) => sku.variant_name === varName);

        if (selected) {
          counter++;
        }
      });

      if (counter < temp_sku.length) {
        const allSkuName = formattedSku.map((sku) => {
          const varName =
            sku.variants.length > 1
              ? sku.variants.map((variant) => variant.label).join("|")
              : sku.variants[0].label;

          return varName;
        });

        const deletedTempSku = temp_sku.filter(
          (tempData) => !allSkuName.includes(tempData.variant_name)
        );

        if (deletedTempSku.length > 0) {
          deletedTempSku.forEach((data) =>
            formattedSku.push({
              is_deleted: true,
              variants: [],
              default: 0,
              price: data.product_price,
              stock: data.product_stock,
              number: data.product_sku_mpn,
              image: data.product_image_url,
              image_id: data.product_image_id,
              status: data.product_status_id,
              product_sku_id: data.product_sku_id,
            })
          );
        }
      }
    }

    if (temp_sku && temp_sku.length === 1 && isEdit) {
      if (temp_sku[0].product_sku_id !== formattedSku[0].product_sku_id) {
        formattedSku.push({
          is_deleted: true,
          variants: [],
          default: 0,
          price: temp_sku[0].product_price,
          stock: temp_sku[0].product_stock,
          number: temp_sku[0].product_sku_mpn,
          image: temp_sku[0].product_image_url,
          image_id: temp_sku[0].product_image_id,
          status: temp_sku[0].product_status_id,
          product_sku_id: temp_sku[0].product_sku_id,
        });
      }
    }

    const isHaveZeroStatus = !isEmpty(
      formattedSku.find((item) => item.status === 0)
    );

    if (isHaveZeroStatus) {
      formattedSku = formattedSku.map((item) => {
        item.status = item.status === 0 ? 2 : item.status;

        return item;
      });
    }

    payload.sku = JSON.stringify(formattedSku);
    payload.stock = 0;
  }

  return payload;
};

export const mobileValidationEachFields = (values) => {
  const validation = [
    !values.tempProductName,
    values.productImages.length === 0,
    !values.tempProductCategories?.[0],
    (values.variant === 0 && !values.weight) ||
      !values.width ||
      !values.height ||
      !values.length,
    !isEmpty(values.sku) && values.sku.length > 0
      ? !mobileSkuValidationSchema(values.sku)
      : !values.stock || !values.price,
  ];

  return validation.every((item) => !item);
};
