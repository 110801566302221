import { Flex, Box, Text } from "renos-ui";
import ImgEmptyNotes from "assets/images/no-rating.png";

const EmptyList = ({ title, description }) => {
  return (
    <Flex
      gap="8px"
      justifyContent="center"
      alignItems="center"
      minHeight="500px"
    >
      <Flex flexDirection="column" alignItems="center" gap="24px">
        <Box
          as="img"
          src={ImgEmptyNotes}
          width="120px"
          height="120px"
          alt="Empty Data"
        />
        <Flex flexDirection="column" alignItems="center" gap="4px">
          <Text variant="heading3" weight="bold" color="black100">
            {title}
          </Text>
          <Flex width="244px" textAlign="center">
            <Text color="black75">
              {description}
            </Text>
          </Flex>

        </Flex>
      </Flex>
    </Flex>
  );
};

export default EmptyList;
