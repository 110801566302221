import { focusTo, setGTM } from "helpers";
import React from "react";
import { useHistory } from "react-router-dom";
import { Box, Button, colors, Flex, Icon, Text } from "renos-ui";
import { useCheckAuth, useCheckStore } from "services/auth";
import { NavLink } from "./styled";

const LandingHeader = ({ visible }) => {
  const { data: authData, isLoading: isAuthLoading } = useCheckAuth();
  const { data: storeData, isLoading: isStoreLoading } = useCheckStore({
    onError: (error) => {
      console.error(error);
    },
  });

  const onLoginClicked = () =>
    window.open(
      `${process.env.REACT_APP_BUYER_SITE}/login?referrer=${window.location.origin}`,
      "_self"
    );

  const onJoinClicked = () => {
    setGTM("seller_center_section", {
      event_category: "menu",
      event_action: "click",
      event_label: "start_selling",
    });

    if (authData?.status) {
      return history.push("/register");
    }

    return window.open(
      `${process.env.REACT_APP_BUYER_SITE}/register?referrer=${window.location.origin}`,
      "_self"
    );
  };

  const history = useHistory();

  const handleGoBack = () => {
    setGTM("seller_center_section", {
      event_category: "menu",
      event_action: "click",
      event_label: "back_to_renos",
    });

    window.location.href = process.env.REACT_APP_BUYER_SITE;
    localStorage.removeItem("storeInformation");
  };

  const renderComponent = () => {
    if (!isAuthLoading && !isStoreLoading) {
      if (authData?.status && storeData?.status) {
        window.location.pathname = "/";
      }
    }

    if (isAuthLoading || isStoreLoading) {
      return (
        <Flex
          position="absolute"
          height="100vh"
          width="100vw"
          justifyContent="center"
          alignItems="center"
          background="white"
          top={0}
          left={0}
          zIndex={1000}
        >
          Loading
        </Flex>
      );
    }

    const handleFocusWithGTM = (gtmParam, focusParam) => {
      setGTM("seller_center_section", {
        event_category: "menu",
        event_action: "click",
        event_label: gtmParam,
      });

      focusTo(focusParam);
    };

    return (
      <Flex
        width="100%"
        backgroundColor={visible && "white"}
        justifyContent="center"
        paddingY="30px"
        position="fixed"
        top={0}
        zIndex="9999"
        boxShadow={visible && "0 10px 20px rgb(0 0 0 / 10%)"}
      >
        <Box width="1200px">
          <Flex width="100%" alignItems="center" justifyContent="space-between">
            <Flex alignItems="center" columnGap="16px">
              <Flex
                width="24px"
                height="24px"
                border={`solid 1px ${colors.black50}`}
                borderRadius="100%"
                cursor="pointer"
                alignItems="center"
                justifyContent="center"
                onClick={() => handleGoBack()}
              >
                <Flex style={{ pointerEvents: "none" }}>
                  <Icon name="Left-outline" color="black50" size={16} />
                </Flex>
              </Flex>
              <Box as="img" src="/assets/images/seller-center.png" />
            </Flex>
            <Flex alignItems="center" columnGap="40px">
              <Flex alignItems="center" columnGap="32px">
                <NavLink
                  as="a"
                  onClick={() => handleFocusWithGTM("Tentang Renos", "about")}
                >
                  <Text variant="subtitle" color="black100">
                    Tentang Renos
                  </Text>
                </NavLink>
                <NavLink
                  as="a"
                  onClick={() =>
                    handleFocusWithGTM("Keuntungan Bergabung", "benefit")
                  }
                >
                  <Text variant="subtitle" color="black100">
                    Keuntungan Bergabung
                  </Text>
                </NavLink>
                <NavLink
                  as="a"
                  onClick={() =>
                    handleFocusWithGTM("Cara Berjualan", "tutorial")
                  }
                >
                  <Text variant="subtitle" color="black100">
                    Cara Berjualan
                  </Text>
                </NavLink>
                {/* <NavLink as="a" onClick={() => focusTo("about-us")}>
              <Text variant="subtitle" color="black100">
                Renosversity
              </Text>
            </NavLink> */}
              </Flex>
              <Flex alignItems="center" columnGap="16px">
                {!authData?.status && (
                  <Button
                    variant="secondary"
                    size="medium"
                    onClick={onLoginClicked}
                  >
                    Masuk
                  </Button>
                )}
                <Button size="medium" onClick={onJoinClicked}>
                  Mulai Berjualan
                </Button>
              </Flex>
            </Flex>
          </Flex>
        </Box>
      </Flex>
    );
  };

  return renderComponent();
};

export default LandingHeader;
