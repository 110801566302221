import { Box, Flex, Icon, Text } from "renos-ui";
import { StyledTextTitle } from "../styled";

const RatingItem = ({ image, title, rating, review }) => {
  const screenWidth = window.innerWidth;

  return (
    <Flex p="16px" gap="16px" backgroundColor="white">
      <Flex alignItems="center" gap="16px">
        <Box
          as="img"
          height="48px"
          width="48px"
          borderRadius="8px"
          alt="produt-image"
          src={encodeURI(image)}
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = "/assets/images/empty-product.svg";
          }}
        />
      </Flex>
      <Flex flexDirection="column" mr="52px" gap="4px">
        <Flex width={screenWidth / 1.45}>
          <StyledTextTitle variant="caption">{title}</StyledTextTitle>
        </Flex>
        <Flex flexDirection={"row"} gap="10px">
          <Flex gap="4px" alignItems="center">
            <Icon
              size={14}
              name="Star-solid"
              color={Math.floor(rating) <= 2 ? "red50" : "yellow50"}
            />
            <Text variant="caption" weight="bold">
              {rating}
            </Text>
          </Flex>
          <Text variant="caption" color="black50">
            |
          </Text>
          <Text variant="caption" weight="bold">
            {review} Ulasan
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default RatingItem;
