import { Box, Checkbox, Flex, Icon, Text } from "renos-ui";

export const isChecked = (id, data) => {
  const listOfId = data.map((product) => product.product_id);

  return listOfId.includes(id);
};

export const onCheckAllClicked = (list, data, setData, maxData) => {
  let tempCheckedProducts = data;

  if (tempCheckedProducts.length < maxData) {
    // eslint-disable-next-line
    const calculateChecked = list.map((products) => {
      if (
        tempCheckedProducts.length !== maxData &&
        !isChecked(products.product_id, data)
      ) {
        tempCheckedProducts.push(products);
      }
    });
  } else {
    tempCheckedProducts = [];
  }
  setData([...tempCheckedProducts]);
};

export const onCheckProductClicked = (dataProduct, data, setData) => {
  let tempCheckedProducts = data;

  if (isChecked(dataProduct.product_id, data)) {
    tempCheckedProducts = tempCheckedProducts.filter(
      (productFilter) => productFilter.product_id !== dataProduct.product_id
    );
  } else {
    tempCheckedProducts.push(dataProduct);
  }
  setData([...tempCheckedProducts]);
};

export const isCheckDisabled = (id, data, maxProduct) => {
  return data.length < maxProduct ? false : !isChecked(id, data);
};

export const dataSaveFormatter = (data) => {
  return data.map((productData) => {
    const { product_skus } = productData;

    if (productData.product_type === "variant") {
      productData.isHasMore = true;
      productData.product_skus = product_skus.map((product) => ({
        ...product,
        product_discount_amount: "",
        product_discount_percentage: "",
        product_discount_quota: "",
        product_discount_date_end: null,
        product_discount_date_start: null,
        isActive: true,
        isChecked: false,
      }));
    } else {
      productData.product_discount_amount_start = "";
      productData.product_discount_percentage_start = "";
      productData.product_discount_date_end = null;
      productData.product_discount_date_start = null;
      productData.product_discount_quota = "";
    }

    return productData;
  });
};

export const dataColumn = ({
  isCheckedAll,
  isIndeterminate,
  handleClickAll,
  maxProduct,
  checkedProducts,
  setCheckedProducts,
}) => {
  return [
    {
      title: (
        <Checkbox
          checked={isCheckedAll || isIndeterminate}
          onChange={handleClickAll}
          indeterminate={isIndeterminate}
        />
      ),
      dataIndex: "check",
      key: "checkBox",
      render: (_, data) => {
        const { product_id: id } = data;

        return (
          <Flex>
            <Checkbox
              checked={isChecked(id, checkedProducts)}
              onChange={() =>
                onCheckProductClicked(data, checkedProducts, setCheckedProducts)
              }
              disabled={isCheckDisabled(id, checkedProducts, maxProduct)}
            />
          </Flex>
        );
      },
      padding: "16px 8px 16px 0",
      align: "center",
      verticalAlign: "middle",
    },
    {
      title: "Informasi Produk",
      dataIndex: "product",
      key: "product",
      render: (_, data) => {
        const {
          product_image_url: image,
          product_name: name,
          product_sku_id: skuID,
          product_skus: variant,
          product_type,
          total_product_view: totalSeen,
          total_product_wishlist: totalWishlist,
          total_sold: totalSold,
        } = data;

        const totalVariant = variant.length > 5 ? "+5" : variant.length;

        return (
          <Flex gap="24px">
            <Flex alignItems="center" columnGap="8px">
              <Box
                as="img"
                src={image}
                alt="img-product"
                width="56px"
                height="56px"
                borderRadius="8px"
                backgroundSize="cover"
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = "/assets/images/empty-product.svg";
                }}
              />
              <Flex flexDirection="column" gap="8px" width="calc(100% - 56px)">
                <Flex flexDirection="column">
                  <Text weight="bold" lineClamp={1} variant="caption">
                    {name}
                  </Text>
                  <Text color="black50" variant="caption">
                    SKU: {skuID}
                  </Text>
                  <Flex justifyContent="space-between" width="180px">
                    <Flex gap="4px" alignItems="center">
                      <Icon name="Show-solid" size={16} color="black25" />
                      <Text color="black50" variant="caption">
                        {totalSeen}
                      </Text>
                    </Flex>
                    <Flex gap="4px" alignItems="center">
                      <Icon name="Love-solid" size={16} color="black25" />
                      <Text color="black50" variant="caption">
                        {totalWishlist}
                      </Text>
                    </Flex>
                    <Flex gap="4px" alignItems="center">
                      <Icon name="Cart-solid" size={16} color="black25" />
                      <Text color="black50" variant="caption">
                        {totalSold}
                      </Text>
                    </Flex>
                  </Flex>
                </Flex>
                {product_type === "variant" && (
                  <Flex
                    justifyContent="center"
                    alignItems="center"
                    backgroundColor="black5"
                    borderRadius="100px"
                    width="62px"
                    height="16px"
                  >
                    <Text color="black50" weight="bold" variant="small">
                      {totalVariant} varian
                    </Text>
                  </Flex>
                )}
              </Flex>
            </Flex>
          </Flex>
        );
      },
    },
    {
      title: "Harga",
      dataIndex: "formatted_product_price",
      key: "formatted_product_price",
      width: "30%",
      render: (_, data) => {
        const { formatted_product_price } = data;

        return <Text>{formatted_product_price}</Text>;
      },
    },
    {
      title: "Stok",
      dataIndex: "total_sold",
      key: "total_sold",
      width: "15%",
      render: (_, data) => {
        const { total_sold } = data;

        return <Text>{total_sold}</Text>;
      },
    },
  ];
};
