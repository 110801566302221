import MobileLayout from "layouts/MobileLayout";
import React from "react";
import { useHistory } from "react-router-dom";
import {
  Box,
  Flex,
  Text,
  Input,
  useToast,
  useUpload,
  Upload,
  colors,
  Spinner,
} from "renos-ui";
import { FormProvider } from "react-hook-form";
import { useState, useEffect, useMemo } from "react";
import {
  blobToBase64,
  imageTypeCheckingDocument,
  setGTM,
  imgCompressor,
} from "helpers";
import { modalText, IMAGE_FILE_TYPE } from "./../constant";
import mobileUrl from "constants/mobileUrl";
import { StyledButton } from "./../styled";
import { useGetDocument, useSaveNumber } from "services/settings/document";
import CustomHeader from "./components/CustomHeader";

const DocumentAdd = ({ location }) => {
  const detailDocument = location.state.data;
  const [value, setValue] = useState({});
  const [selectedCategory, setSelectCategory] = useState({});

  const pageOpen = detailDocument.id;
  const [isError, setIsError] = useState({
    ktp: false,
    siup: false,
    npwp: false,
  });
  const { push } = useHistory();
  const toast = useToast();
  const [selectedItem, setSelectedItem] = useState({});
  const [images, setImages] = useState({
    ktp: { raw: null, value: "", isImageChange: false },
    npwp: { raw: null, value: "", isImageChange: false },
    siup: { raw: null, value: "", isImageChange: false },
    bank_book: { raw: null, value: "", isImageChange: false },
  });

  useEffect(() => {
    if (location.state !== undefined) {
      setValue(location.state.data);
      setSelectCategory(location.state.data.id);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setSelectedItem({
      ...modalText[detailDocument.id],
      title: modalText[detailDocument.id].title,
      imgValue: images[detailDocument.id].value,
      category: detailDocument.id,
      uploadPhotoText: modalText[detailDocument.id].uploadPhotoText,
      inputPlacholder: modalText[detailDocument.id].inputPlacholder,
      inputName: modalText[detailDocument.id].inputName,
      inputLabel: modalText[detailDocument.id].inputLabel,
    });
    // eslint-disable-next-line
  }, []);

  const { register } = useUpload({
    fileType: "image",
    onRequest: async (file) => {
      const deepTypeChecking = await imageTypeCheckingDocument(file);

      if (deepTypeChecking) {
        const compressed =
          file.size > 200000
            ? await imgCompressor(file, {
                maxSizeMB: 0.1,
                maxWidthOrHeight: 1920,
                useWebWorker: true,
              })
            : file;

        const result = await blobToBase64(compressed);
        toast({
          label: "File berhasil diupload!",
          placement: "bottom",
        });

        const selectedCategory = selectedItem?.category;

        if (result.length === 0) {
          setImages({
            ...images,
            [selectedCategory]: { raw: null, value: "", isImageChange: true },
          });

          return;
        }

        setImages((prev) => ({
          ...prev,
          [selectedCategory]: {
            raw: result,
            value: compressed,
            isImageChange: true,
          },
        }));
      } else {
        toast({
          label:
            "Gagal upload file. Harap cek kembali jenis file yang akan kamu upload.",
          placement: "bottom",
          backgroundColor: "red50",
          clickLabel: "Oke",
        });
      }
    },
    validator: (file) => {
      if (!IMAGE_FILE_TYPE.includes(file.type)) {
        toast({
          label:
            "Gagal upload file. Harap cek kembali jenis file yang akan kamu upload.",
          placement: "bottom",
          backgroundColor: "red50",
          clickLabel: "Oke",
        });

        return {
          message: "Format gambar tidak sesuai",
        };
      }

      return null;
    },
  });

  const handleChangeValue = (e, category) => {
    const maxLengthKTP = 16;
    const maxLengthNPWP = 16;
    const maxLengthSIUP = 13;
    const maxLength =
      category === "ktp"
        ? maxLengthKTP
        : category === "npwp"
        ? maxLengthNPWP
        : maxLengthSIUP;
    setValue((prev) => ({
      ...prev,
      [e.target.name]: e.target.value
        .replace(/\D/g, "")
        .substring(0, maxLength),
      changed: true,
    }));
    setIsError({
      ...isError,
      [e.target.name]: false,
    });
  };
  // handle error dan save
  const {
    isLoading: isLoadingDocument,
    refetch,
    data: dataGetDocument,
  } = useGetDocument({
    onError: () => {
      toast({
        label: "Gagal mendapatkan dokumen.",
        placement: "bottom",
      });
    },
  });

  useEffect(() => {
    if (!isLoadingDocument) {
      setValue({
        ktp: dataGetDocument?.data?.ktp_number,
        npwp: dataGetDocument?.data?.npwp_number,
        siup: dataGetDocument?.data?.nib_number,
        changed: false,
      });
    }
    // eslint-disable-next-line
  }, [isLoadingDocument]);

  const { mutate: mutateSaveNumber, isLoading: isLoadingSave } = useSaveNumber({
    onSuccess: (data) => {
      const { message, frontend_message } = data.data;
      refetch();

      if (message === "Conflict") {
        toast({
          label: frontend_message || message,
          placement: "bottom",
          backgroundColor: "red50",
        });

        setValue({
          ktp: dataGetDocument?.data?.ktp_number,
          npwp: dataGetDocument?.data?.npwp_number,
          siup: dataGetDocument?.data?.nib_number,
        });
        push(mobileUrl.STORE_DOCUMENT);
      } else {
        toast({
          label: frontend_message || message,
          placement: "bottom",
        });
        setTimeout(() => {
          push(mobileUrl.STORE_DOCUMENT);
        }, 1000);
      }

      setImages({ ...images, [selectedCategory]: { raw: null, value: "" } });
    },
    onError: (data) => {
      const {
        response: {
          data: { frontend_message, message },
        },
      } = data;
      toast({
        label: frontend_message || message,
        placement: "bottom",
        backgroundColor: "red50",
      });
      setImages({ ...images, [selectedCategory]: { raw: null, value: "" } });
    },
  });

  const lengthValidator = {
    ktp: 16,
    npwp: 15,
    siup: 13,
  };

  const handleError = (pageOpen) => {
    const tempError = isError;
    let countError = 0;

    if (pageOpen === "npwp") {
      if (
        value?.[pageOpen]?.length < lengthValidator[pageOpen] ||
        value?.[pageOpen]?.length > 16
      ) {
        tempError[pageOpen] = true;
        countError++;
      }
    } else {
      if (value?.[pageOpen]?.length < lengthValidator[pageOpen]) {
        tempError[pageOpen] = true;
        countError++;
      }
    }
    setIsError({ ...tempError });

    if (countError !== 0) {
      toast({
        label: `${
          isError.ktp === true
            ? "Nomor KTP tidak boleh kurang dari 16 digit"
            : isError.npwp === true
            ? "Nomor NPWP tidak boleh kurang dari 15 atau 16 digit"
            : isError.siup === true
            ? "Nomor SIUP tidak boleh kurang dari 13 digit"
            : ""
        }`,
        placement: "bottom",
        backgroundColor: "red50",
      });

      return true;
    }

    return false;
  };

  const handleGTM = (label) => {
    setGTM("seller_center_section", {
      event_category: "store_settings | documents",
      event_action: "click",
      event_label: label,
    });
  };

  const handleSave = (event) => {
    handleGTM("edit_company_information");
    event.preventDefault();
    const isHasError = handleError(pageOpen);
    if (isHasError) return false;

    const keyType = {
      ktp: "ktp_number",
      npwp: "npwp_number",
      siup: "nib_number",
    };

    const fileUpload =
      images[selectedItem.category]?.isImageChange === true
        ? images[selectedCategory].raw.replace("data:", "").replace(/^.+,/, "")
        : images[selectedCategory].raw;

    const payload = {
      data: {
        image_base64: fileUpload,
        [keyType[selectedCategory]]: value[selectedCategory],
      },
    };

    mutateSaveNumber(payload);
  };

  const isErrorForm = useMemo(() => {
    switch (selectedItem.inputName) {
      case "ktp":
        return isError.ktp;
      case "siup":
        return isError.siup;
      case "npwp":
        return isError.npwp;
      default:
        return "";
    }
    // eslint-disable-next-line
  }, [isError]);

  const errorForm = useMemo(() => {
    switch (selectedItem.inputName) {
      case "ktp":
        return "KTP harus terdiri dari 16 digit";
      case "siup":
        return "SIUP harus terdiri dari 13 digit";
      case "npwp":
        return "NPWP harus terdiri 15 atau 16 digit";
      default:
        return "";
    }
  }, [selectedItem]);

  const isDisabled = useMemo(
    () =>
      (!value.changed && !images.imgChanged) ||
      isLoadingDocument ||
      value[selectedCategory].length === 0 ||
      images[selectedItem.category]?.raw === null ||
      isLoadingSave,

    [
      value,
      images,
      isLoadingDocument,
      selectedCategory,
      selectedItem,
      isLoadingSave,
    ]
  );

  return (
    <MobileLayout customHeader={<CustomHeader title={selectedItem.title} />}>
      <FormProvider>
        <Flex
          width="100%"
          flexDirection="column"
          flex={1}
          justifyContent="space-between"
        >
          <Flex
            flexDirection="column"
            rowGap="16px"
            height="calc(100% - 45px)"
            overflow="auto"
            p="16px"
          >
            <Flex flexDirection="column" rowGap="16px">
              <Flex
                borderColor="black10"
                borderStyle="solid"
                borderWidth="1px"
                borderRadius="8px"
                padding="8px"
                alignItems="center"
                justifyContent="space-between"
                border={`1px dashed ${colors.black50}`}
                height="124px"
                gap="8px"
              >
                <Flex columnGap="14px" alignItems="center">
                  <Box
                    alt="document-image"
                    as="img"
                    src={
                      images[selectedItem.category]?.raw === null ||
                      images[selectedItem.category]?.raw === undefined
                        ? "/assets/images/mobile/img-upload.svg"
                        : images[selectedItem.category]?.raw
                    }
                    width="40px"
                    height="40px"
                  />
                  <Flex flexDirection="column">
                    <Text weight="bold" variant="caption">
                      Upload File
                    </Text>
                    <Text color="black50" variant="caption" lineClamp={2}>
                      Format file .JPG, .JPEG, .PNG atau .PDF.
                    </Text>
                    <Upload {...register} accept=".jpg, .jpeg, .png, .pdf">
                      <Flex>
                        <Text
                          weight="bold"
                          variant="caption"
                          color="blue50"
                          width="160px"
                        >
                          {location.state.mode === "edit" ||
                          images[selectedItem.category]?.isImageChange === true
                            ? "Ubah File"
                            : "Pilih File"}
                        </Text>
                      </Flex>
                    </Upload>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
            <Flex flexDirection="column" rowGap="16px">
              <Text variant="caption" color="black50" weight="bold">
                {selectedItem.inputLabel}
              </Text>
              <Input
                name={selectedItem.inputName}
                placeholder={selectedItem.inputPlacholder}
                value={value[selectedCategory]}
                onChange={(e) => handleChangeValue(e, selectedItem.category)}
                error={errorForm}
                isError={isErrorForm}
              />
            </Flex>
          </Flex>

          <Flex
            bottom="0"
            width="100%"
            position="fixed"
            justifyContent="center"
            alignItems="center"
            boxShadow="0 0 8px 0 rgba(20, 20, 32, 0.05);"
          >
            <StyledButton
              isBlock
              disabled={isDisabled}
              onClick={handleSave}
              preffix={isLoadingSave && <Spinner size="16px" color="blue10" />}
            >
              Simpan
            </StyledButton>
          </Flex>
        </Flex>
      </FormProvider>
    </MobileLayout>
  );
};

export default DocumentAdd;
