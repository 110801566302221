import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
// import dayjs from "dayjs";
import {
  useGetReview,
  useGetDeleteReply,
  useGetListTemplate,
  useSaveReplyTemplate,
  useUpdateReplyTemplate,
} from "services/sales";
import { Box, Drawer, Flex, useDisclosure, useToast } from "renos-ui";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

import Filter from "./Filter";
import ReviewItem from "./ReviewItem";
import WriteReview from "./WriteReview";
import SkeletonReview from "./SkeletonReview";
import EmptyState from "../components/EmptyState";
import StoreContext from "providers/StoreProvider";
import ActionDrawer from "./components/ActionDrawer";
import { FilterRatingProvider } from "providers/FilterRatingReview";

const Review = () => {
  const toast = useToast();
  const { push } = useHistory();

  const observerElem = useRef(null);

  const formDisclosure = useDisclosure({ open: false });
  const filterDisclosure = useDisclosure({ open: false });
  const actionDisclosure = useDisclosure({ open: false });

  const {
    storeData: {
      data: { id: storeId },
    },
  } = useContext(StoreContext);

  const [items, setItems] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [replyForm, setReplyForm] = useState({});
  const [isFiltered, setIsFiltered] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [reObserveElement, setReObserveElement] = useState(0);

  const [queryParams, setQueryParams] = useState({
    page: 1,
    order_by: "created_date",
    ordering: "desc",
    replied: null,
    scores_in: null,
    store_ids: storeId,
    with_image: true,
    include_pagination: true,
    product_name_contains: "",
  });

  const {
    data: dataReview,
    isLoading,
    isError,
    isFetching,
    refetch,
  } = useGetReview(
    {
      params: {
        ...queryParams,
      },
    },
    {
      onError: (error) => {
        setIsFiltered(false);

        let result = {};
        const {
          response: { data },
        } = error;

        if (typeof data === "string") {
          result = { frontend_message: "Ada masalah dari server." };
        } else {
          result = data;
        }

        toast({
          label: result?.frontend_message,
          placement: "bottom",
          backgroundColor: "red50",
        });
      },
      onSuccess: ({ data, pagination }) => {
        setIsFiltered(false);

        if (pagination.current_page === 1) {
          setItems(data);
        } else {
          setItems((prevItems) => [...prevItems, ...data]);
        }
      },
    }
  );

  const { mutate: saveReply, isLoading: isLoadingSaveReply } =
    useSaveReplyTemplate({
      onError: (data) => {
        const {
          response: {
            data: { frontend_message, message },
          },
        } = data;
        toast({
          label: frontend_message || message,
          placement: "bottom",
          backgroundColor: "red50",
        });
      },
      onSuccess: () => {
        setQueryParams((prev) => ({
          ...prev,
          page: 1,
        }));

        if (queryParams.page === 1) {
          refetch();
        }
        setReObserveElement(1);
        formDisclosure.onClose();
        actionDisclosure.onClose();
        toast({
          label: "Balasan berhasil dikirim",
          placement: "bottom",
        });
      },
    });

  const { mutate: updateReply, isLoading: isLoadingUpdateReply } =
    useUpdateReplyTemplate({
      onError: (data) => {
        const {
          response: {
            data: { frontend_message, message },
          },
        } = data;
        toast({
          label: frontend_message || message,
          placement: "bottom",
          backgroundColor: "red50",
        });
      },
      onSuccess: () => {
        setQueryParams((prev) => ({
          ...prev,
          page: 1,
        }));

        if (queryParams.page === 1) {
          refetch();
        }
        setReObserveElement(1);
        formDisclosure.onClose();
        actionDisclosure.onClose();
        toast({
          label: "Balasan berhasil dikirim",
          placement: "bottom",
        });
      },
    });

  const { mutate: deleteReply } = useGetDeleteReply({
    onSuccess: () => {
      actionDisclosure.onClose();
      setQueryParams((prev) => ({
        ...prev,
        page: 1,
      }));

      if (queryParams.page === 1) {
        refetch();
      }
      setReObserveElement(1);
      toast({
        label: "Balasan berhasil dihapus",
        placement: "bottom",
      });
    },
    onError: (data) => {
      const {
        response: {
          data: { frontend_message, message },
        },
      } = data;
      toast({
        label: frontend_message || message || data?.response?.data,
        placement: "bottom",
        backgroundColor: "red50",
      });
    },
  });

  const { mutate: getListTemplate, isLoading: isLoadingTemplate } =
    useGetListTemplate({
      onError: (error) => {
        const {
          response: {
            data: { frontend_message: message },
          },
        } = error;
        toast({
          label: message | "Error get list template.",
          placement: "bottom",
          backgroundColor: "red50",
        });
      },
      onSuccess: ({ data }) => {
        setTemplates(data);
      },
    });

  const isLoadingDataReview = useMemo(
    () => isLoading && queryParams.page === 1,
    [isLoading, queryParams.page]
  );

  const isLoadingNextPage = useMemo(
    () => isFetching && queryParams.page > 1,
    [isFetching, queryParams.page]
  );

  const hasNextPage = useMemo(
    () =>
      dataReview?.pagination?.current_page !==
      dataReview?.pagination?.total_pages,
    // eslint-disable-next-line
    [items]
  );

  const handleObserver = useCallback(
    (entries) => {
      const [target] = entries;

      if (target.isIntersecting) {
        setQueryParams((prev) => ({
          ...prev,
          page: prev.page + 1,
        }));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hasNextPage]
  );

  useEffect(() => {
    const element = observerElem.current;

    setTimeout(() => {
      if (!element) setReObserveElement((prev) => prev + 1);
    }, 1000);

    if (element) {
      const option = { threshold: 0 };
      const observer = new IntersectionObserver(handleObserver, option);
      observer.observe(element);

      return () => observer.unobserve(element);
    }
  }, [hasNextPage, handleObserver, observerElem, reObserveElement]);

  useEffect(() => {
    setReObserveElement(1);
  }, [queryParams.scores, queryParams.replied]);

  const handleSaveReply = () => {
    const data = {
      primary: false,
      message: replyForm.tempReply,
      product_review_id: selectedItem?.id,
    };

    if (selectedItem?.messages?.length > 1) {
      data.id = selectedItem?.messages?.find(
        (message) => message.primary === false
      )?.id;
      updateReply({ data });
    } else {
      saveReply({ data });
    }
  };

  const toggleDrawerMenu = (item) => {
    setSelectedItem(item);
    actionDisclosure.onToggle();
  };

  const handleClickWriteReview = () => {
    if (selectedItem?.messages?.length > 1) {
      setReplyForm({
        tempReply: selectedItem?.messages?.find(
          (message) => message.primary === false
        )?.message,
      });
    } else {
      setReplyForm({});
    }

    getListTemplate({ params: { store_id: storeId } });
    formDisclosure.onToggle();
  };

  const handleOpenTransactionClick = () => {
    const orderNo = selectedItem?.order?.order_number;
    push(`/sales/orders/detail?no=${orderNo}`);
  };

  const isEditOrDelete = useMemo(() => {
    if (!selectedItem.messages || selectedItem?.messages?.length < 2)
      return false;
    // const secondMessageDate = dayjs(
    //   selectedItem?.messages?.find(message=>message.primary === false)?.created_date,
    //   "YYYY-MM-DD"
    // );
    // const MAX_DAYS_FOR_EDIT_OR_DELETE = 30;
    // const currentDate = dayjs();

    // const diffInDays = currentDate.diff(secondMessageDate, "day");

    // return diffInDays < MAX_DAYS_FOR_EDIT_OR_DELETE;
    return true;
  }, [selectedItem]);

  const loadComponent = useMemo(() => {
    if (isError) return <Flex />;
    if (isFetching && queryParams.page === 1 && !isFiltered)
      return <SkeletonReview itemShow={3} />;

    const isEmpty = items.length === 0 && !isFetching;
    const totalPage = dataReview?.pagination?.total_pages;
    const currentPage = dataReview?.pagination?.current_page;
    const isItemLoading = (isFetching && !isLoadingNextPage) || isFiltered;

    return (
      <Flex flexDirection="column">
        <Flex py="16px" gap="16px" flexDirection="column">
          <Filter filterDisclosure={filterDisclosure} />
        </Flex>
        {isItemLoading ? (
          <SkeletonReview itemShow={3} showFilter={false} />
        ) : (
          <Flex
            gap="16px"
            px="16px"
            pt="16px"
            flexDirection="column"
            pb={currentPage === totalPage ? "16px" : "0"}
            backgroundColor={dataReview?.data?.length > 0 && "black5"}
          >
            {isEmpty && <EmptyState title="Ulasan" />}
            {items?.map((item, index) => (
              <ReviewItem
                item={item}
                index={index}
                key={`review-${index}`}
                toggleDrawer={toggleDrawerMenu}
              />
            ))}
          </Flex>
        )}
        {hasNextPage && !isLoadingDataReview && !isEmpty && (
          <Box backgroundColor="white" ref={observerElem}>
            <SkeletonReview showFilter={false} />
          </Box>
        )}
      </Flex>
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isError,
    isFetching,
    items,
    dataReview,
    queryParams,
    hasNextPage,
    isLoadingDataReview,
    isLoadingNextPage,
  ]);

  return (
    <FilterRatingProvider
      value={{
        setItems,
        filter: queryParams,
        setFilter: setQueryParams,
        setIsFiltered,
      }}
    >
      <Flex
        gap="4px"
        justifyContent="center"
        flexDirection="column"
        borderBox="box-sizing"
      >
        {loadComponent}
      </Flex>

      <ActionDrawer
        isVisible={actionDisclosure.isOpen}
        toggle={actionDisclosure.onToggle}
        onDelete={() =>
          deleteReply({
            id: selectedItem?.messages?.find(
              (message) => message.primary === false
            )?.id,
          })
        }
        onClickReply={handleClickWriteReview}
        allowReply={selectedItem?.messages?.length === 1}
        onClickDetailTransaction={handleOpenTransactionClick}
        id={
          selectedItem?.messages?.length >= 2 &&
          selectedItem?.messages?.find((message) => message.primary === false)
            .id
        }
        allowEditAndDelete={
          selectedItem?.messages?.length >= 2 && isEditOrDelete
        }
      />
      <Drawer
        isFullPage
        padding="0"
        placement="bottom"
        overflow="hidden"
        isVisible={formDisclosure.isOpen}
        onClose={formDisclosure.onToggle}
      >
        <WriteReview
          item={replyForm}
          setItem={setReplyForm}
          templates={templates}
          selectedReview={selectedItem}
          handleSaveReply={handleSaveReply}
          isLoadingTemplate={isLoadingTemplate}
          isLoadingSaveReply={isLoadingSaveReply || isLoadingUpdateReply}
          toggleDrawer={formDisclosure.onToggle}
        />
      </Drawer>
    </FilterRatingProvider>
  );
};

export default Review;
