import { Flex, colors } from "renos-ui";
import styled from "styled-components";

const WrapperItems = styled(Flex)`
  padding: 8px;
  cursor: pointer;
  gap: 12px;
  align-items: center;
  border-radius: 10px;

  &:hover {
    background-color: ${colors.blue5};
    border-radius: 10px;
  }
`;

export { WrapperItems };