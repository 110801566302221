import { Divider, Flex, Icon, useDebounce } from "renos-ui";
import {
  StyledFlexInputSearch,
  StyledInputBorderLess,
  StyledSelectInput,
} from "./styled";
import { useMemo, useState } from "react";
import { useGetShowcase } from "services/promo-management";

const Filter = ({ setValue }) => {
  const [searchValue, setSearchValue] = useState("");
  const [tagValue, setTagValue] = useState({
    value: "all",
    label: "Semua Produk",
  });

  const { data, isLoading } = useGetShowcase();

  useDebounce(
    () => {
      const params = {
        keyword: searchValue,
      };

      params.tag_store_id = tagValue?.value === "all" ? "" : tagValue?.value;

      setValue(params);
    },
    [searchValue, tagValue],
    1000
  );

  const dataShowcase = useMemo(() => {
    const tempArray = data?.data
      ? data?.data.map((showcase) => ({
          value: showcase.tag_store_id,
          label: showcase.tag?.tag_name || "-",
        }))
      : [];

    return [
      {
        value: "all",
        label: "Semua Produk",
      },
    ].concat(tempArray);
  }, [data]);

  return (
    <Flex flexDirection="column" gap="16px" px="24px">
      <Flex gap="16px">
        <StyledFlexInputSearch>
          <StyledInputBorderLess
            size="small"
            value={searchValue}
            placeholder="Cari produk"
            onChange={(event) => setSearchValue(event.target.value)}
            prefix={<Icon size={15} name="Search-outline" color="black50" />}
          />
        </StyledFlexInputSearch>
        <StyledSelectInput
          options={dataShowcase}
          placeholder="Etalase"
          size="small"
          onChange={(event) => setTagValue(event)}
          value={tagValue}
          isDisabled={isLoading}
        />
      </Flex>
      <Divider />
    </Flex>
  );
};

export default Filter;
