import {
  Button,
  Box,
  Checkbox,
  Divider,
  Flex,
  Icon,
  Input,
  Text,
  isEmpty,
} from "renos-ui";
import Table from "widgets/desktop/TableShowcase";
import { StyledSelectInput } from "../styled";
import EmptyShowcase from "../EmptyShowcase";
import AddModalShowcase from "./AddModalShowcase";
import { ListSkeletonShowcase } from "../Skeleton";
import EmptyProduct from "../EmptyProduct";
import useAddShowcase from "./hooks/useAddShowCase";

const AddShowcase = ({
  titleMessage,
  setTitleMessage,
  addShowcaseDisclosure,
  refetchProductShowcase,
  tempTagId,
  setMode,
  mode,
}) => {
  const {
    tableColumns,
    handleBack,
    handleChangeTitle,
    handleSaveShowcase,
    handleOrderChange,
    handleFilterChange,
    isNotReady,
    isCheckedAll,
    onCheckAllClicked,
    handleModal,
    isLoadingSave,
    dataFilter,
    isLoadingDataProduct,
    dataSort,
    addDisclosure,
    paramsIds,
    isError,
    dataProduct,
    searchValue,
    setSearchValue,
    checkedProducts,
    setCheckedProducts,
    setParamsIds,
    setIsCheckedAll,
    handleDelete,
    isLoadingUpdate
  } = useAddShowcase({
    titleMessage,
    setTitleMessage,
    addShowcaseDisclosure,
    refetchProductShowcase,
    tempTagId,
    setMode,
    mode,
  });

  return (
    <Flex flexDirection="column" rowGap="24px" height="100%">
      <Flex justifyContent="space-between">
        <Flex gap="16px">
          <Icon
            name="Back-outline"
            size={28}
            cursor="pointer"
            onClick={handleBack}
          />
          <Text variant="heading3" weight="bold">
            {mode === "edit" ? "Edit" : "Tambah"} Etalase
          </Text>
        </Flex>
        {!isEmpty(paramsIds) && (
          <Flex width="180px">
            <Button
              isBlock
              size="large"
              disabled={isLoadingDataProduct || isLoadingSave || isLoadingUpdate}
              onClick={handleSaveShowcase}
            >
              Selesai
            </Button>
          </Flex>
        )}
      </Flex>
      <Box
        padding="16px 24px"
        borderRadius="16px"
        backgroundColor="white"
        height="20%"
      >
        <Flex flexDirection="column" gap="4px">
          <Text variant="caption" color="black50" weight="bold">
            Nama Etalase
          </Text>
          <Flex display="inline-flex">
            <Input
              placeholder="Tulis nama etalase toko sesuai kategori produkmu agar lebih mudah dicari"
              onChange={handleChangeTitle}
              maxLength={25}
              isError={isError}
              showCount
              error={"Nama Etalase belum dimasukan"}
              value={titleMessage}
            />
          </Flex>

          {!isError && (
            <Flex marginTop="-24px">
              <Text variant="caption" color="black50">
                Contoh: “Meja Belajar”
              </Text>
            </Flex>
          )}
        </Flex>
      </Box>

      <Flex
        padding="16px 24px"
        borderRadius="16px"
        backgroundColor="white"
        height="80%"
        justifyContent="center"
        alignItems="center"
      >
        <Flex
          flexDirection="column"
          alignItems="center"
          gap="24px"
          width="100%"
          height="100%"
        >
          {!isEmpty(paramsIds) || isLoadingDataProduct || isNotReady ? (
            <>
              <Flex width="100%" flexDirection="column" gap="24px">
                <Flex justifyContent="space-between" alignItems="center">
                  <Text variant="heading3" weight="bold">
                    Produk Terpilih
                  </Text>
                  <Button
                    type="button"
                    preffix={<Icon name="Plus-outline" />}
                    variant="secondary"
                    onClick={handleModal}
                    disabled={
                      isNotReady ||
                      isLoadingDataProduct ||
                      isLoadingSave ||
                      dataProduct?.data?.length === 0
                    }
                  >
                    Tambah Produk
                  </Button>
                </Flex>
                <Divider />
                <Flex gap="8px">
                  <Flex width="100%" height="48px" flexBasis="70%">
                    <Input
                      placeholder="Cari nama produk atau SKU"
                      prefix={<Icon name="Search-outline" size={20} />}
                      value={searchValue}
                      onChange={(event) => setSearchValue(event.target.value)}
                    />
                  </Flex>
                  <Flex flexBasis="20%" width="100%">
                    <StyledSelectInput
                      placeholder="Filter"
                      options={dataFilter}
                      onChange={(values) => handleFilterChange(values)}
                    />
                  </Flex>
                  <Flex flexBasis="20%" width="100%">
                    <StyledSelectInput
                      placeholder="Urutkan"
                      options={dataSort}
                      onChange={(name, values) => {
                        handleOrderChange(name, values);
                      }}
                    />
                  </Flex>
                </Flex>
              </Flex>
              {isNotReady || isLoadingDataProduct ? (
                <ListSkeletonShowcase />
              ) : isEmpty(dataProduct?.data) ? (
                <EmptyProduct />
              ) : (
                <Table
                  width="100%"
                  overflow="auto"
                  height="100%"
                  dataSource={dataProduct?.data}
                  columns={tableColumns}
                  customHeader={
                    checkedProducts.length > 0 && (
                      <Flex columnGap="20px" alignItems="center" pb="12px">
                        <Flex gap="15px" flexBasis="54%" alignItems="center">
                          <Checkbox
                            checked={isCheckedAll}
                            onChange={onCheckAllClicked}
                            indeterminate={
                              checkedProducts.length > 0 &&
                              checkedProducts.length < dataProduct?.data.length
                            }
                          />
                          
                          <Text weight="bold">
                            {`${checkedProducts.length} / ${dataProduct?.data.length} Produk Dipilih`}
                          </Text>

                          <Flex columnGap="16px">
                            <Button
                              variant="tertiary"
                              size="small"
                              onClick={() => {
                                setCheckedProducts([]);
                                setIsCheckedAll(false);
                              }}
                            >
                              Batal
                            </Button>
                            {checkedProducts.length > 0 && (
                              <Button
                                variant="tertiary"
                                size="small"
                                onClick={() => handleDelete(checkedProducts)}
                              >
                                Hapus
                              </Button>
                            )}
                          </Flex>
                        </Flex>

                        <Flex flexBasis="20%">
                          <Text>
                            <strong>Harga</strong>
                          </Text>
                        </Flex>

                        <Flex flexBasis="20%">
                          <Text>
                            <strong>Terjual</strong>
                          </Text>
                        </Flex>
                      </Flex>
                    )
                  }
                />
              )}
            </>
          ) : (
            <Flex flexDirection="column" height="100%" justifyContent="center">
              <Flex
                padding="16px 24px"
                borderRadius="16px"
                backgroundColor="white"
                height="100%"
                justifyContent="center"
                alignItems="center"
              >
                <EmptyShowcase
                  title="Tambahkan Produk"
                  description="pilih dan atur produk yang sesuai dengan etalase."
                  handleAdd={handleModal}
                />
              </Flex>
            </Flex>
          )}
        </Flex>
      </Flex>

      {addDisclosure.isOpen && (
        <AddModalShowcase
          addDisclosure={addDisclosure}
          isLoadingDataProduct={isLoadingDataProduct}
          isNotReady={isNotReady}
          paramsIds={paramsIds}
          checkedParent={
            isEmpty(paramsIds) ? [] : paramsIds.split(",").map(Number)
          }
          setMode={setMode}
          setParamsIds={setParamsIds}
        />
      )}
    </Flex>
  );
};

export default AddShowcase;
