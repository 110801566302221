import { useState } from "react";
import { useDisclosure, useToast } from "renos-ui";
import {
  useActivateSellerFleet,
  useDeactivateSellerFleet,
  useGetSellerFleet,
} from "services/logistics/sellerFleet";
import { setGTM } from "helpers";

const useToggle = () => {
  const toast = useToast();
  const showDisclosure = useDisclosure({});
  const [isGetSellerFleetError, setIsGetSellerFleetError] = useState(false);

  const handleGTM = (label) => {
    setGTM("seller_center_section", {
      event_category: "store_settings | delivery",
      event_action: "click",
      event_label: `delivery_service | ${label}`,
    });
  };

  const {
    data: dataGetSellerFleet,
    isLoading: isGetSellerFleetLoading,
    refetch: refetchGetSellerFleet,
  } = useGetSellerFleet({
    onError: (errorData) => {
      const {
        response: { status },
      } = errorData;

      if (status === 404) {
        setIsGetSellerFleetError(false);
      } else {
        setIsGetSellerFleetError(true);
      }
    },
  });

  const { refetch: refetchActivate } = useActivateSellerFleet({
    onSuccess: () => {
      handleGTM("enable_features");
      refetchGetSellerFleet();
      toast({
        label: "Kurir toko berhasil diaktifkan",
        placement: "bottom",
      });
    },
    onError: () => {
      toast({
        label: "Kurir toko gagal diaktifkan",
        backgroundColor: "red50",
        placement: "bottom",
      });
    },
  });

  const { refetch: refetchDeactivate } = useDeactivateSellerFleet({
    onSuccess: () => {
      handleGTM("turn_off");
      refetchGetSellerFleet();
      toast({ label: "Kurir toko berhasil dimatikan", placement: "top" });
    },
    onError: () => {
      toast({
        label: "Kurir toko gagal dimatikan",
        backgroundColor: "red50",
        placement: "bottom",
      });
    },
  });

  return {
    dataGetSellerFleet,
    isGetSellerFleetLoading,
    refetchGetSellerFleet,
    isGetSellerFleetError,
    refetchActivate,
    refetchDeactivate,
    setIsGetSellerFleetError,
    showDisclosure,
    handleGTM,
  };
};

export default useToggle;
