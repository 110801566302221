import React from "react";
import { Flex, Skeleton } from "renos-ui";

const SkeletonTemplate = () => {
  return (
    <Flex gap="16px" paddingY="16px" width="100%">
      <Flex>
        <Skeleton width="20px" height="20px" />
      </Flex>
      <Flex width="100%">
        <Skeleton width="100%" height="20px" />
      </Flex>
    </Flex>
  );
};

export default SkeletonTemplate;
