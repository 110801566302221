import CardVoucher from "containers/web/store-layout/DesignSetting/Menu/Voucher/CardVoucher";
import { abbreviationNum, currencyFormatter } from "helpers";
import StoreLayoutContext from "providers/StoreLayoutProvider";
import SwiperContext, { SwiperProvider } from "providers/SwiperProvider";
import { useCallback, useContext, useEffect, useState } from "react";
import { Flex, Icon, range, Text } from "renos-ui";
import { StyledSwiper } from "../Banner/styled";
import { SwiperSlide, useSwiper } from "swiper/react";

const Voucher = ({ isPreviewMode }) => {
  const { voucherRef, dataHomePage } = useContext(StoreLayoutContext);
  const [dataStoreFront, setDataStoreFront] = useState({});
  const [swiperInstance, setSwiperInstance] = useState();

  useEffect(() => {
    const selectedNewPrduct = dataHomePage.filter(
      (homepage) => homepage.id === 2
    )[0];
    setDataStoreFront(selectedNewPrduct);
  }, [dataHomePage]);

  if (isPreviewMode && !dataStoreFront?.isActive) return <></>;

  return (
    <Flex flexDirection="column" gap="8px" ref={voucherRef}>
      <Text variant="small" weight="bold">
        Voucher Toko
      </Text>

      {dataStoreFront?.isActive ? (
        dataStoreFront?.data.length > 3 ? (
          <SwiperProvider value={{ setSwiperInstance, swiperInstance }}>
            <Flex position="relative">
              <StyledSwiper slidesPerView={3} spaceBetween={8}>
                <Flex>
                  {dataStoreFront.data.map((voucher, indexVoucher) => (
                    <SwiperSlide key={`swiper-${indexVoucher}`}>
                      <CardVoucher
                        minPurchase={`Rp ${currencyFormatter(
                          voucher.minimum_transaction
                        )}`}
                        amount={
                          voucher.discount_type_id === 1
                            ? `${voucher.promo_percentage}%`
                            : `Rp ${abbreviationNum(voucher.promo_amount)}`
                        }
                        key={`card-voucher${indexVoucher}`}
                      />
                    </SwiperSlide>
                  ))}
                </Flex>
                <SwiperInstance />
              </StyledSwiper>
              <NavigationButton prev />
              <NavigationButton />
            </Flex>
          </SwiperProvider>
        ) : (
          <Flex gap="8px">
            {dataStoreFront.data.map((voucher, indexVoucher) => (
              <CardVoucher
                minPurchase={`Rp ${currencyFormatter(
                  voucher.minimum_transaction
                )}`}
                amount={
                  voucher.discount_type_id === 1
                    ? `${voucher.promo_percentage}%`
                    : `Rp ${abbreviationNum(voucher.promo_amount)}`
                }
                key={`card-voucher${indexVoucher}`}
              />
            ))}
          </Flex>
        )
      ) : (
        <Flex gap="8px">
          {range(3).map((_, indexEmpty) => (
            <Flex
              key={`empty-state${indexEmpty}`}
              height="72px"
              flexBasis={0}
              flexGrow={1}
              backgroundColor="black10"
              borderRadius="10px"
            />
          ))}
        </Flex>
      )}
    </Flex>
  );
};

const SwiperInstance = () => {
  const { setSwiperInstance } = useContext(SwiperContext);
  const swiper = useSwiper();

  useEffect(() => {
    if (swiper) return setSwiperInstance(swiper);
  }, [swiper, setSwiperInstance]);
};

const NavigationButton = ({ prev }) => {
  const { swiperInstance } = useContext(SwiperContext);

  const onPrevClicked = useCallback(() => {
    if (prev) return swiperInstance.slidePrev();

    swiperInstance.slideNext();
  }, [swiperInstance, prev]);

  return (
    <Flex
      width="20px"
      height="20px"
      position="absolute"
      left={prev && "-10px"}
      right={!prev && "-10px"}
      top="25px"
      backgroundColor={"white"}
      justifyContent="center"
      alignItems="center"
      borderRadius="50px"
      zIndex={1}
      onClick={onPrevClicked}
      cursor="pointer"
      boxShadow="0px 4px 4px rgba(20, 20, 32, 0.08)"
    >
      <Icon
        name={prev ? "Left-outline" : "Right-outline"}
        color="black50"
        size={14}
      />
    </Flex>
  );
};

export default Voucher;
