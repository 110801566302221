import React, { useMemo } from "react";
import { Flex, Text, isEmpty } from "renos-ui";
import { useHistory } from "react-router-dom";
import FormLayout from "./FormLayout";
import mobileUrl from "constants/mobileUrl";
import { useProductAddContext } from "store/productAdd/reducer";

const ProductSpesification = () => {
  const { push } = useHistory();

  const { state } = useProductAddContext();

  const {
    product_sku: productSku,
    stock,
    price,
    sku,
    product_active: productIsActive,
    uom,
    product_attributes,
  } = state;

  const isSku = useMemo(() => !isEmpty(sku) && sku.length > 0, [sku]);

  const isFilled = isSku || price > 0;

  const minSkuPrice = isSku && Math.min(...sku.map((data) => data.price));
  const maxSkuPrice = isSku && Math.max(...sku.map((data) => data.price));
  const skuStock =
    isSku && sku.reduce((acc, current) => acc + current.stock, 0);
  const minSkuWeight = isSku && Math.min(...sku.map((data) => data.weight));
  const maxSkuWeight = isSku && Math.max(...sku.map((data) => data.weight));
  const variantActive = isSku && sku.filter((data) => data.status === 1).length;

  const isProductAttributeEmpty = product_attributes.every((item) =>
    isEmpty(item.product_attribute_value)
  );

  return (
    <FormLayout
      title="Spesifikasi Produk"
      isFilled={isFilled}
      action={() => push(mobileUrl.PRODUCT_SPECIFICATION)}
    >
      {isFilled && (
        <>
          {isSku ? (
            <>
              <Flex flexDirection="column" rowGap="16px">
                <Flex flexDirection="column">
                  <Text variant="small" color="black50">
                    Harga
                  </Text>
                  <Text variant="caption" lineClamp={2}>
                    {minSkuPrice === maxSkuPrice
                      ? `Rp${minSkuPrice.toLocaleString("id-ID")}`
                      : `Rp${minSkuPrice.toLocaleString(
                          "id-ID"
                        )} - Rp${maxSkuPrice.toLocaleString("id-ID")}`}
                  </Text>
                </Flex>
              </Flex>
              <Flex flexDirection="column">
                <Text variant="small" color="black50">
                  Stok
                </Text>
                <Text variant="caption">
                  {skuStock.toLocaleString("id-ID")}
                </Text>
              </Flex>
              <Flex flexDirection="column">
                <Text variant="small" color="black50">
                  Berat
                </Text>
                <Text variant="caption">
                  {minSkuWeight === maxSkuWeight
                    ? `${minSkuWeight} ${uom === 1 ? "kg" : "g"}`
                    : `${minSkuWeight} - ${maxSkuWeight} ${
                        uom === 1 ? "kg" : "g"
                      }`}
                </Text>
              </Flex>
              <Flex flexDirection="column">
                <Text variant="small" color="black50">
                  Varian
                </Text>
                <Text variant="caption">
                  {`${variantActive}/${sku.length} Varian Aktif`}
                </Text>
              </Flex>
            </>
          ) : (
            <>
              <Flex flexDirection="column" rowGap="16px">
                <Flex flexDirection="column">
                  <Text variant="small" color="black50">
                    Harga
                  </Text>
                  <Text variant="caption" lineClamp={2}>
                    {`Rp${price.toLocaleString("id-ID")}`}
                  </Text>
                </Flex>
              </Flex>
              <Flex flexDirection="column">
                <Text variant="small" color="black50">
                  Stok
                </Text>
                <Text variant="caption">{stock.toLocaleString("id-ID")}</Text>
              </Flex>
              {!isEmpty(productSku) && (
                <Flex flexDirection="column">
                  <Text variant="small" color="black50">
                    SKU
                  </Text>
                  <Text variant="caption">{productSku}</Text>
                </Flex>
              )}
              <Flex flexDirection="column">
                <Text variant="small" color="black50">
                  Status
                </Text>
                <Text variant="caption" color="blue50">
                  {productIsActive ? "Aktif" : "Nonaktif"}
                </Text>
              </Flex>
              {!isProductAttributeEmpty && (
                <Flex flexDirection="column" rowGap="16px">
                  <Flex flexDirection="column">
                    <Text variant="small" color="black50">
                      Standarisasi Produk
                    </Text>
                    <Text variant="caption" lineClamp={2}>
                      No. SNI Terlampir
                    </Text>
                  </Flex>
                </Flex>
              )}
            </>
          )}
        </>
      )}
    </FormLayout>
  );
};

export default ProductSpesification;
