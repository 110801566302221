import { Flex, Skeleton } from "renos-ui";

const UploaderSkeleton = () => {
  return (
    <Flex flexDirection="column" gap="8px" width="100%">
      <Skeleton height="20px" width="300px" />
      <Skeleton height="160px" width="100%" />
      <Skeleton height="76px" width="100%" />
    </Flex>
  );
};

export default UploaderSkeleton;
