import React from "react";
import {
  Button,
  Flex,
  Checkbox,
  Text,
  DatePicker,
  Input,
  Icon,
  Box,
} from "renos-ui";
import dayjs from "dayjs";

const OpenStore = (props) => {
  const {
    datas,
    setDatas,
    openDialog,
    checkedClose,
    seCheckedClose,
    isEdit,
    setIsEdit,
    submitUpdate,
  } = props;

  const toggleEdit = () => {
    setIsEdit(false);
  };

  return (
    <>
      <Flex flexDirection="column" gap="24px">
        <Flex padding="16px" gap="24px" flexDirection="column">
          <Flex gap="10px" alignItems="center">
            <Checkbox
              disabled={isEdit}
              checked={checkedClose}
              onChange={() => {
                seCheckedClose(!checkedClose);
                setDatas({
                  ...datas,
                  date_start: new Date(),
                });
              }}
            />
            <Text variant="caption">Tutup Sekarang</Text>
          </Flex>
          <Flex flexDirection="column" gap="8px" flex="1">
            <Text color="black50" variant="caption" weight="bold">
              Mulai Tutup
            </Text>
            <DatePicker
              onChange={(startDate) => {
                setDatas({
                  ...datas,
                  date_start: startDate,
                  date_end: startDate,
                });
              }}
              minDate={new Date()}
              customInput={
                <Box>
                  <Input
                    placeholder="Mulai Tanggal"
                    size="small"
                    readOnly={true}
                    disabled={isEdit}
                    required={true}
                    sufix={<Icon name="Calendar-solid" size={20} />}
                    value={
                      datas.date_start
                        ? dayjs(datas.date_start).format("D MMM YYYY")
                        : ""
                    }
                  />
                </Box>
              }
            />
          </Flex>
          <Flex flexDirection="column" gap="8px" flex="1">
            <Text color="black50" variant="caption" weight="bold">
              Tutup Hingga
            </Text>
            <DatePicker
              onChange={(endDate) => {
                setDatas({
                  ...datas,
                  date_end: endDate,
                });
              }}
              
              minDate={new Date(datas.date_start)}
              customInput={
                <Box>
                  <Input
                    placeholder="Tanggal Berakhir"
                    size="small"
                    readOnly={true}
                    disabled={isEdit}
                    required={true}
                    sufix={<Icon name="Calendar-solid" size={20} />}
                    value={
                      datas.date_end
                        ? dayjs(datas.date_end).format("D MMM YYYY")
                        : ""
                    }
                  />
                </Box>
              }
            />
          </Flex>
          <Flex flexDirection="column" gap="8px">
            <Text color="black50" variant="caption" weight="bold">
              Catatan
            </Text>
            <Input.TextArea
              value={datas.notes}
              disabled={isEdit}
              onChange={(event) =>
                setDatas({
                  ...datas,
                  notes: event.currentTarget.value,
                })
              }
              placeholder="Masukkan alasan toko tutup."
            />
          </Flex>
        </Flex>

        <Flex
          bottom="0"
          width="100%"
          position="fixed"
          justifyContent="center"
          alignItems="center"
          backgroundColor="white"
          boxShadow="0px 0px 8px 0px rgba(20, 20, 32, 0.05);"
        >
          <Flex width="100%" padding="16px">
            {isEdit ? (
              <Button
                size="medium"
                onClick={datas?.is_closed ? openDialog : toggleEdit}
                isBlock
              >
                <Text weight="bold" variant="caption">
                  {datas?.is_closed ? "Buka Toko" : "Ubah jadwal tutup toko"}
                </Text>
              </Button>
            ) : (
              <Button size="medium" onClick={submitUpdate} isBlock>
                <Text weight="bold" variant="caption">
                  Simpan
                </Text>
              </Button>
            )}
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};

export default OpenStore;
