import React, { useRef, useEffect, useMemo } from "react";
import { dateWithDayFormat } from "helpers";
import {
  Flex,
  Text,
  Input,
  Icon,
  Box,
  Spinner,
  isEmpty,
  useDisclosure,
} from "renos-ui";
import { useGetQuickChat } from "services/chat";
import { QISCUS } from "hooks/qiscus";
import useViewport from "containers/web/chat/chat-room/useViewPort";
import CardProduct from "./components/CardProduct";
import { FlexOverflowWrap } from "./styled";
import DrawerAttachment from "./components/DrawerAttachment";
import DrawerChatTemplate from "./components/DrawerChatTemplate";
import DrawerAttachProduct from "./components/DrawerAttachProduct";
import { useHistory } from "react-router-dom";
import mobileUrl from "constants/mobileUrl";

const ChatRoomBody = ({
  loadBefore,
  messageList,
  qiscusInfo,
  getTime,
  textMessage,
  setTextMessage,
  sendMessage,
  refChat,
  handleLoadMessage,
  attachment,
  setAttachment,
  selectedUser,
  registerUpload,
  attachmentDisclosure,
  attachProductDisclosure,
  seletedAttachProduct,
  setSelectedAttachProduct,
  handleAttachProduct,
  locationState,
  buyerInfo,
}) => {
  const chatTemplateDisclosure = useDisclosure({ isOpen: false });
  const refTop = useRef(null);
  const TopViewport = useViewport(refTop);
  const { data: quickChat } = useGetQuickChat();
  const { push } = useHistory();

  useEffect(() => {
    if (TopViewport) handleLoadMessage();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [TopViewport]);

  const handleTemplateClick = (template) => {
    setTextMessage(template.template_message);
    QISCUS.publishTyping(1);
    chatTemplateDisclosure.onClose();
  };

  const handleKeyPress = (event) => {
    event.key === "Enter" && sendMessage();
    chatTemplateDisclosure.onClose();
  };

  const handleClickSend = () => {
    sendMessage();
    chatTemplateDisclosure.onClose();
  };

  const isShowAttachButton = useMemo(() => {
    return (
      isEmpty(attachment) ||
      attachment.qiscusId?.toString() !== selectedUser?.id?.toString()
    );
  }, [attachment, selectedUser]);

  const isShowListAttachment = useMemo(() => {
    return (
      !isEmpty(attachment) &&
      attachment.qiscusId?.toString() === selectedUser?.id?.toString()
    );
  }, [attachment, selectedUser]);

  const toggleProductMenu = () => {
    if (attachProductDisclosure.isOpen) {
      attachProductDisclosure.onClose();
      attachmentDisclosure.onOpen();
    } else {
      attachmentDisclosure.onClose();
      attachProductDisclosure.onOpen();
    }
  };

  const handleRemoveProductAttachment = (id) => {
    const tempAttachment = { ...attachment };
    const selectedProducts = tempAttachment?.payload?.content?.selectedProducts;

    const index = selectedProducts.findIndex(
      (product) => product.product_id === id
    );

    if (index > -1) {
      selectedProducts.splice(index, 1);
    }

    if (selectedProducts.length === 0) {
      setAttachment({});
    } else {
      setAttachment(tempAttachment);
    }
  };

  const getTimeStatus = (userChat) => {
    return (
      <>
        <Text variant="small" color="black50">
          {getTime(userChat.timestamp)}
        </Text>
        {userChat.status === "read" && (
          <Icon name="DoubleCheck-outline" size={12} color="blue50" />
        )}
        {userChat.status === "delivered" && (
          <Icon name="DoubleCheck-outline" size={12} color="black100" />
        )}
        {userChat.status === "sent" && (
          <Icon name="Checkmark-outline" size={12} />
        )}
        {userChat.status === "pending" && (
          <Icon name="Time-outline" size={12} />
        )}
      </>
    );
  };

  const hadleClickAttachOrder = () => {
    const userId = buyerInfo?.user_id || "";
    push({
      pathname: mobileUrl.CHAT_ORDER,
      search: `?user_id=${userId}`,
      state: locationState,
    });
  };

  return (
    <Flex
      width="100%"
      flexDirection="column"
      height="100%"
      position="relative"
      overflow="none"
    >
      {loadBefore && (
        <Flex justifyContent="center" alignItems="center" marginTop="24px">
          <Spinner size="18px" emptyColor="blue10" />
        </Flex>
      )}
      <Flex flexDirection="column" height="100%" overflowY="auto">
        <Flex
          flexDirection="column"
          padding="16px"
          gap="16px"
          ref={refChat}
          width={window.innerWidth - 32}
        >
          <Box ref={refTop} />

          {messageList?.formated?.map((chat, messageIndex) =>
            chat?.map((newChat, chatIndex) =>
              chatIndex === 0 ? (
                <Flex
                  justifyContent="center"
                  key={`${messageIndex}-${chatIndex}`}
                >
                  <Text variant="caption" color="black50">
                    {dateWithDayFormat(newChat, true)}
                  </Text>
                </Flex>
              ) : (
                newChat?.map((userChat, newChatIndex) =>
                  Number(userChat.email) !== Number(qiscusInfo.userId) ? (
                    <Flex
                      flexDirection="column"
                      gap="16px"
                      id={userChat.id}
                      key={`${userChat.id}-${newChatIndex}`}
                    >
                      {!isEmpty(userChat.payload) && (
                        <CardProduct item={userChat.payload} />
                      )}
                      <Flex alignSelf="flex-start" gap="8px">
                        {userChat.message && (
                          <FlexOverflowWrap
                            border="1px solid"
                            borderColor="black10"
                            padding="12px 16px"
                            width="fit-content"
                            borderRadius="16px"
                            borderTopLeftRadius="0"
                          >
                            <Text variant="caption">{userChat.message}</Text>
                          </FlexOverflowWrap>
                        )}
                        {(!isEmpty(userChat.payload) || userChat.message) && (
                          <Flex alignItems="flex-end" gap="8px">
                            <Text variant="small" color="black50">
                              {getTime(userChat.timestamp)}
                            </Text>
                          </Flex>
                        )}
                      </Flex>
                    </Flex>
                  ) : (
                    <Flex
                      flexDirection="column"
                      gap="16px"
                      id={userChat.id}
                      key={`${userChat.id}-${newChatIndex}`}
                    >
                      <Flex
                        alignSelf="flex-end"
                        gap="8px"
                        key={`newChat-${newChatIndex}`}
                        id={userChat.id}
                      >
                        <Flex
                          flexDirection="column"
                          alignItems="flex-end"
                          gap="8px"
                        >
                          {!isEmpty(userChat.payload) && (
                            <>
                              <CardProduct
                                item={userChat.payload}
                                placement="right"
                              />
                              <Flex gap="8px">
                                {!userChat.message && getTimeStatus(userChat)}
                              </Flex>
                            </>
                          )}

                          {!isEmpty(userChat.message) && (
                            <Flex gap="8px" alignItems="flex-end">
                              {getTimeStatus(userChat)}
                              <FlexOverflowWrap
                                width="fit-content"
                                backgroundColor="black5"
                                padding="12px 16px"
                                borderRadius="16px"
                                borderTopRightRadius="0"
                              >
                                <Text
                                  variant="caption"
                                  lineClamp="100"
                                  style={{ whiteSpace: "pre-line" }}
                                >
                                  {userChat.message}
                                </Text>
                              </FlexOverflowWrap>
                            </Flex>
                          )}
                        </Flex>
                      </Flex>
                    </Flex>
                  )
                )
              )
            )
          )}
        </Flex>
      </Flex>
      <Flex gap="12px" position="sticky" bottom="0" flex="1">
        <Flex flexDirection="column" gap="8px" flexGrow="1">
          {isShowListAttachment && (
            <Flex
              borderBottomWidth="1px"
              borderTopWidth="1px"
              borderColor="black10"
              borderTopStyle="solid"
              borderBottomStyle="solid"
              padding="12px"
              gap="8px"
              maxWidth="calc(100vw - 24px)"
              overflow="auto"
            >
              <CardProduct
                isAttachment
                removeAttachment={handleRemoveProductAttachment}
                item={attachment.payload}
              />
            </Flex>
          )}
          <Flex gap="12px" flexGrow="1" padding="12px" alignContent="center">
            <Flex flexGrow="1" cursor="pointer">
              <Flex
                px="12px"
                width="100%"
                borderWidth="1px"
                borderRadius="8px"
                borderStyle="solid"
                alignItems="center"
                borderColor="black10"
              >
                <Flex onClick={chatTemplateDisclosure.onOpen}>
                  <Icon name="Chat-solid" color="black50" size={20} />
                </Flex>
                <Input
                  noBorder
                  value={textMessage}
                  placeholder="Tulis pesanmu ..."
                  onKeyPress={handleKeyPress}
                  onChange={(e) => setTextMessage(e.target.value)}
                />
                {isShowAttachButton && (
                  <Flex onClick={attachmentDisclosure.onOpen}>
                    <Icon name="Plus-solid" color="black50" size={20} />
                  </Flex>
                )}
              </Flex>
            </Flex>
            <Flex>
              <Icon
                name="Send-solid"
                size={40}
                color="blue50"
                cursor="pointer"
                onClick={handleClickSend}
              />
            </Flex>
          </Flex>
        </Flex>
      </Flex>

      <DrawerChatTemplate
        disclosure={chatTemplateDisclosure}
        quickChat={quickChat}
        handleTemplateClick={handleTemplateClick}
      />
      <DrawerAttachment
        onClickOrder={hadleClickAttachOrder}
        onClickProduct={toggleProductMenu}
        disclosure={attachmentDisclosure}
        registerUpload={registerUpload}
      />
      {attachProductDisclosure.isOpen && (
        <DrawerAttachProduct
          onClose={toggleProductMenu}
          disclosure={attachProductDisclosure}
          selectedAttachProduct={seletedAttachProduct}
          setSelectedAttachProduct={setSelectedAttachProduct}
          handleAttachProduct={handleAttachProduct}
        />
      )}
    </Flex>
  );
};

export default ChatRoomBody;
