import React from "react";
import { Box, Flex, Icon, Text, Tooltip } from "renos-ui";
import PropTypes from "prop-types";
import { StyledTable } from "./styled";

const Table = ({ customHeader, columns, dataSource, ...props }) => {
  const renderTableHeader = () => {
    if (customHeader) {
      return (
        <Box
          as="th"
          border="0"
          borderBottom="1px"
          borderStyle="solid"
          borderColor="black10"
          colSpan={columns.length}
        >
          {customHeader}
        </Box>
      );
    }

    return columns.map((column, i) => (
      <Box
        as="th"
        pt="4px"
        pb="20px"
        border="0"
        borderBottom="1px"
        borderStyle="solid"
        borderColor="black10"
        textAlign={column.align || "left"}
        key={`table-header-${i}`}
        width={column.width || "auto"}
      >
        {typeof column.title === "string" ? (
          <Flex gap="4px">
            <Text color="black50" weight="bold">
              {column.title}
            </Text>
            {column.isTooltip === true && (
              <Tooltip
                width={120}
                label={column.tooltip}
                textAlign="center"
                icon={<Icon name="Info-solid" size={16} color="black50" />}
              />
            )}
          </Flex>
        ) : (
          column.title
        )}
      </Box>
    ));
  };

  return (
    <Box {...props}>
      <StyledTable>
        <Box as="thead">
          <Box as="tr">{renderTableHeader()}</Box>
        </Box>
        <Box as="tbody">
          {dataSource.map((data, rowIndex) => {
            return (
              <tr key={`table-row-${rowIndex}`}>
                {columns.map((column, dataIndex) => {
                  return (
                    <Box
                      as="td"
                      key={`table-data-${rowIndex}-${dataIndex}`}
                      width={column.width || "auto"}
                      p="16px 32px 16px 0px"
                      border="0"
                      borderBottom="1px"
                      borderStyle="solid"
                      borderColor="black10"
                      textAlign="left"
                      verticalAlign="top"
                    >
                      {column.render?.(
                        dataIndex,
                        data,
                        false,
                        data.product_type
                      ) || data[column.dataIndex]}
                    </Box>
                  );
                })}
              </tr>
            );
          })}
        </Box>
      </StyledTable>
    </Box>
  );
};

Table.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
      dataIndex: PropTypes.string,
      key: PropTypes.string,
      render: PropTypes.func,
      align: PropTypes.string,
      width: PropTypes.string,
    })
  ),
  dataSource: PropTypes.arrayOf(PropTypes.object),
  customHeader: PropTypes.node,
};

Table.defaultProps = {
  columns: [],
  dataSource: [],
};

export default Table;
