import React from "react";
import { Flex, Text } from "renos-ui";

const Total = ({
  checkedAutomate,
  data,
  changeDefault,
  handleSetDefault,
  handleCancelDefault,
}) => {
  return (
    <Flex justifyContent="space-between" alignItems="center" p="16px" gap="6px">
      <Flex gap="6px" alignItems="center">
        <Text variant="caption" weight="bold">
          {data.length === 0 ? "List" : data.length}
        </Text>
        <Text variant="caption" weight={data.length === 0 ? "bold" : ""}>
          Template
        </Text>
      </Flex>
      {data.length > 1 && (
        <Flex
          onClick={
            checkedAutomate === false ? (
              <></>
            ) : !changeDefault ? (
              handleSetDefault
            ) : (
              handleCancelDefault
            )
          }
        >
          <Text
            weight="bold"
            variant="caption"
            color={checkedAutomate === true ? "blue50" : "black25"}
          >
            {changeDefault === true ? "Batalkan" : "Pilih Template"}
          </Text>
        </Flex>
      )}
    </Flex>
  );
};

export default Total;
