import React from "react";
import {
  Text,
  Flex,
  Icon,
  colors,
  Grid,
  Drawer,
  Divider,
  useDisclosure,
  isEmpty,
  Box,
} from "renos-ui";
import MobileLayout from "layouts/MobileLayout";
import { useDeliveryContext } from "store/seller-fleet/reducer";
import { useHistory } from "react-router-dom";
import FreeOngkir from "assets/images/free-ongkir.png";
import { currencyFormatter } from "helpers";

const ListSettingDetail = () => {
  const { state } = useDeliveryContext();
  const showDisclosure = useDisclosure({});
  const history = useHistory();
  const {
    listDetail: {
      coverage_detail,
      coverage_summary,
      estimated_shipping_days,
      pricing,
      advanced_option_pricing: { constraints: advancedOption = [] },
    },
  } = state;

  if (isEmpty(state.listDetail)) {
    return history.push("/account/list-setting");
  }

  return (
    <MobileLayout
      header={{
        title: "Detail Pengaturan",
        withBack: true,
      }}
    >
      <Flex flexDirection="column" gap="16px" margin="16px" flex="1">
        <Flex
          padding="16px"
          gap="16px"
          flexDirection="column"
          borderRadius="8px"
          background={`${colors.black5}`}
        >
          <Flex justifyContent="space-between" alignItems="center">
            <Flex flexDirection="column" gap="4px" flexGrow="1">
              <Text variant="caption" color="black50">
                Cara Pengaturan
              </Text>
              <Text variant="caption" color="black75" weight="bold">
                {advancedOption?.length > 0 ? "Opsi Lanjutan" : pricing.label}
              </Text>
            </Flex>
          </Flex>

          <Grid gridTemplateColumns="50% 50%">
            <Flex flexDirection="column" gap="4px" flexGrow="1">
              <Text variant="caption" color="black50">
                Cakupan Wilayah
              </Text>
              <Text
                variant="caption"
                color="black75"
                weight="bold"
                lineClamp={1}
              >
                {coverage_summary}
              </Text>
            </Flex>
            <Flex flexDirection="column" gap="4px" flexGrow="1">
              <Text variant="caption" color="black50">
                Estimasi Tiba
              </Text>
              <Text
                variant="caption"
                color="black75"
                weight="bold"
                lineClamp={1}
              >
                {estimated_shipping_days} hari kerja
              </Text>
            </Flex>
          </Grid>
          <Divider />
          <Flex
            justifyContent="space-between"
            alignItems="center"
            onClick={() => showDisclosure.onOpen()}
          >
            <Text weight="bold" variant="caption" color="blue50">
              Lihat Detail
            </Text>
            <Icon cursor="pointer" name="Right-outline" />
          </Flex>
        </Flex>

        {advancedOption?.length > 0
          ? advancedOption.map((advanced, index) => (
              <Flex
                flexDirection="column"
                gap="8px"
                key={`advanced-description-${index}`}
              >
                <Text variant="body" color="black100" weight="bold">
                  Kondisi {index + 1}
                </Text>

                <Text variant="caption" color="black50">
                  {advanced.condition} {advanced.condition_weight} g
                </Text>
                {advanced.condition_base_id === 1 && (
                  <Flex justifyContent="space-between" gap="8px">
                    <Flex flexDirection="column" gap="4px">
                      <Text
                        variant="caption"
                        color="black50"
                        style={{ display: "none" }}
                      >
                        Kelipatan Berat
                      </Text>
                      <Text variant="caption" color="black75" weight="bold">
                        {advanced.condition_base_weight_value} g
                      </Text>
                    </Flex>
                    <Flex flexDirection="column" gap="4px">
                      <Flex gap="8px">
                        <Text variant="caption" weight="bold">
                          Rp {currencyFormatter(advanced.condition_base_tarif)}
                        </Text>
                        {currencyFormatter(advanced.condition_base_tarif) ===
                          "0" && (
                          <Box as="img" src={FreeOngkir} alt="free-ogkir" />
                        )}
                      </Flex>
                    </Flex>
                  </Flex>
                )}
                {advanced.condition_base_id === 2 && (
                  <Flex justifyContent="space-between" gap="8px">
                    <Flex flexDirection="column" gap="4px">
                      <Text
                        variant="caption"
                        color="black50"
                        style={{ display: "none" }}
                      >
                        Rentang Berat
                      </Text>
                      <Text variant="caption" weight="bold">
                        {`${advanced.condition_base_weight_start} - ${advanced.condition_base_weight_end}`}{" "}
                        g
                      </Text>
                    </Flex>
                    <Flex flexDirection="column" gap="4px">
                      <Flex gap="8px">
                        <Text variant="caption" weight="bold">
                          Rp {currencyFormatter(advanced.condition_base_tarif)}
                        </Text>
                        {currencyFormatter(advanced.condition_base_tarif) ===
                          "0" && (
                          <Box as="img" src={FreeOngkir} alt="free-ogkir" />
                        )}
                      </Flex>
                    </Flex>
                  </Flex>
                )}
                {index !== advancedOption.length - 1 && <Divider mt={16} />}
              </Flex>
            ))
          : !isEmpty(pricing) &&
            pricing?.constraints?.map((row, index) => (
              <Flex flexDirection="column" gap="8px" key={index}>
                <Text color="black25" variant="caption">
                  Rentang Berat
                </Text>
                <Flex justifyContent="space-between" alignItems="center">
                  <Text variant="caption" weight="bold">
                    {row.weight_value}
                  </Text>
                  <Flex gap="8px">
                    <Text variant="caption" weight="bold">
                      {row.shipping_cost}
                    </Text>
                    {currencyFormatter(row.shipping_cost) === "0" && (
                      <Box as="img" src={FreeOngkir} alt="free-ogkir" />
                    )}
                  </Flex>
                </Flex>
                <Divider />
              </Flex>
            ))}
      </Flex>

      <Drawer
        isVisible={showDisclosure.isOpen}
        placement="bottom"
        maxHeight="75vh"
        closable={true}
        onClose={() => showDisclosure.onClose()}
      >
        <Flex margin="16px 0">
          <Text weight="bold">Daftar Cakupan Wilayah</Text>
        </Flex>
        {coverage_detail.map((row, indexDetail) => (
          <Flex
            padding="16px"
            gap="16px"
            flexDirection="column"
            borderRadius="8px"
            marginBottom="16px"
            background={`${colors.black5}`}
          >
            <Grid gridTemplateColumns="50% 50%">
              <Flex justifyContent="space-between" alignItems="center">
                <Flex flexDirection="column" gap="4px" flexGrow="1">
                  <Text variant="caption" color="black50">
                    Provinsi
                  </Text>
                  <Text variant="caption" color="black75" weight="bold">
                    {row.province}
                  </Text>
                </Flex>
              </Flex>
              <Flex flexDirection="column" gap="4px" flexGrow="1">
                <Text variant="caption" color="black50">
                  Estimasi Tiba
                </Text>
                <Text variant="caption" color="black75" weight="bold">
                  {row.estimated_shipping_days} hari kerja
                </Text>
              </Flex>
            </Grid>

            <Flex flexDirection="column" gap="4px" flexGrow="1">
              <Text variant="caption" color="black50">
                Kota/Kabupaten
              </Text>
              <Text variant="caption" color="black75" weight="bold">
                {row.cities || "-"}
              </Text>
            </Flex>

            <Flex flexDirection="column" gap="4px" flexGrow="1">
              <Text variant="caption" color="black50">
                Kecamatan
              </Text>
              <Text variant="caption" color="black75" weight="bold">
                {row.districts || "-"}
              </Text>
            </Flex>
          </Flex>
        ))}
      </Drawer>
    </MobileLayout>
  );
};

export default ListSettingDetail;
