import { Flex, Skeleton, Text, range } from "renos-ui";

const Loading = () => {
  return (
    <Flex flexDirection="column" gap="24px" width="584px">
      <Flex flexDirection="column" gap="16px">
        <Text variant="caption" color="black50" weight="bold">
          Jenis Voucher
        </Text>
        <Flex gap="24px">
          {range(2).map((_, indexSkeleton) => (
            <Skeleton
              key={`skeleton-type-${indexSkeleton}`}
              width="280px"
              height="110px"
            />
          ))}
        </Flex>
      </Flex>
      <Flex flexDirection="column" gap="16px">
        <Text variant="caption" color="black50" weight="bold">
          Target Voucher
        </Text>
        <Flex gap="24px">
          {range(2).map((_, indexSkeleton) => (
            <Skeleton
              key={`skeleton-target-${indexSkeleton}`}
              width="92px"
              height="42px"
            />
          ))}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Loading;
