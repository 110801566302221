import {
  Box,
  Button,
  Flex,
  Icon,
  Text,
  cloneDeep,
  useDisclosure,
} from "renos-ui";
import { StyledDialog } from "./styled";
import { useEffect, useMemo, useState } from "react";
import Condition from "./container/condition";
import DateRange from "./container/date-range";
import PriceRange from "./container/price-range";
import Stock from "./container/stock";
import Courier from "./container/courier";
import Category from "./container/category";

const FilterPopup = ({ value, setValue, filterCount, defaultValue }) => {
  const { isOpen, onClose, onOpen } = useDisclosure({ isOpen: false });
  const [tempValue, setTempValue] = useState(cloneDeep(value));
  const [activeTab, setActiveTab] = useState("Kategori");

  const DATA_SIDEBAR = [
    "Kategori",
    "Kondisi",
    "Stok",
    "Jasa Pengiriman",
    "Harga",
    "Tanggal",
  ];

  const renderContainer = useMemo(() => {
    switch (activeTab) {
      case "Kategori":
        return (
          <Category
            value={tempValue.category}
            setValue={(newValue) =>
              setTempValue({
                ...tempValue,
                category: newValue,
                isChanged: true,
              })
            }
          />
        );
      case "Kondisi":
        return (
          <Condition
            value={tempValue.condition}
            setValue={(newValue) =>
              setTempValue({
                ...tempValue,
                condition: newValue,
                isChanged: true,
              })
            }
          />
        );
      case "Tanggal":
        return (
          <DateRange
            value={tempValue.dateRange}
            setValue={(newValue) =>
              setTempValue({
                ...tempValue,
                dateRange: newValue,
                isChanged: true,
              })
            }
          />
        );
      case "Harga":
        return (
          <PriceRange
            value={tempValue.priceRange}
            setValue={(newValue) =>
              setTempValue({
                ...tempValue,
                priceRange: newValue,
                isChanged: true,
              })
            }
          />
        );
      case "Stok":
        return (
          <Stock
            value={tempValue.stock}
            setValue={(newValue) =>
              setTempValue({
                ...tempValue,
                stock: newValue,
                isChanged: true,
              })
            }
          />
        );
      case "Jasa Pengiriman":
        return (
          <Courier
            value={tempValue.courier}
            setValue={(newValue) =>
              setTempValue({
                ...tempValue,
                courier: newValue,
                isChanged: true,
              })
            }
          />
        );

      default:
        return <></>;
    }
    // eslint-disable-next-line
  }, [activeTab, isOpen, tempValue]);

  const isChecked = (sidebarType) => {
    const { condition, category, courier, dateRange, priceRange, stock } =
      tempValue;

    switch (sidebarType) {
      case "Kategori":
        return category.length !== 0;
      case "Kondisi":
        return condition.length !== 0;
      case "Jasa Pengiriman":
        return courier.length !== 0;
      case "Tanggal":
        return dateRange?.start && dateRange?.end;
      case "Harga":
        return (
          priceRange?.start &&
          priceRange?.end &&
          priceRange?.start < priceRange?.end
        );
      case "Stok":
        return stock?.stock !== "" && stock?.condition?.value;
      default:
        return false;
    }
  };

  const handleApply = () => {
    setValue(tempValue);
    onClose();
  };

  const handleReset = () => {
    setTempValue({
      ...cloneDeep(defaultValue),
      isChanged: true,
    });
  };

  const isDisabled = useMemo(() => {
    const { isChanged, dateRange, priceRange } = tempValue;

    return (
      !isChanged ||
      (dateRange.start && !dateRange.end) ||
      (!dateRange.start && dateRange.end) ||
      (!priceRange.start && priceRange.end) ||
      priceRange.start > priceRange.end ||
      (priceRange.start && !priceRange.end)
    );
  }, [tempValue]);

  useEffect(() => {
    setTempValue({ ...value, isChanged: false });
    setActiveTab("Kategori");
    // eslint-disable-next-line
  }, [isOpen]);

  return (
    <Flex>
      <Flex
        padding="7px 12px"
        gap="8px"
        borderRadius="8px"
        borderWidth="1px"
        borderColor={filterCount > 0 ? "blue50" : "black10"}
        backgroundColor={filterCount > 0 ? "blue5" : "white"}
        borderStyle="solid"
        alignItems="center"
        cursor="pointer"
        onClick={onOpen}
      >
        {filterCount > 0 ? (
          <Flex
            justifyContent="center"
            alignItems="center"
            backgroundColor="blue50"
            borderRadius="50%"
            height="20px"
            width="20px"
          >
            <Text variant="small" color="white" weight="bold">
              {filterCount}
            </Text>
          </Flex>
        ) : (
          <Icon name="Filter-outline" size={20} color="black100" />
        )}

        <Text color={filterCount > 0 ? "blue50" : "black75"}>Filter</Text>
      </Flex>

      <StyledDialog
        onClose={onClose}
        isVisible={isOpen}
        verticalCentered
        width="672px"
      >
        <Flex flexDirection="column" width="100%" gap="24px">
          <Flex justifyContent="space-between" alignContent="center">
            <Flex
              alignContent="center"
              justifyItems="center"
              alignItems="center"
              gap="8px"
            >
              <Text variant="heading3" weight="bold">
                Filter Produk
              </Text>
            </Flex>
            <Icon
              name="Close-outline"
              cursor="pointer"
              size={24}
              color="black50"
              onClick={onClose}
            />
          </Flex>

          <Flex
            borderRadius="8px"
            borderWidth="1px"
            borderColor="black10"
            borderStyle="solid"
          >
            <Flex width="191px" flexDirection="column" height="293px">
              {DATA_SIDEBAR.map((sidebar, indexSidebar) => (
                <Flex
                  key={`sidebar-${indexSidebar}`}
                  padding="12px 24px"
                  width="143px"
                  borderColor="black10"
                  borderStyle="solid"
                  borderWidth={`${indexSidebar !== 0 ? "1px" : "0"} ${
                    activeTab === sidebar ? "0" : "1px"
                  }  0 0`}
                  alignItems="center"
                  position="relative"
                  cursor="pointer"
                  onClick={() => setActiveTab(sidebar)}
                  justifyContent="space-between"
                >
                  {activeTab === sidebar && (
                    <Box
                      width="4px"
                      height="24px"
                      backgroundColor="blue50"
                      borderRadius="0 8px 8px 0"
                      left="0"
                      position="absolute"
                    />
                  )}
                  <Text weight="bold" color="black75">
                    {sidebar}
                  </Text>
                  {isChecked(sidebar) ? (
                    <Flex
                      justifyContent="center"
                      alignItems="center"
                      backgroundColor="blue50"
                      borderRadius="50%"
                      height="20px"
                      width="20px"
                    >
                      <Icon name="Checkmark-outline" color="white" size={16} />
                    </Flex>
                  ) : (
                    <></>
                  )}
                </Flex>
              ))}
            </Flex>

            <Flex
              width="calc(100% - 191px)"
              flexDirection="column"
              height="245px"
              p="24px"
            >
              {renderContainer}
            </Flex>
          </Flex>

          <Flex gap="12px" justifyContent="flex-end">
            <Button variant="tertiary" size="medium" onClick={handleReset}>
              Reset
            </Button>
            <Button size="medium" onClick={handleApply} disabled={isDisabled}>
              Terapkan
            </Button>
          </Flex>
        </Flex>
      </StyledDialog>
    </Flex>
  );
};

export default FilterPopup;
