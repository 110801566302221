import { useEffect, useMemo, useState } from "react";
import {
  Button,
  DatePicker,
  Dialog,
  Divider,
  Flex,
  Icon,
  Input,
  Text,
  Tooltip,
  useDisclosure,
} from "renos-ui";
import dayjs from "dayjs";

const PeriodPicker = ({ disabled, value, onChange, isError, error }) => {
  const disclosure = useDisclosure({ isOpen: false });

  const today = new Date();
  const [startDate, setStartDate] = useState({
    date: value.product_discount_date_start,
    time: value.product_discount_date_start || new Date(),
  });
  const [endDate, setEndDate] = useState({
    date: value.product_discount_date_end,
    time: value.product_discount_date_end || new Date(),
  });

  const [errorStartTime, setErrorStartTime] = useState(false);
  const [errorEndTime, setErrorEndTime] = useState(false);

  const textValue = useMemo(
    () =>
      value.product_discount_date_start && value.product_discount_date_end
        ? `${dayjs(value.product_discount_date_start).format(
            "DD MMM YYYY (HH:mm WIB)"
          )} - ${dayjs(value.product_discount_date_end).format(
            "DD MMM YYYY (HH:mm WIB)"
          )}`
        : "Atur Periode",
    [value]
  );

  const handleChange = (newValue, propertyName, isStart) => {
    let newData = newValue;

    if (propertyName === "time") {
      const splitTime = newValue.split(":");
      newData = new Date();
      newData.setHours(splitTime[0]);
      newData.setMinutes(splitTime[1]);
    }

    if (isStart) {
      setStartDate((prev) => ({ ...prev, [propertyName]: newData }));
      if (propertyName === "date" && newData > endDate[propertyName])
        setEndDate((prev) => ({ ...prev, [propertyName]: "" }));

      if (propertyName === "time") setErrorStartTime(false);
    } else {
      setEndDate((prev) => ({ ...prev, [propertyName]: newData }));

      if (propertyName === "time") setErrorEndTime(false);
    }
  };

  const handleSave = () => {
    const product_discount_date_start = startDate.date;
    product_discount_date_start.setHours(startDate.time.getHours());
    product_discount_date_start.setMinutes(startDate.time.getMinutes());

    const product_discount_date_end = endDate.date;
    product_discount_date_end.setHours(endDate.time.getHours());
    product_discount_date_end.setMinutes(endDate.time.getMinutes());

    const isStartEqualToday =
      dayjs(new Date()).format("D MMM YYYY") ===
      dayjs(product_discount_date_start).format("D MMM YYYY");
    const isTimeDifferentAnHour =
      product_discount_date_start.getTime() - new Date().getTime() < 3540000;

    if (isStartEqualToday && isTimeDifferentAnHour) {
      setErrorStartTime(true);

      return;
    }

    if (product_discount_date_end <= product_discount_date_start) {
      setErrorEndTime(true);

      return;
    }

    onChange({ product_discount_date_start, product_discount_date_end });
    disclosure.onClose();
  };

  const isDisabled = useMemo(
    () => !startDate.date || !endDate.date,
    [startDate, endDate]
  );

  const InputText = () => (
    <Input
      readOnly
      disabled={disabled}
      value={textValue}
      size="small"
      sufix={<Icon name="Calendar-outline" size={20} color="black50" />}
      error={error}
      isError={isError}
    />
  );

  useEffect(() => {
    setStartDate({
      date: value.product_discount_date_start,
      time: value.product_discount_date_start || new Date(),
    });
    setEndDate({
      date: value.product_discount_date_end,
      time: value.product_discount_date_end || new Date(),
    });
  }, [value]);

  return (
    <Flex cursor="pointer" onClick={!disabled && disclosure.onToggle}>
      {textValue === "Atur Periode" ? (
        <InputText />
      ) : (
        <Tooltip label={textValue}>
          <InputText />
        </Tooltip>
      )}

      <Dialog
        isVisible={disclosure.isOpen}
        onClose={disclosure.onClose}
        closable
        verticalCentered
        width="320px"
      >
        <Flex flexDirection="column" width="100%" gap="16px">
          <Text variant="heading3" weight="bold">
            Atur Periode Diskon
          </Text>
          <Divider />

          <Flex flexDirection="column" gap="24px">
            <Flex gap="16px" flexDirection="column">
              <Text variant="caption" weight="bold" color="black50">
                Periode Mulai
              </Text>
              <Flex gap="8px">
                <Flex width="156px">
                  <DatePicker
                    selected={startDate.date}
                    value={startDate.date}
                    minDate={today}
                    onChange={(newValue) =>
                      handleChange(newValue, "date", true)
                    }
                    dateFormat="dd MMM yyyy"
                    customInput={
                      <Input
                        placeholder="Tanggal mulai"
                        size="small"
                        width="156px"
                        sufix={
                          <Icon
                            name="Calendar-outline"
                            size={20}
                            color="black50"
                          />
                        }
                      />
                    }
                  />
                </Flex>
                <Flex width="156px">
                  <DatePicker.Time
                    selected={startDate.time}
                    value={startDate.time}
                    onChange={(_, newValue) =>
                      handleChange(newValue, "time", true)
                    }
                    customInput={({ formattedTime }) => (
                      <Input
                        placeholder="Masukan jam"
                        value={`${formattedTime.hour} : ${formattedTime.minute}`}
                        size="small"
                        isError={errorStartTime}
                        error="Waktu minimal 1 jam dari saat ini"
                      />
                    )}
                  />
                </Flex>
              </Flex>
              <Flex gap="8px" flexDirection="column">
                <Text variant="caption" weight="bold" color="black50">
                  Periode Berakhir
                </Text>
                <Flex gap="8px">
                  <Flex width="156px">
                    <DatePicker
                      selected={endDate.date}
                      value={endDate.date}
                      onChange={(newValue) => handleChange(newValue, "date")}
                      minDate={startDate.date || today}
                      dateFormat="dd MMM yyyy"
                      customInput={
                        <Input
                          size="small"
                          placeholder="Tanggal selesai"
                          sufix={
                            <Icon
                              name="Calendar-outline"
                              size={20}
                              color="black50"
                            />
                          }
                        />
                      }
                    />
                  </Flex>

                  <Flex width="156px">
                    <DatePicker.Time
                      selected={endDate.time}
                      value={endDate.date}
                      onChange={(_, newValue) => handleChange(newValue, "time")}
                      customInput={({ formattedTime }) => (
                        <Input
                          placeholder="Masukan jam"
                          value={`${formattedTime.hour} : ${formattedTime.minute}`}
                          size="small"
                          isError={errorEndTime}
                          error="Waktu harus sesudah periode sebelumnya"
                        />
                      )}
                    />
                  </Flex>
                </Flex>
              </Flex>
            </Flex>

            <Button isBlock disabled={isDisabled} onClick={handleSave}>
              Simpan Pengaturan
            </Button>
          </Flex>
        </Flex>
      </Dialog>
    </Flex>
  );
};

export default PeriodPicker;
