import ENDPOINTS from "constants/endpoints";
import { useQuery } from "react-query";
import client from "../client";

const getProductList = async (params) => {
  const { data } = await client.get(ENDPOINTS.discount_list, {
    params,
  });

  return { data: data.data, pagination: data.meta.pagination };
};

const getProductStatus = async () => {
  const { data } = await client.get(ENDPOINTS.discount_status);

  let all = 0;
  const mappedResponse = {};
  data.data.forEach((status) => {
    all += status.total;

    switch (status.product_discount_status) {
      case "on_going":
        mappedResponse["on_going"] = status.total;
        break;

      case "created":
        mappedResponse["created"] = status.total;
        break;

      case "ended":
        mappedResponse["ended"] = status.total;
        break;

      default:
        mappedResponse["all"] = status.total;
        break;
    }
  });

  return { ...mappedResponse, totalStatus: all };
};

export const useGetProducts = (params, extraParams) => {
  return useQuery(["products", params], () => getProductList({ ...params }), {
    ...extraParams,
  });
};

export const useGetProductStatus = (extraParams) => {
  return useQuery(["product_status"], getProductStatus, { ...extraParams });
};
