import { Flex, Box, Text } from "renos-ui";
import ImgFolder from "assets/images/folder.png";

const EmptyNotif = () => {
  return (
    <Flex
      flexDirection="column"
      backgroundColor="black5"
      padding="32px"
      justifyContent="center"
      alignItems="center"
      gap="4px"
      borderRadius="8px"
    >
      <Flex flexDirection="column" gap="16px" alignItems="center">
        <Box as="img" src={ImgFolder} width="56px" height="56px" />
        <Flex
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
        >
          <Text weight="bold">Belum Ada Notifikasi</Text>
          <Text variant="caption">
            Terus pantau update terbaru <br /> untuk tokomu di sini.
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default EmptyNotif;
