import ENDPOINTS from "constants/endpoints";
import { useMutation, useQuery } from "react-query";
import client from "../client";

const getProductVariant = async () => {
  const { data } = await client.get(ENDPOINTS.variants);
  const response = data.data.map((variant) => ({
    ...variant,
    label: variant.variant_name,
    value: variant.variant_id,
  }));

  return response;
};

const getVariantValue = async (params) => {
  const { data } = await client.get(ENDPOINTS.variants_value, {
    params,
  });
  const response = data.data.map((variant) => ({
    ...variant,
    label: variant.variant_value,
    value: variant.variant_value_id,
  }));

  return response;
};

export const useGetProductVariant = (extraParams) => {
  return useQuery(["product-variants"], getProductVariant, { ...extraParams });
};

export const useVariantValue = (params, extraParams) => {
  return useQuery(["product-value", params], () => getVariantValue(params), {
    ...extraParams,
  });
};

export const useGetVariantValue = (extraParams) => {
  return useMutation((params) => getVariantValue(params), { ...extraParams });
};
