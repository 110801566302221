import {
  Button,
  Divider,
  Drawer,
  Flex,
  Icon,
  Input,
  Text,
  useDebounce,
} from "renos-ui";
import { SearchInputProductWrap } from "../styled";
import ProductEmpty from "./ProductEmpty";
import ProductItem from "./ProductItem";
import ProductSkeleton from "./ProductSkeleton";
import InfiniteScroll from "widgets/global/InfiniteScroll";
import { useEffect, useMemo, useState } from "react";
import { useGetProductsV3 } from "services/product";

const DrawerAttachProduct = ({
  disclosure,
  onClose,
  selectedAttachProduct,
  setSelectedAttachProduct,
  handleAttachProduct,
}) => {
  const MAX_SELECTED_ITEM = 3;
  const { isOpen } = disclosure;
  const [items, setItems] = useState([]);
  const [filter, setFilter] = useState({
    limit: 20,
    page: 1,
    keyword: "",
    "product_status_ids[]": 1,
  });
  const [searchValue, setSearchValue] = useState("");

  useDebounce(
    () =>
      setFilter({
        ...filter,
        keyword: searchValue,
        page: 1,
      }),
    [searchValue],
    500
  );

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        setFilter((prev) => ({ ...prev, page: 1, keyword: "" }));
      }, 300);
    }
  }, [isOpen]);

  const {
    data: dataList,
    isLoading,
    isFetching,
  } = useGetProductsV3(filter, {
    onSuccess: (data) => {
      const items =
        data?.data?.filter((item) => item.product_status_id === 1) || [];

      if (filter.page === 1) {
        setItems(items);
      } else {
        setItems((prev) => [...prev, ...items]);
      }
    },
  });

  const handleCheckProduct = (item) => {
    if (
      selectedAttachProduct.find(
        (product) => product.product_id === item.product_id
      )
    ) {
      setSelectedAttachProduct(
        selectedAttachProduct.filter(
          (product) => product.product_id !== item.product_id
        )
      );
    } else {
      if (selectedAttachProduct.length < MAX_SELECTED_ITEM) {
        setSelectedAttachProduct([...selectedAttachProduct, item]);
      }
    }
  };

  const hasNextPage = useMemo(
    () => dataList?.pagination?.current_page < dataList?.pagination?.last_page,
    [dataList]
  );

  const isProductLoading = useMemo(
    () => (isLoading || isFetching) && filter.page === 1,
    // eslint-disable-next-line
    [isLoading, isFetching]
  );

  const isShowAttachButton = useMemo(
    () => selectedAttachProduct.length > 0,
    [selectedAttachProduct]
  );

  return (
    <Drawer
      isFullPage
      padding="0"
      overflow="hidden"
      placement="bottom"
      onClose={onClose}
      isVisible={isOpen}
      header={{
        render: (
          <Flex
            gap="16px"
            padding="16px"
            borderBottomWidth="1px"
            borderBottomStyle="solid"
            borderBottomColor="black10"
          >
            <Flex onClick={onClose}>
              <Icon name="Close-outline" color="black50" />
            </Flex>
            <Flex>
              <Text variant="subtitle" weight="bold">
                Lampirkan Produk
              </Text>
            </Flex>
          </Flex>
        ),
      }}
    >
      <Flex flexDirection="column" gap="16px" padding="16px">
        <SearchInputProductWrap>
          <Input
            size="small"
            placeholder="Cari nama"
            prefix={<Icon name="Search-outline" size={16} color="black50" />}
            onChange={(event) => setSearchValue(event.target.value)}
          />
        </SearchInputProductWrap>
        <Divider />
        {isProductLoading ? (
          <ProductSkeleton itemsShow={10} />
        ) : items.length === 0 ? (
          <ProductEmpty />
        ) : (
          <Flex
            overflow="auto"
            flexDirection="column"
            gap="16px"
            height={`calc(100dvh - ${!isShowAttachButton ? "160px" : "230px"})`}
          >
            <InfiniteScroll
              isMobile
              page={filter.page}
              isLoading={isFetching}
              loader={<ProductSkeleton itemsShow={1} />}
              hasNextPage={
                hasNextPage && !isProductLoading && items?.length > 20
              }
              onChange={() => {
                setFilter((prev) => ({ ...prev, page: prev.page + 1 }));
              }}
            >
              {items.map((item, indexItem) => (
                <ProductItem
                  item={item}
                  key={`product-item-${indexItem}`}
                  handleCheckProduct={handleCheckProduct}
                  checked={selectedAttachProduct.some(
                    (product) => product.product_id === item.product_id
                  )}
                  disabledCheck={
                    selectedAttachProduct.length >= MAX_SELECTED_ITEM &&
                    !selectedAttachProduct.includes(item)
                  }
                />
              ))}
            </InfiniteScroll>

            {isShowAttachButton && (
              <Flex
                left="0"
                right="0"
                bottom="0"
                padding="16px"
                position="fixed"
                backgroundColor="white"
                boxShadow="0 0 8px 0 rgba(0, 0, 0, 0.05)"
              >
                <Button
                  isBlock
                  size="medium"
                  variant="primary"
                  onClick={handleAttachProduct}
                >
                  {`Lampirkan (${selectedAttachProduct.length}/${MAX_SELECTED_ITEM})`}
                </Button>
              </Flex>
            )}
          </Flex>
        )}
      </Flex>
    </Drawer>
  );
};

export default DrawerAttachProduct;
