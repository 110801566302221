import React from "react";
import { Flex, Text, Label, Icon } from "renos-ui";

const CardLocation = ({
  name,
  contact_number,
  address,
  lower_district_name,
  district_name,
  city_name,
  province_name,
  postal_code,
  is_primary,
  item,
  toggleDrawer,
}) => {
  return (
    <Flex
      flexDirection="column"
      padding="16px"
      borderRadius="8px"
      backgroundColor="white"
    >
      <Flex width="100%" flexDirection="column" gap="8px">
        <Flex flexDirection="column" gap="9px">
          <Flex flexDirection="row" justifyContent="space-between">
            <Flex flexDirection="row" gap="10px">
              <Text weight="bold" variant="body">
                {name}
              </Text>
              {is_primary && (
                <Flex flexGrow="1">
                  <Label variant="success">Utama</Label>
                </Flex>
              )}
            </Flex>
            <Flex onClick={() => toggleDrawer(item)}>
              <Icon name="Vertical-outline" size="20px" color="black50" />
            </Flex>
          </Flex>
          <Text variant="caption" color="black50">
            {contact_number}
          </Text>

          <Text variant="caption" color="black50">
            {address}
          </Text>
          <Text variant="caption" color="black50">
            {lower_district_name}, {district_name}, {city_name}, {province_name}{" "}
            {postal_code}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default CardLocation;
