import { goToUrl } from "helpers";
import { useMemo } from "react";
import {
  Avatar,
  Divider,
  Flex,
  Icon,
  Text,
  numberInputChecking,
  useDisclosure,
} from "renos-ui";
import PopupTracking from "./Footer/component/OnDelivery/PopupTracking";
import { maskAllName } from "helpers/string-mask";

const Body = ({
  user_name: userName,
  products,
  total_price: earlyTotalPrice,
  shipping,
  order_status_id: orderStatusId,
  store_order_shipment: storeOrderShipment,
  order_id: orderId,
  promo_used: promoUsed,
  toDetail,
}) => {
  const disclosureTrack = useDisclosure({ isOpen: false });
  const listShowDelivery = [4, 5, 6];

  const handleOpenDeliveryProof = (event) => {
    event.stopPropagation();

    if (shipping?.is_seller_fleet) {
      goToUrl(storeOrderShipment?.pod?.shipment_deliver_picture_url);
    } else {
      disclosureTrack.onOpen();
    }
  };

  const handleMasking = (value) => {
    const notAllowedMask = [2, 3, 4, 5];

    return notAllowedMask.includes(orderStatusId) ? value : maskAllName(value);
  };

  const deliveryProof = useMemo(
    () =>
      shipping?.is_seller_fleet
        ? "Dokumen Pengiriman"
        : shipping?.order_shipping_receipt_number,
    [shipping]
  );

  const totalPrice = useMemo(() => {
    const sellerFleetAmount = shipping?.is_seller_fleet
      ? parseInt(shipping?.order_shipping_price)
      : 0;
    const promoUsedLen = (promoUsed || [])?.length;
    const voucherAmount = promoUsedLen
      ? promoUsed[0]?.seller_voucher_disc || 0
      : 0;
    const formatted = earlyTotalPrice + sellerFleetAmount + voucherAmount;

    return numberInputChecking(formatted.toString() || "0", 0).formattedValue;
  }, [earlyTotalPrice, shipping, promoUsed]);

  const isShowCourierInfo = useMemo(
    () =>
      storeOrderShipment.pod &&
      storeOrderShipment.pod?.courier_employee_name &&
      storeOrderShipment.pod?.courier_employee_phone_number,
    [storeOrderShipment]
  );

  const renderCourierInfo = useMemo(() => {
    if (shipping?.is_seller_fleet) {
      return (
        <Flex width="50%" gap="4px">
          <Icon name="Edit-outline" size={12} color="black50" />
          <Flex width="calc(100% - 16px)">
            <Text color="black50" variant="small">
              {storeOrderShipment?.pod?.delivery_notes || "Tidak ada catatan"}
            </Text>
          </Flex>
        </Flex>
      );
    }

    if (isShowCourierInfo) {
      return (
        <Flex width="50%" gap="8px">
          <Avatar
            size={32}
            src={
              storeOrderShipment.pod.courier_employee_photo_url
                ? storeOrderShipment.pod.courier_employee_photo_url
                : storeOrderShipment.pod.courier_employee_photo_url_external
                ? storeOrderShipment.pod.courier_employee_photo_url_external
                : ""
            }
          />
          <Flex width="calc(100% - 40px)" flexDirection="column" gap="4px">
            <Text color="black50" variant="small" lineClamp={1}>
              {storeOrderShipment?.pod?.courier_employee_name}
            </Text>
            <Text color="black50" variant="small">
              {storeOrderShipment?.pod?.courier_employee_phone_number}
            </Text>
          </Flex>
        </Flex>
      );
    }

    return <></>;
  }, [shipping, storeOrderShipment, isShowCourierInfo]);

  return (
    <Flex gap="16px" flexDirection="column" onClick={toDetail}>
      <Flex flexDirection="column" gap="8px">
        <Text color="black50" weight="bold" variant="small" lineClamp={1}>
          {handleMasking(userName)}
        </Text>
        <Flex gap="16px" alignItems="center">
          <Flex
            as="img"
            height="48px"
            width="48px"
            borderRadius="8px"
            alt="img-product"
            src={products[0]?.product_image_url}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = "/assets/images/empty-product.svg";
            }}
          />
          <Flex flexDirection="column" gap="4px">
            <Text variant="caption" color="black75" weight="bold" lineClamp={1}>
              {`${products[0]?.product_name} ${
                products[0]?.variant_name && ` - ${products[0]?.variant_name}`
              }`}
            </Text>
            {products.length > 1 && (
              <Text variant="small" color="black50">
                + {products.length - 1} Produk Lainnya
              </Text>
            )}
          </Flex>
        </Flex>

        <Flex
          padding="8px"
          backgroundColor="black5"
          borderRadius="8px"
          justifyContent="space-between"
          alignItems="center"
        >
          <Text color="black50" variant="small" weight="bold">
            Total Harga
          </Text>
          <Text variant="caption" weight="bold">
            Rp {totalPrice}
          </Text>
        </Flex>
      </Flex>
      <Divider />
      <Flex flexDirection="column" gap="16px">
        <Flex gap="8px">
          <Flex width="50%" gap="4px">
            <Icon name="Location-outline" size={12} color="black50" />
            <Flex width="calc(100% - 16px)">
              <Text color="black50" variant="small">
                {`${handleMasking(
                  shipping.destination_address
                )}, ${handleMasking(
                  shipping.destination_district_name
                )}, ${handleMasking(
                  shipping.destination_city_name
                )}, ${handleMasking(
                  shipping.destination_province_name
                )} ${handleMasking(shipping.destination_postal_code)}`}
                ,
              </Text>
            </Flex>
          </Flex>
          <Flex width="50%" gap="4px">
            <Icon name="Delivery-outline" size={12} color="black50" />
            <Flex width="calc(100% - 16px)">
              <Text color="black50" variant="small">
                {`${shipping.logistics_courier_name} - ${shipping.logistics_courier_service_name}`}
              </Text>
            </Flex>
          </Flex>
        </Flex>

        {listShowDelivery.includes(orderStatusId) && (
          <Flex gap="8px">
            <Flex width="50%" gap="4px">
              <Icon name="Transaction-outline" size={12} color="black50" />
              {storeOrderShipment?.pod?.shipment_deliver_picture_url ? (
                <Flex
                  width="calc(100% - 16px)"
                  onClick={handleOpenDeliveryProof}
                >
                  <Text color="blue50" variant="small">
                    <u>{deliveryProof}</u>
                  </Text>
                </Flex>
              ) : (
                <Text color="black50" variant="small">
                  Tidak ada dokumen
                </Text>
              )}
            </Flex>
            {renderCourierInfo}
            <PopupTracking disclosure={disclosureTrack} orderId={orderId} />
          </Flex>
        )}

        {storeOrderShipment.pod && orderStatusId !== 4 && (
          <>
            <Divider />
            <Flex gap="8px">
              <Flex width="50%" gap="4px" flexDirection="column">
                <Text
                  color="black50"
                  variant="small"
                  lineClamp={1}
                  weight="bold"
                >
                  Penerima
                </Text>
                <Text color="black50" variant="small" lineClamp={1}>
                  {storeOrderShipment.pod.receiver_contact_person ||
                    "Nama penerima tidak tersedia"}
                </Text>
              </Flex>
              <Flex width="50%" gap="4px" flexDirection="column">
                <Text
                  color="black50"
                  variant="small"
                  lineClamp={1}
                  weight="bold"
                >
                  Bukti Penerima
                </Text>
                {storeOrderShipment.pod.shipment_receive_picture_url ? (
                  <Flex
                    onClick={(event) => {
                      event.stopPropagation();
                      goToUrl(
                        storeOrderShipment.pod.shipment_receive_picture_url
                      );
                    }}
                    cursor="pointer"
                  >
                    <Text variant="small" color="blue50">
                      <u>Bukti Terima</u>
                    </Text>
                  </Flex>
                ) : (
                  <Text color="black50" variant="small" lineClamp={1}>
                    Bukti Pengiriman Tidak Tersedia
                  </Text>
                )}
              </Flex>
            </Flex>
          </>
        )}
      </Flex>
    </Flex>
  );
};

export default Body;
