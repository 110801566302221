import {
  blobToBase64,
  getImageMeta,
  imageTypeChecking,
  imgCompressor,
} from "helpers";
import { variantToSkuFormat } from "helpers/attribute-mapper/product-variant";
import React, { useMemo, useState } from "react";
import {
  Checkbox,
  Dropdown,
  Flex,
  Icon,
  Input,
  isEmpty,
  Label,
  pick,
  Text,
  Toggle,
  Tooltip,
  // Upload,
  useToast,
  useUpload,
} from "renos-ui";
import { useUploadProductImage } from "services/product";
import { IMAGE_FILE_TYPE } from "../../constants";
import {
  StyledUpload,
  VariantImage,
  VariantImageOvelay,
  VariantTable,
} from "../styled";
import { useWatch } from "react-hook-form";

const TableVariant = ({
  sku,
  productWithValue,
  methods,
  formBatch,
  checkedVariant,
  setCheckedVariant,
  setIsCheckAll,
  isCheckAll,
  filterData,
  setFilterData,
  filteredCount,
  isEdit,
}) => {
  const [imageIndex, setImageIndex] = useState();

  const uom = useWatch({ name: "uom" });
  const tempSku = useWatch({ name: "temp_sku" });

  const toast = useToast();

  const onFormValueChange = (value, index, key) => {
    if (sku.length > 0) {
      const selectedIndex = sku.findIndex((value) => value.index === index);

      if (selectedIndex >= 0) {
        const listActiveProduct = sku.filter((data) => data.status === 1);

        sku[selectedIndex][key] = value;

        if (key === "status") {
          if (sku[selectedIndex].default === 1) {
            const nextActiveIndex = sku.findIndex((data) => data.status === 1);

            if (nextActiveIndex >= 0) {
              sku[selectedIndex].default = 0;
              sku[nextActiveIndex].default = 1;

              if (
                sku[nextActiveIndex].stock === 0 ||
                isEmpty(sku[nextActiveIndex].stock)
              ) {
                sku[nextActiveIndex].stock = 1;
              }
            }
          }

          if (
            listActiveProduct.length === 0 &&
            sku[selectedIndex].default !== 1
          ) {
            const prevDefaultIndex = sku.findIndex(
              (data) => data.default === 1
            );
            sku[prevDefaultIndex].default = 0;
            sku[selectedIndex].default = 1;
          }

          if (
            sku[selectedIndex].stock === 0 ||
            isEmpty(sku[selectedIndex].stock)
          ) {
            sku[selectedIndex].stock = 1;
          }
        }
      } else {
        sku.push({
          index,
          [key]: value,
        });
      }
    } else {
      sku.push({
        index,
        [key]: value,
      });
    }

    methods.setValue("sku", sku);
  };

  const { mutate: uploadImage } = useUploadProductImage({
    onSuccess: (data) => {
      methods.setValue(
        "sku",
        sku.map((prev) => {
          if (prev.index === imageIndex) {
            prev.image_id = data.image_id;
            prev.image = data.path;
            prev.image_url = data.url;
          }

          return prev;
        })
      );
    },
  });

  const { register } = useUpload({
    fileType: "image",
    onRequest: async (file) => {
      const deepTypeChecking = await imageTypeChecking(file);

      if (deepTypeChecking) {
        const result = await blobToBase64(file);
        const { naturalHeight, naturalWidth } = await getImageMeta(result);

        if (naturalHeight < 300 || naturalWidth < 300) {
          toast({
            label: "Format gambar tidak sesuai",
            placement: "top",
          });

          return null;
        }

        const formData = new FormData();
        const compressed =
          file.size > 400000
            ? await imgCompressor(file, {
                maxSizeMB: 0.4,
                maxWidthOrHeight: 1920,
                useWebWorker: true,
              })
            : file;

        formData.append("image", compressed);
        formData.append("image_type_id", 6);
        uploadImage(formData);
      } else {
        toast({
          label: "Format gambar tidak sesuai",
          placement: "top",
        });
      }
    },
    validator: (file) => {
      if (!IMAGE_FILE_TYPE.includes(file.type)) {
        toast({
          label: "Format gambar tidak sesuai",
          placement: "top",
        });

        return {
          message: "Format gambar tidak sesuai",
        };
      }

      return null;
    },
  });

  const onImageDelete = (index) => {
    methods.setValue(
      "sku",
      sku.map((prev) => {
        if (prev.index === index) {
          delete prev.image_id;
          delete prev.image_url;
          delete prev.image;
        }

        return prev;
      })
    );
  };

  const onVariantChecked = (index) => {
    setFilterData({});

    if (checkedVariant.includes(index)) {
      const tempChecked = [...checkedVariant];
      const selectedIndex = checkedVariant.findIndex((data) => data === index);
      tempChecked.splice(selectedIndex, 1);
      setCheckedVariant(tempChecked);
      setIsCheckAll(false);
    } else {
      if (checkedVariant.length === dataSource.length - 1) {
        setIsCheckAll(true);
      }
      setCheckedVariant((prev) => [...prev, index]);
    }
  };

  const onPrimaryChange = (index) => {
    methods.setValue(
      "sku",
      sku.map((prev) => {
        if (prev.index === index) {
          prev.default = 1;

          if (prev.stock === 0) {
            prev.stock = 1;
          }
        } else {
          prev.default = 0;
        }

        return prev;
      })
    );
  };

  const tableColumns = useMemo(
    () => {
      const column = [
        {
          dataIndex: "check",
          key: "check",
          render: (_, data) => {
            const filtered = [];
            Object.keys(filterData).forEach((key) => {
              if (filterData[key] === data.id[key]) {
                filtered.push(true);
              }
            });

            const isFiltered =
              filteredCount > 0 ? filtered.length === filteredCount : false;

            return (
              <Flex pl="12px">
                <Checkbox
                  checked={
                    isFiltered ||
                    isCheckAll ||
                    checkedVariant.includes(data.index)
                  }
                  onChange={() => onVariantChecked(data.index)}
                />
              </Flex>
            );
          },
          width: "48px",
        },
        ...productWithValue.map((variant, i) => ({
          title: variant.variant_name,
          dataIndex: variant.variant_name,
          key: variant.variant_name,
          render: (_, data) => (
            <Text color="black75">{data?.variants?.[i]?.label}</Text>
          ),
          width: "100px",
        })),
        {
          title: "Harga",
          dataIndex: "price",
          key: "price",
          render: (_, data) => {
            const currentForm = sku.find((value) => value.index === data.index);

            const isHaveDiscount =
              currentForm?.is_product_discount_seasonal_active;

            return (
              <Flex pr="12px">
                {isHaveDiscount ? (
                  <Tooltip
                    label={
                      <Flex>
                        Harga produk tidak bisa diubah karena <br /> produk
                        sudah diatur untuk promosi.
                      </Flex>
                    }
                    placement="bottom"
                  >
                    <Input.Number
                      required
                      size="small"
                      max={999999999}
                      prefix={
                        <Text weight="bold" color="black50">
                          Rp
                        </Text>
                      }
                      disabled={isHaveDiscount}
                      placeholder="Harga"
                      value={currentForm ? currentForm.price : null}
                    />
                  </Tooltip>
                ) : (
                  <Input.Number
                    required
                    size="small"
                    max={999999999}
                    prefix={
                      <Text weight="bold" color="black50">
                        Rp
                      </Text>
                    }
                    placeholder="Harga"
                    value={currentForm ? currentForm.price : null}
                    onChange={(e) => onFormValueChange(e, data.index, "price")}
                    // onInvalid={(event) => {
                    //   if (isEmpty(event.target.value)) {
                    //     event.target.setCustomValidity(
                    //       "Harga tidak boleh kosong."
                    //     );
                    //   } else {
                    //     event.target.setCustomValidity("");
                    //   }
                    // }}
                    // onInput={(event) => {
                    //   event.target.setCustomValidity("");
                    // }}
                  />
                )}
              </Flex>
            );
          },
        },
        {
          title: "Stok",
          dataIndex: "stock",
          key: "stock",
          render: (_, data) => {
            const currentForm = sku.find((value) => value.index === data.index);

            return (
              <Flex pr="12px">
                <Input.Number
                  required
                  size="small"
                  max={99999}
                  placeholder="Stok"
                  onChange={(e) => onFormValueChange(e, data.index, "stock")}
                  value={
                    currentForm
                      ? currentForm.stock === 0
                        ? "0"
                        : currentForm.stock
                      : currentForm.stock
                  }
                  min={
                    currentForm.status === 2 && isEdit
                      ? 0
                      : !isEdit || currentForm.default === 1
                      ? 1
                      : 0
                  }
                  onFocus={(event) => event.target.select()}
                  // onInvalid={(event) =>
                  //   event.target.setCustomValidity("Stok tidak boleh kosong.")
                  // }
                  // onInput={(event) => event.target.setCustomValidity("")}
                />
              </Flex>
            );
          },
        },
        {
          title: "SKU",
          dataIndex: "product_sku",
          key: "product_sku",
          render: (index, data) => {
            const currentForm = sku.find((value) => value.index === data.index);

            return (
              <Flex pr="12px">
                <Input
                  required
                  size="small"
                  placeholder="SKU"
                  onChange={(e) =>
                    onFormValueChange(e.target.value, data.index, "number")
                  }
                  value={currentForm.number || ""}
                  // onInvalid={(event) =>
                  //   event.target.setCustomValidity("SKU tidak boleh kosong.")
                  // }
                  // onInput={(event) => event.target.setCustomValidity("")}
                />
              </Flex>
            );
          },
        },
        {
          title: "Berat",
          dataIndex: "product_weight",
          key: "product_weight",
          render: (index, data) => {
            const currentForm = sku.find((value) => value.index === data.index);

            return (
              <Flex pr="12px">
                <Input.Number
                  max={uom === 1 ? 999 : 999999}
                  required
                  size="small"
                  placeholder="Berat"
                  onChange={(value) =>
                    onFormValueChange(value, data.index, "weight")
                  }
                  sufix={
                    <Text color="black50" weight="bold">
                      {uom === 1 ? "kg" : "g"}
                    </Text>
                  }
                  value={currentForm.weight || ""}
                  min={1}
                  onFocus={(event) => event.target.select()}
                  // onInvalid={(event) =>
                  //   event.target.setCustomValidity("Berat tidak boleh kosong.")
                  // }
                  // onInput={(event) => event.target.setCustomValidity("")}
                />
              </Flex>
            );
          },
        },
        {
          title: "Foto",
          dataIndex: "product_image",
          key: "product_image",
          render: (_, data) => {
            const currentForm = sku.find((value) => value.index === data.index);

            return !isEmpty(currentForm.image_url) ? (
              <VariantImage
                width="40px"
                height="40px"
                borderRadius="8px"
                background={`url("${currentForm.image_url}")`}
                backgroundRepeat="no-repeat"
                backgroundPosition="center"
                cursor="pointer"
                position="relative"
                backgroundSize="contain"
              >
                <VariantImageOvelay
                  position="absolute"
                  top="0"
                  left="0"
                  width="100%"
                  height="100%"
                  zIndex={10}
                  cursor="default"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Tooltip
                    width={60}
                    label="Hapus Gambar"
                    textAlign="center"
                    icon={
                      <Icon
                        name="Trash-outline"
                        size={20}
                        cursor="pointer"
                        color="white"
                        onClick={() => onImageDelete(data.index)}
                      />
                    }
                  />
                </VariantImageOvelay>
              </VariantImage>
            ) : (
              <Flex position="relative">
                <StyledUpload
                  {...register}
                  accept="image/jpg, image/jpeg, image/png"
                  onOpen
                  required
                  onInvalid={(event) =>
                    event.target.setCustomValidity("Gambar tidak boleh kosong.")
                  }
                  onInput={(event) => event.target.setCustomValidity("")}
                >
                  <Flex
                    width="40px"
                    height="40px"
                    borderStyle="dashed"
                    borderWidth="1px"
                    borderColor="black25"
                    borderRadius="8px"
                    alignItems="center"
                    justifyContent="center"
                    cursor="pointer"
                    position="relative"
                    onClick={() => setImageIndex(data.index)}
                    zIndex={1}
                    backgroundColor="white"
                  >
                    <Icon name="Plus-solid" color="black60" />
                  </Flex>
                </StyledUpload>
              </Flex>
            );
          },
        },
        {
          title: "Status",
          dataIndex: "product_status",
          key: "product_status",
          render: (_, data) => {
            const currentForm = sku.find((value) => value.index === data.index);

            return (
              <Flex
                px="12px"
                onClick={() => {
                  onFormValueChange(
                    currentForm?.status === 1 ||
                      currentForm?.status === undefined
                      ? 2
                      : 1,
                    data.index,
                    "status"
                  );
                }}
              >
                <Toggle
                  checked={
                    currentForm?.status === undefined ||
                    currentForm?.status === 1
                  }
                />
              </Flex>
            );
          },
        },
        {
          dataIndex: "default",
          key: "default",
          render: (_, data) => {
            const currentForm = sku.find((value) => value.index === data.index);

            return (
              <Flex px="12px" justifyContent="center">
                {currentForm.default === 1 ? (
                  <Label variant="success">Utama</Label>
                ) : (
                  <Dropdown>
                    <Dropdown.Toggle>
                      <Icon
                        name="Vertical-outline"
                        size={20}
                        color="black50"
                        cursor="pointer"
                      />
                    </Dropdown.Toggle>
                    <Dropdown.Content
                      minWidth="127px !important"
                      positionDropdown="center"
                    >
                      <Dropdown.Item
                        onClick={() => onPrimaryChange(data.index)}
                      >
                        <Text>Jadikan Utama</Text>
                      </Dropdown.Item>
                    </Dropdown.Content>
                  </Dropdown>
                )}
              </Flex>
            );
          },
        },
      ];

      if (sku.length === 1) {
        column.splice(0, 1);
      }

      return column;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [productWithValue, isCheckAll, checkedVariant, filterData, sku, formBatch]
  );

  const dataSource = useMemo(() => {
    const formattedData = variantToSkuFormat(productWithValue, isEdit);
    let formattedSku = formattedData.map((data) =>
      pick(data, [
        "index",
        "id",
        "variants",
        "price",
        "stock",
        "status",
        "default",
        "number",
        "weight",
      ])
    );

    if (tempSku) {
      const tempFormattedSku = [...formattedSku];
      formattedSku = tempFormattedSku.map((data) => {
        const varName =
          data.variants.length > 1
            ? data.variants.map((variant) => variant.label)
            : data.variants[0].label;

        const selected = tempSku.find((sku) =>
          data.variants.length > 1
            ? sku.variant_name === varName.join("|") ||
              sku.variant_name === varName.reverse().join("|")
            : sku.variant_name === varName
        );

        if (selected) {
          const newData = { ...data };
          const productWeight = parseInt(selected?.product_weight);

          newData.price = selected?.product_price;
          newData.stock = selected?.product_stock;
          newData.status = selected?.product_status_id;
          newData.default = selected?.is_default || data?.default;
          newData.weight = productWeight;
          newData.number = selected?.product_sku_mpn;
          newData.image_id = selected?.product_image_id;
          newData.image_url = selected?.product_image_url;
          newData.image = selected?.product_image_path;
          newData.product_sku_id = selected?.product_sku_id;
          newData.is_product_discount_seasonal_active =
            selected?.is_product_discount_seasonal_active;

          return newData;
        }

        return data;
      });
    }

    // const defaultSkuIndex = formattedSku.findIndex(
    //   (item) => item.default === 1
    // );

    // if (
    //   formattedSku.length > 0 &&
    //   formattedSku[defaultSkuIndex]?.status &&
    //   formattedSku[defaultSkuIndex].status === 2
    // ) {
    //   formattedSku[defaultSkuIndex].status = 1;
    // }

    methods.setValue("sku", formattedSku);

    return formattedData;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productWithValue, isEdit]);

  return (
    <VariantTable
      borderStyle="solid"
      borderWidth="1px"
      borderRadius="8px"
      borderColor="black10"
      dataSource={dataSource}
      columns={tableColumns}
    />
  );
};

export default TableVariant;
