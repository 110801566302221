import React, { useContext, useEffect } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import mobileUrl from "constants/mobileUrl";
import { QISCUS } from "hooks/qiscus";
import PrivateRoute from "widgets/global/PrivateRoute";
import Dashboard from "containers/mobile/dashboard";
import OnboardPage from "containers/mobile/onboard";
import MobileLayout from "layouts/MobileLayout";
import Information from "containers/mobile/account/information";
import desktopUrl from "constants/desktopUrl";
import ProductList from "containers/mobile/product/list";
import Account from "containers/mobile/account";
import Document from "containers/mobile/account/document";
import ProductAdd from "containers/mobile/product/add-single";
import ProductAssets from "containers/mobile/product/add-single/sub-containers/product-assets";
import AccountReview from "containers/mobile/account/review-rating";
import ProductAddProvider from "store/productAdd/reducer";
import DocumentForm from "containers/mobile/account/document/add";
import DocumentFormBank from "containers/mobile/account/document/add-bank";
import Note from "containers/mobile/account/note";
import NoteForm from "containers/mobile/account/note/add";
import ProductInformation from "containers/mobile/product/add-single/sub-containers/product-information";
import ProductCategories from "containers/mobile/product/add-single/sub-containers/product-categories";
import ProductDetail from "containers/mobile/product/add-single/sub-containers/product-detail";
import OrderManagement from "containers/mobile/order-management/list";
import ChatSetting from "containers/mobile/chat/chat-setting";
import ChatRoom from "containers/mobile/chat/chat-room";
import ChatRoomItems from "containers/mobile/chat/chat-room/ChatRoomItems";
import AccountStoreStatus from "containers/mobile/account/store-status";
import AccountLocation from "containers/mobile/account/location";
import AccountLocationForm from "containers/mobile/account/location/add";
import ProductShipping from "containers/mobile/product/add-single/sub-containers/product-shipping";
import AccountReplyTemplate from "containers/mobile/account/reply-template";
import AccountReplyTemplateAdd from "containers/mobile/account/reply-template/add";
import OrderManagementDetail from "containers/mobile/order-management/detail";
// import PromoManagement from "containers/mobile/promo-management";
// import AddPromo from "containers/mobile/promo-management/add";
import DeliveryProvider from "store/seller-fleet/reducer";
import DeliveryService from "containers/mobile/account/delivery-seller-fleet";
import ListSetting from "containers/mobile/account/delivery-seller-fleet/list-setting";
import ListSettingDetail from "containers/mobile/account/delivery-seller-fleet/list-setting/ListSettingDetail";
import PrintLayout from "layouts/PrintLayout";
import PrintInvoice from "containers/web/order-management/List/Card/Header/PrintInvoice";
import PrintLabel from "containers/mobile/order-management/list/component/Card/Footer/component/SendOrder/PrintLabel";
import Statistic from "containers/mobile/account/statistic";
import ProductSpesification from "containers/mobile/product/add-single/sub-containers/product-spesification";
import VariantSelection from "containers/mobile/product/add-single/sub-containers/product-spesification/variant-selection";
import DeliveryRegion from "containers/mobile/account/delivery-seller-fleet/delivery-region";
import Notification from "containers/mobile/notification";
// import AddBulkPromo from "containers/mobile/promo-management/add/bulk";
// import AddSinglePromo from "containers/mobile/promo-management/add/single";
import ShippingRates from "containers/mobile/account/delivery-seller-fleet/shipping-rates";
import NotFoundPage from "containers/mobile/error/not-found";
import OfflinePage from "containers/mobile/error/offline";
import { OnlineStateContext } from "providers/OnlineStateProvider";
// import AddVariantPromo from "containers/mobile/promo-management/add/variant";
import Register from "containers/mobile/register";
import ChatAttachOrder from "containers/mobile/chat/chat-room/ChatAttachOrder";
import SniInput from "containers/mobile/product/add-single/sub-containers/product-spesification/sni-input";
import useCustomTour, { stepTourMobile, styles } from "hooks/useCustomTour";
import { TourProvider } from "@reactour/tour";

const MobileRouter = () => {
  const { isOnline } = useContext(OnlineStateContext);
  const location = useLocation();
  const isDashboardPage = location.pathname === mobileUrl.DASHBOARD;

  useEffect(() => {
    QISCUS.init();
  }, []);

  return isOnline ? (
    <Switch>
      {/* route with landing layout */}
      <Route path={[mobileUrl.LANDING]}>
        <PrivateRoute
          isLanding
          isMobile
          path={MobileLayout.LANDING}
          component={OnboardPage}
        />
      </Route>

      {/* route with dashboard layout */}
      <Route
        path={[
          mobileUrl.DASHBOARD,
          mobileUrl.ACCOUNT,
          mobileUrl.ACCOUNT_RATING_REVIEW,
          mobileUrl.STORE_INFORMATION,
          mobileUrl.STORE_DOCUMENT,
          desktopUrl.PRODUCTS_LIST,
          desktopUrl.PRODUCTS_ADD,
          mobileUrl.PRODUCT_ASSETS,
          mobileUrl.DOCUMENT_FORM,
          mobileUrl.DOCUMENT_FORM_BANK,
          mobileUrl.STORE_NOTES,
          mobileUrl.STORE_NOTES_FORM,
          mobileUrl.PRODUCT_INFORMATION,
          mobileUrl.PRODUCT_CATEGORY,
          mobileUrl.PRODUCT_DETAIL,
          mobileUrl.ORDERS_LIST,
          mobileUrl.CHAT,
          mobileUrl.CHAT_SETTING,
          mobileUrl.CHATROOM,
          mobileUrl.STORE_STATUS,
          mobileUrl.STORE_LOCATION,
          mobileUrl.STORE_LOCATION_FORM,
          mobileUrl.PRODUCT_SHIPPING,
          mobileUrl.STORE_REPLY_TEMPLATE,
          mobileUrl.STORE_REPLY_TEMPLATE_FORM,
          mobileUrl.ORDERS_DETAIL,
          mobileUrl.STATISTICS,
          mobileUrl.DISCOUNT,
          mobileUrl.DISCOUNT_ADD,
          mobileUrl.DISCOUNT_ADD_SINGLE,
          mobileUrl.DISCOUNT_ADD_VARIANT,
          mobileUrl.DISCOUNT_ADD_BULK,
          mobileUrl.STORE_SELLER_FLEET,
          mobileUrl.STORE_SELLER_FLEET_LIST,
          mobileUrl.STORE_SELLER_FLEET_LIST_DETAIL,
          mobileUrl.PRODUCT_SPECIFICATION,
          mobileUrl.VARIANT_FORM,
          mobileUrl.STORE_SELLER_FLEET_DELIVERY_REGION,
          mobileUrl.NOTIFICATION,
          mobileUrl.STORE_SELLER_FLEET_SHIPPING_RATES,
          mobileUrl.CHAT_ORDER,
          mobileUrl.SNI_INPUT,
        ]}
        exact
      >
        {isDashboardPage && (
          <TourProvider
            steps={stepTourMobile}
            ContentComponent={useCustomTour}
            showDots
            styles={styles}
          >
            <PrivateRoute
              path={mobileUrl.DASHBOARD}
              component={Dashboard}
              exact
            />
          </TourProvider>
        )}

        {/* <PrivateRoute path={mobileUrl.DASHBOARD} component={Dashboard} exact /> */}
        <PrivateRoute path={mobileUrl.ACCOUNT} component={Account} exact />
        <PrivateRoute
          path={mobileUrl.ACCOUNT_RATING_REVIEW}
          component={AccountReview}
          exact
        />
        <PrivateRoute
          path={mobileUrl.STORE_STATUS}
          component={AccountStoreStatus}
          storeParams={{
            params: {
              need: "total_product",
            },
            query: "get-store-detail-store-status",
          }}
          exact
        />
        <PrivateRoute
          path={desktopUrl.PRODUCTS_LIST}
          component={ProductList}
          exact
        />
        <PrivateRoute
          path={mobileUrl.STORE_INFORMATION}
          component={Information}
          exact
        />
        <PrivateRoute
          path={mobileUrl.STORE_DOCUMENT}
          component={Document}
          exact
        />
        <PrivateRoute
          path={mobileUrl.DOCUMENT_FORM}
          component={DocumentForm}
          exact
        />
        <PrivateRoute
          path={mobileUrl.DOCUMENT_FORM_BANK}
          component={DocumentFormBank}
          exact
        />
        <PrivateRoute path={mobileUrl.STORE_NOTES} component={Note} exact />
        <PrivateRoute
          path={mobileUrl.STORE_NOTES_FORM}
          component={NoteForm}
          exact
        />
        <PrivateRoute
          path={mobileUrl.STORE_LOCATION}
          component={AccountLocation}
          exact
        />
        <PrivateRoute
          path={mobileUrl.STORE_LOCATION_FORM}
          component={AccountLocationForm}
          exact
        />
        <PrivateRoute
          path={mobileUrl.STORE_REPLY_TEMPLATE}
          component={AccountReplyTemplate}
          exact
        />
        <PrivateRoute
          path={mobileUrl.STORE_REPLY_TEMPLATE_FORM}
          component={AccountReplyTemplateAdd}
          exact
        />
        <PrivateRoute path={mobileUrl.STATISTICS} component={Statistic} exact />
        <PrivateRoute
          path={mobileUrl.NOTIFICATION}
          component={Notification}
          exact
        />

        <ProductAddProvider>
          <PrivateRoute
            path={desktopUrl.PRODUCTS_ADD}
            component={ProductAdd}
            exact
          />
          <PrivateRoute
            path={mobileUrl.PRODUCT_ASSETS}
            component={ProductAssets}
            exact
          />
          <PrivateRoute
            path={mobileUrl.PRODUCT_INFORMATION}
            component={ProductInformation}
            exact
          />
          <PrivateRoute
            path={mobileUrl.PRODUCT_CATEGORY}
            component={ProductCategories}
            exact
          />
          <PrivateRoute
            path={mobileUrl.PRODUCT_DETAIL}
            component={ProductDetail}
            exact
          />
          <PrivateRoute
            path={mobileUrl.ORDERS_LIST}
            component={OrderManagement}
            exact
          />
          <PrivateRoute
            path={mobileUrl.ORDERS_DETAIL}
            component={OrderManagementDetail}
            exact
          />
          <PrivateRoute
            path={mobileUrl.CHAT}
            component={ChatRoom}
            withQiscus={true}
            exact
          />
          <PrivateRoute
            path={mobileUrl.CHATROOM}
            component={ChatRoomItems}
            withQiscus={true}
            exact
          />
          <PrivateRoute
            path={mobileUrl.CHAT_SETTING}
            component={ChatSetting}
            exact
          />
          <PrivateRoute
            path={mobileUrl.CHAT_ORDER}
            component={ChatAttachOrder}
            exact
          />
          <PrivateRoute
            path={mobileUrl.PRODUCT_SHIPPING}
            component={ProductShipping}
            exact
          />
          {/* <PrivateRoute
            path={mobileUrl.DISCOUNT}
            component={PromoManagement}
            exact
          />
          <PrivateRoute
            path={mobileUrl.DISCOUNT_ADD}
            component={AddPromo}
            exact
          />
          <PrivateRoute
            path={mobileUrl.DISCOUNT_ADD_SINGLE}
            component={AddSinglePromo}
            exact
          />
          <PrivateRoute
            path={mobileUrl.DISCOUNT_ADD_VARIANT}
            component={AddVariantPromo}
            exact
          />
          <PrivateRoute
            path={mobileUrl.DISCOUNT_ADD_BULK}
            component={AddBulkPromo}
            exact
          /> */}
          <PrivateRoute
            path={mobileUrl.PRODUCT_SPECIFICATION}
            component={ProductSpesification}
            exact
          />
          <PrivateRoute
            path={mobileUrl.VARIANT_FORM}
            component={VariantSelection}
            exact
          />
          <PrivateRoute path={mobileUrl.SNI_INPUT} component={SniInput} exact />
        </ProductAddProvider>

        <DeliveryProvider>
          <PrivateRoute
            path={mobileUrl.STORE_SELLER_FLEET}
            component={DeliveryService}
            exact
          />
          <PrivateRoute
            path={mobileUrl.STORE_SELLER_FLEET_LIST}
            component={ListSetting}
            exact
          />
          <PrivateRoute
            path={mobileUrl.STORE_SELLER_FLEET_LIST_DETAIL}
            component={ListSettingDetail}
            exact
          />
          <PrivateRoute
            path={mobileUrl.STORE_SELLER_FLEET_DELIVERY_REGION}
            component={DeliveryRegion}
            exact
          />
          <PrivateRoute
            path={mobileUrl.STORE_SELLER_FLEET_SHIPPING_RATES}
            component={ShippingRates}
            exact
          />
        </DeliveryProvider>
      </Route>

      {/* route with print layout */}
      <Route path={[desktopUrl.PRINT_INVOICE, desktopUrl.PRINT_LABEL]} exact>
        <PrintLayout>
          <PrivateRoute
            path={`${desktopUrl.PRINT_INVOICE}`}
            component={PrintInvoice}
            exact
          />
          <PrivateRoute
            path={`${desktopUrl.PRINT_LABEL}`}
            component={PrintLabel}
            exact
          />
        </PrintLayout>
      </Route>
      <Route path={desktopUrl.REGISTER} exact>
        <Register />
      </Route>
      <Route path="*" exact component={NotFoundPage} />
    </Switch>
  ) : (
    <OfflinePage />
  );
};

export default MobileRouter;
