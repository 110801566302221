import { Flex, Text } from "renos-ui";

const EmptyData = () => {
  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      padding="24px"
      gap="40px"
      flexDirection="column"
      textAlign="center"
      marginTop="24px"
    >
      <Flex
        as="img"
        width="200px"
        height="160px"
        src="/assets/images/promo-management/empty-stock.svg"
      />

      <Flex gap="8px" flexDirection="column">
        <Text variant="heading3" weight="bold">
          List Kosong
        </Text>
        <Flex width="284px" marginBottom="43px">
          <Text>Belum ada produk pada halaman promosi ini</Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default EmptyData;
