import React, { useMemo, useState } from "react";
import {
  Flex,
  Text,
  Input,
  Icon,
  Button,
  Dialog,
  useDisclosure,
  Box,
  useToast,
  get,
  range,
  isEmpty,
  Tooltip,
} from "renos-ui";
import {
  useGetListTemplate,
  useSaveTemplate,
  useDeleteTemplate,
  useOrderTemplate,
  useActiveTemplate,
} from "services/chat";
import EmptyTemplate from "./EmptyTemplate";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { PanelBody, PanelHeading } from "./Panel";
import SkeletonTemplate from "./SkeletonTemplate";

const TemplateChat = () => {
  const toast = useToast();
  const showForm = useDisclosure({});
  const showConfirmation = useDisclosure({ open: false });
  const [tempIndex, setTempIndex] = useState("");
  const [tempSelected, setTempSelected] = useState(null);
  const { data, isLoading: isLoadingList, refetch } = useGetListTemplate();
  const [state, setState] = useState({
    id: "",
    title: "",
    message: "",
  });

  const { mutate: activeToggle } = useActiveTemplate({
    is_active: true,
  });

  const { mutate: mutateSaveChat } = useSaveTemplate({
    onError: () => {
      toast({
        label: "Template balasan chat gagal disimpan!",
        placement: "top",
        backgroundColor: "red50",
      });
      showForm.onClose();
      resetForm();
    },
    onSuccess: () => {
      toast({
        label: "Template balasan chat telah tersimpan!",
        placement: "top",
      });
      showForm.onClose();
      resetForm();
      refetch();
      activeToggle();
    },
  });

  const { mutate: mutateDeleteChat } = useDeleteTemplate({
    onError: () => {
      toast({
        label: "Template balasan chat gagal dihapus!",
        placement: "top",
        backgroundColor: "red50",
      });
      showConfirmation.onClose();
    },
    onSuccess: () => {
      toast({
        label: "Template balasan chat telah dihapus!",
        placement: "top",
      });
      showConfirmation.onClose();
      refetch();
    },
  });

  const { mutate: mutateOrderChat } = useOrderTemplate({
    onError: () => {
      toast({
        label: "Gagal menyusun ulang Template Chat",
        placement: "top",
        backgroundColor: "red50",
      });
    },
    onSuccess: () => {
      toast({
        label: "Berhasil menyusun ulang Template Chat",
        placement: "top",
      });
      refetch();
    },
  });

  const isNotReady = useMemo(
    () => isLoadingList || tempIndex,
    [isLoadingList, tempIndex]
  );

  const handleEdit = (index) => {
    const temp = data.data.templates[index];
    setTempSelected(temp);
    setState({
      ...state,
      id: temp.id,
      title: temp.title,
      message: temp.template_message,
    });
    showForm.onOpen();
  };

  const handleDeleteConfirm = (index) => {
    const temp = data.data.templates[index];
    setTempSelected(temp);
    showConfirmation.onOpen();
  };

  const handleSubmit = () => {
    if (tempSelected === null) {
      mutateSaveChat({
        title: state.title,
        message: state.message,
      });
    } else {
      mutateSaveChat({
        id: tempSelected.id,
        title: state.title,
        message: state.message,
      });
    }
  };

  const handleDelete = () => {
    mutateDeleteChat(tempSelected.id);
  };

  const resetForm = () => {
    showForm.onClose();
    setTempIndex("");
    setTempSelected(null);
    setState({
      title: "",
      message: "",
    });
  };

  const handleOnDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const draggableId = Number(get(result, "source.index"));
    const listData = data.data.templates[draggableId];
    const reOrderIndex = get(result, "destination.index");

    mutateOrderChat({
      id: listData.id,
      template_order: reOrderIndex + 1,
    });
  };

  return (
    <Flex flexDirection="column" marginY="24px" gap="24px">
      <Flex alignItems="center">
        <Flex flexDirection="column" gap="4px" flexGrow="1">
          <Text weight="bold">Template Balasan Chat</Text>
          <Text color="black50" variant="caption">
            Satu klik untuk membalas chat dari pembeli. Kamu bisa membuat
            maksimal 5 template balasan chat.
          </Text>
        </Flex>
        <Button
          disabled={data?.data?.templates?.length === 5}
          variant="secondary"
          onClick={showForm.onOpen}
          preffix={<Icon size={20} name="Plus-outline" />}
        >
          Tambah Template
        </Button>
      </Flex>
      <PanelBody>
        <PanelHeading>
          <Text weight="bold" variant="caption">
            List template
          </Text>
        </PanelHeading>
        {isNotReady ? (
          <Flex flexDirection="column" marginY="16px">
            {range(5).map((_, i) => (
              <SkeletonTemplate key={i} />
            ))}
          </Flex>
        ) : data?.data?.templates.length > 0 ? (
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="auto-template">
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  <Flex flexDirection="column" marginY="16px">
                    {data?.data?.templates.map((row, index) => (
                      <Draggable
                        key={row.id}
                        draggableId={String(row.id)}
                        index={index}
                      >
                        {(provided) => (
                          <Flex
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            padding="8px 24px"
                            key={`list-template-chat-${row.id}`}
                          >
                            <Flex alignItems="center" gap="20px" width="100%">
                              <Tooltip
                                placement="right"
                                width="103px"
                                height="14px"
                                padding="16px 0"
                                zIndex={1000}
                                backgroundColor="black100"
                                label={
                                  <Flex
                                    flexDirection="column"
                                    gap="4px"
                                    color="white"
                                  >
                                    <Text variant="small">
                                      Atur urutan posisi
                                    </Text>
                                    <Text variant="small">
                                      template balasan
                                    </Text>
                                  </Flex>
                                }
                              >
                                <Icon size={20} name="HamburgerMenu-outline" />
                              </Tooltip>

                              <Flex
                                alignItems="center"
                                justifyContent="space-between"
                                width="100%"
                              >
                                <Flex
                                  flexDirection="column"
                                  gap="4px"
                                  maxWidth="600px"
                                >
                                  <Text weight="bold" variant="caption">
                                    {row.title}
                                  </Text>
                                  <Text
                                    color="black75"
                                    variant="caption"
                                    style={{ whiteSpace: "pre-line" }}
                                  >
                                    {row.template_message}
                                  </Text>
                                </Flex>
                                <Flex
                                  gap="24px"
                                  cursor="pointer"
                                  color="blue50"
                                >
                                  <Text
                                    variant="caption"
                                    onClick={() => handleEdit(index)}
                                  >
                                    Ubah
                                  </Text>
                                  {data?.data?.templates.length > 1 && (
                                    <Text
                                      variant="caption"
                                      onClick={() => handleDeleteConfirm(index)}
                                    >
                                      Hapus
                                    </Text>
                                  )}
                                </Flex>
                              </Flex>
                            </Flex>
                          </Flex>
                        )}
                      </Draggable>
                    ))}
                  </Flex>
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        ) : (
          <EmptyTemplate />
        )}
      </PanelBody>

      {/* dialog add or edit  */}
      <Dialog
        isVisible={showForm.isOpen}
        onClose={resetForm}
        closable
        verticalCentered
        width="340px"
      >
        <Flex flexDirection="column" width="100%" gap="24px">
          <Text variant="heading3" weight="bold">
            {tempSelected === null ? "Buat" : "Ubah"} Template Chat
          </Text>

          <Flex flexDirection="column" gap="16px">
            <Box width="100%">
              <Input
                label="Nama Template"
                name="title"
                placeholder="Masukan nama template"
                value={state.title}
                showCount
                maxLength={30}
                onChange={(event) =>
                  setState({
                    ...state,
                    title: event.target.value,
                  })
                }
              />
            </Box>
          </Flex>

          <Flex flexDirection="column" gap="16px">
            <Input.TextArea
              label="Isi Template"
              name="note"
              placeholder="Masukan template balasan"
              value={state.message}
              showCount
              maxLength={200}
              style={{
                resize: "none",
                fontSize: "12px",
              }}
              rows="8"
              onChange={(event) =>
                setState({
                  ...state,
                  message: event.target.value,
                })
              }
            />
          </Flex>

          <Flex justifyContent="space-between" gap="16px">
            <Button isBlock variant="tertiary" onClick={resetForm}>
              Batal
            </Button>
            <Button
              isBlock
              disabled={state.title.length < 5 || state.message.length < 5}
              onClick={handleSubmit}
            >
              <Text
                weight="bold"
                color={
                  isEmpty(state.title) || isEmpty(state.message)
                    ? "black25"
                    : "white"
                }
              >
                Simpan
              </Text>
            </Button>
          </Flex>
        </Flex>
      </Dialog>

      {/* dialog delete */}
      <Dialog
        isVisible={showConfirmation.isOpen}
        onClose={showConfirmation.onClose}
        verticalCentered
        width="300px"
      >
        <Flex
          width="100%"
          fontFamily="sans-serif"
          flexDirection="column"
          rowGap="24px"
        >
          <Flex flexDirection="column" rowGap="8px">
            <Text variant="heading3" weight="bold">
              Hapus template ini?
            </Text>
            <Text variant="body">
              Pastikan kamu sudah memilih template yang benar yaa!
            </Text>
          </Flex>
          <Flex flexDirection="row" justifyContent="space-between" gap="16px">
            <Button
              isBlock
              size="large"
              variant="tertiary"
              onClick={handleDelete}
            >
              Hapus
            </Button>
            <Button
              isBlock
              size="large"
              variant="primary"
              onClick={showConfirmation.onClose}
            >
              Kembali
            </Button>
          </Flex>
        </Flex>
      </Dialog>
    </Flex>
  );
};

export default TemplateChat;
