import React from "react";
import { Checkbox, Flex, Text } from "renos-ui";

const FilterStatus = () => {
  return (
    <Flex padding="24px" flexDirection="column" width="100%" rowGap="16px">
      <Flex flexDirection="column">
        <Text weight="bold" color="black75">
          Status Produk
        </Text>
        <Text variant="caption" color="black75">
          Atur filter berdasarkan status produk aktif / tidak aktif.
        </Text>
      </Flex>

      <Flex
        flexWrap="wrap"
        justifyContent="space-between"
        gap="24px"
        width="80%"
      >
        <Flex alignItems="center" columnGap="8px" width="40%">
          <Checkbox />
          <Text color="black75" variant="caption">
            Produk Baru
          </Text>
        </Flex>
        <Flex alignItems="center" columnGap="8px" width="40%">
          <Checkbox />
          <Text color="black75" variant="caption">
            Produk Bekas
          </Text>
        </Flex>
        <Flex alignItems="center" columnGap="8px" width="40%">
          <Checkbox />
          <Text color="black75" variant="caption">
            Pre Order
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default FilterStatus;
