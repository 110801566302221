import { Flex, Skeleton } from "renos-ui";

const Loading = () => {
  return (
    <Flex
      p="16px"
      borderBottomColor="black5"
      borderBottomWidth="8px"
      borderBottomStyle="solid"
      gap="16px"
      alignItems="center"
    >
      <Skeleton width="64px" height="64px" />
      <Flex width="calc(100% - 116px)" flexDirection="column" gap="2px">
        <Skeleton width="70%" height="20px" />
        <Skeleton width="40%" height="20px" />
        <Flex gap="4px">
          <Skeleton width="51px" height="20px" />
          <Skeleton width="51px" height="20px" />
          <Skeleton width="51px" height="20px" />
          <Skeleton width="51px" height="20px" />
        </Flex>
      </Flex>
      <Skeleton width="20px" height="20px" />
    </Flex>
  );
};

export default Loading;
