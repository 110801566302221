import { useEffect, useMemo, useState } from "react";
import {
  Button,
  Checkbox,
  Dialog,
  Divider,
  Flex,
  Grid,
  Text,
  Tooltip,
  isEmpty,
  Icon,
} from "renos-ui";
import { useGetSellerFleetCategories } from "services/logistics/sellerFleet";
import LoadingModal from "./loadingModal";

const ModalCategory = ({
  isOpen,
  onClose,
  dataCategories,
  setDataCategories,
  setDataRates,
  dataRates,
}) => {
  const [dataModal, setDataModal] = useState({
    changed: false,
    categories: [],
  });
  const [countActive, setCountActive] = useState(false);
  const { isLoading, refetch, isRefetching } = useGetSellerFleetCategories({
    onSuccess: ({ data }) => {
      if (!isEmpty(data)) {
        if (dataCategories.length === 0)
          setDataModal({ changed: false, categories: [...data.categories] });
      }
    },
  });

  const isLoadingAll = useMemo(
    () => isLoading || isRefetching,
    [isLoading, isRefetching]
  );

  const handleOnChangeCheckbox = (categoryId) => {
    const tempCategories = dataModal.categories.map((dataCategories) => ({
      ...dataCategories,
      categories: dataCategories.categories.map((dataCategoriesLvl2) => ({
        ...dataCategoriesLvl2,
        categories: dataCategoriesLvl2.categories.map((dataCategoriesLvl3) => ({
          ...dataCategoriesLvl3,
          active:
            dataCategoriesLvl3.category_id === categoryId
              ? !dataCategoriesLvl3.active
              : dataCategoriesLvl3.active,
        })),
      })),
    }));

    const hasActiveItems = tempCategories.some((dataCategories) =>
      dataCategories.categories.some((dataCategoriesLvl2) =>
        dataCategoriesLvl2.categories.some(
          (dataCategoriesLvl3) => dataCategoriesLvl3.active
        )
      )
    );

    setCountActive(hasActiveItems);

    setDataModal({ changed: true, categories: [...tempCategories] });
  };

  const handleSave = () => {
    setDataCategories([...dataModal.categories]);
    onClose();
  };

  const handleIsAllChecked = (dataCategories) => {
    const totalActiveCategory = dataCategories.filter(
      (categories) => categories.active
    ).length;
    const checkResult = {
      active: false,
      indeterminate: false,
    };
    if (
      totalActiveCategory === dataCategories.length &&
      dataCategories.length !== 0
    )
      checkResult.active = true;
    if (totalActiveCategory > 0 && !checkResult.active)
      checkResult.indeterminate = true;

    return checkResult;
  };

  const handleCheckAll = (indexCategories, indexCategoriesLvl2) => {
    const tempCategories = dataModal.categories;
    let categoriesLvl3 =
      tempCategories[indexCategories].categories[indexCategoriesLvl2]
        .categories;
    const filterActive = categoriesLvl3.filter(
      (categories) => categories.active
    );

    if (filterActive.length === categoriesLvl3.length) {
      categoriesLvl3 = categoriesLvl3.map((categories) => ({
        ...categories,
        active: false,
      }));
    } else {
      categoriesLvl3 = categoriesLvl3.map((categories) => ({
        ...categories,
        active: true,
      }));
    }

    tempCategories[indexCategories].categories[indexCategoriesLvl2].categories =
      categoriesLvl3;

    const hasActiveItems = tempCategories.some((dataCategories) =>
      dataCategories.categories.some((dataCategoriesLvl2) =>
        dataCategoriesLvl2.categories.some(
          (dataCategoriesLvl3) => dataCategoriesLvl3.active
        )
      )
    );

    setCountActive(hasActiveItems);

    setDataModal({ changed: true, categories: [...tempCategories] });
  };

  useEffect(() => {
    setDataModal({ changed: false, categories: dataCategories });

    if (dataCategories.length === 0) refetch(); //eslint-disable-next-line
  }, [isOpen]);

  return (
    <Dialog isVisible={isOpen} onClose={onClose} verticalCentered>
      <Flex width="100%" flexDirection="column" rowGap="24px">
        <Flex justifyContent="space-between" alignContent="center">
          <Flex
            alignContent="center"
            justifyItems="center"
            alignItems="center"
            gap="8px"
          >
            <Text variant="heading3" weight="bold">
              Pilih Kategori
            </Text>
          </Flex>
          <Icon
            name="Close-outline"
            cursor="pointer"
            size={24}
            color="black60"
            onClick={() => {
              onClose();
              setDataRates({
                ...dataRates,
                active: false,
                categories: [],
              });
            }}
          />
        </Flex>
        {/* <Input
          placeholder="Cari nama kategori"
          prefix={<Icon name="Search-outline" size={20} />}
          onChange={(event) => handleSearch(event)}
        /> */}

        <Flex
          flexDirection="column"
          borderColor="black10"
          borderStyle="solid"
          borderWidth="1px"
          borderRadius="8px"
          px="16px"
        >
          <Flex
            flexDirection="column"
            gap="16px"
            height="208px"
            overflowX="auto"
            py="16px"
          >
            {isLoadingAll ? (
              <LoadingModal />
            ) : (
              dataModal.categories.map((categoriesLvl1, indexCategories) => (
                <Flex
                  gap="16px"
                  key={`categories-level-one-${indexCategories}`}
                  flexDirection="column"
                >
                  <Text
                    color="black50"
                    variant="subtitle"
                    weight="bold"
                    lineClamp={1}
                  >
                    {categoriesLvl1.category_name}
                  </Text>

                  {categoriesLvl1.categories.map(
                    (categoriesLvl2, indexCategoriesLvl2) => (
                      <Flex
                        flexDirection="column"
                        gap="12px"
                        key={`categories-level-2-${indexCategories}${indexCategoriesLvl2}`}
                      >
                        <Flex gap="12px" alignItems="center">
                          <Checkbox
                            onChange={() =>
                              handleCheckAll(
                                indexCategories,
                                indexCategoriesLvl2
                              )
                            }
                            checked={
                              handleIsAllChecked(categoriesLvl2.categories)
                                .active
                            }
                            indeterminate={
                              handleIsAllChecked(categoriesLvl2.categories)
                                .indeterminate
                            }
                          />
                          <Text color="black75" weight="bold" lineClamp={1}>
                            {categoriesLvl2.category_name}
                          </Text>
                        </Flex>
                        <Divider />
                        <Grid gap="8px" gridTemplateColumns="196px 196px 196px">
                          {categoriesLvl2.categories.map(
                            (categoriesLvl3, indexCategoriesLvl3) => (
                              <Flex
                                gap="8px"
                                key={`categories-level-3-${indexCategories}${indexCategoriesLvl2}${indexCategoriesLvl3}`}
                                alignItems="center"
                              >
                                <Flex width="20px">
                                  <Checkbox
                                    checked={categoriesLvl3.active}
                                    onChange={() =>
                                      handleOnChangeCheckbox(
                                        categoriesLvl3.category_id
                                      )
                                    }
                                  />
                                </Flex>
                                <Tooltip label={categoriesLvl3.category_name}>
                                  <Text color="black75" lineClamp={1}>
                                    {categoriesLvl3.category_name}
                                  </Text>
                                </Tooltip>
                              </Flex>
                            )
                          )}
                        </Grid>
                      </Flex>
                    )
                  )}
                </Flex>
              ))
            )}
          </Flex>
        </Flex>
        <Flex alignSelf="end" width="160px">
          <Button
            disabled={!countActive}
            size="medium"
            isBlock
            onClick={handleSave}
          >
            Simpan
          </Button>
        </Flex>
      </Flex>
    </Dialog>
  );
};

export default ModalCategory;
