export const DEFAULT_FORM = {
  store_name: "",
  name: "",
  contact_person: "",
  address: "",
  contact_number: "",
  coordinates: {
    latitude: "",
    longitude: "",
  },
  province_id: "",
  province_name: "",
  city_id: "",
  city_name: "",
  district_id: "",
  district_name: "",
  lower_district_id: "",
  lower_district_name: "",
  postal_code: "",
  is_primary: false,
  point_address: "",
  description: "alamat",
};

export const EMPTY_OPTION = {
  label: "",
  value: "",
};