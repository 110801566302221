import { Flex, colors, Text, Icon } from "renos-ui";

const Items = ({
  title,
  handleOpenPreview,
  handleDelete,
  handleEdit,
  index,
}) => {
  return (
    <Flex
      padding="20px 24px"
      alignItems="center"
      justifyContent="space-between"
      marginX="24px"
      borderRadius="8px"
      border={`1px solid ${colors.black10}`}
    >
      <Text variant="subtitle" weight="bold">
        {title}
      </Text>
      <Flex gap="20px">
        <Flex
          alignItems="center"
          gap="4px"
          cursor="pointer"
          onClick={() => handleOpenPreview(index)}
        >
          <Flex style={{ pointerEvents: "none" }}>
            <Icon name="Show-solid" color="black50" size={20} />
          </Flex>
          <Text color="black50">Preview</Text>
        </Flex>

        <Flex
          alignItems="center"
          gap="4px"
          cursor="pointer"
          onClick={() => handleEdit(index)}
        >
          <Flex style={{ pointerEvents: "none" }}>
            <Icon name="Edit-solid" color="black50" size={20} />
          </Flex>
          <Text color="black50">Ubah</Text>
        </Flex>

        <Flex
          alignItems="center"
          gap="4px"
          cursor="pointer"
          onClick={() => handleDelete(index)}
        >
          <Flex style={{ pointerEvents: "none" }}>
            <Icon name="Trash-solid" color="black50" size={20} />
          </Flex>
          <Text color="black50">Hapus</Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Items;
