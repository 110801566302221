import { Flex, Icon, Text } from "renos-ui";

const SearchBar = () => {
  return (
    <Flex
      backgroundColor="white"
      borderTopLeftRadius="16px"
      borderTopRightRadius="16px"
      p="8px 16px"
      gap="16px"
      alignItems="center"
    >
      <Icon size={24} color="black50" name="Back-outline" />
      <Flex
        width="calc(208px - 24px)"
        height="calc(32px - 12px)"
        padding="4px 12px"
        borderRadius="100px"
        gap="8px"
        backgroundColor="black5"
        alignItems="center"
      >
        <Icon size={16} color="black50" name="Search-outline" />
        <Text color="black25">Ingin cari apa?</Text>
      </Flex>
      <Icon size={24} color="black50" name="Cart-outline" />
      <Icon size={24} color="black50" name="Vertical-outline" />
    </Flex>
  );
};

export default SearchBar;
