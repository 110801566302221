import { Flex, Skeleton, Divider, Grid, Each, range } from "renos-ui";

const LoadingIncomingRequest = () => {
  return (
    <Flex flexDirection="column" gap="24px" marginTop={24}>
      <Each
        of={range(3)}
        render={() => (
          <Flex
            flexDirection="column"
            gap="24px"
            backgroundColor="white"
            borderRadius={16}
            py={16}
            px={24}
            boxShadow="0px 4px 4px rgba(20, 20, 32, 0.05)"
          >
            <Flex
              justifyContent="space-between"
              alignItems="center"
              paddingBottom={16}
              borderBottomWidth={1}
              borderBottomStyle="solid"
              borderBottomColor="black10"
            >
              <Flex alignItems="center" gap="8px">
                <Skeleton height="20px" width="300px" />
              </Flex>
              <Skeleton height="40px" width="203px" />
            </Flex>
            <Flex gap="16px">
              <Flex alignItems="center" gap="8px">
                <Skeleton width="250px" />
              </Flex>
              <Divider orientation="vertical" />
              <Flex alignItems="center" gap="8px">
                <Skeleton width="250px" />
              </Flex>
              <Divider orientation="vertical" />
              <Flex alignItems="center" gap="8px">
                <Skeleton width="250px" />
              </Flex>
            </Flex>
            <Grid gridTemplateColumns="1fr 1fr 1fr" gap="16px">
              <Each
                of={range(3)}
                render={() => (
                  <Flex
                    padding={16}
                    flexDirection="column"
                    gap="4px"
                    borderRadius={8}
                    borderWidth={1}
                    borderStyle="solid"
                    borderColor="black10"
                  >
                    <Skeleton width="300px" />
                    <Skeleton width="100px" height="20px" />
                  </Flex>
                )}
              />
            </Grid>
          </Flex>
        )}
      />
    </Flex>
  );
};

export default LoadingIncomingRequest;
