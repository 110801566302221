import React from "react";
import { Box, Flex, Text } from "renos-ui";

const LandingTutorial = (props) => {
  return (
    <Flex
      flexDirection="column"
      marginTop="120px"
      marginBottom="64px"
      width="100%"
      alignItems="center"
      rowGap="40px"
      {...props}
    >
      <Flex
        rowGap="16px"
        flexDirection="column"
        width="400px"
        textAlign="center"
      >
        <Text variant="heading1" weight="bold">
          Cara berjualan di Renos
        </Text>
        <Text variant="subtitle" color="black75">
          3 Tahapan mudah untuk mulai berjualan di Renos.
        </Text>
      </Flex>
      <Flex columnGap="116px" justifyContent="space-between">
        <Flex
          position="relative"
          flexDirection="column"
          rowGap="8px"
          alignItems="center"
          textAlign="center"
        >
          <Flex
            width="48px"
            height="48px"
            backgroundColor="blue50"
            position="absolute"
            top="20px"
            left="0"
            justifyContent="center"
            alignItems="center"
            borderRadius="50px"
          >
            <Text variant="subtitle" weight="bold" color="white">
              1
            </Text>
          </Flex>
          <Box as="img" src="/assets/images/landing/step-selling-1.svg" />
          <Box width="200px">
            <Text variant="subtitle" weight="bold" color="black75">
              Registrasi Akun dan Lengkapi Data
            </Text>
          </Box>
        </Flex>
        <Flex
          position="relative"
          flexDirection="column"
          rowGap="8px"
          alignItems="center"
          textAlign="center"
        >
          <Flex
            width="48px"
            height="48px"
            backgroundColor="blue50"
            position="absolute"
            top="20px"
            left="0"
            justifyContent="center"
            alignItems="center"
            borderRadius="50px"
          >
            <Text variant="subtitle" weight="bold" color="white">
              2
            </Text>
          </Flex>
          <Box as="img" src="/assets/images/landing/step-selling-2.svg" />
          <Box width="200px">
            <Text variant="subtitle" weight="bold" color="black75">
              Daftarkan Barang Jualanmu
            </Text>
          </Box>
        </Flex>
        <Flex
          position="relative"
          flexDirection="column"
          rowGap="8px"
          alignItems="center"
          textAlign="center"
        >
          <Flex
            width="48px"
            height="48px"
            backgroundColor="blue50"
            position="absolute"
            top="20px"
            left="0"
            justifyContent="center"
            alignItems="center"
            borderRadius="50px"
          >
            <Text variant="subtitle" weight="bold" color="white">
              3
            </Text>
          </Flex>
          <Box as="img" src="/assets/images/landing/step-selling-3.svg" />
          <Box width="200px">
            <Text variant="subtitle" weight="bold" color="black75">
              Proses Pesanan Pembeli
            </Text>
          </Box>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default LandingTutorial;
