import React, { useContext, useMemo } from "react";
import { Flex, Text, Box, Label, Icon } from "renos-ui";
import { StyledChipDiscount } from "../styled";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import StoreContext from "providers/StoreProvider";

const CardProduct = ({
  item,
  placement = "left",
  isAttachment = false,
  removeAttachment,
}) => {
  const { push } = useHistory();
  const {
    storeData: {
      data: { slug: storeSlug },
    },
  } = useContext(StoreContext);
  const content = item?.content;
  const productURL = content?.productURL || content?.product_url;
  const productName = content?.productName || content?.product_name;
  const productImage = content?.productImage || content?.product_image_url;
  const productPrice =
    content?.fullPrice || content?.default?.formatted_product_price;
  const sellingPrice =
    content?.sellingPrice || content?.default?.formatted_selling_price;
  const discount =
    content?.discount ||
    content?.default?.formatted_discount_percentage?.toString()?.split("%")[0];
  const orderNumber = content?.orderNumber;
  const orderStatus = content?.orderStatus;
  const origins = content?.origins;

  const selectedProducts = content?.selectedProducts || [];

  const handleClickPDP = (url) => {
    if (!productURL && !url) {
      return;
    }
    let fullUrl = productURL || url;

    if (!fullUrl?.includes("https://")) {
      fullUrl = `${process.env.REACT_APP_BUYER_SITE}${fullUrl}`;
    }

    window.open(fullUrl, "_blank").focus();
  };

  const handleClickOrder = () => {
    push(`/sales/orders/detail?no=${orderNumber}`);
  };

  const handleLabel = () => {
    switch (orderStatus) {
      case "Diproses":
        return <Label variant="success">{orderStatus}</Label>;
      case "Dibatalkan":
        return <Label variant="error">{orderStatus}</Label>;
      default:
        return <Label variant="warning">{orderStatus}</Label>;
    }
  };

  const extrasOrigins = ["PDP", "ORDER"];

  const getWidth = useMemo(() => {
    if (isAttachment) {
      if (productName) return "100%";

      return "fit-content";
    }

    return "263px";
  }, [isAttachment, productName]);

  const getMaxWidth = useMemo(() => {
    if (isAttachment) {
      if (productName) return "100%";

      return "250px";
    }

    return "263px";
  }, [isAttachment, productName]);

  const getOrderImageSize = useMemo(() => {
    if (!isAttachment) {
      if (productName) return "60px";

      return "50px";
    }

    return "40px";
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAttachment]);

  return (
    <>
      {extrasOrigins?.includes(origins) && (
        <Flex
          padding="12px"
          width={getWidth}
          maxWidth={getMaxWidth}
          borderRadius="8px"
          backgroundColor={isAttachment ? "black5" : "blue5"}
          alignSelf={!isAttachment && placement === "right" && "flex-end"}
          borderTopRightRadius={!isAttachment && placement === "right" && "0"}
          borderTopLeftRadius={!isAttachment && placement === "left" && "0"}
        >
          {origins === "PDP" && (
            <Flex gap="8px" cursor="pointer" onClick={handleClickPDP}>
              <Box
                as="img"
                width="80px"
                height="80px"
                borderRadius="8px"
                alt="product-image"
                src={productImage}
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = "/assets/images/empty-product.svg";
                }}
              />
              <Flex flexDirection="column" justifyContent="center">
                <Text variant="caption" color="black50" lineClamp={2}>
                  {productName}
                </Text>
                {discount && (
                  <Flex gap="4px" alignItems="center">
                    <Flex>
                      <StyledChipDiscount
                        color="red50"
                        fontWeight="bold"
                        backgroundColor="red5"
                        label={`${discount}%`}
                      />
                    </Flex>
                    <Flex>
                      <Text variant="small" strikeThrough>
                        {productPrice}
                      </Text>
                    </Flex>
                  </Flex>
                )}
                <Flex>
                  <Text weight="bold">{sellingPrice}</Text>
                </Flex>
              </Flex>
            </Flex>
          )}

          {origins === "ORDER" && (
            <Flex
              gap="8px"
              width={getWidth}
              maxWidth={getMaxWidth}
              onClick={handleClickOrder}
            >
              <Flex gap="8px" justifyContent="space-between" width="100%">
                <Flex gap="8px">
                  <Box
                    as="img"
                    alt="product-image"
                    width={getOrderImageSize}
                    height={getOrderImageSize}
                    borderRadius="8px"
                    src={productImage}
                    cursor="pointer"
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = "/assets/images/empty-product.svg";
                    }}
                  />
                  <Flex
                    flexDirection="column"
                    cursor="pointer"
                    gap={!isAttachment ? "8px" : "0"}
                  >
                    {handleLabel()}
                    <Text
                      variant="caption"
                      lineClamp={isAttachment ? 1 : 2}
                      style={{ wordBreak: "break-word" }}
                    >
                      {orderNumber}
                    </Text>
                  </Flex>
                </Flex>
                {removeAttachment && (
                  <Flex cursor="pointer" onClick={removeAttachment}>
                    <Icon name="Close-solid" size={16} color="black50" />
                  </Flex>
                )}
              </Flex>
            </Flex>
          )}
        </Flex>
      )}

      {origins === "IMAGE" && (
        <Flex gap="8px">
          <Box
            as="img"
            alt="product-image"
            width="80px"
            height="80px"
            cursor="pointer"
            borderRadius="8px"
            src={productImage}
            onClick={() => window.open(productImage, "_blank").focus()}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = "/assets/images/empty-product.svg";
            }}
          />
          <Flex flexDirection="column" justifyContent="center" gap="8px">
            <Text>{orderStatus}</Text>
            <Text variant="caption" lineClamp={2} color="black50">
              {orderNumber}
            </Text>
          </Flex>
        </Flex>
      )}

      {origins === "PRODUCTS" &&
        selectedProducts.map((product, index) => (
          <Flex
            padding="12px"
            borderRadius="8px"
            width={getWidth}
            maxWidth={getMaxWidth}
            flex="none"
            key={`chat-product-${index}`}
            backgroundColor={isAttachment ? "black5" : "blue5"}
            alignSelf={!isAttachment && placement === "right" && "flex-end"}
            borderTopRightRadius={!isAttachment && placement === "right" && "0"}
            borderTopLeftRadius={!isAttachment && placement === "left" && "0"}
          >
            {!isAttachment ? (
              <Flex gap="8px">
                <Box
                  as="img"
                  width="80px"
                  height="80px"
                  borderRadius="8px"
                  alt="product-image"
                  src={product.productImage}
                  cursor="pointer"
                  onClick={() =>
                    handleClickPDP(`/p/${storeSlug}/${product?.slug}`)
                  }
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = "/assets/images/empty-product.svg";
                  }}
                />
                <Flex
                  flexDirection="column"
                  justifyContent="center"
                  cursor="pointer"
                  onClick={() =>
                    handleClickPDP(`/p/${storeSlug}/${product?.slug}`)
                  }
                  style={{ wordBreak: "break-all" }}
                >
                  <Text variant="caption" color="black50" lineClamp={2}>
                    {product.productName}
                  </Text>
                  {product.discount > 0 && (
                    <Flex gap="4px" alignItems="center">
                      <Flex>
                        <StyledChipDiscount
                          color="red50"
                          fontWeight="bold"
                          backgroundColor="red5"
                          label={`${product?.discount}%`}
                        />
                      </Flex>
                      <Flex>
                        <Text variant="small" strikeThrough>
                          {product.fullPrice}
                        </Text>
                      </Flex>
                    </Flex>
                  )}
                  <Flex>
                    <Text weight="bold">{product?.sellingPrice}</Text>
                  </Flex>
                </Flex>
              </Flex>
            ) : (
              <Flex gap="8px">
                <Box
                  as="img"
                  width={isAttachment ? "40px" : "80px"}
                  height={isAttachment ? "40px" : "80px"}
                  borderRadius="8px"
                  alt="product-image"
                  src={product.product_image_url}
                  cursor="pointer"
                  onClick={() =>
                    handleClickPDP(`/p/${storeSlug}/${product?.product_slug}`)
                  }
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = "/assets/images/empty-product.svg";
                  }}
                />
                <Flex
                  flexDirection="column"
                  justifyContent="center"
                  cursor="pointer"
                  onClick={() =>
                    handleClickPDP(`/p/${storeSlug}/${product?.product_slug}`)
                  }
                  style={{ wordBreak: "break-all" }}
                >
                  <Text variant="caption" color="black50" lineClamp={2}>
                    {product.product_name}
                  </Text>
                  {(product?.formatted_discount_percentage ||
                    product?.discount_type_id) &&
                    !isAttachment && (
                      <Flex gap="4px" alignItems="center">
                        <Flex>
                          <StyledChipDiscount
                            color="red50"
                            fontWeight="bold"
                            backgroundColor="red5"
                            label={`${
                              product?.formatted_discount_percentage ||
                              product?.formatted_product_discount_percentage
                            }%`}
                          />
                        </Flex>
                        <Flex>
                          <Text variant="small" strikeThrough>
                            {product.formatted_product_price}
                          </Text>
                        </Flex>
                      </Flex>
                    )}
                  {!isAttachment && (
                    <Flex>
                      <Text weight="bold">
                        {product?.formatted_selling_price}
                      </Text>
                    </Flex>
                  )}
                </Flex>
                {removeAttachment && (
                  <Flex
                    cursor="pointer"
                    onClick={() => removeAttachment(product.product_id)}
                  >
                    <Icon name="Close-solid" size={16} color="black50" />
                  </Flex>
                )}
              </Flex>
            )}
          </Flex>
        ))}
    </>
  );
};

export default CardProduct;
