import { getYoutubeId } from "helpers";
import StoreLayoutContext from "providers/StoreLayoutProvider";
import { useContext, useEffect, useState } from "react";
import { Flex } from "renos-ui";

const BannerVideo = ({ isPreviewMode, id }) => {
  const { bannerVideoRef, dataHomePage, isHomePageLoading } =
    useContext(StoreLayoutContext);

  const [dataBannerVideo, setDataBannerVideo] = useState();
  const [isActive, setIsActive] = useState();

  useEffect(() => {
    const selectedBanner = dataHomePage.filter(
      (homepage) => homepage.id === id
    )[0];

    let linkEmbed = "";
    setIsActive(selectedBanner?.isActive);

    if (selectedBanner?.data?.length > 0) {
      const youtubeId = getYoutubeId(selectedBanner?.data[0].url);
      linkEmbed = `https://www.youtube.com/embed/${youtubeId}`;
    }

    setDataBannerVideo(linkEmbed);
    // eslint-disable-next-line
  }, [dataHomePage]);

  if (isPreviewMode && !isActive) return <></>;

  if (isHomePageLoading)
    return (
      <Flex>
        <Flex
          height="365px"
          width="730px"
          backgroundColor="black10"
          borderRadius="10px"
          ref={bannerVideoRef}
        />
      </Flex>
    );

  return (
    <Flex ref={bannerVideoRef}>
      {dataBannerVideo ? (
        <iframe
          height="365px"
          width="730px"
          data-testid="video-player"
          allowFullScreen
          src={`${dataBannerVideo}?autoplay=0`}
          title="video-player"
          style={{ borderWidth: "0" }}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        />
      ) : (
        <Flex
          backgroundColor={"black10"}
          height="365px"
          width="730px"
          borderRadius="10px"
        />
      )}
    </Flex>
  );
};

export default BannerVideo;
