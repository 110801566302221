import { goToUrl } from "helpers";
import React from "react";
import { Box, Button, Flex, Text } from "renos-ui";

const LandingAbout = (props) => {
  return (
    <Flex
      paddingTop="120px"
      paddingBottom="64px"
      justifyContent="center"
      width="100%"
      {...props}
    >
      <Flex
        flexDirection="column"
        rowGap="24px"
        textAlign="center"
        alignItems="center"
      >
        <Flex flexDirection="column" rowGap="16px">
          <Text variant="heading1" weight="bold">
            Mengapa Renos?
          </Text>
          <Box width="800px">
            <Text variant="subtitle" color="black75">
              Renos.id merupakan online marketplace yang berfokus kepada produk
              dan jasa kebutuhan rumah (home living & home improvement), dengan
              memanfaatkan teknologi yang menjadi passion kami. Selain dapat
              membuka toko secara gratis, Renos juga memiliki beragam fitur yang
              akan menjamin kemudahan dan keamanan transaksi online jual beli
              yang seller dan calon pembeli lakukan.
            </Text>
          </Box>
        </Flex>
        <Button onClick={() => goToUrl("https://renos.id/about")} size="large">
          Selengkapnya Tentang Renos
        </Button>
      </Flex>
    </Flex>
  );
};

export default LandingAbout;
