import React from "react";
import { Flex, Text, isEmpty } from "renos-ui";
import { useHistory } from "react-router-dom";
import FormLayout from "./FormLayout";
import mobileUrl from "constants/mobileUrl";
import { useProductAddContext } from "store/productAdd/reducer";
import useFeatureFlag from "hooks/useFeatureFlag";
import { PRODUCT_PREORDER_OPTIONS } from "containers/web/product/add/single/constants";
// import { PRODUCT_PREORDER_OPTIONS } from "containers/web/product/add/single/constants";

const ProductShipping = () => {
  const isPreorderEnabled = useFeatureFlag("preorder");

  const { state } = useProductAddContext();
  const {
    productShipping,
    uom,
    weight,
    length,
    width,
    height,
    preorderType,
    shippingType,
    preorder,
    variant,
  } = state;

  const { push } = useHistory();

  const isFilled =
    !isEmpty(weight) ||
    !isEmpty(length) ||
    !isEmpty(width) ||
    !isEmpty(height) ||
    productShipping.size > 0 ||
    (preorder > 0 && isPreorderEnabled);

  return (
    <FormLayout
      title="Pengiriman"
      isFilled={isFilled}
      action={() => push(mobileUrl.PRODUCT_SHIPPING)}
    >
      {isFilled && (
        <>
          {variant === 0 && (
            <Flex flexDirection="column" rowGap="16px">
              <Flex flexDirection="column">
                <Text variant="small" color="black50">
                  Berat Produk
                </Text>
                <Text variant="caption" lineClamp={2}>
                  {`${weight} ${uom === 1 ? "kg" : "g"}`}
                </Text>
              </Flex>
            </Flex>
          )}
          <Flex flexDirection="column">
            <Text variant="small" color="black50">
              {`Ukuran Produk (PxLxT)`}
            </Text>
            <Text variant="caption">
              {length} x {width} x {height} cm
            </Text>
          </Flex>
          <Flex flexDirection="column">
            <Text variant="small" color="black50">
              Layanan Pengiriman
            </Text>
            <Text variant="caption">
              {shippingType === 1
                ? "Standar"
                : `Custom (${productShipping.size} Layanan)`}
            </Text>
          </Flex>
          {isPreorderEnabled && preorder > 0 && (
            <Flex flexDirection="column">
              <Text variant="small" color="black50">
                Pre Order
              </Text>
              <Text variant="caption">
                {`${preorder} ${
                  PRODUCT_PREORDER_OPTIONS.find(
                    (data) => data.value === preorderType
                  ).label
                }`}
              </Text>
            </Flex>
          )}
        </>
      )}
    </FormLayout>
  );
};

export default ProductShipping;
