import StoreContext from "providers/StoreProvider";
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Button, Drawer, Flex, Icon, Text } from "renos-ui";
import FormPickup from "./FormPickup";

const Popup3pl = ({ disclosure3pl, toFirstPage, orderId }) => {
  const {
    storeData: {
      data: { locations },
    },
  } = useContext(StoreContext);
  const address = locations.filter((location) => location.is_primary)[0];

  const { push } = useHistory();
  const { isOpen, onClose } = disclosure3pl;
  const [isConfirmed, setIsconfirmed] = useState(false);

  useEffect(() => {
    setIsconfirmed(false);
  }, [isOpen]);

  return (
    <Drawer isVisible={isOpen} onClose={onClose} placement="bottom">
      {isConfirmed ? (
        <FormPickup
          orderId={orderId}
          toFirstPage={toFirstPage}
          onClose={onClose}
          isOpen={isOpen}
          backToConfirmation={() => setIsconfirmed(false)}
        />
      ) : (
        <Flex flexDirection="column" width="100%" gap="16px" height="100%">
          <Flex gap="8px" alignItems="center">
            <Icon
              name="Close-outline"
              size={24}
              color="black50"
              onClick={onClose}
            />
            <Text variant="subtitle" weight="bold">
              Request Pick Up
            </Text>
          </Flex>
          <Flex flexDirection="column" width="100%" gap="24px">
            <Text variant="caption" color="black50">
              Kurir {"<<Nama 3PL>>"} akan melakukan penjemputan paket dari
              alamat pickup-mu.
            </Text>
            <Flex
              flexDirection="column"
              gap="8px"
              padding="10px 8px"
              backgroundColor="black5"
              borderRadius="8px"
            >
              <Text variant="caption" weight="bold">
                Alamat Pick Up
              </Text>
              <Flex flexDirection="column" gap="4px">
                <Text variant="caption" color="black75">
                  {" "}
                  {address.name} ({address.contact_number})
                </Text>
                <Text variant="caption" color="black75">
                  {address.address}
                </Text>
                <Text variant="caption" color="black75">
                  {`${address.lower_district_name}, ${address.district_name}, ${address.city_name}, ${address.province_name} - ${address.postal_code}`}
                </Text>
              </Flex>
              <Flex cursor="pointer" onClick={() => push("/account/location")}>
                <Text variant="caption" weight="bold" color="blue50">
                  Ubah Alamat Utama
                </Text>
              </Flex>
            </Flex>
          </Flex>

          <Flex gap="8px">
            <Button size="medium" isBlock onClick={() => setIsconfirmed(true)}>
              Konfirmasi
            </Button>
          </Flex>
        </Flex>
      )}
    </Drawer>
  );
};

export default Popup3pl;
