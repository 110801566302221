import EmptyTemplate from "containers/web/chat/chat-setting/EmptyTemplate";
import { useState } from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import {
  useActiveTemplate,
  useDeleteTemplate,
  useGetListTemplate,
  useOrderTemplate,
  useSaveTemplate,
} from "services/chat";
import SkeletonTemplate from "./SkeletonTemplate";
import TemplateItem from "./TemplateItem";

import {
  Flex,
  Box,
  Icon,
  Text,
  Toggle,
  Divider,
  get,
  useToast,
  range,
  Button,
  Skeleton,
  Drawer,
  useDisclosure,
} from "renos-ui";
import FormTemplate from "./FormTemplate";
import DrawerDelete from "./DrawerDelete";

const TemplateChat = () => {
  const toast = useToast();
  const [items, setItems] = useState([]);
  const [isActive, setIsActive] = useState(false);
  const formDrawerDisclosure = useDisclosure({ isOpen: false });
  const deleteConfirmDrawerDisclosure = useDisclosure({ isOpen: false });

  const [selectedItem, setSelectedItem] = useState({
    id: null,
    title: "",
    message: "",
  });

  /**
   * Get List Template
   */
  const {
    data,
    isFetching: isFetchingList,
    refetch,
  } = useGetListTemplate(
    {},
    {
      onSuccess: (data) => {
        setItems(data.data.templates);
        setIsActive(data.data.is_active);
      },
    }
  );

  /**
   * Set active template
   */
  const { mutate: activeToggle } = useActiveTemplate();

  const handleActiveToggle = () => {
    setIsActive(!isActive);
    activeToggle({
      is_active: !isActive,
    });
  };

  /**
   * Drag and Drop
   */
  const { mutate: mutateOrderChat } = useOrderTemplate({
    onError: () => {
      toast({
        label: "Gagal menyusun ulang Template Chat",
        placement: "bottom",
        backgroundColor: "red50",
      });
    },
    onSuccess: (data) => {
      setItems(data.data.data);
      toast({
        label: "Berhasil menyusun ulang Template Chat",
        placement: "bottom",
      });
    },
  });

  const { mutate: mutateSaveChat, isLoading: isLoadingSave } = useSaveTemplate({
    onError: () => {
      toast({
        label: "Template balasan chat gagal disimpan!",
        placement: "bottom",
        backgroundColor: "red50",
      });
      handleAndResetDrawer();
    },
    onSuccess: () => {
      let message = "Berhasil menambahkan template baru.";

      if (selectedItem.id) {
        message = "Template berhasil diubah!";
      }

      toast({
        label: message,
        placement: "bottom",
      });
      handleAndResetDrawer();
      refetch();
    },
  });

  const { mutate: mutateDeleteChat, isLoading: isLoadingDelete } =
    useDeleteTemplate({
      onError: () => {
        toast({
          label: "Template balasan chat gagal dihapus!",
          placement: "bottom",
          backgroundColor: "red50",
        });
        deleteConfirmDrawerDisclosure.onClose();
      },
      onSuccess: () => {
        toast({
          label: "Template telah berhasil dihapus.",
          placement: "bottom",
        });
        deleteConfirmDrawerDisclosure.onClose();
        refetch();
      },
    });

  const handleOnDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const destinationIndex = get(result, "destination.index");
    const sourceIndex = Number(get(result, "source.index"));

    let newItems = [...items];
    const [draggedItem] = newItems.splice(sourceIndex, 1);

    newItems.splice(destinationIndex, 0, draggedItem);
    newItems = newItems.map((item, index) => ({
      ...item,
      template_order: index + 1,
    }));

    setItems(newItems);
    const selected = newItems.find((item) => item.id === draggedItem.id);

    if (selected) {
      mutateOrderChat({
        id: selected.id,
        template_order: selected.template_order,
      });
    }
  };

  const handleAndResetDrawer = () => {
    setSelectedItem({
      id: null,
      title: "",
      message: "",
    });
    formDrawerDisclosure.onToggle();
  };

  const handleSubmit = () => {
    if (selectedItem.id === null) {
      mutateSaveChat({
        title: selectedItem.title,
        message: selectedItem.message,
      });
    } else {
      mutateSaveChat(selectedItem);
    }
  };

  return (
    <>
      <Flex gap="8px" mb="60px" flexDirection="column" backgroundColor="black5">
        <Flex padding="16px" backgroundColor="white">
          <Box width="100%" borderRadius="8px" backgroundColor="blue5">
            <Flex padding="12px" justifyContent="space-between">
              <Flex gap="8px" alignItems="center">
                <Flex flexDirection="column">
                  <Flex alignItems="center" gap="8px">
                    <Flex>
                      <Icon name="Info-solid" size="16px" color="blue25" />
                    </Flex>
                    <Flex>
                      <Text color="blue75" weight="bold">
                        Template Balasan Chat
                      </Text>
                    </Flex>
                  </Flex>
                  <Flex>
                    <Text variant="caption" color="blue75">
                      Satu klik untuk membalas chat dari pembeli. Kamu bisa
                      membuat maksimal 5 template balasan chat.
                    </Text>
                  </Flex>
                </Flex>
              </Flex>
              {/* Hide Toggle Activated */}
              <Flex display="none">
                {isFetchingList ? (
                  <Skeleton width="36px" height="20px" borderRadius="100px" />
                ) : (
                  <Box onClick={handleActiveToggle}>
                    <Toggle checked={isActive} />
                  </Box>
                )}
              </Flex>
            </Flex>
          </Box>
        </Flex>
        <Flex padding="16px" flexDirection="column" backgroundColor="white">
          <Flex pb="16px">
            <Text weight="bold">List Template</Text>
          </Flex>
          <Divider />
          {isFetchingList ? (
            <Flex flexDirection="column">
              {range(7).map((_, index) => (
                <SkeletonTemplate key={index} />
              ))}
            </Flex>
          ) : data?.data?.templates.length > 0 ? (
            <DragDropContext onDragEnd={handleOnDragEnd}>
              <Droppable droppableId="auto-template">
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    <Flex flexDirection="column">
                      {items?.map((item, index) => (
                        <TemplateItem
                          item={item}
                          index={index}
                          key={`template-${item.id}`}
                          itemCount={items.length - 1}
                          setSelectedItem={setSelectedItem}
                          isSingleItem={items.length === 1}
                          toggleDrawer={formDrawerDisclosure.onToggle}
                          onDelete={deleteConfirmDrawerDisclosure.onToggle}
                        />
                      ))}
                    </Flex>
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          ) : (
            <EmptyTemplate />
          )}
        </Flex>
        <Flex
          bottom="0"
          position="fixed"
          width="100%"
          justifyContent="center"
          backgroundColor="white"
          boxShadow="0 0 8px 0 rgba(20, 20, 32, 0.05);"
        >
          <Box padding="12px" width="100%">
            <Button
              isBlock
              onClick={handleAndResetDrawer}
              disabled={items.length >= 5}
            >
              <Icon
                name="Plus-outline"
                size="20px"
                color={items.length >= 5 ? "black25" : "white"}
              />
              <Text
                color={items.length >= 5 ? "black25" : "white"}
                weight="bold"
              >
                Tambah Template
              </Text>
            </Button>
          </Box>
        </Flex>
      </Flex>

      {/* Drawer Add and Update */}
      <Drawer
        closable={true}
        placement="bottom"
        isVisible={formDrawerDisclosure.isOpen}
        onClose={formDrawerDisclosure.onClose}
      >
        <FormTemplate
          item={selectedItem}
          isFetching={isLoadingSave}
          onSubmit={handleSubmit}
          onCancel={handleAndResetDrawer}
          disclosure={formDrawerDisclosure}
          setSelectedItem={setSelectedItem}
          mode={selectedItem.id ? "edit" : "add"}
        />
      </Drawer>

      {/* Drawer Delete Confirmation */}
      <Drawer
        onClose={deleteConfirmDrawerDisclosure.onClose}
        isVisible={deleteConfirmDrawerDisclosure.isOpen}
        closable={true}
        placement="bottom"
      >
        <DrawerDelete
          item={selectedItem}
          isFetching={isLoadingDelete}
          onDelete={mutateDeleteChat}
          onClose={deleteConfirmDrawerDisclosure.onToggle}
        />
      </Drawer>
    </>
  );
};

export default TemplateChat;
