import React from "react";
import { Flex, Icon } from "renos-ui";

const IconChecked = () => {
  return (
    <Flex
      width="18px"
      height="18px"
      borderRadius="100%"
      backgroundColor="blue50"
      alignItems="center"
      justifyContent="center"
    >
      <Icon size={16} name="Checkmark-outline" color="white" />
    </Flex>
  );
};

export default IconChecked;
