import useQueryParams from "hooks/useQueryParams";
import StoreContext from "providers/StoreProvider";
import { useContext, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { Box, Button, Flex, Icon, Tabs, Text, useDisclosure } from "renos-ui";
import { useGetOrderStatus } from "services/order-management";
import FailLoadData from "widgets/global/FailLoadData";
import List from "./List";
import LoadingStatus from "./LoadingStatus";
import ModalDownload from "./ModalDownload";

const OrderManagement = () => {
  const {
    storeData: {
      data: { id: storeId },
    },
  } = useContext(StoreContext);

  const query = useQueryParams();
  const { push } = useHistory();

  const [activeTab, setActiveTab] = useState(query.get("order_status") || "1");

  const disclosureDownload = useDisclosure({ isOpen: false });

  const handleChangeTabActive = (value) => {
    setActiveTab(value);
    push(`/sales/orders?order_status=${value}`);
  };

  const {
    isLoading: isStatusLoading,
    isError,
    data: dataStatus,
    refetch,
  } = useGetOrderStatus({ store: storeId });

  const items = useMemo(() => {
    let tempData = [];

    if (dataStatus?.data) {
      tempData = dataStatus.data.map((status) => ({
        label: status.formatted_order_status_name,
        key: status.order_status_id ? status.order_status_id + "" : "1",
        children: () => (
          <List statusId={status.order_status_id} refetchStatus={refetch} />
        ),
        width: "160px",
      }));
    }

    return tempData; // eslint-disable-next-line
  }, [dataStatus]);

  return (
    <Flex flexDirection="column" gap="8px">
      <Flex
        justifyContent="space-between"
        padding="24px"
        alignItems="center"
        borderTopLeftRadius="16px"
        borderTopRightRadius="16px"
        backgroundColor="white"
        boxShadow="0px 4px 4px rgba(20, 20, 32, 0.05)"
      >
        <Text variant="heading3" weight="bold">
          Daftar Penjualan
        </Text>
        <Button
          variant="tertiary"
          size="medium"
          preffix={<Icon name="Download-outline" color="black60" size={20} />}
          onClick={disclosureDownload.onToggle}
        >
          Download Laporan
        </Button>
      </Flex>

      <Box
        padding="24px"
        borderBottomLeftRadius="16px"
        borderBottomRightRadius="16px"
        backgroundColor="white"
        boxShadow="0px 4px 4px rgba(20, 20, 32, 0.05)"
      >
        {isStatusLoading || items.length === 0 ? (
          <LoadingStatus />
        ) : isError ? (
          <FailLoadData onClick={refetch} />
        ) : (
          <Tabs
            onChange={handleChangeTabActive}
            defaultActiveKey={activeTab}
            placement="bottom"
            items={items}
          />
        )}
      </Box>

      <ModalDownload
        isOpen={disclosureDownload.isOpen}
        onClose={disclosureDownload.onClose}
      />
    </Flex>
  );
};

export default OrderManagement;
