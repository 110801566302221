const ENDPOINTS = {
  //produk
  products: "product-service/v2/store/products",
  productsv3: "product-service/v3/store/products",

  products_active:
    "product-service/v2/store/products?is_discount=0&product_status_id=2",
  search_product: "product-service/v2/store/products",
  save_products: "/api/v2/product-service/products/save",
  upload_image_product: "/api/v2/product-service/save-product-image",
  image_type: "/api/v2/product-service/get-image-type",
  tags: "/api/v2/product-service/get-product-tag",
  detail_products: "/api/v2/product-service/products/single",
  custom_shipping: "/logistics/custom_product_shipping/services",
  variants: "/api/v2/product-service/get-variant",
  variants_value: "/api/v2/product-service/get-variant-value",
  update_product_inline: "/api/v2/product-service/products/update-single",
  update_product_inline_v3:
    "product-service/v3/store/product-skus/update-basic-bulk",
  upload_bulk_product: "product-service/v3/store/product-add-bulks/upload-file",
  get_bulk_upload_info: "product-service/v3/store/product-add-bulks/",
  product_status: "product-service/v3/store/product-status-summaries",
  download_bulk_upload_template:
    "product-service/v3/store/product-add-bulks/download-template",
  delete_product: "/api/v2/product-service/products/delete",
  generate_bulk_edit_template:
    "product-service/v3/store/product-edit-bulks/book-generate",
  status_bulk_edit_template: "product-service/v3/store/product-edit-bulks",
  upload_bulk_edit_product:
    "product-service/v3/store/product-edit-bulks/upload-final-file",
  generate_template_product:
    "/api/v2/product-service/products/bulk-save/download",

  // chat
  list_room: "/chat-service/room/list",
  room: "/chat-service/room/get?user_qiscus_id_from=1&user_qiscus_id_to=2",
  unread: "/chat-servie/room/list?user_qiscus_id=2",
  send_message: "/chat-service/message/send",
  get_user_buyer: "api/v1/buyer/user",
  load_message: "/chat-service/message/load",
  chat_info: "/chat-service/user/detail-by-store",
  chat_user_detail: "/chat-service/user/detail",
  chat_template: "/chat-service/chat-template",
  auto_reply: "/chat-service/store-autoreply-template",
  quick_chat: "/chat-service/chat-template",
  chat_unread_total: "/chat-service/unread-room/total",
  reset_chat_unread: "/chat-service/unread-room",
  upload_image_chat: "/chat-service/user/upload",

  // category
  categories: "api/v1/buyer/categories",
  categories_level_2: "product-service/v3/common/second-level-categories",

  //order
  orders: "/order-service/v3/seller/orders",
  ordersv3: "/order-service/v3/seller/orders",
  order_reject: "/order-service/v3/seller/order/reject",
  order_confirm: "/order-service/v3/seller/order/confirm",
  order_status: "order-service/v3/seller/order/total",
  order_send: "/logistics/store_order_shipment/create",
  order_print_label: "/logistics/store_order_shipment",
  order_print_label_img_courier: "/logistics/courier_services",
  order_bulk_invoice: "order-service/v3/seller/order/invoice",

  order_upload_delivery_order:
    "/logistics/store_order_shipment/seller_fleet/create/upload",
  order_seller_fleet_delivery_order:
    "api-logistics/v3/seller/store-order-shipment/create/seller-fleet",
  order_seller_fleet_delivery_arrive:
    "api-logistics/v3/seller/store-order-shipment/finish/seller-fleet",
  store_order_shipments: "/logistics/store_order_shipments",
  store_order_retry_pickup: "/logistics/store_order_shipment/3pl/book",

  store_order_pickup_datetime:
    "/api-logistics/v3/seller/store-order-shipment/possible-pickup-datetime",
  store_order_request_pickup:
    "/api-logistics/v3/seller/store-order-shipment/create",
  store_order_barcode: "/api-logistics/v3/seller/store-order-shipment/barcode",

  download_sales_report: "order-service/v3/seller/order/report_excel",
  store_order_history: "order-service/v3/seller/history-order",
  order_confirm_bulk: "logistics/store_order/bulk/confirm",

  //courier
  couriers: "/api-logistics/v3/seller/courier/get-store-area-3pl",
  couriers_bulk_update:
    "/api-logistics/v3/seller/courier-service-store/bulk-assign",
  shipping_information: "/api-logistics/v3/seller/shipping-information",

  //delivery
  seller_fleet: "api-logistics/v3/seller/seller-fleet/get",
  seller_fleet_activate: "api-logistics/v3/seller/seller-fleet/activate",
  seller_fleet_deactive: "api-logistics/v3/seller/seller-fleet/deactivate",
  courier_service_setting: "/logistics/courier_service_setting/save",

  courier_service_setting_delete: "/logistics/courier_service_setting/delete",
  seller_fleet_coverage: "/logistics/courier_service_coverage/save",

  seller_fleet_weight_multiple: "/logistics/weight_multiple/save",
  seller_fleet_weight_groups: "/logistics/weight_group/bulk_save",
  seller_fleet_weight_groups_delete: "/logistics/weight_group/bulk_delete",

  seller_fleet_coverage_region_update:
    "/logistics/courier_service_coverage_region/bulk_assign",
  seller_fleet_coverage_region_create:
    "/logistics/courier_service_coverage/bulk_save",
  seller_fleet_coverage_region_delete:
    "/logistics/courier_service_coverage/delete",
  tracking_order: "/api-logistics/v3/seller/store-order-shipment/tracking",

  seller_fleet_categories:
    "api-logistics/v3/seller/courier-service-setting/categories",

  seller_fleet_categories_save:
    "/logistics/courier_service_setting/categories/bulk_save",
  seller_fleet_setting_list:
    "api-logistics/v3/seller/seller-fleet/get-setting-summary",
  seller_fleet_delete: "api-logistics/v3/seller/seller-fleet/delete-setting",
  seller_fleet_setting_by_id:
    "api-logistics/v3/seller/seller-fleet/get-setting",
  seller_fleet_setting_update:
    "api-logistics/v3/seller/seller-fleet/save-setting",
  list_filter_courier: "logistics/couriers",
  seller_fleet_setting_bulk_save:
    "api-logistics/v3/seller/seller-fleet/save-setting-bulk",
  seller_fleet_setting_bulk_delete:
    "api-logistics/v3/seller/seller-fleet/delete-setting-bulk",

  //location
  location: "/store-service/v3/location",

  //statistic
  statistic_new_order: "/statistics/v1/statistic/new-order",
  statistic_summary: "/statistics/v1/statistic/transaction-summary",
  statistic_cancel_order: "/statistics/v1/statistic/cancelled-order",

  statistic_download_report:
    "/statistics/v1/statistic/export/transaction-summary-xls",
  statistic_summary_net_income: "/statistics/v1/statistic/net-income",
  statistic_summary_sold_order: "/statistics/v1/statistic/sold-order",
  statistic_summary_seen: "/statistics/v1/statistic/product-view",
  statistic_summary_conversion: "/statistics/v1/statistic/conversion-rate",

  //Review
  ratings: "/store-reviews/product-ratings",
  reviews: "/store-reviews/reviews",
  rating_average: "/store-reviews/product-rating-average",
  review_reply_template: "/store-reviews/review-message-templates",
  review_reply_template_save: "/store-reviews/review-message-templates",
  review_reply_template_delete: "/store-reviews/messages",
  review_reply_save: "/store-reviews/messages/save",
  review_reply: "/store-reviews/messages/update",
  review_template_auto: "/store-reviews/review-message-template/auto-reply",
  review_template_auto_activate:
    "/store-reviews/review-message-template/auto-reply/activate",
  review_template_auto_deactivate:
    "/store-reviews/review-message-template/auto-reply/deactivate",
  newest_review: "/store-reviews/reviews/newest",

  //authentication
  check_auth: "/api/v1/buyer/is_authenticated",
  logout: "/api/v1/buyer/logout",

  // store
  store_checklist: "/store-service/v3/dashboard/checklist",
  check_store: "/store-service/v3/store/check",
  get_store: "/store-service/v3/store/detail",
  store_close_save: "/store-service/v3/close-schedule",
  store_close: "/store-service/v3/close-schedule",
  store_open_now: "/store-service/v3/store/open",
  document_number: "store-service/v3/document",
  upload_document: "store-service/v3/document/upload",
  document: "store-service/v3/document/detail",
  download_document: "/store-service/v3/document/download",
  get_store_detail: "store-service/v3/store/detail",
  update_store: "store-service/v3/store",
  upload_store_img: "store-service/v3/store/image",
  hour_schedule: "store-service/v3/hour-schedule",

  //bank account
  bank_master: "store-service/v3/bank/master",

  //address
  provinces: "api/v1/master/provinces",
  city: "api/v1/master/cities",
  district: "api/v1/master/districts",
  sub_district: "api/v1/master/lower_districts",

  //promotion discount
  discount_list: "product-service/v3/store/product-discounts/products",
  discount_save: "product-service/v3/store/product-discounts/save-bulk",
  discount_edit: "product-service/v3/store/product-discounts/edit-bulk",
  discount_delete: "product-service/v3/store/product-discounts/end-bulk",
  discount_status: "product-service/v3/store/product-discount-status-summaries",
  discount_get_active:
    "/product-service/v3/store/active-product-discounts/products",

  //notification
  notification_list: "notification/v1/notification/seller",
  notification_update_read: "notification/v1/notification/update/read/seller",
  notification_create: "notification/v1/notification/save",

  //setting note
  save_note: "/store-service/v3/note",
  store_note: "/store-service/v3/note",
  detail_note: "store/store_note",
  delete_note: "/store-service/v3/note",

  heartbeat: "ident-service/v1/heartbeat",

  //store-layout
  homepage: "store-service/v3/homepage",
  apply_homepage: "store-service/v3/homepage/apply",
  homepage_status: "store-service/v3/homepage/activate",
  homepage_reorder: "store-service/v3/homepage/re-order",
  homepage_template: "store-service/v3/homepage/template",

  // etalase
  etalase: "product-service/v3/store/showcases",
  etalase_save: "product-service/v3/store/showcases",
  etalase_update_order:
    "product-service/v3/store/manage/showcases/update-order",
  etalase_delete: "product-service/v3/store/showcases",
  etalase_save_image: "product-service/v3/store/showcases",
  etalase_upload_image:
    "product-service/v3/store/manage/showcase-images/upload",

  // banner
  banner_master_type: "store-service/v3/banner/master-type",
  banner_upload: "store-service/v3/banner/upload",

  //voucher
  voucher_master: "promo-service/v1/seller/master/data",
  voucher: "promo-service/v1/seller/voucher",
  voucher_status: "promo-service/v1/seller/voucher/total-summary",

  //aio search
  search_suggestion: "search-service/v1/store/searches/suggestion",

  //showcase
  showcase: "product-service/v3/store/showcases",

  //register
  register: "store-service/v3/store/register",

  //Bidding
  offer_quotation_form: "/bidding-service/seller/v1/quotation",
  bidding_detail: "/bidding-service/seller/v1/request/detail",
  my_bidding: "/bidding-service/seller/v1/request/quotation",
  download_attachment: "/bidding-service/seller/v1/request/product-attachment",
  bidding_incoming_request: "bidding-service/seller/v1/request",
  bidding_my_offer: "bidding-service/seller/v1/quotation",
};

export default ENDPOINTS;
