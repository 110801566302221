import ENDPOINTS from "constants/endpoints";
import { useMutation, useQuery } from "react-query";
import client from "../../client";

const getProvinceFetcher = async () => {
  const { data } = await client.get(ENDPOINTS.provinces);

  const tempData = data.data.map((province) => ({
    value: province,
    label: province.province_name,
  }));

  return tempData;
};

export const useGetProvince = (extraParams) => {
  return useQuery(["getProvince"], getProvinceFetcher, {
    ...extraParams,
  });
};

export const getCityFetcher = async (body) => {
  const { data } = await client.get(ENDPOINTS.city, { params: body });

  return { data: data.data, indexCoverage: body.indexCoverage };
};

export const useGetCity = (extraParams) =>
  useMutation((body) => getCityFetcher(body), {
    ...extraParams,
  });

export const getDistrictFetcher = async (body) => {
  const { data } = await client.get(ENDPOINTS.district, { params: body });

  return { data: data.data, indexCoverage: body.indexCoverage };
};

export const useGetDistrict = (extraParams) =>
  useMutation((body) => getDistrictFetcher(body), {
    ...extraParams,
  });

export const getNewProvinceFetcher = async (body) => {
  const { data } = await client.get(ENDPOINTS.provinces, { params: body });

  return { data: data.data, indexCoverage: body.indexCoverage };
};

export const useGetNewProvince = (extraParams) =>
  useMutation((body) => getNewProvinceFetcher(body), {
    ...extraParams,
  });
