import { useState, useEffect, useMemo } from "react";
import { useDeliveryContext } from "store/seller-fleet/reducer";
import constants from "store/seller-fleet/constants";
import { cloneDeep } from "renos-ui";

const useModalDistrict = ({
  isOpen,
  onClose,
  selectedIndex,
  mutateDistrict,
  isLoadingDistrict,
}) => {
  const { state, dispatch } = useDeliveryContext();
  const { dataCoverage } = state;
  const [dataModal, setDataModal] = useState({
    listDistrict: [],
    selectedDistrict: [],
    changed: false,
  });
  const [dataSearch, setDataSearch] = useState([]);

  const isCheckedAll = useMemo(() => {
    const listDistrictId = dataSearch.map((district) => district.district_id);
    const filterSelected = dataModal.selectedDistrict.filter((filterDistrict) =>
      listDistrictId.includes(filterDistrict.district_id)
    );
    const checkResult = {
      active: false,
      indeterminate: false,
    };
    if (filterSelected.length >= listDistrictId.length)
      checkResult.active = true;
    if (filterSelected.length > 0 && !checkResult.active)
      checkResult.indeterminate = true;

    return checkResult;
  }, [dataModal, dataSearch]);

  const handleCheckAll = () => {
    let tempSelectedDistrict = dataModal.selectedDistrict;

    if (!isCheckedAll.active && !isCheckedAll.indeterminate) {
      tempSelectedDistrict = dataSearch;
    } else if (isCheckedAll.indeterminate) {
      const filterNotSelected = dataSearch.filter(
        (filterDistrict) => !isIncludeSelected(filterDistrict)
      );
      tempSelectedDistrict = tempSelectedDistrict.concat(filterNotSelected);
      const filterDuplicate = [
        ...new Map(
          tempSelectedDistrict.map((district) => [
            district.district_id,
            district,
          ])
        ).values(),
      ];
      tempSelectedDistrict = filterDuplicate;
    } else {
      const listDistrictId = dataSearch.map((district) => district.district_id);
      const filterSelected = tempSelectedDistrict.filter(
        (filterDistrict) => !listDistrictId.includes(filterDistrict.district_id)
      );
      tempSelectedDistrict = filterSelected;
    }
    setDataModal((prevState) => ({
      ...prevState,
      selectedDistrict: tempSelectedDistrict,
      changed: true,
    }));
  };

  const isIncludeSelected = (idDistrict) => {
    const { selectedDistrict } = dataModal;
    const dataChecker = selectedDistrict.filter(
      (filterDistrict) => filterDistrict.district_id === idDistrict
    );

    return dataChecker.length > 0 && true;
  };

  const handleChangeCheckBox = (dataDistrict) => {
    let tempSelectedDistrict = dataModal.selectedDistrict;

    if (isIncludeSelected(dataDistrict.district_id)) {
      tempSelectedDistrict = tempSelectedDistrict.filter(
        (filterSelected) =>
          filterSelected.district_id !== dataDistrict.district_id
      );
    } else {
      tempSelectedDistrict.push(dataDistrict);
    }
    setDataModal((prevState) => ({
      ...prevState,
      selectedDistrict: tempSelectedDistrict,
      changed: true,
    }));
  };

  const handleSearch = (event) => {
    const filterDistrict = dataModal.listDistrict.filter((district) =>
      district.district_name.toLowerCase().includes(event.target.value.toLowerCase())
    );
    setDataSearch([...filterDistrict]);
  };

  const handleSave = () => {
    const tempCoverage = dataCoverage;
    tempCoverage[selectedIndex].selectedDistrict = dataModal.selectedDistrict;
    dispatch({
      type: constants.GET_DISTRICT_BY_INDEX,
      payload: {
        dataCoverage: tempCoverage,
      },
    });

    onClose();
  };

  useEffect(() => {
    const selectedCoverage = dataCoverage[selectedIndex];

    if (selectedCoverage.listDistrict.length === 0 && isOpen) {
      const payload = `[${selectedCoverage.selectedCity
        .map((cities) => cities.city_id)
        .join(",")}]`;
      mutateDistrict({ city_id: payload, indexCoverage: selectedIndex });
    }

    // eslint-disable-next-line
  }, [isOpen]);

  useEffect(() => {
    const selectedCoverage = dataCoverage[selectedIndex]
      ? dataCoverage[selectedIndex]
      : dataCoverage[selectedIndex - 1];

    if (!isLoadingDistrict) {
      setDataModal(cloneDeep(selectedCoverage));
      setDataSearch(cloneDeep(selectedCoverage?.listDistrict));
    }
    //eslint-disable-next-line
  }, [dataCoverage, isOpen, isLoadingDistrict]);

  return {
    dataModal,
    isCheckedAll,
    dataSearch,
    handleCheckAll,
    isIncludeSelected,
    handleChangeCheckBox,
    handleSearch,
    handleSave,
  };
};

export default useModalDistrict;