import React, { useEffect, useState } from "react";
import { Drawer, Flex, Box, Text, Icon, Button } from "renos-ui";
import MapMobile from "widgets/mobile/GoogleMapMobile/MapMobile";

const LocationDrawer = ({
  isOpenDrawer,
  value,
  setValue,
  isError,
  setIsError,
  setCloseDrawer,
}) => {
  const [addressData, setAddressData] = useState("");
  const [tempLatLong, setTempLatLong] = useState({
    lat: value?.coordinates?.latitude,
    long: value?.coordinates?.longitude,
  });

  const handleSubmit = () => {
    setValue({
      ...value,
      coordinates: {
        latitude: tempLatLong.lat,
        longitude: tempLatLong.long,
      },
      is_coordinate_changed: true,
      point_address: addressData,
    });
    setIsError({ ...isError, is_coordinate_changed: false });
    setCloseDrawer();
  };

  const onChangeMap = (position, address) => {
    setTempLatLong({
      lat: position.lat,
      long: position.lng,
    });
    setAddressData(address);
  };

  useEffect(() => {
    setTempLatLong({
      lat: value?.coordinates?.latitude,
      long: value?.coordinates?.longitude,
    });
  }, [isOpenDrawer, value]);

  return (
    <Drawer
      isVisible={isOpenDrawer}
      width="100%"
      maxHeight="100%"
      style={{
        padding: 0,
      }}
      onClose={setCloseDrawer}
    >
      <Flex flexDirection="column" height="100%">
        <Box p="16px" boxShadow="0px 0px 8px 0px rgba(20, 20, 32, 0.05)">
          <Flex gap="16px" flexDirection="row">
            <Flex>
              <Icon name="Back-outline" onClick={setCloseDrawer} />
            </Flex>
            <Flex>
              <Text weight="bold" variant="subtitle">
                Pin Lokasi
              </Text>
            </Flex>
          </Flex>
        </Box>

        <MapMobile position={tempLatLong} onChange={onChangeMap} />

        <Flex
          bottom="0"
          width="100%"
          position="fixed"
          height="fit-content"
          justifyContent="center"
          boxShadow="0px 0px 8px 0px rgba(20, 20, 32, 0.05);"
        >
          <Flex flexDirection="column" width="100%" padding="16px">
            <Flex flexDirection="column" gap="4px" marginBottom="16px">
              <Text color="black100" weight="bold">
                Alamat
              </Text>
              <Text color="black25" variant="body" lineClamp="3">
                {addressData}
              </Text>
            </Flex>
            <Button isBlock onClick={handleSubmit}>
              <Text weight="bold">Pilih Lokasi</Text>
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </Drawer>
  );
};

export default LocationDrawer;
