import React, { useRef } from "react";
import { StyledDisclosure } from "./styled";
import { Box, Divider, Flex, Icon, Text } from "renos-ui";

const SellerTutorial = () => {
  const section1 = useRef(null);
  const section2 = useRef(null);

  const onScrollTopClicked = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const scrollIntoRef = (ref) => {
    ref.current.scrollIntoView({
      behavior: "smooth",
    });
  };

  return (
    <Flex
      flexDirection="column"
      width="100%"
      alignItems="center"
      rowGap="48px"
      paddingBottom="24px"
      backgroundColor="black5"
    >
      <Divider />
      <Box width="1200px" marginTop="60px">
        <Flex width="100%" justifyContent="space-between" gap="40px">
          <Flex
            borderRadius={8}
            padding={24}
            backgroundColor="white"
            boxShadow="0px 4px 4px rgba(20, 20, 32, 0.05)"
            flexDirection="column"
            rowGap="28px"
            width="342px"
            height="fit-content"
            position="sticky"
            top={0}
          >
            <Flex gap="24px" flexDirection="column">
              <Flex>
                <Text variant="subtitle" weight="bold" color="black75">
                  Tutorial Berjualan di Renos
                </Text>
              </Flex>

              <Flex flexDirection="column" gap="16px">
                <Flex
                  justifyContent="space-beetwen"
                  gap="8px"
                  onClick={() => scrollIntoRef(section1)}
                >
                  <Text>1.</Text>
                  <Text>Membuat akun dan menggunakan fitur Renos</Text>
                </Flex>
                <Flex
                  justifyContent="space-beetwen"
                  gap="8px"
                  onClick={() => scrollIntoRef(section2)}
                >
                  <Text>2.</Text>
                  <Text>Mengunggah Produk di Renos Seller Center</Text>
                </Flex>
              </Flex>
              <Divider />
              <Flex
                justifyContent="space-beetwen"
                gap="8px"
                onClick={onScrollTopClicked}
              >
                <Text>Kembali ke atas</Text>
              </Flex>
            </Flex>
          </Flex>

          <Flex flexDirection="column" gap="40px">
            <Flex
              borderRadius={8}
              padding={24}
              backgroundColor="white"
              boxShadow="0px 4px 4px rgba(20, 20, 32, 0.05)"
              flexDirection="column"
              rowGap="28px"
              width="826px"
              ref={section1}
            >
              <Flex columnGap="16px" alignItems="center">
                <Box
                  backgroundColor="blue50"
                  borderRadius={2}
                  height={24}
                  width={4}
                />
                <Text variant="subtitle" weight="bold" color="blue50">
                  Membuat akun dan menggunakan fitur Renos
                </Text>
              </Flex>
              <Divider />
              <StyledDisclosure defaultOpen={true}>
                {({ isOpen, onToggle }) => (
                  <Flex
                    flexDirection="column"
                    backgroundColor="white"
                    rowGap="16px"
                  >
                    <Flex
                      width="100%"
                      justifyContent="space-between"
                      onClick={onToggle}
                      cursor="pointer"
                    >
                      <Text variant="subtitle" weight="bold">
                        Bagaimana cara saya memulai berjualan di Renos dan
                        bagaimana cara menggunakan fitur Renos Seller Center?
                      </Text>
                      <Icon
                        name={isOpen ? "Up-outline" : "Down-outline"}
                        size={24}
                        color="black50"
                      />
                    </Flex>
                    {isOpen && (
                      <Flex flexDirection="column" gap="24px">
                        <Text>
                          Selamat datang di Renos Seller Center! Untuk membantu
                          kamu menjadi Seller terbaik di Renos, kami sudah
                          menyiapkan video tutorial bagi para Seller di Renos.
                          Panduan ini berisi tahap-tahap yang akan kamu lalui
                          untuk menjadi seller di Renos dan tentunya
                          mengoptimalkan toko kamu melalui fitur-fitur di Renos
                          Seller Center.
                        </Text>
                        <iframe
                          src="https://www.youtube.com/embed/b6jdIUl1za8"
                          title="YouTube video player"
                          frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                          height="407px"
                        />
                      </Flex>
                    )}
                  </Flex>
                )}
              </StyledDisclosure>
            </Flex>

            <Flex
              borderRadius={8}
              padding={24}
              backgroundColor="white"
              boxShadow="0px 4px 4px rgba(20, 20, 32, 0.05)"
              flexDirection="column"
              rowGap="28px"
              width="826px"
              ref={section2}
            >
              <Flex columnGap="16px" alignItems="center">
                <Box
                  backgroundColor="blue50"
                  borderRadius={2}
                  height={24}
                  width={4}
                />
                <Text variant="subtitle" weight="bold" color="blue50">
                  Mengunggah Produk di Renos Seller Center
                </Text>
              </Flex>
              <Divider />
              <StyledDisclosure>
                {({ isOpen, onToggle }) => (
                  <Flex
                    flexDirection="column"
                    backgroundColor="white"
                    rowGap="16px"
                  >
                    <Flex
                      width="100%"
                      justifyContent="space-between"
                      onClick={onToggle}
                      cursor="pointer"
                    >
                      <Text variant="subtitle" weight="bold">
                        Bagaimana cara saya mengunggah produk di Renos Seller
                        Center?
                      </Text>
                      <Icon
                        name={isOpen ? "Up-outline" : "Down-outline"}
                        size={24}
                        color="black50"
                      />
                    </Flex>
                    {isOpen && (
                      <Flex flexDirection="column" gap="24px">
                        <Text>
                          Selamat datang di Renos Seller Center! Untuk membantu
                          kamu menjadi Seller terbaik di Renos, kami menyiapkan
                          sebuah video tutorial bagi para Seller di Renos.
                          Paduan ini berisi langkah-langkah untuk mengunggah
                          atau mengupload produk kamu di Renos.
                        </Text>
                        <iframe
                          src="https://www.youtube.com/embed/b6jdIUl1za8"
                          title="YouTube video player"
                          frameborder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowfullscreen
                          height="407px"
                        />
                      </Flex>
                    )}
                  </Flex>
                )}
              </StyledDisclosure>
            </Flex>
          </Flex>
        </Flex>
      </Box>
    </Flex>
  );
};

export default SellerTutorial;
