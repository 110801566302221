import { Divider, Flex, Icon, isEmpty, Text } from "renos-ui";
import { StyledDialog } from "./styled";
import { useEffect, useMemo, useState } from "react";
import dayjs from "dayjs";
import { abbreviationNum, currencyFormatter } from "helpers";

const ModalViewVoucher = ({ values, isOpen, onClose, dataMaster }) => {
  const isTargetGeneral = useMemo(() => {
    if (isEmpty(dataMaster?.data.voucher_target) && isEmpty(values))
      return false;

    return (
      dataMaster?.data.voucher_target.find(
        (item) => item.voucher_target_name.toLowerCase() === "umum"
      ).voucher_target_id === values.voucherTargetId
    );
  }, [values, dataMaster]);

  const [isLoading, setIsLoading] = useState(true);

  const promoCode = useMemo(
    () => (values.code ? values.code : "XXXXXXXXXX"),
    [values]
  );

  const formatDate = useMemo(
    () =>
      `${dayjs(values.startDate).format("DD MMM YYYY")} - ${dayjs(
        values.endDate
      ).format("DD MMM YYYY")}`,
    [values]
  );

  const formatAmount = useMemo(
    () =>
      values.discountTypeId === 1
        ? `${values.percentageDiscount}%`
        : abbreviationNum(parseInt(values.nominalDiscount)),
    [values]
  );

  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, [isOpen]);

  return (
    <StyledDialog verticalCentered isVisible={isOpen}>
      <Flex width="320px" flexDirection="column" gap="24px">
        <Flex flexDirection="column" gap="4px" alignItems="center" width="100%">
          <Flex justifyContent="space-between" alignItems="center" width="100%">
            <Text weight="bold" color="black75">
              Preview Voucher
            </Text>
            <Icon
              size={24}
              color="black50"
              name="Close-outline"
              onClick={onClose}
            />
          </Flex>
          <Text color="black75">
            Bagian Voucher pada halaman tokomu akan terlihat seperti tampilan di
            bawah ini.
          </Text>
          {/* <Flex
            as="img"
            width="200px"
            height="200px"
            src="/assets/images/voucher/voucher-add.svg"
          /> */}
        </Flex>
        <Divider />

        <Flex
          background="linear-gradient(266.92deg, #4654D6 24.23%, #6C77E1 90.69%)"
          padding="14px"
          alignItems="center"
        >
          {isLoading ? (
            <Flex
              height="120px"
              justifyContent="center"
              width="100%"
              alignItems="center"
            >
              <Text variant="heading2" weight="bold" color="white">
                Loading...
              </Text>
            </Flex>
          ) : (
            <>
              <Flex
                width="203px"
                height="120px"
                borderRadius="8px"
                flexDirection="column"
                background="white"
                position="relative"
              >
                <Flex
                  flexDirection="column"
                  zIndex={2}
                  padding="4px 14px"
                  gap="6px"
                >
                  <Flex flexDirection="column">
                    <Text variant="caption" color="white">
                      Diskon
                    </Text>
                    <Text variant="heading2" weight="bold" color="white">
                      {formatAmount}
                    </Text>
                    <Text variant="small" color="white">
                      min. belanja {currencyFormatter(values.minPurchase)}
                    </Text>
                  </Flex>

                  <Flex flexDirection="column" gap="2px">
                    {!isTargetGeneral && (
                      <Text variant="caption" color="white">
                        KODE: <b>{promoCode}</b>
                      </Text>
                    )}

                    <Flex gap="6px" opacity="50%">
                      <Icon name="Calendar-outline" size={16} color="white" />
                      <Text color="white" variant="small">
                        {formatDate}
                      </Text>
                    </Flex>
                  </Flex>
                </Flex>
                <Flex
                  as="img"
                  width="190px"
                  height="120px"
                  position="absolute"
                  alt="elipsis"
                  src="/assets/images/voucher/ellipse-ticket.svg"
                  zIndex={1}
                  borderTopLeftRadius="8px"
                  borderBottomLeftRadius="8px"
                />
              </Flex>

              <Flex
                as="img"
                height="123px"
                width="88px"
                alt="voucher"
                src="/assets/images/voucher/ticket-image.svg"
              />
            </>
          )}
        </Flex>
      </Flex>
    </StyledDialog>
  );
};

export default ModalViewVoucher;
