import { Flex, Text, colors } from "renos-ui";

const LandscapeTwo = ({ imageRef, variant, data, setData }) => {
  const handleSetEdit = (type) => {
    setData((prev) => ({ ...prev, active: type }));
  };

  const renderBorder = (type) => {
    return data?.active === type && `1px solid ${colors.violet50}`;
  };

  return (
    <Flex flexDirection="column" gap="8px" paddingTop="100px">
      <Flex flexDirection="column">
        <Text variant="caption" color="black50" weight="bold">
          Template {variant}
        </Text>
        <Text variant="caption" color="black50">
          Slider Banner 2:1 (1208 x 604 px)
        </Text>
      </Flex>
      <Flex
        width="473px"
        height="239px"
        gap="24px"
        ref={imageRef}
        position="relative"
      >
        <Flex
          position="absolute"
          as="img"
          alt="img-banner"
          width="473px"
          height="239px"
          src={data.imgLandscape}
          cursor="pointer"
          onClick={() => handleSetEdit("imgLandscape")}
          border={renderBorder("imgLandscape")}
          backgroundSize="cover"
          zIndex="0"
        />

        <Flex
          position="absolute"
          width="45%"
          height="100%"
          background="#1833B4"
          zIndex="0"
          borderBottomRightRadius="50%"
          borderTopRightRadius="50%"
        />

        <Flex
          width="40%"
          p="20px"
          flexDirection="column"
          zIndex={1}
          alignItems="baseline"
        >
          <Flex
            as="img"
            height="16px"
            src={data.logo}
            marginBottom="20px"
            onClick={() => handleSetEdit("logo")}
            border={renderBorder("logo")}
            cursor="pointer"
          />
          <Flex
            marginBottom="8px"
            onClick={() => handleSetEdit("title")}
            border={renderBorder("title")}
            cursor="pointer"
          >
            <Text variant="heading3" weight="bold" color="white" lineClamp={2}>
              {data.title}
            </Text>
          </Flex>
          <Flex
            marginBottom="16px"
            onClick={() => handleSetEdit("description")}
            border={renderBorder("description")}
            cursor="pointer"
            style={{ wordBreak: "break-all" }}
          >
            <Text variant="small" color="white" lineClamp={2}>
              {data.description}
            </Text>
          </Flex>

          <Flex
            p="6px 16px"
            borderRadius="6px"
            background="#1833B4"
            onClick={() => handleSetEdit("textButton")}
            border={renderBorder("textButton") || "1px solid white"}
            cursor="pointer"
            style={{ wordBreak: "break-all" }}
          >
            <Text color="white" variant="small" weight="bold" lineClamp={1}>
              {data.textButton || "Belanja Sekarang!"}
            </Text>
          </Flex>

          <Flex
            marginTop="24px"
            onClick={() => handleSetEdit("tags")}
            border={renderBorder("tags")}
            cursor="pointer"
            style={{ wordBreak: "break-all" }}
          >
            <Text variant="small" color="white">
              {data.tags}
            </Text>
          </Flex>
        </Flex>
        <Flex
          right={0}
          top={0}
          position="absolute"
          width="83px"
          height="82px"
          opacity={data.isDiscountActive ? 1 : 0}
          backgroundImage="url('assets/images/store-layout/banner-2x1/ellipse_discount.svg')"
          cursor="pointer"
          onClick={() => handleSetEdit("discount")}
          border={renderBorder("discount")}
          backgroundSize="cover"
          alignItems="center"
          flexDirection="column"
        >
          <Flex fontSize="8px !important" marginLeft="8px" marginTop="12px">
            {data.discountName}
          </Flex>
          <Flex alignItems="center" gap="1px" marginTop="-8px" marginLeft="8px">
            <Flex fontSize="37px !important" color="black75" fontWeight="800">
              {data.discount}
            </Flex>
            <Flex fontSize="13px !important" color="black75" fontWeight="800">
              {data.discountSuffix}
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default LandscapeTwo;
