import React, { useEffect } from "react";
import { useState, useMemo } from "react";
import {
  Flex,
  Text,
  colors,
  Box,
  useUpload,
  Spinner,
  useToast,
} from "renos-ui";
import {
  useGetBulkUploadInfo,
  useUploadFile,
} from "services/product-add-multiple";
import VariantSelector from "./VariantSelector";
import InitialInput from "../component/InitialInput";
import RespondError from "../component/RespondError";
import RespondSuccess from "../component/RespondSuccess";

const Add = () => {
  const toast = useToast();
  const [template, setTemplate] = useState("non_variant");
  const { register, result } = useUpload({
    fileType: "image",
  });

  const [formValue, setFormValue] = useState({
    changed: false,
    imgChanged: false,
  });
  const [status, setStatus] = useState("");
  const [failedData, setFailedData] = useState("");
  const [isTimerLoading, setIsTimerLoading] = useState(false);

  const { mutate: getUploadStatus, isLoading: isUploadStatusLoading } =
    useGetBulkUploadInfo({
      onSuccess: (data) => {
        const { data: dataStatus } = data;
        const arrLoading = ["in_process", "final_finished"];
        setIsTimerLoading(true);

        if (arrLoading.includes(dataStatus.product_upload_file_status)) {
          setTimeout(() => {
            getUploadStatus(dataStatus.product_upload_file_id);
            setIsTimerLoading(false);
          }, 3000);
        } else if (dataStatus.product_upload_file_status === "failed") {
          setFailedData(dataStatus.product_upload_file_revision_name);
          setStatus("error");
          setIsTimerLoading(false);
        } else {
          setStatus("success");
          setIsTimerLoading(false);
        }
      },
    });

  const { mutate: mutateUpload, isLoading: isMutateUploadLoading } =
    useUploadFile({
      onSuccess: (data) => {
        getUploadStatus(data.data.product_upload_file_id);
      },
      onError: (error) => {
        toast({
          label:
            error?.response?.data?.front_end_message || "Gagal upload file.",
          placement: "top",
          backgroundColor: "red50",
        });

        setStatus("error");
        setFailedData("");
      },
    });

  useEffect(() => {
    if (formValue?.imageFile) {
      const maxAllowedSize = 1 * 1024 * 1024;

      if (formValue?.imageFile.size > maxAllowedSize) {
        toast({
          label: "Ukuran file maksimal 1 mb.",
          backgroundColor: "red50",
          placement: "top",
        });
      } else {
        const formData = new FormData();
        formData.append("template_type", template);
        formData.append("file", formValue?.imageFile);
        formData.append("file_name", formValue?.imageFile?.name);
        mutateUpload(formData);
      }
    }

    // eslint-disable-next-line
  }, [formValue]);

  useEffect(() => {
    setFormValue({
      ...formValue,
      imageUrl: result?.source,
      imageFile: result?.file,
      imgChanged: true,
    });
    // eslint-disable-next-line
  }, [result]);

  const isLoading = useMemo(
   () => isMutateUploadLoading || isUploadStatusLoading || isTimerLoading,
    [isMutateUploadLoading, isUploadStatusLoading, isTimerLoading],
  );

  return (
    <Flex justifyContent="space-between" gap="16px">
      <VariantSelector template={template} setTemplate={setTemplate} />
      <Flex
        borderBottomLeftRadius="16px"
        borderBottomRightRadius="16px"
        boxShadow="0px 4px 4px rgba(20, 20, 32, 0.05)"
        backgroundColor="white"
        padding="24px"
        height="398px"
        gap="24px"
        flexDirection="column"
        justifyContent="space-between"
        width="488px"
      >
        <Flex gap="20px" alignItems="center">
          <Flex
            backgroundColor="violet5"
            alignItems="center"
            py="6px"
            px="14px"
            borderRadius="100px"
          >
            <Text variant="heading3" weight="bold" color="blue50">
              2
            </Text>
          </Flex>
          <Flex>
            <Text variant="heading3" weight="bold">
              Upload File Excel
            </Text>
          </Flex>
        </Flex>

        {isLoading === true ? (
          <Flex
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            py="39px"
            px="78px"
            gap="24px"
            height="286px"
            border={`1px dashed ${colors.black10}`}
            borderRadius="8px"
          >
            <Spinner size="72px" emptyColor="blue10" />

            <Flex gap="8px">
              <Text variant="title" weight="bold">
                Proses Uploading..
              </Text>
            </Flex>
            <Box textAlign="center">
              <Text variant="body" color="black50">
                File sedang di upload, harap menunggu.
              </Text>
            </Box>
          </Flex>
        ) : status === "error" ? (
          <RespondError register={register} linkError={failedData} />
        ) : status === "success" ? (
          <RespondSuccess register={register} />
        ) : (
          <InitialInput register={register} />
        )}
      </Flex>
    </Flex>
  );
};

export default Add;
