import {
  Button,
  Divider,
  Flex,
  isEmpty,
  numberInputChecking,
  Text,
} from "renos-ui";
import { ReactComponent as ICRenos } from "assets/icons/renos-logo.svg";
import { useState, useRef, useEffect, Fragment } from "react";
import ReactToPrint from "react-to-print";
import { goToUrl } from "helpers";
import { useMemo } from "react";
import { maskAllName } from "helpers/string-mask";

const PrintInvoice = () => {
  const [data, setData] = useState({});
  const isMobile = window.innerWidth <= 760;
  const componentRef = useRef();

  const handleMasking = (value, isPreffix) => {
    const notAllowedMask = [];

    return notAllowedMask.includes(data.order_status_id)
      ? value
      : maskAllName(value, isPreffix);
  };

  useEffect(() => {
    const dataSession = sessionStorage.getItem("inv-prod-renos");

    if (dataSession) {
      setData(JSON.parse(dataSession));
    } else {
      window.location.href = "/sales/orders";
    }
  }, []);

  const totalSold = useMemo(() => {
    const sellerFleetAmount = data?.shipping?.is_seller_fleet
      ? parseInt(data?.shipping?.order_shipping_price)
      : 0;
    const voucherAmount =
      data?.promo_used?.length > 0 ? data.promo_used[0].seller_voucher_disc : 0;

    const commissionFee = data?.commission_fee || 0;

    return (
      data?.total_price + sellerFleetAmount + voucherAmount + commissionFee
    );
  }, [data]);

  return (
    <Flex flexDirection="column" width="100%" justifyContent="center">
      <Flex padding="16px 24px" justifyContent="end">
        <ReactToPrint
          trigger={() => <Button>Cetak Invoice</Button>}
          content={() => componentRef.current}
          documentTitle={"Invoice_" + data.order_number}
          copyStyles={true}
        />
      </Flex>
      <Divider />
      {!isEmpty(data) && (
        <Flex
          justifyContent="center"
          style={{ transform: isMobile ? "scale(0.6)" : "none" }}
          marginTop={isMobile ? "-180px" : 0}
        >
          <Flex
            width="714px"
            padding="40px"
            flexDirection="column"
            gap="40px"
            ref={componentRef}
          >
            <ICRenos />
            <Flex gap="36px">
              <Flex flexGrow={1} width="50%" flexDirection="column" gap="16px">
                <Flex>
                  <Flex width="140px">
                    <Text weight="bold" color="black75">
                      Nomor Pesanan
                    </Text>
                  </Flex>
                  <Flex width="calc(100% - 140px)">
                    <Text color="blue50">{data.order_number}</Text>
                  </Flex>
                </Flex>
                <Flex flexDirection="column" gap="8px">
                  <Text color="black75">Diterbitkan atas nama:</Text>
                  <Flex>
                    <Flex width="140px">
                      <Text weight="bold" color="black75">
                        Penjual
                      </Text>
                    </Flex>
                    <Flex width="calc(100% - 140px)">
                      <Text color="blue50">{data.storeInfo.name}</Text>
                    </Flex>
                  </Flex>
                  <Flex>
                    <Flex width="140px">
                      <Text weight="bold" color="black75">
                        Status Pembayaran
                      </Text>
                    </Flex>
                    <Flex width="calc(100% - 140px)">
                      <Text color="black75">
                        {data?.payment_status_name || "LUNAS"}
                      </Text>
                    </Flex>
                  </Flex>
                  <Flex>
                    <Flex width="140px">
                      <Text weight="bold" color="black75">
                        Tanggal Bayar
                      </Text>
                    </Flex>
                    <Flex width="calc(100% - 140px)">
                      <Text color="black75">
                        {data.payment.payment_paid_date
                          ? data.payment.payment_paid_date
                          : "-"}
                      </Text>
                    </Flex>
                  </Flex>
                  <Flex>
                    <Flex width="140px">
                      <Text weight="bold" color="black75">
                        Metode Bayar
                      </Text>
                    </Flex>
                    <Flex width="calc(100% - 140px)">
                      <Text color="black75">{data.payment_method_name}</Text>
                    </Flex>
                  </Flex>
                  <Flex>
                    <Flex width="140px">
                      <Text weight="bold" color="black75">
                        Metode Kirim
                      </Text>
                    </Flex>
                    <Flex width="calc(100% - 140px)">
                      <Text color="black75">
                        {data.shipping.logistics_courier_name} -{" "}
                        {data.shipping.logistics_courier_service_name}
                      </Text>
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>
              <Flex flexGrow={1} width="50%" flexDirection="column" gap="16px">
                <Text color="black75" weight="bold">
                  Tujuan Pengiriman:
                </Text>
                <Flex flexDirection="column" gap="8px">
                  <Text color="black75">
                    {data.shipping.destination_pic_name}
                  </Text>
                  <Text color="black75">
                    {handleMasking(data.shipping.destination_pic_phone, true)}
                  </Text>
                  <Text color="black75">
                    {handleMasking(data.shipping.destination_address)}
                  </Text>
                  <Text color="black75">
                    {handleMasking(data.shipping.destination_district_name)} -{" "}
                    {handleMasking(data.shipping.destination_city_name)} -{" "}
                    {handleMasking(data.shipping.destination_province_name)} -{" "}
                    {handleMasking(data.shipping.destination_postal_code)}
                  </Text>
                </Flex>
              </Flex>
            </Flex>

            <Flex flexDirection="column" gap="24px">
              <Flex
                borderColor="black10"
                borderWidth="1px"
                borderStyle="solid"
                borderRadius="8px"
                flexDirection="column"
              >
                <Flex backgroundColor="black5">
                  <Flex padding="12px 16px" width="calc(100% - 364px)">
                    <Text color="black75" weight="bold">
                      Nama Produk
                    </Text>
                  </Flex>

                  <Flex padding="12px 16px" width="149px">
                    <Text color="black75" weight="bold">
                      Harga Produk
                    </Text>
                  </Flex>
                  <Flex padding="12px 16px" width="67px">
                    <Text color="black75" weight="bold">
                      Jumlah
                    </Text>
                  </Flex>
                  <Flex
                    padding="12px 16px"
                    width="148px"
                    justifyContent="flex-end"
                  >
                    <Text color="black75" weight="bold">
                      Subtotal
                    </Text>
                  </Flex>
                </Flex>
                <Flex flexDirection="column">
                  {data.products.map((product, indexProduct) => (
                    <Fragment key={`product-row-${indexProduct}`}>
                      <Flex>
                        <Flex
                          padding="12px 16px"
                          width="calc(100% - 364px)"
                          flexDirection="column"
                        >
                          <Flex
                            cursor="pointer"
                            onClick={() => goToUrl(product.product_url)}
                          >
                            <Text color="blue50">{product.product_name}</Text>
                          </Flex>
                          {product.variant_name && (
                            <Text variant="caption" color="black75">
                              varian: {product.variant_name}
                            </Text>
                          )}
                        </Flex>
                        <Flex
                          padding="12px 16px"
                          width="149px"
                          alignItems="center"
                        >
                          <Text color="black75">
                            {product.formatted_order_product_unit_price}
                          </Text>
                        </Flex>
                        <Flex
                          padding="12px 16px"
                          width="67px"
                          alignItems="center"
                        >
                          <Text color="black75">
                            {product.formatted_order_product_quantity}
                          </Text>
                        </Flex>
                        <Flex
                          padding="12px 16px"
                          width="148px"
                          justifyContent="flex-end"
                          alignItems="center"
                        >
                          <Text color="black75">
                            {product.formatted_order_product_subtotal}
                          </Text>
                        </Flex>
                      </Flex>
                      <Divider />
                    </Fragment>
                  ))}

                  <Flex>
                    <Flex padding="12px 16px" width="calc(100% - 364px)" />
                    <Flex padding="12px 16px" width="149px" alignItems="center">
                      <Text color="black75" weight="bold">
                        Subtotal Harga
                      </Text>
                    </Flex>
                    <Flex
                      padding="12px 16px"
                      width="27px"
                      alignItems="center"
                    />
                    <Flex
                      padding="12px 16px"
                      width="188px"
                      justifyContent="flex-end"
                      alignItems="center"
                    >
                      <Text color="black75" weight="bold">
                        {data.formatted_order_products_total_price}
                      </Text>
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>

              <Flex alignItems="flex-end" flexDirection="column" gap="24px">
                <Flex
                  borderColor="black10"
                  borderWidth="1px"
                  borderStyle="solid"
                  borderRadius="8px"
                  width="50%"
                  px="16px"
                  flexDirection="column"
                >
                  <Flex justifyContent="space-between" py="16px">
                    <Text>Subtotal Harga</Text>
                    <Text>{data.formatted_total_price}</Text>
                  </Flex>
                  <Divider />
                  <Flex justifyContent="space-between" py="16px">
                    <Text>Commission Fee </Text>
                    <Text>{data?.formatted_commission_fee}</Text>
                  </Flex>
                  <Flex justifyContent="space-between" py="16px">
                    <Text>Ongkos Kirim</Text>
                    <Text>{data.shipping.order_shipping_price_fmt}</Text>
                  </Flex>
                  {!data.shipping.is_seller_fleet && (
                    <>
                      <Divider />
                      <Flex justifyContent="space-between" py="16px">
                        <Text>Ongkir diteruskan ke kurir</Text>
                        <Text>- {data.shipping.order_shipping_price_fmt}</Text>
                      </Flex>
                    </>
                  )}
                  {data.promo_used.length > 0 && (
                    <>
                      <Divider />
                      <Flex justifyContent="space-between" py="16px">
                        <Text>Diskon Toko</Text>
                        <Text>
                          {data.promo_used[0].formatted_seller_voucher_disc}
                        </Text>
                      </Flex>
                    </>
                  )}
                </Flex>

                <Flex
                  justifyContent="space-between"
                  padding="16px"
                  borderColor="black10"
                  borderWidth="1px"
                  borderStyle="solid"
                  borderRadius="8px"
                  width="50%"
                >
                  <Text color="blue50" weight="bold">
                    Total Penjualan
                  </Text>
                  <Text color="blue50" weight="bold">
                    Rp{" "}
                    {
                      numberInputChecking(totalSold.toString() || "0", 0)
                        .formattedValue
                    }
                  </Text>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      )}
    </Flex>
  );
};

export default PrintInvoice;
