import React from "react";
import { Flex, Skeleton } from "renos-ui";

const SkeletonSidebar = () => {
  return (
    <Flex padding="8px" gap="12px" alignItems="center">
      <Skeleton.Avatar />
      <Flex flexGrow="1">
        <Skeleton />
      </Flex>
    </Flex>
  );
};

export default SkeletonSidebar;