import dayjs from "dayjs";
import { useMemo, useState } from "react";

import {
  Flex,
  Icon,
  Text,
  Box,
  Progress,
  Avatar,
  Divider,
  Input,
  Chip,
  Button,
} from "renos-ui";
import { Wrapper } from "../styled";

const WriteReview = ({
  item,
  setItem,
  templates,
  toggleDrawer,
  selectedReview,
  handleSaveReply,
  isLoadingTemplate,
  isLoadingSaveReply,
}) => {
  const baseImgUrl = process.env.REACT_APP_IMAGE_AWS;
  const [inputFocus, setInputFocus] = useState(item?.tempReply?.length > 0);

  const handleSetTemplate = (template) => {
    setItem((prevItem) => ({
      ...prevItem,
      tempReply: template.message,
      templateId: template.id,
    }));
    setInputFocus(true);
  };

  const isShowSaveButton = useMemo(() => {
    if (item?.tempReply?.length > 0) {
      return true;
    }
  }, [item]);

  return (
    <Flex flexDirection="column" gap="16px">
      <Box
        p="16px"
        left="0"
        right="0"
        width="100%"
        position="fixed"
        backgroundColor="white"
        boxShadow="0px 0px 8px 0px rgba(20, 20, 32, 0.05)"
      >
        <Flex gap="16px" flexDirection="row">
          <Flex>
            <Icon name="Close-outline" onClick={toggleDrawer} />
          </Flex>
          <Flex>
            <Text weight="bold" variant="subtitle">
              Tulis Ulasan
            </Text>
          </Flex>
        </Flex>
      </Box>
      <Flex
        flexDirection="column"
        gap="16px"
        mt="59px"
        overflowY="auto"
        height="calc(100vh - 64px)"
      >
        <Flex flexDirection="column" gap="4px" padding="16px">
          <Flex flexDirection="row" justifyContent="space-between">
            <Flex flexDirection="row" gap="8px">
              <Text weight="bold" variant="body" lineClamp={1}>
                {selectedReview?.user?.user_name}
              </Text>
              <Flex flexDirection="row" alignItems="center" gap="4px">
                <Icon name="Circle-solid" color="black25" size="4px" />
                <Text variant="caption" color="black75" width="80px">
                  {dayjs(
                    selectedReview.messages.find(
                      (message) => message.primary === false
                    )?.created_date
                  ).format("D MMM YYYY")}
                </Text>
              </Flex>
            </Flex>
          </Flex>
          <Text variant="caption" color="black50" lineClamp={1}>
            {selectedReview?.product?.product_name}
          </Text>
          <Progress.Rating
            readOnly
            size="16px"
            gap="4px"
            count={selectedReview.score}
            value={selectedReview.score}
          />
          <Wrapper>
            <Text variant="caption">
              {
                selectedReview?.messages.find(
                  (message) => message.primary === false
                )?.message
              }
            </Text>
          </Wrapper>
        </Flex>
        <Flex flexDirection="row" gap="8px" px="16px">
          {selectedReview?.images?.map((image, indexImg) => (
            <Avatar
              size={40}
              src={`${baseImgUrl}${encodeURI(image?.review_image)}`}
              shape="square"
              key={`product-review-image-${indexImg}`}
            />
          ))}
        </Flex>
        <Flex>
          <Divider />
        </Flex>
        <Flex flexDirection="column" gap="9px" px="16px">
          <Input.TextArea
            label="Balasan"
            maxLength={1000}
            showCount
            value={item?.tempReply || ""}
            rows={inputFocus ? 10 : 1}
            readOnly={isLoadingSaveReply}
            placeholder="Tulis balasanmu untuk ulasan ini"
            onFocus={() => setInputFocus(true)}
            onChange={({ target: { value } }) => {
              setItem((prev) => ({
                ...prev,
                tempReply: value,
                templateId: null,
              }));
            }}
          />

          <Flex gap="8px" flexWrap="wrap" mb="150px">
            {isLoadingTemplate && (
              <Text variant="caption">Load Template Balasan...</Text>
            )}
            {templates?.map((list) => (
              <Chip
                size="small"
                key={list.id}
                label={list.name}
                borderRadius="100px"
                onClick={() =>
                  !isLoadingSaveReply ? handleSetTemplate(list) : null
                }
                backgroundColor={
                  item?.templateId === list.id ? "blue5" : "white"
                }
                color={item?.templateId === list.id ? "blue50" : "black75"}
                borderColor={
                  item?.templateId === list.id ? "blue50" : "black10"
                }
              />
            ))}
          </Flex>
        </Flex>
        {isShowSaveButton && (
          <Flex
            left="0"
            right="0"
            bottom="0"
            padding="16px"
            position="fixed"
            backgroundColor="white"
            boxShadow="0 0 8px 0 rgba(0, 0, 0, 0.05)"
          >
            <Button
              isBlock
              size="small"
              disabled={isLoadingSaveReply}
              onClick={handleSaveReply}
            >
              {!isLoadingSaveReply ? "Kirim" : "Mengirim..."}
            </Button>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};

export default WriteReview;
