import sidebarData from "constants/sidebar";
import React, { useMemo } from "react";
import {
  Flex,
  Box,
  Avatar,
  Text,
  Icon,
  Divider,
  Tooltip,
  // Button
} from "renos-ui";
import { useCheckStore } from "services/auth";
import SidebarItem from "./SidebarItem";
import { SidebarWrapper } from "./styled";
// import desktopUrl from "constants/desktopUrl";
// import { useHistory } from "react-router-dom";
import { useGetChatCount } from "services/chat";

const Sidebar = ({
  isMinimized,
  setIsMinimized,
  handleClick,
  handleOnMouseLeave,
}) => {
  const { data: storeData } = useCheckStore();
  // const history = useHistory();

  const defaultImg = "/assets/images/default_store.svg";
  const SrcImgProfile = useMemo(
    () =>
      storeData?.data?.image_url || storeData?.data?.image_path || defaultImg,
    [storeData]
  );

  const { data } = useGetChatCount();
  sidebarData[1].badge = data;

  return (
    <SidebarWrapper
      flexDirection="column"
      width={isMinimized ? "72px" : "230px"}
      flexShrink={0}
      backgroundColor="white"
      borderTopRightRadius="40px"
      flexwrap="nowrap"
      boxShadow="0px 4px 4px rgba(20, 20, 32, 0.05)"
      rowGap="16px"
      pt="24px"
      alignItems="center"
      position="sticky"
      top={0}
      className="welcome-step"
      onMouseLeave={handleOnMouseLeave}
    >
      <Flex flexDirection="column" rowGap="32px" alignItems="center">
        <Box
          as="img"
          src={
            !isMinimized
              ? "/assets/images/seller-center.png"
              : "/assets/images/seller-center-icon.svg"
          }
          width={isMinimized ? "24px" : "114px"}
          height={isMinimized ? "24px" : "20px"}
        />
        <Flex alignItems="center" flexDirection="column" rowGap="24px">
          <Flex
            flexDirection="column"
            alignItems="center"
            rowGap="8px"
            px="16px"
            style={{ wordBreak: "break-all" }}
          >
            <Avatar
              backgroundColor="blue5"
              size={!isMinimized ? 54.86 : 40}
              isBorder
              src={SrcImgProfile}
            />
            {!isMinimized && (
              <Tooltip placement="bottom-left" label={storeData?.data?.name}>
                <Text size="caption" weight="bold" lineClamp={1}>
                  {storeData?.data?.name}
                </Text>
              </Tooltip>
            )}
          </Flex>

          {/* saldo */}
          {/* {!isMinimized && (
            <Flex
              width="198px"
              borderRadius="8px"
              background="url(/assets/images/dashboard/saldo.png)"
              backgroundPosition="top"
              backgroundRepeat="no-repeat"
              backgroundSize="100% 100%"
              alignItems="center"
              justifyContent="space-between"
            >
              <Flex flexDirection="column" margin="16px">
                <Text variant="small" size color="white">
                  Total Saldo Aktif
                </Text>
                <Text variant="caption" weight="bold" color="white">
                  Rp. 10.000.000
                </Text>
              </Flex>
              <Flex margin="16px">
                <Button
                  size="small"
                  icon={<Icon size={16} name="Right-outline" />}
                  variant="circular"
                  onClick={() => history.push(desktopUrl.WALLET)}
                />
              </Flex>
            </Flex>
          )} */}
        </Flex>
      </Flex>

      {/* menu */}
      <Flex
        width="100%"
        flexDirection="column"
        rowGap="2px"
        flexGrow={1}
        overflow="auto"
        alignItems={isMinimized && "center"}
      >
        {sidebarData.map((item, menuIndex) => (
          <SidebarItem
            key={menuIndex}
            isMinimized={isMinimized}
            item={item}
            setIsMinimized={setIsMinimized}
          />
        ))}
      </Flex>

      <Flex
        bg="white"
        flexDirection="column"
        cursor="pointer"
        color="black50"
        onClick={handleClick}
        width="100%"
        position="sticky"
        bottom="0"
      >
        <Divider orientation="horizontal" />
        <Flex padding="12px 16px" justifyContent={isMinimized && "center"}>
          {isMinimized ? (
            <Icon name={"Right-outline"} size={20} color="black50" />
          ) : (
            <Flex gap="8px" alignItems="center">
              <Icon name={"Left-outline"} size={20} color="black50" />
              <Text variant="caption" color="black100">
                Sembunyikan Menu
              </Text>
            </Flex>
          )}
        </Flex>
      </Flex>
    </SidebarWrapper>
  );
};

export default Sidebar;
