import { colors } from "renos-ui";
import styled from "styled-components";
import { Swiper } from "swiper/react";

export const StyledSwiper = styled(Swiper)`
  position: relative;
  .swiper-wrapper {
    position: relative;
    width: calc(100vw - 64px);
    padding-top: 60px;
    padding-bottom: 40px;
    height: fit-content;
    div {
      box-sizing: border-box;
    }
  }
  .swiper-pagination {
    .swiper-pagination-bullet {
      height: 6px;
      width: 6px;
      margin: 0 3px !important;
    }
    .swiper-pagination-bullet-active {
      background-color: ${colors.blue50};
      width: 12px;
      border-radius: 6px;
    }
  }
`;
