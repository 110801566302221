import desktopUrl from "constants/desktopUrl";
import StoreLayoutContext from "providers/StoreLayoutProvider";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Flex,
  Button,
  Divider,
  useToast,
  range,
  Checkbox,
  Text,
  Box,
  Skeleton,
  Icon,
  useDisclosure,
} from "renos-ui";
import { useGetEtalase } from "services/store-layout/etalase";
import { useUpdateHomePage } from "services/store-layout/homepage";
import Header from "../component/Header";
import NoData from "../component/NoData";
import ModalDelete from "../component/ModalDelete";

const StoreFront = ({ handleBack }) => {
  const toast = useToast();
  const { push } = useHistory();
  const disclosureDelete = useDisclosure({ isOpen: false });
  const maxProduct = 6;

  const [dataStoreFront, setDataStoreFront] = useState({ data: [] });
  const [showWarning, setShowWarning] = useState(true);

  const { dataHomePage, setDataHomePage } = useContext(StoreLayoutContext);

  const {
    data: dataGetEtalase,
    isLoading: isEtalaseLoading,
    refetch: refetchEtalase,
    isRefetching: isEtalaseRefetching,
  } = useGetEtalase();

  const { mutate, isLoading: isUpdateHomePageLoading } = useUpdateHomePage({
    onSuccess: (data) => {
      const { message, frontend_message } = data;
      toast({
        label: frontend_message || message,
        placement: "top",
      });
      handleBack();
    },
    onError: (data) => {
      const {
        response: {
          data: { frontend_message, message },
        },
      } = data;
      toast({
        label: frontend_message || message,
        placement: "top",
        backgroundColor: "red50",
      });
    },
  });

  const handleSave = () => {
    const tempIds = dataStoreFront.data.map(
      (storefront) => storefront.tag_store_id
    );
    mutate({
      type: 5,
      ids: tempIds,
    });
  };

  const handleReset = () => {
    const tempDataHomePage = dataHomePage.map((homepage) => ({
      ...homepage,
      isActive: homepage.id === 5 ? false : homepage.isActive,
      data: homepage.id === 5 ? [] : homepage.data,
    }));
    setDataHomePage([...tempDataHomePage]);
    disclosureDelete.onClose();
  };

  const isAllEtalaseLoading = useMemo(
    () => isEtalaseLoading || isEtalaseRefetching,
    [isEtalaseLoading, isEtalaseRefetching]
  );

  const isAllChecked = useMemo(() => {
    const totalSelected = dataStoreFront.data ? dataStoreFront?.data.length : 0;
    const checkedResult = {
      active: false,
      indeterminate: false,
    };

    if (totalSelected > 0 && totalSelected < maxProduct)
      checkedResult.indeterminate = true;
    if (totalSelected === maxProduct) checkedResult.active = true;

    return checkedResult;
  }, [dataStoreFront]);

  const isChecked = (id) => {
    const checkIncludedId = dataStoreFront.data.filter(
      (filterStoreFront) => filterStoreFront.tag_store_id === id
    );

    return checkIncludedId.length > 0 && true;
  };

  const handleCheck = (data) => {
    let tempData = dataStoreFront.data;

    if (isChecked(data.tag_store_id)) {
      tempData = tempData.filter(
        (filterStoreFront) =>
          filterStoreFront.tag_store_id !== data.tag_store_id
      );
    } else {
      tempData.push(data);
    }

    const tempDataHomePage = dataHomePage.map((homepage) => ({
      ...homepage,
      isActive:
        homepage.id === 5 ? tempData.length > 0 && true : homepage.isActive,
      data: homepage.id === 5 ? [...tempData] : homepage.data,
    }));
    setDataHomePage([...tempDataHomePage]);
  };

  const handleCheckAll = () => {
    let tempData = dataStoreFront?.data || [];

    if (
      tempData?.length === maxProduct ||
      tempData?.length === dataGetEtalase?.data?.length
    )
      tempData = [];
    else {
      const listId = tempData.map((dataSelected) => dataSelected.tag_id);

      for (let i = 0; i < dataGetEtalase?.data?.length; i++) {
        if (
          !listId.includes(dataGetEtalase?.data[i].tag_id) &&
          tempData?.length < maxProduct
        )
          tempData.push(dataGetEtalase?.data[i]);

        if (tempData.length === maxProduct) break;
      }
    }

    const tempDataHomePage = dataHomePage.map((homepage) => ({
      ...homepage,
      isActive:
        homepage.id === 5 ? tempData.length > 0 && true : homepage.isActive,
      data: homepage.id === 5 ? [...tempData] : homepage.data,
    }));

    setDataHomePage([...tempDataHomePage]);
  };

  useEffect(() => {
    const selectedNewPrduct = dataHomePage.filter(
      (homepage) => homepage.id === 5
    )[0];
    selectedNewPrduct.data = selectedNewPrduct.data
      ? selectedNewPrduct.data
      : [];
    setDataStoreFront(selectedNewPrduct);
  }, [dataHomePage]);

  return (
    <Flex flexDirection="column" gap="16px">
      <Header handleBack={handleBack} title="Navigasi Etalase" />

      {dataGetEtalase?.data.length === 0 ? (
        <NoData
          title="Belum Ada Etalase"
          description="Yuk, mulai tambahkan etalase terlebih dahulu."
          onClick={() => push(desktopUrl.SHOWCASE)}
          btnText="Tambah Etalase"
          isHasRefetchButton={true}
          refetch={refetchEtalase}
          refetchText="Sudah ada etalase?"
        />
      ) : (
        <>
          {showWarning && (
            <Flex
              alignItems="center"
              padding="10px 12px"
              borderRadius="8px"
              backgroundColor="yellow5"
              justifyContent="space-between"
            >
              <Flex gap="8px" alignItems="center">
                <Icon name="Info-solid" color="yellow50" size={16} />
                <Text variant="caption" color="yellow100">
                  Maks.{" "}
                  <Text variant="caption" weight="bold" color="yellow100">
                    {maxProduct}
                  </Text>{" "}
                  etalase yang dapat ditampilkan.
                </Text>
              </Flex>
              <Icon
                name="Close-outline"
                color="black50"
                size={20}
                cursor="pointer"
                onClick={() => setShowWarning(false)}
              />
            </Flex>
          )}

          <Flex gap="16px" alignItems="center">
            <Checkbox
              onChange={handleCheckAll}
              checked={isAllChecked.active}
              indeterminate={isAllChecked.indeterminate}
              _checkedException
              disabled={isAllEtalaseLoading}
            />
            <Text color="black75">
              <Text color="black75" weight="bold">
                {dataStoreFront?.data.length}
              </Text>{" "}
              dari{" "}
              <Text color="black75" weight="bold">
                {maxProduct}
              </Text>{" "}
              Etalase dipilih
            </Text>
          </Flex>
          <Divider />
          <Flex
            height="195px"
            overflowY="auto"
            flexDirection="column"
            width="100%"
            gap="16px"
          >
            {isAllEtalaseLoading
              ? range(3).map((_, indexSkeleton) => (
                  <React.Fragment key={`etalase-skeleton-${indexSkeleton}`}>
                    <Flex gap="16px" alignItems="center">
                      <Skeleton height="20px" width="20px" />
                      <Flex gap="12px" width="100%">
                        <Skeleton height="40px" width="40px" />
                        <Flex flexDirection="column" width="80%" gap="4px">
                          <Skeleton height="12px" width="80%" />
                          <Skeleton height="12px" width="50%" />
                        </Flex>
                      </Flex>
                    </Flex>
                    {indexSkeleton !== 2 && (
                      <Box>
                        <Divider />
                      </Box>
                    )}
                  </React.Fragment>
                ))
              : dataGetEtalase?.data.map((etalase, indexEtalase) => (
                  <React.Fragment key={`etalase-${indexEtalase}`}>
                    <Flex gap="16px" alignItems="center">
                      <Checkbox
                        onChange={() => handleCheck(etalase)}
                        checked={isChecked(etalase.tag_store_id)}
                        disabled={
                          dataStoreFront?.data.length === maxProduct &&
                          !isChecked(etalase.tag_store_id)
                        }
                      />
                      <Flex gap="12px">
                        <Box
                          as="img"
                          src={etalase.tag_image_url}
                          height="40px"
                          width="40px"
                          borderRadius="8px"
                          alt="img-etalase"
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = "/assets/images/empty-product.svg";
                          }}
                        />
                        <Flex flexDirection="column">
                          <Text weight="bold" lineClamp={1}>
                            {etalase?.tag?.tag_name || etalase?.tag_name}
                          </Text>
                          <Text variant="caption" color="black75" lineClamp={1}>
                            {etalase.total_products} produk
                          </Text>
                        </Flex>
                      </Flex>
                    </Flex>
                    {dataGetEtalase.data.length - 1 !== indexEtalase && (
                      <Box>
                        <Divider />
                      </Box>
                    )}
                  </React.Fragment>
                ))}
          </Flex>
          <Divider />
          <Flex gap="16px" paddingBottom="24px">
            {dataStoreFront.data.length > 0 ? (
              <Flex flexGrow={1}>
                <Button
                  size="medium"
                  variant="tertiary"
                  isBlock
                  onClick={disclosureDelete.onToggle}
                >
                  Hapus Pengaturan
                </Button>
              </Flex>
            ) : (
              <Flex flexGrow={1} flexBasis={0}>
                <Button
                  size="medium"
                  variant="tertiary"
                  isBlock
                  onClick={handleBack}
                >
                  Batalkan
                </Button>
              </Flex>
            )}
            <Flex
              flexGrow={1}
              flexBasis={dataStoreFront.data.length === 0 && 0}
            >
              <Button
                size="medium"
                isBlock
                disabled={isUpdateHomePageLoading}
                onClick={handleSave}
              >
                Simpan
              </Button>
            </Flex>
          </Flex>
        </>
      )}

      <ModalDelete
        isOpen={disclosureDelete.isOpen}
        onClose={disclosureDelete.onClose}
        onConfirm={handleReset}
      />
    </Flex>
  );
};

export default StoreFront;
