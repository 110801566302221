import MobileLayout from "layouts/MobileLayout";
import React, { useCallback, useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";
import {
  Box,
  Button,
  Divider,
  Flex,
  Spinner,
  groupBy,
  isEmpty,
  pick,
  useToast,
} from "renos-ui";
import ProductAssets from "./components/ProductAssets";
import ProductInformations from "./components/ProductInformations";
import ProductDetail from "./components/ProductDetail";
import ProductSpesification from "./components/ProductSpesification";
import ProductShipping from "./components/ProductShipping";
import {
  productAddInitialState,
  useProductAddContext,
} from "store/productAdd/reducer";
import { useGetProductDetail, useSaveProduct } from "services/product";
import {
  formValueToJson,
  mobileValidationEachFields,
} from "helpers/attribute-mapper/product-add";
import mobileUrl from "constants/mobileUrl";
import constants from "store/productAdd/constants";
import useQueryParams from "hooks/useQueryParams";
import { setProductMobileInitialValue } from "helpers/attribute-mapper/product-edit";
import { useGetVariantValue } from "services/product-variant";
import { getUniqueListBy } from "helpers";
import { variantToSkuFormat } from "helpers/attribute-mapper/product-variant";

const ProductAdd = () => {
  const { push, ...history } = useHistory();
  const toast = useToast();
  const query = useQueryParams();

  const { state: values, dispatch } = useProductAddContext();

  const {
    productVariants,
    sku,
    fromEmpty,
    temp_sku: tempSku,
    tempId,
    initialFilled,
    skeletonFilled,
    product_attributes,
  } = values;

  const id = query.get("id");

  const {
    data: productDetail,
    isLoading: isGetLoading,
    remove,
  } = useGetProductDetail(id, {
    enabled: !!id && isEmpty(values.tempId),
  });

  const isEdit = useMemo(() => !isEmpty(productDetail), [productDetail]);

  const isProductAttributeEmpty = useMemo(
    () =>
      product_attributes.length > 0 &&
      product_attributes.filter(
        (item) =>
          item.data_type !== "file" &&
          item.is_required === 1 &&
          isEmpty(item.product_attribute_value)
      ).length > 0,
    [product_attributes]
  );

  const productWithValue = useMemo(
    () =>
      productVariants.filter((variant) => variant?.variant_values?.length > 0),
    [productVariants]
  );

  const onBack = useCallback(
    (isOrganicBack) => {
      if (!isOrganicBack) {
        push(mobileUrl.PRODUCTS_LIST);
      }

      dispatch({
        type: constants.SAVE_VALUE,
        payload: productAddInitialState,
      });
    },
    [dispatch, push]
  );

  useEffect(() => {
    if (isEdit) return;
    if (initialFilled) return;
    dispatch({
      type: constants.SAVE_VALUE,
      payload: { ...productAddInitialState, initialFilled: true },
    });
  }, [isEdit, dispatch, initialFilled]);

  useEffect(() => {
    const unlisten = history.listen((location, action) => {
      if (action === "POP") {
        onBack();
      }
    });

    return () => {
      unlisten();
    };
  }, [history, onBack]);

  useEffect(() => {
    if (!isEdit) return;
    if (isEdit && !isEmpty(tempId)) return;

    setProductMobileInitialValue(dispatch, productDetail, id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, productDetail, dispatch, id]);

  useEffect(() => {
    if (isEmpty(productDetail)) return;

    if (productDetail?.product_skus.length > 0) {
      const isVariant = productDetail?.product_skus[0].variants.length > 0;

      if (isVariant) {
        const tempProductVariant = productDetail?.product_skus[0].variants.map(
          (data) => ({
            variant_id: data.variant,
            variant_name: data.variant_label,
            variant_values: [],
            isEdit: true,
          })
        );

        dispatch({
          type: constants.SAVE_VALUE,
          payload: {
            productVariants: tempProductVariant,
          },
        });
        setTimeout(() => {
          tempProductVariant.forEach((data) => {
            const params = {
              "variant[]": data.variant_id,
            };
            getVariantValue(params);
          });
        }, 250);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productDetail]);

  const { mutate: getVariantValue } = useGetVariantValue({
    onSuccess: (data) => {
      const tempProductVariant = [...productVariants];
      const selectedIndex = tempProductVariant.findIndex(
        (variant) => variant.variant_id === data[0].variant_id
      );
      tempProductVariant[selectedIndex].options = data;

      const allVariantValues = productVariants.map(
        (variant) => variant.variant_values
      );

      const newData = [];

      allVariantValues.forEach((data, i) => {
        const reFlatted = [
          ...new Map(data.map((m) => [m.variant_value_id, m])).values(),
        ];

        newData.push({
          ...tempProductVariant[i],
          variant_values_minify: reFlatted,
        });
      });

      dispatch({
        type: constants.SAVE_VALUE,
        payload: {
          productVariants: newData,
        },
      });
    },
  });

  const isProductVariantsHaveOptions = productVariants
    ? productVariants.every((data) => Object.keys(data).includes("options"))
    : false;

  useEffect(() => {
    if (
      isProductVariantsHaveOptions &&
      !isEmpty(productDetail) &&
      !isEmpty(tempId)
    ) {
      const flattenSku = productDetail.product_skus
        .map((sku) => sku.variants)
        .flat();

      const reducedSku = getUniqueListBy(flattenSku, "variant_value");

      const groupedSku = groupBy(reducedSku, (sku) => sku.variant_label);

      const tempProductVariant = [...productVariants];

      Object.keys(groupedSku).forEach((key) => {
        const exposedIndex = tempProductVariant.findIndex(
          (variant) => variant?.variant_name === key
        );

        groupedSku[key].forEach((variant) =>
          tempProductVariant[exposedIndex]?.variant_values.push({
            variant_value_id: variant?.variant_value,
            variant_id: variant?.variant,
            variant_name: variant?.variant_label,
            variant_value: variant?.label,
            label: variant?.label,
            value: variant?.variant_value,
          })
        );
        groupedSku[key].forEach((variant) =>
          tempProductVariant[exposedIndex]?.variant_values_minify.push({
            variant_value_id: variant?.variant_value,
            variant_id: variant?.variant,
            variant_name: variant?.variant_label,
            variant_value: variant?.label,
            label: variant?.label,
            value: variant?.variant_value,
          })
        );
      });

      dispatch({
        type: constants.SAVE_VALUE,
        payload: {
          productVariants: tempProductVariant,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isProductVariantsHaveOptions, productDetail]);

  useEffect(() => {
    if ((isEmpty(productDetail) && sku?.length === 0) || fromEmpty) return;

    const fetchedSkus = productDetail?.product_skus;

    const formattedSku = sku.map((skuData) => {
      skuData.variant_name = skuData.variants.map((data) => data.label);

      return skuData;
    });

    if (fetchedSkus && !isEmpty(formattedSku) && !initialFilled) {
      const skuLength = fetchedSkus.length;
      const tempSku = formattedSku.map((skuData) => {
        const fetchedData = fetchedSkus.find((data) =>
          skuData.variant_name.every((variant) =>
            data.variant_name.includes(variant)
          )
        );

        if (skuLength === 1 && fetchedData.is_default === 0) {
          fetchedData.is_default = 1;
        }

        const productWeight = parseInt(fetchedData?.product_weight);

        skuData.price = fetchedData?.product_price;
        skuData.stock = fetchedData?.product_stock;
        skuData.default = fetchedData?.is_default;
        skuData.status = fetchedData?.product_status_id;
        skuData.weight = productWeight;
        skuData.number = fetchedData?.product_sku_mpn;
        skuData.image_id = fetchedData?.product_image_id;
        skuData.image_url = fetchedData?.product_image_url;
        skuData.image = fetchedData?.product_image_path;
        skuData.product_sku_id = fetchedData?.product_sku_id;
        skuData.is_product_discount_seasonal_active =
          fetchedData?.is_product_discount_seasonal_active;

        return skuData;
      });

      dispatch({
        type: constants.SAVE_VALUE,
        payload: {
          sku: tempSku,
          initialFilled: true,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productDetail, sku, initialFilled, fromEmpty]);

  useEffect(() => {
    if (!isEdit) return;
    if (productWithValue.length === 0) return;
    if (skeletonFilled) return;

    const formattedData = variantToSkuFormat(productWithValue, isEdit);
    let formattedSku = formattedData.map((data) =>
      pick(data, [
        "index",
        "id",
        "variants",
        "price",
        "stock",
        "status",
        "default",
        "number",
        "weight",
      ])
    );

    if (tempSku) {
      const tempFormattedSku = [...formattedSku];
      formattedSku = tempFormattedSku.map((data) => {
        const varName =
          data.variants.length > 1
            ? data.variants.map((variant) => variant.label)
            : data.variants[0].label;

        const selected = tempSku.find((sku) =>
          data.variants.length > 1
            ? sku.variant_name === varName.join("|") ||
              sku.variant_name === varName.reverse().join("|")
            : sku.variant_name === varName
        );

        if (selected) {
          const newData = { ...data };
          const productWeight = parseInt(selected?.product_weight);

          newData.price = selected?.product_price;
          newData.stock = selected?.product_stock;
          newData.status = selected?.product_status_id;
          newData.default = selected?.is_default;
          newData.weight = productWeight;
          newData.number = selected?.product_sku_mpn;
          newData.image_id = selected?.product_image_id;
          newData.image_url = selected?.product_image_url;
          newData.image = selected?.product_image_path;
          newData.product_sku_id = selected?.product_sku_id;
          newData.is_product_discount_seasonal_active =
            selected?.is_product_discount_seasonal_active;

          return newData;
        }

        return data;
      });
    }

    dispatch({
      type: constants.SAVE_VALUE,
      payload: {
        sku: formattedSku,
        skeletonFilled: true,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productWithValue, isEdit, skeletonFilled]);

  const { mutate, isLoading } = useSaveProduct({
    onSuccess: (_) => {
      toast({
        label: isEdit
          ? "Produk kamu berhasil diedit!"
          : "Produk kamu berhasil ditambahkan!",
        placement: "bottom",
      });

      remove();

      setTimeout(() => {
        push(mobileUrl.PRODUCTS_LIST);
        dispatch({
          type: constants.SAVE_VALUE,
          payload: productAddInitialState,
        });
      }, 1500);
    },
    onError: (data) => {
      if (data?.response.status === 400) {
        toast({
          label: "Nama produk sudah tersedia!",
          placement: "bottom",
        });
      }
    },
  });

  const onFinish = () => {
    if (isProductAttributeEmpty) {
      toast({
        label: "Lengkapi data standarisasi produk untuk melanjutkan",
        placement: "bottom",
      });

      return;
    }

    if (values.variant === 1 && isEmpty(values.sku)) {
      toast({
        label: "Product variant tidak boleh kosong",
        placement: "bottom",
      });

      return;
    }

    if (!mobileValidationEachFields(values)) {
      toast({
        label: "Lengkapi data sebelum melanjutkan",
        placement: "bottom",
      });

      return;
    }

    //handling numeric product_attributes cant change 0
    values.product_attributes = values.product_attributes.map((attribute) => ({
      ...attribute,
      product_attribute_value: attribute.product_attribute_value || "",
    }));

    const payload = formValueToJson(values, isEdit, true);

    mutate(payload);
  };

  if (isGetLoading) {
    return (
      <Flex
        height="100dvh"
        width="100%"
        justifyContent="center"
        alignItems="center"
      >
        <Spinner />
      </Flex>
    );
  }

  return (
    <MobileLayout
      header={{
        title: `${isEdit ? "Edit" : "Tambah"} Produk`,
        onBack,
        withBack: true,
        withoutGoBack: true,
        backIconName: "Close-outline",
      }}
    >
      <Flex
        width="100%"
        p="16px"
        flexDirection="column"
        flex={1}
        justifyContent="space-between"
      >
        <Flex
          flexDirection="column"
          rowGap="16px"
          height="calc(100% - 45px)"
          overflow="auto"
        >
          <ProductAssets />
          <Divider />
          <ProductInformations />
          <Divider />
          <ProductDetail />
          <Divider />
          <ProductSpesification />
          <Divider />
          <ProductShipping />
          <Divider />
        </Flex>
        <Box bg="white" pt="16px">
          <Button
            preffix={isLoading && <Spinner size="10px" color="white" />}
            disabled={isLoading}
            isBlock
            onClick={onFinish}
          >
            Simpan
          </Button>
        </Box>
      </Flex>
    </MobileLayout>
  );
};

export default ProductAdd;
