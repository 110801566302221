import React from "react";
import { Box, Flex, Icon, Text } from "renos-ui";
import { translateDay } from "helpers";

const ListOpenStore = ({ day, time }) => {
  let renderDay;

  if (typeof day === "string") {
    renderDay = translateDay(day);
  } else {
    renderDay = `${translateDay(day[0])} - ${translateDay(
      day[day.length - 1]
    )}`;
  }

  let renderTime;
  const fullDay = "00:00 - 23:00";

  if (time === fullDay) {
    renderTime = "Buka 24 Jam";
  } else {
    renderTime = time;
  }

  return (
    <Flex gap="16px">
      <Icon color="white" size={20} name="Circle-solid" />
      <Box minWidth="100px">
        <Text color="black75">{renderDay}</Text>
      </Box>
      <Text color="black10">|</Text>
      <Text color="black75">{renderTime}</Text>
    </Flex>
  );
};

export default ListOpenStore;
