import styled from "styled-components";
import { Button, Input } from "renos-ui";

export const StyledTextArea = styled(Input.TextArea)`
  resize: none;
`;

export const StyledButton = styled(Button)`
  margin: 12px;
`;
