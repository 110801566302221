import React, { useEffect, useState, useContext, useRef } from "react";
import {
  Flex,
  range,
  isEmpty,
  useUpload,
  useToast,
  useDisclosure,
} from "renos-ui";
import ChatHeaderRoom from "./ChatRoomHeader";
import ChatSidebarEmpty from "./ChatSidebarEmpty";
import ChatSidebarHeader from "./ChatSidebarHeader";
import ChatSidebarItems from "./ChatSidebarItems";
import ChatRoomEmpty from "./ChatRoomEmpty";
import StoreContext from "providers/StoreProvider";
import { QISCUS, event } from "hooks/qiscus";
import SkeletonSidebar from "./SkeletonSidebar";
import {
  arrMonth,
  getTimestamp,
  imgCompressor,
  imageTypeChecking,
  getImageMeta,
  blobToBase64,
} from "helpers";
import ChatRoomBody from "./ChatRoomBody";
import { useGetResetChatCount } from "services/chat";
import dayjs from "dayjs";
import { useGetUserByQiscusId, useUploadChatImage } from "services/chat";
import { IMAGE_FILE_TYPE } from "containers/web/setting/document/constant";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const ChatRoom = () => {
  const toast = useToast();
  const refChat = useRef(null);
  const [userList, setUserList] = useState([]);
  const [emitterStatus, setEmitterStatus] = useState({});
  const [textMessage, setTextMessage] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);
  const [firstOpen, setFirstOpen] = useState(true);
  const [typing, setTyping] = useState(false);
  const [loadBefore, setLoadBefore] = useState(false);
  const attachmentDisclosure = useDisclosure({ isOpen: false });
  const attachProductDisclosure = useDisclosure({ isOpen: false });
  const attachOrderDisclosure = useDisclosure({ isOpen: false });
  const [seletedAttachProduct, setSelectedAttachProduct] = useState([]);
  const [selectedAttachOrder, setSelectedAttachOrder] = useState([]);
  const [buyerInfo, setBuyerInfo] = useState({});
  const [userLoading, setUserLoading] = useState({
    load: true,
    fail: false,
    isFirst: false,
  });
  const {
    qiscusInfo,
    storeData: {
      data: { id: storeId, slug },
    },
  } = useContext(StoreContext);
  const location = useLocation();

  const [online, setOnline] = useState({
    isOnline: false,
    lastOnline: "",
  });
  const [messageList, setMessageList] = useState({
    formated: [],
    normal: [],
  });
  const [loadingUser, setLoadingUser] = useState({
    load: true,
    fail: false,
    isFirst: false,
  });
  const [attachment, setAttachment] = useState(null);

  const { mutate } = useGetResetChatCount();

  const scrollToBottom = (toId) => {
    document
      .getElementById(toId ? toId.toString() : "div-end")
      ?.scrollIntoView({
        top: refChat.current.scrollHeight,
        behavior: toId ? "auto" : "smooth",
      });
  };

  const getTime = (dt) => {
    // eslint-disable-next-line prefer-const
    let temp = new Date(dt);

    return `${temp.getHours()}.${
      temp.getMinutes() > 9 ? temp.getMinutes() : "0" + temp.getMinutes()
    }`;
  };

  const onlineStatus = () => {
    const today = new Date();
    let lastSeen = "";

    if (today.toDateString() === online.lastOnline.toDateString()) {
      lastSeen = getTime(online.lastOnline);
    } else {
      lastSeen = `${online.lastOnline.getDate()} ${
        arrMonth[online.lastOnline.getDay]
      } ${
        today.getFullYear() !== online.lastOnline.getFullYear()
          ? today.getFullYear()
          : ""
      }`;
    }

    return lastSeen;
  };

  const formatYYYYMMDD = (timestamp) => {
    const fullDate = dayjs(timestamp);
    const formatedDate = fullDate.format("YYYY-MM-DD");

    return formatedDate;
  };

  const groupByDates = (tempDate) => {
    return tempDate.reduce((acc, td) => {
      const date = formatYYYYMMDD(td.timestamp);
      acc[date] = acc[date] || [];
      acc[date].push(td);

      return acc;
    }, {});
  };

  const msgFormat = (dt) => {
    const tempDate = dt.map((td) => ({
      ...td,
      dates: formatYYYYMMDD(td.timestamp),
    }));
    const groupedByDates = groupByDates(tempDate);

    return Object.entries(groupedByDates);
  };

  const getRoom = async (qiscusId) => {
    const userParam = {
      page: 1,
      limit: 100,
      show_participants: true,
      show_empty: false,
    };

    QISCUS.loadRooms(userParam)
      .then(async (resUser) => {
        setUserList([...resUser]);
        setLoadingUser({
          ...loadingUser,
          load: false,
        });
        const selectedUser = resUser.find((user) =>
          user.participants.some(
            (participant) =>
              participant.email?.toString() === qiscusId?.toString()
          )
        );

        if (selectedUser) {
          handleDetailUser(selectedUser, false);
          setAttachment((prev) => ({
            ...prev,
            qiscusId: selectedUser.id,
          }));
        } else {
          if (qiscusId) {
            const room = await QISCUS.createRoom(qiscusId);
            setAttachment((prev) => ({
              ...prev,
              qiscusId: room.id,
            }));

            handleDetailUser(room, false);
          }
        }
      })
      .catch(() => {
        setLoadingUser({
          ...loadingUser,
          load: false,
          isFirst: false,
          fail: true,
        });
      });
  };

  const handleDetailUser = (data, isStay) => {
    mutate({ room_id: data.id });

    setOnline({
      isOnline: false,
      lastOnline: "",
    });
    QISCUS.getRoomById(data.id)
      .then((rooms) => {
        getRoom();
        let tempMsgList = {};

        if (isStay) {
          let tempComment = messageList.normal.concat(rooms.comments);
          tempComment = tempComment.filter(
            (v, i, a) => a.findIndex((v2) => v2.id === v.id) === i
          );
          tempMsgList = {
            formated: [...msgFormat(tempComment)],
            normal: [...tempComment],
          };
        } else {
          // eslint-disable-next-line prefer-const
          let tempComment = rooms.comments;
          tempMsgList = {
            formated: [...msgFormat(tempComment)],
            normal: [...tempComment],
          };
        }
        setSelectedUser(data);
        setMessageList({ ...tempMsgList });

        setLoadingUser({
          ...loadingUser,
          load: false,
          isFirst: false,
          fail: false,
          page: 1,
        });
      })
      .catch(() => {
        setLoadingUser({
          ...loadingUser,
          load: false,
          isFirst: false,
          fail: true,
          page: 1,
        });
      });
  };

  const getDetail = async (data, isStay) => {
    try {
      setSelectedUser(data);
      setOnline({
        isOnline: false,
        lastOnline: "",
      });
      const room = await QISCUS.getRoomById(data.id);
      let tempMsgList = {};

      if (isStay) {
        let tempComment = messageList.normal?.concat(room.comments);
        tempComment = tempComment.filter(
          (currValue, index, items) =>
            items.findIndex((item) => item.id === currValue.id) === index
        );
        tempMsgList = {
          formated: [...msgFormat(tempComment)],
          normal: [...tempComment],
        };
      } else {
        // eslint-disable-next-line prefer-const
        let tempComment = room.comments;
        tempMsgList = {
          formated: [...msgFormat(tempComment)],
          normal: [...tempComment],
        };
      }

      setMessageList({ ...tempMsgList });
      setUserLoading((prev) => ({
        ...prev,
        load: false,
        isFirst: false,
        fail: false,
        page: 1,
      }));

      setTimeout(() => {
        scrollToBottom(tempMsgList.normal[tempMsgList.normal.length - 1]?.id);
      }, 1000);
    } catch (error) {
      toast(
        error?.message || "Gagal mendapatkan pesan, silakan coba lagi nanti."
      );
      setUserLoading({
        ...userLoading,
        load: false,
        isFirst: false,
        fail: true,
        page: 1,
      });
    }
  };

  const sendMessage = async (attachmentImage) => {
    try {
      if (
        isEmpty(textMessage) &&
        !attachmentImage &&
        attachment?.extras?.key !== "PRODUCTS" &&
        attachment?.extras?.key !== "ORDER"
      ) {
        console.log(
          "pesan dan attachment tidak terikim, mohon di isi terlebih dahulu"
        );

        return false;
      }
      let uniqueId = getTimestamp();

      if (isEmpty(attachment)) {
        uniqueId = null;
      }

      let tempAttachment = attachment;

      if (attachmentImage?.extras?.key === "IMAGE") {
        tempAttachment = attachmentImage;
      }

      const type = "custom";

      if (tempAttachment?.extras?.key === "PRODUCTS") {
        tempAttachment = {
          extras: attachment.extras,
          payload: {
            content: {
              origins: attachment?.payload?.content?.origins,
              selectedProducts:
                attachment?.payload?.content?.selectedProducts.map((row) => {
                  return {
                    discount: row?.product_skus[0]?.product_discount_percentage,
                    fullPrice: row?.product_skus[0]?.formatted_product_price,
                    origins: "PRODUCTS",
                    productImage: row?.product_skus[0]?.product_image_url,
                    productName: row?.product_skus[0]?.product_name,
                    productURL: `/p/${slug}/${row?.product_slug}`,
                    sellingPrice: row?.product_skus[0]?.formatted_selling_price,
                    skuId: row?.product_skus[0]?.product_sku_id,
                    slug: row?.product_skus[0]?.product_slug,
                  };
                }),
            },
            type: "custom-text",
          },
          qiscusId: attachment.qiscusId,
        };
      }

      await QISCUS.sendMessage(
        selectedUser.id,
        textMessage,
        uniqueId,
        type,
        tempAttachment?.payload,
        tempAttachment?.extras
      );

      setAttachment(null);
      setSelectedAttachProduct([]);
      setTextMessage("");
      getDetail(selectedUser);
      attachmentDisclosure.onClose();
    } catch (error) {
      console.error("error", error);
    }
  };

  // const sendMessage = async (attachmentImage) => {
  //   try {
  //     if (
  //       !textMessage &&
  //       !attachmentImage &&
  //       attachment?.extras?.key !== "PRODUCTS" &&
  //       attachment?.extras?.key !== "ORDER"
  //     )
  //       return;
  //     const type = "custom";
  //     let uniqueId = getTimestamp();

  //     if (
  //       attachment.qiscusId?.toString() !== selectedUser?.id?.toString() &&
  //       !attachmentImage
  //     ) {
  //       uniqueId = null;
  //     }
  //     let tempAttachment = attachment;

  //     if (attachmentImage) {
  //       tempAttachment = attachmentImage;
  //     }

  //     if (tempAttachment?.extras?.key === "PRODUCTS") {
  //       tempAttachment = {
  //         extras: attachment.extras,
  //         payload: {
  //           content: {
  //             origins: attachment.payload.content.origins,
  //             selectedProducts:
  //               attachment?.payload?.content?.selectedProducts.map((row) => {
  //                 return {
  //                   discount: row?.product_skus[0]?.product_discount_percentage,
  //                   fullPrice: row?.product_skus[0]?.formatted_product_price,
  //                   origins: "PRODUCTS",
  //                   productImage: row?.product_skus[0]?.product_image_url,
  //                   productName: row?.product_skus[0]?.product_name,
  //                   productURL: `/p/${slug}/${row?.product_slug}`,
  //                   sellingPrice: row?.product_skus[0]?.formatted_selling_price,
  //                   skuId: row?.product_skus[0]?.product_sku_id,
  //                   slug: row?.product_skus[0]?.product_slug,
  //                 };
  //               }),
  //           },
  //           type: "custom-text",
  //         },
  //         qiscusId: attachment.qiscusId,
  //       };
  //     }

  //     await QISCUS.sendMessage(
  //       selectedUser.id,
  //       textMessage,
  //       uniqueId,
  //       type,
  //       tempAttachment.payload,
  //       tempAttachment.extras
  //     );

  //     setAttachment({});
  //     setSelectedAttachProduct([]);
  //     setTextMessage("");
  //     getDetail(selectedUser);

  //     attachmentDisclosure.onClose();
  //   } catch (error) {
  //     toast(error?.message || "Gagal mengirim pesan, silakan coba lagi nanti.");
  //   }
  // };

  const { mutate: uploadImage } = useUploadChatImage({
    onSuccess: (data) => {
      const attachmentImage = {
        extras: { key: "IMAGE" },
        payload: {
          type: "custom-text",
          content: {
            origins: "IMAGE",
            productImage: data,
          },
        },
      };
      sendMessage(attachmentImage);
    },
    onError: (error) => {
      console.log("error upload image", error);
    },
  });

  const { register: registerUpload } = useUpload({
    fileType: "image",
    onRequest: async (file) => {
      const deepTypeChecking = await imageTypeChecking(file);

      if (deepTypeChecking) {
        const result = await blobToBase64(file);
        const { naturalHeight, naturalWidth } = await getImageMeta(result);

        if (naturalHeight < 300 || naturalWidth < 300) {
          toast({
            label: "Format gambar tidak sesuai",
            placement: "top",
          });

          attachProductDisclosure.onClose();
          attachmentDisclosure.onClose();

          return null;
        }

        const formData = new FormData();
        const compressed =
          file.size > 400000
            ? await imgCompressor(file, {
                maxSizeMB: 0.4,
                maxWidthOrHeight: 1920,
                useWebWorker: true,
              })
            : file;

        formData.append("file", compressed);
        uploadImage(formData);
      } else {
        toast({
          label: "Format gambar tidak sesuai",
          placement: "top",
        });
        attachProductDisclosure.onClose();
        attachmentDisclosure.onClose();
      }
    },
    validator: (file) => {
      if (!IMAGE_FILE_TYPE.includes(file.type)) {
        toast({
          label: "Format gambar tidak sesuai",
          placement: "bottom",
        });
        attachProductDisclosure.onClose();
        attachmentDisclosure.onClose();

        return {
          message: "Format gambar tidak sesuai",
        };
      }

      return null;
    },
  });

  const handleLoadMessage = () => {
    if (!isEmpty(selectedUser)) {
      const firstNormalMessage = messageList.normal[0];

      if (
        firstNormalMessage?.comment_before_id ||
        firstNormalMessage?.before_id
      ) {
        const options = {
          last_comment_id: firstNormalMessage.id,
          after: false,
          limit: 20,
        };
        setLoadBefore(true);
        QISCUS.loadComments(selectedUser.id, options)
          .then((comments) => {
            const tempMsg = [...comments, ...messageList.normal];
            const tempMsgFormatted = msgFormat(tempMsg);
            setMessageList({
              formated: tempMsgFormatted,
              normal: tempMsg,
            });
            scrollToBottom(tempMsg[1].id);
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => {
            setLoadBefore(false);
          });
      }
    }
  };

  const handleAttachProduct = () => {
    setAttachment({
      qiscusId: selectedUser?.id,
      extras: {
        key: "PRODUCTS",
        slug,
        storeId,
      },
      payload: {
        type: "custom-text",
        content: {
          origins: "PRODUCTS",
          selectedProducts: seletedAttachProduct,
        },
      },
    });
    attachProductDisclosure.onClose();
    attachmentDisclosure.onClose();
  };

  // ntar lanjut attach image masih error

  const handleAttachOrder = (value) => {
    const {
      buyer_qiscus_id,
      order_number,
      order_status_name,
      formatted_calculated_total_price,
      products,
    } = value;

    setAttachment({
      qiscusId: buyer_qiscus_id,
      extras: {
        key: "ORDER",
        slug,
        storeId,
      },
      payload: {
        type: "custom-text",
        content: {
          orderNumber: order_number,
          orderStatus: order_status_name,
          origins: "ORDER",
          productImage: products[0]?.product_image_url,
          productName: products[0].product_name,
          totalPrice: formatted_calculated_total_price,
        },
      },
    });
    attachOrderDisclosure.onClose();
    attachmentDisclosure.onClose();
  };

  const handleDeleteChat = () => {
    QISCUS.deleteChatRoom(selectedUser.unique_id)
      .then(() => {
        setSelectedUser(null);
        getRoom();
        toast({
          label: "Chat berhasil dihapus.",
          placement: "top",
        });
      })
      .catch((error) => {
        console.error("Error deleting chat room", error);
      });
  };

  const { mutate: getUserByQiscusId } = useGetUserByQiscusId({
    onSuccess: ({ data }) => {
      setBuyerInfo(data);
    },
  });

  useEffect(() => {
    // attachProductDisclosure.onClose();
    // attachmentDisclosure.onClose();

    const { kind, data } = emitterStatus;

    const handleNewMessage = () => {
      if (!isEmpty(selectedUser)) {
        const filteredParticipants = selectedUser.participants.filter(
          (p) => p.email !== qiscusInfo.userId.toString()
        );
        const filterId =
          filteredParticipants.length !== 0
            ? filteredParticipants[0].email.toString()
            : "";

        if (
          data[0].email === filterId ||
          data[0].email === qiscusInfo.userId.toString()
        ) {
          handleDetailUser(selectedUser, true);
        }
      }

      getRoom();
    };

    const handleTyping = () => {
      if (data.username !== qiscusInfo.userId.toString()) {
        const typing = data.message === "1";
        setTyping(typing);
      }
    };

    const handleOnlinePresence = () => {
      if (
        !isEmpty(selectedUser) &&
        data.idUser !== qiscusInfo.userId.toString()
      ) {
        const filteredParticipants = selectedUser.participants.filter(
          (p) => p.email === data.idUser
        );

        if (filteredParticipants.length !== 0) {
          setOnline({ ...data });
        }
      }
    };

    const handleCommentRead = () => {
      getRoom();
      const tempComment = [...messageList.normal]
        .reverse()
        .map((v, i) => {
          let statusCheck = data.comment.id === v.id ? "read" : v.status;

          if (i !== 0) {
            statusCheck =
              messageList.normal[i - 1].status === "read"
                ? "read"
                : statusCheck;
          }

          return { ...v, status: statusCheck };
        })
        .reverse();

      setMessageList({
        formated: [...msgFormat(tempComment)],
        normal: [...tempComment],
      });
    };

    const handleDeliver = () => {
      // getRoom();
      let tempComment = [];
      tempComment = messageList.normal.map((v) => ({
        ...v,
        status: emitterStatus.data.comment.id === v.id ? "delivered" : v.status,
      }));
      setMessageList({
        formated: [...msgFormat(tempComment)],
        normal: [...tempComment],
      });
    };

    const handleReconnecting = () => {
      QISCUS.login(qiscusInfo);
    };

    const handleLoginSuccess = () => {
      getRoom();
    };

    switch (kind) {
      case "new-message":
        handleNewMessage();
        break;
      case "typing":
        handleTyping();
        break;
      case "online-presence":
        handleOnlinePresence();
        break;
      case "comment-read":
        handleCommentRead();
        break;
      case "comment-delivered":
        handleDeliver();
        break;
      case "reconnecting":
        handleReconnecting();
        break;
      case "login-success":
        handleLoginSuccess();
        break;
      default:
        break;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emitterStatus]);

  useEffect(() => {
    event.on("event", function (data) {
      setEmitterStatus((emmit) => ({
        ...emmit,
        kind: data.kind,
        data: data.data,
      }));
    });

    if (firstOpen) {
      setEmitterStatus({
        kind: "reconnecting",
        data: null,
      });
      setFirstOpen(false);
    }

    const qiscusUser = selectedUser?.participants?.find(
      (user) => user.username === selectedUser?.name
    );

    getUserByQiscusId(qiscusUser?.email);
    attachmentDisclosure.onClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUser]);

  useEffect(() => {
    const { qiscusId, orderNumber, orderStatus, productImage } =
      location.state || {};

    if (qiscusId) {
      getRoom(qiscusId);
      setAttachment({
        qiscusId,
        extras: {
          key: "ORDER",
          slug,
          storeId,
        },
        payload: {
          type: "custom-text",
          content: {
            orderNumber,
            orderStatus,
            origins: "ORDER",
            productImage,
          },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClickUser = (data, isStay) => {
    if (data.id !== location?.state?.qiscusId) {
      setAttachment(null);
    }

    handleDetailUser(data, isStay);
  };

  return (
    <Flex height="100%" overflow="hidden">
      <Flex
        borderRadius="16px"
        boxShadow="0px 4px 4px rgba(20, 20, 32, 0.05)"
        backgroundColor="white"
        width="100%"
      >
        <Flex
          flexDirection="column"
          minWidth="326px"
          borderRight="1px solid"
          borderColor="black10"
        >
          <ChatSidebarHeader newChat={userList} />
          <Flex
            flexDirection="column"
            height="100%"
            padding="8px"
            overflowY="auto"
            gap="8px"
          >
            {loadingUser.load ? (
              range(8).map((_, i) => (
                <SkeletonSidebar key={`skeleton-sidebar-${i}`} />
              ))
            ) : userList.length > 0 ? (
              userList.map((row) => (
                <ChatSidebarItems
                  key={`user-${row.id}`}
                  user={row}
                  handleDetailUser={onClickUser}
                  selectedUser={selectedUser}
                />
              ))
            ) : (
              <ChatSidebarEmpty />
            )}
          </Flex>
        </Flex>
        <Flex flexDirection="column" flex={8}>
          <ChatHeaderRoom
            selectedUser={selectedUser !== null ? selectedUser : false}
            online={online}
            typing={typing}
            onlineStatus={onlineStatus}
            handleDeleteChat={handleDeleteChat}
          />
          {selectedUser !== null ? (
            <ChatRoomBody
              loadBefore={loadBefore}
              messageList={messageList}
              qiscusInfo={qiscusInfo}
              getTime={getTime}
              refChat={refChat}
              textMessage={textMessage}
              setTextMessage={setTextMessage}
              sendMessage={sendMessage}
              handleLoadMessage={handleLoadMessage}
              attachment={attachment}
              setAttachment={setAttachment}
              selectedUser={selectedUser}
              registerUpload={registerUpload}
              attachmentDisclosure={attachmentDisclosure}
              attachProductDisclosure={attachProductDisclosure}
              seletedAttachProduct={seletedAttachProduct}
              setSelectedAttachProduct={setSelectedAttachProduct}
              handleAttachProduct={handleAttachProduct}
              attachOrderDisclosure={attachOrderDisclosure}
              selectedAttachOrder={selectedAttachOrder}
              setSelectedAttachOrder={setSelectedAttachOrder}
              handleAttachOrder={(value) => handleAttachOrder(value)}
              firstOpen={firstOpen}
              setEmitterStatus={setEmitterStatus}
              setFirstOpen={setFirstOpen}
              buyerInfo={buyerInfo}
              setBuyerInfo={setBuyerInfo}
              slug={slug}
              storeId={storeId}
            />
          ) : (
            <ChatRoomEmpty user={userList.length} />
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default ChatRoom;
