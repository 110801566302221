import { Button, useDisclosure, useToast, Spinner, Icon } from "renos-ui";
import { useMemo } from "react";
import { useRequestPickupInstant } from "services/order-management";
import Popup3pl from "containers/mobile/order-management/list/component/Card/Footer/component/SendOrder/Popup3pl";
import PopupSellerFleet from "containers/mobile/order-management/list/component/Card/Footer/component/SendOrder/PopupSellerFleet";
import PopupMore from "containers/mobile/order-management/list/component/Card/Footer/component/SendOrder/PopupMore";
import { StyledButtonIcon } from "../../../styled";

const SendOrder = ({
  storeOrderShipment,
  shipping,
  orderId,
  refetch,
  data,
  onClickChat,
}) => {
  const toast = useToast();
  const disclosure3pl = useDisclosure({ isOpen: false });
  const disclosureSellerFleet = useDisclosure({ isOpen: false });
  const disclosureMore = useDisclosure({ isOpen: false });

  const { mutate: requestPickupInstant, isLoading: isLoadingRequestPickup } =
    useRequestPickupInstant({
      onSuccess: (data) => {
        const { message, frontend_message } = data;
        refetch();
        toast({
          label: frontend_message || message || "Request pickup berhasil",
          placement: "bottom",
        });
      },
      onError: (data) => {
        const {
          response: {
            data: { frontend_message, message },
          },
        } = data;
        toast({
          label: frontend_message || message || "Request pickup gagal",
          placement: "bottom",
          backgroundColor: "red50",
        });
      },
    });

  const handleRequestPickupInstant = () => {
    const params = {
      order_id: orderId,
      pickup: true,
      awb: "",
    };
    requestPickupInstant(params);
  };

  const onclickButton = () => {
    if (shipping?.is_seller_fleet) {
      disclosureSellerFleet.onOpen();
    } else {
      if (storeOrderShipment.request_pickup_datetime_possible) {
        disclosure3pl.onOpen();
      } else {
        handleRequestPickupInstant();
      }
    }
  };

  const labelButton = useMemo(
    () => (shipping?.is_seller_fleet ? "Kirim Pesanan" : "Request Pick Up"),
    [shipping]
  );

  return (
    <>
      <StyledButtonIcon
        variant="tertiary"
        size="small"
        onClick={disclosureMore.onOpen}
        icon={<Icon size={24} name="Vertical-outline" />}
      />
      <StyledButtonIcon
        variant="tertiary"
        size="small"
        onClick={onClickChat}
        icon={<Icon size={24} name="Chat-outline" />}
      />
      <Button
        size="medium"
        isBlock
        isLoading={isLoadingRequestPickup}
        preffix={isLoadingRequestPickup && <Spinner color="white" size="8px" />}
        onClick={onclickButton}
      >
        {labelButton}
      </Button>

      <Popup3pl
        disclosure3pl={disclosure3pl}
        toFirstPage={refetch}
        orderId={orderId}
      />
      <PopupSellerFleet
        disclosure={disclosureSellerFleet}
        toFirstPage={refetch}
        orderId={orderId}
      />
      <PopupMore disclosure={disclosureMore} data={data} />
    </>
  );
};

export default SendOrder;
