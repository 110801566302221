import { Button, Drawer, Flex, Spinner, Text, useToast } from "renos-ui";
import { useDeactivateProduct } from "services/product";

const Activate = ({
  isOpenActivate,
  onCloseActivate,
  data,
  handleCloseAll,
}) => {
  const toast = useToast();

  const { mutate: activateProducts, isLoading: isActivateLoading } =
    useDeactivateProduct({
      onSuccess: () => {
        handleCloseAll();
        toast({
          label: "Produk berhasil diaktifkan",
          placement: "bottom",
        });
      },
      onError: () => {
        toast({
          label: "Produk gagal diaktifkan",
          placement: "bottom",
          backgroundColor: "red50",
        });
      },
    });

  const handleActivate = () => {
    const payload = [];
    //eslint-disable-next-line
    const findPayload = data.map((product) => {
      if (product.product_skus.length > 0) {
        //eslint-disable-next-line
        const findPayloadVariant = product.product_skus.map((skus) => {
          payload.push({
            product_id: skus.product_id,
            product_status_id: 1,
            product_sku_id: skus.product_sku_id,
            product_sku_number: skus.product_sku_number,
          });
        });
      } else {
        payload.push({
          product_id: product.product_id,
          product_status_id: 1,
          product_sku_id: product.product_sku_id,
          product_sku_number: product.product_sku_number,
        });
      }
    });
    activateProducts({ data: payload });
  };

  return (
    <Drawer
      isVisible={isOpenActivate}
      onClose={onCloseActivate}
      closable
      placement="bottom"
    >
      <Flex flexDirection="column" width="100%" gap="24px" height="100%">
        <Flex gap="4px" flexDirection="column">
          <Text variant="subtitle" weight="bold">
            Aktifkan {data.length} produk?
          </Text>
          <Text color="black75">Produkmu dapat dilihat oleh calon pembeli</Text>
        </Flex>

        <Flex gap="8px">
          <Button
            variant="tertiary"
            size="medium"
            isBlock
            onClick={onCloseActivate}
          >
            Batalkan
          </Button>
          <Button
            size="medium"
            isBlock
            onClick={handleActivate}
            disabled={isActivateLoading}
            preffix={isActivateLoading && <Spinner color="white" size="8px" />}
          >
            Ya, Aktifkan
          </Button>
        </Flex>
      </Flex>
    </Drawer>
  );
};

export default Activate;
