import React from "react";
import { Box, Flex, Icon, Text, useDisclosure } from "renos-ui";
import PropTypes from "prop-types";
import { StyledTable } from "./styled";

const TableCascader = ({ productSkus, rowIndex, columns }) => {
  const { isOpen, onToggle } = useDisclosure({ open: false });

  return (
    <>
      {isOpen &&
        productSkus?.map((data, rowIndex) => (
          <tr key={`table-row-${rowIndex}`}>
            {columns.map((column, dataIndex) => {
              const omittedColumn = ["checkBox", "cta"];

              // if (column.key === "cta") {
              //   return (
              //     <Box
              //       as="td"
              //       key={`table-data-${rowIndex}-${dataIndex}`}
              //       width={column.width || "auto"}
              //       py="20px"
              //       border="0"
              //       borderBottom="1px"
              //       borderStyle="solid"
              //       borderColor="black10"
              //       textAlign="left"
              //     >
              //       <Dropdown>
              //         <Dropdown.Toggle>
              //           <Flex
              //             width="92px"
              //             height="40px"
              //             alignItems="center"
              //             justifyContent="space-between"
              //             border="1px"
              //             borderColor="black10"
              //             borderRadius={8}
              //             borderStyle="solid"
              //             px="12px"
              //             marginRight="4px"
              //             cursor="pointer"
              //           >
              //             <Text color="black75">Atur</Text>
              //             <Icon name="Down-outline" color="black50" size={20} />
              //           </Flex>
              //         </Dropdown.Toggle>
              //         <Dropdown.Content>
              //           <Dropdown.Item
              //             onClick={() =>
              //               (location.href = `/products/add?id=${data.product_id}`)
              //             }
              //           >
              //             <Flex px="16" py="6px">
              //               Jadikan Utama
              //             </Flex>
              //           </Dropdown.Item>
              //         </Dropdown.Content>
              //       </Dropdown>
              //     </Box>
              //   );
              // }

              return omittedColumn.includes(column.key) ? (
                <Box as="td" />
              ) : (
                <Box
                  as="td"
                  key={`table-data-${rowIndex}-${dataIndex}`}
                  width={column.width || "auto"}
                  py="20px"
                  border="0"
                  borderBottom="1px"
                  borderStyle="solid"
                  borderColor="black10"
                  textAlign="left"
                >
                  {column.render?.(dataIndex, data, true) ||
                    data[column.dataIndex]}
                </Box>
              );
            })}
          </tr>
        ))}
      <tr key={`is-has-more-${rowIndex}`}>
        <Box
          as="td"
          key={`table-data-${rowIndex}`}
          py="20px"
          border="0"
          borderBottom="1px"
          borderStyle="solid"
          borderColor="black10"
          textAlign="left"
          colSpan={columns.length}
        >
          <Flex
            alignItems="center"
            columnGap="4px"
            paddingLeft="40px"
            cursor="pointer"
            onClick={onToggle}
          >
            <Text variant="caption" color="blue50" weight="bold">
              {isOpen ? "Tutup" : "Lihat Varian Produk"}
            </Text>
            <Icon
              name={isOpen ? "Up-outline" : "Down-outline"}
              size={16}
              color="blue50"
            />
          </Flex>
        </Box>
      </tr>
    </>
  );
};

const Table = ({ customHeader, columns, dataSource, ...props }) => {
  const renderTableHeader = () => {
    if (customHeader) {
      return (
        <Box
          as="th"
          border="0"
          borderBottom="1px"
          borderStyle="solid"
          borderColor="black10"
          colSpan={columns.length}
        >
          {customHeader}
        </Box>
      );
    }

    return columns.map((column, i) => (
      <Box
        as="th"
        pt="4px"
        pb="20px"
        border="0"
        borderBottom="1px"
        borderStyle="solid"
        borderColor="black10"
        textAlign={column.align || "left"}
        key={`table-header-${i}`}
        width={column.width || "auto"}
      >
        {typeof column.title === "string" ? (
          <Text color="black50" weight="bold">
            {column.title}
          </Text>
        ) : (
          column.title
        )}
      </Box>
    ));
  };

  return (
    <Box {...props} zIndex={0}>
      <StyledTable>
        <Box as="thead">
          <Box as="tr">{renderTableHeader()}</Box>
        </Box>
        <Box as="tbody">
          {dataSource.map((data, rowIndex) => {
            const { isHasMore, product_skus } = data;

            return isHasMore ? (
              [
                <tr key={`table-row-${rowIndex}`}>
                  {columns.map((column, dataIndex) => {
                    return (
                      <Box
                        as="td"
                        key={`table-data-${rowIndex}-${dataIndex}`}
                        width={column.width || "auto"}
                        py="20px"
                        textAlign="left"
                      >
                        {column.render?.(dataIndex, data) ||
                          data[column.dataIndex]}
                      </Box>
                    );
                  })}
                </tr>,
                <TableCascader
                  productSkus={product_skus}
                  columns={columns}
                  rowIndex={rowIndex}
                />,
              ]
            ) : (
              <tr key={`table-row-${rowIndex}`}>
                {columns.map((column, dataIndex) => {
                  return (
                    <Box
                      as="td"
                      key={`table-data-${rowIndex}-${dataIndex}`}
                      width={column.width || "auto"}
                      py="20px"
                      border="0"
                      borderBottom="1px"
                      borderStyle="solid"
                      borderColor="black10"
                      textAlign="left"
                    >
                      {column.render?.(dataIndex, data) ||
                        data[column.dataIndex]}
                    </Box>
                  );
                })}
              </tr>
            );
          })}
        </Box>
      </StyledTable>
    </Box>
  );
};

Table.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
      dataIndex: PropTypes.string,
      key: PropTypes.string,
      render: PropTypes.func,
      align: PropTypes.string,
      width: PropTypes.string,
    })
  ),
  dataSource: PropTypes.arrayOf(PropTypes.object),
  customHeader: PropTypes.node,
};

Table.defaultProps = {
  columns: [],
  dataSource: [],
};

export default Table;
