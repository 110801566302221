import { useCallback, useEffect, useRef } from "react";
import { Flex, Text } from "renos-ui";

const InfiniteScroll = ({
  hasNextPage,
  children,
  onChange,
  isMobile,
  isLoading,
  page,
  loader,
}) => {
  const observerElem = useRef(null);
  const handleObserver = useCallback(
    (entries) => {
      const [target] = entries;

      if (target.isIntersecting) {
        onChange();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hasNextPage]
  );

  useEffect(() => {
    const element = observerElem.current;

    if (element) {
      const option = { threshold: 0 };

      const observer = new IntersectionObserver(handleObserver, option);
      observer.observe(element);

      return () => observer.unobserve(element);
    }
  }, [page, hasNextPage, handleObserver, observerElem, children, isLoading]);

  return (
    <>
      {children}

      {hasNextPage && !isLoading && <Flex ref={observerElem} p="16px" />}

      {isLoading &&
        page !== 1 &&
        (loader || (
          <Flex
            width="100%"
            justifyContent="center"
            backgroundColor="white"
            py="16px"
          >
            {loader || (
              <Text variant={isMobile && "caption"} color="black75">
                Loading...
              </Text>
            )}
          </Flex>
        ))}
    </>
  );
};

InfiniteScroll.defaultProps = {
  onChange: () => {},
  setIsloading: () => {},
  children: <></>,
  isLoading: false,
  isMobile: false,
  page: 1,
};

export default InfiniteScroll;
