import { Button, Dialog, Flex, Text } from "renos-ui";

const ModalSuccess = ({ isOpen, onClose }) => {
  return (
    <Dialog verticalCentered isVisible={isOpen}>
      <Flex width="320px" flexDirection="column" gap="24px">
        <Flex flexDirection="column" gap="8px" alignItems="center">
          <Text variant="heading3" weight="bold">
            Perubahan berhasil dilakukan
          </Text>
          <Flex
            as="img"
            width="156px"
            height="156px"
            src="/assets/images/store-layout/oke.svg"
            alt="oke"
          />
          <Text>
            Harap tunggu sebentar, yaa. Tampilan tokomu akan berubah beberapa
            saat lagi.
          </Text>
        </Flex>
        <Button onClick={onClose} isBlock>
          Oke
        </Button>
      </Flex>
    </Dialog>
  );
};

export default ModalSuccess;
