import React from "react";
import { Flex } from "renos-ui";

const FixedBottomContainer = ({ children }) => {
  return (
    <Flex
      bottom="0"
      width="100%"
      position="fixed"
      justifyContent="center"
      alignItems="center"
      backgroundColor="white"
      boxShadow="0px 0px 8px 0px rgba(20, 20, 32, 0.05);"
    >
      {children}
    </Flex>
  );
};

export default FixedBottomContainer;
