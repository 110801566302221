import { Text } from "renos-ui";
import styled from "styled-components";
import { Swiper } from "swiper/react";

export const StyledSwiper = styled(Swiper)`
  .swiper-wrapper {
    position: relative;
    div {
      box-sizing: border-box;
    }
    padding-bottom: 1px;

    .swiper-slide {
      width: 156px !important;
    }
  }
`;

export const ExtraSmallText = styled(Text)`
  font-size: 8px !important;
`;
