import { Flex, Label, Text } from "renos-ui";
import { Wrapper } from "../styled";

const CardTemplate = ({
  index,
  list,
  handleEdit,
  handleSelect,
  handleDelete,
  templateList,
}) => {
  return (
    <Flex key={index} justifyContent="space-between">
      <Flex flexDirection="column" width="75%" gap="8px">
        <Flex
          alignItems="center"
          borderRadius="8px"
          gap="16px"
          alignContent="center"
        >
          <Text color="black75" weight="bold">
            {list?.name}
          </Text>
          {list?.default === true ? (
            <Label variant="success">Balasan Otomatis</Label>
          ) : (
            <></>
          )}
        </Flex>
        <Wrapper color="black75">{list.message}</Wrapper>
      </Flex>

      {list?.default === true && templateList?.length === 1 ? (
        <Flex
          alignItems="center"
          gap="4px"
          cursor="pointer"
          onClick={() => handleEdit(index)}
        >
          <Text color="black50">Ubah</Text>
        </Flex>
      ) : list?.default === false && templateList?.length === 1 ? (
        <Flex gap="24px">
          <Flex
            alignItems="center"
            gap="4px"
            cursor="pointer"
            onClick={() => handleSelect(index)}
          >
            <Text color="black50">Pilih Template</Text>
          </Flex>

          <Flex
            alignItems="center"
            gap="4px"
            cursor="pointer"
            onClick={() => handleEdit(index)}
          >
            <Text color="black50">Ubah</Text>
          </Flex>

          <Flex
            alignItems="center"
            gap="4px"
            cursor="pointer"
            onClick={() => handleDelete(list)}
          >
            <Text color="black50">Hapus</Text>
          </Flex>
        </Flex>
      ) : (
        <Flex gap="24px">
          {!list?.default && templateList.length !== 1 && (
            <Flex
              alignItems="center"
              gap="4px"
              cursor="pointer"
              onClick={() => handleSelect(index)}
            >
              <Text color="black50">Pilih Template</Text>
            </Flex>
          )}

          <Flex
            alignItems="center"
            gap="4px"
            cursor="pointer"
            onClick={() => handleEdit(index)}
          >
            <Text color="black50">Ubah</Text>
          </Flex>
          {templateList.length !== 1 && (
            <Flex
              alignItems="center"
              gap="4px"
              cursor="pointer"
              onClick={() => handleDelete(list)}
            >
              <Text color="black50">Hapus</Text>
            </Flex>
          )}
        </Flex>
      )}
    </Flex>
  );
};

export default CardTemplate;
