import { Button, Flex, Spinner, useDisclosure, useToast } from "renos-ui";
import { useRequestPickupInstant } from "services/order-management";
import ModalRequestPickup from "./ModalRequestPickup";
import ModalSendOrder from "./ModalSendOrder";

const SendOrder = ({ data, refetchStatus, refetchList }) => {
  const toast = useToast();

  const disclosureSendOrder = useDisclosure({ isOpen: false });
  const disclosureRequestPicup = useDisclosure({ isOpen: false });

  const { mutate: requestPickupInstant, isLoading } = useRequestPickupInstant({
    onSuccess: (data) => {
      const { message, frontend_message } = data;

      refetchStatus();
      refetchList();

      toast({
        label: frontend_message || message || "Request pickup berhasil",
        placement: "top",
      });
    },
    onError: (data) => {
      const {
        response: {
          data: { frontend_message, message },
        },
      } = data;
      toast({
        label: frontend_message || message || "Request pickup gagal",
        placement: "top",
        backgroundColor: "red50",
      });
    },
  });

  const handleRequestPickupInstant = () => {
    const params = {
      order_id: data.order_id,
      pickup: true,
      awb: "",
    };
    requestPickupInstant(params);
  };

  const sendOrderCheck = () => {
    if (data.store_order_shipment.request_pickup_datetime_possible) {
      disclosureRequestPicup.onToggle();
    } else {
      handleRequestPickupInstant();
    }
  };

  return (
    <Flex gap="16px">
      {/* <Dropdown>
        <Dropdown.Toggle>
          <StyledButtonIcon
            variant="tertiary"
            size="medium"
            icon={<Icon name="Horizontal-outline" size={24} color="black50" />}
          />
        </Dropdown.Toggle>
        <Dropdown.Content minWidth="127px !important" positionDropdown="right">
          <Dropdown.Item>
            <Text color="black75">Batalkan Pesanan</Text>
          </Dropdown.Item>
        </Dropdown.Content>
      </Dropdown> */}

      {data?.shipping?.is_seller_fleet ? (
        <Button size="medium" onClick={disclosureSendOrder.onToggle}>
          Kirim Pesanan
        </Button>
      ) : (
        <Button
          size="medium"
          onClick={sendOrderCheck}
          disabled={isLoading}
          preffix={isLoading && <Spinner color="white" size="8px" />}
        >
          Request Pickup
        </Button>
      )}

      <ModalRequestPickup
        refetchStatus={refetchStatus}
        refetchList={refetchList}
        isOpen={disclosureRequestPicup.isOpen}
        onClose={disclosureRequestPicup.onClose}
        orderId={data.order_id}
      />
      <ModalSendOrder
        refetchStatus={refetchStatus}
        refetchList={refetchList}
        isOpen={disclosureSendOrder.isOpen}
        onClose={disclosureSendOrder.onClose}
        orderId={data.order_id}
      />
    </Flex>
  );
};

export default SendOrder;
