import React from "react";
import { Flex } from "renos-ui";
import LandingFooter from "widgets/desktop/LandingFooter";
import LandingHeader from "widgets/desktop/LandingTutorialHeader";

const TutorialLayout = ({ children }) => {
  return (
    <Flex width="100%" alignItems="center" flexDirection="column">
      <LandingHeader />
      {children}
      <LandingFooter />
    </Flex>
  );
};

export default TutorialLayout;
