import dayjs from "dayjs";
import { isObjectEqual } from "helpers";
import { useEffect, useState } from "react";
import { Flex, Icon, Text, useDebounce } from "renos-ui";
import InputCourier from "./InputCourier";
import InputDatepicker from "./InputDatepicker";
import { StyledInput, StyledSelectInput } from "./styled";
import useQueryParams from "hooks/useQueryParams";

const Filter = ({ data, setData, defaultData }) => {
  const query = useQueryParams();

  const listSort = [
    { value: "desc", label: "Paling Baru" },
    { value: "asc", label: "Paling Lama" },
  ];
  const defaultToday = {
    startDate: new Date(Date.now() - 29 * 24 * 60 * 60 * 1000),
    endDate: new Date(),
  };

  const [searchvalue, setSearchValue] = useState("");
  const [selectedDate, setSelectedDate] = useState({
    startDate: "",
    endDate: "",
  });
  const [selectedSort, setSelectedSort] = useState(listSort[0]);
  const [selectedCourier, setSelectedCourier] = useState([]);
  const [changed, setChanged] = useState(false);

  const handleChangeCourier = (dataCourier) => {
    setSelectedCourier([...dataCourier]);
    setChanged(true);
  };

  const handleReset = () => {
    setSearchValue("");
    setSelectedSort(listSort[0]);
    setSelectedCourier([]);
    setData({ ...defaultData });
    setSelectedDate({
      startDate: "",
      endDate: "",
    });
    setChanged(false);
  };

  useDebounce(
    () => {
      const tempData = data;

      tempData.query = searchvalue;
      tempData.sort_type = selectedSort.value;

      if (selectedDate.startDate) {
        tempData.date_start = dayjs(selectedDate.startDate).format(
          "YYYY-MM-DD"
        );
      } else {
        delete tempData.date_start;
      }

      if (selectedDate.endDate) {
        tempData.date_end = dayjs(selectedDate.endDate).format("YYYY-MM-DD");
      } else {
        delete tempData.date_end;
      }

      if (!tempData.query) delete tempData.query;

      if (!tempData.sort_type) delete tempData.sort_type;

      if (selectedCourier.length === 0) {
        delete tempData.courier_ids;
      } else {
        tempData.courier_ids = selectedCourier.join(",");
      }
      if (isObjectEqual(tempData, defaultData)) setChanged(false);

      setData({ ...tempData });
    },
    [selectedSort, selectedCourier, searchvalue, selectedDate],
    1000
  );

  useEffect(() => {
    const searchparam = query.get("query");

    if (searchparam) setSearchValue(searchparam);
    // eslint-disable-next-line
  }, []);

  return (
    <Flex gap="24px" alignItems="center" justifyContent="space-between">
      <Flex gap="16px">
        <Flex width="328px">
          <StyledInput
            onChange={(event) => {
              setSearchValue(event.target.value);
              setChanged(true);
            }}
            prefix={<Icon name="Search-outline" size={20} color="black50" />}
            placeholder="Cari nama pembeli, produk, nomor transaksi"
            size="small"
            value={searchvalue}
          />
        </Flex>
        <InputDatepicker
          value={selectedDate.startDate && selectedDate}
          setValue={setSelectedDate}
          defaultToday={defaultToday}
          setChanged={setChanged}
        />
        <InputCourier
          setSelected={(event) => handleChangeCourier(event)}
          selected={selectedCourier}
        />
        <StyledSelectInput
          options={listSort}
          placeholder="Urutkan"
          size="small"
          onChange={(event) => {
            setSelectedSort(event);
            setChanged(true);
          }}
          defaultValue={listSort[0]}
          hideSelectedOptions={false}
        />
      </Flex>
      <Flex cursor={changed ? "pointer" : "not-allowed"} onClick={handleReset}>
        <Text weight="bold" color={changed ? "blue50" : "black50"}>
          Reset Filter
        </Text>
      </Flex>
    </Flex>
  );
};

export default Filter;
