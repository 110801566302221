import React from "react";
import { Flex, Text, Box } from "renos-ui";

const ChatRoomEmpty = ({ user }) => {
  return (
    <Flex
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="100%"
      textAlign="center"
    >
      <Box as="img" src="/assets/images/empty-chat.png" alt="empty-chat" />
      <Flex flexDirection="column" textAlign="center" gap="8px">
        <Text weight="bold">
          {user > 0 ? "Tidak ada percakapan yang ditampilkan" : "Chat Kosong"}
        </Text>
        {user > 0 ? (
          <Flex width="236px" marginX="auto">
            <Text variant="caption" color="black50">
              Ayo pastikan semua chat dari pembelimu sudah kamu balas ya!
            </Text>
          </Flex>
        ) : (
          <Text variant="caption" color="black50">
            Belum ada pembeli yang menghubungi
          </Text>
        )}
      </Flex>
    </Flex>
  );
};

export default ChatRoomEmpty;