import StoreLayoutContext from "providers/StoreLayoutProvider";
import { useContext, useEffect, useState } from "react";
import { Flex, Text, range } from "renos-ui";
import EmptyCard from "../EmptyCard";
import CardProduct from "../CardProduct";
import SwiperContext, { SwiperProvider } from "providers/SwiperProvider";
import { StyledSwiper } from "./styled";
import { SwiperSlide, useSwiper } from "swiper/react";
import { useGetProductsV3 } from "services/product";
import dayjs from "dayjs";

const NewProduct = ({ isPreviewMode }) => {
  const { dataHomePage, newProductRef } = useContext(StoreLayoutContext);
  const [dataNewProduct, setDataNewProduct] = useState({});
  const [swiperInstance, setSwiperInstance] = useState();

  const dayEnd = new Date();
  const dayStart = new Date(new Date().setDate(dayEnd.getDate() - 30));

  const { data: dataProduct, isLoading } = useGetProductsV3({
    order_by: "upload_date",
    page: 1,
    product_status_ids: 1,
    upload_start_date: dayjs(dayStart).format("YYYY-MM-DD"),
    upload_end_date: dayjs(dayEnd).format("YYYY-MM-DD"),
    limit: 12,
  });

  useEffect(() => {
    const selectedNewPrduct = dataHomePage.filter(
      (homepage) => homepage.id === 4,
    )[0];
    setDataNewProduct(selectedNewPrduct);
  }, [dataHomePage]);

  if (isPreviewMode && !dataNewProduct?.isActive) return <></>;

  return (
    <Flex gap="16px" flexDirection="column" ref={newProductRef}>
      <Flex justifyContent="space-between" alignItems="center">
        <Text weight="bold">Produk Terbaru</Text>
        {dataNewProduct?.isActive && (
          <Text variant="caption" weight="bold" color="blue50">
            Lihat Semua
          </Text>
        )}
      </Flex>
      {dataNewProduct?.isActive &&
      !isLoading &&
      dataNewProduct?.data?.length !== 0 ? (
        dataNewProduct?.data?.length > 2 ? (
          <SwiperProvider value={{ setSwiperInstance, swiperInstance }}>
            <Flex position="relative">
              <StyledSwiper slidesPerView={2} spaceBetween={8}>
                <Flex>
                  {dataProduct?.data.map((product, indexProduct) => {
                    return (
                      <SwiperSlide key={`swiper-${indexProduct}`}>
                        <CardProduct
                          data={product}
                          key={`product-card${indexProduct}`}
                        />
                      </SwiperSlide>
                    );
                  })}
                </Flex>
                <SwiperInstance />
              </StyledSwiper>
            </Flex>
          </SwiperProvider>
        ) : (
          <Flex gap="8px">
            {dataProduct?.data.map((product, indexProduct) => {
              return (
                <CardProduct
                  data={product}
                  key={`product-card${indexProduct}`}
                />
              );
            })}
          </Flex>
        )
      ) : (
        <Flex gap="8px">
          {range(2).map(() => (
            <EmptyCard />
          ))}
        </Flex>
      )}
    </Flex>
  );
};

const SwiperInstance = () => {
  const { setSwiperInstance } = useContext(SwiperContext);
  const swiper = useSwiper();

  useEffect(() => {
    if (swiper) return setSwiperInstance(swiper);
  }, [swiper, setSwiperInstance]);
};

export default NewProduct;
