import { Flex, Text } from "renos-ui";

const Card = ({ isActive, name, onClick }) => {
  return (
    <Flex
      padding="8px 12px"
      cursor="pointer"
      alignItems="center"
      borderColor={isActive ? "blue50" : "black10"}
      backgroundColor={isActive ? "blue5" : "white"}
      borderWidth="1px"
      borderStyle="solid"
      borderRadius="12px"
      style={{ whiteSpace: "nowrap" }}
      onClick={onClick}
    >
      <Text color={isActive ? "blue50" : "black75"}>{name}</Text>
    </Flex>
  );
};

export default Card;
