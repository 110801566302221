import { useState } from "react";
import { Flex, Icon, Input, useDebounce } from "renos-ui";

const Filter = ({ setSearchParam }) => {
  const [tempParams, setTempParams] = useState("");

  useDebounce(() => setSearchParam(tempParams), [tempParams], 500);

  return (
    <Flex gap="16px">
      <Input
        prefix={<Icon name="Search-outline" size={20} />}
        size="small"
        placeholder="Cari produk"
        value={tempParams}
        onChange={(event) => setTempParams(event.target.value)}
      />
      {/* backend not ready for filter by etalase */}
    </Flex>
  );
};

export default Filter;
