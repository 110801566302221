import { setGTM } from "helpers";
import StoreContext from "providers/StoreProvider";
import { useContext } from "react";
import { Flex, Text, colors, Label } from "renos-ui";

const PickupLocation = ({ handleChangeTabActive }) => {
  const {
    storeData: {
      data: { locations },
    },
  } = useContext(StoreContext);

  const ADDRESS = locations.filter((location) => location.is_primary)[0];

  const changePage = () => {
    setGTM("seller_center_section", {
      event_category: "store_settings | delivery",
      event_action: "click",
      event_label: "pickup_location | change_address",
    });

    handleChangeTabActive("location");
  };

  return (
    <Flex flexDirection="column" gap="16px">
      <Flex flexDirection="column">
        <Text variant="subtitle" weight="bold" color="black100">
          Lokasi Pickup
        </Text>
        <Text color="black75">
          Jika ingin menggunakan layanan pick up, pastikan kembali alamat tokomu
          sesuai dengan titik penjemputan kurir.
        </Text>
      </Flex>
      <Flex
        padding="24px"
        border={`1px dashed ${colors.blue10}`}
        borderRadius="8px"
        width="512px"
        gap="16px"
        flexDirection="column"
      >
        <Flex flexDirection="column" gap="8px">
          <Flex gap="12px" alignItems="center">
            <Text color="black75" weight="bold">
              {ADDRESS.name}
            </Text>
            <Label variant="success">Alamat Utama</Label>
          </Flex>
          <Text color="black75">{ADDRESS.contact_number}</Text>
          <Text color="black75">{ADDRESS.address}</Text>
          <Text
            variant="body"
            color="black75"
          >{`${ADDRESS.lower_district_name}, ${ADDRESS.district_name}, ${ADDRESS.city_name}, ${ADDRESS.province_name} - ${ADDRESS.postal_code}`}</Text>
        </Flex>
        <Flex cursor="pointer" onClick={changePage}>
          <Text color="blue50" weight="bold">
            Ubah Alamat Utama
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default PickupLocation;
