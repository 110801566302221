import { goToUrl } from "helpers";
import StoreContext from "providers/StoreProvider";
import { useContext } from "react";
import { useEffect, useMemo } from "react";
import { Box, Button, Flex, Icon, Text } from "renos-ui";
import { useTrackOrder } from "services/order-management";
import { StyledDialog } from "../styled";
import Loading from "./Loading";
import FailLoadData from "widgets/global/FailLoadData";

const ModalTrack = ({ isOpen, onClose, orderId }) => {
  const {
    storeData: {
      data: { name: storeName },
    },
  } = useContext(StoreContext);

  const {
    data: dataTrack,
    isLoading,
    isFetching,
    refetch,
    isError,
  } = useTrackOrder({ order_id: orderId });

  const isAllLoading = useMemo(
    () => isLoading || isFetching,
    [isLoading, isFetching]
  );

  const dataTracker = useMemo(
    () =>
      dataTrack?.data?.tracking?.events
        ? dataTrack?.data?.tracking?.events
        : [],
    [dataTrack]
  );

  useEffect(() => {
    if (isOpen) {
      refetch();
    }
    // eslint-disable-next-line
  }, [isOpen]);

  return (
    <StyledDialog isVisible={isOpen} onClose={onClose} verticalCentered>
      <Flex width="672px" flexDirection="column" gap="24px">
        <Flex justifyContent="space-between" alignItems="center">
          <Text variant="heading3" weight="bold">
            Lacak Pesanan
          </Text>
          <Icon
            name="Close-outline"
            cursor="pointer"
            size={24}
            color="black60"
            onClick={onClose}
          />
        </Flex>
        {isAllLoading ? (
          <Loading />
        ) : isError ? (
          <FailLoadData
            title="Data tracking belum tersedia"
            description="Mohon coba muat ulang secara berkala atau hubungi renos"
            onClick={refetch}
            isLoading={isLoading || isFetching}
          />
        ) : (
          <Flex gap="24px" height="381px">
            <Flex
              width="304px"
              flexDirection="column"
              justifyContent="space-between"
            >
              <Flex flexDirection="column" gap="24px">
                <Flex flexDirection="column" gap="8px">
                  <Flex>
                    <Flex width="96px">
                      <Text color="black50">No Resi</Text>
                    </Flex>
                    <Flex width="calc(100% - 96px)">
                      <Text color="black75">
                        {dataTrack?.data.tracking_number}
                      </Text>
                    </Flex>
                  </Flex>
                  <Flex>
                    <Flex width="96px">
                      <Text color="black50">Kurir</Text>
                    </Flex>
                    <Flex width="calc(100% - 96px)">
                      <Text color="black75">
                        {dataTrack?.data.courier_name}
                      </Text>
                    </Flex>
                  </Flex>
                  <Flex>
                    <Flex width="96px">
                      <Text color="black50">Layanan</Text>
                    </Flex>
                    <Flex width="calc(100% - 96px)">
                      <Text color="black75">
                        {dataTrack?.data.courier_service_name}
                      </Text>
                    </Flex>
                  </Flex>
                  <Flex>
                    <Flex width="96px">
                      <Text color="black50">Estimasi Tiba</Text>
                    </Flex>
                    <Flex width="calc(100% - 96px)">
                      <Text color="black75">{dataTrack?.data.eta}</Text>
                    </Flex>
                  </Flex>
                </Flex>
                <Flex flexDirection="column" gap="8px">
                  <Flex>
                    <Flex width="96px">
                      <Text color="black50">Penjual </Text>
                    </Flex>
                    <Flex width="calc(100% - 96px)">
                      <Text color="black75">{storeName}</Text>
                    </Flex>
                  </Flex>
                  <Flex>
                    <Flex width="96px">
                      <Text color="black50">Penerima </Text>
                    </Flex>
                    <Flex
                      width="calc(100% - 96px)"
                      flexDirection="column"
                      maxHeight="151px"
                      overflow="auto"
                    >
                      <Text color="black75">
                        {dataTrack?.data.destination.pic_name}
                      </Text>
                      <Text color="black50">
                        {`${dataTrack?.data.destination?.address}, ${dataTrack?.data.destination?.lower_district_name}, ${dataTrack?.data.destination?.district_name}, ${dataTrack?.data.destination?.city_name}, ${dataTrack?.data.destination?.province_name}, ${dataTrack?.data.destination?.postal_code}`}
                      </Text>
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>
              {dataTrack?.data.tracking.tracking_url && (
                <Button
                  isBlock
                  size="medium"
                  onClick={() => goToUrl(dataTrack?.data.tracking.tracking_url)}
                >
                  Live Tracking
                </Button>
              )}
            </Flex>
            <Flex
              width="calc(100% - 328px)"
              flexDirection="column"
              borderColor="black10"
              borderWidth="1px"
              borderStyle="solid"
              borderRadius="16px"
              padding="16px"
              overflowX="auto"
            >
              {dataTracker.length === 0 ? (
                <Flex
                  width="100%"
                  height="100%"
                  alignItems="center"
                  justifyContent="center"
                >
                  Data belum tersedia
                </Flex>
              ) : (
                [...dataTracker].map((tracking, indexTracking) => (
                  <Flex key={`tracker-${indexTracking}`}>
                    <Flex
                      flexDirection="column"
                      paddingRight="18px"
                      width="48px"
                      alignItems="flex-end"
                      borderRightColor="black10"
                      borderRightWidth="1px"
                      borderRightStyle="dashed"
                      position="relative"
                    >
                      <Text variant="caption" color="black50">
                        {tracking.time_fmt}
                      </Text>
                      <Text variant="caption" color="black50">
                        {tracking.date_fmt}
                      </Text>

                      {indexTracking === 0 ? (
                        <Flex position="absolute" right="5.3px" top="5px">
                          <Flex position="relative" alignItems="center">
                            <Box
                              position="absolute"
                              backgroundColor="blue10"
                              height="12px"
                              width="12px"
                              borderRadius="50%"
                            />
                            <Box
                              position="absolute"
                              backgroundColor="blue50"
                              height="6px"
                              width="6px"
                              borderRadius="50%"
                              left="3px"
                            />
                          </Flex>
                        </Flex>
                      ) : (
                        <Box
                          position="absolute"
                          backgroundColor="black25"
                          height="8px"
                          width="8px"
                          borderRadius="50%"
                          right="-4.4px"
                          top="5px"
                        />
                      )}
                    </Flex>

                    <Flex
                      paddingLeft="18px"
                      flexDirection="column"
                      width="calc(100% - 66px)"
                      pb={indexTracking !== dataTracker.length - 1 && "24px"}
                    >
                      <Text
                        variant="caption"
                        color="black75"
                        weight={indexTracking === 0 && "bold"}
                      >
                        {tracking.event}
                      </Text>
                    </Flex>
                  </Flex>
                ))
              )}
            </Flex>
          </Flex>
        )}
      </Flex>
    </StyledDialog>
  );
};

export default ModalTrack;
