import { useHistory } from "react-router-dom";
import { Flex, Text, Icon, Tabs } from "renos-ui";
import Menu from "./Menu";

const items = [
  {
    label: ({ isActive }) => (
      <Flex gap="8px" alignItems="center" marginBottom="12px">
        <Icon
          name="Desktop-outline"
          size={20}
          color={isActive ? "blue50" : "black50"}
        />
        <Text
          color={isActive ? "blue50" : "black50"}
          weight={isActive && "bold"}
        >
          Desktop
        </Text>
      </Flex>
    ),
    key: "web",
    icon: "Right-solid",
    width: "160px",
    children: () => {},
  },
  {
    label: ({ isActive }) => (
      <Flex gap="8px" alignItems="center" marginBottom="12px">
        <Icon
          name="Mobile-outline"
          size={20}
          color={isActive ? "blue50" : "black50"}
        />
        <Text
          color={isActive ? "blue50" : "black50"}
          weight={isActive && "bold"}
        >
          Mobile
        </Text>
      </Flex>
    ),
    key: "mobile",
    width: "160px",
    children: () => {},
  },
];

const DesignSetting = ({ setActiveTab, activeTab }) => {
  const { push } = useHistory();

  const handleChangeTabActive = (value) => {
    setActiveTab(value);
    push(`/store-layout?tab-active=${value}`);
  };

  return (
    <Flex
      width="368px"
      height="461px"
      borderRadius="16px"
      flexDirection="column"
      gap="16px"
    >
      <Flex
        padding="16px 24px"
        boxShadow="0px 4px 4px rgba(20, 20, 32, 0.05)"
        backgroundColor="white"
        borderTopLeftRadius="16px"
        borderTopRightRadius="16px"
      >
        <Tabs
          onChange={handleChangeTabActive}
          defaultActiveKey={activeTab}
          placement="bottom"
          items={items}
        />
      </Flex>
      <Menu />
    </Flex>
  );
};

export default DesignSetting;
