import React from "react";
import { Flex, Text, Box } from "renos-ui";

const EmptyTemplate = () => {
  return (
    <Flex
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      gap="12px"
      height="300px"
    >
      <Box as="img" src="/assets/images/empty-auto-chat.png" alt="Empty Data" />
      <Flex flexDirection="column" textAlign="center">
        <Text weight="bold">Belum ada template balasan chat</Text>
        <Flex flexDirection="column" marginTop="8px">
          <Text variant="caption" color="black50">
            Yuk buat template balasan chat-mu sekarang.
          </Text>
          <Text variant="caption" color="black50">
            Klik tombol +Tambah Template di pojok kanan atas ya!
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default EmptyTemplate;
