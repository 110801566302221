import {
  Icon,
  Flex,
  Badge,
  Dropdown,
  Tabs,
  Text,
  Box,
  useDisclosure,
} from "renos-ui";
import { useGetNotificationCounting } from "services/notification";
import { StyledDropDownContent } from "./styled";
import SaleTab from "./SaleTab";
// import UpdateTab from "./UpdateTab";
import NavbarContext from "providers/NavbarProvider";
import { useContext } from "react";
import { useEffect } from "react";

const NotifBox = ({ flat }) => {
  const disclosure = useDisclosure({ open: false });

  const { overlay, setOverlay } = useContext(NavbarContext);

  useEffect(() => {
    if (disclosure.isOpen) {
      setOverlay("notif");
    } else {
      setOverlay(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disclosure.isOpen]);

  const { data: totalNotification } = useGetNotificationCounting();

  const tabItems = [
    {
      label: ({ isActive }) => (
        <Box padding="10px">
          <Text
            color={isActive ? "blue50" : "black50"}
            weight={isActive ? "bold" : "regular"}
          >
            Penjualan
          </Text>
        </Box>
      ),
      children: SaleTab,
      key: "penjualan",
      width: "100%",
    },
    // Hide update tab until API from BE is ready
    // {
    //   label: ({ isActive }) => (
    //     <Box padding="10px">
    //       <Text
    //         color={isActive ? "blue50" : "black50"}
    //         weight={isActive ? "bold" : "regular"}
    //       >
    //         Update
    //       </Text>
    //     </Box>
    //   ),
    //   children: UpdateTab,
    //   key: "update",
    //   width: "50%",
    // },
  ];

  return (
    <Box zIndex={overlay === "notif" && 10}>
      <Dropdown control={disclosure} trigger="hover">
        <Dropdown.Toggle>
          <Flex
            alignItems="center"
            justifyContent="center"
            width="48px"
            height="48px"
            borderRadius="8px"
            boxShadow="0px 4px 12px rgba(0, 0, 0, 0.05)"
            backgroundColor="white"
            borderWidth="1px"
            borderColor="black10"
            borderStyle="solid"
            cursor="pointer"
          >
            <Badge
              showZero={false}
              borderRadius="100%"
              count={totalNotification?.total_unread}
              backgroundColor="red50"
            >
              <Icon name="Notification-solid" color="black50" size={24} />
            </Badge>
          </Flex>
        </Dropdown.Toggle>
        <StyledDropDownContent
          minWidth="344px !important"
          positionDropdown="center"
        >
          <Box
            marginTop="10px"
            padding="16px 24px"
            backgroundColor="white"
            borderRadius="8px"
            boxShadow="0px 4px 12px rgba(0, 0, 0, 0.05)"
          >
            <Tabs
              defaultActiveKey="penjualan"
              placement="bottom"
              items={tabItems}
            />
          </Box>
        </StyledDropDownContent>
      </Dropdown>
    </Box>
  );
};

export default NotifBox;
