import React, { useState } from "react";
import { Box, DatePicker, Divider, Flex, Text } from "renos-ui";

const FilterDate = () => {
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const maxDate = new Date();

  return (
    <Flex padding="24px" flexDirection="column" width="100%" rowGap="16px">
      <Flex flexDirection="column">
        <Text weight="bold" color="black75">
          Rentang Tanggal
        </Text>
        <Text variant="caption" color="black75">
          Atur filter produk berdasarkan rentang tanggal produk di upload.
        </Text>
      </Flex>

      <Flex gap="8px" width="100%" alignItems="center">
        <DatePicker
          placeholderText="Mulai Tanggal"
          maxDate={maxDate}
          selected={startDate}
          onChange={(date) => setStartDate(date)}
        />
        <Box width="12px">
          <Divider />
        </Box>
        <DatePicker
          placeholderText="Sampai Tanggal"
          maxDate={maxDate}
          minDate={startDate}
          selected={endDate}
          onChange={(date) => setEndDate(date)}
        />
      </Flex>
    </Flex>
  );
};

export default FilterDate;
