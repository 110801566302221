import { Button, Flex, Icon, Text, cloneDeep } from "renos-ui";
import { StyledDialog } from "./styled";
import Card from "./Card";
import { useState } from "react";

const ModalFilter = ({ onClose, status, isOpen, value, setValue }) => {
  const [tempValue, setTempValue] = useState(value);
  const arrStatus = [
    {
      id: 1,
      name: "Berlangsung",
    },
    {
      id: 2,
      name: "Menunggu",
    },
  ];

  const arrVoucherType = [
    {
      id: 1,
      name: "Voucher Toko",
    },
    {
      id: 2,
      name: "Voucher Produk",
    },
  ];

  const arrDiscountType = [
    {
      id: 2,
      name: "Nominal",
    },
    {
      id: 1,
      name: "Persentase",
    },
  ];

  const handleChange = (param, newValue) => {
    switch (param) {
      case "status":
        setTempValue({
          ...tempValue,
          custom_status: tempValue.custom_status === newValue ? "" : newValue,
        });
        break;
      case "type":
        setTempValue({
          ...tempValue,
          jenis_voucher: tempValue.jenis_voucher === newValue ? "" : newValue,
        });
        break;
      case "discount":
        setTempValue({
          ...tempValue,
          tipe_potongan: tempValue.tipe_potongan === newValue ? "" : newValue,
        });
        break;
      default:
        break;
    }
  };

  const handleSave = () => {
    setValue(tempValue);
    onClose();
  };

  const handleReset = () => {
    const tempDataReset = cloneDeep(tempValue);
    delete tempDataReset.custom_status;
    delete tempDataReset.jenis_voucher;
    delete tempDataReset.tipe_potongan;

    setTempValue(tempDataReset);
  };

  return (
    <StyledDialog isVisible={isOpen} onClose={onClose} verticalCentered>
      <Flex flexDirection="column" gap="24px" width="301px">
        <Flex justifyContent="space-between" alignContent="center">
          <Text variant="heading3" weight="bold">
            Filter
          </Text>
          <Icon
            name="Close-outline"
            cursor="pointer"
            size={24}
            color="black60"
            onClick={onClose}
          />
        </Flex>
        <Flex flexDirection="column" gap="16px">
          {parseInt(status) !== 2 && (
            <Flex flexDirection="column" gap="8px">
              <Text weight="bold" color="black75">
                Status
              </Text>
              <Flex gap="24px">
                {arrStatus.map((cardStatus, indexStatus) => (
                  <Card
                    key={`card-status${indexStatus}`}
                    name={cardStatus.name}
                    isActive={cardStatus.id === tempValue?.custom_status}
                    onClick={() => handleChange("status", cardStatus.id)}
                  />
                ))}
              </Flex>
            </Flex>
          )}
          <Flex flexDirection="column" gap="8px">
            <Text weight="bold" color="black75">
              Jenis Voucher
            </Text>
            <Flex gap="24px">
              {arrVoucherType.map((cardVoucher, indexVoucher) => (
                <Card
                  key={`card-status${indexVoucher}`}
                  name={cardVoucher.name}
                  isActive={cardVoucher.id === tempValue?.jenis_voucher}
                  onClick={() => handleChange("type", cardVoucher.id)}
                />
              ))}
            </Flex>{" "}
          </Flex>
          <Flex flexDirection="column" gap="8px">
            <Text weight="bold" color="black75">
              Tipe Potongan
            </Text>
            <Flex gap="24px">
              {arrDiscountType.map((cardDiscount, indexDiscount) => (
                <Card
                  key={`card-status${indexDiscount}`}
                  name={cardDiscount.name}
                  isActive={cardDiscount.id === tempValue?.tipe_potongan}
                  onClick={() => handleChange("discount", cardDiscount.id)}
                />
              ))}
            </Flex>
          </Flex>
        </Flex>
        <Flex gap="16px">
          <Button onClick={handleReset} variant="secondary" isBlock>
            Reset
          </Button>
          <Button onClick={handleSave} isBlock>
            Terapkan
          </Button>
        </Flex>
      </Flex>
    </StyledDialog>
  );
};

export default ModalFilter;
