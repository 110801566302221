import React from "react";
import { useHistory } from "react-router-dom";
import { Box, Button, Flex, Text } from "renos-ui";
import { useCheckAuth } from "services/auth";
import { LandingScrollIcon } from "./styled";
import { setGTM } from "helpers";

const LandingBanner = () => {
  const { data: authData } = useCheckAuth();

  const history = useHistory();

  const onJoinClicked = () => {
    setGTM("seller_center_section", {
      event_category: "menu",
      event_action: "click",
      event_label: "start_selling",
    });

    if (authData?.status) {
      return history.push("/register");
    }

    return window.open(
      `${process.env.REACT_APP_BUYER_SITE}/register?referrer=${window.location.origin}`,
      "_self"
    );
  };

  return (
    <Flex
      width="100%"
      position="relative"
      height="100vh"
      background="url(/assets/images/landing/banner.png)"
      backgroundRepeat="no-repeat"
      backgroundSize="cover"
      backgroundPosition="50%"
      alignItems="center"
      justifyContent="center"
    >
      <Box
        as="img"
        width="100%"
        position="absolute"
        bottom={0}
        left={0}
        src="/assets/images/landing/wave-banner.png"
      />
      <LandingScrollIcon
        as="img"
        position="absolute"
        bottom={0}
        left={0}
        src="/assets/images/landing/scroll-icon.png"
      />
      <Box width="1200px">
        <Flex
          rowGap="24px"
          flexDirection="column"
          width="360px"
          position="relative"
          zIndex={1000}
        >
          <Flex rowGap="16px" flexDirection="column">
            <Text variant="heading1" weight="bold">
              Yuk, mulai jual produkmu di Renos!
            </Text>
            <Text variant="subtitle" color="black75">
              Jangkau lebih banyak pelanggan dengan mudah untuk tingkatkan omzet
              penjualanmu.
            </Text>
          </Flex>
          <Button size="large" onClick={onJoinClicked}>
            Mulai Berjualan
          </Button>
        </Flex>
      </Box>
    </Flex>
  );
};

export default LandingBanner;
