import { useState } from "react";
import { Box, Button, Flex, Icon, Text } from "renos-ui";
import { useGetReport } from "services/statistic";
import DatepickerRange from "widgets/desktop/DatepickerRange";
import OrdersPerformance from "./orders-performance";
import TransactionSummary from "./transactionSummary";
import dayjs from "dayjs";

const Statistic = () => {
  const [dataDatepicker, setDataDatepicker] = useState({
    type: "daily",
    startDate: new Date(),
  });

  const [tempDate, setTempDate] = useState(null);
  const { mutate: mutateReport } = useGetReport({
    onSuccess: (data) => {
      const outputFilename = `laporan statistik penjualan renos ${dayjs(
        dataDatepicker.startDate
      ).format("DD MMM YYYY")}${
        dataDatepicker.endDate
          ? ` sampai ${dayjs(dataDatepicker.endDate).format("DD MMM YYYY")}`
          : ""
      }.xlsx`;
      const url = URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", outputFilename);
      document.body.appendChild(link);
      link.click();
    },
  });

  return (
    <Flex flexDirection="column" gap="40px">
      <Flex
        flexDirection="column"
        padding="24px"
        gap="24px"
        backgroundColor="white"
        boxShadow="0px 4px 4px rgba(20, 20, 32, 0.05)"
        borderRadius="16px"
      >
        <Flex flexDirection="column">
          <Text variant="heading3" weight="bold">
            Data Statistik
          </Text>
          <Box>
            <Text color="black75">
              Pantau perkembangan bisnismu untuk tingkatkan penjualanmu.
            </Text>
          </Box>
        </Flex>
        <Flex gap="16px">
          <DatepickerRange
            value={dataDatepicker}
            onChange={setDataDatepicker}
          />
          <Button
            preffix={<Icon name="Download-outline" />}
            variant="tertiary"
            onClick={() => mutateReport(tempDate)}
          >
            Download Laporan
          </Button>
        </Flex>
      </Flex>

      <TransactionSummary dataDate={dataDatepicker} setTempDate={setTempDate} />

      <OrdersPerformance dataDate={dataDatepicker} />
    </Flex>
  );
};

export default Statistic;
