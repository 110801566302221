import { Box, Divider, Flex, Skeleton } from "renos-ui";

const SkeletonLoading = () => {
  return (
    <Flex
      backgroundColor="white"
      padding="16px"
      gap="16px"
      flexDirection="column"
    >
      <Flex justifyContent="space-between" alignItems="center">
        <Flex gap="8px" height="100%">
          <Box height="100%" width="4px" borderRadius="8px" />
          <Flex flexDirection="column" gap="4px">
            <Skeleton height="16px" width="90px" />
            <Skeleton height="16px" width="90px" />
          </Flex>
        </Flex>
        <Skeleton height="16px" width="90px" />
      </Flex>
      <Divider />
      <Flex gap="16px" flexDirection="column">
        <Flex flexDirection="column" gap="8px">
          <Skeleton height="16px" width="90px" />
          <Flex gap="16px" alignItems="center">
            <Skeleton height="48px" width="48px" />
            <Flex flexDirection="column" gap="4px">
              <Skeleton height="16px" width="100px" />
              <Skeleton height="16px" width="90px" />
            </Flex>
          </Flex>

          <Flex
            padding="8px"
            backgroundColor="black5"
            borderRadius="8px"
            justifyContent="space-between"
            alignItems="center"
          >
            <Skeleton height="16px" width="90px" />
            <Skeleton height="16px" width="90px" />
          </Flex>
        </Flex>
        <Divider />
        <Flex gap="8px">
          <Flex width="50%" gap="4px">
            <Skeleton height="12px" width="12px" />
            <Flex width="calc(100% - 16px)" flexDirection="column" gap="4px">
              <Skeleton height="16px" width="100%" />
              <Skeleton height="16px" width="100%" />
            </Flex>
          </Flex>
          <Flex width="50%" gap="4px">
            <Skeleton height="12px" width="12px" />
            <Flex width="calc(100% - 16px)" flexDirection="column" gap="4px">
              <Skeleton height="16px" width="100%" />
              <Skeleton height="16px" width="100%" />
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <Divider />
      <Flex justifyContent="space-between" alignItems="center">
        <Flex flexDirection="column" gap="4px">
          <Skeleton height="16px" width="90px" />
          <Skeleton height="16px" width="90px" />
        </Flex>

        <Flex gap="4px">
          <Skeleton height="20px" width="90px" />
          <Skeleton height="20px" width="20px" />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default SkeletonLoading;
