import React from "react";
import { Box, Button, Flex, Text, Checkbox } from "renos-ui";
import FixedBottomContainer from "../components/FixedBottomContainer";
import LoadingCheckBox from "../components/LoadingCheckbox";
import useCouriers from "./useCouriers";

const CardCouriers = () => {
  const {
    listCourier,
    isCouriersError,
    isCouriersLoading,
    refetchGetCouriers,
    handleCheckbox,
    handleSave,
    changedChecker,
  } = useCouriers();

  return (
    <>
      <Flex flexDirection="column" height="calc(100% - 240px)" overflow="auto">
        {isCouriersError ? (
          <Text variant="caption" onClick={refetchGetCouriers}>
            muat ulang
          </Text>
        ) : isCouriersLoading ? (
          <LoadingCheckBox />
        ) : (
          listCourier.map((courier, index) => {
            return (
              <Flex key={`courier-${index}`} flexDirection="column">
                {!courier.fakeCard && (
                  <Flex gap="16px" alignItems="center" padding="16px">
                    <Box
                      as="img"
                      src={courier.image_url}
                      maxWidth="49px"
                      alt="courier-logo"
                    />
                    <Text variant="caption" weight="bold">
                      {courier.name}
                    </Text>
                  </Flex>
                )}

                <Flex gap="12px" flexDirection="column">
                  {courier.services.map((services, serviceIndex) => {
                    return (
                      <Flex
                        gap="8px"
                        padding="0 16px"
                        alignItems="center"
                        key={`courier-service-${index}${serviceIndex}`}
                      >
                        <Checkbox
                          checked={services.assigned_to_user_store}
                          onChange={() => handleCheckbox(index, serviceIndex)}
                        />
                        <Text variant="caption" color="black75" lineClamp={1}>
                          {services.name}
                        </Text>
                      </Flex>
                    );
                  })}
                  {!courier.fakeCard && (
                    <Flex
                      height="8px"
                      width="100%"
                      background="#f8f8f8"
                      margin="8px 0"
                    />
                  )}
                </Flex>
              </Flex>
            );
          })
        )}
      </Flex>
      <FixedBottomContainer>
        <Button
          isBlock
          size="medium"
          style={{ margin: "16px" }}
          onClick={handleSave}
          disabled={!changedChecker()}
        >
          Simpan
        </Button>
      </FixedBottomContainer>
    </>
  );
};

export default CardCouriers;