import { Flex, Text } from "renos-ui";

const Loading = () => {
  return (
    <Flex
      position="absolute"
      height="100vh"
      width="100vw"
      justifyContent="center"
      alignItems="center"
      background="white"
      top={0}
      left={0}
      zIndex={1000}
      flexDirection="column"
    >
      <Flex
        as="img"
        alt="loading"
        src="/assets/images/loading.gif"
        height="64px"
        width="64px"
      />
      <Text color="black75">Loading...</Text>
    </Flex>
  );
};

export default Loading;
