import StoreLayoutContext from "providers/StoreLayoutProvider";
import React, { useContext, useEffect, useMemo, useState } from "react";
import {
  Button,
  Flex,
  Box,
  Text,
  Icon,
  Divider,
  Label,
  useDisclosure,
  Spinner,
  useToast,
  cloneDeep,
} from "renos-ui";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Banner from "./Banner";
import BestSelling from "./BestSelling";
import Fail from "./Fail";
import Loading from "./Loading";
import ModalPreview from "./ModalPreview";
import NewProduct from "./NewProduct";
import StoreFront from "./StoreFront";
import {
  useApplyHomepage,
  useGetHomePageStatus,
  useSwapHomepage,
} from "services/store-layout/homepage";
import Voucher from "./Voucher";
import { reactDndReorder } from "helpers";
import ProductDiscount from "./ProductDiscount";
import BannerVideo from "./BannerVideo";
import ModalSuccess from "./ModalSuccess";

const Web = () => {
  const toast = useToast();

  const {
    dataHomePage,
    setDataHomePage,
    isHomePageLoading,
    bannerRef,
    voucherRef,
    newProductRef,
    bestSellingRef,
    storeFrontRef,
    discountRef,
    banner2Ref,
    bannerVideoRef,
    refetchHomePage,
    isHomePageError,
  } = useContext(StoreLayoutContext);
  const [isEdit, setIsEdit] = useState(false);
  const [active, setActive] = useState("");
  const { onToggle, isOpen, onClose } = useDisclosure({ isOpen: false });
  const {
    onToggle: onToggleSuccess,
    isOpen: isOpenSuccess,
    onClose: onCloseSuccess,
  } = useDisclosure({
    isOpen: false,
  });
  const [tempHomepage, setTempHomepage] = useState([]);

  const { mutate: mutateApply, isLoading: isApplyLoading } = useApplyHomepage({
    onSuccess: (data) => {
      onToggleSuccess();
    },
    onError: (data) => {
      const {
        response: {
          data: { frontend_message, message },
        },
      } = data;
      toast({
        label: frontend_message || message || "Gagal Terapkan",
        placement: "top",
        backgroundColor: "red50",
      });
    },
  });

  const { mutate: mutateSwap, isLoading: isSwapLoading } = useSwapHomepage({
    onError: (data) => {
      const {
        response: {
          data: { frontend_message, message },
        },
      } = data;
      toast({
        label: frontend_message || message || "Gagal merubah posisi.",
        placement: "top",
        backgroundColor: "red50",
      });

      setDataHomePage([...tempHomepage]);
    },
  });

  const {
    isLoading: isGetStatusLoading,
    isError: isGetStatusError,
    data: dataStatus,
    refetch: refetchHomePageStatus,
  } = useGetHomePageStatus();

  const handleToEdit = (id) => {
    setActive(id);
    setIsEdit(true);

    switch (id) {
      case 1:
        bannerRef.current.scrollIntoView();
        break;
      case 2:
        voucherRef.current.scrollIntoView();
        break;
      case 3:
        bestSellingRef.current.scrollIntoView();
        break;
      case 4:
        newProductRef.current.scrollIntoView();
        break;
      case 5:
        storeFrontRef.current.scrollIntoView();
        break;
      case 6:
        discountRef.current.scrollIntoView();
        break;
      case 7:
        bannerVideoRef.current.scrollIntoView();
        break;
      case 8:
        banner2Ref.current.scrollIntoView();
        break;
      default:
        break;
    }
  };

  const handleBack = () => {
    setIsEdit(false);
    refetchHomePage();
  };

  const handleEditView = () => {
    switch (active) {
      case 1:
      case 8:
        return <Banner id={active} handleBack={handleBack} />;
      case 2:
        return (
          <Voucher
            id={active}
            handleBack={handleBack}
            isLoading={isHomePageLoading}
          />
        );
      case 3:
        return <BestSelling id={active} handleBack={handleBack} />;
      case 4:
        return <NewProduct id={active} handleBack={handleBack} />;
      case 5:
        return <StoreFront id={active} handleBack={handleBack} />;
      case 6:
        return <ProductDiscount id={active} handleBack={handleBack} />;
      case 7:
        return <BannerVideo id={active} handleBack={handleBack} />;
      default:
        break;
    }
  };

  const handleApply = () => {
    mutateApply();
  };

  const handleSwap = (swapedValue) => {
    const orderedData = reactDndReorder(
      cloneDeep(dataHomePage),
      swapedValue?.source?.index,
      swapedValue?.destination?.index
    ).map((orderedHomepage) => ({
      ...orderedHomepage,
      isDraft: true,
    }));

    setTempHomepage([...cloneDeep(dataHomePage)]);
    setDataHomePage([...orderedData]);

    mutateSwap({
      data: orderedData,
    });
  };

  const handlePreview = () => {
    if (isPreviewDisabled) {
      toast({
        label: "Kamu belum mengatur tampilan toko.",
        placement: "top",
        backgroundColor: "red50",
      });
    } else {
      onToggle();
    }
  };

  const handleCloseSuccess = () => {
    refetchHomePage();
    refetchHomePageStatus();
    onCloseSuccess();
  };

  const isPreviewDisabled = useMemo(() => {
    const countActive = dataHomePage.filter(
      (filterHomepage) => filterHomepage.isActive
    );
    if (countActive.length > 0) return false;

    return true;
  }, [dataHomePage]);

  const isShowDraftStatus = useMemo(
    () =>
      !isHomePageLoading &&
      !isHomePageError &&
      !isGetStatusLoading &&
      !isGetStatusError,
    [isGetStatusLoading, isHomePageLoading, isHomePageError, isGetStatusError]
  );

  useEffect(() => {
    if (!isEdit) refetchHomePageStatus();
    // eslint-disable-next-line
  }, [isEdit]);

  return (
    <Flex
      px="24px"
      boxShadow="0px 4px 4px rgba(20, 20, 32, 0.05)"
      backgroundColor="white"
      borderBottomLeftRadius="16px"
      borderBottomRightRadius="16px"
      gap="24px"
      flexDirection="column"
    >
      {isEdit ? (
        handleEditView()
      ) : (
        <>
          <Flex gap="12px" alignItems="center" paddingTop="24px" width="314px">
            <Box
              as="img"
              src="/assets/images/store-layout/icon-web-design.svg"
              alt="icon-design"
            />
            <Text variant="subtitle" weight="bold">
              Pengaturan Desain
            </Text>
          </Flex>

          <Flex flexDirection="column">
            {isHomePageLoading ? (
              <Loading />
            ) : isHomePageError ? (
              <Fail refetch={refetchHomePage} />
            ) : (
              <DragDropContext onDragEnd={handleSwap}>
                <Droppable droppableId="droppable">
                  {(provided) => (
                    <Flex
                      flexDirection="column"
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      {dataHomePage.map((menu, indexMenu) => (
                        <Draggable
                          key={`menu-${menu.id}`}
                          draggableId={String(menu.id)}
                          index={indexMenu}
                        >
                          {(provided) => (
                            <Flex
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              flexDirection="column"
                            >
                              <Flex
                                justifyContent="space-between"
                                alignItems="center"
                                py="16px"
                              >
                                <Flex gap="8px" alignItems="center">
                                  <Icon name="Drag-outline" color="black25" />
                                  <Flex
                                    gap="8px"
                                    onClick={() => handleToEdit(menu.id)}
                                    cursor="pointer"
                                  >
                                    <Text color="black75" weight="bold">
                                      {menu.name}
                                    </Text>
                                    {menu.isDraft ? (
                                      <Label variant="warning">Draft</Label>
                                    ) : menu.isActive ? (
                                      <Label variant="success">Aktif</Label>
                                    ) : (
                                      <></>
                                    )}
                                  </Flex>
                                </Flex>

                                <Icon
                                  name="Right-outline"
                                  color="black50"
                                  size={20}
                                  cursor="pointer"
                                  onClick={() => handleToEdit(menu.id)}
                                />
                              </Flex>
                              <Divider />
                            </Flex>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </Flex>
                  )}
                </Droppable>
              </DragDropContext>
            )}
          </Flex>

          {isShowDraftStatus && (
            <Flex
              padding="16px"
              flexDirection="column"
              gap="8px"
              borderRadius="8px"
              backgroundColor="black5"
            >
              <Flex justifyContent="space-between" alignItems="center">
                <Text variant="caption" color="black50">
                  Terakhir disimpan:
                </Text>
                <Text variant="caption">
                  {dataStatus?.data?.draft_date || "-"}
                </Text>
              </Flex>
              <Flex justifyContent="space-between" alignItems="center">
                <Text variant="caption" color="black50">
                  Terakhir diterapkan:
                </Text>
                <Text variant="caption">
                  {dataStatus?.data?.activate_date || "-"}
                </Text>
              </Flex>
            </Flex>
          )}

          {!isHomePageError && (
            <Flex gap="16px" paddingBottom="24px">
              <Button
                variant="tertiary"
                isBlock
                onClick={handlePreview}
                disabled={isHomePageLoading}
              >
                Lihat Tampilan
              </Button>

              <Button
                isBlock
                onClick={handleApply}
                disabled={isHomePageLoading || isApplyLoading || isSwapLoading}
                preffix={
                  (isHomePageLoading || isApplyLoading) && (
                    <Spinner color="white" size="8px" />
                  )
                }
              >
                Terapkan
              </Button>
            </Flex>
          )}
        </>
      )}
      <ModalPreview
        isOpen={isOpen}
        onClose={onClose}
        disabled={isPreviewDisabled}
      />

      <ModalSuccess isOpen={isOpenSuccess} onClose={handleCloseSuccess} />
    </Flex>
  );
};

export default Web;
