import { colors, Box } from "renos-ui";
import styled from "styled-components";

const WrapperChart = styled(Box)`
  background: ${colors.white};
  padding: 24px;
  box-shadow: 0px 4px 4px rgba(20, 20, 32, 0.05);
  border-radius: 16px;

  .arrow_box {
    margin: 20px 20px;
    position: relative;
    background: #ffffff;
  }

  .date-time {
    flex-grow: 0;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: 0.75px;
    text-align: left;
    color: ${colors.black50};
  }

  .period {
    flex-grow: 0;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: 0.75px;
    text-align: left;
    color: ${colors.black50};
  }

  #chart {
    width: 100%;
  }

  #chart .apexcharts-tooltip {
    color: ${colors.white};
    transform: translateX(10px) translateY(10px);
    overflow: visible !important;
    white-space: normal !important;
  }
`;

export { WrapperChart };
