import React, { useEffect } from "react";
import { Flex, Text, Input, Button, Icon } from "renos-ui";
import { useCheckStoreName } from "services/register/check-store";
import MobileLayout from "layouts/MobileLayout";

import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { StyledInput } from "../styled";
import { StoreInfoSchema } from "./storeInfo-schema";
import { useLogout } from "services/auth";

const StoreInformation = ({ setStep }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    setError,
  } = useForm({
    resolver: yupResolver(StoreInfoSchema),
    defaultValues: {
      name: "",
      email: "",
      description: "",
      contact_person: "",
      phone_number: "",
    },
  });

  const { mutate: submitRegister } = useCheckStoreName({
    onSuccess: (data) => {
      if (data.data.name_already_exist) {
        setError("name", {
          type: "manual",
          message: data?.frontend_message || "Nama sudah diambil",
        });
      } else {
        const storeValues = {
          name: getValues("name"),
          email: getValues("email"),
          description: getValues("description"),
          contact_person: getValues("contact_person"),
          phone_number: getValues("phone_number"),
        };

        const storeInformation = JSON.stringify(storeValues);

        localStorage.setItem(
          "storeInformation",
          JSON.stringify(storeInformation)
        );
        setStep(2);
      }
    },
    onError: (error) => {
      console.log("error", error);
    },
  });

  const { mutate: onLogout } = useLogout({
    onSuccess: () => {
      localStorage.removeItem("storeInformation");
    },
  });

  const onSubmit = () => {
    submitRegister({ name: getValues("name") });
  };

  useEffect(() => {
    const storedJsonString = localStorage.getItem("storeInformation");
    const storedObject = JSON.parse(storedJsonString);

    setValue("name", JSON.parse(storedObject)?.name);
    setValue("email", JSON.parse(storedObject)?.email);
    setValue("description", JSON.parse(storedObject)?.description);
    setValue("contact_person", JSON.parse(storedObject)?.contact_person);
    setValue("phone_number", JSON.parse(storedObject)?.phone_number);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setStep]);

  return (
    <MobileLayout
      header={{
        title: "Informasi Toko",
        isHomepage: true,
        withBack: false,
        suffix: (
          <Flex alignItems="center" cursor="pointer" onClick={onLogout}>
            <Icon name="Logout-outline" color="black50" cursor="pointer" />
          </Flex>
        ),
      }}
    >
      <Flex flexDirection="column" padding="16px" width="100%">
        <form
          onSubmit={handleSubmit(onSubmit)}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            width: "100%",
            height: "100%",
          }}
        >
          <Flex
            flexDirection="column"
            width="100%"
            height="100%"
            justifyContent="space-between"
          >
            <Flex flexDirection="column" gap="24px">
              <Flex width="100%">
                <Controller
                  name="name"
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      label="Nama Toko"
                      showCount
                      size="small"
                      placeholder="Ketik nama toko"
                      maxLength={60}
                      isError={!!errors.name}
                      error={errors.name?.message}
                    />
                  )}
                />
              </Flex>

              <Controller
                name="contact_person"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    label="Nama Contact Person"
                    maxLength={50}
                    size="small"
                    placeholder="Ketik nama Contact Person"
                    isError={!!errors.contact_person}
                    error={errors.contact_person?.message}
                  />
                )}
              />
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    label="Email Toko"
                    maxLength={50}
                    size="small"
                    placeholder="Ketik email toko"
                    isError={!!errors.email}
                    error={errors.email?.message}
                  />
                )}
              />

              <Flex flexDirection="column" rowGap="8px">
                <Text weight="bold" variant="caption" color="black50">
                  No. HP Contact Person
                </Text>
                <Controller
                  name="phone_number"
                  control={control}
                  render={({ field }) => (
                    <StyledInput
                      {...field}
                      type="text"
                      value={getValues("phone_number")}
                      name="phone_number"
                      placeholder="Ketik nomor HP Contact Person"
                      onChange={(e) =>
                        setValue(
                          "phone_number",
                          e.target.value.replace(/[^+\d]/g, "").substring(0, 15)
                        )
                      }
                      isError={!!errors.phone_number}
                    />
                  )}
                />

                {!!errors.phone_number && (
                  <Text variant="caption" color="red50">
                    {errors.phone_number?.message ||
                      "No. HP Contact Person harus diisi."}
                  </Text>
                )}
              </Flex>
            </Flex>

            <Flex>
              <Button isBlock size="small" type="submit" variant="primary">
                <Text weight="bold">Simpan</Text>
              </Button>
            </Flex>
          </Flex>
        </form>
      </Flex>
    </MobileLayout>
  );
};

export default StoreInformation;
