import React from "react";
import { Box, Flex, Icon, Text, useDisclosure, Tooltip } from "renos-ui";
import PropTypes from "prop-types";
import { StyledTable } from "./styled";

const TableCascader = ({ productSkus, rowIndex, columns, productType }) => {
  const { isOpen, onToggle } = useDisclosure({ open: false });

  return (
    <>
      <tr>
        <Box
          as="td"
          border="0"
          colSpan={columns.length}
          borderBottom="1px"
          borderStyle="solid"
          borderColor="black10"
        />
      </tr>
      {isOpen &&
        productSkus
          .filter(
            (filterSKU) =>
              filterSKU?.active_product_discount?.discount_type_id !== null
          )
          .map((dataSku, rowIndex) => (
            <tr key={`table-row-${rowIndex}`}>
              {columns.map((column, dataIndex) => {
                const omittedColumn = ["checkBox", "cta", "product_status_id"];

                return omittedColumn.includes(column.key) ? (
                  <Box
                    as="td"
                    border="0"
                    borderBottom="1px"
                    borderStyle="solid"
                    borderColor="black10"
                  />
                ) : (
                  <Box
                    as="td"
                    key={`table-data-${rowIndex}-${dataIndex}`}
                    width={column.width || "auto"}
                    py="20px"
                    border="0"
                    borderBottom="1px"
                    borderStyle="solid"
                    borderColor="black10"
                    textAlign="left"
                  >
                    {column.render?.(dataIndex, dataSku, true, productType) ||
                      dataSku[column.dataIndex]}
                  </Box>
                );
              })}
            </tr>
          ))}
      <tr key={`is-has-more-${rowIndex}`}>
        <Box
          as="td"
          key={`table-data-${rowIndex}`}
          py="20px"
          border="0"
          borderBottom="1px"
          borderStyle="solid"
          borderColor="black10"
          textAlign="left"
          colSpan={columns.length}
        >
          <Flex
            alignItems="center"
            columnGap="4px"
            paddingLeft="40px"
            cursor="pointer"
            onClick={onToggle}
          >
            <Text variant="caption" color="blue50" weight="bold">
              {isOpen ? "Tutup" : "Lihat Varian Produk"}
            </Text>
            <Icon
              name={isOpen ? "Up-outline" : "Down-outline"}
              size={16}
              color="blue50"
            />
          </Flex>
        </Box>
      </tr>
    </>
  );
};

const Table = ({ customHeader, columns, dataSource, ...props }) => {
  const renderTableHeader = () => {
    if (customHeader) {
      return (
        <Box
          as="th"
          border="0"
          borderBottom="1px"
          borderStyle="solid"
          borderColor="black10"
          colSpan={columns.length}
        >
          {customHeader}
        </Box>
      );
    }

    return columns.map((column, i) => (
      <Box
        as="th"
        pt="4px"
        pb="20px"
        border="0"
        borderBottom="1px"
        borderStyle="solid"
        borderColor="black10"
        textAlign={column.align || "left"}
        key={`table-header-${i}`}
        width={column.width || "auto"}
      >
        {typeof column.title === "string" ? (
          <Flex gap="4px">
            <Text color="black50" weight="bold">
              {column.title}
            </Text>
            {column.isTooltip === true && (
              <Tooltip
                width={120}
                label={column.tooltip}
                textAlign="center"
                icon={<Icon name="Info-solid" size={16} color="black50" />}
              />
            )}
          </Flex>
        ) : (
          column.title
        )}
      </Box>
    ));
  };

  return (
    <Box {...props}>
      <StyledTable>
        <Box as="thead">
          <Box as="tr">{renderTableHeader()}</Box>
        </Box>
        <Box as="tbody">
          {dataSource.map((data, rowIndex) => {
            const { isHasMore, product_skus } = data;

            return isHasMore ? (
              [
                <tr key={`table-row-${rowIndex}`}>
                  {columns.map((column, dataIndex) => {
                    return (
                      <Box
                        as="td"
                        key={`table-data-${rowIndex}-${dataIndex}`}
                        width={column.width || "auto"}
                        py="20px"
                        textAlign="left"
                      >
                        {column.render?.(
                          dataIndex,
                          data,
                          false,
                          data.product_type
                        ) || data[column.dataIndex]}
                      </Box>
                    );
                  })}
                </tr>,
                <TableCascader
                  key={`table-cascader-${rowIndex}`}
                  productSkus={product_skus}
                  columns={columns}
                  rowIndex={rowIndex}
                  productType={data.product_type}
                />,
              ]
            ) : (
              <tr key={`table-row-${rowIndex}`}>
                {columns.map((column, dataIndex) => {
                  return (
                    <Box
                      as="td"
                      key={`table-data-${rowIndex}-${dataIndex}`}
                      width={column.width || "auto"}
                      py="20px"
                      border="0"
                      borderBottom="1px"
                      borderStyle="solid"
                      borderColor="black10"
                      textAlign="left"
                    >
                      {column.render?.(
                        dataIndex,
                        data,
                        false,
                        data.product_type
                      ) || data[column.dataIndex]}
                    </Box>
                  );
                })}
              </tr>
            );
          })}
        </Box>
      </StyledTable>
    </Box>
  );
};

Table.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
      dataIndex: PropTypes.string,
      key: PropTypes.string,
      render: PropTypes.func,
      align: PropTypes.string,
      width: PropTypes.string,
    })
  ),
  dataSource: PropTypes.arrayOf(PropTypes.object),
  customHeader: PropTypes.node,
};

Table.defaultProps = {
  columns: [],
  dataSource: [],
};

export default Table;
