import styled from "styled-components";
import { Input, Flex } from "renos-ui";
import { colors } from "renos-ui";

// export const StyledInput = styled(Input)`
//   z-index: 1;
//   width: 94%;
//   padding: 10px 12px;
//   margin: 12px;
//   height: 32px;
//   border-radius: 100px;
//   background-color: #f5f5f6;
// `;

export const MapsContainer = styled(Flex)`
  height: 100%;
  width: 100%;
  border-radius: 0 !important;
`;

export const StyledDatalist = styled.datalist`
  width: 100%;
  ::-webkit-calendar-picker-indicator {
    display: none;
  }
`;

export const StyledInput = styled(Input)`
  box-sizing: border-box;
  width: 100%;
  line-height: 20px;
  padding: 12px 16px;
  border-radius: 8px;
  border: 1px solid
    ${(props) => (props.isError ? colors.red50 : colors.black25)};
  color: #333346;
  appearance: none;
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &::placeholder {
    color: ${colors.black25};
    font-size: "14px";
  }
  &:focus,
  &:hover {
    border-color: ${(props) => (props.isError ? colors.red50 : colors.blue50)};
    outline: none;
  }
`;
