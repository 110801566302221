import { Flex, Text, Divider, Button, numberInputChecking } from "renos-ui";
import { ReactComponent as ICRenos } from "assets/icons/renos-logo.svg";
import ReactToPrint from "react-to-print";
import { useContext, useEffect, useRef, useState } from "react";
import { useGetBarcode, useGetCourierImg } from "services/order-management";
import StoreContext from "providers/StoreProvider";
import { maskAllName } from "helpers/string-mask";

const LabelDetail = ({ isOpen, data, refMobile }) => {
  const { storeData } = useContext(StoreContext);
  const isMobile = window.innerWidth <= 760;
  const componentRef = useRef();

  const [imageBarcode, setImageBarcode] = useState("");

  const [image3pl, setImage3pl] = useState("");
  const [isSicepat, setIsSicepat] = useState(false);

  const { isLoading: isBarcodeLoading, refetch: refetchBarcode } =
    useGetBarcode(
      { order_id: data.order_id },
      {
        onSuccess: (ret) => {
          if (typeof ret !== "object") {
            setImageBarcode(ret);
          }
        },
      }
    );

  const { refetch: refetchGetCourerImg } = useGetCourierImg(
    {
      courier_service_id: data.shipping.logistics_courier_service_id,
      include_courier: true,
    },
    {
      onSuccess: (ret) => {
        if (ret.data.length !== 0) {
          const tempImage = ret?.data[0].courier?.image_url
            ? `${process.env.REACT_APP_ASSETS_IMAGE}/${ret?.data[0].courier?.image_url}`
            : "";
          setIsSicepat(ret?.data[0].courier?.name);
          setImage3pl(tempImage);
        }
      },
    }
  );

  useEffect(() => {
    refetchBarcode();
    refetchGetCourerImg();
    // eslint-disable-next-line
  }, [isOpen]);

  return (
    <>
      <Flex
        maxHeight={isMobile ? "none" : "calc(100vh - 200px)"}
        overflowY={isMobile ? "unset" : "auto"}
      >
        <Flex
          flexDirection="column"
          ref={isMobile ? refMobile : componentRef}
          width="526px"
          padding="16px"
        >
          <Flex gap="16px" flexDirection="column">
            <Flex justifyContent="space-between" alignItems="center">
              <ICRenos style={{ width: 80 }} />

              {image3pl && (
                <Flex
                  as="img"
                  alt="img-3pl"
                  src={image3pl}
                  width={isSicepat ? "23mm" : "40px"}
                />
              )}
            </Flex>
            <Divider />
            <Flex
              textAlign="center"
              justifyContent="center"
              flexDirection="column"
            >
              <Text variant="caption" color="black50">
                No Pesanan
              </Text>
              <Text weight="bold" color="black75">
                {data.order_number}
              </Text>
            </Flex>

            {!isBarcodeLoading && imageBarcode.length > 11 ? (
              <Flex dangerouslySetInnerHTML={{ __html: `${imageBarcode}` }} />
            ) : null}

            <Flex
              padding="12px"
              justifyContent="center"
              borderColor="black10"
              borderWidth="1px"
              borderStyle="solid"
            >
              <Text variant="caption" color="black75">
                Ongkir sudah dibayarkan dan kamu <b>tidak perlu membayar</b>{" "}
                biaya kirim paket ke kurir.
              </Text>
            </Flex>

            <Flex gap="8px">
              <Flex width="50%" flexDirection="column" gap="4px">
                <Flex>
                  <Flex width="80px">
                    <Text color="black50">Kurir</Text>
                  </Flex>
                  <Flex width="calc(100% - 80px)">
                    <Text color="black75" weight="bold">
                      : {data.shipping.logistics_courier_name} -{" "}
                      {data.shipping.logistics_courier_service_name}
                    </Text>
                  </Flex>
                </Flex>
                {data.shipping.order_shipping_receipt_number && (
                  <Flex>
                    <Flex width="80px">
                      <Text color="black50">Nomor Resi</Text>
                    </Flex>
                    <Flex width="calc(100% - 80px)">
                      <Text color="black75" weight="bold">
                        : {data.shipping.order_shipping_receipt_number}
                      </Text>
                    </Flex>
                  </Flex>
                )}
                <Flex>
                  <Flex width="80px">
                    <Text color="black50">Berat Paket</Text>
                  </Flex>
                  <Flex width="calc(100% - 80px)">
                    <Text color="black75" weight="bold">
                      :{" "}
                      {data.products.reduce(
                        (totalWeight, { product_weight }) =>
                          totalWeight + product_weight,
                        0
                      ) !== null
                        ? data.products.reduce(
                            (n, { product_weight }) =>
                              n + parseInt(product_weight),
                            0
                          ) +
                          " " +
                          data.products[0].uom_name
                        : "-"}
                    </Text>
                  </Flex>
                </Flex>
                <Flex>
                  <Flex width="80px">
                    <Text color="black50">Biaya Kirim</Text>
                  </Flex>
                  <Flex width="calc(100% - 80px)">
                    <Text color="black75" weight="bold">
                      :{" "}
                      {data.shipping.order_shipping_price !== null
                        ? "Rp " +
                          numberInputChecking(
                            data.shipping.order_shipping_price.toString() ||
                              "0",
                            0
                          ).formattedValue
                        : "-"}{" "}
                    </Text>
                  </Flex>
                </Flex>
              </Flex>
              <Flex width="50%" flexDirection="column" gap="16px">
                <Flex flexDirection="column" gap="4px">
                  <Text color="black75" weight="bold">
                    PENERIMA
                  </Text>
                  <Flex flexDirection="column">
                    <Text variant="caption" color="black50" weight="bold">
                      {data.shipping.destination_pic_name} -{" "}
                      {maskAllName(
                        data.shipping.destination_pic_phone || "",
                        true
                      )}
                    </Text>
                    <Text variant="caption" color="black50">
                      {data.shipping.destination_address},{" "}
                    </Text>
                    <Text variant="caption" color="black50">
                      {data.shipping.destination_lower_district_name},{" "}
                      {data.shipping.destination_district_name},{" "}
                      {data.shipping.destination_city_name},{" "}
                      {data.shipping.destination_province_name}{" "}
                      {data.shipping.destination_postal_code}
                    </Text>
                  </Flex>
                </Flex>

                <Flex flexDirection="column" gap="4px">
                  <Text color="black75" weight="bold">
                    PENGIRIM
                  </Text>
                  <Flex flexDirection="column" fontSize="8px">
                    <Text variant="caption" color="black50" weight="bold">
                      {storeData.data.name} - {data.shipping.origin_pic_phone}
                    </Text>
                    <Text variant="caption" color="black50">
                      {data.shipping.origin_address},{" "}
                    </Text>
                    <Text variant="caption" color="black50">
                      {data.shipping.origin_lower_district_name},{" "}
                      {data.shipping.origin_district_name},{" "}
                      {data.shipping.origin_city_name},{" "}
                      {data.shipping.origin_province_name}{" "}
                      {data.shipping.origin_postal_code}
                    </Text>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          </Flex>

          <Flex flexDirection="column" gap="8px">
            <Text color="black75" weight="bold">
              DETAIL PAKET
            </Text>

            <Flex>
              <Flex width="calc(100% - 220px)" pr="8px">
                <Text variant="caption" color="black50">
                  Nama Barang
                </Text>
              </Flex>
              <Flex width="70px" pr="8px">
                <Text variant="caption" color="black50">
                  Jumlah
                </Text>
              </Flex>
              <Flex width="150px">
                <Text variant="caption" color="black50">
                  Catatan Pembeli
                </Text>
              </Flex>
            </Flex>
            <Divider />
            {data.products.map((product, indexProduct) => (
              <Flex key={`product-${indexProduct}`}>
                <Flex width="calc(100% - 220px)" pr="8px">
                  <Text variant="caption" color="black50">
                    {product.product_name}
                  </Text>
                </Flex>
                <Flex width="70px" pr="8px">
                  <Text variant="caption" color="black50">
                    {product.order_product_quantity}
                  </Text>
                </Flex>
                <Flex width="150px">
                  <Text variant="caption" color="black50">
                    {product.order_note ? product.order_note : "-"}
                  </Text>
                </Flex>
              </Flex>
            ))}
          </Flex>
        </Flex>
      </Flex>
      {!isMobile && (
        <ReactToPrint
          trigger={() => <Button isBlock>Cetak</Button>}
          content={() => componentRef.current}
          documentTitle={`renos_pesanan_${data.order_number}`}
          copyStyles={true}
        />
      )}
    </>
  );
};

export default LabelDetail;
