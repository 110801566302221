import { useMutation } from "react-query";

const youtubeUrlToJson = async (youtubeUrl) => {
  const data = await fetch(
    `https://www.youtube.com/oembed?url=${youtubeUrl}&format=json&width=800&height=444`
  );

  const json = await data.json();

  return { ...json, url: youtubeUrl };
};

export const useGetYoutubeData = (extraParams) =>
  useMutation((youtubeUrl) => youtubeUrlToJson(youtubeUrl), {
    ...extraParams,
  });
