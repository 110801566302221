import React, { useEffect, useMemo, useState } from "react";
import { Flex, Icon, useDebounce } from "renos-ui";
import { StyledInput, StyledSelectInput } from "./styled";
import { useGetShowcase } from "services/promo-management";
import FilterPopup from "./FilterPopup";
import FilterDetail from "./FilterDetail";
import dayjs from "dayjs";

const Filter = ({
  setFilterParam,
  activeTab,
  filterParam,
  setPage,
  isPromo,
}) => {
  const DEFAULT_POPUP = {
    condition: [],
    category: [],
    courier: [],
    dateRange: { start: "", end: "" },
    priceRange: { start: "", end: "" },
    stock: { condition: "", stock: 0 },
  };

  const [searchValue, setSearchValue] = useState();
  const [sortValue, setSortValue] = useState();
  const [tagValue, setTagValue] = useState();
  const [popupValue, setPopupValue] = useState(DEFAULT_POPUP);

  const { data, isLoading } = useGetShowcase();

  const SORT_DATA = [
    { value: "bestSeller", label: "Terlaris" },
    { value: "nameAsc", label: "Nama : A-Z" },
    { value: "nameDesc", label: "Nama : Z-A" },
    { value: "highestPrice", label: "Harga Tertinggi" },
    { value: "lowestPrice", label: "Harga Terendah" },
    { value: "lowestStock", label: "Stok Tersedikit" },
    { value: "highestStock", label: "Stok Terbanyak" },
  ];

  useDebounce(
    () => {
      const params = {
        keyword: searchValue,
      };

      switch (sortValue?.value) {
        case "bestSeller":
          params.ordering = "desc";
          params.order_by = "total_sold";
          break;
        case "lowestPrice":
          params.ordering = "asc";
          params.order_by = "product_price";
          break;
        case "highestPrice":
          params.ordering = "desc";
          params.order_by = "product_price";
          break;
        case "lowestStock":
          params.ordering = "asc";
          params.order_by = "product_stock";
          break;
        case "highestStock":
          params.ordering = "desc";
          params.order_by = "product_stock";
          break;
        case "nameDesc":
          params.ordering = "desc";
          params.order_by = "product_name";
          break;
        case "nameAsc":
          params.ordering = "asc";
          params.order_by = "product_name";
          break;

        default:
          params.ordering = filterParam?.ordering;
          params.order_by = filterParam?.order_by;
          break;
      }

      if (tagValue?.value) {
        params.tag_store_id = tagValue?.value;
      }

      if (popupValue.category.length > 0) {
        const categoryIds = popupValue.category.map(
          (category) => category.value
        );

        params.category_ids = categoryIds.join(",");
      }

      if (popupValue.condition.length > 0) {
        const conditionIds = popupValue.condition.map(
          (condition) => condition.value
        );

        params.product_condition_ids = conditionIds
          .filter((id) => id !== 3)
          .join(",");

        if (conditionIds.includes(3)) {
          params.is_preorder = 1;
        }
      }

      if (popupValue.courier.length > 0) {
        const courierIds = popupValue.courier.map((courier) => courier.value);

        params.courier_service_ids = courierIds.join(",");
      }

      if (popupValue.priceRange?.start && popupValue.priceRange?.end) {
        const { start, end } = popupValue.priceRange;

        if (isPromo) {
          params.seasonal_product_discount_price_start = start;
          params.seasonal_product_discount_price_end = end;
        } else {
          params.product_price_start = start;
          params.product_price_end = end;
        }
      }

      if (popupValue.dateRange?.start && popupValue.dateRange?.end) {
        const { start, end } = popupValue.dateRange;
        params.upload_date_start = dayjs(start).format("YYYY-MM-DD");
        params.upload_date_end = dayjs(end).format("YYYY-MM-DD");
      }

      if (popupValue.stock?.stock >= 0 && popupValue.stock?.condition?.value) {
        const { stock, condition } = popupValue.stock;
        params.product_stock_comparison_value = condition.value;

        params.product_stock_value = stock;
      }

      if (setPage) {
        setPage(1);
      }

      setFilterParam(params);
    },
    [searchValue, sortValue, tagValue, popupValue],
    1000
  );

  const dataShowcase = useMemo(
    () =>
      (data?.data || []).length > 0
        ? data?.data.map((showcase) => ({
            value: showcase.tag_store_id,
            label: showcase.tag?.tag_name || "-",
          }))
        : [
            {
              value: "",
              label: "Etalase kosong",
            },
          ],
    [data]
  );

  const filterCount = useMemo(() => {
    let count = 0;

    const {
      condition = [],
      category = [],
      courier = [],
      dateRange,
      priceRange,
      stock,
    } = popupValue;

    if (category.length !== 0) count++;

    if (condition.length !== 0) count++;

    if (courier.length !== 0) count++;

    if (dateRange?.start || dateRange?.end) count++;

    if (priceRange?.start || priceRange?.end) count++;

    if (stock?.stock !== "" && stock?.condition?.value) count++;

    return count;
  }, [popupValue]);

  useEffect(() => {
    setSearchValue("");
    setSortValue(null);
    setPopupValue(DEFAULT_POPUP);
    setTagValue(null);
    // eslint-disable-next-line
  }, [activeTab]);

  return (
    <Flex flexDirection="column" gap="16px">
      <Flex justifyContent="space-between" columnGap="16px">
        <StyledInput
          size="small"
          prefix={<Icon name="Search-outline" size={20} />}
          placeholder="Cari nama produk atau SKU"
          onChange={(event) => setSearchValue(event.target.value)}
          value={searchValue}
        />

        <FilterPopup
          value={popupValue}
          setValue={setPopupValue}
          filterCount={filterCount}
          defaultValue={DEFAULT_POPUP}
        />

        <StyledSelectInput
          options={dataShowcase}
          placeholder="Etalase"
          size="small"
          onChange={(event) =>
            setTagValue(
              event?.value && event?.value !== tagValue?.value ? event : null
            )
          }
          value={tagValue}
          isDisabled={isLoading}
        />

        <StyledSelectInput
          options={SORT_DATA}
          placeholder="Urutkan"
          size="small"
          onChange={(event) =>
            setSortValue(event?.value !== sortValue?.value ? event : null)
          }
          value={sortValue}
        />
      </Flex>
      {filterCount > 0 && (
        <FilterDetail
          value={popupValue}
          setValue={setPopupValue}
          defaultValue={DEFAULT_POPUP}
        />
      )}
    </Flex>
  );
};

export default Filter;
