import { useLogout } from "services/auth";
import { useHistory } from "react-router-dom";
import {
  Text,
  Avatar,
  Flex,
  Dropdown,
  Icon,
  Divider,
  get,
  Box,
  useDisclosure,
  Tooltip,
} from "renos-ui";
import { SDDownContent } from "./styled";
import { useContext, useMemo } from "react";
import { useEffect } from "react";
import NavbarContext from "providers/NavbarProvider";
// import Switch from "./Switch";
import { useGetStoreDetail } from "services/store";

const ProfileBox = () => {
  const disclosure = useDisclosure({ open: false });

  const { overlay, setOverlay } = useContext(NavbarContext);

  useEffect(() => {
    if (disclosure.isOpen) {
      setOverlay("profile");
    } else {
      setOverlay(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disclosure.isOpen]);

  const history = useHistory();
  const { mutate: onLogout } = useLogout();
  const { data: storeData } = useGetStoreDetail();

  const storeName = useMemo(
    () => get(storeData, "data.name", "-"),
    [storeData]
  );
  const userName = useMemo(
    () => get(storeData, "data.user.user_name", "-"),
    [storeData]
  );
  const defaultImg = "https://assets.renos.id/avatar.svg";

  const SrcImgProfile = useMemo(
    () => get(storeData, "data.user.user_photo_url") || defaultImg,
    [storeData]
  );

  return (
    <Box zIndex={overlay === "profile" && 10}>
      <Dropdown control={disclosure} trigger="hover">
        <Dropdown.Toggle>
          <Flex
            alignItems="center"
            justifyContent="start"
            flexDirection="row"
            width="280px"
            height="48px"
            borderRadius="8px"
            boxShadow="0px 4px 12px rgba(0, 0, 0, 0.05)"
            backgroundColor="white"
            borderWidth="1px"
            borderColor="black10"
            borderStyle="solid"
            gap="12px"
            cursor="pointer"
          >
            <Avatar marginLeft="16px" src={SrcImgProfile} />
            <Box maxWidth="200px" style={{ wordBreak: "break-all" }}>
              <Text weight="bold" lineClamp={1}>
                {userName}
              </Text>
            </Box>
          </Flex>
        </Dropdown.Toggle>
        <SDDownContent minWidth="344px !important" positionDropdown="right">
          <Flex
            flexDirection="column"
            gap="24px"
            marginTop="10px"
            padding="24px"
            backgroundColor="white"
            borderRadius="8px"
            boxShadow="0px 4px 12px rgba(0, 0, 0, 0.05)"
          >
            <Flex justifyContent="center">
              <Flex
                width="296px"
                height="88px"
                backgroundColor="black5"
                borderRadius="8px"
              >
                <Flex
                  flexDirection="row"
                  justifyContent="start"
                  alignItems="center"
                  gap="16px"
                >
                  <Avatar
                    width="48px"
                    height="48px"
                    marginLeft="16px"
                    src={SrcImgProfile}
                  />
                  <Flex flexDirection="column">
                    <Box maxWidth="200px" style={{ wordBreak: "break-all" }}>
                      <Tooltip placement="bottom-right" label={userName}>
                        <Text variant="subtitle" weight="bold" lineClamp={1}>
                          {userName}
                        </Text>
                      </Tooltip>
                    </Box>
                    <Flex
                      flexDirection="row"
                      gap="10px"
                      style={{ wordBreak: "break-all" }}
                    >
                      <Icon name="Store-outline" />
                      <Tooltip placement="bottom-right" label={storeName}>
<Flex width="calc(192px - 34px)">
                          <Text color="black50" lineClamp={1}>
                            {storeName}
                          </Text>
                        </Flex>
                      </Tooltip>
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>

            <Flex
              gap="8px"
              alignItems="center"
              cursor="pointer"
              onClick={() =>
                window.open(process.env.REACT_APP_BUYER_SITE, "_blank")
              }
            >
              <Icon name="Renos" color="black75" />
              <Text color="black75" variant="subtitle">
                Kembali ke Renos
              </Text>
            </Flex>

            <Flex
              gap="8px"
              alignItems="center"
              cursor="pointer"
              onClick={() => history.push("/setting")}
            >
              <Icon name="Setting-outline" />
              <Text color="black75" variant="subtitle">
                Pengaturan Akun
              </Text>
            </Flex>

            <Divider />
            {/* <Switch /> */}
            {/* <Divider /> */}

            <Flex
              gap="8px"
              alignItems="center"
              cursor="pointer"
              onClick={onLogout}
            >
              <Icon name="Logout-outline" />
              <Text color="black75" variant="subtitle" weight="bold">
                Keluar Akun
              </Text>
            </Flex>
          </Flex>
        </SDDownContent>
      </Dropdown>
    </Box>
  );
};

export default ProfileBox;
