import { colors, Dialog, Flex } from "renos-ui";
import styled from "styled-components";
import { FieldTextAreaInput } from "widgets/global/HookForm";

export const DragItemOverlay = styled(Flex)`
  display: none;
  transition: all 1s;
  background-color: ${colors.black100}70;
  border-radius: 8px;
`;

export const DragItem = styled(Flex)`
  &:hover ${DragItemOverlay} {
    display: flex;
  }
  &:hover #label-primary {
    display: none !important;
  }
`;

export const VideoPlayer = styled(Dialog)`
  div {
    top: -24px;
    right: -24px;
    svg {
      path {
        fill: white !important;
      }
    }
  }
`;

export const StyledFieldTextarea = styled(FieldTextAreaInput)`
  max-width: 550px;
  min-width: 550px;
`;
