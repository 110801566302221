import { Flex, Text } from "renos-ui";

const EmptyList = () => {
  return (
    <Flex
      gap="8px"
      width="100%"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      pt="64px"
    >
      <Flex
        as="img"
        width="160px"
        height="160px"
        alt="empty-stock-image"
        src="/assets/images/promo-management/empty-stock.svg"
      />

      <Flex gap="8px" flexDirection="column" alignItems="center">
        <Text weight="bold"> Belum ada produk</Text>
      </Flex>
    </Flex>
  );
};

export default EmptyList;
