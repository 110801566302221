import styled from "styled-components";
import { Box, Input } from "renos-ui";

export const Wrapper = styled(Box)`
  word-wrap: break-word;
`;

export const StyledTextArea = styled(Input.TextArea)`
  max-width: 400px;
  min-width: 400px;
`;
