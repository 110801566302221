import ENDPOINTS from "constants/endpoints";
import { goToUrl } from "helpers";
import { maskAllName } from "helpers/string-mask";
import { useMemo } from "react";
import { Box, Divider, Flex, Icon, Label, Text, useToast } from "renos-ui";

const OrderInfo = ({
  order_status_id: orderStatusId,
  order_status_name: orderStatusName,
  order_number: orderNumber,
  formatted_order_date: formattedOrderDate,
  formatted_order_expired_date: expiredDate,
  order_cancellation_reason: cancelReason,
  shipping,
  complaint_status: complaintStatus,
  order_type_id: orderTypeId,
  order_type_icon_url: orderTypeIconUrl,
  order_type_name: orderTypeName,
  order_id: orderId,
}) => {
  const toast = useToast();

  const handleCopy = (orderNo) => {
    navigator.clipboard.writeText(orderNo);

    toast({
      label: "Berhasil salin nomor order.",
      placement: "bottom",
    });
  };

  const handleMasking = (value) => {
    const notAllowedMask = [2, 3, 4, 5];

    return notAllowedMask.includes(orderStatusId) ? value : maskAllName(value);
  };

  const showInvoice = () => {
    goToUrl(
      `${process.env.REACT_APP_API}/${ENDPOINTS.order_bulk_invoice}?order_id=${orderId}`,
      "_blank"
    );
    toast({
      label: `Invoice ${orderNumber} berhasil dicetak`,
      placement: "bottom",
    });
  };

  const isShowTimerText = useMemo(() => {
    switch (orderStatusId) {
      case 2:
        return "Respon Sebelum";
      case 3:
        return "Kirim Sebelum";
      case 5:
        return "Selesai Otomatis";
      default:
        return "";
    }
  }, [orderStatusId]);

  const badgeColor = useMemo(() => {
    switch (orderStatusId) {
      case 2:
      case 3:
      case 4:
        return "yellow50";
      case 7:
        return "red50";
      case 5:
        return "blue50";
      case 6:
        return "black50";
      default:
        return "";
    }
  }, [orderStatusId]);

  const isShowTimer = useMemo(() => {
    let labelStatus = "";

    switch (orderStatusId) {
      case 2:
        labelStatus = "warning";
        break;
      case 3:
        labelStatus = "error";
        break;
      case 5:
        labelStatus = "gray";
        break;
      default:
        break;
    }

    if (labelStatus === "gray")
      return (
        <Label preffix={<Icon size={10} name="Time-outline" />}>
          {expiredDate}
        </Label>
      );

    if (labelStatus)
      return (
        <Label
          preffix={<Icon size={10} name="Time-outline" />}
          variant={labelStatus}
        >
          {expiredDate}
        </Label>
      );

    return "";
    // eslint-disable-next-line
  }, [orderStatusId]);

  const orderTypeColor = useMemo(() => {
    switch (orderTypeId) {
      case 1:
        return {
          text: "black50",
          background: "black5",
        };
      case 2:
        return {
          text: "blue50",
          background: "blue5",
        };
      default:
        return {};
    }
  }, [orderTypeId]);

  return (
    <Flex flexDirection="column">
      <Flex padding="16px" gap="16px" flexDirection="column">
        <Flex gap="8px" width="100%">
          <Box
            height="100%"
            width="4px"
            backgroundColor={badgeColor}
            borderRadius="8px"
          />
          <Flex flexDirection="column" gap="2px">
            <Flex gap="4px" alignItems="center">
              <Flex
                gap="2px"
                p="2px 4px"
                alignItems="center"
                backgroundColor={orderTypeColor?.background}
                borderRadius="4px"
              >
                <Flex
                  as="img"
                  alt="icon"
                  src={orderTypeIconUrl}
                  width="16px"
                  height="16px"
                />
                <Text
                  variant="small"
                  weight="bold"
                  color={orderTypeColor?.text}
                >
                  {orderTypeName}
                </Text>
              </Flex>
              <Text weight="bold">
                {orderStatusId === 8 && "Pesanan "}
                {orderStatusName}
              </Text>
            </Flex>

            {(complaintStatus || orderStatusId === 8) && (
              <Text
                variant="caption"
                color="black75"
                style={{ wordBreak: "break-all" }}
              >
                {complaintStatus || "Menunggu Direspon"}
              </Text>
            )}
            {cancelReason && (
              <Text
                variant="caption"
                color="black75"
                style={{ wordBreak: "break-all" }}
              >
                {cancelReason}
              </Text>
            )}
          </Flex>
        </Flex>

        <Divider />

        <Flex gap="8px" flexDirection="column">
          <Flex justifyContent="space-between" alignItems="center">
            <Flex gap="12px" alignItems="center">
              <Text variant="caption" color="blue50">
                {orderNumber}
              </Text>
              <Icon
                size={16}
                name="Copy-solid"
                color="blue50"
                onClick={() => handleCopy(orderNumber)}
              />
            </Flex>
            <Flex onClick={showInvoice}>
              <Text variant="caption" color="blue50">
                Lihat Invoice
              </Text>
            </Flex>
          </Flex>
          <Flex justifyContent="space-between" alignItems="center">
            <Text variant="caption" color="black50">
              Nama Pembeli
            </Text>
            <Text variant="caption" color="black75">
              {handleMasking(shipping.destination_pic_name)}
            </Text>
          </Flex>
          <Flex justifyContent="space-between" alignItems="center">
            <Text variant="caption" color="black50">
              Tanggal Pemesanan
            </Text>
            <Text variant="caption" color="black75">
              {formattedOrderDate}
            </Text>
          </Flex>
          {(isShowTimerText || isShowTimer) && (
            <Flex justifyContent="space-between" alignItems="center">
              <Text variant="caption" color="black50">
                {isShowTimerText}
              </Text>
              {isShowTimer}
            </Flex>
          )}
        </Flex>
      </Flex>
      <Divider height="8px !important" backgroundColor="black5" />
    </Flex>
  );
};

export default OrderInfo;
