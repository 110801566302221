import { useMemo, useState } from "react";
import { Flex, Icon, Text, useDisclosure, useToast } from "renos-ui";
import ProductPicker from "./ProductPicker";
import { StyledButton, StyledButtonIcon } from "./styled";
import { useUploadBanner } from "services/store-layout/banner";
import DeleteConfirmation from "./DeleteConfirmation";

const ProductLink = ({ value, setValue }) => {
  const toast = useToast();
  const { isOpen, onClose, onOpen } = useDisclosure({ isOpen: false });
  const {
    isOpen: isOpenDelete,
    onClose: onCloseDelete,
    onOpen: onOpenDelete,
  } = useDisclosure({ isOpen: false });
  const [showOverlay, setShowOverlay] = useState(false);

  const { mutate: deleteBanner, isLoading } = useUploadBanner({
    onSuccess: () => {
      setValue({
        ...value,
        product_id: null,
        etalase_id: null,
        product: null,
        etalase: null,
      });

      onCloseDelete();
      setShowOverlay(false);
    },
    onError: () => {
      toast({
        label: `Gagal menghapus link.`,
        placement: "top",
        backgroundColor: "red50",
      });
    },
  });

  const handleDelete = () => {
    const formData = new FormData();
    formData.append("product_id", null);
    formData.append("etalase_id", null);
    formData.append("image_type", value.mst_banner_image_type_id);

    if (value.id) formData.append("id", value.id);

    deleteBanner(formData);
  };

  const isLinkFilled = useMemo(
    () => value.product_id || value.etalase_id,
    [value]
  );

  const isShowOverlay = useMemo(
    () => isLinkFilled && showOverlay,
    [isLinkFilled, showOverlay]
  );

  const imageUrl = useMemo(
    () =>
      value.etalase_id
        ? value?.etalase?.tag_image_url
        : value?.product?.product_image_url,
    [value]
  );

  const cardTitle = useMemo(
    () =>
      value.etalase_id
        ? value?.etalase?.tag?.tag_name
        : value?.product?.product_name,
    [value]
  );

  const cardDescription = useMemo(
    () =>
      value.etalase_id
        ? `${value?.etalase?.total_products} produk`
        : value?.product?.formatted_product_price,
    [value]
  );

  return (
    <Flex
      borderRadius="8px"
      padding="16px"
      backgroundColor="black5"
      flexDirection="column"
      gap="4px"
      position="relative"
      onMouseOver={() => setShowOverlay(true)}
      onMouseOut={() => setShowOverlay(false)}
    >
      {isShowOverlay && (
        <Flex
          backgroundColor="overlay75"
          width="100%"
          height="120px"
          position="absolute"
          borderRadius="8px"
          alignItems="center"
          justifyContent="center"
          gap="6px"
          margin="-16px"
        >
          <StyledButton
            size="small"
            preffix={<Icon name="Edit-solid" size={16} color="white" />}
            onClick={() => {
              onOpen();
              setShowOverlay(false);
            }}
          >
            Ubah Link
          </StyledButton>
          <StyledButtonIcon
            onClick={onOpenDelete}
            size="small"
            icon={<Icon name="Trash-outline" size={20} color="white" />}
          />
        </Flex>
      )}

      <Flex justifyContent="space-between" alignItems="center">
        <Text color="black75" variant="caption" weight="bold">
          Link {value.etalase_id ? "Etalase" : "Produk"}
        </Text>
      </Flex>
      {isLinkFilled ? (
        <Flex
          padding="8px"
          borderRadius="8px"
          backgroundColor="white"
          gap="12px"
          alignItems="center"
        >
          <Flex
            as="img"
            src={imageUrl}
            height="48px"
            width="48px"
            borderRadius="4px"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = "/assets/images/empty-product.svg";
            }}
          />
          <Flex flexDirection="column" width="calc(100% - 60px)">
            <Text
              variant="caption"
              weight={value.etalase_id && "bold"}
              lineClamp={1}
            >
              {cardTitle}
            </Text>
            <Text
              weight={value.product_id && "bold"}
              variant={value.etalase_id ? "caption" : "body"}
            >
              {cardDescription}
            </Text>
          </Flex>
        </Flex>
      ) : (
        <Flex justifyContent="space-between" alignItems="center">
          <Text color="black75" variant="caption">
            Link banner dengan produkmu.
          </Text>
          <Flex
            cursor="pointer"
            onClick={() => {
              if (!value.image_path) {
                toast({
                  label: `Gambar Banner harus diisi dahulu.`,
                  placement: "top",
                  backgroundColor: "red50",
                });
              } else {
                onOpen();
              }

              setShowOverlay(false);
            }}
          >
            <Text color="blue50" variant="caption" weight="bold">
              Tambah Link
            </Text>
          </Flex>
        </Flex>
      )}

      <ProductPicker
        isOpen={isOpen}
        onClose={() => {
          onClose();
          setShowOverlay(false);
        }}
        setValue={setValue}
        value={value}
      />

      <DeleteConfirmation
        onClose={() => {
          onCloseDelete();
          onClose();
          setShowOverlay(false);
        }}
        isOpen={isOpenDelete}
        onReject={handleDelete}
        isLoading={isLoading}
      />
    </Flex>
  );
};

export default ProductLink;
