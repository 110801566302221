import { Flex, Icon, Spinner, Text } from "renos-ui";
import { useHistory } from "react-router-dom";
import desktopUrl from "constants/desktopUrl";

const Header = ({ isLoading }) => {
  const { push } = useHistory();

  return (
    <Flex
      gap="12px"
      padding="22px 23px 24px 23px"
      backgroundColor="white"
      borderTopLeftRadius="16px"
      borderTopRightRadius="16px"
      boxShadow="0px 4px 4px rgba(20, 20, 32, 0.05)"
      alignItems="center"
    >
      <Icon
        name="Back-outline"
        size={24}
        onClick={() => push(desktopUrl.VOUCHER)}
        cursor="pointer"
      />
      <Text variant="heading3" weight="bold">
        Voucher Toko
      </Text>
      {isLoading && <Spinner size="16px" color="black100" />}
    </Flex>
  );
};

export default Header;
