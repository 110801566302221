import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Flex, isEmpty, useToast } from "renos-ui";
import BottomBar from "./BottomBar";
import ProductInformation from "./ProductInformation";
import { yupResolver } from "@hookform/resolvers/yup";
import TopBar from "./components/TopBar";
import ProductAssets from "./ProductAssets";
import ProductDetail from "./ProductDetail";
import ProductVariant from "./ProductVariant";
import ProductShipping from "./ProductShipping";
import { productAddValidationSchema } from "helpers/validation-schema/product-add";
import { formValueToJson } from "helpers/attribute-mapper/product-add";
import { useGetProductDetail, useSaveProduct } from "services/product";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import useQueryParams from "hooks/useQueryParams";
import { useMemo } from "react";
import { useEffect } from "react";
import { setProductInitialValue } from "helpers/attribute-mapper/product-edit";

const ProductAdd = () => {
  const history = useHistory();
  const query = useQueryParams();
  const toast = useToast();

  const id = query.get("id");

  const { data: productDetail } = useGetProductDetail(id, {
    enabled: !!id,
  });

  const isEdit = useMemo(() => !isEmpty(productDetail), [productDetail]);

  const methods = useForm({
    mode: "onChange",
    defaultValues: {
      product_name: "",
      description: "",
      product_condition: "new",
      product_active: true,
      sku: [],
      product_sku: "",
      shipping_type: 1,
      product_shipping: new Set([]),
      insurance: 0,
      isPreorder: false,
      preorder: 0,
      price: 0,
      stock: 0,
      uom: 2,
      weight: 0,
      length: 0,
      width: 0,
      height: 0,
      variant: 0,
      preorder_type: 1,
      fromEmpty: false,
      product_attributes: [],
    },
    resolver: yupResolver(productAddValidationSchema(isEdit)),
  });

  useEffect(() => {
    if (!isEdit) return;

    setProductInitialValue(methods, productDetail, id);
  }, [isEdit, productDetail, methods, id]);

  const { isSubmitted } = methods.formState;

  const [isReset, setIsReset] = useState(false);

  const { mutate: saveProduct, isLoading } = useSaveProduct({
    onSuccess: (_) => {
      toast({
        label: isEdit
          ? "Produk kamu berhasil diedit!"
          : "Produk kamu berhasil ditambahkan!",
        placement: "top",
      });

      setTimeout(() => {
        if (isReset) {
          if (isEdit) {
            history.push("/products/add");
          }
          location.reload();
        } else {
          history.push("/products/list");
        }
      }, 1500);
    },
    onError: (data) => {
      if (data?.response.status === 400) {
        toast({
          label: "Nama produk sudah tersedia!",
          placement: "top",
        });
      }
    },
  });

  const submitHandler = (values) => {
    // handling stock should be 0 on product single
    if (
      values.sku.length === 0 &&
      values.stock === 0 &&
      isEdit &&
      values.product_active === true
    ) {
      toast({
        label:
          "Stok produk harus lebih dari 0 jika status SKU/Produk nya Aktif.",
        placement: "top",
      });

      return;
    }

    // handling on add variant should be not empty
    if (values.variant === 1 && isEmpty(values.sku)) {
      toast({
        label: "Product variant tidak boleh kosong",
        placement: "top",
      });

      return;
    }

    // handling default variant should active on edit
    if (isEdit && values.sku.length > 0 && values.variant === 1) {
      const defaultSkuIndex = values.sku.findIndex(
        (data) => data.default === 1
      );
      const activeSkuIndex = values.sku.findIndex((data) => data.status === 1);

      if (
        values.sku[defaultSkuIndex].status === 2 &&
        defaultSkuIndex !== activeSkuIndex &&
        activeSkuIndex >= 0
      ) {
        toast({
          label: "Pastikan varian aktif merupakan varian utama",
          placement: "top",
        });

        return;
      }
    }

    // handling active variant should have more than 0 stock
    if (isEdit && values.sku.length > 0 && values.variant === 1) {
      const activeSkuwithZeroStock = values.sku.find(
        (data) => data.status === 1 && data.stock === 0
      );

      if (activeSkuwithZeroStock) {
        toast({
          label:
            "Stok produk harus lebih dari 0 jika status SKU/Produk nya Aktif.",
          placement: "top",
        });

        return;
      }
    }

    //handling numeric product_attributes cant change 0
    values.product_attributes = values.product_attributes.map((attribute) => ({
      ...attribute,
      product_attribute_value: attribute.product_attribute_value || "",
    }));

    const payload = formValueToJson(values, isEdit);

    saveProduct(payload);
  };

  return (
    <FormProvider {...methods}>
      <Flex
        as="form"
        onSubmit={methods.handleSubmit(submitHandler)}
        width="952px"
        flexDirection="column"
        rowGap="16px"
      >
        <TopBar isEdit={isEdit} />
        <ProductInformation methods={methods} />
        <ProductAssets methods={methods} isSubmitted={isSubmitted} />
        <ProductDetail methods={methods} />
        <ProductVariant
          methods={methods}
          productDetail={productDetail}
          isEdit={isEdit}
        />
        <ProductShipping methods={methods} />
        <BottomBar isLoading={isLoading} setIsReset={setIsReset} />
      </Flex>
    </FormProvider>
  );
};

export default ProductAdd;
