import { useMemo } from "react";
import { Flex, Icon, Label, Text } from "renos-ui";
import ConfirmRejectNewOrder from "./component/ConfirmRejectNewOrder";
import SendOrder from "./component/SendOrder";
import OnDelivery from "./component/OnDelivery";

const Footer = ({ data, toFirstPage }) => {
  const {
    order_status_id: orderStatusId,
    formatted_order_expired_date: orderExpired,
    store_order_shipment: storeOrderShipment,
  } = data;

  const isShowTimerText = useMemo(() => {
    switch (orderStatusId) {
      case 2:
        return "Respon Sebelum";
      case 3:
        return "Kirim Sebelum";
      case 5:
        return "Selesai Otomatis";
      default:
        return "";
    }
    // eslint-disable-next-line
  }, [data]);

  const isShowTimer = useMemo(() => {
    let labelStatus = "";

    switch (orderStatusId) {
      case 2:
        labelStatus = "warning";
        break;
      case 3:
        labelStatus = "error";
        break;
      case 5:
        labelStatus = "gray";
        break;
      default:
        break;
    }

    if (labelStatus === "gray")
      return (
        <Label preffix={<Icon size={10} name="Time-outline" />}>
          {orderExpired}
        </Label>
      );

    if (labelStatus)
      return (
        <Label
          preffix={<Icon size={10} name="Time-outline" />}
          variant={labelStatus}
        >
          {orderExpired}
        </Label>
      );

    return "";
    // eslint-disable-next-line
  }, [data]);

  const isHasButton = useMemo(() => {
    switch (orderStatusId) {
      case 2:
        return <ConfirmRejectNewOrder data={data} toFirstPage={toFirstPage} />;
      case 3:
        return <SendOrder data={data} toFirstPage={toFirstPage} />;
      case 4:
        return <OnDelivery data={data} toFirstPage={toFirstPage} />;
      default:
        return "";
    }
    // eslint-disable-next-line
  }, [data]);

  return (
    <Flex justifyContent="space-between" alignItems="center">
      {storeOrderShipment?.retry_booking_possible ? (
        <Label variant="error">Gagal Pickup</Label>
      ) : (
        <Flex flexDirection="column" gap="4px">
          <Text color="black50" variant="small">
            {isShowTimerText}
          </Text>
          {isShowTimer}
        </Flex>
      )}

      {isHasButton}
    </Flex>
  );
};

export default Footer;
