/* eslint-disable no-useless-escape */
import { useEffect } from "react";
import { useRef } from "react";
import { numberInputChecking, pick, range } from "renos-ui";
import imageCompression from "browser-image-compression";
import dayjs from "dayjs";
import { csPhoneNumber } from "constants/common";

export const wordSplitter = (string) => string.split(" ");

export const wordCounter = (string) => string.split(" ").length;

export const wordSlicer = (string, numSlice) =>
  wordSplitter(string).slice(0, numSlice).join(" ");

export const focusTo = (id) => {
  const element = document.getElementById(id);

  if (element) {
    element.focus();
    element.scrollIntoView({
      block: "center",
      behavior: "smooth",
    });
  }
};

export const goToUrl = (url, target = "_blank") => {
  window.open(url, target).focus();
};

export const getRangeWeek = (dateVal) => {
  const first = dateVal.getDate() - dateVal.getDay() + 1;
  const last = new Date(dateVal.setDate(first + 6));

  return [new Date(dateVal.setDate(first)), last];
};

export const translateDay = (day) => {
  switch (day) {
    case "SUNDAY":
      return "Minggu";
    case "MONDAY":
      return "Senin";
    case "TUESDAY":
      return "Selasa";
    case "WEDNESDAY":
      return "Rabu";
    case "THURSDAY":
      return "Kamis";
    case "FRIDAY":
      return "Jumat";
    case "SATURDAY":
      return "Sabtu";
    default:
      break;
  }
};

export const formatTime = (string) => {
  const [hour, minute] = string.split(":");
  const time = `${hour}:${minute}`;

  return time;
};

export const formatTime2 = (number) => {
  let hour;

  if (number < 10) {
    hour = `0${number}:00`;
  } else {
    hour = `${number}:00`;
  }

  return hour;
};

export const bouncer = (array) => {
  return array.filter((value) => {
    return !!value;
  });
};

export const pickAndSplitProductStatus = (product) => {
  const skus = [];

  product?.product_skus?.forEach((sku) => {
    const pickedProduct = pick(sku, [
      "product_sku_id",
      "product_status_id",
      "product_stock",
      "product_sku_number",
      "product_price",
      "price",
      "is_default",
    ]);

    pickedProduct.product_sku_id =
      pickedProduct.product_sku_id === "-"
        ? null
        : pickedProduct.product_sku_id;

    pickedProduct.product_sku_number =
      pickedProduct.product_sku_number === "-"
        ? null
        : pickedProduct.product_sku_number;

    pickedProduct.product_status_id =
      pickedProduct.product_status_id === 1 || pickedProduct.product_stock === 0
        ? 2
        : 1;

    skus.push(pickedProduct);
  });

  return skus;
};

export const youtubeLinkValidator = (url) => {
  const regExp =
    /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;

  if (url.match(regExp)) {
    return true;
  }

  return false;
};

export const getYoutubeId = (url) => {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  const match = url?.match(regExp);

  return match && match[2].length === 11 ? match[2] : null;
};

export const blobToBase64 = (blob) => {
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
};

export const imageTypeChecking = (blob) => {
  return new Promise((resolve, _) => {
    const fileReader = new FileReader();
    fileReader.readAsArrayBuffer(blob);

    fileReader.onloadend = (e) => {
      const arr = new Uint8Array(fileReader.result).subarray(0, 4);
      let header = "";

      for (let i = 0; i < arr.length; i++) {
        header += arr[i].toString(16);
      }

      switch (header) {
        case "89504e47": //"image/png";
        case "ffd8ffe0": //"image/jpeg";
        case "ffd8ffe1": //"image/jpeg";
        case "ffd8ffe2": //"image/jpeg";
        case "ffd8ffe3": //"image/jpeg";
        case "ffd8ffe8": //"image/jpeg";
          resolve(true);
          break;

        case "47494638":
          // type = "image/gif";
          resolve(false);
          break;

        default:
          resolve(false);
          break;
      }
    };
  });
};

export const imageTypeCheckingDocument = (blob) => {
  return new Promise((resolve, _) => {
    const fileReader = new FileReader();
    fileReader.readAsArrayBuffer(blob);

    fileReader.onloadend = (e) => {
      const arr = new Uint8Array(fileReader.result).subarray(0, 4);
      let header = "";

      for (let i = 0; i < arr.length; i++) {
        header += arr[i].toString(16);
      }

      switch (header) {
        case "89504e47": //"image/png";
        case "ffd8ffe0": //"image/jpeg";
        case "ffd8ffe1": //"image/jpeg";
        case "ffd8ffe2": //"image/jpeg";
        case "ffd8ffe3": //"image/jpeg";
        case "ffd8ffe8": //"image/jpeg";
        case "25504446": //"application/pdf"
          resolve(true);
          break;

        case "47494638":
          // type = "image/gif";
          resolve(false);
          break;

        default:
          resolve(false);
          break;
      }
    };
  });
};

export const getImageMeta = (url) =>
  new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve(img);
    img.onerror = (err) => reject(err);
    img.src = url;
  });

export const reactDndReorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const generateStoreLayoutTemplate = ({
  min_amount,
  mst_banner_type_id,
  name,
}) => {
  return range(min_amount).map(() => ({
    image_path: "",
    mst_banner_image_type_id: mst_banner_type_id,
    mst_banner_image_type_name: name,
  }));
};

export const imageAssetsLoader = (path) => {
  return `${process.env.REACT_APP_ASSETS_IMAGE}/${path}`;
};

export const isObjectEqual = (x, y) => {
  const ok = Object.keys,
    tx = typeof x,
    ty = typeof y;

  return x && y && tx === "object" && tx === ty
    ? ok(x).length === ok(y).length &&
        ok(x).every((key) => isObjectEqual(x[key], y[key]))
    : x === y;
};

export const arrMonth = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "Mei",
  "Jun",
  "Jul",
  "Agu",
  "Sep",
  "Okt",
  "Nov",
  "Des",
];

export const arrFullMonth = [
  "Januari",
  "Februari",
  "Maret",
  "April",
  "Mei",
  "Juni",
  "Juli",
  "Agustus",
  "September",
  "Oktober",
  "November",
  "Desember",
];

const arrDay = ["Minggu", "Senin", "Selasa", "Rabu", "Kamis", "Jumat", "Sabtu"];

export const dateWithDayFormat = (date, isFullMonth) => {
  if (date) {
    const list = date.split("-");

    const d = new Date(date);
    d.setHours(0, 0, 0, 0);
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const day =
      d.toDateString() === today.toDateString()
        ? "Hari ini"
        : arrDay[d.getDay()];

    if (isFullMonth) {
      return `${day} , ${d.getDate()} ${arrFullMonth[parseInt(list[1]) - 1]} ${
        list[0]
      } `;
    } else {
      return `${day} , ${d.getDate()} ${arrMonth[parseInt(list[1]) - 1]} ${
        list[0]
      } `;
    }
  } else return "";
};

export const getUniqueListBy = (arr, key) => {
  return [...new Map(arr.map((item) => [item[key], item])).values()];
};

export function isArrayEqual(a1, a2) {
  /* WARNING: arrays must not contain {objects} or behavior may be undefined */
  return JSON.stringify(a1) === JSON.stringify(a2);
}

export const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
};

export const truncate = (string = "", maxLength = 10) => {
  if (string.length > maxLength) {
    return `${string.substring(0, maxLength)}...`;
  } else {
    return string;
  }
};

export const encodeUrl = (dataUrl) => {
  const paths = decodeURI(dataUrl).split("/");
  const path = paths.slice(0, paths.length - 1).join("/");
  const fileName = encodeURIComponent(paths.at(-1) || "");

  return `${path}/${fileName}`;
};

export const checkImageType = (blob) => {
  return new Promise((resolve, _) => {
    const fileReader = new FileReader();
    fileReader.readAsArrayBuffer(blob);

    fileReader.onloadend = (e) => {
      const arr = new Uint8Array(fileReader.result).subarray(0, 4);
      let header = "";

      for (let i = 0; i < arr.length; i++) {
        header += arr[i].toString(16);
      }

      switch (header) {
        case "89504e47": //"image/png";
        case "ffd8ffe0": //"image/jpeg";
        case "ffd8ffe1": //"image/jpeg";
        case "ffd8ffe2": //"image/jpeg";
        case "ffd8ffe3": //"image/jpeg";
        case "ffd8ffe8": //"image/jpeg";
          resolve(true);
          break;

        case "47494638": // type = "image/gif";
        case "49492a0": /* tif */
        case "424d8a7b": /* bmp */
        case "000c": /* jpeg2000 */
        case "0100": /* ttf */
        case "25504446": /* pdf */
        case "54686573": /* text */
        case "504b34": /* zip, xlsx, app, pptx */
        case "3c3f7068": /* php */
        case "696d706f": /* js */
        case "2d2d2d2d": /* pem */
        case "00018": /* heic, mp4 */
        case "38425053": /* psd */
        case "4f54544f": /* otf */
        case "7ba2020": /* json */
        case "23205448": /* lock */
        case "3c21444f": /* html */
        case "23204062": /* md */
        case "2a207ba": /* scss */
        case "2f2f2066": /* rc */
        case "3c737667": /* svg */
        case "55736572": /* txt */
        case "52494646" /* webp */:
          resolve(false);
          break;

        default:
          resolve(false);
          break;
      }
    };
  });
};

export const setGTM = (event, params) => {
  window?.dataLayer?.push({
    event,
    ...params,
  });
};

export const imgCompressor = async (file, options) => {
  const compressed = await imageCompression(file, options);

  return new File([compressed], "compressed.jpg", { type: "image/png" });
};

export const getTime = (dateTime) => {
  const fullDate = dayjs(dateTime);

  return fullDate.format("HH.mm");
};

export const getDayAsHumane = (days) => {
  let result;

  if (typeof days === "string") {
    result = translateDay(days);
  } else {
    result = `${translateDay(days[0])} - ${translateDay(
      days[days.length - 1]
    )}`;
  }

  return result;
};

export const getTimeAsHumane = (time) => {
  let result;
  const fullDay = "00:00 - 23:00";

  if (time === fullDay) {
    result = "Buka 24 Jam";
  } else {
    result = time;
  }

  return result;
};

export const goToBuyerPage = () => {
  window.open(`${process.env.REACT_APP_BUYER_SITE}`, "_self");
};

export const goToCustomerService = () => {
  goToUrl(`https://wa.me/${csPhoneNumber}`, "_blank");
};

export const setParamQuery = (obj) => {
  const arr = Object.entries(obj);
  let query = "";

  for (let i = 0; i < arr.length; i++) {
    query = query + `${i === 0 ? "?" : "&"}${arr[i][0]}=${arr[i][1]}`;
  }

  return query;
};

export const getArrYears = (paramStartYear, paramEndYear) => {
  const years = [];
  const startYear = paramStartYear || 1975;
  const endYears = paramEndYear || dayjs().add(10, "years").year();

  for (let i = startYear; i <= endYears; i++) {
    years.push(i);
  }

  return years;
};

export const getArrDate = (month, year) => {
  const date = [];
  const daysInMonth = dayjs(`${year}-${month}`).daysInMonth();

  for (let i = 1; i <= daysInMonth; i++) {
    date.push(i);
  }

  return date;
};

export const currencyFormatter = (number) => {
  return numberInputChecking(number ? number.toString() : "0", 0)
    .formattedValue;
};

export const numberFormatter = (number = 0) => {
  return numberInputChecking(number ? number.toString() : "0", 0).parsedValue;
};

export const getTimestamp = () => {
  return new Date().getTime()?.toString();
};

export const abbreviationNum = (value) => {
  const decPlaces = Math.pow(10, 1);
  const abbrev = ["rb", "jt"];
  let number = value;

  for (let i = abbrev.length - 1; i >= 0; i--) {
    const size = Math.pow(10, (i + 1) * 3);

    if (size <= number) {
      number = Math.floor((number * decPlaces) / size).toFixed(0) / decPlaces;

      if (number === 1000 && i < abbrev.length - 1) {
        number = 1;
        i++;
      }

      number += abbrev[i];
      break;
    }
  }

  return String(number).split(".").join(",");
};

export const getPercentage = (value, total) => {
  return (value / total) * 100;
};

export const checkWhitespace = (str) => {
  return str === null || str.match(/^ *$/) !== null;
};

export const limitDotConverter = (value = "", limitValue) => {
  //handle dot in currency format
  const countDot = currencyFormatter(value).split(".").length - 1;

  return limitValue && limitValue > 0 ? limitValue + countDot : null;
};
