export const getPercentage = (newValue, productPrice) => {
  return (
    100 - Math.ceil(((parseInt(newValue) * 100) / productPrice).toFixed(2)) ||
    "0"
  );
};

export const getDiscountAmount = (newValue, productPrice) => {
  return (
    productPrice -
      Math.ceil((parseInt(newValue) * 0.01 * productPrice).toFixed(2)) || "0"
  );
};

export const handleChangeInput = (newValue, propertyName, value, setValue) => {
  const tempValue = value;

  if (propertyName === "date") {
    tempValue.date_start = new Date(newValue.product_discount_date_start);
    tempValue.date_end = new Date(newValue.product_discount_date_end);
  } else {
    tempValue[propertyName] = newValue;
  }

  tempValue[`${propertyName}Active`] = true;
  setValue({ ...tempValue });
};

export const dataApplyFormatter = ({ selected, defaultData, value }) => {
  const arrIdSelected = selected.map((product) => product.product_id);

  return defaultData.map((product) => {
    if (arrIdSelected.includes(product.product_id)) {
      let tempProduct = selected.filter(
        (filterProduct) => filterProduct.product_id === product.product_id
      )[0];

      if (product.isHasMore) {
        tempProduct = {
          ...tempProduct,
          product_skus: tempProduct.product_skus.map((sku, indexSKU) => {
            if (sku.isChecked) {
              const formattedAmount =
                value.amount < sku.product_price
                  ? value.amount
                  : sku.product_price;
              const tempSKU = {
                ...sku,
                product_discount_amount: formattedAmount,
                product_discount_percentage: value.percentage,
                product_discount_date_start: value.date_start
                  ? new Date(value.date_start)
                  : "",
                product_discount_date_end: value.date_end
                  ? new Date(value.date_end)
                  : "",
                product_discount_quota: value.quota,
                isActive: value.isActive,
              };

              if (value.amount) {
                tempSKU.product_discount_percentage = getPercentage(
                  formattedAmount,
                  sku.product_price
                );
              }

              if (value.percentage) {
                tempSKU.product_discount_amount = getDiscountAmount(
                  value.percentage,
                  sku.product_price
                );
              }

              return { ...tempSKU };
            }

            return { ...product.product_skus[indexSKU] };
          }),
        };

        return tempProduct;
      }

      const formattedAmount =
        value.amount < product.product_price_start
          ? value.amount
          : product.product_price_start;
      tempProduct = {
        ...tempProduct,
        product_discount_amount_start: formattedAmount,
        product_discount_percentage_start: value.percentage,
        product_discount_date_start: value.date_start
          ? new Date(value.date_start)
          : "",
        product_discount_date_end: value.date_end
          ? new Date(value.date_end)
          : "",
        product_discount_quota: value.quota,
      };

      if (value.amount) {
        tempProduct.product_discount_percentage_start = getPercentage(
          formattedAmount,
          product.product_price_start
        );
      }

      if (value.percentage) {
        tempProduct.product_discount_amount_start = getDiscountAmount(
          value.percentage,
          product.product_price_start
        );
      }

      return tempProduct;
    }

    return product;
  });
};
