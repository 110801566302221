import { setGTM } from "helpers";
import {
  Flex,
  Text,
  colors,
  Checkbox,
  Box,
  range,
  Skeleton,
  Grid,
} from "renos-ui";
import FailLoadData from "widgets/global/FailLoadData";

const CourierCard = ({ list, setList, isLoading, isError, refetch }) => {
  const handleCheckbox = (listIndex, serviceIndex) => {
    const tempList = list;
    setGTM("seller_center_section", {
      event_category: "store_settings | delivery",
      event_action: tempList[listIndex].services[serviceIndex]
        .assigned_to_user_store
        ? "uncheck"
        : "check",
      event_label: `${tempList[listIndex].name} | ${tempList[listIndex].services[serviceIndex].name}`,
    });

    tempList[listIndex].services[serviceIndex].changed = true;
    tempList[listIndex].services[serviceIndex].assigned_to_user_store =
      !tempList[listIndex].services[serviceIndex].assigned_to_user_store;
    setList([...tempList]);
  };

  return (
    <Grid
      gridTemplateColumns="25% 25% 25% 25%"
      padding="24px 0"
      border={`1px solid ${colors.black10}`}
      borderRadius="8px"
    >
      {/* <Flex
        padding="24px 0"
        border={`1px solid ${colors.black10}`}
        borderRadius="8px"
      > */}
      {isError ? (
        <FailLoadData onClick={refetch} isLoading={isLoading} />
      ) : isLoading ? (
        range(4).map((_, index) => {
          return (
            <Flex
              key={`service-skeleton${index}`}
              gap="24px"
              px="24px"
              flexDirection="column"
              borderRight={
                (index + 1) % 4 === 0 ? "none" : `1px solid ${colors.black10}`
              }
              height="268px"
            >
              <Skeleton height="52px" width="104px" />
              <Flex gap="12px" flexDirection="column">
                {range(5).map((__, index2) => {
                  return (
                    <Flex
                      gap="8px"
                      alignItems="center"
                      key={`service-skeleton${index}-${index2}`}
                    >
                      <Skeleton height="20px" width="22px" />
                      <Skeleton height="20px" />
                    </Flex>
                  );
                })}
              </Flex>
            </Flex>
          );
        })
      ) : (
        list.map((courier, index) => {
          return (
            <Flex
              key={`courier-${index}`}
              gap="24px"
              px="24px"
              flexDirection="column"
              borderRight={
                (index + 1) % 4 === 0 ? "none" : `1px solid ${colors.black10}`
              }
            >
              {!courier.fakeCard && (
                <Flex justifyContent="center">
                  <Box
                    as="img"
                    src={courier.image_url}
                    maxWidth="104px"
                    alt="courier-logo"
                  />
                </Flex>
              )}
              <Flex gap="12px" flexDirection="column">
                {courier.services.map((services, serviceIndex) => {
                  return (
                    <Flex
                      gap="8px"
                      alignItems="center"
                      key={`courier-service-${index}${serviceIndex}`}
                    >
                      <Checkbox
                        checked={services.assigned_to_user_store}
                        onChange={() => handleCheckbox(index, serviceIndex)}
                      />
                      <Text
                        variant="body"
                        color="black75"
                        width="100%"
                        lineClamp={1}
                      >
                        {services.name}
                      </Text>
                    </Flex>
                  );
                })}
              </Flex>
            </Flex>
          );
        })
      )}
      {/* </Flex> */}
    </Grid>
  );
};

export default CourierCard;
