import React, { useEffect, useState } from "react";
import { Box, Flex, Icon, Input, SelectInput } from "renos-ui";

export const FilterProduct = ({ setFilterParams, setSearchValue, setData }) => {
  const [filterValue, setFilterValue] = useState({});
  const [orderValue, setOrderValue] = useState({});

  const initalValue = {
    ordering: "asc",
    order_by: "",
    product_status_ids: 1,
    page: 1,
    limit: 10,
  };

  const optionsFilter = [
    {
      value: { ...initalValue },
      label: "Semua",
    },
    {
      value: { product_condition_ids: 1 },
      label: "Baru",
    },
    {
      value: { product_condition_ids: 2 },
      label: "Bekas",
    },
    {
      value: { product_stock_comparison_value: "eq", product_stock_value: 0 },
      label: "Stok Kosong",
    },
    {
      value: { product_variant_only: 1 },
      label: "Dengan Varian",
    },
  ];

  const optionsOrder = [
    {
      value: { order_by: "total_sold", ordering: "asc" },
      label: "Terlaris",
    },
    {
      value: { ordering: "asc", order_by: "product_name" },
      label: "Nama A - Z",
    },
    {
      value: { ordering: "desc", order_by: "product_name" },
      label: "Nama Z - A",
    },
    {
      value: { ordering: "desc", order_by: "product_price" },
      label: "Harga Tertinggi",
    },
    {
      value: { ordering: "asc", order_by: "product_price" },
      label: "Harga Terendah",
    },
    {
      value: { ordering: "asc", order_by: "product_stock" },
      label: "Stok Tersedikit",
    },
    {
      value: { ordering: "desc", order_by: "product_stock" },
      label: "Stok Terbanyak",
    },
  ];

  useEffect(() => {
    setData([]);
    setFilterParams({ ...initalValue, ...filterValue, ...orderValue });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterValue, orderValue]);

  return (
    <Flex gap="16px" alignItems="center">
      <Box minWidth="280px">
        <Input
          placeholder="Cari nama produk atau SKU"
          prefix={<Icon name="Search-outline" />}
          onChange={({ currentTarget }) => setSearchValue(currentTarget.value)}
        />
      </Box>
      <Box width="100%">
        <SelectInput
          placeholder="Filter"
          options={optionsFilter.map((item) => ({
            value: item.value,
            label: item.label,
          }))}
          onChange={({ value }) => {
            setFilterValue(value);
          }}
        />
      </Box>
      <Box width="100%">
        <SelectInput
          options={optionsOrder.map((item) => ({
            value: item.value,
            label: item.label,
          }))}
          onChange={({ value }) => {
            setOrderValue(value);
          }}
          placeholder="Urutkan"
        />
      </Box>
    </Flex>
  );
};
