import desktopUrl from "constants/desktopUrl";
import mobileUrl from "constants/mobileUrl";
import { setGTM } from "helpers";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Badge, Flex as Box, Flex, Icon, Text, isEmpty } from "renos-ui";
import { useCheckStore } from "services/auth";
import { useGetChatCount } from "services/chat";
import { useGetNotificationCounting } from "services/notification";
import DrawerStoreClosed from "widgets/mobile/DrawerStoreClosed";

const navigations = [
  {
    name: "Home",
    path: "/",
    icon: "Home",
  },
  {
    name: "Produk",
    path: desktopUrl.PRODUCTS_LIST,
    icon: "Product",
  },
  // {
  //   name: "Promosi",
  //   path: mobileUrl.DISCOUNT,
  //   icon: "Voucher",
  // },
  {
    name: "Chat",
    path: mobileUrl.CHAT,
    icon: "Chat",
  },
  {
    name: "Pesanan",
    path: desktopUrl.ORDERS_LIST,
    icon: "Transaction",
  },
  {
    name: "Akun",
    path: "/account",
    icon: "Profile",
  },
];

const MobileLayout = ({
  children,
  header,
  customHeader,
  footer,
  noBorder,
  ...restProps
}) => {
  const { goBack, push } = useHistory();
  const location = useLocation();

  const { data: storeData, isLoading } = useCheckStore();

  const { data: totalNotification } = useGetNotificationCounting();

  const { data: chatCount } = useGetChatCount(
    {},
    { enabled: !location.pathname.includes("/products/add") }
  );

  return (
    <Box
      width="100vw"
      height="100dvh"
      flexDirection="column"
      justifyContent={header && footer ? "space-between" : "start"}
      {...restProps}
    >
      {customHeader ||
        (!isEmpty(header) && (
          <Box
            height="fit-content"
            p="16px"
            flexDirection="column"
            rowGap="16px"
            boxShadow={noBorder ? "none" : "0px 0px 8px rgba(20, 20, 32, 0.1)"}
          >
            <Box justifyContent="space-between">
              <Box columnGap="16px" alignItems="center">
                {header?.withBack && (
                  <Icon
                    cursor="pointer"
                    onClick={() => {
                      header?.onBack?.();

                      if (!header?.withoutGoBack) goBack();
                    }}
                    name={header.backIconName || "Back-outline"}
                    color="black50"
                    size={24}
                  />
                )}
                <Text
                  weight="bold"
                  color="black100"
                  variant="subtitle"
                  lineClamp="1"
                  width={`calc(100vw - ${header?.withBack ? "132px" : "92px"})`}
                >
                  {header?.title}
                </Text>
              </Box>
              {header?.isHomepage && (
                <Flex columnGap="16px">
                  {header?.suffix || (
                    <>
                      <Flex display="none">
                        <Icon name="Share-outline" cursor="pointer" />
                      </Flex>
                      <Badge
                        count={totalNotification?.total_unread}
                        cursor="pointer"
                      >
                        <Icon
                          name="Notification-outline"
                          onClick={() => push(mobileUrl.NOTIFICATION)}
                        />
                      </Badge>
                    </>
                  )}
                </Flex>
              )}
            </Box>
            {header?.additional}
          </Box>
        ))}
      <Box flex={1} overflow="auto">
        {children}
      </Box>
      {!isEmpty(footer) && (
        <Box
          height="fit-content"
          py="16px"
          px="24px"
          rowGap="16px"
          className="welcome-step-mobile"
          boxShadow="0px 0px 8px rgba(20, 20, 32, 0.1)"
          justifyContent="space-between"
        >
          {navigations.map((navigation) => {
            const isActive = navigation.path === location.pathname;

            return (
              <Box
                flexDirection="column"
                alignItems="center"
                rowGap="4px"
                key={navigation.name}
                cursor="pointer"
                onClick={() => {
                  push(navigation.path);

                  if (navigation.path === desktopUrl.PRODUCTS_LIST)
                    setGTM("seller_center_section", {
                      event_category: "menu",
                      event_action: "click",
                      event_label: "my_product",
                    });
                }}
              >
                {navigation.name === "Chat" ? (
                  <Badge count={chatCount || 0} cursor="pointer">
                    <Icon
                      name={`${navigation.icon}-${
                        isActive ? "solid" : "outline"
                      }`}
                      color={isActive ? "blue50" : "black50"}
                    />
                  </Badge>
                ) : (
                  <Icon
                    name={`${navigation.icon}-${
                      isActive ? "solid" : "outline"
                    }`}
                    color={isActive ? "blue50" : "black50"}
                  />
                )}

                <Text
                  variant="small"
                  color={isActive ? "blue50" : "black50"}
                  weight={isActive ? "bold" : "regular"}
                >
                  {navigation.name}
                </Text>
              </Box>
            );
          })}
        </Box>
      )}

      <DrawerStoreClosed
        isOpen={!isLoading && storeData && !storeData?.data?.active}
      />
    </Box>
  );
};

export default MobileLayout;
