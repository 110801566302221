import React from "react";
import { Button, Flex, Text, Dialog, Spinner } from "renos-ui";

const DeleteModal = ({ deleteModal, handleOkDelete, isDeleteLoading }) => {
  return (
    <Dialog
      isVisible={deleteModal.isOpen}
      onClose={deleteModal.onClose}
      verticalCentered
      width="300px"
      minHeight="100px"
    >
      <Flex flexDirection="column" width="100%" gap="24px">
        <Flex flexDirection="column" gap="8px">
          <Text variant="heading3" weight="bold">
            Hapus Template ini?
          </Text>
          <Text>Pastikan kamu sudah memilih template yang benar yaa!</Text>
        </Flex>
        <Flex justifyContent="space-between" gap="16px">
          <Button
            isBlock
            variant="tertiary"
            onClick={handleOkDelete}
            preffix={isDeleteLoading && <Spinner size="16px" color="blue10" />}
          >
            Hapus
          </Button>
          <Button isBlock onClick={deleteModal.onClose}>
            Kembali
          </Button>
        </Flex>
      </Flex>
    </Dialog>
  );
};

export default DeleteModal;
