import { useState, useContext } from "react";
import StoreContext from "providers/StoreProvider";
import {
  Box,
  Button,
  Flex,
  Spinner,
  Text,
  get,
  useToast,
  useDisclosure,
} from "renos-ui";
import {
  useGetNotes,
  useDeleteNotes,
  useSaveNotes,
} from "services/settings/note";

import EmptyNote from "./component/EmptyNote";
import DeleteModal from "./component/DeleteModal";
import PreviewModal from "./component/PreviewModal";
import AddModal from "./component/AddModal";
import Items from "./component/Items";

import { setGTM } from "helpers";

const Note = () => {
  const {
    storeData: {
      data: { id: storeId },
    },
  } = useContext(StoreContext);

  const {
    data: dataGetNotes,
    isLoading: isLoadingGetNotes,
    refetch: refetchGetNotes,
  } = useGetNotes({
    store_id: storeId,
    is_active: true,
  });

  const noteList = get(dataGetNotes, "data", []);

  const { mutate: mutateSaveNotes, isLoading: isSaveLoading } = useSaveNotes({
    onError: (data) => {
      const {
        response: {
          data: { frontend_message, message },
        },
      } = data;

      toast({
        label:
          frontend_message ||
          message ||
          `Catatan gagal ${mode === "add" ? "ditambahkan" : "diubah"}`,
        placement: "top",
        backgroundColor: "red50",
      });
    },
    onSuccess: () => {
      toast({
        label: `Catatan berhasil ${mode === "add" ? "ditambahkan" : "diubah"}`,
        placement: "top",
      });
      refetchGetNotes();
      addModal.onClose();
    },
  });

  const toast = useToast();
  const [tempIndex, setTempIndex] = useState("");
  const [value, setValue] = useState({
    title: "",
    note: "",
  });
  const [mode, setMode] = useState("add");

  const addModal = useDisclosure({});
  const previewModal = useDisclosure({});
  const deleteModal = useDisclosure({});

  const { mutate: mutateDeleteNotes } = useDeleteNotes({
    onSuccess: () => {
      toast({
        label: "Catatan berhasil dihapus",
        placement: "top",
      });
      deleteModal.onClose();
      refetchGetNotes();
    },
    onError: () => {
      toast({
        label: "Catatan gagal dihapus",
        placement: "top",
      });
    },
  });

  const handleAdd = () => {
    setGTM("seller_center_section", {
      event_category: "store_settings | notes",
      event_action: "click",
      event_label: "add_note",
    });

    setMode("add");
    addModal.onOpen();
    setValue({
      title: "",
      note: "",
    });
  };

  const handleOpenPreview = (index) => {
    setGTM("seller_center_section", {
      event_category: "store_settings | notes",
      event_action: "click",
      event_label: "preview",
    });

    setTempIndex(index);
    const tempSelected = noteList[index];
    setValue(tempSelected);
    previewModal.onOpen();
  };

  const handleDelete = async (index) => {
    setTempIndex(index);
    deleteModal.onOpen();
  };

  const handleEdit = (index) => {
    setMode("edit");
    setTempIndex(index);
    const tempSelected = noteList[index];
    setValue(tempSelected);
    addModal.onOpen();
  };

  const handleOkDelete = () => {
    setGTM("seller_center_section", {
      event_category: "store_settings | notes",
      event_action: "click",
      event_label: "delete",
    });

    const payload = { note_id: tempIndex };
    mutateDeleteNotes({ params: payload });
  };

  const handleChangeValue = (event) => {
    setValue((prevState) => {
      return {
        ...prevState,
        [event.target.name]: event.target.value,
      };
    });
  };

  const handleSave = () => {
    if (mode === "edit") {
      setGTM("seller_center_section", {
        event_category: "store_settings | notes",
        event_action: "click",
        event_label: "change",
      });
    }

    const body = {
      data: {
        id: value.id,
        store_id: storeId,
        title: value.title,
        note: value.note,
        active: true,
      },
    };

    mutateSaveNotes(body);
  };

  return (
    <Box>
      {isLoadingGetNotes ? (
        <Spinner size="16px" color="blue10" />
      ) : noteList.length === 0 ? (
        <EmptyNote handleAdd={handleAdd} />
      ) : (
        <>
          <Flex
            justifyContent="space-between"
            padding="24px"
            alignItems="center"
          >
            <Text weight="bold">Catatan Toko</Text>
            <Button onClick={handleAdd}>Tambah Catatan</Button>
          </Flex>
          <Flex flexDirection="column" gap="24px">
            {noteList &&
              noteList.map((list, index) => (
                <Items
                  title={list.title}
                  handleOpenPreview={handleOpenPreview}
                  handleDelete={() => handleDelete(list.id)}
                  handleEdit={handleEdit}
                  index={index}
                  key={`items-${index}`}
                />
              ))}
          </Flex>
        </>
      )}

      <AddModal
        addModal={addModal}
        mode={mode}
        handleChangeValue={(event) => handleChangeValue(event)}
        value={value}
        handleSave={handleSave}
        isSaveLoading={isSaveLoading}
      />

      <PreviewModal
        previewModal={previewModal}
        title={value.title}
        note={value.note}
      />
      <DeleteModal deleteModal={deleteModal} handleOkDelete={handleOkDelete} />
    </Box>
  );
};

export default Note;
