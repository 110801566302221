import React, { useState, useContext } from "react";
import {
  Box,
  Button,
  Divider,
  Flex,
  Icon,
  Text,
  useToast,
  useDisclosure,
} from "renos-ui";
import StoreContext from "providers/StoreProvider";
import {
  useGetStoreClose,
  useUpdateCloseStore,
  useOpenStoreNow,
} from "services/settings/status";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import duration from "dayjs/plugin/duration";
import OpenStore from "./OpenStore";
import ClosedStore from "./CloseStore";
import { setGTM } from "helpers";
import ConfirmationModal from "./component/ConfirmationModal";

dayjs.extend(localizedFormat);
dayjs.extend(duration);

const CloseStore = () => {
  const toast = useToast();
  const {
    storeData: {
      data: { id: storeId },
    },
  } = useContext(StoreContext);

  const [isEdit, setIsEdit] = useState(false);
  const [checkedClose, seCheckedClose] = useState(false);
  const [isChangeSchedule, setIsChangeSchedule] = useState(false);
  const showConfirmation = useDisclosure({ open: false });
  const [datas, setDatas] = useState({
    date_end: "",
    date_start: "",
    notes: "",
  });
  const { data: storeClose, refetch: refetchGetStore } = useGetStoreClose(
    {
      store_id: storeId,
      is_active: true,
    },
    {
      onSuccess: (data) => {
        if (data.length > 0) {
          setDatas({
            id: data[0].id,
            date_end: data[0].date_end,
            date_start: data[0].date_start,
            notes: data[0].notes,
            is_closed: data[0].is_closed,
          });
        } else {
          setDatas({
            ...datas,
            date_end: dayjs().add(dayjs.duration({ days: 2 })),
            date_start: dayjs().add(dayjs.duration({ days: 1 })),
            is_closed: false,
          });
        }
      },
    }
  );

  const { mutate: storeCloseSchedule, isLoading: isLoadingUpdateSchedule } =
    useUpdateCloseStore({
      onError: (error) => {
        const {
          response: {
            data: { frontend_message: message },
          },
        } = error;
        toast({
          label: message || "Toko gagal ditutup.",
          placement: "top",
          backgroundColor: "red50",
        });
      },
      onSuccess: () => {
        toast({
          label: "Toko berhasil ditutup.",
          placement: "top",
        });
        showConfirmation.onClose();
        setIsEdit(!isEdit);
        setIsChangeSchedule(false);
        refetchGetStore();
      },
    });

  const { mutate: storeOpen } = useOpenStoreNow({
    onError: (error) => {
      const {
        response: {
          data: { frontend_message: message },
        },
      } = error;
      toast({
        label: message || "Toko gagal dibuka.",
        placement: "top",
        backgroundColor: "red50",
      });
    },
    onSuccess: () => {
      toast({
        label: "Toko berhasil dibuka kembali.",
        placement: "top",
      });
      showConfirmation.onClose();
      setIsEdit(!isEdit);
      refetchGetStore();
      setDatas({
        ...datas,
        date_end: dayjs().add(dayjs.duration({ days: 2 })),
        date_start: dayjs().add(dayjs.duration({ days: 1 })),
        notes: "",
      });
    },
  });

  const formatDate = (date) => dayjs(date).format("dddd, D MMM YYYY");

  const submitUpdate = () => {
    handleGTM("set_holiday_dates | close_shop");
    const data = {
      date_end: dayjs(datas.date_end).format("YYYY-MM-DD"),
      date_start: dayjs(datas.date_start).format("YYYY-MM-DD"),
      notes: datas.notes,
    };
    storeCloseSchedule({ data });
  };

  const submitStoreOpen = () =>
    storeOpen({
      params: {
        store_id: storeId,
      },
    });

  let listLength = storeClose?.length > 0;

  if (storeClose?.length > 0) {
    const closed = storeClose[0];
    const today = dayjs("YYYY-MM-DD");

    if (closed.date_end?.split("T")[0] > today) {
      listLength = false;
    }
  }

  const handleGTM = (label) => {
    setGTM("seller_center_section", {
      event_category: "store_settings | status",
      event_action: "click",
      event_label: label,
    });
  };

  return (
    <Flex
      borderStyle="solid"
      borderColor="black10"
      backgroundColor="white"
      borderWidth="1px"
      padding="24px"
      flexDirection="column"
      gap="24px"
      borderRadius="8px"
    >
      <Flex flexDirection="row" gap="8px" width="100%">
        <Flex alignItems="start" width="100%">
          <Box paddingX="8px">
            <Icon color="black50" size={20} name="Calendar-outline" />
          </Box>
          <Flex flexDirection="column" width="100%">
            <Text weight="bold">Atur tanggal libur</Text>
            <Flex justifyContent="space-between">
              <Flex flexBasis="70%">
                <Text color="black50">
                  Jadwal hanya digunakan sebagai informasi bahwa tokomu sedang
                  tutup ke pembeli. Pembeli tidak bisa melakukan pemesanan
                  sampai toko aktif kembali.
                </Text>
              </Flex>
              {!isEdit && (
                <Button
                  size="large"
                  variant="secondary"
                  onClick={() => {
                    setIsEdit(!isEdit);
                    handleGTM("set_holiday_dates | set_holiday_dates");
                  }}
                >
                  Atur Tanggal Libur
                </Button>
              )}
            </Flex>
          </Flex>
        </Flex>
      </Flex>

      <Divider />

      <Flex flexDirection="column">
        {isEdit && listLength ? (
          <OpenStore
            datas={datas}
            setDatas={setDatas}
            checkedClose={checkedClose}
            seCheckedClose={seCheckedClose}
            isLoading={isLoadingUpdateSchedule}
            isChangeSchedule={isChangeSchedule}
            setIsChangeSchedule={setIsChangeSchedule}
            openDialog={() => showConfirmation.onOpen()}
          />
        ) : (
          <>
            {!isEdit && listLength && (
              <Flex gap="8px" paddingX="38px">
                <Text>
                  {formatDate(storeClose && storeClose[0]?.date_start)}
                </Text>
                <Text> - </Text>
                <Text>{formatDate(storeClose && storeClose[0]?.date_end)}</Text>
              </Flex>
            )}

            {isEdit && (
              <ClosedStore
                datas={datas}
                setDatas={setDatas}
                checkedClose={checkedClose}
                seCheckedClose={seCheckedClose}
                isLoading={isLoadingUpdateSchedule}
                openDialog={() => showConfirmation.onOpen()}
              />
            )}
          </>
        )}
      </Flex>

      <ConfirmationModal
        showConfirmation={showConfirmation}
        isEdit={isEdit}
        datas={datas}
        setIsEdit={setIsEdit}
        submitUpdate={submitUpdate}
        submitStoreOpen={submitStoreOpen}
      />
    </Flex>
  );
};

export default CloseStore;
