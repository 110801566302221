import { useEffect, useMemo, useState } from "react";
import StoreContext from "providers/StoreProvider";
import { useContext } from "react";
import { Flex, Text, Box, Icon, Tooltip } from "renos-ui";
import {
  useGetNewOrder,
  useGetNewReview,
  useGetChecklistStore,
} from "services/dashboard";
import dayjs from "dayjs";
// import Article from "./article";
import Store from "./store";
import Analityc from "./analysis";
import { useTour } from "@reactour/tour";
import { goToCustomerService } from "helpers";

const Dashboard = () => {
  const [isCheckLoading, setIsCheckLoading] = useState(true);
  const startDate = dayjs(new Date()).format("YYYY-MM-DD");
  const endDate = dayjs(new Date()).format("YYYY-MM-DD");

  const { data: checklist } = useGetChecklistStore({
    onSuccess: () => setIsCheckLoading(!isCheckLoading),
    onError: () => setIsCheckLoading(false),
  });

  const checkDone = useMemo(
    () => checklist?.checklist.filter((ft) => ft.completed === false),
    [checklist]
  );

  const {
    storeData: {
      data: { id: storeId },
    },
  } = useContext(StoreContext);
  const { data: dataReview, isLoading: loadingNewReview } = useGetNewReview({
    store_id: storeId,
    date_start: startDate,
    date_end: endDate,
  });

  const defaultDataNewOeder = [{ total: 0 }, { total: 0 }, { total: 0 }];
  const {
    data: dataNewOrder = defaultDataNewOeder,
    isLoading: loadingNewOrder,
  } = useGetNewOrder({
    store: storeId,
    date_start: startDate,
    date_end: endDate,
  });

  // const article = [
  //   {
  //     id: "1",
  //     title:
  //       "Ada Kendala? Seller Bisa Perpanjang Waktu Pengiriman dengan waktu tertentu",
  //     date: "19 Agustus 2022",
  //     img: "https://lh3.googleusercontent.com/zfEVFPeq9Oxyr9VxgDBDhTo6ceERNKoFuVwaAHAF444GxnpNNZecZNqNyouPStyztegPXiInLErqpoG7FIeRzS3735fu3v528_WUMAL-Dj4K1goPS6GYcbbMtC76V9YcU9Gt2R_B",
  //   },
  //   {
  //     id: "2",
  //     title:
  //       "Tips Mengambil Foto Produk Menarik yang Memberikan Waktu Lebih Anda",
  //     date: "17 Agustus 2022",
  //     img: "https://assets.renos.id/promo/1675095229-5declutteryourhome.jpg",
  //   },
  //   {
  //     id: "3",
  //     title: "Jualan Lebih Mudah Dengan Fitur Varian Pada Produk Anda",
  //     date: "10 Agustus 2022",
  //     img: "https://sisternet.co.id/assets/images/jjdodfm1pzy7s8plgos9.jpg",
  //   },
  // ];

  const newActivity = [
    {
      id: "1",
      note: "Pesanan Baru",
    },
    {
      id: "2",
      note: "Siap Dikirim",
    },
    // {
    //   id: "3",
    //   note: "Dikomplain",
    // },
    {
      id: "4",
      note: "Ulasan baru",
    },
  ];

  const { setIsOpen } = useTour();

  useEffect(() => {
    const isOpen = checklist?.checklist?.some((ft) => ft.completed === false);
    setIsOpen(false);
    setIsCheckLoading(false);

    if (isOpen) {
      setIsOpen(true);
    } else {
      localStorage.removeItem("currentStepUpdate");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checklist]);

  return (
    <Flex justifyContent="space-between" marginTop="20px" gap="40px">
      <Flex flexDirection="column" rowGap="4px" width="765px" flexGrow={1}>
        <Text variant="heading3" weight="bold">
          Penting Hari Ini
        </Text>
        <Text>
          Aktivitas yang perlu kamu pantau untuk jaga kepuasan pembeli.
        </Text>
        <Flex
          justifyContent="space-between"
          width="100%"
          height="96px"
          gap="16px"
          marginTop="20px"
        >
          {newActivity.map((info, index) => (
            <Box
              key={index}
              width="100%"
              backgroundColor="white"
              justifyContent="center"
              borderRadius="16px"
              boxShadow="0px 4px 4px rgba(20, 20, 32, 0.05)"
            >
              {!loadingNewReview && !loadingNewOrder && (
                <Flex padding="30px 24px 16px 24px" flexDirection="column">
                  <Text variant="heading3" weight="bold" marginBottom="4px">
                    {info.id === "1"
                      ? dataNewOrder[1].total
                      : info.id === "2"
                      ? dataNewOrder[2].total
                      : info.id === "4"
                      ? dataReview?.total_newest_review
                      : 0}
                  </Text>
                  <Text variant="caption">{info.note}</Text>
                </Flex>
              )}
            </Box>
          ))}
        </Flex>

        <Analityc />
      </Flex>

      {checkDone?.length !== 0 && (
        <Flex
          width="365px"
          rowGap="24px"
          overflow="auto"
          flexDirection="column"
        >
          {checkDone?.length === 0 ? <></> : <Store />}
          {/* <Article data={article} /> */}
        </Flex>
      )}

      <Flex
        position="fixed"
        bottom="40px"
        right="40px"
        padding="14px 16px"
        boxShadow="0px 0px 8px 0px rgba(20, 20, 32, 0.16);"
        justifyContent="center"
        alignItems="center"
        borderRadius="40px"
        cursor="pointer"
        zIndex={2}
        backgroundColor="white"
        onClick={goToCustomerService}
      >
        <Tooltip
          placement="bottom-right"
          label={
            <Flex textAlign="center">
              Chat dengan Customer Service <br /> Renos.id melalui WhatsApp
            </Flex>
          }
        >
          <Icon name="Support-solid" size={20} color="blue50" />
        </Tooltip>
      </Flex>
    </Flex>
  );
};

export default Dashboard;
