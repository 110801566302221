import { useEffect, useMemo } from "react";
import {
  Box,
  Divider,
  Drawer,
  Flex,
  Icon,
  Skeleton,
  Text,
  range,
  useToast,
} from "renos-ui";
import { useTrackOrder } from "services/order-management";
import FailLoadData from "widgets/mobile/FailLoadData";

const PopupTracking = ({ orderId, disclosure }) => {
  const toast = useToast();
  const { isOpen, onClose } = disclosure;

  const {
    data: dataTrack,
    isLoading,
    isFetching,
    refetch,
    isError,
  } = useTrackOrder({ order_id: orderId });

  const handleCopy = (awb) => {
    navigator.clipboard.writeText(awb);

    toast({
      label: "Berhasil salin nomor resi.",
      placement: "bottom",
    });
  };

  const handleOnClose = (event) => {
    event.stopPropagation();
    onClose();
  };

  const dataTracker = useMemo(
    () =>
      dataTrack?.data?.tracking?.events
        ? dataTrack?.data?.tracking?.events
        : [],
    [dataTrack]
  );

  const dataAwb = useMemo(
    () => dataTrack?.data?.awb || dataTrack?.data?.tracking_number,
    [dataTrack?.data?.awb, dataTrack?.data?.tracking_number]
  );

  useEffect(() => {
    if (isOpen) {
      refetch();
    }
    // eslint-disable-next-line
  }, [isOpen]);

  return (
    <Drawer
      isVisible={isOpen}
      onClose={handleOnClose}
      placement="bottom"
      height="calc(100dvh - 32px)"
      borderRadius="unset !important"
    >
      <Flex
        flexDirection="column"
        height="calc(100dvh - 32px)"
        gap="24px"
        onClick={(event) => event.stopPropagation()}
      >
        <Flex gap="8px" alignItems="center">
          <Icon
            name="Close-outline"
            size={24}
            color="black50"
            onClick={handleOnClose}
          />
          <Text variant="subtitle" weight="bold">
            Lacak
          </Text>
        </Flex>

        {!isError && (
          <>
            <Flex flexDirection="column" gap="16px">
              <Flex flexDirection="column" gap="2px" width="100%">
                <Text variant="caption" color="black50">
                  Nomor Resi
                </Text>
                {isLoading || isFetching ? (
                  <Skeleton height="20px" width="100%" />
                ) : dataAwb ? (
                  <Flex gap="8px" alignItems="center">
                    <Text color="black75">{dataAwb}</Text>
                    <Icon
                      name="Copy-solid"
                      color="blue50"
                      onClick={() => handleCopy(dataAwb)}
                    />
                  </Flex>
                ) : (
                  <Text color="black75">Nomor resi belum tersedia</Text>
                )}
              </Flex>
              <Flex flexDirection="column" gap="2px" width="100%">
                <Text variant="caption" color="black50">
                  Status Terbaru
                </Text>
                {isLoading || isFetching ? (
                  <Skeleton height="20px" width="100%" />
                ) : (
                  <Text color="black75">
                    {dataTrack?.data?.tracking?.status}
                  </Text>
                )}
              </Flex>
            </Flex>

            <Divider />
          </>
        )}

        <Flex
          flexDirection="column"
          overflowX="auto"
          margin="0 -16px"
          padding=" 0 16px"
          height="calc(100% - 184px)"
        >
          {isLoading || isFetching ? (
            range(8).map((_, indexSkeleton) => (
              <Flex
                marginBottom="16px"
                width="100%"
                key={`skeleton${indexSkeleton}`}
              >
                <Skeleton height="20px" width="100%" />
              </Flex>
            ))
          ) : dataTracker.length === 0 || isError ? (
            <FailLoadData
              title="Data tracking belum tersedia"
              description="Mohon coba muat ulang secara berkala atau hubungi renos"
              onClick={refetch}
              isLoading={isLoading || isFetching}
            />
          ) : (
            [...dataTracker].map((tracking, indexTracking) => (
              <Flex key={`tracker-${indexTracking}`} width="100%">
                <Flex
                  flexDirection="column"
                  // paddingRight="18px"
                  alignItems="flex-end"
                  borderRightColor="black10"
                  borderRightWidth="1px"
                  borderRightStyle="dashed"
                  position="relative"
                >
                  {indexTracking === 0 ? (
                    <Flex position="absolute" right="5.3px" top="5px">
                      <Flex position="relative" alignItems="center">
                        <Box
                          position="absolute"
                          backgroundColor="blue10"
                          height="12px"
                          width="12px"
                          borderRadius="50%"
                        />
                        <Box
                          position="absolute"
                          backgroundColor="blue50"
                          height="6px"
                          width="6px"
                          borderRadius="50%"
                          left="3px"
                        />
                      </Flex>
                    </Flex>
                  ) : (
                    <Box
                      position="absolute"
                      backgroundColor="black25"
                      height="8px"
                      width="8px"
                      borderRadius="50%"
                      right="-4.4px"
                      top="5px"
                    />
                  )}
                </Flex>

                <Flex
                  paddingLeft="18px"
                  flexDirection="column"
                  pb={indexTracking !== dataTracker.length - 1 && "24px"}
                >
                  <Text
                    color={indexTracking === 0 ? "blue50" : "black50"}
                    weight="bold"
                  >
                    {tracking.date_fmt}, {tracking.time_fmt}
                  </Text>
                  <Text variant="caption" color="black50">
                    {tracking.event}
                  </Text>
                </Flex>
              </Flex>
            ))
          )}
        </Flex>
      </Flex>
    </Drawer>
  );
};

export default PopupTracking;
