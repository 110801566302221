import { Divider, Flex, Text } from "renos-ui";

const Empty = () => {
  return (
    <Flex flexDirection="column" gap="24px">
      <Divider />
      <Flex
        flexDirection="column"
        gap="8px"
        alignItems="center"
        textAlign="center"
      >
        <Flex
          as="img"
          src="/assets/images/voucher/no-product.svg"
          alt="no-img"
          height="156px"
          width="156px"
        />
        <Text variant="heading3" weight="bold">
          Belum ada produk
        </Text>
        <Text color="black75">
          Silahkan tambahkan produk untuk voucher ini.
        </Text>
      </Flex>
      <Divider />
    </Flex>
  );
};

export default Empty;
