import { Flex, Input, colors, SelectInput } from "renos-ui";
import styled from "styled-components";

export const StyledFlexInputSearch = styled(Flex)`
  > div > span {
    border-color: ${colors.black5};
    // border-radius: 100px;
    background-color: ${colors.black5};
    width: 240px;
    padding: 9px 12px;
    > input {
      background-color: ${colors.black5};
    }
  }
`;

export const StyledInputBorderLess = styled(Input)`
  border: 0;
  width: 240px;
`;

export const StyledSelectInput = styled(SelectInput)`
  width: 192px;
  color: ${colors.black75} !important;
  font-size: 14px !important;

  .select__control {
    padding: 9px 12px;
  }
`;
