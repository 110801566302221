import { useHistory } from "react-router-dom";
import {
  Flex,
  Text,
  colors,
  Box,
  Button,
  useToast,
  useDisclosure,
} from "renos-ui";
import {
  useActivateSellerFleet,
  useDeactivateSellerFleet,
  useGetSellerFleet,
} from "services/logistics/sellerFleet";
import ModalUnderDevelopment from "./ModalUnderDevelopment";
import { useState } from "react";
import { setGTM } from "helpers";

const SellerFleetStatus = () => {
  const toast = useToast();
  const history = useHistory();
  const disclosureMaintenance = useDisclosure({ isOpen: false });
  const [isGetSellerFleetError, setIsGetSellerFleetError] = useState(false);

  const {
    data: dataGetSellerFleet,
    isLoading: isGetSellerFleetLoading,
    refetch: refetchGetSellerFleet,
  } = useGetSellerFleet({
    onError: (errorData) => {
      const {
        response: { status },
      } = errorData;

      if (status === 404) {
        setIsGetSellerFleetError(false);
      } else {
        setIsGetSellerFleetError(true);
      }
    },
  });
  const {
    isLoading: isActivateLoading,
    refetch: refetchActivate,
    isFetching: isActivateFetching,
  } = useActivateSellerFleet({
    onSuccess: () => {
      handleGTM("enable_features");
      refetchGetSellerFleet();
      toast({ label: "Kurir toko berhasil diaktifkan", placement: "top" });
    },
    onError: () => {
      toast({
        label: "Kurir toko gagal diaktifkan",
        backgroundColor: "red50",
        placement: "top",
      });
    },
  });

  const handleGTM = (label) => {
    setGTM("seller_center_section", {
      event_category: "store_settings | delivery",
      event_action: "click",
      event_label: `delivery_service | ${label}`,
    });
  };

  const {
    isLoading: isDeactivateLoading,
    refetch: refetchDeactivate,
    isFetching: isDeactivateFetching,
  } = useDeactivateSellerFleet({
    onSuccess: () => {
      handleGTM("turn_off");
      refetchGetSellerFleet();
      toast({ label: "Kurir toko berhasil dimatikan", placement: "top" });
    },
    onError: () => {
      toast({
        label: "Kurir toko gagal dimatikan",
        backgroundColor: "red50",
        placement: "top",
      });
    },
  });

  return (
    <Flex
      padding="24px"
      border={`1px dashed ${colors.blue10}`}
      borderRadius="8px"
      background="url(/assets/images/delivery/wave-courier-activation.svg)"
      backgroundRepeat="no-repeat"
      backgroundSize="cover"
      justifyContent="space-between"
      alignItems="center"
    >
      <Flex flexDirection="column">
        <Text weight="bold" color="black75">
          Kurir Toko
        </Text>
        <Text color="black50">
          Fitur kurir toko menambahkan opsi pengiriman menggunakan kurir tokomu
          kepada pembeli.
        </Text>
      </Flex>

      {isGetSellerFleetError ? (
        <Box>
          <Button
            variant="secondary"
            size="medium"
            disabled={isGetSellerFleetLoading}
            onClick={refetchGetSellerFleet}
          >
            Muat Ulang
          </Button>
        </Box>
      ) : dataGetSellerFleet?.data.active ? (
        <Flex gap="12px">
          <Box>
            <Button
              variant="tertiary"
              size="medium"
              disabled={
                isGetSellerFleetLoading ||
                isDeactivateLoading ||
                isDeactivateFetching
              }
              onClick={refetchDeactivate}
            >
              Matikan
            </Button>
          </Box>
          <Box>
            <Button
              variant="secondary"
              size="medium"
              disabled={
                isGetSellerFleetLoading ||
                isDeactivateLoading ||
                isDeactivateFetching
              }
              onClick={
                () => {
                  handleGTM("arrange");
                  history.push("/setting/seller-fleet");
                }
                // disclosureMaintenance.onToggle
              }
            >
              Atur
            </Button>
          </Box>
        </Flex>
      ) : (
        <Box>
          <Button
            variant="secondary"
            size="medium"
            onClick={refetchActivate}
            disabled={
              isGetSellerFleetLoading || isActivateLoading || isActivateFetching
            }
          >
            Aktifkan Fitur
          </Button>
        </Box>
      )}

      <ModalUnderDevelopment
        isOpen={disclosureMaintenance.isOpen}
        onClose={disclosureMaintenance.onClose}
      />
    </Flex>
  );
};

export default SellerFleetStatus;
