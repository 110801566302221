import dayjs from "dayjs";
import { useMemo } from "react";
import { DatePicker, Dialog, Flex, Icon, Input, useDisclosure } from "renos-ui";
import { Wrapper } from "./styled";

const Datepicker = ({
  label,
  value,
  onChange,
  min,
  max,
  placeholder,
  disabled,
}) => {
  const { isOpen, onClose, onOpen } = useDisclosure({ isOpen: false });

  const handleChange = (vals) => {
    onChange(vals);
    onClose();
  };

  const inputValue = useMemo(
    () => (value ? dayjs(value).format("DD MMMM YYYY") : ""),
    [value]
  );

  return (
    <Flex>
      <Input
        readOnly
        prefix={<Icon name="Calendar-outline" size={20} color="black50" />}
        label={label}
        value={inputValue}
        placeholder={placeholder}
        onClick={onOpen}
        disabled={disabled}
      />

      {isOpen && (
        <Dialog isVisible={isOpen} onClose={onClose} closable verticalCentered>
          <Wrapper>
            <DatePicker
              inline
              selected={value}
              onChange={(vals) => handleChange(vals)}
              maxDate={max}
              minDate={min}
            />
          </Wrapper>
        </Dialog>
      )}
    </Flex>
  );
};

export default Datepicker;
