import { useMemo } from "react";
import { Flex } from "renos-ui";
import ConfirmRejectNewOrder from "./component/ConfirmRejectNewOrder";
import ChatBuyer from "./component/ChatBuyer";
import OnDelivery from "./component/OnDelivery";
import OrderStatus from "./component/OrderStatus";
import PrintLabel from "./component/PrintLabel";
import SendOrder from "./component/SendOrder";

const Footer = ({ data, refetchStatus, refetchList }) => {
  const isHasApprovalButton = useMemo(() => {
    switch (data.order_status_id) {
      case 2:
        return (
          <ConfirmRejectNewOrder
            data={data}
            refetchStatus={refetchStatus}
            refetchList={refetchList}
          />
        );
      case 3:
        return (
          <SendOrder
            data={data}
            refetchStatus={refetchStatus}
            refetchList={refetchList}
          />
        );
      case 4:
        return (
          <OnDelivery
            data={data}
            refetchStatus={refetchStatus}
            refetchList={refetchList}
          />
        );
      default:
        return "";
    }
    // eslint-disable-next-line
  }, [data]);

  const isHasPrintLabel = useMemo(
    () =>
      (data.order_status_id === 3 || data.order_status_id === 4) && (
        <PrintLabel data={data} />
      ),
    [data]
  );

  return (
    <Flex padding="12px 24px" justifyContent="space-between">
      <Flex gap="24px">
        {isHasPrintLabel}
        <OrderStatus data={data} />
        <ChatBuyer data={data} />
      </Flex>
      {isHasApprovalButton}
    </Flex>
  );
};

export default Footer;
