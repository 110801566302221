import ENDPOINTS from "constants/endpoints";
import dayjs from "dayjs";
import { useMutation, useQuery } from "react-query";
import client from "../client";

const calculateDatePrev = (params) => {
  const { date_type, start_date: startDate, end_date: endDate } = params;
  const tempStarDate = new Date(startDate);
  const tempEndDate = new Date(endDate);

  if (date_type === "daily") {
    tempStarDate.setDate(tempStarDate.getDate() - 1);
    tempEndDate.setDate(tempEndDate.getDate() - 1);
  } else if (date_type === "weekly") {
    tempStarDate.setDate(tempStarDate.getDate() - 7);
    tempEndDate.setDate(tempEndDate.getDate() - 7);
  } else {
    tempStarDate.setMonth(tempStarDate.getMonth() - 1);
    tempEndDate.setMonth(tempEndDate.getMonth() - 1);
  }

  return {
    params: {
      start_date: dayjs(tempStarDate).format("YYYY-MM-DD"),
      end_date: dayjs(tempEndDate).format("YYYY-MM-DD"),
    },
  };
};

const getTransactionSummaryFetcher = async (params) => {
  const { data } = await client.get(ENDPOINTS.statistic_summary, params);

  return data;
};

const getProductSeenFetcher = async (body) => {
  const { data: dataNow } = await client.get(
    ENDPOINTS.statistic_summary_seen,
    body
  );
  const bodyPrev = calculateDatePrev(body.params);
  const { data: dataPrev } = await client.get(
    ENDPOINTS.statistic_summary_seen,
    bodyPrev
  );

  return [dataNow, dataPrev];
};

const getProductSoldFetcher = async (body) => {
  const { data: dataNow } = await client.get(
    ENDPOINTS.statistic_summary_sold_order,
    body
  );
  const bodyPrev = calculateDatePrev(body.params);
  const { data: dataPrev } = await client.get(
    ENDPOINTS.statistic_summary_sold_order,
    bodyPrev
  );

  return [dataNow, dataPrev];
};

const getProductConversionFetcher = async (body) => {
  const { data: dataNow } = await client.get(
    ENDPOINTS.statistic_summary_conversion,
    body
  );
  const bodyPrev = calculateDatePrev(body.params);
  const { data: dataPrev } = await client.get(
    ENDPOINTS.statistic_summary_conversion,
    bodyPrev
  );

  return [dataNow, dataPrev];
};

export const useGetNetIncome = (extraParams) =>
  useMutation((body) => getNetIncomeFetcher(body), { ...extraParams });

export const useGetProductSeen = (extraParams) =>
  useMutation((body) => getProductSeenFetcher(body), { ...extraParams });

export const useGetProductSold = (extraParams) =>
  useMutation((body) => getProductSoldFetcher(body), { ...extraParams });

export const useGetProductConversion = (extraParams) =>
  useMutation((body) => getProductConversionFetcher(body), { ...extraParams });

export const useGetTransactionSummary = (params, extraParams) => {
  return useQuery(
    ["getTransactionSummary", params],
    () => getTransactionSummaryFetcher(params),
    {
      ...extraParams,
    }
  );
};

const getNetIncomeFetcher = async (body) => {
  const { data: dataNow } = await client.get(
    ENDPOINTS.statistic_summary_net_income,
    body
  );
  const bodyPrev = calculateDatePrev(body.params);
  const { data: dataPrev } = await client.get(
    ENDPOINTS.statistic_summary_net_income,
    bodyPrev
  );

  return [dataNow, dataPrev];
};

const getReportFetcher = async (body) => {
  delete body.params.date_type;
  const params = new URLSearchParams(body.params).toString();
  const { data } = await client.get(
    `${ENDPOINTS.statistic_download_report}?${params}`,
    {
      responseType: "blob",
    }
  );

  return data;
};

export const useGetReport = (extraParams) =>
  useMutation((body) => getReportFetcher(body), { ...extraParams });

const getNewOrderFetcher = async (body) => {
  body.params.order_status = 2;
  const { data: dataNow } = await client.get(
    ENDPOINTS.statistic_new_order,
    body
  );

  return dataNow;
};

export const useGetNewOrder = (params, extraParams) => {
  return useQuery(["getNewOrder", params], () => getNewOrderFetcher(params), {
    ...extraParams,
  });
};

const getCancelFetcher = async (body) => {
  const { data: dataNow } = await client.get(
    ENDPOINTS.statistic_cancel_order,
    body
  );

  // return dataNow?.data?.slice(0, -1);
  return dataNow;
};

export const useGetCancelOrder = (params, extraParams) => {
  return useQuery(["getCancelOrder", params], () => getCancelFetcher(params), {
    ...extraParams,
  });
};
