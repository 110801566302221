import React from "react";
import { Flex } from "renos-ui";

const PrintLayout = ({ children }) => {
  return (
    <Flex width="100vw" minHeight="100%">
      {children}
    </Flex>
  );
};

export default PrintLayout;
