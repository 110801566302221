import React from "react";
import {
  Flex,
  Text,
  Input,
  Button,
  Box,
  Icon,
  SelectInput,
  isEmpty,
  useDisclosure,
} from "renos-ui";
import useRegister from "./hooks/useRegister";
import { StyledInput } from "../styled";
import MobileLayout from "layouts/MobileLayout";
import LocationDrawer from "./LocationDrawer";

const DetailInformation = (props) => {
  const {
    handleSubmit,
    onSubmit,
    listProvince,
    listCity,
    getCity,
    isLoadingCity,
    listDistrict,
    getDistrict,
    isLoadingDistrict,
    listSubDistrict,
    getSubDistrict,
    isLoadingSubDistrict,
    emptyOptions,
    errors,
    control,
    getValues,
    setValue,
    Controller,
  } = useRegister();

  const showDisclosure = useDisclosure({});

  return (
    <MobileLayout
      header={{
        title: "Isi Detail Informasi",
        isHomepage: false,
        withBack: true,
        withoutGoBack: true,
        onBack: () => props.setStep(1),
      }}
    >
      <Flex flexDirection="column" width="100%" padding="16px">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Flex flexDirection="column" gap="24px" mb="24px">
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  label="Nama Lokasi"
                  showCount
                  size="small"
                  placeholder="Contoh: Gudang, Toko, Cabang, dll."
                  maxLength={60}
                  isError={!!errors.name}
                  error={errors.name?.message}
                />
              )}
            />
            <Controller
              name="address"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  label="Alamat Toko"
                  placeholder="Tulis alamat lengkap toko"
                  isError={!!errors.address}
                  size="small"
                  error={errors.address?.message}
                />
              )}
            />
            <Flex flexDirection="column" rowGap="8px">
              <Text weight="bold" variant="caption" color="black50">
                Provinsi
              </Text>

              <Controller
                name="province"
                control={control}
                render={({ field }) => (
                  <SelectInput
                    placeholder="Pilih Provinsi"
                    hideSelectedOptions={true}
                    size="small"
                    isSearchable="true"
                    {...field}
                    options={listProvince?.data?.map((row) => ({
                      value: row.province_id,
                      label: row.province_name,
                    }))}
                    onChange={(value) => {
                      field.onChange(value);
                      setValue("city", null);
                      setValue("district", null);
                      setValue("sub_district", null);
                      getCity({
                        params: {
                          province_id: value.value,
                        },
                      });
                    }}
                    isError={!!errors?.province}
                  />
                )}
              />
              {!!errors.province && (
                <Text variant="caption" color="red50">
                  {errors.province?.message}
                </Text>
              )}
            </Flex>
            <Flex flexDirection="column" rowGap="8px">
              <Text weight="bold" variant="caption" color="black50">
                Kota/Kabupaten
              </Text>

              <Controller
                name="city"
                control={control}
                render={({ field }) => (
                  <SelectInput
                    options={
                      listCity?.data?.map((row) => ({
                        value: row.city_id,
                        label: row.city_name,
                      })) || emptyOptions
                    }
                    size="small"
                    hideSelectedOptions={true}
                    isSearchable={!isEmpty(listCity)}
                    placeholder="Pilih Kota/Kabupaten"
                    {...field}
                    isDisabled={isLoadingCity || isEmpty(getValues("province"))}
                    onChange={(value) => {
                      field.onChange(value);
                      setValue("district", null);
                      setValue("sub_district", null);
                      getDistrict({
                        params: {
                          city_id: value.value,
                        },
                      });
                    }}
                    isError={!!errors?.city}
                  />
                )}
              />
              {!!errors.city && (
                <Text variant="caption" color="red50">
                  {errors.city?.message}
                </Text>
              )}
            </Flex>
            <Flex flexDirection="column" rowGap="8px">
              <Text weight="bold" variant="caption" color="black50">
                Kecamatan
              </Text>

              <Controller
                name="district"
                control={control}
                render={({ field }) => (
                  <SelectInput
                    options={
                      listDistrict?.data?.map((row) => ({
                        value: row.district_id,
                        label: row.district_name,
                      })) || emptyOptions
                    }
                    hideSelectedOptions={true}
                    placeholder="Pilih Kecamatan"
                    size="small"
                    isSearchable={!isEmpty(listDistrict)}
                    {...field}
                    isDisabled={isLoadingDistrict || isEmpty(getValues("city"))}
                    onChange={(value) => {
                      field.onChange(value);
                      setValue("sub_district", null);
                      setValue("postal_code", "");
                      getSubDistrict({
                        params: {
                          district_id: value.value,
                        },
                      });
                    }}
                    isError={!!errors?.district}
                  />
                )}
              />
              {!!errors.district && (
                <Text variant="caption" color="red50">
                  {errors.district?.message}
                </Text>
              )}
            </Flex>
            <Flex flexDirection="column" rowGap="8px">
              <Text weight="bold" variant="caption" color="black50">
                Kelurahan
              </Text>

              <Controller
                name="sub_district"
                control={control}
                render={({ field }) => (
                  <SelectInput
                    options={
                      listSubDistrict?.data?.map((row) => ({
                        value: row.lower_district_id,
                        label: row.lower_district_name,
                        data: row,
                      })) || emptyOptions
                    }
                    hideSelectedOptions={true}
                    size="small"
                    isSearchable={!isEmpty(listSubDistrict)}
                    placeholder="Pilih Kecamatan"
                    {...field}
                    isDisabled={
                      isLoadingSubDistrict || isEmpty(getValues("district"))
                    }
                    onChange={(value) => {
                      setValue("postal_code", value.data.postal_code);
                      field.onChange(value);
                    }}
                    isError={!!errors?.sub_district}
                  />
                )}
              />
              {!!errors.sub_district && (
                <Text variant="caption" color="red50">
                  {errors.sub_district?.message}
                </Text>
              )}
            </Flex>
            <Controller
              name="contact_person"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  label="Nama PIC Toko"
                  size="small"
                  placeholder="Tulis nama PIC toko / gudang"
                  isError={!!errors.contact_person}
                  error={errors.contact_person?.message}
                />
              )}
            />
            <Flex flexDirection="column" rowGap="8px">
              <Text weight="bold" variant="caption" color="black50">
                Nomor HP/Telepon
              </Text>
              <Controller
                name="contact_number"
                control={control}
                render={({ field }) => (
                  <StyledInput
                    {...field}
                    type="text"
                    value={getValues("contact_number")}
                    name="contact_number"
                    placeholder="Masukkan nomor HP/telepon"
                    onChange={(e) =>
                      setValue(
                        "contact_number",
                        e.target.value.replace(/[^+\d]/g, "").substring(0, 15)
                      )
                    }
                    isError={!!errors.contact_number}
                  />
                )}
              />

              {!!errors.contact_number && (
                <Text variant="caption" color="red50">
                  {errors.contact_number?.message ||
                    "No. HP Contact Person harus diisi."}
                </Text>
              )}
            </Flex>
            <Controller
              name="postal_code"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  label="Kode Pos"
                  placeholder="Kode pos"
                  value={getValues("postal_code")}
                  disabled
                  size="small"
                />
              )}
            />
            <Flex
              width="100%"
              flexDirection="column"
              gap="8px"
              marginBottom="8px"
            >
              <Flex justifyContent="space-between" alignItems="center">
                <Text variant="caption" color="black50" weight="bold">
                  Pin Lokasi
                </Text>
                {getValues("point_address") && (
                  <Text
                    variant="caption"
                    color="black50"
                    weight="bold"
                    onClick={() => showDisclosure.onOpen()}
                  >
                    Ubah
                  </Text>
                )}
              </Flex>

              <Flex
                gap="16px"
                alignItems="center"
                onClick={() => showDisclosure.onOpen()}
              >
                <Flex
                  background={`url("/assets/images/mobile/bg-map.svg")`}
                  height="48px"
                  minWidth="48px"
                  borderRadius="8px"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Icon
                    name="Location-solid"
                    color="blue50"
                    cursor="pointer"
                    width
                  />
                </Flex>
                <Flex>
                  {getValues("point_address") ? (
                    <Text color="black75" variant="small">
                      {getValues("point_address")}
                    </Text>
                  ) : (
                    <Text color="blue50" variant="small" weight="bold">
                      Set Lokasi
                    </Text>
                  )}
                </Flex>
              </Flex>

              <Flex>
                <Text variant="caption" color="black25">
                  Pastikan lokasi yang kamu tandai di peta sesuai dengan alamat
                  yang kamu isi di atas.
                </Text>
              </Flex>
              {!!errors.point_address && (
                <Text variant="caption" color="red50">
                  {errors.point_address.message}
                </Text>
              )}
            </Flex>
          </Flex>

          <Flex flexDirection="column" width="100%" gap="16px" mb="24px">
            <Button isBlock size="small" type="submit" variant="primary">
              <Text weight="bold">Buka Toko</Text>
            </Button>

            <Text variant="caption">
              Dengan mengklik tombol buka toko, saya menyetujui {""}
              <Box
                as="strong"
                cursor="pointer"
                display="inline"
                fontSize="12px"
                color="blue50"
                onClick={() =>
                  window.open(" https://www.renos.id/terms", "_blank")
                }
              >
                Syarat dan Ketentuan
              </Box>
              , &{" "}
              <Box
                as="strong"
                cursor="pointer"
                display="inline"
                fontSize="12px"
                color="blue50"
                onClick={() =>
                  window.open(" https://www.renos.id/privacy", "_blank")
                }
              >
                {""} kebijakan Privasi
              </Box>
              {""} Renos.id
            </Text>
          </Flex>
        </form>
      </Flex>

      <LocationDrawer
        isOpenDrawer={showDisclosure.isOpen}
        setCloseDrawer={showDisclosure.onClose}
        value={getValues("coordinates")}
        setValue={setValue}
      />
    </MobileLayout>
  );
};

export default DetailInformation;
