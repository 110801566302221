import { useState } from "react";
import { Flex, Tabs, Text } from "renos-ui";
import Product from "./component/product";
import Home from "./component/Home";

const StoreMenu = ({ isDesignActive, isPreviewMode }) => {
  const items = [
    {
      label: ({ isActive }) => (
        <Flex py="10px">
          <Text
            color={isActive ? "blue50" : "black50"}
            weight={isActive && "bold"}
          >
            Beranda
          </Text>
        </Flex>
      ),
      key: "home",
      icon: "Right-solid",
      width: "180px",
      children: () => {},
    },
    {
      label: ({ isActive }) => (
        <Flex py="10px">
          <Text
            color={isActive ? "blue50" : "black50"}
            weight={isActive && "bold"}
          >
            Produk
          </Text>
        </Flex>
      ),
      key: "product",
      width: "180px",
      children: () => {},
    },
  ];

  const [activeTab, setActiveTab] = useState("home");

  const handleChangeTabActive = (value) => {
    setActiveTab(value);
  };

  return (
    <Flex
      flexDirection="column"
      borderTopColor="black5"
      borderTopStyle="solid"
      borderTopWidth="1px"
      backgroundColor="white"
      borderBottomLeftRadius="16px"
      borderBottomRightRadius="16px"
      gap="24px"
    >
      <Tabs
        onChange={handleChangeTabActive}
        defaultActiveKey={activeTab}
        placement="bottom"
        items={items}
      />

      {activeTab === "home" ? (
        <Home {...{ isDesignActive, isPreviewMode }} />
      ) : (
        <Product {...{ isDesignActive, isPreviewMode }} />
      )}
    </Flex>
  );
};

export default StoreMenu;
