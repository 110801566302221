import { Dialog, Flex, Text, Spinner, Icon, Checkbox, Divider } from "renos-ui";
import { StyledButton, StyledTextAreaOutofStock } from "./styled";
import { useContext } from "react";
import ModalCancelOrderContext from "providers/ModalCancelOrder";

const ModalRejectOutOfStock = ({ isOpen, onClose }) => {
  const {
    products,
    isLoading,
    onReject,
    onOpenParent,
    payloadReject,
    setPayloadReject,
  } = useContext(ModalCancelOrderContext);

  const onChangeReason = (event) => {
    setPayloadReject((prev) => ({
      ...prev,
      order_cancellation_reason: event.target.value,
    }));
  };

  const handleClose = () => {
    onClose();
    onOpenParent();
  };

  return (
    <Dialog isVisible={isOpen} onClose={onClose} verticalCentered>
      <Flex flexDirection="column" gap="24px" width="496px">
        <Flex justifyContent="space-between">
          <Text variant="heading3" weight="bold">
            Pilih Produk yang Kosong
          </Text>
          <Flex cursor="pointer" onClick={handleClose}>
            <Icon name="Close-outline" size={24} color="black50" />
          </Flex>
        </Flex>
        <Divider />
        {products.map((item, index) => (
          <Flex
            gap="8px"
            alignItems="center"
            borderBottomColor="black10"
            borderBottomWidth="1px"
            borderBottomStyle="solid"
            pb="16px"
          >
            <Flex display="none">
              <Checkbox />
            </Flex>
            <Flex
              as="img"
              src={item.product_image_url}
              width="64px"
              height="64px"
              borderRadius="8px"
            />
            <Flex flexDirection="column">
              <Text>
                {item.product_name}
                {item.variant_name && ` - ${item.variant_name}`}
              </Text>
              <Text variant="subtitle" weight="bold">
                {`${item.order_product_quantity} x ${item.formatted_order_product_unit_price}`}
              </Text>
            </Flex>
          </Flex>
        ))}
        <StyledTextAreaOutofStock
          value={payloadReject.order_cancellation_reason}
          onChange={onChangeReason}
          label="Pesan untuk Pembeli"
          placeholder="Tulis pesan untuk pembeli..."
          disabled={isLoading}
          maxLength={150}
          showCount
        />
        <StyledButton
          isBlock
          onClick={onReject}
          disabled={isLoading || !payloadReject.order_cancellation_reason}
          preffix={isLoading && <Spinner color="white" size="8px" />}
        >
          Tolak Pesanan
        </StyledButton>
      </Flex>
    </Dialog>
  );
};

export default ModalRejectOutOfStock;
