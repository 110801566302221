import { Divider, Flex, Skeleton, range } from "renos-ui";

const SettingLoading = () => {
  return (
    <>
      <Divider />
      <Flex gap="24px">
        <Flex flexGrow={1} flexDirection="column" gap="16px">
          {range(3).map((_, indexFirstColumn) => {
            return (
              <Flex
                gap="8px"
                flexDirection="column"
                key={`first-column${indexFirstColumn}`}
              >
                <Skeleton />
                <Skeleton />
              </Flex>
            );
          })}
        </Flex>
        <Divider orientation="vertical" />
        <Flex flexGrow={1} flexDirection="column" gap="16px">
          {range(2).map((_, indexSecondColumn) => (
            <Flex gap="16px" key={`second-column${indexSecondColumn}`}>
              <Flex flexDirection="column" width="50%" gap="8px">
                <Skeleton />
                <Skeleton />
              </Flex>
              <Flex flexDirection="column" width="50%" gap="8px">
                <Skeleton />
                <Skeleton />
              </Flex>
            </Flex>
          ))}
        </Flex>
        <Flex gap="12px" flexGrow={1} flexDirection="row-reverse">
          <Skeleton width="40px" height="40px" />
          <Skeleton width="163px" height="40px" />
        </Flex>
      </Flex>
    </>
  );
};

export default SettingLoading;
