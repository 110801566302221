import { useEffect, useMemo, useContext, useState } from "react";
import MobileLayout from "layouts/MobileLayout";
import { useHistory } from "react-router-dom";
import {
  Flex,
  Icon,
  Text,
  Input,
  Button,
  isEmpty,
  useDisclosure,
  Divider,
  Box,
  useToast,
  Toggle,
  Grid,
  Drawer,
} from "renos-ui";
import { useDeliveryContext } from "store/seller-fleet/reducer";
import constants from "store/seller-fleet/constants";
import ModalCategories from "./modalCategories";
import FixedBottomContainer from "../components/FixedBottomContainer";
import ModalGroupWeight from "./modalGroupWeight";
import {
  useGetSellerFleet,
  useSingleSaveSellerFleet,
} from "services/logistics/sellerFleet";
import StoreContext from "providers/StoreProvider";
import useValidation from "../hooks/useValidation";
import ModalAdvanceOption from "./modalAdvanceOption";
import { showModalData } from "../utlis";

const ShippingRates = () => {
  const {
    storeData: {
      data: { id: storeId },
    },
  } = useContext(StoreContext);

  const { state, dispatch } = useDeliveryContext();
  const { dataRates, dataCoverage, selectedId } = state;
  const history = useHistory();
  const toast = useToast();
  const { validation, setValidation, handleError } = useValidation();
  const disclosureCategory = useDisclosure({ isOpen: false });
  const disclosureAddGroup = useDisclosure({ isOpen: false });
  const disclosureStaticModal = useDisclosure({ isOpen: false });
  const disclosureAdvanceOption = useDisclosure({ isOpen: false });
  const [staticModal, setStaticModal] = useState({
    title: "",
    description: "",
  });

  const { data: dataGetSellerFleet } = useGetSellerFleet();
  const { mutate: singleSave, isLoading: isLoadingSave } =
    useSingleSaveSellerFleet({
      onSuccess: (data) => {
        const { message, frontend_message } = data;

        if (data.data.status === true) {
          dispatch({
            type: constants.RESET_TO_INTIAL_STATE,
          });

          toast({
            label:
              frontend_message ||
              message ||
              "Pengaturan kurir toko berhasil disimpan.",
            placement: "bottom",
          });

          setTimeout(() => {
            history.push("/account/list-setting");
          }, 2000);
        } else {
          toast({
            label: data.data.message || "Pengaturan kurir toko gagal disimpan.",
            placement: "bottom",
            backgroundColor: "red50",
          });
        }
      },
      onError: (data) => {
        const {
          response: {
            data: { frontend_message, message },
          },
        } = data;
        toast({
          label:
            frontend_message ||
            message ||
            "Pengaturan kurir toko gagal disimpan.",
          placement: "bottom",
          backgroundColor: "red50",
        });
      },
    });

  const isDataFilled = useMemo(() => {
    let totalActive = 0;
    // eslint-disable-next-line
    const tempCategories = dataRates.categories.map((dataCategories) => ({
      ...dataCategories,
      categories: dataCategories.categories.map((dataCategoriesLvl2) => ({
        ...dataCategoriesLvl2,
        categories: dataCategoriesLvl2.categories.map((dataCategoriesLvl3) => {
          if (dataCategoriesLvl3.active) totalActive++;

          return dataCategoriesLvl3;
        }),
      })),
    }));
    if (totalActive > 0) return true;

    return false;
  }, [dataRates]);

  const toggleDataRates = () => {
    dispatch({
      type: constants.SET_TOGGLE_CATEGORIES,
      payload: {
        ...dataRates,
        active: !dataRates.active,
        categories: [],
      },
    });
  };

  const handleChangeType = (type) => {
    dispatch({
      type: constants.SET_TYPE_CALCULATION,
      payload: {
        ...dataRates,
        calculation: {
          ...dataRates.calculation,
          type: type,
          group: [{ min: 1 }],
        },
      },
    });
  };

  const handleChangeMultiple = (name, value) => {
    setValidation({
      ...validation,
      dataRates: {
        ...validation.dataRates,
        multiple: {
          ...validation.multiple,
          [name]: false,
        },
      },
    });

    dispatch({
      type: constants.SET_MULTIPLE_OF_WEIGHT,
      payload: {
        ...dataRates,
        calculation: {
          ...dataRates.calculation,
          multiple: {
            ...dataRates.calculation.multiple,
            [name]: value,
          },
        },
      },
    });
  };

  const handleChangeGroup = (name, value, indexGroup) => {
    const tempGroup = dataRates.calculation.group;
    const tempValidationGroup = validation.dataRates.group;

    if (tempValidationGroup[indexGroup]) {
      tempValidationGroup[indexGroup][name] = false;
    }

    const tempValue = Number.isNaN(value) ? 0 : value;

    if (tempGroup[indexGroup + 1] !== undefined && name === "max") {
      if (tempGroup[indexGroup] && tempGroup[indexGroup + 1]) {
        tempGroup[indexGroup + 1].min = tempValue + 1;
      }
    }

    setValidation({
      ...validation,
      dataRates: {
        ...validation.dataRates,
        group: [...tempValidationGroup],
      },
    });

    if (tempGroup[indexGroup]) {
      tempGroup[indexGroup][name] = tempValue;
    }

    dispatch({
      type: constants.SET_WEIGHT_GROUP,
      payload: {
        ...dataRates,
        calculation: {
          ...dataRates.calculation,
          group: [...tempGroup],
        },
      },
    });
  };

  const isAllowAddGroup = useMemo(() => {
    const filterMaxWeight = dataRates.calculation?.group?.filter(
      (groupFilter) => groupFilter.max === undefined || !groupFilter.max
    );

    const filterPrice = dataRates.calculation?.group?.filter(
      (groupFilter) =>
        groupFilter.price === undefined ||
        (groupFilter.price !== 0 && !groupFilter.price)
    );

    return filterMaxWeight?.length === 0 && filterPrice?.length === 0;
  }, [dataRates.calculation]);

  const handleSubmit = () => {
    const isHasError = handleError();

    if (!isHasError) {
      const formattedCoverages = dataCoverage.map((coverage) => ({
        estimate_days_end: parseInt(coverage.estimateDays.split("-").pop()),
        estimate_days_start: parseInt(coverage.estimateDays.split("-").shift()),
        province_id: coverage.selectedProvince.province_id,
        province_name: coverage.selectedProvince.province_name,
        cities: coverage.selectedCity.map((city) => ({
          ...city,
          districts: coverage.selectedDistrict
            .filter((filterDistrict) => filterDistrict.city_id === city.city_id)
            .map((district) => ({
              ...district,
              lower_districts: [],
            })),
        })),
      }));

      const formattedPayload = {
        courier_service_id: dataGetSellerFleet?.data?.id,
        settings: {
          categories: dataRates.categories,
          courier_service_id: dataGetSellerFleet?.data?.id,
          coverages: formattedCoverages,
          store_id: storeId,
        },
      };

      if (selectedId) {
        formattedPayload.id = parseInt(selectedId);
      }

      if (dataRates.calculation.type === "multiple") {
        formattedPayload.settings.weight_multiple = {
          base_price: parseInt(dataRates.calculation.multiple.price),
          base_weight: parseInt(dataRates.calculation.multiple.weight),
        };
      } else if (dataRates.calculation.type === "group") {
        formattedPayload.settings.weight_groups =
          dataRates.calculation.group.map((group) => ({
            maximum_weight: parseInt(group.max),
            minimum_weight: parseInt(group.min),
            shipping_cost: parseInt(group.price),
          }));
      } else {
        formattedPayload.settings.advanced_options =
          dataRates.calculation.advanced_options.map((advanced, index) => {
            return {
              ...advanced,
              courier_service_setting_id: parseInt(selectedId || "0"),
              id: advanced?.id || index + 1,
              condition_base_weight_end:
                advanced?.condition_base_weight_end === false
                  ? 0
                  : advanced?.condition_base_weight_end,
              condition_base_weight_start:
                advanced?.condition_base_weight_start === false
                  ? 0
                  : advanced?.condition_base_weight_start,
              condition_base_tarif:
                advanced?.condition_base_tarif === false
                  ? 0
                  : advanced?.condition_base_tarif,
            };
          });
      }
      singleSave(formattedPayload);
    }
  };

  useEffect(() => {
    if (isEmpty(dataRates.calculation.type)) {
      handleChangeType("multiple");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataRates.calculation.type]);

  useEffect(() => {
    if (isEmpty(dataCoverage[0].estimateDays)) {
      dispatch({
        type: constants.RESET_TO_INTIAL_STATE,
      });
      history.push("list-setting");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showStaticModal = (type) => {
    const modalData = showModalData[type] || showModalData.default;
    setStaticModal({
      ...staticModal,
      title: modalData.title,
      description: modalData.description,
    });
    disclosureStaticModal.onOpen();
  };

  const backgroundColor = dataRates.active ? "blue5" : "black5";

  return (
    <MobileLayout
      header={{
        title: "Atur Kelompok Berat",
        withBack: (
          <Icon
            name="Close-outline"
            color="black50"
            cursor="pointer"
            onClick={() => {
              history.goBack();
            }}
          />
        ),
      }}
    >
      <Flex flexDirection="column" backgroundColor="black5" flex="1" gap="8px">
        {/* component  */}
        <Flex backgroundColor="white" padding="16px">
          <Flex
            height="48px"
            backgroundColor={backgroundColor}
            width="100%"
            borderRadius="8px"
            alignItems="center"
          >
            <Flex padding="16px" width="100%" justifyContent="space-between">
              <Flex flexGrow="1" gap="8px" alignItems="center">
                <Icon
                  width="16px"
                  height="16px"
                  name="Info-solid"
                  color={dataRates.active ? "blue75" : "black50"}
                  cursor="pointer"
                  onClick={() =>
                    showStaticModal(
                      dataRates.active
                        ? "categoriesActive"
                        : "categoriesNonActive"
                    )
                  }
                />
                <Text
                  weight="bold"
                  color={dataRates.active ? "blue75" : "black100"}
                  variant="caption"
                >
                  {dataRates.active ? "Semua Kategori" : "Kategori Pilihan"}
                </Text>
              </Flex>
              <Box onClick={toggleDataRates}>
                <Toggle checked={dataRates.active} />
              </Box>
            </Flex>
          </Flex>
        </Flex>
        {/* component  */}

        {/* input kategori */}
        {dataRates.active && (
          <Flex flexDirection="column" backgroundColor="white" padding="16px">
            {dataRates.active && (
              <Input
                size="small"
                label="Provinsi"
                placeholder="bebrapa kategori kepilih"
                sufix={
                  <Icon
                    name={isDataFilled ? "Edit-solid" : "Right-outline"}
                    color={isDataFilled ? "blue50" : "black50"}
                    size={16}
                  />
                }
                onClick={disclosureCategory.onToggle}
                value={
                  isDataFilled
                    ? "Beberapa kategori telah dipilih"
                    : "Pilih Kategori"
                }
                readOnly
              />
            )}
          </Flex>
        )}
        {/* input kategori */}

        {/* Pilih Cara Penghitungan */}
        <Flex
          flexDirection="column"
          backgroundColor="white"
          padding="16px"
          gap="16px"
        >
          <Text variant="caption" weight="bold">
            Pilih Cara Penghitungan
          </Text>
          <Divider />
          {/* Berdasarkan Kelipatan Berat */}
          <Flex alignItems="center" justifyContent="space-between">
            <Input.Radio
              size="small"
              checked={dataRates.calculation.type === "multiple"}
              onClick={() => handleChangeType("multiple")}
            >
              <Text variant="caption" color="black50" weight="bold">
                Berdasarkan Kelipatan Berat
              </Text>
            </Input.Radio>

            <Icon
              width="16px"
              height="16px"
              name="Info-solid"
              color="black50"
              cursor="pointer"
              onClick={() => showStaticModal("kelipatanBerat")}
            />
          </Flex>

          {dataRates.calculation.type === "multiple" && (
            <Flex flexDirection="column" gap="16px" id="multiple">
              <Input.Number
                size="small"
                isError={validation.dataRates.multiple.weight}
                error={validation.dataRates.multiple.weightText}
                onChange={(event) => handleChangeMultiple("weight", event)}
                name="weight"
                value={dataRates.calculation.multiple.weight}
                sufix={
                  <Text color="black50" weight="bold">
                    g
                  </Text>
                }
                placeholder="Masukkan Kelipatan Berat"
              />
              <Input.Number
                label="Ongkos Kirim"
                size="small"
                isError={validation.dataRates.multiple.price}
                error={validation?.dataRates?.multiple.weightTextPrice}
                onChange={(event) => handleChangeMultiple("price", event)}
                name="price"
                value={dataRates.calculation.multiple.price?.toString()}
                prefix={
                  <Text color="black50" weight="bold" variant="caption">
                    Rp
                  </Text>
                }
                placeholder="Masukkan Tarif"
              />
            </Flex>
          )}
          {/* End Berdasarkan Kelipatan Berat */}

          {/* Berdasarkan Kelompok berat */}
          <Flex alignItems="center" justifyContent="space-between">
            <Input.Radio
              size="small"
              checked={dataRates.calculation.type === "group"}
              onClick={() => handleChangeType("group")}
            >
              <Text variant="caption" color="black50" weight="bold">
                Berdasarkan Kelompok Berat
              </Text>
            </Input.Radio>
            <Icon
              width="16px"
              height="16px"
              name="Info-solid"
              color="black50"
              cursor="pointer"
              onClick={() => showStaticModal("kelompokBerat")}
            />
          </Flex>

          {dataRates.calculation.type === "group" && (
            <Flex
              flexDirection="column"
              gap="16px"
              id={`group0`}
              alignItems="center"
            >
              <Grid gridTemplateColumns="44% 8% 44%" gridGap="6px">
                <Input.Number
                  size="small"
                  onChange={(event) => handleChangeGroup("min", event, 0)}
                  name="min"
                  isError={validation?.dataRates?.group[0]?.min}
                  error={validation?.dataRates?.group[0]?.minText}
                  value={dataRates.calculation.group[0].min}
                  sufix={
                    <Text color="black50" weight="bold">
                      g
                    </Text>
                  }
                  placeholder="Berat Minumum"
                />
                <Flex marginX="auto" alignSelf="center">
                  <Text weight="bold"> - </Text>
                </Flex>
                <Input.Number
                  size="small"
                  isError={validation?.dataRates?.group[0]?.max}
                  error={validation?.dataRates?.group[0]?.maxText}
                  onChange={(event) => handleChangeGroup("max", event, 0)}
                  name="max"
                  value={dataRates.calculation.group[0].max}
                  sufix={
                    <Text color="black50" weight="bold">
                      g
                    </Text>
                  }
                  placeholder="Berat Maksimum"
                />
              </Grid>

              <Input.Number
                isError={validation?.dataRates?.group[0]?.price}
                error={validation?.dataRates?.group[0]?.priceText}
                onChange={(event) => handleChangeGroup("price", event, 0)}
                name="price"
                value={
                  dataRates?.calculation?.group[0]?.price === 0
                    ? "0"
                    : dataRates?.calculation?.group[0]?.price
                }
                prefix={
                  <Text color="black50" weight="bold" variant="caption">
                    Rp
                  </Text>
                }
                placeholder="Masukkan Tarif"
              />

              <Flex
                gap="8px"
                alignSelf="start"
                cursor={isAllowAddGroup ? "pointer" : "not-allowed"}
                onClick={() => disclosureAddGroup.onOpen()}
                alignItems="center"
              >
                <Icon name="Plus-outline" size={16} color="blue50" />
                <Text weight="bold" variant="caption" color="blue50">
                  Tambah Kelompok Berat
                </Text>
              </Flex>
            </Flex>
          )}
          {/*End Berdasarkan Kelompok berat */}

          {/* opsi lanjutan */}
          <Flex alignItems="center" justifyContent="space-between">
            <Input.Radio
              size="small"
              checked={dataRates.calculation.type === "advanced_options"}
              onClick={() => handleChangeType("advanced_options")}
            >
              <Text variant="caption" color="black50" weight="bold">
                Opsi Lanjutan
              </Text>
            </Input.Radio>
            <Icon
              width="16px"
              height="16px"
              name="Info-solid"
              color="black50"
              cursor="pointer"
              onClick={() => showStaticModal("opsiLanjutan")}
            />
          </Flex>

          {dataRates.calculation.type === "advanced_options" && (
            <Input
              size="small"
              placeholder="Atur berdasarkan kondisi"
              sufix={
                <Icon
                  name={
                    dataRates.calculation.advanced_options.length > 1
                      ? "Edit-solid"
                      : "Right-outline"
                  }
                  color={
                    dataRates.calculation.advanced_options.length > 1
                      ? "blue50"
                      : "black50"
                  }
                  size={16}
                />
              }
              onClick={disclosureAdvanceOption.onToggle}
              value={
                dataRates.calculation.advanced_options.length > 1
                  ? "Beberapa kondisi telah diatur"
                  : "Atur berdasarkan kondisi"
              }
              readOnly
            />
          )}

          {/* end opsi lanjutan */}
        </Flex>
        {/* Pilih Cara Penghitungan */}

        {/* save kelompok berat */}
        <FixedBottomContainer>
          <Flex flexDirection="column" gap="16px" width="100%" padding="16px">
            <Button
              isBlock
              size="medium"
              onClick={handleSubmit}
              disabled={(dataRates.active && !isDataFilled) || isLoadingSave}
            >
              <Text variant="caption" weight="bold">
                Simpan
              </Text>
            </Button>
          </Flex>
        </FixedBottomContainer>
      </Flex>

      <ModalCategories
        isOpen={disclosureCategory.isOpen}
        onClose={disclosureCategory.onClose}
        dataCategories={dataRates.categories}
        isDataFilled={isDataFilled}
        setDataCategories={(dataCategory) =>
          dispatch({
            type: constants.SET_ACTIVE_CATEGORIES,
            payload: {
              ...dataRates,
              categories: [...dataCategory],
            },
          })
        }
        dataRates={dataRates}
      />

      <ModalGroupWeight
        isOpen={disclosureAddGroup.isOpen}
        onClose={disclosureAddGroup.onClose}
        handleChangeGroup={(name, event, index) =>
          handleChangeGroup(name, event, index)
        }
        isAllowAddGroup={isAllowAddGroup}
        submitGroup={() => disclosureAddGroup.onClose()}
      />

      <ModalAdvanceOption
        isOpen={disclosureAdvanceOption.isOpen}
        onClose={disclosureAdvanceOption.onClose}
        submitGroup={() => disclosureAdvanceOption.onClose()}
      />

      <Drawer
        isVisible={disclosureStaticModal.isOpen}
        onClose={disclosureStaticModal.onClose}
        placement="bottom"
        closable={true}
      >
        <Flex flexDirection="column" gap="4px" marginTop="8px">
          <Text weight="bold" variant="caption">
            {staticModal.title}
          </Text>
          <Text variant="caption" color="black50">
            {staticModal.description}
          </Text>
        </Flex>
      </Drawer>
    </MobileLayout>
  );
};

export default ShippingRates;
