import { Button, Dialog, Flex, Spinner, Text } from "renos-ui";

const DeleteConfirmation = ({ onClose, isOpen, onReject, isLoading }) => {
  return (
    <Dialog isVisible={isOpen} onClose={onClose} verticalCentered width="300px">
      <Flex
        width="100%"
        fontFamily="sans-serif"
        flexDirection="column"
        rowGap="24px"
      >
        <Flex flexDirection="column" rowGap="8px" flexWrap="wrap">
          <Text variant="heading3" weight="bold">
            Akhiri Diskon
          </Text>
          <Flex width="300px">
            <Text variant="body">
              Apakah anda yakin untuk mengakhiri diskon?
            </Text>
          </Flex>
        </Flex>
        <Flex flexDirection="row" justifyContent="space-between" gap="16px">
          <Button isBlock size="large" variant="tertiary" onClick={onClose}>
            Batal
          </Button>
          <Button
            isBlock
            size="large"
            variant="primary"
            onClick={onReject}
            disabled={isLoading}
            preffix={isLoading && <Spinner color="white" size="8px" />}
          >
            Ya, Akhiri
          </Button>
        </Flex>
      </Flex>
    </Dialog>
  );
};

export default DeleteConfirmation;
