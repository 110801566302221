import { Box, Button, Divider, Each, Flex, Grid, Icon, Text } from "renos-ui";
import { useGetBiddingIncomingRequests } from "services/bidding";
import EmptyList from "../EmptyList";
import useQueryParams from "hooks/useQueryParams";
import LoadingIncomingRequest from "../LoadingIncomingRequest";
import desktopUrl from "constants/desktopUrl";
import { useHistory } from "react-router-dom";

const IncomingRequest = () => {
  const query = useQueryParams();
  const history = useHistory();

  const { data, isFetching } = useGetBiddingIncomingRequests({
    query: query.get("q"),
    ordering: query.get("order"),
  });

  const incomingRequests = data?.data || [];

  if (isFetching) return <LoadingIncomingRequest />;

  if (!incomingRequests.length) return <EmptyList />;

  const formattedStartDate = (timestamp) => {
    const [date, timeWithZone] = new Intl.DateTimeFormat("id-ID", {
      dateStyle: "medium",
      timeStyle: "long",
    })
      .format(new Date(timestamp * 1000))
      .split(", ");

    const [time, zone] = timeWithZone.split(" ");
    const [hour, minute] = time.split(".");

    return `${date} - ${hour}:${minute} ${zone}`;
  };

  const formattedEndDate = (timestamp) =>
    new Intl.DateTimeFormat("id-ID", {
      dateStyle: "medium",
    }).format(new Date(timestamp * 1000));

  return (
    <Flex flexDirection="column" gap="24px" marginTop={24}>
      <Each
        of={incomingRequests}
        render={(request) => (
          <Flex
            flexDirection="column"
            gap="24px"
            backgroundColor="white"
            borderRadius={16}
            py={16}
            px={24}
            boxShadow="0px 4px 4px rgba(20, 20, 32, 0.05)"
          >
            <Flex
              justifyContent="space-between"
              alignItems="center"
              paddingBottom={16}
              borderBottomWidth={1}
              borderBottomStyle="solid"
              borderBottomColor="black10"
            >
              <Flex alignItems="center" gap="8px">
                <Text color="black75" weight="bold">
                  {request.user_address_receiver_name}
                </Text>
                <Box
                  width={8}
                  height={8}
                  borderRadius="100%"
                  backgroundColor="black10"
                />
                <Text>{formattedStartDate(request.request_start_date)}</Text>
              </Flex>
              <Button
                size="medium"
                variant="secondary"
                onClick={() =>
                  history.push(
                    `${desktopUrl.BIDDING}/${request.request_number}`
                  )
                }
              >
                Lihat Detail Permintaan
              </Button>
            </Flex>
            <Flex gap="16px">
              <Flex alignItems="center" gap="8px">
                <Icon name="Time-solid" size={18} color="black50" />
                <Text>
                  Bidding berakhir pada
                  <strong> {formattedEndDate(request.request_end_date)}</strong>
                </Text>
              </Flex>
              <Divider orientation="vertical" />
              <Flex alignItems="center" gap="8px">
                <Icon name="Wallet-solid" size={18} color="black50" />
                <Text>
                  Total Budget <strong>{request.total_budget}</strong>
                </Text>
              </Flex>
              <Divider orientation="vertical" />
              <Flex alignItems="center" gap="8px">
                <Icon name="Location-solid" size={18} color="black50" />
                <Text>
                  Lokasi
                  <strong>
                    {` ${request.district_name}, ${request.city_name}, ${request.province_name}`}
                  </strong>
                </Text>
              </Flex>
            </Flex>
            <Grid gridTemplateColumns="1fr 1fr 1fr" gap="16px">
              <Each
                of={request.request_products}
                render={(product) => (
                  <Flex
                    padding={16}
                    flexDirection="column"
                    gap="4px"
                    borderRadius={8}
                    borderWidth={1}
                    borderStyle="solid"
                    borderColor="black10"
                  >
                    <Text weight="bold" color="black75" lineClamp={1}>
                      {product.product_name}
                    </Text>
                    <Text variant="caption" color="black75">
                      {product.product_quantity}
                    </Text>
                  </Flex>
                )}
              />
            </Grid>
          </Flex>
        )}
      />
    </Flex>
  );
};

export default IncomingRequest;
