import ENDPOINTS from "constants/endpoints";
import { useQuery, useMutation } from "react-query";
import client from "../client";

const getProvince = async () => {
  const { data } = await client.get(ENDPOINTS.provinces);

  return data;
};

export const useGetProvince = (extraParams) => {
  return useQuery(["getProvince"], getProvince, {
    ...extraParams,
    enabled: true,
  });
};

const getCity = async (body) => {
  const { data } = await client.get(ENDPOINTS.city, body);

  return data;
};

export const useGetCity = (extraParams) =>
  useMutation((body) => getCity(body), {
    ...extraParams,
  });

const getDistrict = async (body) => {
  const { data } = await client.get(ENDPOINTS.district, body);

  return data;
};

export const useGetDistrict = (extraParams) =>
  useMutation((body) => getDistrict(body), {
    ...extraParams,
  });

const getSubDistrict = async (body) => {
  const { data } = await client.get(ENDPOINTS.sub_district, body);

  return data;
};

export const useGetSubDistrict = (extraParams) =>
  useMutation((body) => getSubDistrict(body), {
    ...extraParams,
  });
