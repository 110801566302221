import styled from "styled-components";
import { Swiper } from "swiper/react";

export const StyledSwiper = styled(Swiper)`
  position: relative;
  .swiper-wrapper {
    position: relative;
    width: calc(100vw - 16px);
    padding-top: 60px;
    padding-bottom: 40px;
    height: fit-content;
    div {
      box-sizing: border-box;
    }
  }
`;
