import MobileLayout from "layouts/MobileLayout";
import React from "react";
import { Icon, Flex, Text, useToast, colors, useDisclosure } from "renos-ui";
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  useGetDocument,
  useDownloadDocument,
} from "services/settings/document";
import mobileUrl from "constants/mobileUrl";
import DrawerAction from "./DrawerAction";

const AccountDocument = () => {
  const showDisclosure = useDisclosure({ isOpen: false });
  const [type, setIsType] = useState("");
  const history = useHistory();
  const [item, setItem] = useState([
    {
      id: "ktp",
      title: "Foto KTP",
      subtitle: "Pastikan foto KTP terlihat jelas.",
      link: null,
    },
    {
      id: "siup",
      title: "Nomor Induk Berusaha (NIB)",
      subtitle: "Tidak lebih dari 60 hari.",
      link: null,
    },
    {
      id: "npwp",
      title: "NPWP Perusahaan",
      subtitle: "Salinan Registrasi PPN Bersertifikat.",
      link: null,
    },
    {
      id: "bank_book",
      title: "Buku Tabungan",
      subtitle: "Buku Tabungan Perusahaan.",
      link: null,
    },
  ]);

  const toast = useToast();

  const handleEdit = (type) => {
    showDisclosure.onOpen();
    setIsType(type);
  };

  const {
    isLoading: isLoadingDocument,
    // eslint-disable-next-line
    refetch,
    data: dataGetDocument,
  } = useGetDocument({
    onError: () => {
      toast({
        label: "Gagal mendapatkan document.",
        placement: "top",
      });
    },
  });

  const handleDownload = (category) => {
    mutateDownload({ doctype: category });
  };

  useEffect(() => {
    if (!isLoadingDocument) {
      const tempLinkDoc = {
        ktp: dataGetDocument?.data?.ktp,
        npwp: dataGetDocument?.data?.npwp,
        siup: dataGetDocument?.data?.siup,
        bank_book: dataGetDocument?.data?.bank_book,
      };
      const updatedItems = [];
      item.forEach((item) => {
        const key = item.id;
        updatedItems.push({ ...item, link: tempLinkDoc[key] });
      });
      setItem([...updatedItems]);
    }
    // eslint-disable-next-line
  }, [isLoadingDocument]);

  const handleForm = (type) => {
    history.push(mobileUrl.DOCUMENT_FORM, { data: type, mode: "add" });
  };

  const handleEditForm = (type) => {
    history.push(mobileUrl.DOCUMENT_FORM, { data: type, mode: "edit" });
  };

  const handleFormBank = (type) => {
    history.push(mobileUrl.DOCUMENT_FORM_BANK, { data: type, mode: "add" });
  };

  const handleShowFile = (type) => {
    history.push(mobileUrl.DOCUMENT_FORM_BANK, {
      data: type,
      mode: "showFile",
    });
  };

  const { mutate: mutateDownload } = useDownloadDocument({
    onSuccess: (data) => {
      let typeBook;

      switch (data.type) {
        case "image/jpeg":
          typeBook = `Dokumen Renos.jpeg`;
          break;
        case "image/jpg":
          typeBook = `Dokumen Renos.jpg`;
          break;
        case "image/png":
          typeBook = `Dokumen Renos.png`;
          break;
        case "application/pdf":
          typeBook = `Dokumen Renos.pdf`;
          break;
        default:
          break;
      }
      const outputFilename = typeBook;
      const url = URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", outputFilename);
      document.body.appendChild(link);
      link.click();
    },
    onError: () => {
      toast({
        label: "Data dokumen gagal didownload",
        placement: "top",
      });
    },
  });

  return (
    <MobileLayout
      header={{
        title: "Dokumen",
        withBack: true,
        withoutGoBack: true,
        onBack: () => history.push("/account"),
      }}
    >
      <Flex backgroundColor="black5" p="16px" gap="16px" flexDirection="column">
        <Flex flexDirection="column">
          <Text weight="bold">Informasi Perusahaan</Text>
          <Text variant="caption" color="black50">
            Lengkapi data dan verifikasi dokumen dengan format file .JPG, .JPEG,
            .PNG atau .PDF.
          </Text>
        </Flex>

        {item.map((list, indexDoc) => (
          <Flex
            key={`type-${indexDoc}`}
            width="100%"
            justifyContent="space-between"
            alignItems="center"
            backgroundColor="white"
            p="16px"
            boxSizing="border-box"
            borderRadius="8px"
          >
            <Flex alignItems="center">
              <Flex flexDirection="column" gap="6px">
                <Flex>
                  <Text weight="bold">{list.title}</Text>
                </Flex>
                <Flex gap="8px">
                  <Flex>
                    <Text>{list.subtitle}</Text>
                  </Flex>
                </Flex>
                <Flex
                  onClick={() => handleDownload(list.id)}
                  target="_blank"
                  cursor="pointer"
                >
                  {list.link !== null && (
                    <Text variant="caption" color="blue50">
                      {list.id === "ktp" && dataGetDocument?.data?.ktp !== null
                        ? "Unduh Dokumen KTP"
                        : list.id === "npwp" &&
                          dataGetDocument?.data?.npwp !== null
                        ? "Unduh Dokumen NPWP"
                        : list.id === "siup" &&
                          dataGetDocument?.data?.siup !== null
                        ? "Unduh Dokumen NIB"
                        : list.id === "bank_book" &&
                          dataGetDocument?.data?.bank_book !== null
                        ? "Unduh Dokumen Bank"
                        : ""}
                    </Text>
                  )}
                </Flex>
              </Flex>
            </Flex>

            {!isLoadingDocument && list.link === null ? (
              <Flex
                width="24px"
                height="24px"
                border={`solid 1px ${colors.blue50}`}
                borderRadius="100%"
                cursor="pointer"
                alignItems="center"
                justifyContent="center"
                onClick={() =>
                  list.id === "bank_book"
                    ? handleFormBank(list)
                    : handleForm(list)
                }
                gap="16px"
              >
                <Icon name="Right-outline" color="blue50" size={16} />
              </Flex>
            ) : (
              <Flex
                width="24px"
                height="24px"
                alignItems="center"
                justifyContent="center"
                onClick={() => handleEdit(list)}
                gap="16px"
              >
                <Icon name="Vertical-outline" size={16} />
              </Flex>
            )}
          </Flex>
        ))}
      </Flex>

      <DrawerAction
        disclosure={showDisclosure}
        onClick={() =>
          type.id === "bank_book" ? handleShowFile(type) : handleEditForm(type)
        }
        type={type}
      />
    </MobileLayout>
  );
};

export default AccountDocument;
