import React from "react";
import { Button, Flex, Text } from "renos-ui";
import { useHistory } from "react-router-dom";

const NotFoundPage = () => {
  const history = useHistory();

  return (
    <Flex
      width="100%"
      height="100%"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      gap="24px"
    >
      <Flex
        as="img"
        alt="not-found"
        src="/assets/images/error-state/state-404.svg"
      />
      <Flex flexDirection="column" width="328px" textAlign="center" gap="8px">
        <Text variant="heading3" weight="bold">
          Maaf, tujuanmu nggak ada
        </Text>
        <Text color="black50">
          Wah, sepertinya halaman yang kamu cari tidak ada atau salah. Yuk,
          kembali ke beranda.
        </Text>
      </Flex>
      <Flex width="328px">
        <Button isBlock onClick={() => history.push("/")}>
          Kembali
        </Button>
      </Flex>
    </Flex>
  );
};

export default NotFoundPage;
