import StoreContext from "providers/StoreProvider";
import { useContext, useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Drawer,
  Flex,
  Icon,
  Input,
  Skeleton,
  Text,
  range,
  useDisclosure,
} from "renos-ui";
import { useGetListFilterCourier } from "services/order-management";
import FailLoadData from "widgets/mobile/FailLoadData";

const SelectCourier = ({ value, setValue }) => {
  const disclosure = useDisclosure({ isOpen: false });
  const [selected, setSelected] = useState([]);

  const {
    storeData: {
      data: { id: storeId },
    },
  } = useContext(StoreContext);

  const {
    data: dataCourier,
    isLoading,
    isFetching,
    isError,
    refetch,
  } = useGetListFilterCourier({ available_courier_store: storeId });

  const handleCheck = (id) => {
    if (selected.includes(id)) {
      setSelected(selected.filter((courier) => courier !== id));
    } else {
      setSelected(selected.concat(id));
    }
  };

  const handleSave = () => {
    setValue([...selected]);
    disclosure.onClose();
  };

  const textValue = useMemo(
    () => (value.length === 0 ? "Kurir" : `${value.length} Kurir dipilih`),
    [value]
  );

  const isChecked = (id) => {
    return selected.includes(id);
  };

  useEffect(() => {
    setSelected([...value]);
    // eslint-disable-next-line
  }, [disclosure.isOpen]);

  return (
    <Flex width="100%">
      <Input
        onClick={disclosure.onOpen}
        value={textValue}
        readOnly
        size="small"
        sufix={
          <Icon
            onClick={disclosure.onOpen}
            name="Down-outline"
            color="black75"
            size="20px"
          />
        }
      />
      <Drawer
        isVisible={disclosure.isOpen}
        onClose={disclosure.onClose}
        placement="bottom"
      >
        <Flex flexDirection="column" width="100%" gap="16px" height="100%">
          <Flex justifyContent="center">
            <Flex
              width="40px"
              height="3px"
              borderRadius="16px"
              backgroundColor="black25"
            />
          </Flex>
          <Flex justifyContent="space-between" gap="8px" alignItems="end">
            <Flex gap="8px" alignItems="center">
              <Icon
                name="Close-outline"
                color="black50"
                size="24"
                onClick={disclosure.onClose}
              />
              <Text variant="subtitle" weight="bold">
                Kurir
              </Text>
            </Flex>
            {selected.length > 0 && (
              <Box cursor="pointer" onClick={() => setSelected([])}>
                <Text variant="caption" color="blue50" weight="bold">
                  Reset
                </Text>
              </Box>
            )}
          </Flex>
          {isError ? (
            <FailLoadData refetch={refetch} />
          ) : (
            <>
              <Flex flexDirection="column" paddingTop="6px">
                {isLoading || isFetching
                  ? range(4).map((_, indexSkeleton) => (
                      <Flex
                        key={`courier-skeleton-${indexSkeleton}`}
                        justifyContent="space-between"
                        gap="8px"
                        alignItems="center"
                        borderBottom="1px"
                        borderBottomStyle="solid"
                        borderBottomColor="black10"
                        paddingBottom={indexSkeleton === 4 ? 0 : "16px"}
                        paddingTop={indexSkeleton === 0 ? 0 : "16px"}
                      >
                        <Skeleton height="40px" width="calc(100% - 40px)" />
                        <Skeleton height="40px" width="40px" />
                      </Flex>
                    ))
                  : dataCourier?.data?.map((courier, indexCourier) => (
                      <Flex
                        key={`courier-${indexCourier}`}
                        justifyContent="space-between"
                        gap="8px"
                        alignItems="center"
                        borderBottom="1px"
                        borderBottomStyle="solid"
                        borderBottomColor="black10"
                        paddingBottom={
                          dataCourier.data.length === indexCourier ? 0 : "16px"
                        }
                        paddingTop={indexCourier === 0 ? 0 : "16px"}
                      >
                        <Text color="black75">{courier.name}</Text>
                        <Checkbox
                          checked={isChecked(courier.id)}
                          onChange={() => handleCheck(courier.id)}
                        />
                      </Flex>
                    ))}
              </Flex>
              <Button size="medium" isBlock onClick={handleSave}>
                Terapkan
              </Button>
            </>
          )}
        </Flex>
      </Drawer>
    </Flex>
  );
};

export default SelectCourier;
