import styled from "styled-components";
import { colors, Input, SelectInput } from "renos-ui";

export const StyledSelectInput = styled(SelectInput)`
  width: 144px;
  color: ${colors.black75};
`;

export const StyledInputRange = styled(Input)`
  width: 272px;
  color: ${colors.black75};
`;

export const StyledInput = styled(Input)`
  font-size: 14px;
  color: ${colors.black75};
  cursor: pointer;
`;
