import { Flex, Icon, Text, Drawer, useDisclosure } from "renos-ui";
import DownloadReport from "./component/DownloadReport";

const More = ({ disclosure: { isOpen, onClose } }) => {
  const disclosureDownload = useDisclosure({ isOpen: false });

  return (
    <Drawer isVisible={isOpen} onClose={onClose} placement="bottom">
      <Flex flexDirection="column" width="100%" gap="16px" height="100%">
        <Flex justifyContent="center">
          <Flex
            width="40px"
            height="3px"
            borderRadius="16px"
            backgroundColor="black25"
          />
        </Flex>
        <Flex justifyContent="space-between" gap="8px" alignItems="end">
          <Flex gap="8px" alignItems="center">
            <Icon
              name="Close-outline"
              color="black50"
              size="24"
              onClick={onClose}
            />
            <Text variant="subtitle" weight="bold">
              Lainnya
            </Text>
          </Flex>
        </Flex>
        <Flex
          alignItems="center"
          paddingTop="16px"
          onClick={disclosureDownload.onOpen}
        >
          <Text color="black75">Download Laporan</Text>
        </Flex>
      </Flex>
      <DownloadReport disclosure={disclosureDownload} onCloseMore={onClose} />
    </Drawer>
  );
};

export default More;
