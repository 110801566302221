import {
  Divider,
  Flex,
  Text,
  Input,
  Button,
  SelectInput,
  Spinner,
  useToast,
} from "renos-ui";
import ListTemplate from "./ListTemplate";
import { useMemo, useState } from "react";
import { DATA_CATEGORY, DATA_SHOWCASE } from "./constant";
import {
  useGenerateEditTemplate,
  useGetShowcase,
  useGetStatusEditTemplate,
} from "services/product-add-multiple";

const VariantSelector = () => {
  const toast = useToast();

  const [categoryType, setCategoryType] = useState("sales_information");
  const [showcaseType, setShowcaseType] = useState("all");
  const [dataTemplate, setDataTemplate] = useState([]);
  const [selectedTag, setSelectedTag] = useState();
  const [isManualLoading, setIsManualLoading] = useState(false);

  const { data: dataShowcase, isLoading: isShowcaseLoading } = useGetShowcase();

  const { mutate: generateTemplate, isLoading: isGenerateLoading } =
    useGenerateEditTemplate({
      onSuccess: (data) => {
        getStatusTemplate(data?.data?.uuid);
        setIsManualLoading(true);
      },
      onError: (data) => {
        const {
          response: {
            data: { frontend_message, message },
          },
        } = data;
        toast({
          label: frontend_message || message || "Gagal mengunduh file excel.",
          placement: "top",
          backgroundColor: "red50",
        });
      },
    });

  const { mutate: getStatusTemplate, isLoading: isGetStatusLoading } =
    useGetStatusEditTemplate({
      onSuccess: (data) => {
        if (data.data.status === "request_in_process") {
          setTimeout(() => {
            getStatusTemplate(data.data.uuid);
            setIsManualLoading(true);
          }, 3000);
        } else {
          setIsManualLoading(false);
          setDataTemplate([
            ...dataTemplate.concat({
              link: data?.data?.request_file,
              totalSKU: data?.data?.total_sku || 0,
              date: new Date(),
              type:
                categoryType === "sales_information"
                  ? "Informasi Penjualan"
                  : "Informasi Dasar",
              category:
                showcaseType === "all"
                  ? "Semua Produk"
                  : showcaseType === "untagged"
                    ? "Di Luar Etalase"
                    : selectedTag.label,
            }),
          ]);
          toast({
            label: "Berhasil mengunduh file excel.",
            placement: "top",
          });
          window.location.href = data.data.request_file;
        }
      },
      onError: (data) => {
        const {
          response: {
            data: { frontend_message, message },
          },
        } = data;
        toast({
          label: frontend_message || message || "Gagal mengunduh file excel.",
          placement: "top",
          backgroundColor: "red50",
        });
      },
    });

  const handleDownload = () => {
    const param = {
      template_type: categoryType,
      products_type: showcaseType,
    };

    if (selectedTag?.value) param.tag_id = selectedTag.value;

    generateTemplate(param);
  };

  const listShowcase = useMemo(
    () =>
      dataShowcase?.data && dataShowcase?.data.length !== 0
        ? dataShowcase?.data.map((showcase) => ({
            value: showcase.tag_id,
            label: showcase.tag?.tag_name || "-",
          }))
        : [
            {
              value: "",
              label: "Etalase kosong",
            },
          ],
    [dataShowcase],
  );

  const isLoading = useMemo(
    () => isGenerateLoading || isGetStatusLoading || isManualLoading,
    [isGenerateLoading, isGetStatusLoading, isManualLoading],
  );

  return (
    <Flex flexDirection="column" width="536px" gap="8px">
      <Flex
        boxShadow="0px 4px 4px rgba(20, 20, 32, 0.05)"
        backgroundColor="white"
        padding="24px"
        flexDirection="column"
        borderBottomLeftRadius={dataTemplate.length === 0 && "16px"}
        borderBottomRightRadius={dataTemplate.length === 0 && "16px"}
      >
        <Flex gap="12px" alignItems="center">
          <Flex
            width="40px"
            height="40px"
            backgroundColor="violet5"
            borderRadius="100%"
            z
            alignItems="center"
            justifyContent="center"
          >
            <Text variant="heading3" weight="bold" color="blue50">
              1
            </Text>
          </Flex>
          <Flex>
            <Text variant="heading3" weight="bold">
              Download & Isi File Excel
            </Text>
          </Flex>
        </Flex>
        <Flex py="24px">
          <Divider />
        </Flex>

        <Flex flexDirection="column" gap="48px">
          <Flex flexDirection="column" gap="12px">
            <Text weight="bold" variant="subtitle">
              Pilih Produk Kamu
            </Text>
            <Flex gap="11px" flexWrap="wrap">
              {DATA_SHOWCASE.map((showcase, indexShowcase) => {
                if (showcase.value === "all" || showcase.value === "untagged")
                  return (
                    <Flex
                      key={`btn-showcase-${indexShowcase}`}
                      padding="8px 21px 8px 11px"
                      borderRadius="8px"
                      borderWidth="1px"
                      borderStyle="solid"
                      borderColor="black10"
                      gap="10px"
                      width="204px"
                      cursor="pointer"
                      alignItems="center"
                      onClick={() => setShowcaseType(showcase.value)}
                    >
                      <Input.Radio
                        checked={showcaseType === showcase.value}
                        onChange={() => {}}
                        disabled={isLoading}
                      />
                      <Text color="black75">{showcase.label}</Text>
                    </Flex>
                  );

                return (
                  <SelectInput
                    options={listShowcase}
                    key={`btn-showcase-${indexShowcase}`}
                    placeholder="Pilih Etalase"
                    size="small"
                    isDisabled={isShowcaseLoading}
                    onChange={(event) => {
                      if (event?.value && event?.value !== selectedTag?.value) {
                        setSelectedTag(event);
                        setShowcaseType("tagged");
                      } else {
                        setSelectedTag(null);
                        setShowcaseType("all");
                      }
                    }}
                    hideSelectedOptions={false}
                    value={selectedTag}
                    components={{
                      Control: (props) => {
                        return (
                          <Flex
                            padding="8px 21px 8px 11px"
                            borderRadius="8px"
                            borderWidth="1px"
                            borderStyle="solid"
                            borderColor="black10"
                            gap="10px"
                            width="204px"
                            cursor="pointer"
                            alignItems="center"
                            {...props}
                          >
                            <Input.Radio
                              name="radioGroup"
                              onClick={() =>
                                selectedTag && setShowcaseType("tagged")
                              }
                              checked={showcaseType === "tagged"}
                            />
                            {props.children}
                          </Flex>
                        );
                      },
                    }}
                  />
                );
              })}
            </Flex>
          </Flex>

          <Flex>
            <Flex
              gap="12px"
              flexDirection="column"
              justifyContent="space-between"
            >
              <Flex>
                <Text weight="bold" variant="subtitle">
                  Pilih Kategori yang Diubah
                </Text>
              </Flex>
              <Flex gap="16px">
                {DATA_CATEGORY.map((category, indexCategory) => (
                  <Flex
                    key={`btn-showcase-${indexCategory}`}
                    padding="8px 0"
                    gap="3px"
                    width="236px"
                    flexDirection="column"
                  >
                    <Flex alignItems="center" gap="3px" position="sticky">
                      <Input.Radio
                        checked={categoryType === category.value}
                        onChange={() => setCategoryType(category.value)}
                      />
                      <Text color="black75" weight="bold">
                        {category.label}
                      </Text>
                    </Flex>
                    <Flex width="calc(236px - 33px)" alignSelf="flex-end">
                      <Text color="black50" variant="caption">
                        {category.description}
                      </Text>
                    </Flex>
                  </Flex>
                ))}
              </Flex>
            </Flex>
          </Flex>
        </Flex>
        <Flex py="24px">
          <Divider />
        </Flex>
        <Button
          variant="secondary"
          isBlock
          onClick={handleDownload}
          preffix={isLoading && <Spinner size="16px" color="blue10" />}
          disabled={isLoading}
        >
          Proses File Excel
        </Button>
      </Flex>

      {dataTemplate.length > 0 && <ListTemplate data={dataTemplate} />}
    </Flex>
  );
};

export default VariantSelector;
