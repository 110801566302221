import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Box, Flex, Icon, Text } from "renos-ui";

const CustomHeader = ({ mode }) => {
  const { goBack } = useHistory();

  return (
    <Box
      p="16px"
      rowGap="16px"
      height="fit-content"
      flexDirection="column"
      boxShadow={"0 0 8px rgba(20, 20, 32, 0.1)"}
    >
      <Flex justifyContent="space-between">
        <Flex gap="16px" alignItems="center">
          <Icon
            cursor="pointer"
            onClick={() => {
              goBack();
            }}
            name="Close-outline"
            color="black50"
            size={24}
          />

          <Flex flexDirection="column">
            <Text weight="bold" color="black100" variant="subtitle">
              {mode === "add" ? "Tambah Lokasi" : "Edit Lokasi"}
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
};

export default CustomHeader;
