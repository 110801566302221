import { Box, Divider, Flex, Text, Icon } from "renos-ui";

const Card = ({ data, isActive, onClick }) => {
  return (
    <Flex flexDirection="column" onClick={onClick} cursor="pointer">
      <Flex py="8px">
        <Flex
          gap="16px"
          alignItems="center"
          py="8px"
          backgroundColor={isActive ? "blue5" : "white"}
          width="100%"
          px="24px"
          borderLeftColor="blue50"
          borderLeftStyle="solid"
          borderLeftWidth={isActive ? "3px" : 0}
        >
          <Box
            as="img"
            src={data.product_image_url}
            height="80px"
            width="80px"
            borderRadius="8px"
            alt="img-product"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = "/assets/images/empty-product.svg";
            }}
          />
          <Flex flexDirection="column" width="calc(100% - 104px)">
            <Flex style={{ wordBreak: "break-all" }}>
              <Text color="black75" lineClamp={1}>
                {data.product_name}
              </Text>
            </Flex>
            <Text weight="bold" variant="subtitle">
              {data.formatted_product_price}
            </Text>
            <Flex gap="4px" alignItems="center">
              <Icon color="yellow50" size={16} name="Star-solid" />
              <Text color="black75" weight="bold">
                {data.review_rating || 0}
              </Text>
              <Text color="black50">({data.review_count || 0} ulasan)</Text>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <Divider />
    </Flex>
  );
};

export default Card;
