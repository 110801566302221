import React, { useEffect, useState } from "react";
import {
  Flex,
  Input,
  SelectInput,
  Icon,
  Text,
  Box,
  useDisclosure,
} from "renos-ui";
import { formatterNumber, sanitizeNumber } from "helpers/number-format";
import { ProductList } from "./ProductListTable/ProductList";
import ProductCard from "./ProductCard";

const WrapperStyledInputBox = ({ label, children }) => (
  <Box width="100%">
    <Box mb="8px">
      <Text weight="bold" variant="caption">
        {label}
      </Text>
    </Box>
    {children}
  </Box>
);

export const DetailProductListForm = ({
  data,
  index,
  setProductListOffer,
  errorMessages,
  length,
}) => {
  const { onToggle, isOpen } = useDisclosure({ open: false });
  const [finalPrice, setFinalPrice] = useState();

  const initialValue = {
    productUOM: "unit",
    productQty: 1,
  };
  const optionsUOM = [
    {
      value: { uom: "unit", measure: 1 },
      label: "Unit",
    },
    {
      value: { uom: "lusin", measure: 12 },
      label: "Lusin",
    },
    {
      value: { uom: "bruto", measure: 144 },
      label: "Bruto",
    },
  ];

  const handleRemove = () => {
    setProductListOffer((itemPrev) => [
      ...itemPrev.filter((_, itemIndex) => itemIndex !== index),
    ]);
  };

  const handleOnChange = (value, objectKey) => {
    setProductListOffer((prev) =>
      prev.map((productOfferData, productOfferIndex) => {
        if (productOfferIndex === index) {
          let newValue = {
            ...productOfferData,
            [objectKey]: value === 0 ? "" : value,
          };

          if (objectKey === "productUOM") {
            newValue = { ...newValue, [objectKey]: value.uom };
          }

          return newValue;
        }

        return productOfferData;
      })
    );
  };

  const getTotalOffer = (productPrice) => {
    const totalPrice = Number(productPrice) + Number(data?.productShippingFee);

    setFinalPrice(totalPrice);
  };

  useEffect(() => {
    // Reset Value when choosed a product
    setProductListOffer((prev) =>
      prev.map((productOfferData, productOfferIndex) => {
        if (productOfferIndex === index) {
          const newValue = {
            ...productOfferData,
            productUOM: initialValue.productUOM,
            productQty: initialValue.productQty,
            productTotalPrice: data?.product?.selling_price_end,
          };

          return newValue;
        }

        return productOfferData;
      })
    );

    setFinalPrice(data?.product?.selling_price_end);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.product]);

  useEffect(() => {
    getTotalOffer(data.productTotalPrice);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.productQty, data.productShippingFee, data.productUOM]);

  return (
    <Flex color="black100" flexDirection="column" gap="24px">
      <WrapperStyledInputBox
        label={
          <Flex justifyContent="space-between" mb="16px">
            <Text variant="caption" weight="bold">
              Produk yang ditawarkan
            </Text>
            {length > 1 && (
              <Icon
                name="Trash-outline"
                color="black50"
                onClick={handleRemove}
                cursor="pointer"
              />
            )}
          </Flex>
        }
      >
        {data.product ? (
          <ProductCard data={data} onClick={onToggle} />
        ) : (
          <Box cursor="pointer">
            <Input
              error={errorMessages[`productId_${index}`]}
              isError={errorMessages[`productId_${index}`]}
              placeholder="Pilih Produk Dari Tokomu"
              onClick={onToggle}
              readOnly
              sufix={<Icon name="Right-outline" size={20} onClick={onToggle} />}
            />
          </Box>
        )}
      </WrapperStyledInputBox>
      <Flex gap="24px" justifyContent="space-between">
        <WrapperStyledInputBox label="Kuantitas Produk">
          <Input
            name="productQty"
            error={errorMessages[`productQty_${index}`]}
            isError={errorMessages[`productQty_${index}`]}
            value={data?.productQty}
            onChange={({ target }) => {
              const UOMValue = optionsUOM.find(
                (option) => option.value.uom === data?.productUOM
              );
              handleOnChange(
                data?.product?.selling_price_end *
                  target?.value *
                  Number(UOMValue.value.measure),
                "productTotalPrice"
              );
              handleOnChange(
                sanitizeNumber(String(target.value)),
                "productQty"
              );
            }}
          />
        </WrapperStyledInputBox>

        <WrapperStyledInputBox label="Satuan">
          <SelectInput
            options={optionsUOM}
            defaultValue={{ value: "unit", label: "Unit" }}
            label="Satuan"
            value={optionsUOM.find(
              (item) => item.value.uom === data?.productUOM
            )}
            onChange={({ value }) => {
              handleOnChange(
                data?.product?.selling_price_end *
                  Number(data?.productQty || 1) *
                  Number(value.measure),
                "productTotalPrice"
              );
              handleOnChange(value, "productUOM");
            }}
          />
        </WrapperStyledInputBox>
      </Flex>
      <Flex gap="24px" justifyContent="space-between">
        <WrapperStyledInputBox label="Biaya Pengiriman">
          <Input
            error={errorMessages[`productShippingFee_${index}`]}
            isError={errorMessages[`productShippingFee_${index}`]}
            value={
              data?.productShippingFee
                ? formatterNumber(Number(data?.productShippingFee))
                : ""
            }
            onChange={({ target }) =>
              handleOnChange(
                sanitizeNumber(String(target.value)),
                "productShippingFee"
              )
            }
            prefix={
              <Text color="black50" variant="caption" weight="bold">
                Rp
              </Text>
            }
          />
        </WrapperStyledInputBox>
        <WrapperStyledInputBox label="Estimasi Lama Pengiriman">
          <Input
            error={errorMessages[`productShippingEstimate_${index}`]}
            isError={errorMessages[`productShippingEstimate_${index}`]}
            value={data?.productShippingEstimate}
            onChange={({ currentTarget }) =>
              handleOnChange(
                sanitizeNumber(String(currentTarget.value)),
                "productShippingEstimate"
              )
            }
            sufix={
              <Text color="black50" variant="caption" weight="bold">
                Hari
              </Text>
            }
          />
        </WrapperStyledInputBox>
      </Flex>
      <WrapperStyledInputBox label="Total Harga Produk">
        <Input
          error={errorMessages[`productTotalPrice_${index}`]}
          isError={errorMessages[`productTotalPrice_${index}`]}
          onChange={({ currentTarget }) => {
            const value = sanitizeNumber(String(currentTarget.value));
            handleOnChange(
              sanitizeNumber(String(currentTarget.value)),
              "productTotalPrice"
            );
            getTotalOffer(value);
          }}
          value={
            data?.productTotalPrice
              ? formatterNumber(Number(data?.productTotalPrice))
              : ""
          }
          prefix={
            <Text color="black50" variant="caption" weight="bold">
              Rp
            </Text>
          }
        />
      </WrapperStyledInputBox>

      <Flex flexDirection="column">
        <Text variant="caption" weight="bold">
          Total Harga Penawaran
        </Text>

        <Text>Rp {finalPrice ? formatterNumber(finalPrice) : "0"}</Text>
      </Flex>

      {isOpen && (
        <ProductList index={index} isVisible={isOpen} onToggle={onToggle} />
      )}
    </Flex>
  );
};
