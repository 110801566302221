import React from "react";
import {
  Drawer,
  Divider,
  Text,
  Flex,
  Button,
  useToast,
  useDisclosure,
} from "renos-ui";
import { useDeliveryContext } from "store/seller-fleet/reducer";
import { useHistory } from "react-router-dom";
import { useDeleteSellerFleetSetting } from "services/logistics/sellerFleet";

const DrawerById = ({ isVisible, onClose, refetch }) => {
  const history = useHistory();
  const { state } = useDeliveryContext();
  const toast = useToast();
  const {
    listDetail: { id },
  } = state;

  const deleteDisclosure = useDisclosure({ isOpen: false });
  const { isLoading, mutate: mutateDelete } = useDeleteSellerFleetSetting({
    onSuccess: () => {
      onClose();
      toast({
        label: "Satu pengaturan telah dihapus.",
        placement: "bottom",
      });
      refetch();
    },
    onError: () => {
      toast({
        label: "Pengaturan gagal dihapus.",
        backgroundColor: "red50",
        placement: "bottom",
      });
    },
  });

  return (
    <Drawer
      isVisible={isVisible}
      placement="bottom"
      closable={true}
      onClose={onClose}
    >
      <Flex flexDirection="column" gap="16px" marginTop="16px">
        <Text
          variant="caption"
          onClick={() => history.push(`/account/list-setting-detail?id=${id}`)}
        >
          Lihat Detail
        </Text>
        <Divider />
        <Text
          variant="caption"
          onClick={() => history.push(`/account/set-delivery-region?id=${id}`)}
        >
          Ubah Pengaturan
        </Text>
        <Divider />
        <Text
          variant="caption"
          onClick={() => {
            deleteDisclosure.onOpen();
            // onClose();
          }}
        >
          Hapus
        </Text>
      </Flex>

      <Drawer
        isVisible={deleteDisclosure.isOpen}
        placement="bottom"
        closable={true}
        onClose={deleteDisclosure.onClose}
      >
        <Flex flexDirection="column" gap="16px" marginTop="16px">
          <Flex flexDirection="column" rowGap="8px">
            <Text variant="caption" weight="bold">
              Hapus Pengaturan?
            </Text>
            <Text variant="caption" color="black50">
              Pengaturan yang dipilih akan dihapus.
            </Text>
          </Flex>
          <Flex
            flexDirection="row"
            justifyContent="space-between"
            gap="1rem"
            marginTop="16px"
          >
            <Button isBlock size="medium" variant="tertiary" onClick={onClose}>
              Batal
            </Button>
            <Button
              isBlock
              size="medium"
              variant="primary"
              onClick={() => mutateDelete({ courier_service_setting_id: id })}
              disabled={isLoading}
            >
              Ya, Hapus
            </Button>
          </Flex>
        </Flex>
      </Drawer>
    </Drawer>
  );
};

export default DrawerById;
