import desktopUrl from "constants/desktopUrl";
import { useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Dialog, Flex, Spinner, Text, useToast } from "renos-ui";
import { useSetTemplate } from "services/store-layout/homepage";

const DialogSelect = ({ isOpen, onClose, isLoading, data }) => {
  const toast = useToast();
  const { push } = useHistory();
  const [delayLoading, setDelayLoading] = useState(false);

  const { isLoading: isLoadingSave, mutate } = useSetTemplate({
    onSuccess: (dataSuccess) => {
      const { message, frontend_message } = dataSuccess;
      setDelayLoading(true);

      toast({
        label: frontend_message || message || "Berhasil merubah template.",
        placement: "top",
      });

      setTimeout(() => {
        push(desktopUrl.STORE_LAYOUT);
      }, 3000);
    },
    onError: (dataError) => {
      const {
        response: {
          data: { frontend_message, message },
        },
      } = dataError;
      toast({
        label: frontend_message || message || "Gagal merubah posisi.",
        placement: "top",
        backgroundColor: "red50",
      });
    },
  });

  const handleSave = () => {
    mutate(data.id);
  };

  const isLoadingAll = useMemo(
    () => isLoading || isLoadingSave || delayLoading,
    [isLoading, isLoadingSave, delayLoading]
  );

  return (
    <Dialog isVisible={isOpen} onClose={onClose} verticalCentered>
      <Flex
        width="320px"
        fontFamily="sans-serif"
        flexDirection="column"
        rowGap="24px"
      >
        <Flex flexDirection="column" rowGap="8px">
          <Text variant="heading3" weight="bold">
            Gunakan Template Ini?
          </Text>
          <Text>
            Template yang aktif kamu gunakan saat ini tidak akan tersimpan.
            Apakah kamu yakin?
          </Text>
        </Flex>
        <Flex flexDirection="row" justifyContent="space-between" gap="1rem">
          <Button
            isBlock
            size="large"
            variant="tertiary"
            onClick={onClose}
            disabled={delayLoading}
          >
            Tidak, Batal
          </Button>
          <Button
            isBlock
            size="large"
            variant="primary"
            onClick={handleSave}
            disabled={isLoadingAll}
            preffix={isLoadingAll && <Spinner size="16px" color="white" />}
          >
            Gunakan
          </Button>
        </Flex>
      </Flex>
    </Dialog>
  );
};

export default DialogSelect;
