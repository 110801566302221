import { Divider, Drawer, Flex, Text, Upload } from "renos-ui";

const DrawerAttachment = ({
  disclosure,
  registerUpload,
  onClickProduct,
  onClickOrder,
}) => {
  const { isOpen, onClose } = disclosure;

  return (
    <Drawer
      placement="bottom"
      closable={true}
      onClose={onClose}
      isVisible={isOpen}
    >
      <Flex flexDirection="column" gap="16px" my="16px">
        <Flex flexDirection="column" gap="16px">
          <Upload
            {...registerUpload}
            accept="image/jpg, image/jpeg, image/png"
            onInvalid={(event) =>
              event.target.setCustomValidity("Gambar tidak boleh kosong.")
            }
            onInput={(event) => event.target.setCustomValidity("")}
          >
            <Text>Gambar</Text>
          </Upload>
          <Flex>
            <Divider />
          </Flex>
        </Flex>
        <Flex flexDirection="column" gap="16px" onClick={onClickProduct}>
          <Text>Produk</Text>
          <Flex>
            <Divider />
          </Flex>
        </Flex>
        <Flex flexDirection="column" gap="16px" onClick={onClickOrder}>
          <Text>Pesanan</Text>
        </Flex>
      </Flex>
    </Drawer>
  );
};

export default DrawerAttachment;
