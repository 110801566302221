import React from "react";
import {
  Button,
  Checkbox,
  Divider,
  Flex,
  Text,
  Tooltip,
  Input,
  Icon,
  Drawer,
} from "renos-ui";
import LoadingCheckBox from "../components/LoadingCheckbox";
import { StyledFlexInputSearch } from "../components/styled";
import useModalCategories from "./hooks/useModalCategories";

const ModalCategories = ({
  isOpen,
  onClose,
  dataCategories,
  setDataCategories,
}) => {
  const {
    dataModal,
    isLoadingAll,
    handleOnChangeCheckbox,
    handleCheckAll,
    handleIsAllChecked,
    handleSave,
    countActive,
    resetCategories
  } = useModalCategories({
    isOpen,
    onClose,
    dataCategories,
    setDataCategories,
  });

  return (
    <Drawer
      isVisible={isOpen}
      onClose={onClose}
      isFullPage
      padding="0"
      header={{
        render: (
          <Flex
            flexDirection="row"
            alignItems="center"
            gap="8px"
            padding="16px"
          >
            <Icon
              name="Back-outline"
              size="24"
              color="black50"
              onClick={() => {
                onClose();
                resetCategories();
              }}
            />
            <Text weight="bold">Pilih Kategori</Text>
            <StyledFlexInputSearch
              width="100%"
              borderRadius="100%"
              style={{ display: "none" }}
            >
              <Input
                size="small"
                placeholder="Cari nama kategori"
                prefix={
                  <Icon name="Search-outline" size={16} color="black50" />
                }
                // onChange={(event) => handleSearch(event)}
              />
            </StyledFlexInputSearch>
          </Flex>
        ),
      }}
    >
      <Flex
        flexDirection="column"
        gap="16px"
        padding="0 16px"
        height="calc(100% - 130px)"
        overflow="auto"
      >
        {isLoadingAll ? (
          <LoadingCheckBox />
        ) : (
          dataModal.categories.map((categoriesLvl1, indexCategories) => (
            <Flex
              gap="16px"
              key={`categories-level-one-${indexCategories}`}
              flexDirection="column"
            >
              {categoriesLvl1.categories.map(
                (categoriesLvl2, indexCategoriesLvl2) => (
                  <Flex
                    flexDirection="column"
                    gap="12px"
                    key={`categories-level-2-${indexCategories}${indexCategoriesLvl2}`}
                  >
                    <Flex
                      gap="12px"
                      alignItems="center"
                      marginTop={indexCategoriesLvl2 === 0 ? "0" : "24px"}
                    >
                      <Checkbox
                        onChange={() =>
                          handleCheckAll(indexCategories, indexCategoriesLvl2)
                        }
                        checked={
                          handleIsAllChecked(categoriesLvl2.categories).active
                        }
                        indeterminate={
                          handleIsAllChecked(categoriesLvl2.categories)
                            .indeterminate
                        }
                      />
                      <Text
                        color="black75"
                        weight="bold"
                        variant="caption"
                        lineClamp={1}
                      >
                        {categoriesLvl2.category_name}
                      </Text>
                    </Flex>
                    <Divider />

                    {categoriesLvl2.categories.map(
                      (categoriesLvl3, indexCategoriesLvl3) => (
                        <Flex
                          gap="8px"
                          key={`categories-level-3-${indexCategories}${indexCategoriesLvl2}${indexCategoriesLvl3}`}
                          alignItems="center"
                        >
                          <Flex gap="8px" alignItems="center" height="24px">
                            <Checkbox
                              checked={categoriesLvl3.active}
                              onChange={() =>
                                handleOnChangeCheckbox(
                                  categoriesLvl3.category_id
                                )
                              }
                            />
                          </Flex>
                          <Tooltip label={categoriesLvl3.category_name}>
                            <Text
                              color="black75"
                              variant="caption"
                              lineClamp={1}
                            >
                              {categoriesLvl3.category_name}
                            </Text>
                          </Tooltip>
                        </Flex>
                      )
                    )}
                  </Flex>
                )
              )}
            </Flex>
          ))
        )}
      </Flex>
      <Flex padding="16px">
        <Button disabled={!countActive} isBlock size="medium" onClick={handleSave}>
          <Text variant="caption" weight="bold">
            Simpan
          </Text>
        </Button>
      </Flex>
    </Drawer>
  );
};

export default ModalCategories;