import React, { useContext, useState } from "react";
import {
  Flex,
  Icon,
  Input,
  SelectInput,
  useDebounce,
  DatePicker,
} from "renos-ui";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import duration from "dayjs/plugin/duration";
import FilterRatingContext from "providers/FilterRatingReview";

dayjs.extend(localizedFormat);
dayjs.extend(duration);

/* 
  untuk filter range date dijadikan display none karena dari desain ada UI/UX,
  dari parameter postman tidak tersedia utk filter date

  berikut parameter dari postman: 
  1. store_id
  2. order_by
  3. ordering
  4. page
*/

const FilterRating = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const { filter, setFilter, listFilter } = useContext(FilterRatingContext);
  const [selectedFilter, setSelectedFilter] = useState(listFilter[0]);
  const [productNameContains, setProductNameContains] = useState(
    filter.product_name_contains
  );

  useDebounce(
    () => {
      setFilter({
        ...filter,
        product_name_contains: productNameContains,
      });
    },
    [productNameContains],
    500
  );

  const handleChange = (event) => {
    setSelectedFilter(event);
    setFilter((prev) => ({
      ...prev,
      order_by: event.params.order_by,
      ordering: event.params.ordering,
    }));
  };

  return (
    <Flex gap="16px">
      <Flex width="264px">
        <Input
          size="regular"
          name="input search"
          value={productNameContains}
          prefix={<Icon name="Search-outline" size={20} />}
          placeholder="Cari nama produk"
          onChange={(event) => {
            setProductNameContains(event.target.value);
          }}
        />
      </Flex>
      <Flex display="none">
        <DatePicker.Range
          startDate={startDate}
          endDate={endDate}
          customInput={
            <Flex width="268px">
              <Input
                value={`${dayjs(startDate).format("D MMM YYYY")} - ${dayjs(
                  endDate
                ).format("D MMM YYYY")}`}
                sufix={<Icon name="Calendar-outline" size={20} />}
              />
            </Flex>
          }
          onChange={([startDate, endDate]) => {
            setStartDate(startDate);
            setEndDate(endDate);
          }}
        />
      </Flex>

      <SelectInput
        defaultValue={selectedFilter}
        options={listFilter}
        onChange={handleChange}
      />
    </Flex>
  );
};

export default FilterRating;
