import { Divider, Flex, Skeleton } from "renos-ui";

const CardSkeleton = () => {
  return (
    <Flex
      borderWidth="1px"
      borderColor="black10"
      borderStyle="solid"
      borderRadius="16px"
      flexDirection="column"
    >
      <Flex justifyContent="space-between" padding="16px 24px">
        <Flex gap="20px">
          <Skeleton width="97px" height="24px" />
          <Skeleton width="178px" height="24px" />
          <Skeleton width="157px" height="24px" />
        </Flex>

        <Flex gap="20px">
          <Skeleton width="110px" height="24px" />
          <Skeleton width="133px" height="24px" />
        </Flex>
      </Flex>
      <Divider />

      <Flex padding="24px">
        <Flex
          width="50%"
          borderRightColor="black10"
          borderRightStyle="solid"
          borderRightWidth="1px"
          paddingRight="24px"
          gap="16px"
          flexDirection="column"
        >
          <Flex gap="16px" alignItems="center">
            <Skeleton.Avatar size="16px" />
            <Skeleton width="145px" height="24px" />
          </Flex>
          <Flex>
            <Flex gap="16px">
              <Skeleton width="64px" height="64px" />
              <Flex flexDirection="column" gap="4px">
                <Skeleton width="300px" height="20px" />
                <Skeleton width="100px" height="20px" />
                <Skeleton width="200px" height="20px" />
              </Flex>
            </Flex>
          </Flex>
          <Skeleton height="48px" width="100%" />
        </Flex>
        <Flex width="50%" paddingLeft="24px" flexDirection="column" gap="16px">
          <Flex flexDirection="column" gap="4px">
            <Skeleton width="100px" height="20px" />
            <Skeleton width="300px" height="20px" />
            <Skeleton width="200px" height="20px" />
          </Flex>

          <Flex flexDirection="column" gap="4px">
            <Skeleton width="100px" height="20px" />
            <Skeleton width="200px" height="20px" />
          </Flex>
        </Flex>
      </Flex>

      <Divider />
      <Flex justifyContent="space-between" padding="24px" alignItems="center">
        <Flex gap="24px">
          <Skeleton width="117px" height="24px" />
          <Skeleton width="117px" height="24px" />
        </Flex>

        <Flex gap="24px">
          <Skeleton width="142px" height="40px" />
          <Skeleton width="161px" height="40px" />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default CardSkeleton;
