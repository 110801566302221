import {
  Flex,
  Text,
  Box,
  useToast,
  Icon,
  Avatar,
  Button,
  Divider,
  Upload,
  useUpload,
  Spinner,
} from "renos-ui";
import { useMemo } from "react";
import {
  useGetStoreDetail,
  useUpdateStore,
  useUploadStoreImg,
} from "services/store";
import { useQueryClient } from "react-query";
import { StyledButton } from "./styled";
import { IMAGE_FILE_TYPE } from "./constant";

import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { StoreInfoSchema } from "./storeInfo-schema";
import { FieldTextInput, FieldTextAreaInput } from "widgets/global/HookForm";
import { goToUrl, imageTypeCheckingDocument, blobToBase64 } from "helpers";

const Information = () => {
  const queryClient = useQueryClient();
  const defaultImage = "/assets/images/default_store.svg";
  const toast = useToast();
  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(StoreInfoSchema),
    defaultValues: {
      name: "",
      email: "",
      description: "",
      contact_person: "",
      phone_number: "",
    },
  });

  const {
    isLoading: isGetStoreLoading,
    refetch: refetchGetStoreDetail,
    isFetching: isGetStoreDetailFetching,
  } = useGetStoreDetail(null, null, {
    onSuccess: (data) => {
      methods.setValue(
        "name",
        data?.data?.name === null ? "" : data?.data?.name
      );
      methods.setValue(
        "email",
        data?.data?.email === null ? "" : data?.data?.email
      );
      methods.setValue(
        "description",
        data?.data?.description === null ? "" : data?.data?.description
      );
      methods.setValue(
        "contact_person",
        data?.data?.contact_person === null ? "" : data?.data?.contact_person
      );
      methods.setValue(
        "phone_number",
        data?.data?.phone_number === null ? "" : data?.data?.phone_number
      );
      methods.setValue(
        "imageUrl",
        data?.data?.image_path === null ? "" : data?.data?.image_path
      );
      methods.setValue("imgChanged", false);
      methods.setValue("isNameUpdated", data?.data?.is_name_updated);
      methods.trigger();
    },
  });

  const { mutate: mutateUpdateStore, isLoading: isMutateUpdateStoreLoading } =
    useUpdateStore({
      onSuccess: (data) => {
        const { message, frontend_message } = data.data;

        if (!methods.getValues("imgChanged")) refetchGetStoreDetail();
        toast({
          label: frontend_message || message,
          placement: "top",
        });
      },
      onError: (data) => {
        const {
          response: {
            data: { frontend_message, message },
          },
        } = data;
        toast({
          label: frontend_message || message,
          placement: "top",
          backgroundColor: "red50",
        });
      },
    });

  const { mutate: mutateUploadImg, isLoading: isMutateUploadImgLoading } =
    useUploadStoreImg({
      onSuccess: (data) => {
        const { message, frontend_message } = data;
        refetchGetStoreDetail();
        if (!methods.formState.isDirty)
          toast({
            label: frontend_message || message,
            placement: "top",
          });
        queryClient.invalidateQueries({
          queryKey: ["getCheckStore"],
        });
      },
      onError: (data) => {
        const {
          response: {
            data: { frontend_message, message },
          },
        } = data;
        toast({
          label: frontend_message || message,
          placement: "top",
          backgroundColor: "red50",
        });
      },
    });

  const isLoading = useMemo(
    () =>
      isGetStoreLoading ||
      isGetStoreDetailFetching ||
      isMutateUpdateStoreLoading ||
      isMutateUploadImgLoading,
    [
      isGetStoreLoading,
      isGetStoreDetailFetching,
      isMutateUpdateStoreLoading,
      isMutateUploadImgLoading,
    ]
  );

  const onSubmit = (data) => {
    const payload = {
      data: {
        name: data.name,
        email: data.email,
        contact_person: data.contact_person,
        phone_number: data.phone_number,
        description: data.description,
      },
    };

    if (methods.getValues("isNameUpdated")) delete payload.data.name;
    mutateUpdateStore(payload);

    const formData = new FormData();
    formData.append("file", methods.getValues("imageFile"));
    if (methods.getValues("imageFile")) mutateUploadImg(formData);
  };

  const { register } = useUpload({
    fileType: "image",
    onRequest: async (file) => {
      const deepTypeChecking = await imageTypeCheckingDocument(file);

      if (deepTypeChecking) {
        const maxAllowedSize = 10000000;

        if (maxAllowedSize < file?.size) {
          toast({
            label: "Ukuran file tidak boleh melebihi dari 10mb.",
            placement: "top",
            backgroundColor: "red50",
          });

          return "";
        }

        const result = await blobToBase64(file);
        toast({
          label: "File berhasil diupload!",
          placement: "top",
        });

        methods.setValue("imageUrl", result);
        methods.setValue("imageFile", file);
        methods.setValue("imgChanged", true);
      } else {
        toast({
          label:
            "Gagal upload file. Harap cek kembali jenis file yang akan kamu upload.",
          placement: "top",
          backgroundColor: "red50",
          clickLabel: "Oke",
        });
      }
    },
    validator: (file) => {
      if (!IMAGE_FILE_TYPE.includes(file.type)) {
        toast({
          label:
            "Gagal upload file. Harap cek kembali jenis file yang akan kamu upload.",
          placement: "top",
          backgroundColor: "red50",
          clickLabel: "Oke",
        });

        return {
          message: "Format gambar tidak sesuai",
        };
      }

      return null;
    },
  });

  return (
    <Flex flexDirection="column" gap="24px" py="24px">
      <Flex flexDirection="column" gap="16px">
        <Text variant="subtitle" weight="bold" color="black100">
          Informasi Toko
        </Text>
        <Flex
          padding="14px 16px"
          alignItems="center"
          backgroundColor="yellow5"
          borderRadius="8px"
          columnGap="8px"
        >
          <Icon name="Info-solid" color="yellow50" size={20} />
          <Text color="yellow100">
            {!methods.getValues("isNameUpdated") ? (
              <>
                Nama Toko tidak dapat diubah. Silahkan hubungi{" "}
                <Box
                  color="yellow100"
                  as="a"
                  onClick={() => goToUrl("mailto:support@renos.id", "_self")}
                  cursor="pointer"
                  display="inline"
                >
                  <Text weight="bold">support@renos.id </Text>
                </Box>{" "}
                jika ingin melakukan perubahan nama.
              </>
            ) : (
              "Nama Toko hanya dapat diubah 1 kali"
            )}
          </Text>
        </Flex>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Flex justifyContent="space-between" columnGap="16px" width="100%">
              <Flex width="50%" flexDirection="column" rowGap="16px">
                <FieldTextInput
                  maxLength={60}
                  disabled={methods.getValues("isNameUpdated")}
                  showCount
                  name="name"
                  placeholder=" Nama Toko"
                  label="Nama Toko"
                  isTrim
                />
                <Flex flexDirection="column" gap="8px">
                  <FieldTextInput
                    maxLength={50}
                    label="Nama Contact Person"
                    showCount
                    name="contact_person"
                    placeholder="Nama Contact Person"
                    isTrim
                  />
                </Flex>
                <Flex flexDirection="column" gap="8px">
                  <FieldTextInput
                    maxLength={15}
                    label=" No. HP Contact Person"
                    showCount
                    name="phone_number"
                    placeholder="Masukan nomor HP/Telepon"
                    isTrim
                    typePhoneNumber={true}
                  />
                </Flex>
                <Flex flexDirection="column" gap="8px">
                  <FieldTextInput
                    maxLength={50}
                    label="Email Toko"
                    showCount
                    name="email"
                    placeholder="Ketik email toko"
                    isTrim
                  />
                </Flex>
              </Flex>
              <Flex
                flexDirection="column"
                rowGap="24px"
                alignContent="center"
                width="45%"
              >
                <Flex
                  borderWidth="1px"
                  borderStyle="solid"
                  borderColor="black10"
                  borderRadius="8px"
                  flexDirection="column"
                  padding="38px 24px"
                  rowGap="24px"
                  alignContent="center"
                >
                  <Flex alignItems="center" columnGap="16px">
                    <Flex>
                      <Avatar
                        backgroundColor="blue5"
                        size={80}
                        src={
                          methods.getValues("imageUrl") || defaultImage
                        }
                        isBorder
                      />
                    </Flex>

                    <Flex flexDirection="column" gap="8px">
                      <Text variant="subtitle" weight="bold">
                        Foto Profil Toko
                      </Text>
                      <Text>
                        Ukuran File maksimal 10 mb dengan format: JPG, JPEG, dan
                        PNG.
                      </Text>
                    </Flex>
                  </Flex>
                  <Flex>
                    <Upload {...register}>
                      <Box width="450px">
                        <Button
                          type="button"
                          isBlock
                          variant="secondary"
                          disabled={isLoading}
                        >
                          Ubah Foto
                        </Button>
                      </Box>
                    </Upload>
                  </Flex>
                </Flex>

                <FieldTextAreaInput
                  maxLength={140}
                  label=" Deskripsi Toko"
                  showCount
                  name="description"
                  placeholder="Tambahkan deskripsi toko"
                  isTrim
                />
              </Flex>
            </Flex>
            <Divider orientation="horizontal" marginTop="20px" />
            <Flex justifyContent="flex-end" marginTop="20px">
              <StyledButton
                type="submit"
                disabled={!methods.formState.isValid}
                preffix={isLoading && <Spinner size="16px" color="blue10" />}
              >
                Simpan Pengaturan
              </StyledButton>
            </Flex>
          </form>
        </FormProvider>
      </Flex>
    </Flex>
  );
};

export default Information;
