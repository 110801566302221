import desktopUrl from "constants/desktopUrl";
import React from "react";
import { useHistory } from "react-router-dom";
import { Box, Button, Flex, Icon, Text } from "renos-ui";

const TopBar = ({ isEdit }) => {
  const history = useHistory();

  return (
    <Flex
      backgroundColor="white"
      borderTopLeftRadius={16}
      borderTopRightRadius={16}
      py={16}
      px={24}
      justifyContent="space-between"
      boxShadow="0px 4px 4px rgba(20, 20, 32, 0.05)"
    >
      <Flex alignItems="center" columnGap="12px">
        <Box cursor="pointer" onClick={() => history.goBack()}>
          <Icon name="Back-outline" size={24} />
        </Box>
        <Text variant="heading3" weight="bold">
          {isEdit ? "Edit" : "Tambah"} Produk
        </Text>
      </Flex>
      <Button
        type="button"
        preffix={<Icon name="Plus-outline" />}
        variant="secondary"
        onClick={() => history.push(desktopUrl.PRODUCTS_ADD_MULTIPLE)}
      >
        Tambah Banyak Sekaligus
      </Button>
    </Flex>
  );
};

export default TopBar;
