import { Dialog } from "renos-ui";
import styled from "styled-components";

export const ImagePreview = styled(Dialog)`
  div {
    top: 0px;
    right: -154px;
    svg {
      path {
        fill: white !important;
      }
    }
  }
`;
