import React, { useMemo, useState } from "react";
import MobileLayout from "layouts/MobileLayout";
import { useHistory } from "react-router-dom";
import useQueryParams from "hooks/useQueryParams";
import mobileUrl from "constants/mobileUrl";
import { Flex, Tabs } from "renos-ui";
import OpenStore from "./open-store";
import CloseStore from "./close-store";

const StoreStatus = () => {
  const query = useQueryParams();
  const { push } = useHistory();
  const [activeTab, setActiveTab] = useState(
    query.get("tab-active") || "buka-toko"
  );

  const tabsItems = useMemo(() => {
    return [
      {
        key: "buka-toko",
        label: "Buka Toko",
        width: "50%",
        children: OpenStore,
      },
      {
        key: "tutup-toko",
        label: "Tutup Toko",
        width: "50%",
        children: CloseStore,
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  const handleChangeTabs = (value) => {
    setActiveTab(value);
    push(`${mobileUrl.STORE_STATUS}?tab-active=${value}`);
  };

  return (
    <MobileLayout
      header={{
        title: "Status Toko",
        withBack: true,
        withoutGoBack: true,
        onBack: () => push("/account"),
      }}
    >
      <Flex flexDirection="column" width="100%">
        <Tabs
          defaultActiveKey={activeTab}
          onChange={handleChangeTabs}
          items={tabsItems}
        />
      </Flex>
    </MobileLayout>
  );
};

export default StoreStatus;
