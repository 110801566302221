import React from "react";
import { Flex, Text, Icon } from "renos-ui";

const Warning = () => {
  return (
    <Flex
      padding="14px 16px"
      alignItems="center"
      backgroundColor="yellow5"
      borderRadius="8px"
      columnGap="8px"
      width="100%"
      height="64px"
      gap="12px"
      style={{ boxSizing: "border-box" }}
    >
      <Icon name="Info-solid" color="yellow50" size={20} />
      <Text variant="caption" color="yellow100">
        Maksimal menyimpan 5 template balasan, hapus template yang ada untuk
        tambah baru.
      </Text>
    </Flex>
  );
};

export default Warning;
