export const PRODUCT_SIDES = ["Utama", "Depan", "Samping", "Detail", "Fitur"];

export const IMAGE_FILE_TYPE = "image/jpg, image/jpeg, image/png";

export const PRODUCT_PREORDER_OPTIONS = [
  {
    label: "Hari",
    value: 1,
    min: 5,
    max: 90,
  },
  {
    label: "Minggu",
    value: 2,
    min: 2,
    max: 12,
  },
  {
    label: "Bulan",
    value: 3,
    min: 1,
    max: 3,
  },
];

export const initialFormBatch = {
  status: 1,
  stock: 1,
  weight: 1,
};
