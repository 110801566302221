import desktopUrl from "constants/desktopUrl";
import MobileLayout from "layouts/MobileLayout";
import React, { useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Button,
  Checkbox,
  Flex,
  Icon,
  Text,
  range,
  useDisclosure,
} from "renos-ui";
import Filter from "./component/Filter";
import Empty from "./component/Empty";
import Card from "./component/Card";
import { useGetProducts } from "services/product";
import InfiniteScroll from "widgets/global/InfiniteScroll";
import Loading from "./component/Loading";
import PopupSetAtOnce from "./component/PopupSetAtOnce";
import { useQueryClient } from "react-query";

const ProductList = () => {
  const { push } = useHistory();
  const queryClient = useQueryClient();
  const [isSelectMode, setIsSelectMode] = useState(false);
  const [dataProduct, setDataProduct] = useState([]);
  const [checkedProduct, setCheckedProduct] = useState([]);
  const [dataFilter, setDataFilter] = useState({
    page: 1,
    product_status_id: "",
    query: "",
  });

  const disclosureSetAtOnce = useDisclosure({ isOpen: false });

  const {
    data: dataGetProduct,
    isLoading,
    isFetching,
  } = useGetProducts(dataFilter, {
    onSuccess: (dataSuccess) => {
      if (dataFilter.page === 1) {
        setDataProduct([...dataSuccess.data]);
      } else {
        setDataProduct([...dataProduct.concat(dataSuccess.data)]);
      }
    },
  });

  const toFirstPage = () => {
    setCheckedProduct([]);
    setIsSelectMode(false);

    queryClient.invalidateQueries({
      queryKey: ["productStatus"],
    });

    if (dataFilter.page === 1) {
      queryClient.invalidateQueries({
        queryKey: ["products"],
      });
    } else {
      setDataFilter({ ...dataFilter, page: 1 });
    }
  };

  const handleInlineEdit = (newData) => {
    let arrProduct = dataProduct.map((product) => {
      if (product.product_id === newData.product_id) return newData;

      return product;
    });

    if (dataFilter.product_status_id) {
      arrProduct = arrProduct.filter(
        (filterProduct) =>
          filterProduct.product_status_id === dataFilter.product_status_id
      );
    }

    setDataProduct([...arrProduct]);
  };

  const isChecked = (productId) => {
    const listOfId = checkedProduct.map((product) => product.product_id);

    return listOfId.includes(productId);
  };

  const onCheckAllClicked = () => {
    let tempCheckedProducts = checkedProduct;

    if (tempCheckedProducts.length < dataProduct.length) {
      // eslint-disable-next-line
      const calculateChecked = dataProduct.map((products) => {
        if (
          tempCheckedProducts.length !== dataProduct.length &&
          !isChecked(products.product_id)
        ) {
          tempCheckedProducts.push(products);
        }
      });
    } else {
      tempCheckedProducts = [];
    }

    setCheckedProduct([...tempCheckedProducts]);
  };

  const isCheckedAll = useMemo(
    () => checkedProduct.length === dataProduct.length,
    [checkedProduct, dataProduct]
  );

  const isIndeterminate = useMemo(
    () =>
      checkedProduct.length < dataProduct.length && checkedProduct.length !== 0,
    [checkedProduct, dataProduct]
  );

  const onCheckProductClicked = (selectedProduct) => {
    let tempCheckedProducts = checkedProduct;

    if (isChecked(selectedProduct.product_id)) {
      tempCheckedProducts = tempCheckedProducts.filter(
        (productFilter) =>
          productFilter.product_id !== selectedProduct.product_id
      );
    } else {
      tempCheckedProducts.push(selectedProduct);
    }

    setCheckedProduct([...tempCheckedProducts]);
  };

  const isHasNextPage = useMemo(
    () => dataGetProduct?.pagination?.has_more_pages,
    [dataGetProduct]
  );

  const isLoadingFirst = useMemo(
    () => (isLoading || isFetching) && dataFilter.page === 1,
    [isLoading, isFetching, dataFilter]
  );

  useState(() => {
    setDataProduct([]);
  }, []);

  return (
    <MobileLayout
      header={{
        title: "Daftar Produk",
        isHomepage: true,
        suffix: (
          <Flex columnGap="16px" alignItems="center">
            <Icon
              name="Plus-outline"
              color="black50"
              cursor="pointer"
              onClick={() => push(desktopUrl.PRODUCTS_ADD)}
            />
            {/* <Icon name="Vertical-outline" color="black50" cursor="pointer" /> */}
          </Flex>
        ),
      }}
      noBorder
      footer
    >
      <Flex width="100%" flexDirection="column">
        <Filter {...{ dataFilter, setDataFilter, setCheckedProduct }} />
        {dataProduct.length === 0 && !isLoading && !isLoadingFirst ? (
          <Empty />
        ) : (
          <>
            <Flex
              justifyContent="space-between"
              alignItems="center"
              marginBottom="16px"
              px="16px"
            >
              <Text variant="caption" color="black75">
                {isSelectMode ? (
                  <Flex gap="16px">
                    <Checkbox
                      checked={isCheckedAll}
                      onChange={onCheckAllClicked}
                      indeterminate={isIndeterminate}
                    />
                    {`${checkedProduct.length} produk dipilih`}
                  </Flex>
                ) : (
                  <>
                    <b>{dataProduct.length}</b> Produk
                  </>
                )}
              </Text>
              <Flex onClick={() => setIsSelectMode(!isSelectMode)}>
                <Text variant="caption" color="blue50" weight="bold">
                  {isSelectMode ? "Batal" : "Pilih Sekaligus"}
                </Text>
              </Flex>
            </Flex>

            <Flex
              height={
                isSelectMode ? "calc(100% - 180px)" : "calc(100% - 116px)"
              }
              overflowX="auto"
              borderTopColor="black5"
              borderTopWidth="8px"
              borderTopStyle="solid"
              flexDirection="column"
            >
              <InfiniteScroll
                page={dataFilter.page}
                onChange={() => {
                  setDataFilter((prev) => ({ ...prev, page: prev.page + 1 }));
                }}
                isLoading={isLoading || isFetching}
                hasNextPage={!isLoadingFirst && isHasNextPage}
                loader={<Loading />}
              >
                {isLoadingFirst
                  ? range(10).map((_, indexLoading) => (
                      <Loading key={`loading-${indexLoading}`} />
                    ))
                  : dataProduct.map((product, indexProduct) => {
                      const {
                        product_image_url,
                        product_name,
                        product_status_id,
                        product_skus,
                        product_condition_id,
                        formatted_product_price,
                        product_stock,
                        product_id,
                      } = product;

                      return (
                        <Card
                          key={`card-product-${indexProduct}`}
                          productName={product_name}
                          price={formatted_product_price}
                          stock={product_stock}
                          isChecked={isChecked(product_id)}
                          onCheck={() => onCheckProductClicked(product)}
                          imgSource={product_image_url}
                          isNew={product_condition_id === 1}
                          isActive={product_status_id === 1}
                          isVariant={product_skus.length > 0}
                          isSelectMode={isSelectMode}
                          data={product}
                          handleInlineEdit={handleInlineEdit}
                        />
                      );
                    })}
              </InfiniteScroll>
            </Flex>
            {isSelectMode && (
              <>
                <Flex p="12px 16px" backgroundColor="white">
                  <Button
                    size="medium"
                    isBlock
                    disabled={checkedProduct.length === 0}
                    onClick={disclosureSetAtOnce.onOpen}
                  >
                    Atur Sekaligus
                  </Button>
                </Flex>
                <PopupSetAtOnce
                  disclosure={disclosureSetAtOnce}
                  data={checkedProduct}
                  toFirstPage={toFirstPage}
                  status={dataFilter.product_status_id}
                />
              </>
            )}
          </>
        )}
      </Flex>
    </MobileLayout>
  );
};

export default ProductList;
