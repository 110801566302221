import { useState } from "react";
import {
  Button,
  Flex,
  Text,
  Input,
  Icon,
  Grid,
  Drawer,
  useToast,
  useDisclosure,
} from "renos-ui";
import FixedBottomContainer from "../components/FixedBottomContainer";
import { useDeliveryContext } from "store/seller-fleet/reducer";
import constants from "store/seller-fleet/constants";
import useValidation from "../hooks/useValidation";

const ModalGroupWeight = ({
  isOpen,
  onClose,
  handleChangeGroup,
  isAllowAddGroup,
  submitGroup,
}) => {
  const { state, dispatch } = useDeliveryContext();
  const { dataRates } = state;
  const showDisclosure = useDisclosure({});
  const [selectedIndex, setSelectedIndex] = useState(null);
  const { validation, setValidation, handleError } = useValidation();
  const toast = useToast();

  const handleAddGroup = () => {
    const tempGroup = dataRates.calculation.group;
    const tempValidationGroup = validation.dataRates.group;

    const newMax = tempGroup.length > 0 && tempGroup[tempGroup.length - 1]?.max;

    tempGroup.push({ min: newMax ? newMax + 1 : 0 });

    tempValidationGroup.push({ price: false, max: false });

    setValidation({
      ...validation,
      dataRates: {
        ...validation.dataRates,
        group: [...tempValidationGroup],
      },
    });

    dispatch({
      type: constants.SET_ADD_GROUP,
      payload: {
        ...dataRates,
        calculation: {
          ...dataRates.calculation,
          group: [...tempGroup],
        },
      },
    });
  };

  const handleShowDelete = (indexGroup) => {
    setSelectedIndex(indexGroup);
    showDisclosure.onOpen();
  };

  const handleDelete = () => {
    const tempGroup = dataRates.calculation.group.filter(
      (dataFilter) =>
        dataFilter.min !== dataRates.calculation.group[selectedIndex].min
    );
    const validateTempGroup = tempGroup.map((group, indexGroup) => {
      let tempMin = group.min;

      if (indexGroup !== 0 && indexGroup !== tempGroup.length) {
        tempMin = tempGroup[indexGroup - 1].max + 1;
      }

      return {
        ...group,
        min: tempMin,
      };
    });

    validateTempGroup[0].min =
      validateTempGroup[0].min !== 1 ? 1 : validateTempGroup[0].min;

    dispatch({
      type: constants.DELETE_WEIGHT_GROUP_BY_INDEX,
      payload: {
        ...dataRates,
        calculation: {
          ...dataRates.calculation,
          group: [...validateTempGroup],
        },
      },
    });

    toast({
      label: "Satu pengaturan telah dihapus.",
      placement: "bottom",
      backgroundColor: "black100",
    });

    showDisclosure.onClose();
  };

  const handleSubmit = () => {
    const isHasError = handleError();

    if (!isHasError) {
      submitGroup();
    }
  };

  return (
    <Drawer
      isVisible={isOpen}
      onClose={onClose}
      isFullPage
      padding="0"
      header={{
        render: (
          <Flex
            flexDirection="row"
            alignItems="center"
            gap="8px"
            padding="16px"
          >
            <Icon
              name="Back-outline"
              size="24"
              color="black50"
              onClick={onClose}
            />
            <Text weight="bold" variant="regular">
              Atur Kelompok Berat
            </Text>
          </Flex>
        ),
      }}
    >
      <Flex
        flexDirection="column"
        gap="16px"
        padding="16px"
        height="calc(100% - 130px)"
        overflow="scroll"
        backgroundColor="black5"
      >
        {dataRates.calculation.group.map((dataGroup, indexGroup) => (
          <Flex
            flexDirection="column"
            gap="16px"
            key={`row-group${indexGroup}`}
            id={`group${indexGroup}`}
            alignItems="center"
            padding="16px"
            borderRadius="8px"
            backgroundColor="white"
            position="relative"
          >
            {indexGroup > 0 && (
              <Flex position="absolute" right="6px" top="15px">
                <Icon
                  name="Vertical-outline"
                  color="black50"
                  size={22}
                  onClick={() => handleShowDelete(indexGroup)}
                />
              </Flex>
            )}

            <Grid
              gridTemplateColumns={
                indexGroup > 0 ? "42% 6% 42% 15%" : "44% 8% 44%"
              }
              gridGap="6px"
            >
              <Input.Number
                size="small"
                label="Berat Minimum"
                onChange={(event) =>
                  handleChangeGroup("min", event, indexGroup)
                }
                name="min"
                isError={validation?.dataRates?.group[indexGroup]?.min}
                error={validation?.dataRates?.group[indexGroup]?.minText}
                value={dataGroup.min}
                sufix={
                  <Text color="black50" weight="bold">
                    g
                  </Text>
                }
                placeholder="Minimum"
                disabled={indexGroup !== 0}
              />
              <Flex justifyContent="center" alignSelf="center">
                <Text weight="bold"> - </Text>
              </Flex>
              <Input.Number
                size="small"
                label="Berat Maksimum"
                isError={validation?.dataRates?.group[indexGroup]?.max}
                error={validation?.dataRates?.group[indexGroup]?.maxText}
                onChange={(event) =>
                  handleChangeGroup("max", event, indexGroup)
                }
                name="max"
                value={dataGroup.max}
                sufix={
                  <Text color="black50" weight="bold">
                    g
                  </Text>
                }
                placeholder="Maksimum"
              />
            </Grid>

            <Flex
              alignSelf="flex-start"
              width={indexGroup > 0 ? "94%" : "100%"}
            >
              <Input.Number
                size="small"
                label="Ongkos Kirim"
                isError={validation?.dataRates?.group[indexGroup]?.price}
                error={validation?.dataRates?.group[indexGroup]?.priceText}
                onChange={(event) =>
                  handleChangeGroup("price", event, indexGroup)
                }
                name="price"
                value={dataGroup.price === 0 ? "0" : dataGroup.price}
                prefix={
                  <Text color="black50" weight="bold" variant="caption">
                    Rp
                  </Text>
                }
                placeholder="Masukkan Tarif"
              />
            </Flex>
          </Flex>
        ))}
        <Flex marginBottom="60px" />
      </Flex>

      <FixedBottomContainer>
        <Flex flexDirection="column" gap="16px" width="100%" padding="16px">
          <Button
            isBlock
            size="medium"
            variant="secondary"
            disabled={!isAllowAddGroup}
            onClick={handleAddGroup}
          >
            <Text variant="caption" weight="bold">
              Tambah Kelompok
            </Text>
          </Button>
          <Button
            isBlock
            size="medium"
            onClick={handleSubmit}
            disabled={!isAllowAddGroup}
          >
            <Text variant="caption" weight="bold">
              Simpan
            </Text>
          </Button>
        </Flex>
      </FixedBottomContainer>

      {showDisclosure.isOpen && (
        <Drawer
          isVisible={showDisclosure.isOpen}
          placement="bottom"
          isNestedDrawer={true}
          closable={true}
          zIndex={11}
          onClose={() => showDisclosure.onClose()}
        >
          <Flex padding="16px 0">
            <Text variant="caption" onClick={handleDelete}>
              Hapus
            </Text>
          </Flex>
        </Drawer>
      )}
    </Drawer>
  );
};

export default ModalGroupWeight;