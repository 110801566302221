import { useMemo, useState } from "react";
import {
  Box,
  Button,
  Divider,
  Icon,
  Input,
  Flex,
  SelectInput,
  Text,
  useDisclosure,
  isEmpty,
  Skeleton,
} from "renos-ui";
import {
  useGetCity,
  useGetDistrict,
  useGetProvince,
} from "services/logistics/area";
import ModalCity from "./ModalCity";
import ModalDistrict from "./ModalDistrict";

const DeliveryArea = ({
  dataCoverage,
  setDataCoverage,
  isLoading,
  validation,
  setValidation,
}) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const cityDisclosure = useDisclosure({ isOpen: false });
  const districtDisclosure = useDisclosure({ isOpen: false });

  const { data: dataProvince, isLoading: isLoadingProvince } = useGetProvince();

  const { mutate: mutateCity, isLoading: isLoadingCity } = useGetCity({
    onSuccess: ({ data, indexCoverage }) => {
      const tempCoverage = dataCoverage;
      dataCoverage[indexCoverage] = {
        ...dataCoverage[indexCoverage],
        listCity: data,
        listDistrict: [],
      };

      setDataCoverage([...tempCoverage]);
    },
  });

  const { mutate: mutateDistrict, isLoading: isLoadingDistrict } =
    useGetDistrict({
      onSuccess: ({ data, indexCoverage }) => {
        const tempCoverage = dataCoverage;

        dataCoverage[indexCoverage] = {
          ...dataCoverage[indexCoverage],
          listDistrict: data,
        };
        setDataCoverage([...tempCoverage]);
      },
    });

  const handleChangeEstimate = (event, indexCoverage) => {
    const value = event.target.value;

    let val = value //eslint-disable-next-line
      .replace(/[A-Za-z!@#$%^&*()_+=\[\]{};':"\\|,.<>\/?]/gm, "")
      .trim();
    // eslint-disable-next-line
    const dashCount = (val.match(/\-/gm) || []).length;

    if (dashCount > 1) {
      // eslint-disable-next-line
      val = val.replace(/\-$/gm, "");
    }

    const [firstVal, secondVal] = val.split("-") || "";

    const singleNumber = val.split("-").length;

    const arrVal = val.split("");
    const hasTilde = arrVal.includes("~");
    const hasBacktick = arrVal.includes("`");

    let isFormatError = false;

    if (Number(firstVal) === 0) {
      isFormatError = true;
    }

    if (singleNumber <= 1) {
      isFormatError = true;
    }

    if(isEmpty(firstVal)) {
      isFormatError = true;
    }

    if ((val && val.length === 0) || Number(secondVal) - Number(firstVal) < 1) {
      isFormatError = true;
    }

    if (hasTilde || hasBacktick) {
      isFormatError = true;
    }

    const tempCoverage = dataCoverage;
    tempCoverage[indexCoverage].estimateDays = val;

    const tempValidation = validation;
    tempValidation[indexCoverage].estimateDays = isFormatError;
    tempValidation[indexCoverage].estimateDaysText = "";

    if (isFormatError) {
      tempValidation[indexCoverage].estimateDaysText =
        "Estimasi hari pengiriman yang anda masukkan salah.";
    }

    setValidation([...tempValidation]);
    setDataCoverage([...tempCoverage]);
  };

  const handleChangeProvince = (event, indexCoverage) => {
    const tempCoverage = dataCoverage;
    tempCoverage[indexCoverage].selectedProvince = event.value;
    tempCoverage[indexCoverage].selectedCity = [];
    tempCoverage[indexCoverage].selectedDistrict = [];
    tempCoverage[indexCoverage].listCity = [];
    tempCoverage[indexCoverage].listDistrict = [];
    setDataCoverage([...tempCoverage]);

    const tempValidation = validation;
    tempValidation[indexCoverage].selectedProvince = false;
    tempValidation[indexCoverage].selectedCity = false;
    tempValidation[indexCoverage].selectedDistrict = false;

    setValidation([...tempValidation]);
    mutateCity({ ...event.value, indexCoverage });
  };

  const handleAddArea = () => {
    const tempCoverage = dataCoverage;
    const tempValidation = validation;

    tempCoverage.push({
      selectedProvince: {},
      selectedCity: [],
      listCity: [],
      selectedDistrict: [],
      estimateDays: "",
    });

    tempValidation.push({
      selectedProvince: false,
      estimateDays: false,
      estimateDaysText: "",
    });

    setDataCoverage([...tempCoverage]);
  };

  const isAllowAddArea = useMemo(
    () =>
      dataCoverage?.filter(
        (filterCoverage) =>
          !isEmpty(filterCoverage.selectedProvince) &&
          filterCoverage.estimateDays
      ).length === dataCoverage.length,
    [dataCoverage]
  );

  const isAllowRemoveArea = useMemo(
    () => dataCoverage && dataCoverage.length > 1,
    [dataCoverage]
  );

  const handleShowCity = (indexCoverage) => {
    setSelectedIndex(indexCoverage);
    cityDisclosure.onOpen();
  };

  const handleShowDistrict = (indexCoverage) => {
    setSelectedIndex(indexCoverage);
    districtDisclosure.onOpen();
  };

  const handleDeleteCoverage = (index) => {
    const tempDataCoverage = dataCoverage;
    const tempValidation = validation;

    tempValidation.splice(index, 1);
    setValidation([...tempValidation]);

    dataCoverage.splice(index, 1);
    setDataCoverage([...tempDataCoverage]);
  };

  return (
    <Flex
      gap="16px"
      padding="24px"
      backgroundColor="white"
      boxShadow="0px 4px 4px rgba(20, 20, 32, 0.05)"
      borderBottomLeftRadius="16px"
      borderBottomRightRadius="16px"
    >
      {!isLoading && (
        <>
          <ModalCity
            isOpen={cityDisclosure.isOpen}
            onClose={cityDisclosure.onClose}
            selectedIndex={selectedIndex}
            dataCoverage={dataCoverage}
            setDataCoverage={setDataCoverage}
            mutateDistrict={mutateDistrict}
            mutateCity={mutateCity}
            isLoadingCity={isLoadingCity}
          />
          <ModalDistrict
            isOpen={districtDisclosure.isOpen}
            onClose={districtDisclosure.onClose}
            selectedIndex={selectedIndex}
            dataCoverage={dataCoverage}
            setDataCoverage={setDataCoverage}
            mutateDistrict={mutateDistrict}
            isLoadingDistrict={isLoadingDistrict}
          />
        </>
      )}
      <Flex flexDirection="column" gap="24px">
        <Flex gap="16px" alignContent="center">
          <Box
            backgroundColor="blue50"
            width="4px"
            orientation="vertical"
            borderRadius="8px"
            height="24px"
          />
          <Text variant="subtitle" color="black75" weight="bold">
            Wilayah Pengiriman
          </Text>
        </Flex>
        {(isLoading && isLoadingProvince) && (
          <Flex gap="16px" flexWrap="wrap">
            <Skeleton width="240px" height="40px" />
            <Skeleton width="315px" height="40px" />
            <Skeleton width="315px" height="40px" />
            <Skeleton width="240px" height="40px" />
          </Flex>
        )}

        {(!isLoading && !isLoadingProvince) &&
          dataCoverage?.map((coverage, indexCoverage) => {
            const optProvince = {
              label: coverage?.selectedProvince?.province_name,
              value: coverage?.selectedProvince?.province_id,
            };
            
            return (
              <Flex
                gap="16px"
                flexWrap="wrap"
                key={`coverage-row${indexCoverage}`}
              >
                <Flex
                  width="240px"
                  flexDirection="column"
                  gap="8px"
                  id={`selectedProvince${indexCoverage}`}
                >
                  <Text variant="caption" weight="bold" color="black50">
                    Provinsi
                  </Text>
                  <SelectInput
                    label="Provinsi"
                    placeholder="Pilih Provinsi"
                    options={dataProvince && dataProvince}
                    prefix={<Icon name="Search-outline" size={20} />}
                    onChange={(event) =>
                      handleChangeProvince(event, indexCoverage)
                    }
                    value={optProvince}
                    disabled={isLoadingProvince || isLoading}
                    isError={validation[indexCoverage]?.selectedProvince}
                  />
                  {validation[indexCoverage]?.selectedProvince && (
                    <Text color="red50" variant="caption">
                      Provinsi harus dipilih.
                    </Text>
                  )}
                </Flex>
                <Box width="315px">
                  <Input
                    label="Kabupaten"
                    placeholder={
                      !isEmpty(coverage.selectedProvince)
                        ? "Terpilih Semua"
                        : "Pilih Kabupaten"
                    }
                    sufix={<Icon name="Right-outline" size={20} />}
                    onClick={() => handleShowCity(indexCoverage)}
                    disabled={isEmpty(coverage.selectedProvince) || isLoading}
                    value={coverage?.selectedCity
                      ?.map((cities) => cities.city_name)
                      .join(", ")}
                    readOnly
                    // autoFocus={true}
                  />
                </Box>
                <Box width="315px">
                  <Input
                    label="Kecamatan"
                    placeholder={
                      !isEmpty(coverage.selectedProvince)
                        ? "Terpilih Semua"
                        : "Pilih Kecamatan"
                    }
                    sufix={<Icon name="Right-outline" size={20} />}
                    disabled={coverage.selectedCity.length === 0 || isLoading}
                    onClick={() => handleShowDistrict(indexCoverage)}
                    value={coverage.selectedDistrict
                      .map((district) => district.district_name)
                      .join(", ")}
                    readOnly
                  />
                </Box>

                <Flex
                  justifyContent="space-between"
                  width="100%"
                  alignItems="center"
                >
                  <Box width="240px">
                    <Input
                      disabled={isLoading}
                      isError={validation[indexCoverage]?.estimateDays}
                      error={validation[indexCoverage]?.estimateDaysText}
                      label="Estimasi Sampai"
                      placeholder="Contoh : 1-2"
                      min={1}
                      onChange={(event) =>
                        handleChangeEstimate(event, indexCoverage)
                      }
                      value={coverage.estimateDays}
                      sufix={
                        <Text weight="bold" color="black50">
                          Hari kerja
                        </Text>
                      }
                    />
                  </Box>

                  {isAllowRemoveArea && (
                    <Button
                      variant="tertiary"
                      size="medium"
                      onClick={() => handleDeleteCoverage(indexCoverage)}
                      icon={<Icon name="Trash-outline" size={16} />}
                    />
                  )}
                </Flex>
              </Flex>
            );
          })}

        <Divider />
        <Flex
          gap="8px"
          alignItems="center"
          cursor={isAllowAddArea ? "pointer" : "not-allowed"}
          alignSelf="self-start"
          onClick={isAllowAddArea ? handleAddArea : () => {}}
        >
          <Icon
            name="Plus-solid"
            size={20}
            color={isAllowAddArea ? "blue50" : "black50"}
          />
          <Text color={isAllowAddArea ? "blue50" : "black50"} weight="bold">
            Tambah Wilayah
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default DeliveryArea;
