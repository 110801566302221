import { useEffect, useState } from "react";
import { useDeliveryContext } from "store/seller-fleet/reducer";
import constants from "store/seller-fleet/constants";

const useModalProvince = ({
  onClose,
  selectedIndex,
  submitProvince,
  isOpen,
  isLoadingProvince,
}) => {
  const { state, dispatch } = useDeliveryContext();
  const { dataCoverage } = state;
  const [dataModal, setDataModal] = useState({
    listProvince: [],
    selectedProvince: {},
  });
  const [dataSearch, setDataSearch] = useState([]);

  const handleChangeCheckBox = (value) => {
    setDataModal((prevDataModal) => ({
      ...prevDataModal,
      selectedProvince:
        prevDataModal.selectedProvince.province_id === value.province_id
          ? {}
          : value,
    }));
  };

  const filterProvinces = (searchValue, provinces) => {
    return searchValue
      ? provinces.filter((province) =>
          province.province_name.toLowerCase().includes(searchValue)
        )
      : provinces;
  };

  const handleSearch = (event) => {
    const searchValue = event.target.value.toLowerCase();
    const filteredProvinces = filterProvinces(
      searchValue,
      dataModal.listProvince
    );
    setDataSearch(filteredProvinces);
  };

  const handleSave = () => {
    const tempCoverage = dataCoverage;
    tempCoverage[selectedIndex].selectedProvince = dataModal.selectedProvince;
    tempCoverage[selectedIndex].selectedCity = [];
    tempCoverage[selectedIndex].selectedDistrict = [];

    dispatch({
      type: constants.GET_PROVINCE_BY_INDEX,
      payload: {
        dataCoverage: tempCoverage,
      },
    });
    submitProvince(tempCoverage);
    onClose();
  };

  useEffect(() => {
    const selectedCoverage = dataCoverage[selectedIndex];

    if (!isLoadingProvince) {
      setDataSearch(selectedCoverage?.listProvince);
      setDataModal({
        ...dataModal,
        listProvince: selectedCoverage?.listProvince,
        selectedProvince: selectedCoverage?.selectedProvince,
      });
    }
    // eslint-disable-next-line
  }, [isOpen]);

  return {
    state,
    dispatch,
    dataSearch,
    setDataSearch,
    dataModal,
    setDataModal,
    handleChangeCheckBox,
    handleSearch,
    handleSave,
    isLoadingProvince,
  };
};

export default useModalProvince;