import React from "react";
import { Flex, Text, Box, Checkbox } from "renos-ui";

const TermCondition = ({ onChange }) => {
  return (
    <Flex
      marginY="24px"
      flexDirection="row"
      columnGap="14px"
      alignItems="center"
    >
      <Flex>
        <Checkbox onChange={onChange} />
      </Flex>
      <Flex>
        <Text>
          Saya telah membaca dan bersedia mengikuti peraturan yang tertulis pada{" "}
          {""}
          <Box
            as="strong"
            cursor="pointer"
            display="inline"
            fontSize="14px"
            fontWeigth="700"
            color="blue50"
            onClick={() => window.open(" https://www.renos.id/terms", "_blank")}
          >
            Syarat dan Ketentuan,
          </Box>
          <Box
            as="strong"
            cursor="pointer"
            display="inline"
            fontSize="14px"
            fontWeigth="700"
            color="blue50"
            onClick={() =>
              window.open(
                "https://assets.renos.id/placeholder/Perjanjian%20Penjual%20Renos.id.pdf",
                "_blank"
              )
            }
          >
            {""} Perjanjian Penjual
          </Box>
          , dan{" "}
          <Box
            as="strong"
            cursor="pointer"
            display="inline"
            fontSize="14px"
            fontWeigth="700"
            color="blue50"
            onClick={() =>
              window.open(" https://www.renos.id/privacy", "_blank")
            }
          >
            Ketentuan Penjual
          </Box>{" "}
          Renos.id
        </Text>
      </Flex>
    </Flex>
  );
};

export default TermCondition;