import MobileLayout from "layouts/MobileLayout";
import React, { useEffect, useState } from "react";
import { Box, Flex, Icon, Skeleton, Text } from "renos-ui";
import { useGetCategories } from "services/product-category";
import Accordion from "./Accordion";
import { useProductAddContext } from "store/productAdd/reducer";
import constants from "store/productAdd/constants";
import { goToCustomerService } from "helpers";

const ProductCategories = () => {
  const { dispatch, state: product } = useProductAddContext();

  const { data: categories, isLoading: isCategoryLoading } = useGetCategories();

  const [selectedCategory, setSelectedCategory] = useState([]);

  const { isEdit } = product;

  const onBack = (selected) => {
    if (!selected) return;
    if (!selected[0]) return;

    setSelectedCategory(selected);

    let productAttributes = [];

    const excludeFile = selected[0]?.category_attributes?.filter(
      (item) => item.data_type !== "file"
    );

    if (excludeFile.length >= 0) {
      productAttributes = excludeFile.map((item) => ({
        ...item,
        product_attribute_value: "",
      }));
    }

    dispatch({
      type: constants.SAVE_VALUE,
      payload: {
        tempProductCategories: selected,
        product_attributes: productAttributes,
      },
    });
  };

  useEffect(() => {
    if (selectedCategory.length === 0 && product.productCategories.length > 0) {
      setSelectedCategory(product.productCategories);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product.productCategories]);

  return (
    <MobileLayout
      header={{
        title: "Pilih Kategori",
        withBack: true,
        onBack,
      }}
    >
      <Flex flexDirection="column" width="100%">
        {isEdit && (
          <Flex
            p="12px 16px"
            gap="8px"
            backgroundColor="yellow5"
            borderRadius="8px"
            alignItems="center"
          >
            <Icon name="Info-solid" size={40} color="yellow50" />
            <Text color="yellow100" variant="caption">
              Kamu tidak dapat mengubah kategori utama dari produk ini. Segera
              hubungi{" "}
              <Box
                as="b"
                onClick={goToCustomerService}
                style={{ textDecoration: "underline" }}
                cursor="pointer"
              >
                Customer Service Renos.id
              </Box>{" "}
              jika ingin melakukan pengubahan kategori utama.
            </Text>
          </Flex>
        )}
        {isCategoryLoading ? (
          <Skeleton />
        ) : (
          categories.map((category) => (
            <Accordion
              {...category}
              key={category.category_id}
              setSelectedCategory={setSelectedCategory}
              selectedCategory={selectedCategory}
              onBack={onBack}
            />
          ))
        )}
      </Flex>
    </MobileLayout>
  );
};

export default ProductCategories;
