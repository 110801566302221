import { Button, colors, Flex } from "renos-ui";
import styled from "styled-components";

export const StyledButton = styled(Button)`
  background-color: transparent;
  border: 1px solid ${colors.white};

  :hover {
    background-color: transparent;
  }
`;

export const StyledButtonIcon = styled(Button)`
  background-color: transparent;
  border: 1px solid ${colors.white};
  padding: 6px;
  :hover {
    background-color: transparent;
  }
`;

export const StyledImgSquare = styled(Flex)`
  object-fit: contain;
`;

export const StyledButtonBanner = styled(Button)`
  padding: 6px 12px;
  border-radius: 4px;
`;
