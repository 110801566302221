import ENDPOINTS from "constants/endpoints";
import { useMutation, useQuery } from "react-query";
import client from "../../client";

const getMasterBannerTypeFetcher = async () => {
  const { data } = await client.get(ENDPOINTS.banner_master_type);

  return data;
};

export const useGetMasterBannerType = (extraParams) => {
  return useQuery(["getMasterBannerType"], () => getMasterBannerTypeFetcher(), {
    ...extraParams,
  });
};

const uploadBannerFetcher = async (body) => {
  const { data } = await client.post(ENDPOINTS.banner_upload, body, {
    headers: { "Content-Type": "multipart/form-data" },
  });

  return data;
};

export const useUploadBanner = (extraParams) =>
  useMutation((body) => uploadBannerFetcher(body), {
    ...extraParams,
  });
