import { Button, Flex, Icon, Text, useDisclosure } from "renos-ui";
import DialogSelect from "../../../DialogSelect";
import desktopUrl from "constants/desktopUrl";
import { useHistory } from "react-router-dom";

const Sidebar = ({ onClose, data, idGet }) => {
  const {
    isOpen,
    onClose: onCloseSelect,
    onOpen,
  } = useDisclosure({ isOpen: false });
  const { push } = useHistory();

  return (
    <Flex
      height="calc(600px - 32px)"
      width="calc(286px - 32px)"
      borderRadius="16px"
      p="16px"
      backgroundColor="white"
      flexDirection="column"
      justifyContent="space-between"
    >
      <Flex flexDirection="column" gap="24px">
        <Flex flexDirection="column" gap="6px">
          <Text variant="subtitle" weight="bold">
            {data?.name}
          </Text>
          <Text color="black50">{data?.description}</Text>
        </Flex>
        <Flex flexDirection="column" gap="6px">
          <Text variant="subtitle" weight="bold">
            List Komponen
          </Text>
          <Flex flexDirection="column">
            {data?.components?.map((component, indexComponent) => (
              <Flex
                key={`component-${indexComponent}`}
                alignItems="center"
                gap="8px"
              >
                <Icon name="Circle-solid" color="black50" size={4} />
                <Text color="black50">{component.name}</Text>
              </Flex>
            ))}
          </Flex>
        </Flex>
      </Flex>

      <Flex flexDirection="column" gap="8px" justifyContent="center">
        <Button
          size="medium"
          isBlock
          onClick={() => {
            if (data?.id === idGet) push(desktopUrl.STORE_LAYOUT);
            onOpen();
          }}
        >
          {data?.id === idGet ? "Tetap Gunakan" : "Gunakan Template"}
        </Button>
        <Button size="medium" variant="tertiary" isBlock onClick={onClose}>
          Tutup
        </Button>
      </Flex>
      <DialogSelect {...{ isOpen, onClose: onCloseSelect, data: data }} />
    </Flex>
  );
};

export default Sidebar;
